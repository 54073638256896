import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, brand } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

import { Button, CollapsibleContent, LoadingMask, NotFound, CollapsibleIcon } from '@tbh/ui-kit';
import MarketGroupItem from './MarketGroupItem/MarketGroupItem';
import MarketGroupFrame from './MarketGroupFrame/MarketGroupFrame';

const StyledMarketGroupings__Item = styled(MarketGroupItem)`
	label: MarketGroupings__Item;

	${(props) =>
		css`
			margin-bottom: ${spacings(props).tight}px;
		`};
`;

const StyledMarketGroupings__ToggleWrap = styled('div')`
	label: MarketGroupings__ToggleWrap;

	${(props) =>
		css`
			text-align: center;
			border-top: 1px solid ${brand(props).color_1};
		`};
`;

const StyledMarketGroupings__ToggleIcon = styled(CollapsibleIcon)`
	label: MarketGroupings__ToggleIcon;

	${(props) =>
		css`
			vertical-align: text-bottom;
			padding-left: ${spacings(props).compact}px;
		`};
`;

class MarketGroupings extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Action to open the bet prompt */
		addToSingle: PropTypes.func.isRequired,

		/** The ID of the selected event */
		eventId: PropTypes.number.isRequired,

		/** Action to fire when more markets are requested */
		onShowMoreMarkets: PropTypes.func.isRequired,

		/** Sets of markets for the event */
		marketGroupItems: PropTypes.arrayOf(
			PropTypes.shape({
				/** Market groupID */
				id: PropTypes.number.isRequired,

				/** Whether to open the market group be default */
				marketGroupInitiallyOpen: PropTypes.bool,

				/** Market group name */
				name: PropTypes.string.isRequired,

				/** List of market items in the group */
				markets: PropTypes.array,
			}),
		).isRequired,

		/** The products available for the event */
		products: PropTypes.arrayOf(
			PropTypes.shape({
				product_id: PropTypes.number.isRequired,
				product_type: PropTypes.string.isRequired,
			}),
		).isRequired,

		/** Action to fetch the rolled up price of a boosted fixed price */
		fetchFixedPriceRollup: PropTypes.func,

		/** Action to fetch the amount of rolls for a price */
		fetchRollTablePrice: PropTypes.func,

		/** Base API src for player bets widget */
		playerBetsWidgetSrc: PropTypes.string,

		/** Feature toggle for the player bets widgets */
		playerBetsEnabled: PropTypes.bool,

		/** Player Bets iframe link */
		dstURI: PropTypes.string,

		/** Action to add a selection to the multi bet slip */
		addToMulti: PropTypes.func,

		/** Action to fire when extra markets are to be hidden */
		onHideMoreMarkets: PropTypes.func,

		/** Markets are loading */
		isLoading: PropTypes.bool,

		/** Whether or not to show all markets or not */
		showAllMarkets: PropTypes.bool,

		/** Indicate whether bump is available */
		bumpAvailable: PropTypes.bool,

		/** Disable a market from being selected */
		disableMarket: PropTypes.bool,

		/** The currently authenticated user */
		user: PropTypes.shape({
			/** Their username */
			username: PropTypes.string.isRequired,

			/** Their laravel session ID */
			user_token: PropTypes.string.isRequired,
		}),

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		dstURI: null,
		marketGroupInitiallyOpen: false,
		addToMulti: null,
		eventId: null,
		isLoading: false,
		onHideMoreMarkets: function() {},
		showAllMarkets: false,
		disableMarket: false,
		bumpAvailable: false,
		playerBetsEnabled: false,
		fetchFixedPriceRollup: () => {},
		fetchRollTablePrice: () => {},
		playerBetsWidgetSrc: '*',
		user: null,
	};

	state = {
		showingMarketGroups: this.props.showAllMarkets,
		hasTriedToLoadMoreMarkets: false,
		prevProps: this.props,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		/**
		 * Open all markets when opening single event
		 */
		const prevProps = prevState.prevProps;
		const showingMarketGroups =
			prevProps.showAllMarkets !== nextProps.showAllMarkets ? nextProps.showAllMarkets : prevState.showingMarketGroups;
		return {
			showingMarketGroups,
			prevProps: nextProps,
		};
	}

	/**
	 * Toggles the market groups
	 */
	toggleMarketGroups = () => {
		if (!this.state.showingMarketGroups) {
			this.props.onShowMoreMarkets(this.props.eventId);
		} else {
			this.props.onHideMoreMarkets(this.props.eventId);
		}

		this.setState((state) => {
			return {
				showingMarketGroups: !state.showingMarketGroups,
			};
		});
	};

	render() {
		const {
			t,
			className,
			user,
			dstURI,
			eventId,
			bumpAvailable,
			products,
			disableMarket,
			marketGroupItems,
			addToSingle,
			addToMulti,
			isLoading,
			fetchFixedPriceRollup,
			fetchRollTablePrice,
			playerBetsWidgetSrc,
			playerBetsEnabled,
		} = this.props;

		const { showingMarketGroups } = this.state;

		const toggleText = showingMarketGroups ? t('HideMarkets') : t('MoreMarkets');

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<div className={componentClasses}>
				<CollapsibleContent
					contentClassName={css`
						min-height: 50px;
					`}
					isCollapsed={!showingMarketGroups}
				>
					<LoadingMask loading={isLoading} transparent={!dstURI} />
					{!isLoading &&
						marketGroupItems.map((item, index) => (
							<StyledMarketGroupings__Item
								key={item.id}
								disableMarket={disableMarket}
								marketGroupInitiallyOpen={index === 0}
								market_group_name={item.name}
								marketItems={item.markets || []}
								addToSingle={addToSingle}
								addToMulti={addToMulti}
								bumpAvailable={bumpAvailable}
								fetchFixedPriceRollup={fetchFixedPriceRollup}
								fetchRollTablePrice={fetchRollTablePrice}
								products={products}
							/>
						))}

					{playerBetsEnabled &&
						dstURI && (
							<MarketGroupFrame
								title={t('PlayerBets')}
								frameURI={dstURI}
								data={eventId}
								user={user}
								playerBetsWidgetSrc={playerBetsWidgetSrc}
								inView={showingMarketGroups}
							/>
						)}

					{marketGroupItems.length === 0 &&
						!dstURI &&
						!isLoading &&
						this.state.showingMarketGroups && <NotFound message={t('MarketGroupings__NoMarketsMessage')} />}
				</CollapsibleContent>

				<StyledMarketGroupings__ToggleWrap>
					<Button action={this.toggleMarketGroups} size="small" type="primary">
						{toggleText}
						<StyledMarketGroupings__ToggleIcon isCollapsed={!showingMarketGroups} color="white" />
					</Button>
				</StyledMarketGroupings__ToggleWrap>
			</div>
		);
	}
}

export default withNamespaces()(MarketGroupings);
