import { get } from '../../../common/Ajax';
import { updateEntities, replaceEntities } from '../../../common/actions/actionHelpers';
import { normalizeCompetitions } from '../schemas/CompetitionSchema';
import { denormalizeEvents } from '../schemas/EventSchema';
import { fetchCombinedMarketsGroupsWithSelections } from './MarketActions';
import { isFutureEvent } from '../../../pages/Sports/SportsHome/sportsHomeSelectors';

/**
 * Loads competition with events for given competition.
 *
 * @param competition_id
 */
export const fetchCompetitionWithEvents = (competition_id) => (dispatch) => {
	return get('combined/events/markets/selections', {
		params: { competition_id },
	}).then((response) => {
		// dispatch(updateEntities(normalizeCompetitions(response.data.data).entities));
		dispatch(replaceEntities(normalizeCompetitions(response.data.data).entities));
	});
};

/**
 * Action to fetch a single competition model data.
 * @param competitionID
 */
export const fetchCompetition = (competitionID) => (dispatch) => {
	return get(`competitions/${competitionID}`).then((response) => {
		let competition = response.data.data;
		// This endpoint is receiving 'events' field with undefined
		// Setting empty array in theses cases avoid normalization problems.
		// Todo: improve endpoints to avoid sending undefined when it should send empty arrays.
		competition.events = competition.events || [];
		dispatch(updateEntities(normalizeCompetitions([competition]).entities));
	});
};

/**
 * Assess number of events for given competition already in state
 * and fetch markets if there is a single event only.
 * @param competitionId
 */
export const fetchMarketWhenSingleEvent = (competitionId) => (dispatch, getState) => {
	const state = getState();
	const competition = state.entities.competitions[competitionId] || {};
	let competitionEvents = Array.isArray(competition.events) ? competition.events : [];

	// If there is more than 1 event we should filter them first to only list future events
	if (competitionEvents.length > 1) {
		const denormalizedEvents = denormalizeEvents(state.entities, competitionEvents);
		competitionEvents = denormalizedEvents.filter(isFutureEvent).map((event) => event.id);
	}

	// If there is only one event, fetch it's markets
	if (competitionEvents.length === 1) {
		return dispatch(fetchCombinedMarketsGroupsWithSelections(competitionEvents[0]));
	}

	return Promise.resolve();
};
