// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';

// Components
import SettlingPositionRunner from './SettlingPositionRunner/SettlingPositionRunner';
import { PlotElements } from '@tbh/ui-kit';

// Functions
import { getBackingPosition, getSettlingRows } from '../../../../../pages/Racing/RacingHome/racingSelectorsGRS';

const StyledSettlingPosition__Row = styled(PlotElements)(
	(props) => css`
		label: SettlingPosition__Row;

		position: relative;
		height: ${spacings(props).roomy}px;
	`,
	media(
		css`
			height: 46px;
		`,
		380,
	),
	media(
		(props) => css`
			height: ${spacings(props).spacious}px;
		`,
		620,
	),
);

const StyledSettlingPosition = styled('div')(
	(props) => css`
		label: SettlingPosition;

		margin: 0 0 ${spacings(props).cozy}px ${spacings(props).cozy}px;
	`,
);

const SettlingPosition = (props) => {
	const { className, selections, handleClickSelection, raceProducts, isBettingAvailable, size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const backingPosition = getBackingPosition(selections, 'position');

	return (
		<StyledSettlingPosition className={componentClasses}>
			{getSettlingRows(selections).map((row) => {
				return (
					<StyledSettlingPosition__Row key={row} size={size}>
						{selections
							.filter((sel) => sel.row && sel.position && sel.row === row)
							.map((sel) => (
								<SettlingPositionRunner
									key={`${sel.id}+${sel.position}${sel.row}`}
									selection={sel}
									backingPosition={backingPosition}
									raceProducts={raceProducts}
									isBettingAvailable={isBettingAvailable}
									handleClickSelection={handleClickSelection}
									size={size}
								/>
							))}
					</StyledSettlingPosition__Row>
				);
			})}
		</StyledSettlingPosition>
	);
};

SettlingPosition.propTypes = {
	/** Handle when a selection is clicked on */
	handleClickSelection: PropTypes.func.isRequired,

	/** Race selections */
	selections: PropTypes.arrayOf(
		PropTypes.shape({
			/** Runner id */
			id: PropTypes.number.isRequired,
			/** Runner number */
			number: PropTypes.number,
			/** Runner settling speed positions */
			settling_speed_positions: PropTypes.arrayOf(PropTypes.string),
			/** Runner silk */
			silk: PropTypes.string,
			/** Runner settling row */
			row: PropTypes.string,
			/** Runner settling speed position */
			position: PropTypes.string,
		}),
	).isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Whether or not betting is allowed on the race */
	isBettingAvailable: PropTypes.bool,

	/** List of betting products on the race */
	raceProducts: PropTypes.arrayOf(
		PropTypes.shape({
			available: PropTypes.bool.isRequired,
			bet_type: PropTypes.string.isRequired,
			fixed: PropTypes.bool.isRequired,
			product_id: PropTypes.number.isRequired,
		}),
	),

	/** Extra classes */
	className: PropTypes.string,
};

SettlingPosition.defaultProps = {
	className: '',
	raceProducts: [],
	isBettingAvailable: true,
};

export default SettlingPosition;
