import {get} from '../../../common/Ajax';
import {updateEntities} from '../../../common/actions/actionHelpers';
import {normalizeEvents} from '../schemas/EventSchema';

/**
 * Action to fetch a single event model data.
 * @param eventID
 */
export const fetchEvent = (eventID) => (dispatch) => {
	return get(`events/${eventID}`)
		.then((response) => {
			dispatch(updateEntities(normalizeEvents([response.data.data]).entities));
		});
};