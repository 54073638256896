import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { ui, brand, spacings, LightenDarkenColor, PlotElements } from '@tbh/ui-kit';

import CurrencyDisplay from '../../../features/Application/Currency/CurrencyDisplay/CurrencyDisplay';

const StyledRange__Label = styled('label')(
	(props) => css`
		label: Range__Label;
		position: relative;
		display: block;
		margin-bottom: ${spacings(props).tight}px;
		width: 100%;

		${props.hideLabel &&
			css`
				display: none;
			`};
	`,
);

const StyledRange__Input = styled('input')((props) => {
	const RANGE_HANDLE_SIZE = `${spacings(props).cozy}px`;
	const RANGE_TRACK_HEIGHT = `${spacings(props).tight}px`;
	const RANGE_LABEL_WIDTH = '75px';
	const RANGE_TRACK_COLOR = ui(props).color_3;

	return css`
		label: Range__Input;
		appearance: none; // Hides the slider so that custom slider can be made
		min-height: ${spacings(props).cozy}px;
		width: calc(100% - (${RANGE_LABEL_WIDTH} + 12px)); // Specific width is required for Firefox
		background: transparent; // Otherwise white in Chrome
		padding: 0;
		outline: none;

		/** Chrome and Safari */
		&::-webkit-slider-thumb {
			appearance: none;
			height: ${RANGE_HANDLE_SIZE};
			width: ${RANGE_HANDLE_SIZE};
			background: ${brand(props).color_2};
			border-radius: 50%;
			margin-top: -6px; // You need to specify a margin in Chrome, but in Firefox and IE it is automatic
			cursor: pointer;
			transition: background-color 0.1s ease-in-out;

			&:hover {
				background: ${LightenDarkenColor(brand(props).color_2, 30)};
			}
		}

		&:active::-webkit-slider-thumb {
			background: ${LightenDarkenColor(brand(props).color_2, 30)};
		}

		&::-webkit-slider-runnable-track {
			appearance: none;
			height: ${spacings(props).tight}px;
			background-color: ${RANGE_TRACK_COLOR}; // Otherwise white in Chrome
			border: 0;
		}

		/** Firefox */
		&::-moz-range-thumb {
			height: ${RANGE_HANDLE_SIZE};
			width: ${RANGE_HANDLE_SIZE};
			background: ${brand(props).color_2};
			border: 0;
			border-radius: 50%;
			cursor: pointer;
			transition: background-color 0.1s ease-in-out;

			&:hover {
				background: ${LightenDarkenColor(brand(props).color_2, 30)};
			}
		}

		&:active::-moz-range-thumb {
			background: ${LightenDarkenColor(brand(props).color_2, 30)};
		}

		&::-moz-range-track {
			height: ${RANGE_TRACK_HEIGHT};
			background-color: ${RANGE_TRACK_COLOR}; // Otherwise white in Chrome
			border: 0;
			cursor: pointer;
		}

		/** IE */
		&::-ms-thumb {
			height: ${RANGE_HANDLE_SIZE};
			width: ${RANGE_HANDLE_SIZE};
			background-color: ${brand(props).color_2};
			border-radius: 50%;
			cursor: pointer;
		}
		&::-ms-track {
			height: ${spacings(props).tight}px;
			color: transparent;
			background-color: #eee;
			border: 0;
			cursor: pointer;
		}
		&::-ms-fill-lower,
		&::-ms-fill-upper {
			background: #eee;
			border: 0;
		}
	`;
});

const Range = (props) => {
	const { id, value, min, max, step, label, action, hideLabel } = props;

	return (
		<div
			className={css`
				width: 100%;
			`}
		>
			<StyledRange__Label hideLabel={hideLabel} htmlFor={id}>
				{label}
			</StyledRange__Label>

			<PlotElements align="space-between">
				<StyledRange__Input type="range" id={id} value={value} min={min} max={max} step={step} onChange={action} />
				<CurrencyDisplay amount={value} />
			</PlotElements>
		</div>
	);
};

Range.propTypes = {
	/** Action to fire when the range is changed */
	action: PropTypes.func.isRequired,

	/** ID attribute for the input */
	id: PropTypes.string.isRequired,

	/** Label text */
	label: PropTypes.string.isRequired,

	/** The min amount for the range */
	min: PropTypes.number,

	/** The max amount for the range */
	max: PropTypes.number,

	/** How far to step between each point in the range */
	step: PropTypes.number,

	/** The current value */
	value: PropTypes.number,

	/** Hide the input label */
	hideLabel: PropTypes.bool,
};

Range.defaultProps = {
	min: undefined,
	max: undefined,
	step: undefined,
	value: undefined,
	hideLabel: false,
	action: undefined,
};

export default Range;
