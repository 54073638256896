import { StatusBar } from '@capacitor/status-bar';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_COLORS } from './constants/themes';
import { Capacitor } from '@capacitor/core';

const changeStatusBarColor = async (color) => {
	if (Capacitor.isPluginAvailable('StatusBar')) {
		await StatusBar.setBackgroundColor({ color });
	}
};

const AppStatusBar = ({ isMultiBetSlipOpen, showSidebar }) => {
	useEffect(() => {
		let statusBarColor = DEFAULT_COLORS.df_tertiary_active_color;

		if (isMultiBetSlipOpen) {
			statusBarColor = DEFAULT_COLORS.df_secondary_color;
		}

		if (showSidebar) {
			statusBarColor = DEFAULT_COLORS.df_primary_color;
		}
		changeStatusBarColor(statusBarColor);
	}, [isMultiBetSlipOpen, showSidebar]);

	return null;
};

const mapStateToProps = (state) => {
	return {
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
		showSidebar: state.application.showSidebar,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppStatusBar);
