import createStore from './CreateStore';
import rootReducer from './reducers';
import Tracking from '../middleware/Tracking';
import env from '../common/EnvironmentVariables';
import { mergeEntities, removeEntities, replaceEntities, updateEntities } from '../common/actions/actionHelpers';
import { TRACKING_ACTIONS } from './trackingPixels/trackingPixelsActionTypes';
import { combineReducers } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { addFeatures } from './featureToggles/featureTogglesActions';

/**
 * Generate an action creator for easy testing
 *
 * @param method
 * @returns {Function}
 */
function addSingleEntityToStore(method) {
	return function(type, id, attributes) {
		return method({
			[type]: {
				[id]: attributes,
			},
		});
	};
}

const OPTIONS = {
	name: 'TOPBETTA',
	actionCreators: {
		update: addSingleEntityToStore(updateEntities),
		merge: addSingleEntityToStore(mergeEntities),
		replace: addSingleEntityToStore(replaceEntities),
		remove: addSingleEntityToStore(removeEntities),
		addFeatures: addFeatures,
		...require('./theme/themeActions'),
		...require('./modal/modalActions'),
		// ...require('../actions/TrackingPixelActions'),
		// ...require('../actions/TournamentActions'),
		// ...require('../actions/ActiveTournamentActions'),
		// ...require('../actions/ApplicationActions'),
		// ...require('../actions/TicketActions'),
		// ...require('./entities/actions/testActions'),
		// ...require('./entities/actions/SportsActions'),
		// ...require('./entities/actions/BetActions'),
	},
	actionsBlacklist: env('logTrackingEvents') ? [] : TRACKING_ACTIONS,
};

export default (preloadedState, options = {}) => {
	const newOptions = {
		...OPTIONS,
		//middleware: [Tracking],
		...options,
	};

	const store = createStore(rootReducer, preloadedState, newOptions);

	if (module.hot && process.env.NODE_ENV !== 'production') {
		module.hot.accept('./reducers', () => {
			const nextReducer = require('./reducers').default;
			store.replaceReducer(enableBatching(combineReducers(nextReducer)));
			if (window.devToolsExtension) {
				window.devToolsExtension.updateStore(store);
			}
		});
	}

	return store;
};
