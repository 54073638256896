import styled, { css } from 'react-emotion';
import BannCarousel from '../../Carousel/BannCarousel';
import { media } from '@tbh/ui-kit';

export const cssBannersCarousel = css`
	label: BannersCarousel;

	position: relative;
`;
// const StyledBanner__Carousel = styled(BannCarousel)(
// 	(props) => css`
// 		label: Banner__Carousel;

// 		cursor: auto;
// 		background-color: ${ui(props).color_3};

// 		.swiper-pagination-bullet {
// 			background: ${ui(props).color_3};
// 		}
// 	`,
// );

// Size at which the mode should change over
const modeChangeOverSize = 751;
export const StyledBanner__Carousel = styled(BannCarousel)(
	(props) => css`
		label: Banner__Carousel;
		flex: 1;
		height: auto;
		overflow: hidden;
		border-radius: 4px;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		.swiper-wrapper {
			transform: translate3d(0px, 0px, 0px);
			align-items: center;
		}
	`,
	(props) =>
		media(
			css`
				flex: 1;
				height: auto;
				overflow: hidden;
			`,
			modeChangeOverSize,
		),
);

export const cssBanner__CarouselPagination = css`
	label: Banner__CarouselPagination;

	top: 4px;
	height: 22px;
`;
