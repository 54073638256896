import React from 'react';
import { Icon, Text } from '@tbh/ui-kit';
import styled from 'react-emotion';

const Container = styled('div')(
	(props) =>
		`
	display: flex;
	align-items: center;
	justify-content: center;
    position: absolute;
    gap: 4px;
    padding: 4px;
    top: ${props.top}px;
    right: ${props.right}px;
    border-radius: 8px;
    ${
			props.type === 'red'
				? `
        background: #c72128;
        color: #fff;
    `
				: `
        background: #fff;
        color: #000;
     `
		}
`,
);

export const PendingBetCount = ({ count, type = 'red', top = -24, right = -4 }) => {
	return (
			<Container type={type} top={top} right={right}>
				<Icon
					icon="clock-light"
					size="-3"
					style={{
						color: 'inherit',
					}}
				/>
				<Text
					style={{
						color: 'inherit',
					}}
					size="-3"
					strong
				>
					{count}
				</Text>
			</Container>
	);
};
