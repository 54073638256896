import { get } from '../../../common/Ajax';

import { normalizeRaces, denormalizeRaces } from '../schemas/RaceSchema';
import { normalizeDerivativeSelections } from '../schemas/DerivativeSelectionSchema';
import { mergeEntities, updateEntities, deepCompareMergeEntities } from '../../../common/actions/actionHelpers';
import { normalizeSelections } from '../schemas/SelectionSchema';

/**
 * Returns the action to update a single race.
 *
 * UPDATE: This function has been used by Tournaments.
 * It's been tested in isolation and updated.
 *
 * Previously, Races had the field 'selections' that points to the entity 'raceSelections' slice in state.
 * RaceSelections used to keep their Prices and Runner.Currently, we are adding Prices and Runners to
 * state so we flatten entities in redux store.
 *
 * @param race
 * @returns {Object}
 */
export const updateRace = (race) => {
	return updateEntities(normalizeRaces([race]).entities);
};

/**
 * Normalizes and dispatch merge action for array of Races.
 * @param {Array} races
 */
export const mergeRaces = (races) => {
	return mergeEntities(normalizeRaces(races).entities);
};

/**
 * Normalizes and dispatch merge action for a Race
 * @param race
 */
export const mergeRace = (race) => {
	return mergeRaces([race]);
};

/**
 * Normalizes and dispatch merge action for a derivative selection
 * @param selections
 */
export const mergeDerivativeSelections = (selections) => {
	return mergeEntities(normalizeDerivativeSelections(selections).entities);
};

/**
 * Normalizes and dispatch merge action for a derivative selection
 * @param selection
 */
export const mergeDerivativeSelection = (selection) => {
	return mergeDerivativeSelections([selection]);
};

export const mergeSelection = (selection) => {
	return updateEntities(normalizeSelections([selection]).entities);
};

/**
 * Fetch race and it's selections.
 *
 * @param race_id
 */
export const fetchRaceWithSelections = (race_id) => (dispatch) => {
	return get('combined/race/selections', {
		params: { race_id },
	}).then((response) => {
		// /**
		//  * Due to the requested_at field in individual races after a meeting request occasionally being more recent than
		//  * the requested_at field in the individual race from this call, we need to delete it, otherwise it can cause a
		//  * races merge to not happen and so the race is never updated in the store with it's selections.
		//  */
		// const newResponse = {...response.data.data};
		// delete newResponse.requested_at;

		/**
		 * The bug above has now been fixed, as it was a backend issue caused by converting timestamps to integers.
		 * As it is now fixed we shouldn't strip the requested_at field, however I am keeping the above code as it is
		 * something we may want to look into in the future, especially if it comes back again.
		 */
		const newResponse = response.data.data;
		dispatch(deepCompareMergeEntities(normalizeRaces([newResponse]).entities));
	}).catch((error) => {
		document.Sentry && document.Sentry.captureException(error);
		return error;
	});
};

/**
 * Get the list of races that have selections
 *
 * @returns {Array.<*>|*}
 */
export const getLoadedRaces = () => (dispatch, getState) => {
	let loadedRaces;
	const races = denormalizeRaces(getState().entities);

	if (races) {
		loadedRaces = races.filter((race) => race.selections);
	}

	return loadedRaces;
};
