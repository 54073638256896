import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui } from '@tbh/ui-kit';

// Components
import { Image } from '@tbh/ui-kit';
import { Icon } from '@tbh/ui-kit';
import { Text } from '@tbh/ui-kit';

const StyledCarouselItem__Image = styled(Image)(
	(props) => css`
		label: CarouselItem__Image;

		margin: 0 0 ${spacings(props).compact}px 0;
		pointer-events: none;
		max-width: none;

		${props.center &&
			css`
				margin-left: auto;
				margin-right: auto;
			`};
	`,
);

const StyledCarouselItem__Label = styled(Text)(
	(props) => css`
		label: CarouselItem__Label;

		display: block;
		margin-bottom: ${spacings(props).tight}px;
	`,
);

const StyledCarouselItem__Meta = styled(Text)(
	(props) => css`
		label: CarouselItem__Meta;

		display: block;
		margin-top: ${spacings(props).tight}px;
	`,
);

const StyledCarouselItem = styled(Text)(
	(props) => css`
		label: CarouselItem;

		display: block;
		position: relative;
		cursor: pointer;

		${props.center &&
			css`
				text-align: center;
			`};

		${props.padding &&
			css`
				padding: ${spacings(props).compact}px ${spacings(props).cozy}px;
			`};

		${props.margin &&
			css`
				margin-right: ${spacings(props).cozy}px;
			`};

		${props.border &&
			css`
				border-right: 1px solid ${ui(props).color_3};
			`};

		${props.slides &&
			props.slides === '1' &&
			css`
				width: 100%;
			`};

		${props.slides &&
			props.slides === '2' &&
			css`
				width: 50%;
			`};
	`,
);

const CarouselItem = (props) => {
	const { className, children, id, label, text, meta, icon, image, action, ...rest } = props;
	const { center } = rest;

	const carouselItemClass = cx({
		[className]: className,
	});

	const carouselImage = image && <StyledCarouselItem__Image center={center} height={64} src={image} alt={label} />;
	const carouselIcon = icon && <Icon icon={icon} size="1" push="1" color="primary" />;
	const carouselLabel = label && (
		<StyledCarouselItem__Label size="-1" whiteSpace="nowrap" ellipsis strong>
			{carouselIcon}
			{label}
		</StyledCarouselItem__Label>
	);
	const carouselText = text && (
		<Text align={center ? 'center' : 'left'} size="-3" whiteSpace="nowrap" ellipsis>
			{text}
		</Text>
	);
	const carouselMeta = meta && (
		<StyledCarouselItem__Meta type="primary" size="-2" whiteSpace="nowrap" ellipsis strong>
			{meta}
		</StyledCarouselItem__Meta>
	);

	const handleClick = (e) => {
		e.preventDefault();
		action(id);
	};

	if (children) {
		return (
			<StyledCarouselItem {...rest} className={carouselItemClass} action={handleClick}>
				{children}
			</StyledCarouselItem>
		);
	}

	return (
		<StyledCarouselItem {...rest} className={carouselItemClass} action={handleClick}>
			{carouselImage}
			{carouselLabel}
			{carouselText}
			{carouselMeta}
		</StyledCarouselItem>
	);
};

CarouselItem.propTypes = {
	/** ID of the carousel item */
	id: PropTypes.any.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Label for the carousel item */
	label: PropTypes.string,

	/** Text for the carousel item */
	text: PropTypes.string,

	/** Extra information for the carousel item */
	meta: PropTypes.string,

	/** Icon to display in the carousel item */
	icon: PropTypes.string,

	/** Image to display in the carousel item */
	image: PropTypes.string,

	/** How many slides to display */
	slides: PropTypes.string,

	/** If the carousel item should be centered */
	center: PropTypes.bool,

	/** If the carousel item should have padding */
	padding: PropTypes.bool,

	/** If the carousel item should have a margin */
	margin: PropTypes.bool,

	/** If the carousel item should have a border */
	border: PropTypes.bool,

	/** Action when the carousel item is clicked on */
	action: PropTypes.func,

	/** Children to display under the carousel item */
	children: PropTypes.node,
};

CarouselItem.defaultProps = {
	className: '',
	label: undefined,
	text: undefined,
	meta: undefined,
	icon: undefined,
	image: undefined,
	slides: undefined,
	center: undefined,
	padding: undefined,
	margin: undefined,
	border: undefined,
	action: undefined,
	children: undefined,
};

export default CarouselItem;
