import {UPDATE_SPEND_LIMIT} from './spendLimitsActionsTypes';
import {CLEAR_AUTHENTICATED_USER} from '../application/applicationActionTypes';
import {UPDATE_ALL_LIMITS} from '../limitsActionsTypes';

const initialState = {
	daily_spend_limit: {},
	weekly_spend_limit: {},
	monthly_spend_limit: {}
};

const spendLimits = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_SPEND_LIMIT:
			return {
				...state,
				...action.payload
			};

		case UPDATE_ALL_LIMITS:
			return {
				...state,
				...action.payload.spendLimits
			};

		case CLEAR_AUTHENTICATED_USER:
			return {
				...initialState
			};

		default:
			return state;
	}
};

export default spendLimits;