import {
	CLEAR_USER_ACCOUNT_PAGE,
	USER_ACCOUNT_SET_SELECTED_MENU_ITEM,
	USER_ACCOUNT_V2_SET_SELECTED_MENU_ITEM
} from './userAccountActionTypes';
import menuItems from '../../pages/UserAccount/UserAccountPage.data';
import menuV2 from '../../pages/UserAccountV2/UserAccountV2Page.data';

const initialState = {
	transactionsFilter: 'bets',
	selectedMenuItem: menuItems[1],
	selectedMenuItemV2: menuV2[1],
};

/**
 * Reducer for the user account page
 * @param state
 * @param action
 * @returns {Object}
 */
const userAccount = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_USER_ACCOUNT_PAGE:
			return {
				...initialState,
			};

		case USER_ACCOUNT_SET_SELECTED_MENU_ITEM:
			return {
				...state,
				transactionsFilter: action.payload.transactionsFilter || state.transactionsFilter,
				selectedMenuItem: action.payload.selectedMenuItem || state.selectedMenuItem,
			};

		case USER_ACCOUNT_V2_SET_SELECTED_MENU_ITEM:
			return {
				...state,
				transactionsFilter: action.payload.transactionsFilter || state.transactionsFilter,
				selectedMenuItemV2: action.payload.selectedMenuItemV2 || state.selectedMenuItemV2,
			};


		default:
			return state;
	}
};

export default userAccount;
