import { createAction } from '../../common/actions/actionHelpers';
import { NEXT_TO_JUMP_CHANGE } from './newNextToJumpRacesTypes';
import { get } from '../../common/Ajax';

/**
 * Fetch Next To Jump Races
 * @HW make by Paulo
 */
export const fetchNewNextToJumpRaces_GRS = () => (dispatch) => {
	return get('racing/next-to-jump-grs-v2').then((response) => {
		const result = response.data.result;
		const payload = {
			races: result,
		};

		return dispatch(createAction(NEXT_TO_JUMP_CHANGE, payload));
	});
};
