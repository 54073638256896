import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { withNamespaces } from 'react-i18next';
import { spacings, typography, media, StatCell, Table, Text, Button, LoadingMask ,brand } from '@tbh/ui-kit';
import { MEDIA_QUERIES } from '../../../../constants/themes';

import RunnerLastStartRow from './RunnerLastStartRow/RunnerLastStartRow';
import { RNSAddToBackBook } from '../../../../store/RNS/RNSAddtoBackBook';
import { connect } from 'react-redux';
import { openNotification } from '../../../../store/application/applicationActions';

const StyledRunnerForm = styled('div')`
	${(props) => css`
		label: RunnerForm;

		font-size: ${typography(props).size_0};
		color: ${typography(props).color_1};

		border-left: 2px solid #c72128;
		padding: ${spacings(props).tight}px;
		margin-bottom: ${spacings(props).compact}px;
		position: relative;
	`};
`;

const StyledAddToBackBook__Button = styled(Button)`
	label: AddToBackBook__Button;
	margin-top: 10px;
	${(props) =>
		props.topMargin &&
		css`
			margin-top: ${spacings(props).tight}px;
		`};
`;


const StyledAddToBackBook__Response = styled('span')`
  label: AddToBackBook__Response;
  font-size: 10px;
  margin-left: 10px;
  color: ${props => props.isError ? 'red' : 'green'};
`;

const StyledRunnerForm__Info = styled(Table)`
	${(props) => css`
		label: RunnerForm__Info;

		display: flex;
		flex-wrap: wrap;
		margin-top: ${spacings(props).tight}px;
		margin-bottom: ${spacings(props).tight}px;
		padding: 0 ${spacings(props).constrictive}px ${spacings(props).constrictive}px;
		font-size: ${typography(props)['size_-4']};
	`};
`;

const StyledRunnerForm__Stats = styled('div')`
	${(props) => css`
		label: RunnerForm__Stats;

		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
		gap: 4px;
		font-size: ${typography(props)['size_-4']};

		@media ${MEDIA_QUERIES.laptopAndUp} {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));
		}
	`};
`;

const StyledRunnerForm__StatCell = styled(StatCell)`
	${(props) => css`
		label: RunnerForm__StatCell;

		flex: 1 0 19%;
		margin: ${spacings(props).constrictive}px;
		background:  ${props.highlight ? brand(props).runner_form_block_bg : '#dbdbdb'};
		padding: 3px;
		border-radius: 2px;
		//border-top: 2px solid ${props.highlight ? brand(props).runner_form_block_bg : '#dbdbdb'};
		font-size: 8px;
		
		@media ${MEDIA_QUERIES.laptopAndUp} {
			font-size: 11px;
			padding: 4px;
		}
	`};

	${media(
		css`
			flex: 1 0 12%;
		`,
		500,
	)};

	${media(
		css`
			flex: 1;
		`,
		700,
	)};
`;

const StyledRunnerForm__RunnerComments = styled('div')`
	${(props) => css`
		label: RunnerForm__RunnerComments;

		//margin-top: ${spacings(props).compact}px;
		background: ${brand(props).runner_form_block_bg};
		padding: 6px 8px;
		margin-bottom: 6px;
	`};
`;

const StyledRunnerForm__RunnerCommentsItem = styled(Text)`
	${(props) => css`
		label: RunnerForm__RunnerCommentsItem;
		font-weight: 500;
	`};
`;

const ToggleTableHeader = styled(Table.TR)`
	align-items: center;
	flex-direction: row;
	border: 1px solid #e0e0e0;
	background-color: #f1f1f1;
	color: #000000;
	cursor: pointer;
	@media ${MEDIA_QUERIES.laptopAndUp} {
		display: grid;
		grid-template-columns: 100px 180px 200px 180px 100px 40px;
	}
`;

const ToggleTableBody = styled(Table.TR)`
	align-items: flex-start;
	flex-direction: column;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	color: #000000;
	display: grid;
	grid-template-columns: 1fr;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		display: grid;
		grid-template-columns: 100px 180px 200px 180px 120px 40px;
	}
`;

const ToggleTableData = styled(Table.TD)`
	font-weight: 400;
	font-size: 12px;
	text-align: left;
	padding: 4px 10px;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		font-weight: 400;
	}
`;

const ToggleTableArrowIcon = styled(Table.TD)`
	font-size: 12px;
	text-align: right;
	padding: 10px 16px;
	color: #555555;
	cursor: pointer;
`;

const TableViewData = styled('div')`
	display: block;
	text-align: left;
`;
const TableViewToggleData = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr;
	@media ${MEDIA_QUERIES.laptopAndUp} {
		grid-template-columns: 1fr;
	}
`;

const TableViewDataText = styled('span')`
	display: block;
	font-size: 10px;
	margin-bottom: 3px;
	 overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`;

const ViewDataTextBold = styled('strong')`
	margin-right: 3px;
`;

const RecentBlock = styled('div')`
	margin-top: 8px;
`;

const RecentStartsTitle = styled('h4')`
	color: #000000;
	font-weight: 600;
	font-size: 12px;
	text-transform: uppercase;
	margin-bottom: 6px;
`;

const ToggleTableDataDate = styled(ToggleTableData)`
	display: none;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		display: block;
	}
`;

const ToggleTableDataMeter = styled(ToggleTableData)`
	display: block;
	min-width: 48px;
	font-weight: 600;
	font-size: 8px;
	padding: 4px 4px;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		font-weight: 400;
		font-size: 12px;
		min-width: 120px;
		padding: 4px 10px;
	}
`;

const ToggleTableDataTrCondition = styled(ToggleTableData)`
	display: block;
	min-width: 48px;
	font-weight: 600;
	font-size: 8px;
	padding: 4px 4px;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		font-weight: 400;
		font-size: 12px;
		min-width: 120px;
		padding: 4px 10px;
	}
`;

const ToggleTableDataStartAndFinish = styled(ToggleTableData)`
	display: block;
	min-width: 48px;
	font-weight: 600;
	font-size: 8px;
	padding: 4px 4px;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		font-weight: 400;
		font-size: 12px;
		min-width: 120px;
		padding: 4px 10px;
	}
`;

const ToggleTableDataMgtDate = styled(ToggleTableData)`
	display: block;
	min-width: 48px;
	font-weight: 600;
	font-size: 8px;
	padding: 4px 4px;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		font-weight: 400;
		font-size: 12px;
		min-width: 120px;
		padding: 4px 10px;
	}
`;

const ToggleTableDataABRVenue = styled(ToggleTableData)`
	display: block;
	min-width: 64px;
	font-weight: 600;
	font-size: 8px;
	padding: 4px 4px;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		font-weight: 400;
		font-size: 12px;
		min-width: 120px;
		padding: 4px 10px;
	}
`;

const RunnerFormInfoTableRow = styled(Table.TR)`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));

	@media ${MEDIA_QUERIES.laptopAndUp} {
		display: flex;
		flex-direction: row;
	}
`;

const TableDataOwners = styled(Table.TD)`
	white-space: pre-wrap;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		white-space: nowrap;
	}
`;

const RunnerForm = (props) => {
	const [runnerToggleTable, setRunnerToggleTable] = useState({});

	const [addedToBackBook, setAddedToBackBook] = useState({});



	const handleToggleRunnerForm = (id) => {
		setRunnerToggleTable((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};

	const {
		t,
		size,
		isMobileWidth,
		// info row
		age,
		sex,
		color,
		sire,
		dam,
		foal_date,
		owner,
		colour,

		// stats row
		career,
		track,
		trackOn,
		track_distance,
		track_distanceOn,
		distance,
		distanceOn,
		synthetic,
		good,
		isGood,
		firm,
		isFirm,
		soft,
		isSoft,
		heavy,
		isHeavy,
		night,
		isAllWeather,
		firstUp,
		first_up,
		secondUp,
		second_up,
		thirdUp,
		third_up,
		jockey_horse,
		jockey_horseOn,
		season,
		nonwet,
		wet,
		prize_money,
		prize_moneyOn,
		// last starts row
		last_starts,

		comment,
		className,
		win_percentage,
		place_percentage,
		user,
		handleAddToBackBook,
		MettingName,
		runner,
		raceNumber,
		type_code,
		StartDate,
		meeting,
		backBookResponse,
		handleBlackbook,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const [loading, setLoading] = useState(false);

	const handleClick = async (e) => {
		const payLoad = {
			meeting: MettingName,
			horseName: runner.name,
			meetingName: MettingName,
			raceNo: raceNumber,
			meetingDate: StartDate,
			userName: user.rns_user_name,
			id: 0,
			comment: comment,
			type: type_code,
			discipline: type_code,
			racecountry: '1,2,3,4',
			horseid: runner.id,
			horsecountry: 'AUS',
		};

		setLoading(true);
		await handleBlackbook(payLoad);
		setLoading(false);
	};

	const ordinalSuffixOf = (i) => {
		let j = i % 10;
		let k = i % 100;
		if (j == 1 && k != 11) {
			return i + 'st';
		}
		if (j == 2 && k != 12) {
			return i + 'nd';
		}
		if (j == 3 && k != 13) {
			return i + 'rd';
		}
		return i + 'th';
	};

	const buildToggleData = (item) => {
		return (
			<ToggleTableBody>
				<ToggleTableData
					className={css`
						min-width: 120px;
					`}
				>
					<TableViewToggleData>
						<TableViewDataText>
							<ViewDataTextBold>Margin:</ViewDataTextBold>
							{item.margin_decimal}
						</TableViewDataText>
						<TableViewDataText>
							<ViewDataTextBold>Class:</ViewDataTextBold>
							{item.name_race_form}
						</TableViewDataText>
					</TableViewToggleData>
				</ToggleTableData>
				<ToggleTableData
					className={css`
						min-width: 120px;
					`}
				>
					<TableViewToggleData>
						{type_code === 'T' && (
							<TableViewDataText>
								<ViewDataTextBold>Jockey:</ViewDataTextBold>
								{item.jockey_surname}
							</TableViewDataText>
						)}
						{type_code === 'G' && (
							<TableViewDataText>
								<ViewDataTextBold>Trainer:</ViewDataTextBold>
								{item.jockey_surname}
							</TableViewDataText>
						)}
						{type_code === 'H' && (
							<TableViewDataText>
								<ViewDataTextBold>Driver:</ViewDataTextBold>
								{item.jockey_surname}
							</TableViewDataText>
						)}
						<TableViewDataText>
							<ViewDataTextBold>Time:</ViewDataTextBold>
							{item.runner_time || t('n/a')}
						</TableViewDataText>
					</TableViewToggleData>
				</ToggleTableData>
				<ToggleTableData
					className={css`
						min-width: 120px;
					`}
				>
					<TableViewToggleData>
						<TableViewDataText>
							<ViewDataTextBold>Weight:</ViewDataTextBold>
							{item.runner_race_allocated_weight ? item.runner_race_allocated_weight + 'kg' : t('n/a')}
						</TableViewDataText>
						<TableViewDataText>
							<ViewDataTextBold>
								Last {item.sectional_distance ? item.sectional_distance + 'm' : `(${t('n/a')})`}:
							</ViewDataTextBold>
							{item.sectional || t('n/a')}
						</TableViewDataText>
					</TableViewToggleData>
				</ToggleTableData>
				<ToggleTableData
					className={css`
						min-width: 120px;
					`}
				>
					<TableViewToggleData>
						<TableViewDataText>
							<ViewDataTextBold>Barrier:</ViewDataTextBold>
							{item.barrier}
						</TableViewDataText>
						<TableViewDataText>
							<ViewDataTextBold>1st:</ViewDataTextBold>
							{item.other_runner_name}
							{item.race_winner_weight ? `(${item.race_winner_weight}kg)` : `(${t('n/a')})`}
						</TableViewDataText>
					</TableViewToggleData>
				</ToggleTableData>
				<ToggleTableData
					className={css`
						min-width: 120px;
					`}
				>
					<TableViewToggleData>
						<TableViewDataText>
							<ViewDataTextBold>SP:</ViewDataTextBold>${item.starting_win_price}
						</TableViewDataText>
						<TableViewDataText>
							<ViewDataTextBold>2nd:</ViewDataTextBold>
							{item.race_second}							
							{item.race_second_weight ? `(${item.race_second_weight}kg)` : `(${t('n/a')})`}
						</TableViewDataText>
					</TableViewToggleData>
				</ToggleTableData>
				<ToggleTableData></ToggleTableData>
			</ToggleTableBody>
		);
	};

	return (
		<StyledRunnerForm className={componentClasses}>
			{/* info row */}
			<StyledRunnerForm__Info miniTable>
				<RunnerFormInfoTableRow
					wrapRowItems={true}
					className={css`
						width: 100%;
					`}
				>
					<Table.TD textWrap={false} size="-4">
						<Text tag="span" size="-3">
							{t('Age')}
						</Text>
						:&nbsp;
						<Text tag="span" size="-4" strong>
							{age || t('n/a')}
						</Text>
					</Table.TD>
					<Table.TD textWrap={false} size="-4">
						<Text tag="span" size="-3">
							{t('Sex')}
						</Text>
						:&nbsp;
						<Text tag="span" size="-4" strong>
							{sex || t('n/a')}
						</Text>
					</Table.TD>
					<Table.TD textWrap={false} size="-4">
						<Text tag="span" size="-3">
							Colour
						</Text>
						:&nbsp;
						<Text tag="span" size="-4" strong>
							{colour || t('n/a')}
						</Text>
					</Table.TD>

					<Table.TD textWrap={false} colSpan="2" size="-4">
						<Text tag="span" size="-3">
							{t('Sire')}
						</Text>
						:&nbsp;
						<Text tag="span" size="-4" strong>
							{sire || t('n/a')}
						</Text>
					</Table.TD>
					<Table.TD textWrap={false} colSpan="2" size="-4">
						<Text tag="span" size="-3">
							{t('Dam')}
						</Text>
						:&nbsp;
						<Text tag="span" size="-4" strong>
							{dam || t('n/a')}
						</Text>
					</Table.TD>
					{/* <TableDataOwners textWrap={false} size="-4">
						<Text tag="span" size="-3">
							Owners
						</Text>
						:&nbsp;
						<Text tag="span" size="-4" strong>
							{owner}
						</Text>
					</TableDataOwners> */}
					<Table.TD textWrap={false} size="-4">
						<Text tag="span" size="-3">
							{t('W% - P%')}
						</Text>
						:&nbsp;
						<Text tag="span" size="-4" strong>
							{win_percentage && place_percentage ? win_percentage + '%' + '-' + place_percentage + '%' : t('-')}
						</Text>
					</Table.TD>
					{/*
            <Table.TD textWrap={false} colSpan="2" size="-5">
						{t('DOB')}
						:&nbsp;
						<Text tag="span" size="-5" strong>
							{foal_date || t('n/a')}
						</Text>
					</Table.TD>
					<Table.TD colSpan="4" size="-5">
						{t('Owners')}
						:&nbsp;
						<Text tag="span" size="-5" strong>
							{owner || t('n/a')}
						</Text>
					</Table.TD> */}
				</RunnerFormInfoTableRow>
			</StyledRunnerForm__Info>

			{comment && (
				<StyledRunnerForm__RunnerComments>
					<StyledRunnerForm__RunnerCommentsItem size="-2" type="primary">
						<Text tag="span" size="-3" strong>
							{t('RunnerForm__RunnerComments')}
						</Text>
					</StyledRunnerForm__RunnerCommentsItem>
					<StyledRunnerForm__RunnerCommentsItem
						size="-3"
						className={css`
							font-weight: 500;
						`}
					>
						{comment}
					</StyledRunnerForm__RunnerCommentsItem>
				</StyledRunnerForm__RunnerComments>
			)}

			{/* stats row */}
			<StyledRunnerForm__Stats>
				<StyledRunnerForm__StatCell
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Overall')}
					value={career}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={firstUp}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('1st Up')}
					value={first_up}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={secondUp}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('2nd Up')}
					value={second_up}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={thirdUp}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('3rd Up')}
					value={third_up}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={trackOn}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Track')}
					value={track}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={distanceOn}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Distance')}
					value={distance}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={track_distanceOn}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('TrackDistance')}
					value={track_distance}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={jockey_horseOn}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Jockey/Horse')}
					value={jockey_horse}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={isFirm}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Firm')}
					value={firm}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={isGood}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Good')}
					value={good}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={isSoft}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Soft')}
					value={soft}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={isHeavy}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Heavy')}
					value={heavy}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={isAllWeather}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label="All Weather"
					value={synthetic}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					highlight={prize_moneyOn}
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Prize Money')}
					value={
						prize_money && Number(prize_money) != 0
							? Number(prize_money).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
							: '-'
					}
					wrapLabel={false}
					wrapValue={false}
				/>

				{/*				<StyledRunnerForm__StatCell
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Non Wet')}
					value={nonwet}
					wrapLabel={false}
					wrapValue={false}
				/>
				<StyledRunnerForm__StatCell
					spacing={StatCell.spacings.SPACING_CONSTRICTIVE}
					size={size}
					label={t('Wet')}
					value={wet}
					wrapLabel={false}
					wrapValue={false}
				/>*/}
			</StyledRunnerForm__Stats>
			{last_starts > 0 && (
				<Table miniTable striped>
					<Table.TR
						className={css`
							width: 100%;
							background: #c3c5d7;
							border: 1px solid #091257;
						`}
					>
						<Table.TD textWrap={false} strong>
							FP
						</Table.TD>
						<Table.TD textWrap={false} strong colSpan="2">
							TIME
						</Table.TD>
						<Table.TD textWrap={false} colSpan="2">
							DATE
						</Table.TD>
						<Table.TD textWrap={false} colSpan="3" center>
							TRACK
						</Table.TD>
						<Table.TD textWrap={false} right>
							MARGIN
						</Table.TD>
						<Table.TD textWrap={false} right colSpan="2">
							DIST
						</Table.TD>
						<Table.TD textWrap={false} colSpan="2" center>
							RACE
						</Table.TD>

						<Table.TD textWrap={false} colSpan="2">
							SOT
						</Table.TD>
						<Table.TD textWrap={false} strong colSpan="3">
							JOCKEY
						</Table.TD>
						{/* <Table.TD textWrap={false} right/> */}
						<Table.TD textWrap={false} right>
							BP
						</Table.TD>
						<Table.TD textWrap={false} right colSpan="2">
							SP
						</Table.TD>
						<Table.TD strong colSpan="4" center>
							WINNER
						</Table.TD>
						{/* <Table.TD textWrap={false} center/>
					<Table.TD textWrap={false}  right/> */}
					</Table.TR>

					{last_starts.map((lastStartRow) => (
						<RunnerLastStartRow key={lastStartRow.id} {...lastStartRow} />
					))}
				</Table>
			)}

			{/* <br/> */}

			{/*<Table.TR className={css`
						width: 100%;
						background: #c3c5d7;
						border: 1px solid #091257;
					`}>
						<Table.TD textWrap={false}  strong colSpan="11">Comment</Table.TD>
						<Table.TD textWrap={false}   colSpan="1">FP</Table.TD>
						<Table.TD textWrap={false}  >DATE</Table.TD>
						<Table.TD textWrap={false}  colSpan="2" center>RACE</Table.TD>

				</Table.TR>*/}

			{/*					{last_starts.map((lastStartRow) => (
						lastStartRow.comment && (
							<RunnerLastStartCommentRow key={lastStartRow.id} {...lastStartRow} />
						)

					))}*/}

			<LoadingMask loading={loading} />

			{user && user.rns_user_name !== null && (
				<div>
					<StyledAddToBackBook__Button
						action={handleClick}
						type="secondary"
						disabled={addedToBackBook[runner.id]} // Disable the button if already added
					>
						{t('Add To Blackbook')}
					</StyledAddToBackBook__Button>

					{/* Conditionally render the green label for this specific horse */}
					{addedToBackBook[runner.id] && (
						<span style={{ color: 'green', marginLeft: '10px' }}>Successfully added!</span>
					)}
				</div>
			)}

			<StyledAddToBackBook__Response>{backBookResponse}</StyledAddToBackBook__Response>
			{last_starts.length > 0 && (
				<RecentBlock>
					<RecentStartsTitle>Recent Starts:</RecentStartsTitle>
					<Table striped>
						{last_starts.map((item, index) => (
							<React.Fragment key={index}>
								<ToggleTableHeader onClick={() => handleToggleRunnerForm(item.id)}>
									<ToggleTableDataStartAndFinish>
										{`${item.finish_position === 0 ? 'PU' : ordinalSuffixOf(item.finish_position)}/${
											item.race_starters
										}`}
									</ToggleTableDataStartAndFinish>
									<ToggleTableDataMgtDate>{item.mgt_date}</ToggleTableDataMgtDate>
									<ToggleTableDataABRVenue>{item.abr_venue}</ToggleTableDataABRVenue>
									<ToggleTableDataMeter>{`${item.race_distance}m`}</ToggleTableDataMeter>
									<ToggleTableDataTrCondition>{item.track_condition}</ToggleTableDataTrCondition>
									<ToggleTableArrowIcon>
										<span>{runnerToggleTable[item.id] ? <FaChevronUp /> : <FaChevronDown />}</span>
									</ToggleTableArrowIcon>
								</ToggleTableHeader>
								{runnerToggleTable[item.id] && buildToggleData(item)}
							</React.Fragment>
						))}
					</Table>
				</RecentBlock>
			)}
		</StyledRunnerForm>
	);
};

RunnerForm.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,

	// form info row
	age: PropTypes.number,
	sex: PropTypes.string,
	color: PropTypes.string,
	sire: PropTypes.string,
	dam: PropTypes.string,
	foal_date: PropTypes.string,
	owner: PropTypes.string,

	// from stats row
	career: PropTypes.string,
	track: PropTypes.string,
	track_distance: PropTypes.string,
	distance: PropTypes.string,
	synthetic: PropTypes.string,
	good: PropTypes.string,
	firm: PropTypes.string,
	soft: PropTypes.string,
	heavy: PropTypes.string,
	night: PropTypes.string,
	first_up: PropTypes.string,
	second_up: PropTypes.string,
	season: PropTypes.string,
	nonwet: PropTypes.string,
	wet: PropTypes.string,
	meeting: PropTypes.string,

	/** Runner selection comment */
	comment: PropTypes.string,

	last_starts: PropTypes.array,
	className: PropTypes.string,

	win_percentage: PropTypes.string,
	place_percentage: PropTypes.string,
	runner_time: PropTypes.string,
};

RunnerForm.defaultProps = {
	// form info row
	age: null,
	sex: null,
	color: null,
	sire: null,
	dam: null,
	foal_date: null,
	owner: null,

	// from stats row
	career: null,
	track: null,
	track_distance: null,
	distance: null,
	synthetic: null,
	good: null,
	firm: null,
	soft: null,
	heavy: null,
	night: null,
	first_up: null,
	second_up: null,
	season: null,
	nonwet: null,
	wet: null,

	comment: null,
	last_starts: [],
	className: null,
	win_percentage: null,
	place_percentage: null,
	runner_time: null,
	meeting: null,
};
const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = (dispatch) => ({
	handleBlackbook: async (payload) => {
		const { success, message } = await RNSAddToBackBook(payload);
		dispatch(
			openNotification(message, success ? 'success' : 'danger', {
				autoClose: 3500,
			}),
		);
	},
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(RunnerForm));
