import { USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING } from '../../pages/UserAccount/UserAccountPageConstants';
import menuItems from '../../pages/UserAccount/UserAccountPage.data';

/**
 * Get an item object from the User Account menu items
 */
export const getUserAccountItem = (itemId) => {
	let userAccountItem = menuItems.find((menuItem) => menuItem.id === itemId);
	if (!userAccountItem) {
		const responsibleGamblingItems = menuItems.find(
			(menuItem) => menuItem.id === USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
		);
		userAccountItem = responsibleGamblingItems.items.find((item) => item.id === itemId);
	}
	return userAccountItem;
};
