import { Checkbox, spacings, Text } from '@tbh/ui-kit';
import React from 'react';
import {
	QUADDIES_BET_TYPES,
	RACING_BET_TYPE_TITLES,
	RACING_EXOTIC_BET_TYPES,
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_NON_EXOTIC_BET_TYPE_TITLE,
	RACING_SAME_RACE_MULTI_TYPE,
} from '../../../../../common/constants/Racing';
import { css } from 'emotion';
import { withNamespaces } from 'react-i18next';
import styled from 'react-emotion';

const cssInActiveTabItems = css`
	label: InActiveTabItems;
	color: black !important;
	display: flex;
	align-items: flex-start;
`;

const cssActiveTabItems = css`
	label: ActiveTabItems;

	display: flex;
	align-items: flex-start;
	color: #c72128 !important;
	font-weight: 700;
	border: none;
	&::after {
		display: none;
	}
`;
let bet_types = '';

const StyledRaceTypeMenuContainer = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		padding: ${spacings().compact}px;
		width: 100%;
		border-bottom: 1px solid #666666;
		border-left: 1px solid #666666;
		border-right: 1px solid #666666;
		border-radius: 0px 0px 4px 4px;
	`,
);

const StyledTypeMenu = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		gap: ${spacings(props).compact}px;
	`,
);

const StyledType = styled('div')(
	(props) => css`
		cursor: pointer;
		white-space: nowrap;
		display: flex;
		border-radius: 4px;
		padding: ${spacings(props).compact}px;
		border: 1px solid #e0e0e0;
		background-color: #f1f1f1;

		${props.active &&
			css`
				border: 1px solid #c72128;
			`}
	`,
);

const StyledExoticTab = styled('div')(
	(props) => css`
	display: flex;
	flex-direction: row;
	gap: ${spacings(props).compact}px;
	width:100%;
	padding: ${spacings(props).compact}px;
	// padding-top: ${spacings(props).cozy}px;
`,
);

const StyledTypeList = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: row;
		gap: ${spacings(props).compact}px;
		padding: ${spacings(props).compact}px;
		width: 100%;
		white-space: nowrap;
		overflow-x: scroll;

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
			border-radius: 12px;
		}

		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 12px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #555;
			border-radius: 12px;
		}
	`,
);

const RaceTypeMenu = ({
	size,
	t,
	handleBetTypeChange,
	activeBetType,
	activeProduct,
	isBoxedSelected,
	raceProductBetTypes,
}) => {
	/**
	 * Returns the new bet type & boxed status
	 *
	 * @param betType
	 * @param boxed
	 */
	const handleSelectBetType = (betType, boxed = false) => {
		// no need to change bet type if selected bet type is already active
		if (activeBetType === betType && boxed === isBoxedSelected) {
			return;
		}
		// return the new bet type and checkbox state
		handleBetTypeChange(betType, boxed, activeProduct);
	};

	/**
	 * Handle the selecting of a win/place bet type
	 *
	 * @param e
	 * @param betType
	 */
	const changeBetType = (betType) => {
		// Always set the boxed flag to false when selecting the win/place bet type
		handleSelectBetType(betType, false);
		bet_types = betType;
	};

	// Only display the buttons for the exotic bet types if they are in the list of race products
	const exoticBetTypes = RACING_EXOTIC_BET_TYPES.filter((exoticBetType) => raceProductBetTypes.includes(exoticBetType));
	const quaddieBetTypes = QUADDIES_BET_TYPES.filter((quaddieBetType) => raceProductBetTypes.includes(quaddieBetType));
	const isExotic = exoticBetTypes.includes(activeBetType);
	const isQuaddie = quaddieBetTypes.includes(activeBetType);
	const hasSRM = raceProductBetTypes.includes(RACING_SAME_RACE_MULTI_TYPE) ? [RACING_SAME_RACE_MULTI_TYPE] : [];
	if (size.width < 720) {
		const types = [RACING_NON_EXOTIC_BET_TYPE, ...hasSRM, ...exoticBetTypes, ...quaddieBetTypes];

		return (
			<StyledRaceTypeMenuContainer>
				<StyledTypeList>
					{types.map((type) => (
						<StyledType
							active={activeBetType === type}
							key={type}
							onClick={() => changeBetType(type)}
							pointer
							data={type}
						>
							<Text size="-1" className={activeBetType !== type ? cssInActiveTabItems : cssActiveTabItems}>
								{RACING_BET_TYPE_TITLES[type]}
							</Text>

							{/* {
								exoticBetTypes.includes(type) && (
									<StyledExoticBetTypeFilters__Checkbox
										name={type}
										size={checkboxSize}
										inline
										checked={activeBetType === type && isBoxedSelected}
										action={toggleBoxedSelector}
										pointer
										data={type}
									/>
								)
							} */}
						</StyledType>
					))}
				</StyledTypeList>
			</StyledRaceTypeMenuContainer>
		);
	}

	return (
		<StyledRaceTypeMenuContainer>
			<StyledTypeMenu>
				<StyledType
					active={activeBetType === RACING_NON_EXOTIC_BET_TYPE}
					key={RACING_NON_EXOTIC_BET_TYPE}
					onClick={() => changeBetType(RACING_NON_EXOTIC_BET_TYPE)}
					pointer
					data={RACING_NON_EXOTIC_BET_TYPE}
				>
					<Text
						size="-1"
						className={activeBetType !== RACING_NON_EXOTIC_BET_TYPE ? cssInActiveTabItems : cssActiveTabItems}
					>
						{t(RACING_NON_EXOTIC_BET_TYPE_TITLE)}
					</Text>
				</StyledType>

				{/* <Tabs padding={Tabs.paddings.SPACING_TIGHT}>
					<TabsItem
						className={activeBetType !== RACING_BET_TYPE_EACHWAY ? cssInActiveTabItems : cssActiveTabItems}
						active={activeBetType === RACING_BET_TYPE_EACHWAY}
						key={RACING_BET_TYPE_EACHWAY}
						action={changeBetType}
						pointer
						data={RACING_BET_TYPE_EACHWAY}
					>
						{t(RACING_EACHWAY_LABEL)}
					</TabsItem>
				</Tabs> */}

				{raceProductBetTypes.includes(RACING_SAME_RACE_MULTI_TYPE) && (
					<StyledType
						active={activeBetType === RACING_SAME_RACE_MULTI_TYPE}
						key={RACING_SAME_RACE_MULTI_TYPE}
						onClick={() => changeBetType(RACING_SAME_RACE_MULTI_TYPE)}
						pointer
						data={RACING_SAME_RACE_MULTI_TYPE}
						//  disabled={true}
					>
						<Text
							size="-1"
							className={activeBetType !== RACING_SAME_RACE_MULTI_TYPE ? cssInActiveTabItems : cssActiveTabItems}
						>
							Same Race Multi
						</Text>
					</StyledType>
				)}
				{exoticBetTypes.length > 0 && (
					<StyledType active={isExotic} onClick={() => changeBetType(exoticBetTypes[0])} pointer>
						<Text size="-1" className={!isExotic ? cssInActiveTabItems : cssActiveTabItems}>
							Exotics
						</Text>
					</StyledType>
				)}
				{quaddieBetTypes.length > 0 && (
					<StyledType active={isQuaddie} onClick={() => changeBetType(quaddieBetTypes[0])} pointer>
						<Text size="-1" className={!isQuaddie ? cssInActiveTabItems : cssActiveTabItems}>
							Quaddies & More
						</Text>
					</StyledType>
				)}
			</StyledTypeMenu>
			{isExotic && (
				<StyledExoticTab size={size}>
					{exoticBetTypes.map((exoticBetType) => (
						<StyledType
							active={activeBetType === exoticBetType}
							key={exoticBetType}
							onClick={() => handleSelectBetType(exoticBetType)}
							pointer
							data={exoticBetType}
						>
							<Text size="-1" className={activeBetType !== exoticBetType ? cssInActiveTabItems : cssActiveTabItems}>
								{t(RACING_BET_TYPE_TITLES[exoticBetType])}
							</Text>
						</StyledType>
					))}
				</StyledExoticTab>
			)}

			{isQuaddie && (
				<StyledExoticTab size={size}>
					{quaddieBetTypes.map((quaddieBetType) => (
						<StyledType
							active={activeBetType === quaddieBetType}
							key={quaddieBetType}
							onClick={() => handleSelectBetType(quaddieBetType)}
							pointer
							data={quaddieBetType}
						>
							<Text size="-1" className={activeBetType !== quaddieBetType ? cssInActiveTabItems : cssActiveTabItems}>
								{t(RACING_BET_TYPE_TITLES[quaddieBetType])}

								{/* {quaddieBetType === RACING_BET_TYPE_QUADDIE && quaddieRaceNumbers} */}

								{/* {quaddieBetType === RACING_BET_TYPE_DAILY_DOUBLE && dailyDoubleRaceNumbers} */}
							</Text>
						</StyledType>
					))}
				</StyledExoticTab>
			)}
		</StyledRaceTypeMenuContainer>
	);
};

export default withNamespaces()(RaceTypeMenu);
