import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { RACING_TYPES_LOOKUP } from '../../../../common/constants/Racing';
import { countdown } from '../../../../legacy/core/format';

import CarouselItem from '../../Carousel/CarouselItem/CarouselItem';

/**
 * This feature wraps the CarouselItem in order to compute the props every time
 * the Ticker triggers a new rendering.
 */

const NtjRacesCarouselItem = ({ t, id, raceNumber, meetingName, startTime, type, action, className }) => {
	return (
		<CarouselItem
			border
			id={id}
			label={`${t('Race')} ${raceNumber}`}
			text={meetingName}
			meta={countdown(startTime)}
			icon={RACING_TYPES_LOOKUP[type]}
			action={action}
			className={className}
		/>
	);
};

NtjRacesCarouselItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The race ID */
	id: PropTypes.number.isRequired,

	/** The race number */
	raceNumber: PropTypes.number.isRequired,

	/** The name of the meeting that the race is part of */
	meetingName: PropTypes.string.isRequired,

	/** The start time of the race */
	startTime: PropTypes.string.isRequired,

	/** The race type */
	type: PropTypes.string.isRequired,

	/** Action to perform when the race item is clicked on */
	action: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

NtjRacesCarouselItem.defaultProps = {
	className: '',
};
export default withNamespaces()(NtjRacesCarouselItem);
