import { batchActions } from 'redux-batched-actions';
import { BETBUILDER_SUB_APP, BETBUILDER_BASE_URL, BETBUILDER_ID_URL } from '../pages/BetBuilder/BetBuilderConstants';
import {
	BETBUILDER_HOME_DONE__BATCH,
	BETBUILDER_HOME_LOADING__BATCH,
} from '../store/betBuilder/betBuilderHomeActionType';

import { show404, scrollToTop, setBodyLoading, closeSideBetSlip } from '../store/application/applicationActions';

import {
	setShowingNTJRaces,
	setShowingMeetings,
	resetRacingFilters,
	setSelectedBuilderMeeting,
	setSelectedDate,
} from '../store/betBuilder/betBuilderHomeAction';

import {
	fetchMeetingsAndRaces,
	fetchBetBulderMeetingWithRaceSelections,
} from '../store/entities/actions/MeetingActions';
import moment from 'moment';
import { serverDateTime } from '../legacy/core/format';
import { fetchNewNextToJumpRaces_GRS } from './../store/newNextToJumpRaces/newNextToJumpRacesActions';
const { dispatch, getState } = App.store;

const BetBuilderRouter = Backbone.Router.extend({
	routes: {
		[BETBUILDER_BASE_URL]: 'showBetBuilder',
		[`${BETBUILDER_ID_URL}/:date`]: 'showBetBuilderWithDate',
		[`${BETBUILDER_ID_URL}/:date/:meetingId`]: 'showBetBuilderWithMeeting',
	},

	showBetBuilderDefault: function() {
		App.startSubApp(BETBUILDER_SUB_APP);
		scrollToTop();
		App.store.dispatch(setBodyLoading(false));
	},

	showBetBuilder: async () => {
		let userDate = serverDateTime(new Date());
		/**
		 * Reset filters and render loading mask for the whole container
		 */
		dispatch(
			batchActions(
				[
					setBodyLoading(true),
					closeSideBetSlip(true),
					setSelectedDate(userDate),
					resetRacingFilters(null),
					setSelectedBuilderMeeting(null),
				],
				BETBUILDER_HOME_LOADING__BATCH,
			),
		);
		try {
			let serverDate = serverDateTime(userDate);

			let [response, ntjResponse] = await Promise.all([
				dispatch(fetchMeetingsAndRaces(serverDate.format('YYYY-MM-DD'))),
				dispatch(fetchNewNextToJumpRaces_GRS()),
			]);
			scrollToTop();
			dispatch(
				batchActions(
					[
						setShowingMeetings(response.meetingIds),
						setShowingNTJRaces(ntjResponse.ntjRaceIds),
						// setSelectedBuilderMeeting(response.meetingIds[0]),
						setBodyLoading(false),
					],
					BETBUILDER_HOME_DONE__BATCH,
				),
			);

			App.startSubApp(BETBUILDER_SUB_APP);
		} catch (e) {
			//console.log(e);
			dispatch(show404());
		}
	},
	showBetBuilderWithDate: async (date) => {
		let userDate = moment(date);
		/**
		 * Reset filters and render loading mask for the whole container
		 */
		dispatch(
			batchActions(
				[
					setBodyLoading(true),
					closeSideBetSlip(true),
					setSelectedDate(userDate),
					resetRacingFilters(null),
					setSelectedBuilderMeeting(null),
				],
				BETBUILDER_HOME_LOADING__BATCH,
			),
		);
		try {
			let serverDate = serverDateTime(userDate);

			let [response, ntjResponse] = await Promise.all([
				dispatch(fetchMeetingsAndRaces(serverDate.format('YYYY-MM-DD'))),
				dispatch(fetchNewNextToJumpRaces_GRS()),
			]);
			scrollToTop();

			dispatch(
				batchActions(
					[
						setShowingMeetings(response.meetingIds),
						setShowingNTJRaces(ntjResponse.ntjRaceIds),
						// setSelectedBuilderMeeting(response.meetingIds[0]),
						// resetRacingFilters(response.meetingIds[0]),
						setBodyLoading(false),
					],
					BETBUILDER_HOME_DONE__BATCH,
				),
			);
			App.startSubApp(BETBUILDER_SUB_APP);
		} catch (e) {
			//console.log(e);
			dispatch(show404());
		}
	},
	/**
	 *
	 */
	showBetBuilderWithMeeting: async (date, meetingId) => {
		let userDate = moment(date);
		meetingId = Number.parseInt(meetingId);
		dispatch(
			batchActions(
				[setBodyLoading(true), closeSideBetSlip(true), setSelectedDate(userDate), resetRacingFilters(meetingId)],
				BETBUILDER_HOME_LOADING__BATCH,
			),
		);
		try {
			let serverDate = serverDateTime(userDate);
			let [response, btResponse, ntjResponse] = await Promise.all([
				dispatch(fetchMeetingsAndRaces(serverDate.format('YYYY-MM-DD'))),
				dispatch(fetchBetBulderMeetingWithRaceSelections(meetingId)),
				dispatch(fetchNewNextToJumpRaces_GRS()),
			]);
			scrollToTop();
			dispatch(
				batchActions(
					[
						setShowingMeetings(response.meetingIds),
						setSelectedBuilderMeeting(btResponse.data.data.id),
						setShowingNTJRaces(ntjResponse.ntjRaceIds),
						setSelectedDate(serverDate),
						setBodyLoading(false),
					],
					BETBUILDER_HOME_DONE__BATCH,
				),
			);
		} catch (e) {
			//console.log(e);
			dispatch(show404());
		}

		App.startSubApp(BETBUILDER_SUB_APP);
	},

	/**
	 * Receives wildcard and call Sports container to mount based on sport name.
	 *
	 * @param meetingId
	 */
	// showBetBuilderWithMeeting: function(meetingId) {
	// 	this.resetBetBuilderMeetingsStore(meetingId);
	// 	scrollToTop();

	// 	/**
	// 	 * It's better fetching all data prior to render
	// 	 */
	// 	return dispatch(
	//         loadBetBuilderMeetingData(meetingId),//
	//         //fetchNewNextToJumpRaces_GRS(),
	//         )
	// 		.then(() => {
	// 			dispatch(
	//                 //setShowingNTJRaces(ntjResponse.ntjRaceIds),
	//                 setBodyLoading(false)
	//                 );
	// 			return App.startSubApp(BETBUILDER_SUB_APP);
	// 		})
	// 		.catch((e) => {
	// 			console.log(e);
	// 			dispatch(show404());
	// 		});
	// },

	// resetBetBuilderMeetingsStore: function(meetingId) {
	// 	let state = getState();
	// 	let selectedMeeting = Object.values(state.entities.meetings).find((BetBuilderMeeting) => BetBuilderMeeting.id === meetingId) || {};

	// 	dispatch(setBodyLoading(true));
	// 	dispatch(
	// 		setBetBuilderHomeMeetingFilters(
	// 			selectedMeeting.id,
	// 		),
	// 	);
	// },
});

export default new BetBuilderRouter();
