// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';
import env from '../../../../common/EnvironmentVariables';

// Components
import {
	CreditCardInput,
	CreditCardSecurityInput,
	DateInput,
	FlexText,
	Icon,
	Input,
	InputGroup,
	Link,
	Text,
} from '@tbh/ui-kit';
import AuthoriseVerification from './AuthoriseVerification/AuthoriseVerification';

// Constants
import {
	MASK_CREDIT_CARD_CVV,
	MASK_DATE_MM_YY,
	SECURITY_CODE,
	CONSTRAINT_EXPIRY_DATE,
	translateConstraints,
} from '@tbh/ui-kit';

const StyledCreditCardForm__TitleBlock = styled(FlexText)`
	${(props) => css`
		label: CreditCardForm__TitleBlock;

		padding: 0 ${spacings(props).cozy}px;
	`};
`;

const StyledCreditCardForm__Form = styled('div')`
	${(props) => css`
		label: CreditCardForm__Form;

		padding: ${spacings(props).compact}px ${spacings(props).cozy}px ${spacings(props).cozy}px ${spacings(props).cozy}px;
		background-color: ${ui(props).color_3};
	`};
`;

const CreditCardForm = (props) => {
	const {
		t,
		depositAmount,
		className,
		creditCardName,
		creditCardNumber,
		creditCardExpiry,
		creditCardExpiryError,
		creditCardSecurityCode,
		onChange,
		shouldVerifyCard,
		isSaveCard,
		handleShouldShowVerification,
		handleShouldShowSaveCard,
		onOpenVerifyInfo,
		onShowExistingCard,
		promoCode,
		brandName,
	} = props;

	translateConstraints(t, [MASK_CREDIT_CARD_CVV, MASK_DATE_MM_YY, SECURITY_CODE, CONSTRAINT_EXPIRY_DATE]);
	

	let renderInput = () => {
		if (typeof creditCardSecurityCode === 'string') {
			return (
				<CreditCardSecurityInput
					className={css`
						flex: 1 1 50%;
					`}
					name="creditCardSecurityCode"
					onChange={onChange}
					label={t('SecurityCode')}
					placeholder="###"
					type="tel"
					value={creditCardSecurityCode}
					mask={MASK_CREDIT_CARD_CVV}
					constraints={SECURITY_CODE}
					nodeRight={<Icon icon="cvv" size="4" />}
					horizontalMargin="compact"
					margin="cozy"
				/>
			);
		} else if (!(typeof creditCardSecurityCode === 'string') && typeof promoCode === 'string') {
			return <Input name="promo" label={t('PromoCode')} value={promoCode} margin="cozy" disabled />;
		}
	};

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<StyledCreditCardForm__TitleBlock>
				<Text
					paragraph
					strong
					className={css`
						font-size: 15px;
					`}
				>
					{t('EnterCreditCardDetails')}
				</Text>

				{onShowExistingCard && !env('webdosh') && (
					<Link action={onShowExistingCard} size="-1" underline>
						{t('Existing')}
					</Link>
				)}
			</StyledCreditCardForm__TitleBlock>

			<StyledCreditCardForm__Form>
				<Input
					name="creditCardName"
					onChange={onChange}
					label={t('NameOnCreditCard')}
					value={creditCardName}
					constraints={{
						name: {
							// is required
							presence: true,
							length: {
								minimum: 2,
							},
						},
					}}
					margin="cozy"
				/>

				<CreditCardInput
					name="creditCardNumber"
					onChange={onChange}
					label={t('CreditCardNumber')}
					value={creditCardNumber}
					margin="cozy"
				/>

				<InputGroup>
					<DateInput
						className={css`
							flex: 0 1 calc(50% - 4px);
						`}
						name="creditCardExpiry"
						type="tel"
						onChange={onChange}
						value={creditCardExpiry}
						label={t('Expiry')}
						mask={MASK_DATE_MM_YY}
						constraints={CONSTRAINT_EXPIRY_DATE}
						placeholder={t('MM_YY')}
						horizontalMargin="cozy"
						error={creditCardExpiryError}
						margin="cozy"
					/>

					{renderInput()}
				</InputGroup>

				{typeof creditCardSecurityCode === 'string' && typeof promoCode === 'string' && (
					<InputGroup>
						<Input
							className={css`
								flex: 0 1 calc(50% - 4px);
							`}
							name="promo"
							type="tel"
							label={t('PromoCode')}
							value={promoCode}
							margin="cozy"
							horizontalMargin="cozy"
							disabled
						/>

						{typeof depositAmount === 'string' && (
							<Input
								name="depositAmount"
								type="tel"
								onChange={onChange}
								label={t('DepositAmount')}
								value={depositAmount.toString()}
								margin="cozy"
							/>
						)}
					</InputGroup>
				)}

				{!env('webdosh') && (
					<AuthoriseVerification
						handleShouldShowVerification={handleShouldShowVerification}
						handleShouldShowSaveCard={handleShouldShowSaveCard}
						shouldVerifyCard={shouldVerifyCard}
						isSaveCard={isSaveCard}
						onOpenVerifyInfo={onOpenVerifyInfo}
						brandName={brandName}
					/>
				)}
			</StyledCreditCardForm__Form>
		</div>
	);
};

CreditCardForm.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to fire when an input is changed */
	onChange: PropTypes.func.isRequired,

	/** Name on Credit Card */
	creditCardName: PropTypes.string.isRequired,

	/** Credit Card number */
	creditCardNumber: PropTypes.string.isRequired,

	/** Expiry date MM/YY */
	creditCardExpiry: PropTypes.string.isRequired,

	/** Trigger verification instructions */
	onOpenVerifyInfo: PropTypes.func.isRequired,

	/** If the CVV is valid or not */
	creditCardSecurityCodeValid: PropTypes.bool,

	/** Error message if there is a problem with the expiry date */
	creditCardExpiryError: PropTypes.string,

	/** Error message if there is a problem with the security code */
	creditCardSecurityCode: PropTypes.string,

	/** Falsy value hides Existing button */
	onShowExistingCard: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),

	/** Promo code to use */
	promoCode: PropTypes.string,

	/** The amount to charge the card */
	depositAmount: PropTypes.string,

	/** Brand name */
	brandName: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

CreditCardForm.defaultProps = {
	className: '',
	creditCardSecurityCode: undefined,
	creditCardSecurityCodeValid: false,
	creditCardExpiryError: null,
	depositAmount: null,
	onShowExistingCard: undefined,
	promoCode: null,
	brandName: null,
};

export default withNamespaces()(CreditCardForm);
