import { mergeEntities } from '../../../common/actions/actionHelpers';
import { normalizePrices } from '../schemas/PriceSchema';

/**
 * Normalizes and update Prices
 * @param prices
 */
export const updatePrices = (prices) => {
	return mergeEntities(normalizePrices(prices).entities);
};

/**
 * Flatten price updates for multiple selections
 *
 * @param selections
 * @returns {*}
 */
export const buildPriceUpdates = (selections) => {
	if (!selections) return [];

	let s = null;
	if (!Array.isArray(selections)) {
		s = Object.values(selections);
	} else {
		s = selections;
	}

	return s.reduce((acc, selection) => {
		const { prices } = selection;
		if (Array.isArray(prices)) {
			return acc.concat(prices);
		} else if (typeof prices === 'object') {
			acc.push(prices);
		}

		return acc;
	}, []);
};

export const buildPriceUpdateliability = (selections) =>
	selections.reduce((acc, selection) => {
		const { prices } = selection;
		if (
			prices.product_id === 19 || prices.product_id === 20 || prices.product_id === 21
				? Array.isArray(parseInt(prices.liability))
				: Array.isArray(prices)
		) {
			return acc.concat(prices);
		} else if (typeof prices === 'object') {
			acc.push(prices);
		}

		return acc;
	}, []);
