import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SizeMe} from 'react-sizeme';
import {withNamespaces} from 'react-i18next';
import cx from 'classnames/bind';
import moment from 'moment';

import { Icon,Text } from '@tbh/ui-kit';


import {buildSportsRouteFromUpcoming} from '../../../routes/Sports';
import {createErrorBoundary} from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import Route from '../../../components/controllers/Route/Route';
import {getAuthenticatedUser} from '../../../store/application/applicationSelectors';
import {getPageMeta} from '../../GRSSports/GRSSportsHome/GRSSportsHomeSelectors';
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';
import * as CSHP from '../SportsHome/SportsHomePage.styled';

/**
 * Components
 */

import SportsTeams from '../../../components/features/Sports/UpCommingSports/SportsTeams';
import SportBets from '../../../components/features/Sports/UpCommingSports/SportBets';
//Sports

import UpcomingSportsList from '../../../components/features/Sports/SportsList/UpcomingSportsList';

import {SportsStyles__BetRow} from '../SportsHome/SportsHomePage.styled';

class SportUpComingPage extends Component {
  static propTypes = {

    /** The page meta to be applied */
    pageMeta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,

    addToMulti: PropTypes.func.isRequired,

    /** Function for the click event */
    onClick: PropTypes.func.isRequired,

  }

  constructor(props) {
    super(props);

    this.state = {
      selectedSports: [],
    };
  }

  render() {
    const getShowingSports = () => {
      let visibleSportsId = [];
      let ShowVisibleSport = [];
      Object.keys(visibleSportWithId).forEach((key) => {
        visibleSportsId.push(visibleSportWithId[key].sportId);
      });
      return ShowVisibleSport = visibleSports.filter((sport) => visibleSportsId.includes(sport.id));
    };

    const componentClasses = cx({

    });

    /**
     * Handles clicking on a price.
     *
     * @param selectionId
     */
    const onSelectionClick = (selectionId) => {
      onClick(125632, 20, 25663);
    };

    /**
     * Handles clicking on a price.
     *
     * @param startDate
     */

    const getDateDiff = (startDate) => {
      return moment();
    };

    const OnItemClick = (id) => {
      this.setState({
        selectedSports: visibleSportWithId.filter((sports) => sports.sportId === parseInt(id)),
      });
    };


    const {
      pageMeta,
      addToMulti,
      onClick,
      visibleSports,
      visibleSportWithId,
      t,
    } = this.props;
    const { selectedSports } = this.state;
    return(
      <SizeMe>
        {({ size }) => (

          <CSHP.StyledSportsHomePage size={size}>
            <React.Fragment>
              <Route route={buildSportsRouteFromUpcoming()}/>
              <DocumentTitle>{pageMeta.title}</DocumentTitle>
              <DescriptionMeta>{pageMeta.description}</DescriptionMeta>
            </React.Fragment>
            <SizeMe>
              {({ size: bodySize }) => (
                <CSHP.StyledSportsUpcoming__Body size={size}>
                  <CSHP.StyledSports__major_header>
                    <CSHP.StyledSports__major_header_h2>
                      {t('Sports__Upcoming')}
                    </CSHP.StyledSports__major_header_h2>
                  </CSHP.StyledSports__major_header>
                    <UpcomingSportsList
                      sports={getShowingSports()}
                      OnItemClick={OnItemClick}
                    />
                  {selectedSports.length !== 0 ?
                    selectedSports.map((sports) => (
                      <CSHP.StyledSportsUpcomingPage__MarketGroups key={`${sports.id}`}>
                          <CSHP.StyledSportsUpcomingPage__EventName>
                            <CSHP.Styles__HeaderContainer>
                              <CSHP.SportsStyles__TitleContainer>
                                <CSHP.SportsStyles__IconContainer>
                                  <Icon push="2" icon={'cricket'} />
                                </CSHP.SportsStyles__IconContainer>
                                <Text size="-2" strong>{sports.event[0].name}</Text>
                              </CSHP.SportsStyles__TitleContainer>
                            </CSHP.Styles__HeaderContainer>
                          </CSHP.StyledSportsUpcomingPage__EventName>

                          <CSHP.SportsStyles__BetContainer>
                            <CSHP.SportsStyles__SelectionsContainer>
                              <CSHP.SportsStyles__MarketNameRow>
                                <CSHP.SportsStyles__EmptySelectionName/>
                                <CSHP.SportsStyles__MarketNames>
                                  <Text size="-3">
                                    {sports.markets.name}
                                  </Text>
                                </CSHP.SportsStyles__MarketNames>
                              </CSHP.SportsStyles__MarketNameRow>

                              <SportsStyles__BetRow>
                                <SportsTeams
                                  Teams={sports.Teams}
                                />
                                <SportBets
                                  selection={sports.sport_selections}
                                />
                              </SportsStyles__BetRow>

                            </CSHP.SportsStyles__SelectionsContainer>
                          </CSHP.SportsStyles__BetContainer>




                      </CSHP.StyledSportsUpcomingPage__MarketGroups>
                    ))
                    : ''
                  }
                </CSHP.StyledSportsUpcoming__Body>
              )}
            </SizeMe>
          </CSHP.StyledSportsHomePage>
        )}
      </SizeMe>
    );
  }

}

const mapStateToProps = (state) => {
  const user = getAuthenticatedUser(state);
  return {
    user,
    loadingContainer: state.SportCricketHome.loadingContainer,
    pageMeta:getPageMeta(state),
    visibleSports:state.application.visibleSportWithName,
    visibleSportWithId:state.application.visibleSportWithSportId,
  };
};


export default withNamespaces()(
  createErrorBoundary(
    connect(
      mapStateToProps
    )(SportUpComingPage),
  ),
);
