import { brand, Table, Tabs, TabsItem, Text, ui } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';
import { QUADDIES_BET_TYPES, RACING_EXOTIC_BET_TYPES } from '../../../../common/constants/Racing';
import { BREAKPOINTS, MEDIA_QUERIES } from '../../../../constants/themes';
import { TOTE_NAMES_BY_PRODUCT, toteProducts } from '../../../../store/entities/constants/BetConstants';
import { RaceResultSelectionDetails } from './RaceResultSelectionDetails';

const StyledRaceResultTable = styled(Table)(
	(props) => css`
		label: RaceResultTable;

		width: 100%;
		margin-bottom: 0;
		border: 1px solid ${ui(props).color_5};
		border-radius: 0px 0px 4px 4px;
		color: black;
		min-width: 300px;
	`,
);

const ExoticsTableDataView = styled(Table.TD)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: auto;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		flex: 1;
	}
`;

const ExoticsTableDataTitle = styled(Text)`
	width: 100px;
	font-size: 12px;

	@media ${MEDIA_QUERIES.laptopAndUp} {
		width: 100%;
		font-size: 13px;
	}
`;

const StyledRaceResults = styled('div')`
	label: RaceResults;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledRaceResultsTabsItem = styled(TabsItem)(
	(props) => css`
		label: RaceResultsTabsItem;

		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 34px;

		padding: 12px;
		background-color: ${props.isTab ? `${brand(props).rch_4} !important` : 'none'};
		${props.isTab && 'color:white;'}

		&:is(:first-child) {
			border-left: 1px solid ${ui(props).color_5}!important;
		}

		&:is(:last-child) {
			border-right: 1px solid ${ui(props).color_5}!important;
		}
	`,
);

const StyledRaceResultWinPlace = styled(Table.TD)`
	label: RaceResultWinPlace;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 10px;
	padding: 0px;
	min-height: 54px;

	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		justify-content: center;
		padding: 4px 0px;
		background: #dddddd;
		&:last-child {
			border-radius: 0px 0px 4px 4px;
		}
	}
`;

const SubstituteTableWrapper = styled('div')`
	margin-top: 10px;
	border: 1px solid ${ui.color_5};
	border-radius: 4px;
`;

const resultValue = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	text-align: center;
	width: 70px;
	gap: 4px;
`;

const ResultValue = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	text-align: center;
	width: 70px;
	gap: 4px;
`;

const ResultRow = styled(Table.TR)`
	&:not(:last-child) {
		border-bottom: 1px solid #666666;
	}
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		flex-direction: column;
	}
`;

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const RaceResults = (props) => {
	const { t, className, results = [], result_string, exoticResults = [], totes_results = [], race } = props;
	if (results.length === 0) return null;

	const selections = race.selections;
	const substitutes = race.substitutes;
	function BuildRaceResultsByTab(results, exoticResults) {
		const tabs = {
			Results: [],
			// Dividends: [],
			// Exotics: [],
		};

		const productsRepresentation = {
			4: 'VIC',
			5: 'QLD',
			8: 'NSW',
			7: 'SP',
			14: 'TF',
			15: 'OSP',
			16: 'GRSF',
		};
		// http://localhost:9000/racing-v3/r/2022-11-30/geelong/race-1-1064614-1006917

		function addResultToTab(tabName, result) {
			if (!tabs[tabName]) {
				return;
			}
			if (tabName !== 'Exotics') {
				let resultRow = tabs[tabName].find((tabResult) => tabResult.selection.selection_id === result.selection_id);

				if (!resultRow) {
					const selectedSelection = selections.find((selection) => selection.id == result.selection_id);

					resultRow = {
						selection: {
							selection_id: result.selection_id,
							name: result.name,
							number: result.number,
							position: result.position,
							jockey: selectedSelection.jockey,
							trainer: selectedSelection.trainer,
							weight: selectedSelection.weight,
							f: selectedSelection.last_starts_string,
							silk: selectedSelection.silk,
						},
					};

					if (tabName === 'Dividends') {
						resultRow = {
							...resultRow,
							VIC: [],
							QLD: [],
							NSW: [],
							SP: [],
						};
					}
					tabs[tabName].push(resultRow);
				}

				if (tabName === 'Results') {
					let name = '';

					if (result.product_code) {
						name = result.product_code;
					} else {
						name = productsRepresentation[result.product_id];
					}

					if (toteProducts.includes(name)) {
						name = TOTE_NAMES_BY_PRODUCT[name][race.type];
					}

					if (!resultRow[name]) resultRow[name] = {};
					resultRow[name][result.bet_type] = {
						dividend: result.dividend,
						position: result.position,
					};
				} else {
					if (!resultRow[productsRepresentation[result.product_id]]) {
						resultRow[productsRepresentation[result.product_id]] = {};
					}

					resultRow[productsRepresentation[result.product_id]][result.bet_type] = {
						dividend: result.dividend,
						position: result.position,
					};
				}

				const index = tabs[tabName].findIndex((tabResult) => tabResult.selection.selection_id === result.selection_id);

				// order by selection position;
				// resultRow.sort((a, b) => a.selection.position - b.selection.position)

				let sortedResultRow = {};

				let keys = Object.keys(resultRow);

				keys.sort((a, b) => {
					if (a === 'selection') {
						return -1;
					}

					if (b === 'selection') return 1;

					if (a === 'GRSF') return -1;

					if (b === 'GRSF') return 1;

					if (resultRow[a].win && resultRow[a].place) {
						return -1;
					}

					if (resultRow[b].win && resultRow[b].place) {
						return 1;
					}

					if (resultRow[a].win) {
						return -1;
					}

					if (resultRow[b].win) {
						return 1;
					}

					return 1;
				});

				keys.forEach((key) => {
					sortedResultRow[key] = resultRow[key];
				});

				tabs[tabName][index] = sortedResultRow;
				tabs[tabName].sort((a, b) => a.selection.position - b.selection.position);
			} else {
				let resultRow = tabs[tabName].find(
					(tabResult) => tabResult.bet_type === result.bet_type && tabResult.selections === result.selections,
				);

				if (!resultRow) {
					resultRow = {
						bet_type: result.bet_type,
						selections: result.selections,
					};
					tabs[tabName].push(resultRow);
				}

				resultRow[productsRepresentation[result.product_id]] = {};

				resultRow[productsRepresentation[result.product_id]] = {
					dividend: result.dividend,
					position: result.position,
				};

				const index = tabs[tabName].findIndex(
					(tabResult) => tabResult.bet_type === result.bet_type && tabResult.selections === result.selections,
				);

				// order by selection position;
				// resultRow.sort((a, b) => a.selection.position - b.selection.position)

				tabs[tabName][index] = resultRow;
			}
		}

		// Build Results
		const dividendReults = [
			...results.filter(
				(result) => result.product_id === 16 || result.product_id === 15 || result.product_id === 14,
				// result.product_code === RACING_BET_PRODUCT_CODE_FIXED ||
				// result.product_code === RACING_BET_PRODUCT_CODE_SP ||
				// result.product_code === RACING_BET_PRODUCT_CODE_TF,
			),
			...totes_results,
		];
		dividendReults.sort((a, b) => a.position - b.position);
		const types = {};

		for (let i = 0; i < dividendReults.length; i++) {
			const result = dividendReults[i];
			let product_code = result.product_code;

			if (!product_code) {
				product_code = productsRepresentation[result.product_id];
			}

			if (toteProducts.includes(product_code)) {
				product_code = TOTE_NAMES_BY_PRODUCT[product_code][race.type];
			}

			if (!types[product_code]) {
				types[product_code] = [];
			}

			if (!types[product_code].find((type) => type === result.bet_type)) {
				types[product_code].push(result.bet_type);
			}
		}

		for (let result of dividendReults) {
			let row = tabs['Results'].find((tabResult) => tabResult.selection.selection_id === result.selection_id);

			if (!row) {
				row = {
					selection: {
						selection_id: result.selection_id,
						name: result.name,
						number: result.number,
						position: result.position,
					},
				};

				tabs['Results'].push(row);
			}

			const typeKeys = Object.keys(types);

			for (let type of typeKeys) {
				if (!row[type]) {
					row[type] = {};
				}

				for (let bet_type of types[type]) {
					if (!row[type][bet_type]) {
						row[type][bet_type] = null;
					}
				}
			}

			let product_code = result.product_code;

			if (!product_code) {
				product_code = productsRepresentation[result.product_id];
			}

			if (toteProducts.includes(product_code)) {
				product_code = TOTE_NAMES_BY_PRODUCT[product_code][race.type];
			}

			row[product_code][result.bet_type] = {
				product_code,
				dividend: result.dividend,
				position: result.position,
			};

			let sortedRow = {};

			let keys = Object.keys(row);

			keys.sort((a, b) => {
				if (a === 'selection') return -1;

				if (b === 'selection') return 1;

				if (a === 'SP') return -1;

				if (b === 'SP') return 1;

				if (a === 'TF') return -1;

				if (b === 'TF') return 1;

				if (a === 'GRSF') return -1;

				if (b === 'GRSF') return 1;

				if (row[a].win && row[a].place) {
					return -1;
				}

				if (row[b].win && row[b].place) {
					return 1;
				}

				if (row[a].win || row[a].win === null) {
					return -1;
				}

				if (row[b].win || row[b].win === null) {
					return 1;
				}

				return 1;
			});

			keys.forEach((key) => {
				sortedRow[key] = row[key];
			});

			const index = tabs['Results'].findIndex((tabResult) => tabResult.selection.selection_id === result.selection_id);

			tabs['Results'][index] = sortedRow;
		}

		if (exoticResults.length === 0) return tabs;
		tabs.Exotics = [];
		const exoticsDividend = exoticResults.filter((result) => result.product_id === 4);

		exoticsDividend.forEach((result) => {
			addResultToTab('Exotics', result);
		});

		return tabs;
	}

	const tabs = BuildRaceResultsByTab(results, exoticResults);
	// Divide results into RESULTS / DIVIDENDS / EXOTICS
	const tabsNames = Object.keys(tabs);

	const [activeTab, setActiveTab] = React.useState(tabsNames[0]);

	React.useEffect(() => {
		setActiveTab(tabsNames[0]);
	}, [result_string]);

	const renderTable = (activeTab) => {
		let data = {};

		if (activeTab === 'Exotics') {
			data = createTableExotics();
		} else if (activeTab === 'Dividends') {
			data = createTableDevidends();
		} else {
			data = createTableResult();
		}

		// let { tableHead, tableRows } = data;
		let { tableRows } = data;
		return (
			<StyledRaceResultTable density="cozy" size="-2" border>
				<Table.TG thead compact size="-3">
					{/* <Table.TR>{tableHead}</Table.TR> */}
				</Table.TG>

				{tableRows}
			</StyledRaceResultTable>
		);
	};

	if (!selections) return null;

	const runnerQty = selections.length;
	const high = runnerQty >= 8;
	const mid = runnerQty <= 7 && runnerQty >= 5;
	const low = runnerQty < 5;

	const createTableResult = () => {
		const content = tabs[activeTab];
		let tableHead = [
			<Table.TD strong key={'Results'}>
				{t('Results')}
			</Table.TD>,
		];

		let tableRows = [];
		//console.log('content', content[0]);
		let order = content.length > 0 ? Object.keys(content[0]) : [];
		content.forEach((result, i) => {
			const items = [];
			let r = Object.keys(result);

			if (order.length === 0) {
				order = [...r];
			}

			for (let key of order) {
				if (!r.includes(key)) {
					r = [...order];
					break;
				}
			}

			for (let key of r) {
				if (!result[key]) {
					result[key] = {};
				}
			}

			r.forEach((key) => {
				if (key === 'selection') {
					return;
				}

				let keyName = key === 'GRSF' ? 'FIXED' : key;
				if (result[key].win) {
					let label = 'Win';
					if (result[key].win && result[key].win.product_code == 'SP') {
						label = '';
						keyName = 'SP';
					} else if (result[key].win && result[key].win.product_code == 'TF') {
						label = '';
						keyName = 'TF';
					}
					items.push(
						<ResultValue
							id={key + '_' + i}
							style={{
								justifyContent: keyName === 'SP' || keyName === 'TF' ? 'space-around' : 'center',
							}}
						>
							{<span>{i === 0 && keyName}</span>}
							<span>{label}</span>
							<span>{result[key].win && result[key].win.dividend.toFixed(2)}</span>
						</ResultValue>,
					);
				} else if (result[key].win === null) {
					items.push(
						<ResultValue id={key + '_' + i}>
							<span></span>
							<span></span>
						</ResultValue>,
					);
				}
				if (
					result[key].place &&
					((high && result[key].place.position < 4) || (mid && result[key].place.position < 4))
				) {
					items.push(
						<ResultValue id={key + '_' + i}>
							{<span>{i === 0 && keyName}</span>}
							{/* <span>{result[key].place && result[key].place.dividend ? t('Place'):''}</span> */}
							<span>{t('Place')}</span>
							<span>
								{result[key].place && result[key].place.dividend == 0 ? 'NTD' : result[key].place.dividend.toFixed(2)}
							</span>
						</ResultValue>,
					);
				} else if (result[key].place === null) {
					items.push(
						<ResultValue id={key + '_' + i}>
							<span></span>
							<span></span>
						</ResultValue>,
					);
				}
			});

			tableRows.push(
				<ResultRow key={result.selection.selection_id}>
					<Table.TD
						className={css`
							padding: 0px 8px;
							display: flex;
							align-items: end;
						`}
					>
						<RaceResultSelectionDetails selection={result.selection} />
					</Table.TD>
					<StyledRaceResultWinPlace right>{items}</StyledRaceResultWinPlace>
				</ResultRow>,
			);
		});

		return { tableHead, tableRows };
	};

	const createTableDevidends = () => {
		const content = tabs[activeTab];

		const tableHead = [];

		content.forEach((result) => {
			Object.keys(result).forEach((key) => {
				if (!tableHead.find((item) => item.key === key)) {
					if (key !== 'selection') {
						tableHead.push(
							<Table.TD strong key={key} center>
								{t(key)}
							</Table.TD>,
						);
					} else {
						tableHead.push(
							<Table.TD strong key={key}>
								<div
								// className={css`width: 200px;`}
								>
									{t('Win or Place')}
								</div>
							</Table.TD>,
						);
					}
				}
			});
		});

		const tableRows = [];

		content.forEach((result, i) => {
			const tableRowItem = [];

			let show = (high && i < 3) || (mid && i < 3) || (low && i < 1);
			tableHead.forEach(({ key }) => {
				if (key !== 'selection') {
					let win = '';
					let place = '';

					const item = result[key];

					if (item && show) {
						win = item.win ? item.win.dividend.toFixed(2) : '';

						place = item.place ? (item.place.dividend === 0 ? 'NTD' : item.place.dividend.toFixed(2)) : '';
					}

					tableRowItem.push(
						<Table.TD
							key={key}
							right
							className={css`
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
							`}
						>
							<Text size="-2">{win}</Text>
							<Text size="-3" color="grey">
								{place}
							</Text>
						</Table.TD>,
					);
				}
			});

			tableRows.push(
				<Table.TR
					key={result.selection.selection_id}
					className={css`
						padding: 0px;
						display: flex;
						align-items: end;
					`}
				>
					<Table.TD
						key={'Results'}
						className={css`
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
						`}
					>
						<RaceResultSelectionDetails selection={result.selection} />
					</Table.TD>
					{tableRowItem}
				</Table.TR>,
			);
		});

		return { tableHead, tableRows };
	};
	// Follow this pattern // Exotics // Quaddies

	const createTableExotics = () => {
		const content = tabs[activeTab];

		const tableHead = [
			<Table.TD strong key={'Exotic Results'}>
				{t('Exotic Results')}
			</Table.TD>,
			<Table.TD strong key={'GRSF'} right>
				{t('DIV')}
			</Table.TD>,
		];

		const titles = {
			exacta: 'Exacta',
			trifecta: 'Trifecta',
			firstfour: 'First 4',
			quinella: 'Quinella',
			// Add other titles if needed
		};

		const tableRows = [];

		// Combined order for sorting
		const combinedOrder = [...RACING_EXOTIC_BET_TYPES, ...QUADDIES_BET_TYPES];

		content.sort((a, b) => {
			const indexB = combinedOrder.indexOf(b.bet_type);
			const indexA = combinedOrder.indexOf(a.bet_type);

			if (indexA === -1 && indexB !== -1) return 1;
			if (indexB === -1 && indexA !== -1) return -1;
			if (indexA === -1 && indexB === -1) return 0;
			return indexA - indexB;
		});

		content.forEach((result) => {
			const selections = result.selections.split('/').map((selection, i) => {
				const position_sign = i + 1 === 1 ? 'st' : i + 1 === 2 ? 'nd' : i + 1 === 3 ? 'rd' : 'th';

				const prefix = QUADDIES_BET_TYPES.includes(result.bet_type) ? 'R' : '';

				return (
					<div key={selection}>
						<Text size="-3" right>
							{i + 1}
							{position_sign}
						</Text>
						<Text
							className={css`
								padding: 2px 3px;
								color: white;
								text-align: center;
								background: #666666;
								border-radius: 4px;
								min-width: 24px;
							`}
							size="-1"
							right
						>
							{prefix + selection}
						</Text>
					</div>
				);
			});

			tableRows.push(
				<Table.TR
					key={result.bet_type + result.selections}
					className={css`
						border-bottom: 1px solid #e6e6e6;
						align-items: center;
					`}
				>
					<ExoticsTableDataView>
						<ExoticsTableDataTitle size="-2">
							{titles[result.bet_type] || capitalizeFirstLetter(result.bet_type)}
						</ExoticsTableDataTitle>
						<div
							className={css`
								display: flex;
								flex-direction: row;
								width: 100%;
								gap: 8px;
							`}
						>
							{selections}
						</div>
					</ExoticsTableDataView>
					<Table.TD right>
						<Text
							size="-1"
							className={css`
								color: black;
								width: 100%;
								text-align: right;
							`}
							right
						>
							{result.VIC && result.VIC.dividend.toFixed(2)}
						</Text>
					</Table.TD>
				</Table.TR>,
			);
		});

		return { tableHead, tableRows };
	};

	const CreateSubstituteTable = () => {
		// Return null if substitutes is null/undefined
		if (!substitutes || Object.keys(substitutes).length === 0) return null;

		const tableRows = Object.entries(substitutes)
			.filter(([_, value]) => value && value.length > 0) // Only include rows with non-empty values
			.map(([key, value]) => (
				<Table.TR key={key}>
					<Table.TD>{key.charAt(0).toUpperCase() + key.slice(1)}</Table.TD>
					<Table.TD>
						<Text
							size="-3"
							className={css`
								color: black;
								width: 100%;
								text-align: right;
							`}
							right
						>
							{value}
						</Text>
					</Table.TD>
				</Table.TR>
			));

		// Don't render the table if no rows
		if (tableRows.length === 0) return null;

		return (
			<SubstituteTableWrapper>
				<Table density="cozy" size="-2" border>
					<Table.TG tbody>{tableRows}</Table.TG>
				</Table>
			</SubstituteTableWrapper>
		);
	};

	return (
		<StyledRaceResults>
			<Tabs padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder type="secondary">
				{tabsNames.map((tabName) => (
					<StyledRaceResultsTabsItem key={tabName} isTab={tabName === activeTab} action={setActiveTab} data={tabName}>
						{tabName === 'Results' && 'Win & Place'}
						{tabName === 'Exotics' && 'Exotics / Multiples'}
					</StyledRaceResultsTabsItem>
				))}
			</Tabs>

			{renderTable(activeTab)}

			{CreateSubstituteTable()}
		</StyledRaceResults>
	);
};

export default RaceResults;
