const {
	denormalize,
	normalize,
	schema,
} = require('normalizr');

// Schemas
const creditCardSchema = new schema.Entity('creditCards');
const creditCardsSchema = new schema.Array(creditCardSchema);

/**
 * Denormalize entities back to a structure similar to the API response.
 *
 * @param {object} entities
 * @param {Array} keys
 * @returns {Array}
 */
export const denormalizeCreditCards = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.creditCards), creditCardsSchema, entities);
};

/**
 * Normalize structure to suit our application.
 *
 * @returns {Object}
 * @param response
 */
export const normalizeCreditCards = (response) => {
	return normalize(response, creditCardsSchema);
};
