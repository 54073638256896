import {
	denormalize,
	normalize,
	schema,
} from 'normalizr';

// Schemas
const trendingBetSchema = new schema.Entity('trendingBets');
export const trendingBetsSchema = new schema.Array(trendingBetSchema);

/**
 * Denormalize trending bets
 *
 * @param entities
 */
export const denormalizeTrendingBets = (entities) => {
	return denormalize(Object.keys(entities.trendingBets), trendingBetsSchema, entities);
};

/**
 * Normalize an array of trending bets
 *
 * @param trendingBets
 */
export const normalizeTrendingBets = (trendingBets = []) => {
	return normalize(trendingBets, trendingBetsSchema);
};