/**
 * Created by Josef Frank on 2017/05/03.
 */
'use strict';

const {buildSitemapItem} = require('../common/SitemapHelpers');
const {fetchTournamentsCombined} = require('../store/entities/actions/TournamentActions');
const {denormalizeTournaments} = require('../store/entities/schemas/TournamentSchema');
const {buildRouteToTournament} = require('../routes/Tournaments');

/**
 * Builds a tournament sitemap, using an AJAX request.
 *
 * @param store
 */
function buildTournamentSitemapFromAJAX(store) {
	return store.dispatch(fetchTournamentsCombined())
		.then(() => buildTournamentSitemapFromEntities(store.getState().entities));
}

/**
 * Builds a tournament sitemaps, using an entities object.
 *
 * @param entities
 */
function buildTournamentSitemapFromEntities(entities) {
	let tournaments = denormalizeTournaments(entities);
	let routes = tournaments.map((tournament) => {
		let route = buildRouteToTournament(tournament.id, tournament.name);
		return buildSitemapItem(route);
	});
	return {
		name: 'Tournaments',
		map: `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">${routes.join('')}</urlset>`
	};
}

module.exports = {
	buildTournamentSitemapFromAJAX,
	buildTournamentSitemapFromEntities,
};