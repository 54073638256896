import { connect } from 'react-redux';
import React  from 'react';
import { HeadingSelector } from '@tbh/ui-kit';
import { selectCompetition } from '../../store/sportsHome/sportsHomeActions';
import { buildCompetitions } from '../../pages/Sports/SportsHome/sportsHomeSelectors';
const mapStateToProps = (state, ownProps) => {
	const selectedCompetition = state.entities.competitions[state.sportsHome.selectedCompetition] || {};
	const competitions = buildCompetitions(state) || [selectedCompetition];
  const element = <h1>Test</h1>;
	return {
		useButton: competitions.length > 1,
		heading: selectedCompetition.name + ' (AU) ' || '',
		items: competitions,
		className: ownProps.className,
	};
};

const mapDispatchToProps = (dispatch) => ({
	onClickItem: (competition) => dispatch(selectCompetition(competition)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(HeadingSelector);
