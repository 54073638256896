import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	PUSHER_GRS_EVENT_ODDS_UPDATE,
	PUSHER_GRS_EVENT_UPDATE,
	// PUSHER_GRS_EVENT_ODDS_UPDATE,
} from '../../../../common/constants/Racing';
import { updatePrices, buildPriceUpdates } from '../../../../store/entities/actions/PriceActions';

import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';

import { mergeNJRace } from '../../../../store/entities/actions/NextToJumpRaceActions';
import { mergeRace } from '../../../../store/entities/actions/RaceActions';

/**
 * Pusher Subscriber Container for Race.
 * Channel: grs_race_<raceId>
 */

class PusherSubscriberGRSNjRaceChannel extends Component {
	static propTypes = {
		/** The race ID to update */
		raceId: PropTypes.number.isRequired,

		/** Action to update the selected race */
		updateRace: PropTypes.func,

		/** Action to update the prices in the race */
		updatePrices: PropTypes.func,

		/** Action to update the prices for a derivative selection */
		updateDerivativeSelection: PropTypes.func,
	};

	static defaultProps = {
		updateRace: () => {},
		updatePrices: () => {},
		updateDerivativeSelection: () => {},
	};

	/**
	 * Handles pushed data to update race
	 * @param data
	 */
	handlePusherRaceUpdate = (data) => {
		this.props.updateRace(data);
		// this.props.updateNJ(data);
	};

	/**
	 * Handles pushed data to update odds.
	 * @param data
	 */
	handlePusherOddsUpdate = (data) => {
		/**
		 * Separate Price entities to be updated,
		 * otherwise Race can lose selections and selections can lose price.
		 */
		const prices = buildPriceUpdates(data);
		this.props.updatePrices(prices);
	};

	render() {
		const { raceId } = this.props;

		// Determine channel for all subscriptions
		const channel = `grs_race_${raceId}`;
		return (
			<Fragment>
				<PusherSubscribe
					key={PUSHER_GRS_EVENT_UPDATE}
					channel={channel}
					event={PUSHER_GRS_EVENT_UPDATE}
					onUpdate={this.handlePusherRaceUpdate}
				/>
				<PusherSubscribe
					key={PUSHER_GRS_EVENT_ODDS_UPDATE}
					channel={channel}
					event={PUSHER_GRS_EVENT_ODDS_UPDATE}
					onUpdate={this.handlePusherOddsUpdate}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
	updateRace: (race) => dispatch(mergeRace(race)),
	updatePrices: (prices) => dispatch(updatePrices(prices)),
	updateNJ: (race) => dispatch(mergeNJRace(race)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberGRSNjRaceChannel);
