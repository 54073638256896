import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, CollapsiblePanel, Text } from '@tbh/ui-kit';
import ThemeItem from '../ThemeItem/ThemeItem';

const StyledThemeGroup__SubItem = styled('div')(
	(props) => css`
		label: ThemeGroup__SubItem;

		margin-left: ${spacings(props).cozy}px;
	`,
);

const StyledThemeGroup__Item = styled(ThemeItem)(
	(props) => css`
		label: ThemeGroup__Item;

		margin-bottom: ${spacings(props).compact}px;
	`,
);

class ThemeGroup extends Component {
	static propTypes = {
		/** Extra classes */
		className: PropTypes.string,

		/** Handler for changing the color in the Color Picker */
		onThemeChange: PropTypes.func,

		/** Object with the theme group properties and values */
		themeGroup: PropTypes.object,

		/** Name of the theme group */
		themeGroupName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		onThemeChange: null,
		themeGroup: {},
		themeGroupName: '',
	};

	state = {
		showThemeGroup: this.props.themeGroupName === 'brand',
	};

	/**
	 * Opens/Closes the Collapsible Panel
	 */
	toggleThemeGroup = () => {
		this.setState({
			showThemeGroup: !this.state.showThemeGroup,
		});
	};

	/**
	 * Renders the theme item
	 *
	 * @param propertyName
	 * @param propertyValue
	 * @returns {*}
	 */
	renderThemeItem = (propertyName, propertyValue) => {
		const { onThemeChange, themeGroupName } = this.props;

		if (propertyValue && typeof propertyValue === 'object') {
			return (
				<div>
					<Text size="-1" strong>
						{propertyName}:
					</Text>

					<StyledThemeGroup__SubItem>
						{Object.keys(propertyValue).map((key) => {
							const value = propertyValue[key];

							return (
								<div key={key}>
									<StyledThemeGroup__Item
										onThemeChange={onThemeChange}
										propertyName={key}
										propertyValue={value}
										subItem={propertyName}
										themeGroupName={themeGroupName}
									/>
								</div>
							);
						})}
					</StyledThemeGroup__SubItem>
				</div>
			);
		} else {
			return (
				<StyledThemeGroup__Item
					onThemeChange={onThemeChange}
					propertyName={propertyName}
					propertyValue={propertyValue}
					themeGroupName={themeGroupName}
				/>
			);
		}
	};

	render() {
		const { className, themeGroup, themeGroupName } = this.props;

		const { showThemeGroup } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<CollapsiblePanel
				className={componentClasses}
				label={
					<Text transform="capitalize" strong>
						{themeGroupName}
					</Text>
				}
				isCollapsed={!showThemeGroup}
				padding={CollapsiblePanel.spacings.SPACING_COZY}
				toggleCollapsibleContent={this.toggleThemeGroup}
				type={CollapsiblePanel.types.SUPPORT}
				useBorder
			>
				{Object.keys(themeGroup).map((propertyName) => {
					const propertyValue = themeGroup[propertyName];

					return <div key={propertyName}>{this.renderThemeItem(propertyName, propertyValue)}</div>;
				})}
			</CollapsiblePanel>
		);
	}
}

export default ThemeGroup;
