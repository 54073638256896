// Libraries
import {denormalize, normalize,} from 'normalizr';


import {competitionsSchema} from './Entities';

/**
 * Denormalizes Competitions
 *
 * @param entities
 * @returns {Array}
 */
export const denormalizeCompetitions = (entities) => {
	return denormalize(Object.keys(entities.competitions), competitionsSchema, entities);
};

/**
 * Normalizes competitions.
 *
 * @param response
 * @returns {Object}
 */
export const normalizeCompetitions = (response) => {
	return normalize(response, competitionsSchema);
};