import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { getNextToJumpRaces } from '../../pages/Racing/RacingHome/racingSelectorsGRS';
import { navigateToNextToJumpRace } from '../../store/GRSracingHome/GRSracingHomeActions';
import RaceNextJump from '../../components/features/Racing/RaceNextJump/RaceNextJump';
// import PusherSubscriberNTJRacesChannel from '../../components/features/Racing/PusherSubscribers/PusherSubscriberNTJRacesChannel';
import { Ticker } from '@tbh/ui-kit';

class NextToJumpRacesContainer extends React.Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
		onItemClick: PropTypes.func.isRequired,
		isRacingHome: PropTypes.bool,
		className: PropTypes.string,
		hideRaceNextJumpFilter: PropTypes.bool,
	};
	static defaultProps = {
		isRacingHome: false,
		className: '',
		hideRaceNextJumpFilter: false,
	};

	// Navigate to Race
	handleOnItemClick = (raceId, meetingId) => {
		this.props.onItemClick(raceId, meetingId, this.props.isRacingHome);
	};

	render() {
		return (
			<React.Fragment>
				{/* <PusherSubscriberNTJRacesChannel /> */}
				<SizeMe noPlaceholder>
					{({ size }) => (
						<Ticker every={60000}>
							<RaceNextJump
								className={this.props.className}
								items={this.props.items}
								onItemClick={this.handleOnItemClick}
								trackOnFilterClick={()=>{}}
								hideRaceNextJumpFilter={this.props.hideRaceNextJumpFilter}
								size={size}
							/>
						</Ticker>
					)}
				</SizeMe>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	items: getNextToJumpRaces(state),
});

const mapDispatchToProps = (dispatch) => ({
	onItemClick: (raceId, meetingId, isRacingHome) => {
		dispatch(navigateToNextToJumpRace(raceId, meetingId));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(NextToJumpRacesContainer);
