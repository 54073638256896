export const setCache = (name, id, data) => {
	const currentData = getCache(name);

	let obj = {
		...currentData,
		[id]: {
			data: data,
			timestamp: new Date().getTime(),
		},
	};

	localStorage.setItem(name, JSON.stringify(obj));
};

export const getCache = (name) => {
	try {
		return JSON.parse(localStorage.getItem(name));
	} catch (e) {
		return null;
	}
};

export const removeCache = (name, id) => {
	const currentData = getCache(name);

	if (!currentData || !currentData[id]) return;

	delete currentData[id];

	localStorage.setItem(name, JSON.stringify(currentData));
};