import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { SizeMe } from 'react-sizeme';


//@HW add new next sport page design
import * as NJSHPS from './SportNextJumpHomePage.styled';
import Route from '../../../components/controllers/Route/Route';
// Containers
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import {
	resetBetPrompt,
} from '../../../store/betPrompt/betPromptActions';
import { fetchTrendingBets } from '../../../store/entities/actions/TrendingBetActions';
import {
	getAuthenticatedUser,
} from '../../../store/application/applicationSelectors';


// Components
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';
import { buildEventMarketGroups, getBets, getEvents} from './SportNextJumpHomeSelectors';

import { LoadingSpanner } from '../../../components/features/Application/LoadingSpinner/LoadingSpinner';
// Actions
import {  buildRouteToSportNextJump } from '../../../routes/SportsNextJump';
import {
    navigateToSportNextJump,
  } from '../../../store/sportsNextJumpHome/SportsNextJumpHomeAction';
import { fetchActiveBets } from '../../../store/entities/actions/BetActions';

class SportNextJumpHomePage extends Component {
    static propTypes = {
        /** Translation func provided by withNamespaces HOC */
        t: PropTypes.func.isRequired,
         /** Brand name */
        brandName: PropTypes.string,

        /** ac; data */
        acl: PropTypes.shape(),

        /** Loading mask to cover both meetings and races lists. */
        loadingContainer: PropTypes.bool,
        /** Show routing and meta components from the page */
        useRouting: PropTypes.bool,

        /** Based of state. Used for routing. */
        currentRoute: PropTypes.string,
        /** Watch for authenticatedUser to fetch their bets. */
        authenticatedUser: PropTypes.number,

         /** Is the app loaded on a phone type device */
        isPhoneDevice: PropTypes.bool,

        /** Only render the compeition components */
        competitionOnly: PropTypes.bool,

        /** If a bet has been referred to a trader for review */
        betReferred: PropTypes.bool,

        /** Determines whether the component will render sports home or not. */
        isSportsHome: PropTypes.bool.isRequired,

        /** Attempt to fetch the logged in users active bets */
		fetchActiveBets: PropTypes.func.isRequired,
    };

    static defaultProps = {
        brandName: '',
        currentRoute: null,
        authenticatedUser: null,
        acl: {},
        isPhoneDevice: false,
        useRouting: true,
        loadingContainer: false,
        betReferred: false,
        competitionOnly: false,
      };

    constructor(props) {
        super(props);


        this.state = {
          isMobile: props.isPhoneDevice,
        };
    }

    componentDidMount() {
        if (this.props.authenticatedUser) {
          this.props.fetchActiveBets();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.authenticatedUser && prevProps.authenticatedUser !== this.props.authenticatedUser) {
            this.props.fetchActiveBets();
        }
    }

    render(){
        const {
            t,
            brandName,
            currentRoute,
            useRouting,
            loadingContainer,
            competitionOnly,
            isSportsHome,
          } = this.props;
          const {isMobile} = this.state;

        return (
	<SizeMe>
		{({size}) => (
			<NJSHPS.StyledNextJumpSportHomePage>
				{/* set page title and meta data*/}
				{useRouting && (
				<React.Fragment>
					<Route route={currentRoute}/>
					<DocumentTitle>{`${t('SportsNextJumpHomePage_Title')}${
                        brandName ? ` ${t('at')} ${brandName}` : ''
                        }`}</DocumentTitle>
					<DescriptionMeta>
						{`${t('SportsNextJumpHomePage__Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. `}
					</DescriptionMeta>


				</React.Fragment>
                    )}

				<LoadingSpanner style={{display: loadingContainer ? 'block' : 'none'}}/>

				<NJSHPS.StyledNextJumpSportHomePage__Content size={size}>

					<NJSHPS.StyledNextJumpSportHomePage__Card__Body size={size}>
						<NJSHPS.StyledNextJumpSportHomePage_Header_section>
							<NJSHPS.StyledNextJumpSportHomePage_maijor_header>
								<NJSHPS.StyledNextJumpSportHomePage_Major_header_h2>{t('UPCOMING SPORTS')}</NJSHPS.StyledNextJumpSportHomePage_Major_header_h2>
							</NJSHPS.StyledNextJumpSportHomePage_maijor_header>
						</NJSHPS.StyledNextJumpSportHomePage_Header_section>


						<NJSHPS.StyledSportNextJumpHomePage__container>

							{isSportsHome && !competitionOnly && (
							<React.Fragment>
								<NJSHPS.StyledSportsNextJumpHomePage__NTJSports_Container />
							</React.Fragment>
                                        )}
						</NJSHPS.StyledSportNextJumpHomePage__container>

					</NJSHPS.StyledNextJumpSportHomePage__Card__Body>


				</NJSHPS.StyledNextJumpSportHomePage__Content>
			</NJSHPS.StyledNextJumpSportHomePage>
                )}
	</SizeMe>
        );
    };
}
const mapStateToProps = (state, ownProps) => {

    const user = getAuthenticatedUser(state);
    return {
        user,
        loadingContainer: state.sportsNextJumpHome.loadingContainer,
        currentRoute: buildRouteToSportNextJump(),
        authenticatedUser: state.application.authenticatedUser,
        brandName: state.acl.brandDetails && state.acl.brandDetails.name,
        betPrompt: state.betPrompt,
        events: getEvents(state),
		    marketGroups: buildEventMarketGroups(state),
		    bets: getBets(state.entities),
        useRouting:
        typeof ownProps.useRouting === 'undefined'
            ? state.featureToggles.features.urlRouting.enabled
            : ownProps.useRouting,

       // We should consider the case where we select an event of unknown sport.
        isSportsHome: !state.sportsNextJumpHome.selectedSport && !state.sportsNextJumpHome.selectedEvent,
        loadingEvents: state.sportsNextJumpHome.loadingEvents,
    };
};
const mapDispatchToProps = (dispatch) => ({
    fetchActiveBets: () => dispatch(fetchActiveBets()),
    fetchTrendingBets: () => dispatch(fetchTrendingBets()),
    resetBetPrompt: () => dispatch(resetBetPrompt()),
    navigateToSportNextJump: () => dispatch(navigateToSportNextJump()),

});


export default withNamespaces()(
    createErrorBoundary(
      connect(
        mapStateToProps,
        mapDispatchToProps,
      )(SportNextJumpHomePage),
    ),
);
