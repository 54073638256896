import React from 'react';
import { List, ListItem, spacings, ui, typography, media, Text } from '@tbh/ui-kit';
import { css } from 'emotion';
import styled from 'react-emotion';
import { StyledNJRaceHeaderBar__Metting__NameLoading } from './../../Carousel/UpCarouselItem/UpCarouselItem.styled';
import { StyledRaceCard } from './RaceCard';

const StyledRaceSelectionsList__RaceSelectionsListItems = styled(List)`
	${(props) =>
		css`
			label: RaceSelectionsList__RaceSelectionsListItem;
			margin-bottom: ${spacings(props).cozy}px;
		`};
`;

const StyledRaceSelectionsListItem = styled(ListItem)`
	${(props) =>
		css`
			label: RaceSelectionsListItem;

			list-style: none;
			padding: ${spacings(props).tight}px;

			&:nth-of-type(4n + 1):hover,
			&:hover {
				background: ${ui(props).color_3};
			}

			&:nth-of-type(4n + 1) {
				background: #f5f5f5;
			}
		`};
`;
const StyledRaceSelectionsListItem__Wrapper = styled('div')(
	(props) =>
		css`
			label: RaceSelectionsListItem__Wrapper;

			// Set a min-height so the trends don't overlap
			min-height: ${spacings(props).roomy}px;
			display: flex;
			align-items: center;
			position: relative;
			padding: ${spacings(props).tight}px;
		`,
);

const StyledRaceSelectionsDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__DetailsColumn;

			padding-top: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;
			${props.scratched &&
				css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
		css`
			padding-top: 0;
		`,
		660,
	)};
`;

const StyledRaceSelectionsDetails__Name = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Name;
			margin-right: ${spacings(props).tight}px;
			color: #003a91;
			&:after {
				content: ' ';
			}
		`};

	${media(
		(props) =>
			css`
				font-size: ${typography(props)['size_-2']}px;
			`,
		660,
		{ sizeKey: 'mediaSize' },
	)};
`;

const RaceCardLoading = () => {
	const selections = [0, 1, 2, 3, 4, 5, 6];
	return (
		<StyledRaceCard
			style={{
				width: '100%',
				overflow: 'hidden',
			}}
		>
			<div style={{ display: 'flex', padding: '0px 4px' }}>
				<StyledNJRaceHeaderBar__Metting__NameLoading width="50px" height="20px" style={{ borderRadius: '4px' }} />
				<StyledNJRaceHeaderBar__Metting__NameLoading width="50px" height="20px" style={{ borderRadius: '4px' }} />
				<StyledNJRaceHeaderBar__Metting__NameLoading width="50px" height="20px" style={{ borderRadius: '4px' }} />
				<StyledNJRaceHeaderBar__Metting__NameLoading width="50px" height="20px" style={{ borderRadius: '4px' }} />
			</div>
			{/* <div style={{ display: 'flex', gap: 4, cursor: 'pointer', padding: '0px 4px' }}>
				<StyledNJRaceHeaderBar__Metting__NameLoading width="80px" height="10px" style={{ borderRadius: '4px' }} />
				<StyledNJRaceHeaderBar__Metting__NameLoading width="12px" height="12px" style={{ borderRadius: '4px' }} />
			</div> */}
			<StyledRaceSelectionsList__RaceSelectionsListItems>
				{selections.map((s) => (
					<StyledRaceSelectionsListItem key={s}>
						<StyledRaceSelectionsListItem__Wrapper
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<StyledNJRaceHeaderBar__Metting__NameLoading
									width="35px"
									height="35px"
									style={{ borderRadius: '4px' }}
								/>
								<StyledRaceSelectionsDetails__DetailsColumn>
									<StyledRaceSelectionsDetails__Name size="-2" transform="uppercase" strong>
										<StyledNJRaceHeaderBar__Metting__NameLoading
											width="80px"
											height="10px"
											style={{ borderRadius: '4px' }}
										/>
										<StyledNJRaceHeaderBar__Metting__NameLoading
											width="50px"
											height="10px"
											style={{ borderRadius: '4px' }}
										/>
										<StyledNJRaceHeaderBar__Metting__NameLoading
											width="130px"
											height="10px"
											style={{ borderRadius: '4px' }}
										/>
									</StyledRaceSelectionsDetails__Name>
								</StyledRaceSelectionsDetails__DetailsColumn>
							</div>

							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<StyledNJRaceHeaderBar__Metting__NameLoading
									width="50px"
									height="35px"
									style={{ borderRadius: '4px' }}
								/>
								<StyledNJRaceHeaderBar__Metting__NameLoading
									width="50px"
									height="35px"
									style={{ borderRadius: '4px' }}
								/>
								<StyledNJRaceHeaderBar__Metting__NameLoading
									width="50px"
									height="35px"
									style={{ borderRadius: '4px' }}
								/>
								<StyledNJRaceHeaderBar__Metting__NameLoading
									width="50px"
									height="35px"
									style={{ borderRadius: '4px' }}
								/>
							</div>
						</StyledRaceSelectionsListItem__Wrapper>
					</StyledRaceSelectionsListItem>
				))}
			</StyledRaceSelectionsList__RaceSelectionsListItems>
		</StyledRaceCard>
	);
};

export default RaceCardLoading;
