import { denormalize, normalize } from 'normalizr';
import { derivativeMarketsSchema } from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param {Array} keys
 * @returns {Array}
 */
const denormalizeDerivativeMarkets = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.derivative_markets), derivativeMarketsSchema, entities).filter(
		(sel) => !!sel,
	);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizeDerivativeMarkets = (response) => {
	return normalize(response, derivativeMarketsSchema);
};

export { denormalizeDerivativeMarkets, normalizeDerivativeMarkets };
