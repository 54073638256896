import { get, post } from '../../../common/Ajax';
import env from '../../../common/EnvironmentVariables';

import { SET_AUTHENTICATED_USER, CLEAR_AUTHENTICATED_USER } from '../../application/applicationActionTypes';

import { normalizeUsers } from '../schemas/UserSchema';
import { mergeEntities } from '../../../common/actions/actionHelpers';

/**
 * Action for setting the authenticated user
 *
 * @param userObject
 * @returns {{type: *, user: *}}
 */
export const setAuthenticatedUser = (userObject) => {
	if (!userObject || !userObject.id) {
		throw new Error('A user object with an [id] must be provided for this action');
	}

	let result = normalizeUsers([userObject]);

	return {
		type: SET_AUTHENTICATED_USER,
		users: result.entities.users,
		userId: userObject.id,
		track: {
			category: 'send',
			action: 'event',
			label: 'user',
			value: 'login',
		},
	};
};

/**
 * Create an action for clearing the authenticated user
 */
export const clearAuthenticatedUser = () => {
	return {
		type: CLEAR_AUTHENTICATED_USER,
	};
};

/**
 * Dispatch an action to normalize and merge a user entity
 * @param data
 */
export const normalizeAndMergeUser = (data) => normalizeAndMergeUsers([data]);

/**
 * Dispatch an action to normalize and merge users entities
 * @param data
 */
export const normalizeAndMergeUsers = (data) => mergeEntities(normalizeUsers(data).entities);

/**
 * Action to block the user for the selected duration
 *
 * @param days: number
 */
export const blockUser = (days) => () => {
	return post('/user/self-exclude', { days }).then((response) => {
		return response;
	});
};

/**
 * Action to PERMANENTLY block the user
 *
 * @param email
 * @param reason
 */
export const excludeUser = (email, reason) => () => {
	let params = {
		days: -1,
		email,
		reason,
	};

	return post('/user/self-exclude', params).then((response) => {
		return response;
	});
};

/**
 * Action to set the reality check timer
 *
 * @param period: 0, 1, 2 or 6 (number of hours)
 */
export const setRealityCheck = (period) => () => {
	return post('/user/set-reality-check', { period }).then((response) => {
		return response;
	});
};

/**
 * Action to update the reality check timer
 */
export const continueRealityCheck = () => () => {
	return post('/user/reality-check').then((response) => {
		return response;
	});
};

/**
 * Credit Card Deposit
 *
 * @param object: {
 * amount (in cents),
 * billingCountry,
 * cvv,
 * expiryMonth,
 * expiryYear,
 * firstName,
 * lastName,
 * number (credit card number),
 * promo_code,
 * source
 * }
 */
export const makeADeposit = (object) => () => {
	// encrypts the credit card number and security code
	const ewayMerchantKey = env('ewayMerchantKey');
	object.number = eCrypt.encryptValue(object.number, ewayMerchantKey);
	object.cvv = eCrypt.encryptValue(object.cvv, ewayMerchantKey);

	return post('/deposits', object).then((response) => {
		return response;
	});
};

/**
 * API call to fetch all the account transactions for a user
 *
 * @param {number|null} page - The page number to fetch (null or 1, 2, …).
 * @param {string} type - The type of transactions to fetch (bets, all, open, bet-losing, bet-winning, bet-refunded, deposits, withdrawals).
 * @param {string} date - The date filter (today, week, month, range).
 * @param {string} startDate - The start date for the range filter (if date is 'range').
 * @param {string} endDate - The end date for the range filter (if date is 'range').
 * @returns {Promise} - A promise that resolves to the response from the API.
 */
export const fetchTransactions = (page = 1, type = 'bets', date = '', startDate = '', endDate = '') => {
	// Initialize the base URL
	let url = `user/transactions?page=${page}&type=${type}&date=${date}`;
	// Append fromDate and toDate if date type is 'range'
	if (date === 'range') {
		url += `&from_date=${startDate}&to_date=${endDate}`;
	}
	// Make the API call and return the response
	return get(url).then((response) => {
		return response;
	});
};


/**
 * API call to fetch the account transaction statistics for the user
 */
export const fetchStatistics = () => {
	return get('user/transaction-stats').then((response) => {
		return response;
	});
};


const POSSIBLE_DATE_VALUES = ['today', 'week', 'month', 'range'];

/**
 * API call to fetch the account transaction reports
 * @param startDate - only used if date is 'range'
 * @param endDate - only used if date is 'range'
 * @param type -
 * @param date - today, week, month, range
 */
export const getTransactionDetails = async (type = 'all', date = 'today', startDate, endDate) => {
	if (!POSSIBLE_DATE_VALUES.includes(date)) {
		throw new Error('Invalid date parameter');
	}
	// let url = `user/transactions-report?date=${date}&type=${type}`;	
       let url = `user/user-transactions-report?type=${type}&date=${date}`;

	if (date === 'range') {
		url += `&from_date=${startDate}&to_date=${endDate}`;
	}
	try {
		const response = await get(url, {
			responseType: 'blob',
		});

		if (response.data.size === 0) {
			throw new Error('Sorry no data available');
		}

		return URL.createObjectURL(response.data);
	} catch (err) {
		let error = '';
		//

		if (err.response && err.response.data) {
			// transform blob to json
			const res = await err.response.data.text();
			const json = JSON.parse(res);
			if (Array.isArray(json.errors)) {
				for(let i = 0; i < json.errors.length; i++) {
					error += ' [' + json.errors[i] + '] ';
				}
			} else {
				error = json.errors;
			}
		}
		throw new Error(error || err.message);
	}
};

                                                    // bonus bet
													  // bonus bet
													    // bonus bet
														  // bonus bet
/**
 * API call to fetch all the bonus bet transactions for a user
 *
 * @param page
 * @param type
 */
// export const fetchBonusTransactions = (page = 1, type = 'bets') => {
// 	return get(`user/free-credit-transactions?page=${page}&type=${type}`).then((response) => {
// 		return response;
// 	});
// };


export const fetchBonusTransactions = (page = 1, type = 'bets', date = '', startDate = '', endDate = '') => {
	// Initialize the base URL
	let url = `user/free-credit-transactions?page=${page}&type=${type}&date=${date}`;
	// Append fromDate and toDate if date type is 'range'
	if (date === 'range') {
		url += `&from_date=${startDate}&to_date=${endDate}`;
	}
	// Make the API call and return the response
	return get(url).then((response) => {
		return response;
	});
};




/**
 * API call to fetch the account Bonus transaction statistics for the user
 */
export const fetchBonusStatistics = () => {
	return get('user/bonus-transaction-stats').then((response) => {
		return response;
	});
};


/**
 * API call to fetch the account Bonus transaction reports
 * @param startDate - only used if date is 'range'
 * @param endDate - only used if date is 'range'
 * @param type -
 * @param date - today, week, month, range
 */
export const getBonusTransactionDetails = async (type = 'all', date = 'today', startDate, endDate) => {
	if (!POSSIBLE_DATE_VALUES.includes(date)) {
		throw new Error('Invalid date parameter');
	}
	
       let url = `user/free-credit-transactions-report?type=${type}&date=${date}`;

	if (date === 'range') {
		url += `&from_date=${startDate}&to_date=${endDate}`;
	}
	try {
		const response = await get(url, {
			responseType: 'blob',
		});

		if (response.data.size === 0) {
			throw new Error('Sorry no data available');
		}

		return URL.createObjectURL(response.data);
	} catch (err) {
		let error = '';
		//

		if (err.response && err.response.data) {
			// transform blob to json
			const res = await err.response.data.text();
			const json = JSON.parse(res);
			if (Array.isArray(json.errors)) {
				for(let i = 0; i < json.errors.length; i++) {
					error += ' [' + json.errors[i] + '] ';
				}
			} else {
				error = json.errors;
			}
		}
		throw new Error(error || err.message);
	}
};
