/**
 * Created by Josef Frank on 2017/04/03.
 */

import {denormalize, normalize,} from 'normalizr';

import {sportsSchema} from './Entities';

/**
 * Denormalizes sports.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
const denormalizeSports = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.sports), sportsSchema, entities);
};

/**
 * Normalizes sports.
 *
 * @param response
 * @returns {Object}
 */
const normalizeSports = (response) => {
	return normalize(response, sportsSchema);
};

export {
	denormalizeSports,
	normalizeSports,
};