// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

// Components
import { Text } from '@tbh/ui-kit';

const YouQualifyAlert = (props) => {
	const { className, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<Text className={componentClasses} align="center" paragraph>
			<Text align="center" strong>
				{t('YouQualifyAlert__Qualify')}
			</Text>
			<Text align="center" size="-1">
				{t('YouQualifyAlert__Deposit')}
			</Text>
		</Text>
	);
};

YouQualifyAlert.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

YouQualifyAlert.defaultProps = {
	className: '',
};

export default withNamespaces()(YouQualifyAlert);
