import trim from 'underscore.string/trim';
import dasherize from 'underscore.string/dasherize';
import { createSelector } from 'reselect';
import { denormalizeNavigationItems, getNavigationItemsEntities } from '../schemas/NavigationItemsSchema';
import { getAuthenticatedUser } from '../../application/applicationSelectors';

import { TOURNAMENTS_BASE_URL } from '../../../pages/Tournaments/TournamentsConstants';
import { PLAYER_BETS } from '../../../containers/Application/MainNavigation/MainNavigationConstants';

/**
 * Filter the navigation items
 *
 * @param item
 * @param itemType
 * @param authenticatedUser
 * @returns {*}
 */
const navigationItemFilter = (item, itemType, authenticatedUser) =>
	item[itemType] && item.show && (authenticatedUser ? item.showForUser : item.showForGuest);

/**
 * Sort the navigation items by order, then by title
 *
 * @param a
 * @param b
 * @returns {number}
 */
const navigationItemSort = (a, b) => a.order - b.order || a.title.localeCompare(b.title);

/**
 * Filter and sort the navigation items for the main navigation
 *
 * @param navigationItems
 * @param authenticatedUser
 * @returns {{primaryItems, secondaryItems, mobileItems}}
 */
export const sortNavigationItems = (navigationItems, authenticatedUser) => {
	const primaryItems = navigationItems
		.filter((item) => navigationItemFilter(item, 'primary', authenticatedUser))
		.sort(navigationItemSort);

	const secondaryItems = navigationItems
		.filter((item) => navigationItemFilter(item, 'secondary', authenticatedUser))
		.sort(navigationItemSort);

	const mobileItems = navigationItems
		.filter((item) => navigationItemFilter(item, 'mobile', authenticatedUser))
		.sort(navigationItemSort);

	return {
		primaryItems,
		secondaryItems,
		mobileItems,
	};
};

/**
 * Get a sorted list of navigation items
 */
export const getNavigationItems = createSelector(
	(state) => getNavigationItemsEntities(state.entities),
	(state) => getAuthenticatedUser(state),
	(navigationItemsEntities, authenticatedUser) => {
		const navigationItems = denormalizeNavigationItems(navigationItemsEntities);
		return sortNavigationItems(navigationItems, authenticatedUser);
	},
);

/**
 * Get the active navigation item based on the application's current route
 *
 * @param state
 * @param sports
 */
export const getActiveNavigationItem = (state, sports) => {
	const navigationItems = denormalizeNavigationItems(state.entities);
	let currentRoute = state.application.currentRoute;
	let activeNavigationItem;

	if (!currentRoute) {
		return null;
	}

	if (!currentRoute.startsWith('/')) {
		currentRoute = '/' + currentRoute;
	}

	// If the current route is a sport, get the sport name
	if (sports && currentRoute.startsWith('/sports/')) {
		activeNavigationItem = Object.values(sports).find((sport) => {
			// Converts the camelized sport name into a dasherized string
			let name = dasherize(sport.name);
			name = trim(name, '-');

			return currentRoute.startsWith('/sports/' + name);
		});

		activeNavigationItem = activeNavigationItem ? activeNavigationItem.name : null;
	} else {
		// If the current route is not a sport, get the navigation item's route
		activeNavigationItem = navigationItems.find((item) => currentRoute.startsWith(item.route));
		activeNavigationItem = activeNavigationItem ? activeNavigationItem.route : null;
	}

	return activeNavigationItem;
};

/**
 * Filter and sort the navigation items for a flat structure
 *
 * @param state
 * @param isMobile
 */
export const sortFlatNavigationItems = (state, isMobile = false) => {
	const navigationItems = denormalizeNavigationItems(state.entities);
	const authenticatedUser = getAuthenticatedUser(state);

	return navigationItems
		.filter(
			(item) =>
				item.show &&
				(authenticatedUser ? item.showForUser : item.showForGuest) &&
				(item.mobile === isMobile || (!isMobile && (item.primary === true || item.secondary === true))),
		)
		.sort((a, b) => {
			return a.order - b.order || b.primary - a.primary || a.title.localeCompare(b.title);
		});
};

/**
 * Filter and sort the navigation items for the sidebar navigation
 *
 * @param state
 */
export const sortSidebarNavigationItems = (state) => {
	const navigationItems = denormalizeNavigationItems(state.entities);
	const authenticatedUser = getAuthenticatedUser(state);
	const featureToggles = state.featureToggles.features;
	const { tournaments } = featureToggles;

	let sidebarItems = navigationItems.filter((item) => {
		return item.showOnSidebar && item.show && (authenticatedUser ? item.showForUser : item.showForGuest);
	});

	// Remove the tournament items from the menu items if the feature is toggled
	if (tournaments && !tournaments.enabled) {
		sidebarItems = sidebarItems.filter((item) => item.id !== TOURNAMENTS_BASE_URL);
	}

	sidebarItems.sort((a, b) => {
		return a.sidebarOrder - b.sidebarOrder || a.title.localeCompare(b.title);
	});

	return sidebarItems;
};

/**
 * Get the Player Bets from the navigation items
 *
 * @param entities
 */
export const getPlayerBetItem = (entities = {}) => {
	const navigationItems = denormalizeNavigationItems(entities);

	return navigationItems.find((item) => item.id === PLAYER_BETS);
};
