import { connect } from 'react-redux';
import UserAccountMenu from '../../../components/features/UserAccount/UserAccountMenu/UserAccountMenu';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { setSelectedMenuItem } from '../../../store/userAccount/userAccountActions';
import { getUserAccountMenuItems } from '../../../store/userAccount/userAccountSelectors';

const mapStateToProps = (state, ownProps) => {
	return {
		className: ownProps.className,
		showHeader: ownProps.showHeader,
		items: ownProps.menuItems ? ownProps.menuItems : getUserAccountMenuItems(state),
		activeItem: state.userAccount.selectedMenuItem,
	};
};

const mapDispatchToProps = (dispatch) => ({
	onSelect: (item) => {
		dispatch(trackGaEvent('MyAccount', 'Click', item.trackingTitle));
		dispatch(setSelectedMenuItem(item));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountMenu);
