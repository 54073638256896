// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { Button } from '@tbh/ui-kit';


// Context

// Styles
const StyledCreateAccount = styled(Button)(
	(props) => css`
		label: CreateAccount;

		text-align: center;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		padding: 8px;
	`,
);

const CreateAccount = (props) => {
	const { className, createAccount, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Goes to the Registration page
	 */
	const handleCreateAccount = () => {
		createAccount('Login Register', 'Click', 'Join');
	};

	return (
		<StyledCreateAccount type="primary" className={componentClasses} action={handleCreateAccount} bold
		>
			{t('CreateAccount__Button')}
		</StyledCreateAccount>
	);
};

CreateAccount.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to create an account */
	createAccount: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

CreateAccount.defaultProps = {
	className: null,
};

export default withNamespaces()(CreateAccount);
