// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'react-emotion';
import { spacings, media, Text } from '@tbh/ui-kit';
import moment from 'moment';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';

// Actions
import {
	resetUserAccount,
	setSelectedMenuItem,
	setTransactionFilter,
} from '../../store/userAccount/userAccountActions';
import { getUserAccountMenuItems } from '../../store/userAccount/userAccountSelectors';
import { routeTo } from '../../store/application/applicationActions';
import { launchLiveChat } from '../../store/application/applicationActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';

// Components
import Route from '../../components/controllers/Route/Route';
import { createErrorBoundary } from '../../containers/ErrorBoundary/ErrorBoundaryActions';
import EventContext from '../../contexts/eventContext';
import UserAccountMenuContainer from '../../containers/UserAccount/UserAccountMenuContainer/UserAccountMenuContainer';
import Profile from '../../components/features/UserAccount/Profile/Profile';
import DepositsPromptContainer from '../../containers/Deposit/DepositsPromptContainer';
import UserTransactionsContainer from '../../containers/UserAccount/UserTransactions/UserTransactionsContainer';
import WithdrawalPromptContainer from '../../containers/WithdrawalPrompt/WithdrawalPromptContainer';
import PendingWithdrawalsContainer from '../../containers/UserAccount/PendingWithdrawals/PendingWithdrawalsContainer';
import UpdatePasswordContainer from '../../containers/UserAccount/UpdatePassword/UpdatePasswordContainer';
import DefaultBetAmountsContainer from '../../containers/ResponsibleGambling/DefaultBetAmountsContainer/DefaultBetAmountsContainer';
import RNSContainer from '../../containers/UserAccount/R&S/RNSContainer';
import FileUploadGreenidContainer from '../../containers/UserAccount/UploadGreenIdDocument/FileUploadGreenidContainer';

import TakeABreakContainer from '../../containers/ResponsibleGambling/TakeABreakContainer/TakeABreakContainer';
import SelfExclusionContainer from '../../containers/ResponsibleGambling/SelfExclusionContainer/SelfExclusionContainer';
import DepositLimitsContainer from '../../containers/ResponsibleGambling/DepositLimitsContainer/DepositLimitsContainer';
import LossLimitsContainer from '../../containers/ResponsibleGambling/LossLimitsContainer/LossLimitsContainer';
import SpendLimitsContainer from '../../containers/ResponsibleGambling/SpendLimitsContainer/SpendLimitsContainer';
import RealityCheckContainer from '../../containers/ResponsibleGambling/RealityCheck/RealityCheckContainer';
import BrandContactPhone from '../../components/ui-components/BrandContactPhone/BrandContactPhone';
import DocumentTitle from '../../components/controllers/Meta/DocumentTitle';
import { HeaderBar } from '@tbh/ui-kit';
import { FONT_SIZE_15 } from '../../store/application/applicationConstants';

// Constants
import { SERVER_DATE_FORMAT } from '../../common/constants/DateFormats.js';
import {
	USER_ACCOUNT_MEDIA_SIZE,
	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
	USER_ACCOUNT_SUBMENU_USER_HOME,
	USER_ACCOUNT_SUBMENU_DEPOSIT,
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
	USER_ACCOUNT_SUBMENU_WITHDRAWALS,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
	USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
	USER_ACCOUNT_SUBMENU_TAKE_A_BREAK,
	USER_ACCOUNT_SUBMENU_SELF_EXCLUSION,
	USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT,
	USER_ACCOUNT_SUBMENU_LOSS_LIMITS,
	USER_ACCOUNT_SUBMENU_SPEND_LIMIT,
	USER_ACCOUNT_SUBMENU_REALITY_CHECK,
	USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL,
	USER_ACCOUNT_PAGES,
	USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD,
	USER_ACCOUNT_SUBMENU_RNS_ACCOUNT,
} from './UserAccountPageConstants';
import { DEPOSIT_USER_ACCOUNT } from '../../store/deposits/depositsReducerNames';
import DescriptionMeta from '../../components/controllers/Meta/DescriptionMeta';

const MENU_ITEMS_WIDTH = 210;

const StyledUserAccountPage = styled('div')((props) =>

	css`
		label: UserAccountPage;

		display: flex;
		// padding: ${spacings(props).compact}px;
		width:100%;
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: 100vh;
		width: 100%;
		padding: ${spacings(props).compact}px;
		background-color: white;
		border-radius: 10px;
		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		label,
		span {
			// font-family: 'Roboto', sans-serif;
		}

		`
);

const StyledUserAccountPage__Header = styled(HeaderBar)(
	css`
		label: UserAccountPage__Header;

		display: none;
		font-size: ${FONT_SIZE_15};
	`,
	media(
		css`
			display: flex;
			font-size: ${FONT_SIZE_15};
		`,
		USER_ACCOUNT_MEDIA_SIZE,
	),
);

const StyledUserAccountPage__MenuItems = styled(UserAccountMenuContainer)(
	css`
		label: UserAccountPage__MenuItems;
		min-width: ${MENU_ITEMS_WIDTH}px;
		height:fit-content;
		&:active {
		color:#000000;
		}

		margin-bottom:10px;
		background: white;
		border-radius: 8px;
	`,
	(props) =>
		media(
			css`
				margin-right: ${spacings(props).compact}px;
			`,
			USER_ACCOUNT_MEDIA_SIZE,
		),
);

const StyledUserAccountPage__Wrapper = styled('div')(
	(props) => css`
		label: UserAccountPage__Wrapper;
		background: white;
		border-radius: 8px;
    	// padding-left:5%;
		flex: 1 1 auto;
		max-width: 100%;
		margin-bottom:10px;

	`,
	(props) =>
		media(
			css`
				max-width: 85%;
			`,
			USER_ACCOUNT_MEDIA_SIZE,
		),
);

const StyledHeaderText = css`
		font-size : 15px;
		color: #ffffff;
`;

const StyledUserAccountPage__Profile = StyledUserAccountPage__Wrapper.withComponent(Profile);
const StyledUserAccountPage__UpdatePassword = StyledUserAccountPage__Wrapper.withComponent(UpdatePasswordContainer);
const StyledUserAccountPage__File_Upload_Green_Id = StyledUserAccountPage__Wrapper.withComponent(FileUploadGreenidContainer);
const StyledUserAccountPage__Deposits = StyledUserAccountPage__Wrapper.withComponent(DepositsPromptContainer);
const StyledUserAccountPage__Transactions = StyledUserAccountPage__Wrapper.withComponent(UserTransactionsContainer);
const StyledUserAccountPage__Withdrawals = StyledUserAccountPage__Wrapper.withComponent(WithdrawalPromptContainer);
const StyledUserAccountPage__rnsAccount = StyledUserAccountPage__Wrapper.withComponent(RNSContainer);
const StyledUserAccountPage__CancelWithdrawals = StyledUserAccountPage__Wrapper.withComponent(
	PendingWithdrawalsContainer,
);

class UserAccountPage extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** If there is an authenticated user or not */
		authenticatedUser: PropTypes.bool.isRequired,

		/** Closes the user account page */
		closeUserAccountPage: PropTypes.func.isRequired,

		/** Clears the state for the user account */
		resetUserAccount: PropTypes.func.isRequired,

		/** Menu items */
		menuItems: PropTypes.array.isRequired,

		/** Set the currently selected menu item */
		setSelectedMenuItem: PropTypes.func.isRequired,

		/** Function to mark that the container has stopped loading - NOTE: automatically passed in by a wrapper in web context */
		setContainerHasLoaded: PropTypes.func,

		/** Show routing and meta components from the page */
		useRouting: PropTypes.bool,

		/** Show the headers around containers */
		showHeaders: PropTypes.bool,

		/** Used for routing and tracking */
		selectedMenuItem: PropTypes.shape({
			action: PropTypes.func,
			defaultUrlFragments: PropTypes.string,
			id: PropTypes.string,
			route: PropTypes.string,
			trackingTitle: PropTypes.string,
		}),

		/**
		 * Account Profile
		 */
		/** Opens the intercom so the user can complete the verification */
		onCompleteVerification: PropTypes.func.isRequired,

		/** The current user's email */
		email: PropTypes.string,

		/** The current user's first name */
		first_name: PropTypes.string,

		/** The current user's mobile number */
		mobile: PropTypes.string,

		/** The current user's username */
		username: PropTypes.string,

		/** The current user's address */
		address: PropTypes.string,

		/** The current user's country */
		country: PropTypes.string,

		/** The current user's dob */
		dob: PropTypes.string,

		/** The current user's last name */
		last_name: PropTypes.string,

		/** The current user's postcode */
		postcode: PropTypes.string,

		/** The current user's state */
		state: PropTypes.string,

		/** The current user's suburb */
		suburb: PropTypes.string,

		/** If it's a verified user or not */
		userIsVerified: PropTypes.bool,

		user_green_id: PropTypes.string,

		/** Brand name */
		brandName: PropTypes.string,

		/**
		 * Transactions
		 */
		/** Saves the transaction filter into state */
		setTransactionFilter: PropTypes.func.isRequired,

		/** Current transaction filter */
		filter: PropTypes.string,

		send_email: PropTypes.number.isRequired,

		/**
		 * Deposits
		 */
		/** If the receipt is being displayed or not **/
		showDepositReceipt: PropTypes.bool,


		green_id_overall_status: PropTypes.string,

		green_id_status: PropTypes.string,
	};

	static defaultProps = {
		useRouting: true,
		showHeaders: true,
		setContainerHasLoaded: null,
		selectedMenuItem: {
			action: undefined,
			defaultUrlFragments: '',
			id: USER_ACCOUNT_SUBMENU_USER_HOME,
			route: '/user',
			trackingTitle: 'My Profile',
		},

		// Account Profile
		address: '',
		country: '',
		dob: '',
		email: '',
		first_name: '',
		mobile: '',
		last_name: '',
		postcode: '',
		state: '',
		suburb: '',
		username: '',
		userIsVerified: false,
		brandName: '',
		user_green_id: '',
		// Transactions
		filter: USER_ACCOUNT_ALL_TRANSACTIONS_URL,

		// Deposits
		showDepositReceipt: false,
		green_id_overall_status: null,
		green_id_status: null,
	};

	constructor(props) {
		super(props);

		// Sets the route to display the right content
		const { selectedMenuItem } = props;

		let page, route;
		if (selectedMenuItem && selectedMenuItem.id) {
			page = selectedMenuItem.id;
			route = selectedMenuItem.defaultUrlFragments
				? selectedMenuItem.route + '/' + props.filter
				: selectedMenuItem.route;
		} else {
			page = USER_ACCOUNT_SUBMENU_USER_HOME;
			route = '/user';
		}

		this.state = {
			loading: false,
			page: page,
			route: route,
		};
	}

	/**
	 * Checks the selected Menu Item to display the correct content
	 *
	 * @param nextProps
	 * @param prevState
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		const selectedMenuItem = nextProps.selectedMenuItem;

		if (!nextProps.authenticatedUser) {
			nextProps.closeUserAccountPage();
			return null;
		}

		// if the selectedMenuItem is a page and not a modal
		if (USER_ACCOUNT_PAGES.indexOf(selectedMenuItem.id) !== -1) {
			// if it's a different page, set the page to be displayed and route and reset the filter
			if (selectedMenuItem.id !== prevState.page) {
				return {
					page: selectedMenuItem.id,
					route: selectedMenuItem.defaultUrlFragments
						? selectedMenuItem.route + '/' + selectedMenuItem.defaultUrlFragments
						: selectedMenuItem.route,
				};
			} else {
				// if it's the same page
				return {
					route: selectedMenuItem.defaultUrlFragments
						? selectedMenuItem.route + '/' + nextProps.filter
						: selectedMenuItem.route,
				};
			}
		}

		return null;
	}

	/**
	 * If the user is not authenticated, close the user account page
	 */
	componentDidMount() {
		const {
			authenticatedUser,
			closeUserAccountPage,
			setContainerHasLoaded,
			menuItems,
			selectedMenuItem,
			setSelectedMenuItem,
		} = this.props;

		// If the user is not authenticated, then closes the user account page
		if (!authenticatedUser) {
			return closeUserAccountPage();
		}

		// If the selected menu item is not valid, then set it to the first item
		const activeItem = menuItems.some((item) => item.id === selectedMenuItem.id) ? selectedMenuItem : menuItems[0];
		if (selectedMenuItem.id !== activeItem.id) {
			setSelectedMenuItem(activeItem);
		}

		if (setContainerHasLoaded) {
			setContainerHasLoaded();
		}
	}

	componentDidUpdate() {
		// If the user is not authenticated, then closes the user account page
		if (!this.props.authenticatedUser) {
			this.props.closeUserAccountPage();
		}
	}

	/**
	 * Clears the state when the User Account page is closed
	 */
	componentWillUnmount() {
		this.props.resetUserAccount();
	}

	/**
	 * Set a loading mask
	 */
	setLoadingMask = (loading = false) => {
		this.setState({
			loading: loading,
		});
	};

	/**
	 * Set the transactions filter to update the route and active tab/selection on Account Transactions
	 *
	 * @param filter
	 */
	setTransactionFilter = (filter) => {
		this.props.setTransactionFilter(filter);
	};

	render() {
		const {
			t,
			useRouting,
			showHeaders,
			authenticatedUser,

			// Account Profile
			address,
			country,
			dob,
			email,
			first_name,
			last_name,
			mobile,
			postcode,
			state,
			suburb,
			username,
			filter,
			onCompleteVerification,
			userIsVerified,
			brandName,
			green_id_overall_status,
			green_id_status,
			user_green_id,
			send_email,
			// Deposits
			showDepositReceipt,
		} = this.props;

		const { route } = this.state;

		if (!authenticatedUser) {
			return null;
		}

		return (
			<SizeMe>
				{({ size: containerSize }) => (
					<StyledUserAccountPage size={containerSize}>
						{useRouting && <Route route={route} />}

						<DocumentTitle>{`${t('RacingHomePage__Title')}${brandName ? ` ${t('at')} ${brandName}` : ''
							}`}
						</DocumentTitle>
						<DescriptionMeta>
							{`${t('RacingHomePage__Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. ${t(
								'RacingHomePage__DescriptionBonus',
							)}`}
						</DescriptionMeta>
						<StyledUserAccountPage__MenuItems
							size={containerSize}
							showHeader={showHeaders}
							isMobile={containerSize.width < USER_ACCOUNT_MEDIA_SIZE}
						/>

						<SizeMe noPlaceholder refreshMode="debounce">
							{({ size }) => {
								switch (this.state.page) {
									case USER_ACCOUNT_SUBMENU_USER_HOME:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>		{brandName ? `${brandName} ` : ''}
															{t('UserAccountPage__AccountProfile')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<StyledUserAccountPage__Profile
													size={size}
													onCompleteVerification={onCompleteVerification}
													city={suburb}
													country={country}
													dob={moment(dob, SERVER_DATE_FORMAT).format(SERVER_DATE_FORMAT)}
													email={email}
													first_name={first_name}
													last_name={last_name}
													msisdn={mobile}
													postcode={postcode}
													state={state}
													street={address}
													username={username}
													userIsVerified={userIsVerified}
													formDisabled
												/>
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>	{t('UserAccountPage__Upload_Green_Id_Document')} </Text>
													</StyledUserAccountPage__Header>
												)}
												<StyledUserAccountPage__File_Upload_Green_Id size={size}
													email={email}
													formDisabled
													first_name={first_name}
													last_name={last_name}
													street={address}
													msisdn={mobile}
													green_id_overall_status={green_id_overall_status}
													green_id_status={green_id_status}
													send_email={send_email}
													user_green_id={user_green_id}

												/>
											</StyledUserAccountPage__Wrapper>
										);
									case USER_ACCOUNT_SUBMENU_DEPOSIT:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header
														size={containerSize}
														type={HeaderBar.types.PRIMARY}
														aside={<BrandContactPhone />}
													>
														<Text className={StyledHeaderText}>		{showDepositReceipt
															? t('UserAccountPage__DepositReceipt')
															: t('UserAccountPage__MakeDeposit')} </Text>
													</StyledUserAccountPage__Header>
												)}

												<StyledUserAccountPage__Deposits size={size} renderOnPage />
											</StyledUserAccountPage__Wrapper>
										);
									case USER_ACCOUNT_SUBMENU_RNS_ACCOUNT:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>	{t('UserAccountPage__RNS_Account')} </Text>
													</StyledUserAccountPage__Header>
												)}
												<StyledUserAccountPage__rnsAccount size={size}
													email={email}
													formDisabled
													first_name={first_name}
													last_name={last_name}
													street={address}
													msisdn={mobile}
													green_id_overall_status={green_id_overall_status}
													green_id_status={green_id_status}
													send_email={send_email}
													user_green_id={user_green_id}
												/>
											</StyledUserAccountPage__Wrapper>
										);
									case USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header
														size={containerSize}
														type={HeaderBar.types.PRIMARY}
														aside={<BrandContactPhone />}
													>
														<Text className={StyledHeaderText}>	{t('UserAccountPage__VerifyCC')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<StyledUserAccountPage__Deposits size={size} renderOnPage verifyCreditCardsOnly />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS:
									case USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS:
										return (
											<EventContext.Consumer>
												{(eventContext) => (
													<StyledUserAccountPage__Transactions
														size={size}
														parentSize={containerSize}
														transactionType={this.state.page}
														setTransactionFilter={this.setTransactionFilter}
														filter={filter}
														eventContext={eventContext}
														showHeader={showHeaders}
													/>
												)}
											</EventContext.Consumer>
										);

									case USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS:
										return (
											<StyledUserAccountPage__Withdrawals
												parentSize={showHeaders ? containerSize : null}
												verifyMessage={t('WithdrawalPromptContainer__VerifyMessage')}
											/>
										);

									case USER_ACCOUNT_SUBMENU_WITHDRAWALS:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__CancelWithdrawal')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<StyledUserAccountPage__CancelWithdrawals size={size} />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_RESET_PASSWORD:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__ResetPassword')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<StyledUserAccountPage__UpdatePassword size={size} />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__DefaultBetAmounts')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<DefaultBetAmountsContainer />
											</StyledUserAccountPage__Wrapper>
										);


									case USER_ACCOUNT_SUBMENU_TAKE_A_BREAK:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__TakeABreak')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<TakeABreakContainer />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_SELF_EXCLUSION:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__SelfExclusion')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<SelfExclusionContainer />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__SetDepositLimits')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<DepositLimitsContainer />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_LOSS_LIMITS:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={css`font-size:15px;`}>{t('UserAccountPage__SetLossLimits')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<LossLimitsContainer />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_SPEND_LIMIT:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__SetSpendLimits')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<SpendLimitsContainer />
											</StyledUserAccountPage__Wrapper>
										);

									case USER_ACCOUNT_SUBMENU_REALITY_CHECK:
										return (
											<StyledUserAccountPage__Wrapper size={size}>
												{showHeaders && (
													<StyledUserAccountPage__Header size={containerSize} type={HeaderBar.types.PRIMARY}>
														<Text className={StyledHeaderText}>{t('UserAccountPage__ManageAccount')}</Text>
													</StyledUserAccountPage__Header>
												)}

												<RealityCheckContainer />
											</StyledUserAccountPage__Wrapper>
										);
								}
							}}
						</SizeMe>
					</StyledUserAccountPage>
				)}
			</SizeMe>
		);
	}
}

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};

	return {
		authenticatedUser: Boolean(authenticatedUser),
		selectedMenuItem: state.userAccount.selectedMenuItem,
		menuItems: getUserAccountMenuItems(state),

		// Account Profile
		address: user.street,
		country: user.country,
		dob: user.dob,
		email: user.email,
		first_name: user.first_name,
		last_name: user.last_name,
		mobile: user.msisdn,
		postcode: user.postcode,
		state: user.state,
		suburb: user.city,
		username: user.username,
		userIsVerified: user.verified,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		green_id_overall_status: user.green_id_overall_status,
		green_id_status: user.green_id_status,
		user_green_id: user.green_id_trans_id,
		send_email: user.send_email,
		// Transactions
		filter: state.userAccount.transactionsFilter,

		// Deposits
		showDepositReceipt: state[DEPOSIT_USER_ACCOUNT].lastDepositId && !state[DEPOSIT_USER_ACCOUNT].depositLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetUserAccount: () => {
			return dispatch(resetUserAccount());
		},
		setTransactionFilter: (filter) => {
			return dispatch(setTransactionFilter(filter));
		},
		closeUserAccountPage: () => {
			return dispatch(routeTo('/welcome'));
		},
		onCompleteVerification: () => {
			dispatch(trackGaEvent('MyAccount', 'Click', 'Complete Verification'));
			return dispatch(launchLiveChat());
		},
		setSelectedMenuItem: (item) => dispatch(setSelectedMenuItem(item)),
	};
};

export default withNamespaces()(
	createErrorBoundary(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(UserAccountPage),
	),
);
