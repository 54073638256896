import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PusherSubscribe extends Component {
	static propTypes = {
		channel: PropTypes.string.isRequired,
		onUpdate: PropTypes.func.isRequired,
		event: PropTypes.string.isRequired,
	};

	componentDidMount() {
		if (!App.pusher) {
			throw new Error('You must set a pusherClient by calling setPusherClient');
		}

		this.bindPusherEvent(this.props.channel, this.props.event);
	}

	/**
	 * Re-subscribe when the channel changes
	 *
	 * @param prevProps
	 */
	componentDidUpdate(prevProps) {
		const { channel, event } = prevProps;
		const { channel: newChannel, event: newEvent } = this.props;
		if (channel === newChannel && event === newEvent) {
			return;
		}

		this.unbindPusherEvent(channel, event);
		this.bindPusherEvent(newChannel, newEvent);
	}

	/**
	 * Un-subscribe when the component un-mounts
	 */
	componentWillUnmount() {
		this.unbindPusherEvent(this.props.channel, this.props.event);
	}

	/**
	 * Handle the update of data
	 */
	handleUpdate = (data) => {
		if (!App.push) {
			App.warn('push blocked');
			return;
		}
		this.props.onUpdate(data);
	};

	/**
	 * Subscribe to pusher event
	 *
	 * @param channelName
	 * @param event
	 */
	bindPusherEvent = (channelName, event) => {
		let channel = App.pusher.channels.find(channelName);
		if (!channel) {
			channel = App.pusher.subscribe(channelName);
		}
		channel.bind(event, this.handleUpdate);
	};

	/**
	 * Unbind pusher event
	 *
	 * @param channelName
	 * @param event
	 */
	unbindPusherEvent = (channelName, event) => {
		const channel = App.pusher.channels.find(channelName);
		if (!channel) {
			return;
		}

		channel.unbind(event, this.handleUpdate);

		if (!Object.keys(channel.callbacks._callbacks).length) {
			App.pusher.unsubscribe(channelName);
		}
	};

	render() {
		return <noscript />;
	}
}

export default PusherSubscribe;
