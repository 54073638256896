/**
 * Filter Events based on selection
 *
 * @param {Array} events
 * @param {Number} competitionId
 * @param {Number} eventId
 * @param ids
 * @returns {*}
 */
export const getEventsSortedByStartDate = (events = [], competitionId = null, eventId = null, ids = null) => {
	return sortEventsByStartDate(filterSelectedEvents(events, competitionId, eventId, ids));
};

/**
 * Filter events given competition id, event id or list of event Ids
 *
 * @param {Array} events
 * @param {Number} competitionId
 * @param {Number|Array} eventIds
 * @returns {Array}
 */
export const filterSelectedEvents = (events = [], competitionId = null, eventIds = null) => {
	if (!(events instanceof Array)) throw new Error('Oops! I need an Array!');
	/**
	 * Short way for exceptions.
	 * If no selection is passed, component is probably trying to render Sports home.
	 * If array is empty, return.
	 */
	if (!competitionId && !eventIds) return events;

	if (typeof eventIds === 'number') {
		eventIds = [eventIds];
	}

	if (Array.isArray(eventIds) && eventIds.length) {
		return events.filter((event) => eventIds.includes(event.id.toString()) || eventIds.includes(event.id));
	} else {
		return events.filter((event) => event.competition_id === competitionId);
	}
};

/**
 * Sort Events by date field named start_date.
 *
 * @param {Array} events
 */
export const sortEventsByStartDate = (events = []) => {
	if (!(events instanceof Array)) throw new Error('Oops! I need an Array!');

	return events.sort((a, b) => {
		return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
	});
};

/**
 * Find a single event in a list of events
 *
 * @param events
 * @param eventId
 * @returns {*}
 */
export const getEventById = (events = [], eventId) => {
	const event = events.find(function(event) {
		return event.id === eventId;
	});

	if (!event) {
		throw new Error(`Event ID [${eventId}] could not be found.`);
	}

	return event;
};


/**
 * Find a single  event in a list of next to jump events
 * @HW 28Feb2020
 * @param events
 * @param eventId
 * @returns {*}
 */
export const getNextToJumpEventById = (events = [], eventId) => {
	const event = events.find(function(event) {
		return event.id === eventId;
	});

	if (!event) {
		throw new Error(`Event ID [${eventId}] could not be found.`);
	}

	return event;
};
