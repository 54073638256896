import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import UpRaceSelectionsListItem from './UpRaceSelectionsListItem/UpRaceSelectionsListItem';
import {
	StyledUpRaceSelectionsList,
	StyledUpRaceSelectionsList__HeaderFlucs,
	StyledUpRaceSelectionsList__RaceSelectionsListItems,
} from './UpRaceSelectionsList.styled';

class UpRaceSelectionsList extends Component {
	static initialState = (props = {}) => ({
		/** Props used for checking updates */
		raceId: props.raceId,
		betType: props.betType,
	});

	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}),

		/** If race changes clear state. */
		raceId: PropTypes.number.isRequired,

		/** Array of objects that contain all the selection details */
		selections: PropTypes.arrayOf(
			PropTypes.shape({
				/**
				 * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
				 * They are defined in the RaceSelectionsListItem
				 */
				id: PropTypes.number.isRequired,

				/** The list of prices for the selection */

				prices: PropTypes.arrayOf(
					PropTypes.shape({
						/** The current price for a win bet with this product */
						win_odds: PropTypes.number,

						/** The current price for a place bet */
						place_odds: PropTypes.number,

						/** The current price for a margin bet */
						margin_odds: PropTypes.number,

						/** The code for this product with this product */
						product_code: PropTypes.string.isRequired,

						/** The product id (NOTE: this is not a unique id between products) */
						product_id: PropTypes.number.isRequired,
					}),
				),
			}),
		).isRequired,

		/**
		 * This will be an array of bet products that will be displayed for each selection
		 * The order of display will be from left to right as given in the array.
		 */
		displayedBetProducts: PropTypes.arrayOf(
			PropTypes.shape({
				/** The bet type for the product */
				bet_type: PropTypes.string.isRequired,

				/** The product ID of the bet product */
				product_id: PropTypes.number.isRequired,

				/** The product code of the bet product */
				product_code: PropTypes.string.isRequired,

				/** The product image */
				product_image: PropTypes.string,

				/** The type of product if it is a special product, eg. butt */
				product_type: PropTypes.string,

				/** If the product is available for betting */
				available: PropTypes.bool,
			}),
		).isRequired,

		/** Action to add the selection to the single bet prompt */
		clickSingle: PropTypes.func.isRequired,

		/** Action for handling adding the bet to the multi bet slip */
		clickMulti: PropTypes.func.isRequired,

		/** Boolean indicating if betting is available ie. race isn't closed */
		bettingAvailable: PropTypes.bool.isRequired,

		/** Whether or not the component is being viewed in small context */
		isMobileWidth: PropTypes.bool,

		/** The currently selected bet type */
		betType: PropTypes.string,

		/** If the currently selected bet type is boxed */
		boxed: PropTypes.bool,
		/** Which fluctuations to display, or don't display if null */
		displayedFlucs: PropTypes.oneOf([null, 'apn_fluctuations', 'topbetta_fluctuations']),

		winPlaceProducts: PropTypes.array,
	};
	static defaultProps = {
		isMobileWidth: false,
		displayedFlucs: null,
		betType: '',
		boxed: false,
		className: null,
		winPlaceProducts: [],
		size: null,
	};

	constructor(props) {
		super(props);

		this.state = UpRaceSelectionsList.initialState(props);
	}

	/**
	 * Handles the single bet clicks
	 *
	 * @param selectionId
	 * @param productId
	 * @param betType
	 */
	handleSingle = (selectionId, productId, betType) => {
		this.props.clickSingle(selectionId, productId, betType);
	};

	/**
	 * Handles the multi bet clicks
	 *
	 * @param {number} selectionId
	 * @param {number} productId
	 * @param {string} betType either 'win' or 'place'
	 */
	handleMulti = (selectionId, productId, betType) => {
		
		this.props.clickMulti(selectionId, productId, betType);
	};

	render() {
		const {
			t,
			className,
			displayedBetProducts,
			selections,
			bettingAvailable,
			betType,
			buildSelectionBetButtons,
			isMobileWidth,
			size,
			boxed,
			displayedFlucs,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<div
				className={css`
					margin-top: -10px;
				`}
			>
				{/* {selections.length > 0 &&
		<Text className={css`color:#c72128;margin-top: 15px;text-transform: uppercase;padding-left:10px;`} size="-3" >Top Selections</Text>
       } */}
				<StyledUpRaceSelectionsList className={componentClasses}>
					<StyledUpRaceSelectionsList__RaceSelectionsListItems center compact>
						{displayedFlucs ? (
							<StyledUpRaceSelectionsList__HeaderFlucs size={size}>
								{t('RaceSelectionsList__Flucs')}
							</StyledUpRaceSelectionsList__HeaderFlucs>
						) : null}
						{selections &&
							selections.map((topselection) => (
								<UpRaceSelectionsListItem
									key={topselection.id}
									{...topselection}
									addToSingle={this.handleSingle}
									addToMulti={this.handleMulti}
									selectionBetButtons={buildSelectionBetButtons(topselection.prices, displayedBetProducts, betType)}
									bettingAvailable={bettingAvailable}
									displayedFlucs={displayedFlucs}
									//isMobileWidth={isMobileWidth}
									betType={betType}
									size={size}
									boxed={boxed}
								/>
							))}
					</StyledUpRaceSelectionsList__RaceSelectionsListItems>
				</StyledUpRaceSelectionsList>
			</div>
		);
	}
}

export default withNamespaces()(UpRaceSelectionsList);
