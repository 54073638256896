import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import {countdown, countdownLessThan2Min} from '../../../../legacy/core/format';
import CarouselItemBetBuilder from '../../Carousel/CarouselItemBetBuilder/CarouselItemBetBuilder'; // @HW newly added

import {
	RACING_TYPES_LOOKUP,
} from '../../../../common/constants/Racing';

// eslint-disable-next-line react/prop-types
const BetBuilderCarouselItem = ({
	t, id, raceNumber, meetingName, startTime, type, action, className , raceName,  status,
	raceDistance, start_datetime }) => {

	return (
		<CarouselItemBetBuilder
			border
			id={id}
			label={`${t('Race')} ${raceNumber}`}
			text={meetingName}
			raceText={raceName} 
			meta={`${countdown(startTime)}`}
			lessThan2Min={countdownLessThan2Min(startTime)}
			icon={RACING_TYPES_LOOKUP[type]}
			action={action}
			className={className}
			distance={raceDistance}
			start_datetime={start_datetime}
			raceNumber={raceNumber}
			status={status}
		/>
	);
};

BetBuilderCarouselItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	/** The race ID */
	id: PropTypes.number.isRequired,

	/** The race number */
	raceNumber: PropTypes.number.isRequired,

	/** The name of the meeting that the race is part of */
	meetingName: PropTypes.string,

	/** The start time of the race */
	startTime: PropTypes.string.isRequired,

	/** The race type */
	type: PropTypes.string.isRequired,

	/** Action to perform when the race item is clicked on */
	action: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,

	raceName: PropTypes.string.isRequired,


	raceDistance: PropTypes.string,


  	start_datetime: PropTypes.string,

	raceText: PropTypes.string,

	status: PropTypes.string,

};

BetBuilderCarouselItem.defaultProps = {
	className: '',
	raceText: '',
	size: null,
	raceDistance: 0,
	country:  null,
	start_datetime: null,
	meetingName: '',
	action: undefined,
	status: '',
};
export default withNamespaces()(BetBuilderCarouselItem);
