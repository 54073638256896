import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

import Format from '../../../legacy/core/format';

import { launchLiveChat } from '../../../store/application/applicationActions';
import { createErrorBoundary } from '../../ErrorBoundary/ErrorBoundaryActions';

/**
 * Functions & Actions
 */
import { isFeatureEnabled } from '../../Application/Context/contextMemoizedSelectors';
import { calculatePossiblePayout, formatOddsAsPrice, isExotic, isRacing } from '../../../common/BetPlacement';
import {
	clearBetPromptErrors,
	setBetPromptErrors,
	updateBetPromptDetails,
	resetBetPromptTitle,
	formatAndAddSingleSelectionToBetPrompt,
	formatAndAddSportSelectionToBetPrompt,
} from '../../../store/betPrompt/betPromptActions';
import { formatAndAddRacingMultiBet, addSportSelectionToBetSlip } from '../../../common/actions/multiActions';
import { fetchCreditCards } from '../../../store/entities/actions/CreditCardActions';
import { getVerifiedCreditCardsOnly } from '../../../store/deposits/depositMemoizedSelectors';
import {
	getBetsWithId,
	getSportSelectionsForPrompt,
	getOddsForSelection,
	getSingleRaceSelectionsForPrompt,
	getTournamentBetsWithId,
	getDerivativeMarketInPrompt,
	getDerivativeSelectionsInPrompt,
} from '../bettingMemoizedSelectors';
import { getRacingProductIdAndBetType } from './betPromptContainerActions';
import * as singleBetPlacementActions from '../../../store/betPlacement/singleBetPlacementActions';
import {
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_EACHWAY_SHORT,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_PLACE_SHORT,
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_WIN_SHORT,
	RACING_BET_TYPE_MARGIN,
	RACING_WIN_PLACE_BET_TYPES,
	RACING_BET_TYPE_SHORT_LOOKUP,
	RACING_TOTE_NAME,
  RACING_ODDS_GIDS,
} from '../../../common/constants/Racing';
import {
	BET_PENDING_STATUS,
	BET_REJECTED_STATUS,
	BET_CANCELLED_STATUS,
	BET_PROCESSING_STATUS,
	BET_UNRESULTED_STATUS,
	BET_FULL_REFUND_STATUS,
} from '../../../common/constants/Bets';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../common/constants/GoatProducts';
import {
	BET_TYPE_RACE,
	BET_TYPE_EXOTIC,
	BET_TYPE_SPORT,
	BET_TYPE_STANDARD,
	BET_TYPE_DERIVATIVE,
	DEFAULT_BET_PROMPT_TITLE,
	BET_TYPE_WIN,
	PRODUCT_TYPE_STANDARD,
} from '../../../store/entities/constants/BetConstants';
import {
	getExoticSelectionType,
	getExoticSelectionsFromBetPrompt,
	getRacingBetTypeLabel,
	getBetSelectionDerivativeLabel,
	getBetSelectionDerivativeTitle,
	getSportBetLabel,
} from '../../../store/entities/selectors/BetSelectors';
import {
	fetchRollTablePrice,
	fetchFixedPriceRollup,
	getAuthenticatedUser,
	isTournamentPage,
} from '../../../store/application/applicationSelectors';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import {
	betPromptRaceId,
	buildBetsForRace,
	getDenormalizedMeeting,
} from '../../../pages/Racing/RacingHome/racingSelectorsGRS';
import { getBets } from '../../../pages/Sports/SportsHome/sportsHomeSelectors';
import { triggerEventMessage } from '../../../common/actions/widgetActions';
import { TITLE_BET_RECEIPT, TITLE_QUICK_DEPOSIT } from '../../../common/constants/Application';

/**
 * Components
 */
import { LoadingMask, Notification, PlotElements } from '@tbh/ui-kit';
import BetPlacementContainer from './BetPlacementContainer';
import BetReceipt from '../../../components/features/Betting/Receipt/BetReceipt/BetReceipt';
import BetPendingReview from '../../../components/features/Betting/BetPendingReview/BetPendingReview';
import CurrencyDisplay from '../../../components/features/Application/Currency/CurrencyDisplay/CurrencyDisplay';
import CurrencyNameDisplay from '../../../components/features/Application/Currency/CurrencyNameDisplay/CurrencyNameDisplay';

/**
 * Styling
 */
const StyledBetPromptContainer__BetReceipt = styled(BetReceipt)(
	(props) => css`
		label: BetPromptContainer__BetReceipt;

		padding-top: ${spacings(props).cozy}px;
	`,
);

const StyledBetPromptContainer__BetPendingReview = styled(BetPendingReview)(
	(props) => css`
		label: BetPromptContainer__BetPendingReview;

		padding: ${spacings(props).cozy}px;
	`,
);

export class BetPromptContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Cancel out of the bet slip */
		handleCancel: PropTypes.func.isRequired,

		/** Confirm the bet */
		handleConfirm: PropTypes.func.isRequired,

		/** Initiate a rebet from the Receipt */
		handleReBet: PropTypes.func.isRequired,

		/** Fetch the list of credit cards for the user */
		fetchCreditCards: PropTypes.func.isRequired,

		/** The bet selections for the bet prompt (should only contain one selection) */
		selections: PropTypes.array.isRequired,

		/** Function to set an error */
		setErrorMessage: PropTypes.func.isRequired,

		/** Handler for adding the selection to the multi slip if it is Racing */
		formatAndAddRacingMultiBet: PropTypes.func.isRequired,

		/** Handler for adding the selection to the multi slip if it is Sport */
		addSportSelectionToMulti: PropTypes.func.isRequired,

		/** Fetch the rolled up price from state, based on the base price and the number of rolls */
		fetchFixedPriceRollup: PropTypes.func.isRequired,

		/** Function for determining the bet label for a racing selection */
		getRacingBetTypeLabel: PropTypes.func.isRequired,

		/** Function for determining the bet label for a derivative selection */
		getBetSelectionDerivativeLabel: PropTypes.func.isRequired,

		/** Function for determining the bet title for a derivative selection */
		getBetSelectionDerivativeTitle: PropTypes.func.isRequired,

		/** Function for determining the correct product ID and bet type to use for a racing selection */
		getRacingProductIdAndBetType: PropTypes.func.isRequired,

		/** Flag whether or not we must have a confirmation stage for bet placement */
		confirmBetPlacement: PropTypes.bool.isRequired,

		/**	Limit the user bet amount to their balance */
		limitUserBetAmount: PropTypes.bool.isRequired,

		/** Function for determining the bet label for a sport selection */
		getSportBetLabel: PropTypes.func.isRequired,

		/** Reset the bet prompt title back to it's default value */
		resetBetPromptTitle: PropTypes.func.isRequired,

		/** Set the bet prompt title to a specified value */
		setBetPromptTitle: PropTypes.func.isRequired,

		/** Used to reset the racing selection for the bet prompt */
		setRacingSelection: PropTypes.func.isRequired,

		/** Used to reset the sport selection for the bet prompt */
		setSportSelection: PropTypes.func.isRequired,

		/** Get the matching fixed price from the roll table */
		fetchRollTablePrice: PropTypes.func.isRequired,

		/** A list of bets related to the current race or sport selection */
		relatedBets: PropTypes.array.isRequired,

		/** Is an exotic selection */
		isExotic: PropTypes.func.isRequired,

		/** Is a race selection */
		isRacing: PropTypes.func.isRequired,

    amount: PropTypes.string,

		/** The title of the bet prompt */
		title: PropTypes.string.isRequired,

		/** Whether the application is running in single wallet mode (and this isn't a tournament bet) or not */
		isSingleWallet: PropTypes.bool.isRequired,

		/** Context name for prefixing custom events to be fired through middleware */
		eventContext: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

		/** Allow for navigation actions */
		useRouting: PropTypes.bool,

		/** The type of selection that is present in the bet prompt */
		selectionType: PropTypes.oneOf([BET_TYPE_RACE, BET_TYPE_EXOTIC, BET_TYPE_DERIVATIVE, BET_TYPE_SPORT]).isRequired,

		/** List of amount buttons - generally handled by this.getAmountButtons() */
		amountButtons: PropTypes.array,

		/** Whether to auto-accept a change in odds or not (generally not used with this container) */
		autoAccept: PropTypes.bool,

		/** Bet successfully place */
		betConfirmed: PropTypes.bool,

		/** All the ID's of the bets placed */
		betsPlaced: PropTypes.array,

		/** All the bets placed */
		bets: PropTypes.array,

		/** Receipt/Bet ID if the bet was successfully placed */
		betId: PropTypes.number,

		/** Odds for the final bet place */
		betOdds: PropTypes.number,

		/** The status of the bet placed */
		betStatus: PropTypes.string,

		/** If the bet was fully refunded */
		betFullyRefunded: PropTypes.bool,

		/** If the bet was referred */
		betReferred: PropTypes.bool,

		/** If the bet was rejected */
		betRejected: PropTypes.bool,

		/** Callback after bet placement (executes from the finally callback in the promise) */
		betPlaced: PropTypes.func,

		/** Fantasy dollars for tournaments */
		bettaBucks: PropTypes.number,

		/** Disable bonus bets completely */
		bonusBets: PropTypes.bool,

		/** Whether or not we are using tournament dollars */
		useBettaBucks: PropTypes.bool,

		/** Cost of entry for the tournament */
		entryFee: PropTypes.number,

		/** Extra classes */
		className: PropTypes.string,

		/** Reset for the singleBetPlacement slice */
		clearBetPlacement: PropTypes.func,

		/** Clears any error message */
		clearErrorMessage: PropTypes.func,

		/** Hides the add to multi button */
		disableAddToMulti: PropTypes.bool,

		/** The derivative market, if a derivative selection is loaded */
		derivativeMarket: PropTypes.object,

    back1_liability: PropTypes.number,

    back2_liability: PropTypes.number,

    back3_liability: PropTypes.number,

		/** Any error message, or list of error messages */
		errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),

		/** Enhanced details for an exotic bet */
		exoticDetails: PropTypes.shape({
			id: PropTypes.string,
			isBoxed: PropTypes.bool,
			race: PropTypes.string,
			title: PropTypes.string,
		}),

		/** The current meeting if it is a racing bet - used for multiSlip dispatcher */
		meeting: PropTypes.shape({
			country: PropTypes.string,
			type: PropTypes.string,
			name: PropTypes.string,
			id: PropTypes.number,
		}),

		/** The current race if it is a racing bet - used for multiSlip dispatcher */
		race: PropTypes.object,

		/** Selector for determining all possible odds for a selection */
		allOddsForSelection: PropTypes.object,

		/** Open the Quick Deposit window */
		handleQuickDeposit: PropTypes.func,

		/** Display the loading mask */
		isLoading: PropTypes.bool,

		/** The racing bet type ('win', 'place', 'eachway') */
		racingBetType: PropTypes.oneOf([
			'',
			RACING_BET_TYPE_WIN,
			RACING_BET_TYPE_PLACE,
			RACING_BET_TYPE_EACHWAY,
			RACING_BET_TYPE_MARGIN,
		]),

		/** List of possible racing bet types and their shorthand value */
		racingBetTypes: PropTypes.array,

		/** Whether or not we should display the Quick Deposit button */
		showQuickDeposit: PropTypes.bool,

		/** The user's bet */
		stake: PropTypes.number,

		/** The default bet amount, set from the store */
		defaultBetAmount: PropTypes.number,

		/** Tracking helper */
		track: PropTypes.func,

		/** Whether or not the user has elected to use their Bonus Bets */
		useBonusBets: PropTypes.bool,

		/** Reason for the bet being rejected */
		betRejectedReason: PropTypes.string,

		/** Comment in regards to the bet being rejected */
		betRejectedComment: PropTypes.string,

		/** The currently logged in User */
		user: PropTypes.shape({
			/** The ID of the user */
			id: PropTypes.number,

			/** Bonus bet allowance */
			free_credit_balance: PropTypes.number,

			/** === 'Vip' if they are a VIP user **/
			segmentation: PropTypes.string,
		}),
	};

	static defaultProps = {
		allOddsForSelection: {},
		amountButtons: [], // See getAmountButtons()
		autoAccept: false,
		betStatus: '',
		betFullyRefunded: false,
		betConfirmed: false,
		betsPlaced: [],
		bets: [],
		betId: null,
		betOdds: null,
		betReferred: false,
		betRejected: false,
		betRejectedReason: '',
		betRejectedComment: '',
		bettaBucks: null,
		betPlaced: () => {},
		bonusBets: false,
		useBettaBucks: false,
		entryFee: null,
		eventContext: '',
		className: '',
		clearBetPlacement: () => {},
		clearErrorMessage: () => {},
		disableAddToMulti: false,
		errorMessage: '',
		exoticDetails: {
			id: '',
			isBoxed: true,
			race: '',
			title: '',
		},
		meeting: null,
		race: null,
		derivativeMarket: null,
		handleCancel: () => {},
		handleQuickDeposit: () => {},
		isLoading: false,
		racingBetType: '',
		racingBetTypes: [
			{
				text: RACING_BET_TYPE_WIN_SHORT,
				value: RACING_BET_TYPE_WIN,
			},
			{
				text: RACING_BET_TYPE_PLACE_SHORT,
				value: RACING_BET_TYPE_PLACE,
			},
			{
				text: RACING_BET_TYPE_EACHWAY_SHORT,
				value: RACING_BET_TYPE_EACHWAY,
			},
		],
		useRouting: true,
		showQuickDeposit: false,
		stake: 0,
		defaultBetAmount: 0,
		track: () => {},
		useBonusBets: false,
		user: null,
	};

	constructor(props) {
		super(props);

		// Exit early if there are no selections
		if (!props.selections || props.selections.length === 0) {
			props.clearBetPlacement();
			props.handleCancel();
		}

		if (props.title !== DEFAULT_BET_PROMPT_TITLE) {
			props.resetBetPromptTitle();
		}

		if (!props.useBettaBucks && props.user) {
			props.fetchCreditCards();
		}

		// State
		this.state = this.buildInitialState(props);
	}

	componentDidUpdate(prevProps) {
		// Clear any errors when the bet goes to the next stage
		if (this.props.betId && prevProps.errorMessage) {
			this.props.clearErrorMessage();
		}

		// Update state if the selection or the racing bet type if it changes externally
		const oldSelection = prevProps.selections[0];
		const newSelection = this.props.selections[0];
		if (
			oldSelection &&
			newSelection &&
			(oldSelection.id !== newSelection.id ||
				oldSelection.racingBetType !== newSelection.racingBetType ||
				(oldSelection.product_id &&
					newSelection.product_id &&
					oldSelection.product_id !== newSelection.product_id &&
					oldSelection.product_type !== GOAT_PRODUCT_TYPE_BOOST &&
					newSelection.product_type !== GOAT_PRODUCT_TYPE_BOOST))
		) {
			this.setState(this.buildInitialState(this.props));
		} else {
			// Hide/Show the racing bet types if the products available change
			if (prevProps.racingBetTypes.length !== this.props.racingBetTypes.length) {
				this.setState((prevState, props) => {
					const eachwayDisabled =
						!prevState.isRacing || (prevState.isRacing && !prevState.isExotic && props.racingBetTypes.length < 3);

					return {
						eachwayDisabled,
					};
				});
			}
		}

		/* re-render the isExotic prop to cascade it's value on {selection} length change */
		if (prevProps.selections.length !== this.props.selections.length) {
			return this.setState({ isExotic: isExotic(this.props.selections) });
		}

		if (this.props.user) {
			// If user logged in or component received a new user, run fetch cards.
			if (
				!this.props.useBettaBucks &&
				((this.props.user && !prevProps.user) ||
					(this.props.user && prevProps.user && this.props.user.id !== prevProps.user.id))
			) {
				this.updateSelection();
				this.props.fetchCreditCards();
			}
		}
	}

	buildInitialState = (props) => {
		const selection = props.selections[0];
		const isExotic = props.isExotic(props.selections); // Is an exotic selection
		const isRacing = props.isRacing(props.selections); // Is a race selection
		const isVIP = props.user && props.user.segmentation && props.user.segmentation.toLowerCase() === 'vip'; // The user is a VIP user

		// Tote odds are selected if it is a racing selection and the odds selectionId matches up
		const isToteOdds =
			isRacing &&
			!isExotic &&
			selection.prices &&
			selection[props.racingBetType] &&
			(Array.isArray(selection.prices[`${props.racingBetType}_${RACING_TOTE_NAME}`])
				? selection.prices[`${props.racingBetType}_${RACING_TOTE_NAME}`].includes(
						selection[props.racingBetType].productId,
				  )
				: selection[props.racingBetType].productId === selection.prices[`${props.racingBetType}_${RACING_TOTE_NAME}`]);

		/**
		 * Price is bumped for fixed sports odds with a product_id of type 'boost'
		 * or a fixed race with a product_id of type 'boost'
		 */
		const isPriceBumped =
			!isToteOdds &&
			((!isRacing &&
				selection && selection.event &&
				selection.event.products &&
				selection.event.products.some(
					(product) => product.product_type === GOAT_PRODUCT_TYPE_BOOST && selection.product_id === product.product_id,
				)) ||
				(isRacing &&
					selection[props.racingBetType] &&
					props.race &&
					props.race.products &&
					props.race.products.some(
						(product) =>
							product.product_type === GOAT_PRODUCT_TYPE_BOOST &&
							selection[props.racingBetType].productId === product.product_id,
					)));

		const swapOddsAvailable = isRacing && !isExotic && props.race && props.race.fixed_odds_enabled;
		const eachwayDisabled = !isRacing || (isRacing && !isExotic && props.racingBetTypes.length < 3);
		const racingBetType = isRacing ? props.racingBetType : '';

		return {
			isExotic,
			isRacing,
			isVIP,
			isToteOdds,
			isPriceBumped,
			eachwayDisabled,
			racingBetType,
			swapOddsAvailable,

			// Auto accept new odds - not strictly required anymore
			autoAccept: props.autoAccept,
			oddsChangeText: {
				place: '',
				win: '',
			},
			originalOdds: this.getOdds(),
			useBonusBets: props.useBonusBets,
			selectedGoatProduct: isPriceBumped
				? GOAT_PRODUCT_TYPE_BOOST
				: racingBetType === RACING_BET_TYPE_MARGIN
					? RACING_BET_TYPE_MARGIN
					: null,
			stage: '',
			priceRollups: 0, // Set to 0 initially as we only want to rollup on GOAT price bump
		};
	};

	/**
	 * Clears the error message from the state
	 */
	clearErrorMessage = () => {
		this.setState(
			{
				oddsChangeText: {
					place: '',
					win: '',
				},
			},
			() => this.props.clearErrorMessage(),
		);
	};

	/**
	 * Returns the amount buttons prop, or determines default buttons.
	 *
	 * return {Array}
	 */
	getAmountButtons = () => {
		// If amount buttons are passed in as props, simply return them
		if (this.props.amountButtons.length) {
			return this.props.amountButtons;
		}

		// Determine default buttons based on bettabucks
		if (this.props.bettaBucks !== null) {
			// Half the All In amount if an Eachway bet
			const bettaBucksAmount =
				this.state.racingBetType === RACING_BET_TYPE_EACHWAY ? this.props.bettaBucks / 2 : this.props.bettaBucks;

			return [
				{ amount: '1000' },
				{ amount: '2000' },
				{ amount: '5000' },
				{ amount: '7500' },
				{ amount: '10000' },
				{ amount: '25000' },
				{ amount: '50000' },
				{ amount: '100000' },

				// NOTE: Cast this as a string if we want the same 'amount' button to also highlight
				{ amount: bettaBucksAmount, label: 'All In', standalone: true },
			];
		} else if (this.state.isVIP) {
			//  or if the user is a VIP user
			return [
				{ amount: '5000' },
				{ amount: '10000' },
				{ amount: '15000' },
				{ amount: '20000' },
				{ amount: '25000' },
				{ amount: '50000' },
				{ amount: '100000' },
				{ amount: '250000' },
				{ amount: '500000' },
			];
		}

		return [
			{ amount: '100' },
			{ amount: '200' },
			{ amount: '500' },
			{ amount: '1000' },
			{ amount: '2000' },
			{ amount: '5000' },
			{ amount: '10000' },
			{ amount: '20000' },
			{ amount: '50000' },
		];
	};

	/**
	 * Determine the maximum amount that can be bet on
	 *
	 * return {Number}
	 */
	getAvailableBalance = () => {
		const { bettaBucks, user } = this.props;

		// Half balance if eachway bet
		const multiplier = this.state.racingBetType === RACING_BET_TYPE_EACHWAY && !this.props.betConfirmed ? 0.5 : 1;

		if (bettaBucks !== null) {
			return Math.floor(bettaBucks * multiplier);
		}

		if (user) {
			const betType = this.state.useBonusBets ? 'free_credit' : 'account';
			return Math.floor(user[betType + '_balance'] * multiplier);
		}

		return null;
	};

	/**
	 * Calculates the total odds given all selections.
   * AR Change Odds
   * Push To odds Value Ashan
	 * @returns {*}
	 */
	getOdds = () => {
		const { race, selections, allOddsForSelection, fetchFixedPriceRollup, selectionType } = this.props;

		// Short function for exotic details
		if (this.props.exoticDetails.id) {
			return null;
		}

		// This function will be called before state is defined, so add fallback
		const state = this.state || {};
		const racingBetType = state.racingBetType || this.props.racingBetType;

		// For single race selections, use information on race
		const selection = selections[0];
		if (selectionType === BET_TYPE_DERIVATIVE) {
			return {
				win: selection.price,
			};
		} else if (selection && selection.type === BET_TYPE_RACE && selections.length === 1) {
			const winPlaceRacingBetType = racingBetType === RACING_BET_TYPE_EACHWAY ? RACING_BET_TYPE_WIN : racingBetType;
			const isTote = selection[winPlaceRacingBetType] ? !selection[winPlaceRacingBetType].fixed : state.isToteOdds;
			const odds = {
				place: null,
				win: null,
        oddsgrid: null,
			};

			// If tote is available, then we need to use those prices
			if (race && race.fixed_odds_enabled) {
				if (isTote) {
					if (allOddsForSelection.place_tote.length) {
						const place = allOddsForSelection.place_tote.find((odd) => odd.productId === selection.place.productId);
						odds.place = place ? place.odds : allOddsForSelection.place_tote[0].odds;
					}
					if (allOddsForSelection.win_tote.length) {
						const win = allOddsForSelection.win_tote.find((odd) => odd.productId === selection.win.productId);
						odds.win = win ? win.odds : allOddsForSelection.win_tote[0].odds;
					}
          if (allOddsForSelection.oddsgrid_tote.length) {
            const oddsgrid = allOddsForSelection.win.find((odd) => odd.productId === selection.win.productId);
            odds.oddsgrid = oddsgrid ? oddsgrid.odds : allOddsForSelection.win[0].odds;
          }

				} else {
					if (state.isPriceBumped) {
						// Calculate the boosted price, based off the win fixed price
						const isRacing = true;
						odds.win = fetchFixedPriceRollup(isRacing, allOddsForSelection.win_fixed[0].odds);
					}

					odds.place = allOddsForSelection.place_fixed.length ? allOddsForSelection.place_fixed[0].odds : null;
					if (!odds.win) {
						odds.win = allOddsForSelection.win_fixed.length ? allOddsForSelection.win_fixed[0].odds : null;
					}
				}

				// Used for determining odds changed
				odds.win_fixed = allOddsForSelection.win_fixed.length ? allOddsForSelection.win_fixed[0].odds : odds.win;
				odds.oddsgrid_fixed = allOddsForSelection.oddsgrid_fixed.length ? allOddsForSelection.oddsgrid_fixed[0].odds : odds.win;
				odds.place_fixed = allOddsForSelection.place_fixed.length
					? allOddsForSelection.place_fixed[0].odds
					: odds.place;
			}

			if (!odds.place) {
				odds.place = selection.place.odds || selection.place.code;
			}
			if (!odds.win) {
				odds.win = selection.win.odds || selection.win.code;
			}

      if (!odds.oddsgrid) {
        odds.oddsgrid = selection.win.odds || selection.win.code;
      }

      if(selection.win.liability)
      {
        odds.liability = selection.win.liability || 0;
      }

			if (selection.margin) {
				odds.margin = selection.margin.odds || selection.margin.code;
			}

			if (this.props.betOdds) {
				odds[racingBetType] = this.props.betOdds;
			}

			return odds;

		} else if (selection && selection.type === BET_TYPE_SPORT) {
			const sportOdds = selection.base_odds || selection.odds || selection.price;
			let odds = { win: sportOdds };

			// If the sport selection has a valid product_id
			if (state.isPriceBumped && selection.product_id && selection.product_type === GOAT_PRODUCT_TYPE_BOOST) {
				// Calculate the boosted price, based off the bet price
				const isRacing = false;
				odds.win = fetchFixedPriceRollup(isRacing, sportOdds);
			}

			return odds;
		}

		return selections.reduce(
			(odds, sel) => {
				if (sel.type === 'race') {
					// This accounts for eachway, win, and place racing types
					if (racingBetType !== RACING_BET_TYPE_PLACE) {
						odds.win *= sel.win.odds;
					}
					if (racingBetType !== RACING_BET_TYPE_WIN) {
						odds.place *= sel.place.odds;
					}
				} else {
					odds.win *= this.props.betOdds || sel.odds;
				}
				return odds;
			},
			{ win: 1, place: 1 },
		);
	};

	/**
	 * Performed when the 'Swap Odds' action is pressed
	 */
	handleSwapOdds = () => {
		this.props.track('Click', 'Swap Odds');

		this.setState(
			{
				isToteOdds: !this.state.isToteOdds,
				isPriceBumped: false,
				selectedGoatProduct: this.state.selectedGoatProduct === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_MARGIN : null,
			},
			() => this.updateSelection(),
		);
	};

	/**
	 * Reset/Re-add the selection to the bet prompt to update it
	 */
	updateSelection = (state) => {
		if (this.state.isRacing) {
			this.setRacingSelection(state);
		} else {
			this.setSportSelection(state);
		}
	};

	/**
	 * Set the racing selection
	 *
	 * @param state
	 */
	setRacingSelection = (state) => {
		// Using an optional newState cache can stop unnecessary renders
		const { isToteOdds, isExotic, selectedGoatProduct } = state || this.state;

		if (isExotic) {
			return;
		}

		const { meeting, race, selections, setRacingSelection, getRacingProductIdAndBetType } = this.props;
		const selection = selections[0];
		selection.racingBetType = state ? state.racingBetType : this.state.racingBetType;

		const { productId, racingBetType } = getRacingProductIdAndBetType(selection, race, isToteOdds, selectedGoatProduct);

		// Reset the racing selection in the store with the new productId and racingBetType
		setRacingSelection(selection.id, race.id, meeting.id, productId, racingBetType, !isToteOdds);
	};

	/**
	 * Set the sport selection
	 *
	 * @param state
	 */
	setSportSelection = (state) => {
		// Using an optional newState cache can stop unnecessary renders
		const { isPriceBumped, isRacing } = state || this.state;
		const { selections, setSportSelection, fetchFixedPriceRollup } = this.props;
		const selection = selections[0];

		const selectionOdds = selection.base_odds || selection.odds;
		const newOdds = isPriceBumped ? fetchFixedPriceRollup(isRacing, selectionOdds) : selectionOdds;

		let productId = BET_TYPE_STANDARD;
		let productType = BET_TYPE_STANDARD;
		if (selection.event && selection.event.products) {
			const eventProduct = selection.event.products.find(
				(product) =>
					isPriceBumped ? product.product_type === GOAT_PRODUCT_TYPE_BOOST : product.product_type === BET_TYPE_STANDARD,
			);
			if (eventProduct) {
				productId = eventProduct.product_id;
				productType = eventProduct.product_type;
			}
		}

		if (selection.market_id) {
			setSportSelection(selection.id, selection.market_id, newOdds, productId, productType);
		}
	};

	/**
	 * Submits the required data to place a bet
	 */
	onConfirm = (value) => {
		if (!this.props.confirmBetPlacement || this.state.stage === 'confirm') {
			this.props.track('Click', 'Place Bet');
			this.props
				.handleConfirm(
					this.props.selectionType,
					value,
					this.state.useBonusBets,
					this.state.racingBetType,
					this.props.exoticDetails,
				)
				// .then(() => this.props.setBetPromptTitle((t'test title')))
				.then(() => this.props.setBetPromptTitle(TITLE_BET_RECEIPT))
				.finally(() => this.props.betPlaced());
		} else {
			this.props.track('Click', 'Confirm Bet');
			this.setStage('confirm');
		}
	};

	/**
	 * Set the stage that the betting process is in for the user
	 *
	 * @param stage
	 */
	setStage = (stage = '') => {
		this.setState({ stage });
	};

	/**
	 * Sets the use bonus bets flag
	 */
	setBonusBet = (value) => {
		this.setState({
			useBonusBets: value,
			stage: '',
		});
	};

	/**
	 * Sets the odds change text.
	 *
	 * @param placeText
	 * @param winText
	 */
	setOddsChangeText = (placeText, winText) => {
		this.setState({
			oddsChangeText: {
				place: placeText || this.state.oddsChangeText.place,
				win: winText || this.state.oddsChangeText.win,
			},
		});
	};

	/**
	 * Sets the selected racing bet type flag
	 */
	setRacingBetType = (value) => {
		let type = Format.forHumans(value);
		// If the first selection is fixed, add that to type
		if (type !== 'Eachway' && this.props.selections[0].is_fixed) {
			type += ` ${this.props.t('fixed')}`;
		}
		// Track the bet type
		this.props.track('Select', `Bet Type - ${type}`);

		const newState = {
			stage: '',
			priceRollups: 0,
			racingBetType: value,
			isPriceBumped: value === RACING_BET_TYPE_PLACE ? false : this.state.isPriceBumped,
			selectedGoatProduct:
				this.state.selectedGoatProduct === RACING_BET_TYPE_MARGIN || value === RACING_BET_TYPE_PLACE
					? null
					: this.state.selectedGoatProduct,
			isToteOdds: this.state.isToteOdds,
		};

		this.setState(newState);
		this.updateSelection(newState);
	};

	/**
	 * Handles when the multi button is clicked on and determines what type of multi to add
	 *
	 * @param isRacing
	 * @param selection
	 * @param racingBetType
   * @param amount
   * @param back1_liability
   * @param back2_liability
   * @param back3_liability
   * @param back4_liability
   * AR
	 */
	handleAddToMulti = (isRacing, selection, racingBetType, amount, back1_liability, back2_liability, back3_liability, back4_liability) => {
		if (isRacing) {
			this.addRacingToMulti(selection.id, racingBetType === RACING_ODDS_GIDS ? selection['win'].productId : selection[racingBetType].productId, racingBetType, amount, selection.back1_liability, selection.back2_liability, selection.back3_liability, selection.back4_liability, selection.total_liability);
		} else {
			this.addSportToMulti(selection);
		}
	};

	/**
	 * Dispatches and event to add a racing multi and close the bet prompt
	 * Betting Added To BetSlip Ashan
	 * @param selectionId
	 * @param productId
	 * @param betType
   * @param amount
   * @param back1_liability
   * @param back2_liability
   * @param back3_liability
   * @param back4_liability
   * @param totel_liability
	 */
	addRacingToMulti = (selectionId, productId, betType, amount, back1_liability, back2_liability, back3_liability, back4_liability, totel_liability) => {

		const { meeting, race, eventContext } = this.props;

    /**
     * Back 1 Product Added
     */


		if (race && meeting && race.id && meeting.id && productId === 19) {
      if(amount / 100 <= back1_liability)
      {
        this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, amount).then(() => {
          triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        });
        this.props.handleCancel();
      }
      else {
        if(totel_liability === amount / 100){
          this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, back1_liability * 100).then(() => {
            triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
          });
          this.props.handleCancel();

          if(back2_liability !== 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back2_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back3_liability !== 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back3_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back4_liability !== 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, back4_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
        } else{
          //place back 1
          this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, back1_liability * 100).then(() => {
            triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
          });
          this.props.handleCancel();

          //if back 2 !== 0 and back 3 !== 0 place bets
          if(back2_liability !== 0 && back3_liability !== 0)
          {
            const back_2_place_amount = (amount / 100 - back1_liability) > back2_liability ? back2_liability * 100 : (amount / 100 - back1_liability) * 100 ;
            const win1_back_2_amount = (amount / 100 - (back1_liability + back2_liability));
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back_2_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();

            if(win1_back_2_amount > 0)
            {
              const back_3_amount = (win1_back_2_amount / 100 - back2_liability) > back3_liability ? back3_liability * 100 : (amount - (back2_liability * 100 + back1_liability * 100));
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back_3_amount).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          }
          //if Win 4 Active place bets
          if(back2_liability === 0 || back3_liability === 0 && back4_liability !== 0)
          {
            if(back2_liability === 0 && back4_liability !== 0){
              const win1_back_3_place_amount = (amount / 100 - back1_liability) > back3_liability ? back3_liability * 100 : (amount / 100 - back1_liability) * 100 ;
              const win_1_back3_amount = (amount / 100 - (back1_liability + back3_liability));
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, win1_back_3_place_amount).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
              if(win_1_back3_amount > 0){
                this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, win_1_back3_amount * 100).then(() => {
                  triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
                });
                this.props.handleCancel();
              }
            }
            if(back3_liability === 0 && back4_liability !== 0){
              const win1_back_2_place_amount = (amount / 100 - back1_liability) > back2_liability ? back2_liability * 100 : (amount / 100 - back1_liability) * 100 ;
              const win_1_back2_amount = (amount / 100 - (back1_liability + back2_liability));
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, win1_back_2_place_amount).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();

              if(win_1_back2_amount > 0){
                this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, win_1_back2_amount * 100).then(() => {
                  triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
                });
                this.props.handleCancel();
              }
            }
          }
          if(back3_liability === 0 && back4_liability === 0)
          {
            const win1_back_2_4_place_amount = (amount / 100 - back1_liability) > back2_liability ? back2_liability * 100 : (amount / 100 - back1_liability) * 100;
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, win1_back_2_4_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back2_liability === 0 && back4_liability === 0)
          {
            const win1_back_2_2_place_amount = (amount / 100 - back1_liability) > back3_liability ? back3_liability * 100 : (amount / 100 - back1_liability) * 100;
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, win1_back_2_2_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
        }
      }
		}

    /**
     * Back 2 Product Added
     */

    if (race && meeting && race.id && meeting.id && productId === 20) {
      if(amount / 100 <= back2_liability)
      {
        this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, amount).then(() => {
          triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        });
        this.props.handleCancel();
      }
      else {
        if(totel_liability === amount / 100){
          this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back2_liability * 100).then(() => {
            triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
          });
          this.props.handleCancel();

          if(back1_liability !== 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, back1_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back3_liability !== 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back3_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back4_liability !== 0 && back1_liability === 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, back4_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back4_liability !== 0 && back3_liability === 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, back4_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
        }
        else {
          // // place back 2
           this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back2_liability * 100).then(() => {
             triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
           });
           this.props.handleCancel();

          if(back3_liability !== 0 && back1_liability !== 0)
          {
            const back_2_1_place_amount = (amount / 100 - back2_liability) > back3_liability ? back3_liability * 100 : (amount / 100 - back2_liability) * 100 ;
            const win2_back_3_amount = (amount / 100 - (back2_liability + back3_liability));

            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back_2_1_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();

            if(win2_back_3_amount > 0)
            {
              const back_3_amount = (win2_back_3_amount / 100 - back3_liability) > back3_liability ? back1_liability * 100 : (amount - (back2_liability * 100 + back3_liability * 100));
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, back_3_amount).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          }
            //if Win 4 Active place bets || back3 === 0
           if(back3_liability === 0)
           {
             if(back4_liability !== 0) {
               const win2_back_4_place_amount = (amount / 100 - back2_liability) > back4_liability ? back4_liability * 100 : (amount / 100 - back2_liability) * 100;
               const win_2_back1_amount = (amount / 100 - (back2_liability + back4_liability));
               this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, win2_back_4_place_amount).then(() => {
                 triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
               });
               this.props.handleCancel();

               if(back1_liability !== 0 && win_2_back1_amount > 0)
               {
                 this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, win_2_back1_amount).then(() => {
                   triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
                 });
                 this.props.handleCancel();
               }
             }
             if(back4_liability === 0)
             {
               const win2_back_1_place_amount = (amount / 100 - back2_liability) > back1_liability ? back1_liability * 100 : (amount / 100 - back2_liability) * 100;
               this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, win2_back_1_place_amount).then(() => {
                 triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
               });
               this.props.handleCancel();
             }
           }
           if(back1_liability === 0)
           {
             if(back4_liability !== 0 && back3_liability !== 0)
             {
               const win2_back_4_place_amount = (amount / 100 - back2_liability) > back3_liability ? back3_liability * 100 : (amount / 100 - back2_liability) * 100;
               const win_3_back4_amount = (amount / 100 - (back3_liability + back2_liability));

               this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, win2_back_4_place_amount).then(() => {
                 triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
               });
               this.props.handleCancel();

               if(win_3_back4_amount > 0)
               {
                 this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, win_3_back4_amount * 100).then(() => {
                   triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
                 });
                 this.props.handleCancel();
               }
             }
           }
           if(back4_liability === 0)
           {
             if(back3_liability === 0)
             {
               const win2_back_1_place_amount = (amount / 100 - back2_liability) > back1_liability ? back1_liability * 100 : (amount / 100 - back2_liability) * 100;
               this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, win2_back_1_place_amount).then(() => {
                 triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
               });
               this.props.handleCancel();
             }
             if(back1_liability === 0)
             {
               const win2_back_3_place_amount = (amount / 100 - back2_liability) > back3_liability ? back3_liability * 100 : (amount / 100 - back2_liability) * 100;
               this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, win2_back_3_place_amount).then(() => {
                 triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
               });
               this.props.handleCancel();
             }
           }
        }
      }
    }

    /**
     * Back 3 Product Added
     */

    if (race && meeting && race.id && meeting.id && productId === 21) {
      if(amount / 100 <= back3_liability){
        this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, amount).then(() => {
          triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        });
      } else{

        /**
         * if total libility === place amount
         */

        if(totel_liability === amount / 100){
          //win 3 bet
          this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back3_liability * 100).then(() => {
            triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
          });

          if(back4_liability !== 0 && back2_liability === 0 || back1_liability === 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, back4_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }

          if(back2_liability !== 0){
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back2_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back1_liability !== 0)
          {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, back1_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }


        } else {
           //win 3 bet place
          this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back3_liability * 100).then(() => {
            triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
          });

          if(back2_liability !== 0 && back1_liability !== 0)
          {
            const back_3_2_place_amount = (amount / 100 - back3_liability) > back2_liability ? back2_liability * 100 : (amount / 100 - back3_liability) * 100 ;
            const win_3_back_1_amount = (amount / 100 - (back2_liability + back3_liability));

            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back_3_2_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();

            if(win_3_back_1_amount > 0)
            {
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, win_3_back_1_amount * 100).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          }
          //if Win 4 Active place bets || back3 === 0
          if(back2_liability === 0 && back4_liability !== 0)
          {
            const win_3_back_4_place_amount = (amount / 100 - back3_liability) > back4_liability ? back4_liability * 100 : (amount / 100 - back3_liability) * 100;
            const win_2_back1_amount = (amount / 100 - (back3_liability + back4_liability));

            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, win_3_back_4_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();

            if(win_2_back1_amount > 0)
            {
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, win_2_back1_amount * 100).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          }
          if(back1_liability === 0 && back4_liability !== 0)
          {
            const win_3_back_4_place_amount = (amount / 100 - back3_liability) > back4_liability ? back4_liability * 100 : (amount / 100 - back3_liability) * 100;
            const win_2_back1_amount = (amount / 100 - (back3_liability + back4_liability));

            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, win_3_back_4_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();

            if(win_2_back1_amount > 0)
            {
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, win_2_back1_amount * 100).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          }
          if(back2_liability === 0 && back4_liability === 0)
          {
            const win_3_back_1_place_amount = (amount / 100 - back3_liability) > back1_liability ? back1_liability * 100 : (amount / 100 - back3_liability) * 100;
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, win_3_back_1_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
          if(back1_liability === 0 && back4_liability === 0)
          {
            const win_3_back_2_place_amount = (amount / 100 - back3_liability) > back2_liability ? back2_liability * 100 : (amount / 100 - back3_liability) * 100;
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, win_3_back_2_place_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
        }
      }
      this.props.handleCancel();
    }

    /**
     * Back 4 Product Added
     */
    if (race && meeting && race.id && meeting.id && productId === 22) {
      if(amount / 100 <= back4_liability){
        this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, amount).then(() => {
          triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        });
        this.props.handleCancel();
      } else{

        if(totel_liability === amount / 100){
          this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 22, betType, back4_liability * 100).then(() => {
            triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
          });
          this.props.handleCancel();
          if(back1_liability !== 0){
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, back1_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          } if(back2_liability !== 0) {
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back2_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          } if(back3_liability !== 0){
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back3_liability * 100).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }
        } else{
          const back_4_amount = back4_liability * 100;

          //place back 4
          this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, back_4_amount).then(() => {
            triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
          });
          this.props.handleCancel();

          if(back1_liability === 0 && back2_liability === 0){
            const back_3_amount = amount - back_4_amount;
            //place back 3
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back_3_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          } else if(back1_liability === 0 && back3_liability === 0){
            const back_2_amount = amount - back_4_amount;
            //place back 3
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, back_2_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          } else if(back2_liability === 0 && back3_liability === 0){
            const back_1_amount = amount - back_4_amount;
            //place back 1
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, back_1_amount).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
          }else if(back1_liability === 0 && back3_liability !== 0 && back2_liability !== 0) {
            const back_3_amount = (amount - back_4_amount);
            const back_2_amount = (amount - (back_4_amount + back3_liability * 100));
            //place back 3
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, (back_3_amount / 100 <= back3_liability) ? back_3_amount : (back3_liability * 100)).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();
            if (back_3_amount / 100 > back3_liability) {
              //place back 2
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, (back_2_amount / 100 <= back2_liability) ? back_2_amount : (back2_liability * 100)).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          } else if(back2_liability === 0 && back3_liability !== 0 && back1_liability !== 0) {

            const back_3_amount = (amount - back_4_amount);
            const back_1_amount = (amount - (back_4_amount + back3_liability * 100));

            //place back 3
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, (back_3_amount / 100 <= back3_liability) ? back_3_amount : (back3_liability * 100)).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();

            if (back_3_amount / 100 > back3_liability) {
              //place back 2
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, (back_1_amount / 100 <= back1_liability) ? back_1_amount : (back1_liability * 100)).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          } else if(back3_liability === 0 && back2_liability !== 0 && back1_liability !== 0) {
            const back_2_amount = (amount - back_4_amount);
            const back_1_amount = (amount - (back_4_amount - back2_liability * 100));

            //place back 2
            this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, (back_2_amount / 100 <= back2_liability) ? back_2_amount : (back2_liability * 100)).then(() => {
              triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
            });
            this.props.handleCancel();


            if (back_2_amount / 100 > back2_liability) {
              //place back 2
              this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, (back_1_amount / 100 <= back1_liability) ? back_1_amount : (back1_liability * 100)).then(() => {
                triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
              });
              this.props.handleCancel();
            }
          }
        }
        //
        //
        //
        //
        //
        //
        // //back one 0
        //  if(back1_liability === 0 && back2_liability === 0){
        //   const back_4_amount = back4_liability * 100;
        //   const back_3_amount = amount - back_4_amount;
        //   //place back 4
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, back_4_amount).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        //   //place back 3
        //
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, back_3_amount).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        // } else if(back1_liability === 0 && back3_liability === 0){
        //   const back_4_amount = (amount / 100 - back4_liability) * 100;
        //   const back_2_amount = (amount / 100) - back_4_amount;
        //
        //   //place back 4
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, back_4_amount).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        //   //place back 3
        //
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, (back_2_amount / 100 <= back2_liability) ? back_2_amount : (back2_liability * 100)).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        // } else if(back2_liability === 0 && back3_liability === 0){
        //   const back_4_amount = (amount / 100 - back4_liability) * 100;
        //   const back_1_amount = (amount / 100) - back_4_amount;
        //
        //   //place back 4
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, back_4_amount).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        //   //place back 3
        //
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, (back_1_amount / 100 <= back1_liability) ? back_1_amount : (back1_liability * 100)).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        // } else if(back1_liability === 0) {
        //    const back_4_amount = (back4_liability) * 100;
        //    const back_3_amount = (amount / 100 - back4_liability) * 100;
        //    const back_1_amount = (amount / 100 - (back4_liability + back3_liability)) * 100
        //
        //    //place back 4
        //    this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, back_4_amount).then(() => {
        //      triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //    });
        //    this.props.handleCancel();
        //
        //    //place back 3
        //
        //    this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, (back_3_amount / 100 <= back3_liability) ? back_3_amount : (back3_liability * 100)).then(() => {
        //      triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //    });
        //    this.props.handleCancel();
        //
        //    //place back 2
        //
        //    this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, (back_1_amount / 100 <= back2_liability) ? back_1_amount : (back2_liability * 100)).then(() => {
        //      triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //    });
        //    this.props.handleCancel();
        // } else if(back2_liability === 0) {
        //     //back one 2
        //   const back_4_amount = (back4_liability) * 100;
        //   const back_3_amount = (amount / 100 - back4_liability) * 100;
        //   const back_1_amount = (amount / 100 - (back4_liability + back3_liability)) * 100
        //
        //   //place back 4
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, back_4_amount).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        //   //place back 3
        //   if(back3_liability !== 0)
        //   {
        //     this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 21, betType, (back_3_amount / 100 <= back3_liability) ? back_3_amount : (back3_liability * 100)).then(() => {
        //       triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //     });
        //     this.props.handleCancel();
        //   }
        //   //place back 1
        //   if(back1_liability !== 0) {
        //     this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, (back_1_amount / 100 <= back1_liability) ? back_1_amount : (back1_liability * 100)).then(() => {
        //       triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //     });
        //     this.props.handleCancel();
        //   }
        //
        // } else if(back3_liability === 0) {
        //   const back_4_amount = (back4_liability) * 100;
        //   const back_2_amount = (amount / 100 - back4_liability) * 100;
        //   const back_1_amount = (amount / 100 - (back4_liability + back2_liability)) * 100;
        //
        //   //place back 4
        //   this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, productId, betType, back_4_amount).then(() => {
        //     triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //   });
        //   this.props.handleCancel();
        //
        //   if(back2_liability !== 0)
        //   {
        //     //place back 2
        //     this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 20, betType, (back_2_amount / 100 <= back3_liability) ? back_2_amount : (back2_liability * 100)).then(() => {
        //       triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //     });
        //     this.props.handleCancel();
        //   }
        //
        //   //place back 1
        //   if(back1_liability !== 0)
        //   {
        //     this.props.formatAndAddRacingMultiBet(selectionId, race.id, meeting.id, 19, betType, (back_1_amount / 100 <= back1_liability) ? back_1_amount : (back1_liability * 100)).then(() => {
        //       triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
        //     });
        //     this.props.handleCancel();
        //   }
        // }
      }
    }


	};

	/**
	 * Dispatches and event to add a sport multi and close the bet prompt
	 *
	 * @param selection
	 */
	addSportToMulti = (selection) => {
		if (selection.market_id) {
			this.props
				.addSportSelectionToMulti(
					selection.id,
					selection.market_id,
					selection.odds || selection.price,
					selection.product_id,
					selection.product_type || PRODUCT_TYPE_STANDARD,
				)
				.then(() => {
					triggerEventMessage(this.props.eventContext, 'selectionAddedToBetSlip');
				});
			this.props.handleCancel();
		}
	};

	/**
	 * Determine the Bet Type label to display
	 *
	 * @returns {string}
	 */
	getRacingBetTypeLabel = () => {
		const { racingBetType } = this.state;
		const { selections, t } = this.props;
		const selection = selections[0];

		return this.props.getRacingBetTypeLabel(racingBetType, selection, t);
	};

	/**
	 * Handler for selecting a GOAT product
	 */
	onSelectGoatProduct = (data) => {
		const { fetchRollTablePrice } = this.props;
		const { isRacing, isToteOdds, isPriceBumped, racingBetType, selectedGoatProduct } = this.state;

		let priceRollups = 0;
		if (data === GOAT_PRODUCT_TYPE_BOOST) {
			const odds = this.getOdds();
			const fixedPriceFromTable = fetchRollTablePrice(isRacing, odds.win);
			priceRollups = fixedPriceFromTable.rolls;

			if (this.state.selectedGoatProduct !== GOAT_PRODUCT_TYPE_BOOST) {
				this.props.track('Select', 'GOAT Bump');
			}
		} else if (data === RACING_BET_TYPE_MARGIN && this.state.selectedGoatProduct !== RACING_BET_TYPE_MARGIN) {
			this.props.track('Select', 'GOAT Margin');
		}

		// Passing through the new state stops unnecessary renders to the bet placement container
		const newState = {
			// Needed for the newState cache in setRacingSelection
			isRacing,
			isToteOdds,
			priceRollups,

			stage: '',
			isPriceBumped: data === GOAT_PRODUCT_TYPE_BOOST && !isPriceBumped,
			racingBetType: isRacing
				? data === RACING_BET_TYPE_MARGIN
					? racingBetType === RACING_BET_TYPE_MARGIN
						? RACING_BET_TYPE_WIN
						: RACING_BET_TYPE_MARGIN
					: data === GOAT_PRODUCT_TYPE_BOOST && racingBetType === RACING_BET_TYPE_MARGIN
						? RACING_BET_TYPE_WIN
						: racingBetType
				: '',
			selectedGoatProduct: data === selectedGoatProduct ? null : data,
			useBonusBets: false,
		};

		this.setState(newState);
		this.updateSelection(newState);
	};

	/**
	 * Return the props needed for the BetSelectionSingle if it's a Sport selection
	 *
	 * @returns {{isRacing: boolean, selectionName, betTypeLabel, eventName, price: number}}
	 */
	betSelectionSportProps = () => {
		const { getSportBetLabel, selections } = this.props;
		const { priceRollups, isPriceBumped } = this.state;
		const selection = selections[0];
		const odds = this.getOdds();

		return {
			isRacing: false,
			selectionName: selection ? selection.name : '',
			betTypeLabel: selection ? getSportBetLabel(selection): '',
			eventName: selection && selection.event ? selection.event.name : '',
			price: odds.win * 100,
			racingBetType: null,
			priceRollups: priceRollups,
			displayPriceIsBumped: isPriceBumped,
		};
	};

	/**
	 * Handle when one of the Racing Bet Type buttons is clicked on
	 *
	 * @param betType
	 */
	handleBetTypeChange = (betType) => {
		const nonExoticBetObj = this.props.racingBetTypes.find((racingBetType) => racingBetType.text === betType);
		const nonExoticBetType = nonExoticBetObj ? nonExoticBetObj.value : RACING_BET_TYPE_WIN;
		this.setRacingBetType(nonExoticBetType);
	};

	/**
	 * Get the product id(s) for the current selection(s)
	 *
	 * @param selection
	 */
	getSelectedProductIds = (selection) => {
		let selectedProductIds = [];

		if (selection.racingBetType === RACING_BET_TYPE_EACHWAY || selection.racingBetType === RACING_ODDS_GIDS) {
			// Add the win product
			selectedProductIds.push(selection.win.productId);

			// Add the place product
			selectedProductIds.push(selection.place.productId);
		} else {
			// Add the selected bet types product id
			selectedProductIds.push(selection[selection.racingBetType].productId);
		}

		return selectedProductIds;
	};

	/**
	 * Return the props needed for the BetSelectionSingle if it's a Racing selection
	 *
	 * @returns {{isRacing: boolean, silk, racingBetType: string, selectionName, betTypeLabel: string, eventName, price: number, placePrice: number, onBetTypeChange: (function(*))}}
	 */
	betSelectionRacingProps = () => {

		const { eachwayDisabled, racingBetType, priceRollups, isPriceBumped } = this.state;
		const { race, selections, t } = this.props;
		const selection = selections[0];
		const odds = this.getOdds();
		let price = isNaN(odds.win) ? odds.win : odds.win * 100;
		if (racingBetType === RACING_BET_TYPE_MARGIN) {
			price = isNaN(odds.margin) ? odds.margin : odds.margin * 100;
		}
		if(racingBetType === RACING_ODDS_GIDS){
      price = isNaN(odds.oddsgrid) ? odds.oddsgrid : odds.oddsgrid * 100;
    }

		return {
			priceRollups,
			eachwayDisabled,
			isRacing: true,
			silk: selection.silk,
			racingBetType: RACING_BET_TYPE_SHORT_LOOKUP[racingBetType],
			selectionName: `${selection.number}. ${selection.name}`,
			betTypeLabel: this.getRacingBetTypeLabel(),
			eventName: `${race.meeting_name}`,
      Dis_raceNumber:`${t('Race')} #${race.number}`,
			price: price,
			placePrice: isNaN(odds.place) ? odds.place : odds.place * 100,
			onBetTypeChange: this.handleBetTypeChange,
			displayPriceIsBumped: isPriceBumped,
			margin: selection.margin ? selection.margin.margin : null,
			selectedProductIds: this.getSelectedProductIds(selection),
		};
	};

	/**
	 * Return the props needed for the BetSelectionExotic
	 *
	 * @returns {{isRacing: boolean, isExotic: boolean, exoticType: string, betTypeLabel: string, eventName: string, price: number, exoticSelectionType: string, exoticSelections: Array}}
	 */
	betSelectionExoticProps = () => {
		const { exoticDetails, race, selections, t } = this.props;

		return {
			isRacing: true,
			isExotic: true,
			exoticType: exoticDetails.id,
			betTypeLabel: exoticDetails.isBoxed ? t('BetPromptContainer__AnyOrder') : t('BetPromptContainer__ExactOrder'),
			eventName: `${race.meeting_name} ${t('Race')} #${race.number}`,
			price: 100,
			exoticSelectionType: getExoticSelectionType({ exoticDetails, selections }),
			exoticSelections: getExoticSelectionsFromBetPrompt({ exoticDetails, selections }),
			selectedProductIds: [exoticDetails.productId],
		};
	};

	/**
	 * Return the props needed for the BetSelectionSingle if it's a Derivative selection
	 *
	 * @returns {{isRacing: boolean, selectionName, betTypeLabel, eventName, price: number}}
	 */
	betSelectionDerivativeProps = () => {
		const {
			selections,
			derivativeMarket,
			getBetSelectionDerivativeLabel,
			getBetSelectionDerivativeTitle,
			t,
		} = this.props;
		const selection = selections[0];
		const baseSelection = selection.base_selections.length === 1 ? selection.base_selections[0] : {};

		return {
			isRacing: true,
			silk: baseSelection.silk,
			selectionName: getBetSelectionDerivativeTitle(derivativeMarket.market_type_code, selection, false, t),
			eventName: getBetSelectionDerivativeLabel(derivativeMarket, selection, t),
			price: selection.price * 100,
			eachwayDisabled: true,
		};
	};

	/**
	 * Get the correct set of bet selection props for the bet selection
	 *
	 * @param eachwayDisabled
	 * @returns {*}
	 */
	getBetSelectionProps = (eachwayDisabled = false) => {
		const { selectionType } = this.props;
		let betSelectionProps;
		switch (selectionType) {
			case BET_TYPE_RACE:
				betSelectionProps = this.betSelectionRacingProps();
				// Set eachway disabled to true when we want to hide the W/P/EW controls
				betSelectionProps.eachwayDisabled = eachwayDisabled;
				break;
			case BET_TYPE_EXOTIC:
				betSelectionProps = this.betSelectionExoticProps();
				break;
			case BET_TYPE_DERIVATIVE:
				betSelectionProps = this.betSelectionDerivativeProps();
				break;
			default:
				betSelectionProps = this.betSelectionSportProps();
		}

		return betSelectionProps;
	};

	showQuickDepositForm = () => {
		this.props.setBetPromptTitle(TITLE_QUICK_DEPOSIT);
		this.props.handleQuickDeposit();
	};

	getCurrencyType = () => {
		return this.props.useBettaBucks ? 'tournamentCurrency' : this.state.useBonusBets ? 'bonusCurrency' : 'currency';
	};

	/**
	 * Renders the placing stage
	 */
	renderPlacingStage = () => {
		const { isExotic, isRacing, isVIP, isToteOdds, eachwayDisabled } = this.state;
		const betSelectionProps = this.getBetSelectionProps(eachwayDisabled);

		return (
			<BetPlacementContainer
				isExotic={isExotic}
				isRacing={isRacing}
				isVIP={isVIP}
				isToteOdds={isToteOdds}
				swapOddsAvailable={this.state.swapOddsAvailable}
				autoAccept={this.state.autoAccept}
				bettaBucks={this.props.bettaBucks}
				useBettaBucks={this.props.useBettaBucks}
				entryFee={this.props.entryFee}
				betSelectionProps={betSelectionProps}
				amountButtons={this.getAmountButtons()}
				availableBalance={this.getAvailableBalance()}
				disableAddToMulti={this.props.disableAddToMulti}
				exoticDetails={this.props.exoticDetails}
				handleAddToMulti={this.handleAddToMulti}
				handleCancel={this.props.handleCancel}
				handleConfirm={this.onConfirm}
				handleQuickDeposit={this.showQuickDepositForm}
				handleUseBonusBetsChange={this.setBonusBet}
				handleSwapOdds={this.handleSwapOdds}
				allOdds={this.props.allOddsForSelection}
				odds={this.getOdds()}
				oddsChangeText={this.state.oddsChangeText}
				racingBetType={this.state.racingBetType}
				selections={this.props.selections}
				setErrorMessage={this.props.setErrorMessage}
				clearErrorMessage={this.clearErrorMessage}
				setOddsChangeText={this.setOddsChangeText}
				showQuickDeposit={this.props.showQuickDeposit}
        		stake={this.state.racingBetType === 'oddsgrid' ? this.getOdds().liability * 100 : this.props.stake}
				/*stake={this.getOdds().liability * 100}*/
				track={this.props.track}
				bonusBets={this.props.bonusBets}
				useBonusBets={this.state.useBonusBets}
				user={this.props.user}
				race={this.props.race}
				meeting={this.props.meeting}
				updateSelection={this.updateSelection}
				isPriceBumped={this.state.isPriceBumped}
				selectedGoatProduct={this.state.selectedGoatProduct}
				onSelectGoatProduct={this.onSelectGoatProduct}
				relatedBets={this.props.relatedBets}
				stage={this.state.stage}
				setStage={this.setStage}
				selectionType={this.props.selectionType}
				isSingleWallet={this.props.isSingleWallet}
				useRouting={this.props.useRouting}
				limitUserBetAmount={this.props.limitUserBetAmount}
			/>
		);
	};

	/**
	 * Clear certain state values and process the rebet action
	 */
	handleRebet = () => {
		const newState = {
			...this.state,
			stage: '',
			priceRollups: 0,
			isPriceBumped: false,
			selectedGoatProduct: null,
			racingBetType: this.state.isRacing
				? this.state.selectedGoatProduct
					? RACING_BET_TYPE_WIN
					: this.state.racingBetType
				: this.state.racingBetType,
		};

		this.setState(newState);
		this.updateSelection(newState);
		this.props.handleReBet(this.props.stake);
	};

	/**
	 * Renders the receipt after a bet has been placed
	 */
	renderReceipt = (rejectionReason, rejectionComment) => {
		const { betStatus, bets, handleCancel, betsPlaced, racingBetType, stake, isSingleWallet, t } = this.props;
		const { isExotic, isPriceBumped, useBonusBets } = this.state;

		const betSelectionProps = this.getBetSelectionProps(true);

		const odds = this.getOdds();
		let priceString = '';
		let possiblePayout = t('Dividend');
		if (!isExotic && odds) {
			if (rejectionReason) {
				const isEachway = racingBetType === RACING_BET_TYPE_EACHWAY;
				if (isEachway) {
					priceString = `${formatOddsAsPrice(odds.win)} ${formatOddsAsPrice(odds.place)}`;
				} else {
					if (racingBetType === RACING_BET_TYPE_PLACE) {
						priceString = formatOddsAsPrice(odds.place);
					} else {
						priceString = formatOddsAsPrice(odds.win);
					}
				}
			} else {
				const possiblePayoutAmount =
					calculatePossiblePayout(
						stake,
						odds.win * 100,
						useBonusBets,
						betSelectionProps.racingBetType,
						odds.place * 100,
					) / 100;

				if (!isNaN(possiblePayoutAmount)) {
					possiblePayout = <CurrencyDisplay type={this.getCurrencyType()} amount={possiblePayoutAmount} />;
				}
			}
		}

		// Double the stake if an eachway bet
		const amount = racingBetType === RACING_BET_TYPE_EACHWAY ? stake * 2 : stake;

		return (
			<StyledBetPromptContainer__BetReceipt
				betStatus={betStatus}
				isSingleWallet={isSingleWallet}
				account_balance={this.getAvailableBalance()}
				currencyType={this.getCurrencyType()}
				backToBetting={handleCancel}
				bets={bets}
				betSelectionProps={betSelectionProps}
				handleReBet={this.handleRebet}
				receiptId={betsPlaced}
				receiptMethod={
					rejectionReason ? (
						priceString
					) : (
						<PlotElements align="start">
							<span>
								{t('PossiblePayout')}
								&nbsp;
							</span>
							{possiblePayout}
						</PlotElements>
					)
				}
				receiptMethodTitle={
					<PlotElements align="start">
						<CurrencyDisplay amount={amount} type={this.getCurrencyType()} />
						&nbsp;
						<CurrencyNameDisplay type={this.getCurrencyType()} /> {t('Bet')}
					</PlotElements>
				}
				receiptType="Bet"
				wasPriceBumped={isPriceBumped}
				interceptMessage={rejectionComment}
				interceptRejectReason={rejectionReason}
				subtitle={
					<span>
						{t('Available')} <CurrencyNameDisplay type={this.getCurrencyType()} /> {t('Balance')}
					</span>
				}
			/>
		);
	};

	renderReferral = () => {
		return <StyledBetPromptContainer__BetPendingReview />;
	};

	render() {
		const {
			t,
			betFullyRefunded,
			betReferred,
			betConfirmed,
			betRejectedReason,
			betRejectedComment,
			className,
			errorMessage,
			isLoading,
			betRejected,
		} = this.props;

		let stage;
		if (betReferred) {
			stage = this.renderReferral();
		} else if (betRejected) {
			stage = this.renderReceipt(betRejectedReason, betRejectedComment);
		} else if (betConfirmed || betFullyRefunded) {
			stage = this.renderReceipt();
		} else {
			stage = this.renderPlacingStage();
		}

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<div className={componentClasses}>
				<LoadingMask loading={isLoading} />
				{errorMessage && (
					<Notification
						type={Notification.types.COLOUR_DANGER}
						message={errorMessage}
						strong
						buttonText={t('Dismiss')}
						buttonAction={this.clearErrorMessage}
					/>
				)}
				{stage}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const creditCards = getVerifiedCreditCardsOnly(state);
	const isTournaments = isTournamentPage(state);

	// Ensure that we load the placed bets from the correct entities slice
	const bets = ownProps.useBettaBucks ? getTournamentBetsWithId(state) : getBetsWithId(state);

	const bet = bets[0] || {};
	const betDetails = {
		receipts: state.betPrompt.betsPlaced,
		betStatus: bet.status,
		betConfirmed: bet.status === BET_UNRESULTED_STATUS,
		betReferred: bet.status === BET_PENDING_STATUS,
		betFullyRefunded: bet.status === BET_FULL_REFUND_STATUS,
		betRejected: bet.status === BET_REJECTED_STATUS || bet.status === BET_CANCELLED_STATUS,
		betRejectedComment: bet.rejection_comment,
		betRejectedReason: bet.rejection_reason,
		betOdds: bet.odds,
		betId: bet.id,
	};

	/**
	 * If the bet was confirmed - don't show the receipt if the feature toggle is turned off.
	 * Placing this logic here and not in the component also saves a render.
	 */
	const betPlacementReceiptFeatureToggle = state.featureToggles.features.betPlacementShowReceipt;
	if (
		betDetails.betConfirmed &&
		betPlacementReceiptFeatureToggle &&
		(!betPlacementReceiptFeatureToggle.enabled || !betPlacementReceiptFeatureToggle.value.betPrompt)
	) {
		ownProps.handleCancel();
	}

	// Keep the bet prompt indicator loading if the bet is in a processing status
	const isLoading = bet.status === BET_PROCESSING_STATUS || state.betPrompt.isLoading;

	// Split up were we watch the selections from depending on if Racing or Sport
	let selections, derivativeMarket, meeting, race;
	let relatedBets = [];
	if (
		state.betPrompt.selectionType === BET_TYPE_RACE ||
		state.betPrompt.selectionType === BET_TYPE_EXOTIC ||
		state.betPrompt.racingBetType ||
		state.betPrompt.selections.length > 1 ||
		(state.betPrompt.exoticDetails && state.betPrompt.exoticDetails.id)
	) {
		// Single race only
		if (
			state.betPrompt.selectionType === BET_TYPE_RACE ||
			RACING_WIN_PLACE_BET_TYPES.includes(state.betPrompt.racingBetType)
		) {
			selections = getSingleRaceSelectionsForPrompt(state);
		} else if (
			state.betPrompt.selectionType === BET_TYPE_EXOTIC ||
			state.betPrompt.selections.length > 1 ||
			(state.betPrompt.exoticDetails && state.betPrompt.exoticDetails.id)
		) {
			// Exotic selection
			selections = state.betPrompt.selections;
		}

		const meetings = getDenormalizedMeeting(state, selections[0].meeting_id);
		if (meetings.length) {
			meeting = meetings[0];
			race = meeting.races.find((race) => race.id === selections[0].race_id);
		}

		relatedBets = buildBetsForRace(state);
	} else if (state.betPrompt.selectionType === BET_TYPE_DERIVATIVE) {
		// Derivative selections
		selections = getDerivativeSelectionsInPrompt(state);
		const derivativeMarkets = getDerivativeMarketInPrompt(state);
		derivativeMarket = derivativeMarkets.length ? derivativeMarkets[0] : null;
	} else {
		// Sport selection
		selections = getSportSelectionsForPrompt(state);
		relatedBets = getBets(state.entities);
	}

	// Is the bonus bets feature enabled
	const isBonusBetsEnabled = isFeatureEnabled(state, 'bonusBets');

	return {
		...state.betPrompt,
		...betDetails,
		bets,
		isLoading,
		relatedBets,
		derivativeMarket,
		meeting,
		race,
		bettaBucks: typeof ownProps.bettaBucks !== 'undefined' ? ownProps.bettaBucks : state.betPrompt.bettaBucks,
		entryFee: typeof ownProps.entryFee !== 'undefined' ? ownProps.entryFee : state.betPrompt.entryFee,
		selections: selections || state.betPrompt.selections,
		user: getAuthenticatedUser(state),
		creditCards: creditCards,
		showQuickDeposit:
			typeof ownProps.showQuickDeposit !== 'undefined'
				? ownProps.showQuickDeposit
				: Object.keys(creditCards).length > 0,
		defaultBetAmount: isTournaments
			? state.defaultBetAmounts.default_tournament_bet_amount
			: state.defaultBetAmounts.default_bet_amount,
		allOddsForSelection: getOddsForSelection(state, betPromptRaceId),
		bonusBets: isBonusBetsEnabled,
		useBonusBets: isBonusBetsEnabled ? ownProps.useBonusBets : false,
		isSingleWallet: !ownProps.useBettaBucks && state.featureToggles.features.singleWallet.enabled,
		confirmBetPlacement: state.featureToggles.features.confirmBetPlacement.enabled,
		limitUserBetAmount: state.featureToggles.features.limitUserBetAmount.enabled,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		isExotic,
		isRacing,
		getSportBetLabel,
		getRacingBetTypeLabel,
		getBetSelectionDerivativeLabel,
		getBetSelectionDerivativeTitle,
		getRacingProductIdAndBetType,
		fetchRollTablePrice: (isRacing, price) => dispatch(fetchRollTablePrice(isRacing, price)),
		fetchFixedPriceRollup: (isRacing, price) => dispatch(fetchFixedPriceRollup(isRacing, price)),
		clearBetPlacement: () => dispatch(singleBetPlacementActions.reset()),
		handleCancel: () => {
			triggerEventMessage(ownProps.eventContext, 'closed');
			ownProps.handleCancel();
		},
		handleConfirm: ownProps.handleConfirm
			? ownProps.handleConfirm
			: (selectionType, stake, useBonusBets, racingBetType, exoticDetails) => {
					dispatch(updateBetPromptDetails({ stake, useBonusBets, racingBetType, exoticDetails }));

					// If 'racingBetType' or 'exoticDetails' has reasonable content, place racing bet
					if (
						selectionType === BET_TYPE_RACE ||
						selectionType === BET_TYPE_EXOTIC ||
						racingBetType ||
						exoticDetails.race
					) {
						return dispatch(
							singleBetPlacementActions.placeRacingBet(
								racingBetType,
								stake,
								useBonusBets,
								racingBetType,
								exoticDetails,
							),
						);
					} else if (selectionType === BET_TYPE_DERIVATIVE) {
						return dispatch(singleBetPlacementActions.placeRacingBet(selectionType, stake, useBonusBets, BET_TYPE_WIN));
					}

					return dispatch(singleBetPlacementActions.placeSportBet(stake, useBonusBets));
			  },
		handleReBet: (stake) => {
			dispatch(
				updateBetPromptDetails({
					stake,
					betConfirmed: false,
					betRejected: false,
					betRejectedReason: null,
					betRejectedComment: null,
					betOdds: null,
					betsPlaced: [],
					title: DEFAULT_BET_PROMPT_TITLE,
				}),
			);
		},
		formatAndAddRacingMultiBet: (meetingData, race, selectionId, productId, betType, amount) =>
			dispatch(formatAndAddRacingMultiBet(meetingData, race, selectionId, productId, betType, amount)),
		addSportSelectionToMulti: (selectionId, marketId, odds, productId, productType) =>
			dispatch(addSportSelectionToBetSlip(selectionId, marketId, odds, productId, productType, true)),
		clearErrorMessage: () => dispatch(clearBetPromptErrors()),
		setErrorMessage: (error) => dispatch(setBetPromptErrors(error)),
		track: (label, value) => dispatch(trackGaEvent('BetSlip', label, value)),
		fetchCreditCards: () => dispatch(fetchCreditCards()),
		setBetPromptTitle: (title) => dispatch(updateBetPromptDetails({ title })),
		setRacingSelection: (selectionId, raceId, meetingId, productId, betType, isFixed) =>
			dispatch(formatAndAddSingleSelectionToBetPrompt(selectionId, raceId, meetingId, productId, betType, isFixed)),
		setSportSelection: (selectionId, marketId, odds, productId, productType) =>
			dispatch(formatAndAddSportSelectionToBetPrompt(selectionId, marketId, odds, productId, productType)),
		resetBetPromptTitle: () => dispatch(resetBetPromptTitle()),
	};
};

export default withNamespaces()(
	createErrorBoundary(
		connect(
			mapStateToProps,
			mapDispatchToProps,
		)(BetPromptContainer),
		{
			message: 'MessageBetPrompt',
			buttonText: 'Retry',
			remountFailureButtonAction: launchLiveChat,
		},
	),
);
