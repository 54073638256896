import {
	denormalize,
	normalize,
} from 'normalizr';
import {pricesSchema} from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param {Array} keys
 * @returns {Array}
 */
const denormalizePrices = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.prices), pricesSchema, entities);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizePrices = (response) => {
	return normalize(response, pricesSchema);
};

export {
	denormalizePrices,
	normalizePrices,
};