// Constants needed for translations
import {
	SELECTION_PRICE_NOT_APPLICABLE,
	DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT,
} from '../../store/entities/constants/BetConstants';
import {
	DAILY_TOURNAMENTS_LABEL,
	HIGHLIGHTS_LABEL,
	MY_TOURNAMENTS_LABEL,
	ACTIVE_LABEL,
	CLOSED_LABEL,
	RACING_LABEL,
	SPORTS_LABEL,
	PRIZE_POOL_LABEL,
	REBUYS_LABEL,
	BUY_IN_LABEL,
} from '../../pages/Tournaments/TournamentsConstants';
import {
	USER_ACCOUNT_SUBMENU_USER_HOME,
	USER_ACCOUNT_SUBMENU_DEPOSIT,
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
	USER_ACCOUNT_SUBMENU_WITHDRAWALS,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
	USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_SUBMENU_TAKE_A_BREAK,
	USER_ACCOUNT_SUBMENU_SELF_EXCLUSION,
	USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT,
	USER_ACCOUNT_SUBMENU_LOSS_LIMITS,
	USER_ACCOUNT_SUBMENU_SPEND_LIMIT,
	USER_ACCOUNT_SUBMENU_REALITY_CHECK,
} from '../../pages/UserAccount/UserAccountPageConstants';
import { GENERIC_ERROR_MESSAGE, GENERIC_LOGIN_MESSAGE } from '../../common/constants/Notifications';
import {
	CURRENT,
	YESTERDAY,
	LAST_7_DAYS,
	LAST_MONTH,
	CUSTOM,
	FILTER_RESULTS,
} from '../../common/constants/Tournaments';
import { VERIFIED, NOT_VERIFIED } from '../../containers/Deposit/DepositConstants';
import {
	RACING_EACHWAY_LABEL,
	RACING_BUMPED_LABEL,
	EXOTIC_BOXED,
	EXOTIC_STANDARD,
	EXOTIC_STANDOUT,
} from '../../common/constants/Racing';

// Translation strings
export default {
	// Error validation messages
	'^Adults Only': '^Tylko dorośli',
	'^Invalid Credit Card Number': '^Nieprawidłowy numer karty kredytowej',
	'^Invalid CVV': '^Nieprawidłowy CVV',
	'^Invalid expiry date': '^Nieprawidłowa data ważności',
	'Details used on this account are already in use by a previous user. Please contact Support on 1300 886 503 to resolve.':
		'Szczegóły używane na tym koncie są już używane przez poprzedniego użytkownika. Skontaktuj się z pomocą techniczną pod numerem 1300 886 503, aby rozwiązać problem.',
	'Email is not a valid email': 'Email nie jest prawidłowym adresem e-mail',
	'is not a valid email': 'nie jest prawidłowym adresem e-mail',
	'is not valid': 'nie jest poprawny',
	'is the wrong length (should be %{count} characters)': 'ma złą długość (powinno być %{count} znaków)',
	'is too long (maximum is %{count} characters)': 'jest za długi (maksimum to %{count} znaków)',
	'is too short (minimum is %{count} characters)': 'jest za krótki (minimum to %{count} characters)',
	'must be an integer': 'musi być liczbą całkowitą',
	'must be at least 6 characters': 'musi mieć co najmniej 6 znaków',
	'must have at least 1 number and 1 letter': 'musi mieć co najmniej 1 numer i 1 literę',
	'Password must contain at least one number and one letter':
		'Hasło musi zawierać co najmniej jedną cyfrę i jedną literę',
	'The city field is required.': 'Pole miasta jest wymagane.',
	'The country field is required.': 'Pole kraju jest wymagane.',
	'The dob day field is required.': 'Wymagane jest pole dnia dob.',
	'The dob month field is required.': 'Wymagane jest pole miesiąca dobowego.',
	'The dob year field is required.': 'Wymagane jest pole roku.',
	'The email format is invalid.': 'Format wiadomości e-mail jest nieprawidłowy.',
	'The first name field is required.': 'Pole imienia jest wymagane.',
	'The first name must be at least 2 characters.': 'Imię musi składać się z co najmniej 2 znaków.',
	'The last name field is required.': 'Pole nazwiska jest wymagane.',
	'The last name must be at least 2 characters.': 'Nazwisko musi składać się z co najmniej 2 znaków.',
	'The marketing opt in flag field is required.': 'Wymagane jest pole wyboru wyboru w branży marketingowej.',
	'The password field is required.': 'Pole hasła jest wymagane.',
	'The password must be at least 6 characters.': 'Hasło musi mieć co najmniej 6 znaków.',
	'The postcode field is required.': 'Pole kodu pocztowego jest wymagane.',
	'The source field is required.': 'Pole źródłowe jest wymagane.',
	'The state field is required.': 'Pole stanu jest wymagane.',
	'The street field is required.': 'Pole ulicy jest wymagane.',
	'The title field is required.': 'Pole tytułu jest wymagane.',
	'The username field is required.': 'Pole nazwy użytkownika jest wymagane.',
	'The username must be at least 6 characters.': 'Nazwa użytkownika musi składać się z co najmniej 6 znaków.',
	'You must be 18 or older to sign up to TopBetta': 'Aby zarejestrować się w TopBetta, musisz mieć ukończone 18 lat',
	"can't be blank": 'nie może być puste',
	// Common & general definitions
	'1st Prize': 'Pierwsza nagroda',
	'1st Up': 'Pierwszy w górę',
	'2nd Up': 'Drugi w górę',
	'Available until': 'Dostępne do',
	'e/w': 'e/w',
	'First 4': 'Pierwsze 4',
	'First Four': 'Pierwsza czwórka',
	'FREE Tournament': 'DARMOWY turniej',
	'n/a': 'n / d',
	'NO RETURN': 'BEZ POWROTU',
	'No.': 'Nr.',
	'Non Wet': 'nie mokry',
	'Not enough selections!': 'Za mało wyborów!',
	'out of': 'na',
	'Play with': 'Bawić się',
	'Say something': 'Powiedz coś',
	'Terms&Conditions': 'Zasady i Warunki',
	'To Beat the Field': 'Pokonać pole',
	'To Beat': 'Pobić',
	'Type something...': 'Napisz coś...',
	'v/s': 'vs',
	'Win/Place': 'Zdobyć/Miejsce',
	[ACTIVE_LABEL]: 'Aktywny',
	[BUY_IN_LABEL]: 'Przelicytować',
	[CLOSED_LABEL]: 'Zamknięte',
	[CURRENT]: 'obecny',
	[CUSTOM]: 'Zwyczaj',
	[DAILY_TOURNAMENTS_LABEL]: 'Codziennie',
	[DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT]: 'Kwota zakładu',
	[EXOTIC_BOXED]: 'zapakowany',
	[EXOTIC_STANDARD]: 'standard',
	[EXOTIC_STANDOUT]: 'wyjątkowość',
	[FILTER_RESULTS]: 'Filtruj wyniki',
	[GENERIC_ERROR_MESSAGE]: 'Ups! Przepraszamy, ale coś poszło nie tak. Proszę spróbuj ponownie.',
	[GENERIC_LOGIN_MESSAGE]: 'Przepraszamy, musisz zalogować się, aby kontynuować!',
	[HIGHLIGHTS_LABEL]: 'Najważniejsze',
	[LAST_7_DAYS]: 'Ostatnie 7 dni',
	[LAST_MONTH]: 'W zeszłym miesiącu',
	[MY_TOURNAMENTS_LABEL]: 'Moje turnieje',
	[NOT_VERIFIED]: 'Nie zweryfikowany',
	[PRIZE_POOL_LABEL]: 'Pula nagród',
	[RACING_BUMPED_LABEL]: 'BUMPED',
	[RACING_EACHWAY_LABEL]: 'Każdy sposób',
	[RACING_LABEL]: 'Wyścigi',
	[REBUYS_LABEL]: 'Ponownie kupuje',
	[SELECTION_PRICE_NOT_APPLICABLE]: 'n / d',
	[SPORTS_LABEL]: 'Sport',
	[VERIFIED]: 'Zweryfikowany',
	[YESTERDAY]: 'Wczoraj',
	Abandoned: 'Opuszczony',
	Accept: 'Zaakceptować',
	accepted: 'przyjęty',
	Account: 'Konto',
	account: 'konto',
	AccountBlockedTemporarily: 'Konto zablokowane na czas',
	AccountNumber: 'Numer konta',
	AcronymDriver: 'K',
	AcronymJockey: 'J',
	AcronymKilogram: 'kg',
	AcronymLoss: 'U',
	AcronymRace: 'W',
	AcronymRefunded: 'Z',
	AcronymTrainer: 'T',
	AcronymUnresulted: 'N',
	AcronymWeather: 'P',
	AcronymWin: 'Z',
	Active: 'Aktywny',
	Add: 'Dodaj',
	Added: 'Dodany',
	Adds: 'Dodaje',
	AddToBetSlip: 'Dodaj do zakładu Slip',
	Age: 'Wiek',
	All: 'Wszystko',
	Amount: 'Ilość',
	Apply: 'Zastosować',
	Approved: 'Zatwierdzony',
	at: 'w',
	Available: 'Dostępny',
	AvailableBonusBet: 'Dostępny zakład bonusowy',
	AvailableCash: 'Dostępne środki pieniężne',
	AwesomeThanks: 'Wielkie dzieki!',
	BackToBetting: 'Powrót do zakładów',
	BackToTop: 'Powrót do góry',
	Balance: 'Saldo',
	Bank: 'Bank',
	BankDepositInformation: 'Informacje o depozycie bankowym',
	BankEFT: 'Bank EFT',
	BetLimit: 'Limit zakładów',
	Bets: 'Zakłady',
	BetSlip: 'Kupon',
	BillerCode: 'Kod billingowy',
	Bonus: 'Premia',
	Box: 'Pudełko',
	Boxed: 'W pudełku',
	Break: 'Złamać',
	BSB: 'BSB',
	Buy: 'Kupować',
	BuyIn: 'Przelicytować',
	Cancel: 'Anuluj',
	CancelWithdrawal: 'Anuluj wypłatę',
	Career: 'Opiekun',
	Cash: 'Gotówkowy',
	CashTournament: 'Turniej gotówkowy',
	Change: 'Zmiana',
	ChooseCreditCard: 'Wybierz kartę kredytową',
	ClaimFreeTicket: 'Zgłoś bezpłatny bilet',
	Clear: 'Jasny',
	Close: 'Blisko',
	Closed: 'Zamknięte',
	Closing: 'Zamknięcie',
	Colour: 'Kolor',
	Comment: 'Komentarz',
	Competitions: 'Zawody',
	Confirm: 'Potwierdzać',
	Confirmed: 'Zatwardziały',
	ContinueBetting: 'Kontynuuj zakłady',
	CreditCard: 'Karta kredytowa',
	CreditCardNumber: 'Numer karty kredytowej',
	Currency: 'Waluta',
	CustomAmount: 'Kwota niestandardowa',
	Daily: 'Codziennie',
	daily: 'codziennie',
	DailyTournamentSchedule: 'Harmonogram codziennych turniejów',
	Dam: 'Matka',
	Date: 'Data',
	Delete: 'Kasować',
	Deposit: 'Kaucja',
	deposit: 'kaucja',
	DepositAmount: 'Kwota depozytu',
	DepositFromBankAccount: 'Wpłata z konta bankowego',
	DepositReceipt: 'Pokwitowanie depozytu',
	Deposits: 'Depozyty',
	DepositSuccessful: 'Wpłata pomyślna',
	Description: 'Opis',
	Details: 'Detale',
	Dismiss: 'Odwołać',
	Distance: 'Dystans',
	Dividend: 'Dywidenda',
	DOB: 'Data urodzenia',
	EmailAddress: 'Adres e-mail',
	EndDate: 'Data zakonczenia',
	Ended: 'Zakończone',
	EnterCreditCardDetails: 'Wprowadź dane karty kredytowej',
	EnterTournament: 'Wejdź do turnieju',
	Entries: 'Wpisy',
	EntriesClose: 'Wpisy Zamknij',
	EntryCost: 'Koszt wejścia',
	events: 'wydarzenia',
	EW: 'KS',
	Exacta: 'Exacta',
	Existing: 'Istniejący',
	Exotic: 'Egzotyczny',
	Exotics: 'Egzotyki',
	Expiry: 'Wygaśnięcie',
	Fee: 'Opłata',
	FilterBy: 'Filtruj według',
	Firm: 'Mocny',
	fixed: 'naprawiony',
	Fixed: 'Stały',
	FixedOdds: 'Naprawiono kursy',
	Flexi: 'Flexi',
	Forgot: 'Zapomniałem',
	Free: 'Darmowy',
	FullyRefunded: 'Całkowicie zwrócony',
	THOROUGHBRED: 'GALOP',
	Thoroughbred: 'Galop',
	Get: 'Dostać',
	Good: 'Dobry',
	GotItThanks: 'Mam to, dzieki!',
	Greyhounds: 'Charty',
	GREYHOUNDS: 'CHARTY',
	Harness: 'Uprząż',
	HARNESS: 'UPRZĄŻ',
	Heavy: 'Ciężki',
	Hidden: 'Ukryty',
	Hide: 'Ukryć',
	HideMarkets: 'Ukryj rynki',
	HowDoesThisWork: 'Jak to działa?',
	ID: 'ID',
	Immediately: 'natychmiast',
	including: 'włącznie z',
	INTERNATIONAL: 'MIĘDZYNARODOWY',
	International: 'Międzynarodowy',
	Join: 'Przystąpić',
	Last: 'Ostatni',
	length: 'długość',
	Limit: 'Limit',
	limit: 'limit',
	Live: 'na żywo',
	LIVE: 'na żywo',
	Locked: 'Zablokowany',
	Login: 'Zaloguj Się',
	Logo: 'Logo',
	Logout: 'Wyloguj',
	Long: 'Długie',
	lose: 'stracić',
	Losing: 'Przegrywający',
	Loss: 'Utrata',
	LOSS: 'UTRATA',
	loss: 'utrata',
	MakeAWithdrawal: 'Wypłacić pieniądze',
	MakeDeposit: 'Złożyć depozyt',
	Menu: 'Menu',
	Minimum: 'Minimum',
	minus: 'minus',
	MM_YY: 'MM/RR',
	Mobile: 'mobilny',
	monthly: 'miesięczny',
	Monthly: 'Miesięczny',
	MoreInfo: 'Więcej informacji',
	MoreMarkets: 'Więcej rynków',
	Multi: 'Wielo',
	MyRebuys: 'Moje ponowne zakupy',
	MyTournaments: 'Meus torneios',
	NameOnCreditCard: 'Nazwa na karcie kredytowej',
	NeedHelp: 'Potrzebuję pomocy?',
	Next: 'Kolejny',
	NextStep: 'Następny krok',
	NextTournament: 'Następny turniej',
	Night: 'Noc',
	No: 'Nie',
	None: 'Żaden',
	Note: 'Uwaga',
	Now: 'Teraz',
	Odds: 'Szansa',
	OrderBy: 'Zamów przez',
	Other: 'Inny',
	Owners: 'Właściciel',
	P: 'M',
	Paid: 'Płatny',
	PartiallyRefunded: 'Częściowo zwrócone',
	Password: 'Hasło',
	Pending: 'W oczekiwaniu',
	pending: 'w oczekiwaniu',
	PerEvent: 'Na wydarzenie',
	PhoneNumber: 'Numer telefonu',
	place: 'miejsce',
	Place: 'Miejsce',
	PLACE: 'MIEJSCE',
	Play: 'Grać',
	PlayerBets: 'Zakłady dla graczy',
	PlayerProfile: 'Perfil do Jogador',
	PlayersRebuys: 'Gracze kupują ponownie',
	PlayTournament: 'Zagraj w turnieju',
	PlayWith: 'Bawić się',
	Popular: 'Popularny',
	Position: 'Pozycja',
	PossiblePayout: 'Możliwa wypłata',
	PotentialPayout: 'Potencjalna wypłata',
	PotentialWinnings: 'Potencjalne wygrane',
	PrivacyPolicy: 'Polityka prywatności',
	Prize: 'Nagroda',
	PrizeFormat: 'Format nagrody',
	PrizePool: 'Pula nagród',
	PrizeTable: 'Tabela nagród',
	PromoCode: 'Kod promocyjny',
	Promotions: 'Promocje',
	Quinella: 'Quinella',
	Race: 'Wyścig',
	race: 'wyścig',
	races: 'wyścigi',
	Rank: 'Ranga',
	ReBet: 'ReBet',
	RebuyIntoTournament: 'Przeładuj na turniej',
	RebuyLimit: 'Limit ponownego zakupu',
	Receipt: 'Paragon',
	Recent: 'Niedawny',
	Ref: 'Odn',
	Refunded: 'Zwrócono środki',
	Rejected: 'Odrzucono',
	removed: 'oddalony',
	Results: 'Wyniki',
	Retry: 'Spróbować ponownie',
	Scratching: 'Drapanie',
	SearchTournaments: 'Wyszukaj turnieje',
	Season: 'Sezon',
	SecurityCode: 'Kod bezpieczeństwa',
	Selection: 'Wybór',
	Selections: 'Wybór',
	Send: 'Wysłać',
	Sex: 'Płeć',
	Shared: 'Udostępnione',
	Short: 'Krótki',
	Show: 'Pokazać',
	Single: 'Pojedynczy',
	SingleBetSlip: 'Boletim de Aposta Único',
	Singles: 'Syngiel',
	Sire: 'Ojciec',
	Sledge: 'Saneczki',
	SledgeBox: 'Caixa de trenó',
	Soft: 'Miękki',
	spend: 'wydać',
	Spend: 'Wydać',
	Sport: 'Sport',
	Sports: 'Sport',
	Stake: 'Stawka',
	StartDate: 'Data rozpoczęcia',
	StartPlaying: 'Zacznij grać',
	Starts: 'początek',
	Status: 'Status',
	Step: 'Krok',
	Support: 'Wsparcie',
	Synthetic: 'Syntetyczny',
	TermsAndConditions: 'Regulamin',
	termsAndConditions: 'Regulamin',
	TermsOfService: 'Warunki usługi',
	Ticket: 'Bilet',
	'Ticket Purchase': 'Zakup biletu',
	Tickets: 'Bilety',
	To: 'Do',
	to: 'do',
	Today: 'Dzisiaj',
	togo: 'iść',
	Top: 'Top',
	Total: 'Całkowity',
	TotalRebuys: 'Total Re kupuje',
	Tournament: 'Zawody',
	TournamentDetails: 'Szczegóły turnieju',
	TournamentEntry: 'Wejście do turnieju',
	TournamentRebuy: 'Rebuy do torneio',
	Tournaments: 'Turnieje',
	tournaments: 'turnieje',
	TournamentsEntered: 'Turnieje wprowadzone',
	towin: 'wygrać',
	ToWin: 'Wygrać',
	Track: 'Tor',
	TrackDistance: 'Odległość toru',
	Transactions: 'Transakcje',
	TrendingTournament: 'Trendy w turniejach',
	Trifecta: 'Trifecta',
	TryAgain: 'Spróbuj ponownie',
	Type: 'Rodzaj',
	User: 'Użytkownik',
	Username: 'Nazwa Użytkownika',
	Verified: 'Zweryfikowany',
	Verify: 'Zweryfikować',
	VerifyAmount: 'Zweryfikuj kwotę',
	VerifyCard: 'Sprawdź kartę',
	VerifyLater: 'Zweryfikuj później',
	via: 'przez',
	ViewTournament: 'Zobacz turniej',
	W: 'Z',
	Weekly: 'Co tydzień',
	weekly: 'co tydzień',
	Welcome: 'Witamy',
	Wet: 'Mokry',
	WeWillSendYou: 'Wyślemy cię',
	win: 'wygrana',
	Win: 'Wygrana',
	WIN: 'ZDOBYĆ',
	Winning: 'Zwycięski',
	winning: 'zwycięski',
	WinPotential: 'Potencjalnie wygrać',
	with: 'z',
	Withdraw: 'Wycofać',
	withdraw: 'wycofać',
	WithdrawableBalance: 'Saldo do wypłaty',
	Withdrawal: 'Wycofanie',
	withdrawal: 'wycofanie',
	WithdrawalMethod: 'Metoda wypłaty',
	WithdrawalReceipt: 'Potwierdzenie wypłaty',
	Withdrawals: 'Wypłaty',
	Without: 'Bez',
	Won: 'Wygrał',
	WON: 'WYGRAŁ',
	Yes: 'tak',
	YesPlease: 'Tak proszę',

	// Component definitions
	"RacingHomePage__Today's": 'Dzisiejsze',
	"RacingHomePage__Tomorrow's": 'Jutro',
	"RacingHomePage__Yesterday's": 'Przeszłość',
	'DerivativeSelectionList__Label--FVF': 'Favourite vs Field',
	'DerivativeSelectionList__Label--H2H': 'Łeb w łeb',
	'DerivativeSelectionList__Label--HVH': 'Ulubiony kontra pole',
	'DerivativeSelectionList__Label--IO': 'Wewnątrz a na zewnątrz',
	'DerivativeSelectionList__Label--OVE': 'Szansa lub Parzysty',
	'Racing__Status--abandoned': 'Opuszczony',
	'Racing__Status--closed': 'Zamknięty',
	'Racing__Status--deleted': 'usunięte',
	'Racing__Status--interim': 'Tymczasowy',
	'Racing__Status--paid': 'Zapłacony',
	'Racing__Status--paying': 'Płacić',
	'Racing__Status--selling': 'Sprzedawanie',
	'SpeedmapLegend__Value--Backmarker': 'Backmarker',
	'SpeedmapLegend__Value--Leader': 'Lider',
	'SpeedmapLegend__Value--Midfield': 'Środek toru',
	'SpeedmapLegend__Value--Off-pace': 'Off-pace',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}--mobile`]: 'Wyciąg z konta',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}`]: 'Transakcje na koncie',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}--mobile`]: 'Oświadczenie bonusowe',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}`]: 'Transakcje z bonusem',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS}--mobile`]: 'Domyślne kwoty zakładów',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS}`]: 'Domyślne kwoty zakładów',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT}--mobile`]: 'Ustaw limity wpłat',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT}`]: 'Ustaw limity wpłat',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT}--mobile`]: 'Złożyć depozyt',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT}`]: 'Wpłać środki',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_LOSS_LIMITS}--mobile`]: 'Ustaw limity strat',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_LOSS_LIMITS}`]: 'Ustaw limity strat',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_REALITY_CHECK}--mobile`]: 'Sprawdzenie autentyczności',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_REALITY_CHECK}`]: 'Sprawdzenie autentyczności',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESET_PASSWORD}--mobile`]: 'Zresetuj hasło',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESET_PASSWORD}`]: 'Zresetuj hasło',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING}--mobile`]: 'Odpowiedzialny hazard',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING}`]: 'Odpowiedzialny hazard',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SELF_EXCLUSION}--mobile`]: 'Samowykluczenie (stałe)',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SELF_EXCLUSION}`]: 'Samowykluczenie (stałe)',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SPEND_LIMIT}--mobile`]: 'Ustaw limity wydatków',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SPEND_LIMIT}`]: 'Ustaw limity wydatków',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_TAKE_A_BREAK}--mobile`]: 'Zrób sobie przerwę',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_TAKE_A_BREAK}`]: 'Zrób sobie przerwę',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_USER_HOME}--mobile`]: 'Mój profil',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_USER_HOME}`]: 'Mój profil',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS}--mobile`]: 'Wypłata środków',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS}`]: 'Wypłata środków',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAWALS}--mobile`]: 'Anuluj wypłatę',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAWALS}`]: 'Anuluj wypłatę',
	AccountSummary__AccountNumber: 'Numer konta',
	AccountSummary__LockScreen: 'Ekran blokady',
	AccountSummary__MyAccount: 'Moje konto',
	AccountSummaryNavList__Download: 'Pobierz aplikację mobilną',
	AccountSummaryStatistics__Happy: 'Cieszę się, że cię znowu widzę',
	AccountSummaryStatistics__PendingBets: 'Oczekujące zakłady',
	AccountSummaryStatistics__PotentialWinnings: 'Potencjalne wygrane',
	AccountSummaryStatistics__TotalStakes: 'Łączna stawka',
	AccountSummaryStatistics__Welcome: 'Witamy spowrotem',
	AccountTransactionsCellMulti__Leg: '{{length}} Leg Multi',
	AccountTransactionsCellRacing__GoatMargin: '* GOAT Długość marży {{margin}} dodana do 2. pozycji biegacza',
	AccountTransactionsCellRebet__MatchStarts: 'Mecz rozpoczyna się w:',
	AdditionalAddressFields__AutoComplete: 'Wróć do automatycznego zakończenia adresu',
	AdditionalAddressFields__PostcodeConstraint: '^Minimum 4 wymagane liczby',
	AdditionalAddressFields__TownCity: 'Miasto / miasto',
	AuthoriseVerification__CreditCard: 'Udzielam upoważnienia do weryfikacji mojej karty kredytowej.',
	AuthoriseVerification__CreditCardWithBrand: 'Upoważniam {{brandName}} do weryfikacji mojej karty kredytowej.',
	BankAccountForm__AccountHolderName: 'Nazwa właściciela konta',
	BankAccountForm__AccountName: 'Nazwa konta',
	BankAccountForm__AccountNumber: 'Numer konta',
	BankAccountForm__BankBSB: 'BSB banku',
	BankAccountForm__BankName: 'Nazwę banku',
	BankAccountForm__BankStateBranch: 'Oddział Banku',
	BankAccountForm__WhatBankName: 'Jak nazywa się bank?',
	BetPendingReview__Title: 'Zakład oczekujący na weryfikację',
	BetPlacementContainer__AddToMulti: 'Dodaj do wielu',
	BetPlacementContainer__ChargeEntryButton: 'Kup bilet & ',
	BetPlacementContainer__ChargeEntryMessage: 'Zostaniesz obciążony {{cost}}} wpisowym do turnieju.',
	BetPlacementContainer__ExceededBalance: 'Kwota zakładu przekroczyła Twoje saldo. Kwota ustawiona na maksimum.',
	BetPlacementContainer__FreeEntryButton: 'Zgłoszenie biletów i ',
	BetPlacementContainer__FreeEntryMessage: 'NIE zostanie naliczona opłata za wejście do turnieju.',
	BetPlacementContainer__PlaceOdds: 'Rozstaw kursy',
	BetPlacementContainer__RacingOddsChange: 'zmieniono - {{last}} na {{current}}',
	BetPlacementContainer__SportOddsChange: 'Zmieniono kursy - {{last}} na {{current}}',
	BetPlacementContainer__WinOdds: 'Wygraj kursy',
	BetPromptContainer__AnyOrder: 'Jakiekolwiek zamówienie',
	BetPromptContainer__BetReceiptTitle: 'Zakład Pokwitowanie',
	BetPromptContainer__ExactOrder: 'Dokładne zamówienie',
	BetPromptContainer__QuickDepositTitle: 'Szybki depozyt',
	BetReceipt__Continue: 'Kontynuuj zakłady',
	BetReceipt__Refunded: 'Twój zakład został w pełni zwrócony',
	BetReceipt__RefundedSingle: 'Jeden z twoich zakładów został zwrócony w całości',
	BetReceipt__Rejected: 'Twój zakład został odrzucony',
	BetReceipt__RejectedSingle: 'Jeden z twoich zakładów został odrzucony',
	BetReceipt__RejectReason: 'Odrzuć powód',
	BetReceipt__Trader: 'Komentarz inwestora',
	BetSelectionSingle__BUMPED: 'BUMPED',
	BetSelectionSingle__MarginMessage: 'Dodaj {{margin}}L na 2',
	BetSlipButton__Label: 'Kupon',
	BetSummaryBetItem__MarginMessage: '* Dodaje długość {{marginButt}} do drugiego umieszczonego biegacza',
	BetSummaryBetList__NoBets: 'Brak dostępnych zakładów do pokazania.',
	BetSummaryTicketList__NoTickets: 'Nie masz żadnych biletów.',
	BettaBucksAmount__PlayWith: 'Bawić się',
	BetTermsMessage__TermsMessageStart: 'Wszystkie zakłady przyjęte zgodnie z ',
	BetTermsMessage__TermsMessageEnd: ' - Grasz odpowiedzialnie.',
	BonusBetsList__Accepted: 'Przyjęty',
	BonusBetsList__BonusBetsMessage:
		'Zakłady bonusowe są przyznawane w celu promowania strony internetowej i dawania nowym graczom możliwości rekreacyjnego korzystania z platformy.',
	BPayDeposit__InfoTitle: 'Informacje o lokacie {{BPay}}',
	BPayDeposit__Title: 'Wpłać z {{BPay}}',
	CancelWithdrawalConfirmation__CanBe: 'można anulować.',
	CancelWithdrawalConfirmation__FundsWillAppear: 'Fundusze pojawią się w Twoim',
	CancelWithdrawalConfirmation__Success: 'został pomyślnie anulowany.',
	CancelWithdrawalConfirmation__ThisRequest: 'To żądanie wypłaty z',
	CancelWithdrawalConfirmation__TryAgain:
		'Spróbuj ponownie lub skontaktuj się z obsługą klienta, aby uzyskać dalszą pomoc.',
	CancelWithdrawalConfirmation__UnableToProcess: 'Nie możemy przetworzyć Twojej prośby.',
	ContactUsPanel__Title: 'Skontaktuj się z nami w celu uzyskania pomocy',
	CountdownPanel__Message: 'Odbierz swój bonusowy zakład, zanim minie czas!',
	CreateAccount__Button: 'Stwórz nowe konto',
	CreateAccount__NewTo: 'Jesteś nowy w witrynie {{brandName}}?',
	CreditCardVerification__EnterVerification:
		'Wprowadź kwotę centów weryfikacyjnych dodaną do Twojej wpłaty weryfikacyjnej. Można to znaleźć na wyciągu z karty kredytowej.',
	CreditCardVerification__VerificationError:
		'Ups! Sprawdź, czy wprowadzona kwota jest nieprawidłowa. Spróbuj ponownie: {{verificationAttemptsLeft}}/3 pozostałe próby.',
	CreditCardVerification__VerifyCreditCard:
		'Twoja karta kredytowa musi zostać zweryfikowana przed złożeniem wniosku o wypłatę.',
	DefaultBetAmounts__Accepted: 'Przyjęty',
	DefaultBetAmounts__BetAmount: 'Kwota zakładu',
	DefaultBetAmounts__BetAmountMessage: 'Twoja aktualna domyślna kwota zakładu to:',
	DefaultBetAmounts__Error: 'Twoje domyślne kwoty zostały zaktualizowane.',
	DefaultBetAmounts__NewDefault: 'Nowa domyślna kwota zakładu',
	DefaultBetAmounts__NotAccepted: 'Nie zaakceptowany',
	DefaultBetAmounts__NotSet: 'Nie ustawiony',
	DefaultBetAmounts__TournamentBetAmount: 'Kwota zakładu turniejowego',
	DefaultBetAmounts__TournamentBetAmountMessage: 'Twoja domyślna kwota zakładu na turnieje to:',
	DefaultBetAmounts__TournamentLive: 'Ostrzeżenia o turnieju na żywo',
	DefaultBetAmounts__TournamentMessage: 'Zakładając zakład na prawdziwe pieniądze w turnieju, potwierdzam:',
	DefaultBetAmounts__Update: 'Zaktualizuj domyślne',
	DepositConfirmation__CreditAccount: 'Zasługujemy na Twoje konto',
	DepositConfirmation__CreditBalance: 'Przyznaliśmy ci kredyt{{bonus}} saldo.',
	DepositConfirmation__FirstSuccessMessage: 'Pierwsza wpłata okazała się sukcesem!',
	DepositConfirmation__NoBonusApplied: 'Bonus depozytowy nie został zastosowany!',
	DepositContainer__AddBalance: 'Dodamy do Twojego salda',
	DepositContainer__RemoveCard: 'Za chwilę usuniesz kartę kredytową kończącą się',
	DepositForm__Deposit: 'Chcę dokonać depozytu',
	DepositForm__ExceededVerification:
		'Przekroczyłeś próby weryfikacji. Skontaktujemy się z Tobą bezpośrednio, aby zweryfikować Twoją kartę kredytową.',
	DepositForm__Minimum: '10-minutowy depozyt',
	DepositForm__VerifiedMessage: 'Ta karta kredytowa jest teraz w pełni zweryfikowana - dzięki!',
	DepositReceipt__SuccessMessage: 'Zasobiliśmy saldo Twojego konta.',
	DerivativeSelectionList__Heading: 'Specjalne rynki wyścigowe',
	ErrorBoundaryContainer__Message: 'Ups. Coś poszło nie tak. Odśwież stronę i spróbuj ponownie.',
	ErrorBoundaryContainer__MessageBetPrompt: 'Ups. Coś poszło nie tak. Odśwież kupon i spróbuj ponownie.',
	ErrorBoundaryContainer__OpenIntercom: 'Otwarty Intercom',
	ErrorBoundaryContainer__RemountDefault: 'Nie udało się odświeżyć. Czy chcesz otworzyć czat z pomocą techniczną?',
	ErrorBoundaryDefault__Message:
		'Coś poszło nie tak i nie mogliśmy załadować tej strony. Czy chciałbyś spróbować jeszcze raz?',
	ErrorPage404__Late: 'Nastąpiło spóźnienie',
	ErrorPage404__Message: 'Kursy potknąłeś się na tej stronie, wpisując niewłaściwą rzecz, a może właśnie tak się stało',
	ErrorPage404__SubMessage: 'Tak czy inaczej, oto kilka opcji, które pomogą Ci w podróży.',
	ErrorPage404__Title: 'Strona nie znaleziona',
	ExternalDepositResult__FailMessage: 'Wpłata nieudana! Spróbuj ponownie.',
	ExternalDepositResult__SuccessMessage: 'Wpłata pomyślna! Wkrótce saldo Twojego konta zostanie zaksięgowane.',
	FirstDeposit__AmountError: 'Kwota musi być mniejsza niż lub równa 100',
	FirstDeposit__BillingStatements: "Faktury rozliczeniowe będą odzwierciedlać '{{brandName}}'",
	FirstDeposit__Depositing: 'Deponujesz',
	FirstDeposit__DepositingCustom: 'Dokonujesz depozytu niestandardowej kwoty',
	FirstDeposit__Glad: 'Cieszymy się że tu jesteś, {{first_name}}',
	FirstDeposit__MakeADeposit: 'Złożyć depozyt',
	FirstDeposit__PreferAnother: 'Preferuję kolejną opcję pierwszego depozytu',
	FirstDeposit__Skip: 'Pomiń ofertę bonusową za pierwszą wpłatę',
	FirstDeposit__Spend: 'Jak dużo chcesz wydać?',
	Footer__Rights: 'Wszelkie prawa zastrzeżone.',
	FooterNote__One: `Uzyskując dostęp, korzystając z tej strony lub poruszając się po niej, akceptujesz, że wykorzystamy pewne
		ciasteczka przeglądarki, aby poprawić wrażenia, które otrzymujesz z nami. {{brandName}} nie używaj żadnych plików cookie, które
		ingerować w twoją prywatność, ale tylko te, które poprawią twoje wrażenia podczas korzystania z naszej strony
		zapoznaj się z naszą Polityką prywatności, aby uzyskać dodatkowe informacje na temat naszego używania plików cookie oraz tego, jak zapobiegać ich użyciu
		chciałbyś.`,
	FooterNote__Two: `Usługa {{brandName}} jest obsługiwana przez {{brandName}} PTY LTD zarejestrowaną w Australii. {{brandName}} jest licencjonowany na Terytorium Północnym,
		Australia przez NT Racing Commission, aby zaakceptować zakłady przez internet na wyścigi, sport i rozrywkę.`,
	GetStartedBanner__GetStarted: 'Zaczynać',
	GetStartedBanner__NotTooLate: 'Nie jest za późno!',
	GetStartedBanner__StepDeposit: 'Złożyć depozyt',
	GetStartedBanner__StepGame: "'Wejdź do gry'",
	HowToPlayMessage__Heading: 'Jak grać w turnieju?',
	HowToPlayMessage__Point1: 'Zacznij od zakupu biletu turniejowego.',
	HowToPlayMessage__Point2: 'Następnie obstaw zakłady turniejowe!',
	HowToPlayMessage__Quote: '“Powodzenia i dobra praca”',
	HowToPlayMessage__QuoteName: 'Todd',
	HowToPlayMessage__QuoteTitle: 'CEO TopBetta',
	HowToPlayTournaments__Heading: 'Jak grać w turnieju',
	HowToPlayTournaments__Point1: 'Wybierz turniej, w którym chciałbyś zagrać, to zależy od Ciebie',
	HowToPlayTournaments__Point3:
		'Musisz spekulować, aby zebrać i wyprzedzić LeaderBoard, biorąc gotówkę lub bilet na jeszcze większy turniej!',
	HowToPlayTournaments__SubHeading1: 'Wybierz turniej',
	HowToPlayTournaments__SubHeading2: 'Umieść swoje zakłady',
	HowToPlayTournaments__SubHeading3: 'Sprawdź tabelę liderów',
	JoinLoginElements__GetStarted: 'Zaczynać',
	JoinLoginElements__LoginRegister: 'Zaloguj się / Zarejestruj się',
	JoinLoginElements__Username: 'Nazwa użytkownika lub email',
	LockedScreen__NotYou: 'Nie ty?',
	LockedScreen__SignIn: 'Zaloguj się jako inne konto',
	LockedScreenContainer__Error: 'Logowanie nieudane. Sprawdź swoje hasło.',
	LoggedUserElements__AddFunds: 'Dodać fundusze',
	Login__Chat: 'Nie można uzyskać dostępu do konta - skontaktuj się z nami',
	Login__ForgotPassword: 'Zapomniałeś hasła?',
	Login__KeepLoggedIn: 'Pozostaw mnie zalogowanym',
	Login__Player: 'Jestem graczem {{brandName}}',
	Login__UsernameOrEmail: 'Nazwa użytkownika lub email',
	LoginContainer__ForgotPassword: 'Zapomniałeś hasła?',
	LoginContainer__LoginOrJoin: 'Zaloguj się lub dołącz',
	LoginForPromotions__LoginForPromotions: 'Zaloguj się, aby skorzystać z promocji',
	MarketGroupings__NoMarketsMessage: 'Brak dostępnych rynków otwartych',
	Masthead__KeepGoing: 'Niesamowite. Tak trzymaj',
	Masthead__LoginError: 'Proszę wypełnić wymagane pola.',
	MastheadContainer__PasswordError: 'Nie możemy znaleźć użytkownika z tym adresem e-mail.',
	MeetingList__NextRace: 'Następny wyścig',
	MeetingListContainer__NoAURaces: 'Brak dostępnych wyścigów AU / NZ',
	MeetingListContainer__NoInternationalRaces: 'Brak dostępnych wyścigów międzynarodowych',
	MobileAuthenticationForm__IsValid: 'Dziękuję, to wygląda świetnie. Stuknij Wyślij SMS, aby kontynuować.',
	MobileAuthenticationForm__SendSMS: 'Wyślij kod SMS',
	MobileAuthenticationForm__SMSVerification:
		'Będziemy używać numeru telefonu komórkowego do weryfikacji przez SMS. Obowiązują standardowe stawki za wiadomości.',
	MobileAuthenticationForm__SMSVerificationCode:
		'Najpierw wprowadź swój numer telefonu komórkowego, aby otrzymać jednorazowy kod weryfikacyjny SMS.',
	MobileAuthenticationForm__VerifyRealPerson: 'Sprawdźmy, czy jesteś prawdziwą osobą',
	MobileFooter__PuntersClub: 'Regulamin klubu Punting Club',
	MobileVerificationForm__CodeError: 'Kod nie jest liczbą',
	MobileVerificationForm__CreateAccount: 'Utwórz konto',
	MobileVerificationForm__DontForget: 'Nie zapomnij',
	MobileVerificationForm__Step3of3: 'Krok 3 z 3',
	MobileVerificationForm__UndoNumber: 'Utwórz konto',
	MobileVerificationForm__VerificationCode: 'Kod weryfikacyjny',
	MobileVerificationForm__Verified: 'Miło cię poznać, {{first_name}}!',
	MobileVerificationForm__VerifiedOne:
		'Wszystko dobrze - masz ważny numer telefonu komórkowego i mimo wszystko jesteś człowiekiem.',
	MobileVerificationForm__VerifiedThree: 'Dokonaj wpłaty i rozpocznij zakłady.',
	MobileVerificationForm__VerifiedTwo: 'Co się potem dzieje?',
	MobileVerificationForm__Verify: 'Sprawdźmy, czy jesteś prawdziwą osobą',
	MobileVerificationForm__VerifyMobile: 'Zweryfikuj swój numer telefonu komórkowego za pomocą kodu weryfikacyjnego.',
	MobileVerificationForm__VerifyMobileButton: 'Zweryfikuj urządzenie mobilne',
	ModalFooter__Message: 'To bezpieczna, zaszyfrowana forma - jesteś bezpieczny.',
	MoreInfo__Done: 'Mam to, dzieki!',
	MoreInfo__Five:
		'Możesz również kontynuować obstawianie z dowolnymi wygranymi pieniężnymi, które otrzymasz w tym dniu, pod warunkiem, że całkowita wygrana {{typeOfLimitLowerCase}} {{limitTypeFactor}} pozostanie poniżej Twojego limitu.',
	MoreInfo__Four:
		'Od północy do północy (AEST) będziesz mógł stawiać zakłady i wprowadzać turnieje pieniężne do limitu {{typeOfLimitLowerCase}}.',
	MoreInfo__Heading: 'Limity dzienne, tygodniowe i miesięczne {{limitType}}',
	MoreInfo__One:
		'Dzienny limit {{typeOfLimitLowerCase}} oznacza, że ​​nie będziesz w stanie {{limitTypeText}} przekroczyć kwoty w ciągu 24 godzin.',
	MoreInfo__Seven: 'Jeśli chcesz całkowicie zablokować dostęp do witryny, możesz poprosić o samowykluczenie.',
	MoreInfo__Six:
		'Możesz obniżyć limit {{typeOfLimitLowerCase}} w dowolnym momencie, ale jego podniesienie zacznie obowiązywać po 7 dniach.',
	MoreInfo__Three:
		'Miesięczny limit {{typeOfLimitLowerCase}} oznacza, że ​​nie będziesz w stanie {{limitTypeText}} przekroczyć kwoty w okresie 30 dni.',
	MoreInfo__Two:
		'Tygodniowy limit {{typeOfLimitLowerCase}} oznacza, że ​​nie będziesz w stanie {{limitTypeText}} przekroczyć kwoty w okresie 7 dni.',
	MultiBetContainer__Balance: 'Saldo',
	MultiBetContainer__BetSlip: 'Bet Slip',
	MultiBetContainer__EmptyOne: 'Twój Slip Bet jest pusty.',
	MultiBetContainer__EmptyTwo: 'Zakłady, które dodasz pojawią się tutaj.',
	MultiBetContainer__ExceedMessage: 'Kwota zakładu przekroczyła saldo Twojego konta.',
	MultiBetContainer__InvalidSelections:
		'Masz selekcje, które są nieważne i nie można ich połączyć w wiele zakładów. Nadal możesz stawiać zakłady pojedyncze.',
	MultiBetContainer__KeepBets: 'Zachowaj zakłady',
	MultiBetContainer__OddsChange: 'Kursy zmieniły się z {{oldPrice}} na {{newPrice}}',
	MultiBetContainer__StruckWarning: 'Twój zakład zostanie złożony, jeśli będziesz kontynuować.',
	MultiBetSelectionList__AllSingles: 'Dodaj do wszystkich singli',
	MultiBetSummary__Title: 'Podsumowanie zakładów',
	MyTournaments__LoginMessage: 'Musisz się zalogować, aby zobaczyć wprowadzone turnieje.',
	MyTournaments__NotEntered: 'Nie uczestniczyłeś w żadnych turniejach',
	NextToJumpCard__Upcoming: 'Nie ma nadchodzących',
	NextToJumpRaceDetails__View: 'Wyświetl pełną kartę wyścigową',
	NextToJumpTournamentDetails__EnterTournament: 'Wejdź do turnieju',
	NextTournamentContainer__NoTournamentsMessage: 'Brak dostępnych turniejów',
	PasswordResetContainer__Error: 'Nie możemy znaleźć użytkownika z tym adresem e-mail.',
	PasswordResetMessage__CreateAccount: 'Utwórz teraz konto {{brandName}}.',
	PasswordResetMessage__New: 'Nowy tutaj?',
	PasswordResetMessage__Oops: 'Ups! Teraz pamiętam. ',
	PasswordResetRequestSuccess__Inbox: 'Proszę, sprawdź swoją skrzynkę odbiorczą',
	PasswordResetRequestSuccess__Instructions:
		'Instrukcje dotyczące resetowania hasła wysłaliśmy na podany adres e-mail.',
	PasswordResetRequestSuccess__Success: 'Sukces: wysłano wiadomość e-mail',
	PINAuthentication__Error: 'PIN nie pasuje.',
	PINAuthenticationContainer__ForgotPin: 'Zapomniałeś PIN?',
	PINAuthenticationContainer__Message: 'Kod PIN to szybki sposób logowania przy użyciu 4 cyfr',
	PINAuthenticationContainer__NotificationSuccess: 'Konfiguracja uwierzytelniania PIN dla tego urządzenia!',
	PINAuthenticationContainer__Password: 'Użyj hasła, aby się zalogować',
	PINAuthenticationContainer__RepeatMessage: 'Powtórz PIN, aby potwierdzić konfigurację',
	PlayerBetsHomePage__Description: 'Przeglądaj i stawiaj na wydajność gracza',
	PlayerBetsHomePage__Title: 'Wyniki zawodnika i zakłady sportowe online',
	PlayerProfileBets__BetsPlaced: 'Zakłady umieszczone',
	PlayerProfileSummary__AverageBet: 'Średni zakład',
	PlayerProfileSummary__BettingPerformance: 'Skuteczność zakładów',
	PlayerProfileSummary__WinRatio: 'Współczynnik wygranych',
	PoliForm__Title: 'Wpłać za pomocą {{poli}}',
	Profile__Address: 'Adres',
	Profile__AddressInformation: 'Informacje adresowe',
	Profile__Birthday: 'Urodziny',
	Profile__Country: 'Kraj',
	Profile__FirstName: 'Imię',
	Profile__FormDisabledMessage:
		'Jeśli chcesz zmienić swoje dane osobowe, skontaktuj się z naszym działem obsługi klienta, który może pomóc w zmianie tych danych.',
	Profile__LastName: 'Nazwisko',
	Profile__MobileNumber: 'Numer telefonu komórkowego',
	Profile__Postcode: 'Kod pocztowy',
	Profile__State: 'Stan',
	Profile__Suburb: 'Przedmieście',
	Profile__Title: 'Informacje osobiste',
	PromoAlert__Title: 'PROMO ALERT',
	Promotions__BetNow: 'Obstawiaj teraz',
	Promotions__CurrentPromotions: 'Aktualne promocje',
	Promotions__NoArticles: 'Brak artykułów do wyświetlenia.',
	Promotions__NoPromotions: 'Brak dostępnych promocji.',
	Promotions__PromotionUnavailable: 'Promocja, o którą prosiłeś, nie jest dostępna.',
	QuickBettingSummaryContainer__Title: 'Podsumowanie zakładów SelectiQuick',
	RaceCard__EarlySpeed: 'Wczesna prędkość',
	RaceCard__Form: 'Formy',
	RaceCard__HideMyBets: 'Ukryj moje zakłady',
	RaceCard__OnSettling: 'O osiedleniu',
	RaceCard__RaceOverview: 'Przegląd wyścigu',
	RaceCard__ShowMyBets: 'Pokaż moje zakłady',
	RaceCard__Speedmaps: 'Mapa prędkości',
	RaceProductLegend__AllExotics: 'Wszyscy egzotyki',
	RaceProductLegend__PleaseNote: 'Proszę zanotować',
	RaceSelectionsList__Flucs: 'Fluksy',
	RaceSelectionsList__GoatLength: 'KOZA +Długość',
	RaceSelectionsList__MarginMessage: 'GOAT Długość marginesu {{goatLength}} dodana do 2. pozycji biegacza',
	RaceSelectionsList__PlaceBet: 'Postawić zakład',
	RaceSelectionsList__PoolTotals: 'Sumy w puli',
	RacingDateSelection__Futures: 'Przyszłość',
	RacingDateSelection__Tomorrow: 'Jutro',
	RacingDateSelection__Yesterday: 'Wczoraj',
	RacingHomePage__Description: 'Obstawiaj teraz wyścigi konne, charty i uprzęże online',
	RacingHomePage__DescriptionBonus: 'Bonus Zakład dla nowych graczy.',
	RacingHomePage__Next: 'Następny',
	RacingHomePage__Races: 'Wyścigi',
	RacingHomePage__Racing: 'Wyścigi',
	RacingHomePage__Title: 'Zakłady na wyścigi konne, kursy i wyniki',
	Receipt__DepositMethod: 'Metoda depozytu',
	RegistrationConfirmation__Heading: 'Usiądź wygodnie, tworzymy Twoje konto!',
	RegistrationConfirmation__Step1: '1 z 3: Tworzenie konta',
	RegistrationConfirmation__Step2: '2 z 3: Inicjowanie konta',
	RegistrationConfirmation__Step3: '3 z 3: Prawie zrobione',
	RegistrationHeader__CreateAccount: 'Utwórz konto dla pełnego dostępu',
	RegistrationPage__AdultsOnly: 'Tylko dla dorosłych',
	RegistrationPage__BettingLimitError:
		'Ups! Przepraszamy, ale coś poszło nie tak podczas ustawiania limitu zakładów. Spróbuj ponownie później.',
	RegistrationPage__DepositLimitError:
		'Ups! Przepraszamy, ale coś poszło nie tak podczas ustawiania limitu wpłat. Spróbuj ponownie później.',
	RunnerForm__RunnerComments: 'Komentarze biegacza',
	SelfExclusionConfirmation__ChangedMind: 'Zmieniłem zdanie',
	SelfExclusionConfirmation__DontLike: 'Nie podoba mi się strona',
	SelfExclusionConfirmation__GamblingProblem: 'Problem z hazardem',
	SelfExclusionConfirmation__InsertComment: 'Proszę wstawić komentarz ...',
	SelfExclusionConfirmation__Others: 'Inni',
	SelfExclusionPrompt__Account: 'NIE będziesz mógł zalogować się na swoje konto lub utworzyć nowego konta.',
	SelfExclusionPrompt__Confirmation: 'Potwierdzenie stałego samowykluczenia zostanie wysłane na ten adres e-mail.',
	SelfExclusionPrompt__Exclude: 'Wyklucz siebie teraz',
	SelfExclusionPrompt__Marketing: 'Przestaniesz otrzymywać komunikaty marketingowe w ciągu 24 godzin.',
	SelfExclusionPrompt__Pending: 'Wszystkie oczekujące zakłady będą ważne.',
	SelfExclusionPrompt__Proceed: 'Co się stanie, jeśli przejdę?',
	SelfExclusionPrompt__PuntingClub: 'Zostaniesz usunięty ze wszystkich aktywnych klubów Puntingowych.',
	SelfExclusionPrompt__StandingBalance:
		'Saldo na stojąco zostanie automatycznie wycofane do ostatniej aktywnej metody wypłaty, gdy oczekują zakłady.',
	SelfExclusions__Others: 'Inni',
	SelfExclusions__Reason: 'Problem z hazardem',
	SetLimit__24HourDay: '24-godzinny dzień',
	SetLimit__30Day: 'Okres 30-dniowy',
	SetLimit__7Day: 'Okres 7-dniowy',
	SetLimit__AdjustLimitMessage: 'Zmień poniżej limit {{period}} {{limitTypeLowerCase}}.',
	SetLimit__InputLabel: 'Nowa {{periodUpperCase}} {{limitType}} Limit kwoty',
	SetLimit__LoseAmount: 'Nie możesz stracić kwoty większej niż ta w {{changePeriod}}.',
	SetLimit__LowerLimit:
		'Możesz obniżyć limit {{limitTypeLowerCase}} w dowolnym momencie, ale jego podniesienie zacznie obowiązywać po 7 dniach.',
	SetLimit__MoreInfo: 'Więcej informacji.',
	SetLimit__NotSet: 'Nie ustawiony',
	SetLimit__PendingLimitMessage: 'Twój oczekujący limit {{limitTypeLowerCase}} to:',
	SetLimit__RemoveLimit: 'Usuń limit',
	SetLimit__Support: 'Grupa wsparcia',
	SetLimit__UpdateLimit: 'Zaktualizuj limit',
	SetTimer__Alert: 'Powiadomimy cię, gdy automatycznie osiągniesz swój Test Rzeczywistości.',
	SetTimer__Check: 'Możesz zmienić czek, jeśli chcesz',
	SetTimer__SupportTeam: 'Grupa wsparcia',
	SetTimer__Update: 'Zaktualizuj sprawdzanie rzeczywistości',
	SetTimer__Updated: 'Twoja weryfikacja rzeczywistości została zaktualizowana.',
	SidebarContainer__MenuOptions: 'Opcje menu',
	SidebarSettings__NT: 'Pokaż następny turniej',
	SidebarSettings__NTJ: 'Pokaż następny skok',
	SidebarSettings__NTP: 'Pokaż obok, aby grać',
	SidebarSettings__Save: 'Zapisz opcje',
	SimpleRegistration__HaveAccount: 'Posiadasz już konto?',
	SimpleRegistration__Header: 'Formularz rejestracyjny',
	SimpleRegistration__SignIn: 'Zaloguj',
	SimpleRegistration__SignUpNow: 'Zapisz się teraz',
	SimpleRegistration__YourDetails: 'Twoje szczegóły',
	SpeedmapHeader__Barrier: 'Bariera',
	SpeedmapHeader__Lead: 'Prowadzenie',
	SportsHomePage__LiveBetsHeading: 'Sportowe zakłady na żywo',
	SportsHomePage__NoBetsMessage: 'Nie masz aktywnych zakładów.',
	SportsHomePage__NoBettingMessage: 'W tej chwili zakłady są wyłączone',
	SportsHomePage__NoMarketsMessage: 'Brak dostępnych wydarzeń na otwartych rynkach.',
	SwapOddsButton__Text: 'Zamień kurs',
	TakeABreak__1Day: '1 Dzień',
	TakeABreak__Long: 'Długa przerwa',
	TakeABreak__Short: 'Krótka przerwa',
	TakeABreak__TakeBreak: 'Weź {{breakLabel}} Przerwa',
	TakeABreakConfirmation__ChangedMind: 'Zmieniłem zdanie',
	TakeABreakConfirmation__Title: 'Weź {{breakLabel}} Przerwa',
	TakeABreakLong__1YearLabel: '1 rok',
	TakeABreakLong__1YearValue: '1 rok przerwy',
	TakeABreakLong__6MonthsLabel: '6 miesięcy',
	TakeABreakLong__6MonthsValue: '6 miesięcy przerwy',
	TicketHeader__QualifiedMessage: 'Jesteś kwalifikowany.',
	TicketHeader__RemainingTurnoverMessage: 'pozostały obrót, aby się zakwalifikować.',
	TicketPurchase__MaxExceededMessage: 'Przekroczyłeś maksymalną liczbę',
	TimerHelpSection__SessionTime: 'Czas sesji',
	TournamentBets__NoBets: 'Nie masz żadnych zakładów na to wydarzenie.',
	TournamentBets__OnThisEvent: 'Na tym wydarzeniu',
	TournamentBets__OtherUnresulted: 'Inne Nieresonowane',
	TournamentBets__ViewMore: 'Zobacz więcej zakładów turniejowych',
	TournamentEventItem__NoMarketsMessage: 'Rynki nie są jeszcze otwarte na obstawianie',
	TournamentHeaderFooter__NoRanking: 'Nie masz rankingu.',
	TournamentInsights__BackedWinner: 'poparłem zwycięzcę.',
	TournamentInsights__Loser: 'Przepraszam, przegapiłeś!',
	TournamentInsights__Title: 'Insights turniejowe',
	TournamentInsights__Winner: 'Tak! Jesteś zwycięzcą!',
	TournamentPrizePool__Title: 'Pula nagród turniejowych',
	TournamentsHomePage__NoTournamentsMessage:
		'Niestety nie możemy znaleźć żadnych turniejów z tymi kryteriami wyszukiwania.',
	TournamentsHomePage__Previous: 'Poprzednio zgłoszone turnieje',
	TournamentsHomePage__Title: 'Wyścigi Fantasy i zakłady sportowe',
	TournamentSummary__PrizePoolSubText: 'Graj na chwałę, aby być # 1',
	TrendingBets__Heading: 'Trendy w grach',
	TrendingBets__NoBetsMessage: 'Żadne popularne zakłady nie są dostępne',
	TrendingTournaments__NoTournamentsMessage: 'Nie są dostępne żadne popularne turnieje.',
	TrendingTournaments__ViewMore: 'Zobacz więcej popularnych turniejów',
	UnitStake__BetReceipt: 'Zakład Pokwitowanie',
	UnitStake__PendingReview: 'W oczekiwaniu na opinię',
	UnitStake__ViewDetails: 'Wyświetl szczegóły zakładu',
	UnitStake__ViewSelections: 'Wyświetl selekcje',
	UpdatePassword__ConfirmationError: 'Musi pasować do nowego hasła',
	UpdatePassword__ConfirmPassword: 'Potwierdź nowe hasło',
	UpdatePassword__EnterCurrent: 'Wprowadź bieżące hasło',
	UpdatePassword__EnterEmail: 'Wprowadź adres e-mail',
	UpdatePassword__EnterNew: 'Wprowadź nowe hasło',
	UpdatePassword__GenericError: 'Wystąpił błąd. Skontaktuj się z Help Desk.',
	UpdatePassword__NewPassword: 'Twoje nowe hasło',
	UpdatePassword__OldPassword: 'Wprowadź swoje obecne hasło',
	UpdatePassword__PasswordUpdate: 'Twoje hasło zostało zaktualizowane.',
	UpdatePassword__SetNew: 'Ustaw nowe hasło',
	UpdatePassword__ShouldMatch: 'Hasła nie pasują do siebie',
	UpdatePassword__Step: 'Krok {{step}}.',
	UserAccountMenu__ManageAccount: 'Zarządzaj kontem',
	UserAccountPage__AccountProfile: 'Profil konta',
	UserAccountPage__CancelWithdrawal: 'Anuluj wypłatę',
	UserAccountPage__DefaultBetAmounts: 'Ustaw domyślne kwoty zakładów',
	UserAccountPage__DepositReceipt: 'Pokwitowanie depozytu',
	UserAccountPage__MakeDeposit: 'Złożyć depozyt',
	UserAccountPage__ManageAccount: 'Zarządzać kontem',
	UserAccountPage__ResetPassword: 'Zresetuj hasło',
	UserAccountPage__SelfExclusion: 'Samowykluczenie',
	UserAccountPage__SetDepositLimits: 'Ustaw limity wpłat',
	UserAccountPage__SetLossLimits: 'Ustaw limity strat',
	UserAccountPage__SetSpendLimits: 'Ustaw limity wydatków',
	UserAccountPage__TakeABreak: 'Zrób sobie przerwę',
	UserAccountPage__VerifyCC: 'Zweryfikuj karty kredytowe',
	UserAddressForm__Address: 'Adres',
	UserAddressForm__AddressMessage: 'Dodaj adres, aby otrzymywać pieniądze',
	UserAddressForm__ControlLimits: 'Kontroluj swoje limity depozytów i zakładów?',
	UserAddressForm__DepositLimit: 'Limit wpłat ($)',
	UserAddressForm__EnterManually: 'Nie możesz go znaleźć? Wprowadź adres ręcznie',
	UserAddressForm__FirstName: 'Imię',
	UserAddressForm__FirstNameConstraint: '^Imię musi składać się z co najmniej 2 znaków.',
	UserAddressForm__GambleResponsibly: 'GAMA ODPOWIEDZIALNA:',
	UserAddressForm__GoBack: 'Wróć',
	UserAddressForm__LossLimit: 'Limit strat ($)',
	UserAddressForm__Outside: 'Poza Australią',
	UserAddressForm__PlayersNotice: 'Informacje o graczach',
	UserAddressForm__SetLimits:
		'Ustaw limity dla kwot, które chcesz postawić lub wpłacić, aby zachować kontrolę nad swoim kontem. Możesz je zmienić w preferencjach konta po rejestracji.',
	UserAddressForm__Step2of3: 'Krok 2 z 3',
	UserAddressForm__Surname: 'Nazwisko',
	UserAddressForm__SurnameConstraint: '^Nazwisko musi składać się z co najmniej 2 znaków.',
	UserAddressForm__WeeklyLimits: 'Limit na cotygodniowe limity',
	UserDetailsForm__18Plus: '18+',
	UserDetailsForm__AdultsOnly: 'TYLKO DLA DOROSŁYCH',
	UserDetailsForm__DateOfBirth: 'Data urodzenia',
	UserDetailsForm__Email: 'Email (zalogujesz się z tym)',
	UserDetailsForm__HaveAnAccount: 'Mieć konto?',
	UserDetailsForm__Password: 'Hasło (6 znaków + 1 numer min)',
	UserDetailsForm__StartAccount: 'Uruchom swoje konto {{brandName}}',
	UserDetailsForm__Step1of3: 'Krok 1 z 3',
	UserDetailsForm__Terms:
		'Tworząc nowe konto, akceptuję warunki i zasady, politykę prywatności i potwierdzam, że jestem w wieku {{wiek}}. Przestępstwo uprawiania hazardu jest niepełnoletnie.',
	UserDetailsForm__Username: 'Nazwa użytkownika (min. 6 znaków)',
	UserTransactionsContainer__FilterAll: 'Wszystkie operacje',
	UserTransactionsContainer__FilterBets: 'Zakłady umieszczone',
	UserTransactionsContainer__FilterLosing: 'Przegrywanie zakładów',
	UserTransactionsContainer__FilterRefunded: 'Zakłady zwrotu',
	UserTransactionsContainer__FilterTournaments: 'Wpisy turniejowe / zakłady',
	UserTransactionsContainer__FilterWinning: 'Wygrywanie zakładów',
	UserTransactionsContainer__Last: 'Ostatnie {{days}}d',
	UserTransactionsContainer__OpenBets: 'Otwarte zakłady',
	UserTransactionsContainer__Title: 'Panel konta',
	UserTransactionsContainer__UnableToLoadDashboard: 'Nie można załadować pulpitu nawigacyjnego konta',
	UserTransactionsContainer__UnableToLoadTransactions: 'Nie można załadować transakcji na koncie',
	VerificationStatus__Complete: 'Pełna weryfikacja',
	VerificationStatus__Tasks: 'Ukończyłeś {{from}} z {{to}} zadań weryfikacyjnych.',
	VerificationStatus__Title: 'Status weryfikacji',
	VerificationStatus__Why: 'Dlaczego muszę zweryfikować swoją tożsamość?',
	VerifyCardExplanation__AccountVerified: 'Ta wartość zostanie dodana do Twojego konta po zweryfikowaniu karty.',
	VerifyCardExplanation__AreAuthorising: 'autoryzują {{brandName}}',
	VerifyCardExplanation__CentValue:
		'Aby zweryfikować kartę kredytową, musisz uzyskać wartość centową z wyciągu z karty kredytowej i wpisać, kiedy dokonasz następnego depozytu.',
	VerifyCardExplanation__Done: 'Mam to, dzieki!',
	VerifyCardExplanation__GiveAuthorisation: 'udzielić zezwolenia',
	VerifyCardExplanation__MustBeVerified:
		'Twoja karta kredytowa musi zostać zweryfikowana, zanim możliwe będzie przetworzenie jakichkolwiek wniosków o wypłatę.',
	VerifyCardExplanation__Questions: 'W razie pytań prosimy o kontakt z naszym Zespołem Obsługi Klienta.',
	VerifyCardExplanation__VerificationProcess:
		'Ty {{authorisationMessage}}, aby dodać losową wartość w przedziale 1-99 do tej kwoty depozytu, aby przeprowadzić proces weryfikacji.',
	VerifyCardExplanation__VerifyingCreditCard: 'Weryfikacja karty kredytowej',
	WithdrawalPromptContainer__Add: 'Dodaj nowe konto',
	WithdrawalPromptContainer__Amount: 'Kwota wypłaty',
	WithdrawalPromptContainer__Credit: 'Rachunek bankowy kredytu',
	WithdrawalPromptContainer__MaxBalance: 'Twoje maksymalne saldo wypłat to',
	WithdrawalPromptContainer__PendingNotFound: 'Nie znaleźliśmy żadnych oczekujących wypłat.',
	WithdrawalPromptContainer__ProcessedIn: 'Wszystkie wypłaty są przetwarzane w',
	WithdrawalPromptContainer__RemoveBankAccountMessage: 'Za chwilę usuniesz to konto bankowe ze swojego konta.',
	WithdrawalPromptContainer__VerifyMessage:
		'Wszystkie karty kredytowe muszą zostać zweryfikowane przed złożeniem wniosku o wypłatę.',
	WithdrawalPromptContainer__WantTo: 'Chcę się wycofać',
	WithdrawalReceipt__RequestSuccess: 'Otrzymaliśmy Twoją prośbę i zostanie ona przetworzona w godzinach pracy.',
	WithdrawalReceipt__RequestSuccessHeader: 'Wniosek o wypłatę Sukces',
	YouQualifyAlert__Deposit: '*Ale nie dla zakładów z bonusem 1. depozytu',
	YouQualifyAlert__Qualify: 'Zakwalifikujesz się do promocji',

	// All plural definitions including components
	Bet: 'Zakład',
	Bet_0: 'Zakład',
	Bet_1: 'Zakładów',
	Bet_2: 'Zakłady',
	Bet_plural: 'Zakłady',
	BonusBet: 'Zakład bonusowy',
	BonusBet_0: 'Zakład bonusowy',
	BonusBet_1: 'Zakłady premiowe',
	BonusBet_2: 'Zakłady premiowe',
	BonusBet_plural: 'Zakłady premiowe',
	CompetitionsList__BetCount: '{{count}} zakład',
	CompetitionsList__BetCount_0: '{{count}} zakład',
	CompetitionsList__BetCount_1: '{{count}} zakłady',
	CompetitionsList__BetCount_2: '{{count}} zakłady',
	CompetitionsList__BetCount_plural: '{{count}} zakłady',
	Day: 'Dzień',
	Day_0: 'Dzień',
	Day_1: 'Dni',
	Day_2: 'Dni',
	Day_plural: 'Dni',
	market: 'rynek',
	market_0: 'rynek',
	market_1: 'rynki',
	market_2: 'rynków',
	market_plural: 'rynków',
	MarketGroupItem__NumberOfMarkets: '{{count}} dostępny rynek',
	MarketGroupItem__NumberOfMarkets_0: '{{count}} dostępny rynek',
	MarketGroupItem__NumberOfMarkets_1: '{{count}} dostępnych rynków',
	MarketGroupItem__NumberOfMarkets_2: '{{count}} dostępnych rynków',
	MarketGroupItem__NumberOfMarkets_plural: '{{count}} dostępnych rynków',
	Month: 'Miesiąc',
	Month_0: 'Miesiąc',
	Month_1: 'Miesiące',
	Month_2: 'Miesięcy',
	Month_plural: 'Miesięcy',
	Player: 'Gracz',
	Player_0: 'Gracze',
	Player_1: 'Gracze',
	Player_2: 'Gracze',
	Player_plural: 'Gracze',
	Rebuy: 'Ponownie kup',
	Rebuy_0: 'Ponownie kup',
	Rebuy_1: 'Ponownie kupuje',
	Rebuy_2: 'Ponownie kupuje',
	Rebuy_plural: 'Ponownie kupuje',
	Runner: 'Biegacz',
	Runner_0: 'Biegacz',
	Runner_1: 'Biegacze',
	Runner_2: 'Biegacze',
	Runner_plural: 'Biegacze',
	SetTimer__Hour: '{{count}} godzina',
	SetTimer__Hour_0: '{{count}} godzina',
	SetTimer__Hour_1: '{{count}} godziny',
	SetTimer__Hour_2: '{{count}} godziny',
	SetTimer__Hour_plural: '{{count}} godziny',
	Week: 'Tydzień',
	Week_0: 'Tydzień',
	Week_1: 'Tygodnie',
	Week_2: 'Tygodnie',
	Week_plural: 'Tygodnie',
	Winner: 'Zwycięzca',
	Winner_0: 'Zwycięzca',
	Winner_1: 'Zwycięzcy',
	Winner_2: 'Zwycięzcy',
	Winner_plural: 'Zwycięzcy',
	Year: 'Rok',
	Year_0: 'Rok',
	Year_1: 'Lata',
	Year_2: 'Lat',
	Year_plural: 'Lat',
};
