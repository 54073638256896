// Features
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { ui, Input, Popover } from '@tbh/ui-kit';

// Components
import AutoCompleteAddressItem from './AutoCompleteAddressItem/AutoCompleteAddressItem';
// import { AutoCompleteAddressNew } from '../AutoCompleteAddressNew/AutoCompleteAdressNew';

// Constants
const { searchAddressDatabase, retrieveAddress } = require('../../../../../common/Harmony');
const { KEY_DOWN, KEY_ENTER, KEY_TAB, KEY_UP } = require('../../../../../common/constants/Keys');

const StyledAutoCompleteAddress__List = styled('div')`
	${(props) => css`
		label: AutoCompleteAddress__List;

		position: absolute;
		background-color: ${ui(props).color_2};
		border: 1px solid ${ui(props).color_5};
		height: auto;
		left: 0;
		max-height: 300px;
		overflow-y: auto;
		right: 0;
		top: 100%;
		z-index: 10000;
	`};
`;

const StyledAutoCompleteAddress__ListWrapper = styled(Popover)`
	top: 44px;
	left: 0;

	${Popover.StyledPopover__Arrow} {
		display: none;
	}
`;

const StyledAutoCompleteAddress = styled('div')`
	position: relative;

	${Input.StyledMaskedInput}:not(focus),
	${Input.StyledInput}:not(focus) {
		${StyledAutoCompleteAddress__List} {
			display: none;
		}
	}
`;

/**
 * Auto complete address
 */
class AutoCompleteAddress extends Component {
	static propTypes = {
		/** The input name */
		name: PropTypes.string.isRequired,

		/** Action when an address is selected */
		onSelect: PropTypes.func.isRequired,

		/** Extra props for the Input component */
		inputProps: PropTypes.object,

		/** Value for the Input component */
		inputText: PropTypes.string,

		/** Action to fire when the input value is changed */
		onChange: PropTypes.func,

		/** Action to fire when lookup fails for an address */
		onFailedLookup: PropTypes.func,
		/** Action to fire when lookup is successful for an address */
		onSuccessfulLookup: PropTypes.func,
	};

	static defaultProps = {
		inputProps: {},
		inputText: '',
		onChange: () => { },
		onFailedLookup: () => { },
		onSuccessfulLookup: () => { },
	};

	state = {
		// comment
		addresses: [],
		highlighted: 0,
		street: '',
		fullAddress: '',
		text: this.props.inputText,
		prevProps: this.props,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		const prevProps = prevState.prevProps;
		const text = prevProps.inputText !== nextProps.inputText ? nextProps.inputText : prevState.text;
		return {
			text,
			prevProps: nextProps,
		};
	}

	/**
	 * Performed when an address item is selected.
	 *
	 * @param id
	 */
	onAddressSelect = (id) => {

		retrieveAddress(id).then((address) => {
			// Clear all addresses and use found data
			this.setAddresses([], () => {
				if (address.postal === '') {
					this.setState(
						{
							fullAddress: `${address.fullAddress}`,
							street: `${address.streetNumber} ${address.street}`,
						});
				}
				else {
					this.setState(
						{
							fullAddress: `${address.postal}`,
							street: `${address.postal}`,
						});
				}

				const object = {
					...address,
					city: address.locality
				};


				this.props.onSelect(object);
			});
		});


	};

	/**
	 * Performed when keys are pressed inside input box.
	 *
	 * @param event
	 */
	onKeyDown = (event) => {
		let key = event.keyCode;

		const { addresses, highlighted } = this.state;

		if (!addresses.length) {
			return;
		}

		switch (key) {
			case KEY_DOWN:
				this.setState(
					{
						highlighted: Math.min(addresses.length - 1, highlighted + 1),
					},
					this.resolveScroll,
				);
				break;

			case KEY_UP:
				this.setState(
					{
						highlighted: Math.max(0, highlighted - 1),
					},
					this.resolveScroll,
				);
				break;

			case KEY_ENTER:
			case KEY_TAB:
				this.onAddressSelect(addresses[highlighted].eid);
		}
	};

	/**
	 * Performed when text in the input box changes.
	 *
	 * @param name
	 * @param value
	 */
	onTextInput = (name, value) => {
		// Set state with new input text
		this.setState({
			text: value,
		});

		this.props.onChange(name, value);

		// Looks up the given address
		this.searchByAddress(value);
	};

	/**
	 * Scrolls the dropdown based on which item is selected.
	 */
	resolveScroll = () => {
		if (!this.list || !this.item) {
			return;
		}

		const { offsetHeight, offsetTop } = this.item;
		const { clientHeight, scrollTop } = this.list;

		// Scroll up if item is above scroll block
		if (offsetTop < scrollTop) {
			this.list.scrollTop = offsetTop;
		}

		// Scroll down if item is below scroll block
		if (offsetTop + offsetHeight > scrollTop + clientHeight) {
			this.list.scrollTop = offsetTop + offsetHeight - clientHeight;
		}
	};

	/**
	 * Search database for addresses and handle response
	 *
	 * @param {*} address
	 */
	searchByAddress = (address) => {
		const { onFailedLookup, onSuccessfulLookup } = this.props;

		searchAddressDatabase(address)
			.then((response) => {
				this.setAddresses(response, () => onSuccessfulLookup(response));
			})
			.catch((errors) => {
				this.setAddresses([], () => onFailedLookup(errors));
			});
	};

	/**
	 * Sets the internal list of looked-up addresses.
	 *
	 * @param addresses
	 * @param callback
	 */
	setAddresses = (addresses, callback) => {
		this.setState(
			{
				addresses,
				highlighted: 0,
			},
			() => {
				this.resolveScroll();
				callback();
			},
		);
	};

	/**
	 * Sets the ref of the whole container to use as a kind-of ground for focus.
	 *
	 * @param ref
	 */
	setContainerRef = (ref) => {
		this.container = ref;
	};

	/**
	 * Determines and sets the highlighted item given an eid.
	 *
	 * @param eid
	 */
	setHighlightedFromEid = (eid) => {
		this.setState({
			highlighted: this.state.addresses.findIndex((addr) => addr.eid === eid),
		});
	};

	/**
	 * Sets the ref of the highlighted item.
	 *
	 * @param ref
	 */
	setItemRef = (ref) => {
		this.item = ref;
	};

	/**
	 * Sets the ref of the list container.
	 *
	 * @param ref
	 */
	setListRef = (ref) => {
		this.list = ref;
	};

	/**
	 * Clear the addresses
	 */
	clearAddress = () => {
		this.setState({ addresses: [] });
	};

	render() {
		const { name, inputProps } = this.props;
		const { addresses, highlighted, text } = this.state;
		return (
			<StyledAutoCompleteAddress>
				<Input
					autoComplete="false"
					name={name}
					list={name}
					value={this.state.fullAddress}
					onChange={this.onTextInput}
					onKeyDown={this.onKeyDown}	
					{...inputProps}
				/>
				{/* <AutoCompleteAddressNew/> */}
				<StyledAutoCompleteAddress__ListWrapper open={addresses.length > 0} onOutsideClick={this.clearAddress}>
					<StyledAutoCompleteAddress__List id={name} innerRef={this.setListRef}>
						{addresses.map((address, index) => {

							const active = highlighted === index;

							return (
								<div key={address.eid} ref={active && this.setItemRef}>
									<AutoCompleteAddressItem
										active={active}
										id={address.id}
										onClick={this.onAddressSelect}
										text={address.fullAddress}
									/>
								</div>
							);
						})}
					</StyledAutoCompleteAddress__List>
				</StyledAutoCompleteAddress__ListWrapper>
			</StyledAutoCompleteAddress>
		);
	}
}

export default AutoCompleteAddress;
