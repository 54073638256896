import React from 'react';
import {
	StyledfeatureRaces_list_content,
	StyledFeaturesMain,
	StyledRacingFeature_list_header_racedeails,
	StyledRacingFeature_topSelectionItemDetails,
} from '../../components/features/Racing/RacingFeature/RacingFeature.styled';
import { StyledMajor_Mainheader_h2 } from './../../components/features/Racing/RacingFeature/RacingFeature.styled';
import styled, { css } from 'react-emotion';
import {
	StyledRacingFeature_races,
	StyledFeature_races_raceDetails,
	StyledFeature_races_raceTime,
	StyledFeature_races_timeButton,
} from '../../components/features/Racing/FeatureRaceList/FeatureRaceList.styled';
import { MEDIA_QUERIES } from '../../constants/themes';

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 10px;
	width: 100%;
`;

const HeaderContainer = styled('div')`
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;
	width: 100%;
	@media ${MEDIA_QUERIES.tabletAndUp} {
		grid-template-columns: 1fr 1fr;
	}
`;

const WrapperContent = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-radius: 4px;
	padding: 10px;
	gap: 10px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
`;

const TopContent = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const HeaderContent = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	width: 100%;
`;

const SelectionContainer = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
`;

const LoadingBlock = styled('div')(
	(props) => css`
		width: ${props.width ? props.width : '100%'};
		height: ${props.height ? props.height : '100%'};
		border-radius: ${props.radius ? props.radius : '4px'};
		background-color: rgb(54, 69, 79);
		animation: changeColor 1s infinite;
	`,
);

const BlockPrice = styled('div')`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	gap: 4px;
	width: 90px;
	height: 40px;
`;

const FeatureRacesContainerLoading = ({ componentClasses, size }) => {
	return (
		<StyledFeaturesMain className={componentClasses} size={size}>
			<StyledRacingFeature_topSelectionItemDetails>
				<StyledRacingFeature_list_header_racedeails>
					<StyledMajor_Mainheader_h2>FEATURED Events</StyledMajor_Mainheader_h2>
				</StyledRacingFeature_list_header_racedeails>
			</StyledRacingFeature_topSelectionItemDetails>

			<Container>
				<HeaderContainer>
					<WrapperContent>
						<TopContent>
							<HeaderContent style={{ justifyContent: 'flex-start' }}>
								<LoadingBlock width="60px" height="60px" />
							</HeaderContent>
							<HeaderContent>
								<LoadingBlock width="100%" height="20px" />
								<LoadingBlock width="80%" height="20px" />
								<LoadingBlock width="70%" height="15px" />
							</HeaderContent>
							<HeaderContent style={{ justifyContent: 'flex-end' }}>
								<LoadingBlock width="60px" height="60px" />
							</HeaderContent>
						</TopContent>

						<LoadingBlock width="100%" height="60px" />
					</WrapperContent>

					<WrapperContent>
						<TopContent>
							<LoadingBlock width="40px" height="15px" />
							<BlockPrice>
								<LoadingBlock width="20px" height="20px" radius="99999px" />
								<LoadingBlock width="20px" height="20px" radius="99999px" />
							</BlockPrice>
						</TopContent>

						{[...Array(3).keys()].map((item, index) => (
							<TopContent key={index}>
								<SelectionContainer>
									<LoadingBlock width="50px" height="40px" />
									<HeaderContent style={{ gap: '2px' }}>
										<LoadingBlock width="90px" height="10px" />
										<LoadingBlock width="80px" height="8px" />
									</HeaderContent>
								</SelectionContainer>

								<BlockPrice>
									<LoadingBlock width="36px" height="24px" />
									<LoadingBlock width="36px" height="24px" />
								</BlockPrice>
							</TopContent>
						))}
					</WrapperContent>
				</HeaderContainer>
			</Container>

			<StyledfeatureRaces_list_content>
				{[...Array(3).keys()].map((item, index) => (
					<div key={'feature_race' + item} size={size}>
						<StyledRacingFeature_races size={size} style={{ padding: '4px' }}>
							<StyledFeature_races_raceDetails style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
								<LoadingBlock width="36px" height="16px" />
								<LoadingBlock width="60%" height="16px" />
							</StyledFeature_races_raceDetails>

							<StyledFeature_races_raceTime size={size}>
								<LoadingBlock width="60%" height="16px" />
							</StyledFeature_races_raceTime>
							<StyledFeature_races_timeButton size={size}>
								<LoadingBlock width="36px" height="24px" />
							</StyledFeature_races_timeButton>
						</StyledRacingFeature_races>
					</div>
				))}
			</StyledfeatureRaces_list_content>
		</StyledFeaturesMain>
	);
};

export default FeatureRacesContainerLoading;
