export const leaderKey = 'leader';
export const leaderTitle = 'Leader';
export const offpaceKey = 'offpace';
export const offpaceTitle = 'Off-pace';
export const midfieldKey = 'midfield';
export const midfieldTitle = 'Midfield';
export const backmarkerKey = 'backmarker';
export const backmarkerTitle = 'Backmarker';
export const firstStarterKey = 'firstStarter';
export const firstStarterTitle = 'First-Starter';

export const speedMapLegendGroups = {
	[firstStarterKey]: firstStarterTitle,
	[backmarkerKey]: backmarkerTitle,
	[midfieldKey]: midfieldTitle,
	[offpaceKey]: offpaceTitle,
	[leaderKey]: leaderTitle,
};
