// Data
export const RACING_HOME_SET_SELECTED_BET_TYPE = 'RACING_HOME_SET_SELECTED_BET_TYPE';
export const RACING_HOME_SET_SELECTED_DATE = 'RACING_HOME_SET_SELECTED_DATE';
export const GRS_RACING_HOME_SET_SELECTED_TYPE = 'GRS_RACING_HOME_SET_SELECTED_TYPE';
export const GRS_RACING_HOME_SET_RACE_TYPE_FILTERS = 'GRS_RACING_HOME_SET_RACE_TYPE_FILTERS';
export const RACING_HOME_RESET_FILTERS = 'RACING_HOME_RESET_FILTERS';
export const GRS_RACING_HOME_RESET_FILTERS = 'GRS_RACING_HOME_RESET_FILTERS';
export const RACING_HOME_SET_SHOWING_MEETINGS = 'RACING_HOME_SET_SHOWING_MEETINGS';
export const RACING_HOME_SET_SHOWING_NTJ_RACES = 'RACING_HOME_SET_SHOWING_NTJ_RACES';
export const RACING_HOME_SET_EXOTIC_POOLS = 'RACING_HOME_SET_EXOTIC_POOLS';
export const RACING_HOME_SET_BET_BUILDER_MEETING = 'RACING_HOME_SET_BET_BUILDER_MEETING';

// Loading Masks
export const RACING_HOME_SET_CONTAINER_LOADING_MASK = 'RACING_HOME_SET_CONTAINER_LOADING_MASK';
export const RACING_HOME_SET_MEETING_LOADING_MASK = 'RACING_HOME_SET_MEETING_LOADING_MASK';
export const RACING_HOME_SET_RACE_LOADING_MASK = 'RACING_HOME_SET_RACE_LOADING_MASK';

// Named Batch Actions
export const RACING_HOME_NAVIGATE_TO_MEETING__BATCH = 'RACING_HOME_NAVIGATE_TO_MEETING__BATCH';
export const RACING_HOME_NAVIGATE_TO_NTJ_RACE__BATCH = 'RACING_HOME_NAVIGATE_TO_NTJ_RACE__BATCH';
export const RACING_HOME_NAVIGATE_TO_RACE__BATCH = 'RACING_HOME_NAVIGATE_TO_RACE__BATCH';
export const RACING_HOME_NAVIGATE_TO_HOME__BATCH = 'RACING_HOME_NAVIGATE_TO_HOME__BATCH';
export const RACING_HOME_LOADING__BATCH = 'RACING_HOME_LOADING__BATCH';
export const RACING_HOME_DONE__BATCH = 'RACING_HOME_DONE__BATCH';

// Pusher
export const PUSHER_ACTION_MEETING_UPDATE = 'PUSHER_ACTION_MEETING_UPDATE';
export const PUSHER_ACTION_RACE_UPDATE = 'PUSHER_ACTION_RACE_UPDATE';
export const PUSHER_ACTION_ODDS_UPDATE = 'PUSHER_ACTION_ODDS_UPDATE';
export const PUSHER_ACTION_NTJ_RACES = 'PUSHER_ACTION_NTJ_RACES';
export const PUSHER_ACTION_MEETING_RACES = 'PUSHER_ACTION_MEETING_RACES';

// Exotic Pool Filters
export const RACING_EXOTIC_QUADDIE_TOGGLE = 'RACING_EXOTIC_QUADDIE_TOGGLE';
export const RACING_EXOTIC_DAILYDOUBLE_TOGGLE = 'RACING_EXOTIC_DAILYDOUBLE_TOGGLE';
export const RACING_EXOTIC_SELECTIONS_TOGGLE = 'RACING_EXOTIC_SELECTIONS_TOGGLE';

//
export const BETBUILDER_HOME_SET_BET_BUILDER_MEETING = 'BETBUILDER_HOME_SET_BET_BUILDER_MEETING';

export const BET_QUADDIES_LEG_SELECTIONS = 'BET_QUADDIES_LEG_SELECTIONS';
export const BET_QUADDIES_REMOVE_LEG_SELECTIONS = 'BET_QUADDIES_REMOVE_LEG_SELECTIONS';
export const BET_QUADDIES_RESET_LEG_SELECTIONS = 'BET_QUADDIES_RESET_LEG_SELECTIONS';

export const SET_SELECTED_QUADDIES_RACES = 'SET_SELECTED_QUADDIES_RACES';

export const SET_REQUEST_ID = 'SET_REQUEST_ID';

export const RACING_HOME_SET_BET_TYPES = 'RACING_HOME_SET_BET_TYPES';
