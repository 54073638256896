import { denormalizeArticles } from '../../../store/entities/schemas/ArticleSchema';
import { PROMOTIONS_BASE_URL } from '../../../routers/PromotionsRouterConstants';
import env from '../../../common/EnvironmentVariables';

/**
 * Returns the array of promotions ordered by date
 *
 * @param state
 * @returns {Array}
 */
export const getPromotionsSortedByDate = (state) => {
	const denormalizedArticles = denormalizeArticles(state.entities);
	const sortedArticles = denormalizedArticles.sort((a, b) => {
		// Date.parse requires the timestamp to be prefixed with a T
		return new Date(b.date.replace(' ', 'T')) - new Date(a.date.replace(' ', 'T'));
	});

	return buildArticlesArray(sortedArticles);
};

/**
 * Restructure a promotion object into a more usable structure
 *
 * @param promotion
 * @returns {Object}
 */
export const buildArticleArray = (promotion) => {
	// Build the social link url
	const socialLinkUrl = `${env('websiteUrl')}/${PROMOTIONS_BASE_URL}`;

	/**
	 * This just sets the custom_fields to null arrays if they don't exist, as they come from non-required props
	 */
	const imageUrl =
		(promotion.thumbnail_images && promotion.thumbnail_images.full && promotion.thumbnail_images.full.url) || null;

	/**
	 * This just sets the custom_fields to null arrays if they don't exist, as they come from non-required props
	 */
	const customFields = promotion.custom_fields || {
		how_it_works: [''],
		claim_url_text: [null],
		claim_url: [null],
		terms_and_conditions: [null],
	};

	// return the new restructured promotion object
	return {
		id: promotion.id,
		title: promotion.title,
		slug: promotion.slug,

		image: imageUrl,
		mainContent: customFields.how_it_works[0],
		mainContentTitle: 'How does it work?',
		message: promotion.content,
		actionText: customFields.claim_url_text[0],
		actionData: customFields.claim_url[0],
		socialLink: socialLinkUrl,
		termsAndConditions: customFields.terms_and_conditions[0],
	};
};

/**
 * Rebuild the promotions array with the new promotion object structure
 *
 * @param promotions
 * @returns {Array}
 */
const buildArticlesArray = (promotions) => {
	return promotions.map((promotion) => buildArticleArray(promotion));
};
