/**
 * Bet status definitions
 */
export const BET_UNRESULTED_STATUS = 'unresulted';
export const BET_PENDING_STATUS = 'pending';
export const BET_PROCESSING_STATUS = 'processing';
export const BET_REJECTED_STATUS = 'rejected';
export const BET_FULL_REFUND_STATUS = 'fully-refunded';
export const BET_PARTIAL_REFUND_STATUS = 'partially-refunded';
export const BET_CANCELLED_STATUS = 'cancelled';
export const BET_PAID_STATUS = 'paid';
export const BET_WINNING_STATUS = 'winning';

/**
 * Available bet status definitions
 */
export const BET_RESULT_STATUSES = [
	BET_UNRESULTED_STATUS,
	BET_PENDING_STATUS,
	BET_PROCESSING_STATUS,
	BET_REJECTED_STATUS,
	BET_FULL_REFUND_STATUS,
	BET_PARTIAL_REFUND_STATUS,
	BET_CANCELLED_STATUS,
	BET_PAID_STATUS,
	BET_WINNING_STATUS,
];