// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

// Components
import { FlexText, Text, PlotElements } from '@tbh/ui-kit';

/**
 * Bet header for multi bets
 *
 * @param props
 * @returns {XML}
 * @constructor
 */
const BetSummaryBetHeader = (props) => {
	const { betSubtitleLeft, betSubtitleRight, betSubtitleRightAmount, className, onClick } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const renderSubtitles = () => {
		if (betSubtitleLeft && !betSubtitleRight) {
			return (
				<Text size="-2" paragraph strong>
					{betSubtitleLeft}
				</Text>
			);
		} else if (betSubtitleRight && !betSubtitleLeft) {
			return (
				<Text size="-2" align="right" paragraph strong>
					{betSubtitleRight}&nbsp;
					<Text size="-2" type="success" tag="span" strong>
						{betSubtitleRightAmount}
					</Text>
				</Text>
			);
		} else {
			return (
				<FlexText className={componentClasses}>
					<Text size="-2" paragraph strong>
						{betSubtitleLeft}
					</Text>
					<Text size="-2" paragraph strong>
						<PlotElements align="end">
							<span>{betSubtitleRight}&nbsp;</span>
							<Text size="-2" type="success" tag="span" strong>
								{betSubtitleRightAmount}
							</Text>
						</PlotElements>
					</Text>
				</FlexText>
			);
		}
	};

	return <div onClick={onClick}>{renderSubtitles()}</div>;
};

BetSummaryBetHeader.propTypes = {
	/** Text for the left side of the header */
	betSubtitleLeft: PropTypes.node,

	/** Text for the right side of the header */
	betSubtitleRight: PropTypes.node,

	/** Bet earnings */
	betSubtitleRightAmount: PropTypes.node,

	/** Extra classes */
	className: PropTypes.string,

	/** Actions for when clicking on the node */
	onClick: PropTypes.func,
};

BetSummaryBetHeader.defaultProps = {
	betSubtitleLeft: null,
	betSubtitleRight: null,
	betSubtitleRightAmount: null,
	className: '',
	onClick: undefined,
};

export default BetSummaryBetHeader;
