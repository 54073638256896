import styled, { css } from 'react-emotion';
import { brand, typography } from '@tbh/ui-kit';
import { COLORS, WEIGHTS, TYPE_SCALE } from '../../../constants/themes';

export const Card = styled.div`
	border-radius: 4px;
	display: block;
	box-shadow: 2px 4px 12px hsl(0deg 0% 0% / 0.1);
`;

export const CardHeader = styled('div')(
	(props) => css`
		background-color: ${brand(props).tertiary_color ? brand(props).tertiary_color : '#161A1D'};
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
	`,
);

export const CardBody = styled.div`
	background-color: ${COLORS.white};
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	text-align: left;
	padding: 12px;
`;

export const CardTitle = styled('h4')(
	(props) => css`
		color: ${COLORS.white};
		font-weight: ${WEIGHTS.bold};
		font-size: ${TYPE_SCALE.header4};
		font-family: ${typography(props).base_font};
		text-transform: uppercase;
	`,
);
