import { Text } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';

// Can have more than  top number 

// win up to 1
// Top 2 up to 2
// Top 3 up to 3
// Top 4 up to 4

const Spining = styled.div`

    width: 20px;
    height: 20px;
    border: 4px solid #f2f2f2;
    border-top: 4px solid #850000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
`;

export const LoadingSpining = () => {
    return (
        <div style={{marginLeft:8,width:20}}>
            <Spining/>
        </div>
    );
};

const RaceLegsPrice = ({ price, legs }) => {

    if (!legs.length || legs.length <= 1) return null;

    return (
        <div style={{display:'flex',flexDirection:'row'}}>
            <Text size="-1" className={css`color:white!important;`}>LEGS {legs.length} ODDS <span style={{fontWeight:'bold'}}>{price && price}</span></Text>
            { !price &&<LoadingSpining />}
        </div>
    );
};

export default RaceLegsPrice;