/**
 * Libs
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, spacings, Ticker, Icon  } from '@tbh/ui-kit';
import { slugify } from '../../../../legacy/core/format';
/**
 * Components
 */
import Carousel from '../../Carousel/Carousel';

import GRSNextSportsFilter from './GRSNextSportsFilter/GRSNextSportsFilter';
import { filterNextToJumpSports } from '../../../../store/entities/selectors/NextJumpGRSSportsSelectors';
import NJSportsCardContainer from '../../../../containers/Sports/NJSportsCardContainer/NJSportsCardContainer';
import NtjSportsCarouselItem from '../NtjSportsCarouselItem/NtjSportsCarouselItem';


// Size at which the mode should change over
const modeChangeOverSize = 751;

const StyledGRSNextSportsList__Carousel = styled(Carousel)(

	(props) => css`
		label: GRSNextSportsList__Carousel;

		flex: 1;
		height: auto;
		overflow: hidden;
	`,
	(props) =>
		media(
			css`
				margin-left: ${spacings(props).compact}px;
			`,
			modeChangeOverSize,
		),

);

const StyledGRSNextSportsList = styled('div')(
    (props) => css`
		label: GRSNextSportsList_Wrapper;

		display: flex;
		flex-wrap: wrap;
		margin:${spacings(props).tight}px;
        `,
);

const StyledGRSNextSportsList_MainSection = styled('div')(
    (props) => css`
		label: GRSNextSportsList_MainSection;

		padding:${spacings(props).tight}px;
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        height: auto;
        width: 100%;
        `,
);

const StyledSportNextJumpHomePage__Filters_section = styled('div')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__Filters_section;

			padding: ${spacings(props).tight}px;
			width:100%;
			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;
		`};

	${media(
		(props) =>
			css`
				display: flex;
				padding: ${spacings(props).tight}px;
			`,
		500,
	)};
`;



const StyledGRSNextSportsListWrapper = styled('div')(
    (props) => css`
        label: GRSNextSportsListWrapper;


        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 0 0px;
        background: #f7f8f8;
        height: auto;
        border-radius: 1px;
        box-sizing: border-box;
		width: 280px;
		margin:3px;
        `,
);


const StyledGRSNextSportsLis__CarouselSportsItem = styled(NtjSportsCarouselItem)(
	(props) => css`
		label: GRSNextSportsLis__CarouselSportsItem;
		min-height:100px;
		width: auto;
		box-sizing: border-box;
		padding:${spacings(props).tight}px;
	`,
);

const StyledGRSNextSportsLis_carousel_body_content= styled('div')(
	(props) => css `
	label: GRSNextSportsLis_carousel_body_content;

	color:#2E2E2E;
	width:280px;
	padding: ${spacings(props).tight}px;

	`,
);

const StyleNjSportCardContainer = styled(NJSportsCardContainer)(
	(props) => css `
	 label: GRSNextSportsLis__NjSportCardContainer;
	`
);

class GRSNextSportsList extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func,
		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}).isRequired,

		/** List of the next sports */
		items: PropTypes.arrayOf(
			PropTypes.shape({
				/** The Event ID for the sport */
				event_id: PropTypes.number.isRequired,

				/** The competition name that the event belongs to */
				competition_name: PropTypes.string.isRequired,

				/** The event name that the sport belongs to */
				event_name: PropTypes.string.isRequired,

				/** Time until the event starts */
				to_go: PropTypes.string.isRequired,

				/** The sport's name */
				sport_name: PropTypes.string.isRequired,

				grsevents: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.number.isRequired,
						name: PropTypes.string.isRequired,
						markets: PropTypes.array,
						products: PropTypes.array,
				}),
				).isRequired,

			}),
		).isRequired,



		/** Handle the sport item being clicked */
		onItemClick: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Action to track when a filter is applied */
		trackOnFilterClick: PropTypes.func,

		/** Whether or not to hide the next to jump filter */
		hideSportNextJumpFilter: PropTypes.bool,

		sportsFilterGroup : PropTypes.array.isRequired,

		sportIds: PropTypes.array.isRequired,

		/** Navigate to the event page */
		onGoToEvent: PropTypes.func,


	};

	static defaultProps = {
		className: null,
		onItemClick: () => undefined,
		trackOnFilterClick: () => undefined,
    	hideSportNextJumpFilter: false,
		onGoToEvent: () => undefined,
		t: undefined,
	};

	state = {
		// initially set all the type filters active
		typeFilter: new Set([]),

	};

	/**
	 * The onClick on the Carousel item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *
	 * @param id
	 */
	handleEventClick = (eventId) => {
		let event = this.props.items.find((item) => item.event_id === eventId);
		this.props.onItemClick(event.competition_id, event.event_id);
	};

	/**
	 * The onClick on the Carousel item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *  go to  event
	 * @param eventId
	 */
	handleClick = (eventId) => {
		const event = this.props.items.find((item) => item.event_id === eventId);
		this.props.onGoToEvent(event.competition_id, event.event_id);

	};

	/**
	 * Handles the clicking/selecting of the  sportId filters
	 * @param sportId
	 */
	handleOnFilterClick = (filterSportId) => {
		this.saveTypeToFilters(filterSportId);
		this.props.trackOnFilterClick(filterSportId);
	};

	/**
	 * Saves the sport id filters to state
	 * @param filterSportId
	 */
	saveTypeToFilters = (filterSportId) => {
		let sportIdLists = new Set(this.state.typeFilter);

		if (!filterSportId) {
			return;
		}

		if (sportIdLists.has(filterSportId)) {
			sportIdLists.delete(filterSportId);
		}
		else {
			sportIdLists.add(filterSportId);
		}

		this.setState({
			typeFilter: sportIdLists,
		});
	};

	/**
	 * Checks whether the startTime is around 5min from now and returns period to set Ticker interval.
	 *
	 * @param {string} startTime YYYY-11-29T16:32:42+11:00
	 * @return {number|null} - ticker period
	 */
	getTickerInterval = (startTime = null) => {
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}
		// null makes Ticker clear interval.
		return null;
	};




	render() {
		const { className, hideSportNextJumpFilter,  sportsFilterGroup, size} = this.props;

		const items =filterNextToJumpSports(this.props.items, Array.from(this.state.typeFilter));


		const componentClasses = cx({
			[className]: className,
		});

		const blockClasses = cx({
			[className]: className,
		});

		const isSingleEvent = items.length === 1;

		if (items.length === 0) {
			return false;
		}

		return(
			<StyledGRSNextSportsList_MainSection>
				<StyledSportNextJumpHomePage__Filters_section>
					{!hideSportNextJumpFilter && (
					<GRSNextSportsFilter
				onFilterItemClick={this.handleOnFilterClick}
				sportIds={Array.from(this.state.typeFilter)}
				sportFilter= {sportsFilterGroup}
				size={size}
          />
						)}
				</StyledSportNextJumpHomePage__Filters_section>


				<StyledGRSNextSportsList className={componentClasses}>
					<StyledGRSNextSportsList__Carousel  nextButton prevButton freeMode buttonStyle="1" className={blockClasses}>
						{
							items.map(
								(item) => (
									<StyledGRSNextSportsListWrapper key={item.event_id}>
										<Ticker every={this.getTickerInterval(item.start_time)}>
											<StyledGRSNextSportsLis__CarouselSportsItem
												id={item.event_id}
												icon={<Icon color="white" icon={slugify(item.sport_name)} size="2"/>}
												eventName={item.event_name}
												competitionName={item.competition_name}
												country={item.country}
												startTime={item.start_time}
												action={this.handleClick}
												sportName={item.sportName}
											/>


											<StyledGRSNextSportsLis_carousel_body_content>
												<StyleNjSportCardContainer
													id={item.event_id}
													event={item}
													events={Array.isArray(item.grsevents[0]) ? item.grsevents[0] : [] }
													eventId={item.event_id}
													eventName={item.event_name}
													markets={Array.isArray(item.grsevents[0].markets) ? item.grsevents[0].markets : [] }
													onEventClick={!isSingleEvent ? this.handleNavigateToEvent : null}
													action={this.handleClick}
												/>
											</StyledGRSNextSportsLis_carousel_body_content>
										</Ticker>
									</StyledGRSNextSportsListWrapper>
								),this,
							)
						}
					</StyledGRSNextSportsList__Carousel>

				</StyledGRSNextSportsList>
			</StyledGRSNextSportsList_MainSection>
		);
	}
}

export default GRSNextSportsList;


