import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErrorPage404 from '../../../components/ui-components/ErrorPage/ErrorPage404/ErrorPage404';

import { MOBILE_MAX_WIDTH } from '../../../common/constants/Breakpoints';

import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import { getNavigationItems } from '../../../store/entities/selectors/NavigationItemsSelectors';

class Error404Page extends React.Component {
	static propTypes = {
		/** List of navigation items to show */
		navigationItems: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				route: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired,
				icon: PropTypes.string,
			}),
		).isRequired,

		/** Function to mark that the container has stopped loading */
		setContainerHasLoaded: PropTypes.func.isRequired,
	};

	componentDidMount() {
		if (this.props.setContainerHasLoaded) {
			this.props.setContainerHasLoaded();
		}

	}

	render() {
		return <ErrorPage404 navigationItems={this.props.navigationItems} />;
	}
}

const mapStateToProps = (state) => {
	const navigationList = getNavigationItems(state);
	const isMobileWidth = window.innerWidth < MOBILE_MAX_WIDTH;
	const navigationItems = isMobileWidth ? navigationList.mobileItems : navigationList.primaryItems;

	return {
		navigationItems,
	};
};

export default createErrorBoundary(connect(mapStateToProps)(Error404Page));
