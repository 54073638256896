import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { brand, spacings, ui, LightenDarkenColor, media, Image } from '@tbh/ui-kit';

import { Text } from '@tbh/ui-kit';

import { BET_TYPE_WIN } from '../../../../../../store/entities/constants/BetConstants';
import { GENERIC_SILK, RACING_TYPES_LOOKUP, RACING_GREYHOUNDS_TYPE } from '../../../../../../common/constants/Racing';

import {
	determineSpeedColour,
	getBetProduct,
	determinePositionPercent,
} from '../../../../../../pages/Racing/RacingHome/racingSelectorsGRS';

const StyledEarlySpeedItem__Barrier = styled(Text)(
	(props) => css`
		label: EarlySpeedItem__Barrier;

		min-width: ${spacings(props).cozy}px;
		text-align: center;
		margin-right: ${spacings(props).compact}px;
	`,
);

const StyledEarlySpeedItem__Silk = styled('div')(
	(props) => css`
		label: EarlySpeedItem__Silk;

		position: absolute;
		right: -${spacings(props).cozy}px;
		top: -${spacings(props).tight}px;
		height: ${spacings(props).comfortable}px;
		width: ${spacings(props).comfortable}px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${ui(props).color_2};
		border: 2px solid ${ui(props)[props.speedColour]? ui(props)[props.speedColour] : 'rgba(0, 0, 0, 0.3)'};

		${props.racingType !== RACING_GREYHOUNDS_TYPE &&
			css`
				border-radius: 50%;
			`};
	`,
	media(
		(props) => css`
			height: 28px;
			width: 28px;
			top: -5px;
			right: -${spacings(props).comfortable}px;
		`,
		380,
	),
);

const StyledEarlySpeedItem__Bar = styled('div')(
	(props) => css`
		label: EarlySpeedItem__Bar;

		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		align-self: stretch;
		width: ${props.barWidthSpace - 48}px; // Prevent the bar overflowing
		background-color: ${ui(props)[props.speedColour]? ui(props)[props.speedColour] : 'rgba(0, 0, 0, 0.3)'};
		transition: width 500ms ease-in-out;
	`,
);

const StyledEarlySpeedItem__Name_Inside = styled(Text)(
	(props) => css`
		label: EarlySpeedItem__Name_Inside;
		color: #ffffff;
		font-weight: 500;
		margin-right: 12px;
		margin-left: ${spacings(props).tight}px;
	`,
	media(
		css`
			margin-right: 20px;
		`,
		380,
		{ sizeKey: 'mediaSize' },
	),
);

const StyledEarlySpeedItem__Name_Outside = styled(Text)(
	(props) => css`
		label: EarlySpeedItem__Name_Outside;
		display: flex;
		flex-direction: column;
		gap: 2px;
		margin-left: ${spacings(props).comfortable}px;
	`,
	media(
		(props) => css`
			margin-left: ${spacings(props).spacious}px;
		`,
		380,
		{ sizeKey: 'mediaSize' },
	),
);

const StyledEarlySpeedItem = styled('div')(
	(props) => css`
		label: EarlySpeedItem;

		position: relative;
		display: flex;
		align-items: center;
		// height: ${spacings(props).cozy}px;
		margin-right: ${spacings(props).cozy}px;
		height: 14px;
		${props.betProduct &&
			css`
				cursor: pointer;

				&:hover {
					${EarlySpeedItem.StyledEarlySpeedItem__Bar} {
						background-color: ${ui(props)[props.speedColour]
							? LightenDarkenColor(ui(props)[props.speedColour], 15)
							: 'rgba(0, 0, 0, 0.3)'};
					}

					${EarlySpeedItem.StyledEarlySpeedItem__Barrier} {
						color: ${brand(props).color_1};
					}
				}
			`};
	`,
	media(
		css`
			height: 14px;
		`,
		380,
	),
);

const EarlySpeedItem = (props) => {
	/** Approximate character width, used for calculating runner name size */
	const CHARACTER_WIDTH = 13;

	const {
		className,
		selection,
		backingPosition,
		boundingWidth,
		handleClickSelection,
		isBettingAvailable,
		raceProducts,
		size,
	} = props;

	const betProduct = getBetProduct(raceProducts, selection.prices, BET_TYPE_WIN, isBettingAvailable);

	/** Handle when a selection is clicked on */
	const onClickItem = () => {
		handleClickSelection(selection.id, betProduct.product_id, BET_TYPE_WIN);
	};

	const racingType = RACING_TYPES_LOOKUP[selection.type_code];

	const componentClasses = cx({
		[className]: className,
	});

	const selectionName = `${selection.number}. ${selection.name}`;

	// Determine how far the bar should flow across
	// The lower the early_speed_position, the farther to the right it is
	const barWidthPerc = determinePositionPercent(selection.early_speed_position, backingPosition);

	// Determine how much space the bar fills up
	const barWidthSpace = boundingWidth * barWidthPerc;

	// Determine approximate space of how much the selection name fills up
	const selectionNameSpace = selectionName.length * CHARACTER_WIDTH;

	// If the component size minus the bar size is greater than the selection name size,
	// or if the bar size is smaller than the outside size, then the selection name belongs outside
	const spaceOutside = boundingWidth - barWidthSpace;
	const nameOutside = spaceOutside > selectionNameSpace || spaceOutside > barWidthSpace;

	// Speed color for the selection
	const speedColour = determineSpeedColour(
		selection.early_speed_position,
		backingPosition,
		!selection.last_starts_string,
	);

	return (
		<StyledEarlySpeedItem
			className={componentClasses}
			onClick={betProduct ? onClickItem : null}
			betProduct={betProduct}
			speedColour={speedColour}
			size={size}
		>
			<StyledEarlySpeedItem__Barrier size="-1" strong>
				{selection.barrier}
			</StyledEarlySpeedItem__Barrier>
			<StyledEarlySpeedItem__Bar speedColour={speedColour} barWidthSpace={barWidthSpace}>
				{!nameOutside && (
					<StyledEarlySpeedItem__Name_Inside
						size={size.width < 380 ? '-3' : '-2'}
						whiteSpace="nowrap"
						textOverflow="ellipsis"
						mediaSize={size}
						strong
					>
						{selectionName}
					</StyledEarlySpeedItem__Name_Inside>
				)}
				<StyledEarlySpeedItem__Silk speedColour={speedColour} racingType={racingType} size={size}>
					<Image
						height={size.width < 380 ? 18 : 20}
						src={selection.silk}
						alt={`${selection.name} racing silk`}
						substituteImageSrc={GENERIC_SILK}
					/>
				</StyledEarlySpeedItem__Silk>
			</StyledEarlySpeedItem__Bar>
			{nameOutside && (
				<StyledEarlySpeedItem__Name_Outside
					size={size.width < 380 ? '-3' : '-2'}
					whiteSpace="nowrap"
					textOverflow="ellipsis"
					mediaSize={size}
					strong
				>
					{selectionName}
				</StyledEarlySpeedItem__Name_Outside>
			)}
		</StyledEarlySpeedItem>
	);
};

EarlySpeedItem.propTypes = {
	/** Handle when a selection is clicked on */
	handleClickSelection: PropTypes.func.isRequired,

	/** The selection to display */
	selection: PropTypes.shape({
		/** The early speed position to display */
		early_speed_position: PropTypes.number.isRequired,
	}).isRequired,

	/** The width of the parent container - used for calculating space */
	boundingWidth: PropTypes.number.isRequired,

	/** The farthest back position in the race */
	backingPosition: PropTypes.number.isRequired,

	/** List of betting products on the race */
	raceProducts: PropTypes.arrayOf(
		PropTypes.shape({
			available: PropTypes.bool.isRequired,
			bet_type: PropTypes.string.isRequired,
			fixed: PropTypes.bool.isRequired,
			product_id: PropTypes.number.isRequired,
		}),
	),

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Whether or not betting is allowed on the race */
	isBettingAvailable: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

EarlySpeedItem.defaultProps = {
	className: '',
	raceProducts: [],
	isBettingAvailable: true,
};

EarlySpeedItem.StyledEarlySpeedItem__Barrier = StyledEarlySpeedItem__Barrier;
EarlySpeedItem.StyledEarlySpeedItem__Bar = StyledEarlySpeedItem__Bar;

export default EarlySpeedItem;
