import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

import { ui, typography, Text, Panel } from '@tbh/ui-kit';
import { OFFLINE_MESSAGE } from '../../../../common/constants/Application';

const StyledOffline = styled(Panel)(
	(props) => css`
		label: Offline;

		font-family: ${typography(props).base_font};
		height: 100%;
		background: ${props.backgroundColour ? props.backgroundColour : ui(props).color_2};
	`,
);

const Offline = (props) => {
	const { className, backgroundColour, textColour, message } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledOffline
			className={componentClasses}
			padding={Panel.padding.SPACING_COZY}
			backgroundColour={backgroundColour}
		>
			<Text
				align="center"
				italic
				className={
					textColour &&
					css`
						color: ${textColour};
					`
				}
			>
				{message}
			</Text>
		</StyledOffline>
	);
};

Offline.propTypes = {
	/** Background colour for offline mode */
	backgroundColour: PropTypes.string,

	/** Text colour for the message in offline mode */
	textColour: PropTypes.string,

	/** Message to display if the app is set to offline mode */
	message: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

Offline.defaultProps = {
	backgroundColour: null,
	textColour: null,
	message: OFFLINE_MESSAGE,
	className: '',
};

export default Offline;
