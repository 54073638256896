import { ACTIVE_TOURNAMENT_SUB_APP } from '../../pages/Tournaments/TournamentsConstants';

const {
	SET_ACTIVE_TOURNAMENT_ACTIVE_MODAL,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_AUTO_ACCEPT,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_CONFIRMED,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_TYPE,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_ERROR_MESSAGE,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_EVENT_ID,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_IS_LOADING,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_RECEIPTS,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_EXOTIC,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_PRODUCT,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_SELECTION,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_STAKE,
	SET_ACTIVE_TOURNAMENT_COMPETITION_FILTER,
	SET_ACTIVE_TOURNAMENT_EVENT_MARKET_FILTER,
	SET_ACTIVE_TOURNAMENT_EVENTS_ARE_LOADING,
	SET_ACTIVE_TOURNAMENT_MARKET_GROUP,
	SET_ACTIVE_TOURNAMENT_MARKET_TYPE_FILTER,
	SET_ACTIVE_TOURNAMENT_PLAYER_PROFILE_BETS_LOADING,
	SET_ACTIVE_TOURNAMENT_RACE_FILTER,
	SET_ACTIVE_TOURNAMENT_RACE_IS_LOADING,
	SET_ACTIVE_TOURNAMENT_REBUY_ERROR_MESSAGE,
	SET_ACTIVE_TOURNAMENT_REBUY_IS_LOADING,
	SET_ACTIVE_TOURNAMENT_SELECTED_EVENT,
	SET_ACTIVE_TOURNAMENT_SELECTED_USER,
	SET_ACTIVE_TOURNAMENT_SLEDGE_LOADING,
	SET_ACTIVE_TOURNAMENT_TICKET_ID,
	SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_ERROR_MESSAGE,
	SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_LOADING,
	SET_ACTIVE_TOURNAMENT_TITLE_IS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_TOURNAMENT_BETS_IS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_MY_PROGRESS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_COMPETITIONS_IS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_TOURNAMENT_ID,
	SET_ACTIVE_TOURNAMENT_TOURNAMENT_TYPE,
	SET_MARKETS_FOR_EVENT,
	SET_ACTIVE_TOURNAMENT_DETAIL_TAB,
} = require('./activeTournamentActionTypes');

const { MERGE_ENTITIES } = require('../entities/EntityActionTypes');

import { get } from '../../common/Ajax';
const { createAction } = require('../../common/actions/actionHelpers');
const { normalizeComments } = require('../entities/schemas/CommentSchema');
const { normalizeLeaderboardItems } = require('../entities/schemas/LeaderboardItemSchema');
const { normalizeMarkets } = require('../entities/schemas/MarketSchema');
const { normalizeRaces } = require('../entities/schemas/RaceSchema');
const { normalizeTournamentBets } = require('../entities/schemas/TournamentBetSchema');
import { getLoadedRaces } from '../entities/actions/RaceActions';

/**
 * Load the bets for a given tournament
 *
 * @param tournamentId
 * @param userId
 * @returns {Promise}
 */
const fetchBetsForTournament = (tournamentId, userId) => (dispatch) => {
	let params = {
		tournament_id: tournamentId,
	};

	return get('tournament-bets', { params }).then((response) => {
		let massagedData = response.data.data.map((tournamentBet) => {
			return {
				...tournamentBet,
				user_id: userId,
			};
		});

		dispatch({
			type: MERGE_ENTITIES,
			...normalizeTournamentBets(massagedData),
		});
	});
};

/**
 * Load the comments for a given tournament
 *
 * @param tournamentId
 * @returns {Promise}
 */
const fetchCommentsForTournament = (tournamentId) => (dispatch) => {
	let params = {
		tournament_id: tournamentId,
	};

	return get('comments', { params }).then((response) => {
		dispatch({
			type: MERGE_ENTITIES,
			...normalizeComments(response.data.data),
		});
	});
};

/**
 * Fetches markets in groups.
 *
 * @param eventId
 * @returns {Promise}
 */
const fetchGroupedMarketsForEvent = (eventId) => (dispatch) => {
	return get(`combined/tournament/markets/selections?event_id=${eventId}`).then((response) => {
		let marketTypeGroups = response.data.data || [];
		let markets = [];

		marketTypeGroups.forEach((marketTypeGroup) => {
			let group = {
				...marketTypeGroup,
				markets: undefined,
			};

			marketTypeGroup.markets.forEach((market) => {
				markets.push({
					...market,
					markettypegroup: group,
				});
			});
		});

		dispatch({
			type: MERGE_ENTITIES,
			...normalizeMarkets(markets),
		});

		dispatch({
			type: SET_MARKETS_FOR_EVENT,
			payload: {
				eventId,
				markets: markets.map((market) => market.id),
			},
		});
	});
};

/**
 * Load the leaderboard for a given tournament
 *
 * @param {number | string} tournamentId
 * @param {number} perPage
 * @param {number} page
 * @returns {Promise}
 */
const fetchLeaderboardForTournament = (tournamentId, perPage = 30, page = 1) => (dispatch) => {
	return get(`tournament/${tournamentId}/leaderboard`, {
		params: {
			per_page: perPage,
			page: page - 1,
		},
	}).then((response) => {
		let massagedData = response.data.data.map((leaderboardItem) => {
			return {
				...leaderboardItem,
				tournament_id: tournamentId,
			};
		});

		dispatch({
			type: MERGE_ENTITIES,
			...normalizeLeaderboardItems(massagedData),
		});
	});
};

/**
 * Set loading mask depending if the race was previously loaded or not,
 * fetch data for given tournament race ID and set selected Race in store.
 *
 * @param {number} raceId
 * @param {number} tournamentId
 */
const navigateToTournamentRace = (raceId, tournamentId) => (dispatch) => {
	const loadedRaces = dispatch(getLoadedRaces());
	const raceWasLoadedPreviously = loadedRaces.some((race) => race.id === raceId);

	/**
	 * If the race was loaded previously (it's already in state with selections), don't show the loading mask and
	 * reset the racing filters to show the race, but still fetch the race to update it
	 */
	if (raceWasLoadedPreviously) {
		dispatch(setActiveTournamentRaceFilter(raceId));
		return dispatch(fetchRaceWithSelections(raceId, tournamentId));
	}

	/**
	 * If the race wasn't loaded previously, show the loading mask, fetch the race and then reset the racing filters
	 */
	dispatch(setActiveTournamentRaceIsLoading(true));
	return dispatch(fetchRaceWithSelections(raceId, tournamentId)).finally(() => {
		dispatch(setActiveTournamentRaceFilter(raceId));
		dispatch(setActiveTournamentRaceIsLoading(false));
	});
};

/**
 * Fetch race with selections
 *
 * @param race_id
 * @param tournament_id
 * @returns {Promise}
 */
const fetchRaceWithSelections = (race_id, tournament_id) => (dispatch) => {
	return get('combined/tournament/race/selections', {
		params: {
			race_id,
			tournament_id,
		},
	})
		.then((response) => {
			dispatch({
				type: MERGE_ENTITIES,
				...normalizeRaces([response.data.data]),
			});
		})
		.catch((e) => {
			console.warn(e);
		});
};

/**
 * Load tournament bets for user.
 *
 * @param tournamentId
 * @param userId
 * @returns {Promise}
 */
const fetchTournamentBetsForUser = (tournamentId, userId) => (dispatch) => {
	return get(`user/${userId}/tournament/${tournamentId}/bets`)
		.then((response) => {
			let massagedData = response.data.data.map((tournamentBet) => {
				return {
					...tournamentBet,
					user_id: userId,
				};
			});

			dispatch({
				type: MERGE_ENTITIES,
				...normalizeTournamentBets(massagedData),
			});
		})
		.catch((reason) => Promise.reject(reason));
};

/**
 * Set the active modal type
 * @param modalType
 */
const setActiveTournamentActiveModal = (modalType) => {
	return createAction(SET_ACTIVE_TOURNAMENT_ACTIVE_MODAL, modalType);
};

/**
 * Set the active bet prompt auto accept value.
 *
 * @param autoAccept
 * @returns {Object}
 */
const setActiveTournamentBetPromptAutoAccept = (autoAccept) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_AUTO_ACCEPT, autoAccept);
};

/**
 * Set the active bet prompt bet confirmed value.
 *
 * @param isBetConfirmed
 * @returns {Object}
 */
const setActiveTournamentBetPromptBetConfirmed = (isBetConfirmed) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_CONFIRMED, isBetConfirmed);
};

/**
 * Set the active bet prompt bet type.
 *
 * @param betType
 * @returns {Object}
 */
const setActiveTournamentBetPromptBetType = (betType) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_TYPE, betType);
};

/**
 * Set the active bet prompt error message.
 *
 * @param errorMessage
 * @returns {Object}
 */
const setActiveTournamentBetPromptErrorMessage = (errorMessage) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_ERROR_MESSAGE, errorMessage);
};

/**
 * Set the active bet prompt event id.
 *
 * @param eventId
 * @returns {Object}
 */
const setActiveTournamentBetPromptEventId = (eventId) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_EVENT_ID, eventId);
};

/**
 * Set the active bet prompt loading flag.
 *
 * @param isLoading
 * @returns {Object}
 */
const setActiveTournamentBetPromptIsLoading = (isLoading) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_IS_LOADING, isLoading);
};

/**
 * Set the active bet prompt receipts.
 *
 * @param receipts
 * @returns {Object}
 */
const setActiveTournamentBetPromptReceipts = (receipts) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_RECEIPTS, receipts);
};

/**
 * Set the active bet prompt product.
 *
 * @param product
 * @returns {Object}
 */
const setActiveTournamentBetPromptExotic = (exotic) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_EXOTIC, exotic);
};

/**
 * Set the active bet prompt product.
 *
 * @param product
 * @returns {Object}
 */
const setActiveTournamentBetPromptProduct = (product) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_PRODUCT, product);
};

/**
 * Set the active bet prompt selection.
 *
 * @param selection
 * @returns {Object}
 */
const setActiveTournamentBetPromptSelection = (selection) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_SELECTION, selection);
};

/**
 * Set the active bet prompt stake.
 *
 * @param stake
 * @returns {Object}
 */
const setActiveTournamentBetPromptStake = (stake) => {
	return createAction(SET_ACTIVE_TOURNAMENT_BET_PROMPT_STAKE, stake);
};

/**
 * Set the active competition filter
 * @param filter
 */
const setActiveTournamentCompetitionFilter = (filter) => {
	return createAction(SET_ACTIVE_TOURNAMENT_COMPETITION_FILTER, filter);
};

/**
 * Set the active event market filter.
 *
 * @param eventMarketFilter
 */
const setActiveTournamentEventMarketFilter = (eventMarketFilter) => {
	return createAction(SET_ACTIVE_TOURNAMENT_EVENT_MARKET_FILTER, eventMarketFilter);
};

const setActiveTournamentEventsAreLoading = (eventsAreLoading) => {
	return createAction(SET_ACTIVE_TOURNAMENT_EVENTS_ARE_LOADING, eventsAreLoading);
};

/**
 * Set the active market filter
 * @param filter
 */
const setActiveTournamentMarketFilter = (filter) => {
	return createAction(SET_ACTIVE_TOURNAMENT_MARKET_TYPE_FILTER, filter);
};

const setActiveTournamentMarketGroup = (marketGroupId) => {
	return createAction(SET_ACTIVE_TOURNAMENT_MARKET_GROUP, marketGroupId);
};

const setActiveTournamentPlayerProfileBetsLoading = (betsLoading) => {
	return createAction(SET_ACTIVE_TOURNAMENT_PLAYER_PROFILE_BETS_LOADING, betsLoading);
};

/**
 * Set the active race filter
 * @param filter
 */
const setActiveTournamentRaceFilter = (filter) => {
	return createAction(SET_ACTIVE_TOURNAMENT_RACE_FILTER, filter);
};

/**
 * Set the active race loading mask on/off
 * @param isLoading
 */
const setActiveTournamentRaceIsLoading = (isLoading) => {
	return createAction(SET_ACTIVE_TOURNAMENT_RACE_IS_LOADING, isLoading);
};

/**
 * Sets the active tournament rebuy modal error message.
 * @param errorMessage
 */
const setActiveTournamentRebuyErrorMessage = (errorMessage) => {
	return createAction(SET_ACTIVE_TOURNAMENT_REBUY_ERROR_MESSAGE, errorMessage);
};

/**
 * Sets the active tournament rebuy modal loading mask.
 * @param isLoading
 */
const setActiveTournamentRebuyIsLoading = (isLoading) => {
	return createAction(SET_ACTIVE_TOURNAMENT_REBUY_IS_LOADING, isLoading);
};

/**
 * Set the selected event id.
 *
 * @param eventId
 */
const setActiveTournamentSelectedEvent = (eventId) => {
	return createAction(SET_ACTIVE_TOURNAMENT_SELECTED_EVENT, eventId);
};

/**
 * Set whether the sledge is loading.
 *
 * @param sledgeIsLoading
 */
const setActiveTournamentSledgeLoading = (sledgeIsLoading) => {
	return createAction(SET_ACTIVE_TOURNAMENT_SLEDGE_LOADING, sledgeIsLoading);
};

/**
 * Set the active ticket id
 * @param ticketId
 */
const setActiveTournamentTicketId = (ticketId) => {
	return createAction(SET_ACTIVE_TOURNAMENT_TICKET_ID, ticketId);
};

/**
 * Set the ticket purchase error message
 * @param message
 */
const setActiveTournamentTicketPurchaseErrorMessage = (message) => {
	return createAction(SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_ERROR_MESSAGE, message);
};

/**
 * Set the ticket purchase loading flag
 * @param message
 */
const setActiveTournamentTicketPurchaseLoading = (isLoading) => {
	return createAction(SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_LOADING, isLoading);
};

/**
 * Sets the title to be collapsed or not.
 *
 * @param isCollapsed
 */
const setActiveTournamentTitleIsCollapsed = (isCollapsed) => {
	return createAction(SET_ACTIVE_TOURNAMENT_TITLE_IS_COLLAPSED, isCollapsed);
};

/**
 * Sets the tournament bets component to be collapsed or not.
 *
 * @param isCollapsed
 */
const setActiveTournamentTournamentBetsIsCollapsed = (isCollapsed) => {
	return createAction(SET_ACTIVE_TOURNAMENT_TOURNAMENT_BETS_IS_COLLAPSED, isCollapsed);
};

/**
 * Sets the sidebar My Progress under rank to open/close
 *
 * @param isCollapsed
 */
const setActiveTournamentMyProgressCollapsed = (isCollapsed) => {
	return createAction(SET_ACTIVE_TOURNAMENT_MY_PROGRESS_COLLAPSED, isCollapsed);
};

/**
 * Sets the competitions in the in play header to be collapsed or not.
 *
 * @param isCollapsed
 */
const setActiveTournamentCompetitionsIsCollapsed = (isCollapsed) => {
	return createAction(SET_ACTIVE_TOURNAMENT_COMPETITIONS_IS_COLLAPSED, isCollapsed);
};

/**
 * Set the active tournament id
 * @param tournamentId
 */
const setActiveTournamentTournamentId = (tournamentId) => {
	return createAction(SET_ACTIVE_TOURNAMENT_TOURNAMENT_ID, tournamentId);
};

/**
 * Set the active competition filter
 * @param tournamentType
 */
const setActiveTournamentTournamentType = (tournamentType) => {
	return createAction(SET_ACTIVE_TOURNAMENT_TOURNAMENT_TYPE, tournamentType);
};

const setSelectedUser = (userId) => {
	return createAction(SET_ACTIVE_TOURNAMENT_SELECTED_USER, userId);
};

/**
 *
 * @param activeTab
 * @returns {*}
 */
const setActiveTournamentDetailTab = (activeTab) => {
	return createAction(SET_ACTIVE_TOURNAMENT_DETAIL_TAB, activeTab);
};

/**
 * Route to an active tournament
 *
 * @param tournamentId
 * @returns {function(*)}
 */
const routeToTournament = (tournamentId) => {
	return (dispatch) => {
		dispatch(setActiveTournamentTournamentId(+tournamentId));
		return App.startSubApp(ACTIVE_TOURNAMENT_SUB_APP);
	};
};

/**
 * Export functions
 */
module.exports = {
	setActiveTournamentDetailTab,
	fetchBetsForTournament,
	fetchCommentsForTournament,
	fetchGroupedMarketsForEvent,
	fetchLeaderboardForTournament,
	fetchRaceWithSelections,
	fetchTournamentBetsForUser,
	routeToTournament,
	setActiveTournamentActiveModal,
	setActiveTournamentBetPromptAutoAccept,
	setActiveTournamentBetPromptBetConfirmed,
	setActiveTournamentBetPromptBetType,
	setActiveTournamentBetPromptErrorMessage,
	setActiveTournamentBetPromptEventId,
	setActiveTournamentBetPromptIsLoading,
	setActiveTournamentBetPromptReceipts,
	setActiveTournamentBetPromptExotic,
	setActiveTournamentBetPromptProduct,
	setActiveTournamentBetPromptSelection,
	setActiveTournamentBetPromptStake,
	setActiveTournamentCompetitionFilter,
	setActiveTournamentCompetitionsIsCollapsed,
	setActiveTournamentEventMarketFilter,
	setActiveTournamentEventsAreLoading,
	setActiveTournamentMarketFilter,
	setActiveTournamentMarketGroup,
	setActiveTournamentPlayerProfileBetsLoading,
	setActiveTournamentRaceFilter,
	setActiveTournamentRaceIsLoading,
	setActiveTournamentRebuyErrorMessage,
	setActiveTournamentRebuyIsLoading,
	setActiveTournamentSelectedEvent,
	setActiveTournamentSledgeLoading,
	setActiveTournamentTicketId,
	setActiveTournamentTicketPurchaseErrorMessage,
	setActiveTournamentTicketPurchaseLoading,
	setActiveTournamentTitleIsCollapsed,
	setActiveTournamentTournamentBetsIsCollapsed,
	setActiveTournamentMyProgressCollapsed,
	setActiveTournamentTournamentId,
	setActiveTournamentTournamentType,
	setSelectedUser,
	navigateToTournamentRace,
};
