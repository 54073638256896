// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, media, mediaMax ,application } from '@tbh/ui-kit';

// Components
import { Button, ButtonGroup, FlexText, Icon, Text } from '@tbh/ui-kit';
import AccountSummaryNavList from './AccountSummaryNavList/AccountSummaryNavList';
import AccountSummaryStatistics from './AccountSummaryStatistics/AccountSummaryStatistics';
import FeatureContext from '../../../../contexts/featureContext';

const StyledAccountSummary = styled('div')(
	(props) => css`
		label: AccountSummary;

		width: 100%;
		//height: 180px;
		height: auto;
		display: flex;
		//background-color: ${ui(props).color_2};
		background-color: #212429;
		color: #fff;

		${props.isMobile &&
			css`
				display: block;
				display: flex;
				// flex-direction: column;
				flex-wrap: wrap;
				height: auto;
			`};
	`,
	(props) =>
		!props.isMobile &&
		mediaMax(
			css`
				flex-direction: column;
				height: auto;
			`,
			620,
		),
);

const cssAccountSummary__Popover = css`
	label: AccountSummary__Popover;

	width: 100%;
	height: 100%;
	border: 0;
	padding: 0;
`;

const errorText = css`
	label: AccountSummary_negative_texts;

	font-weight: normal;
	line-height: 1.3;
	max-width: 100%;
	color: #cf1b41;
	font-size: 14.222222222222221px;
	text-transform: none;
	-webkit-text-decoration: none;
	text-decoration: none;
	text-align: right;
	font-weight: 700;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	text-align: center;
	text-align: right;
	padding: 0;
`;

const StyledAccountSummary__Statistics = styled(AccountSummaryStatistics)(
	(props) => css`
		label: AccountSummary__Statistics;

		flex-grow: 1;
		flex-basis: 50%;
		width: 75%;

		@media (width < 620px) {
			// width: 100%;
			background-color: #212429;
			color: white;
		}

		${props.isMobile &&
			css`
				flex: auto;
				background-color: ${ui(props).color_1};
				padding: ${spacings(props).spacious}px 0;
			`};
	`,
	(props) =>
		!props.isMobile &&
		mediaMax(
			css`
				flex: auto;
				background-color: ${ui(props).color_1};
				padding: ${spacings(props).spacious}px 0;
			`,
			620,
		),
	(props) =>
		!props.isMobile &&
		media(
			css`
				flex-basis: auto;
				flex-wrap: nowrap;
				align-items: center;
				padding: 0 ${spacings(props).cozy}px;
			`,
			780,
		),
);

const StyledAccountSummary__StatisticsUser = styled('div')`
	label: AccountSummary__StatisticsUser;

	${(props) => css`
		${props.isMobile &&
			css`
				display: flex;
				align-self: flex-start;
			`};
	`};
`;

const StyledAccountSummary__Controls = styled('div')(
	(props) => css`
		label: AccountSummary__Controls;

		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #212429;
		color: #fff;
		//background-color: ${ui(props).color_1};
		//margin-bottom: ${spacings(props).roomy}px;
		margin-bottom: ${spacings(props).cozy}px;

		${props.grow &&
			css`
				flex-grow: 1;
				width: 100%;
			`};

		${
			props.isMobile
				? css`
						flex-direction: row;
				  `
				: css`
						flex-direction: column;
						flex-basis: 50%;
				  `
		};
	`,
	(props) =>
		!props.isMobile &&
		mediaMax(
			css`
				//background-color: ${ui(props).color_2};
				background-color: #212429;
				color: #fff;

				${props.grow &&
					css`
						display: none;
					`};
			`,
			620,
		),
	(props) =>
		!props.isMobile &&
		media(
			css`
				flex-basis: auto;
			`,
			780,
		),
);

const TextColor = css`
	label: TextColor;

	color: #fff;
`;

const TextAccountNumberColor = css`
	label: TextColor;

	color: #fff;
	padding-left: 5%;
	font-weight: 800;
`;

const StyledAccountSummary__ControlsButtons = styled(ButtonGroup)`
	label: AccountSummary__ControlsButtons;

	${(props) => css`
		margin-bottom: ${spacings(props).compact}px;
		justify-content: space-between;
	`};
`;

const StyledAccountSummary__Logout = styled('div')`
	label: AccountSummary__Logout;

	${(props) => css`
		width: 100%;
		text-align: center;
		border-top: 2px ${ui(props).color_3} solid;
		padding: ${spacings(props).cozy}px;
		align-self: flex-end;
		cursor: pointer;
	`};
`;

const StyledAccountSummary__Welcome = styled('div')(
	(props) => css`
		label: AccountSummary__Welcome;
		flex-grow: 1;
		display: none;
		margin: 0 ${spacings(props).cozy}px;
	`,
	mediaMax(
		css`
			display: none;
		`,
		620,
	),
	media(
		css`
			display: block;
		`,
		1024,
	),
);

const StyledProgressbar_section = styled('div')(
	(props) => css`
		label: Progressbar_section;
		width: 100%;
		display: none;
	`,
	mediaMax(
		css`
			display: none;
		`,
		620,
	),
	media(
		css`
			display: block;
		`,
		1024,
	),
);

const StyledWelcome_Main_section = styled('div')(
	(props) => css`
		label: Welcome_left_section;

		display: flex;
		width: 80%;
		margin-bottom: 10px;
	`,
	mediaMax(
		css`
			display: none;
		`,
		620,
	),
	media(
		css`
			display: flex;
		`,
		1024,
	),
);

const StyledAccountSummary__WelcomeLeft = styled('div')(
	(props) => css`
		label: AccountSummary__WelcomeLeft;

		display: none;
	`,
	mediaMax(
		css`
			display: none;
		`,
		620,
	),
	media(
		css`
			display: block;
			flex: 0 0 40%;
		`,
		1025,
	),
);

const StyledAccountSummary__WelcomeRight = styled('div')(
	(props) => css`
		label: AccountSummary__WelcomeRight;

		display: flex;
		flex: 0 0 100%;
		padding: 4px;
	`,
	mediaMax(
		css`
			flex: 0 0 100%;
		`,
		620,
	),
	media(
		css`
			flex: 0 0 60%;
			padding: 4px;
		`,
		1025,
	),
);

const StyledAccountSummary__WelcomeRight_MAIN = styled('div')(
	(props) => css`
		label: AccountSummary__WelcomeRight_MAIN;

		display: flex;
		width: 100%;
		padding: 2px;
	`,

	media(
		css`
			padding: 4px;
		`,
		1025,
	),
);
const StyledBetSlipButton__Button = styled(Button)(
	(props) => css`
		label: BetSlipButton__Button;

		padding: 0;
		//width: ${application(props).form_height_medium}px;
    //height: ${application(props).form_height_medium}px;
    width: 70px;
	height: 300px;
    padding: 10px;

    ${props.type === 'plain' &&
			css`
				//background: #c72128;
				//border: 1px solid #c72128;

				&:hover {
					color: #ffffff;
					background-color: #070b19;
				}
			`};
	`,
);

const StyledBetSlipButton__Label = styled(Text)`
	label: BetSlipButton__Label;

	position: absolute;
	//left: -3px;
	//bottom: -15px;
	color: #2e2e2e;
	font-weight: 100;

	&:hover {
		color: #ffffff;
	}
`;

const StyledBetSlipButton = styled('div')`
	label: BetSlipButton;
	width: 70px;
	position: relative;
	display: inline-flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	align-self: flex-start;
	//border-radius: 50%;
`;

const StyledDepositLock = styled('span')`
	${(props) => css`
		padding: 3px;
		width: 64px;
		height: 29px;
		border-radius: 5px;
		background-color: green;
		background-color: ${ui(props).color_3_light};
		//background-color: ${ui(props).color_2};
	`}
`;

const AccountSummary = (props) => {
	const {
		t,
		id,
		account_balance,
		free_credit_balance,
		cash_balance,
		className,
		pending_bets,
		first_name,
		last_name,
		name,
		total_stake,
		username,
		verified,
		potential_winnings,
		goToAccountTransactions,
		goToAccountTransactionsBonus,
		goToPendingBets,
		goToAccount,
		goToWithdrawn,
		onLockScreenClick,
		onLogout,
		navItems,
		onNavItemClick,
		gamblingMessage,
		isCordova,
		isMobile,
		size,
		street,
		dob,
		green_id_status,
		green_id_trans_id,
		userEmail,
		activated_user,
		isUserVerified,
		cashout_limit,
		checkBonusbet,
		isChecked,
		send_email,
		mobile,
		bailOutLimit,
		pricePumpLimit,
		onAddFunds,

		onBetSlipClick,
		count_bets,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});



	return (
		<StyledAccountSummary className={componentClasses} isMobile={isMobile} size={size}>
			<StyledAccountSummary__Statistics
				first_name={first_name}
				goToPendingBets={goToPendingBets}
				goToAccountTransactions={goToAccountTransactions}
				goToAccountTransactionsBonus={goToAccountTransactionsBonus}
				id={id}
				name={name}
				verified={verified}
				account_balance={account_balance}
				cash_balance={cash_balance}
				free_credit_balance={free_credit_balance}
				pending_bets={pending_bets}
				potential_winnings={potential_winnings}
				total_stake={total_stake}
				username={username}
				isMobile={isMobile}
				size={size}
				userEmail={userEmail}
				cashout_limit={cashout_limit}
				checkBonusbet={checkBonusbet}
				isChecked={isChecked}
				bailOutLimit={bailOutLimit}
				pricePumpLimit={pricePumpLimit}
			/>
			{isMobile && (
				<StyledBetSlipButton>
					<StyledBetSlipButton__Button
						type={count_bets ? Button.types.CALLTOACTION : Button.types.PLAIN}
						action={onBetSlipClick}
						// round
						bold
					>
						<div> {count_bets} </div>

						<StyledBetSlipButton__Label type="alternate" size="-3" strong>
							{t('BetSlipButton__Label')}
						</StyledBetSlipButton__Label>
					</StyledBetSlipButton__Button>
				</StyledBetSlipButton>
			)}

			{navItems && (
				<AccountSummaryNavList
					items={navItems}
					onItemClick={onNavItemClick}
					gamblingMessage={gamblingMessage}
					isCordova={isCordova}
				/>
			)}
			<StyledAccountSummary__Controls isMobile={isMobile} size={size}>
				{!navItems && (
					<StyledAccountSummary__Controls grow size={size}>
						<StyledWelcome_Main_section size={size}>
							<div className="drop-dwn-menu">
								<div className="drop-dwn-menu-items">
									<span className="drop-dwn-log-out" onClick={onLogout}>
										Log Out
									</span>
								</div>
								<div className="drop-dwn-menu-items">
									<span className="drop-dwn-lock-screen" onClick={onLockScreenClick}>
										Lock Screen
									</span>
								</div>
								<div className="drop-dwn-menu-items" onClick={onAddFunds}>
									<StyledDepositLock>Deposit</StyledDepositLock>
								</div>

								<div className="drop-dwn-menu-items" onClick={goToPendingBets}>
									Pending Bets
								</div>

								<div className="drop-dwn-menu-items" onClick={goToAccount}>
									My Account
								</div>

								<div className="drop-dwn-menu-items" onClick={goToAccountTransactions}>
									My Bets
								</div>

								<div className="drop-dwn-menu-items" onClick={goToWithdrawn}>
										Withdrow
								</div>
							</div>

							{/* <StyledAccountSummary__WelcomeRight size={size}></StyledAccountSummary__WelcomeRight> */}
						</StyledWelcome_Main_section>

						<div
							className={css`
								width: 85%;
							`}
						>
							{/* <StyledAccountSummary__ControlsButtons center>
								<Button action={goToAccount} icon="cog" type="secondary">
									{t('AccountSummary__MyAccount')}
								</Button>
								<Button action={onLockScreenClick} icon="lock">
									{t('AccountSummary__LockScreen')}
								</Button>
							</StyledAccountSummary__ControlsButtons> */}
							<FeatureContext.Consumer>
								{(featureToggles) => {
									return featureToggles.features.userVerification.enabled ? (
										<React.Fragment>
											{/* <FlexText className={css`margin-bottom:1px;`}>
												<Text size="-1" className={TextColor}>
													{t('AccountSummary__UserEmail')}: {userEmail}
												</Text>
											</FlexText> */}

											{/* <FlexText
												className={css`
													margin-bottom: 2px;
												`}
											>
												<Text size="-1" align="right" className={TextAccountNumberColor} strong>
													{t('AccountSummary__AccountNumber')}:<span className={TextColor}>#{id}</span>
												</Text>
											</FlexText> */}

											{/*<FlexText>*/}
											{/*	<Text size="-1">*/}
											{/*		{t('Overall Verification')}*/}
											{/*	</Text>*/}
											{/*</FlexText>*/}

											<StyledProgressbar_section size={size} className="progress-bar-verification">
												<FlexText>
													<Text size="-1" className="progress-bar-verification-text">
														{green_id_status !== 'VERIFIED' ? (
															<a
																// href={websiteUrl + '/user/green-id-form-upload'}
																className={css`
																	color: #fff;
																	text-align: center !important;
																`}
															>
																{t('Verified your account')}
															</a>
														) : null}
													</Text>
												</FlexText>
												{/* progressbar section */}
												<ul className="progressbar">
													<li
														className={
															green_id_status === null ? null : green_id_status !== 'VERIFIED' ? null : 'active2'
														}
													>
														{green_id_status !== 'VERIFIED' ? (
															<a
																// href={websiteUrl + '/user/green-id-form-upload'}
																href={'#'}
																className={css`
																	color: #fff;
																	text-align: center !important;
																`}
															>
																Name
															</a>
														) : (
															'Name'
														)}
													</li>
													<li
														className={
															green_id_status === null ? null : green_id_status !== 'VERIFIED' ? null : 'active2'
														}
													>
														{green_id_status !== 'VERIFIED' ? (
															<a
																href={'#'}
																// href={websiteUrl + '/user/green-id-form-upload'}
																className={css`
																	color: #fff;
																	text-align: center !important;
																`}
															>
																Address
															</a>
														) : (
															'Address'
														)}
													</li>
													<li className={mobile ? 'active2' : null}>
														{!mobile ? (
															<a
																href={'#'}
																// href={websiteUrl + '/user/green-id-form-upload'}
																className={css`
																	color: #fff;
																	text-align: center !important;
																`}
															>
																Phone Number
															</a>
														) : (
															'Phone Number'
														)}
													</li>
													<li className={send_email !== null ? 'active2' : null}>
														{send_email !== null ? (
															'Email'
														) : (
															<a
																href={'#'}
																// href={websiteUrl + '/user/green-id-form-upload'}
																className={css`
																	color: #fff;
																	text-align: center !important;
																`}
															>
																Email
															</a>
														)}
													</li>
													<li className={green_id_status === 'VERIFIED' ? 'active2' : null}>
														{green_id_status !== 'VERIFIED' ? (
															<a
																href={'#'}
																// href={websiteUrl + '/user/green-id-form-upload'}
																className={css`
																	color: #fff;
																	text-align: center !important;
																`}
															>
																GreenId
															</a>
														) : (
															'GreenId'
														)}
													</li>
												</ul>
											</StyledProgressbar_section>

											{/* <FlexText>
												<div className="progress">
													<div className="progress-bar bg-success" data-tip={'25%'} data-for="MobileSucess" role="progressbar" style={{width: '20%'}} aria-valuenow="15"
                               aria-valuemin="0" aria-valuemax="100"/>
													{send_email != null ? <div className="progress-bar bg-success" data-tip={'50%'} data-for="EmailSucess" role="progressbar" style={{width: '40%'}}
                                                     aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/> : <div className="progress-bar bg-success" data-tip={'75%'} data-for="EmailSucess" role="progressbar" style={{width: '0%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"/>}
													{green_id_status === 'PENDING' ? <div className="progress-bar bg-success" role="progressbar" style={{width: '0%'}} aria-valuenow="40"
                                                                   aria-valuemin="0" aria-valuemax="100"/> : green_id_status === 'IN_PROGRESS' ? <div className="progress-bar bg-success" role="progressbar" style={{width: '0%'}} aria-valuenow="20"
                                                                                                                                                      aria-valuemin="0" aria-valuemax="100"/> : <div className="progress-bar bg-success" data-tip={'100%'} data-for="GreenSucess" role="progressbar" style={{width: '40%'}} aria-valuenow="20"
                                                                                                                 aria-valuemin="0" aria-valuemax="100"/>}
												</div>
												<ReactTooltip id="MobileSucess" type="success" place="bottom" textColor="black" />
												<ReactTooltip id="EmailSucess" type="success" place="bottom" textColor="black" />
												<ReactTooltip id="GreenSucess" type="success" place="bottom" textColor="black" />

											</FlexText> */}
											{/* <FlexText>
												<Text size="-1" className={TextColor}>
													{green_id_status !== 'VERIFIED' ? <a href={'user/green-id-form-upload'} className={TextColor}>{t('Verified your account')}</a> : null}
												</Text>
											</FlexText> */}
										</React.Fragment>
									) : (
										<React.Fragment>
											<div
												className={css`
													background-color: #212429;
													color: #fff;
												`}
											>
												<Text size="-1" className={TextColor}>
													{t('Username')}: {first_name + ' ' + last_name}
												</Text>
												<Text size="-1" className={TextColor}>
													{t('AccountSummary__AccountNumber')}: #{id}
												</Text>
											</div>
										</React.Fragment>
									);
								}}
							</FeatureContext.Consumer>
						</div>
					</StyledAccountSummary__Controls>
				)}

				{size.width < 768 && (
					<Button
						type={'primary'}
						className={css`
							background: #000;
						`}
						action={onLogout}
						block
					>
						<Icon icon="log-out" push="1" />
						{t('Logout')}
					</Button>
				)}
			</StyledAccountSummary__Controls>
		</StyledAccountSummary>
	);
};

AccountSummary.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The current users' ID */
	id: PropTypes.number.isRequired,

	/** Action to fire when the pending bets link is clicked on */
	goToPendingBets: PropTypes.func.isRequired,

	/** The current users' username */
	username: PropTypes.string.isRequired,

	/** Whether the current user is verified or not */
	verified: PropTypes.bool.isRequired,

	/** Action to fire when the logout button is clicked on */
	onLogout: PropTypes.func.isRequired,

	/** The current users' full name */
	name: PropTypes.string,

	/** The current users' first name */
	first_name: PropTypes.string,

	last_name: PropTypes.string,

	street: PropTypes.string,

	dob: PropTypes.string,

	green_id_status: PropTypes.string,

	green_id_trans_id: PropTypes.string,

	send_email: PropTypes.string,

	/** The current users' account balance */
	account_balance: PropTypes.number,

	/** The current users' balance for their bonus bets */
	free_credit_balance: PropTypes.number,

	/** The current users' cash balance */
	cash_balance: PropTypes.number,

	/** Extra classes */
	className: PropTypes.string,

	/** Action to fire when the account button is clicked */
	goToAccount: PropTypes.func,

	/** Navigates to the account transactions page */
	goToAccountTransactions: PropTypes.func,

	/** Navigates to the account transactions bonus page */
	goToAccountTransactionsBonus: PropTypes.func,

	/** If it's the cordova app or not */
	isCordova: PropTypes.bool,

	/** The total number of pending/unresulted bets for the current user */
	pending_bets: PropTypes.number,

	/** The total amount that the user has staked and is unresulted */
	total_stake: PropTypes.number,

	/** The total $ amount of potential winnings unresulted for the user */
	potential_winnings: PropTypes.number,

	/** Navigation items */
	navItems: PropTypes.arrayOf(PropTypes.object),

	/** Action to fire when the lock button is clicked on */
	onLockScreenClick: PropTypes.func,

	/** Handler for clicking on a navigation item */
	onNavItemClick: PropTypes.func,

	/** Gambling message from the acl */
	gamblingMessage: PropTypes.string,

	/** If the account summary will be displayed for mobile or not. This will control the styles and media queries */
	isMobile: PropTypes.bool,

	userEmail: PropTypes.string,

	mobile: PropTypes.number,

	bailOutLimit: PropTypes.number,

	pricePumpLimit: PropTypes.number,

	/** Action when the add funds button is clicked on */
	onAddFunds: PropTypes.func.isRequired,
};

AccountSummary.defaultProps = {
	account_balance: 0,
	free_credit_balance: 0,
	cash_balance: 0,
	className: '',
	pending_bets: 0,
	total_stake: 0,
	potential_winnings: 0,
	goToPendingBets: undefined,
	showNavList: false,
	navItems: null,
	onNavItemClick: undefined,
	gamblingMessage: null,
	onLockScreenClick: undefined,
	goToAccount: undefined,
	isCordova: false,
	isMobile: false,
	name: null,
	first_name: null,
	last_name: null,
	goToAccountTransactions: null,
	goToAccountTransactionsBonus: null,
	street: null,
	dob: null,
	green_id_status: null,
	green_id_trans_id: null,
	userEmail: null,
	mobile: null,
	cashout_limit: null,
	bailOutLimit: null,
	pricePumpLimit: null,
	send_email : null,
};

export default withNamespaces()(AccountSummary);
