import React, { useState } from 'react';
import styled, { css } from 'react-emotion';
import { brand } from '@tbh/ui-kit';
import { GoVerified, GoUnverified } from 'react-icons/go';
//import UserInfoSection from './Sections/UserInfo';
//import { StyledSection } from '../Components';
//import icon_warning from '../../../../images/icons/icon_warning.svg';
import { Image } from '@tbh/ui-kit';
import { COLORS, FAMILYS, DEFAULT_COLORS, MEDIA_QUERIES } from '../../../../constants/themes';
import { PrimaryButton } from '../../../../components/UI/Buttons/Buttons';
import ModalConfirm from '../../../../components/UI/ModalConfirm';

const StyledHeaderOverview = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	img {
		width: 80px;
		height: 80px;
		border-radius: 100%;
	}

	h4 {
		font-size: 24px;
		font-weight: bold;
		margin: 0px;
		margin-left: 10px;
	}

	p {
		font-size: 13px;
		color: #747474;
		font-weight: normal;
		margin: 0px;
		margin-left: 15px;
	}

	margin-bottom: 20px;
`;

const StyledSectionsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`;

const StyledAccountIsNotVerified = styled.div`
	display: flex;
	flex-direction: row;
	background-color: #f5f5f5;
	align-items: flex-start;
	padding: 10px;
	font-weight: normal;
	font-size: 14px;
	border-radius: 4px;
	margin-bottom: 20px;

	img {
		margin-right: 10px;
	}
	p {
		margin: 0px;
	}

	span {
		color: #006ce8;
		cursor: pointer;
	}
`;

const ProfileAccountRow = styled('div')`
	display: flex;
	align-items: center;
	width: 100%;
`;

const ProfileCardWrapper = styled('div')`
	background-color: #ffffff;
	border: 1px solid #dfdfdf;
	border-radius: 6px;
	width: 100%;
	margin-bottom: 16px;
`;

const ProfileCardHeader = styled('div')(
	(props) => css`
		background-color: ${brand(props).tertiary_color ? brand(props).tertiary_color : DEFAULT_COLORS.df_tertiary_color};
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		padding: 60px 24px;
	`
);

const ProfileCardAvatr = styled('div')`
	background-color: #ffffff;
	border-radius: 50%;
	text-align: center;
	padding: 3px;
	width: 120px;
	height: 120px;
	margin-top: -84px;
`;

const ProfileCardBody = styled('div')`
	padding: 16px;
`;

const ProfileInfo = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 4px;
	text-align: left;
	padding-left: 14px;
	padding-bottom: 16px;
	border-bottom: 1px solid #dfdfdf;
`;

const ProfileName = styled('h3')`
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 0;
	font-family: ${FAMILYS.primary} !important;
`;

const ProfileUsername = styled('h5')`
	font-weight: 500;
	font-size: 14px;
	margin-top: 0;
	margin-bottom: 3px;
	color: ${COLORS.gray[400]};
	font-family: ${FAMILYS.primary} !important;
`;

const ProfileAccountNum = styled('h5')`
	font-weight: 500;
	font-size: 14px;
	margin-top: 0;
	margin-bottom: 0;
	color: ${COLORS.gray[400]};
	font-family: ${FAMILYS.primary} !important;
`;

const ProfileSummery = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: initial;
	padding: 14px;
	border-bottom: 1px solid #dfdfdf;
	gap: 12px;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 14px;
		border-bottom: 1px solid #dfdfdf;
		flex-wrap: wrap;
		gap: 12px;
	}
`;

const SummaryBlock = styled('div')`
	text-align: left;
`;

const SummaryLabel = styled.span`
	display: block;
	color: ${COLORS.gray[600]};
	font-weight: 600;
	font-size: 14px;
	font-family: ${FAMILYS.primary} !important;
	margin-top: 0;
	margin-bottom: 3px;
`;

const SummaryAmount = styled.h4`
	display: block;
	color: ${COLORS.gray[500]};
	font-weight: 700;
	font-size: 16px;
	font-family: ${FAMILYS.primary} !important;
	margin-top: 0;
	margin-bottom: 3px;
`;

const SummaryLink = styled.h4`
	display: block;
	color: ${COLORS.status.info};
	font-weight: 600;
	font-size: 12px;
	font-family: ${FAMILYS.primary} !important;
	margin-top: 0;
	margin-bottom: 0;
	cursor: pointer;
`;

const VerifiedIcon = styled(GoVerified)`
	color: ${COLORS.status.success};
	font-size: 16px;
`;

const UnVerifiedIcon = styled(GoUnverified)`
	color: ${COLORS.status.danger};
	font-size: 16px;
`;

const EmailSection = styled('div')`
	display: flex;
	flex-direction: row;
	gap: 4px;
	justify-content: start;
	align-items: center;
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const EmailButton = styled(PrimaryButton)`
	border-radius: 4px;
	width: fit-content;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const OverviewContainer = ({ user, goToWithdrawal, openDeposits, goToResponsibleGambling, verifyEmail }) => {

	const { name, send_email, msisdn, green_id_status } = user;
	const accountIsVerified = green_id_status === 'VERIFIED' && !!send_email && !!msisdn && !!name;
	//console.log(accountIsVerified, !!green_id_status, !!send_email, !!msisdn, !!name);

	const isEmailVerified = send_email === null ? <UnVerifiedIcon /> : <VerifiedIcon />;
	const verified = !accountIsVerified ? <UnVerifiedIcon /> : <VerifiedIcon />;

	const accountBalance = user.account_balance / 100;
	const withdrawableBalance = user.withdrawable_balance / 100;

	const [modal, setModal] = useState({
		open: false,
		text: 'Are you sure you want to re-send the verification email to your email address?',
		showConfirm: true,
	});
	const handleCloseModal = (state) => {
		setModal({
			open: state,
			text: 'Are you sure you want to re-send the verification email to your email address?',
		});
	};

	const handleVerifyEmail = async () => {
		const { status } = await verifyEmail();

		if (status === 'success') {
			// Verification email sent successfully
			setModal({
				open: true,
				showConfirm: false,
				text: 'Verification email sent successfully, please check your email inbox.',
			});
		} else {
			// There was an error sending the verification email
			setModal({
				open: true,
				showConfirm: false,
				text: 'There was an error sending the verification email, please try again later.',
			});
		}
	};

	return (
		<React.Fragment>
			<ProfileAccountRow>
				<ProfileCardWrapper>
					<ProfileCardHeader></ProfileCardHeader>
					<ProfileCardBody>
						<ProfileCardAvatr>
							<Image src={require('../../../../assets/img/Avatr.png')} class="user" />
						</ProfileCardAvatr>
						<ProfileInfo>
							<ProfileName>{user.name}</ProfileName>
							<EmailSection>
								<ProfileUsername>
									Email : {user.email} {isEmailVerified}
								</ProfileUsername>
								{send_email === null && <EmailButton onClick={() => setModal({
									...modal,
									open: true,
									showConfirm: true,
								})}>Verify Email</EmailButton>}
							</EmailSection>
							<ProfileAccountNum>Account number : {user.id}</ProfileAccountNum>
							{accountIsVerified && <ProfileAccountNum>
								IDENTIFICATION STATUS: Verified {verified}
							</ProfileAccountNum>}
						</ProfileInfo>
						<ProfileSummery>
							<SummaryBlock>
								<SummaryLabel>Account Balance</SummaryLabel>
								<SummaryAmount>${accountBalance.toFixed(2)}</SummaryAmount>
								<SummaryLink onClick={openDeposits}>Deposit</SummaryLink>
							</SummaryBlock>
							<SummaryBlock>
								<SummaryLabel>Withdrawable</SummaryLabel>
								<SummaryAmount>${withdrawableBalance.toFixed(2)}</SummaryAmount>
								<SummaryLink onClick={goToWithdrawal}>Withdraw</SummaryLink>
							</SummaryBlock>
							{/* <SummaryBlock>
									<SummaryLabel>Cash Card</SummaryLabel>
									<SummaryLink>Apply</SummaryLink>
								</SummaryBlock> */}
							<SummaryBlock>
								<SummaryLabel>Responsible Gambling</SummaryLabel>
								<SummaryLink onClick={goToResponsibleGambling}>Apply</SummaryLink>
							</SummaryBlock>
						</ProfileSummery>
						<div>{/* <ButtonGroupsSection /> */}</div>
					</ProfileCardBody>
				</ProfileCardWrapper>
			</ProfileAccountRow>
			<ModalConfirm
				open={modal.open}
				setOpen={() => handleCloseModal(false)}
				onConfirm={handleVerifyEmail}
				title="Verify Email"
				text={modal.text}
				showConfirm={modal.showConfirm}
			/>
		</React.Fragment>
	);
};

export { OverviewContainer };
