import { brand } from '@tbh/ui-kit';
import { isNumber } from 'lodash-es';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import { COLORS, DEFAULT_COLORS, MEDIA_QUERIES, TYPE_SCALE, WEIGHTS } from '../../../constants/themes';
import useBrowserSessionTime from '../../../hooks/useBrowserSessionTime';
import useLoginSessionTime from '../../../hooks/useLoginSessionTime';
import { handleTaglines } from '../../../store/acl/aclActions';
import { routeTo } from '../../../store/application/applicationActions';
import { openModal } from '../../../store/modal/modalActions';
import Image from '../../UI/Image/Image';
import Text from '../../UI/Text/Text';
//import { LEGAL_BASE_URL } from '../../../pages/LegalPage/LegalConstants';

const FooterWrapper = styled('footer')(
	(props) => css`
		background-color: ${brand(props).tertiary_color ? brand(props).tertiary_color : DEFAULT_COLORS.df_tertiary_color};
		border-radius: 6px;
		padding: 16px;
	`,
);

const FooterLinkSection = styled.div`
	display: none;

	@media ${MEDIA_QUERIES.tabletOnly} {
		display: none;
	}

	@media ${MEDIA_QUERIES.laptopAndUp} {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 16px;
		flex-wrap: wrap;
	}
`;

const FooterLinkWrapper = styled.div`
	text-align: left;
	margin-bottom: 16px;
	order: ${(props) => (props.block === 'PAYMENT METHODS' ? 4 : 0)};

	@media ${MEDIA_QUERIES.tabletAndUp} {
		order: ${(props) => (props.block === 'PAYMENT METHODS' ? 0 : 'unset')};
	}
`;

const FooterTitle = styled.h3`
	color: ${COLORS.white};
	font-weight: ${WEIGHTS.bold};
	font-size: ${(props) => (props.size ? props.size : TYPE_SCALE.header5)};
	text-transform: uppercase;
	margin-bottom: 3px;
`;

const Divder = styled.div`
	background-color: ${COLORS.red[300]};
	width: 50px;
	height: 3px;
	margin-bottom: 6px;
`;

const FooterLink = styled.a`
	color: ${COLORS.gray[200]};
	font-weight: ${WEIGHTS.medium};
	font-size: ${TYPE_SCALE.header6};
	text-decoration: none;
	display: block;

	&:hover {
		color: ${COLORS.red[300]};
	}
`;

const FooterLinkButton = styled.button`
	color: ${COLORS.gray[200]};
	font-weight: ${WEIGHTS.medium};
	font-size: ${TYPE_SCALE.header6};
	text-decoration: none;
	display: block;
	border: 0;
	background: none;

	&:hover,
	&:focus,
	&:active {
		outline: none;
		border: 0;
		color: ${COLORS.red[300]};
		box-shadow: none;
	}
`;

const LinkList = styled.ul`
	display: flex;
	flex-direction: ${({ as }) => (as ? as : 'column')};
	align-items: ${({ as }) => (as ? 'center' : 'normal')};
	gap: ${({ as }) => (as ? '6px' : null)};
	list-style: none;
	padding: 0;
`;

const LinkListItem = styled.li`
	padding-top: 6px;
	padding-bottom: 6px;
`;

const PaymentIconWrapper = styled.div`
	background-color: ${COLORS.white};
	border-radius: 3px;
	padding: 4px;
	text-align: center;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 16px;
	gap: 16px;
`;

const BottomWrapper = styled.div`
	border-bottom: 1px solid ${COLORS.gray[300]};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 3px;
	text-align: center;
`;

const CopyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const FooterMessage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	width: 100%;
	padding: 12px 16px;
	border-radius: 6px;
	background-color: white;
`;

const Footer = ({
	loading,
	gamblingMessages,
	gambleResponsiblyMessage,
	seoContent,
	footerLists,
	abn,
	brandName,
	user,
	handleClick,
	openReminderModal,
	taglines,
	logedCount,
	active,
	sessionTimeStop,
}) => {
	const [browserSessionTime, setBrowserSessionTime] = useBrowserSessionTime('browsertime');
	const [sessionTime, setSessionTime] = useLoginSessionTime('sessiontime');
	const sessionInterval = useRef();

	useEffect(() => {
		if (user && user.id) {
			setBrowserSessionTime(0);
			sessionInterval.current = setInterval(() => {
				setSessionTime((prevState) => prevState + 1);
			}, 1000);
			return () => clearInterval(sessionInterval.current);
		}
	}, [user]);

	useEffect(() => {
		if (user && user.reality_check_hours > 0 && sessionTime >= user.reality_check_hours * 3600) {
			openReminderModal();
			sessionTimeStop();
			setSessionTime(0);
		}
	}, [sessionTime]);

	const numberToTime = (number) => {
		const hours = Math.floor(number / 3600);
		const minutes = Math.floor((number - hours * 3600) / 60);
		const seconds = number - hours * 3600 - minutes * 60;

		// 00:00:00
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
			.toString()
			.padStart(2, '0')}`;
	};

	const key =
		taglines &&
		Object.keys(taglines).find((key) => {
			if (isNumber(Number(key)) && taglines[key].footer) {
				return true;
			}
			return false;
		});

	const selectedTagLine = key ? taglines[key] : null;

	return (
		<FooterWrapper>
			{!loading ? (
				<FooterLinkSection>
					{footerLists &&
						footerLists.map((item) => (
							<FooterLinkWrapper block={item.title} key={item.index}>
								<FooterTitle>{item.title}</FooterTitle>
								<Divder></Divder>
								<LinkList as={item.itemsStyle ? 'row' : ''} key={item.index}>
									{item.links &&
										item.links.map((link) => (
											<LinkListItem key={link.index}>
												{link.imageSrc ? (
													<PaymentIconWrapper key={link.index}>
														<Image modifiers="static" src={link.imageSrc} />
													</PaymentIconWrapper>
												) : (
													<FooterLink
														href={link.href.startsWith('http') ? link.href : '#'}
														target="_blank"
														onClick={handleClick}
														data-url-param={link.href}
													>
														{link.icon && (
															<i className={`fa-${link.icon === 'phone' ? 'solid' : 'brands'} fa-${link.icon}`}></i>
														)}{' '}
														{link.linkText}
													</FooterLink>
												)}
											</LinkListItem>
										))}
								</LinkList>
							</FooterLinkWrapper>
						))}
				</FooterLinkSection>
			) : (
				''
			)}

			<InfoWrapper>
				{seoContent && seoContent.footer && seoContent.footer.extraContent ? (
					<div dangerouslySetInnerHTML={{ __html: seoContent.footer.extraContent }} />
				) : null}

				{seoContent && seoContent.footer && seoContent.footer.betstopfooter ? (
					<FooterMessage
						style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
						dangerouslySetInnerHTML={{ __html: seoContent.footer.betstopfooter }}
					/>
				) : null}
			</InfoWrapper>
			<BottomWrapper>
				{taglines && taglines.contact ? (
					<FooterMessage style={{ alignItems: 'center' }}>
						<p
							className={css`
								color: black;
								font-weight: bold;
								font-size: 15px;
								text-align: center;
								padding-bottom: 0;
								margin: 0;
							`}
						>
							{handleTaglines(taglines, 'footer')}
						</p>
						<div dangerouslySetInnerHTML={{ __html: taglines.contact }} />
					</FooterMessage>
				) : (
					<Text weight={WEIGHTS.exBold} size={TYPE_SCALE.header5}>
						{!loading && gamblingMessages && `${gamblingMessages} ${gambleResponsiblyMessage}`}
					</Text>
				)}

				<Text weight={WEIGHTS.exBold} size={TYPE_SCALE.header5} style={{ marginTop: 10 }}>
					{!user && `Session Time ${numberToTime(browserSessionTime)}`}
					{user && `Session Time ${numberToTime(sessionTime)}`}
				</Text>
			</BottomWrapper>

			<CopyWrapper>
				<Text color={COLORS.gray[200]}>
					{process.env.PACKAGE_VERSION}
					{' Copyright'} {'\u00A9'} {brandName}.
				</Text>
				<Text color={COLORS.gray[200]}>{abn}</Text>
			</CopyWrapper>
		</FooterWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		page: state.application.currentRoute,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleClick: (event) => {
			const urlParam = event.target.getAttribute('data-url-param');
			if (!urlParam.startsWith('http')) {
				event.preventDefault();
				dispatch(routeTo('/' + urlParam));
			}
		},
		openReminderModal: () =>
			dispatch(
				openModal({
					id: 'ReminderDialogContainer',
					Component: 'ReminderDialogContainer',
				}),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
