import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import * as RCS from './RaceCard.styled';

import { Tabs, TabsItem, Panel, Button, spacings, Icon, Text } from '@tbh/ui-kit';
import PusherSubscriberRaceChannel from '../PusherSubscribers/PusherSubscriberRaceChannel';
import RaceSelectionsList from '../RaceSelectionsList/RaceSelectionsList';
import RaceProductLegend from '../RaceProductLegend/RaceProductLegend';
import EarlySpeed from '../Informatics/EarlySpeed/EarlySpeed';
import SettlingPosition from '../Informatics/SettlingPosition/SettlingPosition';
import RaceSelectionTips from '../RaceSelectionTips/RaceSelectionTips';
import MyBetsIcon from '../../../../containers/Application/MyBets/MyBetsIcon/MyBetsIcon';
// import RaceCardProductBadge from './RaceCardProductBadge';
// import Switch from 'react-switch';
import {
	RACE_CLOSED_STATUS,
	RACE_SELLING_STATUS,
	RACING_BET_TYPE_EXACTA,
	RACING_BET_TYPE_FIRST_FOUR,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_DAILY_DOUBLE,
	RACING_BET_TYPE_STRAIGHT_EIGHT,
	RACING_BET_TYPE_QUINELLA,
	RACING_BET_TYPE_TRIFECTA,
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_MARGIN,
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_EXOTIC_MINIMUM_SELECTIONS,
	RACING_ODD_GRID_BET_TYPE,
	RACING_ISSURE_BET,
	RACING_BET_TYPE_NOTTO_WIN_BET,
	RACING_BET_TYPE_TRAINERT_4_BET,
	RACING_BET_TYPE_TRAINER_WIN_BET,
	RACING_MORE_MARKETS_TYPE,
	RACING_MULTIPLES_TYPE,
	RACING_SAME_RACE_MULTI_TYPE,
	RACE_FINISHED_STATUS,
	RACING_BET_TYPE_EACHWAY,
} from '../../../../common/constants/Racing';
import { GOAT_PRODUCT_TYPE_BOOST, GOAT_BET_TYPE_WIN } from '../../../../common/constants/GoatProducts';
import { PRODUCT_TYPE_STANDARD } from '../../../../store/entities/constants/BetConstants';
import { MOBILE_MAX_WIDTH } from '../../../../common/constants/Breakpoints';
import { quaddiePosition } from '../../../../common/QuaddiePosition';
import { dailyDoublePosition } from '../../../../common/DailyDoublePosition';
import { buildRaceLegSequence } from '../../../../common/BuildRaceLegSequence';
import { isBetPlacedForProduct } from '../../../../store/entities/actions/BetActions';
import { getNotScratchedSelections, getSelectionsFromTips } from '../../../../pages/Racing/RacingHome/racingSelectorsGRS';
import { buildRaceNumberSequence } from '../../../../common/BuildRaceNumberSequence';
import { RACING_TYPES_LOOKUP } from '../../../../common/constants/Racing';
import { LoadingSpanner } from '../../Application/LoadingSpinner/LoadingSpinner';

import ComputerTips from '../Informatics/ComputerTips/ComputerTips';
// import RaceCardCombinationTable from '../RaceCardCombinationTable/RaceCardCombinationTable';

const cssActiveTabItems = css`
	label: ActiveTabItems;

	color: #c72128;

	font-weight: 700;
`;

const cssActiveTabItems2 = css`
	label: ActiveTabItems;

	background-color: #c72128;

	font-weight: 700;

	&:after {
		position: absolute;
		content: '';
		height: 2px;
		bottom: -1px;
		left: 0;
		right: 0;
		-webkit-transition: background-color 0.1s ease-in-out;
		transition: background-color 0.1s ease-in-out;
	}
`;
const cssInActiveTabItems = css`
	label: InActiveTabItems;
`;

export const StyledRaceCard = styled('div')`
	${(props) =>
		`
	width:100%;
	border-radius:8px;
	margin-top: 20px;
	border:1px solid #cacaca;
	padding: ${spacings(props).compact}
	`}
`;
const StyledRaceResultsTitle = styled('div')`
	${(props) => css`
		label: RaceResultsTitle;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		text-transform: uppercase;
		color: #000000;
		margin: 40px 0px;
		padding: ${spacings(props).compact}px;
	`}
`;

// TODO: move
function getNumberOfResults(results, product) {
	if (!product || !product.product_id || !results[product.product_id]) {
		return 0;
	}

	return results[product.product_id].length;
}

class RaceCard extends Component {
	/**
	 * Check if a certain bet with a matching type or product, etc, has been placed
	 *
	 * @param key
	 * @param value
	 * @param props
	 * @returns {*}
	 */
	static isBetPlaced = (key, value, props) => {
		const { bets, race } = props;

		return isBetPlacedForProduct(key, value, bets, race.products);
	};

	/**
	 * Return whether or not a Margin bet has been placed
	 *
	 * @param props
	 * @returns {*}
	 */
	static isMarginBetPlaced = (props) => {
		return RaceCard.isBetPlaced('bet_type', RACING_BET_TYPE_MARGIN, props);
	};

	/**
	 * Return whether or not a Bump bet has been placed
	 *
	 * @param props
	 * @returns {*}
	 */
	static isBumpBetPlaced = (props) => {
		return RaceCard.isBetPlaced('product_type', GOAT_PRODUCT_TYPE_BOOST, props);
	};

	/**
	 * Check that Early Speed is available by seeing if the first selection has an early speed position
	 *
	 * @param selections
	 * @returns {Array|number|boolean}
	 */
	static isEarlySpeedAvailable = (selections = []) => {
		return selections && selections.length && parseInt(selections[0].early_speed_position) >= 0;
	};

	/**
	 * Check that Settling Position is available by checking the length of the first selections settling speed positions
	 *
	 * @param selections
	 * @returns {Array|number|*|boolean}
	 */
	static isSettlingPositionAvailable = (selections = []) => {
		return (
			selections &&
			selections.length > 0 &&
			selections[0].settling_speed_positions &&
			selections[0].settling_speed_positions.length > 0
		);
	};

	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}).isRequired,

		/** De-normalized race and details */
		race: PropTypes.shape({
			id: PropTypes.number.isRequired,
			status: PropTypes.string,
			fixed_odds_enabled: PropTypes.bool,
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
			exotic_bets_allowed: PropTypes.bool,
			selections: PropTypes.array,
			win_pool_total: PropTypes.number,
			place_pool_total: PropTypes.number,
			products: PropTypes.array,
			tips: PropTypes.array,
			displayed_results: PropTypes.array,
			results: PropTypes.array,
		}).isRequired,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** The currently authenticated user */
		user: PropTypes.shape({
			/** The number of boosts available to the user */
			racing_boost_available: PropTypes.number,
		}),

		/** Single bets place in this race */
		bets: PropTypes.array,

		/** Whether or not to render the pusher updater */
		includePusher: PropTypes.bool,

		/** Trigger to clear selected checkboxes state */
		clearSelections: PropTypes.bool,

		/** Function to clear selected checkboxes state */
		handleClearSelections: PropTypes.func,

		/** Display the bet type filters */
		displayBetTypeFilters: PropTypes.bool,

		/** Whether betting should be disabled or not */
		disableBetting: PropTypes.bool,

		/** Display exotic bet types */
		disableExotics: PropTypes.bool,

		/** Display derivative bet types */
		disableDerivatives: PropTypes.bool,

		/** Display speedmap tabs */
		disableSpeedmaps: PropTypes.bool,

		/** Computed results to render */
		displayedResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
		displayedExoticResults: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

		/** Loading mask status to cover race card section */
		loadingRace: PropTypes.bool,

		/** Fluctuations key to be rendered. False values hides Flucs column */
		fluctuationsKey: PropTypes.string,

		/** Flag to render Forms button or not. Based of 'runner', under Selections. */
		shouldRenderFormsButton: PropTypes.bool,

		/** Handle when a selection is clicked on to make a bet */
		onClickSingle: PropTypes.func,

		/** Handle when an exotic selection is clicked on */
		onClickExotic: PropTypes.func,

		ShowBetProduct: PropTypes.bool,

		/** Handle when a selection is added to multi */
		onClickMulti: PropTypes.func,

		/** Handle when a derivative selection is clicked on */
		onDerivativeSelectionClick: PropTypes.func,

		/** Handle navigation to a race id */
		navigateToRace: PropTypes.func,

		/** The selected bet type ( win/place, trifecta, etc) */
		selectedBetType: PropTypes.string,

		/** If the exotic is boxed or not */
		boxed: PropTypes.bool,

		/** The selected bet product */
		selectedProduct: PropTypes.string,

		/** Function to calculate the margin length from the race */
		getGoatMarginButtLength: PropTypes.func,

		/** Handle when the bet type is changed */
		onBetTypeChange: PropTypes.func,

		/** Tracks bet type selection */
		trackGaMarketType: PropTypes.func,

		/** Tracking all form toggle */
		trackGaAllForm: PropTypes.func,

		/** Tracks single runner form toggle */
		trackGaRunnerForm: PropTypes.func,

		/** Tracks goat product being enabled */
		trackGaGoatType: PropTypes.func,

		/** Tracks when speedmaps are toggled */
		trackGaSpeedmaps: PropTypes.func,

		/** Tracks when speedmaps are toggled */
		trackGRSHotStats: PropTypes.func,

		/** Tracks when BetBuilder are toggled */
		trackBetBuilder: PropTypes.func,

		onclickBetBuilder: PropTypes.func,

		/** Win/Place to build betting buttons */
		winPlaceProducts: PropTypes.arrayOf(
			PropTypes.shape({
				fixed: PropTypes.bool,
				bet_type: PropTypes.string,
			}),
		),

		/** Product choices to be displayed on small screens */
		winPlaceProductChoices: PropTypes.array,

		/** Extra classes */
		className: PropTypes.string,

		/** Passed via ownProps from parent */
		meetingId: PropTypes.number.isRequired,

		betBuilderMeeting: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			state: PropTypes.string,
			country: PropTypes.string,
			type: PropTypes.string.isRequired,
			start_date: PropTypes.string.isRequired,
			races: PropTypes.array,
		}),

		/** Navigate to the bet builder page */
		onGoToBetBulder: PropTypes.func.isRequired,

		meetingCountry: PropTypes.string,

		raceDistance: PropTypes.string,

		oddsGridPrice: PropTypes.array,

		trackComputerTipsType: PropTypes.func,

		dailyBailOut: PropTypes.number,
	};

	static defaultProps = {
		className: '',
		user: null,
		displayBetTypeFilters: true,
		disableExotics: false,
		disableDerivatives: false,
		disableSpeedmaps: false,
		includePusher: true,
		bets: [],
		loadingRace: false,
		meetingName: 'n/a',
		fluctuationsKey: null,
		displayedResults: [],
		displayedExoticResults: [],
		winPlaceProducts: [],
		oddsGridPrice: [],
		winPlaceProductChoices: [],
		disableBetting: false,
		shouldRenderFormsButton: true,
		selectedBetType: '',
		boxed: false,
		selectedProduct: '',
		clearSelections: false,
		handleClearSelections: () => { },
		getGoatMarginButtLength: () => { },
		onBetTypeChange: () => { },
		trackGaMarketType: () => { },
		trackGaAllForm: () => { },
		trackGaRunnerForm: () => { },
		trackGaGoatType: () => { },
		trackGaSpeedmaps: () => { },
		onClickExotic: () => { },
		onClickSingle: () => { },
		onClickMulti: () => { },
		navigateToRace: () => { },
		onDerivativeSelectionClick: () => { },
		trackGRSHotStats: () => { },
		ShowBetProduct: false,
		trackBetBuilder: () => { },
		onclickBetBuilder: () => { },
		betBuilderMeeting: null,
		meetingCountry: '',
		raceDistance: null,
		trackComputerTipsType: () => { },
		dailyBailOut: 0,
	};

	constructor(props) {
		super(props);

		this.state = {

			// Initial result to display
			activeResultTab: this.initialActiveResults(props.race),

			// Holds the status of the 'all form' open button
			allFormOpen: false,

			//hot selections open button
			hotStatsOpen: false,

			BetBuilderOpened: false,
			// Show my bets list if 2 bets or less or if betting not available
			showMyBets: props.bets.length < 3 || !this.isBettingAvailable(props.race),

			// The special GOAT product that has been selected
			selectedGoatProduct: null,

			// before click price bump showing ald fixed win price
			selectWinFixedOldPrice: [],

			// The informatics section and screen in view
			informatics: {
				speedmapsOpen: false,
				earlySpeedOpen: false,
				settlingPositionOpen: false,
				computerTipsOpen: false,
			},

			// Race details for prop comparisons
			raceId: props.race.id,
			raceStatus: props.race.status,
			toteChecked: false,
			betProductId: null,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.race.id !== prevState.raceId ||
			(nextProps.race.status !== prevState.raceStatus && nextProps.race.status === RACE_CLOSED_STATUS) ||
			(prevState.selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST &&
				(RaceCard.isBumpBetPlaced(nextProps) || (nextProps.user && nextProps.user.racing_boost_available <= 0))) ||
			(prevState.selectedGoatProduct === RACING_BET_TYPE_MARGIN &&
				(RaceCard.isMarginBetPlaced(nextProps) || (nextProps.user && nextProps.user.racing_butt_available <= 0)))
		) {
			return {
				allFormOpen: false,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				selectedGoatProduct: null,
				raceId: nextProps.race.id,
				raceStatus: nextProps.race.status,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: false,
				},
			};
		}

		return null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.race.id !== this.props.race.id) {
			this.setState({
				activeResultTab: this.initialActiveResults(this.props.race),
			});
		}
	}

	getFirstProductWithResult(race) {
		let products = race.products || [];
		let results = race.results || [];
		let exoticResults = race.displayed_exotic_results || [];
		return products.find((product) => {
			if (
				results.find((result) => result.product_id === product.product_id) ||
				exoticResults.find((result) => result.product_id === product.product_id)
			) {
				return true;
			}
			return false;
		});
	}

	/**
	 * Determine which set of race results to display by default
	 * @param race
	 * @returns {string}
	 */
	initialActiveResults = (race) => {
		let activeResultTab = '';
		// If there are (legacy) results to display, grab the Product ID of the results that would be displayed
		// by default and set them as the initial result to set to display for the results list
		if (race.displayed_results && race.displayed_results.length) {
			const defaultResultsProductId = race.displayed_results[0].product_id;
			const defaultResultsProduct = race.products.find((product) => product.product_id === defaultResultsProductId);
			if (defaultResultsProduct) {
				activeResultTab = defaultResultsProduct.product_name;
			}
		}

		if (!activeResultTab) {
			// Loop through the products until you find a result for that product
			let product = this.getFirstProductWithResult(race);
			activeResultTab = product && product.product_name;
		}

		return activeResultTab;
	};

	/**
	 * Add a selection id to the list of opened form items
	 */
	handleAllFormToggle = () => {
		this.setState((prevState) => {
			// When switching to true send GA event.
			if (!prevState.allFormOpen) {
				this.props.trackGaAllForm();
			}
			return {
				allFormOpen: !prevState.allFormOpen,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: false,
				},
			};
		});

	};

	/**
	 * Toggle the hot stats section open and closed
	 */
	handleHotStatsToggle = () => {
		this.setState((prevState) => {
			// When switching to true send GA event.
			// if (!prevState.informatics.speedmapsOpen) {
			// 	this.props.trackGRSHotStats('Hotstats');
			// }
			return {
				allFormOpen: false,
				hotStatsOpen: !prevState.hotStatsOpen,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: false,
				},
			};
		});
	};

	handleComputerizedTipToggle = (tabClicked) => {
		//this.props.trackGaSpeedmaps(tabClicked);
		this.setState((prevState) => {
			// When switching to true send GA event.
			// if (!prevState.informatics.computerTipsOpen) {
			//   this.props.trackGaSpeedmaps(tabClicked);
			// }

			return {
				allFormOpen: false,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: false,
					earlySpeedOpen: false,
					settlingPositionOpen: false,
					computerTipsOpen: !prevState.informatics.computerTipsOpen,
				},
			};
		});
	};

	goToBetBuilderPage = () => {
		const { meetingId, race } = this.props;
		this.props.onGoToBetBulder(this.props.meetingId);
	};

	/**
	 * Toggle the speedmaps section open and closed
	 */
	handleSpeedmapsToggle = () => {
		this.setState((prevState) => {
			// When switching to true send GA event.
			if (!prevState.informatics.speedmapsOpen) {
				this.props.trackGaSpeedmaps('Speedmap');
			}
			return {
				allFormOpen: false,
				hotStatsOpen: false,
				BetBuilderOpened: false,
				informatics: {
					speedmapsOpen: !prevState.informatics.speedmapsOpen,
					earlySpeedOpen: !prevState.informatics.speedmapsOpen,
					settlingPositionOpen: false,
					computerTipsOpen: false,
				},
			};
		});
	};

	/**
	 * Toggle the informatics section
	 * @param tabClicked
	 */
	handleInformaticsToggle = (tabClicked) => {
		this.props.trackGaSpeedmaps(tabClicked);
		this.setState({
			informatics: {
				speedmapsOpen: true,
				earlySpeedOpen: tabClicked !== 'settlingPosition',
				settlingPositionOpen: tabClicked === 'settlingPosition',
				computerTipsOpen: false,
			},
		});
	};

	/**
	 * Assess race status and determine whether is available
	 * @param race
	 * @return {boolean}
	 */
	isBettingAvailable = (race) => {
		return race.status === RACE_SELLING_STATUS && !this.props.disableBetting;
	};

	/**
	 * Return how many checkboxes to display for an exotic bet
	 *
	 * @returns {*}
	 */
	getCheckboxes = () => {
		let { selectedBetType, boxed } = this.props;

		if (boxed) {
			return { 1: true };
		}

		switch (selectedBetType) {
			case RACING_BET_TYPE_QUINELLA:
				return { 1: true, 2: true }; // add two checkbox
			//return { 1: true };
			case RACING_MULTIPLES_TYPE:
				return { 1: true, 2: true }; // add two checkbox
			case RACING_BET_TYPE_QUADDIE:
				return { 1: true };
			case RACING_BET_TYPE_DAILY_DOUBLE:
				return { 1: true };
			case RACING_BET_TYPE_EXACTA:
				return { 1: true, 2: true };
			case RACING_BET_TYPE_TRIFECTA:
				return { 1: true, 2: true, 3: true };
			case RACING_BET_TYPE_FIRST_FOUR:
				return { 1: true, 2: true, 3: true, 4: true };
			case RACING_BET_TYPE_STRAIGHT_EIGHT:
				return { 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true };
		}
	};

	/**
	 * Sort the products so that Fixed is first, and by bet type
	 * Change To the win place order a - win place, b - place place
	 * Ex: change to the win win must change to the order a.ordinal - b.ordinal
	 */
	sortProducts = (a, b) => {
		// Sort by the ordinal key first
		const order = a.ordinal - a.ordinal;
		if (order === 0) {
			// Then sort by fixed, if the ordinal is the same
			const isFixed = b.fixed - a.fixed;
			// If they are both fixed, sort by bet_type
			if (isFixed === 0) {
				// Margin and Win bet types should come first, so we must set Margin to be Win
				// otherwise Place will come before it
				const betTypeA = a.bet_type === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_WIN : a.bet_type;
				const betTypeB = b.bet_type === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_WIN : b.bet_type;
				return betTypeA < betTypeB;
			}
			return isFixed;
		}
		return order;
	};

	/**
	 * Get the products shown for pools. This will be the same as `getShowingProducts` unless the selected product is an exotic
	 */
	getShowingPoolProducts = () => {
		const { selectedBetType } = this.props;
		const { selectedGoatProduct } = this.state;
		let exoticProduct;
		// If the bet type isn't win or place, it means that its an exotic. Find the bet product on that exotic. NOTE: we are making the assumption there is only one bet product for each exotic type. if we ever support more, then this is going to have to change...
		if (selectedBetType !== 'winplace' && !selectedGoatProduct) {
			exoticProduct =
				this.props.race.products &&
				this.props.race.products.find((product) => {
					const { bet_type } = product;
					return bet_type === selectedBetType;
				});
			if (exoticProduct) {
				return [exoticProduct];
			}
			return [];
		}

		return this.getShowingProducts();
	};

	handleChange(toteChecked) {
		this.setState({ toteChecked });
	}

	/**
	 * Selects which products to build betting button on selections list.
	 * @return {*}
	 */
	getShowingProducts = () => {
		const { selectedProduct, selectedBetType, winPlaceProducts, winPlaceProductChoices, size } = this.props;
		const { fixed_odds_enabled } = this.props.race;
		const { selectedGoatProduct } = this.state;
		// For mobile version purpose. Holds win/place category to render by pairs.
		const isMobileWidth = size.width < MOBILE_MAX_WIDTH;
		// Useful only from mobile version
		const chosenProduct = winPlaceProductChoices.find((choice) => choice.betType === selectedProduct) || {};

		let newWinPlaceProducts = [...winPlaceProducts];

		if (selectedGoatProduct === RACING_BET_TYPE_MARGIN) {
			// Grab the list of margin products
			const marginProducts = newWinPlaceProducts.filter((product) => product.bet_type === RACING_BET_TYPE_MARGIN);

			// Loop through each one, and replace the corresponding WIN product
			marginProducts.forEach((marginProduct) => {
				newWinPlaceProducts = newWinPlaceProducts.filter(
					(product) =>
						product.bet_type !== RACING_BET_TYPE_PLACE ||
						!(
							product.bet_type === RACING_BET_TYPE_PLACE &&
							product.product_code === marginProduct.product_code &&
							product.fixed === marginProduct.fixed
						),
				);
			});
		} else {
			// Filter out the GOAT Butt product if it is not enabled
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => product.bet_type !== RACING_BET_TYPE_MARGIN);
		}
		// Remove the fixed win product and use the goat bump product
		if (selectedGoatProduct === GOAT_PRODUCT_TYPE_BOOST) {
			newWinPlaceProducts = newWinPlaceProducts.filter(
				(product) =>
					product.product_type == GOAT_PRODUCT_TYPE_BOOST &&
					product.bet_type === RACING_BET_TYPE_WIN &&
					product.product_code === GOAT_BET_TYPE_WIN &&
					product.fixed,
			);
			return newWinPlaceProducts;
		} else {
			newWinPlaceProducts = newWinPlaceProducts.filter(
				(product) => product.product_type !== GOAT_PRODUCT_TYPE_BOOST && product.product_code != GOAT_BET_TYPE_WIN,
			);
		}

		if (!selectedBetType) {
			return [];
		}
		// When tote is selected
		if (!this.state.toteChecked) {
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => product.product_id != 6);
		}

		if (selectedBetType === 'winplace') {
			newWinPlaceProducts = newWinPlaceProducts.filter(
				(product) =>
					product.product_type !== GOAT_PRODUCT_TYPE_BOOST &&
					(product.product_id === 16 || // 16 is the product id for the win place product
						product.product_id === 6) && // 16 is the product id for the win place product
					(product.bet_type === RACING_BET_TYPE_WIN || product.bet_type === RACING_BET_TYPE_PLACE),
			);
			return newWinPlaceProducts;
		}

		if (selectedBetType === RACING_BET_TYPE_EACHWAY) {
			newWinPlaceProducts = newWinPlaceProducts.filter(
				(product) =>
					product.product_type !== GOAT_PRODUCT_TYPE_BOOST &&
					product.product_id === 16 &&
					product.bet_type === RACING_BET_TYPE_WIN,
			);
			// Change Fixed - Win to Win-Place
			// Price to EW
			newWinPlaceProducts[0] = {
				...newWinPlaceProducts[0],
				product_name: 'Win-Place',
				product_code: 'EW',
				product_id: -1,
				bet_type: RACING_BET_TYPE_EACHWAY,
				product_type: RACING_BET_TYPE_EACHWAY,
			};
			return newWinPlaceProducts;
		}

		if (selectedBetType === RACING_ODD_GRID_BET_TYPE) {
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => {
				if (selectedGoatProduct === RACING_BET_TYPE_MARGIN && chosenProduct.betType === RACING_BET_TYPE_WIN) {
					return product.bet_type === RACING_BET_TYPE_MARGIN || product.bet_type !== chosenProduct.betType;
				}
				return product.bet_type === chosenProduct.betType || product.bet_type === 'oddsgrid';
			});
			return newWinPlaceProducts.sort((a, b) => (a.product_code.toLowerCase() < b.product_code.toLowerCase() ? 1 : -1));
		}

		if (
			selectedBetType === RACING_BET_TYPE_NOTTO_WIN_BET ||
			selectedBetType === RACING_ISSURE_BET ||
			selectedBetType === RACING_MORE_MARKETS_TYPE
		) {
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => {
				return product.bet_type === chosenProduct.betType || product.bet_type === 'cover';
			});
			return newWinPlaceProducts;
		}

		if (selectedBetType === RACING_BET_TYPE_TRAINERT_4_BET) {
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => {
				return product.bet_type === chosenProduct.betType || RACING_BET_TYPE_TRAINERT_4_BET;
			});
			return newWinPlaceProducts;
		}

		if (selectedBetType === RACING_BET_TYPE_TRAINER_WIN_BET) {
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => {
				return product.bet_type === chosenProduct.betType || RACING_BET_TYPE_TRAINER_WIN_BET;
			});
			return newWinPlaceProducts;
		}

		if (selectedBetType === RACING_SAME_RACE_MULTI_TYPE) {
			// Remove the product win with sameracemulti bet type
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => {
				return (
					// product.bet_type === chosenProduct.betType ||
					product.bet_type === RACING_SAME_RACE_MULTI_TYPE && product.product_code !== 'Win'
				);
			});

			// Put the win product at the top of the list
			let sameRaceMultiWin = newWinPlaceProducts.find((product) => product.product_code === 'SRMWin');

			if (sameRaceMultiWin) {
				newWinPlaceProducts = newWinPlaceProducts.filter((product) => product.product_code !== 'SRMWin');
				newWinPlaceProducts.unshift(sameRaceMultiWin);
			}

			return newWinPlaceProducts;
		}
		// If exotic bet type, return available 'win' product. Also, cover the case where there is nothing to render.
		if (selectedBetType !== RACING_NON_EXOTIC_BET_TYPE) {
			let showingProduct = newWinPlaceProducts.find(
				(product) => product.fixed === fixed_odds_enabled && product.bet_type === RACING_BET_TYPE_WIN,
			);

			// Show the tote price if it can't find the fixed one
			if (!showingProduct && fixed_odds_enabled) {
				showingProduct = newWinPlaceProducts.find(
					(product) => product.fixed === false && product.bet_type === RACING_BET_TYPE_WIN,
				);
			}
			return showingProduct ? [showingProduct] : [];
		}

		newWinPlaceProducts.sort(this.sortProducts);

		// Mobile version
		if (isMobileWidth && chosenProduct.betType) {
			newWinPlaceProducts = newWinPlaceProducts.filter((product) => {
				if (selectedGoatProduct === RACING_BET_TYPE_MARGIN && chosenProduct.betType === RACING_BET_TYPE_WIN) {
					return product.bet_type === RACING_BET_TYPE_MARGIN || product.bet_type === chosenProduct.betType;
				}
				return product.bet_type === chosenProduct.betType;
			});
		}

		return newWinPlaceProducts;
	};

	/**
	 * get old win prices with selections
	 * @returns
	 */
	getSelectionsOldWinFixedPriceWhenPriceBump = (selections) => {
		let newSelections = [];
		selections.map((sel, index) => {
			newSelections.push({
				index,
				id: sel.id,
				name: sel.name,
				selection_status: sel.selection_status,
				prices: sel.prices.length > 0 && sel.prices.find((price) => price.product_id === 16), // product_code: "GRSF"
			});
		});
		return newSelections;
		// return selections.map((sel) => {
		// 	return {
		// 		id: sel.id,
		// 		name: sel.name,
		// 		selection_status: sel.selection_status,
		// 		prices: sel.prices.length > 0 && sel.prices.find((price) => price.product_id === 16), // product_code: "GRSF"

		// 	}
		// });
	};

	/**
	 * Change the tab when clicking on a goat product
	 */
	onSelectGoatProduct = (data) => {
		this.setState((prevState) => {
			if (data === GOAT_PRODUCT_TYPE_BOOST && prevState.selectedGoatProduct !== GOAT_PRODUCT_TYPE_BOOST) {
				this.props.trackGaGoatType('GOAT Price Boost');
			} else if (data === RACING_BET_TYPE_MARGIN && prevState.selectedGoatProduct !== RACING_BET_TYPE_MARGIN) {
				this.props.trackGaGoatType('GOAT Margin Butt');
			}

			const runningSelections = getNotScratchedSelections(this.props.race.selections);
			return {
				selectedGoatProduct: data === prevState.selectedGoatProduct ? null : data,
				selectWinFixedOldPrice:
					data === prevState.selectedGoatProduct
						? null
						: this.getSelectionsOldWinFixedPriceWhenPriceBump(runningSelections),
			};
		});
	};

	/**
	 * Change the selected bet type and reset the selected goat product
	 *
	 * @param selectedBetType
	 * @param boxed
	 * @param selectedProduct
	 */
	handleBetTypeChange = (selectedBetType, boxed, selectedProduct) => {
		this.onSelectGoatProduct(null);
		this.props.onBetTypeChange(selectedBetType, boxed, selectedProduct);
	};

	/**
	 * Toggles the 'My Bets' section
	 */
	toggleShowMyBets = () => {
		this.setState({
			showMyBets: !this.state.showMyBets,
		});
	};

	formatSettlingPositions = (selections) => {
		let settlingSelections = [];

		// Split settling_speed_positions into row and position and add it to the selection
		selections.map((selection) => {
			if (!selection.settling_speed_positions || !selection.settling_speed_positions.length) {
				return selection;
			}

			const settlingPositions = selection.settling_speed_positions;

			// Add a selection in the new array for each settling position.
			// That will create clones for selections that have multiple positions so they can be easily rendered.
			return settlingPositions.map((settlingPosition) => {
				settlingSelections.push({
					...selection,
					row: settlingPosition.substr(settlingPosition.length - 1),
					position: settlingPosition.substring(0, settlingPosition.length - 1),
				});
			});
		});

		return settlingSelections;
	};

	changeResultTab = (product) => {
		this.setState({
			activeResultTab: product,
		});
	};

	/**
	 * Group by relavent field
	 * @param array
	 */
	groupBy = (data, key) => {
		return data.reduce(function (acc, item) {
			(acc[item[key]] = acc[item[key]] || []).push(item);
			return acc;
		}, {});
	};

	/**
	 * get all types
	 * @param array searchArray
	 */
	getTipsTypes = (searchArray) => {
		const { race } = this.props;
		const computerTipsGroup = this.groupBy(searchArray, 'tips_type');
		//  console.log(computerTipsGroup);
		const tips = Object.keys(computerTipsGroup).map((group, key) => {
			return group;
		});
		return tips;
	};

	/**
	 * selections array with computer tips
	 * @param array selections
	 */
	getSelectionsComputerTips = (selections) => {
		const tipSelections = [];
		const race_rankingArray = [];

		const selection = selections.map((sel) => {
			if (sel.race_ranking !== null) {
				Object.entries(sel.race_ranking).forEach(([key, value]) => {
					return race_rankingArray.push({
						id: sel.id,
						name: sel.name,
						tab: sel.number,
						tips_type: key,
						points: Number(value),
						barrier: sel.barrier,
						silk: sel.silk,
						selection_status: sel.selection_status,
						prices: [sel.prices.find((price) => price.product_id === 16)], // product_code: "GRSF"
					});
				});
			}
		});
		if (selection) {
			tipSelections.push(race_rankingArray);
		}

		return tipSelections;
	};

	render() {
		const {
			t,
			race,
			user,
			includePusher,
			loadingRace,
			bets,
			selectedProduct,
			selectedBetType,
			boxed,
			winPlaceProductChoices,
			fluctuationsKey,
			disableExotics,
			disableDerivatives,
			disableSpeedmaps,
			displayedResults,
			displayedExoticResults,
			shouldRenderFormsButton,
			displayBetTypeFilters,
			trackGaRunnerForm,
			navigateToRace,
			onClickSingle,
			onClickMulti,
			onClickExotic,
			clearSelections,
			handleClearSelections,
			onDerivativeSelectionClick,
			buildSelectionBetButtons,
			getGoatMarginButtLength,
			winPlaceProducts,
			className,
			size,
			ShowBetProduct,
			meetingCountry,
			raceDistance,
			handlecacheOut,
			cashout_limit,
			betBuilderMeeting,
			onGoToBetBulder,
			trackComputerTipsType,
			handleAddToBackBook,
			backBookResponse,
			dailyBailOut,
			bailOutIsEnabled,
			handleAddSameRaceMulti,
			// selectionsOnbet
		} = this.props;

		// selectionsSameRaceMulti
		const {
			allFormOpen,
			selectedGoatProduct,
			selectWinFixedOldPrice,
			informatics,
			activeResultTab,
			hotStatsOpen,
			BetBuilderOpened,
		} = this.state;

		const isMobileWidth = size.width < MOBILE_MAX_WIDTH;
		const isQuaddie = selectedBetType === RACING_BET_TYPE_QUADDIE;
		const isDailyDouble = selectedBetType === RACING_BET_TYPE_DAILY_DOUBLE;
		const isQuaddieRace = quaddiePosition(race);
		const isDailyDoubleRace = dailyDoublePosition(race);

		const componentClasses = cx({
			[className]: className,
		});

		const displayedBetProducts = this.getShowingProducts();
		const displayedPoolProducts = this.getShowingPoolProducts();

		// get computer tips selections
		//const notScratchedSelections = getNotScratchedSelections(race.selections);
		const computerTipsSelections = this.getSelectionsComputerTips(race.selections);
		const finalcomputerTipsSelections = computerTipsSelections[0];

		// get all computer tips
		const computerTips = this.getTipsTypes(finalcomputerTipsSelections);

		const resultProductsSet = race.products
			? displayedResults &&
			race.products.length > 0 &&
			race.products.reduce((acc, product) => {
				if (
					(displayedResults[product.product_id] || displayedExoticResults[product.product_id]) &&
					product.product_type === PRODUCT_TYPE_STANDARD
				) {
					acc.add(product.product_name);
				}

				return acc;
			}, new Set())
			: displayedResults &&
			[] > 0 &&
			[].reduce((acc, product) => {
				if (
					(displayedResults[product.product_id] || displayedExoticResults[product.product_id]) &&
					product.product_type === PRODUCT_TYPE_STANDARD
				) {
					acc.add(product.product_name);
				}

				return acc;
			}, new Set());

		// const resultProducts = [...resultProductsSet].sort(this.sortProducts);// comment by original
		const resultProducts = [...resultProductsSet].sort();

		/**
		 * Find the races product given the name. If no name is supplied, then it should try and pick the first product that has a result
		 */
		const findRaceProductByName = (name) => {
			if (!name) {
				return this.getFirstProductWithResult(this.props.race);
			}
			return race.products.find((product) => product.product_name === name);
		};

		const activeResultProduct = findRaceProductByName(activeResultTab);

		const isBettingAvailable = this.isBettingAvailable(race);

		const showPriceBump =
			isBettingAvailable &&
			(!user || user.racing_boost_available > 0) &&
			winPlaceProducts.some((product) => product.product_type === GOAT_PRODUCT_TYPE_BOOST) &&
			!RaceCard.isBumpBetPlaced(this.props);
		const showMarginButt =
			isBettingAvailable &&
			(!user || user.racing_butt_available > 0) &&
			winPlaceProducts.some((product) => product.bet_type === RACING_BET_TYPE_MARGIN) &&
			!RaceCard.isMarginBetPlaced(this.props);

		const goatLength = selectedGoatProduct === RACING_BET_TYPE_MARGIN ? getGoatMarginButtLength(race) : null;
		const isDerivativesAvailable =
			!disableDerivatives && race.derivatives_enabled && race.derivative_markets && race.derivative_markets.length > 0;

		const shouldRenderButtBumpTabs =
			isBettingAvailable &&
			(showPriceBump || showMarginButt) &&
			(!isMobileWidth ||
				(isMobileWidth && selectedProduct === RACING_BET_TYPE_WIN && selectedBetType === RACING_NON_EXOTIC_BET_TYPE));

		// Selections for Speed Maps

		const runningSelections = getNotScratchedSelections(race.selections);
		const tipSelections = getSelectionsFromTips(runningSelections, race.tips || []);

		const displaySpeedmaps =
			!disableSpeedmaps &&
			(RaceCard.isEarlySpeedAvailable(runningSelections) || RaceCard.isSettlingPositionAvailable(runningSelections));

		// We dont want to use the TabsGroup for mobile
		const TabsGroupElement = isMobileWidth ? RCS.StyledRaceCard__TabsContainer_Div : RCS.StyledRaceCard__TabsContainer;

		const winPool =
			// Show only the Pool Total when GT is provided
			!race.global_tote_available ||
				selectedBetType !== RACING_NON_EXOTIC_BET_TYPE ||
				// On mobile, show only when tote products are showing
				(isMobileWidth && selectedProduct !== RACING_BET_TYPE_WIN)
				? null
				: race.win_pool_total / 100;

		const placePool =
			!race.global_tote_available ||
				selectedBetType !== RACING_NON_EXOTIC_BET_TYPE ||
				(isMobileWidth && selectedProduct !== RACING_BET_TYPE_PLACE)
				? null
				: race.place_pool_total / 100;

		// Loop through the products on the race
		const raceProductBetTypes = race.products.reduce((acc, product) => {
			// Check that the min selections matches the number of runners on the race
			const productKey = product.bet_type;
			const minimumRunningSelections =
				!RACING_EXOTIC_MINIMUM_SELECTIONS[productKey] ||
				runningSelections.length >= RACING_EXOTIC_MINIMUM_SELECTIONS[productKey];

			if (minimumRunningSelections) {
				/* only include quaddie/dailydouble if current race is in exotic pool */
				switch (productKey) {
					case 'quaddie':
						isQuaddieRace !== false ? acc.push(productKey) : null;
						break;
					case 'dailydouble':
						isDailyDoubleRace !== false ? acc.push(productKey) : null;
						break;
					default:
						acc.push(productKey);
				}
			}

			return acc;
		}, []);

		const handleNavigation = (raceId, race_id) => {
			/* Prevent navigation if already on race page */
			if (raceId === race_id) {
				return null;
			}
			return navigateToRace(raceId);
		};

		const quaddieNumberSequence = buildRaceNumberSequence(race.number, quaddiePosition(race), RACING_BET_TYPE_QUADDIE);
		const quaddieRaceNumbers = ` (${quaddieNumberSequence.map((numbers) => `${numbers}`)})`;
		const dailyDoubleNumberSequence = buildRaceNumberSequence(
			race.number,
			dailyDoublePosition(race),
			RACING_BET_TYPE_DAILY_DOUBLE,
		);
		const dailyDoubleRaceNumbers = ` (${dailyDoubleNumberSequence.map((numbers) => `${numbers}`)})`;

		const awating_result = () => {
			if (race.status === RACE_CLOSED_STATUS || race.status === RACE_FINISHED_STATUS) {
				return true;
			}

			return false;
		};

		const renderGoatButtBumpTabs = selectedBetType === RACING_NON_EXOTIC_BET_TYPE;

		//newWinPlaceProducts.filter((product) => product.product_type !== GOAT_PRODUCT_TYPE_BOOST && product.product_id === 16)
		return (
			<StyledRaceCard className={componentClasses}>
				{includePusher && <PusherSubscriberRaceChannel raceId={race.id} />}
				{/* //  add new loading spinner by @HW 26Sep2019	 */}
				<LoadingSpanner style={{ display: loadingRace ? 'block' : 'none' }} />
				{/* {loadingRace && <LoadingMask loading={loadingRace} displayAtTop={true} />} */}
				{bets.length > 0 && (
					<RCS.StyledRaceCard__MyBetsListHeader
						toggleCollapsibleContent={this.toggleShowMyBets}
						isCollapsed={!this.state.showMyBets}
						label={`${this.state.showMyBets ? t('RaceCard__HideMyBets').toUpperCase() : t('RaceCard__ShowMyBets').toUpperCase()
							} ( ${bets.length} )`}
						type="support"
						headerBarOptions={{ nodeLeft: <MyBetsIcon size="1" push="1" /> }}
					>
						<RCS.StyledRaceCard__MyBetsList
							activeBets={bets}
							raceStatus={race.status}
							handlecacheOut={handlecacheOut}
							cashout_limit={cashout_limit}
							dailyBailOutLimit={dailyBailOut}
							bailOutIsEnabled={bailOutIsEnabled}
						/>
					</RCS.StyledRaceCard__MyBetsListHeader>
				)}

				{/* {resultProducts.length > 1 && (
					<Tabs
						padding={Tabs.paddings.SPACING_TIGHT}
						wrappingBorder
						justify={resultProducts.length >= 3}
						type="secondary"
					>
						{resultProducts.map((product) => (
							<TabsItem
								key={product}
								className={product !== activeResultTab ? cssInActiveTabItems : cssActiveTabItems}
								active={product === activeResultTab}
								action={this.changeResultTab}
								data={product}
							>
								{product}{' '}
								{getNumberOfResults(displayedResults, findRaceProductByName(product)) > 0 && (
									<RaceCardProductBadge>W/P</RaceCardProductBadge>
								)}{' '}
								{getNumberOfResults(displayedExoticResults, findRaceProductByName(product)) > 0 && (
									<RaceCardProductBadge>Ex</RaceCardProductBadge>
								)}
							</TabsItem>
						))}
					</Tabs>
				)} */}
				<RCS.StyledRaceCard__Results size={size}>
					{activeResultProduct && displayedResults[activeResultProduct.product_id] && (
						<RCS.StyledRaceCard__ResultsWinPlace
							key={activeResultProduct.id}
							size={size}
							results={displayedResults[activeResultProduct.product_id]}
						/>
					)}
					{activeResultProduct && !disableExotics && displayedExoticResults[activeResultProduct.product_id] && (
						<RCS.StyledRaceCard__ResultsExotic
							size={size}
							results={displayedExoticResults[activeResultProduct.product_id]}
						/>
					)}
				</RCS.StyledRaceCard__Results>

				{awating_result() ? (
					<StyledRaceResultsTitle>
						<Icon icon={RACING_TYPES_LOOKUP[race.type]} size="3" push="1" />
						<Text size="1">{t("And they're off. Awaiting race results")}</Text>
					</StyledRaceResultsTitle>
				) : null}

				<TabsGroupElement size={size} wrap={isMobileWidth ? null : true}>
					{(shouldRenderFormsButton || displaySpeedmaps) && (
						<RCS.StyledRaceCard__ToggleAllFormContainer
							justify={isMobileWidth}
							padding={Tabs.paddings.SPACING_TIGHT}
							wrappingBorder
							size={size}
						>
							{shouldRenderFormsButton && (
								<TabsItem
									className={allFormOpen == false ? cssInActiveTabItems : cssActiveTabItems}
									active={allFormOpen}
									pointer
									action={this.handleAllFormToggle}
								>
									{t('RaceCard__Form')}
								</TabsItem>
							)}
							{displaySpeedmaps && (
								<TabsItem
									className={informatics.speedmapsOpen === false ? cssInActiveTabItems : cssActiveTabItems}
									active={informatics.speedmapsOpen}
									pointer
									action={this.handleSpeedmapsToggle}
								>
									{t('RaceCard__Speedmaps')}
								</TabsItem>
							)}

							{race.type === 'T' && selectedBetType !== 'OddGrid' && (
								<TabsItem
									className={hotStatsOpen === false ? cssInActiveTabItems : cssActiveTabItems}
									active={hotStatsOpen}
									pointer
									action={this.handleHotStatsToggle}
								>
									{t('RaceCard__HotStats')}
								</TabsItem>
							)}

							{finalcomputerTipsSelections && finalcomputerTipsSelections.length > 0 && (
								<div
									className={css`
										background: #2e2e2e;
										margin: 3px;
										color: #ffffff;
									`}
								>
									<TabsItem
										className={informatics.computerTipsOpen === false ? cssInActiveTabItems : cssActiveTabItems}
										active={informatics.computerTipsOpen}
										pointer
										action={this.handleComputerizedTipToggle}
									>
										{t('RaceCard__ComputerTips')}
									</TabsItem>
								</div>
							)}

							<Button
								action={this.goToBetBuilderPage}
								className={css`
									padding: 6px;
									line-height: 1.6;
									border-bottom: 0;
									border: 0;
								`}
								type="primary"
							>
								<span>{t('RaceCard_BetBuilder')}</span>
							</Button>
						</RCS.StyledRaceCard__ToggleAllFormContainer>
					)}

					{/* Bet Type Filtering */}
					{isBettingAvailable && displayBetTypeFilters && (
						<RCS.StyledRaceCard__BetTypeFilters
							size={size}
							right={!shouldRenderFormsButton && !shouldRenderButtBumpTabs}
							handleBetTypeChange={this.handleBetTypeChange}
							activeBetType={selectedBetType}
							activeProduct={selectedProduct}
							isBoxedSelected={boxed}
							exoticsAvailable={!disableExotics && race.exotic_bets_allowed}
							displayDropdownVersion={isMobileWidth}
							productsDisplayed={winPlaceProductChoices}
							raceProductBetTypes={raceProductBetTypes}
							quaddieRaceNumbers={quaddieRaceNumbers}
							dailyDoubleRaceNumbers={dailyDoubleRaceNumbers}
							selection={race.available_products}
						/>
					)}

					{renderGoatButtBumpTabs && (
						<RCS.StyledRaceCard__GoatButtBumpTabsWrapper size={size}>
							<RCS.StyledRaceCard__GoatButtBumpTabs
								disabled={selectedProduct !== RACING_BET_TYPE_WIN || selectedBetType !== RACING_NON_EXOTIC_BET_TYPE}
								onSelectGoatProduct={this.onSelectGoatProduct}
								selectedGoatProduct={selectedGoatProduct}
								showPriceBump={showPriceBump}
								showMarginButt={showMarginButt}
								size={size}
								selectedBetType={selectedBetType}
							/>
						</RCS.StyledRaceCard__GoatButtBumpTabsWrapper>
					)}

					{informatics.speedmapsOpen && (
						<RCS.StyledRaceCard__Informatics size={size}>
							<Tabs padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder justify={isMobileWidth} type="secondary">
								{/* {RaceCard.isEarlySpeedAvailable(runningSelections) && (
									<TabsItem
										className={informatics.earlySpeedOpen === false ? cssInActiveTabItems : cssActiveTabItems}
										active={informatics.earlySpeedOpen}
										action={this.handleInformaticsToggle}
										data="earlySpeed"
									>
										{t('RaceCard__EarlySpeed')}
									</TabsItem>
								)} */}
								{/* {RaceCard.isSettlingPositionAvailable(runningSelections) && (
									<TabsItem
										className={informatics.settlingPositionOpen === false ? cssInActiveTabItems : cssActiveTabItems}
										active={informatics.settlingPositionOpen}
										action={this.handleInformaticsToggle}
										data="settlingPosition"
									>
										{t('RaceCard__OnSettling')}
									</TabsItem>
								)} */}
							</Tabs>
							{!informatics.computerTipsOpen && <RCS.StyledRaceCard__SpeedmapHeader />}
							{informatics.earlySpeedOpen && (
								<EarlySpeed
									selections={runningSelections}
									handleClickSelection={onClickSingle}
									isBettingAvailable={isBettingAvailable}
									raceProducts={race.products}
									size={size}
								/>
							)}
							{informatics.settlingPositionOpen && (
								<SettlingPosition
									selections={this.formatSettlingPositions(runningSelections)}
									handleClickSelection={onClickSingle}
									isBettingAvailable={isBettingAvailable}
									raceProducts={race.products}
									size={size}
								/>
							)}
							<RCS.StyledRaceCard__SpeedmapLegend selections={runningSelections} size={size} />
							{tipSelections.length > 0 && (
								<RaceSelectionTips
									size={size}
									handleClickSingle={onClickSingle}
									handleClickMulti={onClickMulti}
									selections={tipSelections}
									raceProducts={race.products}
									isBettingAvailable={isBettingAvailable}
									buildSelectionBetButtons={buildSelectionBetButtons}
								/>
							)}
						</RCS.StyledRaceCard__Informatics>
					)}

					{informatics.computerTipsOpen && (
						<RCS.StyledRaceCard__Informatics size={size}>
							{informatics.computerTipsOpen && (
								<ComputerTips
									size={size}
									raceRanking={finalcomputerTipsSelections}
									trackComputerTipsType={trackComputerTipsType}
									computerTips={computerTips}
									isBettingAvailable={isBettingAvailable}
									raceProducts={race.products}
									racingType={race.type}
									handleClickSelection={onClickSingle}
								/>
							)}
						</RCS.StyledRaceCard__Informatics>
					)}
				</TabsGroupElement>

				{(isQuaddie || isDailyDouble) && (
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						{buildRaceLegSequence(
							race.id,
							selectedBetType === 'quaddie' ? quaddiePosition(race) : dailyDoublePosition(race),
							selectedBetType,
						).map((raceId, index) => (
							<Button
								key={raceId}
								action={() => handleNavigation(raceId, race.id)}
								type={raceId === race.id ? 'primary' : 'secondary'}
								size="small"
							>
								{t('Leg')} {index + 1}
							</Button>
						))}
					</div>
				)}

				{allFormOpen && race.comment && (
					<Panel>
						{tipSelections.length > 0 && (
							<RaceSelectionTips
								size={size}
								handleClickSingle={onClickSingle}
								handleClickMulti={onClickMulti}
								selections={tipSelections}
								raceProducts={race.products}
								isBettingAvailable={isBettingAvailable}
								buildSelectionBetButtons={buildSelectionBetButtons}
							/>
						)}
					</Panel>
				)}

				<RaceSelectionsList
					handleAddSameRaceMultiBetSlip={handleAddSameRaceMulti}
					currentSameRaceMultiPrice={this.props.currentSameRaceMultiPrice}
					selectionsSameRaceMulti={this.props.selectionsSameRaceMulti}
					handleSameRaceMultiSelections={this.props.handleSameRaceMultiSelections}
					// selectionsOnbet={selectionsOnbet}
					expandFormVisibility={this.state.expandFormVisibility}
					toteChanged={this.handleChange}
					toteChecked={this.state.toteChecked}
					size={size}
					clickSingle={onClickSingle}
					clickMulti={onClickMulti}
					clickExotic={onClickExotic}
					selections={race.selections}
					hotSelection={race.hot_selection && race.hot_selection.length > 0 ? race.hot_selection[0].id : null} // hot_selection
					displayedBetProducts={displayedBetProducts}
					displayedPoolProducts={displayedPoolProducts}
					// Some work was done on this concern on tracking issue's branch.
					allFormOpen={allFormOpen}
					// Bet type filtering, checkboxes control
					raceId={race.id}
					raceNumber={race.number}
					raceName={race.name}
					displayedCheckboxes={this.getCheckboxes()}
					clearSelections={clearSelections}
					handleClearSelections={handleClearSelections}
					betType={selectedBetType}
					boxed={boxed}
					// Betting availability based of Race status
					bettingAvailable={isBettingAvailable}
					// Decides whether and which fluctuations should be rendered. Null for none
					displayedFlucs={fluctuationsKey}
					trackOpenRunnerForm={trackGaRunnerForm}
					// Betting availability based of Race status
					goatLength={goatLength}
					isMobileWidth={isMobileWidth}
					buildSelectionBetButtons={buildSelectionBetButtons}
					winPool={winPool}
					placePool={placePool}
					hotStatsOpen={hotStatsOpen}
					ShowBetProduct={ShowBetProduct}
					Type={race.type}
					StartDate={race.start_date}
					MettingName={race.meeting_name}
					Test={race.selections}
					speedmapOpen={informatics.speedmapsOpen}
					meetingCountry={meetingCountry}
					raceDistance={raceDistance}
					user={user}
					enabled_cache_out={race.cash_out_enabled}
					handleAddToBackBook={handleAddToBackBook}
					backBookResponse={backBookResponse}
					selectedGoatProduct={selectedGoatProduct}
					showSelectWinFixedOldPrice={selectWinFixedOldPrice} // show old price when click price bump
				/>

				{/* <RaceCardCombinationTable /> */}
				{isDerivativesAvailable ? (
					<RCS.StyledRaceCard__DerivativeSelectionsList
						raceId={race.id}
						handleDerivativeSelectionClick={onDerivativeSelectionClick}
						derivativeMarkets={race.derivative_markets}
						bettingAvailable={isBettingAvailable}
						size={size}
					/>
				) : null}

				<RaceProductLegend products={race.products} hideExotics={disableExotics} />
			</StyledRaceCard>
		);
	}
}

export default withNamespaces()(RaceCard);
