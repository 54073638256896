import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { typography, Stepper, StatCell } from '@tbh/ui-kit';

import {
	RACING_BET_TYPE_EACHWAY_SHORT,
	RACING_BET_TYPE_PLACE_SHORT,
	RACING_BET_TYPE_WIN_SHORT,
	RACING_BET_TYPE_MARGIN_SHORT,
} from '../../../../../common/constants/Racing';

import { formatCentsAsPrice } from '../../../../../common/BetPlacement';

const StyledBetStake = styled(StatCell)(
	(props) => css`
		label: BetStake;

		color: ${typography(props).color_1};
		font-size: ${typography(props)['size_-3']};
		font-weight: bold;
		white-space: nowrap;
	`,
);

class BetStake extends Component {
	/**
	 * Determine the correct price field to use and display
	 *
	 * @param props
	 * @returns {number}
	 */
	static calculatePriceFromProps(props) {
		return props.racingBetType === RACING_BET_TYPE_PLACE_SHORT ? props.placePrice : props.price;
	}

	static propTypes = {
		/** The name of the selection being bet on */
		price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

		/** place price if the bet is eachway */
		placePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		/** The symbol for the type of race it is */
		racingBetType: PropTypes.oneOf([
			null,
			RACING_BET_TYPE_WIN_SHORT,
			RACING_BET_TYPE_PLACE_SHORT,
			RACING_BET_TYPE_EACHWAY_SHORT,
			RACING_BET_TYPE_MARGIN_SHORT,
		]),

		/** The number of rolls to perform when the price changes */
		priceRollups: PropTypes.number,

		/** Indicate that the price has been bumped up */
		displayPriceIsBumped: PropTypes.bool,

		/** How fast the stepper should animate each tick */
		stepperSpeed: PropTypes.number,

		/** Extra style class(es) to pass through */
		className: PropTypes.string,

		/** Name of the product being bet on */
		productName: PropTypes.string,
	};

	static defaultProps = {
		racingBetType: null,
		placePrice: 0,
		priceRollups: 0,
		isEachway: false,
		displayPriceIsBumped: false,
		stepperSpeed: 50,
		className: null,
		productName: null,
	};

	constructor(props) {
		super(props);

		const price = BetStake.calculatePriceFromProps(props);
		this.state = {
			price: price,
			displayPrice: price,

			// We default this to the current bump status so that it doesn't need to be ran
			ticksFinished: props.displayPriceIsBumped,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// Check if the price has changed
		const nextPrice = BetStake.calculatePriceFromProps(nextProps);
		if (nextPrice !== prevState.price) {
			return {
				price: nextPrice,
				ticksFinished: null,
			};
		}

		return null;
	}

	/**
	 * Handler when the stepper fires
	 *
	 * @param number
	 * @param ticksLeft
	 * @param ticksFinished
	 */
	handleStep = (number, ticksLeft, ticksFinished) => {
		this.setState({
			ticksFinished,
			displayPrice: number,
		});
	};

	render() {
		const {
			className,
			displayPriceIsBumped,
			placePrice,
			racingBetType,
			priceRollups,
			stepperSpeed,
			productName,
			hasTote
		} = this.props;
		const { displayPrice } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		const isEachway = racingBetType === RACING_BET_TYPE_EACHWAY_SHORT;
		let priceText = '';
		let display = isNaN(displayPrice) ? displayPrice : formatCentsAsPrice(displayPrice);

		if(isEachway) {
			priceText = `${RACING_BET_TYPE_WIN_SHORT} ${display}`;
		}else {
			priceText = display;
		}

		if(hasTote) {
			priceText = '@ TOTE';
		}
		return (
			<StyledBetStake
				wrapLabel={false}
				wrapValue={false}
				className={componentClasses}
				value={productName || null}
				defaultValue={null}
				statusColor={displayPriceIsBumped ? 'warning' : 'success'}
			>
				<Stepper number={this.state.price} steps={priceRollups * 3} onStep={this.handleStep} speed={stepperSpeed}>
					<div>
						{priceText}
					</div>
				</Stepper>
				{isEachway && (
					<div>
						&nbsp;/&nbsp;{RACING_BET_TYPE_PLACE_SHORT} {isNaN(placePrice) ? placePrice : formatCentsAsPrice(placePrice)}
					</div>
				)}
			</StyledBetStake>
		);
	}
}

export default BetStake;
