import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, Text, Notification } from '@tbh/ui-kit';

import AclContext from '../../../../contexts/AclContext';

const StyledPasswordResetRequestSuccess__Notification = styled(Notification)(
	(props) => css`
		label: PasswordResetRequestSuccess__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const PasswordResetRequestSuccess = (props) => {
	const { className, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<StyledPasswordResetRequestSuccess__Notification
				type="success"
				message={t('PasswordResetRequestSuccess__Success')}
			/>
			<Text size="-1" strong align="center">
				{t('PasswordResetRequestSuccess__Inbox')}
			</Text>
			<Text size="-1" align="center">
				{t('PasswordResetRequestSuccess__Instructions')}
			</Text>
			<AclContext.Consumer>
				{(acl) =>
					acl.brandDetails && acl.brandDetails.name ? (
						<Text align="center" size="-1" strong>
							- {acl.brandDetails.name} {t('Support')}
						</Text>
					) : null
				}
			</AclContext.Consumer>
		</div>
	);
};

PasswordResetRequestSuccess.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra class names */
	className: PropTypes.string,
};

PasswordResetRequestSuccess.defaultProps = {
	className: null,
};

export default withNamespaces()(PasswordResetRequestSuccess);
