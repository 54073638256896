import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, brand, Image } from '@tbh/ui-kit';

import BetSelectionDetails from '../BetSelectionDetails/BetSelectionDetails';
import NonExoticFilter from '../../NonExoticFilter/NonExoticFilter';

import { GENERIC_SILK, RACING_BET_TYPE_MARGIN_SHORT } from '../../../../../common/constants/Racing';

/**
 * Styling
 */
const StyledBetSelectionSingle__Silk = styled('div')(
	(props) => css`
		label: BetSelectionSingle__Silk;

		flex: 0 0 24px;
		margin-right: ${spacings(props).compact}px;
		color: ${brand(props).color_2};
	`,
);

const StyledBetSelectionSingle__Filter = styled(NonExoticFilter)(
	(props) => css`
		label: BetSelectionSingle__Filter;

		flex: 1;
		display: flex;
		text-align: right;
		margin-top: ${spacings(props).compact}px;
		width: 100%;
	`,
);
// End Styling

const BetSelectionSingle = (props) => {
	const {
		t,
		className,
		isRacing,
		racingBetType,
		silk,
		onBetTypeChange,
		selectionName,
		betTypeLabel,
		eventName,
		price,
		placePrice,
		eachwayDisabled,
		priceRollups,
		displayPriceIsBumped,
		margin,
		product_name,
		raceid_number,
		hasTote,
		handleSelectedCupomBet,
		stake,
		multiBetId
	} = props;

	const componentClasses = cx({
		[className]: className,
	});


	return (
		<div className={componentClasses}>
			<div
				className={css`
					display: flex;
				`}
			>
				{isRacing &&
					silk && (
						<StyledBetSelectionSingle__Silk>
							<Image src={silk} alt={selectionName + 'racing silk'} substituteImageSrc={GENERIC_SILK} />
						</StyledBetSelectionSingle__Silk>
					)}
				<BetSelectionDetails
					className={css`
						flex: 1;
					`}
					betTypeLabel={isRacing ? betTypeLabel : displayPriceIsBumped ? t('BetSelectionSingle__BUMPED') : null}
					marketDescription={
						!isRacing
							? betTypeLabel
							: margin && racingBetType === RACING_BET_TYPE_MARGIN_SHORT
								? t('BetSelectionSingle__MarginMessage', { margin })
								: null
					}
					product_name={product_name}
					selectionName={selectionName}
					eventName={eventName}
					raceid_number={raceid_number}
					price={price}
					placePrice={placePrice}
					racingBetType={racingBetType}
					priceRollups={priceRollups}
					displayPriceIsBumped={displayPriceIsBumped}
					hasTote={hasTote}
					handleSelectedCupomBet={handleSelectedCupomBet}
					stake={stake}
					id={multiBetId}
				/>
				{/* 
			<StyledBetSelectionMulti__Clear>
					<StyledBetSelectionMulti__ClearIcon size="-2" icon="close" action={handleOnRemove} />
				</StyledBetSelectionMulti__Clear> */}
			</div>
			{/*{!eachwayDisabled &&*/}
			{/*	isRacing && (*/}
			{/*		<StyledBetSelectionSingle__Filter type="radio*/}
			{/*		button" betType={racingBetType} action={onBetTypeChange} />*/}
			{/*	)}*/}
		</div>
	);
};

BetSelectionSingle.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The name of the selection being bet on */
	selectionName: PropTypes.string.isRequired,

	/** Name of the event or competition */
	eventName: PropTypes.string.isRequired,

	/** Is the event being bet racing or sports */
	isRacing: PropTypes.bool.isRequired,

	/** The price or odds of the selection being bet on */
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

	/** The non-exotic bet type to use with the filters */
	racingBetType: PropTypes.string,

	/** The bet type being bet on */
	betTypeLabel: PropTypes.string,

	/** Margin of selection */
	margin: PropTypes.number,

	/** Function to call when the non exotic bet type changes */
	onBetTypeChange: PropTypes.func,

	/** If eachway then the place price or odds of the selection being bet on */
	placePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** The number of rolls to perform when the price changes */
	priceRollups: PropTypes.number,

	/** The image path for the racing silk */
	silk: PropTypes.string,

	/** If this is true then hide the NonExoticFilter */
	eachwayDisabled: PropTypes.bool,

	/** Indicate that the price has been bumped up */
	displayPriceIsBumped: PropTypes.bool,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,

	raceid_number: PropTypes.number,
};

BetSelectionSingle.defaultProps = {
	betTypeLabel: null,
	onBetTypeChange: undefined,
	placePrice: null,
	silk: null,
	className: null,
	eachwayDisabled: false,
	margin: null,
	racingBetType: null,
	priceRollups: 0,
	displayPriceIsBumped: false,
	raceid_number: null,
};

export default withNamespaces()(BetSelectionSingle);
