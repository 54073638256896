// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

import { spacings, typography, FlexText, Icon, Text, PlotElements } from '@tbh/ui-kit';

// Components
import CurrencyDisplay from '../../../Application/Currency/CurrencyDisplay/CurrencyDisplay';
import CurrencyNameDisplay from '../../../Application/Currency/CurrencyNameDisplay/CurrencyNameDisplay';

// Functions
import { localDateTime } from '../../../../../legacy/core/format';

// Constants
import { DATE_TIME_FORMAT } from '../../../../../common/constants/DateFormats';

const StyledBetSummaryTicketItem = styled('div')`
	label: BetSummaryTicketItem;

	display: flex;
	align-items: center;
	cursor: pointer;
`;

const StyledBetSummaryTicketItem__Date = styled(Text)(
	(props) => css`
		label: BetSummaryTicketItem__Date;

		width: 60px;
		margin-right: ${spacings(props).compact}px;
	`,
);

const StyledBetSummaryTicketItem__AvailableCurrency = styled(Text)(
	(props) => css`
		label: BetSummaryTicketItem__AvailableCurrency;

		margin-left: ${spacings(props).cozy}px;
	`,
);

const StyledBetSummaryTicketItem__Icon = styled(Icon)(
	(props) => css`
		label: BetSummaryTicketItem__Icon;

		color: ${typography(props).color_2};
	`,
);

const BetSummaryTicketItem = (props) => {
	const {
		t,
		available_currency,
		className,
		buyin,
		end_date,
		entrants,
		icon,
		id,
		name,
		onClickTicketItem,
		position,
		start_date,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const handleClickTicketItem = () => {
		onClickTicketItem(id);
	};

	return (
		<StyledBetSummaryTicketItem className={componentClasses} onClick={handleClickTicketItem}>
			<StyledBetSummaryTicketItem__Date
				type={localDateTime(end_date) < moment() ? 'light' : null}
				size="-1"
				align="center"
				strong
			>
				{localDateTime(start_date).format(DATE_TIME_FORMAT)}
			</StyledBetSummaryTicketItem__Date>

			<div
				className={css`
					flex: 1 1 auto;
				`}
			>
				<FlexText>
					<Text strong>{name}</Text>
					<StyledBetSummaryTicketItem__Icon icon={icon} />
				</FlexText>

				<PlotElements align="start">
					<PlotElements align="start">
						<CurrencyDisplay amount={buyin} />{' '}
						<StyledBetSummaryTicketItem__AvailableCurrency tag="span" size="-1">
							<span>
								<CurrencyNameDisplay type="tournamentCurrency" />:{' '}
							</span>
							<Text tag="span" size="-1">
								{available_currency ? (
									<CurrencyDisplay amount={available_currency} type="tournamentCurrency" displayWholeAmount />
								) : (
									t('n/a')
								)}
							</Text>
						</StyledBetSummaryTicketItem__AvailableCurrency>
					</PlotElements>
					<PlotElements
						align="end"
						className={css`
							flex: 1;
							align-items: flex-end;
						`}
					>
						<span>{t('Rank')}: </span>
						<Text tag="span" size="-1">
							{position} / {entrants}
						</Text>
					</PlotElements>
				</PlotElements>
			</div>
		</StyledBetSummaryTicketItem>
	);
};

BetSummaryTicketItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Tournament's end date */
	end_date: PropTypes.string.isRequired,

	/** Number of participants in the tournament */
	entrants: PropTypes.number.isRequired,

	/** Tournament's id */
	id: PropTypes.number.isRequired,

	/** Tournament name */
	name: PropTypes.string.isRequired,

	/** Action for when clicking the node */
	onClickTicketItem: PropTypes.func.isRequired,

	/** User's position in the tournament */
	position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

	/** Tournament's start date */
	start_date: PropTypes.string.isRequired,

	/** Tournament's available currency */
	available_currency: PropTypes.number,

	/** Tournament's entry cost */
	buyin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	/** Extra classes */
	className: PropTypes.string,

	/** Tournament's icon */
	icon: PropTypes.string,
};

BetSummaryTicketItem.defaultProps = {
	available_currency: null,
	buyin: 'FREE',
	className: '',
	icon: 'star3',
};

export default withNamespaces()(BetSummaryTicketItem);
