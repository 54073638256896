import React from 'react';
import PropTypes from 'prop-types';
import FeatureContext from '../../../../contexts/featureContext';

const JsonLd = ({ content }) => {
	return <FeatureContext.Consumer>
		{(featureToggle) => {
			return featureToggle.features.jsonLd.enabled && <script type="application/ld+json">{JSON.stringify(content)}</script>;
		}}
	</FeatureContext.Consumer>;
};

JsonLd.propTypes = {
	/** Linked JSON data to display */
	content: PropTypes.shape({
		/** Context of the link data */
		'@context': PropTypes.string.isRequired,

		/** Name key for the JSON content */
		name: PropTypes.string,
	}).isRequired,
};



export default JsonLd;
