import styled, { css } from 'react-emotion';
import { spacings, typography, media, CollapsiblePanel, PoolTotals, Tabs, TabsGroup, brand } from '@tbh/ui-kit';

import RaceBetsList from '../RaceBetsList/RaceBetsList';
import GoatButtBumpTabs from '../GoatButtBumpTabs/GoatButtBumpTabs';
import BetTypeFilters from '../BetTypeFilters/BetTypeFilters';
import DerivativeSelectionList from '../Derivative/DerivativeSelectionList/DerivativeSelectionList';
import SpeedmapHeader from '../Informatics/Speedmap/SpeedmapHeader/SpeedmapHeader';
import SpeedmapLegend from '../Informatics/Speedmap/SpeedmapLegend/SpeedmapLegend';
import RaceResultsWinPlace from '../RaceResults/RaceResultsWinPlace/RaceResultsWinPlace';
import RaceResultsExotic from '../RaceResults/RaceResultsExotic/RaceResultsExotic';
import BetBuilderMeetingCard from '../BetBuilderMeetingCard/BetBuilderMeetingCard';
import { TABLET_MAX_WIDTH } from '../../../../common/constants/Breakpoints';

export const StyledRaceCard__DerivativeSelectionsList = styled(DerivativeSelectionList)`
	${(props) =>
		css`
			label: RaceCard__DerivativeSelectionsList;

			margin-top: ${spacings(props).compact}px;
		`};
`;

export const StyledRaceCard__MyBetsListHeader = styled(CollapsiblePanel)`
	${(props) =>
		css`
			label: RaceCard__MyBetsListHeader;

			: ${ '4px' ||spacings(props).compact}px;
			border-radius: ${spacings(props).compact}px;
		`};
`;

export const StyledRaceCard__MyBetsList = styled(RaceBetsList)`
	${(props) =>
		css`
			label: RaceCard__MyBetsList;
			border-radius: 0px 0px ${spacings(props).compact}px ${spacings(props).compact}px;
			border: 1px solid ${brand(props).primary};
			border-top: none;
			margin-bottom: ${spacings(props).cozy}px;
		`};
`;

export const StyledRaceCard__Results = styled('div')`
	${(props) =>
		css`
			label: RaceCard__Results;

			font-size: ${typography(props)['size_-2']};
		`};

	${media(
		css`
			display: flex;
		`,
		500,
	)};
`;

export const StyledRaceCard__TabsContainer = styled(TabsGroup)`
	label: StyledRaceCard__TabsContainer;

	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid hsl(0deg 0% 85%);
	margin-bottom: 10px;
	gap: 20px;

	${media(
		css`
			flex-flow: row wrap;
		`,
		500,
	)};
`;
export const StyledRaceCard__TabsContainer_Div = StyledRaceCard__TabsContainer.withComponent('div');

export const StyledRaceCard__Informatics = styled('div')`
	label: StyledRaceCard__Informatics;

	width: 100%;
	order: 2;

	${media(
		css`
			order: 3;
		`,
		500,
	)};
`;

export const StyledRaceCard__ToggleAllFormContainer = styled(Tabs)`
	${(props) =>
		css`
			label: RaceCard__ToggleAllFormContainer;
			align-self: flex-start;
			order: 1;
			min-width: 46px; //IE
			align-items: center;
			padding-top: 3px;
			padding-bottom: 3px;
		`};

	${media(
		css`
			flex: 0 1 auto; // IE
		`,
		755,
	)};
`;

export const StyledRaceCard__GoatButtBumpTabsWrapper = styled('div')`
	${(props) =>
		css`
			label: RaceCard__GoatButtBumpTabsWrapper;

			display: flex;
			flex: auto; // IE
			order: 4;
			width: 100%;
		`};

	${media(
		css`
			justify-content: flex-end;
		`,
		500,
	)};

	${media(
		(props) => css`
			flex: 1 0 auto; // IE
			order: 3; // Should appear between form and bet types
			margin-right: ${spacings(props).cozy}px;
			margin-left: ${spacings(props).compact}px;
			width: auto;
		`,
		755,
	)};
`;

export const StyledRaceCard_GoatButtBumpTabsWrapper_None = styled('div')`
	${(props) => css`
		display: none;
	`}
`;

export const StyledRaceCard__GoatButtBumpTabs = styled(GoatButtBumpTabs)`
	${(props) =>
		css`
			label: RaceCard__GoatButtBumpTabs;

			justify-content: center;
			flex: auto; // IE
		`};
`;

export const StyledRaceCard__BetTypeFilters = styled(BetTypeFilters)`
	${(props) =>
		css`
			label: RaceCard__BetTypeFilters;

			flex: 1 0 auto; // IE
			order: 3;
		`};

	${media(
		(props) => css`
			flex: 0 1 auto; // IE

			${props.right && 'flex: auto;'};
		`,
		755,
	)};
`;

export const StyledRaceCard__BettingTotals = styled(PoolTotals)`
	${(props) =>
		css`
			label: RaceCard__BettingTotals;

			margin-bottom: ${spacings(props).cozy}px;
		`};
`;

export const StyledRaceCard__SpeedmapHeader = styled(SpeedmapHeader)`
	${(props) =>
		css`
			label: RaceCard__SpeedmapHeader;

			margin-top: ${spacings(props).cozy}px;
			margin-bottom: ${spacings(props).cozy}px;
		`};
`;

export const StyledRaceCard__SpeedmapLegend = styled(SpeedmapLegend)`
	${(props) =>
		css`
			label: RaceCard__SpeedmapLegend;

			margin-top: ${spacings(props).cozy}px;
			margin-bottom: ${spacings(props).cozy}px;
		`};
`;

export const StyledRaceCard__BetBuilder = styled(BetBuilderMeetingCard)`
	label: StyledRaceCard__BetBuilderMeetingCard;
	width: 100%;
	order: 4;

	${media(
		css`
			order: 5;
		`,
		500,
	)};
`;

export const StyledRaceCard__BetBuilder__Content = styled('div')`
	label: StyledRaceCard__BetBuilder;
	width: 100%;
	height: auto;
	background-image: linear-gradient(to bottom, rgba(129, 129, 129, 0.52), rgba(210, 210, 210, 0.73)),
		url('https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/a-california-racetrack-is-shutting-down-indefinit-2-32590-1551897611-0_dblbig.jpg');
	background-size: cover;
	color: white;
	padding: 20px;
	display: flex;
`;

export const StyledRaceCard__BetBuilder__Meeter = styled('div')`
	label: StyledRaceCard__BetBuilder__Meeter;
	background-image: url('https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/ban-909970_1280.png');
	background-size: cover;
	width: 300px;
	height: 300px;
	margin-top: 150px;
	margin-left: 80px;
`;

export const StyledRaceCard__BetBuilder__Button = styled('div')`
	label: StyledRaceCard__BetBuilder__Button;
	width: 50%;
	height: 100%;
`;

export const StyledRaceCard__BetBuilder__Card__Content = styled('div')`
	label: StyledRaceCard__BetBuilder__Card__Content;
	width: 100%;
	display: flex;
`;

export const StyledRaceCard__BetBuilder__Card__Content__Details = styled('div')`
	label: StyledRaceCard__BetBuilder__Card__Content__Details;
	width: 100%;
	height: 100%;
	color: #000000;
`;
export const StyledRaceCard__BetBuilder__Card__Content__Button = styled('div')`
	label: StyledRaceCard__BetBuilder__Card__Content__Button;
`;
export const StyledRaceCard__ResultsWinPlace = styled(RaceResultsWinPlace)`
	${(props) =>
		css`
			label: RaceCard__ResultsWinPlaceExotic;
			display: none;
			margin-bottom: ${spacings(props).compact}px;
		`};

	${media(
		(props) => css`
			flex: 1;
			margin-right: ${spacings(props).cozy}px;

			&:last-child {
				margin-right: 0;
			}
		`,
		500,
	)};
`;

export const StyledRaceCard_ExpertSection = styled('div')`
	label: RaceCard_ExpertSection;
	display: flex;

	${media(
		(props) => css`
			display: block;
		`,
		1024,
	)};
`;
export const StyledRaceCard__RaceOverView = styled('div')`
	${(props) =>
		css`
			label: RaceCard__RaceOverView;

			background: #f0f1f3;
			flex: 1 1 70%;
			padding: ${spacings(props).tight}px;
			//margin-bottom: ${spacings(props).compact}px;
		`};
	${media(
		(props) => css`
			flex: 0 0 0;
		`,
		500,
	)};
`;
export const StyledRaceCard__ExpertTopSelection = styled('div')`
	${(props) =>
		css`
			label: RaceCard__ExpertTopSelection;

			padding-left: ${spacings(props).tight}px;
			padding-right: ${spacings(props).cozy}px;
		`};
`;

export const StyledRaceCard__Expert = styled('div')`
	${(props) =>
		css`
			label: RaceCard__Expert;

			// width: 300px;
			height: 30px;
			position: relative;
			background-color: ${brand(props).rch_4};

			padding: 5px;

			-moz-box-shadow: 0 4px 0px rgba(0, 0, 0, 0.4);
			-webkit-box-shadow: 0 4px 0px rgba(0, 0, 0, 0.4);
			box-shadow: 0 4px 0px rgba(0, 0, 0, 0.4);

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 0;
				border-left: 20px solid #fff;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
			}
			&:before {
				content: '';
				position: absolute;
				right: -20px;
				bottom: 0;
				width: 0;
				height: 0;
				border-left: 20px solid ${brand(props).rch_4};
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
			}
		`};
`;

export const StyledRaceCard__ResultsExotic = StyledRaceCard__ResultsWinPlace.withComponent(RaceResultsExotic);

export const LegButtonGroup = styled('div')`
	label: LegButtonGroup;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 6px;
	margin-left: 10px;
	padding: 4px 8px;
`;

export const RaceCardMenu = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`;

export const StyledLegButton = styled('button')(
	(props) => css`
	label: StyledLegButton;
	position: relative!important;
	z-index:0;
	height: 34px;
	padding: 4px 8px;
	border-radius: 4px;
	font-size: 11px;
	font-weight: 600;
	border: 2px solid transparent;!important;

	&:hover {
		background-color: #BB181B!important;
		color: white;
	}
	${props.verified &&
		css`
			&:before {
				align-items: center;
				background: #5ab708;
				border: 2px solid #fff;
				border-radius: 50%;
				color: #fff;
				content: '';
				display: flex;
				font-family: FontAwesome, sans-serif;
				font-size: 7px;
				height: 17px;
				justify-content: center;
				position: absolute;
				right: -7px;
				top: -6px;
				width: 17px;
				z-index: 1;
			}
		`}
	${props.active &&
		css`
			color: #bb181b;
			border: 2px solid #bb181b !important;
		`};
`,
);

export const StyledOptions = styled('div')(
	(props) => css`
		position: relative;
		top: 18px;
		font-size: 12px;
		right: -8px;
		border-radius: 4px;
		display: -ms-flexbox;
		display: flex;
		flex-direction: row;
		width: fit-content;
		border: 1px solid #666666;
		background: #ffffff;
		z-index: 2;
	`,
);

export const StyledOption = styled('div')(
	(props) => css`
		transitions: ease-in-out 0.2s;
		border-right: 1px solid #666666;
		padding: 8px 12px;
		cursor: pointer;

		${props.active ? 'background: #850000; color: #fff;' : 'background: #fff; color: #000;'}

		@media (min-width: ${TABLET_MAX_WIDTH}px) {
			&:hover {
				background: #850000;
				color: #fff;
			}
		}

		&:last-child {
			border-right: none;
			border-radius: 0 4px 4px 0;
		}

		&:first-child {
			border-radius: 4px 0 0 4px;
		}
	`,
);
