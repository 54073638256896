import { get, post } from '../../common/Ajax';
import { createAction } from '../../common/actions/actionHelpers';
import { setBodyLoading } from '../application/applicationActions';
import { logoutUser } from '../authentication/authenticationActions';
import { normalizeAndMergeUser } from '../entities/actions/UserActions';
import { UPDATE_DEFAULT_BET_AMOUNTS } from './defaultBetAmountsActionsTypes';
// import * as Sentry from '@sentry/react';

/**
 * Action to Getting the Default Bet Amounts
 *
 */
export const getDefaultBetAmounts = () => (dispatch) => {
	return get('/user/settings')
		.then((response) => {
			return dispatch(createAction(UPDATE_DEFAULT_BET_AMOUNTS, response.data.data));
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};

/**
 * Action to Set/Update the Default Bet Amounts
 *
 * @param default_bet_amount
 * @param default_tournament_bet_amount
 * @param accepted_live_bet_warning
 */
export const setDefaultBetAmounts = (default_bet_amount, default_tournament_bet_amount, accepted_live_bet_warning) => (
	dispatch,
) => {
	let params = {
		default_bet_amount,
		default_tournament_bet_amount,
		accepted_live_bet_warning,
	};

	return post('/user/settings', params)
		.then((response) => {
			return dispatch(createAction(UPDATE_DEFAULT_BET_AMOUNTS, response.data.data));
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};

export const setDefaultUserSettings = (user, params) => (dispatch) => {
	const newUser = { ...user };
	dispatch(setBodyLoading(true));
	return post('/user/settings', params)
		.then((response) => {
			if (response.data.data.deactivate && params.deactivate) {
				// Logout the user
				dispatch(logoutUser(true, true));
			}

			dispatch(setBodyLoading(false));
			newUser.settings = response.data.data;
			return dispatch(normalizeAndMergeUser(newUser));
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};
