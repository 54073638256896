import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, application, spacings, Ticker } from '@tbh/ui-kit';

/**
 * Components
 */
import Carousel from '../../Carousel/Carousel';
import RaceNextJumpFilter from './RaceNextJumpFilter/RaceNextJumpFilter';

import { filterNextToJumpRaces } from '../../../../store/entities/selectors/NextToJumpRaceSelectors';
import NtjRacesCarouselItem from '../NtjRacesCarouselItem/NtjRacesCarouselItem';
import {
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_TYPES_LOOKUP,
} from '../../../../common/constants/Racing';

const StyledRaceNextJump = styled('div')`
	label: RaceNextJump;

	display: flex;
	flex-wrap: wrap;
`;

// Size at which the mode should change over
const modeChangeOverSize = 751;

const StyledRaceNextJump__Carousel = styled(Carousel)(
	(props) => css`
		label: RaceNextJump__Carousel;

		flex: 1;
		padding: 0 ${spacings(props).comfortable}px;
		height: ${application(props).racing.ntj_height}px;
		overflow: hidden;
	`,
	(props) =>
		media(
			css`
				margin-left: ${spacings(props).compact}px;
			`,
			modeChangeOverSize,
		),
);

const StyledRaceNextJump__NtjRacesCarouselItem = styled(NtjRacesCarouselItem)(
	(props) => css`
		label: RaceNextJump__NtjRacesCarouselItem;

		width: ${application(props).racing.ntj_item_width}px;
		padding: ${spacings(props).compact}px ${spacings(props).tight}px ${spacings(props).tight}px
			${spacings(props).compact}px;
		box-sizing: border-box;
	`,
);

class RaceNextJump extends React.Component {
	static propTypes = {
		/** Extra class(es) to pass through to the component */
		className: PropTypes.string,

		/** An array of the next to jump races */
		items: PropTypes.arrayOf(
			PropTypes.shape({
				/** The id of the race */
				id: PropTypes.number.isRequired,

				/** The id of the meeting the race is a part of */
				meeting_id: PropTypes.number.isRequired,

				/** The name of the meeting */
				meeting_name: PropTypes.string.isRequired,

				/** The type of race ie. Thoroughbred, Harness, Greyhounds */
				type: PropTypes.oneOf([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]).isRequired,

				/** The number of the race within the meeting */
				race_number: PropTypes.number.isRequired,

				/** The starting time of the race */
				start_datetime: PropTypes.string.isRequired,
			}),
		).isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Function to handle the click event on each next to jump item */
		onItemClick: PropTypes.func,

		/** Action to track when a filter is applied */
		trackOnFilterClick: PropTypes.func,

		/** Whether or not to hide the next to jump filter */
		hideRaceNextJumpFilter: PropTypes.bool,
	};

	static defaultProps = {
		className: null,
		onItemClick: () => undefined,
		trackOnFilterClick: () => undefined,
		hideRaceNextJumpFilter: false,
	};

	state = {
		// initially set all the type filters active
		typeFilter: new Set([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]),
	};

	/**
	 * The onClick on the Carousel item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *
	 * @param id
	 */
	handleClick = (id) => {
		let item = this.props.items.find((item) => item.id === id);
		this.props.onItemClick(item.id, item.meeting_id);
	};

	/**
	 * Handles the clicking/selecting of the race type filters
	 * @param type
	 */
	handleOnFilterClick = (type) => {
		this.saveTypeToFilters(type);
		this.props.trackOnFilterClick(RACING_TYPES_LOOKUP[type]);
	};

	/**
	 * Saves the race type filters to state
	 * @param type
	 */
	saveTypeToFilters = (type) => {
		let types = new Set(this.state.typeFilter);

		if (!type) {
			return;
		}

		if (types.has(type)) {
			types.delete(type);
		} else {
			types.add(type);
		}

		this.setState({
			typeFilter: types,
		});
	};

	/**
	 * Checks whether the startTime is around 5min from now and returns period to set Ticker interval.
	 *
	 * @param {string} startTime YYYY-11-29T16:32:42+11:00
	 * @return {number|null} - ticker period
	 */
	getTickerInterval = (startTime = null) => {
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}

		// null makes Ticker clear interval.
		return null;
	};

	render() {
		const { className, hideRaceNextJumpFilter, size } = this.props;

		const items = filterNextToJumpRaces(this.props.items, Array.from(this.state.typeFilter));

		const componentClasses = cx({
			[className]: className,
		});

		if (items.length === 0) {
			return false;
		}
		return (
			<StyledRaceNextJump className={componentClasses}>
				{!hideRaceNextJumpFilter && (
					<RaceNextJumpFilter
						mode={size.width > modeChangeOverSize ? 'vertical' : 'horizontal'}
						onFilterItemClick={this.handleOnFilterClick}
						types={Array.from(this.state.typeFilter)}
					/>
				)}

				<StyledRaceNextJump__Carousel size={size} nextButton prevButton freeMode buttonStyle="1">
					{items.map(
						(nextToJumpRace) => (
							<Ticker every={this.getTickerInterval(nextToJumpRace.start_datetime)} key={nextToJumpRace.id}>
								<StyledRaceNextJump__NtjRacesCarouselItem
									id={nextToJumpRace.id}
									raceNumber={nextToJumpRace.race_number}
									type={nextToJumpRace.type}
									meetingName={nextToJumpRace.meeting_name}
									startTime={nextToJumpRace.start_datetime}
									action={this.handleClick}
								/>
							</Ticker>
						),
						this,
					)}
				</StyledRaceNextJump__Carousel>
			</StyledRaceNextJump>
		);
	}
}

export default RaceNextJump;
