import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, Checkbox, Tabs, TabsItem } from '@tbh/ui-kit';

import {
	RACING_BET_TYPE_TITLES,
	RACING_EXOTIC_BET_TYPES,
	// RACING_BET_TYPE_QUINELLA,
	// RACING_BET_TYPE_EXACTA,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_DAILY_DOUBLE,
} from '../../../../common/constants/Racing';
const cssActiveTabItems = css`
	label: ActiveTabItems;

	color: #c72128;
    font-weight: 700;
`;
const cssInActiveTabItems = css`
	label: InActiveTabItems;

`;
const StyledExoticBetTypeFilters__TabsItem = styled(TabsItem)(
	(props) => css`
		label: ExoticBetTypeFilters__TabsItem;

		${props.checkboxSize === 'medium' &&
			css`
				line-height: 22px;
			`};
	`,
);

const StyledExoticBetTypeFilters__Checkbox = styled(Checkbox)(
	(props) => css`
		label: ExoticBetTypeFilters__Checkbox;

		margin-left: ${spacings(props).tight}px;

		${props.checkboxSize === 'medium' &&
			css`
				vertical-align: sub;
			`};
	`,
);

const ExoticBetTypeFilters = (props) => {
	const {
		className,
		activeBetType,
		checkboxSize,
		isBoxedSelected,
		selectBetType,
		raceProductBetTypes,
		quaddieRaceNumbers,
		dailyDoubleRaceNumbers,
		t,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Handles the setting of a new bet type
	 *
	 * @param requestedBetType
	 */
	const handleSelectBetType = (requestedBetType) => {
		// no need to do anything if bet type hasn't changed and box not selected
		if (activeBetType === requestedBetType && !isBoxedSelected) {
			return;
		}

		// return the new bet type and set the boxed status to false
		selectBetType(requestedBetType, false);
	};

	/**
	 * Handles the toggling of the boxed selector checkboxes and sets new bet type
	 *
	 * @param e
	 * @param requestedBetType
	 */
	const toggleBoxedSelector = (e, requestedBetType) => {
		// toggle boxed status
		let newBoxedStatus = !isBoxedSelected;

		// unless bet type changes and boxed true
		if (activeBetType !== requestedBetType && isBoxedSelected) {
			newBoxedStatus = isBoxedSelected;
		}

		// send back the bet type and toggle the boxed status
		selectBetType(requestedBetType, newBoxedStatus);
	};

	// Only display the buttons for the exotic bet types if they are in the list of race products
	const exoticBetTypes = RACING_EXOTIC_BET_TYPES.filter((exoticBetType) => raceProductBetTypes.includes(exoticBetType));

	return (
		<Tabs
			alignment="right"
			justifyAuto
			padding={Tabs.paddings.SPACING_TIGHT}
			wrappingBorder
			className={componentClasses}
			checkboxSize={checkboxSize}
		>
			{exoticBetTypes.map((exoticBetType) => (
				<StyledExoticBetTypeFilters__TabsItem
					className={activeBetType === exoticBetType ? cssActiveTabItems  : cssInActiveTabItems}
					checkboxSize={checkboxSize}
					active={activeBetType === exoticBetType}
					key={exoticBetType}
					action={handleSelectBetType}
					pointer
					data={exoticBetType}
				>
					{t(RACING_BET_TYPE_TITLES[exoticBetType])}

					{exoticBetType === RACING_BET_TYPE_QUADDIE && quaddieRaceNumbers}

					{exoticBetType === RACING_BET_TYPE_DAILY_DOUBLE && dailyDoubleRaceNumbers}

					{exoticBetType !== RACING_BET_TYPE_QUADDIE &&
						exoticBetType !== RACING_BET_TYPE_DAILY_DOUBLE &&
						// exoticBetType !== RACING_BET_TYPE_QUINELLA &&
						// exoticBetType !== RACING_BET_TYPE_EXACTA && (
							// @ Change 28/10/2022 - add checkbox for quinella and exacta
							(
							<StyledExoticBetTypeFilters__Checkbox
								name={exoticBetType}
								size={checkboxSize}
								inline
								checked={activeBetType === exoticBetType && isBoxedSelected}
								action={toggleBoxedSelector}
								pointer
								data={exoticBetType}
							/>
						)}
				</StyledExoticBetTypeFilters__TabsItem>
			))}
		</Tabs>
	);
};

ExoticBetTypeFilters.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The list of products available for the race */
	raceProductBetTypes: PropTypes.array,

	/** Which bet type is currently active or selected */
	activeBetType: PropTypes.oneOf([null, ...RACING_EXOTIC_BET_TYPES]),

	/** Whether the bet type selections are boxed or not */
	isBoxedSelected: PropTypes.bool,

	/** Function to handle the selecting of a bet type */
	selectBetType: PropTypes.func,

	/** A collection of race numbers within this bet type */
	dailyDoubleRaceNumbers: PropTypes.string,

	/** A collection of race numbers within this bet type */
	quaddieRaceNumbers: PropTypes.string,

	/** Set the size of the checkboxes for exotics */
	checkboxSize: PropTypes.oneOf([null, 'medium', 'large']),

	/** Extra style class(es) to pass through */
	className: PropTypes.string,
};

ExoticBetTypeFilters.defaultProps = {
	raceProductBetTypes: RACING_EXOTIC_BET_TYPES,
	activeBetType: null,
	isBoxedSelected: false,
	selectBetType: undefined,
	checkboxSize: null,
	className: null,
	quaddieRaceNumbers: '',
	dailyDoubleRaceNumbers: '',
};

export default withNamespaces()(ExoticBetTypeFilters);
