import { denormalize, normalize } from 'normalizr';
import { derivativeSelectionsSchema } from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param {Array} keys
 * @returns {Array}
 */
const denormalizeDerivativeSelections = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.derivative_selections), derivativeSelectionsSchema, entities).filter(
		(sel) => !!sel,
	);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizeDerivativeSelections = (response) => {
	return normalize(response, derivativeSelectionsSchema);
};

export { denormalizeDerivativeSelections, normalizeDerivativeSelections };
