import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';

import { updatePassword } from '../../../store/userProfile/userProfileActions';
import UpdatePassword from '../../../components/features/UserAccount/UpdatePassword/UpdatePassword';

const StyledUpdatePassword__Wrapper = styled(UpdatePassword)(
	(props) => css`
		label: UpdatePassword__Wrapper;
		margin: 0 auto;
		padding: ${spacings(props).cozy}px;
	`,
	media(
		css`
			max-width: 365px;
		`,
		606,
	),
);

const UpdatePasswordContainer = (props) => {
	const { updatePassword, className, size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			<StyledUpdatePassword__Wrapper size={size} updatePassword={updatePassword} />
		</div>
	);
};

UpdatePasswordContainer.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Form submission action*/
	updatePassword: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

UpdatePasswordContainer.defaultProps = {
	className: '',
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
	updatePassword: (oldPassword, newPassword, newPasswordConfirmation) => {
		return dispatch(updatePassword(oldPassword, newPassword, newPasswordConfirmation));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UpdatePasswordContainer);
