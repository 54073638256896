import { Button, ButtonGroup, Notification, Panel, Text } from '@tbh/ui-kit';
import React from 'react';
import { connect } from 'react-redux';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import { logoutUser } from '../../../store/authentication/authenticationActions';
import { closeModal } from '../../../store/modal/modalActions';

const InactivityDialogContainer = ({ handleLogout, handleContinue, user }) => {
	return (
		<div>
			<ModalHeader title="Inactivity" />
			<Panel padding={Panel.padding.SPACING_COZY}>
				<Text size="-1" align="center" strong>
					You have been inactive for too long
				</Text>

				{user && <Notification type="info" transparent message="You will be logged out automatically." />}

				{user ? (
					<ButtonGroup center>
						{/* <Button type="ghost" action={handleLogout} flex>
							Sign out
						</Button> */}

						<Button type="primary" action={handleContinue} flex>
							Sign in
						</Button>
					</ButtonGroup>
				) : (
					<ButtonGroup
						center
						style={{
							marginTop: 12,
						}}
					>
						<Button type="primary" action={handleContinue} flex>
							Sign in
						</Button>
					</ButtonGroup>
				)}
			</Panel>
		</div>
	);
};

const mapStateToProps = (state) => {
	const modal = state.modal.modals.find((modal) => modal.id === 'InactivityDialogContainer');
	let wasLogged = modal && modal.logged;
	return {
		user: wasLogged,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleContinue: () => {
			dispatch(closeModal('InactivityDialogContainer'));
			dispatch(logoutUser(true, true, true));
		},
		handleLogout: () => {
			dispatch(closeModal('InactivityDialogContainer'));
			dispatch(logoutUser(true, true, true));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InactivityDialogContainer);
