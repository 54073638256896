import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, Ticker } from '@tbh/ui-kit';

// Components
import { Text, CollapsibleContent, CollapsibleIcon, HeaderBar } from '@tbh/ui-kit';
import BetSelectionAndAmount from '../BetSelection/BetSelectionAndAmount/BetSelectionAndAmount';
import BetSelectionDetailsNew from '../BetSelection/BetSelectionDetailsNew';
import { BET_TYPE_MULTI, toteProducts } from '../../../../store/entities/constants/BetConstants';
import BetNotificationEventClosed from '../BetNotification/BetNotificationEventClosed';

const StyledMultiLegItem__CloseIcon = styled(CollapsibleIcon)(
	(props) => css`
		label: MultiLegItem__CloseIcon;

		min-width: ${spacings(props).comfortable}px;
	`,
);

const StyledContent = styled('div')`
	display: flex;
	flex-direction: column;
`;

const MultiLegItem = (props) => {
	let {
		className,
		disabled,
		isCollapsed,
		leg,
		onLegToggle,
		onStakeChange,
		t,
		selectionsAreFromSameRace,
		selections,
		handleSelectedCupomBet,
		bonus_bet_enabled,
		handleBonusBetChange,
		onRemove,
		selectedBet,
		handleSelectedBet,
		races,
	} = props;
	let { id, invalid, warning, name, numberOfBets, price, stake, free_credit_flag } = leg;

	const componentClasses = cx({
		[className]: className,
	});

	const handleLegItemToggle = () => {
		onLegToggle(id);
	};

	const isLegMulti = name.includes('Leg Multi');
	selections = selections.filter((selection) => !toteProducts.includes(selection.bet_code));
	// const hasTote = selections.some((selection) => toteProducts.includes(selection.bet_code));

	const onBetClick = (s) => {
		let data = null;

		if (selectedBet && selectedBet.id == s.id && selectedBet.group == 'legs') return;

		if (!selectedBet || (selectedBet.id != s.id && selectedBet.group != 'legs')) {
			data = {
				...s,
				amount: stake,
				group: 'legs',
			};
		}

		handleSelectedBet(data);
	};

	let isClosed = false;

	for (let i = 0; i < races.length; i++) {
		if (selections.find((s) => s.race_id == races[i].id && races[i].status != 'selling')) {
			isClosed = true;
			break;
		}
	}

	return (
		<div className={componentClasses}>
			{
				<HeaderBar
					type="primary"
					action={handleLegItemToggle}
					className={css`
						cursor: pointer;
					`}
					aside={<StyledMultiLegItem__CloseIcon size="-3" isCollapsed={isCollapsed} />}
				>
					<Text type="alternate" strong>
						{name} ({numberOfBets} {numberOfBets === 1 ? t('Bet') : t('Bets')})
					</Text>
				</HeaderBar>
			}

			<CollapsibleContent
				isCollapsed={isCollapsed}
				className={css`
					padding: 4px;
				`}
			>
				{isClosed && (
					<BetNotificationEventClosed onClick={() => selections.forEach((selection) => onRemove(selection))} />
				)}
				<StyledContent
					style={{
						opacity: isClosed ? 0.3 : 1,
					}}
				>
					<BetSelectionAndAmount
						selectionsAreFromSameRace={selectionsAreFromSameRace}
						handleBonusBetChange={() => handleBonusBetChange(id, BET_TYPE_MULTI)}
						bonus_bet_enabled={bonus_bet_enabled}
						free_credit_flag={free_credit_flag}
						isMulti={false}
						isRacing={false}
						selectionDisplay={name}
						betTypeLabel=""
						eventDisplay={`${numberOfBets} x ${t('Bets')}`}
						price={isNaN(parseInt(price)) ? null : parseInt(price)}
						stake={stake}
						id={id}
						enableRemoval={false}
						onStakeChange={onStakeChange}
						disabled={disabled}
						invalid={invalid}
						warning={warning}
						racingBetType="multi"
						isLeg={true}
						// hasTote={hasTote}
						handleSelectedCupomBet={handleSelectedCupomBet}
						selectedBet={selectedBet && selectedBet.id === id && selectedBet.group == 'legs'}
						handleSelectedBet={() => onBetClick(leg)}
					/>

					<Ticker every={1000}>
						{isLegMulti &&
							selections.map((selection) => (
								<BetSelectionDetailsNew
									key={selection.id}
									className={css`
										flex: 1;
									`}
									multiBetId={selection.id}
									onRemove={() => onRemove(selection)}
									selectionName={selection.selectionDisplay}
									betTypeLabel={selection.racingBetType ? selection.betTypeLabel : null}
									marketDescription={!selection.racingBetType ? selection.betTypeLabel : null}
									eventName={selection.eventDisplay}
									selectedBetType={selection.selectedBetType}
									meeting_name={selection.meeting_name}
									number={selection.number}
									raceType={selection.raceType}
									startTime={selection.startTime}
									bet_code={selection.bet_code}
									price={selection.price}
									//placePrice={placePrice}
									racingBetType={selection.racingBetType}
									// onClickSelection={handleClickSelection}
								/>
							))}
					</Ticker>
				</StyledContent>
			</CollapsibleContent>
		</div>
	);
};

MultiLegItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The leg item */
	leg: PropTypes.shape({
		/** The leg ID */
		id: PropTypes.number.isRequired,

		/** The leg name */
		name: PropTypes.string.isRequired,

		/** The number of bets that are included in the leg */
		numberOfBets: PropTypes.number.isRequired,

		/** The total price for the leg */
		price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

		/** The total stake entered for the leg */
		stake: PropTypes.number,

		/** If the leg is invalid */
		invalid: PropTypes.bool,

		/** Warning message to display for the leg */
		warning: PropTypes.string,
	}).isRequired,

	/** Action to update the stake against the leg */
	onStakeChange: PropTypes.func.isRequired,

	/** Action to toggle the leg item open and closed */
	onLegToggle: PropTypes.func.isRequired,

	/** If the leg item is disabled or not */
	disabled: PropTypes.bool,

	/** If the leg item is open or closed */
	isCollapsed: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};
MultiLegItem.defaultProps = {
	className: '',
	disabled: false,
	isCollapsed: false,
};

export default withNamespaces()(MultiLegItem);
