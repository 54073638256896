import React from 'react';
import { StyledPageComponentContainer } from './PageComponentContainer.styled';

const PageComponentContainer = ({ children, ...props }) => {

    return (
        <StyledPageComponentContainer {...props}>
            {children}
        </StyledPageComponentContainer>
    );
};

export { PageComponentContainer };