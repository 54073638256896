import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui, media, Select, TabsItem, Ticker } from '@tbh/ui-kit';

import { RACE_SELLING_STATUS,  } from '../../../../common/constants/Racing';

import RaceItem from './RaceItem/RaceItem';
import Carousel from '../../../features/Carousel/Carousel';
import NewRaceItem, { StatusPattern } from './RaceItem/NewRaceItem';
import { MOBILE_MAX_WIDTH } from '../../../../common/constants/Breakpoints';

const MIN_HEIGHT = 41;

const StyledRaceNav__Carousel = styled(Carousel)(
	(props) =>
		css`
		label: RaceNav__Carousel;
		overflow: hidden;
		height: ${props.isMobileWidth ? '64px' : '64px'}!important;
		padding:  ${props.isMobileWidth ? spacings(props).compact : spacings(props).compact}px;
		${Carousel.NextButton}, ${Carousel.PrevButton},
		${Carousel.NextButtonIcon}, ${Carousel.PrevButtonIcon} {
			height: ${props.isMobileWidth ? '64px' : '64px'}!important;
		}

		.swiper-wrapper {
			gap: 2px;
		}
	`,
);

const StyledRaceNav__RaceItem = styled(NewRaceItem)(
	(props) => css`
		label: RaceNav__RaceItem;
		min-width: 100px;
		// width: 100% !important;
		height: 45.7px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		margin-right: 0 !important;
		${props.displayCarousel &&
			css`
				height: inherit !important;
				padding: ${spacings(props).tight}px;
				${RaceItem.StyledRaceItem__Label} {
					padding-top: 0;
					line-height: unset;
				}
			`};
	`,

	media(
		css`
			min-width: 60px;
		`,
		767,
	),
);

const StyledRaceNav__TabsItem = styled(TabsItem)(
	(props) => css`
		label: RaceNav__TabsItem;
		flex: 1 1 auto;
		min-width: 100px;
		padding: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: ${MIN_HEIGHT}px;
		border-radius: 8px;
	`,
	media(
		css`
			min-width: 60px;
		`,
		767,
	),
);

const StyledRaceNav__RaceTypeSelector = styled(Select)(
	(props) => css`
		label: RaceNav__RaceTypeSelector;

		padding-left: ${spacings(props).compact}px;

		${Select.StyledSelect__Arrow} {
			top: 13px;
		}
	`,
);

const StyledRaceNav__MeetingSelector = styled(Select)(
	(props) => css`
		label: RaceNav__MeetingSelector;

		padding-left: ${spacings(props).compact}px;
		border-left: none;

		${Select.StyledSelect__Arrow} {
			top: 13px;
		}
	`,
	(props) =>
		media(
			css`
				border-left: 1px solid ${ui(props).color_3};
			`,
			767,
			{ sizeKey: 'mediaSize' },
		),
);

const StyledRaceNav = styled('div')(
	(props) => css`
		label: RaceNav;
		border-left: 1px solid #666666;
		border-right: 1px solid #666666;
		${Carousel.StyledCarouselSlideSpan} {
			label: RaceNav__CarouselItem;

			flex: auto;
			min-height: ${MIN_HEIGHT}px;
		}
	`,
);

const RaceNav = (props) => {
	const {
		className,
		carouselActivationLength,
		carouselConfig,
		displayCarousel,
		raceItems,
		meetingItems,
		raceTypeItems,
		selectedRaceId,
		onClickItem,
		onFilterMeeting,
		onFilterRaceType,
		size,
	} = props;
	const isMobileWidth = size.width < MOBILE_MAX_WIDTH + 120;

	const componentClasses = cx({
		[className]: className,
	});

	const disableNavigtionArrows = raceItems.length > 8 ? true : false;

	// If we want to activate the carousel and the races list reaches a certain length,
	// then change tha Tabs component to a carousel component
	let Component = StyledRaceNav__Carousel;
	// height Needed due to overflow: hidden hiding the tab active border
	let componentProps = {
		nextButton: true,
		prevButton: true,
		freeMode: false,
		roundLengths: true,
		cssWidthAndHeight: true,
		visibilityFullFit: true,
		buttonStyle: '1',
		settings: carouselConfig,
		// isActiveNavigation: true
		isActiveNavigation: isMobileWidth ? true : disableNavigtionArrows,
	};

	const getTickerInterval = (status, startTime = null) => {
		if (StatusPattern[status] != RACE_SELLING_STATUS) {
			return null;
		}
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}
		// null makes Ticker clear interval.
		return null;
	};
	return (
		<StyledRaceNav className={componentClasses}>
			<Component {...componentProps} isMobileWidth={isMobileWidth}>
				{raceItems.map((item) => (
					<StyledRaceNav__TabsItem
						id={item.id}
						key={item.id}
						active={item.id === selectedRaceId}
						action={onClickItem}
						data={item.id}
						// showCarousel={showCarousel}
					>
						<Ticker every={getTickerInterval(item.status, item.start_date)}>
							<StyledRaceNav__RaceItem
								id={item.id}
								number={+item.number}
								result_string={item.result_string}
								eventStart={item.start_date}
								race_site_link={item.race_site_link}
								fixed_odds_enabled={item.fixed_odds_enabled}
								global_tote_available={item.global_tote_available}
								meetingId={item.meeting_id}
								meetingName={item.meeting_name}
								type={item.type}
								showRaceLabel={true}
								hasBets={item.hasBets}
								status={item.status}
								status_display={item.race_status_display}
								distance={item.distance}
								name={item.name}
								active={item.id === selectedRaceId}
								// showCarousel={showCarousel}
								displayCarousel={displayCarousel}
								isNav={true}
								size={size}
							/>
						</Ticker>
					</StyledRaceNav__TabsItem>
				))}
			</Component>
		</StyledRaceNav>
	);
};

RaceNav.propTypes = {
	/** The list of different races */
	raceItems: PropTypes.arrayOf(
		PropTypes.shape({
			/** The race ID */
			id: PropTypes.number.isRequired,

			/** The race number */
			number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

			/** The result of the race */
			result_string: PropTypes.string,

			/** The start date of the race */
			start_date: PropTypes.string.isRequired,

			/** The HREF link for the race */
			race_site_link: PropTypes.string.isRequired,

			/** If there are fixed odds enabled for the race */
			fixed_odds_enabled: PropTypes.bool,

			/** If GT is enabled fore the race */
			global_tote_available: PropTypes.bool,

			/** If the current user has placed any bets on the race */
			hasBets: PropTypes.bool,

			/** The status of the race */
			status: PropTypes.string,
		}),
	).isRequired,

	/** Handle a race item being clicked on */
	onClickItem: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The ID of the currently selected race */
	selectedRaceId: PropTypes.number,

	/** How many race items need to exist before the carousel is activated */
	carouselActivationLength: PropTypes.number,

	/** Settings for the carousel item */
	carouselConfig: PropTypes.object,

	/** Whether or not to display the carousel when it reaches it's activation length */
	displayCarousel: PropTypes.bool,

	/** Optional list of meetings to display for filtering */
	meetingItems: PropTypes.array,

	/** Optional list of race types to display for filtering */
	raceTypeItems: PropTypes.array,

	/** Callback when meeting filter is applied */
	onFilterMeeting: PropTypes.func,

	/** Callback when race type filter is applied */
	onFilterRaceType: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,
};

RaceNav.defaultProps = {
	className: null,
	carouselActivationLength: 9,
	carouselConfig: null,
	displayCarousel: false,
	meetingItems: [],
	raceTypeItems: [],
	onFilterMeeting: () => {},
	onFilterRaceType: () => {},
	selectedRaceId: null,
};

export default RaceNav;
