/**
 * Created by Josef Frank on 2017/05/03.
 */
import env from '../common/EnvironmentVariables';
import moment from 'moment';

/**
 * Builds a structured sitemap item given data.
 *
 * @param url
 * @param lastMod
 * @param changeFreq
 * @param priority
 */
function buildSitemapItem(url, lastMod, changeFreq, priority) {
	let websiteUrl = env('websiteUrl', 'https://www.topbetta.com.au');
	return `<url><loc>${websiteUrl + url}</loc><lastmod>${lastMod || moment().format()}</lastmod><changefreq>${changeFreq || 'hourly'}</changefreq><priority>${priority || '0.1'}</priority></url>`;
}

module.exports = {
	buildSitemapItem,
};