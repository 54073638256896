import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
//import Format from '../../../../legacy/core/format';
import {
	spacings,
	media,
	// Icon,
	brand,
} from '@tbh/ui-kit';

import {

	RACE_SELLING_STATUS,

} from '../../../../common/constants/Racing';


import {
	FlexText,
	Text,
	HeaderBar,
	PlotElements,
	StatCell,
	//	Select,
	//	SelectOption
} from '@tbh/ui-kit';
import RacesDropDown from './RacesDropDown';
import { MOBILE_MAX_WIDTH } from '../../../../common/constants/Breakpoints';

const StyledRaceHeaderBar = styled(HeaderBar)`
	${(props) => css`
		border-radius: 4px 4px 0px 0px;
		overflow: visible;
		min-height: 45px; // To Match the height of a regular header bar
		background-color: ${brand(props).race_grey_color ? brand(props).race_grey_color : '#1C1F21'};
	`};
`;

const StyledRaceHeaderBar__Heading = styled(FlexText)`
	${(props) => css`
		label: RaceHeaderBar__Heading;
		jusitfy-content: center;
		padding-top: ${props.isMobileWidth ? spacings(props).cozy : spacings(props).compact}px;
		padding-bottom: ${props.isMobileWidth ? spacings(props).cozy : spacings(props).compact}px;
		flex-wrap: wrap;
	`};
	${media(
	css`
			jusitfy-content: space-around;
		`,
	400,
)};
`;

const StyledRaceHeaderBar__WeatherAndStatus = styled(PlotElements)`
	flex: 0 0 0!important;
	flex-grow: 0!important;
	width: 100%;
	${props => props.isDesktop ? css`@media (max-width: 640px) {display:none!important;}` : css`@media (min-width: 640px) {display:none!important;}`};

	${media(
	css`
			flex: 0 0 145px;
		`,
	375,
)};
`;

const StyledRaceHeaderBar__WeatherAndTrack = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__WeatherAndTrack;
		max-width: 180px;
	`};

	${media(
	css`
			display: block;
		`,
	375,
)};
`;

const StyledRaceHeaderBar__WeatherAndTrack_Item = styled(Text)`
	${(props) => css`
		label: RaceHeaderBar__WeatherAndTrack_Item;

		white-space: nowrap;
		padding-top: ${spacings(props).tight}px;
	`};
`;

const StyledRaceHeaderBar__WeatherAndTrack_ItemLabel = styled(Text)`
	${(props) => css`
		label: RaceHeaderBar__WeatherAndTrack_ItemLabel;

		display: inline-block;
		// width: 15px;
		min-width: 15px;
		text-align: right;
		margin-right: ${spacings(props).tight}px;
	`};
`;

const StyledRaceHeaderBar__Status = styled(StatCell)`
	${(props) => css`
		label: RaceHeaderBar__Status;
		flex-direction: row;
		max-width: 120px;
		margin-left: ${spacings(props).compact}px;

		color: #ffff;
		${props.status === 'paying' &&
		css`
				background-color: #32be60;
			`};
		${props.status === 'interim' &&
		css`
				background-color: #32be60;
			`};
		${props.status === 'closed' &&
		css`
				background-color: ${brand(props).rch_4};
				/* background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); */
				/* background:#f48c06; */
			`};

		${props.status === 'paid' &&
		css`
				background-color: #5d97fb;
			`};
		${props.status === 'selling' &&
		css`
				background-color: #ffff;
				color: black;
			`};
		@media (max-width: 440px) {
			flex-direction: column;
		}

		@media (max-width: 390px) {
			flex-direction: row;
		}
	`};
`;

const StyledRaceHeaderBar__RaceName = styled(Text)`
	display: none;
	font-size: 100%;
	${media(
	css`
			display: block;
		`,
	500,
	{ sizeKey: 'mediaSize' },
)};
`;

const StyledRaceHeaderBar__RaceLocationWrapper = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__RaceLocationWrapper;
		//display: inline-block;
		padding-right: 10px;
		width: 100%;

	`};
`;

const StyledRaceHeaderBar__StateAndDistance = styled(Text)`
	display: block;
	margin-left: 4px;
	line-height: 1.3;
	white-space: nowrap;
	${media(
	(props) => css`
			display: inline-block;
		`,
	500,
	{ sizeKey: 'mediaSize' },
)};
`;

const StyledRaceHeaderBar__RaceNumber = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__RaceNumbe;

		background-color: ${brand(props).rch_4};
		display: inline-block;

		padding: ${spacings(props).compact}px;
		font-size: 30px;
		font-weight: 600;

		text-align: center;
	`};
`;

const RaceHeaderBar = (props) => {
	const {
		t,
		className,
		size,
		meetingType,
		meetingId,
		meetingName,
		meetingWeather,
		meetingTrack,
		meetingState,
		meetingCountry,
		raceName,
		raceClass,
		raceDistance,
		raceStartDate,
		raceStatus,
		rail_position,
		raceNumber,
		CombineMetingGroups,
		HandleMeetingSelect,
		racePrize
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	/**
	 * Builds the country or state and distance display string
	 *
	 * @returns {string}
	 */
	const buildStateAndDistanceLabel = () => {
		let stateAndDistanceLabel = '';

		// Show the meeting state, if no state show country, if neither then don't show anything
		if (meetingState || meetingCountry) {
			stateAndDistanceLabel += `${meetingState || meetingCountry}`;
		}

		if (raceDistance) {
			stateAndDistanceLabel += ` | ${raceDistance}m`;
		}

		return stateAndDistanceLabel;
	};

	const handleDropDownClick = (fieldName, selectedValue) => {
		HandleMeetingSelect(selectedValue);
	};

	// const meetingName = meeting ? meeting.name ? '';

	const meetingWeathers = {
		'MOSTLY SUNNY': {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png',
			alt: 'Sun',
		},
		CLOUDS: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/cloud.png',
			alt: 'Cloud',
		},
		CLOUDY: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/cloud.png',
			alt: 'Cloud',
		},
		SNOW: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/snow.png',
			alt: 'Snow',
		},
		RAIN: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/rain.png',
			alt: 'Rain',
		},
		CLEAR: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png',
			alt: 'Cloud',
		},
		FINE: {
			src: 'https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png',
			alt: 'Cloud',
		},
	};

	const isMobileWidth = size.width < MOBILE_MAX_WIDTH + 120;

	return (
		<StyledRaceHeaderBar
			fluid
			noPadding
			className={componentClasses}
			type="primary"
			nodeLeft={
				<div>
					<StyledRaceHeaderBar__RaceNumber>{raceNumber}</StyledRaceHeaderBar__RaceNumber>
				</div>
			}
			margin={false}
		>
			<StyledRaceHeaderBar__Heading isMobileWidth={isMobileWidth}>
				<PlotElements
					//vertical
					align="space-between"
					alignElements="middle"
					className={css`
						padding: 4px;
						max-width: 100%;
						@media (max-width: 900px) {
							flex-direction: column;
							align-items: baseline;
							max-width: 100%;
							padding: 4px;
						}
					`}
				>
					<StyledRaceHeaderBar__RaceLocationWrapper>
						<RacesDropDown meetingType={meetingType} meetingId={meetingId} meetingName={meetingName} />
						<div className={css`display:flex;align-items:center;justify-content:space-between;gap:6px;color:white;font-size:12px;`}>
							<div className={css`display:flex;flex-direction:column;`}>
								<Text
									strong
									className={css`
								display: inline-block;
								font-size: 16px;
								font-weight: 100!important;
								display: inline;
							`}
									type="alternate"
								>
									{`${raceName}`}
								</Text>
								<div className={css`display:flex;flex-wrap:wrap;gap:6px;color:white;font-size:12px;`}>
									{buildStateAndDistanceLabel() && (
										<Text mediaSize={size} tag="span" size="-2" type="alternate">
											{buildStateAndDistanceLabel()} |
										</Text>)}
									<Text mediaSize={size} tag="span" size="-2" type="alternate">
										{raceClass}
									</Text>

									<Text mediaSize={size} tag="span" size="-2" type="alternate">
										{racePrize !== 0 && racePrize !== null &&
											'| ' + racePrize
											/* Number(racePrize / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) */
										}
									</Text>
								</div>
								<div className={css`display:flex;flex-wrap:wrap;gap:4px;justify-content:space-between;align-items:flex-start;`}>
									<div className={css`display:flex;flex-wrap:wrap;gap:6px;color:white;font-size:12px;`}>
										<Text
											className={css`
								color: white;
								font-size: 12px;
							`}
										>
											Race Time : {moment(raceStartDate).format('h:mm A')} |
											{/* {moment.utc(raceStartDate).format(HOUR_MIN_12H)} */}
										</Text>
										<StyledRaceHeaderBar__StateAndDistance mediaSize={size} tag="span" size="-2" type="alternate">
											{t('Race Date')} : {raceStartDate && moment(raceStartDate).format('DD-MM-YYYY').split('T')[0]}
										</StyledRaceHeaderBar__StateAndDistance>

									</div>
									<StyledRaceHeaderBar__WeatherAndStatus size={size} align="end" alignElements="middle">
										<StyledRaceHeaderBar__WeatherAndTrack size={size}>
											<StyledRaceHeaderBar__WeatherAndTrack_Item size="-3" ellipsis type="alternate">
												<StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-3" tag="span" type="alternate">
													{meetingWeather && t('AcronymWeather')}
													{meetingWeather && ' : '}
												</StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
												{meetingWeather}
												<span
													className={css`
									padding-left: 3px;
								`}
												/>
												{meetingWeather !== null && meetingWeathers[meetingWeather] && (
													<img
														src={meetingWeathers[meetingWeather].src}
														alt={meetingWeathers[meetingWeather].alt}
														className={css`
										width: 15px;
										height: 15px;
										margin-left: 3px;
									`}
													/>
												)}
											</StyledRaceHeaderBar__WeatherAndTrack_Item>
											<StyledRaceHeaderBar__WeatherAndTrack_Item size="-3" ellipsis type="alternate">
												<StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-3" tag="span" type="alternate">
													{meetingTrack && t('Track')} {meetingTrack && ' : '}
													{meetingTrack}
												</StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
											</StyledRaceHeaderBar__WeatherAndTrack_Item>
											{rail_position !== null ? (
												<StyledRaceHeaderBar__WeatherAndTrack_Item size="-4" ellipsis type="alternate">
													<StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-4" tag="span" type="alternate">
														{t('R/P')}
													</StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
													{rail_position}
												</StyledRaceHeaderBar__WeatherAndTrack_Item>
											) : null}
										</StyledRaceHeaderBar__WeatherAndTrack>

										<StyledRaceHeaderBar__Status
											transparent
											spacing={StatCell.spacings.SPACING_COMPACT}
											statusColor={''}
											status={raceStatus}
											value={
												raceStatus ? (
													<Text
														size="-4"
														type={raceStatus === RACE_SELLING_STATUS ? 'dark' : 'alternate'}
														transform="uppercase"
														ellipsis
													>
														{t(`Racing__Status--${raceStatus}`)}
													</Text>
												) : null
											}
										/>

									</StyledRaceHeaderBar__WeatherAndStatus>
								</div>
							</div>

							<StyledRaceHeaderBar__WeatherAndStatus size={size} isDesktop align="end" alignElements="middle">
								<StyledRaceHeaderBar__WeatherAndTrack size={size}>
									<StyledRaceHeaderBar__WeatherAndTrack_Item size="-3" ellipsis type="alternate">
										<StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-3" tag="span" type="alternate">
											{meetingWeather && t('AcronymWeather')}
											{meetingWeather && ' : '}
										</StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
										{meetingWeather}
										<span
											className={css`
									padding-left: 3px;
								`}
										/>
										{meetingWeather !== null && meetingWeathers[meetingWeather] && (
											<img
												src={meetingWeathers[meetingWeather].src}
												alt={meetingWeathers[meetingWeather].alt}
												className={css`
										width: 15px;
										height: 15px;
										margin-left: 3px;
									`}
											/>
										)}
									</StyledRaceHeaderBar__WeatherAndTrack_Item>
									<StyledRaceHeaderBar__WeatherAndTrack_Item size="-3" ellipsis type="alternate">
										<StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-3" tag="span" type="alternate">
											{meetingTrack && t('Track')} {meetingTrack && ' : '}
											{meetingTrack}
										</StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
									</StyledRaceHeaderBar__WeatherAndTrack_Item>
									{rail_position !== null ? (
										<StyledRaceHeaderBar__WeatherAndTrack_Item size="-4" ellipsis type="alternate">
											<StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-4" tag="span" type="alternate">
												{t('R/P')}
											</StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
											{rail_position}
										</StyledRaceHeaderBar__WeatherAndTrack_Item>
									) : null}
								</StyledRaceHeaderBar__WeatherAndTrack>

								<StyledRaceHeaderBar__Status
									transparent
									spacing={StatCell.spacings.SPACING_COMPACT}
									statusColor={''}
									status={raceStatus}
									value={
										raceStatus ? (
											<Text
												size="-4"
												type={raceStatus === RACE_SELLING_STATUS ? 'dark' : 'alternate'}
												transform="uppercase"
												ellipsis
											>
												{t(`Racing__Status--${raceStatus}`)}
											</Text>
										) : null
									}
								/>

							</StyledRaceHeaderBar__WeatherAndStatus>
						</div>

					</StyledRaceHeaderBar__RaceLocationWrapper>

				</PlotElements>
			</StyledRaceHeaderBar__Heading>
		</StyledRaceHeaderBar>
	);
};

RaceHeaderBar.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,

	// Race and Meeting details
	/** The type of meeting ie. gallops, harness or greyhounds */
	meetingType: PropTypes.string.isRequired,

	/** The name of the meeting */
	meetingName: PropTypes.string.isRequired,

	/** The name of the race */
	raceName: PropTypes.string.isRequired,

	/** The status of the race */
	raceStatus: PropTypes.string.isRequired,

	/** The start time of the race */
	raceStartDate: PropTypes.string.isRequired,

	/** The weather details for the meeting */
	meetingWeather: PropTypes.string,

	/** The track conditions for the meeting */
	meetingTrack: PropTypes.string,

	/** The state the meeting is being held in */
	meetingState: PropTypes.string,

	/** The country the meeting is being held in */
	meetingCountry: PropTypes.string,

	/** The distance of the race */
	raceDistance: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	rail_position: PropTypes.string,
	raceNumber: PropTypes.number,
};

RaceHeaderBar.defaultProps = {
	meetingWeather: null,
	meetingTrack: null,
	meetingState: '',
	meetingCountry: '',
	raceDistance: null,
	className: '',
	raceNumber: null,
	rail_position: null,
};


export default withNamespaces()(RaceHeaderBar);
