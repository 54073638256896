import styled, { css } from 'react-emotion';
import { spacings, media, ButtonGroup, application, Button, typography } from '@tbh/ui-kit';

import { Breadcrumbs } from '@tbh/ui-kit';
import SportsListContainer from '../../../containers/Sports/SportsListContainer';
import TrendingBetsContainer from '../../../containers/Betting/TrendingBetsContainer';
import CompetitionSelectorContainer from '../../../containers/Sports/CompetitionSelectorContainer';
import NextToJumpSportsContainer from '../../../containers/Sports/NextToJumpSportsContainer/NextToJumpSportsContainer';
import RaceBettingAdd from '../../../components/features/Racing/RaceBettingAdd/RaceBettingAdd';
import { RACING_BET_TYPE_MARGIN } from '../../../common/constants/Racing';

export const StyledSportsHomePage = styled('div')(
	(props) =>
		css`
			label: SportsHomePage;

			margin: ${spacings(props).compact}px;
		`,
	media(
		css`
			display: flex;
			flex-wrap: wrap;
		`,
		768,
	),
);

export const SportCardView = styled('div')(
	(props) =>
		css`
			label: SportCardView;
			margin: ${spacings(props).compact}px;
			box-sizing: border-box;
			border-radius: 4px;
			margin-top: 0px;
			margin-bottom: 10px;
			overflow: hidden;
			background-color: rgb(255, 255, 255);
		`,
);

export const HeadingSelector__Wrappe = styled('div')(
	(props) =>
		css`
			label: HeadingSelector__Wrappe;
			padding-top: 10px;
			padding-bottom: 10px;
		`,
);

export const HeadingSelector__Wrappe_Text = styled('div')(
	(props) =>
		css`
    label: HeadingSelector__Wrappe_Text;
    position: relative;
    margin: 0px;
   padding-bottom:10px;
    font-size: 14px;
    display: inline-block;
    z-index: 20;


}
    `,
);
export const SportView_Header_Bar = styled('div')(
	(props) =>
		css`
			label: SportView_Header_Bar;
			position: relative;
			display: flex;
			height: 45px;
			width: 100%;
			-webkit-box-align: center;
			align-items: center;
			overflow: hidden;
			text-transform: none;
			background-color: rgb(7, 11, 25);
			padding: 0px 14px;
			font-weight: 700;
		`,
);

export const Sport_HeaderBar__Label = styled('div')(
	(props) =>
		css`
			label: Sport_HeaderBar__Label;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			padding-right: 0px;
			width: 100%;
			color: #fff;
		`,
);

export const SportView_Header_Bar_inner_view = styled('div')(
	css`
		label: SportView_Header_Bar_inner_view;
		position: relative;
		display: flex;
		height: 45px;
		width: 100%;
		-webkit-box-align: center;
		align-items: center;
		text-transform: none;
		background: rgb(199, 33, 40);
		padding: 0px 14px;
		font-weight: 700;
	`,
);

export const Sport_Team_Header = styled('div')(
	(props) =>
		css`
			label: Sport_Team_Header;
			flex: 1 1 auto;
			margin-left: 6px;
			text-overflow: ellipsis;
			overflow: hidden;
			max-height: 45px;
		`,
);

export const Sport_Team_Header_Sec = styled('div')(
	(props) =>
		css`
			label: Sport_Team_Header_Sec;
			flex: 1 1 auto;
			margin-right: 6px;
			text-align: right;
			text-overflow: ellipsis;
			overflow: hidden;
			max-height: 45px;
		`,
);

export const Sport_PlotElements = styled('div')(
	(props) =>
		css`
			label: Sport_PlotElements;
			display: flex;
			-webkit-box-pack: start;
			justify-content: flex-start;
			-webkit-box-align: center;
			align-items: center;
			flex: 1 1 50%;
		`,
);

export const Sport_PlotElements_Sec = styled('div')(
	(props) =>
		css`
			label: Sport_PlotElements_Sec;
			display: flex;
			-webkit-box-pack: end;
			justify-content: flex-end;
			-webkit-box-align: center;
			align-items: center;
			flex: 1 1 50%;
		`,
);
export const Sport_StartDateAndIcon_TeamsEventHeader = styled('div')(
	(props) =>
		css`
			label: Sport_StartDateAndIcon_TeamsEventHeader;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			flex: 1 1 auto;
			display: flex;
			margin-right: 6px;
		`,
);

export const Sport_TeamsEventHeaderStartDate_TeamsEventHeaderStartDate = styled('div')(
	(props) =>
		css`
    label: Sport_TeamsEventHeaderStartDate_TeamsEventHeaderStartDate;
    margin: 0px 6px;
    white-space: nowrap;
    color#fff;
    `,
);

export const Sport_TeamsEventItem__MarketRow_TeamsEventItem_TeamsEventItem = styled('div')(
	(props) =>
		css`
			label: Sport_TeamsEventItem__MarketRow_TeamsEventItem_TeamsEventItem;
			-webkit-box-align: center;
			align-items: center;
			padding: 0px;
			display: flex;
			margin-bottom: 4px;
			margin-top: 10px;
			margin-right: 100px;
		`,
);

export const Sport_MarketRow__SelectionContainer = styled('div')(
	(props) =>
		css`
			label: Sport_MarketRow__SelectionContainer;
			flex: 1 1 auto;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			justify-content: space-around;
		`,
);

export const Sport_MarketRow__Selection_MarketRow = styled('div')(
	(props) =>
		css`
			label: Sport_MarketRow__Selection_MarketRow;
			display: flex;
			flex-direction: column;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			flex: 2 1 100%;
			align-self: flex-end;
		`,
);

export const Sport_MarketRow__BettingAddContainer = styled('div')(
	(props) =>
		css`
			label: Sport_MarketRow__BettingAddContainer;
			width: 100%;
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
		`,
);

export const StyledSportBettingAdd = styled(RaceBettingAdd)(
	(props) => css`
		flex: 0 0 ${application(props).racing.odds_button_width}px;
		width: 70px;
		margin: 0 5px;
		text-align: center;
		position: relative;
		height: 36px;
		border-style: solid;
		// -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
		// -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
		// box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);

		${props.scratched &&
			css`
				display: none;
			`};

		${props.betType === RACING_BET_TYPE_MARGIN &&
			css`
				margin: 0;
			`};

		${props.small &&
			css`
				flex: 0 0 ${application(props).racing.odds_button_width_small}px;
				max-width: ${application(props).racing.odds_button_width_small}px;
			`};
	`,
	media(
		(props) => css`
			${props.small &&
				css`
					flex: 0 0 ${application(props).racing.odds_button_width}px;
					max-width: ${application(props).racing.odds_button_width}px;
				`};
		`,
		660,
	),
);

export const SportView_Header_Bar_inner_view_Aside_HeaderBar = styled('div')(
	(props) =>
		css`
			label: SportView_Header_Bar_inner_view_Aside_HeaderBar;
			display: block;
			font-size: 11.0617px;
			font-weight: normal;
			text-transform: none;
			right: 6px;
			color: #fff;
		`,
);

export const SportCardView_CardWrapper = styled('div')(
	(props) =>
		css`
			label: SportCardView_CardWrapper;
			box-sizing: border-box;
			padding: 8px;
			background-color: #f5f5f5;
			margin-bottom: 20px;
			margin-top: 20px;
		`,
);
export const SportCardView_Header_Bar_Label = styled('div')(
	(props) =>
		css`
    label: SportCardView_Header_Bar_Label;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-right: 0px;
    color:#fff;
}
    `,
);
export const StyledSportsHomePage__Breadcrumbs = styled(Breadcrumbs)(
	(props) =>
		css`
			label: SportsHomePage__Breadcrumbs;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: ${spacings(props).compact}px;
		`,
);

export const StyledSportsHomePage__SportsList = styled(SportsListContainer)(
	css`
		label: SportsHomePage__SportsList;
	`,
	(props) =>
		media(
			css`
				flex: 1 1 auto;
				margin-right: ${spacings(props).compact}px;
			`,
			768,
		),

	media(
		css`
			display: none;
			margin-right: 0;
		`,
		900,
	),
);

export const StyledSportsHomePage__Body = styled('div')(
	(props) => css`
		label: SportsHomePage__Body;

		${props.isSportsHome &&
			!props.competitionOnly &&
			css`
				display: none;
			`};
	`,
	(props) =>
		media(
			css`
				flex: 3;
				margin-left: ${spacings(props).compact}px;
				margin-right: ${spacings(props).compact}px;
				overflow: hidden; // This is required for the Swiper to stay within its container
				padding-top: 3px; /// This is to allow the team icons overflow to show on the headers

				${props.isSportsHome &&
					css`
						display: block;
					`};
			`,
			768,
		),

	(props) =>
		media(
			css`
				flex: 3;
				margin-right: ${spacings(props).compact}px;
				overflow: hidden; // This is required for the Swiper to stay within its container

				${props.isSportsHome &&
					css`
						display: block;
					`};
			`,
			900,
		),
);

export const StyledSportsUpcoming__Body = styled('div')(
	(props) => css`
		label: SportsUpcoming_Body;
	`,
	(props) =>
		media(
			css`
				flex: 3;
				margin-left: ${spacings(props).compact}px;
				margin-right: ${spacings(props).compact}px;
				overflow: hidden; // This is required for the Swiper to stay within its container
				padding-top: 3px; /// This is to allow the team icons overflow to show on the headers

				${props.isSportsHome &&
					css`
						display: block;
					`};
			`,
			768,
		),
);

export const StyledSports__major_header = styled('div')`
	${(props) =>
		css`
			label: Sports__major_header;

			border-bottom: 3px solid #c72128;
			clear: both;
			display: flex;
			justify-content: space-between;
		`};
`;

export const StyledSports__major_header_h2 = styled('h2')`
	${(props) =>
		css`
			label: Sports__major_header_h2;

			text-transform: uppercase;
			font-weight: bold;
			background: #c72128;
			border-color: #c72128;
			color: white;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
			padding: 7px;
			height: auto;
			position: relative;
			font-size: 14.222222222222221px;
			letter-spacing: 0.32px;
			text-align: center;
		`};
`;

export const StyledMenuButtonGroup = styled(ButtonGroup)(
	(props) => css`
		label: MenuButtonGroup;

		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: ${spacings(props).compact}px;
		margin-left: 5px;
	`,
	media(
		(props) => css`
			justify-content: flex-start;

			${StyledTopMenuButton} {
				min-width: ${application(props).racing.button_width_expanded}px;
			}
		`,
		500,
	),
);

export const StyledTopMenuButton = styled(Button)`
	${(props) =>
		css`
			label: TopMenuButton;
			text-transform: uppercase;
			min-width: ${application(props).racing.button_width}px;
			padding: ${spacings(props).compact}px;
			max-height: 36px;
			margin-top: ${spacings(props).compact}px;

			&:hover {
				color: ${typography(props).color_1_alternate};
			}
		`};

	${media(
		(props) => css`
			max-height: 52px;
			min-width: ${application(props).racing.button_width_expanded}px;
		`,
		500,
		{ sizeKey: 'mediaSize' },
	)};
`;

export const StyledSportsHomePage__RightBar = styled('div')(
	css`
		label: SportsHomePage__RightBar;

		display: none;
	`,
	(props) =>
		media(
			css`
				display: block;
				flex: 1 1 auto;
				max-width: 280px;
				margin-left: ${spacings(props).compact}px;
			`,
			900,
		),
);

export const StyledSportsHomePage__TrendingBets = styled(TrendingBetsContainer)(
	css`
		label: SportsHomePage__TrendingBets;
	`,
	media(
		css`
			margin-bottom: 30px;
			display: block;
		`,
		900,
	),
);

export const StyledSportsHomePage__SEOContent = styled('div')(
	(props) => css`
		label: SportsHomePage__SEOContent;

		padding: ${spacings(props).cozy}px 0;
	`,
);

export const StyledSportsHomePage__NTJSports_Container = styled(NextToJumpSportsContainer)(
	(props) => css`
		label: SportsHomePage__Containers;

		width: 100%;
		margin-bottom: ${spacings(props).compact}px;
	`,
);
export const StyledSportsHomePage__CompetitionSelectorContainer = StyledSportsHomePage__NTJSports_Container.withComponent(
	CompetitionSelectorContainer,
);

export const StyledSportsUpcomingPage__VisibleSportContainer = styled('div')(
	(props) => css`
		label: UpcomingPage__VisibleSportContainer;
		margin: 0 0 12px 0;
		padding: 0;
		box-sizing: border-box;
		display: table;
		width: 100%;
		display: inline-flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	`,
);
export const StyledSportsUpcomingPage__VisibleSport_UL = styled('ul')(
	(props) => css`
		label: SportsUpcomingPage__VisibleSport_UL;
		list-style: disc outside;
	`,
);
export const StyledSportsUpcomingPage__VisibleSport_LI = styled('li')(
	(props) => css`
		label: SportsUpcomingPage__VisibleSport_LI;
		flex-basis: 15.66666667%;
		width: 100px;
		display: inline-block;
		margin: 0.5px;
		padding: 15px;
		border-style: solid;
		border-width: thin;
		cursor: pointer;
		text-align: center;
	`,
);
export const StyledSportsUpcomingPage__MarketGroups = styled('div')(
	(props) => css`
		label: SportsUpcomingPage__MarketGroups;
		padding: 12px;
		border: none;
		background-color: #fefefe;
		box-shadow: #cccccc 0 3px 9px;
		margin-bottom: 18px;
		border-radius: 4px;
		width: 80%;
	`,
);
export const Styles__HeaderContainer = styled('div')(
	(props) => css`
		label: Styles__HeaderContainer;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 8px;
	`,
);
export const SportsStyles__TitleContainer = styled('div')(
	(props) => css`
		label: SportsStyles__TitleContainer;
		display: flex;
		max-width: 75%;
		align-self: inherit;
	`,
);
export const SportsStyles__IconContainer = styled('div')(
	(props) => css`
		label: SportsStyles__IconContainer;
		height: 14px;
		width: 14px;
		margin-right: 14px;
		padding-top: 6px;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
	`,
);

export const SportsStyles__BetContainer = styled('div')(
	(props) => css`
		label: SportsStyles__BetContainer;
		display: flex;
		gap: 4px;
		width: 100%;
		flex-direction: column;
	`,
);

export const SportsStyles__SelectionsContainer = styled('div')(
	(props) => css`
		label: SportsStyles__SelectionsContainer;
		display: flex;
		flex-direction: column;
	`,
);

export const SportsStyles__MarketNameRow = styled('div')(
	(props) => css`
		label: SportsStyles__MarketNameRow;
		width: 100%;
		display: flex;
		margin-bottom: 4px;
	`,
);

export const SportsStyles__BetRow = styled('div')(
	(props) => css`
		label: SportsStyles__BetRow;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		margin-bottom: 10px;
		align-self: stretch;
	`,
);

export const SportsStyles__EmptySelectionName = styled('div')(
	(props) => css`
		label: SportsStyles__EmptySelectionName;
		width: 100%;
		display: flex;
		margin-bottom: 4px;
	`,
);
export const SportsStyles__MarketNames = styled('div')(
	(props) => css`
		label: SportsStyles__MarketNames;
		display: flex;
		width: 70%;
		align-items: flex-end;
	`,
);

export const StyledSportsUpcomingPage__EventName = styled('div')(
	(props) => css`
		label: SportsUpcomingPage__EventName;
		display: flex;
		gap: 4px;
		width: 100%;
		flex-direction: column;
	`,
);
