import { Button, LoadingMask, Text } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';
import { Backdrop } from '../../components/UI/Modal';
import {
	QUADDIES_BET_TYPES,
	RACING_BET_TYPE_TITLES,
	RACING_EXOTIC_BET_TYPES,
	SRMTypes,
} from '../../common/constants/Racing';
import { groupBetSelectionByRaceNumberButNotName } from '../../common/GroupBetSelectionByRaceNumber';
import html2canvas from 'html2canvas';
import logo from '../../images/GRSTLogo250.50.png';
import moment from 'moment';
import { FORM_DATE_FORMAT, HOUR_MIN_AMPM } from '../../common/constants/DateFormats';
import { withNamespaces } from 'react-i18next';
import { TOTE_NAMES_BY_PRODUCT } from '../../store/entities/constants/BetConstants';
import { StyledBetSelectionMulti__ClearIcon } from '../../components/features/Betting/MultiSRMList/MultiSRMItem';
import { generateLegName } from '../../common/BetPlacement';
import RacingIcon from '../../components/features/Racing/RacingIcon/RacingIcon';

const BetExportContainer = styled('div')(
	`    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: white; 
        // border-radius: 24px;
        overflow-y: auto;
        padding: 24px;
        max-width: 440px;

		@media (max-width: 768px) {
			padding:0;
			margin-right: 24px;
			margin-left: 24px;  
		}
		
        overflow-y: auto;
		position: relative;
    `,
);

const BetExportHeader = styled('div')(
	`
     display: flex;
     flex-direction: column;
     align-items: center;
     width: 100%;
     background-color: rgb(0, 110, 176);
     color: #fff;
     padding: 42px 24px;
    //  border-radius: 24px 24px 0px 0px;
     border-right: 1px solid #ccc;
     border-left: 1px solid #ccc;
     border-top: 1px solid #ccc;
    `,
);

const OvalHeaderBottom = styled('div')`
	width: 92px;
	height: 54px;
	border-radius: 100%;
	background-color: rgb(0, 110, 176);
	position: absolute;
`;

const BetExportStatus = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: rgb(22, 26, 29);
	padding: 16px;
	border-radius: 24px 24px 0px 0px;
`;

const SeparatorContainer = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
`;

const Separator = styled('div')`
	top: -5px;
	border-bottom: 8px dotted rgb(0, 110, 176);
	width: calc(100% - 48px);
	position: absolute;
`;

const Oval = styled('div')`
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: rgb(0, 110, 176);
	position: absolute;
`;

const BetExportBets = styled('div')`
	display: flex;
	flex-direction: column;
	background-color: white;
	width: 100%;
	color: black;
	padding: 24px 0px;
	gap: 8px;
	border-radius: 0px 0px 24px 24px;
`;

const BetItem = styled('div')`
	width: 100%;
	display: grid;
	padding: 4px 12px;
	grid-template-columns: 80% 20%;
	justify-content: space-between;
`;

const BetItemRight = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 8px;
`;

const BetItemLeft = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
`;

const BetInfos = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: 8px 12px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	margin-bottom: 12px;
`;

const BetExportFooter = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	width: 100%;
	background-color: white;
	padding: 24px;
	text-align: center;
	// border-radius: 0px 0px 24px 24px;
	border-right: 1px solid #ccc;
	border-left: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
`;

const BetsWinPlace = ({
	id,
	image,
	stake,
	odds,
	meeting_name,
	race_number,
	race_type,
	selection_number,
	selection_name,
	isTote,
	product,
	type,
}) => {
	return (
		<BetItem>
			<BetItemRight>
				<RacingIcon color={'black'} type={race_type} />
				<div>
					<Text size="-2" strong>
						R{race_number} {meeting_name}
					</Text>
					<Text size="-3" strong>
						#{selection_number} {selection_name}
					</Text>
					<Text size="-3">
						{isTote && TOTE_NAMES_BY_PRODUCT[product] && TOTE_NAMES_BY_PRODUCT[product][type]}{' '}
						{RACING_BET_TYPE_TITLES[type]}
					</Text>
				</div>
			</BetItemRight>
			<BetItemLeft>
				<Text
					size="-2"
					strong
					style={{
						whiteSpace: 'nowrap',
					}}
				>
					@ {odds}
				</Text>
			</BetItemLeft>
		</BetItem>
	);
};

const BetsSRM = ({ odds, meeting_name, race_type, race_number, selections, type, product }) => {
	return (
		<BetItem>
			<BetItemRight>
				<RacingIcon color={'black'} type={race_type} />
				<div>
					<Text
						className={css`
							margin-bottom: 4px;
						`}
						size="-2"
						strong
					>
						R{race_number} {meeting_name}
					</Text>

					{selections.map((selection) => (
						<div
							className={css`
								display: flex;
								flex-direction: row;
								align-items: center;
								gap: 12px;
							`}
							key={selection.selection_name + Math.random()}
						>
							<Text size="-3" strong>
								{SRMTypes[selection.product]}: #{selection.selection_number} {selection.selection_name}
							</Text>
						</div>
					))}
					<Text size="-3">{RACING_BET_TYPE_TITLES[type]}</Text>
				</div>
			</BetItemRight>
			<BetItemLeft>
				<Text size="-2" strong>
					@ {odds}
				</Text>
			</BetItemLeft>
		</BetItem>
	);
};

const BetsExotics = ({ flexiAmount, race_type, race_number, meeting_name, selection_string, type, product }) => {
	return (
		<BetItem>
			<BetItemRight>
				<RacingIcon color={'black'} type={race_type} />
				<div>
					<Text size="-2" strong>
						R{race_number} {meeting_name}
					</Text>
					<div
						className={css`
							margin-top: 4px;
							display: flex;
							flex-direction: column;
							gap: 3px;
						`}
					>
						<Text size="-3" strong>
							{selection_string}
						</Text>	
						<Text size="-3">
							{RACING_BET_TYPE_TITLES[type]} (
								{TOTE_NAMES_BY_PRODUCT[product] && TOTE_NAMES_BY_PRODUCT[product][type]
								? TOTE_NAMES_BY_PRODUCT[product][type]
								: product}
							)
						</Text>
					</div>
				</div>
			</BetItemRight>
			<BetItemLeft>
				<Text size="-2" strong>
					Flexi % {flexiAmount}
				</Text>
			</BetItemLeft>
		</BetItem>
	);
};

const BetsQuaddies = ({ flexiAmount, race_type, meeting_name, selections, type, product }) => {
	const legsFormat = groupBetSelectionByRaceNumberButNotName(selections);
	return (
		<BetItem>
			<BetItemRight>
				<RacingIcon color={'black'} type={race_type} />

				<div>
					<Text size="-2" strong>
						{meeting_name}
					</Text>
					<div
						className={css`
							margin-top: 4px;
							display: flex;
							flex-direction: column;
							gap: 3px;
						`}
					>
						{Object.keys(legsFormat).map((key, i) => (
							<div
								className={css`
									display: flex;
									align-items: center;
									font-size: 11px;
									gap: 6px;
								`}
								key={`${key}Leg${i + 1}`}
							>
								<Text size="-3" strong>
									Leg {i + 1} (R{key}):
								</Text>
								<Text
									size="-3"
									className={css`
										margin-left: 2px;
									`}
								>
									{legsFormat[key].join(', ')}
								</Text>
							</div>
						))}
						<Text size="-3">
							{RACING_BET_TYPE_TITLES[type]} (
							{TOTE_NAMES_BY_PRODUCT[product] && TOTE_NAMES_BY_PRODUCT[product][type]
								? TOTE_NAMES_BY_PRODUCT[product][type]
								: product}
							)
						</Text>
					</div>
				</div>
			</BetItemRight>
			<BetItemLeft>
				<Text size="-2" strong>
					Flexi % {flexiAmount}
				</Text>
			</BetItemLeft>
		</BetItem>
	);
};

const BetsMulti = ({ id, stake, odds, selections, type }) => {
	return (
		<BetItem>
			<BetItemRight
				className={css`
					flex-direction: column;
				`}
			>
				<Text size="-2" strong>
					{generateLegName(selections.length, selections.length)}
				</Text>

				{selections.map((selection) => (
					<div
						className={css`
							display: flex;
							align-items: center;
							gap: 12px;
						`}
						key={selection.selection_name}
					>
						<RacingIcon color={'black'} type={selection.race_type} />
						<div>
							<Text size="-2" strong>
								R{selection.race_number} {selection.meeting_name}
							</Text>
							<Text className={css``} size="-3" strong>
								{' '}
								#{selection.selection_number} {selection.selection_name}
							</Text>

							<Text className={css``} size="-3">
								{RACING_BET_TYPE_TITLES[selection.type]}
							</Text>
						</div>
					</div>
				))}
			</BetItemRight>
			<BetItemLeft>
				<Text size="-2" strong>
					@ {odds}
				</Text>
			</BetItemLeft>
		</BetItem>
	);
};

const CloseButton = styled('div')`
	position: absolute;
	top: 8px;
	right: 4px;
	cursor: pointer;
`;

const BetExport = ({ t, closeModal, bet, taglines }) => {
	const handleEscClose = React.useCallback(
		(event) => {
			if (event.key === 'Escape') {
				closeModal();
			}
		},
		[closeModal],
	);

	React.useEffect(() => {
		// Add event listener when component mounts
		window.addEventListener('keydown', handleEscClose);

		// Cleanup event listener when component unmounts
		return () => {
			window.removeEventListener('keydown', handleEscClose);
		};
	}, [handleEscClose]);

	const buildBet = React.useCallback(
		(bet) => {
			// Sort selections based on startTime
			bet.selections.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
			if (bet.type === 'sameracemulti') {
				return (
					<BetsSRM
						key={bet.id + Math.random() + 'srm'}
						id={bet.id}
						stake={bet.stake}
						odds={bet.odds}
						race_type={bet.selections[0].race_type}
						meeting_name={bet.selections[0].meeting_name}
						race_number={bet.selections[0].race_number}
						selections={bet.selections}
						type={bet.type}
					/>
				);
			} else if (RACING_EXOTIC_BET_TYPES.includes(bet.type)) {
				return (
					<BetsExotics
						key={bet.id + Math.random() + 'exotics'}
						id={bet.id}
						stake={bet.stake}
						flexiAmount={bet.flexiAmount}
						race_type={bet.selections[0].race_type}
						race_number={bet.selections[0].race_number}
						meeting_name={bet.selections[0].meeting_name}
						product={bet.selections[0].product}
						selection_string={bet.selection_string}
						type={bet.type}
					/>
				);
			} else if (QUADDIES_BET_TYPES.includes(bet.type)) {
				return (
					<BetsQuaddies
						key={bet.id + Math.random() + 'exotics'}
						id={bet.id}
						stake={bet.stake}
						flexiAmount={bet.flexiAmount}
						race_type={bet.selections[0].race_type}
						meeting_name={bet.selections[0].meeting_name}
						selections={bet.selections}
						product={bet.selections[0].product}
						type={bet.type}
					/>
				);
			} else if (bet.type === 'multi') {
				return (
					<BetsMulti
						key={bet.id + Math.random() + 'multi'}
						id={bet.id}
						stake={bet.stake}
						odds={bet.odds}
						selections={bet.selections}
						type={bet.type}
					/>
				);
			} else {
				return (
					<BetsWinPlace
						key={bet.id + Math.random() + 'winplace'}
						id={bet.id}
						stake={bet.stake}
						odds={bet.odds}
						meeting_name={bet.selections[0].meeting_name}
						image={bet.selections[0].silk}
						selection_number={bet.selections[0].selection_number}
						selection_name={bet.selections[0].selection_name}
						isTote={bet.isTote}
						product={bet.selections[0].product}
						race_type={bet.selections[0].race_type}
						race_number={bet.selections[0].race_number}
						type={bet.type}
					/>
				);
			}
		},
		[!!bet],
	);

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(null);

	const onButtonClick = async () => {
		setLoading(true);
		const cupom = document.getElementById('cupom-print');
		try {
			const canvas = await html2canvas(cupom, {
				scale: 3,
			});
			var link = document.createElement('a');
			document.body.appendChild(link);
			link.download = bet.id + '_' + bet.type + '_' + new Date(bet.date).getTime() + '.png';
			link.href = canvas.toDataURL();
			link.target = '_blank';
			link.click();
			setError(null);
		} catch (err) {
			//console.log(err);
			setError("It wasn't possible to print the bet. Please try again.");
		}

		setLoading(false);
	};

	const handleClose = () => {
		if (loading) return;
		closeModal();
	};

	return (
		<Backdrop
			onClick={handleClose}
			css={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<BetExportContainer onClick={(e) => e.stopPropagation()}>
				<CloseButton>
					<StyledBetSelectionMulti__ClearIcon size="-3" icon="close" action={handleClose} />
				</CloseButton>
				<LoadingMask loading={loading} />
				<div id="cupom-print">
					<BetExportHeader>
						<img
							style={{
								width: 250,
								marginBottom: 24,
							}}
							src={logo}
						/>
						<BetExportStatus>
							<Text style={{ color: 'white' }} size="0">
								{bet.status.toLocaleUpperCase()}
							</Text>
						</BetExportStatus>
						<SeparatorContainer>
							<Oval style={{ left: -5 }} />
							<Separator />
							<Oval style={{ right: -5 }} />
						</SeparatorContainer>
						<BetExportBets>
							{buildBet(bet)}
							<Text size="-3" css={{ paddingLeft: 12, paddingTop: 12, color: 'gray' }}>
								{moment(bet.date).format(FORM_DATE_FORMAT)} {moment(bet.date).format(HOUR_MIN_AMPM)}
							</Text>
							<Text size="-4" css={{ paddingLeft: 12, color: 'gray' }} tag="span">
								{t('UnitStake__BetReceipt')}:{` #${bet.id}`}
							</Text>

							<BetInfos>
								<Text size="-3">
									<span>Stake</span> <strong>${bet.stake}</strong>
								</Text>
								{bet.return ? (
									<Text size="-3">
										<span>Est. Return </span> <strong>{bet.return}</strong>
									</Text>
								) : null}
							</BetInfos>

							<SeparatorContainer>
								<OvalHeaderBottom css={{ top: 5 }} />
							</SeparatorContainer>
						</BetExportBets>
					</BetExportHeader>
					<BetExportFooter>
						<Text size="-2" align="center" strong>
							BETSTOP IS THE NATIONAL SELF-EXCLUSION REGISTER. YOU CAN CHOOSE TO SELF-EXCLUDE FROM ALL LICENSED
							BOOKMAKERS IN AUSTRALIA
						</Text>
						<Text
							size="-2"
							align="center"
							className={css`
								font-size: 15px;
								font-weight: bold;
							`}
						>
							{taglines[0].tagline}
						</Text>
					</BetExportFooter>
				</div>
				{error && (
					<Text
						size="-2"
						className={css`
							color: red;
							margin-top: 12px;
						`}
					>
						{error}
					</Text>
				)}
				<Button
					type="primary"
					className={css`
						margin-top: 12px;
						border-radius: 8px;
					`}
					action={onButtonClick}
					size="medium"
					disabled={loading}
				>
					{loading ? 'Loading' : 'PRINT'}
				</Button>
			</BetExportContainer>
		</Backdrop>
	);
};

export default withNamespaces()(BetExport);
