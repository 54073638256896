import { createSelector } from 'reselect';

/**
 * State slice for featureToggles
 *
 * @param state
 * @returns {{enableFeatureToggling: boolean, bulkFeatureToggler: number, features: {}}|ContextContainer.defaultProps.featureToggles|{enableFeatureToggling, bulkFeatureToggler, features}}
 */
export const featuredToggleState = (state) => state.featureToggles;

/**
 * Return the features, first processing any bulk enabling or disabling
 *
 * @param featureToggles
 * @returns {{}}
 */
export const getToggledFeatures = (featureToggles) => {
	const newFeatureValue = { ...featureToggles };

	// If the feature toggle, or the bulk feature toggled is set to disabled, then remove all features
	if (!newFeatureValue.enableFeatureToggling || newFeatureValue.bulkFeatureToggler === -1) {
		newFeatureValue.features = {};
	} else if (newFeatureValue.bulkFeatureToggler === 1) {
		// Otherwise if the feature toggle is set to bulk enable, enable each individual item
		for (let key in newFeatureValue.features) {
			if (newFeatureValue.features.hasOwnProperty(key)) {
				newFeatureValue.features[key].enabled = true;
			}
		}
	}

	return newFeatureValue;
};

/**
 * Selector for getting the feature toggles
 */
export const selectToggledFeatures = createSelector(featuredToggleState, getToggledFeatures);

/**
 * Get a named feature and handle all bulk processing
 *
 * @param featureToggles
 * @param key
 * @returns {{}}
 */
export const getFeature = (featureToggles, key) => {
	const newFeature = { ...featureToggles.features[key] };

	// If the feature toggle, or the bulk feature toggled is set to disabled, then remove all features
	if (!featureToggles.enableFeatureToggling || featureToggles.bulkFeatureToggler === -1) {
		newFeature.enabled = false;
	} else if (featureToggles.bulkFeatureToggler === 1) {
		// Otherwise if the feature toggle is set to bulk enable, enable each individual item
		newFeature.enabled = true;
	}

	return newFeature;
};

/**
 * Selector for getting an individual feature
 */
export const selectToggledFeature = createSelector(featuredToggleState, (state, key) => key, getFeature);

/**
 * Selector to return if a feature is enabled or not
 */
export const isFeatureEnabled = createSelector(selectToggledFeature, (feature) => feature.enabled);
