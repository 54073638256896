import {
	TRACK_GA_EVENT,
	TRACK_GTM_EVENT,
	TRACK_BRAZE_EVENT,
	TRACKING_ACTIONS,
} from '../store/trackingPixels/trackingPixelsActionTypes';

/**
 * Google Analytics Tracking Event
 * category, action, label, value
 *
 * @param rest
 */
const trackGA = (...rest) => {
	App.ga('send', 'event', ...rest);
};

/**
 * Google Tag Manager Tracking Event
 *
 * @param event
 */
const trackGTM = (event) => {
	App.gtm({ event });
};

/**
 * Braze Tracking Event
 *
 * @param eventName
 * @param eventProperties
 */
const trackBraze = (eventName, eventProperties) => {
	App.braze(eventName, eventProperties);
};

/**
 * Track reducer
 *
 * @param action
 */
const track = ({ type, payload }) => {
	switch (type) {
		case TRACK_GA_EVENT:
			trackGA(payload.category, payload.action, payload.label, payload.value);
			break;
		case TRACK_GTM_EVENT:
			trackGTM(payload.event);
			break;
		case TRACK_BRAZE_EVENT:
			trackBraze(payload.category, payload.action);
			break;
	}
};

/**
 * Tracking Middleware
 *
 * @constructor
 */
const Tracking = (/*store*/) => (next) => (action) => {
	// Check to see if the action has a 'track' property. If so, then implement tracking
	if (action.track) {
		switch (action.track.constructor) {
			case Array:
				action.track.forEach((act) => track(act));
				break;
			case Object:
				track(action.track);
				break;
			default:
				throw new Error('Unsupported tracking action type', action.track);
		}
	}

	// If there isn't a type property and its not a function, ignore. This is so you can dispatch an event with the 'track'
	// property, without the need to have a base action
	if (!action.type && !(typeof action === 'function')) {
		return;
	}

	// Check to see if the action type matches one of the tracking action types
	const actionIsTrackingAction = TRACKING_ACTIONS.includes(action.type);

	if (actionIsTrackingAction) {
		return track(action);
	}

	return next(action);
};

export default Tracking;
