import { PASSWORD_RESET_BASE_URL, PASSWORD_RESET_SUB_APP } from './PasswordResetRouterConstants';
import { setPasswordResetToken } from '../store/passwordReset/passwordResetActions';

const PasswordResetRouter = Backbone.Router.extend({
	routes: {
		[PASSWORD_RESET_BASE_URL]: 'resetPassword',
		[PASSWORD_RESET_BASE_URL + '/:token']: 'resetPassword',
	},

	resetPassword: function(token) {
		App.store.dispatch(setPasswordResetToken(token));
		App.startSubApp(PASSWORD_RESET_SUB_APP);
	},
});

export default new PasswordResetRouter();
