'use strict';

import { denormalize, normalize } from 'normalizr';
import { metasSchema } from './Entities';

/**
 * Normalize an array of meta data
 *
 * @param meta
 */
export const normalizeMeta = (meta = []) => {
	return normalize(meta, metasSchema);
};

/**
 * Denormalize Meta Data
 *
 * @param entities
 * @param keys
 */
export const denormalizeMeta = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.meta), metasSchema, entities).filter((item) => !!item);
};
