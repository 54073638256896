import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui, brand ,} from '@tbh/ui-kit';
import { Text, Button } from '@tbh/ui-kit';

const StyledNJSportHeaderBar = styled('div')`
${(props) => css`
	max-height: 130px; // To Match the height of a regular header bar
	background: ${brand(props).color_2};
	width:100%;
	height: 130px;
`};
`;

const StyledNJSportHeaderBar__Heading = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__Heading;

		padding: ${spacings(props).tight}px;
		width:100%;

	`};
`;

const StyledNJSportHeaderBar__Event__Name = styled('div')`
	${(props) => css`
		label: NJSportHeaderBar__Event__Name;
		display:flex;
		padding: ${spacings(props).tight}px;
	`};
`;
const StyledNJSportHeaderBar__CompetionName = styled('div')`
	${(props) => css`
		label: NJSportHeaderBar__CompetionName;
        display:flex;
        padding: 2px;
	`};
`;
const StyledNJSportHeaderBar__Flage__Details = styled('div')`
	${(props) => css`
		label: StyledNJSportHeaderBar__Flage__Details;
		display:flex;
        padding: 2px;
        flex-flow: row wrap;
	`};
`;
const StyledNJSportHeaderBar__TimeToGoBUtton = styled(Button)(
  (props) => css`
        label: NJSportHeaderBar__TimeToGoBUtton;

		border-radius: 12px !important;
		font-size: 12.2373px;
		display: block;
		margin-top: ${spacings(props).tight}px;
		background: #c72128;
		border-color: #c72128;
		size:small;
		width:auto;
		height:26px;

		position: absolute;
		color:#ffffff;
		font-weight: bold;
	`,
);

 /////////////////////////////////////////////////////////////

const StyledCarouselSportsItem_Wrapper = styled('div')(
    (props) => css`
        label: CarouselSportsItem_Wrapper;

        margin: ${spacings(props).tight}px;
        position: relative;

        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        background: #f7f8f8;
        height: auto;
        width: 100%;
        border-radius: 1px;
        box-sizing: border-box;
		width: 270px;
		height:130px;

        `,
);

const cssGRSNextSportItem__Icon = css`
	label: GRSNextSportItem__Icon;

	// Can't use props or styled component here
	margin-right: 4px !important;
	font-weight: bold;
`;

const StyledGRSNextSportsItem__IconAndTime = styled(Text)`
	${(props) => css`
		label: GRSNextSportsItem__IconAndTime;

		justify-content: center;
		margin: ${spacings(props).tight}px;
		padding-top: ${spacings(props).tight}px;

	`};
`;

const StyledCarouselSportsItem = styled(Text)(
	(props) => css`
		label: CarouselSportsItem;

		display: block;
		cursor: pointer;
        width: 100%;
        ${props.center &&
			css`
				text-align: center;
			`};

		${props.padding &&
			css`
				padding: ${spacings(props).compact}px ${spacings(props).cozy}px;
			`};

		${props.margin &&
			css`
				margin-right: ${spacings(props).cozy}px;
			`};

		${props.border &&
			css`
				border-right: 1px solid ${ui(props).color_3};
			`};

		${props.slides &&
			props.slides === '1' &&
			css`
				width: 100%;
			`};

		${props.slides &&
			props.slides === '4' &&
			css`
				width: 25%;
			`};
	`,
);


const CarouselSportsItem = (props) => {
	const { className, children, id,  timeToGo, eventName, competitionName, icon,meta, action,country,  ...rest } = props;
	const { center } = rest;
	const carouselSportItemClass = cx({
		[className]: className,
	});

	const componentClasses = cx({
		[className]: className,
	});



    const iconClasses = [cssGRSNextSportItem__Icon];
	if (icon && icon.props && icon.props.className) {
		iconClasses.push(icon.props.className);
	}

	const handleClick = (e) => {
		e.preventDefault();
		action(id);
	};

	if (children) {
		return (
			<StyledCarouselSportsItem {...rest} className={carouselSportItemClass}  >
				{children}
			</StyledCarouselSportsItem>
		);
	}

	return (
		<StyledCarouselSportsItem  {...rest} className={carouselSportItemClass} action={handleClick}>
			<StyledNJSportHeaderBar
			noPadding
			className={componentClasses}
			margin={false}
		>
				<StyledNJSportHeaderBar__Heading>
					<StyledNJSportHeaderBar__Event__Name>
						<Text className={css`color:#ffffff;width:320px;text-align:left;padding-left:8px;text-transform: uppercase;`} size="-1" strong>{eventName}
							{/* <Icon key="2" className={css`margin-left:10px;`} size="1" icon={icon} push="1" />	{country === 'AU' ? (<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/AUS.svg" alt="AUS LOGO" title={country} className={css`width:27px;height:18px; margin-left:8px;`}/>) : country === 'NZ' ? (<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/NZ.svg" alt="NZ LOGO" title={country} className={css`width:27px;height:18px; margin-left:8px;`} />) : country === 'SGP' ? (<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SGP.svg" alt="SGP LOGO" title={country} className={css`width:27px;height:18px; margin-left:8px;`} />) : country === 'HK' ? (<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/HK.svg" alt="HK LOGO" title={country} className={css`width:27px;height:18px; margin-left:8px;`} />) : country === 'FR' ? (<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/FR.svg" alt="FR LOGO" title={country} className={css`width:27px;height:18px; margin-left:8px;`} />) : country === 'UK' ? (<img src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/GB.svg" title={country} alt="GB LOGO" className={css`width:27px;height:18px; margin-left:8px;`} />) : null} */}
						</Text>



					</StyledNJSportHeaderBar__Event__Name>
					<StyledNJSportHeaderBar__CompetionName>
						<Text className={css`color:#ffffff;width:320px;text-align:left;padding-left:8px;`} size="-1" > {competitionName}</Text>
					</StyledNJSportHeaderBar__CompetionName>
					<StyledNJSportHeaderBar__Flage__Details>
						<div className={css`flex: 1 1 70%;`} >
							<StyledGRSNextSportsItem__IconAndTime paragraph strong>
								{icon &&
                  React.cloneElement(icon, {
                      className: iconClasses.join(' '),
                  })}

							</StyledGRSNextSportsItem__IconAndTime>
						</div>


						<div className={css`flex: 1 1 30%;`} >
							<StyledNJSportHeaderBar__TimeToGoBUtton>
								{meta}
							</StyledNJSportHeaderBar__TimeToGoBUtton>
						</div>

					</StyledNJSportHeaderBar__Flage__Details>

				</StyledNJSportHeaderBar__Heading>
			</StyledNJSportHeaderBar>
		</StyledCarouselSportsItem>
	);
};
CarouselSportsItem.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	/** The ID of the next sport */
	id: PropTypes.number.isRequired,

	/** The name of the event for the sport */
	eventName: PropTypes.string.isRequired,

	/** The name of the competition for the sport */
	competitionName: PropTypes.string.isRequired,

	/** Time until the event starts */
	timeToGo: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	/** Action for when the sport item is clicked on */
	onClick: PropTypes.func,

	/** Link for when the sport is clicked on */
	link: PropTypes.string,

	/** Icon for the sport/event */
	icon: PropTypes.node,

    /** Extra information for the carousel item */
    meta: PropTypes.string,

    /** How many slides to display */
	slides: PropTypes.string,

	/** If the carousel item should be centered */
	center: PropTypes.bool,

	/** If the carousel item should have padding */
	padding: PropTypes.bool,

	/** If the carousel item should have a margin */
	margin: PropTypes.bool,

	/** If the carousel item should have a border */
	border: PropTypes.bool,

	/** Action when the carousel item is clicked on */
	action: PropTypes.func,

	/** Children to display under the carousel item */
    children: PropTypes.node,
    /** Whether to use a small amount of flex to fit more buttons */
    small: PropTypes.bool,

};

CarouselSportsItem.defaultProps = {
	className: null,
	icon: null,
    meta: null,
    center: undefined,
	padding: undefined,
	margin: undefined,
	border: undefined,
	action: undefined,
	children: undefined,
    small: null,
	slides: undefined,
	size: undefined,
	timeToGo: undefined,
	onClick: undefined,
	link: undefined,

};

export default CarouselSportsItem;
