import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, Text } from '@tbh/ui-kit';
import moment from 'moment';

const StyledTeamsEventHeaderStartDate = styled('div')(
	(props) => css`
		label: TeamsEventHeaderStartDate;

		margin: 0 ${spacings(props).compact}px;
	`,
);

const TeamsEventHeaderStartDate = (props) => {
	const { className, localEventStart } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledTeamsEventHeaderStartDate className={componentClasses}>
			<Text align="center" size="-1" type="alternate" strong>{localEventStart.format('MMM Do')}</Text>
			<Text align="center" size="-2" type="alternate">{localEventStart.format('h:mma')}</Text>
		</StyledTeamsEventHeaderStartDate>
	);
};

TeamsEventHeaderStartDate.propTypes = {
	localEventStart: PropTypes.instanceOf(moment).isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

TeamsEventHeaderStartDate.defaultProps = {
	className: '',
};

export default TeamsEventHeaderStartDate;
