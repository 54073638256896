import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

// Components
import { Button, EmailInput, Notification, PasswordInput, Text } from '@tbh/ui-kit';

// Functions/Constants
import { PASSWORD_8_CHAR, translateConstraints } from '@tbh/ui-kit';
import { errorString } from '../../../../legacy/core/format';
import { EVENT_KEY_ENTER } from '../../../../common/constants/Application';
import { LoadingSpanner } from '../../Application/LoadingSpinner/LoadingSpinner';

const StyledUpdatePassword__Notification = styled(Notification)`
	label: UpdatePassword__Notification;

	${(props) =>
		css`
			margin: 0 0 ${spacings(props).cozy}px;
		`};
`;

const validadePasswordAndReturnError = (password) => {
	// Minimum At least 10 characters, at least 1 number, at least 1 upper-case letter and at least 1 lower-case letter.

	if (!password) {
		return null;
	}

	if (password.length < 10) {
		return 'Must be at least 10 characters';
	}

	const atLeastOneNumber = /\d/;
	const atLeastOneUpperCase = /[A-Z]/;
	const atLeastOneLowerCase = /[a-z]/;

	if (!atLeastOneNumber.test(password)) {
		return 'Must contain at least one number';
	}

	if (!atLeastOneUpperCase.test(password)) {
		return 'Must contain at least one upper-case letter';
	}

	if (!atLeastOneLowerCase.test(password)) {
		return 'Must contain at least one lower-case letter';
	}

	return null;
};

class UpdatePassword extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Submit reset password */
		updatePassword: PropTypes.func.isRequired,

		/** Perform a forgotten password with the users email instead of current password */
		useEmailToResetPassword: PropTypes.bool,

		/** Token used for resetting the password */
		token: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		useEmailToResetPassword: false,
		token: null,
		className: '',
	};

	state = {
		loadingMask: false,
		errors: false,
		showSuccess: false,

		/** Form data */
		email: '',
		email_valid: false,
		oldPassword: '',
		oldPassword_valid: false,
		newPassword: '',
		newPassword_valid: false,
		newPasswordConfirmation: '',
		newPasswordConfirmation_valid: false,
	};

	/**
	 * Changes to input fields and their validation statuses
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleChange = (name, value, valid) => {
		let newState = {
			[name]: value,
			[`${name}_valid`]: valid,
		};

		// Checks validity for password confirmation field.
		switch (name) {
			case 'newPasswordConfirmation':
				newState.newPasswordConfirmation_valid = value === this.state.newPassword;
				break;
			case 'newPassword':
				newState.newPasswordConfirmation_valid = value === this.state.newPasswordConfirmation;
				break;
		}

		this.setState(newState);
	};

	/**
	 * Checks whether submission button is enabled or not.
	 * @return {boolean}
	 */
	shouldDisableButton = () => {
		const {
			email,
			email_valid,
			oldPassword,
			newPassword,
			newPasswordConfirmation,
			oldPassword_valid,
			newPassword_valid,
			newPasswordConfirmation_valid,
		} = this.state;

		let validator = this.props.useEmailToResetPassword ? email : oldPassword;
		let validator_valid = this.props.useEmailToResetPassword ? email_valid : oldPassword_valid;

		return (
			validadePasswordAndReturnError(newPassword) ||
			!validator ||
			!newPassword ||
			!newPasswordConfirmation ||
			!validator_valid ||
			!newPassword_valid ||
			!newPasswordConfirmation_valid ||
			newPassword == oldPassword
		);
	};

	/**
	 * Removes the error message
	 */
	clearErrorMessage = () => {
		this.setState({
			errors: false,
		});
	};

	/**
	 * Dynamically set button text
	 *
	 * @return {string}
	 */
	getButtonText = () => {
		const { t } = this.props;
		const { email, oldPassword, newPassword, newPasswordConfirmation } = this.state;

		// Old pass field blank
		if (!email && !oldPassword) {
			if (this.props.useEmailToResetPassword) {
				return t('UpdatePassword__EnterEmail');
			} else {
				return t('UpdatePassword__EnterCurrent');
			}
		} else if (!newPassword) {
			// New Pass blank
			return t('UpdatePassword__EnterNew');
		} else if (newPassword !== newPasswordConfirmation) {
			// Pass and confirmation should match
			return t('UpdatePassword__ShouldMatch');
		} else if (oldPassword === newPassword) {
			// new pass and old pass dose not equal
			return t('UpdatePassword__oldAndNewNotMatch');
		}

		return t('UpdatePassword__SetNew');
	};

	/**
	 * Submit form when pressing enter
	 */
	onKeyDown = (event) => {
		if (event.key === EVENT_KEY_ENTER && !this.shouldDisableButton()) {
			this.handleSubmit();
		}
	};

	/**
	 * Handle update password submission and set new state
	 */
	handleSubmit = () => {
		this.clearErrorMessage();
		const { email, oldPassword, newPassword, newPasswordConfirmation } = this.state;
		this.setState({
			loadingMask: true,
		});

		let newState = {};
		let validator = this.props.useEmailToResetPassword ? email : oldPassword;

		this.props
			.updatePassword(validator, newPassword, newPasswordConfirmation, this.props.token)
			.then((response) => {
				let error = response.data.error;

				if (response.data.success || (response.status === 200 && !error)) {
					newState = {
						errors: false,
						showSuccess: true,

						/** Reset Form data */
						email: '',
						email_valid: false,
						oldPassword: '',
						oldPassword_valid: false,
						newPassword: '',
						newPassword_valid: false,
						newPasswordConfirmation: '',
						newPasswordConfirmation_valid: false,
					};
				}

				if (error) {
					newState.errors = error;
				}
			})
			.catch((error) => {
				let errorMessage = '';

				if (error.response && error.response.data && error.response.data.errors) {
					if (Array.isArray(error.response.data.errors)) {
						for (let i = 0; i < error.response.data.errors.length; i++) {
							if (typeof error.response.data.errors[i] === 'object') {
								for (let key in error.response.data.errors[i]) {
									if (error.response.data.errors[i][key] && Array.isArray(error.response.data.errors[i][key])) {
										errorMessage += error.response.data.errors[i][key].join(' ') + '\n';
									}
								}
							} else {
								errorMessage += error.response.data.errors[i] + '\n';
							}
						}
					} else {
						errorMessage = error.response.data.errors;
					}
				} else {
					errorMessage = error.message;
				}

				if (errorMessage == '') {
					errorMessage = this.props.t('UpdatePassword__GenericError');
				}
				newState.errors = errorMessage;
			})
			.finally(() => {
				this.setState({
					...newState,
					loadingMask: false,
				});
			});
	};

	render() {
		const { t, className, useEmailToResetPassword } = this.props;

		const {
			email,
			oldPassword,
			newPassword,
			newPasswordConfirmation,
			newPasswordConfirmation_valid,
			loadingMask,
			errors,
			showSuccess,
		} = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		const buttonText = this.getButtonText();

		translateConstraints(t, [PASSWORD_8_CHAR]);

		return (
			<div className={componentClasses}>
				<LoadingSpanner style={{ display: loadingMask ? 'block' : 'none' }} />
				{errors && (
					<StyledUpdatePassword__Notification
						type={Notification.types.COLOUR_DANGER}
						message={errorString(errors)}
						buttonText={t('Dismiss')}
						buttonAction={this.clearErrorMessage}
					/>
				)}

				{!errors && showSuccess && (
					<StyledUpdatePassword__Notification
						type={Notification.types.COLOUR_SUCCESS}
						message={t('UpdatePassword__PasswordUpdate')}
					/>
				)}

				<Text size="-1" strong>
					{t('UpdatePassword__Step', { step: 1 })}
				</Text>
				{useEmailToResetPassword ? (
					<EmailInput
						label={t('EmailAddress')}
						name="email"
						value={email}
						onChange={this.handleChange}
						error={this.resetPasswordError}
						margin="cozy"
						onKeyDown={this.onKeyDown}
					/>
				) : (
					<PasswordInput
						value={oldPassword}
						name="oldPassword"
						onChange={this.handleChange}
						label={t('UpdatePassword__OldPassword')}
						margin="comfortable"
						constraints={PASSWORD_8_CHAR}
						onKeyDown={this.onKeyDown}
					/>
				)}

				<Text size="-1" strong>
					{t('UpdatePassword__Step', { step: 2 })}
				</Text>
				<PasswordInput
					value={newPassword}
					name="newPassword"
					onChange={this.handleChange}
					error={validadePasswordAndReturnError(newPassword)}
					label={t('UpdatePassword__NewPassword')}
					margin="cozy"
					onKeyDown={this.onKeyDown}
				/>
				<PasswordInput
					value={newPasswordConfirmation}
					name="newPasswordConfirmation"
					onChange={this.handleChange}
					label={t('UpdatePassword__ConfirmPassword')}
					margin="comfortable"
					error={
						newPasswordConfirmation && !newPasswordConfirmation_valid ? t('UpdatePassword__ConfirmationError') : ''
					}
					onKeyDown={this.onKeyDown}
				/>

				<Button block type="primary" disabled={this.shouldDisableButton()} action={this.handleSubmit}>
					{buttonText}
				</Button>
			</div>
		);
	}
}

export default withNamespaces()(UpdatePassword);
