import { useEffect, useState } from 'react';

const IDLE_TIMEOUT = 30 * 60 * 1000;

const InactivityMonitor = ({ onIdle, openLogin }) => {
	const [lastActivityTime, setLastActivityTime] = useState(Date.now());

	useEffect(() => {
		const resetTimer = () => {
			setLastActivityTime(Date.now());
		};

		const checkIdle = () => {
			if (Date.now() - lastActivityTime >= IDLE_TIMEOUT) {
				onIdle();
			}
		};

		const handleActivity = () => {
			resetTimer();
		};

		window.addEventListener('load', handleActivity);
		window.addEventListener('mousemove', handleActivity);
		window.addEventListener('mousedown', handleActivity);
		window.addEventListener('click', handleActivity);
		window.addEventListener('scroll', handleActivity);
		window.addEventListener('keypress', handleActivity);

		const intervalId = setInterval(checkIdle, 60 * 1000);

		return () => {
			window.removeEventListener('load', handleActivity);
			window.removeEventListener('mousemove', handleActivity);
			window.removeEventListener('mousedown', handleActivity);
			window.removeEventListener('click', handleActivity);
			window.removeEventListener('scroll', handleActivity);
			window.removeEventListener('keypress', handleActivity);
			clearInterval(intervalId);
		};
	}, [lastActivityTime, onIdle]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);

		if (urlParams.get('openLogin') && urlParams.get('openLogin') === 'true') {
			openLogin && openLogin();
		}
	}, [openLogin]);

	return null;
};

export default InactivityMonitor;
