import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import {
	RACING_BET_TYPE_EACHWAY_SHORT,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_PLACE_SHORT,
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_WIN_SHORT,
	RACING_BET_TYPE_MARGIN_SHORT,
} from '../../../../common/constants/Racing';

import { Select, SelectOption, Tabs, TabsItem } from '@tbh/ui-kit';

const cssNonExoticFilter__RadioItem = css`
	label: NonExoticFilter__RadioItem;

	flex: 1;
`;

const NonExoticFilter = (props) => {
	const { action, betType, buttonSize, className, disableButtons, type, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * When a button is clicked, trigger an event that sends off
	 * the button clicked on, and the previous betType;
	 * But only if a button was clicked that differs from the betType
	 */
	const handleDropDownClick = (fieldName, selectedValue) => {
		if (selectedValue !== betType) {
			action(selectedValue, betType);
		}
	};

	const handleTabClick = (fieldName) => {
		return action(fieldName, betType);
	};

	if (type === 'dropdown') {
		return (
			<Select
				disabled={disableButtons}
				value={betType}
				className={componentClasses}
				name="x"
				onChange={handleDropDownClick}
			>
				<SelectOption value={RACING_BET_TYPE_WIN_SHORT}>{t(RACING_BET_TYPE_WIN)}</SelectOption>
				<SelectOption value={RACING_BET_TYPE_PLACE_SHORT}>{t(RACING_BET_TYPE_PLACE)}</SelectOption>
				<SelectOption value={RACING_BET_TYPE_EACHWAY_SHORT}>{t('e/w')}</SelectOption>
			</Select>
		);
	}

	return (
		<Tabs wrappingBorder padding={Tabs.paddings.SPACING_TIGHT} className={componentClasses}>
			<TabsItem
				className={cssNonExoticFilter__RadioItem}
				action={handleTabClick}
				data={RACING_BET_TYPE_WIN_SHORT}
				size={buttonSize}
				active={betType === RACING_BET_TYPE_WIN_SHORT || betType === RACING_BET_TYPE_MARGIN_SHORT}
				disabled={disableButtons}
			>
				{t(RACING_BET_TYPE_WIN_SHORT)}
			</TabsItem>
			<TabsItem
				className={cssNonExoticFilter__RadioItem}
				action={handleTabClick}
				data={RACING_BET_TYPE_PLACE_SHORT}
				size={buttonSize}
				active={betType === RACING_BET_TYPE_PLACE_SHORT}
				disabled={disableButtons}
			>
				{t(RACING_BET_TYPE_PLACE_SHORT)}
			</TabsItem>
			{/*<TabsItem*/}
			{/*	className={cssNonExoticFilter__RadioItem}*/}
			{/*	action={handleTabClick}*/}
			{/*	data={RACING_BET_TYPE_EACHWAY_SHORT}*/}
			{/*	size={buttonSize}*/}
			{/*	active={betType === RACING_BET_TYPE_EACHWAY_SHORT}*/}
			{/*	disabled={disableButtons}*/}
			{/*>*/}
			{/*	{t(RACING_BET_TYPE_EACHWAY_SHORT)}*/}
			{/*</TabsItem>*/}
		</Tabs>
	);
};

NonExoticFilter.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to handle filter change */
	action: PropTypes.func.isRequired,

	/** The selected filter */
	betType: PropTypes.oneOf([
		RACING_BET_TYPE_WIN_SHORT,
		RACING_BET_TYPE_PLACE_SHORT,
		RACING_BET_TYPE_EACHWAY_SHORT,
		RACING_BET_TYPE_MARGIN_SHORT,
	]),

	/** How large the filter buttons should be */
	buttonSize: PropTypes.oneOf(['small', 'medium', 'large']),

	/** Extra classes */
	className: PropTypes.string,

	/** Whether to disable the filters */
	disableButtons: PropTypes.bool,

	/** The type of filters to display */
	type: PropTypes.oneOf(['dropdown', 'radiobutton']),
};

NonExoticFilter.defaultProps = {
	type: 'dropdown',
	betType: RACING_BET_TYPE_WIN_SHORT,
	buttonSize: 'small',
	className: '',
	disableButtons: false,
};

export default withNamespaces()(NonExoticFilter);
