import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import { css } from 'react-emotion';

import GRSExpertSelectionsDetails from './../GRSExpertSelectionsDetails/GRSExpertSelectionsDetails';

/**
 * Define all the selection statuses & racing type codes as defined in the racing constants file
 */
import {
	SELECTION_NOT_SCRATCHED_STATUS,
	SELECTION_STATUSES,
	RACING_BET_TYPE_MARGIN,
} from '../../../../../common/constants/Racing';
import { StyledGRSExpertRaceBettingAdd, StyledGRSExpertSelectionPriceListItem, StyledGRSExpertSelectionPricesList, StyledGRSExpertSelectionsListItem, StyledGRSExpertSelectionsListItem__Wrapper } from './GRSExpertSelectionsListItem.styled';



const GRSExpertSelectionsListItem = (props) => {
	const {
		selectionBetButtons,
		id,
		selection_status,
		number,
		name,
		silk,
		jockey,
		barrier,
		weight,
		bettingAvailable,
		last_starts_string,
		type_code,
		addToSingle,
		addToMulti,
		className,
		isMobileWidth,
		size,
		trainer,
		betType,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	// Expert selection showing GRSF win products only
	const winGRSProducts = selectionBetButtons.filter(
		(selectionBetButton) =>
			selectionBetButton.product_code === 'GRSF' &&
			selectionBetButton.product_id == 16 &&
			selectionBetButton.bet_type === 'win',
	);

	const ReduceSelectionButtons = (selectionBetButtons) => {
		let reducedSelectionButtons = [];
		selectionBetButtons.forEach((item, i) => {

			// tem msm id mas tem types diferentes
			const sameProductTypeAndId = reducedSelectionButtons.find(selection => selection.product_id === item.product_id && selection.bet_type === item.bet_type);
			if (!sameProductTypeAndId) {
				let onlyOneObject = selectionBetButtons.filter(selection => selection.product_id === item.product_id && selection.bet_type === item.bet_type)[0];

				reducedSelectionButtons.push(onlyOneObject);
			}
		});


		// let newSelectionBetButtons = selectionBetButtons.filter((item, i) => selectionBetButtons.indexOf(item) === i);;
		return reducedSelectionButtons;
	};

	const winGRSProductsNotDoubling = ReduceSelectionButtons(winGRSProducts);
	
	return (
		<React.Fragment>
			<StyledGRSExpertSelectionsListItem id={id} className={componentClasses}>
				<StyledGRSExpertSelectionsListItem__Wrapper>
					<div
						className={css`
							flex: 1 1 auto;
							display: flex;
							overflow: hidden;
						`}
					>
						<GRSExpertSelectionsDetails
							number={number}
							name={name}
							silk={silk}
							jockey={jockey}
							trainer={trainer}
							barrier={barrier}
							weight={weight}
							last_starts_string={last_starts_string}
							selection_status={selection_status}
							type_code={type_code}
							size={size}
						/>
					</div>

					<StyledGRSExpertSelectionPricesList>
						{winGRSProductsNotDoubling.map((betButton) => (
							<StyledGRSExpertSelectionPriceListItem key={betButton.index}>
								{betButton.bet_type == 'win'}
								<StyledGRSExpertRaceBettingAdd
									key={`${betButton.index}_${betButton.product_id}_${betButton.product_code}_${betButton.bet_type}`}
									small={isMobileWidth ? selectionBetButtons.length > 2 : selectionBetButtons.length > 4}
									price={betButton.price}
									initialPrice={betButton.initialPrice}
									betType={betButton.bet_type}
									productId={betButton.product_id}
									selectionId={id}
									hasMulti={betButton.hasMulti}
									addToSingle={addToSingle}
									addToMulti={betButton.hasMulti ? addToMulti : null}
									bettingAvailable={bettingAvailable && betButton.productAvailable} // newly added && betType ===RACING_NON_EXOTIC_BET_TYPE
									priceRollups={betButton.priceRollups}
									useWrapper={
										betButton.bet_type === RACING_BET_TYPE_MARGIN && selection_status === SELECTION_NOT_SCRATCHED_STATUS
									}
									scratched={selection_status !== SELECTION_NOT_SCRATCHED_STATUS}
									size={size}
								/>
							</StyledGRSExpertSelectionPriceListItem>
						))}
					</StyledGRSExpertSelectionPricesList>
				</StyledGRSExpertSelectionsListItem__Wrapper>
			</StyledGRSExpertSelectionsListItem>
		</React.Fragment>
	);
};

GRSExpertSelectionsListItem.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** Action to handle the selection being selected for a single bet */
	addToSingle: PropTypes.func.isRequired,

	/** Action to handle the selection being selected for a multi bet */
	addToMulti: PropTypes.func.isRequired,

	/** Boolean indicating if betting is available ie. race isn't closed */
	bettingAvailable: PropTypes.bool.isRequired,

	/** The currently selected bet type */
	betType: PropTypes.string.isRequired,

	/**
	 * This will be an array of bet buttons that will be displayed for each selection
	 * The order of display will be from left to right as given by the array.
	 */
	selectionBetButtons: PropTypes.arrayOf(
		PropTypes.shape({
			/** The ID of the product */
			product_id: PropTypes.number.isRequired,
			/** The code of the product */
			product_code: PropTypes.string.isRequired,
			/** The type of bet */
			bet_type: PropTypes.string.isRequired,
			/** The odds/price for the bet */
			price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			/** If a multi bet is available for this product */
			hasMulti: PropTypes.bool,
			/** If the product is available to be bet on */
			productAvailable: PropTypes.bool,
		}),
	).isRequired,

	displayPrices: PropTypes.array,

	/** The following props are race selection details which are pretty much what the name suggests! */
	/** Selections race number */
	id: PropTypes.number.isRequired,

	/** Selections race number */
	number: PropTypes.number.isRequired,

	/** Selections name */
	name: PropTypes.string.isRequired,

	/** Whether or not the component is being viewed in small context */
	isMobileWidth: PropTypes.bool,

	/** Selections race silk */
	silk: PropTypes.string,

	/** Selections race jockey or driver */
	jockey: PropTypes.string,

	/** Selections trainer */
	trainer: PropTypes.string,

	/** Selections race barrier number */
	barrier: PropTypes.number,

	/**
	 * This status is used to determine if the race selection has been scratched or late scratched
	 * and if so will render with a new class to visually convey this to the user
	 */
	selection_status: PropTypes.oneOf(SELECTION_STATUSES),

	/** Runner contains the selections form stats and last starts */
	runner: PropTypes.shape(),

	/** Extra class(es) to pass through to the component */
	className: PropTypes.string,

	/** Win deductions in cents */
	win_deductions: PropTypes.number,

	/** Place deductions in cents */
	place_deduction: PropTypes.number,
};

GRSExpertSelectionsListItem.defaultProps = {
	betType: '',
	boxed: false,
	isMobileWidth: false,
	silk: null,
	jockey: null,
	trainer: null,
	barrier: null,
	selection_status: null,
	runner: null,
	selectionBetButtons: [
		{
			price: null,
			hasMulti: false,
			productAvailable: true,
		},
	],
	className: null,
	displayPrices: null,
	size: null,
	win_deductions: 0,
	place_deduction: 0,
};

export default GRSExpertSelectionsListItem;
