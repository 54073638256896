import React, { createContext } from 'react';
import aclReducer from '../store/acl/aclReducer';

const defaultAclContext = aclReducer(undefined, {});

const AclContext = createContext(defaultAclContext);

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withAclContext = (WrappedComponent) => {
	const WithAclContext = (props) => {
		return <AclContext.Consumer>
			{(acl) => (<WrappedComponent acl={acl} {...props}/>)}
		</AclContext.Consumer>;
	};

	WithAclContext.displayName = `WithAclContext(${getDisplayName(WrappedComponent)})`;
	return WithAclContext;
};

export default AclContext;
