import { denormalize, normalize } from 'normalizr';

import { nextToJumpRaceSelectionsSchema } from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
const denormalizeNextToJumpRaceSelections = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.nextToJumpRaceSelections), nextToJumpRaceSelectionsSchema, entities);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizeNextToJumpRaceSelections = (response) => {
	return normalize(response, nextToJumpRaceSelectionsSchema);
};

export { denormalizeNextToJumpRaceSelections, normalizeNextToJumpRaceSelections };
