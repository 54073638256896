import { SPORT_NEXJUMP_SUB_APP, SPORT_NEXJUMP_BASE_URL } from '../pages/SportNextJump/SportNextJumpConstants';

import {
    loadNJSportsHomeData,
    setNJSportsHomeFilters,
  } from '../store/sportsNextJumpHome/SportsNextJumpHomeAction';

import { show404, scrollToTop, setBodyLoading  } from '../store/application/applicationActions';
import { slugify } from '../legacy/core/format';

const { dispatch, getState } = App.store;

const SportsNextJumpRouter = Backbone.Router.extend({
    routes: {
        [SPORT_NEXJUMP_BASE_URL]: 'showNextJumpSportsHome',
    },

    /**
     * make new router as 'next-jump-sports'
     * @HW 22Jan2020
     */
    showNextJumpSportsHome: function() {
      this.resetNJSportsStore(null);
      scrollToTop();
      return dispatch(loadNJSportsHomeData())
        .then(() => {
          dispatch(setBodyLoading(false));
          return App.startSubApp(SPORT_NEXJUMP_SUB_APP);
        })
        .catch((e) => {
          //console.log(e);
          dispatch(show404());
        });
    },

   

      resetNJSportsStore: function(sportName, competitionName, competitionId, eventId) {
        let state = getState();
        let selectedSport = Object.values(state.entities.sports).find((sport) => slugify(sport.name) === sportName) || {};
        let selectedCompetition = state.entities.competitions[competitionId] || {};
        let selectedEvent = state.entities.events[eventId] || {};
        let selectedBaseCompetitionId = selectedCompetition.base_competition_id;
        dispatch(setBodyLoading(true));
        dispatch(
          setNJSportsHomeFilters(
            selectedSport.id,
            selectedSport.name || sportName,
            selectedBaseCompetitionId,
            selectedCompetition.id,
            selectedEvent.id,
          ),
        );
      },
   
});

export default new SportsNextJumpRouter();