import React from 'react';
import UpCarouselItemLoading from './../../Carousel/UpCarouselItem/UpCarouselItemLoading';
import styled, { css } from 'react-emotion';
import { Ticker } from '@tbh/ui-kit';
import cx from 'classnames/bind';
import {
	StyledRaceUpcomingWrapper,
	StyledRaceUpcoming_carousel_body_content,
	StyledRaceUpcoming__Carousel,
	StyleUpRaceCardContainerLoading,
} from './RaceUpcoming.styled';
import { Card, CardBody, CardHeader, CardTitle } from '../../../UI/Card/Card';

export const modeChangeOverSize = 751;

const StyledRaceUpcoming__NtjRacesCarouselItem = styled(UpCarouselItemLoading)(
	(props) => css`
		label: RaceUpcoming__NtjRacesUpCarouselItem;
		min-height: 65px;
		width: auto;
		box-sizing: border-box;
	`,
);

const RaceUpcomingLoading = (props) => {
	const { size, className } = props;

	const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
	const componentClassesV3 = cx({
		[className]: className,
	});
	return (
		<Card className={componentClassesV3}>
			<CardHeader>
				<CardTitle>UPCOMING Races</CardTitle>
			</CardHeader>

			<CardBody>
				<StyledRaceUpcoming__Carousel size={size} nextButton prevButton freeMode buttonStyle="1">
					{items.map((upcomingRace) => (
						<StyledRaceUpcomingWrapper key={upcomingRace} size={size}>
							<Ticker every={90000}>
								<StyledRaceUpcoming__NtjRacesCarouselItem />

								<StyledRaceUpcoming_carousel_body_content size={size}>
									<StyleUpRaceCardContainerLoading size={size} />
								</StyledRaceUpcoming_carousel_body_content>
							</Ticker>
						</StyledRaceUpcomingWrapper>
					))}
				</StyledRaceUpcoming__Carousel>
			</CardBody>
		</Card>
	);
};

export default RaceUpcomingLoading;
