import PropTypes from 'prop-types';
import withSideEffect from 'react-side-effect';
import { normalizeAndMergeMeta } from '../../../store/entities/actions/MetaActions';

const Meta = () => null;

Meta.propTypes = {
	id: PropTypes.string.isRequired,
};

const reducePropsToState = (propsList = []) => {
	return propsList.reverse().reduce((acc, nextProp) => {
		if (!acc.some((i) => i.id === nextProp.id)) {
			acc.push({
				name: nextProp.id,
				...nextProp,
			});
		}

		return acc;
	}, []);
};

const handleStateChangeOnClient = (meta = []) => {
	App.store.dispatch(normalizeAndMergeMeta(meta));
};

export default withSideEffect(reducePropsToState, handleStateChangeOnClient)(Meta);
