import { brand, Text, typography } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';
import { DEFAULT_COLORS } from '../../../../../constants/themes';

const SupportInfoWrapp = styled('div')`
	margin-left: 12px;
	margin-top: 6px;
	margin-bottom: 12px;
`;

const SupportInfoPhone = styled('a')`
	display: block;
	text-decoration: none;
	color: #fbfbfb;
	margin-bottom: 4px;
`;

const SupportInfoLabel = styled('span')`
	display: block;
	color: #fbfbfb;
	cursor: text;
`;

const DropdownContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	color: white;
	width: 240px;
	${(props) =>
		css`
			background: ${controllBackgroundColor(props)};
		`}
`;
const DropdownHeader = styled.div`
	${(props) => css`
        height: ${props.step === 'step-0' ? '45px' : '40px'};
        padding-left: 20px;
        padding-right: 20px;
        ${props.step == 'step-2' &&
					`
            padding-left: 25px;
        `}
        ${props.step == 'step-3' &&
					`
            padding-left: 30px;
        `}
        ${props.step == 'step-4' &&
					`
            padding-left: 35px;
        `}
    `}

	align-items: center;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
`;
const DropdownTitle = styled.div`
	${(props) => css`
		display: flex;
		flex: 1 1 80%;
		align-items: center;

		i {
			margin-right: 10px;
			font-size: ${typography(props)['size_-5']};
		}
	`}
`;
const DropdownIcon = styled.i`
	display: flex;
	flex: 1 1 20%;
	align-items: center;
	justify-content: center;

	font-size: ${typography()['size_-4']};
`;
const DropdownList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
const DropdownItem = styled.div`
	${(props) => css`   
	min-height: 40px;
    padding-left: 20px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	${props.step !== 'step-0' ? `background: ${controllBackgroundColor(props)};` : 'background: #fff;'}
	
    ${props.step == 'step-2' &&
			`
    padding-left: 25px;
`}
${props.step == 'step-3' &&
	`
    padding-left: 30px;
`}
${props.step == 'step-4' &&
	`
    padding-left: 35px;
`}`}
	align-items: center;
	display: flex;
	cursor: pointer;
`;
const StyledDropDownText = styled(Text)`
	${(props) =>
		`&:hover {
			color: ${brand(props).color_2}!important;
		}
    `}

	${(props) => (props.color ? `color:${props.color};` : 'color: white!important;')}
`;


const MenuIconWrapper = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 8px;
`;



const controllBackgroundColor = (props) => {
	const { step } = props;

	if (step === 'step-0')
		return brand(props).primary_active_color
			? brand(props).primary_active_color
			: DEFAULT_COLORS.df_primary_active_color;

	if (step === 'step-1')
		return brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color;

	if (step === 'step-2') return brand(props).color_2_dark ? brand(props).color_2_dark : '#BB181B';

	if (step === 'step-3') return brand(props).color_5_light ? brand(props).color_5_light : '#e23a39';

	if (step === 'step-4') return brand(props).color_5_light ? brand(props).color_5_light : '#e23a39';

	return brand().color_2_light ? brand().color_2_light : '#850000';
};
// Remove poli
// Bet Builder menu
export const Dropdown = ({ name, items, step, action, isFirst, brandDetails, isComingSoon, icon }) => {
	if (items.length === 0) {
		return (
			<DropdownItem isComingSoon={isComingSoon} step={step} onClick={() => action && action()}>
				  {icon && <MenuIconWrapper src={icon} alt="" />}
				<StyledDropDownText step={step} size="-1" color="black">
					{name}
				</StyledDropDownText>
				{isComingSoon && (
					<Text size="-4" style={{ marginLeft: 4, color: '#2E2E2E	' }}>
						(Coming Soon)
					</Text>
				)}
			</DropdownItem>
		);
	}

	// If the step is the highest step, then it should be open by default
	const isLowStep = (step) => step === 'step-0' || step === 'step-1';

	const [isOpen, setIsOpen] = React.useState(isLowStep(step) && isFirst);
	const toggle = () => setIsOpen(!isOpen);

	const itemIsDropdown = (item) => item.children && item.children.length > 0;

	const IconClassName = isOpen ? 'fa fa-fw fa-chevron-up' : 'fa fa-fw fa-chevron-down';

	const title = isLowStep(step) ? name.toUpperCase() : name;

	return (
		<DropdownContainer step={step}>
			<DropdownHeader step={step} onClick={toggle}>
				<DropdownTitle
					onClick={(e) => {
						e.stopPropagation();
						if (action) action();
					}}
				>
					{/* {!isLowStep(step) && <i className="fa fa-fw fa-chevron-right" />} */}
					{icon && <MenuIconWrapper src={icon} alt="" />}
					<StyledDropDownText step={step} size={isLowStep(step) ? '-2' : '-3'}>
						{title}
					</StyledDropDownText>
				</DropdownTitle>

				<DropdownIcon className={IconClassName} onClick={toggle} />
			</DropdownHeader>
			{isOpen && (
				<DropdownList>
					{items.map((item, i) =>
						itemIsDropdown(item) ? (
							<Dropdown
								isFirst={i == 0}
								key={item.name}
								name={item.name}
								items={item.children}
								step={item.step}
								action={item.action}
								icon={item.icon}
							/>
						) : (
							<DropdownItem step={step} key={item.name} onClick={() => item.action()}>
								<StyledDropDownText step={step} size="-3">
									{item.name}
									{item.name === 'Customer Support' && (
										<SupportInfoWrapp>
											<SupportInfoPhone href={`tel:${brandDetails.phone}`}>{brandDetails.phone}</SupportInfoPhone>
											<SupportInfoLabel>24 Hours - 7 Days</SupportInfoLabel>
										</SupportInfoWrapp>
									)}
									{item.name === 'Live Betting' && (
										<SupportInfoWrapp>
											<SupportInfoPhone href={`tel:${brandDetails.phone}`}>{brandDetails.phone}</SupportInfoPhone>
											<SupportInfoLabel>24 Hours - 7 Days</SupportInfoLabel>
										</SupportInfoWrapp>
									)}
								</StyledDropDownText>
							</DropdownItem>
						),
					)}
				</DropdownList>
			)}
		</DropdownContainer>
	);
};
