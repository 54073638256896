// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Actions
import { getLossLimit, setLossLimit, removeLossLimit } from '../../../../../store/lossLimits/lossLimitsActions';
import { trackGaEvent } from '../../../../../store/trackingPixels/trackingActions';
import { setSelectedMenuItem } from '../../../../../store/userAccount/userAccountActions';
import { getUserAccountItem } from '../../../../../pages/UserAccount/UserAccountSelectors';
import { scrollToTop } from '../../../../../store/application/applicationActions';

// Components
import BetLimits from '../../../../../components/features/ResponsibleGambling/BetLimits/BetLimits';

// Constants
import {
	SET_DAILY_LIMIT,
	SET_WEEKLY_LIMIT,
	SET_MONTHLY_LIMIT,
} from '../../../../../components/features/ResponsibleGambling/BetLimits/Constants';
import { USER_ACCOUNT_SUBMENU_SELF_EXCLUSION } from '../../../../../pages/UserAccount/UserAccountPageConstants';
import { Container, StyledExtraContentContainer, StyledSectionTitle } from '../../Components';
import { Text } from '@tbh/ui-kit';

const CONFIRMATION_CHANGE = 'CONFIRMATION_CHANGE';
const CONFIRMATION_REMOVAL = 'CONFIRMATION_REMOVAL';

class LossLimitsContainer extends Component {
	static propTypes = {
		/**
		 * user's id
		 */
		userId: PropTypes.number.isRequired,

		/**
		 * user's first name
		 */
		first_name: PropTypes.string.isRequired,

		/**
		 * hook for getting the loss limits
		 */
		getLossLimit: PropTypes.func.isRequired,

		/**
		 * hook for setting/updating the loss limit
		 */
		setLossLimit: PropTypes.func.isRequired,

		/**
		 * hook for removing the loss limit
		 */
		removeLossLimit: PropTypes.func.isRequired,

		/**
		 * GA Tracking for when a tab is clicked:
		 * Category         | Action    | Label
		 * LossLimits    | Select    | Daily/Weekly
		 */
		onTabClick: PropTypes.func.isRequired,

		/**
		 * redirects to the Self Exclusion route
		 */
		navigateToSelfExclusion: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/**
		 * user's old daily loss limit
		 */
		daily_loss_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/**
		 * user's old weekly loss limit
		 */
		weekly_loss_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/**
		 * user's old monthly loss limit
		 */
		monthly_loss_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		daily_loss_limit: {},
		weekly_loss_limit: {},
		monthly_loss_limit: {},
		brandName: null,
	};

	state = {
		flow: SET_DAILY_LIMIT,
		error: null,
		componentIsLoading: false,
		updateIsLoading: false,
		removeLimitLoading: false,
		confirmation: null,
	};

	componentDidMount() {
		/**
		 * Load the deposit limits
		 * Set a loading mask while loading the limits
		 * Remove when done
		 */
		this.setLoadingMask(true, 'componentIsLoading');

		this.props.getLossLimit('weekly');
		this.props.getLossLimit('monthly');
		return this.props.getLossLimit('daily').finally(() => {
			this.setLoadingMask(false, 'componentIsLoading');
		});
	}

	/**
	 * Set a loading mask
	 *
	 * @param loading
	 * @param region
	 */
	setLoadingMask(loading = false, region) {
		this.setState({
			[region]: loading,
		});
	}

	/**
	 * handle content change when clicking on a tab item
	 * and send GA tracking
	 *
	 * @param screen
	 */
	handleScreenChange = (screen) => {
		let track;

		if (screen === SET_DAILY_LIMIT) {
			track = 'Daily';
		} else if (screen === SET_WEEKLY_LIMIT) {
			track = 'Weekly';
		} else if (screen === SET_MONTHLY_LIMIT) {
			track = 'Monthly';
		}

		this.props.onTabClick(track);

		this.handleClearMessage();

		this.setState({
			flow: screen,
		});
	};

	/**
	 * Calls the action to Set a loss limit
	 * Set an error message if the action fails
	 *
	 * @param amount
	 * @param type
	 */
	handleSetLossLimit = (amount, type) => {
		this.setLoadingMask(true, 'updateIsLoading');

		const period = type === 'daily' ? 1 : type === 'weekly' ? 7 : 30;

		this.props
			.setLossLimit(amount, period)
			.then((response) => {
				this.props
					.getLossLimit(type)
					.then(() => {
						this.setState({
							error: null,
							confirmation: response.data.data,
						});
					})
					.finally(() => {
						this.setLoadingMask(false, 'updateIsLoading');
					});
			})
			.catch((error) => {
				let errorMessage = '';

				if (error.response && error.response.data && error.response.data.errors) {
					if (Array.isArray(error.response.data.errors)) {
						for (let i = 0; i < error.response.data.errors.length; i++) {
							if (typeof error.response.data.errors[i] === 'object') {
								for (let key in error.response.data.errors[i]) {
									if (error.response.data.errors[i][key] && Array.isArray(error.response.data.errors[i][key])) {
										errorMessage += error.response.data.errors[i][key].join(' ') + '\n';
									}
								}
							} else {
								errorMessage += error.response.data.errors[i] + '\n';
							}
						}
					} else {
						errorMessage = error.response.data.errors;
					}
				} else {
					errorMessage = error.message;
				}

				if (errorMessage == '') {
					errorMessage = 'An error occurred while setting the loss limit';
				}

				this.setState({
					error: errorMessage,
					confirmation: null,
				});
				this.setLoadingMask(false, 'updateIsLoading');
			});
	};

	/**
	 * Calls the action to remove the loss limit
	 * Set an error message if the action fails
	 *
	 * @param period
	 */
	handleRemoveLossLimit = (period) => {
		this.setLoadingMask(true, 'removeLimitLoading');

		this.props
			.removeLossLimit(period)
			.then((response) => {
				this.props
					.getLossLimit(period)
					.then(() => {
						this.setState({
							error: null,
							confirmation: response.data.data,
						});
					})
					.finally(() => {
						this.setLoadingMask(false, 'removeLimitLoading');
						scrollToTop();
					});
			})
			.catch((error) => {
				this.setState({
					error: error.response && error.response.data ? error.response.data.errors : error.message,
					confirmation: null,
				});
				this.setLoadingMask(false, 'removeLimitLoading');
				scrollToTop();
			});
	};

	/**
	 * Remove the error message
	 */
	handleClearMessage = () => {
		this.setState({
			error: null,
			confirmation: null,
		});
	};

	render() {
		const {
			className,
			first_name,
			daily_loss_limit,
			weekly_loss_limit,
			monthly_loss_limit,
			navigateToSelfExclusion,
			brandName,
		} = this.props;

		const { componentIsLoading, updateIsLoading, removeLimitLoading, flow, error, confirmation } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		return (
			<div>
				<Text size="1">Loss Limits</Text>
				<Container>
					<StyledExtraContentContainer>
						<BetLimits
							className={containerClasses}
							limitType="Loss"
							first_name={first_name}
							daily_bet_limit={daily_loss_limit}
							weekly_bet_limit={weekly_loss_limit}
							monthly_bet_limit={monthly_loss_limit}
							onScreenChange={this.handleScreenChange}
							setBetLimit={this.handleSetLossLimit}
							removeBetLimit={this.handleRemoveLossLimit}
							clearMessage={this.handleClearMessage}
							goToSelfExclusion={navigateToSelfExclusion}
							error={error}
							componentIsLoading={componentIsLoading}
							updateIsLoading={updateIsLoading}
							removeLimitLoading={removeLimitLoading}
							confirmation={confirmation}
							flow={flow}
							moreInfo
							brandName={brandName}
						/>
					</StyledExtraContentContainer>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		first_name: state.entities.users[state.application.authenticatedUser].first_name,
		userId: state.application.authenticatedUser,
		daily_loss_limit: state.lossLimits.daily_loss_limit,
		weekly_loss_limit: state.lossLimits.weekly_loss_limit,
		monthly_loss_limit: state.lossLimits.monthly_loss_limit,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getLossLimit: (period) => {
			return dispatch(getLossLimit(period));
		},
		setLossLimit: (amount, period) => {
			return dispatch(setLossLimit(amount, period));
		},
		removeLossLimit: (period) => {
			return dispatch(removeLossLimit(period));
		},
		navigateToSelfExclusion: () => {
			dispatch(setSelectedMenuItem(getUserAccountItem(USER_ACCOUNT_SUBMENU_SELF_EXCLUSION)));
		},
		onTabClick: (type) => {
			return dispatch(trackGaEvent('LossLimits', 'Select', type));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LossLimitsContainer);
