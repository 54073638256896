import { get } from '../../../common/Ajax';
import { mergeEntities, deepCompareMergeEntities } from '../../../common/actions/actionHelpers';
import { RACING_HOME_SET_BET_TYPES } from '../../GRSracingHome/GRSracingHomeActionType';
import { setBetTypes, setContainerLoadingMask, setShowingMeetings } from '../../GRSracingHome/GRSracingHomeActions';
import { setBodyLoading } from '../../application/applicationActions';
import { normalizeMeetings } from '../schemas/MeetingSchema';
import { getCache, removeCache } from './CacheActions';

/**
 * Normalizes and dispatch merge action for array of Races.
 * @param {Array} meetings
 */
export const updateMeetings = (meetings) => {
	return mergeEntities(normalizeMeetings(meetings).entities);
};

/**
 * Update meetings and races after a deep compare of old and new entities.
 *
 * @param meetings
 * @returns {function(*, *)}
 */
export const updateMeetingsDeepCompare = (meetings) => {
	const normalizedData = normalizeMeetings(meetings).entities;

	return deepCompareMergeEntities(normalizedData);
};

/**
 * Normalizes and dispatch merge action for single Race.
 * @param {object} meeting
 */
export const updateMeeting = (meeting) => {
	return updateMeetings([meeting]);
};

export const handleMeetingsAndRaceCache = (id) => (dispatch) => {
	const cache = getCache('meetingsAndRaces');
	if (!cache || !cache[id] || !cache[id].timestamp || !cache[id].data) return;

	const now = new Date().getTime();

	// If cache is older than 5 minutes, don't use it.

	if (now - cache[id].timestamp > 300000) {
		// delete cache[id];
		removeCache('meetingsAndRaces', id);
		return;
	}
	const meetings = cache[id].data;
	setTimeout(() => {
		let normalizedMeetings = normalizeMeetings(meetings);
		dispatch(mergeEntities(normalizedMeetings.entities));
		const meetingIds = normalizedMeetings.result;
		dispatch(setShowingMeetings(meetingIds));
		dispatch(setContainerLoadingMask(false));
		dispatch(setBodyLoading(false));
		//console.log('meetingsAndRaces cache');
	}, 500);
};

/**
 * Fetch meetings and races. If param 'date' is passed, fetch for given day.
 *
 * @param {string} date 'YYYY-MM-DD'
 * @return {object} Ajax response added with list of meeting ids.
 */
export const fetchMeetingsAndRaces = (date) => (dispatch) => {
	const id = `${date}`;
	// dispatch(handleMeetingsAndRaceCache(id));
	return get('combined/meetings/races', {
		params: { date },
	}).then((response) => {

		
		const betTypes = response.data.bet_types || [];
		// Dispatch separately to isolate the issue
		dispatch(setBetTypes(betTypes));
		/**
		 * The'requested_at' property specifically from this endpoint might be out of date
		 * due to backend caching purposes.
		 */

		 // Extract bet types from response
		

		let meetings = response.data.data.map((meeting) => ({ ...meeting, requested_at: null }));

		// setCache('meetingsAndRaces', id, meetings);
		let normalizedMeetings = normalizeMeetings(meetings);
		// console.log(normalizedMeetings);
		dispatch(mergeEntities(normalizedMeetings.entities));
		response.meetingIds = normalizedMeetings.result;

		let exoticPools = response.data.data.map((meeting) => ({
			meetingId: meeting.id,
			exoticPools: meeting.exotic_pools,
		}));
		response.exoticPools = exoticPools;	

		return response;
	}).catch((error) => {
		document.Sentry && document.Sentry.captureException(error);
		return error;
	});
};

/**
 * Fetch meetings and races. If param 'date' is passed, fetch for given day.
 * @HW 20Feb2020
 * @param {string} date 'YYYY-MM-DD'
 * @param {string} type 'g' ,'t' and 'h'
 * @return {object} Ajax response added with list of meeting ids.
 */
export const fetchMeetingAndRacesFilterByDateAndType = (date, type) => (dispatch) => {
	const id = `${date}` + `_${type}`;
	// dispatch(handleMeetingsAndRaceCache(id));
	return get('combined/meetings/races', {
		params: { date, type },
	}).then((response) => {
		/**
		 * The'requested_at' property specifically from this endpoint might be out of date
		 * due to backend caching purposes.
		 */
		let meetings = response.data.data.map((meeting) => ({ ...meeting, requested_at: null }));
		// setCache('meetingsAndRaces', id, meetings);
		let normalizedMeetings = normalizeMeetings(meetings);
		// console.log(normalizedMeetings);
		dispatch(mergeEntities(normalizedMeetings.entities));
		response.meetingIds = normalizedMeetings.result;

		let exoticPools = response.data.data.map((meeting) => ({
			meetingId: meeting.id,
			exoticPools: meeting.exotic_pools,
		}));
		response.exoticPools = exoticPools;

		return response;
	}).catch((error) => {
		document.Sentry && document.Sentry.captureException(error);
		return error;
	});
};
/**
 * Fetch meetings and races. If param 'date' is passed, fetch for given day.
 * @HW 20Feb2020
 * @param {string} date 'YYYY-MM-DD'
 * @param {string} type 'g' ,'t' and 'h'
 * @return {object} Ajax response added with list of meeting ids.
 * http://tst.api.grs.bet/api/v3/combined/meetings/races-by-type?date=2020-06-11&type=r
 *
 */
export const fetchMeetingAndRacesFilterByDateAndType_GRS = (date, type) => (dispatch) => {
	return get('combined/meetings/races-by-type', {
		params: { date, type },
	}).then((response) => {
		/**
		 * The'requested_at' property specifically from this endpoint might be out of date
		 * due to backend caching purposes.
		 */
		let meetings = response.data.data.map((meeting) => ({ ...meeting, requested_at: null }));
		let normalizedMeetings = normalizeMeetings(meetings);
		dispatch(mergeEntities(normalizedMeetings.entities));
		response.meetingIds = normalizedMeetings.result;

		let exoticPools = response.data.data.map((meeting) => ({
			meetingId: meeting.id,
			exoticPools: meeting.exotic_pools,
		}));
		response.exoticPools = exoticPools;

		return response;
	}).catch((error) => {
		document.Sentry && document.Sentry.captureException(error);
		return error;
	});
};

/**
 * Fetch meetings of the day, the races for given meeting and selections for given race.
 *
 * Response metadata: 'selected_race' The race given as param.
 *
 * @param {number} meeting_id
 * @param {number} race_id
 * @return {object} Ajax response added with list of meeting ids.
 */
export const fetchMeetingsAndRacesWithSelectionsForRace = (meeting_id, race_id) => (dispatch) => {
	return get('combined/meetings/races/selections', {
		params: { meeting_id, race_id },
	}).then((response) => {
		let normalizedMeetings = normalizeMeetings(response.data.data);
		dispatch(mergeEntities(normalizedMeetings.entities));
		response.meetingIds = normalizedMeetings.result;

		let exoticPools = response.data.data.map((meeting) => ({
			meetingId: meeting.id,
			exoticPools: meeting.exotic_pools,
		}));
		response.exoticPools = exoticPools;
		return response;
	}).catch((error) => {
		document.Sentry && document.Sentry.captureException(error);
		return error;
	});
};

/**
 * Fetch meeting and its races and the selections for next race.
 *
 * Response metadata: 'selected_race' Next race to jump in this meeting.
 *
 * @param {number} meeting_id
 * @param {number} race_id
 */
export const fetchMeetingWithNextRaceSelections = (meeting_id, race_id) => (dispatch) => {
	return get('combined/meeting/races/selections', {
		params: { meeting_id, race_id },
	}).then((response) => {
		dispatch(mergeEntities(normalizeMeetings([response.data.data]).entities));
		return response;
	}).catch((error) => {
		document.Sentry && document.Sentry.captureException(error);
		return error;
	});
};

/**
 * Fetch  meeting and its races and the selections for bet builder.
 * @HW 06June2020
 * @param {*} meeting_id
 */
export const fetchBetBulderMeetingWithRaceSelections = (meeting_id) => (dispatch) => {
	return get('combined/meetings/bet-builder', {
		params: { meeting_id },
	}).then((response) => {
		dispatch(mergeEntities(normalizeMeetings([response.data.data]).entities));
		return response;
	}).catch((error) => {
		document.Sentry && document.Sentry.captureException(error);
		return error;
	});
};
