import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, ui, Button, CustomBorder, Text, TitleBlock, application } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

import Receipt from '../../Transactions/Receipt/Receipt';
import CurrencyNameDisplay from '../../Application/Currency/CurrencyNameDisplay/CurrencyNameDisplay';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

const StyledDepositReceipt__InformationBody = styled('div')(
	(props) => css`
		label: DepositReceipt__InformationBody;

		position: relative;
		background: ${ui(props).color_3};
		padding: 0 ${spacings(props).cozy}px ${spacings(props).cozy}px;
	`,
);

const StyledDepositReceipt__BalanceHeader = styled(TitleBlock)(
	(props) => css`
		label: DepositReceipt__BalanceHeader;

		border-bottom: 1px solid ${ui(props).color_4};
		padding: ${spacings(props).cozy}px;
	`,
);

const StyledDepositReceipt__SuccessBody = styled('div')(
	(props) => css`
		label: DepositReceipt__SuccessBody;

		padding: ${spacings(props).comfortable}px 0;
	`,
);

const StyledDepositReceipt__ExtraInfo = styled('div')(
	(props) => css`
		label: DepositReceipt__ExtraInfo;

		margin-top: ${spacings(props).cozy}px;
	`,
);

const StyledDepositReceipt__Button = styled(Button)(
	(props) => css`
		label: DepositReceipt__Button;
		border-radius: ${application(props).border_radius_2}px
		margin-top: ${spacings(props).cozy}px;
	`,
);

const DepositReceipt = ({
	t,
	account_balance,
	buttonText,
	className,
	backToBetting,
	extraInformation,
	receiptId,
	receiptMethod
}) => {
	const quickDepositReceiptClass = cx({
		[className]: className,
	});

	
	return (
		<div className={quickDepositReceiptClass}>
			<CustomBorder />
			<StyledDepositReceipt__InformationBody>
				<StyledDepositReceipt__BalanceHeader
					align="center"
					header={<CurrencyDisplay amount={account_balance} />}
					subheader={
						<span>
							{t('Available')} <CurrencyNameDisplay /> {t('Balance')}
						</span>
					}
				/>
				<StyledDepositReceipt__SuccessBody>
					<Text size="-1" strong>
						{t('DepositSuccessful')}
					</Text>
					<Text size="-2">{t('DepositReceipt__SuccessMessage')}</Text>
				</StyledDepositReceipt__SuccessBody>
				<Receipt type="Deposit" number={receiptId} method={receiptMethod} methodTitle={t('Receipt__DepositMethod')} />
				{extraInformation && (
					<StyledDepositReceipt__ExtraInfo>
						<Text size="-1" align="center">
							{extraInformation.text}
						</Text>
						<Text size="-1" align="center" strong>
							<CurrencyDisplay amount={extraInformation.amount} />
						</Text>
					</StyledDepositReceipt__ExtraInfo>
				)}
			</StyledDepositReceipt__InformationBody>
			<CustomBorder after />

			{/*shouldVerifyCard ? (  // commented by sonal becuse of we ignore verification
				<StyledDepositReceipt__Button type="primary" action={e => handleStartCardVerification()} size="large" block>
					{t('Verify')}
				</StyledDepositReceipt__Button>
			) :*/ (
				<StyledDepositReceipt__Button type="primary" action={backToBetting} size="large" block>
					{buttonText}
				</StyledDepositReceipt__Button>
			)}
		</div>
	);
};

DepositReceipt.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The user's remaining account balance */
	account_balance: PropTypes.number.isRequired,

	/** Action to leave the receipt */
	backToBetting: PropTypes.func.isRequired,

	/** Required for the Receipt component */
	receiptId: PropTypes.number.isRequired,

	/** Extra details for the receipt */
	receiptMethod: PropTypes.string.isRequired,

	/** Text for the button */
	buttonText: PropTypes.string.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Extra information for the user that will be displayed under the Deposit Method */
	extraInformation: PropTypes.shape({
		text: PropTypes.string,
		amount: PropTypes.number,
	}),
};

DepositReceipt.defaultProps = {
	className: '',
	extraInformation: null,
};

export default withNamespaces()(DepositReceipt);
