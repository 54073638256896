import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PoliForm from '../../components/features/Deposit/PoliForm/PoliForm';
import {
	clearDepositsStore,
	handlePoliDeposit,
	setDepositErrors,
	validatePromoCode,
} from '../../store/deposits/depositActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';
import { isTermsAndConditionsRequired } from '../../store/entities/selectors/UserSelectors';
import { DEPOSIT_DEFAULT, DEPOSIT_USER_ACCOUNT } from '../../store/deposits/depositsReducerNames';

const mapStateToProps = (state, ownProps) => {
	const sliceOfState = ownProps.renderOnPage ? state[DEPOSIT_USER_ACCOUNT] : state[DEPOSIT_DEFAULT];

	return {
		validPromoCode: sliceOfState.validPromoCode,
		isPromoCodeValid: sliceOfState.isPromoCodeValid,
		depositErrors: sliceOfState.depositErrors,
		termsAndConditionsRequired: isTermsAndConditionsRequired(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const reducerName = ownProps.renderOnPage ? DEPOSIT_USER_ACCOUNT : DEPOSIT_DEFAULT;

	return {
		clearDepositErrors: () => dispatch(setDepositErrors(reducerName)),
		clearDepositsStore: () => dispatch(clearDepositsStore(reducerName)),
		onCancel: ownProps.onCancel
			? () => {
					dispatch(trackGaEvent('POLi Deposit', 'Click', 'Close'));
					ownProps.onCancel();
			  }
			: null,
		onDepositClick: (amount) => {
			dispatch(trackGaEvent('POLi Deposit', 'Submit', 'Deposit', amount));
			dispatch(handlePoliDeposit(reducerName, amount));
		},
		onValidatePromoCode: (promoCode) => dispatch(validatePromoCode(reducerName, promoCode)),
	};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(PoliForm);

Container.propTypes = {
	/** Cancel deposit */
	onCancel: PropTypes.func,
};

Container.defaultTypes = {
	onCancel: undefined,
};

export default Container;
