import { createAction } from '../../common/actions/actionHelpers';
import { routeTo, scrollToTop } from '../application/applicationActions';
import { USER_ACCOUNT_SET_SELECTED_MENU_ITEM, CLEAR_USER_ACCOUNT_PAGE, USER_ACCOUNT_V2_SET_SELECTED_MENU_ITEM } from './userAccountActionTypes';
import menuItems from '../../pages/UserAccount/UserAccountPage.data';
import {
	USER_ACCOUNT_PAGES,
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL,
	USER_ACCOUNT_VERIFY_CREDIT_CARDS,
} from '../../pages/UserAccount/UserAccountPageConstants';
import menuItemsV2 from '../../pages/UserAccountV2/UserAccountV2Page.data';

/**
 * Find the user account item that matches the url strings received and
 * Set the current user account sub-page
 *
 * @param subPage
 * @param filter
 */
export const setUserAccountPage = (subPage, filter) => (dispatch) => {
	// If there isn't a sub-page, set the current page to the base url
	let currentPage = menuItems[0];

	// If there is a sub-page, fetch the data
	if (subPage) {
		if (subPage === USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL) {
			currentPage = USER_ACCOUNT_VERIFY_CREDIT_CARDS;
		} else {
			currentPage = menuItems.find((menuItem) => menuItem.id === subPage);
			if (!currentPage) {
				const responsibleGamblingItems = menuItems.find(
					(menuItem) => menuItem.id === USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
				);
				currentPage = responsibleGamblingItems.items.find((item) => item.id === subPage);
			}
		}
	}

	dispatch(
		createAction(USER_ACCOUNT_SET_SELECTED_MENU_ITEM, {
			transactionsFilter: filter,
			selectedMenuItem: currentPage,
		}),
	);
};

/**
 * Calls the menu item's action and set the selected menu item into state
 *
 * @param item
 * @param navigate
 */
export const setSelectedMenuItem = (item, navigate) => (dispatch) => {
	// If the navigation item has an action, call the action
	if (item.action) {
		dispatch(item.action());
	} else if (item.route && navigate) {
		// Or else navigate to the item's route if needed
		dispatch(routeTo(item.route));
	}

	let transactionsFilter;
	if (USER_ACCOUNT_PAGES.indexOf(item.id) !== -1) {
		transactionsFilter = item.defaultUrlFragments || 'all';
	}

	dispatch(
		createAction(USER_ACCOUNT_SET_SELECTED_MENU_ITEM, {
			transactionsFilter: transactionsFilter,
			selectedMenuItem: item,
		}),
	);

	scrollToTop();
};

/**
 * Reset the user account state
 */
export const resetUserAccount = () => (dispatch) => {
	return dispatch(createAction(CLEAR_USER_ACCOUNT_PAGE));
};

/**
 * Set the selected transaction filter into state
 *
 * @param filter
 */
export const setTransactionFilter = (filter) => (dispatch) => {
	return dispatch(
		createAction(USER_ACCOUNT_SET_SELECTED_MENU_ITEM, {
			transactionsFilter: filter || 'bets',
		}),
	);
};


export const setUserAccountPageV2 = (subPage, filter) => (dispatch) => {
	// If there isn't a sub-page, set the current page to the base url
	let currentPage = menuItemsV2[0];

	// If there is a sub-page, fetch the data
	if (subPage) {
		currentPage = menuItemsV2.find((menuItem) => menuItem.id === subPage);
	}
	dispatch(
		createAction(USER_ACCOUNT_V2_SET_SELECTED_MENU_ITEM, {
			transactionsFilter: filter,
			selectedMenuItemV2: currentPage,
		}),
	);


};

export const setSelectedMenuItemV2 = (item, navigate) => (dispatch) => {

	// If the navigation item has an action, call the action
	if (item.action) {
		dispatch(item.action());
	} else if (item.route && navigate) {
		// Or else navigate to the item's route if needed
		dispatch(routeTo(item.route));
	};

	let transactionsFilter;
	if (menuItemsV2.find(item => item.id)) {
		transactionsFilter = item.defaultUrlFragments ? item.defaultUrlFragments : 'all';
	};

	dispatch(
		createAction(USER_ACCOUNT_V2_SET_SELECTED_MENU_ITEM, {
			transactionsFilter: transactionsFilter,
			selectedMenuItemV2: item,
		}),
	);

	scrollToTop();
};

