import { get } from '../common/Ajax';
import { createAction } from '../common/actions/actionHelpers';
import { UPDATE_ALL_LIMITS } from './limitsActionsTypes';
import { openModal } from './modal/modalActions';

/**
 * Get all the user's limits (deposit, loss and spend) and update the store
 */
export const getAllLimits = () => (dispatch) => {
	let daily_deposit_limit,
		weekly_deposit_limit,
		monthly_deposit_limit,
		daily_loss_limit,
		weekly_loss_limit,
		monthly_loss_limit,
		daily_spend_limit,
		weekly_spend_limit,
		monthly_spend_limit;

	// get all limits
	const promises = Promise.all([
		get('/user/deposit-limit?period=daily').then((response) => {
			let limit = response.data.data;
			daily_deposit_limit = {
				bet_limit: limit.deposit_limit,
				requested_bet_limit: limit.requested_deposit_limit,
				requested_date: limit.requested_date,
				days_remaining_to_update: limit.days_remaining_to_update,
				update_limit: limit.update_limit,
			};
			return daily_deposit_limit;
		}),
		get('/user/deposit-limit?period=weekly').then((response) => {
			let limit = response.data.data;
			weekly_deposit_limit = {
				bet_limit: limit.deposit_limit,
				requested_bet_limit: limit.requested_deposit_limit,
				requested_date: limit.requested_date,
				days_remaining_to_update: limit.days_remaining_to_update,
				update_limit: limit.update_limit,
			};
			return weekly_deposit_limit;
		}),
		get('/user/deposit-limit?period=monthly').then((response) => {
			let limit = response.data.data;
			monthly_deposit_limit = {
				bet_limit: limit.deposit_limit,
				requested_bet_limit: limit.requested_deposit_limit,
				requested_date: limit.requested_date,
				days_remaining_to_update: limit.days_remaining_to_update,
				update_limit: limit.update_limit,
			};
			return monthly_deposit_limit;
		}),
		get('/user/bet-limit?period=daily').then((response) => {
			daily_loss_limit = response.data.data;
			return daily_loss_limit;
		}),
		get('/user/bet-limit?period=weekly').then((response) => {
			weekly_loss_limit = response.data.data;
			return weekly_loss_limit;
		}),
		get('/user/bet-limit?period=monthly').then((response) => {
			monthly_loss_limit = response.data.data;
			return monthly_loss_limit;
		}),
		get('/user/spend-limit?period=daily').then((response) => {
			let limit = response.data.data;
			daily_spend_limit = {
				bet_limit: limit.spend_limit,
				requested_bet_limit: limit.requested_spend_limit,
				requested_date: limit.requested_date,
				days_remaining_to_update: limit.days_remaining_to_update,
				update_limit: limit.update_limit,
			};
			return daily_spend_limit;
		}),
		get('/user/spend-limit?period=weekly').then((response) => {
			let limit = response.data.data;
			weekly_spend_limit = {
				bet_limit: limit.spend_limit,
				requested_bet_limit: limit.requested_spend_limit,
				requested_date: limit.requested_date,
				days_remaining_to_update: limit.days_remaining_to_update,
				update_limit: limit.update_limit,
			};
			return weekly_spend_limit;
		}),
		get('/user/spend-limit?period=monthly').then((response) => {
			let limit = response.data.data;
			monthly_spend_limit = {
				bet_limit: limit.spend_limit,
				requested_bet_limit: limit.requested_spend_limit,
				requested_date: limit.requested_date,
				days_remaining_to_update: limit.days_remaining_to_update,
				update_limit: limit.update_limit,
			};
			return monthly_spend_limit;
		}),
	]);

	return promises.then(() => {
		let payload = {
			depositLimits: {
				daily_deposit_limit,
				weekly_deposit_limit,
				monthly_deposit_limit,
			},
			lossLimits: {
				daily_loss_limit,
				weekly_loss_limit,
				monthly_loss_limit,
			},
			spendLimits: {
				daily_spend_limit,
				weekly_spend_limit,
				monthly_spend_limit,
			},
		};

		return dispatch(createAction(UPDATE_ALL_LIMITS, payload));
	});
};

/**
 * Open the Limit Confirmation screen for each limit update that needs to be confirmed
 *
 * @param limits
 */
export const confirmNewLimit = (limits = {}) => (dispatch) => {
	Object.keys(limits).forEach((key) => {
		const limit = limits[key];

		Object.keys(limit).forEach((key) => {
			const period = limit[key];

			if (period.hasOwnProperty('update_limit') && period.update_limit === true) {
				// If a limit has update_limit as true, then open the modal for that specific limit and period
				return dispatch(
					openModal({
						id: 'UKLimitConfirmationContainer',
						Component: 'UKLimitConfirmationContainer',
						props: {
							bet_limit: period,
							period: key,
						},
					}),
				);
			}
		});
	});
};
