import { createAction } from '../../common/actions/actionHelpers';
import { batchActions } from 'redux-batched-actions';
import { slugify } from '../../legacy/core/format';
// Constants
import {
	 GRS_SPORTS_HOME_SET_FILTERS_BATCH,
	 GRS_SPORTS_HOME_SET_NTJ_IDS,
	 GRS_SPORTS_HOME_SET_SELECTED_BASE_COMPETITION,
	 GRS_SPORTS_HOME_SET_SELECTED_COMPETITION,
	 GRS_SPORTS_HOME_SET_SELECTED_EVENT,
	 GRS_SPORTS_HOME_SET_SELECTED_SPORT,
	 GRS_SPORTS_HOME_SET_SELECTED_MARKET,
	 GRS_SPORTS_SET_LOADING_EVENTS,

	
	SPORT_NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK, 
	GRS_NJ_HOME_SET_SHOWING_NTJ_SPORTS,
} from './SportsNextJumpHomeActionType';
// Functions
import {
	fetchNextToJumpSportsWithMarketsAndSelections,
} from '../entities/actions/SportsActions';
import { navigate, setBodyLoading } from '../application/applicationActions';
import {
	fetchNextToJumpSports_GRS,
} from '../entities/actions/NextJumpSportsActions';

/**
 * Load next to jump events to render /next-jump-sports home.
 *
 * @param setLoadingMask
 * @returns {function(*)}
 */
export const loadNJSportsHomeData = (setLoadingMask = false) => (dispatch) => {
	if (setLoadingMask) {
		dispatch(setBodyLoading(true));
	}

	return Promise.all([dispatch(fetchNextToJumpSports_GRS()), dispatch(fetchNextToJumpSportsWithMarketsAndSelections())])
		.then((responses) => {
			dispatch(setNJSportsHomeFilters(null, null, null, null, null));
			dispatch(setNtjSportsIds(responses[0]));
		})
		.finally(() => dispatch(setBodyLoading(false)));
};
/**
 * Navigates to the  sport next jump page
 * 
 * @HW make by hushani 20-01-2020
 */
export const navigateToSportNextJump= () => (dispatch) => {
	return dispatch(navigate('/next-jump-sports', { trigger: true }));
};

/**
 * Set in state list of showing Next to Jump Sports. This is needed
 * so we keep the entities in the right section and the selection
 * for the component declared in the sport slice oh the state.
 *
 * @return {Object}
 * @param ntjIds
 */
export const setShowingNTJSports = (ntjIds = []) => {
	return createAction(GRS_NJ_HOME_SET_SHOWING_NTJ_SPORTS, ntjIds);
};

/**
 * Set a loading mask for the events
 *
 * @param loading
 * @returns {Object}
 */
export const setLoadingEvents = (loading = false) => {
	return createAction(GRS_SPORTS_SET_LOADING_EVENTS, loading);
};
/**
 * Set selected sport id in state.
 *
 * @param {number} sportId
 * @param sportName
 * @returns {Object}
 */
export const setSelectedSport = (sportId = null, sportName = null) => {
	return createAction(GRS_SPORTS_HOME_SET_SELECTED_SPORT, {
		id: sportId,
		name: sportName ? slugify(sportName) : sportName,
	});
};

/**
 * Set selected base competition in state.
 *
 * @param {number} baseCompetitionId
 * @returns {Object}
 */
export const setSelectedBaseCompetition = (baseCompetitionId = null) => {
	return createAction(GRS_SPORTS_HOME_SET_SELECTED_BASE_COMPETITION, baseCompetitionId);
};

/**
 * Set selected competition id in state.
 * @param {number} competitionId
 * @returns {Object}
 */
export const setSelectedCompetition = (competitionId = null) => {
	return createAction(GRS_SPORTS_HOME_SET_SELECTED_COMPETITION, competitionId);
};

/**
 * Set selected event id in state.
 *
 * @param {number} eventId
 * @returns {Object}
 */
export const setSelectedEvent = (eventId) => {
	return createAction(GRS_SPORTS_HOME_SET_SELECTED_EVENT, eventId);
};


/**
 * Set selected market id in state.
 *
 * @param {number} marketId
 * @returns {Object}
 */
export const setSelectedMarket = (marketId) => {
	return createAction(GRS_SPORTS_HOME_SET_SELECTED_MARKET, marketId);
};

export const setContainerLoadingMask = (isLoading) => createAction(SPORT_NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK, isLoading);

/**
 *  Set state based on fetched data.
 *
 * @param {number} sportId
 * @param sportName
 * @param {number} baseCompetitionId
 * @param {number} competitionId
 * @param {number} eventId
 */
export const setNJSportsHomeFilters = (
	sportId = null,
	sportName = null,
	baseCompetitionId = null,
	competitionId = null,
	eventId = null,
) => (dispatch) => {
	let actions = [];
	actions.push(setSelectedSport(sportId, sportName));
	actions.push(setSelectedBaseCompetition(baseCompetitionId));
	actions.push(setSelectedCompetition(competitionId));
	actions.push(setSelectedEvent(eventId));

	dispatch(batchActions(actions, GRS_SPORTS_HOME_SET_FILTERS_BATCH));
};

export const setNtjSportsIds = (ids = []) => {
	return createAction(GRS_SPORTS_HOME_SET_NTJ_IDS, ids);
};