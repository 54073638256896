import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { typography, spacings, List, media } from '@tbh/ui-kit';

import {
	

	BET_BUILDER_LAST_POSITION_WIN,
	BET_BUILDER_LAST_POSITION_PLACE,
	BET_BUILDER_LAST_BIG_WIN_2,
    BET_BUILDER_LAST_BIG_WIN_5,
    BET_BUILDER_BEATEN_FAV_YES,
    BET_BUILDER_BEATEN_FAV_NO,
    BET_BUILDER_DISTANCE_WIN,
    BET_BUILDER_DISTANCE_PLACE,
    BET_BUILDER_COURSE_WIN,
    BET_BUILDER_COURSE_PLACE,
    BET_BUILDER_COURSE_DISTANCE_WIN,
    BET_BUILDER_COURSE_DISTANCE_PLACE,
    BET_BUILDER_RATE_TO_WIN_Y,
    BET_BUILDER_RATE_TO_WIN_N,
    BET_BUILDER_WET_WIN,
    BET_BUILDER_WET_PLACE,
    BET_BUILDER_STRIKE_RATE_16,
	BET_BUILDER_STRIKE_RATE_20,
	BET_BUILDER_JOCKEY_STRIKE_RATE_16,
	BET_BUILDER_JOCKEY_STRIKE_RATE_20,
    BET_BUILDER_JOCKEY_WON_HORSE_Y,
    BET_BUILDER_JOCKEY_WON_HORSE_N,
    BET_BUILDER_JOCKEY_SWITCH_Y,
    BET_BUILDER_JOCKEY_SWITCH_N,
    BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16,
    BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20
} from '../../../../../common/constants/Racing';
import BetBuilderFilterSelection from '../../BetBuilderFilterSelection/BetBuilderFilterSelection';

const StyledGBetBuilderFilter = styled(List)(
	(props) => css`
		label: BetBuilderFilter;

		color: ${typography(props).color_3};
		
     
        //padding: 10px;
        list-style: none;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
		${props.mode === 'horizontal'
			? css`
					// flex: 1 0 100%;
					display: flex;
					margin: ${spacings(props).tight}px;
					//padding: 10px;
			  `
			: css`
					display: block;
					// flex: 0 0 50px;
					overflow: hidden;
					margin-bottom: 0px;
			  `};
	`,

);

const StyledBetBuilderRaceCardFilter = styled('div')`
    label: BetBuilderRaceCardFilter;
      width: 100%;
      order: 4;

      ${media(
      css`
          order: 5;
        `,
      500,
    )};
`;




const BetBuilderRaceCardFilter = (props) => {
	const {  className, onFilterItemClick, mode, types,size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const isActive = (types = [], type) => {
		return types.includes(type);
	};

	return (
		<StyledGBetBuilderFilter className={componentClasses} mode={mode}>
			<StyledBetBuilderRaceCardFilter size={size}>
				<BetBuilderFilterSelection
					className={css`
					flex-flow: row wrap;
					`}
					handleSelectType={onFilterItemClick}
					size={size}
					activeLastStartWin={isActive(types, BET_BUILDER_LAST_POSITION_WIN)}
					activeLastStartPlace={isActive(types, BET_BUILDER_LAST_POSITION_PLACE)}
					activeLastBigWin2={isActive(types, BET_BUILDER_LAST_BIG_WIN_2)}
					activeLastBigWin5={isActive(types, BET_BUILDER_LAST_BIG_WIN_5)}
					activeBeatenFavY={isActive(types, BET_BUILDER_BEATEN_FAV_YES)}
					activeBeatenFavN={isActive(types, BET_BUILDER_BEATEN_FAV_NO)}
					activeDistanceWin={isActive(types, BET_BUILDER_DISTANCE_WIN)}
					activeDistancePlace={isActive(types, BET_BUILDER_DISTANCE_PLACE)}
					activeCourseWin={isActive(types, BET_BUILDER_COURSE_WIN)}
					activeCoursePlace={isActive(types, BET_BUILDER_COURSE_PLACE)}
					activeCourseDistanceWin={isActive(types, BET_BUILDER_COURSE_DISTANCE_WIN)}
					activeCourseDistancePlace={isActive(types, BET_BUILDER_COURSE_DISTANCE_PLACE)}
					activeRateWinYes={isActive(types, BET_BUILDER_RATE_TO_WIN_Y)}
					activeRateWinNo={isActive(types, BET_BUILDER_RATE_TO_WIN_N)}
					activeWetWin={isActive(types, BET_BUILDER_WET_WIN)}
					activeWetPlace={isActive(types, BET_BUILDER_WET_PLACE)}
					activeTrainerStrikeRate16={isActive(types, BET_BUILDER_STRIKE_RATE_16)}
					activeTrainerStrikeRate20={isActive(types, BET_BUILDER_STRIKE_RATE_20)}
					activeJockeyStrikeRate16={isActive(types, BET_BUILDER_JOCKEY_STRIKE_RATE_16)}
					activeJockeyStrikeRate20={isActive(types, BET_BUILDER_JOCKEY_STRIKE_RATE_20)}
					activeJockeyWonHorseYes={isActive(types, BET_BUILDER_JOCKEY_WON_HORSE_Y)}
					activeJockeyWonHorseNo={isActive(types, BET_BUILDER_JOCKEY_WON_HORSE_N)}
					activeJockeySwitchYes={isActive(types, BET_BUILDER_JOCKEY_SWITCH_Y)}
					activeJockeySwitchNo={isActive(types, BET_BUILDER_JOCKEY_SWITCH_N)}
					activeTrainerJockeyStrikeRate16={isActive(types, BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16)}
					activeTrainerJockeyStrikeRate20={isActive(types, BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20)}
				/>

			</StyledBetBuilderRaceCardFilter>
			
			
		</StyledGBetBuilderFilter>

		
	);
};

BetBuilderRaceCardFilter.propTypes = {
    /** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to handle when a filter is clicked on */
	onFilterItemClick: PropTypes.func.isRequired,

	/** Which mode should be rendered */
	mode: PropTypes.oneOf(['horizontal', 'vertical']),
	
    /** Extra classes */
	className: PropTypes.string,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	types: PropTypes.array,
};

BetBuilderRaceCardFilter.defaultProps = {
    className: null,
	mode: 'horizontal',
	types: [],
};

export default withNamespaces()(BetBuilderRaceCardFilter);