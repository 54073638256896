import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';

import UpdatePassword from '../../../components/features/UserAccount/UpdatePassword/UpdatePassword';
import PasswordResetContainer from '../../../containers/UserAccount/PasswordReset/PasswordResetContainer';
import { Text, HeaderBar, Notification, Panel } from '@tbh/ui-kit';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { confirmPasswordReset } from '../../../store/authentication/authenticationActions';
import { setPasswordResetSuccessful } from '../../../store/passwordReset/passwordResetActions';
import { routeTo, setBodyLoading } from '../../../store/application/applicationActions';

const StyledPasswordResetPage__PasswordResetRequest = styled(PasswordResetContainer)(
	(props) => css`
		label: PasswordResetPage__Item;

		margin: 0 auto;
		gap: ${spacings(props).cozy}px;
		max-width: 365px;
		padding: ${spacings(props).cozy}px;
	`,
);
const StyledPasswordResetPage__PasswordResetSuccess = StyledPasswordResetPage__PasswordResetRequest.withComponent(
	Panel,
);
const StyledPasswordResetPage__PasswordUpdate = StyledPasswordResetPage__PasswordResetRequest.withComponent(
	UpdatePassword,
);

const Container = styled('div')(
	(props) => css`
		label: PasswordResetPage__Container;

		margin: 0 auto;
		max-width: 100%;
		// padding: ${spacings(props).cozy}px;
		background-color: #fff;
		margin-bottom: ${spacings(props).cozy}px;
		border-radius: 4px;
	`
);

class PasswordResetPage extends Component {
	static propTypes = {
		/** Function to mark that the container has stopped loading - NOTE: automatically passed in by a wrapper */
		setContainerHasLoaded: PropTypes.func.isRequired,

		/** Action to update password */
		passwordReset: PropTypes.func.isRequired,

		/** Token used for resetting the password */
		token: PropTypes.string,

		/** Are we showing the request or confirmation form */
		showRequestForm: PropTypes.bool,

		/** Password reset has been completed successfully */
		passwordResetSuccessful: PropTypes.bool,
	};

	static defaultProps = {
		showRequestForm: true,
		token: null,
		passwordResetSuccessful: false,
	};

	componentDidMount() {
		this.props.setContainerHasLoaded();
	}

	render() {
		const { passwordReset, token, showRequestForm, passwordResetSuccessful } = this.props;

		return (
			<Container>
				<HeaderBar className={css`border-radius: 4px 4px 0px 0px;`} type="primary">{showRequestForm && 'Request '}Password Reset</HeaderBar>

				{passwordResetSuccessful ? (
					<StyledPasswordResetPage__PasswordResetSuccess padding={Panel.padding.SPACING_COZY}>
						<Notification transparent type="success" message={'Your password has been reset.'} />
						<Panel padding={Panel.padding.SPACING_COZY}>
							<Text align="center" size="-1">
								You can now login with your new password.
							</Text>
							<Text align="center" size="-2">
								You will be redirected to the home page shortly.
							</Text>
						</Panel>
					</StyledPasswordResetPage__PasswordResetSuccess>
				) : showRequestForm ? (
					<StyledPasswordResetPage__PasswordResetRequest />
				) : (
					<StyledPasswordResetPage__PasswordUpdate
						updatePassword={passwordReset}
						useEmailToResetPassword
						token={token}
					/>
				)}
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		token: state.passwordResetPage.token,
		showRequestForm: !state.passwordResetPage.token,
		passwordResetSuccessful: state.passwordResetPage.passwordResetSuccessful,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		passwordReset: (oldPassword, newPassword, newPasswordConfirmation, token) => {
			dispatch(trackGaEvent('Reset Password', 'Submit', 'Send Password'));
			return dispatch(confirmPasswordReset(oldPassword, newPassword, newPasswordConfirmation, token)).then(
				(response) => {
					if (response.data.success) {
						dispatch(setPasswordResetSuccessful(true));
					}

					setTimeout(() => {
						dispatch(routeTo('/welcome'));
					}, 1500);

					return response;
				},
			);
		},
		setContainerHasLoaded: () => dispatch(setBodyLoading(false)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PasswordResetPage);
