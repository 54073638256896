'use strict';
import {ADD_TRACKING_PIXEL} from './trackingPixelsActionTypes';

export const addTrackingPixel = (id, props = {}) => {
	return {
		type: ADD_TRACKING_PIXEL,
		payload: {
			id,
			...props
		}
	};
};