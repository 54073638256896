import { batchActions } from 'redux-batched-actions';
import { createAction } from '../../common/actions/actionHelpers';
import { buildRacingSelection, validateExotics, buildNJRacingSelection } from './betPromptActionHelpers';
import { denormalizeRaces } from '../entities/schemas/RaceSchema';
import { denormalizeNextToJumpRaces } from '../entities/schemas/NextToJumpRaceSchema';

import {
	BET_PROMPT_ADD_SELECTIONS,
	BET_PROMPT_CLEAR_ERRORS,
	BET_PROMPT_RESET,
	BET_PROMPT_RESET_TITLE,
	BET_PROMPT_SET_ERRORS,
	BET_PROMPT_UPDATE,
} from './betPromptActionTypes';
import {
	BET_TYPE_RACE,
	BET_TYPE_SPORT,
	BET_TYPE_EXOTIC,
	BET_TYPE_DERIVATIVE,
	PRODUCT_TYPE_STANDARD,
} from '../entities/constants/BetConstants';
import {
	RACING_BET_TYPE_TITLES,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_DAILY_DOUBLE,
} from '../../common/constants/Racing';
import { quaddiePosition } from '../../common/QuaddiePosition';
import { dailyDoublePosition } from '../../common/DailyDoublePosition';

/**
 * Add to betPrompt slice of store
 * TODO: Change Bet Prompt to not use an array for selections
 *
 * @param newBetSelection
 * @param meta
 * @returns {Object}
 */
export const addSelectionToBetPrompt = (newBetSelection, meta = {}) => {
	const betSelection = Array.isArray(newBetSelection) ? newBetSelection : [newBetSelection];
	return createAction(BET_PROMPT_ADD_SELECTIONS, betSelection, meta);
};

/**
 * Add to betPrompt slice of store
 *
 * @param newBetSelection
 * @returns {Object}
 */
export const addRacingSelectionToBetPrompt = (newBetSelection) => (dispatch) => {
	dispatch(
		batchActions([
			resetBetPrompt(),
			addSelectionToBetPrompt(newBetSelection, {
				racingBetType: newBetSelection.racingBetType,
				racingBetTypes: newBetSelection.racingBetTypes,
				selectionType: BET_TYPE_RACE,
			}),
		]),
	);

	return Promise.resolve();
};

/**
 * Adds a derivative selection ID to the bet prompt
 *
 * @param derivativeId
 * @param marketId
 */
export const formatAndAddDerivativeSelectionToBetPrompt = (derivativeId, marketId) => (dispatch) => {
	dispatch(
		batchActions([
			resetBetPrompt(),
			addSelectionToBetPrompt(derivativeId, {
				racingBetTypes: [],
				selectionType: BET_TYPE_DERIVATIVE,
				derivativeMarketId: marketId,
			}),
		]),
	);

	return Promise.resolve();
};

/**
 * Format data from denormalized input and fill bet prompt fill new data.
 *
 * @param id
 * @param race_id
 * @param meeting_id
 * @param product_id
 * @param bet_type
 * @param isFixed
 * @returns {Function}
 */
export const formatAndAddSingleSelectionToBetPrompt = (
	id,
	race_id,
	meeting_id,
	product_id,
	bet_type,
	isFixed = false,
) => (dispatch, getState) => {
	const raceEntity = denormalizeRaces(getState().entities, [race_id]);

	if (raceEntity.length) {
		const race = raceEntity[0];
		const builtSelection = dispatch(buildRacingSelection(race, id, product_id, bet_type, isFixed));

		//console.log(builtSelection);
		const selection = {
			id,
			race,
			race_id,
			race_status:race.status,
			meeting_id,
			product_id,
			bet_type,
			type: BET_TYPE_RACE,
			...builtSelection,
		};

		return dispatch(addRacingSelectionToBetPrompt(selection));
	}

	return Promise.reject(`Race [${race_id}] not found.`);
};

/**
 * Format data from denormalized input and fill bet prompt fill new data.
 * For Next jump races
 * @param id
 * @param race_id
 * @param meeting_id
 * @param product_id
 * @param bet_type
 * @param isFixed
 * @returns {Function}
 */
export const formatAndAddSingleSelectionToBetPromptNJ = (
	id,
	race_id,
	meeting_id,
	product_id,
	bet_type,
	isFixed = false,
) => (dispatch, getState) => {
	const raceEntity = denormalizeNextToJumpRaces(getState().entities, [race_id]);

	if (raceEntity.length) {
		const race = raceEntity[0];
		const builtSelection = dispatch(buildNJRacingSelection(race, id, product_id, bet_type, isFixed));
		const selection = {
			id,
			race,
			race_id,
			meeting_id,
			product_id,
			bet_type,
			type: BET_TYPE_RACE,
			...builtSelection,
		};

		return dispatch(addRacingSelectionToBetPrompt(selection));
	}

	return Promise.reject(`Race [${race_id}] not found.`);
};

/**
 * Builds array of exotic selections
 * Expected input for selectedCheckboxes':
 * {
 *      123: {2: true},
 *      345: {3: true, 4: true}
 * }
 *
 * @param race
 * @param meetingId
 * @param betType
 * @param boxed
 * @param selectedCheckboxes
 * @returns {{betSelections: Array, exoticDetails: {id: *, isBoxed: *, title: *, productId: *, race: string}}}
 */
export const formatExoticSelection = (race, meetingId, betType, boxed, selectedCheckboxes) => {
	const product = race.products.find((product) => product.bet_type === betType);
	/**
	 * Take all selection ids.
	 * This sections deals with how RaceSelectionsList prepares the data
	 */

	// check if selectedCheckboxes are all from the same position
	// if so, we can assume it is a boxed bet

	let isBoxed = boxed;
	if (!isBoxed) {
		const positions = Object.keys(selectedCheckboxes).map((selectionId) => {
			return Object.keys(selectedCheckboxes[selectionId]).map((position) => position);
		});
	
		isBoxed = positions.every((position) => position == 1);
	};


	const betSelections = Array.isArray(selectedCheckboxes)
		? selectedCheckboxes.map((selection) => ({ ...selection, race_id: race.id, meeting_id: meetingId }))
		: Object.keys(selectedCheckboxes)
				.reduce((acc, selectionId) => {
					const selection = race.selections.find((sel) => sel.id === parseInt(selectionId));
					//console.log(selectedCheckboxes[selectionId]);
					// Iterate through existing positions
					Object.keys(selectedCheckboxes[selectionId]).forEach((position) => {
						if (selectedCheckboxes[selectionId][position]) {
							const positionValue = (position) => {
								switch (betType) {
									case RACING_BET_TYPE_QUADDIE:
										return quaddiePosition(race);
									case RACING_BET_TYPE_DAILY_DOUBLE:
										return dailyDoublePosition(race);
									default:
										return position;
								}
							};

							return acc.push({
								position: positionValue(isBoxed ? 0 : position).toString(),
								id: selectionId,
								number: selection.number,
								race_id: race.id,
								meeting_id: meetingId,
								product_id: product.product_id,
								bet_type: betType,
							});
						}
					});
					return acc;
				}, [])
				.sort((a, b) => a.position - b.position);
	// Changes to the object will affect reducer and container.
	const exoticDetails = {
		id: betType,
		isBoxed: isBoxed,
		title: RACING_BET_TYPE_TITLES[betType],
		productId: product.product_id,
		race_id: race.id,
		number: race.number,
		meeting_id: race.meeting_id,
		meeting_name: race.meeting_name,
	};

	return {
		betSelections,
		exoticDetails,
	};
};

/**
 * Builds array of exotic selections, and if valid, add to bet prompt.
 * Expected input for selectedCheckboxes':
 * {
 *      123: {2: true},
 *      345: {3: true, 4: true}
 * }
 *
 * @param raceId
 * @param meetingId
 * @param betType
 * @param boxed
 * @param selectedCheckboxes
 * @returns {Function}
 */
export const formatAndAddExoticSelectionToBetPrompt = (raceId, meetingId, betType, boxed, selectedCheckboxes) => (
	dispatch,
	getState,
) => {
	const raceEntity = denormalizeRaces(getState().entities, [raceId]);
	if (raceEntity.length) {
		const race = raceEntity[0];

		dispatch(resetBetPrompt());
		const { betSelections, exoticDetails } = formatExoticSelection(race, meetingId, betType, boxed, selectedCheckboxes);

		if (validateExotics(betSelections, betType, boxed)) {
			dispatch(
				addSelectionToBetPrompt(betSelections, {
					exoticDetails,
					selectionType: BET_TYPE_EXOTIC,
					bet_type: exoticDetails.id,
					product_id: exoticDetails.productId,
					race_id: raceId,
					race_status: race.status,
				}),
			);
			dispatch(addRace(PLACE_MULTI_BET, raceId));
			return Promise.resolve();
		}

		return Promise.reject('Could not validate exotic.');
	}

	return Promise.reject(`Race [${raceId}] not found.`);
};

/**
 * Add a sports selection to the bet prompt container
 *
 * @param id
 * @param market_id
 * @param odds
 * @param product_type
 * @param product_id
 * @returns {Function}
 */
export const formatAndAddSportSelectionToBetPrompt = (
	id,
	market_id,
	odds,
	product_id = null,
	product_type = PRODUCT_TYPE_STANDARD,
) => (dispatch) => {
	dispatch(
		batchActions([
			resetBetPrompt(),
			addSelectionToBetPrompt(
				{
					id,
					market_id,
					odds,
					product_id,
					product_type,
					type: BET_TYPE_SPORT,
				},
				{
					selectionType: BET_TYPE_SPORT,
				},
			),
		]),
	);

	return Promise.resolve();
};

/**
 * BetPrompt generic actions
 */

/**
 * Action to update the details of the bet prompt
 *
 * @param opts
 * @returns {Object}
 */
export const updateBetPromptDetails = (opts = {}) => {
	return createAction(BET_PROMPT_UPDATE, opts);
};

/**
 * Action to set the bet prompt error message
 *
 * @param error
 * @returns {Object}
 */
export const setBetPromptErrors = (error = []) => {
	return createAction(BET_PROMPT_SET_ERRORS, error);
};

/**
 * Action to clear the bet prompt error messages
 *
 * @returns {Object}
 */
export const clearBetPromptErrors = () => {
	return createAction(BET_PROMPT_CLEAR_ERRORS);
};

/**
 * Action to reset the bet prompt back to its original state
 *
 * @returns {Object}
 */
export const resetBetPrompt = () => {
	return createAction(BET_PROMPT_RESET);
};

/**
 * Action to reset the bet prompt title back to it's default value
 *
 * @returns {Object}
 */
export const resetBetPromptTitle = () => {
	return createAction(BET_PROMPT_RESET_TITLE);
};
