// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui } from '@tbh/ui-kit';

// Components
import { FlexText, Text } from '@tbh/ui-kit';

const StyledBetSummaryHeader = styled(FlexText)(
	(props) => css`
		label: BetSummaryHeader;

		background-color: ${ui(props).color_1};
		padding: ${spacings(props).cozy}px ${spacings(props).compact}px;
		border-bottom: 1px solid ${ui(props).color_3};
	`,
);

const BetSummaryHeader = (props) => {
	const { className, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledBetSummaryHeader className={componentClasses}>
			<Text strong>{t('Selections')}</Text>
			<Text strong>{t('Bet')}</Text>
		</StyledBetSummaryHeader>
	);
};

BetSummaryHeader.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

BetSummaryHeader.defaultProps = {
	className: '',
};

export default withNamespaces()(BetSummaryHeader);
