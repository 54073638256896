import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';

import { LineText, Text } from '@tbh/ui-kit';
import SportBettingAdd from '../../SportBettingAdd/SportBettingAdd';

import { formatOdds } from '../../../../../common/BetPlacement';

const StyledGRSNJMarketRow = styled('div')(
	(props) => css`
		label: GRSNJMarketRow;

		align-items: center;
        padding: ${spacings(props).tight}px;
        display: flex;
        flex-flow: row wrap;
	`,
	media(
		css`
			display: flex;
		`,
		470,
	),
);

const StyledGRSNJMarketRow__BettingAddItem = styled(LineText)(
	(props) => css`
		label: GRSNJMarketRow__BettingAddItem;

		flex: 1 1 auto;
		max-width: 30px;
		width: 30px;
		padding: ${spacings(props).tight}px;
	`,
);

const cssGRSNJMarketRow__BettingAddContainer = css`
	label: GRSNJMarketRow__BettingAddContainer;

	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const cssGRSNJMarketRow__SelectionContainer = css`
	label: GRSNJMarketRow__SelectionContainer;

	flex: 1 1 70%;
	display: flex;
	align-items: center;
	justify-content: space-around;
`;

const StyledGRSNJMarketRow__Selection = styled('div')(
	(props) => css`
		label: GRSNJMarketRow__Selection;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 2 1 100%;
		align-self: flex-end;

		${props.empty &&
			css`
				flex-grow: 1;
			`};

		${props.alignTop &&
			css`
				align-self: flex-end;
			`};
	`,
);

const cssGRSNJMarketRow__SelectionContainerTwo = css`
	label: GRSNJMarketRow__SelectionContainerTwo;

	flex: 1 1 70%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const LINE_POSITION_LEFT = 'left';
const LINE_POSITION_RIGHT = 'right';
const LINE_POSITION_INSIDE = 'inside';

const GRSNJMarketRow = ({
	className,
	bettingAvailable,
	marketName,
	selections,
	alignTop,
	addToMulti,
	addToSingle,
	productId,
	size,
}) => {
	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledGRSNJMarketRow className={componentClasses} size={size}>
			<div className={css`
					flex: 0 0 30%;
				`}>
				<Text
            align="center"
            size="-1"
            strong
        >
          {marketName == 'h2h' ? 'win odds' : marketName}
				</Text>
			</div>
			<div className={css`
					flex: 0 0 70%;
				`}>
        {
           selections.length == 3 ?
	<div className={cssGRSNJMarketRow__SelectionContainer}>
                    {selections.map((selection) => {
                        return (
	<StyledGRSNJMarketRow__Selection key={selection.id} alignTop={alignTop}>
		<div className={cssGRSNJMarketRow__BettingAddContainer}>

			<SportBettingAdd
          key={selection.id}
          size="1"
          metric={formatOdds(selection.price)}
          productId={productId}
          selectionId={selection.id}
          hasMulti={!!addToMulti}
          addToSingle={addToSingle}
          addToMulti={addToMulti}
          bettingAvailable={bettingAvailable}
      />

		</div>
	</StyledGRSNJMarketRow__Selection>
                        );
                    })}
	</div>
            :
	<div className={cssGRSNJMarketRow__SelectionContainerTwo}>
                    {selections.map((selection , x) => {
                        return (
                            <StyledGRSNJMarketRow__Selection key={selection.id} >

                                <div className={cssGRSNJMarketRow__BettingAddContainer}>

                                   {
                                       x == 0 ?
                                            (
                                            <div  className={css`
                                            margin-left: -35px;
                                            `}>
                                                <SportBettingAdd
                                                    key={selection.id}
                                                    size="1"
                                                    metric={formatOdds(selection.price)}
                                                    productId={productId}
                                                    selectionId={selection.id}
                                                    hasMulti={!!addToMulti}
                                                    addToSingle={addToSingle}
                                                    addToMulti={addToMulti}
                                                    bettingAvailable={bettingAvailable}

                                                />
                                            </div>)
              :
                (
                  <div  className={css`
                  margin-right: -35px;
                  `}>

                    <SportBettingAdd
                      key={selection.id}
                      size="1"
                      metric={formatOdds(selection.price)}
                      productId={productId}
                      selectionId={selection.id}
                      hasMulti={!!addToMulti}
                      addToSingle={addToSingle}
                      addToMulti={addToMulti}
                      bettingAvailable={bettingAvailable}

                    />
                  </div>)
                                   }

                                </div>
                            </StyledGRSNJMarketRow__Selection>
                        );
                    })}
                </div>
        }
			</div>

		</StyledGRSNJMarketRow>
	);
};

GRSNJMarketRow.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}),

	/** The name of the market */
	marketName: PropTypes.string.isRequired,

	/** Action to add the selection to the bet prompt */
	addToSingle: PropTypes.func.isRequired,

	/** The productId available for the event */
	productId: PropTypes.number,

	/** The list of selections in the market */
	selections: PropTypes.arrayOf(
		PropTypes.shape({
			/** The selection ID */
			id: PropTypes.any.isRequired,

			/** The selection price */
			price: PropTypes.number,

			/** The selection line */
			line: PropTypes.any,

			/** The selection name */
			name: PropTypes.string,

			/** The line position for the selection */
			linePosition: PropTypes.oneOf([LINE_POSITION_LEFT, LINE_POSITION_INSIDE, LINE_POSITION_RIGHT]),
		}),
	),

	/** Action to add the selection to the multi bet slip */
	addToMulti: PropTypes.func,

	/** Whether the selections should align to the top of the row or not */
	alignTop: PropTypes.bool,
	bettingAvailable: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

GRSNJMarketRow.defaultProps = {
	selections: [],
	productId: null,
	addToMulti: null,
	alignTop: false,
	bettingAvailable: true,
	className: '',
	size: undefined,
};

export default GRSNJMarketRow;
