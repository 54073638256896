import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, brand } from '@tbh/ui-kit';

import { CollapsiblePanel } from '@tbh/ui-kit';
import MarketItem from '../MarketItem/MarketItem';
import Svg from '../../../../ui-components/Svg/Svg';

import { MARKET_OPEN } from '../../../../../store/entities/constants/MarketContants';
import { GOAT_SPORTS_BUMP_ICON_SIZE } from '../../../../../common/constants/GoatProducts';

const StyledMarketGroupItem__Icon = styled(Svg)`
	label: MarketGroupItem__Icon;

	${(props) =>
		css`
			margin-right: ${spacings(props).compact}px;
			background: ${brand(props).color_1};
			border-radius: 50%;
		`};
`;

class MarketGroupItem extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The name of the market group */
		market_group_name: PropTypes.string.isRequired,

		/** The list of market items in the market group */
		marketItems: PropTypes.arrayOf(
			PropTypes.shape({
				/** The market item */
				id: PropTypes.number.isRequired,

				/** The market name */
				name: PropTypes.string.isRequired,

				/** The list of selections in the market */
				sportSelections: PropTypes.array.isRequired,

				/** The event ID for the market */
				event_id: PropTypes.number.isRequired,
			}),
		).isRequired,

		/** Action to add the selection to the bet prompt */
		addToSingle: PropTypes.func.isRequired,

		/** The products available for the event */
		products: PropTypes.arrayOf(
			PropTypes.shape({
				product_id: PropTypes.number.isRequired,
				product_type: PropTypes.string.isRequired,
			}),
		).isRequired,

		/** Action to fetch the rolled up price of a boosted fixed price */
		fetchFixedPriceRollup: PropTypes.func.isRequired,

		/** Action to fetch the amount of rolls for a price */
		fetchRollTablePrice: PropTypes.func.isRequired,

		/** Indicate whether bump is available */
		bumpAvailable: PropTypes.bool,

		/** Action to add the selection to the multi bet slip */
		addToMulti: PropTypes.func,

		/** Disable a market from being selected */
		disableMarket: PropTypes.bool,

		/** Whether or not the market group is open by default or not */
		marketGroupInitiallyOpen: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		marketGroupInitiallyOpen: false,
		selection_line: null,
		addToMulti: null,
		disableMarket: false,
		bumpAvailable: false,
	};

	state = {
		showMarkets: this.props.marketGroupInitiallyOpen,
	};

	/**
	 * Toggles the markets
	 */
	toggleMarkets = () => {
		this.setState({
			showMarkets: !this.state.showMarkets,
		});
	};

	render() {
		const {
			t,
			className,
			bumpAvailable,
			products,
			disableMarket,
			market_group_name,
			marketItems,
			addToSingle,
			addToMulti,
			fetchFixedPriceRollup,
			fetchRollTablePrice,
		} = this.props;

		const numberOfMarkets = (
			<span>{t('MarketGroupItem__NumberOfMarkets', { count: marketItems.length })}</span>
		);

		const componentClasses = cx({
			[className]: className,
		});

		const addTo = (callback, market) => {
			if (!callback) {
				return callback;
			}
			return (selection, productId) =>
				callback(market.event_id, market.sportSelections.find((s) => s.id === selection), market, productId);
		};

		const bumpAvailableForMarket = bumpAvailable && marketItems.some((market) => market.boost_available);

		return (
			<div className={componentClasses}>
				<CollapsiblePanel
					toggleCollapsibleContent={this.toggleMarkets}
					isCollapsed={!this.state.showMarkets}
					label={market_group_name}
					type="subheading"
					headerBarOptions={{
						meta: bumpAvailableForMarket ? (
							<span
								className={css`
									display: flex;
									align-items: center;
								`}
							>
								<StyledMarketGroupItem__Icon
									name="goat-bump"
									width={GOAT_SPORTS_BUMP_ICON_SIZE}
									height={GOAT_SPORTS_BUMP_ICON_SIZE}
								/>{' '}
								{numberOfMarkets}
							</span>
						) : (
							numberOfMarkets
						),
					}}
				>
					{marketItems.map((market) => {
						/**
						 * marketStatus assess the market status and let it be enabled only in case it gets 'o' or 'O'
						 * in 'market_status' field.
						 */
						const disabledMarket =
							disableMarket || (market.market_status && market.market_status.toLowerCase() !== MARKET_OPEN);

						return (
							<MarketItem
								key={market.id}
								market_name={market.name}
								selectionItems={market.sportSelections}
								disabled={disabledMarket}
								addToSingle={addTo(addToSingle, market)}
								addToMulti={addTo(addToMulti, market)}
								bumpAvailable={bumpAvailable}
								priceBump={bumpAvailable && market.boost_available}
								fetchFixedPriceRollup={fetchFixedPriceRollup}
								fetchRollTablePrice={fetchRollTablePrice}
								products={products}
							/>
						);
					})}
				</CollapsiblePanel>
			</div>
		);
	}
}

export default withNamespaces()(MarketGroupItem);
