// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

// Components
import { CustomBorder, Text } from '@tbh/ui-kit';

// Functions
import Format from '../../../../legacy/core/format';
import { TITLE_WITHDRAWAL } from '../../../../common/constants/Application';

// Styling
import {
	StyledWithdrawalReceipt__InformationBody,
	StyledWithdrawalReceipt__BalanceHeader,
	StyledWithdrawalReceipt__SuccessBody,
	StyledWithdrawalReceipt__Receipt,
	StyledWithdrawalReceipt__Button,
} from './WithdrawalReceipt.styled';

const WithdrawalReceipt = (props) => {
	const { t, account_balance, amount, onGoToWithdrawal, className, receiptId, receiptMethod } = props;

	const quickWithdrawalReceiptClass = cx({
		[className]: className,
	});

	return (
		<div className={quickWithdrawalReceiptClass}>
			<CustomBorder />
			<StyledWithdrawalReceipt__InformationBody>
				<StyledWithdrawalReceipt__BalanceHeader
					align="center"
					header={Format.centsAsDollars(account_balance)}
					subheader={t('WithdrawableBalance')}
				/>
				<StyledWithdrawalReceipt__SuccessBody>
					<Text size="-1" strong>
						{t('WithdrawalReceipt__RequestSuccessHeader')}
					</Text>
					<Text size="-2">{t('WithdrawalReceipt__RequestSuccess')}</Text>
				</StyledWithdrawalReceipt__SuccessBody>
				<StyledWithdrawalReceipt__Receipt
					type={TITLE_WITHDRAWAL}
					number={receiptId}
					method={receiptMethod}
					methodTitle={t('WithdrawalMethod')}
				/>
				<Text size="-1" align="center">
					{t('WeWillSendYou')}
				</Text>
				<Text size="-1" align="center" strong>
					{Format.centsAsDollars(amount)}
				</Text>
			</StyledWithdrawalReceipt__InformationBody>
			<CustomBorder after />

			<StyledWithdrawalReceipt__Button type="primary" size="medium" block action={onGoToWithdrawal}>
				{t('AwesomeThanks')}
			</StyledWithdrawalReceipt__Button>
		</div>
	);
};

WithdrawalReceipt.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The available withdrawable account balance */
	account_balance: PropTypes.number.isRequired,

	/** Amount in cents that was withdrew */
	amount: PropTypes.number.isRequired,
	onGoToWithdrawal: PropTypes.func.isRequired,

	/** Required for the Receipt component */
	receiptId: PropTypes.number.isRequired,
	receiptMethod: PropTypes.string.isRequired,
	className: PropTypes.string,
};

WithdrawalReceipt.defaultProps = {
	className: '',
};

export default withNamespaces()(WithdrawalReceipt);
