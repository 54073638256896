import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

import { Link, Text, Panel } from '@tbh/ui-kit';

// Context
import AclContext from '../../../../contexts/AclContext';

const PasswordResetMessage = (props) => {
	const { className, loginAction, signupAction, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<Panel padding={Panel.padding.SPACING_COZY} className={componentClasses}>
			<Text align="center" size="-1" paragraph>
				{t('PasswordResetMessage__Oops')}
				<Link action={loginAction} type={Link.types.SECONDARY} underline>
					{t('Login')}
				</Link>
			</Text>

			{signupAction && (
				<React.Fragment>
					<Text align="center" strong>
						{t('PasswordResetMessage__New')}
					</Text>
					<Text align="center" size="-1">
						<Link action={signupAction} type={Link.types.SECONDARY} underline>
							<AclContext.Consumer>
								{(acl) =>
									t('PasswordResetMessage__CreateAccount', {
										brandName: acl.brandDetails && acl.brandDetails.name ? acl.brandDetails.name : '',
									})
								}
							</AclContext.Consumer>
						</Link>
					</Text>
				</React.Fragment>
			)}
		</Panel>
	);
};

PasswordResetMessage.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action for the return to login form link */
	loginAction: PropTypes.func.isRequired,

	/** Action for the return to login form link */
	signupAction: PropTypes.func,

	/** Extra classes */
	className: PropTypes.string,
};

PasswordResetMessage.defaultProps = {
	className: '',
	signupAction: null,
};

export default withNamespaces()(PasswordResetMessage);
