export const DATA_PAGINATOR_SET_INITIAL_DATA = 'DATA_PAGINATOR_SET_INITIAL_DATA';
export const DATA_PAGINATOR_SET_DATA = 'DATA_PAGINATOR_SET_DATA';
export const DATA_PAGINATOR_SET_HEADERS = 'DATA_PAGINATOR_SET_HEADERS';
export const DATA_PAGINATOR_SET_LOADING = 'DATA_PAGINATOR_SET_LOADING';
export const DATA_PAGINATOR_SET_SIZE = 'DATA_PAGINATOR_SET_SIZE';
export const DATA_PAGINATOR_SET_PAGE = 'DATA_PAGINATOR_SET_PAGE';
export const DATA_PAGINATOR_SET_TOTAL = 'DATA_PAGINATOR_SET_TOTAL';
export const DATA_PAGINATOR_SET_TOTAL_PAGES = 'DATA_PAGINATOR_SET_TOTAL_PAGES';
export const DATA_PAGINATOR_FILTERED_DATA = 'DATA_PAGINATOR_FILTERED_DATA';
export const DATA_PAGINATTOR_PAGE_NAME = 'DATA_PAGINATTOR_PAGE_NAME';
