import { extend } from 'lodash-es';

import { mergeEntities } from '../../../common/actions/actionHelpers';
import { normalizeNavigationItems } from '../schemas/NavigationItemsSchema';
import { fetchVisibleSports } from './SportsActions';
import { navigate } from '../../application/applicationActions';

/**
 * Fetch all the visible sports and
 * Fetch the navigation items from the acl and merge them into state
 */
export const fetchNavigationItems = () => (dispatch, getState) => {
	dispatch(fetchVisibleSports());

	const state = getState();
	const aclNavigationItems = state.acl.navigation_items;

	// If there are navigation items in the acl, merge them into entities
	if (aclNavigationItems) {
		dispatch(mergeEntities(normalizeNavigationItems(aclNavigationItems).entities));
	}
};

/**
 *
 * @param item
 * @param opts
 */
export const onNavigationItemSelect = (item, opts) => (dispatch) => {
	// If there is an emit event, then trigger it
	if (item.event) {
		const eventOptions = item.eventOptions || {};

		const channel = eventOptions.channel ? App.Channel(eventOptions.channel) : App.channel;
		channel[eventOptions.method || 'request'](event, eventOptions);
	}

	// If there is a route, trigger it
	if (item.route && !item.preventRouting) {
		dispatch(navigate(item.route, extend({ trigger: true }, item.routeOptions), item.meta));
	}

	// This is legacy code and we are not sure if it's needed.
	// If it ever falls on the , please add a comment about the cases below.
	if (item.link) {
		window.open(item.link);
	}
	if (item.onClick) {
		item.onClick();
	}
	if (item.fn) {
		item.fn(item, opts);
	}
};
