import { createAction } from '../../common/actions/actionHelpers';
import { PASSWORD_RESET_SET_TOKEN, PASSWORD_RESET_SUCCESSFUL } from './passwordResetActionTypes';

/**
 * Set the selected promotion using an id
 *
 * @returns {{type, payload: *}}
 * @param token
 */
export const setPasswordResetToken = (token = null) => {
	return createAction(PASSWORD_RESET_SET_TOKEN, token);
};

/**
 * Set the selected promotion using an id
 *
 * @returns {{type, payload: *}}
 * @param resetSuccessful
 */
export const setPasswordResetSuccessful = (resetSuccessful = false) => {
	return createAction(PASSWORD_RESET_SUCCESSFUL, resetSuccessful);
};
