import React from 'react';
import styled, { css } from 'react-emotion';
import { connect } from 'react-redux';
import { StyledPageComponentContainer } from '../../components/features/Application/PageComponentContainer/PageComponentContainer.styled';
import SiteLinkMenuToggle from '../../components/SiteLinkMenuToggle/SiteLinkMenuToggle';
import { routeTo } from '../../store/application/applicationActions';
import { Button, Input, LoadingMask, Text, Textarea } from '@tbh/ui-kit';
import { post } from '../../common/Ajax';

const PageContainer = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
`;

const ContentWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 300px;
	padding: 16px;
	background-color: white;
	border-radius: 8px;

`;

const contactUsAction = async (name, email, message) => {
	return await post('/contact-us', { name, email, message });
};

const ContactUsForm = () => {
	const [form, setForm] = React.useState({
		name: '',
		email: '',
		message: '',
	});

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(null);
	const [success, setSuccess] = React.useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const { name, email, message } = form;

		if (!name || !email || !message) {
			setError('Please, fill all the fields.');
			return;
		}

		try {
			setError(null);
			setLoading(true);
			const response = await contactUsAction(name, email, message);
			setSuccess("We've received your message. We will contact you soon.");
		} catch (error) {
			let message = '';
			if (error.response && error.response.data && error.response.data.errors) {
				Object.keys(error.response.data.errors).forEach((key) => {
					message += error.response.data.errors[key] + '\n';
				});
			} else {
				message = 'Something went wrong. Please, try again later.';
			}
			setError(message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div
			className={css`
				display: flex;
				flex-direction: column;
				gap: 16px;
				padding: 16px;
				border-radius: 4px;
				max-width: 480px;
				width: 100%;
				// box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
				// border: 1px solid rgba(0, 0, 0, 0.1);
				background-color: white;
			`}
		>
			<div
				className={css`
					display: flex;
					flex-direction: column;
					gap: 4px;
				`}
			>
				<Text size="0" align="center" strong>
					Contact us
				</Text>
				<Text size="-2" align="center">
					We will be happy to help you.
				</Text>
			</div>
			{error && (
				<div
					className={css`
						border: 1px solid red;
						padding: 4px;
						border-radius: 4px;
					`}
				>
					<Text
						className={css`
							color: red;
						`}
						size="-2"
					>
						{error}
					</Text>
				</div>
			)}
			{success ? (
				<Text size="-1" align="center">
					{success}
				</Text>
			) : (
				<React.Fragment>
					<Input
						type="text"
						placeholder="Name"
						name="name"
						value={form.name}
						onChange={(name, value) => setForm({ ...form, name: value })}
					/>
					<Input
						type="email"
						placeholder="Email"
						value={form.email}
						onChange={(name, value) => setForm({ ...form, email: value })}
					/>
					<Textarea
						className={css`
							padding-left: 8px;
							border: 1px solid rgba(0, 0, 0, 0.1);
						`}
						placeholder="Message"
						rows={4}
						value={form.message}
						onChange={(e) => setForm({ ...form, message: e.target.value })}
					/>
					<Button
						action={handleSubmit}
						className={css`
							width: 100%;
						`}
						loading={loading}
						type={'secondary'}
					>
						Send{' '}
					</Button>
				</React.Fragment>
			)}
		</div>
	);
};

const LegalPage = ({ rules, loading, error, selectedRule }) => {
	if (error != null && rules.length == 0) {
		return (
			<div>
				<StyledPageComponentContainer>
					<ContentWrapper>
						<Text>A problem occurred while loading the page. Please, try again later.</Text>
						<Text size="-1">{error}</Text>
					</ContentWrapper>
				</StyledPageComponentContainer>
			</div>
		);
	}

	const isContactUs = window.location.pathname.includes('contact-us');

	return (
		<PageContainer>
			<LoadingMask loading={loading} />
			<StyledPageComponentContainer>
				<SiteLinkMenuToggle selectedPage={selectedRule ? selectedRule.catid : null} legalRules={rules} />
			</StyledPageComponentContainer>
			<StyledPageComponentContainer>
				{error && <Text>{error}</Text>}
				<ContentWrapper>
					{selectedRule && (
						<div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: selectedRule.fulltext }} />
					)}
					{!selectedRule && !isContactUs && rules.length > 0 && (
						<Text>Please, select a option available in the menu.</Text>
					)}
					{isContactUs && <ContactUsForm />}
				</ContentWrapper>
			</StyledPageComponentContainer>
		</PageContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.legalRules,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		navigateToPrivacyPolicy: () => {
			dispatch(routeTo('/' + PRIVACY_POLICY_BASE_URL));
			App.startSubApp(PRIVACY_POLICY_SUB_APP);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalPage);
