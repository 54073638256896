export const USER_ACCOUNT_MEDIA_SIZE = 768;

/**
 * User Account Routing
 */
export const USER_ACCOUNT_BASE_URL = 'user';
export const USER_ACCOUNT_SUB_APP = 'UserAccount';
export const USER_ACCOUNT_ACCOUNT_TRANSACTIONS_URL = 'account-transactions';

// Filters for Account Transactions
export const USER_ACCOUNT_ALL_TRANSACTIONS_URL = 'all';
export const USER_ACCOUNT_BETS_TRANSACTIONS_URL = 'bets';
export const USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL = 'tournaments';
export const USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL = 'bet-winning';
export const USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL = 'bet-losing';
export const USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL = 'bet-refunded';
export const USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL = 'deposits';
export const USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL = 'withdrawals';

export const USER_ACCOUNT_TODAY_TRANSACTIONS_URL = 'today';
export const USER_ACCOUNT_OPEN_UNTIL_TODAY_TRANSACTIONS_URL = 'open';

/**
 * User Account Nav Item
 */
export const USER_ACCOUNT_SUBMENU_ITEM = 'submenu-item';
export const USER_ACCOUNT_SUBMENU_HEADER = 'submenu-header';

/**
 * User Account Menu Item ID
 */
export const USER_ACCOUNT_SUBMENU_USER_HOME = 'user-home';
export const USER_ACCOUNT_SUBMENU_DEPOSIT = 'deposit';
export const USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS = 'account-transactions';
export const USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS = 'bonus-credit-transactions';
export const USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS = 'withdraw-funds';
export const USER_ACCOUNT_SUBMENU_WITHDRAWALS = 'withdrawals';
export const USER_ACCOUNT_SUBMENU_RESET_PASSWORD = 'reset-password';
export const USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS = 'default-bet-amounts';
export const USER_ACCOUNT_SUBMENU_TAKE_A_BREAK = 'take-a-break';
export const USER_ACCOUNT_SUBMENU_SELF_EXCLUSION = 'self-exclusion';
export const USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT = 'deposit-limits';
export const USER_ACCOUNT_SUBMENU_LOSS_LIMITS = 'loss-limits';
export const USER_ACCOUNT_SUBMENU_SPEND_LIMIT = 'spend-limits';
export const USER_ACCOUNT_SUBMENU_REALITY_CHECK = 'reality-check';
export const USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING = 'responsible-gambling';
export const USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD = 'green-id-form-upload';
export const USER_ACCOUNT_SUBMENU_RNS_ACCOUNT = 'rns-account';

/**
 * Verify Credit Cards
 */
export const USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL = 'verify-credit-cards';
export const USER_ACCOUNT_VERIFY_CREDIT_CARDS = {
	id: USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL,
	title: 'Verify Credit Cards',
	route: `/user/${USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL}`,
	trackingTitle: 'Deposit',
};

/**
 * Menu items that will render content in the page
 */
export const USER_ACCOUNT_PAGES = [
	USER_ACCOUNT_SUBMENU_USER_HOME,
	USER_ACCOUNT_SUBMENU_DEPOSIT,
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
	USER_ACCOUNT_SUBMENU_WITHDRAWALS,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
	USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
	USER_ACCOUNT_SUBMENU_TAKE_A_BREAK,
	USER_ACCOUNT_SUBMENU_SELF_EXCLUSION,
	USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT,
	USER_ACCOUNT_SUBMENU_LOSS_LIMITS,
	USER_ACCOUNT_SUBMENU_SPEND_LIMIT,
	USER_ACCOUNT_SUBMENU_REALITY_CHECK,
	USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL,
	USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD,
	USER_ACCOUNT_SUBMENU_RNS_ACCOUNT,
];

/**
 * User Account Menu Item icons
 */
export const USER_ACCOUNT_SUBMENU_USER_HOME_ICON = 'user';
export const USER_ACCOUNT_SUBMENU_DEPOSIT_ICON = 'wallet';
export const USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS_ICON = 'book';
export const USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS_ICON = 'bettabucks';
export const USER_ACCOUNT_SUBMENU_WITHDRAW_ICON = 'minus';
export const USER_ACCOUNT_SUBMENU_RESET_PASSWORD_ICON = 'lock';
export const USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD_ICON = 'lock';
export const USER_ACCOUNT_SUBMENU_RNS_ACCOUNT_ICON = 'lock';
export const USER_ACCOUNT_SUBMENU_SETTINGS_ICON = 'cog';
export const USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING_ICON = 'thumbs-up';

export const USER_ACCOUNT_MENU_ICONS = {
	[USER_ACCOUNT_SUBMENU_USER_HOME_ICON]: 'profile',
	[USER_ACCOUNT_SUBMENU_DEPOSIT_ICON]: 'deposit-funds',
	[USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS_ICON]: 'account-transactions',
	[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS_ICON]: 'bettabucks',
	[USER_ACCOUNT_SUBMENU_WITHDRAW_ICON]: 'withdraw',
	[USER_ACCOUNT_SUBMENU_RESET_PASSWORD_ICON]: 'lock',
	[USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD_ICON]: 'lock',
	[USER_ACCOUNT_SUBMENU_RNS_ACCOUNT_ICON]: 'lock',
	[USER_ACCOUNT_SUBMENU_SETTINGS_ICON]: 'settings',
	[USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING_ICON]: '18',
};

/**
 * Deposits Routing
 */
export const DEPOSITS_BASE_URL = 'deposits';

/**
 * Bet Transactions routing
 */
export const USER_ACCOUNT_BET_TRANSACTIONS = `/${USER_ACCOUNT_BASE_URL}/${USER_ACCOUNT_ACCOUNT_TRANSACTIONS_URL}/${USER_ACCOUNT_BETS_TRANSACTIONS_URL}`;
