// Translation strings
export default {
	// Common & general definitions
	'1st Up': '1. up',
	'2nd Up': '2. up',
	'Non Wet': 'Islak değil',
	'Not enough selections!': 'Yeterli seçim yok!',
	'Track Distance': 'Track süresi',
	Age: 'Yaş',
	BetSlip: 'Bahis kuponu',
	Career: 'Kariyer',
	Dam: 'Dam',
	Date: 'Tarih',
	Distance: 'Mesafe',
	DOB: 'DOB',
	Firm: 'Sert',
	Fixed: 'Fix',
	Thoroughbred: 'Gallop',
	Good: 'İyi',
	Greyhounds: 'Greyhound',
	Harness: 'Harness',
	Heavy: 'Ağır',
	Night: 'Gece',
	Owners: 'Sahipleri',
	Place: 'Yer',
	Race: 'Yarış',
	Results: 'Sonuçlar',
	Season: 'Sezon',
	Sex: 'Cinsiyet',
	Sire: 'Sire',
	Soft: 'Yumuşak',
	Synthetic: 'Sentetik',
	Track: 'Track',
	Wet: 'Islak',
	Win: 'Kazanan',

	// Component definitions
	'Racing__Status--abandoned': '中止に関する状況',
	'Racing__Status--closed': 'クローズ',
	'Racing__Status--interim': '暫定結果',
	'Racing__Status--paid': '支払い',
	'Racing__Status--selling': '売却',
	'SpeedmapLegend__Value--Backmarker': 'Backmarker',
	'SpeedmapLegend__Value--Leader': 'Lider',
	'SpeedmapLegend__Value--Midfield': 'Orta',
	'SpeedmapLegend__Value--Off-pace': 'Off-pace',
	MeetingList__NextRace: 'Bir sonraki yarış',
	RaceCard__EarlySpeed: 'Erken hız',
	RaceCard__Form: 'Form',
	RaceCard__Speedmaps: 'Hız haritası',
	RaceSelectionsList__Flucs: 'Fluc',
	RacingDateSelection__Tomorrow: 'Yarın',
	RacingDateSelection__Yesterday: 'Dün',
	RacingHomePage__Next: 'Birsonraki',
	RacingHomePage__Racing: 'Yarış',
	SpeedmapHeader__Barrier: 'Bariyer',
	SpeedmapHeader__Lead: 'Lead',
};
