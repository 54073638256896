import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { ui, brand, typography, spacings, Text } from '@tbh/ui-kit';

const StyledNextSportsItem = styled('a')`
	${(props) => css`
		label: NextSportsItem;

		display: flex;
		flex-direction: column;
		height: 100%;
		width: 180px;
		padding: ${spacings(props).tight}px;
		line-height: 1.1;
		text-decoration: none;
		color: ${typography(props).color_1};

		&:hover {
			background-color: ${ui(props).color_3};
			cursor: pointer;
		}
	`};
`;

const StyledNextSportsItem__IconAndTime = styled(Text)`
	${(props) => css`
		label: NextSportsItem__IconAndTime;

		display: flex;
		justify-content: center;
		color: ${brand(props).color_1};
	`};
`;

const cssNextSportItem__Icon = css`
	label: NextSportItem__Icon;

	// Can't use props or styled component here
	margin-right: 4px !important;
	font-weight: bold;
`;

/**
 * Calling this function creates a new function to be used as a callback
 * The returned function will prevent the default action from occurring, then call
 * the props onClick method
 *
 * @param props
 */
const handleClick = (props) => (e) => {
	e.preventDefault();
	props.onClick(props.id);
};

const NextSportItem = (props) => {
	const { className, timeToGo, eventName, competitionName, icon, link } = props;
	const blockClasses = cx({
		[className]: className,
	});

	const iconClasses = [cssNextSportItem__Icon];
	if (icon && icon.props && icon.props.className) {
		iconClasses.push(icon.props.className);
	}

	return (
		<StyledNextSportsItem href={link} className={blockClasses} onClick={handleClick(props)}>
			<StyledNextSportsItem__IconAndTime paragraph strong>
				{icon &&
					React.cloneElement(icon, {
						className: iconClasses.join(' '),
					})}
				<Text type="primary" size="-1" strong>
					{timeToGo}
				</Text>
			</StyledNextSportsItem__IconAndTime>
			<Text size="-1" align="center" strong>
				{eventName}
			</Text>
			<Text size="-1" align="center">
				{competitionName}
			</Text>
		</StyledNextSportsItem>
	);
};

NextSportItem.propTypes = {
	/** The ID of the next sport */
	id: PropTypes.number.isRequired,

	/** The name of the event for the sport */
	eventName: PropTypes.string.isRequired,

	/** The name of the competition for the sport */
	competitionName: PropTypes.string.isRequired,

	/** Time until the event starts */
	timeToGo: PropTypes.string.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Action for when the sport item is clicked on */
	onClick: PropTypes.func,

	/** Link for when the sport is clicked on */
	link: PropTypes.string,

	/** Icon for the sport/event */
	icon: PropTypes.node,
};

NextSportItem.defaultProps = {
	className: null,
	icon: null,
	link: '',
	onClick: () => {},
};

export default NextSportItem;
