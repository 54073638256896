import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';
import { greenidValid } from '../../../store/greenvalidate/greenValidateAction';
import userverify from '../../../components/features/UserAccount/UploadGreenIdFile/UploadGreenIdFile';
const StyledUpdatePassword__Wrapper = styled(userverify)(
  (props) => css`
		label: UpdatePassword__Wrapper;
		margin: 0 auto;
		padding: ${spacings(props).cozy}px;
	`,
  media(
    css`
			max-width: 365px;
		`,
    606,
  ),
);

const FileUploadGreenidContainer = (props) => {
  const {
    greenidValid,
    className,
    size,
    user_green_id,
    formDisabled,
    first_name,
    last_name,
    t,
    street,
    msisdn,
    green_id_overall_status,
    green_id_status,
    onChange,
    value,
    licncnumber,
    send_email,
    email,
  } = props;

  const componentClasses = cx({
    [className]: className,
  });

  return (
	<div className={componentClasses}>
		<StyledUpdatePassword__Wrapper
      first_name={first_name}
      last_name={last_name}
      street={street}
      msisdn={msisdn}
      green_id_status={green_id_status}
      greenidValid={greenidValid}
      size={size}
      email={email}
      send_email={send_email}
      user_green_id={user_green_id}
    />
	</div>
  );
};

FileUploadGreenidContainer.propTypes = {
  /** The size of the component - used for media query logic */
  size: PropTypes.shape({
    width: PropTypes.number,
  }).isRequired,

  t: PropTypes.func.isRequired,
  /** Form submission action*/
  greenidValid: PropTypes.func.isRequired,

  /** Extra classes */
  className: PropTypes.string,

};

FileUploadGreenidContainer.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  greenidValid: (L_number,L_type) => {
    return dispatch(greenidValid(L_number, L_type));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileUploadGreenidContainer);
