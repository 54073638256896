import styled from 'react-emotion';

export const StyledPageComponentContainer = styled.div`
	width: 100%;
	border-radius: 8px;	
`;

export const StyledSliderComponentContainer = styled.div`
	width: 100%;
	border-radius: 8px;
	position: relative;
`;

