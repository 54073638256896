// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

// Components
import { StyledCheckBox } from '../../../../UI/Checkbox';

const StyledAuthoriseVerification__Label = styled('label')`
	${(props) => css`
		label: AuthoriseVerification__Label;
		font-weight: bolder;
		margin-left: ${spacings(props).compact}px;
		cursor: pointer;
	`};
`;

const AuthoriseVerification = (props) => {
	const { t, className, onOpenVerifyInfo, brandName, isSaveCard,
		shouldVerifyCard, handleShouldShowVerification, handleShouldShowSaveCard } = props;
	const componentClasses = cx(
		css`
			label: AuthoriseVerification;

			display: inline-flex;
			align-items: center;
		`,
		{
			[className]: className,
		},
	);

	return (
		<div className={componentClasses}>
			{/*<StyledCheckBox
				name="authorise"
				value="authorise"
				checked={shouldVerifyCard}
				action={e => handleShouldShowVerification(!shouldVerifyCard)}
				inline />*/}
			{/* <RadioButton
				name="authorise"
				value="authorise"
				checked={shouldVerifyCard}
				onChange={e => handleShouldShowVerification(!shouldVerifyCard)}
				readOnly={false}
				inline /> */}
			{/*<StyledAuthoriseVerification__Label size="-2">
				{brandName
					? t('AuthoriseVerification__CreditCardWithBrand', { brandName })
					: t('AuthoriseVerification__CreditCard')}
				<Link type="primary" action={onOpenVerifyInfo} style={{textDecoration: 'underline'}}>
					{` ${t('HowDoesThisWork')}`}
				</Link>
			</StyledAuthoriseVerification__Label> */}
			<div style={{ display: 'flex', alignItems: 'flex-start' }}>
				<StyledCheckBox
					name="savecard"
					value="savecard"
					checked={isSaveCard}
					action={e => handleShouldShowSaveCard(!isSaveCard)}
					inline />
				<StyledAuthoriseVerification__Label htmlFor="savecard" onClick={() => handleShouldShowSaveCard(!isSaveCard)} >
					{t('Save__CreditCardWithBrand')}
				</StyledAuthoriseVerification__Label>
			</div>

		</div>
	);
};

AuthoriseVerification.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Trigger verification instructions */
	onOpenVerifyInfo: PropTypes.func.isRequired,

	/** Brand name */
	brandName: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

AuthoriseVerification.defaultProps = {
	brandName: null,
	className: '',
};

export default withNamespaces()(AuthoriseVerification);
