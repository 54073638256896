import { createNamedActions } from '../../common/actions/createNamedActions';
import * as betPlacementActions from './betPlacementActions';
import { PLACE_SINGLE_BET } from './betPlacementReducerNames';

const namedActions = createNamedActions(PLACE_SINGLE_BET, betPlacementActions);

export const {
	addBet,
	addExoticBet,
	addMultiBet,
	addSportBet,
	addSingleBet,
	addSelection,
	addExoticSelection,
	addFixedSelection,
	addSportSelection,
	addToteSelection,
	removeSelection,
	replaceSelection,
	update,
	setBoxedFlag,
	setOrigin,
	useBonusBet,
	reset,
	setError,
	validateSelections,
	validateExotic,
	placeBet,
	placeSportBet,
	placeRacingBet,
} = namedActions;
