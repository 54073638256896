import React from 'react';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings, ui } from '@tbh/ui-kit';

// Components
import { Text, CollapsibleContent, CollapsibleIcon, HeaderBar } from '@tbh/ui-kit';
import BetAmountAndPayout from '../BetAmountAndPayout/BetAmountAndPayout';
// import BetSelectionAndAmount from '../BetSelection/BetSelectionAndAmount/BetSelectionAndAmount';
import { centsAsDollars } from '../../../../legacy/core/format';
import { RACING_BET_TYPE_TITLES, RACING_QUADDIES_TITLE } from '../../../../common/constants/Racing';
import { StyledBetSelectionMulti__Clear, StyledBetSelectionMulti__ClearIcon } from '../MultiSRMList/MultiSRMItem';
import { BET_TYPE_QUADDIE } from '../../../../store/entities/constants/BetConstants';
import BetNotificationEventClosed from '../BetNotification/BetNotificationEventClosed';
// import { StyleBetCupomPreview } from '../BetSelection/BetSelectionMulti/BetSelectionMulti';
const StyledMultiQuaddieList__CloseIcon = styled(CollapsibleIcon)(
	(props) => css`
		label: MultiSRMList__CloseIcon;

		min-width: ${spacings(props).comfortable}px;
	`,
);
const StyledMultiQuaddieList = styled('div')(
	(props) => css`
		label: MultiSRMList;

		padding: 4px;
		display: flex;
		flex-direction: column;
		gap: ${spacings(props).compact}px;
	`,
);

const QuaddieList = (props) => {
	const {
		className,
		disabled,
		bet,
		quaddies,
		onStakeChange,
		onRemove,
		t,
		track,
		handleSelectedCupomBet,
		bonus_bet_enabled,
		handleBonusBetChange,
		selectedBet,
		handleSelectedBet,
		races,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const handleItemToggle = () => {
		setIsCollapsed(!isCollapsed);
	};

	const onMultiToggle = (srm, isMulti) => {};

	const [isCollapsed, setIsCollapsed] = React.useState(false);

	return (
		<div className={componentClasses}>
			<HeaderBar
				type="primary"
				action={handleItemToggle}
				className={css`
					cursor: pointer;
				`}
				aside={<StyledMultiQuaddieList__CloseIcon size="-3" isCollapsed={isCollapsed} />}
			>
				<Text type="alternate" strong>
					{RACING_QUADDIES_TITLE}
				</Text>
			</HeaderBar>
			<CollapsibleContent isCollapsed={isCollapsed}>
				<StyledMultiQuaddieList>
					{quaddies.map((item, i) => (
						<QuaddieItem
							betRaces={races}
							handleSelectedCupomBet={handleSelectedCupomBet}
							handleBonusBetChange={handleBonusBetChange}
							bonus_bet_enabled={bonus_bet_enabled}
							key={item.index}
							{...item}
							disabled={false}
							price={item.price}
							onRemove={() => onRemove(item)}
							onStakeChange={onStakeChange}
							onMultiToggle={onMultiToggle}
							track={track}
							quaddie={item}
							bet={bet}
							selectedBet={selectedBet}
							handleSelectedBet={handleSelectedBet}
							// cash_out_enabled={cash_out_enabled}
						/>
					))}
				</StyledMultiQuaddieList>
			</CollapsibleContent>
		</div>
	);
};

const StyledQuaddieItem = styled('div')(
	(props) => css`
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		border: 2px solid ${ui(props).color_4};
		border-radius: 4px;
		${props.selectedBet && 'border: 1px solid #660809!important;'}
	`,
);

const QuaddieTitle = styled(Text)(
	(props) => css`
		font-weight: 600;
		margin: 0;
		flex: 1 1 0%;
		text-transform: uppercase;
		font-style: normal;
		display: flex;
	`,
);

const QuaddieHeader = styled('div')(
	(props) => css`
		display: flex;
		border-bottom: 1px solid ${ui(props).color_4};
		padding: 12px;
	`,
);

const StyledQuaddieItemRaces = styled('div')(
	(props) => css`
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 4px 8px;
		p {
			display: flex;
			font-size: 11px;
			text-transform: none;
			font-style: normal;
			margin: 0;
		}
	`,
);

const QuaddieItem = ({
	id,
	combinations,
	onStakeChange,
	onRemove,
	stake,
	flexiAmount,
	user_bet_amount,
	price,
	bet_type,
	bet,
	betTypeLabel,
	cash_out_enabled,
	eventName,
	races,
	handleSelectedCupomBet,
	bonus_bet_enabled,
	free_credit_flag,
	handleBonusBetChange,
	quaddie,
	selectedBet,
	handleSelectedBet,
	betRaces,
}) => {
	const handleChangeStake = (name, value) => {
		onStakeChange(id, value);
	};

	const onBetClick = (s) => {
		let data = null;

		if (selectedBet && selectedBet.id == s.id && selectedBet.group == 'quaddies') return;

		if (!selectedBet || (selectedBet.id != s.id && selectedBet.group != 'quaddies')) {
			data = {
				...s,
				group: 'quaddies',
			};
		}

		handleSelectedBet(data);
	};

	let isClosed = false;

	for (let i = 0; i < betRaces.length; i++) {
		if (quaddie.races.find((r) => r.id == betRaces[i].id && betRaces[i].status != 'selling')) {
			isClosed = true;
			break;
		}
	}

	return (
		<StyledQuaddieItem
			key={id}
			onClick={() => onBetClick(quaddie)}
			selectedBet={selectedBet && selectedBet.id == id && selectedBet.group == 'quaddies'}
		>
			{isClosed && <BetNotificationEventClosed onClick={onRemove} />}
			{/* <QuaddieHeader>
				<QuaddieTitle size="-2">{RACING_BET_TYPE_TITLES[bet_type]}</QuaddieTitle>
	
				<StyledBetSelectionMulti__Clear>
					<StyledBetSelectionMulti__ClearIcon size="-3" icon="close" action={onRemove} />
				</StyledBetSelectionMulti__Clear>
			</QuaddieHeader> */}

			<div style={{ opacity: isClosed ? 0.3 : 1 }}>
				<StyledQuaddieItemRaces>
					<div>
						<div
							className={css`
								display: flex;
								justify-content: space-between;
								item-align: center;
							`}
						>
							<Text size="-3" type="secondary">
								{eventName}
							</Text>
							<StyledBetSelectionMulti__Clear>
								<StyledBetSelectionMulti__ClearIcon size="-3" icon="close" action={onRemove} />
							</StyledBetSelectionMulti__Clear>
						</div>
						<Text size="-2" strong>
							{RACING_BET_TYPE_TITLES[bet_type]}
						</Text>
					</div>
					{races.map((r, i) => (
						<Text size="-3" key={r.id}>
							<span
								className={css`
									font-weight: 600;
									flex: 0 0 auto;
								`}
							>
								Leg {i + 1} (R{r.number}):
							</span>
							<span
								className={css`
									flex: 0 0 auto;
								`}
							>
								&nbsp;
							</span>
							<span
								className={css`
									margin-left: 5px;
									font-weight: 400;
									flex: 1 1 auto;
								`}
							>
								{r.selections.map((s) => s.number).join(' , ')}
							</span>
						</Text>
					))}
				</StyledQuaddieItemRaces>

				<BetAmountAndPayout
					className={css`
						padding: 8px;
						padding-top: 0;
						flex: 1;
					`}
					free_credit_flag={free_credit_flag}
					handleBonusBetChange={() => handleBonusBetChange(id, BET_TYPE_QUADDIE)}
					bonus_bet_enabled={bonus_bet_enabled}
					onInputChange={handleChangeStake}
					stake={stake ? stake : user_bet_amount}
					price={price}
					racingBetType={bet_type}
					placeHolder={centsAsDollars(bet, true)}
					betTypeLabel={betTypeLabel}
					showFlexiBox={true}
					isMultiLegValid={true}
					flexiAmount={flexiAmount}
					exoticType={bet_type}
					displayFlexed
					cash_out_enabled={cash_out_enabled}
					combinations={combinations}
				/>
			</div>
		</StyledQuaddieItem>
	);
};

export default withNamespaces()(QuaddieList);
