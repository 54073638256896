import { denormalize, normalize } from 'normalizr';
import { ticketsSchema } from './Entities';

/**
 * Convert normalized tournament entities back to a structure similar to the API response
 *
 * @param entities
 */
const denormalizeTickets = (entities) => {
	return denormalize(Object.keys(entities.tickets), ticketsSchema, entities);
};

/**
 * Normalize the tournament structure to suit JS Applications
 *
 * @param response
 */
const normalizeTickets = (response) => {
	return normalize(response, ticketsSchema);
};

module.exports = {
	denormalizeTickets,
	normalizeTickets,
};
