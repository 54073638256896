export const ROOT_ELEMENT_ID = 'root';
export const MAIN_CONTENT = 'main-content';
export const SCROLL_TOP_ID = 'scrollTo';
export const DEFAULT_LANGUAGE = 'en';

export const ALLOWED_LANGUAGES = {
	en: true,
	pl: true,
	tr: true,
	ja: true,
	ru: true,
	'en-au': true,
};

export const MAIN_APPLICAION_BACKGROUND_COLOR = '#081224';

export const MAIN_FONT_FAMILY = '"Poppins",  sans-serif';

export const FONT_SIZE_9 = '9px;';
export const FONT_SIZE_10 = '10px;';
export const FONT_SIZE_12 = '12px;';
export const FONT_SIZE_15 = '15px;';
export const FONT_SIZE_18 = '18px;';
export const FONT_SIZE_20 = '20px;';

export const moreThan2minColor = '#379b37';
export const lessThan2MinColor = '#c72128';

export const paidColor = 'rgb(93, 151, 251)';

export const BET_SLIP_MAX_WIDTH = '300px';

// COLOR SCHEME
export const COLORS = {
	white: '#ffffff',
	primary: '#ff0101',
	secondary: '#091325',
	tertiary: '#27303f',
	urgent: '#081324',
	gray: {
		100: '#f4f4f4',
		200: '#e3e3e3',
		300: '#dddddd',
		400: '#797e87',
		500: '#a1a5ab',
		600: '#262f3e',
	}
};

// FONT WEIGHTS
export const WEIGHTS = {
	normal: 400,
	medium: 600,
	bold: 600,
	exBold: 700,
};

// APP MAIN FONT FAMILY
export const FAMILYS = {

};