// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import { withNamespaces } from 'react-i18next';

// Components
import BannersCarouselItems from './BannersCarouselItems/BannersCarouselItems';

import { LoadingSpanner } from '../LoadingSpinner/LoadingSpinner';
import { cssBannersCarousel, StyledBanner__Carousel } from './BannersCarousel.styled';
import { handleTaglines } from '../../../../store/acl/aclActions';
import { StyleBannerContent } from './BannersCarouselItems/BannersCarouselItems.style';
import { Text } from '@tbh/ui-kit';

/**
 * This component is used in the banner to show the image
 */
const BannersCarousel = (props) => {
	const { t, className, loading, Items, onGoToRace, size, swiperConfig, taglines } = props;

	const componentClasses = cx(cssBannersCarousel, {
		[className]: className,
	});

	const items = [...Items];

	if (taglines && taglines.contact) {
		items.push({
			content: handleTaglines(taglines, 'banner'),
			contact: taglines.contact,
		});
	}

	return (
		<div className={componentClasses}>
			<LoadingSpanner style={{ display: loading ? 'block' : 'none' }} />
			{
				// Items && Items.length > 0  &&
				// <StyledBanner__Carousel
				//     paginationClassName={cssBanner__CarouselPagination}
				//     noLinkItems
				//     pagination
				// >
				<StyledBanner__Carousel size={size} settings={swiperConfig} nextButton prevButton buttonStyle="1">
					{items.map((item) => {
						if (item.link) {
							return (
								<a
									key={item.link + item.image}
									href={item.link}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										window.open(item.link, '_blank');
									}}
								>
									<BannersCarouselItems description={null} image={item.image} size={size} />
								</a>
							);
							// return null;
						}

						if (item.content) {
							return (
								<StyleBannerContent key={item.content}>
									<Text size="2" align="center" strong>
										{item.content}
									</Text>
									<Text size="0" align="center">
										<div dangerouslySetInnerHTML={{ __html: taglines.contact }} />
									</Text>
								</StyleBannerContent>
							);
						}

						return <BannersCarouselItems key={item} description={null} image={item.image} size={size} />;
						// return null;
					})}

					{/* {taglines && taglines.contact ? (
						<div>
							<p
								className={css`
									color: black;
									font-weight: bold;
									font-size: 15px;
									text-align: center;
									padding-bottom: 0;
								`}
							>
								{handleTaglines(taglines, 'banner')}
							</p>

							{taglines.contact}
						</div>
					):null} */}
				</StyledBanner__Carousel>
			}
		</div>
	);
};
BannersCarousel.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** If the data is loading or not */
	loading: PropTypes.bool,

	/** Next to  items */
	Items: PropTypes.array,

	/** Navigate to the race page */
	onGoToRace: PropTypes.func,

	/** Configuration for the swiper */
	swiperConfig: PropTypes.object,
};

BannersCarousel.defaultProps = {
	className: '',
	loading: false,
	Items: [],
	onGoToRace: null,
	slidesPerView: 1,
	breakpoints: {
		320: {
			slidesPerView: 1,
			spaceBetween: 10,
		},

		480: {
			slidesPerView: 1,
			spaceBetween: 10,
		},
	},

	swiperConfig: {
		// freeMode: true,
		// freeModeMomentumBounce: false,
		freeModeMomentumRatio: 0.5,
		paginationClickable: true,
		// slidesPerView: 'auto',
		centeredSlides: false,
		// autoplay: {
		// 	delay: 15000,
		// 	disableOnInteraction: false,
		// },
		speed: 1000,
	},
};

export default withNamespaces()(BannersCarousel);
