// Features
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { ui, spacings, typography } from '@tbh/ui-kit';

const StyledAutoCompleteAddressItem = styled('div')`
	${(props) => css`
		label: AutoCompleteAddressItem;

		font-size: ${typography(props)['size_-2']};
		padding: ${spacings(props).tight}px;

		&:hover {
			background-color: ${ui(props).color_4};
			cursor: pointer;
		}

		${props.active &&
			css`
				background-color: ${ui(props).color_info};
				color: ${typography(props).color_1_alternate};

				&:hover {
					background-color: ${ui(props).color_info};
				}
			`};
	`};
`;

const AutoCompleteAddressItem = (props) => {
	const { active, id, onClick, text, className } = props;

	const handleOnClick = () => {
		onClick(id);
	};

	const addressClassNames = cx({
		[className]: className,
	});
	return (
		<StyledAutoCompleteAddressItem className={addressClassNames} onClick={handleOnClick} active={active}>
			{text}
		</StyledAutoCompleteAddressItem>
	);
};

AutoCompleteAddressItem.propTypes = {
	/** ID of the address item */
	id: PropTypes.string.isRequired,

	/** Action to fire on item click */
	onClick: PropTypes.func.isRequired,

	/** Text for the address item */
	text: PropTypes.string.isRequired,

	/** Whether or not the item is active */
	active: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

AutoCompleteAddressItem.defaultProps = {
	active: false,
	className: '',
};

export default AutoCompleteAddressItem;
