import {
	USER_ACCOUNT_SUBMENU_HEADER,
	USER_ACCOUNT_SUBMENU_ITEM,
	USER_ACCOUNT_SUBMENU_USER_HOME,
	USER_ACCOUNT_SUBMENU_DEPOSIT,
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
	USER_ACCOUNT_SUBMENU_WITHDRAWALS,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
	USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
	USER_ACCOUNT_SUBMENU_TAKE_A_BREAK,
	USER_ACCOUNT_SUBMENU_SELF_EXCLUSION,
	USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT,
	USER_ACCOUNT_SUBMENU_LOSS_LIMITS,
	USER_ACCOUNT_SUBMENU_SPEND_LIMIT,
	USER_ACCOUNT_SUBMENU_REALITY_CHECK,
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_SUBMENU_USER_HOME_ICON,
	USER_ACCOUNT_SUBMENU_DEPOSIT_ICON,
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS_ICON,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS_ICON,
	USER_ACCOUNT_SUBMENU_WITHDRAW_ICON,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD_ICON,
	USER_ACCOUNT_SUBMENU_SETTINGS_ICON,
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING_ICON,
	USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD,
	USER_ACCOUNT_SUBMENU_RNS_ACCOUNT,
	USER_ACCOUNT_SUBMENU_RNS_ACCOUNT_ICON,
} from './UserAccountPageConstants';

export default [
	{
		id: USER_ACCOUNT_SUBMENU_USER_HOME,
		title: 'My Profile',
		route: '/user',
		icon: USER_ACCOUNT_SUBMENU_USER_HOME_ICON,
		trackingTitle: 'My Profile',
	},
	{
		id: USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD,
		title: 'Green Id Form Upload',
		route: `/user/${USER_ACCOUNT_SUBMENU_GREEN_ID_FORM_UPLOAD}`,
		icon: USER_ACCOUNT_SUBMENU_SETTINGS_ICON,
		trackingTitle: 'Green Id Form Upload',
	},
	{
		id: USER_ACCOUNT_SUBMENU_RNS_ACCOUNT,
		title: 'RNS Account',
		route: `/user/${USER_ACCOUNT_SUBMENU_RNS_ACCOUNT}`,
		icon: USER_ACCOUNT_SUBMENU_RNS_ACCOUNT_ICON,
		trackingTitle: 'RNS Account',
	},
	{
		id: USER_ACCOUNT_SUBMENU_DEPOSIT,
		title: 'Deposit Funds',
		mobileTitle: 'Make a Deposit',
		route: `/user/${USER_ACCOUNT_SUBMENU_DEPOSIT}`,
		icon: USER_ACCOUNT_SUBMENU_DEPOSIT_ICON,
		trackingTitle: 'Deposit',
	},
	{
		id: USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
		title: 'Account Transactions',
		mobileTitle: 'Account Statement',
		route: `/user/${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}`,
		defaultUrlFragments: 'all',
		icon: USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS_ICON,
		trackingTitle: 'Account Transactions',
	},
	{
		id: USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
		title: 'Bonus Bet Transactions',
		mobileTitle: 'Bonus Statement',
		route: `/user/${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}`,
		defaultUrlFragments: 'all',
		icon: USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS_ICON,
		trackingTitle: 'Bonus Bet Transactions',
	},
	{
		id: USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
		title: 'Withdraw Funds',
		route: `/user/${USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS}`,
		icon: USER_ACCOUNT_SUBMENU_WITHDRAW_ICON,
		trackingTitle: 'Withdraw',
	},
	{
		id: USER_ACCOUNT_SUBMENU_WITHDRAWALS,
		title: 'Cancel Withdrawal',
		route: `/user/${USER_ACCOUNT_SUBMENU_WITHDRAWALS}`,
		icon: USER_ACCOUNT_SUBMENU_WITHDRAW_ICON,
		trackingTitle: 'Cancel Withdrawal',
	},
	{
		id: USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
		title: 'Reset Password',
		route: `/user/${USER_ACCOUNT_SUBMENU_RESET_PASSWORD}`,
		icon: USER_ACCOUNT_SUBMENU_RESET_PASSWORD_ICON,
		trackingTitle: 'Reset Password',
	},
	{
		id: USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
		title: 'Default Bet Amounts',
		route: `/user/${USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS}`,
		icon: USER_ACCOUNT_SUBMENU_SETTINGS_ICON,
		trackingTitle: 'DefaultBetAmounts',
	},
	{
		id: USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
		title: 'Responsible Gambling',
		type: USER_ACCOUNT_SUBMENU_HEADER,
		icon: USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING_ICON,
		route: '',
		items: [
			{
				id: USER_ACCOUNT_SUBMENU_TAKE_A_BREAK,
				title: 'Take a Break',
				type: USER_ACCOUNT_SUBMENU_ITEM,
				route: `/user/${USER_ACCOUNT_SUBMENU_TAKE_A_BREAK}`,
				trackingTitle: 'TakeABreak',
			},
			{
				id: USER_ACCOUNT_SUBMENU_SELF_EXCLUSION,
				title: 'Self Exclusion (Permanent)',
				type: USER_ACCOUNT_SUBMENU_ITEM,
				route: `/user/${USER_ACCOUNT_SUBMENU_SELF_EXCLUSION}`,
				trackingTitle: 'SelfExclusion',
			},
			{
				id: USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT,
				title: 'Set Deposit Limits',
				type: USER_ACCOUNT_SUBMENU_ITEM,
				route: `/user/${USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT}`,
				trackingTitle: 'DepositLimit',
			},
			{
				id: USER_ACCOUNT_SUBMENU_LOSS_LIMITS,
				title: 'Set Loss Limits',
				type: USER_ACCOUNT_SUBMENU_ITEM,
				route: `/user/${USER_ACCOUNT_SUBMENU_LOSS_LIMITS}`,
				trackingTitle: 'LossLimits',
			},
			{
				id: USER_ACCOUNT_SUBMENU_SPEND_LIMIT,
				title: 'Set Spend Limits',
				type: USER_ACCOUNT_SUBMENU_ITEM,
				route: `/user/${USER_ACCOUNT_SUBMENU_SPEND_LIMIT}`,
				trackingTitle: 'SpendLimit',
			},
			{
				id: USER_ACCOUNT_SUBMENU_REALITY_CHECK,
				title: 'Reality Check',
				type: USER_ACCOUNT_SUBMENU_ITEM,
				route: `/user/${USER_ACCOUNT_SUBMENU_REALITY_CHECK}`,
				trackingTitle: 'RealityCheck',
			},
		],
	},
];
