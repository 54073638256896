import {fetchMeetingsAndRaces} from '../store/entities/actions/MeetingActions';
import {denormalizeMeetings} from '../store/entities/schemas/MeetingSchema';
import {buildRouteToRace} from '../routes/Racing';

const {buildSitemapItem} = require('../common/SitemapHelpers');

export const buildRacingSitemapFromAJAX = (store) => {
	let state;

	return new Promise(async (resolve) => {
		await store.dispatch(fetchMeetingsAndRaces());

		state = store.getState();
		let meetings = denormalizeMeetings(state.entities);

		let routes = [buildSitemapItem(buildRouteToRace())];

		meetings.forEach((meeting) => {
			meeting.races.forEach((race) => {
				routes.push(buildSitemapItem(buildRouteToRace(meeting, race)));
			});
		});

		resolve({
			name: 'Racing',
			map: `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">${routes.join('')}</urlset>`,
		});
	});
};