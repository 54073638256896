import React, { useEffect } from 'react';
import styled from 'react-emotion';
import { Column, Container, Row, StyledSection, StyledSectionTitle } from '../../Components';
import { withNamespaces } from 'react-i18next';
import { createErrorBoundary } from '../../../../ErrorBoundary/ErrorBoundaryActions';
import { connect } from 'react-redux';
import { setDefaultUserSettings } from '../../../../../store/defaultBetAmounts/defaultBetAmountsActions';
import Switch from 'react-switch';
import DefaultBetAmountsContainer from '../../ResponsibleGambling/DefaultBetAmounts/DefaultBetAmountsContainer';
import { Text } from '@tbh/ui-kit';

const SwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	span {
		font-size: 14px;
		margin: 0px;
	}
`;
const PreferencesSection = ({ user, quick_bet, showN2J, updateUserSetting }) => {
	const [form, setForm] = React.useState({
		quick_bet: quick_bet,
		showN2J: showN2J,
	});

	const handleFormChange = (name, checked) => {
		setForm({ ...form, [name]: checked });
	};

	useEffect(() => {
		if (form.quick_bet !== quick_bet || form.showN2J !== showN2J) {
			updateUserSetting(user, form);
		}
	}, [form]);

	return (
		<div>
			<Text size="1">Betting Preferences</Text>
			<Container style={{ gap: '12px' }}>
				<Row
					justifyContent="space-between"
					alignItems="flex-start"
					style={{
						gap: '8px',
					}}
				>
					<Column
						alignItems="flex-start"
						style={{
							gap: '8px',
						}}
					>
						<Text size="-1" strong>
							Quick Bet
						</Text>
						<Text size="-2">Place your bets with one tap in the Bet Slip</Text>
					</Column>
					<SwitchContainer>
						<Switch
							name="quick_bet"
							onChange={(check) => handleFormChange('quick_bet', check)}
							checked={form.quick_bet}
							width={40}
							height={20}
						/>
					</SwitchContainer>
				</Row>
				<Row
					justifyContent="space-between"
					alignItems="flex-start"
					style={{
						gap: '8px',
					}}
				>
					<Column
						alignItems="flex-start"
						style={{
							gap: '8px',
						}}
					>
						<Text size="-1" strong>
							Next Races Menu Bar
						</Text>
						<Text size="-2">Show or hide the next races menu bar at the top of the website.</Text>
					</Column>

					<SwitchContainer>
						<Switch
							name="showN2J"
							onChange={(check) => handleFormChange('showN2J', check)}
							checked={form.showN2J}
							width={40}
							height={20}
						/>
					</SwitchContainer>
				</Row>
				<Column justifyContent="flex-start" alignItems="flex-start">
					<DefaultBetAmountsContainer />
				</Column>
			</Container>
		</div>
	);
};
const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};

	const settings = user.settings || {};

	return {
		user,
		seetings: settings,
		quick_bet: settings.quick_bet ? settings.quick_bet : false,
		showN2J: settings.showN2J != undefined ? settings.showN2J : true,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserSetting: (user, setting) => {
			dispatch(setDefaultUserSettings(user, setting));
		},
	};
};
export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(PreferencesSection)));
