import {
	TRACKING_CATEGORY_RACE_CARD_DATE_SELECTOR,
	TRACKING_CATEGORY_RACE_CARD_RACE_SELECTOR,
	TRACKING_CATEGORY_RACE_CARD_SIDEBAR, TRACKING_CATEGORY_RACING_HOME_DATE_SELECTOR,
	TRACKING_CATEGORY_RACING_HOME_RACE_SELECTOR,
    TRACKING_CATEGORY_RACING_HOME_SIDEBAR,
    
    TRACKING_CATEGORY_GRS_RACING_HOME_TYPE_SELECTOR,
    TRACKING_CATEGORY_GRS_RACE_CARD_TYPE_SELECTOR,
} from '../../common/constants/Racing';


import {trackGaEvent} from '../trackingPixels/trackingActions';

/**
 * Send GA tracking for meeting type selector
 * @param {*} label [gallops|harness|greyhounds]
 * @HW 18May2021
 */
export const trackRaceType = (action, type, isRacingHome) => {
    let category = getCategory(
        isRacingHome,
		TRACKING_CATEGORY_GRS_RACING_HOME_TYPE_SELECTOR,
		TRACKING_CATEGORY_GRS_RACE_CARD_TYPE_SELECTOR,
    );
	return trackGaEvent(category, action, type);
};

/**
 * Send GA tracking for meeting type selector
 * @param {*} label [gallops|harness|greyhounds]
 * @HW 12Dec2019
 */
export const trackCountry = (action, type, isRacingHome) => {
    let category = getCategory(
        isRacingHome,
		TRACKING_CATEGORY_GRS_RACING_HOME_TYPE_SELECTOR,
		TRACKING_CATEGORY_GRS_RACE_CARD_TYPE_SELECTOR,
    );
	return trackGaEvent(category, action, type);
};

/**
 * Send GA tracking for filtering and click on next to jump races carousel
 * @param {string} action - ['Click'|'Filter']
 * @param {string} type - Racing type
 * @param {boolean} isRacingHome - meant to choose 'category'
 * @return {object} action
 */
export const trackNextToJump = (action, type, isRacingHome) => {
	let category = getCategory(
		isRacingHome,
		TRACKING_CATEGORY_RACING_HOME_RACE_SELECTOR,
		TRACKING_CATEGORY_RACE_CARD_RACE_SELECTOR
	);

	return trackGaEvent(category, action, type || 'Race');
};

/**
 * Send GA tracking for click on MeetingsList item
 * @param {boolean} isRacingHome - meant to choose 'category'
 * @return {object} action
 */
export const trackMeetingsListClick = (isRacingHome) => {
	let category = getCategory(
		isRacingHome,
		TRACKING_CATEGORY_RACING_HOME_SIDEBAR,
		TRACKING_CATEGORY_RACE_CARD_SIDEBAR,
	);

	return trackGaEvent(category, 'Click', 'Race');
};

/**
 * Send GA tracking for date selector
 *
 * @param {string} label [Yesterday|Tomorrow|SelectDate]
 * @param {boolean} isRacingHome - meant to choose 'category'
 * @return {object} action
 */
export const trackDateSelection = (label, isRacingHome) => {
	let category = getCategory(
		isRacingHome,
		TRACKING_CATEGORY_RACING_HOME_DATE_SELECTOR,
		TRACKING_CATEGORY_RACE_CARD_DATE_SELECTOR,
	);

	return trackGaEvent(category, 'Select', label);
};

/**
 * Send GA tracking for meeting type selector
 * @param {*} label [gallops|harness|greyhounds]
 * @HW 12Dec2019
 */
export const trackTypeSelection = (label, isRacingHome) => {
    let category = getCategory(
        isRacingHome,
		TRACKING_CATEGORY_GRS_RACING_HOME_TYPE_SELECTOR,
		TRACKING_CATEGORY_GRS_RACE_CARD_TYPE_SELECTOR,
    );

    return trackGaEvent(category, 'Select', label);
};

/**
 * Local function to choose which category to use based which page the client is at.
 * @param isRacingHome
 * @param homeCategory
 * @param raceCategory
 * @return {*}
 */
const getCategory = (isRacingHome, homeCategory, raceCategory) => {
	return isRacingHome ? homeCategory : raceCategory;
};
