import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { navigateToGRSRacingHome, navigateToRaceWithType } from '../../../store/GRSracingHome/GRSracingHomeActions';
import { TRACKING_CATEGORY_RACE_CARD_SELECTOR } from '../../../common/constants/Racing';
import { getSelectedMeeting } from '../../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
import { buildMeetingsListGroups } from '../../../pages/Racing/RacingHome/racingSelectorsGRS';
import PusherSubscriberMeetingChannel from '../../../components/features/Racing/PusherSubscribers/PusherSubscriberMeetingChannel';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { navigateToMeeting } from '../../../store/GRSracingHome/GRSracingHomeActions';

import { NotFound } from '@tbh/ui-kit';
import RaceHeaderBar from '../../../components/features/Racing/RaceHeaderBar/RaceHeaderBar';
import RaceNav from '../../../components/features/Racing/RaceNav/RaceNav';
import EventContext from '../../../contexts/eventContext';
import NewRaceCardContainer from '../RaceCardContainer/NewRaceCardContainer';
import NewRaceHeaderBar from '../../../components/features/Racing/RaceHeaderBar/NewRaceHeaderBar';
// import NewRaceNav from '../../../components/features/Racing/RaceNav/NewRaceNav';

const StyledMeetingContainer__Header = styled(RaceHeaderBar)(
	(props) => css`
		label: MeetingContainer__Header;
		transition: all 0.3s ease 0s;
	`,
);

const StyledMeetingContainer__RaceNav = styled(RaceNav)(
	(props) => css`
		label: MeetingContainer__RaceNav;
		transition: all 0.3s ease 0s;
	`,
);

class MeetingContainer extends React.Component {
	static propTypes = {
		/** Entity for selected meeting */
		meeting: PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
			state: PropTypes.string,
			country: PropTypes.string,
			track: PropTypes.string,
			weather: PropTypes.string,
			rail_position: PropTypes.string,
			type: PropTypes.string.isRequired,
			start_date: PropTypes.string.isRequired,
			races: PropTypes.array,
		}),

		/** Current in store */
		selectedRace: PropTypes.number.isRequired,

		/** current in store (gallops, harness or geyhounds) */
		type: PropTypes.string.isRequired, // @HW add newly 16Dec2019

		date: PropTypes.string, //PropTypes.instanceOf(moment), // @HW add newly 16Dec2019

		/** Action to navigate from RaceNav */
		navigateToRaceWithType: PropTypes.func.isRequired,

		/** Back to racing v3 home action */
		navigateToGRSRacingHome: PropTypes.func.isRequired,

		/** Flag from store whether meeting is loading */
		loadingMeeting: PropTypes.bool,

		/** Extra class(es) to pass through */
		className: PropTypes.string,
	};

	static defaultProps = {
		loadingMeeting: false,
		meeting: null,
		className: null,
		selectedRaceEntity: null,
		date: null,
	};

	state = {
		currentType: this.props.type,
		currentDate: this.props.date,
		meetingId: this.props.meeting ? this.props.meeting.id : null,
		currentRaceId: this.props.selectedRace,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		// If the meeting changes, update the meetingId and currentRaceId
		// This avoids the container breaking when the meeting changes
		if (!nextProps.meeting || nextProps.meeting.id !== prevState.meetingId) {
			return {
				meetingId: nextProps.meeting ? nextProps.meeting.id : null,
				currentRaceId: nextProps.selectedRace,

				currentType: nextProps.type, //
				currentDate: nextProps.date,
			};
		}
		// Update the currentRaceId if the selectedRace changes
		if (nextProps.selectedRace !== prevState.selectedRace) {
			return {
				currentRaceId: nextProps.selectedRace,

				currentType: nextProps.type, //
				currentDate: nextProps.date,
			};
		}

		return null;
	}

	/**
	 * Navigate to Race given the id
	 */
	handleOnRaceClick = (raceId) => {
		const nextRace = this.props.meeting.races.find((race) => race.id === raceId);

		this.setState({
			currentType: nextRace.type, //
			currentDate: nextRace.start_date,
			currentRaceId: nextRace.id,
		});
		//this.props.navigateToRace(raceId, this.props.meeting.id);
		this.props.navigateToRaceWithType(nextRace.type, nextRace.start_date, raceId, this.props.meeting.id);
	};

	render() {
		const { className, meeting, selectedRace, loadingMeeting, loadingRace, meetingGroups } = this.props;
		const { currentRaceId } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		if (!meeting) {
			return (
				<div className={containerClasses}>
					<NotFound message="Meeting not found" />
				</div>
			);
		}
		// If no meeting do not render
		if (!meeting.races) {
			return (
				<div className={containerClasses}>
					<NotFound message="Meeting not found" />
				</div>
			);
		}
		const config = {

		};

		/**
		 * TODO: The above comment was being run to find the selected race object.
		 * However there were cases where the meeting wouldn't have races, but the store did infact have the race.
		 * The solution was to fetch meeting, races and selections before navigating. Need to investigate
		 */
		const race =
			meeting.races.find((race, index) => {
				if (race.id === selectedRace) {
					config.initialSlide = index;
					return true;
				}

				return false;
			}) || {};
		const meetingTypes =
			meeting.type === 'T'
				? ' THOROUGHBRED'
				: meeting.type === 'H'
				? ' HARNESS'
				: meeting.type === 'G'
				? ' GREYHOUNDS'
				: '';
		const meetingTypesInternational =
			meeting.type === 'T'
				? 'INTERNATIONAL THOROUGHBRED'
				: meeting.type === 'H'
				? 'INTERNATIONAL HARNESS'
				: meeting.type === 'G'
				? 'INTERNATIONAL GREYHOUNDS'
				: '';

		const HandleMeetingSelect = (meetingId) => {
			this.props.navigateToMeeting(meetingId);
		};

		return (
			<SizeMe>
				{({ size }) => (
					<div className={containerClasses} style={{ marginTop: size.width <= 500 ? '12px' : '0' }}>
						<PusherSubscriberMeetingChannel meetingId={meeting.id} />

						<NewRaceHeaderBar
							size={size}
							meetingId={meeting.id}
							meetingType={meeting.type}
							meetingName={meeting.name}
							meetingWeather={meeting.weather}
							meetingTrack={meeting.track}
							meetingState={meeting.state}
							meetingCountry={meeting.country}
							raceName={race.name}
							raceClass={race.class}
							raceNumber={race.number}
							raceDistance={race.distance}
							raceStartDate={race.start_date}
							raceStatus={race.status}
							rail_position={meeting.rail_position}
							HandleMeetingSelect={HandleMeetingSelect}
							racePrize={race.prize}
						/>
						{/* <NewRaceNav
							displayCarousel
							carouselActivationLength={8}
							carouselConfig={config}
							raceItems={meeting.races}
							selectedRaceId={currentRaceId && !loadingMeeting ? currentRaceId : selectedRace}
							onClickItem={this.handleOnRaceClick}
							size={size}
						/> */}
						<StyledMeetingContainer__RaceNav
							displayCarousel
							carouselActivationLength={8}
							carouselConfig={config}
							raceItems={meeting.races}
							selectedRaceId={currentRaceId && !loadingMeeting ? currentRaceId : selectedRace}
							onClickItem={this.handleOnRaceClick}
							size={size}
						/>
						<EventContext.Consumer>
							{(eventContext) => (
								<NewRaceCardContainer
									races={meeting.races}
									meetingName={meeting.name}
									meetingCountry={meeting.country}
									raceDistance={race.distance}
									meetingId={meeting.id}
									size={size}
									eventContext={eventContext}
								/>
							)}
						</EventContext.Consumer>
					</div>
				)}
			</SizeMe>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		meeting: state.grsRacingHome.loadingContainer ? getSelectedMeeting(state) : getSelectedMeeting(state),
		selectedRace: state.grsRacingHome.selectedRace,
		loadingMeeting: state.grsRacingHome.loadingMeeting,
		loadingRace: state.grsRacingHome.loadingRace,
		type: state.grsRacingHome.selectedType,
		date: state.grsRacingHome.selectedDate,
		meetingGroups: buildMeetingsListGroups(state) ? buildMeetingsListGroups(state) : [],
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type, date, raceId, meetingId) => {
		dispatch(trackGaEvent(TRACKING_CATEGORY_RACE_CARD_SELECTOR, 'Click', 'RaceNumber'));
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
	},
	navigateToGRSRacingHome: (newType, newDate) => dispatch(navigateToGRSRacingHome(newType, newDate)),
	navigateToMeeting: (metingId) => {
		dispatch(navigateToMeeting(metingId));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingContainer);
