import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

import { DetailsBlock, Text } from '@tbh/ui-kit';
import BetStake from '../BetStake/BetStake';
// import { StyleBetCupomPreview } from '../BetSelectionMulti/BetSelectionMulti';

/**
 * Styling
 */
const cssBetSelectionDetails = css`
	label: BetSelectionDetails;

	display: flex;
	justify-content: space-between;
`;

const StyledBetSelectionDetails__Stake = styled(BetStake)`
	label: BetSelectionDetails__Stake;

	align-self: flex-start;
`;

const StyledBetSelectionDetails__Selection = styled(DetailsBlock)`
	label: BetSelectionDetails__Selection;

	width: 170px;
	min-height: 52px;
`;


const BetSelection__Details = styled('div')`
	  display: flex;
	  justify-content: space-between;
	  flex-direction: column;
`;
// End Styling

const BetSelectionDetails = (props) => {
	const {
		className,
		selectionName,
		betTypeLabel,
		eventName,
		marketDescription,
		onClickSelection,
		price,
		placePrice,
		priceRollups,
		displayPriceIsBumped,
		racingBetType,
		product_name,
		hasTote,
		handleSelectedCupomBet,
		stake,
		id
	} = props;

	const componentClasses = cx(cssBetSelectionDetails, {
		[className]: className,
	});


	return (
		<div className={componentClasses} onClick={onClickSelection}>
			<BetSelection__Details>
				<Text size="-3" type="secondary">
					{eventName}
				</Text>
				<Text size="-2" strong>
					{selectionName}
				</Text>
				<Text size="-3" type="secondary">
					{product_name}
				</Text>
				<Text size="-4" type="secondary">
					{marketDescription}
				</Text>
			</BetSelection__Details>

			{/* <StyledBetSelectionDetails__Selection
				className={css`
					cursor: pointer;
				`}
				headingText={selectionName}
				subHeadingText={eventName}
				supportText={marketDescription}
			/> */}
			<div className={css`display:flex;`}>
			{/* {stake ? (
				<StyleBetCupomPreview onClick={() => handleSelectedCupomBet(id, 'multi')}>Preview</StyleBetCupomPreview>
			) : null} */}
			{(price || placePrice) && (
				<StyledBetSelectionDetails__Stake
					price={price}
					placePrice={placePrice}
					racingBetType={racingBetType}
					priceRollups={priceRollups}
					displayPriceIsBumped={displayPriceIsBumped}
					productName={betTypeLabel}
					hasTote={hasTote}
				/>
			)}
			</div>
		</div>
	);
};

BetSelectionDetails.propTypes = {
	/** The name of the selection being bet on */
	selectionName: PropTypes.string.isRequired,

	/** Name of the event or competition */
	eventName: PropTypes.string.isRequired,

	/** The price or product string */
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** A description of the market being bet on. */
	marketDescription: PropTypes.string,

	/** The bet type being bet on */
	betTypeLabel: PropTypes.string,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,

	/** The place price or product string */
	placePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** The number of rolls to perform when the price changes */
	priceRollups: PropTypes.number,

	/** The non-exotic bet type to use with the filters */
	racingBetType: PropTypes.string,

	/** Indicate that the price has been bumped up */
	displayPriceIsBumped: PropTypes.bool,

	/** Click handler for the selection name */
	onClickSelection: PropTypes.func,
};

BetSelectionDetails.defaultProps = {
	betTypeLabel: null,
	className: null,
	onClickSelection: null,
	price: null,
	placePrice: null,
	racingBetType: null,
	priceRollups: 0,
	marketDescription: null,
	displayPriceIsBumped: false,
};

export default BetSelectionDetails;
