import { createAction } from '../../common/actions/actionHelpers';

import {
	DATA_PAGINATOR_SET_INITIAL_DATA,
	DATA_PAGINATOR_SET_DATA,
	DATA_PAGINATOR_SET_HEADERS,
	DATA_PAGINATOR_SET_LOADING,
	DATA_PAGINATOR_SET_SIZE,
	DATA_PAGINATOR_SET_PAGE,
	DATA_PAGINATOR_FILTERED_DATA,
	DATA_PAGINATOR_SET_TOTAL,
	DATA_PAGINATOR_SET_TOTAL_PAGES,
	DATA_PAGINATTOR_PAGE_NAME,
} from './dataPaginatorActionTypes';

import dataPaginatorReducer from './dataPaginatorReducer';

// Our initial state so that we can use it for optional parameters
const initialState = dataPaginatorReducer(undefined, {});

/**
 * This determines the start and end points for slicing our data based on the page and the size we want
 * @param page
 * @param size
 * @returns {{start: number, end: number}}
 */
const filterPaginationPoints = (page = initialState.page, size = initialState.size) => {
	const start = page * size - size;
	const end = start + size - 1;
	return { start, end };
};

/**
 * Grab a slice of the data, based on the page and size
 * @param data
 * @param page
 * @param size
 */
const filterDataPaginator = (data = initialState.data, page = initialState.page, size = initialState.size) => {
	const { start, end } = filterPaginationPoints(page, size);
	return data.slice(start, end + 1);
};

/**
 * Action for setting the filtered data
 * @param reducerName
 * @param filteredData
 */
const filterDataTable = (reducerName, filteredData = initialState.filteredData) =>
	createAction(DATA_PAGINATOR_FILTERED_DATA, filteredData, { reducerName });

/**
 * Action for changing the loading indicator
 * @param reducerName
 * @param loading
 */
const setLoading = (reducerName, loading = initialState.loading) =>
	createAction(DATA_PAGINATOR_SET_LOADING, loading, { reducerName });

/**
 * Action for changing the page
 * @param reducerName
 * @param newPage
 */
const changePage = (reducerName, newPage = initialState.page) =>
	createAction(DATA_PAGINATOR_SET_PAGE, newPage, { reducerName });

/**
 * Action for changing the slice size
 * @param reducerName
 * @param newSize
 */
const changeSize = (reducerName, newSize = initialState.size) =>
	createAction(DATA_PAGINATOR_SET_SIZE, newSize, { reducerName });

/**
 * Action for changing the headers of the data
 * @param reducerName
 * @param newHeaders
 */
const changeHeaders = (reducerName, newHeaders = initialState.headers) =>
	createAction(DATA_PAGINATOR_SET_HEADERS, newHeaders, { reducerName });

/**
 * Action for changing the slice total
 * @param reducerName
 * @param newTotal
 */
const changeTotal = (reducerName, newTotal = initialState.total) =>
	createAction(DATA_PAGINATOR_SET_TOTAL, newTotal, { reducerName });

/**
 * Action for changing the slice total
 * @param reducerName
 * @param newTotalPages
 */
const changeTotalPages = (reducerName, newTotalPages = initialState.totalPages) =>
	createAction(DATA_PAGINATOR_SET_TOTAL_PAGES, newTotalPages, { reducerName });

/**
 * Sets our main data key
 * @param reducerName
 * @param data
 */
const setData = (reducerName, data = initialState.data) => createAction(DATA_PAGINATOR_SET_DATA, data, { reducerName });

/**
 * Set or reset everything in our store
 * @param reducerName
 * @param payload
 */
const setDataTable = (reducerName, payload = initialState) =>
	createAction(DATA_PAGINATOR_SET_INITIAL_DATA, payload, { reducerName });

/**
 * Filter our data based on the page and size we want and update the page as the same time
 * @param reducerName
 * @param data
 * @param page
 * @param size
 */
const modifyDataTable = (reducerName, page = initialState.page, size = initialState.size, data = initialState.data) => (
	dispatch,
) => {
	dispatch(
		setDataTable(reducerName, {
			data,
			page,
			size,
			headers: initialState.headers,
			filteredData: filterDataPaginator(data, page, size),
		}),
	);
};

const setPageName = (reducerName, pageName = initialState.pageName) => createAction(DATA_PAGINATTOR_PAGE_NAME, pageName, { reducerName });

export {
	filterDataPaginator,
	filterPaginationPoints,
	filterDataTable,
	changePage,
	setLoading,
	changeSize,
	changeHeaders,
	changeTotal,
	changeTotalPages,
	setData,
	setDataTable,
	modifyDataTable,
	setPageName
};
