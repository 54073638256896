import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import moment from 'moment';
import styled, { css } from 'react-emotion';
import { ui, spacings, Button, Icon } from '@tbh/ui-kit';

import ReactDatePicker from 'react-datepicker'; // https://reactdatepicker.com/

import 'react-datepicker/dist/react-datepicker.css';

const StyledDatePicker = styled('div')`
	position: relative;
	z-index: 1000;
	width:100%;
	${(props) => css`
		label: DatePicker;

		input {
			margin-left:0;
			position: relative;
			// padding: ${spacings(props).tight}px ${spacings(props).compact}px;
			// border: 1px solid ${ui(props).color_4};
			outline: none;
			cursor: pointer;
			border: 0;
		}
		transitions: 0s;
		// Default button has a CSS style that we don't want on the datepicker
		.react-datepicker__navigation {
			background: none;
		}

		button {
			background: none!important;
			outline: none;
			cursor: pointer;
			box-shadow: none;

			&:hover {
				outline: none !important;
			}
		}
	`};
`;

const StyledButtonDatePic = styled(Button)`
	border: 0;

	&:hover {
		border: 0;
		color: #ffffff;
	}
`;


class DatePicker extends Component {
	static propTypes = {
		/** Action to fire when the date is changed */
		action: PropTypes.func.isRequired,

		/** The date value */
		date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),

		/** Size of the button, if it is the activator */
		buttonSize: PropTypes.string,

		/** Use the primary style for the button */
		primary: PropTypes.bool,

		/** Use the secondary style for the button */
		secondary: PropTypes.bool,

		/** Use a button as the activator */
		button: PropTypes.bool,

		/** Open the date picker through a portal */
		modal: PropTypes.bool,

		/** Where to attach the date picker popover */
		right: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,

		/** Class name for the calendar */
		calendarClassName: PropTypes.string,

		/** Custom input to use as the activator */
		customInput: PropTypes.node,

		/** The date format to use */
		dateFormat: PropTypes.string,

		/** Dropdown mode for the date picker */
		dropdownMode: PropTypes.string,

		/** Only use am icon as the activator */
		justIcon: PropTypes.bool,

		/** Whether to show the month dropdown or not */
		showMonthDropdown: PropTypes.bool,

		/** Whether to show the year dropdown or not */
		showYearDropdown: PropTypes.bool,
	};

	static defaultProps = {
		date: undefined,
		buttonSize: '',
		position: false,
		primary: false,
		secondary: false,
		button: false,
		modal: false,
		right: false,
		className: null,
		calendarClassName: null,
		customInput: null,
		justIcon: false,
		dateFormat: null,
		dropdownMode: 'scroll',
		showMonthDropdown: false,
		showYearDropdown: false,
		yearDropdownItemNumber: 0,
	};

	state = {
		date: moment(this.props.date),
		originalDate: this.props.date,
		selectedDate: null,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.date !== prevState.originalDate) {
			return {
				date: moment(nextProps.date),
				originalDate: nextProps.date,
			};
		}

		return null;
	}

	componentDidUpdate(prevProps) {
		const nowTime = new Date(this.props.date.toString()).getTime();
		const prevTime = new Date(prevProps.date.toString()).getTime();
		if (nowTime !== prevTime) {
			this.setState({
				date: moment(this.props.date),
				originalDate: this.props.date,
				selectedDate: null,
			});
		}
	}

	handleChange = (date) => {
		const { action } = this.props;
		action(this, date);
		this.setState(() => ({ selectedDate: date }));
		this.setState({ date });
	};

	render() {
		const {
			className,
			calendarClassName,
			button,
			primary,
			secondary,
			modal,
			right,
			buttonSize,
			justIcon,
			customInput,
			dateFormat,
			dropdownMode,
			showMonthDropdown,
			showYearDropdown,
			yearDropdownItemNumber,
			...rest
		} = this.props;

		const buttonProps = {
			type: (primary && 'primary') || (secondary && 'secondary') || 'plain',
			size: buttonSize || null,
			iconSize: buttonSize === 'small' ? '-2' : '-1',
			secondary,
			label: this.state.selectedDate ? this.state.selectedDate.format('Do MMM') : this.state.date.format('Do MMM'),
			icon: 'calendar',
		};

		let input;

		if (customInput) {
			input = customInput;
		} else if (justIcon) {
			input = (
				<span>
					<Icon
						className={css`
							cursor: pointer;
						`}
						icon="calendar"
					/>
				</span>
			);
		} else if (button) {
			input = (
				<span>
					<StyledButtonDatePic  {...buttonProps}>{!justIcon && buttonProps.label}</StyledButtonDatePic>
				</span>
			);
		}

		const datePickerProps = {
			fixedHeight: true,
			dateFormat: dateFormat || 'DD MMM',
			popoverAttachment: right ? 'bottom right' : 'bottom left',
			popoverTargetAttachment: right ? 'top right' : 'top left',
			customInput: input,
			withPortal: modal,
			selected: this.state.selectedDate ? this.state.selectedDate : this.state.date,
			onSelect: this.handleChange,
			onChange: this.handleChange,
			showMonthDropdown: showMonthDropdown,
			showYearDropdown: showYearDropdown,
			dropdownMode: dropdownMode,
			calendarClassName: calendarClassName,
			yearDropdownItemNumber: yearDropdownItemNumber,
			...rest,
		};

		return (
			<StyledDatePicker className={cx('date-picker', { [className]: className })}>
				<ReactDatePicker {...datePickerProps}


				/>
			</StyledDatePicker>
		);
	}
}

DatePicker.StyledButton = Button.StyledButton;

export default DatePicker;
