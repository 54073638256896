import {get, post} from '../../common/Ajax';
import {createAction} from '../../common/actions/actionHelpers';
import {UPDATE_DEPOSIT_LIMIT} from './depositLimitsActionsTypes';


/**
 * Action to Get the Deposit Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const getDepositLimit = (period) => (dispatch) => {

	return get(`/user/deposit-limit?period=${period}`)
		.then((response) => {

			let limit = response.data.data;
			let payload;

			if (period === 'daily') {
				payload = {
					daily_deposit_limit: {
						bet_limit: limit.deposit_limit,
						requested_bet_limit: limit.requested_deposit_limit,
						requested_date: limit.requested_date,
						days_remaining_to_update: limit.days_remaining_to_update,
						update_limit: limit.update_limit
					}
				};
			} else if (period === 'weekly') {
				payload = {
					weekly_deposit_limit: {
						bet_limit: limit.deposit_limit,
						requested_bet_limit: limit.requested_deposit_limit,
						requested_date: limit.requested_date,
						days_remaining_to_update: limit.days_remaining_to_update,
						update_limit: limit.update_limit
					}
				};
			} else if (period === 'monthly') {
				payload = {
					monthly_deposit_limit: {
						bet_limit: limit.deposit_limit,
						requested_bet_limit: limit.requested_deposit_limit,
						requested_date: limit.requested_date,
						days_remaining_to_update: limit.days_remaining_to_update,
						update_limit: limit.update_limit
					}
				};
			}

			return dispatch(createAction(UPDATE_DEPOSIT_LIMIT, payload));
		}).catch((error) => { 
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};


/**
 * Action to Set or Remove the Deposit Limit
 *
 * @param user_id
 * @param amount: number (-1 for removing)
 * @param period: 1, 7 or 30
 */
export const setDepositLimit = (user_id, amount, period) => () => {
	return post('/user/set-deposit-limit', {user_id, amount, period})
		.then((response) => {
			return response;
		}).catch((error) => { 
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};


/**
 * Action to Update the Deposit Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const updateDepositLimit = (period) => () => {
	return post(`/user/update-deposit-limit?period=${period}`)
		.then((response) => {
			return response;
		}).catch((error) => { 
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};

