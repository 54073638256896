import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { ui, application, typography, spacings, List, ListItem, Icon } from '@tbh/ui-kit';

import {
	RACING_TYPES_LOOKUP,
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
} from '../../../../../common/constants/Racing';

const StyledRaceNextJumpFilter = styled(List)(
	(props) => css`
		label: RaceNextJumpFilter;

		color: ${typography(props).color_2};

		${props.mode === 'horizontal'
			? css`
					flex: 1 0 100%;
					display: flex;
					margin-bottom: ${spacings(props).tight}px;
			  `
			: css`
					display: block;
					flex: 0 0 120px;
					height: ${application(props).racing.ntj_height}px;
					overflow: hidden;
					margin-bottom: 0;
			  `};
	`,
);

const StyledRaceNextJumpFilter__ListItem = styled(ListItem)(
	(props) => css`
		label: RaceNextJumpFilter__ListItem;

		flex: 1 1 auto;
		border-bottom: 1px solid ${ui(props).color_3};
		font-weight: ${typography(props).weight.bold};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		${props.mode === 'horizontal' &&
			css`
				font-size: ${typography(props)['size_-3']};
				line-height: 2.2;
				text-align: center;
				border-right: 1px solid ${ui(props).color_3};

				&:last-child {
					border-right: none;
				}
			`};

		${props.mode === 'vertical' &&
			css`
				display: block;
				text-align: left;
				border-right: none;
				font-size: ${typography(props)['size_-1']};
				line-height: 1.5;

				&:last-child {
					border-bottom: none;
				}
			`};
	`,
);

const RaceNextJumpFilter = (props) => {
	const { t, className, onFilterItemClick, types, mode } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const isActive = (types = [], type) => {
		return /*!types.length || */ types.includes(type);
	};

	return (
		<StyledRaceNextJumpFilter className={componentClasses} mode={mode}>
			<StyledRaceNextJumpFilter__ListItem
				id={RACING_THOROUGHBRED_CODE}
				active={isActive(types, RACING_THOROUGHBRED_CODE)}
				onClick={onFilterItemClick}
				mode={mode}
			>
				<Icon icon={RACING_TYPES_LOOKUP[RACING_THOROUGHBRED_CODE]} size="-1" push="1" />
				{t('Thoroughbred')}
			</StyledRaceNextJumpFilter__ListItem>
			<StyledRaceNextJumpFilter__ListItem
				id={RACING_GREYHOUNDS_CODE}
				active={isActive(types, RACING_GREYHOUNDS_CODE)}
				onClick={onFilterItemClick}
				mode={mode}
			>
				<Icon icon={RACING_TYPES_LOOKUP[RACING_GREYHOUNDS_CODE]} size="-1" push="1" />
				{t('Greyhounds')}
			</StyledRaceNextJumpFilter__ListItem>
			<StyledRaceNextJumpFilter__ListItem
				id={RACING_HARNESS_CODE}
				active={isActive(types, RACING_HARNESS_CODE)}
				onClick={onFilterItemClick}
				mode={mode}
			>
				<Icon icon={RACING_TYPES_LOOKUP[RACING_HARNESS_CODE]} size="-1" push="1" />
				{t('Harness')}
			</StyledRaceNextJumpFilter__ListItem>
		</StyledRaceNextJumpFilter>
	);
};

RaceNextJumpFilter.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to handle when a filter is clicked on */
	onFilterItemClick: PropTypes.func.isRequired,

	/** Which mode should be rendered */
	mode: PropTypes.oneOf(['horizontal', 'vertical']),

	/** List of different race types */
	types: PropTypes.array,

	/** Extra classes */
	className: PropTypes.string,
};

RaceNextJumpFilter.defaultProps = {
	className: null,
	mode: 'horizontal',
	types: [],
};

export default withNamespaces()(RaceNextJumpFilter);
