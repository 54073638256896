import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

import { CreditCardSecurityInput, CreditCardSelect } from '@tbh/ui-kit';
import CreditCardVerification from '../CreditCardVerification/CreditCardVerification';
import AuthoriseVerification from '../CreditCardForm/AuthoriseVerification/AuthoriseVerification';

import { VERIFIED, NOT_VERIFIED } from '../../../../containers/Deposit/DepositConstants';

const StyledCreditCardSelectForm__Authorise = styled(AuthoriseVerification)(
	(props) => css`
		label: CreditCardSelectForm__Authorise;

		margin-top: ${spacings(props).cozy}px;
	`,
);

const CreditCardSelectForm = (props) => {
	const {
		t,
		className,
		creditCardSecurityCode,
		creditCardId,
		creditCards,
		onCardChange,
		onChange,
		onOpenVerifyInfo,
		// Credit Card Verification
		onStartCardVerification,
		onVerifyCardSubmit,
		loadingCardVerification,
		verificationAttemptsLeft,
		verificationFailed,
		skipCardVerification,
		verificationStep,
		verifyAmount,
		verifyAmountIsValid,
		verifyCreditCardsOnly,
	} = props;

	const creditCardSelectForm = cx({
		[className]: className,
	});

	const creditCard = creditCards[creditCardId];

	return (
		<div className={creditCardSelectForm}>
			<CreditCardSelect
				action={onCardChange}
				creditCards={creditCards}
				creditCardId={creditCardId}
				disabled={verificationStep === 'blocked' || loadingCardVerification || verificationFailed}
				verifiedLabel={t(VERIFIED)}
				unverifiedLabel={t(NOT_VERIFIED)}
				displayVerificationStatus
			/>
			{creditCard && creditCard && (creditCard.verified || creditCard.OldCardToVerify) ? (
				<CreditCardSecurityInput
					onChange={onChange}
					name="creditCardSecurityCode"
					label={t('SecurityCode')}
					value={creditCardSecurityCode}
				/>
			) : (
				<CreditCardVerification
					creditCardSecurityCode={creditCardSecurityCode}
					loadingCardVerification={loadingCardVerification}
					onChange={onChange}
					verifyAmount={verifyAmount}
					verifyAmountIsValid={verifyAmountIsValid}
					verificationStep={verificationStep}
					verificationFailed={verificationFailed}
					verificationAttemptsLeft={verificationAttemptsLeft}
					skipCardVerification={skipCardVerification}
					onStartCardVerification={onStartCardVerification}
					onVerifyCardSubmit={onVerifyCardSubmit}
					verifyCreditCardsOnly={verifyCreditCardsOnly}
				/>
			)}
			{creditCard && creditCard.OldCardToVerify ? (
				<StyledCreditCardSelectForm__Authorise onOpenVerifyInfo={onOpenVerifyInfo} />
			) : null}
		</div>
	);
};

CreditCardSelectForm.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to fire when the card is changed */
	onCardChange: PropTypes.func.isRequired,

	/** Calls input changes handler */
	onChange: PropTypes.func.isRequired,

	/** Trigger verification instructions */
	onOpenVerifyInfo: PropTypes.func.isRequired,

	/** Prepares view for card verification */
	onStartCardVerification: PropTypes.func,

	/** The list of credit cards from entities */
	creditCards: PropTypes.object.isRequired,

	/** The ID of the currently selected credit card */
	creditCardId: PropTypes.number.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** The current security code */
	creditCardSecurityCode: PropTypes.string,

	/** Submit card verification attempt to server */
	onVerifyCardSubmit: PropTypes.func,

	/** Flag to control loading mask for card verification */
	loadingCardVerification: PropTypes.bool,

	/**
	 * Props for the Credit Card Verification
	 **/

	/** Card verification attempts for current card */
	verificationAttemptsLeft: PropTypes.number,

	/** Flag to signalize attempt failure */
	verificationFailed: PropTypes.bool,

	/** Reset view to skip verification */
	skipCardVerification: PropTypes.func,

	/** Control which view to render according to verification process */
	verificationStep: PropTypes.oneOf(['verify-card', 'verify-amount', 'blocked']),

	/** Input value */
	verifyAmount: PropTypes.string,

	/** If the verification code is valid or not */
	verifyAmountIsValid: PropTypes.bool,

	/** If only the credit card verification should be displayed or not */
	verifyCreditCardsOnly: PropTypes.bool,
};

CreditCardSelectForm.defaultProps = {
	className: '',
	creditCardSecurityCode: '',
	creditCards: {},
	creditCardId: null,
	onStartCardVerification: undefined,
	onVerifyCardSubmit: undefined,
	loadingCardVerification: false,
	verificationFailed: null,
	skipCardVerification: undefined,
	verificationStep: 'verify-card',
	verifyAmount: '',
	verifyAmountIsValid: false,
	verificationAttemptsLeft: null,
	verifyCreditCardsOnly: false,
};

export default withNamespaces()(CreditCardSelectForm);
