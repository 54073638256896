// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

import { Slide, ToastContainer } from 'react-toastify'; // Docs: https://github.com/fkhadra/react-toastify
import 'react-toastify/dist/ReactToastify.css';

// Styles
const cssToastNotification = css`
	label: ToastNotification;

	align-items: center;
	min-height: 50px;
	padding: 0;
`;

const StyledToastNotification__Toaster = styled(ToastContainer)`
	label: ToastNotification__Toaster;

	min-width: 320px; // IE
	z-index: 30000;
`;

const ToastNotification = (props) => {
	const { className } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledToastNotification__Toaster
			className={componentClasses}
			toastClassName={cssToastNotification}
			position="top-right"
			autoClose={4000}
			transition={Slide}
			hideProgressBar
			newestOnTop
			closeOnClick
			rtl={false}
			pauseOnVisibilityChange
			draggable
			pauseOnHover
			closeButton={false}
		/>
	);
};

ToastNotification.propTypes = {
	/** Extra classes */
	className: PropTypes.string,
};

ToastNotification.defaultProps = {
	className: '',
};

export default ToastNotification;
