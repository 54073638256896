import {SPORT_NEW_UI, SPORT_NEW_UI_BASE_URL, SPORT_CRICKET_SUB_APP} from '../pages/Sports/SportsConstants';
import {setBodyLoading, scrollToTop, closeSideBetSlip} from '../store/application/applicationActions';

const {dispatch, getState} = App.store;

const CricketRouter = Backbone.Router.extend({
  routes: {
    [SPORT_NEW_UI_BASE_URL]: 'showCricket',
    [SPORT_NEW_UI]: 'showCricket',
  },
  showCricket: function (){
    try{
      scrollToTop();
      dispatch(closeSideBetSlip(true));
      dispatch(setBodyLoading(false));
      return App.startSubApp(SPORT_CRICKET_SUB_APP);
    } catch (e){
        //console.log(e);
    }

  }
});

module.exports = new CricketRouter;
