import {
	denormalize,
	normalize,
} from 'normalizr';

import {
	upcomingAllRacesSchema,
} from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
const denormalizeUpcomingRaces = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.upcomingRaces), upcomingAllRacesSchema, entities);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizeUpcomingRaces  = (response) => {
	return normalize(response, upcomingAllRacesSchema);
};

export {
	denormalizeUpcomingRaces,
	normalizeUpcomingRaces,
};