import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFeatureRaces } from '../../pages/FeatureRacing/FeatureRacingHome/featureRacingSelectors';
import { navigateToRaceWithType } from '../../store/GRSracingHome/GRSracingHomeActions';
import RacingFeature from '../../components/features/Racing/RacingFeature/RacingFeature';
import { Ticker } from '@tbh/ui-kit';
import { buildSelectionBetButtons } from '../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
import FeatureRacesContainerLoading from './FeatureRacesContainerLoading';

class FeatureRacesContainer extends React.Component {
	static propTypes = {
		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		items: PropTypes.array.isRequired,
		onItemClick: PropTypes.func.isRequired,
		isWelcomeHome: PropTypes.bool,
		className: PropTypes.string,
		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,
	};
	static defaultProps = {
		className: '',
		isWelcomeHome: false,
	};

	// Navigate to feature Race
	handleOnItemClick = (type, date, raceId, meetingId) => {
		this.props.onItemClick(type, date, raceId, meetingId, this.props.isWelcomeHome);
	};

	render() {
		const { buildSelectionBetButtons, loading, size } = this.props;
		const data = this.props.items;

		const races = data.filter((race) => race.status == 'selling');
		
		if (!loading && races.length == 0) {
			return null;
		}

		return (
			<React.Fragment>
				<Ticker every={90000}>
					{!loading ? (
						<RacingFeature
							className={this.props.className}
							items={races}
							onItemClick={this.handleOnItemClick}
							buildSelectionBetButtons={buildSelectionBetButtons}
							size={size}
						/>
					) : (
						<FeatureRacesContainerLoading className={this.props.className} size={size} />
					)}
				</Ticker>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.application.isBodyRegionLoading,
	items: getFeatureRaces(state),
});

const mapDispatchToProps = (dispatch) => ({
	onItemClick: (type, date, raceId, meetingId, isWelcomeHome) => {
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},

	buildSelectionBetButtons: (prices, displayedBetProducts, betType) =>
		dispatch(buildSelectionBetButtons(prices, displayedBetProducts, betType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureRacesContainer);
