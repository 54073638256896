import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { FAMILYS } from '../../../../constants/themes';
import { application, spacings, typography, media, Button, ButtonGroup, Text } from '@tbh/ui-kit';

import {

    BET_BUILDER_LAST_POSITION_WIN,
    BET_BUILDER_LAST_POSITION_PLACE,
    BET_BUILDER_LAST_BIG_WIN_2,
    BET_BUILDER_LAST_BIG_WIN_5,
    BET_BUILDER_BEATEN_FAV_YES,
    BET_BUILDER_BEATEN_FAV_NO,
    BET_BUILDER_DISTANCE_WIN,
    BET_BUILDER_DISTANCE_PLACE,
    BET_BUILDER_COURSE_WIN,
    BET_BUILDER_COURSE_PLACE,
    BET_BUILDER_COURSE_DISTANCE_WIN,
    BET_BUILDER_COURSE_DISTANCE_PLACE,
    BET_BUILDER_RATE_TO_WIN_Y,
    BET_BUILDER_RATE_TO_WIN_N,
    BET_BUILDER_WET_WIN,
    BET_BUILDER_WET_PLACE,
    BET_BUILDER_STRIKE_RATE_16,
    BET_BUILDER_STRIKE_RATE_20,
    BET_BUILDER_JOCKEY_STRIKE_RATE_16,
    BET_BUILDER_JOCKEY_STRIKE_RATE_20,
    BET_BUILDER_JOCKEY_WON_HORSE_Y,
    BET_BUILDER_JOCKEY_WON_HORSE_N,
    BET_BUILDER_JOCKEY_SWITCH_Y,
    BET_BUILDER_JOCKEY_SWITCH_N,
    BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16,
    BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20,
} from '../../../../common/constants/Racing';
import { FONT_SIZE_15 } from '../../../../store/application/applicationConstants';

const StyledBetBuilderFilterSelection_body = styled('div')`
    label: BetBuilderFilterSelection_body;
    width:100%;
    height:100%;
    &:table td, table th {
        padding:0px !important;
    }
`;
const StyledBetBuilderFilterSelection__Content = styled('div')`
    label: StyledBetBuilderFilterSelection__Content;
    width:100%;
    display:flex;
`;

const StyledBetBuilderFilterSelection__Details = styled('div')`
      label: StyledBetBuilderFilterSelection__Details;
      width:100%;
      height:100%;
      color:#000000;
      display: flex;
      flex-wrap: wrap;
`;

const StyledBetBuilderFilterSelection_ButtonGroup = styled(ButtonGroup)(
    (props) => css`
		label: BetBuilderFilterSelection_ButtonGroup;

		display: inline;
		justify-content: flex-start;
		align-items: center;
        margin-bottom: ${spacings(props).compact}px;
        font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
	`,
    media(
        (props) => css`
			justify-content: space-between;

			${StyledBetBuilderFilterSelection__Button} {
                min-width: ${application(props).racing.button_width_expanded}px;
               
			}
		`,
        1280,
    ),
);

const StyledBetBuilderFilterSelection__Button = styled(Button)(
    (props) => css`
		label: BetBuilderFilterSelection__Button;

       
         
		text-align: center;
        min-width: 58px;
        color: #FFFF;
        
        color: ${typography(props).color_1_alternate};

		&:hover {
			// This is to override the specificity of the Text component
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
		}

        display: inline;
		&:first-child {
			margin-left: 0; // This is to override the specificity of the button group
		}
	`,

    media(
        (props) => css`
            padding: 0px;
            min-width: 60px;
		`,
        1280,
    ),
);

const cssBetBuilderFilterSelection__Text = css`
	label: BetBuilderFilterSelection__Text;

    line-height: inherit;
    color:black;
`;

const StyledBetBuilderFilterTypeRow = styled('div')`
        ${(props) =>
        css`
            label: BetBuilderFilterTypeRow;

            display: flex;
            flex-direction: row;
            //-webkit-box-pack: justify; 
            -webkit-box-pack: start;
            -moz-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            width: 50%;
            
            `};

        ${media(
            (props) =>
                css`

                display: flex;
                flex-direction: row;
                //-webkit-box-pack: justify; 
                -webkit-box-pack: start;
                -moz-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                width: 50%;
                `,
            767,
        )};       

      
`;
const StyledBetBuilderFilterTypeRow_details = styled('div')`
        ${(props) =>
        css`
            label: BetBuilderFilterTypeRow_details;
      
            //display: flex;
            //flex-direction: row;
            //flex: 0 0 50%;  
            width:50%;  
            text-overflow: ellipsis;
            -webkit-box-pack: start;
            color:black;
            `};

        ${media(
            (props) =>
                css`

                //display: flex;
                //flex-direction: row;
                //flex: 0 0 45%;   
                width:50%; 
                text-overflow: ellipsis;
                -webkit-box-pack: start;
                `,
            767,
        )};             

     
`;
const StyledBetBuilderFilterTypeRow_buttonsSection = styled('div')`
    ${(props) =>
        css`
        label: BetBuilderFilterTypeRow_buttonsSection;
      
        display: flex;
        flex-direction: row;
        //flex: 0 0 50%;    
        width: 50%;
        text-overflow: ellipsis;
        -webkit-box-pack: start;
        &:${Text} {
            color:black;
        }
        `};

    ${media(
            (props) =>
                css`

            display: flex;
            flex-direction: row;
            //flex: 0 0 55%;   
            width:50%; 
            text-overflow: ellipsis;
            -webkit-box-pack: start;
            `,
            767,
        )};   
      
`;

const RowDetailsText = styled(Text)`
    ${(props) =>
        css`
            label: RowDetailsText;
            color:black;
            `};
`;

const BetBuilderFilterSelection = (props) => {
    const { t, className, handleSelectType, size,
        activeLastStartWin, activeLastStartPlace, activeLastBigWin2, activeLastBigWin5,
        activeBeatenFavY, activeBeatenFavN, activeDistanceWin, activeDistancePlace, activeCourseWin, activeCoursePlace, activeCourseDistanceWin, activeCourseDistancePlace,
        activeRateWinYes, activeRateWinNo, activeWetWin, activeWetPlace, activeTrainerStrikeRate16, activeTrainerStrikeRate20, activeJockeyStrikeRate16, activeJockeyStrikeRate20,
        activeJockeyWonHorseYes, activeJockeyWonHorseNo, activeJockeySwitchYes, activeJockeySwitchNo, activeTrainerJockeyStrikeRate16, activeTrainerJockeyStrikeRate20
    } = props;

    const componentClasses = cx({
        [className]: className,
    });


    const handleLastStartWinClick = () => {
        /// 1st place
        handleSelectType(BET_BUILDER_LAST_POSITION_WIN, BET_BUILDER_LAST_POSITION_WIN);
    };

    const handleLastStartPlaceClick = () => {
        // place 1, 2,3
        handleSelectType(BET_BUILDER_LAST_POSITION_PLACE, BET_BUILDER_LAST_POSITION_PLACE);
    };
    // Last Start Big Win
    const handleLastBigWinClick = () => {
        handleSelectType(BET_BUILDER_LAST_BIG_WIN_2, BET_BUILDER_LAST_BIG_WIN_2);
    };

    const handleLastBigWinFiveClick = () => {
        handleSelectType(BET_BUILDER_LAST_BIG_WIN_5, BET_BUILDER_LAST_BIG_WIN_5);
    };
    // Beaten Favourite
    const handleBeatenFavYClick = () => {
        handleSelectType(BET_BUILDER_BEATEN_FAV_YES, BET_BUILDER_BEATEN_FAV_YES);
    };

    const handleBeatenFavNClick = () => {
        handleSelectType(BET_BUILDER_BEATEN_FAV_NO, BET_BUILDER_BEATEN_FAV_NO);
    };
    // Distance
    const handleDistanceWinClick = () => {
        handleSelectType(BET_BUILDER_DISTANCE_WIN, BET_BUILDER_DISTANCE_WIN);
    };
    const handleDistancePlaceClick = () => {
        handleSelectType(BET_BUILDER_DISTANCE_PLACE, BET_BUILDER_DISTANCE_PLACE);
    };
    // Course
    const handleCourseWinClick = () => {
        handleSelectType(BET_BUILDER_COURSE_WIN, BET_BUILDER_COURSE_WIN);
    };
    const handleCoursePlaceClick = () => {
        handleSelectType(BET_BUILDER_COURSE_PLACE, BET_BUILDER_COURSE_PLACE);
    };
    // Course & Distance
    const handleCourseDistanceWinClick = () => {
        handleSelectType(BET_BUILDER_COURSE_DISTANCE_WIN, BET_BUILDER_COURSE_DISTANCE_WIN);
    };
    const handleCourseDistancePlaceClick = () => {
        handleSelectType(BET_BUILDER_COURSE_DISTANCE_PLACE, BET_BUILDER_COURSE_DISTANCE_PLACE);
    };
    // Rates to win
    const handleRateToWinYClick = () => {
        //yes
        handleSelectType(BET_BUILDER_RATE_TO_WIN_Y, BET_BUILDER_RATE_TO_WIN_Y);
    };

    const handleRateToWinNClick = () => {
        //no
        handleSelectType(BET_BUILDER_RATE_TO_WIN_N, BET_BUILDER_RATE_TO_WIN_N);
    };

    // Wet
    const handleWetWinClick = () => {
        handleSelectType(BET_BUILDER_WET_WIN, BET_BUILDER_WET_WIN);
    };
    const handleWetPlaceClick = () => {
        handleSelectType(BET_BUILDER_WET_PLACE, BET_BUILDER_WET_PLACE);
    };

    // Trainer strike rate 
    const handleTrainerStrikeRate16Click = () => {
        handleSelectType(BET_BUILDER_STRIKE_RATE_16, BET_BUILDER_STRIKE_RATE_16);
    };
    const handleTrainerStrikeRate20Click = () => {
        handleSelectType(BET_BUILDER_STRIKE_RATE_20, BET_BUILDER_STRIKE_RATE_20);
    };
    // Jockey Strike Rate (ST)
    const handleJockeyStrikeRate16Click = () => {
        handleSelectType(BET_BUILDER_JOCKEY_STRIKE_RATE_16, BET_BUILDER_JOCKEY_STRIKE_RATE_16);
    };
    const handleJockeyStrikeRate20Click = () => {
        handleSelectType(BET_BUILDER_JOCKEY_STRIKE_RATE_20, BET_BUILDER_JOCKEY_STRIKE_RATE_20);
    };

    // Won on horse
    const handleJockeyWonHorseYesClick = () => {
        handleSelectType(BET_BUILDER_JOCKEY_WON_HORSE_Y, BET_BUILDER_JOCKEY_WON_HORSE_Y);
    };
    const handleJockeyWonHorseNoClick = () => {
        handleSelectType(BET_BUILDER_JOCKEY_WON_HORSE_N, BET_BUILDER_JOCKEY_WON_HORSE_N);
    };

    //Jockey Switch
    const handleJockeySwitchYesClick = () => {
        handleSelectType(BET_BUILDER_JOCKEY_SWITCH_Y, BET_BUILDER_JOCKEY_SWITCH_Y);
    };
    const handleJockeySwitchNoClick = () => {
        handleSelectType(BET_BUILDER_JOCKEY_SWITCH_N, BET_BUILDER_JOCKEY_SWITCH_N);
    };

    // Trainer and Jockey Strike Rate (ST)
    const handleTrainerJockeyStrikeRate16Click = () => {
        handleSelectType(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16, BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16);
    };
    const handleTrainerJockeyStrikeRate20Click = () => {
        handleSelectType(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20, BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20);
    };

    const getButtonStyle =
        (activeLastStartWin, activeLastStartPlace, activeLastBigWin2, activeLastBigWin5, activeBeatenFavY, activeBeatenFavN, activeDistanceWin, activeDistancePlace
            , activeCourseWin, activeCoursePlace, activeCourseDistanceWin, activeCourseDistancePlace, activeRateWinYes, activeRateWinNo, activeWetWin, activeWetPlace, activeTrainerStrikeRate16
            , activeTrainerStrikeRate20, activeJockeyStrikeRate16, activeJockeyStrikeRate20, activeJockeyWonHorseYes, activeJockeyWonHorseNo, activeJockeySwitchYes
            , activeJockeySwitchNo, activeTrainerJockeyStrikeRate16, activeTrainerJockeyStrikeRate20) => {

            if (activeLastStartWin) {
                return activeLastStartWin ? 'primary' : 'alternate';
            }
            if (activeLastStartPlace) {
                return activeLastStartPlace ? 'primary' : 'alternate';
            }
            if (activeLastBigWin2) {
                return activeLastBigWin2 ? 'primary' : 'alternate';
            }
            if (activeLastBigWin5) {
                return activeLastBigWin5 ? 'primary' : 'alternate';
            }
            if (activeBeatenFavY) {
                return activeBeatenFavY ? 'primary' : 'alternate';
            }
            if (activeBeatenFavN) {
                return activeBeatenFavN ? 'primary' : 'alternate';
            }

            if (activeDistanceWin) {
                return activeDistanceWin ? 'primary' : 'alternate';
            }
            if (activeDistancePlace) {
                return activeDistancePlace ? 'primary' : 'alternate';
            }
            if (activeCourseWin) {
                return activeCourseWin ? 'primary' : 'alternate';
            }
            if (activeCoursePlace) {
                return activeCoursePlace ? 'primary' : 'alternate';
            }
            if (activeCourseDistanceWin) {
                return activeCourseDistanceWin ? 'primary' : 'alternate';
            }
            if (activeCourseDistancePlace) {
                return activeCourseDistancePlace ? 'primary' : 'alternate';
            }

            // Rates to win
            if (activeRateWinYes) {
                //yes
                return activeRateWinYes ? 'primary' : 'alternate';
            }
            if (activeRateWinNo) {
                //no
                return activeRateWinNo ? 'primary' : 'alternate';
            }
            //Wet
            if (activeWetWin) {
                return activeWetWin ? 'primary' : 'alternate';
            }
            if (activeWetPlace) {
                return activeWetPlace ? 'primary' : 'alternate';
            }

            //Trainer Strike Rate (ST)
            if (activeTrainerStrikeRate16) {
                return activeTrainerStrikeRate16 ? 'primary' : 'alternate';
            }
            if (activeTrainerStrikeRate20) {
                return activeTrainerStrikeRate20 ? 'primary' : 'alternate';
            }
            // Jockey Strike Rate (ST)
            if (activeJockeyStrikeRate16) {
                return activeJockeyStrikeRate16 ? 'primary' : 'alternate';
            }
            if (activeJockeyStrikeRate20) {
                return activeJockeyStrikeRate20 ? 'primary' : 'alternate';
            }
            // Jockey Won on horse
            if (activeJockeyWonHorseYes) {
                return activeJockeyWonHorseYes ? 'primary' : 'alternate';
            }
            if (activeJockeyWonHorseNo) {
                return activeJockeyWonHorseNo ? 'primary' : 'alternate';
            }

            // Jockey Switch
            if (activeJockeySwitchYes) {
                return activeJockeySwitchYes ? 'primary' : 'alternate';
            }
            if (activeJockeySwitchNo) {
                return activeJockeySwitchNo ? 'primary' : 'alternate';
            }

            // Trainer and Jockey Strike Rate (ST)
            if (activeTrainerJockeyStrikeRate16) {
                return activeTrainerJockeyStrikeRate16 ? 'primary' : 'alternate';
            }
            if (activeTrainerJockeyStrikeRate20) {
                return activeTrainerJockeyStrikeRate20 ? 'primary' : 'alternate';
            }


            return 'secondary';
        };
    const getTextStyle = (activeLastStartWin, activeLastStartPlace, activeLastBigWin2, activeLastBigWin5,
        activeBeatenFavY, activeBeatenFavN, activeDistanceWin, activeDistancePlace,
        activeCourseWin, activeCoursePlace, activeCourseDistanceWin, activeCourseDistancePlace, activeRateWinYes, activeRateWinNo, activeWetWin, activeWetPlace,
        activeTrainerStrikeRate16, activeTrainerStrikeRate20, activeJockeyStrikeRate16, activeJockeyStrikeRate20, activeJockeyWonHorseYes, activeJockeyWonHorseNo,
        activeJockeySwitchYes, activeJockeySwitchNo, activeTrainerJockeyStrikeRate16, activeTrainerJockeyStrikeRate20) => {

        if (activeLastStartWin || activeLastStartPlace) {
            return activeLastStartWin ? 'alternate' : 'primary';
        }
        // Last Start Big Win
        if (activeLastBigWin2) {
            return activeLastBigWin2 ? 'alternate' : 'primary';
        }
        if (activeLastBigWin5) {
            return activeLastBigWin5 ? 'alternate' : 'primary';
        }
        // Beaten Favourite
        if (activeBeatenFavY) {
            return activeBeatenFavY ? 'alternate' : 'primary';
        }
        if (activeBeatenFavN) {
            return activeBeatenFavN ? 'alternate' : 'primary';
        }
        // Distance
        if (activeDistanceWin) {
            return activeDistanceWin ? 'alternate' : 'primary';
        }
        if (activeDistancePlace) {
            return activeDistancePlace ? 'alternate' : 'primary';
        }
        // Course
        if (activeCourseWin) {
            return activeCourseWin ? 'alternate' : 'primary';
        }
        if (activeCoursePlace) {
            return activeCoursePlace ? 'alternate' : 'primary';
        }
        // Course & Distance
        if (activeCourseDistanceWin) {
            return activeCourseDistanceWin ? 'alternate' : 'primary';
        }
        if (activeCourseDistancePlace) {
            return activeCourseDistancePlace ? 'alternate' : 'primary';
        }
        // Rates to win
        if (activeRateWinYes) {
            return activeRateWinYes ? 'alternate' : 'primary';
        }
        if (activeRateWinNo) {
            return activeRateWinNo ? 'alternate' : 'primary';
        }
        // Wet
        if (activeWetWin) {
            return activeWetWin ? 'alternate' : 'primary';
        }
        if (activeWetPlace) {
            return activeWetPlace ? 'alternate' : 'primary';
        }

        //Trainer Strike Rate (ST)
        if (activeTrainerStrikeRate16) {
            return activeTrainerStrikeRate16 ? 'alternate' : 'primary';
        }
        if (activeTrainerStrikeRate20) {
            return activeTrainerStrikeRate20 ? 'alternate' : 'primary';
        }
        // Jockey Strike Rate (ST)
        if (activeJockeyStrikeRate16) {
            return activeJockeyStrikeRate16 ? 'alternate' : 'primary';
        }
        if (activeJockeyStrikeRate20) {
            return activeJockeyStrikeRate20 ? 'alternate' : 'primary';
        }

        // Jockey Won on horse
        if (activeJockeyWonHorseYes) {
            return activeJockeyWonHorseYes ? 'alternate' : 'primary';
        }
        if (activeJockeyWonHorseNo) {
            return activeJockeyWonHorseNo ? 'alternate' : 'primary';
        }

        // Jockey Switch
        if (activeJockeySwitchYes) {
            return activeJockeySwitchYes ? 'alternate' : 'primary';
        }
        if (activeJockeySwitchNo) {
            return activeJockeySwitchNo ? 'alternate' : 'primary';
        }

        // Trainer and Jockey Strike Rate (ST)
        if (activeTrainerJockeyStrikeRate16) {
            return activeTrainerJockeyStrikeRate16 ? 'alternate' : 'primary';
        }
        if (activeTrainerJockeyStrikeRate20) {
            return activeTrainerJockeyStrikeRate20 ? 'alternate' : 'primary';
        }

        return 'alternate';

    };

    const cssStyledBetBuilderFilterSelection__ButtonExpanded =
        css`
        display: inline;
          `;

    const buttonStyles = {
        betBuilderLastStartWin: {
            button: getButtonStyle(activeLastStartWin),
            text: getTextStyle(activeLastStartWin),
        },
        betBuilderLastStartPlace: {
            button: getButtonStyle(activeLastStartPlace),
            text: getTextStyle(activeLastStartPlace),
        },
        betBuilderLastBigWin2: {
            button: getButtonStyle(activeLastBigWin2),
            text: getTextStyle(activeLastBigWin2),
        },

        betBuilderLastBigWin5: {
            button: getButtonStyle(activeLastBigWin5),
            text: getTextStyle(activeLastBigWin5),
        },
        BeatenFavY: {
            button: getButtonStyle(activeBeatenFavY),
            text: getTextStyle(activeBeatenFavY),
        },
        BeatenFavN: {
            button: getButtonStyle(activeBeatenFavN),
            text: getTextStyle(activeBeatenFavN),
        },
        DistanceWin: {
            button: getButtonStyle(activeDistanceWin),
            text: getTextStyle(activeDistanceWin),
        },
        DistancePlace: {
            button: getButtonStyle(activeDistancePlace),
            text: getTextStyle(activeDistancePlace),
        },
        CourseWin: {
            button: getButtonStyle(activeCourseWin),
            text: getTextStyle(activeCourseWin),
        },
        CoursePlace: {
            button: getButtonStyle(activeCoursePlace),
            text: getTextStyle(activeCoursePlace),
        },
        CourseDistanceWin: {
            button: getButtonStyle(activeCourseDistanceWin),
            text: getTextStyle(activeCourseDistanceWin),
        },
        CourseDistancePlace: {
            button: getButtonStyle(activeCourseDistancePlace),
            text: getTextStyle(activeCourseDistancePlace),
        },
        RateTOWinYes: {
            button: getButtonStyle(activeRateWinYes),
            text: getTextStyle(activeRateWinYes),
        },
        RateTOWinNo: {
            button: getButtonStyle(activeRateWinNo),
            text: getTextStyle(activeRateWinNo),
        },
        WetWin: {
            button: getButtonStyle(activeWetWin),
            text: getTextStyle(activeWetWin),
        },
        WetPlace: {
            button: getButtonStyle(activeWetPlace),
            text: getTextStyle(activeWetPlace),
        },

        TrainerStrikeRate16: {
            button: getButtonStyle(activeTrainerStrikeRate16),
            text: getTextStyle(activeTrainerStrikeRate16),
        },

        TrainerStrikeRate20: {
            button: getButtonStyle(activeTrainerStrikeRate20),
            text: getTextStyle(activeTrainerStrikeRate20),
        },

        JockeyStrikeRate16: {
            button: getButtonStyle(activeJockeyStrikeRate16),
            text: getTextStyle(activeJockeyStrikeRate16),
        },
        JockeyStrikeRate20: {
            button: getButtonStyle(activeJockeyStrikeRate20),
            text: getTextStyle(activeJockeyStrikeRate20),
        },

        JockeyWonHorseYes: {
            button: getButtonStyle(activeJockeyWonHorseYes),
            text: getTextStyle(activeJockeyWonHorseYes),
        },
        JockeyWonHorseNo: {
            button: getButtonStyle(activeJockeyWonHorseNo),
            text: getTextStyle(activeJockeyWonHorseNo),
        },

        JockeySwitchYes: {
            button: getButtonStyle(activeJockeySwitchYes),
            text: getTextStyle(activeJockeySwitchYes),
        },
        JockeySwitchNo: {
            button: getButtonStyle(activeJockeySwitchNo),
            text: getTextStyle(activeJockeySwitchNo),
        },

        TrainerJockeyStrikeRate16: {
            button: getButtonStyle(activeTrainerJockeyStrikeRate16),
            text: getTextStyle(activeTrainerJockeyStrikeRate16),
        },
        TrainerJockeyStrikeRate20: {
            button: getButtonStyle(activeTrainerJockeyStrikeRate20),
            text: getTextStyle(activeTrainerJockeyStrikeRate20),
        },


    };

    const styledH6 = css`
    color:#005395;
    text-align: left; 
    font-weight: bold;
    font-size: ${FONT_SIZE_15};
    width: 100%;
    `;

    return (
        <StyledBetBuilderFilterSelection_body>
            {/* <h5 className={css`color:#005395;text-align: left; font-weight: bold;`}>Meeting Filter</h5> */}
            <StyledBetBuilderFilterSelection__Content size={size}>
                <StyledBetBuilderFilterSelection__Details size={size}>

                    <h6 className={styledH6}>Horse</h6>
                    <StyledBetBuilderFilterTypeRow size={size}>

                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Last Start</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.betBuilderLastStartWin.button}
                                    action={handleLastStartWinClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.betBuilderLastStartWin.text} className={cssBetBuilderFilterSelection__Text}>
                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.betBuilderLastStartWin.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_win')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.betBuilderLastStartPlace.button}
                                    action={handleLastStartPlaceClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.betBuilderLastStartPlace.text} className={cssBetBuilderFilterSelection__Text}>
                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.betBuilderLastStartPlace.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Place')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>


                    <StyledBetBuilderFilterTypeRow size={size}>

                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Last Start Big Win</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.betBuilderLastBigWin2.button}
                                    action={handleLastBigWinClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.betBuilderLastBigWin2.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.betBuilderLastBigWin2.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_2L')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.betBuilderLastBigWin5.button}
                                    action={handleLastBigWinFiveClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.betBuilderLastBigWin5.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.betBuilderLastBigWin5.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_5L')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Beaten Favourite</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.BeatenFavY.button}
                                    action={handleBeatenFavYClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.BeatenFavY.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.BeatenFavY.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Yes')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.BeatenFavN.button}
                                    action={handleBeatenFavNClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.BeatenFavN.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.BeatenFavN.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_No')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Distance</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.DistanceWin.button}
                                    action={handleDistanceWinClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.DistanceWin.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.DistanceWin.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_win')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.DistancePlace.button}
                                    action={handleDistancePlaceClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.DistancePlace.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.DistancePlace.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Place')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Course</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.CourseWin.button}
                                    action={handleCourseWinClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.CourseWin.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.CourseWin.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_win')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.CoursePlace.button}
                                    action={handleCoursePlaceClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.CoursePlace.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.CoursePlace.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Place')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>
                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Course & Distance</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.CourseDistanceWin.button}
                                    action={handleCourseDistanceWinClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.CourseDistanceWin.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.CourseDistanceWin.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_win')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.CourseDistancePlace.button}
                                    action={handleCourseDistancePlaceClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.CourseDistancePlace.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.CourseDistancePlace.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Place')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Rate to win</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.RateTOWinYes.button}
                                    action={handleRateToWinYClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.RateTOWinYes.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.RateTOWinYes.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Yes')}
                                        </Text>
                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.RateTOWinNo.button}
                                    action={handleRateToWinNClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.RateTOWinNo.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.RateTOWinNo.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_No')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Wet</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.WetWin.button}
                                    action={handleWetWinClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.WetWin.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.WetWin.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_win')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.WetPlace.button}
                                    action={handleWetPlaceClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.WetPlace.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.WetPlace.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Place')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <h6 className={styledH6}>Trainer</h6>
                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Strike Rate (ST)</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.TrainerStrikeRate16.button}
                                    action={handleTrainerStrikeRate16Click}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.TrainerStrikeRate16.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.TrainerStrikeRate16.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_16')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.TrainerStrikeRate20.button}
                                    action={handleTrainerStrikeRate20Click}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.TrainerStrikeRate20.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.TrainerStrikeRate20.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_20')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <h6 className={styledH6}>Jockey</h6>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Strike Rate (ST)</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.JockeyStrikeRate16.button}
                                    action={handleJockeyStrikeRate16Click}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.JockeyStrikeRate16.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.JockeyStrikeRate16.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_16')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.JockeyStrikeRate20.button}
                                    action={handleJockeyStrikeRate20Click}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.JockeyStrikeRate20.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.JockeyStrikeRate20.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_20')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Won on horse</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.JockeyWonHorseYes.button}
                                    action={handleJockeyWonHorseYesClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.JockeyWonHorseYes.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.JockeyWonHorseYes.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Yes')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.JockeyWonHorseNo.button}
                                    action={handleJockeyWonHorseNoClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.JockeyWonHorseNo.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.JockeyWonHorseNo.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_No')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Jockey Switch</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.JockeySwitchYes.button}
                                    action={handleJockeySwitchYesClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.JockeySwitchYes.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.JockeySwitchYes.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_Yes')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.JockeySwitchNo.button}
                                    action={handleJockeySwitchNoClick}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.JockeySwitchNo.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.JockeySwitchNo.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_No')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>

                    <h6 className={styledH6}>Trainer and Jockey</h6>

                    <StyledBetBuilderFilterTypeRow size={size}>
                        <StyledBetBuilderFilterTypeRow_details size={size}><RowDetailsText align="left" size="-1">Strike Rate (ST)</RowDetailsText ></StyledBetBuilderFilterTypeRow_details>
                        <StyledBetBuilderFilterTypeRow_buttonsSection size={size}>
                            <StyledBetBuilderFilterSelection_ButtonGroup
                                className={componentClasses}
                                spacing={ButtonGroup.spacings.SPACING_TIGHT}
                                right
                                size={size}
                            >
                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.TrainerJockeyStrikeRate16.button}
                                    action={handleTrainerJockeyStrikeRate16Click}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.TrainerJockeyStrikeRate16.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.TrainerJockeyStrikeRate16.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_16')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>

                                <StyledBetBuilderFilterSelection__Button
                                    hover="alternate"
                                    type={buttonStyles.TrainerJockeyStrikeRate20.button}
                                    action={handleTrainerJockeyStrikeRate20Click}
                                    size="small"
                                >
                                    <Text align="center" size="-3" type={buttonStyles.TrainerJockeyStrikeRate20.text} className={cssBetBuilderFilterSelection__Text}>

                                        <Text
                                            size="-3"
                                            tag="span"
                                            type={buttonStyles.TrainerJockeyStrikeRate20.text}
                                            className={cssStyledBetBuilderFilterSelection__ButtonExpanded}
                                        >
                                            {t('BetBuilder_20')}
                                        </Text>

                                    </Text>
                                </StyledBetBuilderFilterSelection__Button>


                            </StyledBetBuilderFilterSelection_ButtonGroup>
                        </StyledBetBuilderFilterTypeRow_buttonsSection>
                    </StyledBetBuilderFilterTypeRow>


                </StyledBetBuilderFilterSelection__Details>
            </StyledBetBuilderFilterSelection__Content>

        </StyledBetBuilderFilterSelection_body>
    );

};

BetBuilderFilterSelection.propTypes = {
    /** Translation func provided by withNamespaces HOC */
    t: PropTypes.func.isRequired,

    /** The size of the component - used for media query logic */
    size: PropTypes.shape({
        width: PropTypes.number,
    }).isRequired,

    /** Extra classes */
    className: PropTypes.string,

    /** Navigate to type */
    handleSelectType: PropTypes.func,

    activeLastStartWin: PropTypes.bool,
    activeLastStartPlace: PropTypes.bool,
    activeLastBigWin2: PropTypes.bool,
    activeLastBigWin5: PropTypes.bool,
    activeBeatenFavY: PropTypes.bool,
    activeBeatenFavN: PropTypes.bool,
    activeDistanceWin: PropTypes.bool,
    activeDistancePlace: PropTypes.bool,
    activeCourseWin: PropTypes.bool,
    activeCoursePlace: PropTypes.bool,
    activeCourseDistanceWin: PropTypes.bool,
    activeCourseDistancePlace: PropTypes.bool,
    activeRateWinYes: PropTypes.bool,
    activeRateWinNo: PropTypes.bool,
    activeWetWin: PropTypes.bool,
    activeWetPlace: PropTypes.bool,
    activeTrainerStrikeRate16: PropTypes.bool,
    activeTrainerStrikeRate20: PropTypes.bool,
    activeJockeyStrikeRate16: PropTypes.bool,
    activeJockeyStrikeRate20: PropTypes.bool,
    activeJockeyWonHorseYes: PropTypes.bool,
    activeJockeyWonHorseNo: PropTypes.bool,
    activeJockeySwitchYes: PropTypes.bool,
    activeJockeySwitchNo: PropTypes.bool,
    activeTrainerJockeyStrikeRate16: PropTypes.bool,
    activeTrainerJockeyStrikeRate20: PropTypes.bool,
};

BetBuilderFilterSelection.defaultProps = {
    className: null,
    handleSelectType: () => undefined,
    activeLastStartWin: false,
    activeLastStartPlace: false,
    activeLastBigWin2: false,
    activeLastBigWin5: false,
    activeBeatenFavY: false,
    activeBeatenFavN: false,
    activeDistanceWin: false,
    activeDistancePlace: false,
    activeCourseWin: false,
    activeCoursePlace: false,
    activeCourseDistanceWin: false,
    activeCourseDistancePlace: false,
    activeRateWinYes: false,
    activeRateWinNo: false,
    activeWetWin: false,
    activeWetPlace: false,
    activeTrainerStrikeRate16: false,
    activeTrainerStrikeRate20: false,
    activeJockeyStrikeRate16: false,
    activeJockeyStrikeRate20: false,
    activeJockeyWonHorseYes: false,
    activeJockeyWonHorseNo: false,
    activeJockeySwitchYes: false,
    activeJockeySwitchNo: false,
    activeTrainerJockeyStrikeRate16: false,
    activeTrainerJockeyStrikeRate20: false,
};

export default withNamespaces()(BetBuilderFilterSelection);