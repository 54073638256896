import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, application, Ticker, brand, Checkbox } from '@tbh/ui-kit';
import { MEDIA_QUERIES, DEFAULT_COLORS } from '../../../../constants/themes';
/**
 * Components
 */
import GRSTopCarousel from '../../Carousel/GRSTopCarousel';

import NtjRacesCarouselItemV4Header from '../NtjRacesCarouselItemV4Header/NtjRacesCarouselItemV4Header';
import {
	RaceStatusEventById,
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
} from '../../../../common/constants/Racing';
import PusherSubscriberNjUpcomingRaceChannel from '../PusherSubscribers/PusherSubscriberNjUpcomingRaceChannel';
import { filterNextToJumpRaces } from '../../../../store/entities/selectors/NextToJumpRaceSelectors';
import filter from '../../../../images/icons/filters.png';
import RacingIcon from '../RacingIcon/RacingIcon';
const StyledRaceNextJumpV5 = styled('div')`
	label: RaceNextJumpV5;

	${(props) => css`
		display: flex;
		flex-wrap: wrap;
		height: 64px;
		overflow: hidden;
		width: 100%;
		position: initial !important;

		background: ${brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color};
	`} //border-bottom: 1px solid #ffff;
`;

// Size at which the mode should change over
const modeChangeOverSize = 751;

const StyledRaceNextJumpV5__Carousel = styled(GRSTopCarousel)(
	(props) => css`
		label: RaceNextJumpV5__Carousel;
		max-width: 1440px;
		flex: 1;
		height: auto;
		overflow: hidden;
		text-align: center;
		position: initial !important;
		margin-left: 30px;
		margin-right: 30px;
	`,
	(props) =>
		media(
			css`
				flex: 1;
				height: auto;
				overflow: hidden;
			`,
			modeChangeOverSize,
		),
);

const StyledRaceNextJumpV5__NtjRacesCarouselItemV4Header = styled(NtjRacesCarouselItemV4Header)(
	(props) => css`
		label: RaceNextJumpV5__NtjRacesCarouselItemV4Header;

		// width: ${application(props).racing.ntj_item_width}px;
		box-sizing: border-box;
	`,
);

export const FilterButtonControler = styled('div')`
	width: 50px;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		width: 120px;
	}
`;

export const FilterTextNextToJump = styled('span')`
	display: none;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		display: flex;
	}
`;
		// ${props.show ? `top: 64px;` : `top:-20px;`}
const FilterSection = styled('div')(
	(props) => css`
		label: FilterSection;
		transform: ${props.show ? 'translateY(64px)' : 'translateY(-20px)'};
		transition: transform 0.3s ease-in-out;
		display: flex;
		flex-direction: row;
		gap: 5px;
		width: fit-content;
		padding: 8px;
		background-color: #2e2e2e;
		color: white;
		font-size: 15px;
		position: absolute;
		shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 0px 4px 4px 0px;
		z-index: 99999;
	`,
);

const FilterItem = styled('div')`
	display: flex;
	gap: 5px;
`;

class RaceNextJumpV5 extends React.Component {
	static propTypes = {
		/** Extra class(es) to pass through to the component */
		className: PropTypes.string,

		/** An array of the next to jump races */
		items: PropTypes.arrayOf(
			PropTypes.shape({
				/** The id of the race */
				id: PropTypes.number.isRequired,

				/** The id of the meeting the race is a part of */
				meeting_id: PropTypes.number.isRequired,

				/** The name of the meeting */
				meeting_name: PropTypes.string.isRequired,

				/** The type of race ie. Thoroughbred, Harness, Greyhounds */
				type: PropTypes.oneOf([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]).isRequired,

				/** The number of the race within the meeting */
				race_number: PropTypes.number.isRequired,

				/** The starting time of the race */
				start_datetime: PropTypes.string.isRequired,

				race_name: PropTypes.string,

				state: PropTypes.string,

				distance: PropTypes.string,

				country: PropTypes.string.isRequired,
			}),
		).isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Function to handle the click event on each next to jump item */
		onItemClick: PropTypes.func,

		/** Action to track when a filter is applied */
		trackOnFilterClick: PropTypes.func,

		/** Navigate to the race page */
		onGoToRace: PropTypes.func,
	};

	static defaultProps = {
		className: null,
		onItemClick: () => undefined,
		trackOnFilterClick: () => undefined,
		onGoToRace: () => undefined,
	};

	state = {
		// initially set all the type filters active
		typeFilter: new Set([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]),
		show: false,
		// countryFilter: new Set(['AU', 'INT', 'NZ']),
	};

	/**
	 * The onClick on the Carousel item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *
	 * @param id
	 */
	handleClick = (id) => {
		let item = this.props.items.find((item) => item.id === id);
		this.props.onGoToRace(
			item.type,
			item.start_datetime,
			item.id,
			item.meeting_id,
			item.meeting_name,
			item.race_number,
		);
	};

	/**
	 * Checks whether the startTime is around 5min from now and returns period to set Ticker interval.
	 *
	 * @param {string} startTime YYYY-11-29T16:32:42+11:00
	 * @return {number|null} - ticker period
	 */
	getTickerInterval = (startTime = null) => {
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}

		// null makes Ticker clear interval.
		return null;
	};

	render() {
		const { className, size, onGoToRace } = this.props;

		let items = filterNextToJumpRaces(this.props.items, Array.from(this.state.typeFilter));

		const componentClasses = cx({
			[className]: className,
		});

		if (items.length === 0) {
			return false;
		}

		const showFilter = () => {
			this.setState({ show: !this.state.show });
		};

		const handleFilterType = (type) => {
			let types = new Set(this.state.typeFilter);

			if (types.has(type)) {
				types.delete(type);
			} else {
				types.add(type);
			}

			if (types.size === 0) {
				types = new Set([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]);
			}

			this.setState({ typeFilter: types });
		};

		return (
			<StyledRaceNextJumpV5 className={componentClasses}>
				<FilterButtonControler className="filterButton">
					<img className="filter-img" src={filter} onClick={showFilter} />
					<FilterTextNextToJump className="filter-text" onClick={showFilter}>
						Next to Jump
					</FilterTextNextToJump>
				</FilterButtonControler>

				{this.state.show && (
					<FilterSection show={this.state.show}>
						<FilterItem>
							<RacingIcon type={RACING_THOROUGHBRED_CODE} onClick={(e) => handleFilterType(RACING_THOROUGHBRED_CODE)} />
							<Checkbox
								secondary
								checked={this.state.typeFilter.has(RACING_THOROUGHBRED_CODE)}
								action={(e) => handleFilterType(RACING_THOROUGHBRED_CODE)}
								data={RACING_THOROUGHBRED_CODE}
								className={css`
									span:before {
										width: 5px;
									}
								`}
							/>
						</FilterItem>
						<FilterItem>
							<RacingIcon type={RACING_HARNESS_CODE} onClick={(e) => handleFilterType(RACING_HARNESS_CODE)} />
							<Checkbox
								secondary
								checked={this.state.typeFilter.has(RACING_HARNESS_CODE)}
								action={(e) => handleFilterType(RACING_HARNESS_CODE)}
								data={RACING_HARNESS_CODE}
								className={css`
									span:before {
										width: 5px;
									}
								`}
							/>
						</FilterItem>
						<FilterItem>
							<RacingIcon type={RACING_GREYHOUNDS_CODE} onClick={(e) => handleFilterType(RACING_GREYHOUNDS_CODE)} />
							<Checkbox
								secondary
								checked={this.state.typeFilter.has(RACING_GREYHOUNDS_CODE)}
								action={(e) => handleFilterType(RACING_GREYHOUNDS_CODE)}
								data={RACING_GREYHOUNDS_CODE}
								className={css`
									span:before {
										width: 5px;
									}
								`}
							/>
						</FilterItem>
					</FilterSection>
				)}

				<StyledRaceNextJumpV5__Carousel size={size} nextButton prevButton freeMode buttonStyle="1">
					{items.map(
						(nextToJumpRace) => (
							<Ticker every={this.getTickerInterval(nextToJumpRace.start_datetime)} key={nextToJumpRace.id}>
								{/*<PusherSubscriberNjUpcomingRaceChannel raceId={nextToJumpRace.id} />*/}
								<StyledRaceNextJumpV5__NtjRacesCarouselItemV4Header
									id={nextToJumpRace.id}
									raceNumber={nextToJumpRace.race_number}
									type={nextToJumpRace.type}
									meetingName={nextToJumpRace.meeting_name}
									meetingId={nextToJumpRace.meeting_id}
									startTime={nextToJumpRace.start_datetime}
									raceName={nextToJumpRace.race_name}
									distance={nextToJumpRace.distance}
									weather={nextToJumpRace.weather}
									country={nextToJumpRace.country}
									status={RaceStatusEventById(nextToJumpRace.event_status_id)}
									action={this.handleClick}
									size={size}
									//onGoToRace={onGoToRace}
								/>
							</Ticker>
						),
						this,
					)}
				</StyledRaceNextJumpV5__Carousel>
			</StyledRaceNextJumpV5>
		);
	}
}
export default RaceNextJumpV5;
