import { RACING_BET_TYPE_DAILY_DOUBLE } from './constants/Racing';

/**
 * Based on the first_leg and race number returned from race selections
 * work out where the current race fits in the 2 multi-leg sequence
 *
 * @param {object} race
 */
export const dailyDoublePosition = (race,isLastRace) => {
	const products = race.products;

	/* filter products result if bet_type of "Daily Double" exists */
	const dailyDoubleProduct =
		products &&
		products.filter(p => p.bet_type === RACING_BET_TYPE_DAILY_DOUBLE && p.first_leg);

	/* improve performance by not running function unless a daily double product exists */
	if (!dailyDoubleProduct) {
		return false;
	}

	/* find the race number */
	const raceNumber = race && +race.number;

	/* get the first leg number */
	const firstLegObject = { ...dailyDoubleProduct }[0];
	const firstLegNumber = firstLegObject && +firstLegObject.first_leg;

	/* determine the first and second leg positions */
	const firstLeg = firstLegNumber === raceNumber ? 1 : false;
	const secondLeg = firstLegNumber + 2 === raceNumber ? 2 : false;

	/* return the value if validation is met */
	if (firstLeg && !isLastRace) {
		return firstLeg;
	}

	if (secondLeg) {
		return secondLeg;
	}

	/* return false for any remaining null cases */
	if (!firstLeg || !secondLeg) {
		return false;
	}
};
