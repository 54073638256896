import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

import { brand, Icon, spacings, Text, ui } from '@tbh/ui-kit';
import { formatCentsAsPrice } from '../../../../../common/BetPlacement';
import { RACING_TYPES_LOOKUP } from '../../../../../common/constants/Racing';
import { countdown } from '../../../../../legacy/core/format';
import { toteProducts } from '../../../../../store/entities/constants/BetConstants';
/**
 * Styling
 */
const cssBetSelectionDetails = css`
	label: BetSelectionDetails;

	display: flex;
	align-items: start;
	justify-content: space-between;
	padding: ${spacings().compact}px;
	background-color: ${ui().color_4};
	border-top: 1px solid ${ui().color_5};
	border-radius: 0 0 4px 4px;
`;

// End Styling
const StyledTimeToRace = styled('div')(
	(props) => css`
		label: StyledTimeToRace;

		display: block;
		background: ${brand(props).rch_4};
		border-color: ${brand(props).rch_4};
        font-size: 12px;
		width: auto;
		color: #ffffff;
		font-weight: bold;
        padding: 4px;
        border-radius: 3px !important;
		white-space: nowrap;
		${props.lessThan2Min &&
		css`
				&& {
					background-color: #379b37;
					border: 1px solid #379b37;
				}
			`};
	`,
);

const StyledBetSelectionMulti__Clear = styled('div')(
	(props) => css`
		label: BetSelectionMulti__Clear;

		margin-left: ${spacings(props).compact}px;
		text-align: right;
		cursor: pointer;
	`,
);

const StyledBetSelectionMulti__ClearIcon = styled(Icon)(
	(props) => css`
		label: BetSelectionMulti__ClearIcon;

		text-align: center;
		color: ${ui(props).color_danger};
		width: 20px;
		height: 20px;
		line-height: 16px;
	`,
);

const BetSelectionDetailsNew = (props) => {
	const {
		className,
		selectionName,
		betTypeLabel,
		eventName,
		marketDescription,
		onClickSelection,
		price,
		placePrice,
		priceRollups,
		displayPriceIsBumped,
		racingBetType,
		selectedBetType,
		meeting_name,
		number,
		raceType,
		startTime,
		bet_code,
		multiBetId,
		onRemove
	} = props;


	// countdown(startTime)
	const componentClasses = cx(cssBetSelectionDetails, {
		[className]: className,
	});

	const handleOnRemove = () => {
		onRemove(multiBetId);
	};


	const lessThan2Min = new Date(startTime).getTime() - new Date().getTime() >= 120000;


	return (
		<div className={componentClasses} onClick={onClickSelection}>
			<div
				className={css`
					display: flex;
					flex-direction: row;
					align-items: start;
					gap: 4px;
				`}
			>
				<Icon icon={RACING_TYPES_LOOKUP[raceType]} />
				<div
					className={css`
						display: flex;
						flex-direction: column;
						width: 140px;
					`}
				>
					<Text size="-4" type="alternative">
						R{number} {meeting_name}
					</Text>
					<Text size="-3" strong>{selectionName}</Text>
					<Text size="-4" type="alternative" transform="uppercase" className={css`overflow: hidden;white-space: nowrap;text-overflow: ellipsis;`}>
						{selectedBetType}
					</Text>
				</div>
				<StyledTimeToRace lessThan2Min={lessThan2Min}>
					{countdown(startTime)}
				</StyledTimeToRace>
			</div>

			<div>
				<Text size="-4" type="alternative" strong>
					{toteProducts.includes(bet_code) ? '@ TOTE' : formatCentsAsPrice(price)}
				</Text>
				<StyledBetSelectionMulti__Clear>
					<StyledBetSelectionMulti__ClearIcon size="-2" icon="close" action={handleOnRemove} />
				</StyledBetSelectionMulti__Clear>
			</div>
		</div>
	);
};

BetSelectionDetailsNew.propTypes = {
	/** The name of the selection being bet on */
	selectionName: PropTypes.string.isRequired,

	/** Name of the event or competition */
	eventName: PropTypes.string.isRequired,

	/** The price or product string */
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** A description of the market being bet on. */
	marketDescription: PropTypes.string,

	/** The bet type being bet on */
	betTypeLabel: PropTypes.string,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,

	/** The place price or product string */
	placePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** The number of rolls to perform when the price changes */
	priceRollups: PropTypes.number,

	/** The non-exotic bet type to use with the filters */
	racingBetType: PropTypes.string,

	/** Indicate that the price has been bumped up */
	displayPriceIsBumped: PropTypes.bool,

	/** Click handler for the selection name */
	onClickSelection: PropTypes.func,
};

BetSelectionDetailsNew.defaultProps = {
	betTypeLabel: null,
	className: null,
	onClickSelection: null,
	price: null,
	placePrice: null,
	racingBetType: null,
	priceRollups: 0,
	marketDescription: null,
	displayPriceIsBumped: false,
};

export default BetSelectionDetailsNew;
