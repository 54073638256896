import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';

import { spacings } from '@tbh/ui-kit';
import { Image } from '@tbh/ui-kit';

import AclContext from '../../../contexts/AclContext';

import { DEFAULT_CURRENCY_CONFIGURATION } from './CurrencyConstants';

const StyledCurrencyImageContainer__Image = styled(Image)(
	(props) => css`
		label: CurrencyImageContainer__Image;

		vertical-align: baseline;
		margin-right: ${spacings(props).constrictive}px;
	`,
);

const CurrencyImageContainer = ({ imageWidth, configKey }) => {
	return (
		<AclContext.Consumer>
			{(acl) => {
				const currencyConfiguration = acl.currencyConfiguration || DEFAULT_CURRENCY_CONFIGURATION;
				const currencyName = currencyConfiguration[`${configKey}Name`] || DEFAULT_CURRENCY_CONFIGURATION.currencyName;
				const currencySymbol =
					currencyConfiguration[`${configKey}Symbol`] || DEFAULT_CURRENCY_CONFIGURATION.currencySymbol;

				return currencySymbol && currencySymbol.length > 1 ? (
					<StyledCurrencyImageContainer__Image
						width={imageWidth}
						src={global.currencyIconModules[`./${currencySymbol}`] || ''}
						alt={currencyName}
					/>
				) : (
					currencySymbol
				);
			}}
		</AclContext.Consumer>
	);
};

CurrencyImageContainer.propTypes = {
	/** The size of the image to render */
	imageWidth: PropTypes.number.isRequired,

	/** The key to use for loading the currency symbol */
	configKey: PropTypes.oneOf(['currency', 'bonusCurrency', 'tournamentCurrency']),
};

CurrencyImageContainer.defaultProps = {
	configKey: 'currency',
};

export default CurrencyImageContainer;
