// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, media, Notification } from '@tbh/ui-kit';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

// Features
import { Button, ButtonGroup, LoadingMask, HeaderBar, PlotElements } from '@tbh/ui-kit';
import WithdrawalForm from '../../components/features/Withdrawal/WithdrawalForm/WithdrawalForm';
import WithdrawalReceipt from '../../components/features/Withdrawal/WithdrawalReceipt/WithdrawalReceipt';
import CurrencyDisplay from '../../components/features/Application/Currency/CurrencyDisplay/CurrencyDisplay';

// Functions
import { getWithdrawableBalance } from './withdrawalPromptMemoizedSelectors';
import {
	createNewBankAccount,
	createNewWithdrawal,
	deleteAccount,
	fetchWithdrawalPromptData,
	setWithdrawalErrorMessages,
	setWithdrawalReceiptId,
} from '../../store/withdrawalPrompt/withdrawalPromptActions';
import { setSelectedMenuItem } from '../../store/userAccount/userAccountActions';

// Constants
import { BANK_ID_NAB, BANK_ID_OTHER } from '@tbh/ui-kit';
import { USER_ACCOUNT_VERIFY_CREDIT_CARDS } from '../../pages/UserAccount/UserAccountPageConstants';
import { TABLET_MAX_WIDTH } from '../../common/constants/Breakpoints';

// Styles
const StyledWithdrawalPromptContainer__Receipt = styled(WithdrawalReceipt)(
	(props) => css`
		label: WithdrawalPromptContainer__Cozy;

		padding: ${spacings(props).cozy}px;
	`,
);
const StyledWithdrawalPromptContainer__Form = StyledWithdrawalPromptContainer__Receipt.withComponent(WithdrawalForm);

const StyledWithdrawalPromptContainer__Header = styled(HeaderBar)(
	css`
		label: WithdrawalPromptContainer__Header;

		display: none;
	`,
	media(
		css`
			display: flex;
			font-size: 15px;
		`,
		TABLET_MAX_WIDTH,
	),
);

class WithdrawalPromptContainer extends Component {
	/**
	 * Reusable func to count how many bank accounts there are.
	 * @param bankAccounts
	 * @return {Number}
	 */
	static getAccountsCount = (bankAccounts = {}) => {
		return Object.keys(bankAccounts).length;
	};

	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the parent component - used for media query logic */
		parentSize: PropTypes.shape({
			width: PropTypes.number,
		}),

		/** Fetch needed data from back end */
		fetchPromptData: PropTypes.func.isRequired,

		/** Submit delete bank account to server */
		deleteBankAccount: PropTypes.func.isRequired,

		/** Submit create new account */
		createNewBankAccount: PropTypes.func.isRequired,

		/** Submit create withdrawal */
		createNewWithdrawal: PropTypes.func.isRequired,

		/** Clear errors from store */
		clearErrors: PropTypes.func.isRequired,

		/** Handle redirect to deposit container to verify cards */
		onVerifyCreditCards: PropTypes.func.isRequired,

		/** Resets the Withdrawal Receipt Id */
		resetWithdrawalReceiptId: PropTypes.func.isRequired,

		/** Balance calculated from user entity */
		withdrawableBalance: PropTypes.number.isRequired,

		/** Normalized bank accounts, directly from state */
		bankAccounts: PropTypes.object.isRequired,

		/** Message to render when verification is needed */
		verifyMessage: PropTypes.string.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/**
		 * From Store
		 */
		/** Whether should block withdrawal to verify cards first */
		shouldVerifyCards: PropTypes.bool,

		/** Last created withdrawal */
		withdrawalReceiptId: PropTypes.number,

		/** Whether the needed has been loaded */
		hasDataLoaded: PropTypes.bool,

		/** Trigger loading mask when creating new account */
		creatingBankAccount: PropTypes.bool,

		/** Trigger loading mask when creating withdrawal */
		creatingWithdrawal: PropTypes.bool,

		/** Last created bank account */
		createdBankAccountId: PropTypes.number,

		/** Formatted errors to be presented */
		errors: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		withdrawalReceiptId: null,
		hasDataLoaded: false,
		shouldVerifyCards: false,
		creatingBankAccount: false,
		creatingWithdrawal: false,
		createdBankAccountId: null,
		errors: null,
		parentSize: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			withdrawalAmount: '',
			selectedAccount: props.bankAccounts ? Object.keys(props.bankAccounts)[0] : null,

			// State data for tracking prop changes
			bankAccounts: props.bankAccounts,
			hasDataLoaded: props.hasDataLoaded,
			createdBankAccountId: props.createdBankAccountId,

			// Whether showing existing bank accounts or new account form
			newAccount: !WithdrawalPromptContainer.getAccountsCount(this.props.bankAccounts),

			// Whether should render delete account confirmation
			showDeleteConfirmation: false,

			/** Form data */
			other_bank_name: { value: '', valid: false },
			account_name: { value: '', valid: false },
			selectedBank: BANK_ID_NAB,
			account_number: { value: '', valid: false },
			bsb_number: { value: '', valid: false },
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const firstDataLoad = nextProps.hasDataLoaded && !prevState.hasDataLoaded;
		const newBankAccountsCount = WithdrawalPromptContainer.getAccountsCount(nextProps.bankAccounts);
		const changeToExistingAccounts =
			newBankAccountsCount !== WithdrawalPromptContainer.getAccountsCount(prevState.bankAccounts) ||
			nextProps.createdBankAccountId !== prevState.createdBankAccountId;

		if (firstDataLoad || changeToExistingAccounts) {
			return {
				bankAccounts: nextProps.bankAccounts,
				hasDataLoaded: nextProps.hasDataLoaded,
				createdBankAccountId: nextProps.createdBankAccountId,
				newAccount: !newBankAccountsCount,
				selectedAccount: nextProps.createdBankAccountId || Object.keys(nextProps.bankAccounts)[0],
			};
		}
		return null;
	}

	componentDidMount() {
		this.props.fetchPromptData();
	}

	/**
	 * Submit delete account to server
	 */
	deleteBankAccount = () => {
		this.handleDismissErrors();
		this.handleHideDeleteConfirmation();
		this.props.deleteBankAccount(this.state.selectedAccount);
	};

	/**
	 * Clear amount input to set a new value
	 */
	handleOtherValueClick = () => {
		this.handleAmountChange('');
	};

	/**
	 * Submit create new account
	 */
	handleCreateNewAccount = () => {
		this.handleDismissErrors();
		const { bsb_number, account_number, account_name, selectedBank } = this.state;
		this.props.createNewBankAccount(bsb_number.value, account_number.value, account_name.value, selectedBank);
	};

	/**
	 * Used to show Existing accounts
	 */
	handleUndoAccountCreation = () => {
		this.handleDismissErrors();
		this.setState({
			newAccount: false,
		});
	};

	/**
	 * Used show new account form
	 */
	handleAddAccountClick = () => {
		this.handleDismissErrors();
		this.handleHideDeleteConfirmation();
		this.setState({
			newAccount: true,
		});
	};

	/**
	 * Triggers rendering of delete account confirmation
	 */
	handleDeleteAccountClick = () => {
		this.handleDismissErrors();
		this.setState({
			showDeleteConfirmation: true,
		});
	};

	/**
	 * Dismiss delete confirmation
	 */
	handleHideDeleteConfirmation = () => {
		this.handleDismissErrors();
		this.setState({
			showDeleteConfirmation: false,
		});
	};

	/**
	 * Handle the withdrawal amount changing
	 * @param amount
	 */
	handleAmountChange = (amount) => {
		const { withdrawableBalance } = this.props;
		const newAmount = Number(amount);

		this.setState({
			withdrawalAmount: newAmount,
			inputError:
				newAmount > withdrawableBalance ? (
					<PlotElements>
						<span>
							{this.props.t('WithdrawalPromptContainer__MaxBalance')}
							&nbsp;
						</span>
						<CurrencyDisplay amount={withdrawableBalance} />
					</PlotElements>
				) : null,
		});
	};

	/**
	 * Update selected account and dismiss delete confirmation
	 * @param inputName
	 * @param accountId
	 */
	handleAccountChange = (inputName, accountId) => {
		this.handleDismissErrors();
		this.setState({
			showDeleteConfirmation: false,
			selectedAccount: accountId,
		});
	};

	/**
	 * Update local state with changes to form data
	 * @param inputName
	 * @param value
	 * @param valid
	 */
	handleFormChange = (inputName, value, valid) => {
		this.setState({
			[inputName]: {
				value,
				valid,
			},
		});
	};

	/**
	 * Clear errors in store
	 */
	handleDismissErrors = () => {
		this.props.clearErrors();
	};

	/**
	 * Updates selected bank in new account form
	 * @param inputName
	 * @param index
	 *
	 */
	handleChangeSelectedBank = (inputName, index) => {
		this.handleDismissErrors();
		this.setState({
			selectedBank: index,
		});
	};

	/**
	 * Submit withdrawal request
	 */
	handleWithdrawRequest = () => {
		const { withdrawalAmount, selectedAccount } = this.state;
		this.handleDismissErrors();
		this.props.createNewWithdrawal(withdrawalAmount, selectedAccount);
	};

	/**
	 * Closes modal to open deposit container to verify cards
	 */
	handleVerifyCreditCards = () => {
		this.handleDismissErrors();
		this.props.onVerifyCreditCards();
	};

	/**
	 * Set an error for the withdrawal amount input
	 *
	 * @param inputError
	 */
	setWithdrawalAmountError = (inputError) => {
		this.setState({ inputError });
	};

	/**
	 * Return whether withdrawal or account creation can be processed, depending inputs validation.
	 * @returns boolean
	 */
	withdrawalEnabled = () => {
		const {
			inputError,
			withdrawalAmount,
			showDeleteConfirmation,
			newAccount,
			selectedBank,
			other_bank_name,
			bsb_number,
			account_number,
			account_name,
			selectedAccount,
		} = this.state;

		const { shouldVerifyCards, withdrawableBalance, accountIsVerified } = this.props;
		//console.log('withdrawalEnabled', shouldVerifyCards, newAccount);
		  //Tempoarary disable for becuse we need only green id verification not need credit card verification during deposit.
		/*if (!accountIsVerified || shouldVerifyCards && !newAccount) {
			return false;
		}*/
		/*if (!accountIsVerified  && !newAccount) {
			
			return false;
		}*/
		const formIsValid =
			// Not waiting delete account confirmation
			!showDeleteConfirmation &&
			// No validation errors for the input
			!inputError &&
			// If new account, whether inputs are correct
			(newAccount
				? (selectedBank === BANK_ID_OTHER ? other_bank_name.valid : true) &&
				bsb_number.valid &&
				account_number.valid &&
				account_name.valid
				: // Otherwise, if there is account selected
				selectedAccount);

		// Both withdraw amount and balance. Assume true until required
		let fundsAreValid = true;

		// If there is a new bank account being added, then the validation should not require a withdraw amount or available funds for withdrawal
		if (!newAccount) {
			fundsAreValid =
				// Fund available
				withdrawableBalance >= 2000 &&
				// Amount is over $20
				withdrawalAmount >= 2000;
		}
		//console.log('withdrawalEnabled', formIsValid, fundsAreValid);
		return formIsValid && fundsAreValid;
	};

	/**
	 * Custom button for the Amount section. This object must be declared after action handler used.
	 */
	otherButton = { action: this.handleOtherValueClick, type: 'ghost', label: this.props.t('Other') };

	/**
	 * Buttons used at delete account confirmation at notification bar
	 */
	deleteAccountConfirmButtons = () => (
		<ButtonGroup
			right
			noWrap={true}
			className={css`
				min-width: 36px;
			`}
		>
			<Button action={this.handleHideDeleteConfirmation} type="primary" block>
				{this.props.t('Cancel')}
			</Button>

			<Button action={this.deleteBankAccount} type="secondary" block>
				{this.props.t('Confirm')}
			</Button>
		</ButtonGroup>
	);

	render() {
		const {
			t,
			className,
			parentSize,
			bankAccounts,
			shouldVerifyCards,
			hasDataLoaded,
			creatingBankAccount,
			creatingWithdrawal,
			errors,
			withdrawableBalance,
			withdrawalReceiptId,
			verifyMessage,
			resetWithdrawalReceiptId,
			withdrawable_balance,
			accountIsVerified
		} = this.props;

		const {
			withdrawalAmount,
			other_bank_name,
			account_name,
			account_number,
			bsb_number,
			inputError,
			newAccount,
			selectedBank,
			selectedAccount,
			showDeleteConfirmation,

		} = this.state;

		const loading = !hasDataLoaded || creatingBankAccount || creatingWithdrawal;
		const componentClasses = cx({
			[className]: className,
		});
		//console.log('loaded');
		const enabled = this.withdrawalEnabled();

		return (
			<div className={componentClasses}>
				{parentSize && (
					<StyledWithdrawalPromptContainer__Header type="primary" size={parentSize}>
						{!loading && withdrawalReceiptId ? t('WithdrawalReceipt') : t('MakeAWithdrawal')}
					</StyledWithdrawalPromptContainer__Header>
				)}

				{!loading && withdrawalReceiptId ? (
					<StyledWithdrawalPromptContainer__Receipt
						account_balance={withdrawableBalance}
						amount={withdrawalAmount}
						onGoToWithdrawal={resetWithdrawalReceiptId}
						receiptId={withdrawalReceiptId}
						receiptMethod={t('BankEFT')}
					/>
				) : (
					<div>
						{errors && (
							<Notification
								type={Notification.types.COLOUR_DANGER}
								strong
								message={errors}
								buttonAction={this.handleDismissErrors}
								buttonText={t('Dismiss')}
							/>
						)}
						{showDeleteConfirmation && (
							<Notification
								type={Notification.types.COLOUR_DANGER}
								strong
								message={t('WithdrawalPromptContainer__RemoveBankAccountMessage')}
								customActions={this.deleteAccountConfirmButtons}
							/>
						)}
						{/* 
						{shouldVerifyCards && !showDeleteConfirmation && !newAccount ? (
							<Notification
								type={Notification.types.COLOUR_WARNING}
								strong
								message={verifyMessage}
								buttonAction={this.handleVerifyCreditCards}
								buttonText={t('Verify')}
							/>
						) : null}
						{showDeleteConfirmation && (
							<Notification
								type={Notification.types.COLOUR_WARNING}
								strong
								message={t('WithdrawalPromptContainer__RemoveBankAccountMessage')}
								customActions={this.deleteAccountConfirmButtons}
							/>
						)} */}
						<LoadingMask loading={loading} />
						<StyledWithdrawalPromptContainer__Form
							newBankAccount={newAccount}
							withdrawalAmount={withdrawalAmount}
							bankAccounts={bankAccounts}
							selectedAccount={selectedAccount}
							bank_details_id={selectedBank}
							other_bank_name={other_bank_name.value}
							account_name={account_name.value}
							account_number={account_number.value}
							withdrawable_balance={withdrawable_balance}
							bsb_number={bsb_number.value}
							customButton={this.otherButton}
							inputError={inputError}
							isPrimaryActionEnabled={enabled}
							onAddBankAccount={this.handleAddAccountClick}
							onChange={this.handleFormChange}
							onDeleteBankAccount={this.handleDeleteAccountClick}
							onSelectBankAccount={this.handleAccountChange}
							onSelectBankName={this.handleChangeSelectedBank}
							onShowExistingBankAccount={this.handleUndoAccountCreation}
							onSubmitNewBankAccount={this.handleCreateNewAccount}
							onWithdrawalAmountChange={this.handleAmountChange}
							onRequestWithdrawal={this.handleWithdrawRequest}
							setWithdrawalAmountError={this.setWithdrawalAmountError}
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {

	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};

	return {
		// For used properties, check propTypes
		...state.withdrawalPrompt,
		withdrawable_balance: user.withdrawable_balance,
		bankAccounts: state.entities.bankAccounts,
		withdrawableBalance: getWithdrawableBalance(state),
		accountIsVerified: ownProps.accountIsVerified,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchPromptData: () => dispatch(fetchWithdrawalPromptData()),
	createNewBankAccount: (bsb, accountNumber, accountName, selectedBank) =>
		dispatch(createNewBankAccount(bsb, accountNumber, accountName, selectedBank)),
	deleteBankAccount: (id) => dispatch(deleteAccount(id)),
	clearErrors: () => dispatch(setWithdrawalErrorMessages(null)),
	onVerifyCreditCards: () => {
		dispatch(setSelectedMenuItem(USER_ACCOUNT_VERIFY_CREDIT_CARDS));
	},
	createNewWithdrawal: (amount, bankAccountId) => dispatch(createNewWithdrawal(amount, bankAccountId)),
	resetWithdrawalReceiptId: () => dispatch(setWithdrawalReceiptId(null)),
});

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(WithdrawalPromptContainer),
);
