import React from 'react';
import { brand, Text } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';
import { MEDIA_QUERIES } from '../../../../constants/themes';

const StyledRaceResultSelectionDetails = styled('div')`
    label: RaceResultSelectionDetails;

    display: flex;
    flex-direction: row;
    height: 40px;
    width: 100%;
    gap: 6px;
    align-items: center;

    @media ${MEDIA_QUERIES.laptopAndUp} {
		width: 250px;
        gap: 8px
	}
`;

const StyledRunnerImage = styled('img')`
    label: RunnerImage;
    width: 30px;
`;


const StyledColunm = styled('div')`
    label: Column;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: end;
`;

const StyledRacePosition = styled(Text)(
    props => css`
    label: RacePosition;
    width: 28px;
    height: 28px;
    padding: 5px;
    text-align: center;
    background-color: ${brand(props).race_grey_color ? brand(props).race_grey_color : '#1C1F21'};
    color:white;
    border-radius: 2px;
    line-height: 1.8;

    @media ${MEDIA_QUERIES.laptopAndUp} {
		width: 30px;
        height: 25px;
	}

    `
);
const RaceResultSelectionDetails = (props) => {

    const { selection } = props;

    const position_sign =
        selection.position === 1 ? 'st' :
            selection.position === 2 ? 'nd' :
                selection.position === 3 ? 'rd' :
                    'th';

    return (
        <StyledRaceResultSelectionDetails>
            <div className={css`display:flex;flex-direction:row;gap:5px;justify-content: end;align-items: center;`}>
                <StyledRacePosition size="-4">{selection.position}{position_sign}</StyledRacePosition>
                <StyledRunnerImage src={selection.silk} />
            </div>
            <StyledColunm>
                <Text size="-3" className={css`margin-bottom:-1px;`}>
                    {selection.number}. {selection.name}
                     {/* ( {selection.number} ) */}
                </Text>
                {/* <div>
                    <StyledRow>
                        <Text size="-3" style={{width:"60px"}}>W: {selection.weight}</Text>
                        <Text size="-3">T: {selection.trainer}</Text>
                    </StyledRow>
                    <StyledRow>
                        <Text size="-3"style={{width:"60px"}}>F: {selection.f}</Text>
                        <Text size="-3">J: {selection.jockey}</Text>
                    </StyledRow>
                </div> */}
            </StyledColunm>
        </StyledRaceResultSelectionDetails>

    );
};

export { RaceResultSelectionDetails };