import React from 'react';
import GRSTopCarousel from '../../components/features/Carousel/GRSTopCarousel';
import styled, { css } from 'react-emotion';
import { media, application, spacings, brand } from '@tbh/ui-kit';
import filter from '../../images/icons/filters.png';
import cx from 'classnames/bind';
// import NtjRacesCarouselItemV4Header from '../../components/features/Racing/NtjRacesCarouselItemV4Header/NtjRacesCarouselItemV4Header';
import CarouselItemV4Loading from './../../components/features/Carousel/CarouselItemV4/CarouselItemV4Loading';
import {
	FilterButtonControler,
	FilterTextNextToJump,
} from '../../components/features/Racing/RaceNextJumpV5/RaceNextJumpV5';
import { DEFAULT_COLORS } from '../../constants/themes';
// import lazyComponent from `../../store/asyncReduxComponents.js`;
// //{ lazy, Suspense }

const StyledRaceNextJumpV5 = styled('div')(
	(props) => css`
		display: flex;
		flex-wrap: wrap;
		height: 64px;
		overflow: hidden;
		background: ${brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color};
		//border-bottom: 1px solid #ffff;
	`,
);
// Size at which the mode should change over
const modeChangeOverSize = 751;

const StyledRaceNextJumpV5__Carousel = styled(GRSTopCarousel)(
	(props) => css`
		label: RaceNextJumpV5__Carousel;

		flex: 1;
		height: auto;
		overflow: hidden;
		text-align: center;
		margin-left: 30px;
		margin-right: 30px;
	`,
	(props) =>
		media(
			css`
				flex: 1;
				height: auto;
				overflow: hidden;
			`,
			modeChangeOverSize,
		),
);

const StyledRaceNextJumpV5__NtjRacesCarouselItemV4Header = styled(CarouselItemV4Loading)(
	(props) => css`
		label: RaceNextJumpV5__NtjRacesCarouselItemV4Header;

		//width: ${application(props).racing.ntj_item_width}px;
		width:auto;
		padding: ${spacings(props).compact}px ${spacings(props).tight}px 0px 20px;
		box-sizing: border-box;
	`,
);

const NextToJumpRacesContainerV5Loading = ({ size }) => {
	// const lazComponent = React.lazy(() => import)
	const className = '';
	const componentClasses = cx({
		[className]: className,
	});

	const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
	return (
		<React.Fragment>
			<StyledRaceNextJumpV5 className={componentClasses}>
				<FilterButtonControler className="filterButton">
					<img className="filter-img" src={filter} />
					<FilterTextNextToJump className="filter-text">Next to Jump</FilterTextNextToJump>
				</FilterButtonControler>
				<StyledRaceNextJumpV5__Carousel size={size} nextButton prevButton freeMode buttonStyle="1">
					{array.map((item, i) => (
						<StyledRaceNextJumpV5__NtjRacesCarouselItemV4Header key={item.index} />
					))}
				</StyledRaceNextJumpV5__Carousel>
			</StyledRaceNextJumpV5>
		</React.Fragment>
	);
};

export default NextToJumpRacesContainerV5Loading;
