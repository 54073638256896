import {meetingSchema, selectionSchema} from '../Entities';
import {buildEntitySchema} from './relationshipSelectorHelpers';

/**
 * Grab all entities related to racing.
 * @type {*}
 */
export const getRacingEntities = buildEntitySchema(meetingSchema);

/**
 * Grab only entities related to racing selections.
 * @type {*}
 */
export const getRacingSelectionsEntities = buildEntitySchema(selectionSchema);