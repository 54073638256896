// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { ui, EmptyList } from '@tbh/ui-kit';

// Components
import BetSummaryBetListItem from '../BetSummaryBetListItem/BetSummaryBetListItem';

import { BET_TYPE_DERIVATIVE } from '../../../../../store/entities/constants/BetConstants';
import { QUADDIES_BET_TYPES } from '../../../../../common/constants/Racing';

const StyledBetSummaryBetList = styled(BetSummaryBetListItem)(
	(props) => css`
		border-bottom: 1px solid ${ui(props).color_3};
		background-color: ${ui(props).color_2};
		transition: background-color 0.2s ease-in-out;
		cursor: pointer;

		&:nth-child(even) {
			background-color: ${ui(props).color_1};
		}

		&:hover {
			background-color: ${ui(props).color_3};
		}
	`,
);

const BetSummaryBetList = (props) => {
	const { bets, className, onBetHeaderClick, onClickBetListItem, size, selectedTab, t } = props;

	const componentClasses = cx({
		[className]: className,
	});
	if (bets.length > 0) {
		return (
			<React.Fragment>
				{bets.map((bet) => {
					const currencyType = bet.free_credit_amount > 0 ? 'bonusCurrency' : 'currency';

					return (
						<StyledBetSummaryBetList
							size={size}
							className={componentClasses}
							key={bet.id}
							betId={bet.id}
							amount={bet.amount}
							bet_selections={bet.bet_type === BET_TYPE_DERIVATIVE ? bet.derivative_bet_selections : bet.bet_selections}
							all_bet_selections={bet.bet_selections}
							bet_type={bet.bet_type}
							boxed_flag={bet.boxed_flag}
							dividend={bet.dividend}
							is_exotic={bet.is_exotic || QUADDIES_BET_TYPES.includes(bet.bet_type)}
							is_fixed={bet.is_fixed}
							odds={bet.odds}
							display_amount={bet.display_amount}
							onBetHeaderClick={onBetHeaderClick}
							onClickBetListItem={onClickBetListItem}
							paid={bet.paid}
							percentage={bet.percentage}
							product_code={bet.product_code}
							product_type={bet.product_type}
							selection_string={bet.selection_string}
							status={bet.status}
							currencyType={currencyType}
							selectedTab={selectedTab}
						/>
					);
				})}
			</React.Fragment>
		);
	} else {
		return <EmptyList message={t('BetSummaryBetList__NoBets')} />;
	}
};

BetSummaryBetList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** User's bets */
	bets: PropTypes.arrayOf(PropTypes.object).isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Action for when clicking on the BetSummaryBetHeader */
	onBetHeaderClick: PropTypes.func.isRequired,

	/** Action for when clicking on the BetSummaryBetItem node */
	onClickBetListItem: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

BetSummaryBetList.defaultProps = {
	className: '',
};

export default withNamespaces()(BetSummaryBetList);
