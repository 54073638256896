import React from 'react';
import PropTypes from 'prop-types';
import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe.js';

import { connect } from 'react-redux';
import { PUSHER_EVENT_N2J_UPDATE, PUSHER_EVENT_UPDATE } from '../../../../common/constants/Racing';
import { nextNtjRace } from '../../../../store/entities/actions/NextToJumpRaceActions';

class PusherSubscriberNTJRacesChannel extends React.Component {
	static propTypes = {
		/** Action to update the next to jump (NTJ) races */
		onNTJRacesUpdate: PropTypes.func,
	};
	static defaultProps = {
		onNTJRacesUpdate: () => undefined,
	};

	/**
	 * Update handler for next to jump races.
	 */
	onNTJRacesUpdate = (data) => {
		this.props.onNTJRacesUpdate(data);
	};

	render() {
		return <PusherSubscribe channel={'racing_n2j'} event={PUSHER_EVENT_N2J_UPDATE} onUpdate={this.onNTJRacesUpdate} />;
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onNTJRacesUpdate: (data) =>
		ownProps.onNTJRacesUpdate ? ownProps.onNTJRacesUpdate(data) : dispatch(nextNtjRace(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberNTJRacesChannel);
