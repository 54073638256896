import styled from 'react-emotion';
import { COLORS, WEIGHTS, TYPE_SCALE } from '../../../constants/themes';

const Text = styled.p`
    color: ${({ color }) => color ? color : COLORS.white};
    font-weight: ${({ weight }) => weight ? weight : WEIGHTS.normal};
    font-size: ${({ size }) => size ? size : TYPE_SCALE.paragraph};
    padding-bottom: 6px;
    margin: 0;
`;

export default Text;