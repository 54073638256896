// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, media, HeaderBar, Tabs, TabsItem, Text } from '@tbh/ui-kit';

import ComputerTipsHeader from '../ComputerTips/ComputerTipsHeader/ComputerTipsHeader';
import {
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_THOROUGHBRED_CODE,
	SELECTION_NOT_SCRATCHED_STATUS,
} from '../../../../../common/constants/Racing';
import { SizeMe } from 'react-sizeme';

const StyledComputerTips = styled('div')(
	(props) => css`
		label: ComputerTips;
		display: flex;
		padding: ${spacings(props).compact}px;
	`,
);

const StyledComputerTips_MainSection = styled('div')(
	(props) => css`
		label: ComputerTips_MainSection;

		position: relative;
		display: flex;
		flex-direction: column;
		gap: ${spacings(props).compact}px;
		flex: 1 0 0;
		height: auto;
		flex-wrap: wrap;
		width: 100%;
	`,
);

const StyledComputer_main = styled('div')(
	(props) => css`
		flex: 1 0 calc(33.33% - ${spacings(props).compact}px); /* Set initial width for 3 columns */
	`,
);

const StyledComputerTips_header = styled('div')(
	(props) => css`
		label: ComputerTipss_header;
		display: flex;
		flex-wrap: wrap;
		height: auto;
		width: 100%;
		text-transform: none;
		text-transform: uppercase;
	`,
);

const StyledComputerTips__HeaderBar = styled(HeaderBar)`
	${(props) => css`
		label: ComputerTips__HeaderBar;

		background-color: #6666;
		width: 100%;
		text-transform: uppercase;
		// padding-left: ${spacings(props).compact}px;
		// padding-right: ${spacings(props).tight}px;
		height: 25px;
		display: flex;
		width: 100%;
		padding: 0px;
	`};

	${media(
		(props) =>
			css`
				background-color: #6666;
				text-transform: uppercase;
				padding-left: ${spacings(props).compact}px;
				padding-right: ${spacings(props).tight}px;
				height: 25px;
				display: flex;
				width: 100%;
				flex-wrap: wrap;
			`,
		768,
	)};
`;

const SelectionTipWrapper = styled('div')`
	${(props) => `
			label: SelectionTipWrapper;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 12px;
			padding: 8px;
			background: #dddd;
		`}
`;
const SelectionTip = styled('div')`
	${(props) => `
		label: SelectionTip;
		display: flex;
		flex-direction: row:
		justify-content: center;
		align-items: center;
		gap: 8px;
	`}
`;

const SelectionTipNumber = styled(Text)`
	padding: 4px;
	border-radius: 4px;
	background-color: #6666;
	height: 16px;
	width: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

class ComputerTips extends React.Component {
	static propTypes = {
		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Extra classes */
		className: PropTypes.string,

		raceRanking: PropTypes.array.isRequired,

		/** Action to track when a filter is applied */
		trackComputerTipsType: PropTypes.func,

		racingType: PropTypes.string,

		/** Handle when a selection is clicked on */
		handleClickSelection: PropTypes.func.isRequired,
	};

	static defaultProps = {
		className: '',
		trackComputerTipsType: () => undefined,
		racingType: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			// initially set all the type filters active
			typeFilter: new Set([]),
			showfilteredData: false,
		};
	}
  
	componentDidMount() {
	  // Get all available tip types and set them as selected
	  const allTipTypes = this.getTipsTypes();
	  this.setState({ typeFilter: new Set(allTipTypes) });
	}
  
	shouldComponentUpdate(nextProps, nextState) {
	  return this.props.raceRanking !== nextProps.raceRanking || this.state.typeFilter !== nextState.typeFilter;
	}
  
	showAllRaces = () => {
	  this.setState((prevState) => ({ showAll: !prevState.showAll }));
	};
  
	handleOnTipsFilterClick = (type) => {
	  this.saveTypeToFilters(type);
	  this.props.trackComputerTipsType(type);
	};
  
	saveTypeToFilters = (type) => {
	  this.setState((prevState) => {
		const types = new Set(prevState.typeFilter);
		if (types.has(type)) {
		  types.delete(type);
		} else {
		  types.add(type);
		}
		if (types.size !== 0) {
		  this.props.FilterClicked(true);
		}
		return { typeFilter: types };
	  });
	};
  
	groupBy = (data, key) => {
	  return data.reduce((acc, item) => {
		(acc[item[key]] = acc[item[key]] || []).push(item);
		return acc;
	  }, {});
	};
  
	getTipsTypes = () => {
	  const computerTipsGroup = this.groupBy(this.props.raceRanking, 'tips_type');
	  return Object.keys(computerTipsGroup);
	};
  
	getAllTipsGroupByRaceType = () => {
	  const computedTipsByRaceType = {
		[RACING_THOROUGHBRED_CODE]: ['RSTips', 'RTW', '12M', 'L12', 'CareerSR', 'ForAgainst', 'WetSR'],
		[RACING_GREYHOUNDS_CODE]: ['RSTips', 'RTW', '12M', 'L12', 'CareerSR'],
		[RACING_HARNESS_CODE]: ['RSTips', 'RTW', '12M', 'L12', 'CareerSR', 'ForAgainst'],
	  };
	  const computerTipsGroup = this.groupBy(
		this.props.raceRanking.filter((s) => computedTipsByRaceType[this.props.racingType].includes(s.tips_type)),
		'tips_type'
	  );
	  Object.keys(computerTipsGroup).forEach((key) => {
		computerTipsGroup[key].sort((a, b) => b.points - a.points);
	  });
	  return computerTipsGroup;
	};
  
	calculatePoints = (groupTips) => {
	  const result = [];
	  for (const key of Object.keys(groupTips)) {
		if (this.state.typeFilter.has(key)) {
		  groupTips[key].forEach((value, i) => {
			const points = Math.max(3 - i, 0);
			const index = result.findIndex((x) => x.name === value.name);
			if (index === -1 && points > 0) {
			  result.push({
				id: value.id,
				name: value.name,
				tab: value.tab,
				points,
				barrier: value.barrier,
				silk: value.silk,
				selection_status: value.selection_status,
				prices: value.prices,
			  });
			} else if (index !== -1) {
			  result[index].points += points;
			}
		  });
		}
	  }
	  return result.sort((a, b) => b.points - a.points);
	};
  
	filterComputerTipsSelections = (rankings = [], types = []) => {
	  if (!types) {
		return rankings;
	  }
	  if (!Array.isArray(types)) {
		types = [types];
	  }
	  const items = rankings.filter((tip) => types.includes(tip.tips_type));
	  return items.length ? items : rankings;
	};
  
	clearFilter = () => {
	  this.setState({ typeFilter: new Set([]) });
	};
  
	render() {
	  const { className, size } = this.props;
	  const componentClasses = cx(className);
  
	  const computerTipsGroup = this.getAllTipsGroupByRaceType();
	  const getSumPointsOfFilteredSelections = this.calculatePoints(computerTipsGroup);
  
	  return (
		<SizeMe>
		  {({ size }) => (
			<StyledComputerTips>
			  <StyledComputerTips_MainSection>
				<StyledComputerTips_header>
				  {Object.keys(computerTipsGroup).map((tip, ke) => (
					<StyledComputer_main key={ke} size={size}>
					  <StyledComputerTips__HeaderBar size={size}>
						<ComputerTipsHeader
						  computerTips={Array.from(this.state.typeFilter)}
						  tip={tip}
						  size={size}
						  onFilterItemClick={this.handleOnTipsFilterClick}
						/>
					  </StyledComputerTips__HeaderBar>
  
					  <Tabs isColumn padding={Tabs.paddings.SPACING_NONE} noBorder>
						{computerTipsGroup[tip]
						  .sort((a, b) => b.points - a.points)
						  .slice(0, 3)
						  .map(
							(item) =>
							  tip === item.tips_type && (
								<TabsItem
								  key={item.id}
								  id={item.id}
								  className={css`
									font-size: 12px;
									cursor: auto !important;
									&:hover {
									  cursor: none;
									}
								  `}
								>
								  <div className="row">
									<div className="col-md-10">
									  {item.selection_status === SELECTION_NOT_SCRATCHED_STATUS ? (
										<div
										  className={css`
											font-size: 10px;
										  `}
										>
										  {item.tab + '. '}
										  {item.name}
										</div>
									  ) : (
										<div
										  className={css`
											opacity: 0.7;
											filter: grayscale(100%);
											text-decoration: line-through;
										  `}
										>
										  {' '}
										  {item.tab + '. '}
										  {item.name}{' '}
										</div>
									  )}
									</div>
								  </div>
								</TabsItem>
							  )
						  )}
					  </Tabs>
					</StyledComputer_main>
				  ))}
				</StyledComputerTips_header>
  
				{Array.from(this.state.typeFilter).length > 0 && (
				  <SelectionTipWrapper>
					{getSumPointsOfFilteredSelections.map((item, key) => (
					  <SelectionTip key={key}>
						<SelectionTipNumber size="-4">{item.tab}</SelectionTipNumber>
						<Text size="-4">
						  {item.name} <strong>({item.points}pts)</strong>
						</Text>
					  </SelectionTip>
					))}
				  </SelectionTipWrapper>
				)}
			  </StyledComputerTips_MainSection>
			</StyledComputerTips>
		  )}
		</SizeMe>
	  );
	}
  }
  
  export default ComputerTips;
  


