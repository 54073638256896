import { Button, Popover, spacings, Text } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';
import Switch from 'react-switch';
import rocket from '../../../images/user-buttons-icons/rocket.png';
// import bonus from '../../../images/user-buttons-icons/bonus.png';
// import betReturns from '../../../images/user-buttons-icons/bet-returns-black.svg';

const DetailsPopUp = styled(Popover)(
	(props) => css`
		label: UserInfoContainer;
		right: 10px;
		top: 100%;
		width: 100%;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		span {
			display: none;
		}
		border-radius: 0px 0px 12px 12px;
		${!props.isFullWidth &&
			css`
				max-width: 260px;
			`};
	`,
);

const DetailsContainer = styled('div')(
	(props) => css`
		label: UserInfoContainer;

		display: flex;
		flex-direction: column;
		align-items: start;
		padding: ${spacings(props).cozy}px;
		gap: ${spacings(props).cozy}px;
		background: white;
		border-radius: 0px 0px 12px 12px;
	`,
);

const DetailsWrapper = styled('div')(
	(props) => css`
		label: DetailsWrapper;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: start;
		padding: ${spacings(props).compact}px;
	`,
);

const EspecialWrapper = styled('div')(
	(props) => css`
		label: EspecialWrapper;
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	`,
);

const SwitchWrapper = styled('div')(
	(props) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: ${spacings(props).tight}px;

		div {
			cursor: pointer;
			&:hover {
				color: #c72128 !important;
			}
		}
	`,
);

const EspecialContainer = styled('div')(
	(props) => css`
		label: EspecialContainer;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: ${spacings(props).tight}px;
	`,
);

const EspecialContainerContent = styled('div')(
	(props) => css`
		label: EspecialContainerContent;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: ${spacings(props).tight}px;
		padding: ${spacings(props).tight}px;
		width: 64px;
		border-radius: 50px;
		background-color: #f2f2f2;
		padding: 4px 8px;
		img {
			width: 16px;
			height: 16px;
		}
	`,
);
export const DetailsNav = styled('ul')(
	(props) => css`
		label: DetailsNav;

		display: flex;
		flex-direction: column;
		align-items: start;
		width: 100%;
		padding-top: ${spacings(props).cozy}px;
		margin: 0;
		list-style: none;
		gap: ${spacings(props).cozy}px;
		border-top: 1px solid #e5e5e5;
		li {
			cursor: pointer;

			div {
				color: black !important;
				&:hover {
					color: #c72128 !important;
				}
			}
		}
	`,
);

export const LogOutButton = styled(Button)(
	(props) => css`
		label: LogOutButton;
		border-radius: 4px;
		background-color: #c72128;
		color: #ffffff;
		padding: 4px;
		width: 100%;
	`,
);

const AccountDetailsPopUp = ({
	first_name,
	logout,
	open,
	isFullWidth,
	onOutsideClick,
	toggleShowBalance,
	showBalance,
	bailOutLimit,
	cashout_limit = 0,
	pricePumpLimit,
	goToWithdrawal,
	goToAccount,
	goToAccountTransactions,
	onAddFunds,
	onLogout,
	free_credit_balance,
	withdrawable_balance,
	bonus_bet_enabled,
	price_bump_enabled,
	account_balance,
	isMobile,
	depositsAndWithdrawalsEnabled,
}) => {
	const hiddenBalanceText = showBalance ? 'Show Balance' : 'Hide Balance';

	const navOptions = [
		{
			title: 'My Bets',
			action: () => goToAccountTransactions(),
		},
		{
			title: 'Deposit',
			action: () => onAddFunds(),
			disabled: !depositsAndWithdrawalsEnabled,
		},
		{
			title: 'Withdraw',
			action: () => goToWithdrawal(),
			disabled: !depositsAndWithdrawalsEnabled,
		},
		{
			title: 'My Account',
			action: () => goToAccount(),
		},
	];

	return (
		<DetailsPopUp open={open} isFullWidth={isFullWidth} onOutsideClick={onOutsideClick}>
			<DetailsContainer>
				<DetailsWrapper>
					<Text size="-3">
						Welcome <strong>{first_name}</strong>
					</Text>

					<SwitchWrapper onClick={toggleShowBalance}>
						<Text size="-3" id="hide_show_balance">
							{hiddenBalanceText}
						</Text>

						<Switch onChange={toggleShowBalance} checked={showBalance} width={30} height={15} />
					</SwitchWrapper>
				</DetailsWrapper>
				<EspecialWrapper>
					{price_bump_enabled && (
						<EspecialContainer>
							<Text size="-3">Price Boost</Text>

							<EspecialContainerContent>
								<img src={rocket} alt="rocket" />
								<Text size="0" bold>
									{pricePumpLimit}
								</Text>
							</EspecialContainerContent>
						</EspecialContainer>
					)}

					{/* <EspecialContainer> */}
					{/* <Text size="-3">Bonus</Text>
						<EspecialContainerContent>
							<img src={bonus} alt="bonus" />
							<Text size="0" bold>
								{free_credit_balance}
							</Text>
						</EspecialContainerContent>
					</EspecialContainer>
					<EspecialContainer>
						<Text size="-3">Bet returns</Text>
						<EspecialContainerContent>
							<img src={betReturns} alt="bonus" />
							<Text size="0" bold>
								{cashout_limit ? cashout_limit : 0}
							</Text>
						</EspecialContainerContent>
					</EspecialContainer> */}
				</EspecialWrapper>

				<Text size="-3">
					<strong>Withdrawable balance: </strong>
					{showBalance ? `$${withdrawable_balance ? withdrawable_balance : 0}` : 'Hidden'}
				</Text>

				{bonus_bet_enabled && (
					<Text size="-3">
						<strong>Bonus balance: </strong>
						{showBalance ? `$${free_credit_balance ? free_credit_balance : 0}` : 'Hidden'}
					</Text>
				)}

				<DetailsNav>
					{navOptions
						.filter((option) => !option.disabled)
						.map((option, index) => (
							<li key={option.index + option.title} onClick={option.action}>
								<Text size="-1">{option.title}</Text>
							</li>
						))}
				</DetailsNav>

				<LogOutButton action={onLogout}>LOG OUT</LogOutButton>
			</DetailsContainer>
		</DetailsPopUp>
	);
};

export default AccountDetailsPopUp;
