import { denormalizeTickets } from '../schemas/TicketSchema';

const getUserTickets = (tickets, authenticatedUserId) => {
	return tickets.filter((ticket) => {
		return ticket.user_id === authenticatedUserId;
	});
};

/**
 * Get all tickets
 *
 * @param entities
 * @returns {Array}
 */
const getAllTickets = (entities) => {
	const denormalizedTickets = denormalizeTickets(entities);

	return denormalizedTickets.sort((a, b) => (a.tournament.start_date < b.tournament.start_date ? 1 : -1));
};

module.exports = {
	getUserTickets,
	getAllTickets,
};
