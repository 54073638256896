// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings, typography, ui, Button, HeaderBar, NotFound, Panel, PlotElements, Input } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

// Components
import TrendingBet from './TrendingBet';
import Range from '../../../ui-elements/Forms/Range/Range';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

// Functions
import { formatAsOdds, getRaceType, slugify } from '../../../../legacy/core/format';
import { monetize } from '../../../../legacy/core/format';

const StyledTrendingBets__BetsSection = styled('div')(
	(props) => css`
		label: TrendingBets__BetsSection;

		background-color: ${ui(props).color_2};
	`,
);

const StyledTrendingBets__Row = styled('div')(
	(props) => css`
		label: TrendingBets__Row;

		display: flex;
		justify-content: space-between;
		font-size: ${typography(props)['size_-1']};
		font-weight: ${typography(props).weight.bold};
		color: ${typography(props).color_1};
		padding: ${spacings(props).cozy}px;

		${props.bottomBorder &&
			css`
				border-bottom: 1px ${ui(props).color_4} solid;
			`};

		${props.noPaddingTop &&
			css`
				padding-top: 0;
			`};
	`,
);
const StyledTrendingBets__PlotRow = StyledTrendingBets__Row.withComponent(PlotElements);

const StyledTrendingBets__Stake = styled(Input)(
	(props) => css`
		label: TrendingBets__Stake;

		font-size: ${typography(props)['size_-1']};
		text-align: right;
		margin: 0;
		max-width: 72px;
		color: inherit;
	`,
);

const commonBetProps = {
	/** The bet type made against the bet */
	bet_type: PropTypes.string.isRequired,

	/** The event the bet selection is part of */
	event_name: PropTypes.string.isRequired,

	/** The ID of the bet */
	id: PropTypes.number.isRequired,

	/** The market the bet belongs to */
	market_name: PropTypes.string.isRequired,

	/** The bet odds */
	odds: PropTypes.number.isRequired,

	/** The name of the selection in the bet */
	selection_name: PropTypes.string.isRequired,
};

/**
 * Trending Bets
 * -------------
 * Component to show trending bets
 *
 * @module components/ui/TrendingBets
 */
class TrendingBets extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The list of trending bets */
		bets: PropTypes.arrayOf(
			PropTypes.oneOfType([
				// Racing
				PropTypes.shape({
					...commonBetProps,

					/** The type of the event */
					event_type: PropTypes.string.isRequired,

					/** The race number in the meeting */
					race_number: PropTypes.number.isRequired,
				}),

				// Sport
				PropTypes.shape({
					...commonBetProps,

					/** The name of the sport */
					sport_name: PropTypes.string.isRequired,

					/** The line for the selection */
					selection_line: PropTypes.number.isRequired,
				}),
			]),
		).isRequired,

		/** Action to add all trending bets to the bet slip */
		onClickAdd: PropTypes.func.isRequired,

		/** Action when a bet event is clicked on */
		onClickEvent: PropTypes.func.isRequired,

		/** Action if a single bet has their odds clicked on */
		onClickSingle: PropTypes.func.isRequired,

		/** The default stake amount to apply */
		defaultStake: PropTypes.number,

		/** The limit that can be won for all bets */
		winLimit: PropTypes.number,
	};

	static defaultProps = {
		defaultStake: 1000,
		winLimit: 999999,
	};

	state = {
		stake: formatAsOdds(this.props.defaultStake / 100),
	};

	/**
	 * Multiplies all odds to determine the current odds.
	 * Fix to 2 decimal places then return as number.
	 * Logic set to better calculate potential winnings.
	 *
	 * @returns {Number}
	 */
	calculateOdds = () => {
		return +this.props.bets
			.reduce((odds, bet) => {
				return odds * bet.odds;
			}, 1)
			.toFixed(2);
	};

	/**
	 * Performed when the 'Add to Bet Slip' button is clicked.
	 */
	onClickAdd = () => {
		this.props.onClickAdd(this.state.stake * 100);
	};

	/**
	 * Performed when the stake is changed.
	 *
	 * @param name
	 * @param value
	 */
	handleOnStakeChange = (name, value) => {
		const winLimit = this.props.winLimit;
		const odds = this.calculateOdds();
		const stake = value * odds > winLimit / 100 ? (winLimit / odds / 100).toFixed(2) : value;

		this.setState({ stake });
	};

	/**
	 * Format as currency when input loses focus.
	 */
	handleOnInputBlur = () => {
		this.setState({ stake: monetize(this.state.stake, '0,0.00') });
	};
	/**
	 * Performed when the winnings are changed.
	 *
	 * @param e
	 */
	onWinningsChange = (e) => {
		/*
		Grab new value, calculate, fix to 2 decimal places and save.
		 */
		this.setState({
			stake: (+e.target.value / this.calculateOdds()).toFixed(2),
		});
	};

	/**
	 * Parses a given bet into useful data depending on bet type.
	 *
	 * @param bet
	 * @returns {Object}
	 */
	parseBet = (bet) => {
		if (bet.bet_type === 'sport') {
			return {
				event: bet.event_name,
				icon: slugify(bet.sport_name),
				line: bet.selection_line,
			};
		}

		return {
			event: `Race #${bet.race_number} - ${bet.event_name}`,
			icon: slugify(getRaceType(bet.event_type)),
		};
	};

	render() {
		const { t, bets, winLimit } = this.props;

		// Determine stake
		const stake = parseInt(this.state.stake) || 0;
		const odds = this.calculateOdds();
		const potentialWinnings = stake * odds * 100;

		// Section header
		const headerBar = (
			<HeaderBar type="primary" strong>
				{t('TrendingBets__Heading')}
			</HeaderBar>
		);

		return (
			<div>
				{headerBar}

				{bets.length ? (
					<Panel borderType="default">
						<StyledTrendingBets__BetsSection>
							{bets.map((bet) => {
								let { event, icon } = this.parseBet(bet);

								return (
									<TrendingBet
										icon={icon}
										eventName={event}
										selectionName={bet.selection_name}
										odds={bet.odds}
										line={bet.selection_line}
										key={bet.id}
										id={bet.id}
										marketName={bet.market_name}
										onButtonClick={this.props.onClickSingle}
										onEventClick={this.props.onClickEvent}
									/>
								);
							})}
						</StyledTrendingBets__BetsSection>

						<StyledTrendingBets__PlotRow align="space-between" bottomBorder>
							<div>{`${t('Odds')} ${formatAsOdds(odds)}`}</div>

							<PlotElements align="end">
								<span>
									{`${t('Stake')}&nbsp;`}
								</span>
								<CurrencyDisplay />
							</PlotElements>

							<StyledTrendingBets__Stake
								type="number"
								onChange={this.handleOnStakeChange}
								onBlur={this.handleOnInputBlur}
								min={0}
								max={formatAsOdds(winLimit / odds / 100 + 1)}
								step={1}
								placeholder={formatAsOdds(this.props.defaultStake / 100)}
								value={this.state.stake}
							/>
						</StyledTrendingBets__PlotRow>

						<StyledTrendingBets__Row>
							<Range
								id="trending-bets"
								label={t('PotentialWinnings')}
								value={potentialWinnings}
								step={0.1}
								action={this.onWinningsChange}
								max={winLimit / 100}
								min={0}
							/>
						</StyledTrendingBets__Row>

						<StyledTrendingBets__Row noPaddingTop>
							<Button bold block type={'primary'} action={this.onClickAdd}>
								{t('AddToBetSlip')}
							</Button>
						</StyledTrendingBets__Row>
					</Panel>
				) : (
					<NotFound message={t('TrendingBets__NoBetsMessage')} />
				)}
			</div>
		);
	}
}

export default withNamespaces()(TrendingBets);