// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import moment from 'moment';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { application, brand, spacings, media } from '@tbh/ui-kit';

// Components
import { Button, Text, Link } from '@tbh/ui-kit';

// Functions
import F from '../../../../../legacy/core/format';

// Context
import AclContext from '../../../../../contexts/AclContext';

const StyledTimerHelpSection__Help = styled(Text)(
	(props) => css`
		label: TimerHelpSection__Help;

		margin-left: ${spacings(props).cozy}px;
	`,
);

const StyledTimerHelpSection__Wrapper = styled('div')(
	css`
		label: TimerHelpSection__Wrapper;
	`,
	(props) =>
		media(
			css`
				display: flex;
				width: 100%;
				justify-content: flex-end;
				align-items: center;
				padding: 0 ${spacings(props).compact}px;
				margin: 0 auto;

				${!props.isFullWidth &&
					css`
						max-width: ${application(props).max}px;
					`};
			`,
			481,
		),
);

const StyledTimerHelpSection = styled('div')(
	(props) => css`
		label: TimerHelpSection;

		display: none;
		height: 30px;
		background-color: ${brand(props).color_2_dark};
	`,
	media(
		css`
			display: none;
		`,
		481,
	),
);

class TimerHelpSection extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Whether or not there is a max-width restriction on the site */
		isFullWidth: PropTypes.bool.isRequired,

		/** The size of the component */
		size: PropTypes.shape({
			/** How wide the component is */
			width: PropTypes.number,
		}).isRequired,

		/** Open the Acl Picker */
		onOpenAclPicker: PropTypes.func,

		/** Open the Theme Picker */
		onOpenThemePicker: PropTypes.func,

		/** The time to start the session timer from */
		start_time: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		onOpenAclPicker: null,
		onOpenThemePicker: null,
		start_time: null,
	};

	state = {
		elapsed: 0,
	};

	componentDidMount = () => {
		this.timer = window.setInterval(this.tick, 1000);
	};

	componentWillUnmount = () => {
		this.unmounted = true; // Set this on the instance so you can tell it is unmounting
		window.clearInterval(this.timer);
	};

	/**
	 * Updates the state by ticking down 1 second
	 */
	tick = () => {
		// Do nothing as the component has unmounted
		if (this.unmounted) {
			return;
		}

		this.setState({
			elapsed: moment() - moment(this.props.start_time),
		});
	};

	render() {
		const { className, size, onOpenAclPicker, onOpenThemePicker, isFullWidth, t } = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledTimerHelpSection className={componentClasses} size={size}>
				{onOpenAclPicker && (
					<Button action={onOpenAclPicker} size="small">
						ACL
					</Button>
				)}
				{onOpenThemePicker && (
					<Button action={onOpenThemePicker} size="small">
						THEME
					</Button>
				)}

				<StyledTimerHelpSection__Wrapper size={size} isFullWidth={isFullWidth}>
					<Text tag="span" type="alternate" size="-2" strong>
						{t('TimerHelpSection__SessionTime')}: <span>{F.timerForHeader(this.state.elapsed / 1000)}</span>
					</Text>

					<AclContext.Consumer>
						{(acl) =>
							acl.brandDetails && acl.brandDetails.phone ? (
								<StyledTimerHelpSection__Help tag="span" type="primary" size="-2" strong>
									{t('NeedHelp')}{' '}
									<Link href={`tel:${acl.brandDetails.phone}`} type={Link.types.PRIMARY} size="-2" strong>
										{acl.brandDetails.phone}
									</Link>
								</StyledTimerHelpSection__Help>
							) : null
						}
					</AclContext.Consumer>
				</StyledTimerHelpSection__Wrapper>
			</StyledTimerHelpSection>
		);
	}
}

export default withNamespaces()(TimerHelpSection);
