import React, { Component } from 'react';
import { uniqueId } from 'lodash-es';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, Text, Link, NumberPad } from '@tbh/ui-kit';

import PINEnteredNumber from './PINEnteredNumber/PINEnteredNumber';

// Styling
const StyledPINAuthentication = styled('div')(
	(props) => css`
		label: PINAuthentication;

		margin-top: ${spacings(props).comfortable}px;
	`,
);

const StyledPINAuthentication__EnteredContainer = styled('div')(
	(props) => css`
		label: PINAuthentication__EnteredContainer;

		display: flex;
		justify-content: center;
		align-items: flex-end;
		height: 50px;
		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledPINAuthentication__PINEnteredNumber = styled(PINEnteredNumber)(
	(props) => css`
		label: PINAuthentication__PINEnteredNumber;

		margin: 0 ${spacings(props).compact}px;
	`,
);

const StyledPINAuthentication__NumberPadWrapper = styled('div')(
	(props) => css`
		label: PINAuthentication__NumberPadWrapper;

		display: flex;
		background-color: ${ui(props).color_3};
		justify-content: center;
		align-items: center;
		padding: ${spacings(props).cozy}px 0;
	`,
);

const cssPINAuthentication__Message = css`
	label: PINAuthentication__Message;

	height: 30px;
	text-align: center;
	font-weight: bold;
`;

class PINAuthentication extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Action when the PIN process is to be completed */
		onPINComplete: PropTypes.func.isRequired,

		/** Default message to show for PIN Authentication */
		defaultMessage: PropTypes.node.isRequired,

		/** Action to reset the PIN */
		handleReset: PropTypes.func,

		/** How many numbers are needed for the PIN Authentication */
		pinLength: PropTypes.number,

		/** The provided PIN */
		pin: PropTypes.string,

		/** PIN to match against */
		mustMatch: PropTypes.any,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		pinLength: 4,
		pin: '',
		mustMatch: false,
		handleReset: function () { },
		className: null,
	};

	state = {
		error: '',
		pin: this.props.pin,
		prevProps: this.props,
	};

	/**
	 * When the component receives new props, check to see if there is a pin. if so, then set the pin state as well as
	 * running the validation on the newly supplied PIN
	 *
	 * @param nextProps
	 * @param prevState
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		const prevProps = prevState.prevProps;
		const pin = prevProps.pin !== nextProps.pin ? nextProps.pin : prevState.pin;

		return {
			pin,
			prevProps: nextProps,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.pin !== prevState.pin) {
			this.validatePIN();
		}
	}

	/**
	 * Validate the pin and call callbacks
	 */
	validatePIN = () => {
		let newPin = this.state.pin;

		// First step is to make sure that the PINs are the right length. If they aren't, then the PIN is not valid
		if (!newPin || newPin.length !== this.props.pinLength) {
			return;
		}

		// If the mustMatch value is is false, then the pin IS valid
		// If the pin match a given pin and the length is correct, call success
		if (!this.props.mustMatch || this.props.mustMatch === newPin) {
			this.props.onPINComplete(newPin);

			this.setState({
				pin: '',
			});
		}
	};

	handleDelete = () => {
		this.setState((prevState) => {
			let oldPin = prevState.pin.split('');
			oldPin.pop();
			return {
				pin: oldPin.join(''),
			};
		});
	};

	/**
	 * Handle the input of a new PIN item. Call callbacks at necessary places
	 *
	 * @param inputValue
	 */
	handleInput = (inputValue) => {
		this.setState((prevState) => {
			let newPin = prevState.pin + inputValue;

			// If the new pin is longer than it should be, then don't do anything
			if (newPin.length > this.props.pinLength) {
				return prevState;
			}

			let nextState = {
				pin: newPin,
			};

			// If there is a must match value, the length of the pin is the same as the required length, BUT the pins do not match,
			// then set an error and reset the PIN
			if (this.props.mustMatch && newPin.length === this.props.pinLength && newPin !== this.props.mustMatch) {
				nextState.error = this.props.t('PINAuthentication__Error');
				nextState.pin = '';
			} else {
				nextState.error = '';
			}

			return nextState;
		});
	};

	getMessage = () => {
		let message = this.props.defaultMessage;
		let pin = this.state.pin || '';
		if (this.state.error) {
			message = (
				<span>
					{this.state.error}{' '}
					<Link action={this.props.handleReset} strong underline>
						Start Again?
					</Link>
				</span>
			);
		} else if (this.props.mustMatch) {
			if (pin === this.props.pinLength && this.state.pin !== this.props.mustMatch) {
				message = this.props.t('PINAuthentication__Error');
			}
		}

		return <div className={cssPINAuthentication__Message}>{message}</div>;
	};

	render() {
		const { className, pinLength, t } = this.props;

		// Split the PIN into an array so it can be processed into a component
		let pin = this.state.pin ? this.state.pin.split('') : [];
		let remaining = pinLength - pin.length;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledPINAuthentication className={componentClasses}>
				{this.getMessage()}
				<StyledPINAuthentication__EnteredContainer>
					{pin.map((item) => (
						<StyledPINAuthentication__PINEnteredNumber
							key={`${item}${uniqueId('_pin-authentication_')}`}
							number={item}
						/>
					))}
					{[...Array(remaining).keys()].map((item) => (
						<StyledPINAuthentication__PINEnteredNumber key={`remaining_${item}`} />
					))}
				</StyledPINAuthentication__EnteredContainer>
				<StyledPINAuthentication__NumberPadWrapper>
					<NumberPad
						className={css`
							label: PINAuthentication__NumberPad;

							max-width: 320px;
							min-width: 300px;
							height: 340px;
						`}
						onSelectInput={this.handleInput}
						rightButton={<Text action={this.handleDelete}>{t('Delete')}</Text>}
					/>
				</StyledPINAuthentication__NumberPadWrapper>
			</StyledPINAuthentication>
		);
	}
}

export default withNamespaces()(PINAuthentication);
