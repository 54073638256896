import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { typography, spacings, ui, brand } from '../../theme';
import * as tbicons from './tbicon-definitions';
import camelize from 'underscore.string/camelize';

/**
 * Get the color for the Icon
 *
 * @param props
 * @returns {string}
 */
const getIconColor = (props) => {
	switch (props.color) {
	case 'primary':
		return brand(props).color_1;

	case 'secondary':
		return brand(props).color_2;

	case 'white':
		return typography(props).color_1_alternate;

	case 'success':
		return ui(props).color_success;

	case 'danger':
		return ui(props).color_danger;

	case 'warning':
		return ui(props).color_warning;

	default:
		return '';
	}
};

const StyledIcon = styled('i')(
	(props) => css`
	label: Icon;

	// Icomoon font styles from https://icomoon.io/app/#/select 
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'tbicons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// END: Icomoon font styles

	position: relative;
	margin: 0 ${spacings(props).tight * props.push}px 0 0;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:before {
		font-family: 'tbicons' !important;
		font-size: 1em;
		font-weight: normal;
		line-height: 1;
		content: '${tbicons[`${camelize(props.icon)}`]}';
		display: inline-block;
		flex: 1 0 auto;
	}

	font-size: ${typography(props)[`size_${props.size}`]};
	
	color: ${getIconColor(props)}; 

	${props.onClick && 'cursor: pointer;'};
`,
);

const Icon = (props) => {
	const { className, style, icon, size, color, push, action } = props;

	const iconClass = cx({
		[className]: className,
	});

	const elementProps = {
		color,
		size,
		push,
		icon,
		className: iconClass,
		title: icon,
	};

	if (style) {
		elementProps.style = style;
	}

	if (action) {
		elementProps.onClick = action;
	}

	return <StyledIcon {...elementProps} />;
};

Icon.propTypes = {
	/** The icon to display */
	icon: PropTypes.string.isRequired,

	/** Custom styles */
	style: PropTypes.shape(),

	/** Extra classes */
	className: PropTypes.string,

	/** The size of the icon */
	size: PropTypes.oneOf(['-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5', '6']),

	/** margin-right to add to the icon */
	push: PropTypes.oneOf(['0', '1', '2', '3', '4', '5']),

	/** Color to use for the icon */
	color: PropTypes.oneOf(['primary', 'secondary', 'white', 'success', 'danger', 'warning']),

	/** Action if the icon is clicked on */
	action: PropTypes.func,
};

Icon.defaultProps = {
	className: null,
	style: null,
	size: '0',
	push: '0',
	color: null,
	action: undefined,
};

export default Icon;
