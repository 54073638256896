export const SET_ACTIVE_TOURNAMENT_ACTIVE_MODAL = 'SET_ACTIVE_TOURNAMENT_ACTIVE_MODAL';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_AUTO_ACCEPT = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_AUTO_ACCEPT';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_CONFIRMED = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_CONFIRMED';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_ERROR_MESSAGE = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_ERROR_MESSAGE';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_EVENT_ID = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_EVENT_ID';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_IS_LOADING = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_IS_LOADING';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_RECEIPTS = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_RECEIPTS';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_EXOTIC = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_EXOTIC';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_PRODUCT = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_PRODUCT';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_SELECTION = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_SELECTION';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_STAKE = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_STAKE';
export const SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_TYPE = 'SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_TYPE';
export const SET_ACTIVE_TOURNAMENT_COMMENTS = 'SET_ACTIVE_TOURNAMENT_COMMENTS';
export const SET_ACTIVE_TOURNAMENT_COMPETITION_FILTER = 'SET_ACTIVE_TOURNAMENT_COMPETITION_FILTER';
export const SET_ACTIVE_TOURNAMENT_EVENTS_ARE_LOADING = 'SET_ACTIVE_TOURNAMENT_EVENTS_ARE_LOADING';
export const SET_ACTIVE_TOURNAMENT_MARKET_GROUP = 'SET_ACTIVE_TOURNAMENT_MARKET_GROUP';
export const SET_ACTIVE_TOURNAMENT_MARKET_TYPE_FILTER = 'SET_ACTIVE_TOURNAMENT_MARKET_TYPE_FILTER';
export const SET_ACTIVE_TOURNAMENT_PLAYER_PROFILE_BETS_LOADING = 'SET_ACTIVE_TOURNAMENT_PLAYER_PROFILE_BETS_LOADING';
export const SET_ACTIVE_TOURNAMENT_RACE_FILTER = 'SET_ACTIVE_TOURNAMENT_RACE_FILTER';
export const SET_ACTIVE_TOURNAMENT_RACE_IS_LOADING = 'SET_ACTIVE_TOURNAMENT_RACE_IS_LOADING';
export const SET_ACTIVE_TOURNAMENT_REBUY_ERROR_MESSAGE = 'SET_ACTIVE_TOURNAMENT_REBUY_ERROR_MESSAGE';
export const SET_ACTIVE_TOURNAMENT_REBUY_IS_LOADING = 'SET_ACTIVE_TOURNAMENT_REBUY_IS_LOADING';
export const SET_ACTIVE_TOURNAMENT_SELECTED_USER = 'SET_ACTIVE_TOURNAMENT_SELECTED_USER';
export const SET_ACTIVE_TOURNAMENT_SLEDGE_LOADING = 'SET_ACTIVE_TOURNAMENT_SLEDGE_LOADING';
export const SET_ACTIVE_TOURNAMENT_TICKET_ID = 'SET_ACTIVE_TOURNAMENT_TICKET_ID';
export const SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_ERROR_MESSAGE = 'SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_ERROR_MESSAGE';
export const SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_LOADING = 'SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_LOADING';
export const SET_ACTIVE_TOURNAMENT_TITLE_IS_COLLAPSED = 'SET_ACTIVE_TOURNAMENT_TITLE_IS_COLLAPSED';
export const SET_ACTIVE_TOURNAMENT_TOURNAMENT_BETS_IS_COLLAPSED = 'SET_ACTIVE_TOURNAMENT_TOURNAMENT_BETS_IS_COLLAPSED';
export const SET_ACTIVE_TOURNAMENT_MY_PROGRESS_COLLAPSED = 'SET_ACTIVE_TOURNAMENT_MY_PROGRESS_COLLAPSED';
export const SET_ACTIVE_TOURNAMENT_COMPETITIONS_IS_COLLAPSED = 'SET_ACTIVE_TOURNAMENT_COMPETITIONS_IS_COLLAPSED';
export const SET_ACTIVE_TOURNAMENT_TOURNAMENT_ID = 'SET_ACTIVE_TOURNAMENT_TOURNAMENT_ID';
export const SET_ACTIVE_TOURNAMENT_TOURNAMENT_TYPE = 'SET_ACTIVE_TOURNAMENT_TOURNAMENT_TYPE';
export const SET_ACTIVE_TOURNAMENT_DETAIL_TAB = 'SET_ACTIVE_TOURNAMENT_DETAIL_TAB';
export const RESET_ACTIVE_TOURNAMENT_FILTERS = 'RESET_ACTIVE_TOURNAMENT_FILTERS';
export const SET_ACTIVE_TOURNAMENT_SELECTED_EVENT = 'SET_ACTIVE_TOURNAMENT_SELECTED_EVENT';
export const SET_ACTIVE_TOURNAMENT_EVENT_MARKET_FILTER = 'SET_ACTIVE_TOURNAMENT_EVENT_MARKET_FILTER';
export const SET_MARKETS_FOR_EVENT = 'SET_MARKETS_FOR_EVENT';