import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import { Icon, HeaderBar, Tabs, TabsItem } from '@tbh/ui-kit';
import { slugify } from '../../../../legacy/core/format';

const SportsList = (props) => {
	const { className, onItemClick, onPlayerBetsClick, playerBets, sports, hideHeader } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			{!hideHeader && <HeaderBar type="primary">SPORTS</HeaderBar>}

			<Tabs isColumn noBorder>
				{sports.map((item) => {
					return (
						<TabsItem key={item.id} pointer action={onItemClick} data={item.name}>
							<Icon push="2" icon={slugify(item.name)} />
							{item.name}
						</TabsItem>
					);
				})}
				{playerBets.id && (
					<TabsItem key={playerBets.id} pointer action={onPlayerBetsClick} data={playerBets}>
						<Icon push="2" icon={playerBets.icon} />
						{playerBets.title}
					</TabsItem>
				)}
			</Tabs>
		</div>
	);
};

SportsList.propTypes = {
	/** On Sport click */
	onItemClick: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Whether or not the header should be hidden */
	hideHeader: PropTypes.bool,

	/** On Player Bets click */
	onPlayerBetsClick: PropTypes.func,

	/** Player Bets item */
	playerBets: PropTypes.shape({
		/** id */
		id: PropTypes.string,
		/** name */
		title: PropTypes.string,
		/** icon */
		icon: PropTypes.string,
	}),

	/** List of visible sports */
	sports: PropTypes.arrayOf(
		PropTypes.shape({
			/** Sport id */
			id: PropTypes.number,
			/** Sport name */
			name: PropTypes.string,
		}),
	),
};

SportsList.defaultProps = {
	className: '',
	hideHeader: false,
	onPlayerBetsClick: () => {},
	playerBets: {},
	sports: [],
};

export default SportsList;
