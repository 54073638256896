import { navigate } from '../../store/application/applicationActions';

const ApplicationRouter = Backbone.Router.extend({
	routes: {
		'': 'load',
		corporate: 'redirectToCorporate',
	},

	load: function() {

		const acl = App.store.getState().acl;
		if (acl.default_route) {
			return App.store.dispatch(navigate(acl.default_route, { trigger: true }));
		}

		const fragment = Backbone.history.getFragment();
		// Redirects to Racing
		if (fragment === '' || fragment.startsWith('?')) {
			App.store.dispatch(navigate('/welcome', { trigger: true })); // add new welcome router 21Nov2019
			//App.store.dispatch(navigate('/racing', { trigger: true }));
		} 
	},

	redirectToCorporate: function() {
		//window.location = 'http://corporate.topbetta.com';
		window.location = 'http://tst.site.grs.bet';
	},
});

export default ApplicationRouter;
