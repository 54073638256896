import { isObject, reduce } from 'lodash-es';
import humanize from 'underscore.string/humanize';
import slugify from 'underscore.string/slugify';
import numeral from 'numeral';
import moment from 'moment';
import countdown from 'countdown';

const { getLocalTimezone } = require('../../common/Date');

import { SERVER_REGION } from '../../common/constants/DateFormats';
import { BET_UNRESULTED_STATUS, BET_PAID_STATUS } from '../../common/constants/Bets';
import { DEFAULT_CURRENCY_FORMAT } from '../../containers/Application/Currency/CurrencyConstants';

/**
 * Formatting values
 */
var F = {
	/**
	 * Format cents as dollars
	 *
	 * @param value
	 * @param noCurrencySymbol
	 * @returns {*}
	 */
	centsAsDollars: function(value, noCurrencySymbol = false) {
		if (!value && value !== 0) {
			return;
		}

		const format = noCurrencySymbol ? DEFAULT_CURRENCY_FORMAT : `$${DEFAULT_CURRENCY_FORMAT}`;

		try {
			return numeral(value / 100).format(format);
		} catch (error) {
			console.error(error);
		}
	},

	centsAsFormat: function(value, format = `$${DEFAULT_CURRENCY_FORMAT}`) {
		if (!value && value !== 0) {
			return;
		}

		try {
			return numeral(value / 100).format(format);
		} catch (error) {
			console.error(error);
		}
	},

	dollarsAsFormat: function(value, format = `$${DEFAULT_CURRENCY_FORMAT}`) {
		if (!value && value !== 0) {
			return;
		}

		try {
			return numeral(value).format(format);
		} catch (error) {
			console.error(error);
		}
	},

	forHumans: function(value) {
		return humanize(value);
	},

	formatAsOdds: function(odds) {
		return isNaN(+odds) ? odds : numeral(odds).format('0.00');
	},

	separateThousands: function(value, format = '0,0') {
		if (!value && value !== 0) {
			return;
		}

		try {
			return numeral(value).format(format);
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * Converts a given string to a valid dollar amount.
	 *
	 * @param value
	 * @param format
	 * @return
	 */
	monetize: function(value, format) {
		let valueAsCents = Math.floor(F.dollarsAsCents(value));
		return F.centsAsFormat(valueAsCents, format);
	},

	/**
	 * TODO: hack
	 * @param value
	 * @returns {*}
	 */
	centsAsDollarsNoCents: function(value) {
		if (!value && value !== 0) {
			return;
		}

		try {
			return numeral(value / 100).format('$0,0');
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * Format the display of the bet result
	 *
	 * @param status
	 * @param paid
	 * @returns {*}
	 */
	formatResult: function(status, paid) {
		// After race is paid - show win or loss
		if (status === BET_PAID_STATUS) {
			return paid > 0 ? 'WON' : 'NO RETURN';
		}

		// If bet has been accepted
		if (status === BET_UNRESULTED_STATUS) {
			return 'accepted';
		}

		return status;
	},

	/**
	 * Format the to win string for a bet selection
	 *
	 * @param amount - bet amount
	 * @param odds
	 * @param type - bet type
	 * @param productType
	 * @returns {string}
	 */
	toWinStringBuilder: function(amount, odds, type, productType) {
		var toWinString = ` (${type || 'win'})`;

		if (amount * odds !== 0) {
			var toWinValue = numeral(amount * odds || 0).format(`$${DEFAULT_CURRENCY_FORMAT}`);
			toWinString += ` to win approx. ${toWinValue} at ${odds}`;
		} else if (amount !== 0 && !odds) {
			toWinString += ' (odds n/a)';
		}

		if (productType) {
			toWinString += ` (${productType})`;
		}

		return toWinString;
	},

	dollarsAsDollarsNoSign: function(value) {
		return F.centsAsFormat(F.dollarsAsCents(value), DEFAULT_CURRENCY_FORMAT);
	},

	/**
	 * Format dollars to cents
	 *
	 * @param value
	 * @returns {*}
	 */
	dollarsAsCents: function(value) {
		if (!value && value !== 0) {
			return;
		}

		// Converts the given value to a string and checks it for decimal places
		let valueAsString = '' + value;
		let dotIndex = valueAsString.indexOf('.');

		// Bail out if multiple decimal points found, otherwise slice past 2dp
		if (dotIndex > -1) {
			if (valueAsString.lastIndexOf('.') > dotIndex) {
				return 0;
			}
			valueAsString = valueAsString.slice(0, dotIndex + 3);
		}

		return Math.round(numeral(valueAsString).value() * 100) || 0;
	},

	/**
	 * Given a date, return the local version of the time
	 *
	 * @param datetime
	 */
	localDateTime: function(datetime) {
		// cant call this.serverDateTime as 'this' is not always available
		const time = datetime || moment();
		const serverDateTime = moment.tz(time, SERVER_REGION);
		return serverDateTime.clone().utcOffset(moment().utcOffset());
	},

	/**
	 * Add the time zone for Sydney Australia where the server is
	 */
	serverDateTime: function(datetime) {
		const time = datetime || moment();
		return moment.tz(time, SERVER_REGION);
	},

	/**
	 * Format as h:mmA 6:45PM
	 * @param datetime
	 * @returns {*}
	 */
	standardTimeFormat: function(datetime) {
		return this.localDateTime(datetime).format('h:mmA');
	},

	/**
	 * Format as Do MMMM z 15th February AEDT
	 * @param datetime
	 * @returns {*}
	 */
	longDateFormat: function(datetime) {
		return moment(datetime).format(`Do MMMM [${getLocalTimezone()}]`);
	},

	/**
	 * Format as Do MMMM h:mma z 15th February 7:50pm AEDT
	 * @param datetime
	 * @returns {*}
	 */
	longDateTimeFormat: function(datetime) {
		return moment(datetime).format(`Do MMMM h:mma [${getLocalTimezone()}]`);
	},

	/**
	 * Given a date, return the UTC version of the time
	 *
	 * @param datetime
	 */
	utcDateTime: function(datetime) {
		return moment.tz(datetime, 'UTC');
	},

	numberWithSign: function getNumber(number) {
		var num = parseFloat(number);

		if (num > 0) {
			return '+' + num;
		} else {
			return num.toString();
		}
	},

	slugify: function(string) {
		return slugify(string);
	},

	formatToGoCountdown: function(ts, timeString) {
		var formatted = '';
		if (ts.days === 0) {
			if (ts.hours === 0) {
				if (ts.minutes < 5) {
					formatted = ts.minutes + 'm ' + ts.seconds + 's';
				} else {
					formatted = ts.minutes + 'm ';
				}
			} else {
				formatted = ts.hours + 'h ' + ts.minutes + 'm';
			}
		} else {
			formatted = ts.days + 'D ' + ts.hours + 'h';
		}

		if (moment(timeString) < moment()) {
			formatted = '-' + formatted;
		}

		return formatted;
	},

	/**
	 * An optimised countdown string generation
	 *
	 * @param timeString
	 * @returns {string}
	 */
	countdownFast: function(timeString) {
		var ts = countdown(new Date(timeString));
		return F.formatToGoCountdown(ts, timeString);
	},

	countdown: function(time) {
		var localTime = F.localDateTime(time);
		var ts = countdown(localTime);
		var formatted = '';
		if (ts.months > 1) {
			formatted = ts.months + ' months';
		} else if (ts.days === 0) {
			if (ts.hours === 0) {
				if (ts.minutes < 5) {
					formatted = ts.minutes + 'm ' + ts.seconds + 's';
				} else {
					formatted = ts.minutes + 'm ';
				}
			} else {
				formatted = ts.hours + 'h ';
				if (ts.minutes > 0) {
					formatted += ts.minutes + 'm';
				}
			}
		} else {
			formatted = ts.days + 'd ' + ts.hours + 'h';
		}

		if (moment(time) < moment()) {
			formatted = '-' + formatted;
		}

		return formatted;
	},

	/**
	 * count down less than two min
	 * @param {*} time
	 */
	countdownLessThan2Min: function(time) {
		var localTime = F.localDateTime(time);
		var ts = countdown(localTime);
		var formatted = '';
		if (ts.months > 1) {
			formatted = '';
		} else if (ts.days === 0) {
			if (ts.hours === 0) {
				if (ts.minutes < 2) {
					formatted = 'less2Min';
				} else {
					formatted = '';
				}
			} else {
				formatted = '';
			}
		} else {
			formatted = '';
		}

		if (moment(time) < moment()) {
			// get minus
			formatted = 'less2Min';
		}

		return formatted;
	},

	/**
	 * Countdown and format time for Next To Jump
	 *
	 * @param time
	 * @returns {string}
	 */
	countdownNTJ: function(time) {
		var localTime = F.localDateTime(time);
		var ts = countdown(localTime);
		var formatted = '';
		if (ts.months > 1) {
			formatted = ts.months + ' months';
		} else if (ts.days === 0) {
			if (ts.hours === 0) {
				if (ts.minutes < 1) {
					formatted = 'seconds';
				} else {
					formatted = ts.minutes + ' min';
				}
			} else {
				formatted = ts.hours === 1 ? ts.hours + ' hour' : ts.hours + ' hours';
			}
		} else {
			formatted = ts.days === 1 ? ts.days + ' day' : ts.days + ' days';
		}

		if (moment(time) < moment()) {
			formatted = '-' + formatted;
		}

		return formatted;
	},

	/**
	 * Countdown the minutes and always shows seconds with 2 digits
	 *
	 * @param time
	 * @returns {string}
	 */
	minutesCountdown: function(time) {
		let localTime = F.localDateTime(time);
		let ts = countdown(localTime);
		let formatted = '';

		if (ts.seconds < 10) {
			formatted = ts.minutes + 'm ' + '0' + ts.seconds + 's';
		} else {
			formatted = ts.minutes + 'm ' + ts.seconds + 's';
		}

		if (moment(time) < moment()) {
			formatted = '-' + formatted;
		}

		return formatted;
	},

	/**
	 * Countdown the days
	 *
	 * @param date
	 * @returns {number|*}
	 */
	dayCountdown: function(date) {
		let ts = countdown(date);
		let days = ts.days;

		if (ts.hours > 0) {
			days++;
		}

		return days;
	},

	/**
	 * Converts a second value to a nice format with padding
	 * Largest unit is days - can easily be modified to include months or years
	 *
	 * @param elapsedTime
	 * @returns {string}
	 */
	timer: function(elapsedTime) {
		let duration = moment.duration(Math.abs(elapsedTime), 'seconds'),
			formatted = '',
			ts = {
				days: Math.floor(duration.asDays()),
				hours: duration.hours(),
				minutes: duration.minutes(),
				seconds: duration.seconds(),
			};

		if (ts.days === 0) {
			if (ts.hours === 0) {
				formatted = this.padNumber(ts.minutes, 2) + 'm ' + this.padNumber(ts.seconds, 2) + 's';
			} else {
				formatted = this.padNumber(ts.hours, 2) + 'h ' + this.padNumber(ts.minutes, 2) + 'm';
			}
		} else {
			formatted = this.padNumber(ts.days, 2) + 'd ' + this.padNumber(ts.hours, 2) + 'h';
		}

		if (elapsedTime < 0) {
			formatted = '-' + formatted;
		}

		return formatted;
	},

	/**
	 * Converts a second value to a nice format with padding
	 * Largest unit is days - can easily be modified to include months or years
	 *
	 * @param elapsedTime
	 * @returns {string}
	 */
	timerForHeader: function(elapsedTime) {
		let duration = moment.duration(Math.abs(elapsedTime), 'seconds'),
			ts = {
				days: Math.floor(duration.asDays()),
				hours: duration.hours(),
				minutes: duration.minutes(),
				seconds: duration.seconds(),
			};

		let formatted = `${this.padNumber(ts.hours, 2)}:${this.padNumber(ts.minutes, 2)}:${this.padNumber(ts.seconds, 2)}`;

		if (ts.days > 0) {
			formatted = this.padNumber(ts.days, 2) + ':' + formatted;
		}

		if (elapsedTime < 0) {
			formatted = '-' + formatted;
		}

		return formatted;
	},

	/**
	 * Pads a value so that if it has a length shorter than 'places', it will have leading zeroes to compensate
	 *
	 * @param value
	 * @param places
	 * @returns {string|*}
	 */
	padNumber: function(value, places) {
		value = value.toString();
		return value.length < places ? F.padNumber('0' + value, places) : value;
	},

	decimalPlaces: function(value, places = 2) {
		return parseFloat(value).toFixed(places);
	},

	/**
	 * Converts the withdraw amount to either a valid amount, or a string which will prompt a flash message
	 *
	 * @param value
	 * @returns {*}
	 */
	withdrawAmountToCents: function(value) {
		value = value.toString();
		// 1. Remove trailing $

		if (value.indexOf('$') === 0) {
			value = value.substr(1);
		}
		// 2. Compare value to regex'd value with only numbers and .

		if (value !== value.replace(/[^\d.]/g, '')) {
			return 'Error';
		}
		// 3. try / catch numeral formatting

		try {
			value = numeral(value).format('0.00');
		} catch (e) {
			return 'Error';
		}

		return parseInt(parseFloat(value) * 100);
	},

	allWordsWithCapitals: function(string) {
		return string
			.split(' ')
			.map((word) => humanize(word))
			.join(' ');
	},

	/**
	 * Generate name of a multi
	 *
	 * @param count
	 * @returns {*}
	 */
	generateMultiName: function(count) {
		var obj = [
			'Zero',
			'Accumulator',
			'Double',
			'treble',
			'4-fold',
			'5-fold',
			'6-fold',
			'7-fold',
			'8-fold',
			'9-fold',
			'10-fold',
		];

		return `${obj[count]}`;
	},

	/**
	 * Determines the race type from a single letter code
	 *
	 * @param raceCode
	 * @returns {*}
	 */
	getRaceType: function(raceCode) {
		// Race code must be lower case
		raceCode = raceCode ? raceCode.toLowerCase() : '';

		switch (raceCode) {
			case 't':
				return 'Thoroughbred';
			case 'g':
				return 'Greyhounds';
			case 'h':
				return 'Harness';
			default:
				return '';
		}
	},

	/**
	 * This function masks all digits of a card number except for the last four
	 *
	 * @param cardNumber
	 * @returns {String}
	 */
	maskCreditCardNumber: function(cardNumber) {
		return `***-${('' + cardNumber).slice(-4)}`;
	},

	/**
	 * This function validates an expiry date in MM/YY format
	 *
	 * @param expiryDate
	 * @returns {Boolean}
	 */
	validateExpiryDate: function(expiryDate) {
		// Firstly, we check the length and if that passes, the slash
		if (expiryDate.length !== 5 || expiryDate[2] !== '/') {
			return false;
		}

		// Then we parse expiryDate to determine the supplied month and year
		var month = expiryDate.slice(0, 2);
		var year = expiryDate.slice(3);

		// Month and year need to be checked to ensure that they're validates
		// numbers. If they are, we must check to see if we're before the end
		// of the expiry month.
		return (
			+month &&
			month > 0 &&
			month <= 12 &&
			+year &&
			moment()
				.add(-1, 'month')
				.isBefore(moment(expiryDate, 'MM/YY'))
		);
	},

	/**
	 *
	 * @param errors
	 * @returns {*}
	 */
	errorString: function(errors) {
		var text = errors;

		if (Array.isArray(errors)) {
			text = errors.join('. ');
		} else if (isObject(errors)) {
			text = reduce(
				errors,
				function(string, value, key) {
					if (value) {
						return string + ' ' + F.errorString(value);
					}
					return string;
				},
				'',
			);
		}

		return text;
	},
};

/**
 * Exports
 * @type {{centsAsDollars: Function}}
 */
module.exports = F;
