// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Actions
import { getSpendLimit, setSpendLimit, removeSpendLimit } from '../../../store/spendLimits/spendLimitsActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { setSelectedMenuItem } from '../../../store/userAccount/userAccountActions';
import { getUserAccountItem } from '../../../pages/UserAccount/UserAccountSelectors';
import { scrollToTop } from '../../../store/application/applicationActions';

// Components
import BetLimits from '../../../components/features/ResponsibleGambling/BetLimits/BetLimits';

// Constants
import {
	SET_DAILY_LIMIT,
	SET_WEEKLY_LIMIT,
	SET_MONTHLY_LIMIT,
} from '../../../components/features/ResponsibleGambling/BetLimits/Constants';
import { USER_ACCOUNT_SUBMENU_SELF_EXCLUSION } from '../../../pages/UserAccount/UserAccountPageConstants';
const CONFIRMATION_CHANGE = 'CONFIRMATION_CHANGE';
const CONFIRMATION_REMOVAL = 'CONFIRMATION_REMOVAL';

class SpendLimitsContainer extends Component {
	static propTypes = {
		/**
		 * user's id
		 */
		userId: PropTypes.number.isRequired,

		/**
		 * user's first name
		 */
		first_name: PropTypes.string.isRequired,

		/**
		 * hook for getting the spend limits
		 */
		getSpendLimit: PropTypes.func.isRequired,

		/**
		 * hook for setting the spend limit
		 */
		setSpendLimit: PropTypes.func.isRequired,

		/**
		 * hook for removing the spend limit
		 */
		removeSpendLimit: PropTypes.func.isRequired,

		/**
		 * GA Tracking for when a tab is clicked:
		 * Category         | Action    | Label
		 * SpendLimits    | Select    | Daily/Weekly
		 */
		onTabClick: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/**
		 * user's old daily spend limit
		 */
		daily_spend_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/**
		 * user's old weekly spend limit
		 */
		weekly_spend_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/**
		 * user's old monthly spend limit
		 */
		monthly_spend_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/**
		 * redirects to the Self Exclusion route
		 */
		navigateToSelfExclusion: PropTypes.func.isRequired,

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		daily_spend_limit: {},
		weekly_spend_limit: {},
		monthly_spend_limit: {},
		brandName: null,
	};

	state = {
		flow: SET_DAILY_LIMIT,
		error: null,
		componentIsLoading: false,
		updateIsLoading: false,
		removeLimitLoading: false,
		confirmation: null,
	};

	componentDidMount() {
		/**
		 * Load the deposit limits
		 * Set a loading mask while loading the limits
		 * Remove when done
		 */
		this.setLoadingMask(true, 'componentIsLoading');

		this.props.getSpendLimit('weekly');
		this.props.getSpendLimit('monthly');
		return this.props.getSpendLimit('daily').finally(() => {
			this.setLoadingMask(false, 'componentIsLoading');
		});
	}

	/**
	 * Set a loading mask
	 *
	 * @param loading
	 * @param region
	 */
	setLoadingMask(loading = false, region) {
		this.setState({
			[region]: loading,
		});
	}

	/**
	 * handle content change when clicking on a tab item
	 * and send GA tracking
	 *
	 * @param screen
	 */
	handleScreenChange = (screen) => {
		let track;

		if (screen === SET_DAILY_LIMIT) {
			track = 'Daily';
		} else if (screen === SET_WEEKLY_LIMIT) {
			track = 'Weekly';
		} else if (screen === SET_MONTHLY_LIMIT) {
			track = 'Monthly';
		}

		this.props.onTabClick(track);

		this.handleClearMessage();

		this.setState({
			flow: screen,
		});
	};

	/**
	 * Calls the action to set a spend limit
	 * Set an error message if the action fails
	 *
	 * @param amount
	 * @param type
	 */
	handleSetSpendLimit = (amount, type) => {
		this.setLoadingMask(true, 'updateIsLoading');

		const period = type === 'daily' ? 1 : type === 'weekly' ? 7 : 30;

		this.props
			.setSpendLimit(amount, period)
			.then((response) => {
				this.props
					.getSpendLimit(type)
					.then(() => {
						this.setState({
							error: null,
							confirmation: response.data.data,
						});
					})
					.finally(() => {
						this.setLoadingMask(false, 'updateIsLoading');
						scrollToTop();
					});
			})
			.catch((error) => {
				this.setState({
					error: error.response && error.response.data ? error.response.data.errors : error.message,
					confirmation: null,
				});
				this.setLoadingMask(false, 'updateIsLoading');
				scrollToTop();
			});
	};

	/**
	 * Calls the action to remove the spend limit
	 * Set an error message if the action fails
	 *
	 * @param period
	 */
	handleRemoveSpendLimit = (period) => {
		this.setLoadingMask(true, 'removeLimitLoading');

		this.props
			.removeSpendLimit(period)
			.then((response) => {
				this.props
					.getSpendLimit(period)
					.then(() => {
						this.setState({
							error: null,
							confirmation: response.data.data,
						});
					})
					.finally(() => {
						this.setLoadingMask(false, 'removeLimitLoading');
						scrollToTop();
					});
			})
			.catch((error) => {
				this.setState({
					error: error.response && error.response.data ? error.response.data.errors : error.message,
					confirmation: null,
				});
				this.setLoadingMask(false, 'removeLimitLoading');
				scrollToTop();
			});
	};

	/**
	 * Remove the error message
	 */
	handleClearMessage = () => {
		this.setState({
			error: null,
			confirmation: null,
		});
	};

	render() {
		const {
			className,
			first_name,
			daily_spend_limit,
			weekly_spend_limit,
			monthly_spend_limit,
			navigateToSelfExclusion,
			brandName,
		} = this.props;

		const { componentIsLoading, updateIsLoading, removeLimitLoading, flow, error, confirmation } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		return (
			<BetLimits
				className={containerClasses}
				limitType="Spend"
				first_name={first_name}
				daily_bet_limit={daily_spend_limit}
				weekly_bet_limit={weekly_spend_limit}
				monthly_bet_limit={monthly_spend_limit}
				onScreenChange={this.handleScreenChange}
				setBetLimit={this.handleSetSpendLimit}
				removeBetLimit={this.handleRemoveSpendLimit}
				clearMessage={this.handleClearMessage}
				goToSelfExclusion={navigateToSelfExclusion}
				error={error}
				componentIsLoading={componentIsLoading}
				updateIsLoading={updateIsLoading}
				removeLimitLoading={removeLimitLoading}
				confirmation={confirmation}
				flow={flow}
				moreInfo
				brandName={brandName}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		first_name: state.entities.users[state.application.authenticatedUser].first_name,
		userId: state.application.authenticatedUser,
		daily_spend_limit: state.spendLimits.daily_spend_limit,
		weekly_spend_limit: state.spendLimits.weekly_spend_limit,
		monthly_spend_limit: state.spendLimits.monthly_spend_limit,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSpendLimit: (period) => {
			return dispatch(getSpendLimit(period));
		},
		setSpendLimit: (amount, period) => {
			return dispatch(setSpendLimit(amount, period));
		},
		removeSpendLimit: (period) => {
			return dispatch(removeSpendLimit(period));
		},
		navigateToSelfExclusion: () => {
			dispatch(setSelectedMenuItem(getUserAccountItem(USER_ACCOUNT_SUBMENU_SELF_EXCLUSION)));
		},
		onTabClick: (type) => {
			return dispatch(trackGaEvent('SpendLimits', 'Select', type));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SpendLimitsContainer);
