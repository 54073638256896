import { extend, each } from 'lodash-es';
import { TRACK_GA_EVENT, TRACK_GTM_EVENT, TRACK_BRAZE_EVENT } from './trackingPixelsActionTypes';
import { createAction } from '../../common/actions/actionHelpers';

/**
 * Track a GA Event
 *
 * @param category
 * @param action
 * @param label
 * @param value
 * @returns {Object}
 */
export function trackGaEvent(category, action, label, value) {
	return createAction(TRACK_GA_EVENT, { category, action, label, value });
}

/**
 * Track a GTM Event
 *
 * @param event
 * @returns {Object}
 */
export function trackGtmEvent(event) {
	return createAction(TRACK_GTM_EVENT, { event });
}

/**
 * Track a Braze Event
 *
 * @param category
 * @param action
 * @param label
 * @param value
 * @returns {Object}
 */
export function trackBrazeEvent(category, action, label, value) {
	return createAction(TRACK_BRAZE_EVENT, { category, action, label, value });
}

/**
 * Track a GA Transaction
 *
 * @param id
 * @param transactionData
 * @param itemsData
 */
export const trackGaTransaction = (id, transactionData = {}, itemsData = []) => (dispatch, getState) => {
	if (typeof Backbone === 'undefined') {
		return;
	}

	let TransactionModel = Backbone.Model.extend({
		defaults: {
			revenue: 0,
			affiliation: 'TopBetta',
			tax: 0,
			currency: 'AUD',
		},
	});

	let TransactionItemModel = Backbone.Model.extend({
		defaults: {
			price: 0,
			quantity: 1,
			currency: 'AUD',
		},
	});

	const isCordova = getState().application.isCordova;

	// Create transaction model
	const transaction = new TransactionModel(
		extend(
			{
				id: id,
			},
			transactionData,
		),
	);

	if (isCordova) {
		if (window.FirebasePlugin) {
			// Add items
			each(itemsData, function(item) {
				const itemModel = new TransactionItemModel(
					extend(
						{
							id: id,
						},
						item,
					),
				);

				window.FirebasePlugin.logEvent('add_to_cart', {
					item_category: itemModel.get('category'),
					item_name: itemModel.get('name'),
					item_id: itemModel.id,
					currency: itemModel.get('currency'),
					price: itemModel.get('price'),
					quantity: itemModel.get('quantity'),
					sku: itemModel.get('sku'),
				});
			});

			window.FirebasePlugin.logEvent(
				'ecommerce_purchase',
				extend(
					{
						transaction_id: transaction.id,
					},
					transaction.toJSON(),
				),
			);
		}
	} else {
		// Create the transaction
		App.ga('ecommerce:addTransaction', transaction.toJSON());

		// Add items
		each(itemsData, function(item) {
			const itemModel = new TransactionItemModel(
				extend(
					{
						id: id,
					},
					item,
				),
			);

			App.ga('ecommerce:addItem', itemModel.toJSON());
		});

		App.ga('ecommerce:send');
	}
};
