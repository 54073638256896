import React from 'react';
import { Icon, spacings, Text, ui } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';
import { RACING_TYPES_LOOKUP } from '../../../../../common/constants/Racing';


const StyledMultiSRMItem = styled('div')(
    (props) => css`
	label: MultiSRMItem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	border-radius: 4px;
`);

const SRMHeader = styled.div`
    ${props => `
        label: SRMHeader;

        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 40px;
        padding-left: ${spacings(props).compact}px;
        border-bottom: 1px solid #dddddd;
    `}
`;

const SRMHeaderTitle = styled.div`
    label: SRMHeaderTitle;
    display: flex;
`;

const SRMBody = styled.div`
    ${(props) => css`
    label: SRMBody;

	display: flex;
	padding: ${spacings(props).compact}px;

	flex-direction: column;
    `}
	
	
`;

const StyledBetSelectionMulti__Clear = styled('div')(
    (props) => css`
		label: BetSelectionMulti__Clear;

		margin-left: ${spacings(props).compact}px;
		text-align: right;
		cursor: pointer;
	`,
);

const StyledBetSelectionMulti__ClearIcon = styled(Icon)(
    (props) => css`
		label: BetSelectionMulti__ClearIcon;

		text-align: center;
		color: ${ui(props).color_danger};
		width: 20px;
		height: 20px;
		line-height: 16px;
	`,
);

const StyleSRMSelection = styled.div`
${(props) => css`
    label: StyleSRMSelection;

    display: flex;
    flex-direction: row;

    `}
`;

export const BetSelectionSRM = ({
    srm,
    className,
    racingBetType,
    selectionName,
    betTypeLabel,
    eventName,
    price,
    placePrice,
    singleOnly,
    enableRemoval,
    multiBetId,
    onClickSelection,
    onMultiToggle,
    onMultiRemove
}) => {

    const handleRemoveSRM = () => {
        onMultiRemove(srm.id);
    };



    return (
        <StyledMultiSRMItem>
            <SRMHeader>
                <SRMHeaderTitle>
                    <Icon
                        color={'black'}
                        icon={RACING_TYPES_LOOKUP[srm.race.type]}
                        size="-1"
                        push="1"
                    />
                    <Text size="-4">
                        <strong>
                            R{srm.race.number}{''}{srm.race.name}
                        </strong>
                    </Text >
                </SRMHeaderTitle>


                {enableRemoval && (
                    <StyledBetSelectionMulti__Clear>
                        <StyledBetSelectionMulti__ClearIcon size="-3" icon="close" action={handleRemoveSRM} />
                    </StyledBetSelectionMulti__Clear>
                )}
            </SRMHeader>

            <SRMBody>
                {srm.selections.map((selection, i) => (
                    <StyleSRMSelection key={selection.id}>
                        <Text size="-4" style={{ width: '32px', fontWeight: '500' }}>
                            {selection.type_name}:
                        </Text>
                        <Text
                            size="-4"
                            style={{ marginLeft: '5px' }}>
                            #{selection.selection_number} {' '} {selection.name}
                        </Text>
                    </StyleSRMSelection>
                ))}
            </SRMBody>

        </StyledMultiSRMItem>
    );

};

