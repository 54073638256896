// Libraries
import { batchActions } from 'redux-batched-actions';
import { slugify } from '../../legacy/core/format';

// Constants
import {
	SPORTS_HOME_SET_FILTERS_BATCH,
	SPORTS_HOME_SET_NTJ_IDS,
	SPORTS_HOME_SET_SELECTED_BASE_COMPETITION,
	SPORTS_HOME_SET_SELECTED_COMPETITION,
	SPORTS_HOME_SET_SELECTED_EVENT,
	SPORTS_HOME_SET_SELECTED_SPORT,
	SPORTS_HOME_SET_SELECTED_MARKET,
	SPORTS_SET_LOADING_EVENTS,
} from './sportsHomeActionTypes';

// Functions
import {
	fetchNextToJumpSports,
	fetchNextToJumpSportsWithMarketsAndSelections,
	fetchSportsByCompetitionId,
	fetchSportsBySportName,
} from '../entities/actions/SportsActions';
import { fetchCombinedMarketsGroupsWithSelections } from '../entities/actions/MarketActions';
import { createAction } from '../../common/actions/actionHelpers';
import { fetchCompetitionWithEvents, fetchMarketWhenSingleEvent } from '../entities/actions/CompetitionActions';
import { openNotification } from '../../store/application/applicationActions';
import { getEvents } from '../../pages/Sports/SportsHome/sportsHomeSelectors';
import { setBodyLoading } from '../application/applicationActions';

/**
 * Load next to jump events to render /sports home.
 *
 * @param setLoadingMask
 * @returns {function(*)}
 */
export const loadSportsHomeData = (setLoadingMask = false) => (dispatch) => {
	if (setLoadingMask) {
		dispatch(setBodyLoading(true));
	}

	return Promise.all([dispatch(fetchNextToJumpSports()), dispatch(fetchNextToJumpSportsWithMarketsAndSelections())])
		.then((responses) => {
			dispatch(setSportsHomeFilters(null, null, null, null, null));
			dispatch(setNtjSportsIds(responses[0]));
		})
		.finally(() => dispatch(setBodyLoading(false)));
};

/**
 * Fetch data for selected sport, finds the base competition with highest order and load it's first competition data.
 *
 * @param sportName
 * @param setLoadingMask
 * @returns {function(*)}
 */
export const loadSportData = (sportName, setLoadingMask = false) => (dispatch) => {
	if (setLoadingMask) {
		dispatch(setBodyLoading(true));
	}

	return dispatch(fetchSportsBySportName(sportName))
		.then((response) => {
			const sport = response.data.data.find((sport) => slugify(sport.name) === sportName);
			if (sport) {
				let baseCompetitions = sport && sport.base_competitions;
				let firstCompID;
				if (baseCompetitions instanceof Array && baseCompetitions.length > 0) {
					baseCompetitions.sort((bcA, bcB) => bcA.order - bcB.order);

					if (baseCompetitions[0].competitions instanceof Array && baseCompetitions[0].competitions.length > 0) {
						firstCompID = baseCompetitions[0].competitions[0].id;
					} else {
						//In case there is base_competition but no competition.
						return dispatch(setSportsHomeFilters(sport.id, sport.name, baseCompetitions[0].id));
					}
				} else {
					//In case there is no base_competition
					return dispatch(setSportsHomeFilters(sport.id, sport.name));
				}

				return dispatch(loadCompetitionData(firstCompID, sportName));
			}
		})
		.finally(() => dispatch(setBodyLoading(false)));
};

/**
 * Fetch data for selected competition.
 *
 * @param {number} competitionId
 * @param sportName
 */
export const loadCompetitionData = (competitionId, sportName = null) => (dispatch) => {
	return dispatch(fetchSportsByCompetitionId(competitionId))
		.then(() => {
			return dispatch(fetchMarketWhenSingleEvent(competitionId));
		})
		.then(() => {
			dispatch(setFiltersForCompetitionAndEvent(competitionId, null, sportName));
		});
};

/**
 * Fetch data for selected event.
 *
 * @param competitionId
 * @param eventId
 * @param sportName
 * @returns {function(*): Promise<any>}
 */
export const loadEventData = (competitionId, eventId, sportName = null) => (dispatch) => {
	/**
	 * Comment: Using these Ajax calls so we load less redundant data as currently we do.
	 *          This way we load:
	 *               All sports + selected BaseComp + selected Comp + All events.
	 *          Then:
	 *               All market groups for the given event.
	 */
	return Promise.all([
		dispatch(fetchSportsByCompetitionId(competitionId)),
		dispatch(fetchCombinedMarketsGroupsWithSelections(eventId)),
	]).then(() => {
		dispatch(setFiltersForCompetitionAndEvent(competitionId, eventId, sportName));
	});
};

/**
 *  Set state based on fetched data.
 *
 * @param {number} sportId
 * @param sportName
 * @param {number} baseCompetitionId
 * @param {number} competitionId
 * @param {number} eventId
 */
export const setSportsHomeFilters = (
	sportId = null,
	sportName = null,
	baseCompetitionId = null,
	competitionId = null,
	eventId = null,
) => (dispatch) => {
	let actions = [];
	actions.push(setSelectedSport(sportId, sportName));
	actions.push(setSelectedBaseCompetition(baseCompetitionId));
	actions.push(setSelectedCompetition(competitionId));
	actions.push(setSelectedEvent(eventId));

	dispatch(batchActions(actions, SPORTS_HOME_SET_FILTERS_BATCH));
};

/**
 * Retrieve baseCompetition from state by competition id.
 *
 * @param competitionId
 * @param getState
 * @param sportName - sport name so we can set the selected sport id based off the competition, and optionally be supplied with a sport name
 * @returns {Array} Array of actions to be dispatched
 */
const getFilterActionsForCompetitionId = (competitionId, getState, sportName = null) => {
	let currentState = getState();

	let competition = currentState.entities.competitions[competitionId];
	if (!competition) {
		// In case no competition is found return actions to clear related filters.
		return [setSelectedSport(null, null), setSelectedBaseCompetition(null), setSelectedCompetition(null)];
	}

	let baseCompetition = currentState.entities.base_competitions[competition.base_competition_id] || {};

	/**
	 * Fetching old event data we may end up finding competitions with a 'sport_id' field.
	 * Therefore, set sport referenced by the competition if it exists in store.
	 */
	return [
		setSelectedSport(baseCompetition.sport_id || competition.sport_id, sportName),
		setSelectedBaseCompetition(baseCompetition.id),
		setSelectedCompetition(competitionId),
	];
};

/**
 * Gets needed data based on given competition and set filters
 * @param competitionId
 * @param eventId
 * @param sportName
 */
export const setFiltersForCompetitionAndEvent = (competitionId, eventId, sportName = null) => (dispatch, getState) => {
	let actions = getFilterActionsForCompetitionId(competitionId, getState, sportName);
	actions.push(setSelectedEvent(eventId));
	dispatch(batchActions(actions, SPORTS_HOME_SET_FILTERS_BATCH));
};

/**
 * Load the selected competition, then set the selected competition in the sports home state
 *
 * @param competition
 * @param setLoadingMask
 * @returns {function(*)}
 */
export const selectCompetition = (competition, setLoadingMask = false) => (dispatch) => {
	const competitionId = competition.id;
	const competitionWasLoadedPreviously = competition.events && competition.events.length;

	/**
	 * If the competition already have events, don't show the loading mask and
	 * set the selected competition and event, but still fetch the events and market to update the competition
	 */
	if (competitionWasLoadedPreviously) {
		if (setLoadingMask) {
			dispatch(setLoadingEvents(true));
		}

		dispatch(
			batchActions(
				[setSelectedEvent(null), setSelectedCompetition(competitionId)],
				SPORTS_HOME_SET_SELECTED_COMPETITION,
			),
		);
		return dispatch(fetchCompetitionWithEvents(competitionId))
			.then(() => {
				return dispatch(fetchMarketWhenSingleEvent(competitionId));
			})
			.finally(() => dispatch(setLoadingEvents(false)));
	}

	dispatch(setLoadingEvents(true));
	return dispatch(fetchCompetitionWithEvents(competitionId))
		.then(() => {
			return dispatch(fetchMarketWhenSingleEvent(competitionId));
		})
		.then(() => {
			dispatch(
				batchActions(
					[setSelectedEvent(null), setSelectedCompetition(competitionId)],
					SPORTS_HOME_SET_SELECTED_COMPETITION,
				),
			);
		})
		.finally(() => {
			dispatch(setLoadingEvents(false));
		});
};

/**
 * Load the selected competition, then set the selected competition in the sports home state
 *
 * @param baseCompetition
 */
export const selectBaseCompetition = (baseCompetition) => (dispatch) => {
	const competitionId = baseCompetition.competitions[0] && baseCompetition.competitions[0].id;
	const sportName = baseCompetition.sport && baseCompetition.sport.name;

	// If base competition has no competition there is nothing to load
	if (!competitionId) {
		dispatch(openNotification('No competition found!', 'danger'));
		return;
	}

	// Check if the baseCompetition has events
	const baseCompetitionsHasEvents = baseCompetition.competitions.some((comp) => comp.events && comp.events.length);

	/**
	 * If the base competition has loaded events, don't show the loading mask and
	 * set the competition and event filters to show the competition, but still fetch the competition to update it
	 */
	if (baseCompetitionsHasEvents) {
		dispatch(setFiltersForCompetitionAndEvent(competitionId, null, sportName));
		return dispatch(fetchCompetitionWithEvents(competitionId)).then(() => {
			return dispatch(fetchMarketWhenSingleEvent(competitionId));
		});
	}

	dispatch(setLoadingEvents(true));

	return dispatch(fetchCompetitionWithEvents(competitionId))
		.then(() => {
			return dispatch(fetchMarketWhenSingleEvent(competitionId));
		})
		.then(() => {
			dispatch(setFiltersForCompetitionAndEvent(competitionId, null, sportName));
		})
		.finally(() => {
			dispatch(setLoadingEvents(false));
		});
};

/**
 * Load the selected event, then set the selected filter in the sports home state
 *
 * @param {Number} competitionId
 * @param {Number} eventId
 */
export const selectEvent = (competitionId, eventId) => (dispatch, getState) => {
	const events = getEvents(getState());
	const event = events && events.find((event) => event.id === eventId);
	const sportName =
		event && event.competition && event.competition.base_competition && event.competition.base_competition.sport
			? event.competition.base_competition.sport.name
			: '';

	// Check if the event has all markets
	const eventWasLoadedPreviously = event && event.markets && event.markets.length === event.market_count;

	/**
	 * If the event was previously loaded (has all markets), don't show the loading mask and
	 * set the competition and event filters, but still load the event to update it
	 */
	if (eventWasLoadedPreviously) {
		dispatch(setFiltersForCompetitionAndEvent(competitionId, eventId, sportName));
		return dispatch(loadEventData(competitionId, eventId, sportName));
	}

	dispatch(setLoadingEvents(true));
	return dispatch(loadEventData(competitionId, eventId, sportName)).finally(() => {
		dispatch(fetchCombinedMarketsGroupsWithSelections(eventId));
		dispatch(setLoadingEvents(false));
	});
};

/**
 * Set a loading mask for the events
 *
 * @param loading
 * @returns {Object}
 */
export const setLoadingEvents = (loading = false) => {
	return createAction(SPORTS_SET_LOADING_EVENTS, loading);
};

/**
 * Set selected sport id in state.
 *
 * @param {number} sportId
 * @param sportName
 * @returns {Object}
 */
export const setSelectedSport = (sportId = null, sportName = null) => {
	return createAction(SPORTS_HOME_SET_SELECTED_SPORT, {
		id: sportId,
		name: sportName ? slugify(sportName) : sportName,
	});
};

/**
 * Set selected base competition in state.
 *
 * @param {number} baseCompetitionId
 * @returns {Object}
 */
export const setSelectedBaseCompetition = (baseCompetitionId = null) => {
	return createAction(SPORTS_HOME_SET_SELECTED_BASE_COMPETITION, baseCompetitionId);
};

/**
 * Set selected competition id in state.
 * @param {number} competitionId
 * @returns {Object}
 */
export const setSelectedCompetition = (competitionId = null) => {
	return createAction(SPORTS_HOME_SET_SELECTED_COMPETITION, competitionId);
};

/**
 * Set selected event id in state.
 *
 * @param {number} eventId
 * @returns {Object}
 */
export const setSelectedEvent = (eventId) => {
	return createAction(SPORTS_HOME_SET_SELECTED_EVENT, eventId);
};

/**
 * Set selected market id in state.
 *
 * @param {number} marketId
 * @returns {Object}
 */
export const setSelectedMarket = (marketId) => {
	return createAction(SPORTS_HOME_SET_SELECTED_MARKET, marketId);
};

export const setNtjSportsIds = (ids = []) => {
	return createAction(SPORTS_HOME_SET_NTJ_IDS, ids);
};
