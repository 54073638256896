import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled from 'react-emotion';

import CurrencyImageContainer from '../../../../../containers/Application/Currency/CurrencyImageContainer';

import { centsAsDollars, dollarsAsFormat } from '../../../../../legacy/core/format';
import { COLORS, WEIGHTS, MEDIA_QUERIES } from '../../../../../constants/themes';

const StyledCurrencyDisplay = styled('span')`
	label: CurrencyDisplay;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	font-weight: ${WEIGHTS.exBold};
    color: ${props => props.isMinAmount ? COLORS.status.danger : COLORS.status.success};

    @media ${MEDIA_QUERIES.tabletAndUp} {
		font-size: 12px;
	}
`;

const StyledCurrencyDisplay__Amount = styled('span')`
	label: CurrencyDisplay__Amount;

	line-height: inherit;

	font-size: inherit;
`;

const TransactionsCurrency = (props) => {
    const { className, imageWidth, amount, applyConversion, displayWholeAmount, type, isMinAmount } = props;
    const componentClasses = cx({
        [className]: className,
    });
    let displayAmount = Math.abs(amount);
    let isANumber = !isNaN(amount);
    if (amount && applyConversion && isANumber) {
        displayAmount = displayWholeAmount ? dollarsAsFormat(displayAmount / 100, '0,0') : centsAsDollars(displayAmount, true);
    }

    const ammountIsNegative = amount < 0;
    return (
        <StyledCurrencyDisplay isMinAmount={isMinAmount} className={componentClasses}>
            {ammountIsNegative && <span>-</span>}
            {isANumber && <CurrencyImageContainer imageWidth={imageWidth} configKey={type} />}
            <StyledCurrencyDisplay__Amount>{amount && displayAmount}</StyledCurrencyDisplay__Amount>
        </StyledCurrencyDisplay>
    );
};

TransactionsCurrency.propTypes = {
    /** Extra classes */
    className: PropTypes.string,

    type: PropTypes.oneOf(['currency', 'bonusCurrency', 'tournamentCurrency']),

    applyConversion: PropTypes.bool,

    imageWidth: PropTypes.number,

    /** Display as a whole dollar amount with no cents */
    displayWholeAmount: PropTypes.bool,

    amount: PropTypes.any,
};

TransactionsCurrency.defaultProps = {
    className: '',
    applyConversion: true,
    imageWidth: 15,
    amount: null,
    displayWholeAmount: false,
    type: 'currency',
};

TransactionsCurrency.StyledCurrencyDisplay__Amount = StyledCurrencyDisplay__Amount;

export default TransactionsCurrency;
