// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces, Trans } from 'react-i18next';

// Components
import { Heading } from '@tbh/ui-kit';
import { Button, Text } from '@tbh/ui-kit';

const VerifyCardExplanation = (props) => {
	const { className, closeVerifyCardExplanation, brandName, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const authorisationMessage = brandName
		? t('VerifyCardExplanation__AreAuthorising', { brandName })
		: t('VerifyCardExplanation__GiveAuthorisation');

	return (
		<div className={componentClasses}>
			<Heading type="5" centered>
				{t('VerifyCardExplanation__VerifyingCreditCard')}
			</Heading>

			<Text size="-1" paragraph>
				{t('VerifyCardExplanation__MustBeVerified')}
			</Text>
			<Text size="-1" paragraph>
				{t('VerifyCardExplanation__VerificationProcess', { authorisationMessage })}
			</Text>
			<Text size="-1" paragraph>
				<Trans i18nKey="VerifyCardExplanation__WholeDollars">
					This deposit must be in <strong>whole dollars</strong>.
				</Trans>
			</Text>
			<Text size="-1" paragraph>
				{t('VerifyCardExplanation__CentValue')}
			</Text>
			<Text size="-1" paragraph>
				{t('VerifyCardExplanation__AccountVerified')}
			</Text>
			<Text size="-1" paragraph>
				{t('VerifyCardExplanation__Questions')}
			</Text>

			<Button action={closeVerifyCardExplanation} type="secondary" block>
				{t('VerifyCardExplanation__Done')}
			</Button>
		</div>
	);
};

VerifyCardExplanation.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Function to close the explanation component */
	closeVerifyCardExplanation: PropTypes.func.isRequired,

	/** Brand name */
	brandName: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

VerifyCardExplanation.defaultProps = {
	brandName: null,
	className: null,
};

export default withNamespaces()(VerifyCardExplanation);
