/**
 * Higher order function that takes a key, and uses it as the first argument for another function
 * @param key
 * @param action
 * @returns {function(...[*]): *}
 */
const createNamedAction = (key, action) => {
	return (...rest) => action(key, ...rest);
};

/**
 * Use this factory to loop through an object of functions and modify each
 * function so that the first parameter is set as a specified key
 * @param key
 * @param actionsGroup
 * @returns {function(...[*]): *} or object
 */
const createNamedActions = (key, actionsGroup) => {
	let exports = {};

	// If we are just provided a function, treat it as if we called 'createNamedAction'
	if (typeof actionsGroup === 'function') {
		return createNamedAction(key, actionsGroup);
	} else if (typeof actionsGroup === 'object') {
		// Loop through all properties in our actions list
		for (const action in actionsGroup) {
			if (actionsGroup.hasOwnProperty(action)) {
				// If the property is a function
				if (typeof actionsGroup[action] === 'function') {
					// Call our higher order function to overload the first param as the key
					exports[action] = createNamedAction(key, actionsGroup[action]);
				} else {
					exports[action] = actionsGroup[action]; // Leave as is
				}
			}
		}
	}

	return exports;
};

export { createNamedAction, createNamedActions };
