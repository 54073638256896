/**
 * Created by Josef Frank on 2017/03/08.
 */
'use strict';

const {
	ADD_FAVOURITE_COMPETITION,
	ADD_FAVOURITE_RUNNER,
	REMOVE_FAVOURITE_COMPETITION,
	REMOVE_FAVOURITE_RUNNER,
} = require('./favouritesActionTypes');

let initialState = {
	competitions: [],
	runners: [],
};

/**
 * Favourites reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const favouritesReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_FAVOURITE_COMPETITION:
			return {
				...state,
				competitions: [
					...(state.competitions || initialState.competitions),
					action.payload
				]
			};

		case ADD_FAVOURITE_RUNNER:
			return {
				...state,
				runners: [...state.runners, action.payload]
			};

		case REMOVE_FAVOURITE_COMPETITION:
			return {
				...state,
				competitions: (state.competitions || initialState.competitions).filter((competition) => {
					return competition !== action.payload;
				})
			};

		case REMOVE_FAVOURITE_RUNNER:
			return {
				...state,
				runners: state.runners.filter((runner) => {
					return runner !== action.payload;
				})
			};
	}

	return state;
};

module.exports = favouritesReducer;