import { SPACINGS } from './spacings';
import { FONT_BASE, FONT_BASE_SIZE, FONT_BASE_UNIT, LINE_HEIGHT, FONT_BASE_COLOR, FONT_SIZES, FONT_WEIGHTS } from './typography';
import {
	MAX_WIDTH,
	MIN_WIDTH,
	BET_SLIP_WIDTH,
	SIZE_MOBILE,
	SIZE_TABLET,
	SIZE_DESKTOP,
	FORM_HEIGHT_SMALL,
	FORM_HEIGHT_MEDIUM,
	FORM_HEIGHT_LARGE,
	BORDER_RADIUS_1,
	BORDER_RADIUS_2,
	BORDER_RADIUS_3,
	BOX_SHADOW,
	TEXT_SHADOW,
	NUMBER_PAD_BUTTON_SIZE,
	RACING_APPLICATION_STYLE__RACES_ROW_HEIGHT,
	RACING_APPLICATION_STYLE__NEXT_JUMP_HEIGHT,
	RACING_APPLICATION_STYLE__NEXT_JUMP_ITEM_WIDTH,
	RACING_APPLICATION_STYLE__ODDS_BUTTON_WIDTH,
	RACING_APPLICATION_STYLE__ODDS_BUTTON_WIDTH_SMALL,
	RACING_APPLICATION_STYLE__FLUCS__WIDTH,
	RACING_APPLICATION_STYLE__BUTTON_WIDTH,
	RACING_APPLICATION_STYLE__BUTTON_WIDTH_EXPANDED,
} from './application';
import LightenDarkenColor from './LightenDarkenColor';
export const COLOR_WHITE = '#ffffff';
export const COLOR_WHITE_DARK = '#f9f9f9';
export const COLOR_GREY_LIGHT = '#ececeb';
export const COLOR_GREY = '#dddddd';
export const COLOR_GREY_MEDIUM = '#888888';
export const COLOR_GREY_DARK = '#666666';
export const COLOR_BLACK = '#222222';

export const COLOR_GREEN = '#32be60';
export const COLOR_YELLOW = '#F8DC1E';
export const COLOR_ORANGE = '#ee9c15';
export const COLOR_BLUE = '#5d97fb';
export const COLOR_RED = '#cf1b41';
export const COLOR_PURPLE = '#3C487D';

/**
 * 'transparent' is not properly supported in IE and
 * the post processor auto converts rgba(0,0,0,0) to 'transparent' >:[
 */
export const COLOR_TRANSPARENT = 'rgba(255,255,255,0)';

export const BASE_COLORS = {
	green: COLOR_GREEN,
	yellow: COLOR_YELLOW,
	orange: COLOR_ORANGE,
	blue: COLOR_BLUE,
	red: COLOR_RED,
	purple: COLOR_PURPLE,
};

/**
 GLOBAL TOTE COLOURS
 */
export const COLOR_GLOBAL_TOTE = '#2dafe6';

/**
 SOCIAL MEDIA COLOURS
 */
export const SOCIAL_COLORS = {
	facebook: '#4e71a7',
	linkedin: '#1685b0',
	twitter: '#1cb6ea',
	instagram: '#D93175',
};

/**
 BRAND
 */
export const COLOR_BRAND_ONE_DARK = '#850000';//'#212529'; //'#343a40';//' @HW old color #BA0064';
export const COLOR_BRAND_ONE =  '#2E2E2E';//'#c72128'; // @HW old color is #FF0080
export const COLOR_BRAND_ONE_LIGHT = '#FF33B3';

export const COLOR_BRAND_TWO_DARK = '#101F29';
export const COLOR_BRAND_TWO = '#293842';
export const COLOR_BRAND_TWO_LIGHT = '#000000';

export const COLOR_BRAND_THREE = COLOR_GREEN;

export const COLOR_BRAND_FOUR = COLOR_GREY_DARK;

export const COLOR_BRAND_FIVE = '#079191';

export const BRAND_COLORS = {
	color_1: COLOR_BRAND_ONE,
	color_1_light: COLOR_BRAND_ONE_LIGHT,
	color_1_dark: COLOR_BRAND_ONE_DARK,
	color_2: COLOR_BRAND_TWO,
	color_2_light: COLOR_BRAND_TWO_LIGHT,
	color_2_dark: COLOR_BRAND_TWO_DARK,
	color_3: COLOR_BRAND_THREE,
	color_3_light: LightenDarkenColor(COLOR_BRAND_THREE, 10),
	color_3_dark: LightenDarkenColor(COLOR_BRAND_THREE, -10),
	color_4: COLOR_BRAND_FOUR,
	color_4_light: LightenDarkenColor(COLOR_BRAND_FOUR, 10),
	color_4_dark: LightenDarkenColor(COLOR_BRAND_FOUR, -10),
	color_5: COLOR_BRAND_FIVE,
	color_5_light: LightenDarkenColor(COLOR_BRAND_FIVE, 10),
	color_5_dark: LightenDarkenColor(COLOR_BRAND_FIVE, -10),
};

/**
 CONTEXT COLOUR
 */
export const CONTEXT_COLORS = {
	color_success: COLOR_GREEN,
	color_danger: COLOR_RED,
	color_warning: COLOR_ORANGE,
	color_info: COLOR_BLUE,
	color_neutral: COLOR_GREY_LIGHT,
	color_yellow: COLOR_YELLOW,
};

/**
 SPEEDMAP COLOURS
 */
export const COLOR_LEADER = BRAND_COLORS.color_1;
export const COLOR_OFFPACE = BRAND_COLORS.color_1_light;
export const COLOR_MIDFIELD = LightenDarkenColor(COLOR_OFFPACE, 35);
export const COLOR_BACKMARKER = LightenDarkenColor(COLOR_MIDFIELD, 20);

/**
 TEXT
 */
export const TEXT_COLORS = {
	color_1: COLOR_BLACK,
	color_1_alternate: COLOR_WHITE,
	color_2: COLOR_GREY_DARK,
	color_3: COLOR_GREY,
};

const RACING_APPLICATION_STYLE = {
	races_row_height: RACING_APPLICATION_STYLE__RACES_ROW_HEIGHT,
	ntj_height: RACING_APPLICATION_STYLE__NEXT_JUMP_HEIGHT,
	ntj_item_width: RACING_APPLICATION_STYLE__NEXT_JUMP_ITEM_WIDTH,
	odds_button_width: RACING_APPLICATION_STYLE__ODDS_BUTTON_WIDTH,
	odds_button_width_small: RACING_APPLICATION_STYLE__ODDS_BUTTON_WIDTH_SMALL,
	flucs_width: RACING_APPLICATION_STYLE__FLUCS__WIDTH,
	button_width: RACING_APPLICATION_STYLE__BUTTON_WIDTH,
	button_width_expanded: RACING_APPLICATION_STYLE__BUTTON_WIDTH_EXPANDED,
};

/**
 OTHER
 */
export const COLOR_SHADOW = '0 0 10px rgba(#000, .25)';

export const COLORS = {
	global_tote: COLOR_GLOBAL_TOTE,
	leader: COLOR_LEADER,
	offpace: COLOR_OFFPACE,
	midfield: COLOR_MIDFIELD,
	backmarker: COLOR_BACKMARKER,
	social: SOCIAL_COLORS,
	background: COLOR_WHITE_DARK,
};

const MEDIA_QUERY_SIZES = {
	mobile: SIZE_MOBILE,
	tablet: SIZE_TABLET,
	desktop: SIZE_DESKTOP,
};

const theme = {
	application: {
		min: MIN_WIDTH,
		max: MAX_WIDTH,
		bet_slip_width: BET_SLIP_WIDTH,
		size: MEDIA_QUERY_SIZES,
		form_height_small: FORM_HEIGHT_SMALL,
		form_height_medium: FORM_HEIGHT_MEDIUM,
		form_height_large: FORM_HEIGHT_LARGE,
		border_radius_1: BORDER_RADIUS_1,
		border_radius_2: BORDER_RADIUS_2,
		border_radius_3: BORDER_RADIUS_3,
		box_shadow: BOX_SHADOW,
		text_shadow: TEXT_SHADOW,
		shadow: COLOR_SHADOW,
		number_pad_button_size: NUMBER_PAD_BUTTON_SIZE,
		racing: RACING_APPLICATION_STYLE,
	},

	brand: BRAND_COLORS,

	ui: {
		color_1: COLOR_WHITE_DARK,
		color_2: COLOR_WHITE,
		color_3: COLOR_GREY_LIGHT,
		color_4: COLOR_GREY,
		color_5: COLOR_GREY_DARK,
		...CONTEXT_COLORS,
		color_black: COLOR_BLACK,
		color_transparent: COLOR_TRANSPARENT,
		...COLORS,
	},

	spacings: SPACINGS,

	typography: {
		base_font: FONT_BASE,
		base_heading_font: FONT_BASE,
		base_size: FONT_BASE_SIZE + FONT_BASE_UNIT,
		base_line_height: LINE_HEIGHT,
		base_color: FONT_BASE_COLOR,
		weight: FONT_WEIGHTS,
		...FONT_SIZES,
		...TEXT_COLORS,
	},
};

const uiKitWarn = () => {
	// TODO: Support checking for production and leave out the logging. Possibly adding a stack trace etc
	// console.warn(message);
};

const hasRequiredKeys = (props = {}) => {
	const haveFields =
		props.application && props.brand && props.ui && props.spacings && props.typography && props.typography.weight;

	if (!haveFields) {
		uiKitWarn('Theme provided, but doesn\'t have the required fields');
	}

	return haveFields;
};

export const getTheme = (props) => {
	if (!props || !props.theme || !hasRequiredKeys(props.theme)) {
		uiKitWarn('No theme property available on props - using default');
		return theme;
	}

	return props.theme || theme;
};

const themeSlice = (slice, props) => {
	return getTheme(props)[slice];
};

export const application = themeSlice.bind(undefined, 'application');
export const brand = themeSlice.bind(undefined, 'brand');
export const ui = themeSlice.bind(undefined, 'ui');
export const spacings = themeSlice.bind(undefined, 'spacings');
export const typography = themeSlice.bind(undefined, 'typography');

export default theme;
