import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CarouselSlide from './CarouselSlide/CarouselSlide';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { uniqueId } from 'lodash-es';
import { brand, spacings, ui, Icon } from '@tbh/ui-kit';

// Previously using the global Swiper that the legacy stuff uses (bower package and jquery version)
import Swiper from 'swiper';

import 'swiper/dist/css/swiper.min.css';

const StyledCarousel = styled('div')(
	(props) => css`
		label: Carousel;

		${props.padded &&
		css`
				padding-left: ${spacings(props).comfortable}px;
				padding-right: ${spacings(props).comfortable}px;
			`};
	`,
);

const cssCarousel__Button = (props) => css`
	position: absolute;
	padding: 0 ${spacings(props).compact}px;
	opacity: 0.5;
	transition: 0.25s all ease;
	width: 18px;
	height: 60px;
	margin-top: -${spacings(props).spacious}px;
	background-image: none;
	color: ${brand(props).color_1};
	background-color: ${ui(props).color_2};

	&:hover {
		opacity: 0.8;
		background-color: ${ui(props).color_3};
	}

	&:before {
		font-size: 20px;
		line-height: 60px;
		position: relative;
	}

	&.swiper-button-disabled {
		opacity: 0.8;
		background-color: rgb(234 234 235);

		&:before {
			opacity: 0.6;
		}
	}
`;

const StyledCarousel__NextButton = styled('div')(
	(props) => css`
		label: Carousel__NextButton;

		${props.buttonStyle === '1' &&
		css`
				&& {
					right: 0;
					${cssCarousel__Button(props)};

					&:before {
						left: 0%;
					}
				}
			`};
	`,
);
const StyledCarousel__NextButtonIcon = StyledCarousel__NextButton.withComponent(Icon);

const StyledCarousel__PrevButton = styled('div')(
	(props) => css`
		label: Carousel__PrevButton;

		${props.buttonStyle === '1' &&
		css`
				&& {
					left: 0;
					${cssCarousel__Button(props)};
				}
			`};
	`,
);
const StyledCarousel__PrevButtonIcon = StyledCarousel__PrevButton.withComponent(Icon);

class Carousel extends Component {
	static propTypes = {
		/** Children to display for the carousel */
		children: PropTypes.array.isRequired,

		/** All Swiper config options - See documentation */
		settings: PropTypes.object,

		/**
		 * Specifically handled Swiper config options
		 */
		freeMode: PropTypes.bool,
		freeModeMomentumBounce: PropTypes.bool,
		freeModeMomentumRatio: PropTypes.number,
		paginationClickable: PropTypes.bool,
		slidesPerView: PropTypes.any,
		spaceBetween: PropTypes.number,
		roundLengths: PropTypes.bool,

		/** Enabled pagination */
		pagination: PropTypes.bool,

		/** Class name for the paginator */
		paginationClass: PropTypes.string,

		/** Enable the next button */
		nextButton: PropTypes.bool,

		/** Enable the prev button */
		prevButton: PropTypes.bool,

		/** Button style to use */
		buttonStyle: PropTypes.oneOf(['1']),

		/** Extra classes */
		className: PropTypes.string,

		/** Extra class to use for the swiper-wrapper */
		wrapperClassName: PropTypes.string,

		/** Extra class to use for the carousel */
		slideClassName: PropTypes.string,

		/** The ID of the active item */
		active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		/** Action to fire when an item is clicked on */
		onActiveChange: PropTypes.func,

		/** Reinitialise the swiper instance on mount */
		reInitOnFirstMount: PropTypes.bool,

		/** Children to display in the next button */
		nextButtonContent: PropTypes.node,

		/** Children to display in the prev button */
		prevButtonContent: PropTypes.node,

		/** Add padding to add to the component */
		padded: PropTypes.bool,

		/** Classes for the pagination */
		paginationClassName: PropTypes.string,

		/** If the carousel items shouldn't be links */
		noLinkItems: PropTypes.bool,
	};

	static defaultProps = {
		freeMode: false,
		pagination: false,
		nextButton: false,
		prevButton: false,
		freeModeMomentumBounce: false,
		freeModeMomentumRatio: 0.35,
		paginationClickable: true,
		slidesPerView: 'auto',
		spaceBetween: 0,
		roundLengths: true,
		buttonStyle: null,
		padded: false,
		paginationClass: '',
		className: '',
		wrapperClassName: '',
		slideClassName: '',
		settings: {},
		active: '',
		onActiveChange: function () { },
		reInitOnFirstMount: false,
		nextButtonContent: null,
		prevButtonContent: null,
		paginationClassName: null,
		noLinkItems: false,
	};

	constructor(props) {
		super(props);

		let {
			freeMode,
			freeModeMomentumBounce,
			freeModeMomentumRatio,
			paginationClickable,
			roundLengths,
			slidesPerView,
			spaceBetween,
		} = props;

		let config = {
			freeMode,
			freeModeMomentumBounce,
			freeModeMomentumRatio,
			paginationClickable,
			roundLengths,
			slidesPerView,
			spaceBetween,
		};

		if (props.pagination) {
			config.pagination = this.generateClass('swiper-pagination', props.paginationClass);
		}

		if (props.nextButton) {
			config.nextButton = this.generateClass('swiper-button-next');
			if (props.buttonStyle === '1') {
				config.nextButton += '.tbicon.tbicon-arrow-right';
			}
		}

		if (props.prevButton) {
			config.prevButton = this.generateClass('swiper-button-prev');
			if (props.buttonStyle === '1') {
				config.prevButton += '.tbicon.tbicon-arrow-left';
			}
		}

		this.state = {
			settings: Object.assign(
				{},
				{
					scrollbar: '.swiper-scroll-bar',
					onSliderMove() {
						window.disableTapEvents = true;
					},
					onTouchEnd() {
						setTimeout(() => {
							window.disableTapEvents = false;
						}, 100);
					},
					onTransitionEnd: (t) => {
						this.handleClick(t.activeIndex);
					},
				},
				config,
				props.settings,
			),
			active: props.active,
		};

		this.handleClick = this.handleClick.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.active !== prevState.active) {
			return {
				active: nextProps.active,
			};
		}

		return null;
	}

	componentDidMount() {
		const { settings } = this.state;
		if (this.swiperInstance === undefined) {
			this.swiperInstance = new Swiper(this.swiper, settings);
			this.didMount = true;
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.swiperInstance) {
			return;
		}

		if (prevProps.settings.initialSlide !== this.props.settings.initialSlide) {
			this.setState(
				{
					settings: Object.assign({}, prevState.settings, this.props.settings),
				},
				() => {
					this.swiperInstance.slideTo(this.props.settings.initialSlide ? this.props.settings.initialSlide - 1 : 0, 0);
				},
			);
		}

		this.swiperInstance.update();
		this.swiperInstance.emit('onInit', this.swiperInstance);

		if (this.swiperInstance.activeIndex > this.swiperInstance.slides.length) {
			this.swiperInstance.slideTo(this.swiperInstance.slides.length, 0);
		}

		if (this.props.reInitOnFirstMount && this.didMount) {
			this.didMount = false;
			this.swiperInstance.init();
		}

		if (this.state.active !== prevState.active) {
			this.props.onActiveChange(this.state.active);
		}
	}

	componentWillUnmount() {
		if (this.swiperInstance !== undefined && this.props.children.length > 0) {
			this.swiperInstance.destroy();
			this.swiperInstance = undefined;
		}
	}

	/**
	 * Generate a class name. This exists because the next, prev and page pages need to be unique
	 * and compat with a jquery-style selector
	 *
	 * @param className
	 * @param additionalClasses
	 * @returns {*}
	 */
	generateClass(className, additionalClasses) {
		let classes = '.' + className;
		classes += uniqueId('.swiper-pagination-');

		if (additionalClasses) {
			additionalClasses.split(' ').forEach((name) => {
				classes += '.' + name;
			});
		}

		return classes;
	}

	/**
	 * Handle click on tab
	 *
	 * @param itemId
	 */
	handleClick(itemId) {
		if (itemId === this.state.active) {
			return;
		}

		this.setState({
			active: itemId,
		});
	}

	render() {
		const {
			children,
			className,
			wrapperClassName,
			slideClassName,
			padded,
			nextButtonContent,
			prevButtonContent,
			buttonStyle,
			paginationClassName,
			noLinkItems,
			isActiveNavigation
		} = this.props;
		const { settings } = this.state;

		const containerClasses = cx('swiper-container', 'carousel__container', {
			[className]: className,
		});

		const NextButton = buttonStyle === '1' ? StyledCarousel__NextButtonIcon : StyledCarousel__NextButton;
		const PrevButton = buttonStyle === '1' ? StyledCarousel__PrevButtonIcon : StyledCarousel__PrevButton;
		return (
			<StyledCarousel
				className={containerClasses}
				padded={padded}
				innerRef={(e) => {
					this.swiper = e;
				}}
			>
				<div className="swiper-scroll-bar" />
				<div className={'swiper-wrapper ' + wrapperClassName}>
					{children.map((c) => (
						<CarouselSlide
							key={c.key}
							className={slideClassName + ' ' + c.props.swiperParentClasses}
							itemId={c.key}
							autoWidth={!!settings.freeMode}
							onClick={this.handleClick}
							noLinkItems={noLinkItems}
						>
							{React.cloneElement(c)}
						</CarouselSlide>
					))}
				</div>
				{settings.pagination ? (
					<div className={cx(settings.pagination.split('.').join(' '), paginationClassName)} />
				) : null}
				{settings.nextButton ? (
					<React.Fragment>
						{isActiveNavigation && (
							<NextButton
								buttonStyle={buttonStyle}
								icon={buttonStyle === '1' ? 'arrow-right ' : null}
								className={settings.nextButton.split('.').join(' ')}
							>
								{nextButtonContent}
							</NextButton>
						)}
					</React.Fragment>
				) : null}
				{settings.prevButton ? (
					<React.Fragment>
						{isActiveNavigation && (
							<PrevButton
								buttonStyle={buttonStyle}
								icon={buttonStyle === '1' ? 'arrow-left ' : null}
								className={settings.prevButton.split('.').join(' ')}
							>
								{prevButtonContent}
							</PrevButton>
						)}
					</React.Fragment>
				) : null}
				{/* // Rever comentario */}
				{'scrollbar' in settings ? <div className={settings.scrollbar.replace(/^\./g, '')} /> : null}
			</StyledCarousel>
		);
	}
}

Carousel.NextButton = StyledCarousel__NextButton;
Carousel.PrevButton = StyledCarousel__PrevButton;
Carousel.NextButtonIcon = StyledCarousel__NextButtonIcon;
Carousel.PrevButtonIcon = StyledCarousel__PrevButtonIcon;
Carousel.StyledCarouselSlide = CarouselSlide.StyledCarouselSlide;
Carousel.StyledCarouselSlideSpan = CarouselSlide.StyledCarouselSlideSpan;

export default Carousel;
