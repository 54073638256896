export const WITHDRAWALS_SET_DATA_LOADED = 'WITHDRAWALS_SET_DATA_LOADED';
export const WITHDRAWALS_SET_RECEIPT_ID = 'WITHDRAWALS_SET_RECEIPT_ID';
export const WITHDRAWALS_SET_ERRORS = 'WITHDRAWALS_SET_ERRORS';
export const WITHDRAWALS_SET_CREATING_WITHDRAWAL = 'WITHDRAWALS_SET_LOADING_MASK';
export const WITHDRAWALS_SET_CREATING_BANK_ACCOUNT = 'WITHDRAWALS_SET_CREATING_BANK_ACCOUNT';
export const WITHDRAWALS_SET_LAST_CREATED_ACCOUNT = 'WITHDRAWALS_SET_LAST_CREATED_ACCOUNT';
export const WITHDRAWALS_SET_SHOULD_VERIFY = 'WITHDRAWALS_SET_SHOULD_VERIFY';

export const WITHDRAWALS_CLEAR_STATE = 'WITHDRAWALS_CLEAR_STATE';
export const WITHDRAWALS_CLEAR_ENTITIES = 'WITHDRAWALS_CLEAR_ENTITIES';

export const WITHDRAWALS_CLEAR_DATA_BATCH = 'WITHDRAWALS_CLEAR_DATA_BATCH';
export const WITHDRAWALS_FIRST_DATA_LOADED_BATCH = 'WITHDRAWALS_FIRST_DATA_LOADED_BATCH';
