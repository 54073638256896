const LightenDarkenColor = () => {
	return 'red';
};


export const COLOR_WHITE = '#ffffff';
export const COLOR_WHITE_DARK = '#f9f9f9';
export const COLOR_GREY_LIGHT = '#ececeb';
export const COLOR_GREY = '#dddddd';
export const COLOR_GREY_MEDIUM = '#888888';
export const COLOR_GREY_DARK = '#666666';
export const COLOR_BLACK = '#222222';

export const COLOR_GREEN = '#32be60';
export const COLOR_YELLOW = '#F8DC1E';
export const COLOR_ORANGE = '#ee9c15';
export const COLOR_BLUE = '#5d97fb';
export const COLOR_RED = '#cf1b41';
export const COLOR_PURPLE = '#3C487D';

/**
 * 'transparent' is not properly supported in IE and
 * the post processor auto converts rgba(0,0,0,0) to 'transparent' >:[
 */
export const COLOR_TRANSPARENT = 'rgba(255,255,255,0)';

export const GREY_COLORS = {
	white: COLOR_WHITE,
	white_dark: COLOR_WHITE_DARK,
	grey_light: COLOR_GREY_LIGHT,
	grey: COLOR_GREY,
	grey_medium: COLOR_GREY_MEDIUM,
	grey_dark: COLOR_GREY_DARK,
	black: COLOR_BLACK,
};

export const BASE_COLORS = {
	green: COLOR_GREEN,
	yellow: COLOR_YELLOW,
	orange: COLOR_ORANGE,
	blue: COLOR_BLUE,
	red: COLOR_RED,
	purple: COLOR_PURPLE,
};

/**
 GLOBAL TOTE COLOURS
 */
export const COLOR_GLOBAL_TOTE = '#2dafe6';

/**
 SOCIAL MEDIA COLOURS
 */
export const COLOR_SOCIAL_FACEBOOK = '#4e71a7';
export const COLOR_SOCIAL_LINKEDIN = '#1685b0';
export const COLOR_SOCIAL_TWITTER = '#1cb6ea';
export const COLOR_SOCIAL_INSTAGRAM = '#D93175';

export const SOCIAL_COLORS = {
	facebook: COLOR_SOCIAL_FACEBOOK,
	linkedin: COLOR_SOCIAL_LINKEDIN,
	twitter: COLOR_SOCIAL_TWITTER,
	instagram: COLOR_SOCIAL_INSTAGRAM,
};

/**
 SPEEDMAP COLOURS
 */
export const COLOR_LEADER = '#f28131';
export const COLOR_OFFPACE = '#f6a973';
export const COLOR_MIDFIELD = '#facfb2';
export const COLOR_BACKMARKER = '#fdeee3';

/**
 BRAND
 */
export const COLOR_BRAND_ONE_DARK = '#850000'; //'#212529';// @HW  old color'#BA0064'
export const COLOR_BRAND_ONE = '#2E2E2E'; //'#c72128'//'#343a40'; // @HW old color is #FF0080
export const COLOR_BRAND_ONE_LIGHT = '#FF33B3';

export const COLOR_BRAND_TWO_DARK = '#101F29';
export const COLOR_BRAND_TWO = '#293842';
export const COLOR_BRAND_TWO_LIGHT = '#000000';

export const COLOR_BRAND_THREE = '#D8D8D8';

export const COLOR_BRAND_FOUR = '#979797';

export const COLOR_BRAND_FIVE = '#079191';

export const BRAND_COLORS = {
	color_1: {
		normal: COLOR_BRAND_ONE,
		light: COLOR_BRAND_ONE_LIGHT,
		dark: COLOR_BRAND_ONE_DARK,
	},
	color_2: {
		normal: COLOR_BRAND_TWO,
		light: COLOR_BRAND_TWO_LIGHT,
		dark: COLOR_BRAND_TWO_DARK,
	},
	color_3: {
		normal: COLOR_BRAND_THREE,
		light: LightenDarkenColor(COLOR_BRAND_THREE, 10),
		dark: LightenDarkenColor(COLOR_BRAND_THREE, -10),
	},
	color_4: {
		normal: COLOR_BRAND_FOUR,
		light: LightenDarkenColor(COLOR_BRAND_FOUR, 10),
		dark: LightenDarkenColor(COLOR_BRAND_FOUR, -10),
	},

	color_5: {
		normal: COLOR_BRAND_FIVE,
		light: LightenDarkenColor(COLOR_BRAND_FIVE, 10),
		dark: LightenDarkenColor(COLOR_BRAND_FIVE, -10),
	},
};

/**
 CONTEXT COLOUR
 */
export const COLOR_SUCCESS = COLOR_GREEN;
export const COLOR_DANGER = COLOR_RED;
export const COLOR_WARNING = COLOR_ORANGE;
export const COLOR_INFO = COLOR_BLUE;
export const COLOR_NEUTRAL = COLOR_GREY_LIGHT;

export const CONTEXT_COLORS = {
	success: COLOR_SUCCESS,
	danger: COLOR_DANGER,
	warning: COLOR_WARNING,
	info: COLOR_INFO,
	neutral: COLOR_NEUTRAL,
};

/**
 TEXT
 */
export const COLOR_TEXT_ONE = COLOR_BLACK;
export const COLOR_TEXT_ONE_ALTERNATE = COLOR_WHITE;
export const COLOR_TEXT_TWO = COLOR_GREY_DARK;
export const COLOR_TEXT_THREE = COLOR_GREY;

export const TEXT_COLORS = {
	color_1: COLOR_TEXT_ONE,
	color_1_alternate: COLOR_TEXT_ONE_ALTERNATE,
	color_2: COLOR_TEXT_TWO,
	color_3: COLOR_TEXT_THREE,
};

/**
 OTHER
 */
export const COLOR_SHADOW = '0 0 10px rgba(#000, .25)';

const grays = {
	50: '#f9fafb',
	100: '#f3f4f6',
	200: '#e5e7eb',
	300: '#d1d5db',
	400: '#9ca3af',
	500: '#6b7280',
	600: '#4b5563',
	700: '#374151',
	800: '#1f2937',
	900: '#111827',
	950: '#030712',
};

export const COLORS = {
	...GREY_COLORS,
	...BASE_COLORS,
	...CONTEXT_COLORS,
	global_tote: COLOR_GLOBAL_TOTE,
	leader: COLOR_LEADER,
	offpace: COLOR_OFFPACE,
	midfield: COLOR_MIDFIELD,
	backmarker: COLOR_BACKMARKER,
	shadow: COLOR_SHADOW,
	transparent: COLOR_TRANSPARENT,
	brand: BRAND_COLORS,
	social: SOCIAL_COLORS,
	text: TEXT_COLORS,
	grays,
};
