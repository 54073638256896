import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings, Icon, brand } from '@tbh/ui-kit';
import { Text, Button } from '@tbh/ui-kit';
import moment from 'moment';
import { countdown } from '../../../../legacy/core/format';
import { FONT_SIZE_12 } from '../../../../store/application/applicationConstants';

const StyledNJRaceHeaderBar = styled('div')`
	${(props) => css`
		min-height: 65px; // To Match the height of a regular header bar
		background-color: ${brand(props).color_2};
		width: 100%;
	`};
`;

const StyledNJRaceHeaderBar__Heading = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__Heading;

		padding: ${spacings(props).tight}px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		font-size: ${FONT_SIZE_12};
	`};
`;

const StyledRaceHeaderBar__Heading_raceNumber = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__Heading_raceNumber;

		flex: 0 0 10%;
		max-width: 10%;
	`};
`;

const StyledRaceHeaderBar__Heading_flagIcon = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__Heading_flagIcon;

		flex: 0 0 10%;
		max-width: 10%;
		margin-top: 5px;
	`};
`;

const StyledRaceHeaderBar__Heading_More = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__Heading_More;

		flex: 0 0 80%;
		max-width: 80%;
		margin-left: ${spacings(props).compact}px;
	`};
`;

const StyledNJRaceHeaderBar__Metting__Name = styled('div')`
	${(props) => css`
		label: StyledNJRaceHeaderBar__Metting__Name;
		display: flex;
		margin-top: ${spacings(props).tight}px;
	`};
`;
const StyledNJRaceHeaderBar__Race__Details = styled('div')`
	${(props) => css`
		label: StyledNJRaceHeaderBar__Race__Details;
		display: flex;
	`};
`;
const StyledNJRaceHeaderBar__Flage__Details = styled('div')`
	${(props) => css`
		label: StyledNJRaceHeaderBar__Flage__Details;
		display: flex;
		margin-top: ${spacings(props).tight}px;
	`};
`;
const StyledNextToJumpCardItem__TimeToGoBUtton = styled(Button)(
	(props) => css`
		label: StyledNextToJumpCardItem__TimeToGoBUtton;

		font-size: ${FONT_SIZE_12};
		display: block;
		margin-top: ${spacings(props).tight}px;
		background: ${brand(props).rch_4};
		border-color: ${brand(props).rch_4};
		size: small;
		width: auto;
		height: 25px;
		margin-top: -5px;
		position: absolute;
		color: #ffffff;
		font-weight: bold;

		${props.lessThan2Min === 'less2Min' &&
			css`
				&& {
					background-color: #379b37;
					border: 1px solid #379b37;
					border-radius: 3px !important;
				}
			`};
	`,
);

/////////////////////////////////////////////////////////////

const StyledCarouselItem__Image = styled(Image)(
	(props) => css`
		label: CarouselItem__Image;

		margin: 0 0 ${spacings(props).compact}px 0;
		pointer-events: none;
		max-width: none;

		${props.center &&
			css`
				margin-left: auto;
				margin-right: auto;
			`};
	`,
);

const StyledCarouselItem__Label = styled(Text)(
	(props) => css`
		label: CarouselItem__Label;

		display: block;
		margin-bottom: ${spacings(props).tight}px;
	`,
);

const StyledCarouselItemV3 = styled(Text)(
	(props) => css`
		label: CarouselItem;

		display: block;
		cursor: pointer;
		width: 100%;
	`,
);

const styleFlag = css`
	width: 25px;
	height: 12px;
	margin-left: 10px;
`;

const CarouselItemV3 = (props) => {
	const {
		className,
		raceNumber,
		children,
		id,
		label,
		text,
		meta,
		icon,
		image,
		action,
		OpenNext2JumpItem,
		meetingStateText,
		raceText,
		showSelections,
		distance,
		country,
		start_datetime,
		size,
		lessThan2Min,
		...rest
	} = props;
	const { center } = rest;
	const carouselItemClassv3 = cx({
		[className]: className,
	});

	const componentClassesv3 = cx({
		[className]: className,
	});

	const timeTogo = () => {
		return countdown(start_datetime);
	};

	/**
	 * Builds the state and race number display string
	 *
	 * @returns {string}
	 */
	const buildStateAndRaceNumberLabel = () => {
		let stateAndRaceNumberLabel = '';

		// Show the meeting state,

		if (label) {
			stateAndRaceNumberLabel += `${label}`;
		}

		return stateAndRaceNumberLabel;
	};

	const carouselImage = image && <StyledCarouselItem__Image center={center} height={64} src={image} alt={label} />;
	const carouselIcon = icon && <Icon icon={icon} size="1" push="1" color="white" />;
	const carouselLabel = label && (
		<StyledCarouselItem__Label size="-1" whiteSpace="nowrap" ellipsis strong>
			{carouselIcon}
			{label}
		</StyledCarouselItem__Label>
	);

	const handleClick = (e) => {
		e.preventDefault();
		action(id);
	};

	if (children) {
		return (
			<StyledCarouselItemV3 {...rest} className={carouselItemClassv3}>
				{children}
			</StyledCarouselItemV3>
		);
	}

	return (
		<StyledCarouselItemV3 size={size} {...rest} className={carouselItemClassv3} action={handleClick}>
			<StyledNJRaceHeaderBar noPadding className={componentClassesv3} margin={false}>
				<StyledNJRaceHeaderBar__Heading>
					<StyledRaceHeaderBar__Heading_raceNumber>
						<div
							className={css`
							color: #ffffff;
							background: #666666;
							border: 1px solid #666666;
							border-bottom: 2px solid #5c5c5c;
							text-align: center;
							margin-top: 8px;
						}`}
						>
							{raceNumber}
						</div>
					</StyledRaceHeaderBar__Heading_raceNumber>

					<StyledRaceHeaderBar__Heading_flagIcon>
						<div>
							<Icon
								key="2"
								className={css`
									margin-left: 10px;
									color: #ffffff;
								`}
								size="-1"
								icon={icon}
								push="1"
							/>
						</div>
						<div>
							{country === 'AU' ? (
								<img
									src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/AUS.svg"
									alt="AUS LOGO"
									title={country}
									className={styleFlag}
								/>
							) : country === 'NZ' ? (
								<img
									src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/NZ.svg"
									alt="NZ LOGO"
									title={country}
									className={styleFlag}
								/>
							) : country === 'SGP' ? (
								<img
									src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SGP.svg"
									alt="SGP LOGO"
									title={country}
									className={styleFlag}
								/>
							) : country === 'HK' ? (
								<img
									src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/HK.svg"
									alt="HK LOGO"
									title={country}
									className={styleFlag}
								/>
							) : country === 'FR' ? (
								<img
									src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/FR.svg"
									alt="FR LOGO"
									title={country}
									className={styleFlag}
								/>
							) : country === 'UK' ? (
								<img
									src="https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/GB.svg"
									title={country}
									alt="GB LOGO"
									className={styleFlag}
								/>
							) : null}
						</div>
					</StyledRaceHeaderBar__Heading_flagIcon>

					<StyledRaceHeaderBar__Heading_More>
						<StyledNJRaceHeaderBar__Metting__Name>
							<Text
								className={css`
									color: #ffffff;
									text-align: left;
									padding-left: 8px;
									overflow: hidden;
									font-size: ${FONT_SIZE_12};
									text-overflow: ellipsis;
								`}
							>
								{text + ' - ' + distance + 'm'}
							</Text>
						</StyledNJRaceHeaderBar__Metting__Name>
						{/* <StyledNJRaceHeaderBar__Race__Details>
							<Text className={css`color:#ffffff;text-align:left;padding-left:8px;`} size="-2" strong> {distance}{'m'}</Text>
						</StyledNJRaceHeaderBar__Race__Details> */}
						<StyledNJRaceHeaderBar__Flage__Details>
							<div
								className={css`
									flex: 0 0 65%;
									max-width: 65%;
								`}
							>
								<Text
									className={css`
										color: #ffffff;
										text-align: left;
										padding-left: 8px;
										font-size: ${FONT_SIZE_12};
									`}
								>
									{' '}
									{moment(start_datetime).calendar()}{' '}
								</Text>
							</div>
							<div
								className={css`
									flex: 0 0 35%;
									max-width: 35%;
								`}
							>
								<StyledNextToJumpCardItem__TimeToGoBUtton lessThan2Min={lessThan2Min}>
									{meta}
								</StyledNextToJumpCardItem__TimeToGoBUtton>
							</div>
						</StyledNJRaceHeaderBar__Flage__Details>
					</StyledRaceHeaderBar__Heading_More>
				</StyledNJRaceHeaderBar__Heading>
			</StyledNJRaceHeaderBar>
		</StyledCarouselItemV3>
	);
};
CarouselItemV3.propTypes = {
	/** ID of the carousel item */
	id: PropTypes.any.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Label for the carousel item */
	label: PropTypes.string,

	/** Text for the carousel item */
	text: PropTypes.string,

	/** Extra information for the carousel item */
	meta: PropTypes.string,

	/** Icon to display in the carousel item */
	icon: PropTypes.string,

	/** Image to display in the carousel item */
	image: PropTypes.string,

	/** How many slides to display */
	slides: PropTypes.string,

	/** If the carousel item should be centered */
	center: PropTypes.bool,

	/** If the carousel item should have padding */
	padding: PropTypes.bool,

	/** If the carousel item should have a margin */
	margin: PropTypes.bool,

	/** If the carousel item should have a border */
	border: PropTypes.bool,

	/** Action when the carousel item is clicked on */
	action: PropTypes.func,

	/** Children to display under the carousel item */
	children: PropTypes.node,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}),

	/** Whether to use a small amount of flex to fit more buttons */
	small: PropTypes.bool,

	meetingStateText: PropTypes.string,

	raceText: PropTypes.string,

	start_datetime: PropTypes.string,

	distance: PropTypes.string,

	raceNumber: PropTypes.number,

	lessThan2Min: PropTypes.string,
};

CarouselItemV3.defaultProps = {
	className: '',
	label: undefined,
	text: undefined,
	meta: undefined,
	icon: undefined,
	image: undefined,
	slides: undefined,
	center: undefined,
	padding: undefined,
	margin: undefined,
	border: undefined,
	action: undefined,
	children: undefined,
	small: null,
	size: undefined,
	meetingStateText: '',
	raceText: '',
	start_datetime: '',
	distance: 0,
	raceNumber: null,
	lessThan2Min: null,
};

export default CarouselItemV3;
