export const SERVER_DATE_TIME_FORMAT = '';
export const SERVER_REGION = 'Australia/Sydney';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_FORMAT = 'D MMMM YYYY'; // 20 March 2018
export const DAY_AND_MONTH_FORMAT = 'Do MMMM'; // 21st May
export const DATE_TIME_DISPLAY_FORMAT = 'MMM Do, h:mma'; // Mar 20th, 4:05am
export const TIME_DATE_DISPLAY_FORMAT = 'Do MMM HH:mm z'; // 20th Mar 04:05
export const TIME_DATE_TZ_DISPLAY_FORMAT = 'h:mmA Do MMMM z'; // 12:02PM 26th March
export const HOUR_MIN_AMPM = 'H:MMA'; // 2:37am
export const HOUR_MIN_12H = 'hh:mm A'; // 10:15 AM
export const HOUR_MIN_24H = 'HH:mm'; // 2:37am
export const HOUR_MIN_SEC_AMPM_TZ = 'h:mm:ss a (zz)'; // 2:37:00 am (AEDT)
export const FORM_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'Do MMM h:mma'; // 8th Mar 7:50pm
export const LONG_DATE_TIME_FORMAT = 'Do MMMM h:mma'; // 11th March 6:30pm
export const DAY_DATE_FORMAT = 'dddd, Do MMM YYYY'; // Monday, 21st May 2018
export const CALENDAR = {
	sameDay: '[Today, ]Do MMMM', // Today, 28th September
	nextDay: '[Tomorrow, ]Do MMMM', // Tomorrow, 29th September
	nextWeek: 'dddd[, ]Do MMMM', // Wednesday, 3rd October
};
