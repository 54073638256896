// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

const SelectOption = (props) => {
	const { className, children, label, value } = props;

	const selectOptionClass = cx({
		[className]: className,
	});

	return (
		<option className={selectOptionClass} value={value} label={label}>
			{children}
		</option>
	);
};

SelectOption.propTypes = {
	/**
	 * Value attribute
	 * It represents the value to be submitted with the form
	 */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

	/**
	 * Label attribute
	 * It indicates the meaning of the option
	 */
	label: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	/** Content of the component */
	children: PropTypes.node,
};

SelectOption.defaultProps = {
	className: '',
	label: null,
	children: null,
};

export default SelectOption;
