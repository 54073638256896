import ic_greyhound_black from '../../assets/img/ic_greyhound_black.png';
import ic_greyhound_white from '../../assets/img/ic_greyhound_white.png';
import ic_harness_black from '../../assets/img/ic_harness_black.png';
import ic_harness_white from '../../assets/img/ic_harness_white.png';
import ic_horse_black from '../../assets/img/ic_horse_black.png';
import ic_horse_white from '../../assets/img/ic_horse_white.png';

/**
 * The image path for the default silk used when there is no silk supplied or a loading error
 */
export const GENERIC_SILK = 'https://www.racingandsports.com/resources/img/form-guide/default-silk.png';

/**
 * Racing type codes >> 'T = Thoroughbred / Horse Racing/thoroughbred', 'G = Greyhounds / Dogs', 'H = Harness / Trots'
 */

export const RACING_THOROUGHBRED_CODE = 'T';
export const RACING_HARNESS_CODE = 'H';
export const RACING_GREYHOUNDS_CODE = 'G';

export const RACING_THOROUGHBRED_TYPE = 'thoroughbred';
export const RACING_GALLOPS_TYPE = 'gallops'; // just use for icon imgae
export const RACING_HARNESS_TYPE = 'harness';
export const RACING_GREYHOUNDS_TYPE = 'greyhounds';

/**
 * Array of available racing types
 */
export const RACING_TYPE_CODES = [RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE];

/**
 * Racing types dictionary to lookup the type by it's code
 */
export const RACING_TYPES_LOOKUP = {
	[RACING_THOROUGHBRED_CODE]: RACING_THOROUGHBRED_TYPE,
	[RACING_HARNESS_CODE]: RACING_HARNESS_TYPE,
	[RACING_GREYHOUNDS_CODE]: RACING_GREYHOUNDS_TYPE,
};

export const RACING_TYPES_ICONS = {
	[RACING_THOROUGHBRED_CODE]: {
		black: ic_horse_black,
		white: ic_horse_white,
	},
	[RACING_HARNESS_CODE]: {
		black: ic_harness_black,
		white: ic_harness_white,
	},
	[RACING_GREYHOUNDS_CODE]: {
		black: ic_greyhound_black,
		white: ic_greyhound_white,
	},
};
/**
 * Racing types dictionary to lookup the type by it's type
 */
export const RACING_TYPES_REVERSE_LOOKUP = {
	[RACING_THOROUGHBRED_TYPE]: RACING_THOROUGHBRED_CODE,
	[RACING_HARNESS_TYPE]: RACING_HARNESS_CODE,
	[RACING_GREYHOUNDS_TYPE]: RACING_GREYHOUNDS_CODE,
};

/**
 * Sport types dictionary to lookup the type by it's code
 */
export const SPORT_BASKET_BALL_CODE = 'Basketball';
export const SPORT_BASKET_BALL_ICON_CODE = 'basketball';
export const SPORT_SOCCER_CODE = 'Soccer';
export const SPORT_SOCCER_ICON_CODE = 'soccer';
export const SPORT_Badminton_CODE = 'Badminton';
export const SPORT_Baseball_CODE = 'Baseball';
export const SPORT_Basketball_CODE = 'Basketball';
export const SPORT_Cricket_CODE = 'Cricket';
export const SPORT_Cycling_CODE = 'Cycling';
export const SPORT_Soccer_CODE = 'Soccer';
export const SPORT_Tennis_CODE = 'Tennis';
export const SPORT_Rugby_League_CODE = 'Rugby League';
export const SPORT_Australian_Rules_CODE = 'Australian Rules';
// export const SPORT_Australian_Rules_CODE = 'Australian Rules';

/**
 * Meeting group titles.
 */
export const RACING_GROUP_TITLE_THOROUGHBRED = 'THOROUGHBRED';
export const RACING_GROUP_TITLE_GREYHOUNDS = 'GREYHOUNDS';
export const RACING_GROUP_TITLE_HARNESS = 'HARNESS';
{
	/*export const RACING_GROUP_TITLE_LOCAL = 'AU/NZ';*/
}
export const RACING_GROUP_TITLE_LOCAL = '';
export const RACING_GROUP_TITLE_INTERNATIONAL = 'INTERNATIONAL';
export const RACING_GROUP_TITLE_OTHER = 'OTHER';

export const RACING_GROUP_GALLOPS = 'G'; // @HW

/**
 * Countries
 */
export const RACING_AU = 'AU';
export const RACING_NZ = 'NZ';
export const RACING_INT = 'INT';
export const RACING_FR = 'FR';
export const RACING_SGP = 'SGP';
export const RACING_HK = 'HK';
export const RACING_UK = 'UK';

export const AU_STATE_CODE_VIC = 'VIC';
export const AU_STATE_CODE_NSW = 'NSW';
export const AU_STATE_CODE_QLD = 'QLD';
export const AU_STATE_CODE_WA = 'WA';
export const AU_STATE_CODE_SA = 'SA';
export const AU_STATE_CODE_NT = 'NT';
export const AU_STATE_CODE_TAS = 'TAS';
export const AU_STATE_CODE_ACT = 'ACT';

/**
 * BetBuilder Filter
 */
export const BET_BUILDER_LAST_POSITION_WIN = 'LP_W';
export const BET_BUILDER_LAST_POSITION_PLACE = 'LP_P';
export const BET_BUILDER_LAST_BIG_WIN_2 = '2L+';
export const BET_BUILDER_LAST_BIG_WIN_5 = '5L+';
export const BET_BUILDER_BEATEN_FAV_YES = 'fwy';
export const BET_BUILDER_BEATEN_FAV_NO = 'fwn';
export const BET_BUILDER_DISTANCE_WIN = 'DS_W';
export const BET_BUILDER_DISTANCE_PLACE = 'DS_P';
export const BET_BUILDER_COURSE_WIN = 'C_W';
export const BET_BUILDER_COURSE_PLACE = 'C_P';
export const BET_BUILDER_COURSE_DISTANCE_WIN = 'CD_W';
export const BET_BUILDER_COURSE_DISTANCE_PLACE = 'CD_P';
export const BET_BUILDER_RATE_TO_WIN_Y = 'RW_Y';
export const BET_BUILDER_RATE_TO_WIN_N = 'RW_N';
export const BET_BUILDER_WET_WIN = 'WT_W';
export const BET_BUILDER_WET_PLACE = 'WT_P';
export const BET_BUILDER_STRIKE_RATE_16 = 'T_16';
export const BET_BUILDER_STRIKE_RATE_20 = 'T_20';
export const BET_BUILDER_JOCKEY_STRIKE_RATE_16 = 'J_16';
export const BET_BUILDER_JOCKEY_STRIKE_RATE_20 = 'J_20';
export const BET_BUILDER_JOCKEY_WON_HORSE_Y = 'JH_Y';
export const BET_BUILDER_JOCKEY_WON_HORSE_N = 'JH_N';
export const BET_BUILDER_JOCKEY_SWITCH_Y = 'JSW_Y';
export const BET_BUILDER_JOCKEY_SWITCH_N = 'JSW_N';
export const BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16 = 'TJ_16';
export const BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20 = 'TJ_20';

// main menu
export const BET_BUILDER = 'Bet Builder';
export const FEATURES = 'Features';

export const BET_BUILDER_URL = 'betbuilder';
export const RACING_CURRENT_VERSION_URL = 'racing-v3';

/**
 * Race status definitions
 */
export const RACE_CLOSED_STATUS = 'closed';
export const RACE_ABANDONED_STATUS = 'abandoned';
export const RACE_SUSPENDED_STATUS = 'suspended';
export const RACE_INTERIM_STATUS = 'interim';
export const RACE_PAYING_STATUS = 'paying';
export const RACE_PAID_STATUS = 'paid';
export const RACE_DELETED_STATUS = 'deleted';
export const RACE_SELLING_STATUS = 'selling';
export const RACE_AT_THE_POST_STATUS = 'at_the_post';
export const RACE_LONG_DELAY = 'long_delay';
export const RACE_SHORT_DELAY = 'short_delay';
export const RACE_GOING_DOWN_STATUS = 'going_down';
export const RACE_AT_THE_PARADING_STATUS = 'parading';
export const RACE_GOING_BEHIND_STATUS = 'going_behind';
export const RACE_FINISHED_STATUS = 'finished';
export const RACE_FALSE_START_STATUS = 'false_start';
export const RACE_PROTEST_STATUS = 'protest';
export const RACE_PROTEST_UPHELD = 'protest_upheld';
export const RACE_PROTEST_DISMISSED = 'protest_dismissed';
export const RACE_FINISH_STATUS = 'false_start';
// Andoned
// Deleted
// False Start
// Going Down
export const RaceStatusEventById = (id) => {
	switch (id) {
		case 1:
			return RACE_SELLING_STATUS;
		case 2:
			return RACE_PAYING_STATUS;
		case 3:
			return RACE_ABANDONED_STATUS;
		case 4:
			return RACE_PAID_STATUS;
		case 5:
			return RACE_CLOSED_STATUS;
		case 6:
			return RACE_INTERIM_STATUS;
		case 7:
			return RACE_DELETED_STATUS;
		case 8:
			return RACE_AT_THE_POST_STATUS;
		case 9:
			return RACE_GOING_BEHIND_STATUS;
		case 10:
			return RACE_FINISH_STATUS;
		case 11:
			return RACE_FINISHED_STATUS;
		case 12:
			return RACE_PROTEST_STATUS;
		case 13:
			return RACE_PROTEST_UPHELD;
		case 14:
			return RACE_PROTEST_DISMISSED;
		case 15:
			return RACE_AT_THE_PARADING_STATUS;
		case 16:
			return RACE_GOING_DOWN_STATUS;
		default:
			return RACE_SELLING_STATUS;
	}
};
export const RACE_CLOSED_EVENT_ID_STATUS = 5;
export const RACE_ABANDONED_EVENT_ID_STATUS = 3;
export const RACE_INTERIM_EVENT_ID_STATUS = 6;
export const RACE_PAYING_EVENT_ID_STATUS = 2;
export const RACE_PAID_EVENT_ID_STATUS = 4;
export const RACE_DELETED_EVENT_ID_STATUS = 7;
export const RACE_SELLING_EVENT_ID_STATUS = 1;
export const RACE_AT_THE_POST_EVENT_ID_STATUS = 8;
export const RACE_AT_THE_PARADING_EVENT_ID_STATUS = 15;
export const RACE_GOING_BEHIND_EVENT_ID_STATUS = 9;
export const RACE_FINISHED_EVENT_ID_STATUS = 11;
export const RACE_PROTEST_EVENT_ID_STATUS = 12;
export const RACE_PROTEST_UPHELD_EVENT_ID_STATUS = 13;
export const RACE_PROTEST_DISMISSED_EVENT_ID_STATUS = 14;

/**
 * Available race status definitions
 */
export const RACE_STATUSES = [
	RACE_SELLING_STATUS,
	RACE_CLOSED_STATUS,
	RACE_ABANDONED_STATUS,
	RACE_INTERIM_STATUS,
	RACE_PAYING_STATUS,
	RACE_PAID_STATUS,
	RACE_DELETED_STATUS,
	RACE_AT_THE_POST_STATUS,
	RACE_GOING_BEHIND_STATUS,
	RACE_FINISHED_STATUS,
	RACE_PROTEST_STATUS,
	RACE_PROTEST_UPHELD,
	RACE_PROTEST_DISMISSED,
	RACE_AT_THE_PARADING_STATUS,
];

/**
 * Selection status & scratching definitions
 */
export const SELECTION_NOT_SCRATCHED_STATUS = 'not scratched';
export const SELECTION_SCRATCHED_STATUS = 'scratched';
export const SELECTION_LATE_SCRATCHED_STATUS = 'late scratching';
/**
 * Available selection status definitions
 */
//(['not scratched', 'scratched', 'late scratching']),
export const SELECTION_STATUSES = [
	SELECTION_NOT_SCRATCHED_STATUS,
	SELECTION_SCRATCHED_STATUS,
	SELECTION_LATE_SCRATCHED_STATUS,
];

export const RACING_GRADE_METRO = 'METRO';
export const RACING_GRADE_PROVINCIAL = 'PROVINCIAL';
export const RACING_GRADE_COUNTRY = 'COUNTRY';

/**
 * Grade importance to applied to sorting functions. The higher the number, the higher importance.
 */
export const RACING_GRADE_IMPORTANCE_LOOKUP = {
	[RACING_GRADE_METRO]: 3,
	[RACING_GRADE_PROVINCIAL]: 2,
	[RACING_GRADE_COUNTRY]: 1,
	// Tested and works fine with modern JS:
	// [0]: 0,
	// ['']: 0,
	// [null]: 0,
	// [undefined]: 0
};

/**
 * Country importance
 */
export const RACING_COUNTRY_IMPORTANCE_LOOKUP = {
	[RACING_AU]: 3,
	[RACING_NZ]: 2,
	[RACING_INT]: 1,
};

/**
 * Australian states importance. Based on FR v0.3.
 */
export const RACING_STATE_IMPORTANCE_LOOKUP = {
	[AU_STATE_CODE_VIC]: 8,
	[AU_STATE_CODE_NSW]: 7,
	[AU_STATE_CODE_QLD]: 6,
	[AU_STATE_CODE_WA]: 5,
	[AU_STATE_CODE_SA]: 4,
	[AU_STATE_CODE_NT]: 3,
	[AU_STATE_CODE_TAS]: 2,
	[AU_STATE_CODE_ACT]: 1,
};

export const RACING_NON_EXOTIC_BET_TYPE = 'winplace';
export const RACING_NON_EXOTIC_BET_TYPE_TITLE = 'Win/Place';
export const RACING_QUADDIES_TITLE = 'Multiples';
export const RACING_EXOTIC_BET_TYPE = 'exotic';
export const RACING_ODD_GRID_BET_TYPE_TITLE = 'Odds Grid';
export const RACING_ODD_GRID_BET_TYPE = 'OddGrid';
export const RACING_MULTIPLES_TYPE = 'Multiples';
export const RACING_MULTIPLES_TYPE_TITLE = 'Multiples';
export const RACING_MORE_MARKETS_TYPE = 'MoreMarkets';
export const RACING_MORE_MARKETS_TYPE_TITLE = 'More Markets';
export const RACING_SAME_RACE_MULTI_TYPE = 'sameracemulti';
export const RACING_SAME_RACE_MULTI_TYPE_TITLE = 'Same Race Multi';
export const RACING_ISSURE_BET_TYPE_TITLE = 'Insure Bet';
export const RACING_ISSURE_BET_TYPE = 'insurebet';
export const RACING_BET_TYPE_ISSURE_BET = 'insurebet';
export const RACING_BET_TYPE_NOTTO_WIN_BET_TYPE = 'cover';
export const RACING_BET_TYPE_NOTTO_WIN_BET_TYPE_TITLE = 'Not To Win';
export const RACING_BET_TYPE_TRAINERT_4_BET_TYPE = 'trainert4';
export const RACING_BET_TYPE_TRAINERT_4_BET_TYPE_TITLE = 'Trainer Top 4';
export const RACING_BET_TYPE_TRAINER_WIN_BET_TYPE = 'trainerwin';
export const RACING_BET_TYPE_TRAINER_WIN_BET_TYPE_TITLE = 'Trainer Win';
export const SRMTypes = {
	SRMWin: 'Win',
	SRMTop2: 'Top 2',
	SRMTop3: 'Top 3',
	SRMTop4: 'Top 4',
};
export const RACING_BET_TYPE_MARGIN = 'margin';
export const RACING_BET_TYPE_MARGIN_SHORT = 'M';

export const RACING_BET_TYPE_EACHWAY = 'eachway';
export const RACING_BET_TYPE_EACHWAY_SHORT = 'EW';

export const RACING_BET_TYPE_SP = 'OSP';
export const RACING_BET_TYPE_TF = 'TF';
export const RACING_BET_TYPE_WIN = 'win';
export const RACING_ODDS_GIDS = 'oddsgrid';
export const RACING_BET_TYPE_WIN_SHORT = 'W';
export const RACING_BET_TYPE_PLACE = 'place';
export const RACING_BET_TYPE_PLACE_SHORT = 'P';
export const RACING_BET_TYPE_QUINELLA = 'quinella';
export const RACING_BET_TYPE_EXACTA = 'exacta';
export const RACING_BET_TYPE_TRIFECTA = 'trifecta';
export const RACING_BET_TYPE_FIRST_FOUR = 'firstfour';
export const RACING_BET_TYPE_QUADDIE = 'quaddie';
export const RACING_BET_TYPE_DAILY_DOUBLE = 'dailydouble';
export const RACING_BET_TYPE_STRAIGHT_EIGHT = 'straighteight';
export const RACING_ISSURE_BET = 'insurebet';
export const RACING_BET_TYPE_NOTTO_WIN_BET = 'cover';
export const RACING_BET_TYPE_TRAINERT_4_BET = 'trainert4';
export const RACING_BET_TYPE_TRAINER_WIN_BET = 'trainerwin';
export const RACING_BET_TYPE_RUNNING_DOUBLE = 'runningdouble';
export const RACING_BET_TYPE_RUNNING_TREBLE = 'treble';
export const RACING_BET_TYPE_SHORT_LOOKUP = {
	[RACING_BET_TYPE_MARGIN]: RACING_BET_TYPE_MARGIN_SHORT,
	[RACING_BET_TYPE_EACHWAY]: RACING_BET_TYPE_EACHWAY_SHORT,
	[RACING_BET_TYPE_WIN]: RACING_BET_TYPE_WIN_SHORT,
	[RACING_BET_TYPE_PLACE]: RACING_BET_TYPE_PLACE_SHORT,
};

export const RACING_BET_PRODUCT_CODE_SP= 'OSP';
export const RACING_BET_PRODUCT_CODE_TF = 'TF';
export const RACING_BET_PRODUCT_CODE_FIXED = 'GRSF';

export const RACING_BET_TYPE_TITLES = {
	[RACING_BET_TYPE_WIN]: 'Win',
	[RACING_BET_TYPE_PLACE]: 'Place',
	[RACING_BET_TYPE_EACHWAY]: 'Each Way',
	[RACING_NON_EXOTIC_BET_TYPE]: RACING_NON_EXOTIC_BET_TYPE_TITLE,
	[RACING_SAME_RACE_MULTI_TYPE]: 'SRM',
	[RACING_BET_TYPE_QUINELLA]: 'Quinella',
	[RACING_BET_TYPE_EXACTA]: 'Exacta',
	[RACING_BET_TYPE_TRIFECTA]: 'Trifecta',
	[RACING_BET_TYPE_FIRST_FOUR]: 'First 4',
	[RACING_BET_TYPE_QUADDIE]: 'Quaddie',
	[RACING_BET_TYPE_DAILY_DOUBLE]: 'Daily Double',
	[RACING_BET_TYPE_STRAIGHT_EIGHT]: "Str8 '8'",
	[RACING_BET_TYPE_RUNNING_DOUBLE]: 'Running Double',
	[RACING_BET_TYPE_RUNNING_TREBLE]: 'Treble',
};

export const RACING_WIN_PLACE_BET_TYPES = [RACING_BET_TYPE_WIN, RACING_BET_TYPE_PLACE];
export const RACING_BASE_BET_TYPES = [...RACING_WIN_PLACE_BET_TYPES, RACING_BET_TYPE_MARGIN];

export const RACING_EXOTIC_BET_TYPES = [
	RACING_BET_TYPE_QUINELLA,
	RACING_BET_TYPE_EXACTA,
	RACING_BET_TYPE_TRIFECTA,
	RACING_BET_TYPE_FIRST_FOUR,
];
export const QUADDIES_BET_TYPES = [
	RACING_BET_TYPE_RUNNING_DOUBLE,
	RACING_BET_TYPE_DAILY_DOUBLE,
	RACING_BET_TYPE_RUNNING_TREBLE,
	RACING_BET_TYPE_QUADDIE,
];
export const RACING_BET_TYPES = [...RACING_BASE_BET_TYPES, ...RACING_EXOTIC_BET_TYPES];

export const QUADDIES_BET_TYPES_COUNT = {
	[RACING_BET_TYPE_QUADDIE]: 4,
	[RACING_BET_TYPE_DAILY_DOUBLE]: 2,
	[RACING_BET_TYPE_RUNNING_DOUBLE]: 2,
	[RACING_BET_TYPE_RUNNING_TREBLE]: 3,
};

export const RACING_EXOTIC_MINIMUM_SELECTIONS = {
	[RACING_BET_TYPE_QUADDIE]: 1,
	[RACING_BET_TYPE_DAILY_DOUBLE]: 1,
	[RACING_BET_TYPE_QUINELLA]: 2,
	[RACING_BET_TYPE_EXACTA]: 2,
	[RACING_BET_TYPE_TRIFECTA]: 3,
	[RACING_BET_TYPE_FIRST_FOUR]: 4,
	[RACING_BET_TYPE_STRAIGHT_EIGHT]: 8,
	[RACING_SAME_RACE_MULTI_TYPE]: 8,
};

export const RACING_EXOTIC_BET_TYPE_ORDER_LOOKUP = {
	[RACING_BET_TYPE_QUINELLA]: 1,
	[RACING_BET_TYPE_EXACTA]: 2,
	[RACING_BET_TYPE_TRIFECTA]: 3,
	[RACING_BET_TYPE_FIRST_FOUR]: 4,
	[RACING_BET_TYPE_QUADDIE]: 5,
	[RACING_BET_TYPE_DAILY_DOUBLE]: 6,
	[RACING_BET_TYPE_STRAIGHT_EIGHT]: 8,
};

export const DEFAULT_EXOTIC_CHECKBOX_STATE = {
	1: false,
	2: false,
	3: false,
	4: false,
	5: false,
	6: false,
	7: false,
	8: false,
};

export const RACING_EACHWAY_LABEL = 'Each Way';
export const RACING_BUMPED_LABEL = 'BUMPED';
export const RACING_MARKET_NAME = 'Racing';
export const RACING_FIXED_LABEL = 'Fixed';
export const RACING_FIXED_NAME = 'fixed';
export const RACING_TOTE_LABEL = 'Tote';
export const RACING_TOTE_NAME = 'tote';

export const RACING_NTJ_TO_GO_PAST = 'past';

export const EXOTIC_BOXED = 'boxed';
export const EXOTIC_STANDARD = 'standard';
export const EXOTIC_STANDOUT = 'standout';

// Derivative constants
export const DERIVATIVE_HEAD_TO_HEAD_CODE = 'H2H';
export const DERIVATIVE_HEAD_TO_HEAD_LABEL = 'Head to Head';
export const DERIVATIVE_FAV_VS_FIELD_CODE = 'FVF';
export const DERIVATIVE_FAV_VS_FIELD_LABEL = 'Favourite vs Field';
export const DERIVATIVE_HALF_VS_HALF_CODE = 'HVH';
export const DERIVATIVE_HALF_VS_HALF_LABEL = 'Half vs Half';
export const DERIVATIVE_ODDS_VS_EVEN_CODE = 'OVE';
export const DERIVATIVE_ODDS_VS_EVEN_LABEL = 'Odds vs Evens';
export const DERIVATIVE_INSIDE_VS_OUTSIDE_CODE = 'IO';
export const DERIVATIVE_INSIDE_VS_OUTSIDE_LABEL = 'Inside vs Outside';

// The derivative products below are yet to be confirmed
export const DERIVATIVE_3_WAY_MATCHUP_CODE = '3WM';
export const DERIVATIVE_3_WAY_MATCHUP_LABEL = '3 Way Matchup';
export const DERIVATIVE_LUCKY_ROUGHIES_CODE = 'LWR';
export const DERIVATIVE_LUCKY_ROUGHIES_LABEL = 'Lucky with Roughies';

export const DERIVATIVE_LABEL_LOOKUP = {
	[DERIVATIVE_FAV_VS_FIELD_CODE]: DERIVATIVE_FAV_VS_FIELD_LABEL,
	[DERIVATIVE_HALF_VS_HALF_CODE]: DERIVATIVE_HALF_VS_HALF_LABEL,
	[DERIVATIVE_ODDS_VS_EVEN_CODE]: DERIVATIVE_ODDS_VS_EVEN_LABEL,
	[DERIVATIVE_HEAD_TO_HEAD_CODE]: DERIVATIVE_HEAD_TO_HEAD_LABEL,
	[DERIVATIVE_3_WAY_MATCHUP_CODE]: DERIVATIVE_3_WAY_MATCHUP_LABEL,
	[DERIVATIVE_LUCKY_ROUGHIES_CODE]: DERIVATIVE_LUCKY_ROUGHIES_LABEL,
	[DERIVATIVE_INSIDE_VS_OUTSIDE_CODE]: DERIVATIVE_INSIDE_VS_OUTSIDE_LABEL,
};

export const PUSHER_EVENT_UPDATE = 'update';
export const PUSHER_EVENT_N2J_UPDATE = 'n2j_update';
export const PUSHER_EVENT_ODDS_UPDATE = 'odds_update';
export const PUSHER_EVENT_DERIVATIVE_ODDS_UPDATE = 'derivative_odds_update';
export const PUSHER_EVENT_DERIVATIVE_MARKET_UPDATE = 'derivative_market_update';
export const PUSHER_EVENT_HOT_SELECTION_UPDATE = 'hot_selection_update';
export const PUSHER_TOP_SELECTION_UPDATE = 'top_selections_update';
export const PUSHER_SELECTION_UPDATE = 'selection_update';
export const PUSHER_EVENT_UPCOMING_RACES_UPDATE = 'upcoming-races-update';
/**
 * new const for new grs_race_id channel
 *
 */
export const PUSHER_EVENT_UPDATE_NJ = 'grs_n2j_update';
export const PUSHER_GRS_EVENT_UPDATE = 'grs_race_update';
export const PUSHER_GRS_EVENT_ODDS_UPDATE = 'grs_odds_update';
export const PUSHER_GRS_EVENT_SELECTIONS_UPDATE = 'selection-socket-grs';
export const PUSHER_N2J_UPCOMING_EVENT_ODDS_UPDATE = 'race_status_update';
export const PUSHER_GRS_UPCOMING_EVENT_ODDS_UPDATE = 'favorite_selection_update';
export const PUSHER_GRS_EVENT_RACES_SELECTIONS_UPDATE = 'grs_selection_update';

export const APN_FLUCS = 'apn_fluctuations';
export const TB_FLUCS = 'topbetta_fluctuations';

export const TRACKING_CATEGORY_RACING_HOME_RACE_SELECTOR = 'RacingHomePageRaceSelector';
export const TRACKING_CATEGORY_RACE_CARD_RACE_SELECTOR = 'RacingRaceCardRaceSelector';

export const TRACKING_CATEGORY_RACING_HOME_SIDEBAR = 'RacingHomePageTodaySidebar';
export const TRACKING_CATEGORY_RACE_CARD_SIDEBAR = 'RacingRaceCardTodaySidebar';

export const TRACKING_CATEGORY_RACING_HOME_DATE_SELECTOR = 'RacingHomePageRaceDate';
export const TRACKING_CATEGORY_RACE_CARD_DATE_SELECTOR = 'RacingRaceCardRaceDate';

export const TRACKING_CATEGORY_RACE_CARD_SELECTOR = 'RacingRaceCardSelector';
export const TRACKING_CATEGORY_RACE_CARD_PRODUCT_SELECTOR = 'RacingRaceCardProductSelector';

export const TRACKING_CATEGORY_GRS_RACING_HOME_TYPE_SELECTOR = 'GRSRacingHomePageRaceType';
export const TRACKING_CATEGORY_GRS_RACE_CARD_TYPE_SELECTOR = 'GRSRaceCardRaceType';

export const TRACKING_CATEGORY_BET_BUILDER_HOME_TYPE_SELECTOR = 'betBuilderHomePageTypes';
export const TRACKING_CATEGORY_BET_BUILDER_CARD_TYPE_SELECTOR = 'betBuilderHomeType';

export const TRACKING_CATEGORY_WELCOME_HOME_TYPE_SELECTOR = 'welcomeHomePageTypes';
export const TRACKING_CATEGORY_WELCOME_CARD_TYPE_SELECTOR = 'welcomeHomeType';

export const TRACKING_CATEGORY_BET_BUILDER_COUNRY_CODE_SELECTOR = 'betBuilderHomePageCountryCodes';
export const TRACKING_CATEGORY_BET_BUILDER_CARD_COUNRY_CODE_SELECTOR = 'betBuilderHomePageCountryCode';

export const TRACKING_CATEGORY_RACE_MARKET_SELECTOR = 'RacingRaceCardMarkets';
export const TRACKING_CATEGORY_RACE_ALL_FORM = 'RacingRaceCardAllForm';
export const TRACKING_CATEGORY_RACE_RUNNER_FORM = 'RacingRaceCardRunnerForm';
export const TRACKING_CATEGORY_RACE_SPEEDMAP_SELECTOR = 'RacingRaceCardSpeedmapSelector';
export const TRACKING_CATEGORY_RACE_HOT_STATS_SELECTOR = 'RacingRaceCardHotStatsSelector';
export const TRACKING_CATEGORY_RACE_BET_BUILDER_SELECTOR = 'RacingRaceCardBetBuilderSelector';
export const TRACKING_CATEGORY_RACE_DERIVATIVE_SELECTOR = 'RacingRaceCardProductSelector';
export const TRACKING_CATEGORY_TOURNAMENT_RACE_MARKET_SELECTOR = 'TournamentRacingRaceCardMarkets';
export const TRACKING_CATEGORY_TOURNAMENT_RACE_ALL_FORM = 'TournamentRacingRaceCardAllForm';
export const TRACKING_CATEGORY_TOURNAMENT_RACE_RUNNER_FORM = 'TournamentRacingRaceCardRunnerForm';

export const TRACKING_CATEGORY_RACE_COMPUTER_TIPS_SELECTOR = 'RacingRaceCardComputerTipsSelector';

/**
 * SPORTS
 * @HW 28Jan2020
 */
export const TRACKING_CATEGORY_GRSSPORT_HOME_SPORT_SELECTOR = 'GRSSportHomePageSportSelector';
export const TRACKING_CATEGORY_SPORT_CARD_SPORT_SELECTOR = 'SportsCardSportSelector';

/**
 * AR Change Race Card Max Race Number.
 * This is the max race number rendering race card in this number over current race numbers then enabled the scroll bars.
 */

export const COMPACT_RACES_AT = 10;
