import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
// import { css } from 'react-emotion';
import { Ticker } from '@tbh/ui-kit';
/**
 * Components
 */
//import RaceUpcomingFilter from './RaceUpcomingFilter/RaceUpcomingFilter';
import { Card, CardHeader, CardTitle, CardBody } from '../../../UI/Card/Card';

import { filterFeatureRaces } from '../../../../store/entities/selectors/FeatureRaceSelectors';

import {
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_TYPES_LOOKUP,
} from '../../../../common/constants/Racing';

import PusherSubscriberNjUpcomingRaceChannel from '../PusherSubscribers/PusherSubscriberNjUpcomingRaceChannel';
import {
	StyledRaceUpcomingWrapper,
	StyledRaceUpcoming_carousel_body_content,
	StyledRaceUpcoming__Carousel,
	StyledRaceUpcoming__NtjRacesCarouselItem,
	StyleUpRaceCardContainer,
} from './RaceUpcoming.styled';

export const modeChangeOverSize = 751;

class RaceUpcoming extends React.Component {
	static propTypes = {
		/** Extra class(es) to pass through to the component */
		className: PropTypes.string,

		/** An array of the next to jump races */
		items: PropTypes.arrayOf(
			PropTypes.shape({
				/** The id of the race */
				id: PropTypes.number.isRequired,

				/** The id of the meeting the race is a part of */
				meeting_id: PropTypes.number.isRequired,

				/** The name of the meeting */
				meeting_name: PropTypes.string.isRequired,

				/** The type of race ie. Thoroughbred, Harness, Greyhounds */
				type: PropTypes.oneOf([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]).isRequired,

				/** The number of the race within the meeting */
				number: PropTypes.number.isRequired,

				/** The starting time of the race */
				start_datetime: PropTypes.string.isRequired,

				name: PropTypes.string.isRequired,

				state: PropTypes.string.isRequired,

				distance: PropTypes.string,

				country: PropTypes.string.isRequired,

				/** Next to jump race products object */
				products: PropTypes.array,
				/** Array of objects that contain all the selection details */
				selection: PropTypes.arrayOf(
					PropTypes.shape({
						/**
						 * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
						 * They are defined in the RaceSelectionsListItem
						 */
						id: PropTypes.number.isRequired,

						/** The list of prices for the selection */
						prices: PropTypes.arrayOf(
							PropTypes.shape({
								id: PropTypes.number, //@HW
								/** The current price for a win bet with this product */
								win_odds: PropTypes.number,

								/** The current price for a place bet */
								place_odds: PropTypes.number,

								/** The current price for a margin bet */
								margin_odds: PropTypes.number,

								/** The code for this product with this product */
								product_code: PropTypes.string.isRequired,

								/** The product id (NOTE: this is not a unique id between products) */
								product_id: PropTypes.number.isRequired,
							}),
						),
					}),
				),
			}),
		).isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Function to handle the click event on each next to jump item */
		onItemClick: PropTypes.func,

		/** Action to track when a filter is applied */
		trackOnFilterClick: PropTypes.func,

		/** Whether or not to hide the next to jump filter */
		hideRaceNextJumpFilter: PropTypes.bool,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** The currently selected bet type */
		betType: PropTypes.string, // @HW

		selectionCount: PropTypes.number,

		/** Navigate to the race page */
		onGoToRace: PropTypes.func,
	};

	static defaultProps = {
		className: null,
		onItemClick: () => undefined,
		trackOnFilterClick: () => undefined,
		hideRaceNextJumpFilter: false,
		betType: '', // @HW
		selectionCount: 0,
		onGoToRace: null,
	};

	state = {
		// initially set all the type filters active
		//typeFilter: new Set([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]),
		typeFilter: new Set([]),
	};

	/**
	 * The onClick on the Carousel item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *
	 * @param id
	 */
	handleClick = (id) => {
		let item = this.props.items.find((item) => item.id === id);
		this.props.onGoToRace(item.type, item.start_datetime, item.id, item.meeting_id);
	};
	/**
	 * Handles the clicking/selecting of the race type filters
	 * @param type
	 */
	handleOnFilterClick = (type) => {
		this.saveTypeToFilters(type);
		this.props.trackOnFilterClick(RACING_TYPES_LOOKUP[type]);
	};

	/**
	 * Saves the race type filters to state
	 * @param type
	 */
	saveTypeToFilters = (type) => {
		let types = new Set(this.state.typeFilter);

		if (!type) {
			return;
		}

		if (types.has(type)) {
			types.delete(type);
		} else {
			types.add(type);
		}

		this.setState({
			typeFilter: types,
		});
	};

	resetFilterClick = () => {
		this.setState({ typeFilter: new Set([]) });
	};
	/**
	 * Checks whether the startTime is around 5min from now and returns period to set Ticker interval.
	 *
	 * @param {string} startTime YYYY-11-29T16:32:42+11:00
	 * @return {number|null} - ticker period
	 */
	getTickerInterval = (startTime = null) => {
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}
		// null makes Ticker clear interval.
		return null;
	};
	render() {
		const { className, size, onGoToRace, hideRaceNextJumpFilter } = this.props;
		const items = filterFeatureRaces(this.props.items, Array.from(this.state.typeFilter));

		const componentClassesV3 = cx({
			[className]: className,
		});

		if (items.length === 0) {
			return false;
		}

		return (
			<React.Fragment>
				<Card className={componentClassesV3}>
					<CardHeader>
						<CardTitle>UPCOMING Races</CardTitle>
					</CardHeader>
					<CardBody>
						{/*items.map((upcomingRace) => (
							<PusherSubscriberNjUpcomingRaceChannel key={upcomingRace.id} raceId={upcomingRace.id} isWelcomePage={true}/>
						))*/}
						<StyledRaceUpcoming__Carousel size={size} nextButton prevButton freeMode buttonStyle="1">
							{items.map((upcomingRace) => (
								<StyledRaceUpcomingWrapper
									key={upcomingRace.id + upcomingRace.number + upcomingRace.meeting_name}
									size={size}
								>
									<Ticker every={this.getTickerInterval(upcomingRace.start_datetime)}>
										{/* upcoming race header */}
										<StyledRaceUpcoming__NtjRacesCarouselItem
											id={upcomingRace.id}
											raceNumber={upcomingRace.number}
											type={upcomingRace.type}
											meetingName={upcomingRace.meeting_name}
											startTime={upcomingRace.start_datetime}
											meetingState={upcomingRace.state}
											raceName={upcomingRace.name}
											raceDistance={upcomingRace.distance}
											start_datetime={upcomingRace.start_datetime}
											country={upcomingRace.country}
											action={this.handleClick}
											onGoToRace={onGoToRace}
											size={size}
										/>

										<StyledRaceUpcoming_carousel_body_content size={size}>
											<StyleUpRaceCardContainer
												id={upcomingRace.id}
												race={upcomingRace}
												raceId={upcomingRace.id}
												meetingId={upcomingRace.meeting_id}
												meetingName={upcomingRace.meeting_name}
												selections={
													Array.isArray(upcomingRace.favorite_selection)
														? upcomingRace.favorite_selection.slice(0, 3)
														: []
												}
												action={this.handleClick}
												size={size}
											/>
										</StyledRaceUpcoming_carousel_body_content>
									</Ticker>
								</StyledRaceUpcomingWrapper>
							))}
						</StyledRaceUpcoming__Carousel>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default RaceUpcoming;
