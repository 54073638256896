// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import styled, { css } from 'react-emotion';

// Components
import { spacings, RadioButton, Text, Panel } from '@tbh/ui-kit';

const StyledPromoAlert__RadioButton = styled(RadioButton)(
	(props) => css`
		label: PromoAlert__RadioButton;

		margin-right: ${spacings(props).cozy}px;
	`,
);

const StyledPromoAlert = styled(Panel)(
	(props) => css`
		label: PromoAlert;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const PromoAlert = (props) => {
	const { className, handleBonusSelection, promotion, selected_bonus, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<StyledPromoAlert
			className={componentClasses}
			borderType={Panel.borderTypes.DANGER}
			flex
			padding={Panel.padding.SPACING_COZY}
		>
			<StyledPromoAlert__RadioButton
				name="promotion"
				value="promotion"
				onChange={handleBonusSelection}
				checked={selected_bonus === 'promotion'}
			/>
			<div
				className={css`
					width: 100%;
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
				`}
			>
				<Text size="-1" transform="uppercase" strong>
					{t('PromoAlert__Title')}
				</Text>
				<Text size="-1">{parseInt(promotion.bonus) ? `+$${promotion.bonus} ${t('BonusBet')}` : promotion.bonus}</Text>
			</div>
		</StyledPromoAlert>
	);
};

PromoAlert.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action for changing the bonus bet selection */
	handleBonusSelection: PropTypes.func.isRequired,

	/** The bonus bet promotion details */
	promotion: PropTypes.shape({
		/** The amount needed for the bonus bet */
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

		/** The bonus amount included */
		bonus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

		/** The promo code for the bonus */
		promoCode: PropTypes.string,
	}).isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** The selected bonus bet promotion */
	selected_bonus: PropTypes.string,
};

PromoAlert.defaultProps = {
	className: '',
	selected_bonus: null,
};

export default withNamespaces()(PromoAlert);
