import { createSelector } from 'reselect';
import { closestNumberInList } from '../../common/ArrayHelpers';

import { ROOT_ELEMENT_ID } from './applicationConstants';

/**
 * Get the authenticatedUser
 *
 * @param state
 * @returns {*}
 */
export const getAuthenticatedUser = (state) => {
	return state.entities.users[state.application.authenticatedUser];
};

/**
 * Return the users roll table, for sports or racing
 *
 * @param user
 * @param isRacing
 * @returns {*|number}
 */
export const calculateUserRollsList = (user, isRacing = false) => {
	return isRacing ? user.racing_rolls : user.sports_rolls;
};

/**
 * Returns the default roll table, for sports or racing
 *
 * @param state
 * @param isRacing
 * @returns {*|number}
 */
export const calculateDefaultRollsList = (state, isRacing = false) => {
	return isRacing ? state.application.racing_rolls : state.application.sports_rolls;
};

/**
 * Returns the correct roll table depending on if the user is logged in or not, for sports or racing
 *
 * @param state
 * @param isRacing
 * @returns {*|number}
 */
export const calculateRollsList = (state, isRacing = false) => {
	const user = getAuthenticatedUser(state);
	return user ? calculateUserRollsList(user, isRacing) : calculateDefaultRollsList(state, isRacing);
};

/**
 * Thunk wrapper to get the number of rolls for the currently authenticated user
 *
 * @param isRacing
 */
export const getRollsList = (isRacing) => (dispatch, getState) => calculateRollsList(getState());

/**
 * Get the fixed price at a certain index
 *
 * @param rollsList
 * @param isRacing
 * @param index
 * @returns {*}
 */
export const getFixedPriceAtIndex = (rollsList, isRacing, index) => {
	return rollsList[index];
};

/**
 * Get the specific index from a certain price, or the closest floor price
 *
 * @param rollsList
 * @param isRacing
 * @param price
 * @returns {number}
 */
export const getIndexFromFixedPrice = (rollsList, isRacing, price) => {
	let index = rollsList.findIndex((fixedPrice) => fixedPrice.price === price);
	// If we can't find the corresponding price in the table, attempt the find the closest matching one
	if (index < 0) {
		const numericalRollList = rollsList.map((rollPrice) => rollPrice.price);
		const closestNumber = closestNumberInList(price, numericalRollList, true);
		index = getIndexFromFixedPrice(rollsList, isRacing, closestNumber);
	}


	return index;
};

/**
 * Return the fixed price from an index with rolls applied
 *
 * @param state
 * @param isRacing
 * @param startPrice
 * @returns {*}
 */
export const getFixedPriceRollup = (state, isRacing, startPrice) => {
	const parsedStartPrice = parseFloat(startPrice);

	if (isNaN(parsedStartPrice)) {
		throw new Error('Invalid fixed price provided.');
	}

	let endPrice = { price: parsedStartPrice };
	const rollsList = calculateRollsList(state, isRacing);
	// Check that there is a rolls list
	if (rollsList && rollsList.length) {
		// Ensure we have a valid price to roll from
		const startIndex = getIndexFromFixedPrice(rollsList, isRacing, parsedStartPrice);
		if (startIndex >= 0) {
			const startPriceRoll = getFixedPriceAtIndex(rollsList, isRacing, startIndex);
			// If there are rolls to perform
			if (startPriceRoll && startPriceRoll.rolls) {
				// Get the new end price based on the number of rolls
				endPrice = getFixedPriceAtIndex(rollsList, isRacing, startIndex + startPriceRoll.rolls);

				// If exceeded the fixed price limit, return the last fixed price
				if (!endPrice) {
					endPrice = getFixedPriceAtIndex(rollsList, isRacing, rollsList.length - 1);
				}
			}
		}
	}
	return endPrice.price;
};

export const getRollTablePrice = (state, isRacing, price) => {
	const parsedPrice = parseFloat(price);

	if (!isNaN(parsedPrice)) {
		const rollsList = calculateRollsList(state, isRacing);

		// Check that there is a rolls list
		if (rollsList && rollsList.length) {
			// Ensure we have a valid price to roll from
			const priceIndex = getIndexFromFixedPrice(rollsList, isRacing, price);
			if (priceIndex >= 0) {
				const fixedPrice = getFixedPriceAtIndex(rollsList, isRacing, priceIndex);
				if (fixedPrice) {
					return fixedPrice;
				}
			}
		}
	}

	return {
		price,
		rolls: 0,
	};
};

/**
 * Thunk wrapper for getting the fixed price rollup
 *
 * @param startPrice
 * @param isRacing
 */
export const fetchFixedPriceRollup = (isRacing, startPrice) => (dispatch, getState) => {
	return getFixedPriceRollup(getState(), isRacing, startPrice);
};

export const fetchRollTablePrice = (isRacing, price) => (dispatch, getState) => {
	return getRollTablePrice(getState(), isRacing, price);
};

/**
 * Return if the current page is a tournaments page
 * - Tournaments
 * - activeTournament
 */
export const isTournamentPage = createSelector(
	(state) => state.application.selectedPage,
	(selectedPage) => {
		return selectedPage.includes('Tournament');
	},
);

/**
 * Retrieves the root element
 *
 * @returns {HTMLElement}
 */
export const rootElementSelector = () => document.getElementById(ROOT_ELEMENT_ID);

/**
 * Add a single or multiple classes to an element
 *
 * @param el
 * @param classes
 */
export const addClass = (el, classes) => {
	if (Array.isArray(classes)) {
		el.classList.add(...classes);
	} else {
		el.classList.add(classes);
	}
};

/**
 * Remove a single or multiple classes from an element
 *
 * @param el
 * @param classes
 */
export const removeClass = (el, classes) => {
	if (Array.isArray(classes)) {
		el.classList.remove(...classes);
	} else {
		el.classList.remove(classes);
	}
};