// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';

// Components
import { Button, Text } from '@tbh/ui-kit';
import Receipt from '../../../Transactions/Receipt/Receipt';
import Balance from '../../../Transactions/Balance/Balance';
import { TITLE_BONUS_BET, TITLE_DEPOSIT } from '../../../../../common/constants/Application';

const StyledDepositConfirmation__Balance = styled(Balance)(
	(props) => css`
		label: DepositConfirmation__Balance;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledDepositConfirmation__Receipt = styled(Receipt)(
	(props) => css`
		label: DepositConfirmation__Receipt;

		margin: ${spacings(props).comfortable}px 0;
	`,
);

const DepositConfirmation = (props) => {
	const { t, balance, bonus, closePage, deposit, receipt_method, receipt_number, successText } = props;

	return (
		<div>
			<StyledDepositConfirmation__Balance
				type={deposit ? t('AvailableCash') : t('AvailableBonusBet')}
				balance={balance}
			/>

			<Text size="1" strong>
				{deposit ? t('DepositConfirmation__FirstSuccessMessage') : t('DepositConfirmation__NoBonusApplied')}
			</Text>
			<Text size="-1" paragraph>
				{t('DepositConfirmation__CreditBalance', { bonus: !deposit ? ' bonus' : '' })}
			</Text>

			<StyledDepositConfirmation__Receipt
				type={deposit ? TITLE_DEPOSIT : TITLE_BONUS_BET}
				method={receipt_method}
				number={receipt_number}
				methodTitle={t('Receipt__DepositMethod')}
			/>

			<Text align="center">{t('DepositConfirmation__CreditAccount')}</Text>
			<Text size="1" align="center" type="success" strong paragraph>
				{bonus}
			</Text>

			<Button type="secondary" action={closePage} bold block>
				{successText ? successText : t('StartPlaying')}
			</Button>
		</div>
	);
};

DepositConfirmation.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Available bonus bet/cash balance */
	balance: PropTypes.number.isRequired,

	/** Deposit receipt number */
	receipt_number: PropTypes.number.isRequired,

	/** Payment Method */
	receipt_method: PropTypes.string.isRequired,

	/** Bonus' text */
	bonus: PropTypes.string.isRequired,

	/** Action to close the page */
	closePage: PropTypes.func.isRequired,

	/**
	 * If there was a deposit or not
	 * This will change the confirmation messages
	 */
	deposit: PropTypes.bool,

	/** Text for the button */
	successText: PropTypes.string,
};

DepositConfirmation.defaultProps = {
	deposit: false,
	successText: null,
};

export default withNamespaces()(DepositConfirmation);
