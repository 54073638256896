import { centsAsDollars } from '../../../legacy/core/format';

/**
 *
 *
 * @param amount
 * @param bonus
 * @returns {string}
 */
export const formatBonusText = (amount, bonus) => {
	let bonusText = `${centsAsDollars(amount)} + ${
		parseInt(bonus.charAt(0)) && bonus.indexOf('%') === -1 ? '$' + bonus : bonus
	}`;

	if (bonus.indexOf('Bonus Bet') === -1 && bonus.indexOf('bonus bet') === -1) {
		bonusText = bonusText + ' Bonus Bets';
	}

	return bonusText;
};
