import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { brand, spacings, typography, ui, media } from '@tbh/ui-kit';

// Components
import { Circle, Image } from '@tbh/ui-kit';
import { Text } from '@tbh/ui-kit';

// Constants
import { GENERIC_SILK, RACING_TYPES_LOOKUP, RACING_GREYHOUNDS_TYPE } from '../../../../../../common/constants/Racing';
import { BET_TYPE_WIN } from '../../../../../../store/entities/constants/BetConstants';

// Functions
import { determineSpeedColour, getBetProduct } from '../../../../../../pages/Racing/RacingHome/racingSelectorsGRS';

const SILK_CIRCLE_BIG = '28px';

const StyledSettlingPositionRunner__Circle = styled(Circle)(
	(props) => css`
		label: SettlingPositionRunner__Circle;

		height: ${spacings(props).comfortable}px;
		width: ${spacings(props).comfortable}px;
		background-color: ${ui(props).color_2};
		border: 2px solid ${brand(props).color_1};
		transition: width 200ms, height 200ms ease-in-out;
		border-color: ${ui(props)[`${props.color}`]};

		${props.raceType === RACING_GREYHOUNDS_TYPE &&
			css`
				border-radius: 0;
			`};
	`,
);

const StyledSettlingPositionRunner__Number = styled(Text)(
	css`
		label: SettlingPositionRunner__Number;
	`,
);

const StyledSettlingPositionRunner = styled('div')(
	(props) => css`
		label: SettlingPositionRunner;

		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		right: calc(${props.position}*10% - 10%);

		${props.betProduct &&
			css`
				cursor: pointer;

				&:hover {
					z-index: 9999;

					${SettlingPositionRunner.StyledSettlingPositionRunner__Circle} {
						height: ${SILK_CIRCLE_BIG};
						width: ${SILK_CIRCLE_BIG};
						border-color: ${brand(props).color_1};
					}
				}
			`};
	`,
	media(
		(props) => css`
			${StyledSettlingPositionRunner__Number} {
				font-size: ${typography(props)['size_-2']};
			}

			${StyledSettlingPositionRunner__Circle} {
				height: ${SILK_CIRCLE_BIG};
				width: ${SILK_CIRCLE_BIG};
				border-width: 2px;
			}
			
			${props.betProduct &&
				css`
					&:hover {
						${StyledSettlingPositionRunner__Circle} {
							height: ${spacings(props).spacious}px;
							width: ${spacings(props).spacious}px;
						}
					}
				`};
		`,
		380,
	),
	media(
		(props) => css`
			flex-direction: row;

			${StyledSettlingPositionRunner__Circle} {
				margin-right: ${spacings(props).tight}px;
			}
		`,
		620,
	),
);

const SettlingPositionRunner = (props) => {
	const { className, selection, backingPosition, handleClickSelection, isBettingAvailable, raceProducts, size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const betProduct = getBetProduct(raceProducts, selection.prices, BET_TYPE_WIN, isBettingAvailable);

	/** Handle when a selection is clicked on */
	const onClickItem = () => {
		handleClickSelection(selection.id, betProduct.product_id, BET_TYPE_WIN);
	};

	const raceType = RACING_TYPES_LOOKUP[selection.type_code];

	return (
		<StyledSettlingPositionRunner
			className={componentClasses}
			onClick={betProduct ? onClickItem : null}
			betProduct={betProduct}
			position={selection.position}
			size={size}
		>
			<StyledSettlingPositionRunner__Circle
				color={determineSpeedColour(selection.position, backingPosition)}
				innerClassName={css`
					display: flex;
				`}
				raceType={raceType}
				size={24}
			>
				<Image
					src={selection.silk}
					substituteImageSrc={GENERIC_SILK}
					alt={`${selection.name} racing silk`}
					height={size.width >= 380 ? 20 : 18}
				/>
			</StyledSettlingPositionRunner__Circle>
			<StyledSettlingPositionRunner__Number size="-3" strong>
				#{selection.number}
			</StyledSettlingPositionRunner__Number>
		</StyledSettlingPositionRunner>
	);
};

const selectionShape = PropTypes.shape({
	/** Runner id */
	id: PropTypes.number.isRequired,
	/** Runner number */
	number: PropTypes.number,
	/** Runner settling speed positions */
	settling_speed_positions: PropTypes.arrayOf(PropTypes.string),
	/** Runner silk */
	silk: PropTypes.string,
	/** Runner settling row */
	row: PropTypes.string,
	/** Runner settling speed position */
	position: PropTypes.string,
});

SettlingPositionRunner.propTypes = {
	/** Handle when a selection is clicked on */
	handleClickSelection: PropTypes.func.isRequired,

	/** The farthest back position in the race */
	backingPosition: PropTypes.number.isRequired,

	/** Current selection */
	selection: selectionShape.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Whether or not betting is allowed on the race */
	isBettingAvailable: PropTypes.bool,

	/** List of betting products on the race */
	raceProducts: PropTypes.arrayOf(
		PropTypes.shape({
			available: PropTypes.bool.isRequired,
			bet_type: PropTypes.string.isRequired,
			fixed: PropTypes.bool.isRequired,
			product_id: PropTypes.number.isRequired,
		}),
	),

	/** Extra classes */
	className: PropTypes.string,
};

SettlingPositionRunner.defaultProps = {
	className: '',
	raceProducts: [],
	isBettingAvailable: true,
};

SettlingPositionRunner.StyledSettlingPositionRunner__Circle = StyledSettlingPositionRunner__Circle;

export default SettlingPositionRunner;
