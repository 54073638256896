export const SET_DEFAULT_ROLLS = 'SET_DEFAULT_ROLLS';
export const SET_RUNNING_SUB_APP = 'SET_RUNNING_SUB_APP';
export const STOP_RUNNING_SUB_APP = 'STOP_RUNNING_SUB_APP';

// Authenticated User
export const CLEAR_AUTHENTICATED_USER = 'CLEAR_AUTHENTICATED_USER';
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

export const APPLICATION_DEEP_LINK_COMPLETE = 'APPLICATION_DEEP_LINK_COMPLETE';

// Masthead
export const HIDE_MASTHEAD_FIELDS = 'HIDE_MASTHEAD_FIELDS';
export const SET_MASTHEAD_IFRAME_HEIGHT = 'SET_MASTHEAD_IFRAME_HEIGHT';
export const SET_MASTHEAD_HEIGHT = 'SET_MASTHEAD_HEIGHT';

// Main Navigation
export const SHOW_NAVIGATION = 'SHOW_NAVIGATION';
export const HIDE_NAVIGATION = 'HIDE_NAVIGATION';

// Sidebar
export const UPDATE_SIDEBAR_NTJ_SETTINGS = 'UPDATE_SIDEBAR_NTJ_SETTINGS'; // Next to Jump
export const UPDATE_SIDEBAR_NTJ_DATA = 'UPDATE_SIDEBAR_NTJ_DATA';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

// Locked Screen
export const SHOW_LOCKED_SCREEN = 'SHOW_LOCKED_SCREEN';
export const HIDE_LOCKED_SCREEN = 'HIDE_LOCKED_SCREEN';

export const SET_IS_PHONE_DEVICE = 'SET_IS_PHONE_DEVICE';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const CLOSE_MULTI_BET_SLIP = 'CLOSE_MULTI_BET_SLIP';
export const OPEN_MULTI_BET_SLIP = 'OPEN_MULTI_BET_SLIP';
export const CLOSE_SIDE_BET_SLIP = 'CLOSE_SIDE_BET_SLIP';
export const OPEN_SIDE_BET_SLIP = 'OPEN_SIDE_BET_SLIP';
export const SET_VISIBLE_SPORTS_IDS = 'SET_VISIBLE_SPORTS_IDS';
export const SET_SPORT_WITH_SPORT_ID = 'SET_SPORT_WITH_SPORT_ID';
export const SET_VISIBLE_SPORTS_WITH_NAME = 'SET_VISIBLE_SPORTS_WITH_NAME';
export const UPDATE_INTERCOM_BOOTED = 'UPDATE_INTERCOM_BOOTED';
export const SET_BODY_REGION_LOADING = 'SET_BODY_REGION_LOADING';
export const SET_APPLICATION_LOADING = 'SET_APPLICATION_LOADING';
export const SET_VERSION_LOADED = 'SET_VERSION_LOADED';
export const SET_VERSION_INFORMATION = 'SET_VERSION_INFORMATION';
export const APPLICATION_SET_SELECTED_PAGE = 'APPLICATION_SET_SELECTED_PAGE';
export const SET_SCROLLBAR_WIDTH = 'SET_SCROLLBAR_WIDTH';
