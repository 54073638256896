import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, } from '@tbh/ui-kit';

const StyledNjCarouselSlide = styled('div')(
	(props) => css`
		label: NjCarouselSlide;

		&:hover {
			background: rgba(0, 0, 0, 0.05);
		}

		flex: 0 0 100%;
		max-width: 100%;

		${props.autoWidth &&
			css`
				width: auto !important;
			`};
	`,

	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			flex: 0 0 50%;
            max-width: 50%;
			`,
			556,
	),
	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			flex: 0 0 50%;
            max-width: 50%;
			`,
			673,
	),
    media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			flex: 0 0 50%;
            max-width: 50%;
			`,
			680,
	),
	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			flex: 0 0 50%;
            max-width: 50%;
			`,
			721,
	),
	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			flex: 0 0 33.33%;
            max-width: 33.33%;
			`,
			770,
	),
	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			flex: 0 0 33.33%;
            max-width: 33.33%;
			`,
			936,
	),
	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			flex: 0 0 25%;
            max-width: 25%;
			`,
			1024,
	),
	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			-ms-flex: 0 0 20%;
			flex: 0 0 20%;
			max-width: 20%;
			`,
			1440,
	),
	media(
		(props) =>
			css`
			-webkit-box-flex: 0;
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
			`,
			1680,
	)
);
const StyledNjCarouselSlideSpan = StyledNjCarouselSlide.withComponent('span');

class NjCarouselSlide extends React.Component {
	static propTypes = {
		/** Children for the carousel item */
		children: PropTypes.node.isRequired,

		/** If the carousel items shouldn't be links */
		noLinkItems: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,

		/** ID of the carousel item */
		itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

		/** Action to fire when the item is clicked on */
		onClick: PropTypes.func,

		/** If the current item is the active icon */
		active: PropTypes.bool,

		/** Whether to set width: auto */
        autoWidth: PropTypes.bool,
        	/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,
	};

	static defaultProps = {
		noLinkItems: false,
		className: '',
		onClick: function() {},
		active: false,
		autoWidth: false,
	};

	constructor() {
		super();
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Handle click on tab
	 */
	handleClick() {
		this.props.onClick(this.props.itemId);
	}

	render() {
		const { children, className, autoWidth, noLinkItems, size } = this.props;

		const classes = cx('swiper-slide', 'carousel-slide', {
			[className]: className,
		});

		if (noLinkItems) {
			return (
				<StyledNjCarouselSlide size={size} autoWidth={autoWidth} className={classes}>
					{children}
				</StyledNjCarouselSlide>
			);
		}

		return (
			<StyledNjCarouselSlideSpan size={size} autoWidth={autoWidth} className={classes} onClick={this.handleClick}>
				{children}
			</StyledNjCarouselSlideSpan>
		);
	}
}

NjCarouselSlide.StyledNjCarouselSlide = StyledNjCarouselSlide;
NjCarouselSlide.StyledNjCarouselSlideSpan = StyledNjCarouselSlideSpan;

export default NjCarouselSlide;
