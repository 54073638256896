
import styled, { css } from 'react-emotion';
import { spacings, ui,media } from '@tbh/ui-kit';

import CompetitionSelectorContainer from '../../../containers/Sports/CompetitionSelectorContainer';
import GRSNextToJumpSportsContainer from '../../../containers/Sports/NextToJumpSportsContainer/GRSNextToJumpSportsContainer';

export const StyledNextJumpSportHomePage = styled('div')`
	${(props) =>
		css`
			label: NextJumpSportHomePage;
			margin: ${spacings(props).compact}px;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
		`};
`;

export const StyledNextJumpSportHomePage__Content = styled('div')`
	label: NextJumpSportHomePage__Content;
	${media(
  css`
			display: block;
			margin-top:25px;
			margin-bottom:25px;
		`,
  767,
)};
`;
export const StyledNextJumpSportHomePage__Card__Body = styled('div')`
	${(props) =>
  css`
			label: NextJumpSportHomePage__Card_Body;
			background-color: #fff;
		`};
`;

export const StyledNextJumpSportHomePage_Header_section = styled('div')`
	${(props) =>
		css`
			label: NextJumpSportHomePage_Header_section;
			margin: ${spacings(props).compact}px;
			position: relative;
			z-index: 0; // This stops the loading mask from overlapping the header bar
		`};
`;

export const StyledNextJumpSportHomePage_maijor_header = styled('div')`
	${(props) =>
		css`
			label: NextJumpSportHomePage_maijor_header;
			
			border-bottom: 3px solid #c72128;
			clear: both;
			display: flex;
			justify-content: space-between;
		`};
`;

export const StyledNextJumpSportHomePage_Major_header_h2 = styled('h2')`
	${(props) =>
		css`
			label: NextJumpSportHomePage_Major_header_h2;
			

			text-transform: uppercase;
			font-weight: bold;
			background: #c72128;
			border-color: #c72128;
			color: white;
			text-transform: uppercase;
			display: inline-block;
			margin: 0;
			padding: 10px;
			height: auto;
			position: relative;
			font-size: 16px;
			letter-spacing: 0.32px;
			text-align: center;
		`};
`;


export const StyledSportNextJumpHomePage__Filters_section = styled('div')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__Filters_section;

			padding: ${spacings(props).compact}px;
			width:100%;
			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;
			
		`};

	${media(
		(props) =>
			css`
				display: flex;
				padding: ${spacings(props).compact}px;
			`,
		500,
	)};
`;

export const StyledSportNextJumpHomePage__Filters_ul = styled('ul')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__Filters_ul;

			width:100%;
			margin: 5px 0 0;
			padding: 0;
			list-style: none;
			overflow: hidden;
			overflow-x: auto;
			white-space: nowrap;
			
		`};
`;

export const StyledSportNextJumpHomePage__Filters_li = styled('li')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__Filters_li;

			padding: 0 10px;
			display: inline-block;
			border-right: 1px solid #000;
			box-shadow: 1px 0 0 #2b2b2b;
			font-size: 12px;
			letter-spacing: 0.48px;
			text-align: center;
			cursor: pointer;
			line-height: 1;
			position: relative;
			width: 95px;
			&:hover {
				//background: ${ui(props).color_3};
				cursor: pointer;
				background-color: #6c757d;
				color: #ffff;
			};

			&:active {
				//background: ${ui(props).color_3};
				cursor: pointer;
				background-color: #6c757d;
				color: #ffff;
			}
			
		`};
`;

export const StyledSportNextJumpHomePage__sportImg = styled('div')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__sportImg;

			width: 28px;
			height: 28px;
			fill: #717171;
			display: inline-block;
			margin: 3px auto 6px auto;
			
			
		`};
`;
export const StyledSportNextJumpHomePage__sportname = styled('span')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__sportname;

			display: block;
			line-height: 1.4;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			
		`};
`;


export const StyledSportNextJumpHomePage__container = styled('div')`
	${(props) =>
		css`
			label: SportNextJumpHomePage__container;

			padding: ${spacings(props).compact}px;
			width:100%;
			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;
			
		`};

	${media(
		(props) =>
			css`
				display: flex;
				padding: ${spacings(props).compact}px;
			`,
		500,
	)};
`;

export const StyledSportsNextJumpHomePage__NTJSports_Container = styled(GRSNextToJumpSportsContainer)(
	(props) => css`
		label: NextJumpSportsHomePage__Containers;

		width: 100%;
	`,
);

export const StyledNextJumpSportsHomePage__CompetitionSelectorContainer = StyledSportsNextJumpHomePage__NTJSports_Container.withComponent(
	CompetitionSelectorContainer,
);