import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';

// Components
import { Text } from '@tbh/ui-kit';

// Context
import AclContext from '../../../contexts/AclContext';

const BrandContactPhone = (props) => {
	const { className, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<AclContext.Consumer>
			{(acl) =>
				acl.brandDetails && acl.brandDetails.phone ? (
					<Text className={componentClasses} size="-3" type="alternate">
						{/* <Text size="-3" type="alternate">
							{t('LIVECHAT')}
						</Text> */}
						{/* <Link href={`tel:${acl.brandDetails.phone}`} type="alternate">
							{acl.brandDetails.phone}
						</Link> */}
						{/* <Link href={ROCKET_CHAT_LINK} target="_blank"  type="alternate">
							{acl.brandDetails.phone}
						</Link> */}
						
					</Text>
				) : null
			}
		</AclContext.Consumer>
	);
};

BrandContactPhone.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

BrandContactPhone.defaultProps = {
	className: '',
};

export default withNamespaces()(BrandContactPhone);
