import React from 'react';
import MobileDetect from 'mobile-detect';
import { extend } from 'lodash-es';
import URI from 'urijs';
import moment from 'moment';
import numeral from 'numeral';

// Constants
import {
	SET_DEFAULT_ROLLS,
	STOP_RUNNING_SUB_APP,
	HIDE_MASTHEAD_FIELDS,
	SHOW_NAVIGATION,
	HIDE_NAVIGATION,
	UPDATE_SIDEBAR_NTJ_SETTINGS,
	UPDATE_SIDEBAR_NTJ_DATA,
	SET_IS_PHONE_DEVICE,
	SET_CURRENT_ROUTE,
	OPEN_MULTI_BET_SLIP,
	CLOSE_MULTI_BET_SLIP,
	SET_BODY_REGION_LOADING,
	SET_APPLICATION_LOADING,
	UPDATE_INTERCOM_BOOTED,
	SET_VERSION_LOADED,
	SET_VERSION_INFORMATION,
	SET_MASTHEAD_IFRAME_HEIGHT,
	SET_MASTHEAD_HEIGHT,
	SET_SCROLLBAR_WIDTH,
	APPLICATION_SET_SELECTED_PAGE,
	SHOW_SIDEBAR,
	HIDE_SIDEBAR,
	OPEN_SIDE_BET_SLIP,
	CLOSE_SIDE_BET_SLIP,
} from './applicationActionTypes';
import { batchActions } from 'redux-batched-actions';
import { SCROLL_TOP_ID, MAIN_CONTENT, DEFAULT_LANGUAGE, ALLOWED_LANGUAGES } from './applicationConstants';
import { createAction } from '../../common/actions/actionHelpers';
import { get, mergeAxiosDefaultHeaders } from '../../common/Ajax';
import env from '../../common/EnvironmentVariables';
import { getMobileOrDesktop, getOSName } from '../../legacy/shims/device-details';
import { MOBILE_MAX_WIDTH } from '../../common/constants/Breakpoints';
import { addClass, removeClass, getAuthenticatedUser } from './applicationSelectors';
import { addTrackingPixel } from '../trackingPixels/trackingPixelActions';
import { toast } from 'react-toastify';

// import compareVersions from 'compare-versions';
const compareVersions = require('compare-versions');

const md = new MobileDetect(window.navigator.userAgent);

// Containers and Components
import { Icon, Notification } from '@tbh/ui-kit';

// Functions
import { centsAsDollars } from '../../legacy/core/format';
import { fetchVersions } from '../entities/actions/VersionActions';
import { denormalizeVersions } from '../entities/schemas/VersionSchema';
import { postPrefixedMessage, sendWidgetLoginMessage } from '../../common/actions/widgetActions';
import { normalizeAndMergeMeta } from '../entities/actions/MetaActions';
import { closeAllModals, openModal, closeModal } from '../modal/modalActions';

export const setApplicationPage = (pageId, options) => (dispatch) => {
	return dispatch(
		batchActions([
			showPage(pageId, options),
			// TODO: Set active navigation item
		]),
	);
};

/**
 * Set the selected page in redux
 *
 * @param pageId
 * @param options
 * @returns {Object}
 */
export const showPage = (pageId, options) => {
	return createAction(APPLICATION_SET_SELECTED_PAGE, pageId);
};

/**
 * Action creator for stopping a sub app
 *
 * @param registeredName
 * @param regionName
 * @returns {{type: *, registeredName: *}}
 */
export const stopSubApp = (registeredName, regionName) => {
	return {
		type: STOP_RUNNING_SUB_APP,
		regionName,
		registeredName,
	};
};

/**
 * Navigate to page. Doesn't include meta.
 *
 * @param route
 * @param options
 */
export const routeTo = (route, options = { trigger: true }) => (dispatch) => {
	return dispatch(navigate(route, options));
};

/**
 * Function to construct the ios-app link
 */
export const getIosAppLink = () => {
	let iosApp = env('iosApp');

	let iosAppHref = 'ios-app://';

	if (iosApp && iosApp.appStoreId && iosApp.appName) {
		iosAppHref += iosApp.appStoreId;
		iosAppHref += '/';
		iosAppHref += iosApp.appName.toLowerCase();
	}

	if (typeof Backbone !== 'undefined') {
		iosAppHref += Backbone.history.fragment;
	}

	return iosAppHref;
};

/**
 * Navigate to a new route
 *
 * @param route
 * @param opts
 * @param meta
 */
export const navigate = (route, opts = {}, meta = []) => (dispatch) => {
	const config = { ...opts };

	Backbone.history.navigate(route, config);

	postPrefixedMessage(`route:${route}`);

	// Add tracking pixel
	dispatch(
		addTrackingPixel('doubleclickpagetransition', {
			height: '1',
			width: '1',
			alt: '',
			src:
				'https://8055759.fls.doubleclick.net/activityi;src=8055759;type=retarget;cat=reta;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=1?_i=' +
				Math.floor(Math.random() * 99999999999),
		}),
	);

	// Add the default meta data for iOs and Android.
	if (meta) {
		dispatch(normalizeAndMergeMeta(meta));
	}

	// if (!config.trigger) {
	// 	App.pageView((route[0] === '/' ? '' : '/') + route);
	// }

	return meta;
};

/**
 * Action for closing the Modal
 */
export const closePrompt = (modalId) => (dispatch) => dispatch(closeModal(modalId));

/**
 * Action for hiding/showing the banners and Masthead buttons and inputs during registration
 */
export const hideMastheadFields = (bool) => (dispatch) => {
	if (bool) {
		dispatch(hideNavigation());
		dispatch(toggleMultiBetSlip(true));
	} else {
		dispatch(showNavigation());
	}

	return dispatch(createAction(HIDE_MASTHEAD_FIELDS, bool));
};

export const fetchDefaultRolls = () => (dispatch) => {
	return get('rolls').then((response) => {
		const rolls = response.data.data;
		const sortedSportsRolls = rolls.sports_rolls.sort((a, b) => a.price - b.price);
		const sortedRacingRolls = rolls.racing_rolls.sort((a, b) => a.price - b.price);
		const sortedRolls = {
			sports_rolls: sortedSportsRolls,
			racing_rolls: sortedRacingRolls,
		};

		dispatch(createAction(SET_DEFAULT_ROLLS, sortedRolls));
	});
};

export const scrollToTop = () => {
	postPrefixedMessage('scrollToTop');

	const scrollOptions = { block: 'end', inline: 'nearest', behavior: 'smooth' };

	// Scroll to the scrollable element if it exists
	const scrollEl = document.getElementById(SCROLL_TOP_ID);
	if (scrollEl) {
		scrollEl.scrollIntoView(scrollOptions);
	} else if (SCROLL_TOP_ID !== MAIN_CONTENT) {
		// Otherwise scroll to the main element if it exists and isn't the same as the scrollable element
		const mainEl = document.getElementById(MAIN_CONTENT);
		if (mainEl) {
			mainEl.scrollIntoView({ ...scrollOptions, block: 'start' });
		}
	}
};

export const showNavigation = () => {
	return {
		type: SHOW_NAVIGATION,
	};
};

export const hideNavigation = () => {
	return {
		type: HIDE_NAVIGATION,
	};
};

/**
 * Show the Sidebar
 *
 * @returns {{type: string}}
 */
export const showSidebar = () => {
	return {
		type: SHOW_SIDEBAR,
	};
};

/**
 * Hide the Sidebar
 *
 * @returns {{type: string}}
 */
export const hideSidebar = () => {
	return {
		type: HIDE_SIDEBAR,
	};
};

/**
 * Saves in redux the height of the iframe in the Masthead
 *
 * @param height
 * @returns {{type: string, payload: *}}
 */
export const setMastheadIframeHeight = (height) => {
	return {
		type: SET_MASTHEAD_IFRAME_HEIGHT,
		payload: height,
	};
};

/**
 * Saves in global state the height of the Masthead so we can calculate the height of the Top Banner Region
 *
 * @param height
 * @returns {{type: *, payload: *}}
 */
export const setMastheadHeight = (height) => {
	return {
		type: SET_MASTHEAD_HEIGHT,
		payload: height,
	};
};

export const setScrollbarWidth = (width) => {
	return {
		type: SET_SCROLLBAR_WIDTH,
		payload: width,
	};
};

export const openQuickBettingSummary = () => (dispatch) => {
	return dispatch(
		openModal({
			id: 'QuickBettingSummaryContainer',
			Component: 'QuickBettingSummaryContainer',
			config: {
				wide: true,
			},
		}),
	);
};

export const openIframe = (props = null, modalConfig = {}) => (dispatch) => {
	return dispatch(
		openModal({
			id: 'Iframe',
			Component: 'Iframe',
			config: modalConfig,
			props,
		}),
	);
};

/**
 * Action for changing the next to jump in the sidebar settings
 *
 * @param nextToJump
 * @returns {{type, payload: *}}
 */
export const changeSidebarSettingsNTJ = (nextToJump) => {
	return {
		type: UPDATE_SIDEBAR_NTJ_SETTINGS,
		payload: nextToJump,
	};
};

/**
 * Change the sidebar state to signal the mounted Sidebar to fetch the NTJ data.
 * This is used to update the ntj data every time the Sidebar is opened.
 *
 * @param bool
 * @returns {{type, payload: *}}
 */
export const updateSidebarNtjData = (bool) => {
	return {
		type: UPDATE_SIDEBAR_NTJ_DATA,
		payload: bool,
	};
};

/**
 * Sets the current route into state
 *
 * @returns {*}
 */
export const setCurrentRoute = (currentRoute = undefined) => {
	return createAction(SET_CURRENT_ROUTE, currentRoute);
};

/**
 * Checks if the app is being loaded by a phone device and then set that in state
 * FYI: .mobile() will bring back all mobile devices including tablets
 *  .phone() will return 'phone sized' mobile devices which is what we are after
 *
 * NOTE: This could possibly be refactored to use media queries if it is only based on width OR the useragent library??
 */
export const checkAndSetPhoneDevice = () => {
	// Does check using mobile-detect library, returns the detected phone type/family string or null
	const isPhoneDevice = new MobileDetect(window.navigator.userAgent).phone();

	return setIsPhoneDevice(!!isPhoneDevice);
};

/**
 * Sets the state of the isPhoneDevice flag
 *
 * @param isPhoneDevice
 * @returns {Object}
 */
export const setIsPhoneDevice = (isPhoneDevice = false) => {
	return createAction(SET_IS_PHONE_DEVICE, isPhoneDevice);
};

/**
 * Show the Account Summary for mobile
 */
export const showMobileAccountSummary = () => (dispatch) => {
	return dispatch(
		openModal({
			id: 'MobileAccountSummaryContainer',
			Component: 'MobileAccountSummaryContainer',
			config: {
				wide: true,
			},
		}),
	);
};

export const openMultiBetSlip = () => {
	return {
		type: OPEN_MULTI_BET_SLIP,
	};
};

export const closeMultiBetSlip = () => {
	return {
		type: CLOSE_MULTI_BET_SLIP,
	};
};

export const openSideBetSlip = () => {
	return {
		type: OPEN_SIDE_BET_SLIP,
	};
};

export const closeSideBetSlip = () => {
	return {
		type: CLOSE_SIDE_BET_SLIP,
	};
};

/**
 * Toggles whether or not the body region is currently loading content
 *
 * @param isLoading
 * @returns {Object}
 */
export const setBodyLoading = (isLoading = false) => createAction(SET_BODY_REGION_LOADING, isLoading);

/**
 * Toggles whether or not the application is currently loading
 *
 * @param isLoading
 * @returns {Object}
 */
export const setApplicationLoading = (isLoading = false) => createAction(SET_APPLICATION_LOADING, isLoading);

/**
 * Toggles showing or hiding the bet slip region
 */
export const toggleMultiBetSlip = (isMultiBetSlipOpen) => (dispatch) => {
	const betSlipBodyClasses = ['bet-slip-open', 'tb_bet-slip-open'];
	// Sets the open status to the opposite of what it currently is and update the application state
	if (isMultiBetSlipOpen) {
		removeClass(document.body, betSlipBodyClasses);
		dispatch(closeMultiBetSlip());
	} else {
		addClass(document.body, betSlipBodyClasses);
		dispatch(openMultiBetSlip());
		dispatch(sendWidgetLoginMessage());
	}

	return !isMultiBetSlipOpen;
};

/**
 * Toggles showing or hiding the side bet slip region
 */
export const toggleSideBetSlip = (isSideBetSlipOpen) => (dispatch) => {
	const betSlipBodyClasses = ['bet-slip-open', 'tb_bet-slip-open'];
	// Sets the open status to the opposite of what it currently is and update the application state
	if (isSideBetSlipOpen) {
		removeClass(document.body, betSlipBodyClasses);
		dispatch(closeSideBetSlip());
	} else {
		addClass(document.body, betSlipBodyClasses);
		dispatch(openSideBetSlip());
		dispatch(sendWidgetLoginMessage());
	}

	return !isSideBetSlipOpen;
};

/**
 * Show the 404 page
 */
export const show404 = () => (dispatch) => {
	dispatch(
		batchActions([
			showPage('Error404'),
			normalizeAndMergeMeta([
				{
					name: 'robots',
					content: 'nofollow',
				},
			]),
		]),
	);
};

/**
 * Setup the Intercom
 */
export const setupIntercom = (...args) => {
	// This needs to be attached to the window for extra settings to work
	window.intercomSettings = {
		app_id: env('intercomAppId'),
		custom_launcher_selector: '.tb_intercom-icon',
		hide_default_launcher: window.innerWidth < MOBILE_MAX_WIDTH,
		alignment: 'right', // This is the default but was added to stop Windows Edge from flashing the icon on the left during page load
	};

	let d = document;
	let w = window;
	let ic = w.Intercom;

	function l() {
		let s = d.createElement('script');
		s.type = 'text/javascript';
		s.async = true;
		s.src = 'https://widget.intercom.io/widget/' + w.intercomSettings.app_id;
		let x = d.getElementsByTagName('script')[0];
		x.parentNode.insertBefore(s, x);
	}

	if (typeof ic === 'function') {
		ic('reattach_activator');
		ic('update', w.intercomSettings);
	} else {
		let i = function() {
			i.c(arguments); // eslint-disable-line
		};
		i.q = [];
		i.c = function(args) {
			i.q.push(args);
		};

		w.Intercom = i;

		// Check if the document has already finished loading otherwise attach an event listener
		if (document.readyState === 'complete') {
			l();
		} else {
			if (w.attachEvent) {
				w.attachEvent('onload', l);
			} else {
				w.addEventListener('load', l, false);
			}
		}
	}
};

/**
 *
 * @param method
 * @param x
 * @param y
 */
export const getIntercom = (method, x, y) => (dispatch, getState) => {
	const intercom = window.Intercom;
	if (!intercom) {
		console.warn('Intercom does not exist');
		return;
	}

	const isCordova = getState().application.isCordova;

	if (isCordova) {
		switch (method) {
			case 'update':
				if (x.user_id) {
					// We're logged in, we can register the user with Intercom
					intercom.registerIdentifiedUser(extend(x, { userId: x.user_id }));
					intercom.updateUser(extend(x, { userId: x.user_id }));
				} else {
					intercom.registerUnidentifiedUser();
				}

				break;
			case 'boot': // Ignore web boot
			case 'shutdown': // Ignore web boot
				break;

			case 'trackEvent':
				intercom.logEvent(x, y);
				break;

			default:
				alert('INTERCOM: ' + method);
		}
	} else {
		return intercom(method, x, y);
	}
};

/**
 * Hide the Intercom button if it exists
 */
export const hideIntercom = () => {
	const intercomContainer = document.getElementById('intercom-container');
	if (intercomContainer) {
		intercomContainer.style.display = 'none';
	}
};

/**
 * Show the Intercom button if it exists
 */
export const showIntercom = () => {
	const intercomContainer = document.getElementById('intercom-container');
	if (intercomContainer) {
		intercomContainer.style.display = 'block';
	}
};

/**
 * Update intercom with the latest user information. Accept overrides
 *
 * @param overrides
 */
export const updateIntercom = (overrides = {}) => (dispatch) => {
	const config = dispatch(buildLiverUserConfig());
	if (!window.Intercom) {
		console.warn('Intercom not available');
		return;
	}
	dispatch(getIntercom('update', extend(config, overrides)));
};

/**
 * Boot intercom with the current user state
 *
 * @param overrides
 */
export const bootIntercom = (overrides = {}) => (dispatch, getState) => {
	const config = dispatch(buildLiverUserConfig());

	if (!window.Intercom) {
		console.warn('Intercom not available');
		return;
	}

	if (getState().application.intercomBooted) {
		if (env('logTrackingEvents', false)) {
			//console.log('Intercom already booted');
		}
		return;
	}

	if (getState().featureToggles.features.intercom && getState().featureToggles.features.intercom.enabled) {
		dispatch(getIntercom('boot', extend(config, overrides)));
		dispatch(updateIntercomBooted(true));
	} else {
		dispatch(shutdownIntercom());
	}
};

/**
 * Update the intercom state in the application
 *
 * @param bool
 * @returns {Object}
 */
export const updateIntercomBooted = (bool) => {
	return createAction(UPDATE_INTERCOM_BOOTED, bool);
};

/**
 * Shutdown the Intercom
 */
export const shutdownIntercom = () => (dispatch) => {
	dispatch(getIntercom('shutdown'));
	dispatch(updateIntercomBooted(false));
};

/**
 * Restart the Intercom
 */
export const restartIntercom = () => (dispatch) => {
	dispatch(shutdownIntercom());
	setTimeout(dispatch(bootIntercom()), 0);
};

/**
 * Set up the intercom user
 */
export const setupLiveChatUser = () => (dispatch, getState) => {
	const user = getState().application.authenticatedUser;

	if (!user) {
		dispatch(restartIntercom());
		return;
	}

	if (getState().application.intercomBooted) {
		dispatch(updateIntercom());
	} else {
		dispatch(bootIntercom());
	}
};

/**
 * Return the user configuration
 */
const buildLiverUserConfig = () => (dispatch, getState) => {
	let config = {
		app_id: env('intercomAppId'),
	};

	const user = getAuthenticatedUser(getState());

	if (user) {
		config = extend(config, {
			email: user.email,
			user_id: user.id,
			name: `${user.name} (${user.username})`,
			bonus_balance: centsAsDollars(user.free_credit_balance),
			balance: centsAsDollars(user.account_balance),
			verified: user.verified,
		});

		if (user.intercom_user_hash) {
			config.user_hash = user.intercom_user_hash;
		}
	}

	return config;
};

export const triggerOtherLevels = (name, key, payload, callback) => (dispatch, getState) => {
	if (env('logTrackingEvents', false)) {
		//console.log('OTHER LEVEL', name, key, payload, callback);
	}

	try {
		_ol(name, key, typeof payload === 'string' ? payload : JSON.stringify(payload), callback);
	} catch (e) {
		if (env('logTrackingEvents', false) && getState().featureToggles.features.otherLevels.enabled) {
			//console.log('other level fail :(');
		}
	}
};

export const setupOtherLevels = () => (dispatch, getState) => {
	const state = getState();
	const appKey = env('otherLevelAppKey');

	if (state.featureToggles.features.otherLevels.enabled && appKey) {
		(function(i, d, k, f, a) {
			let _s, _f;
			i.OtherLevelsObject = f;
			if (i[f] === undefined) {
				i[f] = function() {
					i[f].q.push(arguments); // eslint-disable-line
				};
				i[f].q = [];
			}
			_s = d.createElement(k);
			_s.src = a;
			_s.async = true;
			_f = d.getElementsByTagName(k)[0];
			_f.parentNode.insertBefore(_s, _f);
		})(window, document, 'script', '_ol', `https://cdn.otherlevels.com/js-sdk/otherlevels.js?appKey=${appKey}`);

		(function() {
			const options = {
				appName: 'TopBetta Test',
				onError: function(e) {
					console.error(typeof e === 'string' ? e : e.message);
				},
			};

			_ol('create', appKey, options, function() {
				_ol('getTag', 'os_name', function(tag) {
					const os_name = getOSName();
					if (!tag.value) {
						_ol('setTag', 'os_name', os_name, 'string', function() {});
					} else {
						if (os_name != tag.value) {
							_ol('setTag', 'os_name', os_name, 'string', function() {});
						}
					}
				});

				_ol('getTag', 'device_type', function(tag) {
					const device_type = getMobileOrDesktop();
					if (!tag.value) {
						_ol('setTag', 'device_type', device_type, 'string', function() {});
					} else {
						if (device_type != tag.value) {
							_ol('setTag', 'device_type', device_type, 'string', function() {});
						}
					}
				});

				_ol('registerEvent', 'pageview', '', function() {});

				_ol('push.isSubscribed', function(isSubscribed) {
					if (!isSubscribed) {
						_ol('askForPermission', 'notification', function() {});
					}
				});
			});
		})();
	}
};

/**
 * Start the Reality Check timer
 */
export const startRealityCheckTimer = () => (dispatch, getState) => {
	stopRealityCheckTimer();

	const authenticatedUser = getAuthenticatedUser(getState());

	// If there is a reality check, check every minute if it's time to open the modal
	if (authenticatedUser.reality_check_hours > 0) {
		App.realityCheckTimer = window.setInterval(dispatch(checkRealityCheck()), 60000);
	}
};

/**
 * Stops the Reality Check timer
 */
const stopRealityCheckTimer = () => {
	window.clearInterval(App.realityCheckTimer);
};

/**
 * Check when to open the Reality Check modal
 */
const checkRealityCheck = () => (dispatch, getState) => {
	const authenticatedUser = getAuthenticatedUser(getState());

	if (authenticatedUser) {
		const reality_check_last_update = authenticatedUser.reality_check_last_update;
		const reality_check_hours = authenticatedUser.reality_check_hours;

		// reality_check_last_update + reality_check_hours - 2minutes
		let date = new Date(reality_check_last_update);
		date.setHours(date.getHours() + reality_check_hours);
		date.setMinutes(date.getMinutes() - 2);

		const now = new Date();

		// remaining time to open the modal
		const remaining_time = date.getTime() - now.getTime();

		// if remaining_time < 1 minutes, then set time to open the Reality Check modal
		if (remaining_time <= 60000 && remaining_time >= 0) {
			window.setTimeout(function() {
				dispatch(showRealityCheckReminder());
			}, remaining_time);
		}
	} else {
		stopRealityCheckTimer();
	}
};

/**
 * Opens the Reality Check modal
 */
const showRealityCheckReminder = () => (dispatch) => {
	return dispatch(
		openModal({
			id: 'ReminderDialogContainer',
			Component: 'ReminderDialogContainer',
		}),
	);
};

/**
 * Show the Deposits modal
 */
export const showDeposits = () => (dispatch) => {
	return dispatch(
		openModal({
			id: 'DepositsPromptContainer',
			Component: 'DepositsPromptContainer',
		}),
	);
};

/**
 * Launches the Intercom Live Chat support
 */
export const launchLiveChat = () => (dispatch) => {
	dispatch(closeAllModals());
	window.Intercom('show');
};

/**
 * Get query string from the url
 *
 * @returns {*|Object}
 */
export const getAffiliateParams = () => () => {
	return URI().query(true);
};

/**
 * Opens the notification alert
 *
 * @param message
 * @param type ('success', 'danger', 'warning', 'info', 'neutral')
 * @param config {object}
 * config: {
 *  autoClose: 10000, (false for disabling)
 *  closeOnClick: false, (if false, the notification doesn't close on click and the close button will be hidden)
 * }
 */
export const openNotification = (message, type = 'neutral', config) => () => {
	// Hide the close button if the closeOnClick config is false
	const closeButton =
		config && config.closeOnClick === false ? null : <Icon icon="signup-cross" size="-2" action={() => {}} secondary />;

	toast(
		<Notification
			type={type}
			message={message}
			customActions={() => {
				return closeButton;
			}}
		/>,
		config,
	);
};

/**
 * Sets the version of the app that was loaded in state
 *
 * @returns {Object}
 * @param versionLoaded
 */
export const setVersionLoaded = (versionLoaded) => {
	return createAction(SET_VERSION_LOADED, versionLoaded);
};

/**
 * Gets the loaded app version information and sets up the supported state
 *
 * @returns {Object}
 */
export const getVersionInformation = () => {
	return (dispatch, getState) => {
		// Get the loaded version form state
		const versionLoaded = getState().application.versionLoaded;

		// Adds all the available app versions to the store's entities
		const promise = dispatch(fetchVersions(versionLoaded));

		promise.then(() => {
			dispatch(updateVersionInformation(versionLoaded));
		});

		promise.catch((error) => {
			console.error(error);
		});

		return promise;
	};
};

/**
 * Update the version information in state
 * @returns {function(*, *)}
 */
export const updateVersionInformation = (versionLoaded) => (dispatch, getState) => {
	// Get the platform the app was loaded on
	const platform = md.os();
	const loadedPlatform = platform === 'AndroidOS' ? 'android' : platform.toLowerCase();

	// Get all the available versions from state
	const versions = denormalizeVersions(getState().entities);

	// Check to see if there is a mandatory update required
	const loadedVersionRequiresUpdate = versions.some(
		(version) => compareVersions(version.version_number, versionLoaded) > 0 && version.mandatory_update,
	);

	// Check if there is an updated version available
	const nonMandatoryUpdateAvailable = versions.some(
		(version) => compareVersions(version.version_number, versionLoaded) > 0,
	);

	// Add the loaded versions information into the store's application slice
	dispatch(setVersionInformation(loadedVersionRequiresUpdate, nonMandatoryUpdateAvailable, loadedPlatform));
};

/**
 * Store the version support information in state
 *
 * @returns {Object}
 * @param loadedVersionRequiresUpdate
 * @param nonMandatoryUpdateAvailable
 * @param loadedPlatform
 */
export const setVersionInformation = (loadedVersionRequiresUpdate, nonMandatoryUpdateAvailable, loadedPlatform) => {
	return createAction(SET_VERSION_INFORMATION, {
		loadedVersionRequiresUpdate,
		nonMandatoryUpdateAvailable,
		loadedPlatform,
	});
};

/**
 * Change the language and locale settings to the provided one
 *
 * @param lng
 */
export const changeLanguage = (lng = DEFAULT_LANGUAGE) => {
	if (lng !== 'blank') {
		// If the provided language code is not one of our whitelisted languages, revert to the default
		if (!ALLOWED_LANGUAGES[lng]) {
			console.warn(`The provided locale [${lng}] has not been whitelisted.`);

			lng = DEFAULT_LANGUAGE;
		}

		try {
			moment.locale(lng); // NOTE: Moment locales need to be loaded in 'momentjs-setup' at build time
		} catch (e) {
			console.warn(`Moment locale [${lng}] not loaded in 'momentjs-setup.js'.`);
		}

		try {
			numeral.locale(lng); // NOTE: Numeral locales need to be loaded in 'numeral-setup' at build time
		} catch (e) {
			console.warn(`Numeral locale [${lng}] not loaded in 'numeral-setup.js'.`);
		}

		mergeAxiosDefaultHeaders({ locale: lng });
	}

	App.i18n.changeLanguage(lng);
};

/**
 * Add a new language resource to the translations
 *
 * @param lng
 * @param resource
 * @param namespace
 */
export const addLanguageResource = (lng, resource, namespace = 'translation') => {
	App.i18n.addResourceBundle(lng, namespace, resource);
};

/**
 * Add a group of resources to the language translations
 *
 * @param resources
 */
export const addLanguageResources = (resources) => {
	Object.entries(resources).forEach(([key, value]) => addLanguageResource(key, value));
};
