import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import { Icon } from '@tbh/ui-kit';
import { slugify } from '../../../../legacy/core/format';
import * as CSHP from '../../../../pages/Sports/SportsHome/SportsHomePage.styled';


const UpcomingSportsList = (props) => {
  const { className, sports, OnItemClick} = props;

  const onItemClick = (event) => {
    OnItemClick(event.target.id);
  };
  const componentClasses = cx({
    [className]: className,
  });

  return (
    <div className={componentClasses}>
      <CSHP.StyledSportsUpcomingPage__VisibleSportContainer>
        <CSHP.StyledSportsUpcomingPage__VisibleSport_UL>
          {sports.map((item) => {
            return (
              <CSHP.StyledSportsUpcomingPage__VisibleSport_LI key={item.id} id={item.id}   onClick={onItemClick}>
                  <Icon push="2" icon={slugify(item.name)} />
                  {item.name}
              </CSHP.StyledSportsUpcomingPage__VisibleSport_LI>
            );
          })}
        </CSHP.StyledSportsUpcomingPage__VisibleSport_UL>
      </CSHP.StyledSportsUpcomingPage__VisibleSportContainer>
    </div>
  );
};

UpcomingSportsList.propTypes = {
  /** On Sport click */
  onItemClick: PropTypes.func.isRequired,

  /** Extra classes */
  className: PropTypes.string,

  /** Whether or not the header should be hidden */
  hideHeader: PropTypes.bool,

  /** On Player Bets click */
  onPlayerBetsClick: PropTypes.func,

  /** Player Bets item */
  playerBets: PropTypes.shape({
    /** id */
    id: PropTypes.string,
    /** name */
    title: PropTypes.string,
    /** icon */
    icon: PropTypes.string,
  }),

  /** List of visible sports */
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      /** Sport id */
      id: PropTypes.number,
      /** Sport name */
      name: PropTypes.string,
    }),
  ),
};

UpcomingSportsList.defaultProps = {
  className: '',
  hideHeader: false,
  onPlayerBetsClick: () => {},
  playerBets: {},
  sports: [],
};

export default UpcomingSportsList;
