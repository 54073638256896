import React, { useState, useEffect } from 'react';
import styled, { css } from 'react-emotion';
import { serverDateTime } from '../../../../legacy/core/format';
import moment from 'moment';
import { Spinner } from '../../Application/LoadingSpinner/LoadingSpinner';
import { Text } from '@tbh/ui-kit';
import { FaArrowDown } from 'react-icons/fa';
import { getTransactionDetails } from '../../../../store/entities/actions/UserActions';
import { getBonusTransactionDetails } from '../../../../store/entities/actions/UserActions';

const TransactionsDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 4px;
    padding: 8px 0px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

const TransactionsDetailsOptions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        justify-content: space-evenly;
    }
`;

const TransactionsDetailsOption = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 12px;

    ${(props) =>
        props.selected &&
        css`
            border: 1px solid #00a651;
        `}

    &:hover {
        border: 1px solid #00a651;
    }
`;

const DownloadButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 48px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 16px;

    ${(props) =>
        props.selected &&
        css`
            border: 1px solid #00a651;
        `}

    &:hover {
        border: 1px solid #00a651;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const TransactionInputGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: end;
    padding: 8px 0px;
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
`;

const Input = styled.input`
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
`;

const SpinnerIcon = styled(Spinner)`
    color: #000; // Change color as needed
`;

const TransactionsDetails = ({ t, type, loadData, context,form,setForm }) => {

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [hasData, setHasData] = useState(true);
    const [selected, setSelected] = useState('today');

    const options = [
        { label: 'Today', value: 'today', selected: false },
        { label: 'This Week', value: 'week', selected: false },
        { label: 'This Month', value: 'month', selected: false },
    ];

    // useEffect(() => {
    //     if (form.startDate && form.endDate && selected === 'range') {
    //         loadData(1, type, 'range', form.startDate, form.endDate);
    //     }
    //     setError({});
    // }, [form.startDate, form.endDate]);

    const handleTransactionDetailsDefaultTimes = (option) => {
        const dateRanges = {
            today: [moment(), moment()],
            week: [moment().startOf('week'), moment().endOf('week')],
            month: [moment().startOf('month'), moment().endOf('month')],
        };

        const [startDate, endDate] = dateRanges[option] || [moment().subtract(option, 'days'), moment()];

        setForm({
            days: option,
            startDate: option === 'range' ? '' : serverDateTime(startDate).format('YYYY-MM-DD'),
            endDate: option === 'range' ? '' : serverDateTime(endDate).format('YYYY-MM-DD'),
        });

        if (option !== 'range') {
            loadData(
                1,
                type,
                option,
                serverDateTime(startDate).format('YYYY-MM-DD'),
                serverDateTime(endDate).format('YYYY-MM-DD')
            );
        }

        setError({});
        setHasData(true); // Reset hasData when filters change
        setSelected(option);
    };

    const formChange = (name, value) => {
        const newValue = serverDateTime(value).format('YYYY-MM-DD');
        const updatedForm = {
            ...form,
            [name]: newValue,
        };
        setForm(updatedForm);
    
        // Check if both startDate and endDate are present to load data
        if (updatedForm.startDate && updatedForm.endDate) {
            setHasData(true); // Reset hasData when form changes
    
            // Call loadData to update the data grid
            loadData(
                1,
                type,
                'range',
                updatedForm.startDate,
                updatedForm.endDate
            );
        }
    };
    

    const handleDownload = async () => {
        if (loading) return;

        if (form.days === 'range') {
            if (!form.startDate || !form.endDate) {
                let error = {};
                Object.keys(form).forEach((key) => {
                    if (!form[key]) {
                        error[key] = `${key} is required`;
                    }
                });
                setError(error);
            }
        }

        setLoading(true);
        try {
            // console.log(form.days, form.startDate, form.endDate);
            let urlObject;
            if (context === 'bonusBet') {
                urlObject = await getBonusTransactionDetails(type, form.days, form.startDate, form.endDate);
            } else {
                urlObject = await getTransactionDetails(type, form.days, form.startDate, form.endDate);
            }

            const pdfname = `Statement_${form.startDate}-${form.endDate}.pdf`;
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = urlObject;
            a.download = pdfname;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(urlObject);
            document.body.removeChild(a);
            setError({});
            setHasData(true); // Data is available
        } catch (err) {
            console.log(err);

            setError({
                data: err.message,
            });
            setHasData(false); // No data available
        }
        setLoading(false);
    };

    return (
        <TransactionsDetailsContainer>
            <TransactionsDetailsOptions>
                {options.map((option, index) => (
                    <TransactionsDetailsOption
                        selected={option.value === selected}
                        key={index}
                        onClick={() => handleTransactionDetailsDefaultTimes(option.value)}
                    >
                        {option.label}
                    </TransactionsDetailsOption>
                ))}
                <TransactionsDetailsOption
                    selected={selected === 'range'}
                    onClick={() => handleTransactionDetailsDefaultTimes('range')}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>Date range</div>
                </TransactionsDetailsOption>
                <DownloadButton onClick={handleDownload} disabled={!hasData}>
                    {loading ? <SpinnerIcon /> : <FaArrowDown style={{ marginLeft: '3px' }} />}
                </DownloadButton>
            </TransactionsDetailsOptions>
            {selected === 'range' && (
                <TransactionInputGroup>
                    <div>
                        <label style={{ marginRight: '8px', fontSize: 11 }}>From</label>
                        <Input type="date" onChange={(e) => formChange('startDate', e.target.value)} value={form.startDate} />
                        {error.startDate && (
                            <Text size="-2" style={{ color: 'red' }}>
                                {error.startDate}
                            </Text>
                        )}
                    </div>
                    <div>
                        <label style={{ marginRight: '8px', fontSize: 11 }}>To</label>
                        <Input type="date" onChange={(e) => formChange('endDate', e.target.value)} value={form.endDate} />
                        {error.endDate && (
                            <Text size="-2" style={{ color: 'red' }}>
                                {error.endDate}
                            </Text>
                        )}
                    </div>
                </TransactionInputGroup>
            )}
            {error.data && (
                <Text size="-2" style={{ color: 'red' }}>
                    {error.data}
                </Text>
            )}
        </TransactionsDetailsContainer>
    );
};

export default TransactionsDetails;
