// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { continueRealityCheck, setRealityCheck } from '../../../store/entities/actions/UserActions';
import { closeModal } from '../../../store/modal/modalActions';
import { logoutUser } from '../../../store/authentication/authenticationActions';

// Components
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import ReminderDialog from '../../../components/features/ResponsibleGambling/RealityCheckSettings/ReminderDialog/ReminderDialog';

class ReminderDialogContainer extends Component {
	state = {
		error: null,
		loading: false,
	};
	/**
	 * Calls the action to continue the Reality Check
	 */
	continueRealityCheck = () => {
		this.setState({ loading: true });
		this.props
			.continueRealityCheck()
			.catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
					this.props.setRealityCheck(this.props.reality_check_hours);
				}
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	/**
	 * Remove the error message
	 */
	handleClearError = () => {
		this.setState({
			error: null,
		});
	};

	render() {
		let { reality_check_hours, reality_check_last_update, handleLogout } = this.props;

		let { loading, error } = this.state;

		let { continueRealityCheck, handleClearError } = this;

		// Time that the user will be logged out:
		// time that the reality check was updated + reality check
		let start_time = moment(reality_check_last_update) + reality_check_hours * 3600 * 1000;

		return (
			<div>
				<ModalHeader title="Reality Check" />

				<ReminderDialog
					isLoading={loading}
					continueRealityCheck={continueRealityCheck}
					onLogout={handleLogout}
					reality_check={reality_check_hours}
					start_time={start_time}
					error={error}
					clearError={handleClearError}
				/>
			</div>
		);
	}
}

ReminderDialogContainer.propTypes = {
	continueRealityCheck: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	reality_check_hours: PropTypes.number.isRequired,
	reality_check_last_update: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {

	const user = state.entities.users[state.application.authenticatedUser];

	return {
		reality_check_hours: user ? user.reality_check_hours : null,
		reality_check_last_update: user ? user.reality_check_last_update : null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		continueRealityCheck: () => {
			return dispatch(continueRealityCheck()).then(() => dispatch(closeModal('ReminderDialogContainer')));
		},
		setRealityCheck: (period) => {
			return dispatch(setRealityCheck(period)).then(() => dispatch(closeModal('ReminderDialogContainer')));
		},
		handleLogout: () => {
			dispatch(logoutUser());
			return dispatch(closeModal('ReminderDialogContainer'));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReminderDialogContainer);
