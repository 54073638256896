// Constants needed for translations
import {
	CURRENT,
	CUSTOM,
	FILTER_RESULTS,
	LAST_7_DAYS,
	LAST_MONTH,
	YESTERDAY,
} from '../../common/constants/Tournaments';
import {
	EXOTIC_BOXED,
	EXOTIC_STANDARD,
	EXOTIC_STANDOUT,
	RACING_BUMPED_LABEL,
	RACING_EACHWAY_LABEL,
} from '../../common/constants/Racing';
import {
	ACTIVE_LABEL,
	BUY_IN_LABEL,
	CLOSED_LABEL,
	DAILY_TOURNAMENTS_LABEL,
	HIGHLIGHTS_LABEL,
	MY_TOURNAMENTS_LABEL,
	PRIZE_POOL_LABEL,
	RACING_LABEL,
	REBUYS_LABEL,
	SPORTS_LABEL,
} from '../../pages/Tournaments/TournamentsConstants';
import {
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
	USER_ACCOUNT_SUBMENU_DEPOSIT,
	USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT,
	USER_ACCOUNT_SUBMENU_LOSS_LIMITS,
	USER_ACCOUNT_SUBMENU_REALITY_CHECK,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_SUBMENU_SELF_EXCLUSION,
	USER_ACCOUNT_SUBMENU_SPEND_LIMIT,
	USER_ACCOUNT_SUBMENU_TAKE_A_BREAK,
	USER_ACCOUNT_SUBMENU_USER_HOME,
	USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
	USER_ACCOUNT_SUBMENU_WITHDRAWALS,
	USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL,
} from '../../pages/UserAccount/UserAccountPageConstants';
import {
	DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT,
	SELECTION_PRICE_NOT_APPLICABLE,
} from '../../store/entities/constants/BetConstants';
import { NOT_VERIFIED, VERIFIED } from '../../containers/Deposit/DepositConstants';
import { GENERIC_ERROR_MESSAGE, GENERIC_LOGIN_MESSAGE } from '../../common/constants/Notifications';

// Translation strings
export default {
	'^Adults Only': '^18歳以上のみご利用可能です。',
	'^Invalid Credit Card Number': '^クレジットカードの番号が無効です。',
	'^Invalid CVV': '^セキュリティコードが無効です。',
	'^Invalid expiry date': '^有効期限が無効です。',
	'Details used on this account are already in use by a previous user. Please contact Support on 1300 886 503 to resolve.':
		'このアカウントに利用された個人情報は他のユーザーが利用しています。1300 886 503までお問い合わせくださいませ。',
	'Email is not a valid email': 'メールアドレスが無効です。',
	'is not a valid email': '有効なメールアドレスではありません。',
	'is not valid': '無効です。',
	'must be an integer': '半角英数字で設定してください。',
	'must be at least 6 characters': '6文字以上で設定してください。',
	'must have at least 1 number and 1 letter': '英数字を1文字以上ずつ含む必要があります。',
	'Password must contain at least one number and one letter': 'パスワードは英数字を1文字以上ずつ含む必要があります。',
	'The city field is required.': '市町村を入力してください。',
	'The country field is required.': '国名を入力してください。',
	'The dob day field is required.': '誕生日を入力してください。',
	'The dob month field is required.': '誕生月を入力してください。',
	'The dob year field is required.': '誕生年を入力してください。',
	'The email format is invalid.': 'メールアドレスの形式が無効です。',
	'The first name field is required.': '名を入力してください。',
	'The first name must be at least 2 characters.': '名をアルファベットで入力してください。',
	'The last name field is required.': '姓を入力してください。',
	'The last name must be at least 2 characters.': '姓をアルファベットで入力してください。',
	'The marketing opt in flag field is required.': '米印のついている項目は必須項目です。',
	'The password field is required.': 'パスワードを入力してください。',
	'The password must be at least 6 characters.': 'パスワードは6文字以上で設定する必要があります。',
	'The postcode field is required.': '郵便番号を入力してください。',
	'The source field is required.': '都道府県名を入力してください。',
	'The state field is required.': '市町村を入力してください。',
	'The street field is required.': '番地を入力してください。',
	'The title field is required.': 'タイトルを入力してください。',
	'The username field is required.': 'ユーザー名を入力してください。',
	'The username must be at least 6 characters.': 'ユーザー名を6文字以上で入力してください。',
	'You must be 18 or older to sign up to TopBetta': '18歳以上である必要があります。',
	"can't be blank": '入力必須項目です。',
	'1st Prize': '1等',
	'1st Up': '1st アップ',
	'2nd Up': '2nd アップ',
	'Available until': 'まで有効です。',
	'e/w': 'e/w',
	'First 4': 'ファースト4',
	'First Four': 'First Four',
	'FREE Tournament': 'フリートーナメント',
	'n/a': 'n/a',
	'NO RETURN': 'ノーリターン',
	'No.': 'いいえ',
	'Non Wet': 'ウェットではない',
	'Not enough selections!': '選択が足りない',
	'out of': 'の内',
	'Play with': 'プレイする',
	'Say something': 'コメントする',
	'Terms&Conditions': 'ご利用規約',
	'To Beat the Field': 'フィールドをビートする',
	'To Beat': 'ビートする',
	'Type something...': '入力してください',
	'v/s': 'v/s',
	'Win/Place': '勝ち/ベット',
	[ACTIVE_LABEL]: 'アクティブ',
	[BUY_IN_LABEL]: '購入',
	[CLOSED_LABEL]: '閉鎖中',
	[CURRENT]: '開催中',
	[CUSTOM]: 'カスタム',
	[DAILY_TOURNAMENTS_LABEL]: 'デイリートーナメント',
	[DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT]: 'ベットアマウント',
	[EXOTIC_BOXED]: '箱に入った',
	[EXOTIC_STANDARD]: 'スタンダード',
	[EXOTIC_STANDOUT]: 'スタンドアウト',
	[FILTER_RESULTS]: 'フィルター後の結果',
	[GENERIC_ERROR_MESSAGE]: 'エラーが発生しました。再度お試しくださいませ。',
	[GENERIC_LOGIN_MESSAGE]: '続けるにはログインが必要です。',
	[HIGHLIGHTS_LABEL]: 'ハイライト',
	[LAST_7_DAYS]: '過去7日間',
	[LAST_MONTH]: '先月',
	[MY_TOURNAMENTS_LABEL]: 'デイリー',
	[NOT_VERIFIED]: '認証されていません。',
	[PRIZE_POOL_LABEL]: '賞金プール',
	[RACING_BUMPED_LABEL]: 'BUMPED',
	[RACING_EACHWAY_LABEL]: '1着・2着・3着の馬券を買う',
	[RACING_LABEL]: 'レーシング',
	[REBUYS_LABEL]: '再購入',
	[SELECTION_PRICE_NOT_APPLICABLE]: 'n/a',
	[SPORTS_LABEL]: 'スポーツ',
	[VERIFIED]: '認証済',
	[YESTERDAY]: '昨日',
	Abandoned: '放棄した',
	Accept: '同意する',
	accepted: '同意されました',
	Account: 'アカウント',
	account: 'アカウント',
	AccountBlockedTemporarily: '一時的にブロックされたアカウント',
	AccountNumber: 'アカウント番号',
	AcronymDriver: 'D',
	AcronymJockey: 'J',
	AcronymKilogram: 'kg',
	AcronymLoss: 'L',
	AcronymRace: 'R',
	AcronymRefunded: 'R',
	AcronymTrainer: 'T',
	AcronymUnresulted: 'U',
	AcronymWeather: 'W',
	AcronymWin: 'W',
	Active: 'アクティブ',
	Add: '追加',
	Added: '追加されました',
	Adds: '追加',
	AddToBetSlip: 'ベットスリップに追加する',
	Age: '年齢',
	All: '全て',
	Amount: '金額',
	Apply: '適用する',
	Approved: '承認されました',
	at: 'で',
	Available: '利用可能',
	AvailableBonusBet: '利用可能なボーナスベット',
	AvailableCash: '利用可能なキャッシュ',
	AwesomeThanks: 'ありがとうございます！',
	BackToBetting: 'ベッティングに戻る',
	BackToTop: 'トップに戻る',
	Balance: '残高',
	Bank: '銀行',
	BankDepositInformation: '銀行入金情報',
	BankEFT: '銀行電子資金振替',
	BetLimit: 'ベット上限',
	Bets: 'Bets',
	BetSlip: 'ベットスリップ',
	BillerCode: 'ビラーコード（Biller code）',
	Bonus: 'ボーナス',
	Box: 'ボックス',
	Boxed: 'ボックスの',
	Break: '休憩',
	BSB: 'BSB',
	Buy: '買う',
	BuyIn: '買い込む',
	Cancel: 'キャンセル',
	CancelWithdrawal: '出金をキャンセルする',
	Career: 'キャリア',
	Cash: 'キャッシュ',
	CashTournament: 'キャッシュトーナメント',
	Change: '変更する',
	ChooseCreditCard: 'クレジットカードを選択する',
	ClaimFreeTicket: '無料チケットを請求する',
	Clear: 'クリアする',
	Close: '閉じる',
	Closed: '閉じた',
	Closing: '閉じる',
	Colour: '色',
	Comment: 'コメント',
	Competitions: 'コンペティション',
	Confirm: '確認する',
	Confirmed: '確認した',
	ContinueBetting: 'ベットを続ける',
	CreditCard: 'クレジットカード',
	CreditCardNumber: 'クレジットカード番号',
	Currency: '通貨',
	CustomAmount: 'カスタムアマウント',
	daily: 'デイリー',
	Daily: 'Daily',
	DailyTournamentSchedule: 'デイリートーナメント日程',
	Dam: '母馬',
	Date: '日付',
	Delete: '削除する',
	deposit: '入金する',
	Deposit: '入金',
	DepositAmount: '入金額',
	DepositFromBankAccount: '銀行口座から入金する',
	DepositReceipt: '入金領収書',
	Deposits: 'Deposits',
	DepositSuccessful: '入金が完了しました。',
	Description: '内容',
	Details: '詳細',
	Dismiss: 'やめる',
	Distance: '距離',
	Dividend: '配当',
	DOB: '生年月日',
	EmailAddress: 'メールアドレス',
	EndDate: '終了日',
	Ended: '終了',
	EnterCreditCardDetails: 'クレジットカード情報を入力する',
	EnterTournament: 'トーナメントに参加する',
	Entries: 'エントリー',
	EntriesClose: 'エントリー終了',
	EntryCost: 'エントリーコスト',
	events: 'イベント',
	EW: 'EW',
	Email: 'メール',
	Exacta: '連勝単式',
	Existing: '既存の',
	Exotic: 'エキゾチック',
	Exotics: 'エキゾチック',
	Expiry: '満期',
	Fee: '料金',
	FilterBy: 'フィルターをかける',
	Firm: '会社',
	Fixed: '固定の',
	fixed: 'fixed',
	FixedOdds: 'Fixed Odds（固定賭け率）',
	Flexi: 'Flexi',
	Forgot: '忘れた',
	Free: '無料',
	FullyRefunded: '全て返金されました',
	THOROUGHBRED: 'ギャロップ',
	Thoroughbred: 'Thoroughbred',
	Get: '獲得',
	Good: '良い',
	GotItThanks: 'ありがとうございます。',
	GREYHOUNDS: 'グレーハウンド',
	Greyhounds: 'グレーハウンド',
	HARNESS: 'ハーネス',
	Harness: 'ハーネス',
	Heavy: 'ヘビー',
	Hidden: '非表示',
	Hide: '非表示にする',
	HideMarkets: 'マーケットを非表示にする',
	HowDoesThisWork: '仕組み',
	ID: 'ID',
	Immediately: 'すぐに',
	including: '含める',
	International: 'インターナショナル',
	INTERNATIONAL: 'インターナショナル',
	Join: '参加する',
	Last: 'ラスト',
	length: '長さ',
	limit: 'リミット',
	Limit: 'リミット',
	Live: 'ライブ',
	LIVE: 'ライブ',
	Locked: 'ロックされた',
	Login: 'ログイン',
	Logo: 'ロゴ',
	Logout: 'ログアウト',
	Long: '長い',
	lose: '負け',
	Losing: '負けている',
	Loss: '損失',
	loss: '損失',
	LOSS: '損失',
	MakeAWithdrawal: '引き出し申請をする',
	MakeDeposit: '入金する',
	Menu: 'メニュー',
	Minimum: '最小',
	minus: 'マイナス',
	MM_YY: '月/年',
	Mobile: 'モバイル',
	Monthly: '毎月',
	monthly: '毎月',
	MoreInfo: 'さらに詳しく',
	MoreMarkets: 'もっとマーケットを見る',
	Multi: 'マルチ',
	MyRebuys: 'マイRe-buys',
	MyTournaments: 'マイトーナメント',
	NameOnCreditCard: 'クレジットカード上の氏名',
	NeedHelp: '何かお困りのことがございますか？',
	Next: '次',
	NextStep: '次のステップ',
	NextTournament: '次のトーナメント',
	Night: '夜',
	No: 'いいえ',
	None: '全く',
	Note: 'ノート',
	Now: '今',
	Odds: 'オッズ',
	OrderBy: 'オーダーする',
	Other: 'その他',
	Owners: 'オーナー',
	P: 'P',
	Paid: '支払い完了',
	PartiallyRefunded: '部分的に返金された',
	Password: 'パスワード',
	Pending: '保留中',
	pending: '保留中',
	PerEvent: 'それぞれのイベント',
	PhoneNumber: '電話番号',
	PLACE: '場所',
	Place: 'Place',
	place: 'place',
	Play: 'プレイする',
	PlayerBets: 'プレーヤーベット',
	PlayerProfile: 'プレーヤのプロフィール',
	PlayersRebuys: 'プレーヤのRe-buys',
	PlayTournament: 'トーナメントをプレイする',
	PlayWith: 'でプレイする',
	Popular: '人気の',
	Position: 'ポジション',
	PossiblePayout: '可能な支払い',
	PotentialPayout: 'Potential Payout',
	PotentialWinnings: '可能のある勝利',
	PrivacyPolicy: 'プライバシーポリシー',
	Prize: '賞金',
	PrizeFormat: '賞金の形式',
	PrizePool: 'プライズプール',
	PrizeTable: 'プライズテーブル',
	PromoCode: 'プロモーションコード',
	Promotions: 'プロモーション',
	Quinella: 'キネラ',
	race: 'レース',
	Race: 'レース',
	races: 'レース',
	Rank: 'ランク',
	ReBet: '再ベット',
	RebuyIntoTournament: 'トーナメントの再購入',
	RebuyLimit: '再購入上限',
	Receipt: '領収書',
	Recent: '最近',
	Ref: '照会',
	Refunded: '返金された',
	Rejected: '却下された',
	removed: '消去された',
	Results: '結果',
	Retry: 'リトライ',
	Scratching: 'スクラッチ',
	SearchTournaments: 'トーナメントを調べる',
	Season: 'シーズン',
	SecurityCode: 'セキュリティコード',
	Selection: '選択',
	Selections: '選択',
	Send: '送る',
	Sex: '性別',
	Shared: '分配された',
	Short: '短い',
	Show: 'ショー',
	Single: 'シングル',
	SingleBetSlip: 'シングルベットスリップ',
	Singles: 'Singles',
	Sire: '雄親',
	Sledge: 'スリッジ',
	SledgeBox: 'スリッジボックス',
	Soft: '柔らかい',
	Spend: 'を費やす',
	spend: 'spend',
	sport: 'スポーツ',
	Sport: 'Sport',
	Sports: 'スポーツ',
	Stake: '賭け金',
	StartDate: '開始日時',
	StartPlaying: 'プレイを開始する',
	Starts: '開始する',
	Status: 'ステータス',
	Step: 'ステップ',
	Support: 'サポート',
	Synthetic: '人工的な',
	TermsAndConditions: '規約と条件',
	termsAndConditions: '規約と条件',
	TermsOfService: 'ご利用規約',
	Ticket: 'チケット',
	'Ticket Purchase': 'チケット購入',
	Tickets: 'Tickets',
	To: 'へ',
	to: 'to',
	Today: '本日',
	togo: 'へ行く',
	Top: 'トップ',
	Total: '合計',
	TotalRebuys: '再購入の合計',
	Tournament: 'トーナメント',
	TournamentDetails: 'トーナメント詳細',
	TournamentEntry: 'トーナメント参加',
	'Tournament Entry': 'Tournament Entry',
	TournamentRebuy: 'トーナメント再購入',
	tournaments: 'tournaments',
	Tournaments: 'Tournaments',
	TournamentsEntered: '参加したトーナメント',
	towin: '勝つには',
	ToWin: 'To Win',
	Track: 'トラック',
	TrackDistance: 'トラックの距離',
	Transactions: '取引',
	TrendingTournament: '注目のトーナメント',
	Trifecta: '三連勝単式',
	TryAgain: 'もう一度試す',
	Type: 'タイプ',
	User: 'ユーザー',
	Username: 'ユーザー名',
	Verified: '認証された',
	Verify: '認証する',
	VerifyAmount: 'アカウントを認証する',
	VerifyCard: 'カードを認証する',
	VerifyLater: 'あとで認証する',
	via: 'によって',
	ViewTournament: 'トーナメントを見る',
	W: 'W',
	weekly: 'ウィークリー',
	Weekly: 'Weekly',
	Welcome: 'ようこそ',
	Wet: '濡れた',
	WeWillSendYou: 'お送りいたします。',
	WIN: '勝ち',
	Win: 'Win',
	win: 'win',
	Winning: '勝った',
	winning: 'winning',
	WinPotential: '可能性として勝つために',
	with: 'と',
	Withdraw: '出金する',
	withdraw: 'withdraw',
	WithdrawableBalance: '出金可能な残高',
	Withdrawal: '出金額',
	withdrawal: 'withdrawal',
	WithdrawalMethod: '出金方法',
	WithdrawalReceipt: '出金領収書',
	Withdrawals: 'Withdrawals',
	Without: 'なしで',
	Won: '勝利した',
	WON: 'WON',
	Yes: 'はい',
	YesPlease: 'はい、お願いします。',
	"RacingHomePage__Today's": '今日の',
	"RacingHomePage__Tomorrow's": '明日の',
	"RacingHomePage__Yesterday's": '昨日の',
	'DerivativeSelectionList__Label--FVF': 'お気に入り vs 全出走馬',
	'DerivativeSelectionList__Label--H2H': 'Head to Head',
	'DerivativeSelectionList__Label--HVH': 'Half vs Half',
	'DerivativeSelectionList__Label--IO': 'インサイド vs アウトサイド',
	'DerivativeSelectionList__Label--OVE': 'オッズ vs イーブン',
	'Racing__Status--abandoned': '放棄済み',
	'Racing__Status--closed': '終了済み',
	'Racing__Status--deleted': '削除済み',
	'Racing__Status--interim': '暫定中',
	'Racing__Status--paid': '支払い済み',
	'Racing__Status--paying': '支払い中',
	'Racing__Status--selling': '売り出し中',
	'SpeedmapLegend__Value--Backmarker': 'バックマーカー',
	'SpeedmapLegend__Value--Leader': 'リーダー',
	'SpeedmapLegend__Value--Midfield': 'ミッドフィールド',
	'SpeedmapLegend__Value--Off-pace': '先頭より遅れて',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}--mobile`]: '口座明細',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}`]: '口座取引',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}--mobile`]: 'ボーナス明細',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}`]: 'ボーナスによるベット取引',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS}--mobile`]: 'ベット額のデフォルト',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS}`]: 'Default Bet Amounts',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT}--mobile`]: '入金上限額を設定する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT_LIMIT}`]: 'Set Deposit Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT}--mobile`]: '入金する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_DEPOSIT}`]: '入金資金',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_LOSS_LIMITS}--mobile`]: '損失上限額を設定する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_LOSS_LIMITS}`]: 'Set Loss Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_REALITY_CHECK}--mobile`]: 'リアリティチェック',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_REALITY_CHECK}`]: 'Reality Check',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESET_PASSWORD}--mobile`]: 'パスワードを再設定する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESET_PASSWORD}`]: 'Reset Password',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING}--mobile`]: '賭け事に対する責任',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING}`]: 'Responsible Gambling',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SELF_EXCLUSION}--mobile`]: '自己除外 (永久)',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SELF_EXCLUSION}`]: 'Self Exclusion (Permanent)',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SPEND_LIMIT}--mobile`]: '利用上限額を設定する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_SPEND_LIMIT}`]: 'Set Spend Limits',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_TAKE_A_BREAK}--mobile`]: '休憩する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_TAKE_A_BREAK}`]: 'Take a Break',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_USER_HOME}--mobile`]: 'マイプロフィール',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_USER_HOME}`]: 'My Profile',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS}--mobile`]: '資金を出金する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS}`]: 'Withdraw Funds',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAWALS}--mobile`]: '出金をキャンセルする',
	[`UserAccountNavList__${USER_ACCOUNT_VERIFY_CREDIT_CARDS_URL}--mobile`]: 'クレジットカードを認証する',
	[`UserAccountNavList__${USER_ACCOUNT_SUBMENU_WITHDRAWALS}`]: 'Cancel Withdrawal',
	AccountSummary__AccountNumber: '口座番号',
	AccountSummary__LockScreen: 'スクリーンをロックする',
	AccountSummary__MyAccount: 'マイアカウント',
	AccountSummaryNavList__Download: 'モバイルアプリケーションをダウンロードする',
	AccountSummaryStatistics__Happy: 'おかえりなさい！',
	AccountSummaryStatistics__PendingBets: '保留中のベット',
	AccountSummaryStatistics__PotentialWinnings: '可能性のある勝ち金額',
	AccountSummaryStatistics__TotalStakes: '賭け金の合計',
	AccountSummaryStatistics__Welcome: 'おかえりなさい',
	AccountTransactionsCellMulti__Leg: '{{length}} Leg Multi',
	AccountTransactionsCellRacing__GoatMargin: '*GOAT {{margin}}のマージンが追加されました。',
	AccountTransactionsCellRebet__MatchStarts: 'マッチ開始まで:',
	AdditionalAddressFields__AutoComplete: '住所を自動入力する',
	AdditionalAddressFields__PostcodeConstraint: '^４文字以上で入力してください',
	AdditionalAddressFields__TownCity: '市町村',
	AuthoriseVerification__CreditCard: 'クレジットカードを認証することを許可します。',
	AuthoriseVerification__CreditCardWithBrand: ' {{brandName}} がクレジットカードを認証することを許可します.',
	BankAccountForm__AccountHolderName: '口座名義',
	BankAccountForm__AccountName: '口座名',
	BankAccountForm__AccountNumber: '口座番号',
	BankAccountForm__BankBSB: '支店確認コード（BSB)',
	BankAccountForm__BankName: '銀行名',
	BankAccountForm__BankStateBranch: '支店名',
	BankAccountForm__WhatBankName: '銀行名をお答えください',
	BetPendingReview__Title: '保留中のベットのレビュー',
	BetPlacementContainer__AddToMulti: 'Add to Multi',
	BetPlacementContainer__ChargeEntryButton: 'チケットを購入する & ',
	BetPlacementContainer__ChargeEntryMessage: 'トーナメント参加料として{{cost}}が請求されます。 ',
	BetPlacementContainer__ExceededBalance: 'ベット額が残高を上回っています。残高分全てをベットする。',
	BetPlacementContainer__FreeEntryButton: 'チケットを請求する & ',
	BetPlacementContainer__FreeEntryMessage: 'トーナメント参加料は請求されません。',
	BetPlacementContainer__PlaceOdds: 'オッズを置く',
	BetPlacementContainer__RacingOddsChange: '- {{last}} から {{current}}に変更されました',
	BetPlacementContainer__SportOddsChange: '- {{last}} から {{current}}にオッズが変更されました',
	BetPlacementContainer__WinOdds: '勝利オッズ',
	BetPromptContainer__AnyOrder: 'オーダー',
	BetPromptContainer__BetReceiptTitle: 'ベットレシート',
	BetPromptContainer__ExactOrder: 'そのオーダー',
	BetPromptContainer__QuickDepositTitle: 'クイック入金',
	BetReceipt__Continue: 'ベットを続ける',
	BetReceipt__Refunded: 'あなたのベットは全て返金されました。',
	BetReceipt__RefundedSingle: 'あなたのベットのうち1つが全て返金されました。',
	BetReceipt__Rejected: 'あなたのベットは却下されました。',
	BetReceipt__RejectedSingle: 'あなたのベットのうち1つが却下されました。',
	BetReceipt__RejectReason: '却下された理由',
	BetReceipt__Trader: 'トレーダーのコメント',
	BetSelectionSingle__BUMPED: 'BUMPED',
	BetSelectionSingle__MarginMessage: '{{margin}}着差を二着目に追加する。',
	BetSlipButton__Label: 'BetSlip',
	BetSummaryBetItem__MarginMessage: '*{{marginButt}} 頭身を二着目に追加する。',
	BetSummaryBetList__NoBets: '表示可能なベットはありません。',
	BetSummaryTicketList__NoTickets: 'チケットがありません。',
	BettaBucksAmount__PlayWith: 'プレイする',
	BetTermsMessage__TermsMessageStart: ' 下記に同意された全てのベット',
	BetTermsMessage__TermsMessageEnd: ' - 賭け事に関する責任',
	BonusBetsList__Accepted: '同意された',
	BonusBetsList__BonusBetsMessage:
		'ボーナスベットはウェブサイトの認知促進と新規プレーヤー様にウェブサイトをお楽しみいただくことを目的とし進呈されます。',
	BPayDeposit__InfoTitle: '{{BPay}} 入金情報',
	BPayDeposit__Title: '{{BPay}}で入金する',
	CancelWithdrawalConfirmation__CanBe: 'キャンセル可能です',
	CancelWithdrawalConfirmation__FundsWillAppear: '金額はお客様のアカウントに表示されます。',
	CancelWithdrawalConfirmation__Success: 'キャンセルされました。',
	CancelWithdrawalConfirmation__ThisRequest: 'こちらのリクエスト',
	CancelWithdrawalConfirmation__TryAgain:
		'もう一度お試しいただくかサポートが必要な場合はカスタマーサポートにお問い合わせください。',
	CancelWithdrawalConfirmation__UnableToProcess: 'お客様のリクエストを手続きすることができません。',
	ContactUsPanel__Title: '問い合わせする',
	CountdownPanel__Message: '時間切れになる前にボーナスベットを請求しましょう！',
	CreateAccount__Button: '新規アカウントを作る',
	CreateAccount__NewTo: '{{brandName}}の利用は初めてですか？',
	CreditCardVerification__EnterVerification:
		'お客様のクレジットカード認証のために請求された額をご入力ください。詳細はお客様のクレジットカードの明細でご確認いただけます。',
	CreditCardVerification__VerificationError:
		'認証のために請求された額が正しくありません。もう一度お試しください。 {{verificationAttemptsLeft}}/3 attempts left.',
	CreditCardVerification__VerifyCreditCard: '出金申請をする前にお客様のクレジットカードを認証する必要があります。',
	DefaultBetAmounts__Accepted: '完了しました',
	DefaultBetAmounts__BetAmount: 'ベット額',
	DefaultBetAmounts__Error: 'デフォルト額が更新されました',
	DefaultBetAmounts__NewDefault: '新たなデフォルトベット額',
	DefaultBetAmounts__NotAccepted: '認められません',
	DefaultBetAmounts__NotSet: '設定されていません',
	DefaultBetAmounts__TournamentBetAmount: 'トーナメントベット額',
	DefaultBetAmounts__TournamentLive: 'ライブトーナメントベットの警告',
	DefaultBetAmounts__TournamentMessage: 'ボーナスではなくリアルマネーでベットした際には下記に同意します。',
	DefaultBetAmounts__Update: 'デフォルトを更新する',
	DepositConfirmation__CreditAccount: 'お客様の口座にお振込します。',
	DepositConfirmation__CreditBalance: 'お客様の{{bonus}} 残高に金額が振り込まれました.',
	DepositConfirmation__FirstSuccessMessage: '初回入金に成功しました！',
	DepositConfirmation__NoBonusApplied: '入金ボーナスベットは適用されません。',
	DepositContainer__AddBalance: 'お客様の残高に追加します',
	DepositContainer__RemoveCard: '下4桁が下記の番号のカード情報を削除します',
	DepositForm__Deposit: '入金する',
	DepositForm__ExceededVerification:
		'クレジットカード認証制限回数を変えました。カード認証のためお客様に直接ご連絡させていただきます。',
	DepositForm__Minimum: '10ドルよりご入金いただけます',
	DepositForm__VerifiedMessage: 'このクレジットカードの認証が完了しました。ありがとうございます。',
	DepositReceipt__SuccessMessage: 'ご入金が完了しアカウントに金額が振り込まれました',
	DerivativeSelectionList__Heading: 'スペシャルレーシングマーケット',
	ErrorBoundaryContainer__Message: 'エラーが発生しました。ページを再ロードし、もう一度お試しくださいませ。',
	ErrorBoundaryContainer__MessageBetPrompt: 'エラーが発生しました。ベットスリップページを更新するか再度お試しください',
	ErrorBoundaryContainer__OpenIntercom: 'オープンインターコム',
	ErrorBoundaryContainer__RemountDefault: 'ページを更新できません。カスタマーサポートにお問い合わせしますか？ ',
	ErrorBoundaryDefault__Message: 'エラーが発生しページをロードすることができません。再度お試しになりますか？',
	ErrorPage404__Late: '遅延が生じました',
	ErrorPage404__Message:
		'Odds are you stumbled across this page by typing the wrong thing, or perhaps it has just been',
	ErrorPage404__SubMessage: 'これらをお試しいただくことが可能です',
	ErrorPage404__Title: 'ページが見つかりません',
	ExternalDepositResult__FailMessage: 'ご入金にエラーが生じました。再度お試しくださいませ。',
	ExternalDepositResult__SuccessMessage:
		'ご入金が完了しました。アカウントに金額が反映されるまでしばらくお待ちください。',
	FirstDeposit__AmountError: '金額は100以下である必要があります',
	FirstDeposit__BillingStatements: "取引明細には '{{brandName}}' が記載されます",
	FirstDeposit__Depositing: '入金しています',
	FirstDeposit__DepositingCustom: '指定の金額を入金しています',
	FirstDeposit__Glad: 'ようこそ, {{first_name}}様',
	FirstDeposit__MakeADeposit: '入金する',
	FirstDeposit__PreferAnother: '別の初回入金方法を希望する',
	FirstDeposit__Skip: '初回入金ボーナスを利用しない',
	FirstDeposit__Spend: '利用希望額はいくらですか？',
	Footer__Rights: '全著作権所有・無断転載禁止',
	FooterNote__One:
		'{{brandName}} は、利便性、品質維持・向上を目的に、Cookieを使用しております。詳しくはをプライバシポリシーをご覧ください。同意頂けない場合は、ブラウザを閉じて閲覧を中止してください。',
	FooterNote__Two:
		'{{brandName}} はオーストラリアに登録している {{brandName}} PTY LTD によって運営されています。 {{brandName}} はNT Racing Commissionによってオーストラリア、ノーザンテリトリーのライセンスを取得しており、インターネット上にてレース、スポーツ、エンターテイメントのベットを受け付けることを許可されています。',
	GetStartedBanner__GetStarted: '開始する',
	GetStartedBanner__NotTooLate: '今すぐ始める！',
	GetStartedBanner__StepDeposit: '入金する',
	GetStartedBanner__StepGame: "'始める'",
	HowToPlayMessage__Heading: 'トーナメントへの参加方法',
	HowToPlayMessage__Point1: 'トーナメントチケットを購入する',
	HowToPlayMessage__Point2: '購入チケットでトーナメントにベットする',
	HowToPlayMessage__Quote: '“グッドラック！”',
	HowToPlayMessage__QuoteName: 'Todd',
	HowToPlayMessage__QuoteTitle: 'CEO The BetMakers',
	HowToPlayTournaments__Heading: 'トーナメントでプレイする方法',
	HowToPlayTournaments__Point1: 'お好きなトーナメントを選択して下さい。',
	HowToPlayTournaments__Point3:
		'キャッシュを獲得するか、更なるトーナメントへのチケットを購入するなどし、リーダーボードの上位へランクインしなければなりません。',
	HowToPlayTournaments__SubHeading1: 'トーナメントを選択する',
	HowToPlayTournaments__SubHeading2: 'ベットする',
	HowToPlayTournaments__SubHeading3: 'リーダーボードを確認する',
	JoinLoginElements__GetStarted: '開始する',
	JoinLoginElements__LoginRegister: 'ログイン / 新規登録',
	JoinLoginElements__Username: 'ユーザーネーム / メールアドレス',
	LockedScreen__NotYou: '異なるユーザー情報が表示された場合',
	LockedScreen__SignIn: '別のアカウントでログインする',
	LockedScreenContainer__Error: 'パスワードが間違っています。',
	LoggedUserElements__AddFunds: '資金を追加する',
	Login__Chat: 'アカウントにログインできない場合',
	Login__ForgotPassword: 'パスワードを忘れてしまった場合',
	Login__KeepLoggedIn: 'ログインしたままにする',
	Login__Player: '{{brandName}}のプレイヤーです。',
	Login__UsernameOrEmail: 'ユーザーネーム / メールアドレス',
	LoginContainer__ForgotPassword: 'パスワードを忘れてしまった場合',
	LoginContainer__LoginOrJoin: 'ログイン / 新規登録',
	LoginForPromotions__LoginForPromotions: 'ログインして、プロモーションを獲得する',
	MarketGroupings__NoMarketsMessage: 'ご利用可能なマーケットがありません',
	Masthead__KeepGoing: '次に進む',
	Masthead__LoginError: '入力必須項目に不備があります',
	MastheadContainer__PasswordError: 'こちらのメールアドレスで登録されたユーザーが見つかりません',
	MeetingList__NextRace: '次のレース',
	MeetingListContainer__NoAURaces: 'オーストラリア/ニュージーランドのレースが見つかりません',
	MeetingListContainer__NoInternationalRaces: 'インターナショナルのレースが見つかりません',
	MobileAuthenticationForm__IsValid: 'ありがとうございます。「SMSコードを送信する」をクリックして下さい。',
	MobileAuthenticationForm__SendSMS: 'SMSコードを送信する',
	MobileAuthenticationForm__SMSVerification:
		'SMS認証のため、お客様の電話番号を使用します。別途受信料が発生する可能性があります。',
	MobileAuthenticationForm__SMSVerificationCode:
		'SMSコードを受信するため、お客様の携帯電話番号を入力して下さい。',
	MobileAuthenticationForm__VerifyRealPerson: 'ご本人であることを確認いたします',
	MobileFooter__PuntersClub: 'Punting Club 利用規約',
	MobileVerificationForm__CodeError: 'コードは番号ではありません',
	MobileVerificationForm__CreateAccount: 'アカウントを作成する',
	MobileVerificationForm__DontForget: 'お忘れなく',
	MobileVerificationForm__Step3of3: 'ステップ3',
	MobileVerificationForm__UndoNumber: '番号を取り消す',
	MobileVerificationForm__VerificationCode: '認証コード',
	MobileVerificationForm__Verified: 'ようこそ、 {{first_name}} 様',
	MobileVerificationForm__VerifiedOne: 'お客様の電話番号が認証され、ロボットではないことが確認されました',
	MobileVerificationForm__VerifiedThree: '入金してベットする',
	MobileVerificationForm__VerifiedTwo: '次に何が起こる？',
	MobileVerificationForm__Verify: 'ロボットではないことを確認します',
	MobileVerificationForm__VerifyMobile: '認証コードを利用して電話番号を認証する',
	MobileVerificationForm__VerifyMobileButton: '電話番号を認証する',
	ModalFooter__Message: 'こちらのフォームは暗号化され安全に守られています',
	MoreInfo__Done: 'かしこまりました、ありがとうございます。',
	MoreInfo__Five:
		'その日に獲得した合計 {{typeOfLimitLowerCase}} {{limitTypeFactor}} 勝ち金がお客様のリミットを下回っている場合、そちらのキャッシュにてベットすることが可能です。',
	MoreInfo__Four:
		'オーストラリア東部標準時の夜中0時から翌0時の間、お客様の {{typeOfLimitLowerCase}} リミット内において、ベットやトーナメントへの参加が可能です。',
	MoreInfo__Heading: 'デイリー、ウィークリー、マンスリー {{limitType}} リミット',
	MoreInfo__One:
		'デイリー {{typeOfLimitLowerCase}} リミットとは、24時間の間にこの金額以上のベット {{limitTypeText}} をすることができない事を指します',
	MoreInfo__Seven: 'ウェブサイトへのアクセスを完全にブロックすることを希望する場合、自己除外をすることが可能です。',
	MoreInfo__Six:
		'お客様の {{typeOfLimitLowerCase}} リミットはいつでも下げることができますが、 上げる場合は反映までに7日かかる場合がございます。',
	MoreInfo__Three:
		'マンスリー {{typeOfLimitLowerCase}} リミットとは、30日の期間中この金額以上のベット {{limitTypeText}} をすることができない事を指します',
	MoreInfo__Two:
		'ウィークリー {{typeOfLimitLowerCase}} リミットとは、1週間の間にこの金額以上のベット {{limitTypeText}} をすることができない事を指します',
	MultiBetContainer__Balance: '残高',
	MultiBetContainer__BetSlip: 'ベットスリップ',
	MultiBetContainer__EmptyOne: 'ベットスリップには何もありません',
	MultiBetContainer__EmptyTwo: '追加したベットはこちらに表示されます',
	MultiBetContainer__ExceedMessage: 'ベット額が残高を上まわっています',
	MultiBetContainer__InvalidSelections: 'マルチベットに有効ではないベットを選択しています。シングルベットは可能です。',
	MultiBetContainer__KeepBets: 'ベットをキープする',
	MultiBetContainer__OddsChange: 'オッズが {{oldPrice}} から {{newPrice}} へと変わりました',
	MultiBetContainer__StruckWarning: 'このまま続けると正常にベットされます',
	MultiBetSelectionList__AllSingles: 'シングルに追加する',
	MultiBetSummary__Title: 'ベットサマリー',
	MyTournaments__LoginMessage: '参加したトーナメントを見るにはログインして下さい',
	MyTournaments__NotEntered: 'お客様はどのトーナメントにも参加していません',
	NextToJumpCard__Upcoming: '最近のものはありません',
	NextToJumpRaceDetails__View: '全てのレースカードを見る',
	NextToJumpTournamentDetails__EnterTournament: 'トーナメントに参加する',
	NextTournamentContainer__NoTournamentsMessage: '最近のトーナメントはありません',
	PasswordResetContainer__Error: 'こちらのメールアドレスで登録されたアカウントは見つかりません。',
	PasswordResetMessage__CreateAccount: '{{brandName}} アカウントを作成する.',
	PasswordResetMessage__New: '初めてのご利用ですか？',
	PasswordResetMessage__Oops: '思い出しました',
	PasswordResetRequestSuccess__Inbox: '受信ボックスを確認して下さい',
	PasswordResetRequestSuccess__Instructions:
		'ご登録のメールアドレスにパスワード再設定用のインストラクションを送信しました',
	PasswordResetRequestSuccess__Success: 'Eメールが送信されました',
	PINAuthentication__Error: 'PINコードがマッチしません',
	PINAuthenticationContainer__ForgotPin: 'PINコードをお忘れですか？',
	PINAuthenticationContainer__Message: 'PINコードは簡単にログインをするための4桁の数字です',
	PINAuthenticationContainer__NotificationSuccess: 'こちらのデバイスでPINコードがセットアップされました',
	PINAuthenticationContainer__Password: 'ログインするためにはパスワードを使用して下さい',
	PINAuthenticationContainer__RepeatMessage: 'セットアップを完了させるため再度PINコードを入力してください',
	PlayerBetsHomePage__Description: 'プレーヤーのパフォーマンスを閲覧しベットする',
	PlayerBetsHomePage__Title: 'プレイヤーパフォーマンスとスポーツベット',
	PlayerProfileBets__BetsPlaced: 'ベットされました',
	PlayerProfileSummary__AverageBet: 'アベレージベット',
	PlayerProfileSummary__BettingPerformance: 'ベッティングパフォーマンス',
	PlayerProfileSummary__WinRatio: '勝率',
	PoliForm__Title: '{{poli}}で入金する',
	Profile__Address: '住所',
	Profile__AddressInformation: '住所情報',
	Profile__Birthday: '生年月日',
	Profile__Country: '都道府県',
	Profile__FirstName: '名',
	Profile__FormDisabledMessage:
		'アカウント情報の変更をご希望の場合、弊社カスタマーサポートまでお気軽にお問い合わせくださいませ。',
	Profile__LastName: '姓',
	Profile__MobileNumber: '電話番号',
	Profile__Postcode: '郵便番号',
	Profile__State: '国',
	Profile__Suburb: '市町村',
	Profile__Title: '個人情報',
	PromoAlert__Title: 'プロモーション',
	Promotions__BetNow: '今すぐベットする',
	Promotions__CurrentPromotions: '現在ご利用可能なプロモーション',
	Promotions__NoArticles: '情報がありません.',
	Promotions__NoPromotions: 'ご利用可能なプロモーションがありません',
	Promotions__PromotionUnavailable: 'ご希望のプロモーションは現在ご利用いただけません。',
	QuickBettingSummaryContainer__Title: 'クイックベッティングサマリー',
	RaceCard__EarlySpeed: 'アーリースピード',
	RaceCard__Form: 'フォーム',
	RaceCard__HideMyBets: '自分のベットを非表示にする',
	RaceCard__OnSettling: '確定待ちです',
	RaceCard__RaceOverview: 'レース概要',
	RaceCard__ShowMyBets: '自分のベットを表示する',
	RaceCard__Speedmaps: 'スピードマップ',
	RaceProductLegend__AllExotics: 'オールエグゾチック',
	RaceProductLegend__PleaseNote: '注意して下さい',
	RaceSelectionsList__Flucs: 'Flucs',
	RaceSelectionsList__GoatLength: 'GOAT + 頭身',
	RaceSelectionsList__MarginMessage: 'GOAT 着差・{{goatLength}}頭身を二着目に追加する',
	RaceSelectionsList__PlaceBet: 'ベットする',
	RaceSelectionsList__PoolTotals: 'プールテーブル',
	RacingDateSelection__Futures: '明日以降',
	RacingHomePage__Description: '今すぐオンラインで競馬、ドッグレース、ハーネスにベットする',
	RacingHomePage__DescriptionBonus: '新規プレイヤーの方にはボーナスベット',
	RacingHomePage__Next: '次へ',
	RacingHomePage__Races: 'レース',
	RacingHomePage__Racing: 'レース',
	RacingHomePage__Title: '競馬ベット、オッズと結果',
	Receipt__DepositMethod: '入金方法',
	RegistrationConfirmation__Heading: 'お客様のアカウントを作成しています！',
	RegistrationConfirmation__Step1: '1/3: アカウント作成',
	RegistrationConfirmation__Step2: '2/3: アカウント設定',
	RegistrationConfirmation__Step3: '3/3: ほぼ完了です',
	RegistrationHeader__CreateAccount: 'アカウントを作成する',
	RegistrationPage__AdultsOnly: '18歳以上の利用のみです',
	RegistrationPage__BettingLimitError:
		'恐れ入りますがベットの制限を設定する際にエラーが発生しました。また後ほどお試しくださいませ。',
	RegistrationPage__DepositLimitError:
		'恐れ入りますが入金額の制限を設定する際にエラーが発生しました。また後ほどお試しくださいませ。',
	RunnerForm__RunnerComments: 'ランナーのコメント',
	SelfExclusionConfirmation__ChangedMind: '気が変わりました',
	SelfExclusionConfirmation__DontLike: 'ウェブサイトが好きではない',
	SelfExclusionConfirmation__GamblingProblem: 'ギャンブルにおける問題',
	SelfExclusionConfirmation__InsertComment: 'コメントを残してください...',
	SelfExclusionConfirmation__Others: 'その他',
	SelfExclusionPrompt__Account: 'アカウントにログインすることや新たにアカウントを作成することはできません',
	SelfExclusionPrompt__Confirmation: '永久的な自己除外に関する確認メールがご登録のメールアドレスに送信されます',
	SelfExclusionPrompt__Exclude: '自己除外を設定する',
	SelfExclusionPrompt__Marketing: '24時間以内にメールマガジンを受け取ることをやめます。',
	SelfExclusionPrompt__Pending: 'All pending bets will stand.',
	SelfExclusionPrompt__Proceed: 'もし続けた場合、何が起きますか？',
	SelfExclusionPrompt__PuntingClub: 'あなたはパンティング・クラブの全てのアクティビティーより消去されます。',
	SelfExclusionPrompt__StandingBalance:
		'未確定のベットの結果が確定されると、残高は自動的に最後にご利用になった出金方法で出金されます。',
	SelfExclusions__Others: 'その他',
	SelfExclusions__Reason: '賭け事に関する問題',
	SetLimit__24HourDay: '1日24時間',
	SetLimit__30Day: '30日間のピリオド',
	SetLimit__7Day: '7日間のピリオド',
	SetLimit__AdjustLimitMessage: '下記の {{period}} {{limitTypeLowerCase}} 制限を調整する',
	SetLimit__InputLabel: '新しい {{periodUpperCase}} {{limitType}} 上限の量',
	SetLimit__LoseAmount: '{{changePeriod}}でこれ以上の金額を失うことはできません。',
	SetLimit__LowerLimit:
		'{{limitTypeLowerCase}}制限はいつでも引き下げることができますが、引き上げると7日後に有効になります。',
	SetLimit__MoreInfo: 'もっと情報を見る',
	SetLimit__NotSet: '設定されていません',
	SetLimit__PendingLimitMessage: '未確定の {{limitTypeLowerCase}} の上限は:',
	SetLimit__RemoveLimit: '上限をなくす',
	SetLimit__Support: 'チームをサポートする',
	SetLimit__UpdateLimit: '上限を更新する',
	SetTimer__Alert: 'リアリティチェックに達した場合、自動的に警告します。',
	SetTimer__Check: 'ご希望の場合はチェックを変更することができます。',
	SetTimer__SupportTeam: 'Support Team',
	SetTimer__Update: 'リアリティチェックを更新する',
	SetTimer__Updated: 'お客様のリアリティチェックが更新されました。',
	SidebarContainer__MenuOptions: 'メニューオプション',
	SidebarSettings__NT: '次のトーナメントを見る',
	SidebarSettings__NTJ: 'ジャンプして次を表示する',
	SidebarSettings__NTP: 'プレイして次を表示する',
	SidebarSettings__Save: 'オプションを保存する',
	SimpleRegistration__HaveAccount: 'すでにアカウントをお持ちですか？',
	SimpleRegistration__Header: '登録フォーム',
	SimpleRegistration__SignIn: 'ログインする',
	SimpleRegistration__SignUpNow: '登録する',
	SimpleRegistration__YourDetails: '登録情報',
	SpeedmapHeader__Barrier: 'バリア',
	SpeedmapHeader__Lead: 'リード',
	SportsHomePage__LiveBetsHeading: 'ライブベット',
	SportsHomePage__NoBetsMessage: 'ベット中のマーケットはありません',
	SportsHomePage__NoBettingMessage: '現在このイベントへのベットは無効です。',
	SportsHomePage__NoMarketsMessage: 'オープンしているマーケット上にベット可能なイベントはありません。',
	SwapOddsButton__Text: 'オッズを交換する',
	TakeABreak__1Day: '1 日',
	TakeABreak__Long: '大休憩',
	TakeABreak__Short: '小休憩',
	TakeABreak__TakeBreak: ' {{breakLabel}} 休憩する',
	TakeABreakConfirmation__ChangedMind: '気が変わりました',
	TakeABreakConfirmation__Title: ' {{breakLabel}} 休憩する',
	TakeABreakLong__1YearLabel: '1年',
	TakeABreakLong__1YearValue: '1年休憩',
	TakeABreakLong__6MonthsLabel: '6か月',
	TakeABreakLong__6MonthsValue: '6か月休憩',
	TicketHeader__QualifiedMessage: '資格を得ました',
	TicketHeader__RemainingTurnoverMessage: '資格を得るためにターンオーバーを継続する',
	TicketPurchase__MaxExceededMessage: '最大数を超えました',
	TimerHelpSection__SessionTime: 'セッションタイム',
	TournamentBets__NoBets: 'このイベントへのベットはありません',
	TournamentBets__OnThisEvent: 'このイベント',
	TournamentBets__OtherUnresulted: 'Other Unresulted',
	TournamentBets__ViewMore: 'もっとトーナメントのベットを見る',
	TournamentEventItem__NoMarketsMessage: 'まだマーケットへのベットは開始されていません',
	TournamentHeaderFooter__NoRanking: 'ランキングがありません',
	TournamentInsights__BackedWinner: 'backed the winner.',
	TournamentInsights__Loser: '申し訳ございません。お客様は見逃しました。',
	TournamentInsights__Title: 'トーナメント内容',
	TournamentInsights__Winner: 'おめでとうございます！勝ちです！',
	TournamentPrizePool__Title: 'トーナメント賞金プール',
	TournamentsHomePage__NoTournamentsMessage: '申し訳ございませんが、検索結果に該当するトーナメントはございません。',
	TournamentsHomePage__Previous: '以前トーナメントに参加しました。',
	TournamentsHomePage__Title: 'ファンタジーレースとスポーツベットトーナメント',
	TournamentSummary__PrizePoolSubText: '1位の栄光を掴むためにプレイする',
	TrendingBets__Heading: '人気のベット',
	TrendingBets__NoBetsMessage: '人気のベットはありません',
	TrendingTournaments__NoTournamentsMessage: '人気のトーナメントはありません',
	TrendingTournaments__ViewMore: '人気のトーナメントをもっと見る',
	UnitStake__BetReceipt: 'ベットのレシート',
	UnitStake__PendingReview: 'Pending Review',
	UnitStake__ViewDetails: 'ベットの詳細を見る',
	UnitStake__ViewSelections: 'セレクションを見る',
	UpdatePassword__ConfirmationError: '新しいパスワード一致しなければなりません',
	UpdatePassword__ConfirmPassword: '新しいパスワードを確認する',
	UpdatePassword__EnterCurrent: '現在のパスワードを入力する',
	UpdatePassword__EnterEmail: 'メールアドレスを入力する',
	UpdatePassword__EnterNew: '新しいパスワードを入力する',
	UpdatePassword__GenericError: 'エラーが発生しました。ヘルプデスクにお問い合わせください。',
	UpdatePassword__NewPassword: 'お客様の新しいパスワード',
	UpdatePassword__OldPassword: '現在のパスワードを入力する',
	UpdatePassword__PasswordUpdate: 'パスワードが更新されました',
	UpdatePassword__SetNew: '新しいパスワードを設定する',
	UpdatePassword__ShouldMatch: 'パスワードが一致します',
	UpdatePassword__Step: 'ステップ{{step}}.',
	UserAccountMenu__ManageAccount: 'アカウントを管理する',
	UserAccountPage__AccountProfile: 'アカウントのプロフィール',
	UserAccountPage__CancelWithdrawal: '出金をキャンセルする',
	UserAccountPage__DefaultBetAmounts: 'ベットのデフォルト額を設定する',
	UserAccountPage__DepositReceipt: '入金のレシート',
	UserAccountPage__MakeDeposit: '入金する',
	UserAccountPage__ManageAccount: 'アカウントを管理する',
	UserAccountPage__ResetPassword: 'パスワードをリセットする',
	UserAccountPage__SelfExclusion: '自己除外',
	UserAccountPage__SetDepositLimits: '入金上限額を設定する',
	UserAccountPage__SetLossLimits: '損失上限額を設定する',
	UserAccountPage__SetSpendLimits: '利用金額の制限を設定する',
	UserAccountPage__TakeABreak: '休憩する',
	UserAccountPage__VerifyCC: 'クレジットカードを認証する',
	UserAddressForm__Address: '住所',
	UserAddressForm__AddressMessage: 'お客様のご氏名・ご住所を登録してください',
	UserAddressForm__ControlLimits: '入金とベット上限をコントロールしますか？',
	UserAddressForm__DepositLimit: '入金上限額 ($)',
	UserAddressForm__EnterManually: '手動で住所を入力する',
	UserAddressForm__FirstName: '名',
	UserAddressForm__FirstNameConstraint: '^名をアルファベットで入力してください',
	UserAddressForm__GambleResponsibly: '賭け事に関する責任：',
	UserAddressForm__GoBack: '戻る',
	UserAddressForm__LossLimit: '損失上限額 ($)',
	UserAddressForm__Outside: 'オーストラリア外',
	UserAddressForm__PlayersNotice: 'プレーヤーへの予告',
	UserAddressForm__SetLimits:
		'お客様のアカウントを管理するためにベットしたい額や入金額の制限を設定してください。ご登録が’完了後、これらの設定はお客様のアカウントよりお好みにより変更可能です。',
	UserAddressForm__Step2of3: '3ステップ中2ステップ',
	UserAddressForm__Surname: '姓',
	UserAddressForm__SurnameConstraint: '^姓をアルファベットで入力してください',
	UserAddressForm__WeeklyLimits: '1週間の制限を設定する',
	UserDetailsForm__18Plus: '18+',
	UserDetailsForm__AdultsOnly: '成人のみ',
	UserDetailsForm__DateOfBirth: '生年月日',
	UserDetailsForm__Email: 'メールアドレス（ログイン時に使用します）',
	UserDetailsForm__HaveAnAccount: 'アカウントをお持ちですか？',
	UserDetailsForm__Password: 'パスワード（数字を一つ以上含む6文字以上の英数字）',
	UserDetailsForm__StartAccount: '{{brandName}}アカウントを始める',
	UserDetailsForm__Step1of3: '3ステップ中1ステップ',
	UserDetailsForm__Terms:
		'新規アカウントを作成するにあたり、規約と条件、プライバシーポリシーおよび{{age}} 歳以上であることい同意します。成人でない場合、賭け事をするのは違反です。',
	UserDetailsForm__Username: 'ユーザーネーム（6文字以上の英数字）',
	UserTransactionsContainer__FilterAll: '全ての取引',
	UserTransactionsContainer__FilterBets: 'ベットされました',
	UserTransactionsContainer__FilterLosing: '賭けに負けています',
	UserTransactionsContainer__FilterRefunded: 'ベットをリファンドする',
	UserTransactionsContainer__FilterTournaments: 'トーナメント参加／ベット',
	UserTransactionsContainer__FilterWinning: '勝利ベット',
	UserTransactionsContainer__Last: '最後の{{days}}',
	UserTransactionsContainer__OpenBets: 'ベットを開く',
	UserTransactionsContainer__Title: 'アカウントダッシュボード',
	UserTransactionsContainer__UnableToLoadDashboard: 'アカウントダッシュボードを読み込むことが’できません',
	UserTransactionsContainer__UnableToLoadTransactions: 'アカウント取引を読み込むことができません',
	VerificationStatus__Complete: '認証を完了する',
	VerificationStatus__Tasks: '{{to}} の認証作業のうち{{from}} を完了しました。verification tasks.',
	VerificationStatus__Title: '認証ステータス',
	VerificationStatus__Why: 'なぜ私の情報を認証する必要があるのですか？',
	VerifyCardExplanation__AccountVerified:
		'この金額はクレジットカード認証が完了しましたら、お客様のアカウントに振り込まれます。',
	VerifyCardExplanation__AreAuthorising: ' {{brandName}}を許可しています',
	VerifyCardExplanation__CentValue:
		'クレジットカードを認証するには、クレジットカードの明細から認証のために請求された金額を確認し、次回入金時に入力する必要があります。',
	VerifyCardExplanation__Done: '確認されました。ありがとうございます。',
	VerifyCardExplanation__GiveAuthorisation: '許可する',
	VerifyCardExplanation__MustBeVerified: '出金申請を行う前に、クレジットカードの認証を完了しなければなりません。',
	VerifyCardExplanation__Questions:
		'質問等がございましたら、弊社のカスタマーサービスチームまでお問い合わせくださいませ。',
	VerifyCardExplanation__VerificationProcess:
		'認証手続きを行うには、{{authorizationMessage}}に入金金額に1〜99の範囲でランダムな額を追加してください。',
	VerifyCardExplanation__VerifyingCreditCard: 'クレジットカードを認証しています',
	WithdrawalPromptContainer__Add: '新しいアカウントを追加する',
	WithdrawalPromptContainer__Amount: '出金額',
	WithdrawalPromptContainer__Credit: 'クレジットの銀行口座',
	WithdrawalPromptContainer__MaxBalance: 'お客様の出金可能最大額は',
	WithdrawalPromptContainer__PendingNotFound: '保留中の出金は確認されていません。',
	WithdrawalPromptContainer__ProcessedIn: '全ての出金を処理しています',
	WithdrawalPromptContainer__RemoveBankAccountMessage: 'お客様のアカウントよりこの銀行口座を削除しようとしています。',
	WithdrawalPromptContainer__VerifyMessage:
		'出金申請を提出する前に、全てのクレジットカードを認証しなければなりません。',
	WithdrawalPromptContainer__WantTo: '出金する',
	WithdrawalReceipt__RequestSuccess: '申請を確認いたしましたので、営業時間中に処理させていただきます。',
	WithdrawalReceipt__RequestSuccessHeader: '出金申請が成功しました',
	YouQualifyAlert__Deposit: '*しかし初回入金ボーナスでのベットは対象外です',
	YouQualifyAlert__Qualify: 'プロモーション資格を獲得しました',
	Margin: 'マージン',
	MARGIN: 'MARGIN',
	'AU/NZ': 'オーストラリア/ニュージーランド',
	'Bet Receipt': 'ベット確認',
	'Quick Deposit': '簡単入金',
	'Quick Betting Summary': 'クリックベッティング概要',
	'Credit Card': 'クレジットカード',
	'1 Day': '一日',
	'6 Months': '半年',
	'PIN Authentication': 'PIN認証',
	'is the wrong length (should be %{count} characters)': 'is the wrong length ( %{count} 文字にする必要があります。)',
	'is too long (maximum is %{count} characters)': '文字が長すぎます。 (最高%{count}文字まで入力可能です。)',
	'is too short (minimum is %{count} characters)':
		'文字数が足りません。 (最低%{count}文字以上入力する必要があります。)',

	////////////////
	// All plural definitions
	Bet: 'ベット',
	BonusBet: 'ボーナスベット',
	Day: '日',
	market: 'マーケット',
	Month: '月',
	Player: 'プレイヤー',
	Rebuy: '再購入',
	Runner: 'ランナー',
	Week: '週',
	Winner: '勝者',
	Year: '年',
	CompetitionsList__BetCount: '{{count}} ベット',
	MarketGroupItem__NumberOfMarkets: '{{count}} のマーケットが利用可能',
	SetTimer__Hour: '{{count}} 時間',
	SetLimit__IncreaseLimit: '<0>Note:</0> <2>{{count}}</2> 日後にリミット上限額を上げることが可能です。.',

	////////////////
	// Remaining translations that use the <Trans> component
	TakeABreakLong__Content: `
		<0>
			長らくご利用を中止されていた場合のご対応について：
		</0>
		<1>
			保留中のベットは全て、現在も有効となります。
		</1>
		<2>
			保留中のベットが確定されたら、前回利用した出金方法と同様の方法で自動的に残高をお引き出しいたします。
		</2>
		<3>
			マーケティングに関するお知らせの配信を24時間以内に停止します。
		</3>
		<4>
			全てのPunting
		</4>
		<5>
			ご利用中期間が終了した場合、アカウントを再認証するためカスタマーサポートへ問い合わせる必要があります。
		</5>
		<6>
			どれくらいの間、ご利用を中止されますか？
		</6>
	`,
	TakeABreakShort__Content: `
		<0>
			しばらくの間ご利用を中止されていた場合のご対応について：
		</0>
		<1>
			保留中のベットは全て、現在も有効となります。
		</1>
		<2>
			アカウントの残高はそのままとなります。お引き出しを希望される場合は、カスタマーサポートまでお問い合わせくださいませ。
		</2>
		<3>
			マーケティングに関するお知らせの配信を24時間以内に停止します。
		</3>
		<4>
			ご利用中止期間を過ぎますと再びアカウントにログインすることが可能となります。
		</4>
		<5>
			どれくらいの間、ご利用を中止されますか？
		</5>
	`,
	TakeABreakConfirmation__Long: `
		<0>
			以下であることに同意します：
		</0>
		<1>
			保留中のベットはそのまま有効な状態となります。
		</1>
		<2>
			保留中のベットが確定されたら、前回利用した出金方法と同様の方法で金額が残高からお引き出しされます。
		</2>
		<3>
			マーケティングに関するお知らせの配信を24時間以内に停止します。
		</3>
		<4>
			こちらはすぐに反映され、やり直すことはできません。
		</4>
		<5>
			期限が終了した場合、アカウントを再認証するためカスタマーサポートへ問い合わせる必要があります。
		</5>
	`,
	TakeABreakConfirmation__Notification: '<0>Note:</0> You will be logged out immediately.',
	TakeABreakConfirmation__Short: `
		<0>
			以下であることに同意します：
		</0>
		<1>
			保留中のベットはそのまま有効な状態となります。
		</1>
		<2>
			残高は変わりません。
		</2>
		<3>
			マーケティングに関するお知らせの配信を24時間以内に停止します。
		</3>
		<4>
			今後、自身のアカウントでベットすることはできなくなります。
		</4>
		<5>
			今後新たにアカウントを作成することはできません。
		</5>
		<6>
			こちらはすぐに反映され、やり直すことはできません。
		</6>
	`,
	SelfExclusionConfirmation__Content: `
		<0>
			以下であることに同意します：
		</0>
		<1>
			保留中のベットはそのまま有効な状態となります。
		</1>
		<2>
			保留中のベットが確定次第、前回利用した出金方法と同様の方法で金額が残高からお引き出しされます。
		</2>
		<3>
			今後、自身のアカウントでベットすることはできなくなります。
		</3>
		<4>
			今後新たにアカウントを作成することはできません。
		</4>
		<5>
			こちらはすぐに反映され、やり直すことはできません。
		</5>
		<6>
			お困りのことがございましたら、お問い合わせくださいませ。
		</6>
	`,
	RacingDateSelection__Tomorrow: '明日',
	RacingDateSelection__Yesterday: '昨日',
	LoginForPromotions__LatestSpecials:
		'最新の情報を確認する場合、<1>新規登録</1>或いはマイアカウントページへ<3>ログイン</3>を行ってください。',
	SetTimer__RealityCheck: '{{first_name}}様、お客様のご利用に関する<2>最新アップデート</2> は:',
	SelfExclusionPrompt__Notification:
		'<0>注意:</0> 自己除外をご希望の場合、お客様のアカウントは<4>永久的に</4>凍結されますのでご注意ください。',
	BankEFTDeposit__Notification:
		'<0>注意: </0>ご入金確認へ記載されているお客様のレースブック専用ユーザーネーム(<2>{{username}}</2>) 、或いは{{brandName}}にてご登録されているユーザーネーム(<6>{{userId}}</6>)をご確認ください。',
	BPayDeposit__Notification:
		'<0>注意: </0>お支払いを完了するには、お客様がご利用されているクレジットカード会社もしくはペイメント会社まで、ご連絡をお願い致しております。',
	HowToPlayMessage__SignupMessage:
		'<0>ご利用されるには、</0> <2>{{brandName}} </2> <4>にてアカウントの新規登録をお済ませ下さい。</4>',
	HowToPlayTournaments__Point2:
		'<0>登録が完了すると、<1>{{brandName}}</1>からアカウント情報が送信されますので、ご確認下さいませ。また、トーナメントに勝利する確率を上げるには、<3>{{brandName}}</3>にてお客様のベットを確定して下さい。</0>',
	PasswordResetRequest:
		'<0>パスワードが間違っています</0><1>入力されたパスワードを確認して下さい</1><2>もしくは、新しいパスワードを設定して下さい</2><3>簡単にパスワードを変更できます</3><4>ご登録メールアドレスを入力して下さい</4><5>メールアドレス</5><6>リセットメールを送信する</6>',
	SelfExclusionConfirmation__Notification:
		'<0>注意:</0> こちらが完了次第、直ちにお客様のアカウントは凍結されますのでご注意ください。',
	CreditCardVerification__Example:
		'ご入金金額が以下の場合、小数点以下の数字もご入力ください。（例）$50.43 - 43を入力する。<1></1>$50.07 - 7を入力する。',
	DefaultBetAmounts__BetAmountMessage: 'お客様が設定された最新の<1>ベット額</1>は:',
	DefaultBetAmounts__TournamentBetAmountMessage: 'また、初期設定の<1>トーナメントベット額</1> は:',
	FirstDeposit__TermsAndConditions: '<1>利用規約</1>に同意する',
	MobileVerificationForm__Message:
		'四文字の認証コードを<1>{{mobNumber}}</1>へ送信しました。認証コードを入力し、ご登録番号の認証確認を行ってください。',
	MobileVerificationForm__Terms: '<1>利用規約</1>に同意する',
	SetLimit__BlockAccess:
		'こちらのサービスのご利用停止をご希望の場合、<2>自己除外</2>サービスにて利用停止手続きが可能です。',
	SimpleRegistration__TermsAndConditions: '<1>利用規約</1>に同意する',
	VerifyCardExplanation__WholeDollars: 'こちらのご入金は、<1>ドル</1>のみご利用可能です。',
};
