

const initialState = {
    logedCount: 120,
    active: false,
};

const realityHoursReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SESSION_TIME_STOP':
            return {
                ...state,
                active: state.active = true
            };
        case 'IACTIVITY_TIME_STOP':
                return {
                    ...state,
                    active: state.active = true
                };
        case 'SESSION_TIME_START':
            return {
                ...state,
                logedCount: state.logedCount + 120,
                active: state.active = false
            };
        default:
            return state;
    }
};

export default realityHoursReducer;