// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

// Components
import {
	spacings,
	Button,
	Input,
	InputGroup,
	Link,
	PlotElements,
	RadioButton,
	Text,
	Select,
	SelectOption,
} from '@tbh/ui-kit';
// import AutoCompleteAddress from '../AutoCompleteAddress/AutoCompleteAddress';
import AdditionalAddressFields from './AdditionalAddressFields';

// Constants
import { USER_DETAILS_FORM } from '../StepsContants';
// import { AutoCompleteAddressNew } from '../AutoCompleteAddressNew/AutoCompleteAdressNew';
import AutoCompleteAddress from '../AutoCompleteAddress/AutoCompleteAddress';

const StyledUserAddressForm__Actions = styled(PlotElements)(
	(props) => css`
		label: UserAddressForm__Actions;

		margin-top: ${spacings(props).cozy}px;
	`,
);

const ManuallyText = styled('span')`
	text-decoration: underline;
	font-weight: 700;
`;

const StyledUserAddressForm__PlayersNotice = styled('div')(
	(props) => css`
		label: UserAddressForm__PlayersNotice;

		margin-top: ${spacings(props).cozy}px;
	`,
);

const StyledUserAddressForm__LimitsOptions = styled('div')(
	(props) => css`
		label: UserAddressForm__LimitsOptions;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledUserAddressForm__ControlLimits = styled(RadioButton)(
	(props) => css`
		label: UserAddressForm__ControlLimits;

		margin-right: ${spacings(props).cozy}px;
	`,
);

class UserAddressForm extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Action to go to a different step in the registration process */
		goToStep: PropTypes.func.isRequired,

		/** Action to create the users' account */
		createAccount: PropTypes.func.isRequired,

		/** Updates state when changes are made */
		onChange: PropTypes.func.isRequired,

		/** Bulk updates to state when changes are made */
		onBulkChange: PropTypes.func.isRequired,

		/** Set the deposit and loss limits */
		setLimits: PropTypes.func.isRequired,

		/** First and Last name can be pre-populated if Facebook was used to register */
		first_name: PropTypes.string,
		last_name: PropTypes.string,

		/** Users' address details */
		city: PropTypes.string,
		country: PropTypes.string,
		postcode: PropTypes.string,
		state: PropTypes.string,
		street: PropTypes.string,
		address: PropTypes.string,
		postal: PropTypes.string,
		fullstreet: PropTypes.string,
		/** Extra classes */
		className: PropTypes.string,

		/** User controlled deposit limit */
		deposit_limit: PropTypes.string,

		/** Whether or not the deposit limit is valid */
		deposit_limit_valid: PropTypes.bool,

		/** Whether or not the form is valid */
		form_is_valid: PropTypes.bool,

		/** Error if the city fails validation */
		city_error: PropTypes.string,

		/** Error if the first name fails validation */
		first_name_error: PropTypes.string,

		/** Error if the last name fails validation */
		last_name_error: PropTypes.string,

		/** User controlled loss limit */
		loss_limit: PropTypes.string,

		/** Whether or not the loss limit is valid */
		loss_limit_valid: PropTypes.bool,

		/** Error if the postcode fails validation */
		postcode_error: PropTypes.string,

		/** Error if the street fails validation */
		street_error: PropTypes.string,

		/** Is mobile verification being used */
		mobileVerification: PropTypes.bool,

		/** Is address verification being used */
		addressVerification: PropTypes.bool,

		/** Shows the loading mask on the Create Account button */
		loadingCreateAccount: PropTypes.bool,
	};

	static defaultProps = {
		city_error: null,
		className: null,
		deposit_limit: '',
		deposit_limit_valid: false,
		title: 'Mr',
		first_name: '',
		first_name_error: null,
		form_is_valid: false,
		last_name: '',
		last_name_error: null,
		loss_limit: '',
		loss_limit_valid: false,
		street_error: null,
		postcode_error: null,
		street: '',
		fullstreet: '',
		postal: '',
		address: '',
		city: '',
		country: 'Australia',
		state: 'NSW',
		postcode: '',
		mobileVerification: true,
		addressVerification: false,
		loadingCreateAccount: false,
	};

	state = {
		address: this.props.address,
		showAdditionalAddressFields: false,
		control_limits: null,
	};

	/**
	 * Updates the state if the new prop for form_is_valid
	 * is different from the current state
	 *
	 * @param nextProps
	 * @param prevState
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			form_is_valid: nextProps.form_is_valid,
		};
	}

	/**
	 * Handles changes in the inputs.
	 * Validates the form on every change.
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleChange = (name, value, valid) => {
		if (name === 'country') {
			this.props.onBulkChange({
				[name]: value,
				state: value === 'Australia' ? 'NSW' : this.props.t('UserAddressForm__Outside'),
			});
		} else {
			this.props.onChange(name, value, valid);
		}
	};

	/**
	 * Goes back to the previous step of the registration
	 */
	goToStep = () => {
		this.props.goToStep(USER_DETAILS_FORM);
	};

	/**
	 * Goes to the next step of the registration
	 * And set limits if there is one
	 */
	nextStep = () => {
		// this.props.goToStep(SET_LIMITS);

		// if (this.state.control_limits === 'yes') {
		// 	this.props.setLimits(this.props.deposit_limit * 100, this.props.loss_limit * 100);
		// }
		this.props.createAccount();
	};

	/**
	 * Shows the additional address form fields
	 */
	showAdditionalAddressFields = () => {
		this.setState(
			{
				address: this.props.address,
				showAdditionalAddressFields: true,
			},
			() => {
				//this.props.onChange('street', "556", true);
			},
		);
	};

	/**
	 * Hides the additional address form fields and resets the address
	 */
	closeAdditionalAddressFields = () => {
		this.setState({
			showAdditionalAddressFields: false,
			address: this.props.street,
		});
	};

	/**
	 * Handles changes when clicking on the radio buttons
	 *
	 * @param event
	 */
	onRadioChange = (event) => {
		this.setState({
			control_limits: event.target.value,
		});
	};

	// /**
	//  * Sets the address when selecting an address from the AutoCompleteAddress
	//  *
	//  * @param address
	//  */
	// onSelect = (address) => {
	// 	if (address.postal === '') {
	// 		this.setState(
	// 			{
	// 				showAdditionalAddressFields: false,
	// 				street: `${address.streetNumber} ${address.street}`,
	// 			},
	// 			() => {
	// 				this.props.onBulkChange({
	// 					street: `${address.streetNumber} ${address.street}`,
	// 					city: address.locality,
	// 					country: 'Australia',
	// 					state: address.state,
	// 					postcode: address.postcode,
	// 				});
	// 			},
	// 		);
	// 	}
	// 	else {
	// 		this.setState(
	// 			{
	// 				showAdditionalAddressFields: false,
	// 				street: `${address.postal}`,
	// 			},
	// 			() => {
	// 				this.props.onBulkChange({
	// 					street: `${address.postal}`,
	// 					city: address.locality,
	// 					country: 'Australia',
	// 					state: address.state,
	// 					postcode: address.postcode,
	// 				});
	// 			},
	// 		);
	// 	}

	// };

	/**
	 * Sets the address when selecting an address from the AutoCompleteAddress
	 *
	 * @param address
	 */
	onSelect = (address) => {
		if (address.street_number) {
			this.setState(
				{
					showAdditionalAddressFields: false,
					street: `${address.streetNumber} ${address.streetName}`,
				},
				() => {
					this.props.onBulkChange({
						unit_type: address.flatUnitType,
						unit_number: address.flatUnitNumber,
						street: `${address.streetName}`,
						street_number: address.streetNumber,
						street_type: address.streetType,
						city: address.locality,
						country: 'Australia',
						state: address.state,
						postcode: address.postcode,
					});
				},
			);
		} else {
			this.setState(
				{
					showAdditionalAddressFields: false,
					street: `${address.street}`,
				},
				() => {
					this.props.onBulkChange({
						unit_type: address.flatUnitType,
						unit_number: address.flatUnitNumber,
						street: `${address.streetName}`,
						street_number: address.streetNumber,
						street_type: address.streetType,
						city: address.city,
						country: 'Australia',
						state: address.state,
						postcode: address.postcode,
					});
				},
			);
		}
	};

	validForm = () => {
		const { first_name, last_name, street, street_number, street_type, city, postcode, country } = this.props;

		if (first_name && last_name && street && street_number && street_type && city && postcode && country) {
			return true;
		}
		return false;
	};

	render() {
		const {
			t,
			title,
			first_name,
			last_name,
			street,
			street_number,
			street_type,
			unit_type,
			unit_number,
			fullstreet,
			city,
			state,
			country,
			postcode,
			first_name_error,
			last_name_error,
			street_error,
			street_number_error,
			street_type_error,
			unit_type_error,
			unit_number_error,
			city_error,
			postcode_error,
			form_is_valid,
			deposit_limit,
			deposit_limit_valid,
			loss_limit,
			loss_limit_valid,
			className,
			mobileVerification,
			createAccount,
			loadingCreateAccount,
		} = this.props;

		const { showAdditionalAddressFields, control_limits, address } = this.state;

		const constraints = {
			value: {
				// must be a number
				numericality: {
					// must be greater than or equal to 0
					greaterThanOrEqualTo: 0,
				},
			},
		};

		// used for disabling the 'next step' button
		let invalid = !form_is_valid;
		// if (state === 'SA' || country === 'United Kingdom') {
		// 	// for users from UK or South Australia
		// 	// if the limits option is not selected
		// 	if (
		// 		!control_limits ||
		// 		(control_limits === 'yes' && (!deposit_limit || !loss_limit || !deposit_limit_valid || !loss_limit_valid))
		// 	) {
		// 		// if the limits option is selected to 'yes', but there isn't any limits or one of the limits is invalid
		// 		invalid = true;
		// 	}
		// }

		return (
			<div className={cx({ [className]: className })}>
				<Text size="-1" paragraph>
					<Link action={this.goToStep} underline>
						{t('UserAddressForm__GoBack')}
					</Link>
				</Text>

				<Text size="1" paragraph strong>
					{t('UserAddressForm__AddressMessage')}
				</Text>

				<InputGroup>
					<Select
						name="title"
						label="Title *"
						onChange={this.handleChange}
						value={title}
						margin="cozy"
						autoFocus
						className={css`
							margin-right: 10px;
						`}
					>
						<SelectOption value={'Mr'}>Mr</SelectOption>

						<SelectOption value={'Mrs'}>Mrs</SelectOption>

						<SelectOption value={'Miss'}>Miss</SelectOption>
					</Select>

					<Input
						autoFocus={true}
						name="first_name"
						onChange={this.handleChange}
						value={first_name}
						label={t('UserAddressForm__FirstName')}
						placeholder={t('MustMatchID')}
						constraints={{
							name: {
								// is required
								presence: true,
								length: {
									minimum: 2,
									message: t('UserAddressForm__FirstNameConstraint'),
								},
								format: { pattern: '.*[(a-zA-Z)]', flags: 'i', message: 'must have letters only' },
							},
						}}
						error={first_name_error}
						margin="cozy"
						horizontalMargin="cozy"
					/>

					<Input
						name="last_name"
						onChange={this.handleChange}
						value={last_name}
						placeholder={t('MustMatchID')}
						label={t('UserAddressForm__Surname')}
						constraints={{
							name: {
								// is required
								presence: true,
								length: {
									minimum: 2,
									message: t('UserAddressForm__SurnameConstraint'),
								},
								format: { pattern: '.*[(a-zA-Z)]', flags: 'i', message: 'must have letters only' },
							},
						}}
						error={last_name_error}
						margin="cozy"
					/>
				</InputGroup>

				{showAdditionalAddressFields ? (
					<div>
						{/* <AutoCompleteAddressNew onSelect={this.onSelect}/> */}
						<AdditionalAddressFields
							handleChange={this.handleChange}
							closeAdditionalAddressFields={this.closeAdditionalAddressFields}
							street={street}
							street_error={street_error}
							street_number={street_number}
							street_number_error={street_number_error}
							street_type={street_type}
							street_type_error={street_type_error}
							unit_type={unit_type}
							unit_type_error={unit_type_error}
							unit_number={unit_number}
							unit_number_error={unit_number_error}
							city={city}
							city_error={city_error}
							country={country}
							state={state}
							postcode={postcode}
							postcode_error={postcode_error}
						/>
					</div>
				) : (
					<div>
						{/* <AutoCompleteAddressNew onSelect={this.onSelect}/> */}
						<AutoCompleteAddress
							name="address"
							onSelect={this.onSelect}
							onChange={this.handleChange}
							inputText={address}
							inputProps={{
								margin: 'cozy',
								label: t('UserAddressForm__Address'),
							}}
						/>

						<Link action={this.showAdditionalAddressFields} size="-2">
							{'Can"t find it? Enter address'} <ManuallyText>manually</ManuallyText>
						</Link>
					</div>
				)}

				{/* {state === 'SA' || country === 'United Kingdom' ? (
					<StyledUserAddressForm__PlayersNotice>
						<Text strong>{t('UserAddressForm__PlayersNotice')}</Text>
						<Text size="-1" paragraph>
							{t('UserAddressForm__ControlLimits')}
						</Text>

						<StyledUserAddressForm__LimitsOptions>
							<StyledUserAddressForm__ControlLimits
								name="control_limits"
								value="yes"
								onChange={this.onRadioChange}
								label={t('Yes')}
								checked={control_limits === 'yes'}
								inline
							/>
							<StyledUserAddressForm__ControlLimits
								name="control_limits"
								value="no"
								onChange={this.onRadioChange}
								label={t('No')}
								checked={control_limits === 'no'}
								inline
							/>
						</StyledUserAddressForm__LimitsOptions>

						{control_limits === 'yes' ? (
							<div>
								<Text strong>{t('UserAddressForm__WeeklyLimits')}</Text>
								<InputGroup>
									<Input
										name="deposit_limit"
										onChange={this.handleChange}
										value={deposit_limit}
										label={t('UserAddressForm__DepositLimit')}
										type="number"
										constraints={constraints}
										margin="cozy"
										horizontalMargin="cozy"
									/>

									<Input
										name="loss_limit"
										onChange={this.handleChange}
										value={loss_limit}
										label={t('UserAddressForm__LossLimit')}
										type="number"
										constraints={constraints}
										margin="cozy"
									/>
								</InputGroup>
							</div>
						) : (
							<div>
								<Text size="-1" transform="uppercase" strong>
									{t('UserAddressForm__GambleResponsibly')}
								</Text>
								<Text size="-1" paragraph>
									{t('UserAddressForm__SetLimits')}
								</Text>
							</div>
						)}
					</StyledUserAddressForm__PlayersNotice>
				) : null} */}

				<StyledUserAddressForm__Actions align="space-between">
					<Text
						className={css`
							flex: 0 1 50%;
						`}
						size="-1"
					>
						{mobileVerification ? t('UserAddressForm__Step4of4') : t('UserAddressForm__Step4of4')}
					</Text>

					{/* {mobileVerification ? (
						<Button action={this.nextStep} type="primary" disabled={invalid} bold block flex>
							{t('NextStep')}
						</Button>
					) : (
						<Button action={createAccount} type="primary" disabled={invalid} loading={loadingCreateAccount} bold flex>
							{t('MobileVerificationForm__CreateAccount')}
						</Button>
					)} */}
					<Button action={createAccount} type="primary" disabled={invalid} loading={loadingCreateAccount} bold flex>
						{t('MobileVerificationForm__CreateAccount')}
					</Button>
				</StyledUserAddressForm__Actions>
			</div>
		);
	}
}

export default withNamespaces()(UserAddressForm);
