import { VERIFICATION_SUCCESS, VERIFICATION_ERROR, VERIFICATION_RESET, VERIFICATION_SET_TOKEN, VERIFICATION_WARNING } from './VerificationsActionType';


const initialState = {
    verified: false,
    error: false,
    warning: false,
    token: null,
};

const verifications = (state = initialState, action) => {

    switch (action.type) {
        case VERIFICATION_SUCCESS:
            return {
                ...state,
                verified: true,
            };

        case VERIFICATION_ERROR:
            return {
                ...state,
                error: action.payload,
            };

        case VERIFICATION_RESET:
            return {
                ...initialState,
                token: state.token,
            };
        case VERIFICATION_SET_TOKEN:
            return {
                ...state,
                token: action.payload,
            };
        case VERIFICATION_WARNING:
            return {
                ...state,
                warning: action.payload,
            };

        default:
            return state;
    }

};

export default verifications;