import React from 'react';
import { ListItem, Text, spacings, ui } from '@tbh/ui-kit';
// Components
import styled, { css } from 'react-emotion';
// import { StyledRaceCard_goToRace } from './../../../components/features/Racing/UpRaceCard/UpRaceCard.styled';
import {
	StyledUpRaceSelectionsList,
	StyledUpRaceSelectionsList__RaceSelectionsListItems,
} from './../../../components/features/Racing/UpRaceSelectionsList/UpRaceSelectionsList.styled';
import cx from 'classnames/bind';
import UpRaceSelectionDetailsLoading from '../../../components/features/Racing/UpRaceSelectionsList/UpRaceSelectionDetails/UpRaceSelectionDetailsLoading';
import { PLACE_ICON_URL, WIN_ICON_URL } from '../../../store/entities/constants/BetConstants';

const StyledUpRaceSelectionsListItem = styled(ListItem)`
	${(props) =>
		css`
			label: UpRaceSelectionsListItem;

			list-style: none;
			padding: ${spacings(props).tight}px 0;

			&:nth-of-type(3n + 1):hover,
			&:hover {
				background: ${ui(props).color_3};
				//cursor: pointer;
			}
		`};
`;
const StyledUpRaceSelectionsListItem__Wrapper = styled('div')(
	(props) =>
		css`
			label: UpRaceSelectionsListItem__Wrapper;

			// Set a min-height so the trends don't overlap
			//min-height: ${spacings(props).roomy}px;
			display: flex;
			align-items: center;
			height:  57px;
			padding: 5px;

			display: flex;
			justify-content: space-between;
		`,
);

const StyledGRSNtjRaceSelectionsListItem__Deductions = styled(Text)`
	${(props) =>
		css`
			label: GRSNtjRaceSelectionsListItem__Deductions;

			margin-right: ${spacings(props).compact}px;
		`};
`;

const StyledUpRaceSelectionPricesList = styled('ul')`
	${(props) =>
		css`
			label: UpRaceSelectionPricesList;

			margin-top: 12px;
			padding: ${spacings(props).constrictive}px 0 ${spacings(props).tight}px;
			margin-bottom: 8px;
		`};
`;

const StyledUpPriceListItem = styled(ListItem)`
	${(props) =>
		css`
			label: UpPriceListItem;

			list-style: none;

			float: right !important;
			padding: 2px 0;
		`};
`;

const UpRaceCardContainerLoading = ({ size, className }) => {
	const componentClasses = cx({
		[className]: className,
	});

	const items = [1, 2, 3, 4];
	return (
		<div
			className={css`
				color: #2e2e2e;
				background-color: #f7f8f8;
				height: auto;
				width: auto;
				box-sizing: border-box;
			`}
		>
			{/* <div
				className={css`
					color: #2e2e2e;
					background-color: black;
					height: 20px;
					width: 50px;
				`}
			/> */}
			<StyledUpRaceSelectionsList className={componentClasses}>
				<StyledUpRaceSelectionsList__RaceSelectionsListItems center compact>
					{items.map((topselection, i) => (
						<StyledUpRaceSelectionsListItem key={'top_'+topselection} size={size}>
							<StyledUpRaceSelectionsListItem__Wrapper>
								<UpRaceSelectionDetailsLoading
									number={1}
									name={'a'}
									silk={'silk'}
									jockey={'jockey'}
									trainer={'trainer'}
									barrier={'barrier'}
									weight={'weight'}
									selection_status={'not scratched'}
									type_code={'type_code'}
									size={size}
								/>
								<StyledUpRaceSelectionPricesList>
									{[1, 2].map((item) => (
										<StyledUpPriceListItem key={item}>
											{item == 1 ? (
													<span
													className={css`
														text-transform: uppercase;
														font-size: 10px;
													`}
												>
												<img src={WIN_ICON_URL} width="20px" height="20px" />
												</span>
											) : (
												<span
													className={css`
														text-transform: uppercase;
														font-size: 10px;
													`}
												>
													<img src={PLACE_ICON_URL} width="20px" height="20px" />
												</span>
											)}
										</StyledUpPriceListItem>
									))}
								</StyledUpRaceSelectionPricesList>
							</StyledUpRaceSelectionsListItem__Wrapper>
						</StyledUpRaceSelectionsListItem>
					))}
				</StyledUpRaceSelectionsList__RaceSelectionsListItems>
			</StyledUpRaceSelectionsList>
			{/* <StyledRaceCard_goToRace>Testing</StyledRaceCard_goToRace> */}
		</div>
	);
};

export default UpRaceCardContainerLoading;
