import moment from 'moment-timezone';

/**
 * Adds Custom Amount and CHATLIVE to the First Deposit Bonuses
 *
 * @param bonus_bets
 */
export const getFirstDepositBonuses = (bonus_bets = []) => {
	// Hour of the day (Australian Eastern Standard Time)
	const now_aest = moment()
		.tz('Australia/Brisbane')
		.format('HH');

	const customAmount = {
		value: 'Custom Amount',
		bonus: '100% Bonus Bet',
	};

	const chatLive = {
		value: 'Other Amount',
		bonus: 'Chat Live',
		promoCode: 'CHATLIVE',
	};

	let bonusArray = [...bonus_bets];

	// Remove the promotion from the bonus list
	for (let i = 0; i < bonus_bets.length; i++) {
		if (bonus_bets[i].hasOwnProperty('promotion')) {
			let array1 = bonus_bets.slice(0, i);
			let array2 = bonus_bets.slice(i + 1, bonus_bets.length);
			bonusArray = [...array1, ...array2];
		}
	}

	let newBonusArray = [];

	// Change all values in the array to string
	for (let i = 0; i < bonusArray.length; i++) {
		let bonus = { ...bonusArray[i] };
		newBonusArray.push(bonus);
		for (let key in bonus) {
			if (bonus.hasOwnProperty(key)) {
				bonus[key] = bonus[key].toString();
			}
		}
	}

	// Add ‘Custom Amount’ option if there isn't one already
	if (
		newBonusArray.filter(function(e) {
			return e.value === 'Custom Amount';
		}).length <= 0
	) {
		newBonusArray.push(customAmount);
	}

	// Add ‘Other Amount’ option during the CS operating hours
	// Mon-Sun: 8am - 9pm AEST
	if (now_aest >= 8 && now_aest < 21) {
		if (
			newBonusArray.filter(function(e) {
				return e.value === 'Other Amount';
			}).length <= 0
		) {
			newBonusArray.push(chatLive);
		}
	}

	return newBonusArray;
};

/**
 * Gets the promotion from the bonus list
 *
 * @param bonus_bets
 * @returns {*}
 */
export const getFirstDepositPromotion = (bonus_bets = []) => {
	let promotion;

	for (let i = 0; i < bonus_bets.length; i++) {
		let bonus = bonus_bets[i];

		if (bonus.hasOwnProperty('promotion')) {
			promotion = bonus;
		}
	}

	return promotion;
};
