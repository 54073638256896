import {
	ADD_FEATURES,
	SET_FEATURES,
	ENABLE_FEATURE_TOGGLING,
	UPDATE_BULK_FEATURE_TOGGLING,
} from './featureTogglesActionTypes';
import { MASTHEAD_DEFAULT } from '../../common/constants/Application';

let initialState = {
	/** Enable or disable this reducer as a whole */
	enableFeatureToggling: true,

	/**
	 * -1 Disable all features
	 * 0 Default (enable each feature individually)
	 * 1 Enable all features
	 **/
	bulkFeatureToggler: 0,

	/** The list of feature to toggle in the feature toggler */
	features: {
		accountTransactions: {
			enabled: true,
			value: {
				hideStatistics: false,
			},
		},
		// Address verification on signup - defaulted to true as per existing behaviour
		addressVerification: {
			enabled: true,
		},
		adverts: {
			enabled: true,
			value: {
				phone: false,
				desktop: true,
			},
		},
		betPlacementShowReceipt: {
			enabled: true,
			value: {
				betPrompt: true,
				betSlip: true, // Not currently used
			},
		},
		bonusBets: {
			enabled: true,
		},
		ceoComment: {
			enabled: false,
		},
		confirmBetPlacement: {
			enabled: true,
		},
		depositsAndWithdrawals: {
			enabled: true,
		},
		enableSpeedmaps: {
			enabled: true,
		},
		exotics: {
			enabled: true,
		},
		firstDepositOnSignup: {
			enabled: false,
		},
		fullWidth: {
			enabled: false,
		},
		intercom: {
			enabled: true,
		},
		jsonLd: {
			enabled: true,
		},
		limitUserBetAmount: {
			enabled: true,
		},
		loginInModals: {
			enabled: true,
		},
		mainNavigation: {
			enabled: true,
		},
		masthead: {
			enabled: true,
			// default, affiliate, iframe
			value: MASTHEAD_DEFAULT,
		},
		// This feature has been deprecated and will be replaced by 'meetingGroups'
		groupMeetingsByCountry: {
			enabled: true,
			deprecated: true,
		},
		// Group & display the meetings based on the supplied country codes
		meetingGroups: {
			enabled: false,
		},
		// Display the meeting group filters on racing home page
		meetingGroupFilters: {
			enabled: false,
		},
		// Mobile verification on signup - defaulted to true as per existing behaviour
		mobileVerification: {
			enabled: true,
		},
		ntjRaces: {
			enabled: true,
		},
		exoticPools: {
			enabled: false,
		},
		otherLevels: {
			enabled: true,
		},
		pinAuthentication: {
			enabled: true,
		},
		playerBetsWidgets: {
			enabled: false,
		},
		poweredBy: {
			enabled: false,
		},
		racingDerivatives: {
			enabled: true,
		},
		racingMultiButton: {
			enabled: true,
		},
		resizeEvent: {
			enabled: false,
		},
		responsibleGambling: {
			enabled: true,
		},
		scrollbar: {
			enabled: true,
			value: {
				vertical: true,
				horizontal: false,
			},
		},
		sessionTimer: {
			enabled: true,
		},
		simpleRegistration: {
			enabled: false,
		},
		singleWallet: {
			enabled: false,
		},
		showFooter: {
			enabled: true,
		},
		tournaments: {
			enabled: false,
		},
		userProfile: {
			enabled: true,
		},
		urlRouting: {
			enabled: true,
		},
		userVerification: {
			enabled: true,
		},
		versionUpdateSupport: {
			enabled: false,
		},
		featuredEvents:{
			enabled: true,
		},
		oddBoost: {
			enabled: true
		},
		liveChat: {
			enabled: false
		}
	},
};

/**
 * Feature toggles reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const featureTogglesReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_FEATURES:
			return {
				...state,
				features: {
					...state.features,
					...action.payload,
				},
			};

		case SET_FEATURES:
			return {
				...state,
				features: {
					...action.payload,
				},
			};

		case ENABLE_FEATURE_TOGGLING:
			return {
				...state,
				enableFeatureToggling: action.payload,
			};

		case UPDATE_BULK_FEATURE_TOGGLING:
			return {
				...state,
				bulkFeatureToggler: action.payload,
			};
	}

	return state;
};

export default featureTogglesReducer;
