import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { brand, spacings, typography, ui, Button, ButtonGroup, Panel, Text } from '@tbh/ui-kit';
import Form from 'react-jsonschema-form';

const StyledAclForm__JsonWrapper = styled('div')(
	(props) => css`
		label: AclForm__JsonWrapper;

		margin-top: ${spacings(props).cozy}px;
	`,
);

const StyledAclForm__Form = styled(Form)(
	(props) => css`
		label: AclForm__Form;

		fieldset {
			background-color: inherit;
			padding: 0;
			border: none;
			margin: 0;

			> .form-group {
				background: ${ui(props).color_3};
				padding: ${spacings(props).cozy}px;
				margin-bottom: ${spacings(props).cozy}px;
			}

			fieldset {
				.form-group {
					background: transparent;
					padding-top: 0;
					padding-right: 0;
					margin-bottom: 0;

					&:last-of-type {
						padding-bottom: 0;
					}
				}
			}

			legend {
				font-size: ${typography(props)['size_-1']};
				font-weight: bold;
				padding: 0;
				margin-bottom: ${spacings(props).cozy}px;
			}

			label {
				font-size: ${typography(props)['size_-1']};
			}

			input,
			textarea,
			select {
				font-size: ${typography(props)['size_-1']};
				margin-bottom: 0;
			}

			fieldset fieldset .row {
				background-color: ${ui(props).color_2};
				padding: ${spacings(props).cozy}px ${spacings(props).cozy}px ${spacings(props).cozy}px 0;
				margin-left: ${spacings(props).cozy}px;

				&:last-child {
					padding: 0 ${spacings(props).cozy}px ${spacings(props).tight}px 0;
				}
			}

			i.glyphicon {
				display: none;
			}

			.btn-group {
				// overrides the styles from react-jsonschema-form
				justify-content: flex-end !important;
				margin: ${spacings(props).cozy}px 0;
			}

			.array-item-remove,
			.array-item-move-up,
			.array-item-move-down {
				font-size: ${typography(props)['size_-2']};
				// overrides the styles from react-jsonschema-form
				flex: none !important;
				width: 100px;
			}

			.btn-add {
				font-size: ${typography(props)['size_-2']};
				color: ${ui(props).color_2};
				background: ${brand(props).color_2_light};
				border: 1px solid ${brand(props).color_2_light};
				transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;

				&::after {
					content: 'Add';
				}
			}

			.array-item-move-up::after {
				content: 'Move Up';
			}
			.array-item-move-down::after {
				content: 'Move Down';
			}
			.array-item-remove {
				font-size: ${typography(props)['size_-2']};
				color: ${ui(props).color_2};
				background: ${ui(props).color_danger};
				border: 1px solid ${ui(props).color_danger};

				&::after {
					content: 'Remove';
				}
			}
		}
	`,
);

const StyledAclForm__Json = styled(Panel)(
	(props) => css`
		label: AclForm__Json;

		margin-top: ${spacings(props).cozy}px;
		margin-bottom: ${spacings(props).cozy}px;
	`,
);

class AclForm extends Component {
	static propTypes = {
		/** Form Schema */
		schema: PropTypes.object.isRequired,

		/** Handler for updating the Acl */
		onAclUpdate: PropTypes.func.isRequired,

		/** Data to prefill the form */
		formData: PropTypes.object,

		/** UI Schema */
		uiSchema: PropTypes.object,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		formData: {},
		uiSchema: {},
	};

	state = {
		data: null,
	};

	handleSubmit = (data) => {
		this.setState({
			data: data.formData,
		});
	};

	handleAclUpdate = () => {
		// Remove undefined properties
		let data = JSON.stringify(this.state.data);
		data = JSON.parse(data);

		this.props.onAclUpdate(data);
	};

	handleRemoveProperty = (e, name) => {
		this.setState((state) => {
			let newData = { ...state.data };
			delete newData[name];

			return {
				data: newData,
			};
		});
	};

	renderRemoveButtons = () => {
		const removableProperties = ['brandDetails', 'brandLogos', 'currencyConfiguration', 'features', 'seoContent'];

		return removableProperties.map((property) => (
			<Button
				key={property}
				type={Button.types.DANGER}
				action={this.handleRemoveProperty}
				data={property}
				size="small"
				flex
				bold
			>
				{property}
			</Button>
		));
	};

	render() {
		const { className, schema, formData, uiSchema } = this.props;

		const { data } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<div>
				<StyledAclForm__Form
					className={componentClasses}
					schema={schema}
					formData={formData}
					onSubmit={this.handleSubmit}
					uiSchema={uiSchema}
					noValidate
				>
					<Button type={Button.types.PRIMARY}>Generate JSON</Button>
				</StyledAclForm__Form>

				{data && (
					<StyledAclForm__JsonWrapper>
						<Text size="-1" strong>
							Remove from the ACL:
						</Text>
						<Text size="-2" paragraph={Text.paragraph.SPACING_COMPACT} italic>
							Note: if you delete a property, you will need to submit the form again to put it back in.
						</Text>
						<ButtonGroup center>{this.renderRemoveButtons()}</ButtonGroup>

						<StyledAclForm__Json padding={Panel.padding.SPACING_COZY} borderType="default">
							<Text
								className={css`
									word-break: break-all;
								`}
								size="-2"
							>
								{JSON.stringify(data)}
							</Text>
						</StyledAclForm__Json>

						<Button type={Button.types.DANGER} action={this.handleAclUpdate}>
							Update ACL
						</Button>
					</StyledAclForm__JsonWrapper>
				)}
			</div>
		);
	}
}

export default AclForm;
