// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { brand, spacings, ui, Icon, Text } from '@tbh/ui-kit';

const StyledModalFooter = styled(Text)`
	label: ModalFooter;
	color: ${(props) => brand(props).color_2};
	padding: ${(props) => spacings(props).compact}px 0;
	background-color: ${(props) => ui(props).color_neutral};
	width:100%;
	border-radius: ${(props) => spacings(props).compact}px;
`;

const ModalFooter = (props) => {
	const { className, t } = props;

	const footerClass = cx({
		[className]: className,
	});

	return (
		<StyledModalFooter className={footerClass} type="default" size="-2" align="center">
			<Icon icon="lock" size="1" push="1" /> {t('ModalFooter__Message')}
		</StyledModalFooter>
	);
};

ModalFooter.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

ModalFooter.defaultProps = {
	className: null,
};

export default withNamespaces()(ModalFooter);
