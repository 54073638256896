import React from 'react';
import ReactDOM from 'react-dom';

import env from '../../common/EnvironmentVariables';
// import * as Sentry from '@sentry/react';
const Pusher = require('pusher-js');
const URI = require('urijs');

const { getReduxSitemapPromises } = require('../../sitemaps/index');
import { getAuthenticatedUser } from '../../store/application/applicationSelectors';
import { login, logoutUser, tokenAuthentication } from '../../store/authentication/authenticationActions';
import {
	setCurrentRoute,
	openNotification,
	triggerOtherLevels,
	show404,
	setApplicationPage,
	changeLanguage,
	addLanguageResources,
} from '../../store/application/applicationActions';
import { addWidgetLoadEvent } from '../../common/actions/widgetActions';

import { WIDGET_EVENT_PREFIX } from '../../common/constants/Widget';

import { Text } from '@tbh/ui-kit';
import VersionNotSupported from '../../components/features/Application/VersionNotSupported/VersionNotSupported';
import DownloadAppButton from '../../components/features/Application/DownloadAppButton/DownloadAppButton';

import i18n from '../../i18n';

/**
 * Base Application
 */
const Application = {
	__bm_envOverrides: {},

	__VERSION__: process.env.PACKAGE_VERSION,
	__BUILD_NUMBER__: process.env.BM_BUILD_NUMBER,
	__WIDGET_VERSION__: process.env.WIDGET_PACKAGE_VERSION,

	/**
	 * Internationalisation instance
	 */
	i18n: i18n,

	/**
	 * Heartbeat timer reference
	 */
	heartBeatTimer: undefined,

	/**
	 * Reality Check timer reference
	 */
	realityCheckTimer: undefined,

	/**
	 * App polling on/off
	 */
	polling: false,

	/**
	 * App push on/off
	 */
	push: true,

	/**
	 * Callback for when the application is started
	 */
	onStart: () => {},

	/**
	 * Initialize
	 */
	initialize: function() {
		addWidgetLoadEvent();

		const History = Backbone.History.extend({
			/**
			 *
			 * @returns {*}
			 */
			loadUrl: function() {
				const match = Backbone.History.prototype.loadUrl.apply(this, arguments);
				if (!match) {
					App.store.dispatch(show404());
				}

				return match;
			},

			/**
			 * Navigate - override to append QS params when used in hash routing
			 *
			 * @param fragment
			 * @param options
			 * @returns {*}
			 */
			navigate: function(fragment, options) {
				if (this._wantsPushState && this._hasPushState) {
					fragment = URI(fragment)
						.addQuery(URI().query(true))
						.toString();
				}

				const navigate = Backbone.History.prototype.navigate.call(this, fragment, options);

				App.store.dispatch(setCurrentRoute(fragment));
				App.pageView((fragment[0] === '/' ? '' : '/') + fragment);

				return navigate;
			},

			/**
			 * Override to cater for google cache
			 *
			 * @returns {*}
			 */
			getFragment: function() {
				let fragment = Backbone.History.prototype.getFragment.apply(this, arguments);

				// When google cache pages are loaded, the fragment loaded is wrong. Attempt extract the real route
				const match = fragment.match(/https:\/\/www\.topbetta\.com\.au\/(.*)\+/);

				if (match && match.length > 1) {
					fragment = match[1];
				}

				return fragment;
			},
		});

		this.history = Backbone.history = new History();
	},

	connectPusher: function(token) {
		const storageToken = localStorage.getItem('user_token');

		let currentToken = '';

		if (token) {
			currentToken = `Bearer ${token}`;
		} else {
			currentToken = `Bearer ${storageToken}`;
		}

		this.pusher = new Pusher(env('pusherKey'), {
			authEndpoint: `${env('apiUrl')}broadcasting/auth`,
			authTransport: 'ajax',
			auth: {
				headers: {
					Authorization: currentToken,
					Accept: 'application/json',
				},
			},
			forceTLS: true,
			...env('pusherConfig', {}),
		});

		this.pusher.connection.bind('error', (error) => {
			// Whenever there is an error, turn polling on
			this.turnOnPolling();
		});
	},

	startSentry: () => {
		window.sentryOnLoad = function() {
			if (env('sentryKey')) {
				document.Sentry.init({
					dsn: env('sentryKey'),
					release: 'grsbet-frontend',
					integrations: [
						// If you use a bundle with performance monitoring enabled, add the BrowserTracing integration
						Sentry.browserTracingIntegration(),
						// If youbundle with session replay enabled, add the Replay integration
						Sentry.replayIntegration(),
					],
					environment: window.location.hostname.includes('localhost') ? 'development' : 'production',
					// We recommend adjusting this value in production, or using tracesSampler
					// for finer control
					tracesSampleRate: 1.0,
				});
			}
		};
	},

	/**
	 * Turn off polling
	 */
	turnOffPolling: function() {
		console.warn('Polling turned off');
		this.polling = false;
	},

	/**
	 * Turn on polling
	 */
	turnOnPolling: function() {
		console.warn('Polling turned on');
		this.polling = true;
	},

	/**
	 * Heartbeat implementation
	 *
	 * @private
	 */
	_heartbeat: function() {
		// If polling is on, turn it off
		if (this.polling) {
			this.turnOffPolling();
		}

		// Clear the timeout
		clearTimeout(this.heartBeatTimer);

		// Setup new timeout for next heartbeat
		this.heartBeatTimer = setTimeout(this.turnOnPolling.bind(this), 65000);
	},

	/**
	 * Setup the socket heartbeat
	 */
	heartbeat: function() {
		// Subscribe to the channel
		var channel = this.pusher.subscribe('heartbeat');

		// Listen for the heartbeat event
		channel.bind('heartbeat', () => {
			if (this.push) {
				this._heartbeat();
			}
		});
	},

	/**
	 * Initiate page view
	 *
	 * @param route
	 * @param opts
	 */
	pageView: function(route, opts = {}) {
		//this.fbq('track', 'PageView');
		//this.ga('send', 'pageview', route);
		//this.twq('track', 'PageView');
		//this.braze('PageView', { Route: route });

		App.store.dispatch(triggerOtherLevels('registerEvent', 'pageview'));
	},

	gtm: function(data) {
		if (typeof dataLayer === 'undefined' || !Array.isArray(dataLayer)) {
			console.warn('GTM does not exist');
			return;
		}

		if (env('logTrackingEvents', false) && env('debug', false)) {
			//console.log('GTM', arguments);
		}
		dataLayer.push(data);
	},

	ga: function() {
		if (typeof ga === 'undefined') {
			console.warn('GA does not exist');
			return;
		}

		const authenticatedUser = getAuthenticatedUser(App.store.getState());

		if (authenticatedUser) {
			this._ga('set', 'userId', authenticatedUser.id);
			this._ga('set', { dimension1: authenticatedUser.id });
		}

		this._ga(...arguments);
	},

	_ga: function() {
		if (typeof ga === 'undefined') {
			console.warn('GA does not exist');
			return;
		}

		if (env('logTrackingEvents', false) && env('debug', false)) {
			//console.log('GA', arguments);
		}
		ga.apply(this, arguments);
	},

	fbq: function() {
		if (typeof fbq === 'undefined') {
			console.warn('FBQ does not exist');
			return;
		}

		fbq.apply(this, arguments);
	},

	twq: function() {
		if (typeof twq === 'undefined') {
			console.warn('TWQ does not exist');
			return;
		}

		twq.apply(this, arguments);
	},

	braze: function(eventName, eventProperties) {
		if (typeof appboy === 'undefined') {
			console.warn('BRAZE does not exist');
			return;
		}

		try {
			const brazeEvent = appboy.logCustomEvent(eventName, eventProperties);

			if (env('logTrackingEvents', true) && env('debug', true)) {
				if (brazeEvent) {
					//console.log('BRAZE', eventName, eventProperties);
				} else {
					console.error('BRAZE event was not logged!');
				}
			}

			brazeEvent();
		} catch (e) {
			// console.error(e);
		}
	},
	livechat: function(licenseNumber) {
		// Create the script element
		const script = document.createElement('script');

		// Set the script content with the provided license number
		script.textContent = `
		  window.__lc = window.__lc || {};
		  window.__lc.license = ${licenseNumber};
		  window.__lc.integration_name = "manual_onboarding";
		  window.__lc.product_name = "livechat";
		  ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice));
		`;

		// Append the script to the head of the document
		document.head.appendChild(script);

		// Add the noscript fallback
		const noscript = document.createElement('noscript');
		noscript.innerHTML = `
		  <a href="https://www.livechat.com/chat-with/${licenseNumber}/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
		`;
		document.body.appendChild(noscript);
	},

	/**
	 * Tracking using Twitter conversion
	 *
	 * @param amount
	 * @param quantity
	 */
	tc: function(amount, quantity = 1) {
		if (typeof twttr === 'undefined' || typeof twttr.conversion === 'undefined') {
			console.warn('Twitter conversion tracking does not exist');
			return;
		}

		twttr.conversion.trackPid(env('twitterId'), {
			tw_sale_amount: amount,
			tw_order_quantity: quantity,
		});
	},

	/**
	 * Attempt to update the app by reloading the page from the server (ie. NOT from cache)
	 *  - to avoid getting into a refreshing loop we will set a localStorage flag
	 */
	attemptUpdateByReload: function() {
		try {
			const versionLoaded = App.store.getState().application.versionLoaded;

			const alreadyAttempted = localStorage.getItem(`TB:VERSION-RELOAD-ATTEMPT:${versionLoaded}`);

			// If there has already been an attempt to update by reload then just return
			if (alreadyAttempted === '1') {
				// Clear out local storage before returning
				// This allows us to attempt a reload again later if the version information in state changes
				localStorage.removeItem(`TB:VERSION-RELOAD-ATTEMPT:${versionLoaded}`);
				return true;
			}

			// Set reloaded flag in local storage and hard reload the page from server
			localStorage.setItem(`TB:VERSION-RELOAD-ATTEMPT:${versionLoaded}`, '1');
			window.location.reload(true);
		} catch (error) {
			console.error(error);
		}
	},

	/**
	 * Handle when the loaded version is not supported
	 */
	versionNotSupported: function() {
		const state = App.store.getState();

		// If on cordova and the app version is NOT supported then render the 'not supported' message component
		if (this.IS_CORDOVA) {
			ReactDOM.render(
				<VersionNotSupported cordovaLoaded loadedPlatform={state.application.loadedPlatform} />,
				document.getElementById('main'),
			);
		} else {
			// If not loading the cordova app then attempt to update by reloading the page from server
			if (App.attemptUpdateByReload()) {
				// If reload already attempted then render message
				ReactDOM.render(<VersionNotSupported />, document.getElementById('main'));
			}
		}
	},

	/**
	 * Handle when there is an app update available
	 */
	nonMandatoryUpdateAvailable: function() {
		const state = App.store.getState();

		// If not loading the cordova app then attempt to update by reloading the page from server
		if (!this.IS_CORDOVA) {
			if (App.attemptUpdateByReload()) {
				// If reload already attempted then display a notification
				App.store.dispatch(openNotification('There is a new version of the app available.', 'success'));
			}

			// If on the cordova app & isn't on iOS then prompt the user to update
		} else if (state.application.loadedPlatform !== 'ios') {
			const androidUpdatePropmt = (
				<Text size="-1" align="center">
					There is a new version of the app available for download.
					<br />
					<br />
					<DownloadAppButton />
				</Text>
			);
			App.store.dispatch(openNotification(androidUpdatePropmt, 'success'));
		}
	},

	receiveWindowMessage: function(event) {
		if (event.data) {
			if (event.data.name === `${WIDGET_EVENT_PREFIX}:login` && event.data.hasOwnProperty('token')) {
				const params = {
					source: event.data.source,
					token: event.data.token,
					username: event.data.username,
				};

				try {
					App.store.dispatch(tokenAuthentication(params));
				} catch (e) {
					console.error(e);
				}
			} else if (event.data.name === `${WIDGET_EVENT_PREFIX}:login` && event.data.hasOwnProperty('password')) {
				try {
					App.store.dispatch(login(event.data.username, event.data.password));
				} catch (e) {
					console.error(e);
				}
			} else if (event.data.name === `${WIDGET_EVENT_PREFIX}:logout`) {
				try {
					App.store.dispatch(logoutUser());
				} catch (e) {
					console.error(e);
				}
			} else if (event.data.name === `${WIDGET_EVENT_PREFIX}:changeLanguage` && event.data.hasOwnProperty('language')) {
				try {
					changeLanguage(event.data.language);
				} catch (e) {
					console.error(e);
				}
			} else if (
				event.data.name === `${WIDGET_EVENT_PREFIX}:addLanguageResources` &&
				event.data.hasOwnProperty('resources')
			) {
				try {
					addLanguageResources(event.data.resources);
				} catch (e) {
					console.error(e);
				}
			}
		}
	},

	/**
	 * Start a sub app. A Sub App should be registered to the application object via `registerSubApp`. This
	 * will make it available to be start/stopped. Typically when initiating a Sub App to start, a region
	 * should be specified, otherwise a default (`bodyRegion`) will be used. Starting a sub app on a
	 * region will stop any other sub app running within that region
	 *
	 * @param subApp
	 * @returns {*}
	 */
	startSubApp: function(subApp) {
		// console.info('STARTSUBAPP', subApp);
		// console.warn(
		// 	`BM Deprecation Warning: Calling startSubApp('${subApp}') is no longer supported and will be removed. Replace by dispatching 'setApplicationPage(${subApp})'`,
		// );

		return this.store.dispatch(setApplicationPage(subApp));
	},

	/**
	 * Given the router to use, add the application routes defined in the ACL
	 * This enables to the default route to redirect correctly
	 *
	 * @param router
	 */
	setupAclRoutes: function(router) {
		const routes = App.store.getState().acl.routes || [];

		routes.forEach((landingPage) =>
			router.route(landingPage.route, landingPage.id, () => App.startSubApp(landingPage.subApp)),
		);
	},

	generateSitemaps: function() {
		let promises = [];
		let response = {};

		getReduxSitemapPromises(App.store).forEach((sitemap) => {
			promises.push(
				sitemap.then((data) => {
					if (data.map) {
						response[data.name] = data.map;
					}
				}),
			);
		});

		return new Promise(function(resolve, reject) {
			Promise.all(promises)
				.then(function() {
					resolve(response);
				})
				.catch(reject);
		});
	},
};

export default Application;
