// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

// Actions
import { makeADeposit } from '../../../store/entities/actions/UserActions';
import { launchLiveChat } from '../../../store/application/applicationActions';
import { openPINRegistration } from '../../../store/authentication/authenticationActions';
import { closeModal } from '../../../store/modal/modalActions';
import { routeTo } from '../../../store/application/applicationActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';

// Selector
import { getFirstDepositBonuses, getFirstDepositPromotion } from '../../../store/acl/aclSelectors';

// Components
import { Notification } from '@tbh/ui-kit';
import FirstDeposit from '../../../components/features/Authentication/Registration/FirstDeposit/FirstDeposit';
import DepositConfirmation from '../../../components/features/Authentication/Registration/DepositConfirmation/DepositConfirmation';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';

// Functions
import { formatBonusText } from './FirstDepositHelperFunctions';

// Styles
const StyledFirstDepositContainer__Header = styled(ModalHeader)(
	(props) => css`
		label: FirstDepositContainer__Spacing;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);
const StyledFirstDepositContainer__Error = StyledFirstDepositContainer__Header.withComponent(Notification);

class FirstDepositContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		first_name: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		makeADeposit: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,

		/** launches Intercom chat */
		launchChat: PropTypes.func.isRequired,

		handleBonusSelection: PropTypes.func.isRequired,

		/** array of bonus */
		bonus_bets: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string.isRequired,
				bonus: PropTypes.string.isRequired,
				promoCode: PropTypes.string,
			}),
		).isRequired,

		promotion: PropTypes.shape({
			value: PropTypes.number,
			bonus: PropTypes.string,
			promoCode: PropTypes.string,
		}),

		/** route that the user should be redirect to when the page is closed */
		redirectRoute: PropTypes.string,

		account_balance: PropTypes.number,

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		account_balance: 0,
		promotion: null,
		redirectRoute: null,
		brandName: null,
	};

	state = {
		amount: null,
		bonus: null,
		confirmation: false,
		error: null,
		loading: false,
		receipt_number: null,
		successLink: null,
		successText: null,
	};

	/**
	 *
	 * @param object
	 */
	handleDeposit = (object) => {
		object['billingCountry'] = 'AU';
		object['source'] = 'topbetta';

		let bonus = object.bonus;
		delete object.bonus;

		this.setLoadingMask(true);

		this.props
			.makeADeposit(object)
			.then((response) => {
				this.setState({
					confirmation: true,
					receipt_number: response.data.data.id,
					error: null,
					amount: response.data.data.amount,
					bonus: bonus,
				});
			})
			.catch((error) => {
				this.setState({
					error: error.response.data.errors || error.message,
				});
			})
			.finally(() => {
				this.setLoadingMask(false);
			});
	};

	/**
	 * Sets a loading mask
	 *
	 * @param loading
	 */
	setLoadingMask = (loading = false) => {
		this.setState({
			loading: loading,
		});
	};

	setConfirmationProps = (successText, successLink) => {
		this.setState({
			successText: successText,
			successLink: successLink,
		});
	};

	/**
	 * Removes the error message
	 */
	clearErrorMessage = () => {
		this.setState({
			error: null,
		});
	};

	/**
	 * Closes the page and return to previous route
	 */
	handleClose = () => {
		let redirect;

		if (this.state.confirmation && this.state.successLink) {
			redirect = this.state.successLink;
		} else if (this.props.redirectRoute) {
			redirect = this.props.redirectRoute;
		} else {
			redirect = 'racing';
		}

		this.props.onClose(redirect, this.props.username);
	};

	getBonus = () => {
		return formatBonusText(this.state.amount, this.state.bonus);
	};

	handleBonusSelection = (option) => {
		this.props.handleBonusSelection(option === 'Other Amount' ? option : '$' + option);
	};

	render() {
		const { account_balance, bonus_bets, country, first_name, launchChat, promotion, brandName, t } = this.props;

		const { confirmation, error, loading, receipt_number, successText } = this.state;

		return (
			<div>
				{confirmation && <StyledFirstDepositContainer__Header title={`${t('Welcome')}, ${first_name}`} />}

				{error && (
					<StyledFirstDepositContainer__Error
						strong
						type={Notification.types.COLOUR_DANGER}
						message={error}
						buttonText={t('Dismiss')}
						buttonAction={this.clearErrorMessage}
					/>
				)}

				{confirmation ? (
					<DepositConfirmation
						balance={account_balance}
						receipt_number={receipt_number}
						receipt_method={t('CreditCard')}
						bonus={this.getBonus()}
						closePage={this.handleClose}
						successText={successText}
						deposit
					/>
				) : (
					<FirstDeposit
						first_name={first_name}
						country={country}
						handleDeposit={this.handleDeposit}
						skipBonus={this.handleClose}
						launchChat={launchChat}
						handleBonusSelection={this.handleBonusSelection}
						setConfirmationProps={this.setConfirmationProps}
						bonus_bets={bonus_bets}
						promotion={promotion}
						termsAndConditionsLink={'https://help.topbetta.com.au/knowledge-base/bonus-bets-terms-conditions/'}
						loading={loading}
						clearErrorMessage={this.clearErrorMessage}
						brandName={brandName}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	let bonus_bets = getFirstDepositBonuses(state.acl.firstDepositBonuses);
	let promotion = getFirstDepositPromotion(state.acl.firstDepositBonuses);

	return {
		first_name: state.entities.users[state.application.authenticatedUser].first_name,
		username: state.entities.users[state.application.authenticatedUser].username,
		country: state.entities.users[state.application.authenticatedUser].country,
		redirectRoute: state.registration.redirectRoute,
		account_balance: state.entities.users[state.application.authenticatedUser].account_balance,
		bonus_bets: bonus_bets,
		promotion: promotion,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		makeADeposit: (object) => {
			dispatch(trackGaEvent('1st Deposit', 'Submit', 'Deposit'));
			return dispatch(makeADeposit(object));
		},
		onClose: (route, username) => {
			dispatch(routeTo(route));
			dispatch(openPINRegistration(username));
			return dispatch(closeModal('FirstDepositContainer'));
		},
		launchChat: () => {
			dispatch(launchLiveChat());
			return dispatch(trackGaEvent('1st Deposit', 'Select', 'Other Amount'));
		},
		handleBonusSelection: (option) => {
			return dispatch(trackGaEvent('1st Deposit', 'Select', option));
		},
	};
};

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(FirstDepositContainer),
);
