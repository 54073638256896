export const SET_TOURNAMENTS_HOME_DAILY_SCHEDULE_VISIBILITY = 'SET_TOURNAMENTS_HOME_DAILY_SCHEDULE_VISIBILITY';
export const SET_TOURNAMENTS_HOME_ORDER_DIRECTION = 'SET_TOURNAMENTS_HOME_ORDER_DIRECTION';
export const SET_TOURNAMENTS_HOME_ACTIVE_MODAL = 'SET_TOURNAMENTS_HOME_ACTIVE_MODAL';
export const SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_END_DATE = 'SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_END_DATE';
export const SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_FILTER = 'SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_FILTER';
export const SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_START_DATE = 'SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_START_DATE';
export const SET_TOURNAMENTS_HOME_DAILY_RACES_COLLAPSED = 'SET_TOURNAMENTS_HOME_DAILY_RACES_COLLAPSED';
export const SET_TOURNAMENTS_HOME_DECK_ITEMS = 'SET_TOURNAMENTS_HOME_DECK_ITEMS';
export const SET_TOURNAMENTS_HOME_IN_PLAY_COLLAPSED = 'SET_TOURNAMENTS_HOME_IN_PLAY_COLLAPSED';
export const SET_TOURNAMENTS_HOME_INITIAL_LOAD_OF_TICKETS = 'SET_TOURNAMENTS_HOME_INITIAL_LOAD_OF_TICKETS';
export const SET_TOURNAMENTS_HOME_SELECTED_TOURNAMENT = 'SET_TOURNAMENTS_HOME_SELECTED_TOURNAMENT';
export const SET_TOURNAMENTS_HOME_NAVIGATION = 'SET_TOURNAMENTS_HOME_NAVIGATION';
export const SET_TOURNAMENTS_HOME_FILTER_AND_ORDER = 'SET_TOURNAMENTS_HOME_FILTER_AND_ORDER';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_FILTER = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_FILTER';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_COLLAPSED = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_COLLAPSED';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_END_DATE = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_END_DATE';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_FILTER = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_FILTER';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_IS_LOADING = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_IS_LOADING';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_RESULTS = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_RESULTS';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_START_DATE = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_START_DATE';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SELECTED = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SELECTED';
export const SET_TOURNAMENTS_HOME_NTP_COLLAPSED = 'SET_TOURNAMENTS_HOME_NTP_COLLAPSED';
export const SET_TOURNAMENTS_HOME_NTP_FILTER = 'SET_TOURNAMENTS_HOME_NTP_FILTER';
export const SET_TOURNAMENTS_HOME_NTP_SEARCH_RESULTS = 'SET_TOURNAMENTS_HOME_NTP_SEARCH_RESULTS';
export const SET_TOURNAMENTS_HOME_NTP_SEARCH_TERM = 'SET_TOURNAMENTS_HOME_NTP_SEARCH_TERM';
export const SET_TOURNAMENTS_HOME_TICKET_PURCHASE_ERROR_MESSAGE = 'SET_TOURNAMENTS_HOME_TICKET_PURCHASE_ERROR_MESSAGE';
export const SET_TOURNAMENTS_HOME_TICKET_PURCHASE_LOADING = 'SET_TOURNAMENTS_HOME_TICKET_PURCHASE_LOADING';
export const SET_TOURNAMENTS_HOME_TRENDING_COLLAPSED = 'SET_TOURNAMENTS_HOME_TRENDING_COLLAPSED';
export const SET_MY_TOURNAMENTS_ACTIVE_FILTER = 'SET_MY_TOURNAMENTS_ACTIVE_FILTER';
export const SET_TOURNAMENTS_HOME_DATE_FILTER = 'SET_TOURNAMENTS_HOME_DATE_FILTER';
export const SET_TOURNAMENTS_HOME_LOADING = 'SET_TOURNAMENTS_HOME_LOADING';
export const SET_TOURNAMENTS_HOME_NTP_LOADING = 'SET_TOURNAMENTS_HOME_NTP_LOADING';
export const SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_COLLAPSED = 'SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_COLLAPSED';