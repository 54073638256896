// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces } from 'react-i18next';
import styled, { css } from 'react-emotion';

import { Button, Panel, Tabs, TabsItem } from '@tbh/ui-kit';

// Components
import TakeABreakShort from './TakeABreakShort/TakeABreakShort';
import TakeABreakLong from './TakeABreakLong/TakeABreakLong';
import TakeABreakConfirmation from './TakeABreakConfirmation/TakeABreakConfirmation';

/**
 * represent the screen that should be displayed
 */
const SHORT_BREAK_SCREEN = 'SHORT_BREAK_SCREEN';
const LONG_BREAK_SCREEN = 'LONG_BREAK_SCREEN';
const CONFIRMATION_SCREEN = 'CONFIRMATION_SCREEN';

const StyledTakeABrake = styled('div')(
	(props) => css`
	label: TakeABrake;
	max-width: 100%;
`,

);

class TakeABreak extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** User's id */
		userId: PropTypes.number.isRequired,

		/** Function for setting the break duration */
		setBreakDuration: PropTypes.func.isRequired,

		/** GA Tracking for when a tab is clicked */
		onTabClick: PropTypes.func.isRequired,

		/** Handle clearing the error message */
		clearError: PropTypes.func.isRequired,

		/** Error returned from the function setBreakDuration */
		error: PropTypes.string,

		/** Extra classes */
		className: PropTypes.string,

		/** If the request is loading or not */
		loading: PropTypes.bool,
	};

	static defaultProps = {
		className: null,
		error: null,
		loading: false,
	};

	state = {
		flow: SHORT_BREAK_SCREEN,
		breakLabel: this.props.t('TakeABreak__1Day'),
		breakValue: 1,
	};

	/**
	 * handle content change when clicking on a tab item
	 * GA Tracking for tab changes
	 *
	 * @param data
	 */
	handleTabChange = (data) => {
		this.props.onTabClick(data === SHORT_BREAK_SCREEN ? this.props.t('Short') : this.props.t('Long'));

		this.setState({
			flow: data,
			breakLabel:
				data === SHORT_BREAK_SCREEN ? this.props.t('TakeABreak__1Day') : this.props.t('TakeABreakLong__6MonthsLabel'),
			breakValue: data === SHORT_BREAK_SCREEN ? 1 : 182,
		});
	};

	/**
	 * handle changes in the break duration selection
	 *
	 * @param name
	 * @param value
	 * @param label
	 */
	handleSelection = (name, value, label) => {
		this.setState({
			breakLabel: label,
			breakValue: parseInt(value),
		});
	};

	/**
	 * redirect the user to the confirmation page
	 * when clicking on 'Take...Break' button
	 */
	handleSetBreak = () => {
		this.setState({
			flow: CONFIRMATION_SCREEN,
		});
	};

	/**
	 * redirects the user to the short break page
	 * when the 'Changed my mind' link is clicked
	 */
	handleCancelBreak = () => {
		// clear the error message
		this.props.clearError();

		if (this.state.breakValue > 42) {
			this.setState({
				flow: LONG_BREAK_SCREEN,
				breakLabel: this.props.t('TakeABreakLong__6MonthsLabel'),
				breakValue: 182,
			});
		} else {
			this.setState({
				flow: SHORT_BREAK_SCREEN,
				breakLabel: this.props.t('TakeABreak__1Day'),
				breakValue: 1,
			});
		}
	};

	/**
	 * hook for blocking the user for the selected duration
	 */
	handleConfirmBreak = () => {
		this.props.setBreakDuration(this.state.breakValue);
	};

	render() {
		let content;

		const { className, error, clearError, loading, t } = this.props;

		const takeBreakClass = cx({
			[className]: className,
		});

		if (this.state.flow === SHORT_BREAK_SCREEN || LONG_BREAK_SCREEN) {
			content = (
				<StyledTakeABrake className={takeBreakClass}>
					<Tabs justify padding={Tabs.paddings.SPACING_COMPACT}>
						<TabsItem
							action={this.handleTabChange}
							data={SHORT_BREAK_SCREEN}
							active={this.state.flow === SHORT_BREAK_SCREEN}
						>
							{t('TakeABreak__Short')}
						</TabsItem>
						<TabsItem
							action={this.handleTabChange}
							data={LONG_BREAK_SCREEN}
							active={this.state.flow === LONG_BREAK_SCREEN}
						>
							{t('TakeABreak__Long')}
						</TabsItem>
					</Tabs>

					<Panel padding={Panel.padding.SPACING_COZY}>
						{this.state.flow === SHORT_BREAK_SCREEN ? (
							<TakeABreakShort onSelection={this.handleSelection} />
						) : (
							<TakeABreakLong onSelection={this.handleSelection} />
						)}

						<Button action={this.handleSetBreak} type="danger" block className={css`borde-radius:4px;`}>
							{t('TakeABreak__TakeBreak', { breakLabel: t(this.state.breakLabel) })}
						</Button>
					</Panel>
				</StyledTakeABrake>
			);
		}

		if (this.state.flow === CONFIRMATION_SCREEN) {
			content = (
				<div className={takeBreakClass}>
					<TakeABreakConfirmation
						breakLabel={this.state.breakLabel}
						confirmBreak={this.handleConfirmBreak}
						cancelBreak={this.handleCancelBreak}
						error={error}
						clearError={clearError}
						loading={loading}
					/>
				</div>
			);
		}

		return content;
	}
}

export default withNamespaces()(TakeABreak);
