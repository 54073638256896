import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { ui, Tabs } from '@tbh/ui-kit';
import UserAccountNavItem from './UserAccountNavItem/UserAccountNavItem';

import { USER_ACCOUNT_SUBMENU_HEADER } from '../../../../pages/UserAccount/UserAccountPageConstants';

const StyledUserAccountNavList = styled(Tabs)`
	label: UserAccountNavList;

	${(props) => {
		if (props.subMenu) {
			return css`
				background-color: ${ui(props).color_1};
			`;
		} else if (!props.isColumn) {
			return css`
				background-color: ${ui(props).color_2};
			`;
		}
	}};
`;

class UserAccountNavList extends React.PureComponent {
	constructor(props) {
		super(props);

		/**
		 * Initiate submenu toggle state.
		 */
		this.state = props.items.reduce((acc, item) => {
			if (item.type === USER_ACCOUNT_SUBMENU_HEADER && item.items) {
				acc[item.id] = false;
			}

			return acc;
		}, {});
	}

	/**
	 * Toggle state for the submenu under state
	 * @param id
	 */
	handleSubmenuToggle = (id) => {
		this.setState({
			[id]: !this.state[id],
		});
	};

	/**
	 * Handle menu item selection.
	 * @param id
	 */
	handleItemClick = (id) => {
		const item = this.props.items.find((item) => item.id === id);
		this.props.onItemClick(item);
	};

	/**
	 * Checks if submenu is open
	 */
	isOpen = (item) => item.type === USER_ACCOUNT_SUBMENU_HEADER && item.items && this.state[item.id];

	/**
	 * Builds submenu for given item
	 * @param item
	 * @return {*}
	 */
	buildSubmenu = (item) => {
		if (this.isOpen(item)) {
			return (
				<UserAccountNavList
					key={`${item.id}-submenu`}
					t={this.props.t}
					noBorder
					isSubMenu
					onItemClick={this.props.onItemClick}
					items={item.items}
					isMobile={this.props.isMobile}
					activeItem={this.props.activeItem}
				/>
			);
		}

		return null;
	};

	render() {
		const { className, items, activeItem, isMobile, isSubMenu, t } = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledUserAccountNavList
				className={componentClasses}
				isColumn={!isMobile}
				subMenu={isMobile && isSubMenu}
				wrap
				noBorder

			>
				{items.map((item) => {
					return [
						<UserAccountNavItem
							key={item.id}
							isActive={item.id === activeItem}
							onClick={item.items ? this.handleSubmenuToggle : this.handleItemClick}
							isMobile={isMobile}
							isOpen={this.isOpen(item)}
							{...item}
							title={t(`UserAccountNavList__${item.id}`)}
							mobileTitle={t(`UserAccountNavList__${item.id}--mobile`)}
						/>,
						this.buildSubmenu(item),
					];
				})}
			</StyledUserAccountNavList>
		);
	}
}

UserAccountNavList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Click handler */
	onItemClick: PropTypes.func.isRequired,

	/** Nav items */
	items: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			items: PropTypes.array,
			id: PropTypes.string.isRequired,
		}),
	).isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Current selected Item */
	activeItem: PropTypes.string,

	/** Mobile version */
	isMobile: PropTypes.bool,

	/** Whether is nested menu */
	isSubMenu: PropTypes.bool,
};

UserAccountNavList.defaultProps = {
	className: '',
	activeItem: undefined,
	isMobile: false,
	isSubMenu: false,
};

export default withNamespaces()(UserAccountNavList);
