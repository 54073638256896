import {
	PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_COMPLETED,
	PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_STARTED,
	PROMOTIONS_PAGE_SET_SELECTED_POST,
	PROMOTIONS_PAGE_SET_SHOW_TERMS,
	FETCH_PROMOTIONS_START,
	FETCH_PROMOTIONS_SUCCESS,
	FETCH_PROMOTIONS_FAILURE
} from './promotionsPageActionTypes';

const initialState = {
	selectedPostId: null,
	loadingCompleted: false,
	loadingError: null,
	showTerms: false,
	promotions: [], //
	isLoading: false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case PROMOTIONS_PAGE_SET_SELECTED_POST:
			return {
				...state,
				selectedPostId: action.payload,
			};

		case PROMOTIONS_PAGE_SET_SHOW_TERMS:
			return {
				...state,
				showTerms: action.payload,
			};

		case PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_STARTED:
			return {
				...state,
				loadingCompleted: false,
				loadingError: null,
			};

		case PROMOTIONS_PAGE_SET_LOADING_PROMOTIONS_COMPLETED: {
			let newState = { ...state };

			if (action.payload) {
				newState.loadingError = action.payload;
			}

			return {
				...newState,
				loadingCompleted: true,
			};

		}
		case FETCH_PROMOTIONS_START:
			return {
				...state,
				isLoading: true,
				error: null
			};
		case FETCH_PROMOTIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
				promotions: action.payload
			};
		case FETCH_PROMOTIONS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.payload
			};

		default:
			return state;
	}
};
