import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';

import { spacings, Button, Text, Panel, PlotElements } from '@tbh/ui-kit';
import DownloadAppButton from '../DownloadAppButton/DownloadAppButton';
import IntercomIcon from '../IntercomIcon/IntercomIcon';

const StyledVersionNotSupported = styled(PlotElements)`
	label: VersionNotSupported;

	max-width: 400px;
	margin: auto;
`;

const StyledVersionNotSupported__Panel = styled(Panel)(
	(props) => css`
		label: VersionNotSupported__Panel;

		margin: ${spacings(props).cozy}px;
	`,
);

const cssVersionNotSupported__RefreshButton = css`
	label: VersionNotSupported__RefreshButton;

	margin: auto;
	display: block;
`;

const VersionNotSupported = (props) => {
	const { className, cordovaLoaded, loadedPlatform } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const reloadPage = () => {
		window.location.reload(true);
	};

	return (
		<StyledVersionNotSupported className={componentClasses}>
			<StyledVersionNotSupported__Panel borderType={Panel.borderTypes.DANGER} padding={Panel.padding.SPACING_COZY}>
				<Text align="center" paragraph strong type="danger">
					The version of the app that you have loaded is no longer supported.
				</Text>
				<Text align="center" paragraph>
					You will need to update before you can use the app again.
				</Text>
				{cordovaLoaded ? (
					<DownloadAppButton platform={loadedPlatform} />
				) : (
					<PlotElements>
						<Button
							className={cssVersionNotSupported__RefreshButton}
							icon="spinner"
							action={reloadPage}
							type="calltoaction"
						>
							Try Again
						</Button>
						<IntercomIcon width={36} />
					</PlotElements>
				)}
			</StyledVersionNotSupported__Panel>
		</StyledVersionNotSupported>
	);
};

VersionNotSupported.propTypes = {
	/** Has the app been loaded on a desktop device */
	cordovaLoaded: PropTypes.bool,

	/** Has the app been loaded on a desktop device */
	loadedPlatform: PropTypes.oneOf(['ios', 'android']),

	/** Extra classes */
	className: PropTypes.string,
};

VersionNotSupported.defaultProps = {
	cordovaLoaded: false,
	loadedPlatform: 'android',
	className: '',
};

export default VersionNotSupported;
