/**
 * Created by Josef Frank on 2017/05/12.
 */
'use strict';

// Device width limits
const MOBILE_MAX_WIDTH = 480;
const SMALL_DESKTOP_MAX_WIDTH = 1024;
const TABLET_MAX_WIDTH = 768;

module.exports = {
	// Device width limits
	SMALL_DESKTOP_MAX_WIDTH,
	MOBILE_MAX_WIDTH,
	TABLET_MAX_WIDTH,
};