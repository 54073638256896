import { errorString } from '../../legacy/core/format';
import { fetchCreditCards } from '../entities/actions/CreditCardActions';
import { createBankAccount, fetchBankAccounts, deleteBankAccount } from '../entities/actions/BankAccountActions';
import { createAction } from '../../common/actions/actionHelpers';
import {
	WITHDRAWALS_CLEAR_ENTITIES,
	WITHDRAWALS_CLEAR_STATE,
	WITHDRAWALS_SET_DATA_LOADED,
	WITHDRAWALS_SET_ERRORS,
	WITHDRAWALS_SET_CREATING_WITHDRAWAL,
	WITHDRAWALS_SET_RECEIPT_ID,
	WITHDRAWALS_SET_CREATING_BANK_ACCOUNT,
	WITHDRAWALS_SET_LAST_CREATED_ACCOUNT,
	WITHDRAWALS_SET_SHOULD_VERIFY,
	WITHDRAWALS_FIRST_DATA_LOADED_BATCH,
} from './withdrawalPromptActionTypes';
import { batchActions } from 'redux-batched-actions';
import { createBankWithdrawal } from '../entities/actions/WithdrawalActions';
import { findUnverifiedCreditCard } from '../entities/selectors/CreditCardSelectors';
/**
 * Action to control state after the data has been loaded or not
 */
export const fetchWithdrawalPromptData = () => (dispatch) => {
	return Promise.all([dispatch(fetchCreditCards()), dispatch(fetchBankAccounts())])
		.then((responses) => {
			const creditCards = responses[0];
			const unverifiedCard = findUnverifiedCreditCard(creditCards);

			// If an unverified card was found, shouldVerifyCards = true
			const shouldVerifyCards = !!unverifiedCard;
			dispatch(
				batchActions(
					[setHasDataLoaded(true), setShouldVerifyCards(false)],
					WITHDRAWALS_FIRST_DATA_LOADED_BATCH,
				),
			);
		})
		.catch((error) => {
			if (error.response && error.response.data.http_status_code == 429) return null;

			document.Sentry && document.Sentry.captureException(error);

			const errors = error.response ? error.response.data.errors : 'An unknown error has occurred';
			dispatch(
				batchActions(
					[setHasDataLoaded(true), setWithdrawalErrorMessages(errorString(errors))],
					WITHDRAWALS_FIRST_DATA_LOADED_BATCH,
				),
			);
		});
};

/**
 * Place withdrawal with existing account.
 *
 * @param amount - in cents.
 * @param bankAccountId
 * @return {Promise}
 */
export const createNewWithdrawal = (amount, bankAccountId) => (dispatch) => {
	dispatch(setCreatingWithdrawalMask(true));
	return dispatch(createBankWithdrawal(amount, bankAccountId))
		.then((withdrawalId) => {
			dispatch(setWithdrawalReceiptId(withdrawalId));
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			dispatch(setWithdrawalErrorMessages(errorString(error.response.data.errors)));
		})
		.then(() => {
			dispatch(setCreatingWithdrawalMask(false));
		});
};

/**
 * Use entity endpoint to create Bank account, adding control to prompt state.
 *
 * @param bsb
 * @param accountNumber
 * @param accountName
 * @param selectedBank
 */
export const createNewBankAccount = (bsb, accountNumber, accountName, selectedBank) => (dispatch) => {
	dispatch(setCreatingBankAccountMask(true));
	return dispatch(createBankAccount(bsb, accountNumber, accountName, selectedBank))
		.then((response) => {
			dispatch(setLastCreatedBankAccount(response.data.data.id));
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			dispatch(setWithdrawalErrorMessages(errorString(error.response.data.errors)));
		})
		.then(() => {
			dispatch(setCreatingBankAccountMask(false));
		});
};

/**
 * Sends delete request to delete Bank account, controlling prompt state.
 *
 * @param id
 */
export const deleteAccount = (id) => (dispatch) => {
	dispatch(setCreatingBankAccountMask(true));
	return dispatch(deleteBankAccount(id))
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			dispatch(setWithdrawalErrorMessages(errorString(error.message)));
		})
		.then(() => {
			dispatch(setCreatingBankAccountMask(false));
		});
};

/**
 * Set errors related
 * @param errors
 */
export const setWithdrawalErrorMessages = (errors) => createAction(WITHDRAWALS_SET_ERRORS, errors);

/**
 * Clears withdrawal prompt slice
 */
const clearStateSlice = () => createAction(WITHDRAWALS_CLEAR_STATE);

/**
 * Clear entities slices related to withdrawals
 */
const clearEntitySlices = () => createAction(WITHDRAWALS_CLEAR_ENTITIES);

/**
 * Whether should render verify message and disable withdrawals.
 *
 * @param bool
 */
const setShouldVerifyCards = (bool) => createAction(WITHDRAWALS_SET_SHOULD_VERIFY, bool);

/**
 * Whether needed data has been loaded.
 * Important to reason about the number of cards the user has.
 *
 * @param bool
 */
const setHasDataLoaded = (bool) => createAction(WITHDRAWALS_SET_DATA_LOADED, bool);

/**
 * Set pending withdrawal id to render receipt
 * @param id
 */
export const setWithdrawalReceiptId = (id) => createAction(WITHDRAWALS_SET_RECEIPT_ID, id);

/**
 * Loading mask for withdrawal creation.
 * @param isLoading
 */
const setCreatingWithdrawalMask = (isLoading) => createAction(WITHDRAWALS_SET_CREATING_WITHDRAWAL, isLoading);

/**
 * Loading mask for bank account creation.
 * @param isLoading
 */
const setCreatingBankAccountMask = (isLoading) => createAction(WITHDRAWALS_SET_CREATING_BANK_ACCOUNT, isLoading);

/**
 * Set in state the bank account just created.
 * @param id
 */
const setLastCreatedBankAccount = (id) => createAction(WITHDRAWALS_SET_LAST_CREATED_ACCOUNT, id);
