// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Actions
import { blockUser } from '../../../store/entities/actions/UserActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { logoutUser } from '../../../store/authentication/authenticationActions';

// Components
import TakeBreakSelection from '../../../components/features/ResponsibleGambling/TakeABreak/TakeABreak';

class TakeABreakContainer extends Component {
	static propTypes = {
		/** User's id */
		userId: PropTypes.number.isRequired,

		/** Hook for blocking the user for the selected duration */
		handleBlockUser: PropTypes.func.isRequired,

		/**
		 * GA Tracking for when a tab is clicked
		 * Category | Action | Label
		 * TakeABreak | Select | Short/Long
		 */
		onTabClick: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
	};

	state = {
		error: null,
		loading: false,
	};

	/**
	 * Calls the action to block the user
	 * Set an error message if the action fails
	 *
	 * @param breakDuration
	 */
	handleBlockUser = (breakDuration) => {
		this.setLoadingMask(true);

		return this.props
			.handleBlockUser(breakDuration)
			.catch((error) => {
				if (error.response.status === 400) {
					this.setState({
						error: error.response.data.errors,
					});
				} else {
					this.setState({
						error: error.message,
					});
				}
			})
			.finally(() => {
				this.setLoadingMask(false);
			});
	};

	/**
	 * Set a loading mask
	 *
	 * @param loading
	 */
	setLoadingMask(loading = false) {
		this.setState({
			loading: loading,
		});
	}

	/**
	 * Remove the error message
	 */
	handleClearError = () => {
		this.setState({
			error: null,
		});
	};

	render() {
		const { className, userId, onTabClick } = this.props;
		const { error, loading } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		return (
			<TakeBreakSelection
				className={containerClasses}
				userId={userId}
				setBreakDuration={this.handleBlockUser}
				onTabClick={onTabClick}
				clearError={this.handleClearError}
				error={error}
				loading={loading}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userId: state.application.authenticatedUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleBlockUser: (breakDuration) => {
			return dispatch(blockUser(breakDuration)).then(() => dispatch(logoutUser()));
		},
		onTabClick: (type) => {
			return dispatch(trackGaEvent('TakeABreak', 'Select', type));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(TakeABreakContainer);
