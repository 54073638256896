import { createSelector } from 'reselect';
import { sortFeatureRacesByStartTime } from '../../store/entities/selectors/FeatureRaceSelectors';
import { denormalizeExpertRaces } from '../../store/entities/schemas/ExpertRaceSchema';

const getExpertRaces = createSelector(
	(state) => state.entities.expertRaces,
	(expertRaces) => {
		return sortFeatureRacesByStartTime(denormalizeExpertRaces({ expertRaces }));
	},
);

export { getExpertRaces };