// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { application, media, brand, spacings, typography, ui, BetSlipButton, Icon, Text } from '@tbh/ui-kit';

import Format from '../../../../legacy/core/format';

const mediaQueryChangeOverSize = 481;

const StyledAffiliateMasthead = styled('div')(
	(props) => css`
		label: AffiliateMasthead;

		width: 100%;
		height: 70px;
		display: flex;
		align-items: center;
		background-color: ${brand(props).color_2};
	`,
	media(
		css`
			height: 90px;
		`,
		mediaQueryChangeOverSize,
	),
);

const StyledAffiliateMasthead__Wrapper = styled('div')(
	(props) => css`
		label: AffiliateMasthead__Wrapper;

		display: flex;
		width: 100%;
		justify-content: space-between;
		padding: 0 ${spacings(props).compact}px;
		margin: 0 auto;

		${!props.isFullWidth &&
			css`
				max-width: ${application(props).max}px;
			`};
	`,
);

const StyledAffiliateMasthead__UserInfo = styled('div')(
	(props) => css`
		label: AffiliateMasthead__UserInfo;

		display: flex;
		flex: 1 1 50%; // IE
		flex-flow: column;
		align-items: flex-end;
		margin: 0 ${spacings(props).compact}px;
	`,
	(props) =>
		media(
			css`
				margin: 0 ${spacings(props).cozy}px;
			`,
			mediaQueryChangeOverSize,
		),
);

const StyledAffiliateMasthead__UserAndBalance = styled('div')(
	media(
		css`
			label: AffiliateMasthead__UserAndBalance;

			display: flex;
		`,
		mediaQueryChangeOverSize,
	),
);

const StyledAffiliateMasthead__User = styled(Text)(
	(props) => css`
		label: AffiliateMasthead__User;

		padding: ${spacings(props).constrictive}px ${spacings(props).tight}px;
		background-color: ${brand(props).color_2_dark};
	`,
	(props) =>
		media(
			css`
				min-width: 120px;
				padding: ${spacings(props).tight}px ${spacings(props).tight}px;
			`,
			mediaQueryChangeOverSize,
			{ sizeKey: 'mediaSize' },
		),
);

const StyledAffiliateMasthead__Balance = styled(Text)(
	(props) => css`
		label: AffiliateMasthead__Balance;

		padding: ${spacings(props).constrictive}px ${spacings(props).tight}px;
		background-color: ${brand(props).color_3};
	`,
	(props) =>
		media(
			css`
				min-width: 120px;
				padding: ${spacings(props).tight}px ${spacings(props).tight}px;
			`,
			mediaQueryChangeOverSize,
			{ sizeKey: 'mediaSize' },
		),
);

const StyledAffiliateMasthead__Text = styled(Text)(
	(props) => css`
		label: AffiliateMasthead__Text;

		max-height: ${typography(props).size_0};
		margin-top: ${spacings(props).tight}px;
	`,
	media(
		css`
			flex: 1 1 auto;
		`,
		mediaQueryChangeOverSize,
		{ sizeKey: 'mediaSize' },
	),
);

const StyledAffiliateMasthead__TextIcon = styled(Icon)(
	(props) => css`
		label: AffiliateMasthead__TextIcon;

		color: ${ui(props).color_warning};
	`,
);

class AffiliateMasthead extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component */
		size: PropTypes.shape({
			/** How wide the component is */
			width: PropTypes.number,
		}).isRequired,

		/** Action to fire when the bet slip badge is clicked on */
		onBetSlipClick: PropTypes.func.isRequired,

		/** The current affiliates account balance */
		account_balance: PropTypes.number.isRequired,

		/** The current affiliates name */
		affiliate_name: PropTypes.string.isRequired,

		/** Whether or not there is a max-width restriction on the site */
		isFullWidth: PropTypes.bool.isRequired,

		/** Warning text to display about the affiliate */
		affiliate_text: PropTypes.string,

		/** The current affiliates username */
		affiliate_user: PropTypes.string,

		/** The total number of bets in the multi slip */
		bets: PropTypes.number,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		affiliate_text: null,
		affiliate_user: null,
		bets: 0,
		className: '',
	};

	state = { bets: null };

	static getDerivedStateFromProps(nextProps) {
		return {
			bets: nextProps.bets,
		};
	}

	render() {
		const {
			t,
			account_balance,
			affiliate_name,
			affiliate_text,
			affiliate_user,
			bets,
			className,
			onBetSlipClick,
			size,
			isFullWidth,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledAffiliateMasthead className={componentClasses} size={size}>
				<StyledAffiliateMasthead__Wrapper isFullWidth={isFullWidth}>
					<Text
						className={css`
							flex: 1 1 auto;
						`}
						type="primary"
						strong
					>
						{affiliate_name}
					</Text>

					<StyledAffiliateMasthead__UserInfo size={size}>
						<StyledAffiliateMasthead__UserAndBalance size={size}>
							<StyledAffiliateMasthead__User mediaSize={size} type="alternate" size="-2" align="center" strong>
								<Icon icon="user" size="-2" /> {affiliate_user}
							</StyledAffiliateMasthead__User>
							<StyledAffiliateMasthead__Balance mediaSize={size} type="alternate" size="-2" align="center" strong>
								{Format.centsAsDollars(account_balance)}
							</StyledAffiliateMasthead__Balance>
						</StyledAffiliateMasthead__UserAndBalance>

						<StyledAffiliateMasthead__Text
							mediaSize={size}
							type="alternate"
							size="-2"
							align="right"
							textOverflow="ellipsis"
							italic
						>
							<StyledAffiliateMasthead__TextIcon icon="warning" size="-2" /> {affiliate_text}
						</StyledAffiliateMasthead__Text>
					</StyledAffiliateMasthead__UserInfo>

					<BetSlipButton onClick={onBetSlipClick} bets={bets} buttonLabel={t('BetSlipButton__Label')} />
				</StyledAffiliateMasthead__Wrapper>
			</StyledAffiliateMasthead>
		);
	}
}

export default withNamespaces()(AffiliateMasthead);
