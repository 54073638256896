// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { spacings, typography, ui } from '@tbh/ui-kit';

// Components
import { BettingAdd, Link, Text, Icon } from '@tbh/ui-kit';

const StyledTrendingBet = styled('div')(
	(props) => css`
		label: TrendingBet;

		min-height: 90px;
		color: ${typography(props).color_1};
		padding: ${spacings(props).compact}px;
		border-bottom: 1px ${ui(props).color_3} solid;
	`,
);

const StyledTrendingBet__ZoneForSelection = styled('div')(
	(props) => css`
		label: TrendingBet__ZoneForSelection;

		display: flex;
		margin-bottom: ${spacings(props).compact}px;
	`,
);

const StyledTrendingBet__Icon = styled(Icon)(
	(props) => css`
		label: TrendingBet__Icon;

		flex: 0 0 20px;
		align-self: flex-start;
		margin-right: ${spacings(props).compact}px;
	`,
);

const StyledTrendingBet__BettingAdd = styled(BettingAdd)(
	(props) => css`
		label: TrendingBet__BettingAdd;

		margin-left: ${spacings(props).compact}px;
		align-self: flex-start;
		float: right;
	`,
);

/**
 * Trending Bet
 * ------------
 * Displays a singular trending bet.
 *
 * @module components/ui/TrendingBet
 */
const TrendingBet = (props) => {
	const { icon, id, eventName, marketName, selectionName, odds, line } = props;

	const onButtonClick = () => {
		props.onButtonClick(id);
	};

	const onEventClick = () => {
		props.onEventClick(id);
	};

	return (
		<StyledTrendingBet>
			<StyledTrendingBet__ZoneForSelection>
				<StyledTrendingBet__Icon icon={icon} size="1" />

				<div
					className={css`
						flex: 1;
					`}
				>
					<Text size="-1" strong>
						{selectionName}
					</Text>
					<Text size="-1" capitalize>
						{marketName}
					</Text>
				</div>

				<StyledTrendingBet__BettingAdd key={id} size="2" metric={odds} line={line} actionSingle={onButtonClick} />
			</StyledTrendingBet__ZoneForSelection>
			<Link type="primary" size="-1" action={onEventClick} block>
				{eventName}
			</Link>
		</StyledTrendingBet>
	);
};

TrendingBet.propTypes = {
	/** The event name for the bet selection */
	eventName: PropTypes.string.isRequired,

	/** The icon for the event */
	icon: PropTypes.string.isRequired,

	/** The ID of the bet */
	id: PropTypes.number.isRequired,

	/** The market the bet belongs to */
	marketName: PropTypes.string.isRequired,

	/** The bet odds */
	odds: PropTypes.number.isRequired,

	/** Action if the bet odds are clicked on */
	onButtonClick: PropTypes.func.isRequired,

	/** Action if the event link is clicked on */
	onEventClick: PropTypes.func.isRequired,

	/** The name of the selection in the bet */
	selectionName: PropTypes.string.isRequired,

	/** The bet line */
	line: PropTypes.number,
};

TrendingBet.defaultProps = {
	line: 0,
};

export default TrendingBet;
