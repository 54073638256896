// import {slugify} from '../legacy/core/format';
import {
	USER_ACCOUNT_BASE_URL,
	USER_ACCOUNT_SUB_APP,
	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
	USER_ACCOUNT_BETS_TRANSACTIONS_URL,
	USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL,
	USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL,
	USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL,
	USER_ACCOUNT_PAGES,
	DEPOSITS_BASE_URL,
} from '../pages/UserAccount/UserAccountPageConstants';
import { setUserAccountPage } from '../store/userAccount/userAccountActions';
import { routeTo, scrollToTop, closeSideBetSlip } from '../store/application/applicationActions';
import { getAuthenticatedUser } from '../store/application/applicationSelectors';
import { openModal } from '../store/modal/modalActions';

const { dispatch, getState } = App.store;

const UserAccountRouter = Backbone.Router.extend({
	routes: {
		[USER_ACCOUNT_BASE_URL]: 'showUserAccount',
		[USER_ACCOUNT_BASE_URL + '/:subPage']: 'showUserAccount',
		[USER_ACCOUNT_BASE_URL + '/:subPage/:filter']: 'showUserAccount',
		[DEPOSITS_BASE_URL + ('(/:type)' || '/(:type)')]: 'showExternalDepositResult',
	},

	showUserAccount: function(subPage, filter) {
		if (!getAuthenticatedUser(getState())) {
			dispatch(routeTo('/welcome'));
			return;
		}

		scrollToTop();

		const filters = [
			USER_ACCOUNT_ALL_TRANSACTIONS_URL,
			USER_ACCOUNT_BETS_TRANSACTIONS_URL,
			USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL,
			USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL,
			USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL,
			USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL,
			USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL,
			USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL,
		];

		const currentFilter = !filter || filters.indexOf(filter) === -1 ? USER_ACCOUNT_ALL_TRANSACTIONS_URL : filter;

		if (!subPage || USER_ACCOUNT_PAGES.indexOf(subPage) > -1) {
			dispatch(setUserAccountPage(subPage, currentFilter));
			dispatch(closeSideBetSlip(true));
			App.startSubApp(USER_ACCOUNT_SUB_APP);
		} else {
			dispatch(setUserAccountPage(null, USER_ACCOUNT_ALL_TRANSACTIONS_URL));
			dispatch(closeSideBetSlip(true));
			App.startSubApp(USER_ACCOUNT_SUB_APP);
		}
	},

	goToUserPage: function() {
		if (!getAuthenticatedUser(getState())) {
			dispatch(routeTo('/welcome'));
			return;
		}
		dispatch(routeTo('/user'));
	},

	/**
	 * Notify user the result of external deposits
	 * @param status
	 */
	showExternalDepositResult: function(status) {
		const statuses = ['success', 'failed'];
		if (statuses.indexOf(status) === -1) {
			this.goToUserPage();
		} else {
			dispatch(
				openModal({
					id: 'ExternalDepositResult',
					Component: 'ExternalDepositResult',
					config: {
						title: 'POLi Deposit',
					},
					props: {
						depositSuccessful: status !== 'failed',
					},
				}),
			);
		}
	},
});

module.exports = new UserAccountRouter();
