import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings } from '@tbh/ui-kit';

import DerivativeItem from '../DerivativeItem/DerivativeItem';
import { HeaderBar } from '@tbh/ui-kit';

const StyledDerivativeSelectionList__Item = styled(DerivativeItem)(
	(props) => css`
		label: DerivativeSelectionList__Item;

		margin-bottom: ${spacings(props).compact}px;
	`,
);

const StyledDerivativeSelectionList = styled('div')(
	(props) => css`
		label: DerivativeSelectionList;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

class DerivativeSelectionList extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The ID of the race for the race card */
		raceId: PropTypes.number.isRequired,

		/** List of derivative markets */
		derivativeMarkets: PropTypes.arrayOf(
			PropTypes.shape({
				/** The derivative market ID */
				id: PropTypes.number.isRequired,

				/** The derivative market type code */
				market_type_code: PropTypes.string.isRequired,

				/** The derivative selections for betting on */
				derivative_selections: PropTypes.arrayOf(
					PropTypes.shape({
						/** The ID of the derivative selection */
						id: PropTypes.number.isRequired,

						/** The list of selections (same as our other entity selections) inside of the derivative selection */
						base_selections: PropTypes.array.isRequired,
					}),
				).isRequired,
			}),
		).isRequired,

		/** Handle when a derivative selection is clicked on */
		handleDerivativeSelectionClick: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Boolean indicating if betting is available ie. race isn't closed */
		bettingAvailable: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		bettingAvailable: true,
	};

	state = {
		raceId: null,
		derivativeMarketsStatus: {},
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		// Check if the race is being set, or has changed
		if (nextProps.raceId !== prevState.raceId) {
			return {
				raceId: nextProps.raceId,
				derivativeMarketsStatus: {},
			};
		}

		return null;
	}

	/**
	 * Toggle the derivative market open or closed
	 *
	 * @param marketId
	 */
	toggleDerivativeMarketHeader = (marketId) => {
		this.setState({
			derivativeMarketsStatus: {
				...this.state.derivativeMarketsStatus,
				[marketId]: {
					...this.state.derivativeMarketsStatus[marketId],
					open: this.state.derivativeMarketsStatus[marketId]
						? !this.state.derivativeMarketsStatus[marketId].open
						: false,
				},
			},
		});
	};

	/**
	 * Filter the markets of same market type
	 *
	 * @param market_type_code
	 */
	getMarketsOfType = (market_type_code) => {
		return this.props.derivativeMarkets.filter((market) => market.market_type_code === market_type_code);
	};

	/**
	 * Build the derivative market title
	 *
	 * @param derivativeMarket
	 * @returns {string}
	 */
	getMarketTitle = (derivativeMarket) => {
		const marketsOfTypeArray = this.getMarketsOfType(derivativeMarket.market_type_code);
		const index = marketsOfTypeArray.findIndex((market) => market.id === derivativeMarket.id);

		return (
			this.props.t(`DerivativeSelectionList__Label--${derivativeMarket.market_type_code}`) +
			(marketsOfTypeArray.length > 1 ? ` ${index + 1}` : '')
		);
	};

	render() {
		const { t, className, bettingAvailable, derivativeMarkets, handleDerivativeSelectionClick, size } = this.props;
		const { derivativeMarketsStatus } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledDerivativeSelectionList className={componentClasses}>
				<HeaderBar type={HeaderBar.types.SUBHEADING}>{t('DerivativeSelectionList__Heading')}</HeaderBar>
				{[...derivativeMarkets]
					.sort((a, b) => {
						return a.market_type_code.localeCompare(b.market_type_code) || a.id - b.id;
					})
					.map((derivativeMarket) => {
						return derivativeMarket.derivative_selections ? (
							<StyledDerivativeSelectionList__Item
								key={derivativeMarket.id}
								marketId={derivativeMarket.id}
								bettingAvailable={bettingAvailable}
								marketType={derivativeMarket.market_type_name}
								marketTypeCode={derivativeMarket.market_type_code}
								handleSelectionClick={handleDerivativeSelectionClick}
								showDerivativeContent={
									!derivativeMarketsStatus[derivativeMarket.id] || derivativeMarketsStatus[derivativeMarket.id].open
								}
								toggleContent={this.toggleDerivativeMarketHeader}
								title={this.getMarketTitle(derivativeMarket)}
								selections={derivativeMarket.derivative_selections}
								derivativeIcons={
									// TODO: Use the proper key/icon logic when icons are finalised
									derivativeMarket.market_icons ? derivativeMarket.market_icons : []
								}
								size={size}
							/>
						) : null;
					})}
			</StyledDerivativeSelectionList>
		);
	}
}

export default withNamespaces()(DerivativeSelectionList);
