import { PLAYER_BETS_BASE_URL, PLAYER_BETS_SUB_APP } from '../common/constants/PlayerBetsHome';
import { setBodyLoading, scrollToTop } from '../store/application/applicationActions';

const PlayerBetsRouter = Backbone.Router.extend({
	routes: {
		[PLAYER_BETS_BASE_URL]: 'showPlayerBetsHome',
	},

	showPlayerBetsHome: function() {
		App.store.dispatch(setBodyLoading(true));
		scrollToTop();
		App.startSubApp(PLAYER_BETS_SUB_APP);
	},
});

module.exports = new PlayerBetsRouter();
