import moment from 'moment';
import { serverDateTime } from '../legacy/core/format';
import { FEATURE_RACING_BASE_URL, FEATURE_RACING_SUB_APP } from '../pages/FeatureRacing/FeatureConstants';
import { FEATURE_HOME_LOADING__BATCH, FEATURE_HOME_DONE__BATCH} from '../store/featureRacingHome/featureRacingHomeActionTypes';

import {
  fetchMeetingsAndRaces,
} from '../store/entities/actions/MeetingActions';

// import{ setShowingMeetings,} from '../store/GRSracingHome/GRSGRSracingHomeActions';
// import {
//   resetRacingFilters, setContainerLoadingMask, 
//   setSelectedDate,
  
//   setShowingNTJRaces,
//   setShowingFeatureRaces,
// } from '../store/featureRacingHome/featureGRSracingHomeActions';  
import { batchActions } from 'redux-batched-actions';

import { show404, scrollToTop, setBodyLoading , closeSideBetSlip } from '../store/application/applicationActions';
import { fetchGRSFeatureRaces, fetchGRSFeatureRacesWithoutDate } from '../store/entities/actions/FeatureRaceActions';
import { fetchNewNextToJumpRaces_GRS } from './../store/newNextToJumpRaces/newNextToJumpRacesActions';
const { dispatch } = App.store;

const FeatureRacingRouter = Backbone.Router.extend({
    routes: {
        [FEATURE_RACING_BASE_URL] : 'showFuturePage',
        [FEATURE_RACING_BASE_URL + '/:date'] : 'showFeatureRacesForDate',
    },

     /**
     * make new router with loading nextjummp data
     * @HW12Oct2020
     */
    showFuturePage: async () =>{
      let date = serverDateTime(moment()).format('YYYY-MM-DD');//moment().format('YYYY-MM-DD');
      let type = 'fr'; // feature races
      let limit = 30;
      /**
       * Reset filters and render loading mask for the whole container
       */
      dispatch(
        batchActions(
          [setBodyLoading(true), resetRacingFilters(), closeSideBetSlip(true), setSelectedDate(date)],
          FEATURE_HOME_LOADING__BATCH,
        ),
      );
      try {
        let [response, ntjResponse, feResponse, feAllResponse] = await Promise.all([
          dispatch(fetchMeetingsAndRaces()),
          dispatch(fetchNewNextToJumpRaces_GRS()), // GRS api route
          dispatch(fetchGRSFeatureRaces(date, type, limit)),
          dispatch(fetchGRSFeatureRacesWithoutDate(type, limit)),//withpout date get all races API
        ]);
        scrollToTop();
        dispatch(
          batchActions(
            [
              // setShowingMeetings(response.meetingIds),
              // setShowingNTJRaces(ntjResponse.ntjRaceIds), // change 
              // setShowingFeatureRaces(feResponse.featureRaceIds),
              // setShowingFeatureRaces(feAllResponse.featureRaceIds),
              // setContainerLoadingMask(false),
              // setBodyLoading(false),
              // setSelectedDate(date),
            ],
            FEATURE_HOME_DONE__BATCH,
          ),
        );
        App.startSubApp(FEATURE_RACING_SUB_APP);
      } catch (e) {
       // console.log(e);
        dispatch(show404());
      }
    },


    /**
     * when selected date
     * @param {*} date 
     */
    showFeatureRacesForDate: async function(date) {
      let userDate = moment(date);
      let type = 'fr'; // feature races
      let limit = 30;
      if (!userDate.isValid()) {
        return this.showFuturePage();
      }
  
      dispatch(
        batchActions(
          [setContainerLoadingMask(true), resetRacingFilters(), closeSideBetSlip(true), setSelectedDate(userDate)],
          FEATURE_HOME_LOADING__BATCH,
        ),
      );
  
      try {
        let serverDate = serverDateTime(userDate);
        let [response, ntjResponse, feResponse, feAllResponse] = await Promise.all([
          dispatch(fetchMeetingsAndRaces(serverDate.format('YYYY-MM-DD'))),
          dispatch(fetchNewNextToJumpRaces_GRS()),
          dispatch(fetchGRSFeatureRaces(serverDate.format('YYYY-MM-DD'), type, limit)),
          dispatch(fetchGRSFeatureRacesWithoutDate(type, limit)),//withpout date get all races API
        ]);
  
        scrollToTop();
        dispatch(
          batchActions(
            [
              // setShowingNTJRaces(ntjResponse.ntjRaceIds),
              // setShowingMeetings(response.meetingIds),
              // setShowingFeatureRaces(feResponse.featureRaceIds),
              // setShowingFeatureRaces(feAllResponse.featureRaceIds),
              // setContainerLoadingMask(false),
              // setBodyLoading(false),
              // setSelectedDate(userDate),
            ],
            FEATURE_HOME_DONE__BATCH,
          ),
        );
        App.startSubApp(FEATURE_RACING_SUB_APP);
      } catch (e) {
        //console.log(e);
        dispatch(show404());
      }
    },

  
});

module.exports = new FeatureRacingRouter();