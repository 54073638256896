import {
	RACING_AU,
	RACING_COUNTRY_IMPORTANCE_LOOKUP,
	RACING_THOROUGHBRED_CODE,
	RACING_GRADE_IMPORTANCE_LOOKUP,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_STATE_IMPORTANCE_LOOKUP,
	RACING_INT,
	RACING_GROUP_TITLE_OTHER,
	RACING_GROUP_TITLE_LOCAL,
	RACING_GROUP_TITLE_INTERNATIONAL,
	RACING_GROUP_TITLE_THOROUGHBRED,
	RACING_GROUP_GALLOPS,
	RACE_ABANDONED_STATUS,
	RACE_PAID_STATUS,
	RACE_FINISHED_STATUS,
	RACE_CLOSED_STATUS,
	RACE_SUSPENDED_STATUS, //@HW
} from '../../../common/constants/Racing';
import { sortMeetingGroups } from '../../../pages/Racing/RacingHome/racingSelectorsGRS';

/**
 * To be applied to Array.sort(). Sort meetings by resulted and un-resulted,
 * and each groups sort them by grade.
 *
 * Return value varies between -1, 0, 1 according to FR rules.
 * @return number
 * @param mA
 * @param mB
 */
export const compareMeetingsByStatusAndGrade = (mA, mB) => {
	if (!isResulted(mA) && !isResulted(mB)) {
		return compareByGrade(mA, mB);
	}

	if (!isResulted(mA) && isResulted(mB)) {
		return -1;
	}

	if (isResulted(mA) && !isResulted(mB)) {
		return 1;
	}

	if (isResulted(mA) && isResulted(mB)) {
		return compareByGrade(mA, mB);
	}

	return 0;
};

/**
 * Considers the grades importance declared in the constant RACING_GRADE_IMPORTANCE_LOOKUP.
 * The grades order should be as follows:
 * METRO > PROVINCIAL > COUNTRY
 *
 * @param meetingA
 * @param meetingB
 * @return {number}
 */
export const compareByGrade = (meetingA, meetingB) => {
	if (meetingA.grade === meetingB.grade) {
		return compareByCountry(meetingA, meetingB);
	}
	return (RACING_GRADE_IMPORTANCE_LOOKUP[meetingA.grade] || 0) > (RACING_GRADE_IMPORTANCE_LOOKUP[meetingB.grade] || 0)
		? -1
		: 1;
};

/**
 * Considers the country importance declared in the constant RACING_COUNTRY_IMPORTANCE_LOOKUP.
 *
 * @param meetingA
 * @param meetingB
 * @return {number}
 */
export const compareByCountry = (meetingA, meetingB) => {
	if (meetingA.country === meetingB.country) {
		// If Australia compare states, otherwise by state name
		if (meetingA.country === RACING_AU) {
			return compareByAustralianState(meetingA, meetingB);
		} else {
			return compareByString(meetingA, meetingB, 'state', 'name');
		}
	}
	return (RACING_COUNTRY_IMPORTANCE_LOOKUP[meetingA.country] || 0) >
		(RACING_COUNTRY_IMPORTANCE_LOOKUP[meetingB.country] || 0)
		? -1
		: 1;
};

/**
 * Considers the state importance declared in the constant RACING_STATE_IMPORTANCE_LOOKUP.
 *
 * @param meetingA
 * @param meetingB
 * @return {number}
 */
export const compareByAustralianState = (meetingA, meetingB) => {
	if (meetingA.state === meetingB.state) {
		return compareByString(meetingA, meetingB, 'name');
	}
	return (RACING_STATE_IMPORTANCE_LOOKUP[meetingA.state] || 0) > (RACING_STATE_IMPORTANCE_LOOKUP[meetingB.state] || 0)
		? -1
		: 1;
};

export const compareByString = (meetingA, meetingB, propertyKey, alternativeKey) => {
	if (meetingA[propertyKey] === meetingB[propertyKey] && alternativeKey) {
		return compareByString(meetingA, meetingB, alternativeKey);
	}
	return meetingA[propertyKey] <= meetingB[propertyKey] ? -1 : 1;
};

/**
 * Checks if a Meeting is of type Thoroughbred.
 * @param meeting
 * @return boolean
 */
export const isGallops = (meeting) => isType(meeting, RACING_THOROUGHBRED_CODE);

/**
 * Checks if a Meeting is of type Greyhounds.
 * @param meeting
 * @return boolean
 */
export const isGreyhounds = (meeting) => isType(meeting, RACING_GREYHOUNDS_CODE);

/**
 * Checks if a Meeting is of type Harness.
 * @param meeting
 * @return boolean
 */
export const isHarness = (meeting) => isType(meeting, RACING_HARNESS_CODE);

//
//
/**
 * Counts how the number of meetings per country code
 *
 * @param state
 * @returns {string{}}
 */
export const meetingsCountByCountryCode = (state) => {
	const meetings = state.entities.meetings;
	let meetingGroupsCount = {};

	Object.values(meetings).forEach((meeting) => {
		let groupCode = meeting.country === 'INT' ? meeting.state : meeting.country;

		meetingGroupsCount[groupCode] && meetingGroupsCount[groupCode] > 0
			? meetingGroupsCount[groupCode]++
			: (meetingGroupsCount[groupCode] = 1);
	});

	return meetingGroupsCount;
};

export const buildMeetingGroupFilters = (groupMeetingsByCountry, meetingGroups, meetingGroupsCount) => {
	let meetingGroupFilters = [];
	const meetingCodesWithMeetings = Object.keys(meetingGroupsCount);

	// If the deprecated 'groupMeetingsByCountry' feature is enabled then return ['AU/NZ', 'INTERNATIONAL']
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		meetingGroupFilters.push(
			{
				title: RACING_GROUP_TITLE_LOCAL,
				groupFilterId: RACING_GROUP_TITLE_LOCAL,
				ordinal: 1,
				default: false,
			},
			{
				title: RACING_GROUP_TITLE_INTERNATIONAL,
				groupFilterId: 'default',
				default: true,
			},
		);
	}

	// If the 'meetingGroups' feature is enabled then return the custom meeting groups
	else if (meetingGroups && meetingGroups.value) {
		// This will accumulate the meeting codes we use
		let groupedMeetingCodes = [];

		meetingGroups.value.map((meetingGroup) => {
			// only add title if code has meetings
			let hasMeetings = false;

			meetingGroup.countryCodes &&
				meetingGroup.countryCodes.forEach((countryCode) => {
					// check if has meetings
					if (meetingCodesWithMeetings.includes(countryCode)) {
						hasMeetings = true;
						groupedMeetingCodes.push(countryCode);
					}
				});

			if (hasMeetings) {
				meetingGroupFilters.push({
					groupFilterId: meetingGroup.title,
					title: meetingGroup.title,
					ordinal: meetingGroup.ordinal,
					default: false,
				});
			}
		});

		let leftOverMeetingsNotGrouped = meetingCodesWithMeetings.filter((code) => {
			return !groupedMeetingCodes.includes(code);
		});

		if (leftOverMeetingsNotGrouped.length > 0) {
			// Check and see if there is a custom title set for the default group
			const hasCustomDefaultGroupTitle = meetingGroups.value.find((meetingGroup) => {
				return meetingGroup.countryCodes && meetingGroup.countryCodes.length === 0 && meetingGroup.title;
			});

			meetingGroupFilters.push({
				groupFilterId: 'default',
				title: hasCustomDefaultGroupTitle ? hasCustomDefaultGroupTitle.title : RACING_GROUP_TITLE_OTHER,
				default: true,
			});
		}
	}

	// Order the meeting groups and return
	return meetingGroupFilters.sort(sortMeetingGroups);
};

// added by @HW
export const buildMeetingFilters = (groupMeetingsByCountry, meetingGroups, meetingGroupsCount) => {
	let meetingGroupFilters = [];
	const meetingCodesWithMeetings = Object.keys(meetingGroupsCount);

	// If the deprecated 'groupMeetingsByCountry' feature is enabled then return ['AU/NZ', 'INTERNATIONAL']
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		meetingGroupFilters.push(
			{
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				groupFilterId: RACING_GROUP_GALLOPS,
				ordinal: 1,
				default: false,
			},
			// {
			// 	title: RACING_GROUP_TITLE_THOROUGHBRED,
			// 	groupFilterId: 'default',
			// 	default: true,
			// },
		);
	}

	// If the 'meetingGroups' feature is enabled then return the custom meeting groups
	else if (meetingGroups && meetingGroups.value) {
		// This will accumulate the meeting codes we use
		let groupedMeetingCodes = [];

		meetingGroups.value.map((meetingGroup) => {
			// only add title if code has meetings
			let hasMeetings = false;

			meetingGroup.countryCodes &&
				meetingGroup.countryCodes.forEach((countryCode) => {
					// check if has meetings
					if (meetingCodesWithMeetings.includes(countryCode)) {
						hasMeetings = true;
						groupedMeetingCodes.push(countryCode);
					}
				});

			if (hasMeetings) {
				meetingGroupFilters.push({
					groupFilterId: meetingGroup.title,
					title: meetingGroup.title,
					ordinal: meetingGroup.ordinal,
					default: false,
				});
			}
		});

		let leftOverMeetingsNotGrouped = meetingCodesWithMeetings.filter((code) => {
			return !groupedMeetingCodes.includes(code);
		});

		if (leftOverMeetingsNotGrouped.length > 0) {
			// Check and see if there is a custom title set for the default group
			const hasCustomDefaultGroupTitle = meetingGroups.value.find((meetingGroup) => {
				return meetingGroup.countryCodes && meetingGroup.countryCodes.length === 0 && meetingGroup.title;
			});

			meetingGroupFilters.push({
				groupFilterId: 'default',
				title: hasCustomDefaultGroupTitle ? hasCustomDefaultGroupTitle.title : RACING_GROUP_TITLE_OTHER,
				default: true,
			});
		}
	}

	// Order the meeting groups and return
	return meetingGroupFilters.sort(sortMeetingGroups);
};

/**
 * Checks a meeting type to see if it is from a country in the countryCodes
 * @param meeting
 * @param type
 * @param countryCodes
 * @return boolean
 */
export const containsCountryCodes = (type, countryCodes, meeting) =>
	isMeetingInCountryList(meeting, countryCodes) && isType(meeting, type);

/**
 * Checks a meeting type to see if it is NOT from a country in the countryCodes
 * @param meeting
 * @param type
 * @param countryCodes
 * @return boolean
 */
export const doesNotContainCountryCodes = (type, countryCodes, meeting) =>
	!isMeetingInCountryList(meeting, countryCodes) && isType(meeting, type);

/**
 * Checks if a meeting is from a country in the countryCodes
 *  NOTE: Currently for International meetings the country code is actually stored in state
 * @param meeting
 * @param countryCodes
 * @return boolean
 */
export const isMeetingInCountryList = (meeting = {}, countryCodes = []) =>
	meeting.country === RACING_INT ? countryCodes.includes(meeting.state) : countryCodes.includes(meeting.country);

/**
 * Checks if a meeting is of given type.
 * @param {object} meeting
 * @param {string} type (gallops, greyhounds, harness)
 * @return boolean
 */
export const isType = (meeting = {}, type) => meeting.type === type;

/**
 * Checks if a meeting finished all races, independent of its status.
 * @param meeting
 * @return boolean
 */
export const isResulted = (meeting = {}) => {
	return meeting.next_race_number === 0;
};

/**
 * filter by Meeting Type
 * @param {object} meetings
 * @param Type;
 * @HW13OCT2019
 */

// export const filterMeetingByType = (state,type) =>{
// 	const meetings = state.entities.meetings;
// 	if (!type) {
// 	return meetings.sort(compareMeetingsByStatusAndGrade);
// 	}
// 	let filterMeetings = Object.values(meetings).filter((meeting) => meeting.type == type).sort(compareMeetingsByStatusAndGrade);
// 	return filterMeetings.length ? filterMeetings : meetings.sort(compareMeetingsByStatusAndGrade);

// };

export const filterMeetingByType = (meetings = [], type) => {
	if (!type) {
		return meetings;
	}
	let filterMeetings = meetings.filter((meeting) => meeting.type == type);
	return filterMeetings.length ? filterMeetings : [];
};

/**
 * get Find by meeting types
 * @HW 15May2020
 * @param {*} meetingGroups
 * @param {*} type
 */
export const filterByMeetingType = (meetingGroups, type) => {
	const filteredByMeetingType = meetingGroups.find((meetingGroup) => {
		return meetingGroup.code == type;
	});
	return filteredByMeetingType;
};

/**
 * Counts how the number of meetings per country code
 *with meeingtpe
 * @param state
 * @returns {string{}}
 */
export const meetingsOfTypeCountByCountryCode = (meetings = []) => {
	let meetingGroupsCount = [];
	meetings.forEach((meeting) => {
		let groupCode = localCountryCodes.includes(meeting.country) ? meeting.country : 'INT';
		meetingGroupsCount[groupCode] && meetingGroupsCount[groupCode] > 0
			? meetingGroupsCount[groupCode]++
			: (meetingGroupsCount[groupCode] = 1);
	});

	return meetingGroupsCount;
};

/**
 * Filter meetins by the given country codes
 *
 * @param {Array} meetings Array with meetings
 * @param {Array} codes    Array with constants
 */
export const filterCoutryWiseMeetings = (meetings = [], codes = []) => {
	if (codes.length == 0) {
		return meetings;
	}

	if (!Array.isArray(codes)) {
		codes = [codes];
	}

	let items = meetings.filter((meeting) => codes.includes(meeting.country));

	return items;
};

/**
 * Meeting groups filter by meeting type code
 * @param {*} meetings
 * @param {*} types
 */
export const filterRaceTypeWiseMeetings = (meetings = [], types = []) => {
	if (types.length == 0) {
		return meetings;
	}

	if (!Array.isArray(types)) {
		types = [types];
	}

	let items = meetings.filter((meeting) => types.includes(meeting.code));

	return items;
};

const localCountryCodes = ['AU', 'NZ'];

export const filterCountryWiseMeetingGroups = (meetingGroups = [], codes = []) => {
	if (codes.length == 0) {
		return meetingGroups;
	}

	if (!Array.isArray(codes)) {
		codes = [codes];
	}

	let items = meetingGroups.filter((meetingGroup) =>
		meetingGroup.meetings.find((meeting) =>
			codes.includes(localCountryCodes.includes(meeting.country) ? meeting.country : 'INT'),
		),
	);

	return items;
};

const isAllRacesFinished = (races) => {
	return races.every(
		(r) =>
			r.status === RACE_ABANDONED_STATUS ||
			r.status === RACE_SUSPENDED_STATUS ||
			r.status === RACE_PAID_STATUS ||
			r.status === RACE_FINISHED_STATUS ||
			r.status === RACE_CLOSED_STATUS,
	);
};

const compareRaceTimes = (ma, mb) => {
	const aFirstRaceTime = new Date(ma.start_date);
	const bFirstRaceTime = new Date(mb.start_date);
	return aFirstRaceTime - bFirstRaceTime;
};

export const SortMeetingGroupsByRaceTime = (meetingGroups = []) => {
	let m = [...meetingGroups];

	return m.map((group) => {
		const meetings = [...group.meetings];

		meetings.sort((ma, mb) => {
			if (ma.races.length === 0 && mb.races.length === 0) {
				return 0;
			}

			if (ma.races.length === 0) {
				return -1;
			}

			if (mb.races.length === 0) {
				return 1;
			}

			const maAllRacesFinished = isAllRacesFinished(ma.races);
			const mbAllRacesFinished = isAllRacesFinished(mb.races);

			if (maAllRacesFinished && !mbAllRacesFinished) {
				return 1;
			}

			if (!maAllRacesFinished && mbAllRacesFinished) {
				return -1;
			}

			const raceTimeComparison = compareRaceTimes(ma, mb);
			if (raceTimeComparison !== 0) {
				return raceTimeComparison;
			}

			if (ma.id > mb.id) {
				return 1;
			} else if (ma.id < mb.id) {
				return -1;
			}

			return 0;
		});
		group.meetings = meetings;

		return group;
	});
};
