import {
	RACING_TOTE_NAME,
	RACING_FIXED_NAME,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_EACHWAY_SHORT,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_PLACE_SHORT,
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_WIN_SHORT,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_MARGIN_SHORT,
	RACING_EXOTIC_MINIMUM_SELECTIONS,
	RACING_BASE_BET_TYPES,
	RACING_ODDS_GIDS,
	RACING_ISSURE_BET,
	RACING_BET_TYPE_NOTTO_WIN_BET,
	// RACING_BET_TYPE_TRAINERT_4_BET,
	RACING_BET_TYPE_TRAINER_WIN_BET,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_BET_TYPE_QUINELLA,
	RACING_BET_TYPE_SP,
	RACING_BET_PRODUCT_CODE_TF,
	RACING_BET_PRODUCT_CODE_SP,
} from '../../common/constants/Racing';
import {
	BET_TYPE_PLACE,
	BET_TYPE_WIN,
	//  BET_TYPE_WIN,
	PRODUCT_TYPE_STANDARD,
} from '../entities/constants/BetConstants';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../common/constants/GoatProducts';
import { getFixedPriceRollup } from '../application/applicationSelectors';
import { createPriceForSelectedProduct, findPrice, findProduct } from '../entities/selectors/ProductSelectors';
import { getNumberOfNotScratchedSelections } from '../../pages/Racing/RacingHome/racingSelectorsGRS';

/**
 * Checks selections against exotic bet type rules and return true or throw error with message.
 *
 * @param {Array.<{id:number, number:number, position:string}>} betSelections
 * @param betType
 * @param boxed
 * @return {boolean}
 * @throws {Error} "Not enough selections!"
 */
export const validateExotics = (betSelections, betType, boxed) => {
	/**
	 * Other exotic types:
	 * Count how many unique runner and positions are involved.
	 */
	const minSelections = RACING_EXOTIC_MINIMUM_SELECTIONS[betType];
	const positionsFilled = betSelections.reduce((acc, selection) => acc.add(selection.position), new Set());

	const uniqueRunners = betSelections.reduce((acc, selection) => acc.add(selection.id), new Set());
	// Boxed
	if (boxed) {
		if (uniqueRunners.size < minSelections || !positionsFilled.has('0')) {
			throw new Error('Not enough selections!');
		}

		return true;
	}

	// Boxed or Quinella
	if (betType === RACING_BET_TYPE_QUINELLA) {
		if (uniqueRunners.size < minSelections || !positionsFilled.has('1')) {
			throw new Error('Not enough selections!');
		}

		return true;
	}

	if (positionsFilled.size < minSelections || uniqueRunners.size < minSelections) {
		throw new Error('Not enough selections!');
	}

	return true;
};

/**
 * Try and maintain that our pricing structure is in a common format for our containers
 *
 * @param selection
 * @param isFixed
 * @param betType
 * @param selectedProducts
 * @param products
 * @returns {{place: *, win: *, prices: {win_fixed: (*|number|null), place_fixed: (*|number|null), win_tote: (*|number|null), place_tote: (*|null|number)}, oldPrices}}
 */
export const buildRacingPrices = (
	selection,
	isFixed = false,
	betType = RACING_BET_TYPE_WIN,
	selectedProducts = {},
	products = [],
) => {
	let winPrice, placePrice, marginPrice;
	const fixedToteSuffix = isFixed ? RACING_FIXED_NAME : RACING_TOTE_NAME;

	/**
	 * Race can have prices either for every selection, for some of the selections, or none of them.
	 */
	if (selectedProducts && selection.prices && selection.prices.length) {
		if (selectedProducts.margin) {
			marginPrice = createPriceForSelectedProduct(selectedProducts.margin, selection.prices);
			if (!marginPrice) {
				marginPrice = selection.prices.find(
					(price) =>
						selectedProducts.margin.product_id === price.product_id &&
						findPrice(price, selection, `margin_${fixedToteSuffix}`),
				);
			}
		}
		if (selectedProducts.win) {
			winPrice = createPriceForSelectedProduct(selectedProducts.win, selection.prices);
			if (!winPrice) {
				winPrice = selection.prices.find(
					(price) =>
						selectedProducts.win.product_id === price.product_id &&
						findPrice(price, selection, `win_${fixedToteSuffix}`),
				);
			}
		}
		if (selectedProducts.place) {
			placePrice = createPriceForSelectedProduct(selectedProducts.place, selection.prices);
			if (!placePrice) {
				placePrice = selection.prices.find(
					(price) =>
						selectedProducts.place.product_id === price.product_id &&
						findPrice(price, selection, `place_${fixedToteSuffix}`),
				);
			}
		}
	}

	/**
	 * If a tote/fixed price hasn't been found, get the respective product. Price values will be undefined.
	 */
	if (!marginPrice && products.length && selectedProducts.margin) {
		marginPrice = products.find(
			(product) =>
				selectedProducts.margin.product_id === product.product_id &&
				findProduct(product, selection, `margin_${fixedToteSuffix}`, RACING_BET_TYPE_MARGIN, isFixed),
		);
	}

	if (products.length && selectedProducts.win) {
		const winProduct = products.find(
			(product) =>
				selectedProducts.win.product_id === product.product_id &&
				findProduct(product, selection, `win_${fixedToteSuffix}`, BET_TYPE_WIN, isFixed),
		);
		if (winProduct) {
			if (!winPrice) {
				winPrice = winProduct;
			}
		}
	}

	if (!placePrice && products.length && selectedProducts.place) {
		placePrice = products.find(
			(product) =>
				selectedProducts.place.product_id === product.product_id &&
				findProduct(product, selection, `place_${fixedToteSuffix}`, BET_TYPE_PLACE, isFixed),
		);
	}

	const place = placePrice
		? {
				code: placePrice.product_code,
				odds: placePrice.place_odds || placePrice.product_code,
				productId: placePrice.product_id,
				type: placePrice.product_type,
				fixed: isFixed,
				available: placePrice.available && (!isFixed || (isFixed && placePrice.place_odds)),
		  }
		: selection.place
		? selection.place
		: {};

	const win = winPrice
		? {
				code: winPrice.product_code,
				odds: winPrice.win_odds || winPrice.product_code,
				productId: winPrice.product_id,
				priceId: winPrice.price_id || winPrice.product_id,
				type: winPrice.product_type,
				fixed: isFixed,
				available: winPrice.available && (!isFixed || (isFixed && winPrice.win_odds)),
		  }
		: selection.win
		? selection.win
		: {};

	const margin = marginPrice
		? {
				code: marginPrice.product_code,
				odds: marginPrice.margin_odds || marginPrice.product_code,
				productId: marginPrice.product_id,
				type: marginPrice.product_type,
				fixed: isFixed,
				margin: selectedProducts.margin ? selectedProducts.margin.margin : null,
				available: marginPrice.available && (!isFixed || (isFixed && marginPrice.margin_odds)),
		  }
		: selection.margin
		? selection.margin
		: {};

	const prices = {
		win_tote: selection.win_tote || (win.fixed ? null : win.productId),
		place_tote: selection.place_tote || (place.fixed ? null : place.productId),
		margin_tote: selection.margin_tote || (margin.fixed ? null : margin.productId),
		win_fixed: selection.win_fixed || (win.fixed ? win.productId : null),
		place_fixed: selection.place_fixed || (place.fixed ? place.productId : null),
		margin_fixed: selection.margin_fixed || (margin.fixed ? margin.productId : null),
	};

	const oldPrices = selection.prices;
	return {
		place,
		win,
		margin,
		prices,
		oldPrices,
		silk: selection.silk,
	};
};

/**
 * Build the racing selection object, with a list of prices
 *
 * @param race
 * @param selectionId
 * @param productId
 * @param betType
 * @param fixed
 */
export const buildRacingSelection = (race, selectionId, productId, betType, fixed = false) => (dispatch, getState) => {
	if (race) {
		let selectedProducts = {};

		// Get the selected product for the bet_type
		let initialBetType;
		if (betType === RACING_BET_TYPE_EACHWAY || betType === RACING_ODDS_GIDS || productId === 36) {
			initialBetType = RACING_BET_TYPE_WIN;
		} else if (betType === RACING_SAME_RACE_MULTI_TYPE) {
			initialBetType = RACING_BET_TYPE_PLACE;
			// initialBetType = RACING_BET_TYPE_WIN;
		} else {
			initialBetType = betType;
		}

		let selectedProduct =
			productId === 19
				? race.products.find((product) => 19 === productId && product.bet_type === RACING_ODDS_GIDS)
				: productId === 20
				? race.products.find((product) => product.product_id === productId && product.bet_type === RACING_ODDS_GIDS)
				: productId === 21
				? race.products.find((product) => product.product_id === productId && product.bet_type === RACING_ODDS_GIDS)
				: productId === 22
				? race.products.find((product) => product.product_id === productId && product.bet_type === RACING_ODDS_GIDS)
				: productId === 23
				? race.products.find((product) => product.product_id === productId && product.bet_type === RACING_ISSURE_BET)
				: productId === 30 || productId === 31
				? race.products.find(
						(product) => product.product_id === productId && product.bet_type === RACING_BET_TYPE_NOTTO_WIN_BET,
				  )
				: productId > 32 && productId < 37
				? race.products.find(
						(product) => product.product_id === productId && product.bet_type === RACING_SAME_RACE_MULTI_TYPE,
				  )
				: productId === 29
				? race.products.find(
						(product) => product.product_id === productId && product.bet_type === RACING_BET_TYPE_TRAINER_WIN_BET,
				  )
				: race.products.find((product) => product.product_id === productId && product.bet_type === initialBetType);
		// Sometimes the product doesn't exist for the chosen bet type (eg. eachway) so we need to pick the closest one

		if (betType === RACING_BET_TYPE_EACHWAY) {
			selectedProduct = race.products.find(
				(product) => product.bet_type === initialBetType && product.product_id === 16,
			);
		}

		if (!selectedProduct) {
			selectedProduct = race.products.find((product) => product.bet_type === initialBetType && product.fixed === fixed);
		}
		if (selectedProduct) {
			// Sometimes we don't want to match other bet types on the product_id key
			const matchingProductID =
				selectedProduct.product_type === GOAT_PRODUCT_TYPE_BOOST
					? selectedProduct.price_id
					: selectedProduct.product_id;

			RACING_BASE_BET_TYPES.forEach((racingBetType) => {
				selectedProducts[racingBetType] = race.products.find(
					(product) => product.product_id === matchingProductID && product.bet_type === racingBetType,
				);
			});

			// Reset our actual selected product to be on the initial matching key
			selectedProducts[initialBetType] = selectedProduct;

			// Check for any that might be non-existing and add the first we find
			if (!selectedProducts.win) {
				const typeProducts = race.products.find(
					(product) => product.bet_type === RACING_BET_TYPE_WIN && product.fixed === fixed,
				);
				selectedProducts.win = Array.isArray(typeProducts) ? typeProducts[0] : typeProducts;
			}
			if (!selectedProducts.place) {
				const typeProducts = race.products.find(
					(product) => product.bet_type === RACING_BET_TYPE_PLACE && product.fixed === fixed,
				);
				selectedProducts.place = Array.isArray(typeProducts) ? typeProducts[0] : typeProducts;
			}
		} else {
			selectedProduct = { product_id: productId, fixed: fixed, product_type: PRODUCT_TYPE_STANDARD };
		}

		// isFixed decides if clicked product is fixed or tote
		// fixed override can be provided when there is no product, eg. Eachway
		const isFixed =
			(selectedProducts.win && selectedProducts.win.fixed) ||
			(selectedProducts.place && selectedProducts.place.fixed) ||
			fixed;

		let selection = {};
		if (race.selections) {
			selection = race.selections.find((selection) => selection.id === selectionId);
		} else if (race.top_selections) {
			selection = race.top_selections.find((selection) => selection.id === selectionId);
		} else if (race.favorite_selections) {
			selection = race.favorite_selections.find((selection) => selection.id === selectionId);
		}

		const { place, win, margin, prices, oldPrices } = buildRacingPrices(
			selection,
			isFixed,
			betType,
			selectedProducts,
			race.products,
		);

		// Get the number of non scratched selections
		const numOfSelections = getNumberOfNotScratchedSelections(race.selections);

		/**
		 * Prepares bet type menu in the bet prompt.
		 * Not initializing 'racingBetTypes' will trigger default menu win/place/eachway.
		 */
		let racingBetTypes = [];
		if (win.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_WIN_SHORT,
				value: RACING_BET_TYPE_WIN,
			});
		}
		if (place.code && numOfSelections >= 5) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_PLACE_SHORT,
				value: RACING_BET_TYPE_PLACE,
			});
		}
		if (win.code && place.code && numOfSelections >= 5) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_EACHWAY_SHORT,
				value: RACING_BET_TYPE_EACHWAY,
			});
		}
		if (margin.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_MARGIN_SHORT,
				value: RACING_BET_TYPE_MARGIN,
			});
		}
		// Check if the product is a bump product
		if (win && selectedProduct && selectedProduct.product_type === GOAT_PRODUCT_TYPE_BOOST) {
			// Calculate the boosted price, based off the bet price
			const state = getState();
			const isRacing = true;
			win.odds = getFixedPriceRollup(state, isRacing, win.odds);
		}

		let type_name;
		let betNumber;
		if (betType === RACING_SAME_RACE_MULTI_TYPE) {
			// Space between the name and the number
			type_name = selectedProduct.product_code.replace('SRM', '');

			if (type_name != 'Win') {
				type_name = type_name.substring(0, type_name.length - 1) + ' ' + type_name.substring(type_name.length - 1);
				betNumber = type_name.substring(type_name.length - 1);
			} else {
				betNumber = 1;
			}
		}

		const hasPump = race.products.some((product) => product.product_type === GOAT_PRODUCT_TYPE_BOOST);
		const totes_json = race.totes_json;

		let totes = {
			win: 18,
			place: 18,
		};

		if (totes_json && totes_json.length != 0) {
			const win_tote = race.products.find(
				(product) => product.product_id == totes_json[0].product_id && product.bet_type == RACING_BET_TYPE_WIN,
			);
			if (win_tote) {
				totes.win = win_tote.product_id;
			}
			if (totes_json.length > 1) {
				const place_tote = race.products.find(
					(product) => product.product_id == totes_json[1].product_id && product.bet_type == RACING_BET_TYPE_PLACE,
				);
				if (place_tote) {
					totes.place = place_tote.product_id;
				}
			}
		}

		if (
			selectedProduct.product_code === RACING_BET_PRODUCT_CODE_TF ||
			selectedProduct.product_code === RACING_BET_PRODUCT_CODE_SP
		) {
			totes.win = 18;
			totes.place = 18;
		}

		return {
			isRacing: true,
			racingBetType: betType,
			racingBetTypes,
			product: selectedProduct,
			product_type: selectedProduct ? selectedProduct.product_type : PRODUCT_TYPE_STANDARD,
			product_id: selectedProduct ? selectedProduct.product_id : null,
			price_id: selectedProduct ? selectedProduct.price_id : null,
			// total_liability: total_liability,
			// back1_liability: parseInt(back1price.liability),
			// back2_liability: parseInt(back2price.liability),
			// back3_liability: parseInt(back3price.liability),
			// back4_liability: parseInt(back4price.npmliability),
			// w2w3: w2w3,
			selection_number: selection.number,
			name: selection.name,
			silk: selection.silk,
			type_name: type_name, // add type_name to the object 09Dec2022
			betNumber, // add betNumber to the object 09Dec2022
			// Prices data
			place,
			win,
			margin,
			prices,
			oldPrices,
			hasPump,
			totes,
		};
	}
};

/**
 * Build the next jump racing selection object, with a list of prices
 * http://tst.api.grs.bet/api/v3/racing/next-to-jump-grs
 * @param race
 * @param selectionId
 * @param productId
 * @param betType
 * @param fixed
 */
export const buildNJRacingSelection = (race, selectionId, productId, betType, fixed = false) => (
	dispatch,
	getState,
) => {
	if (race) {
		let selectedProducts = {};

		// Get the selected product for the bet_type
		const initialBetType = betType === RACING_BET_TYPE_EACHWAY ? RACING_BET_TYPE_WIN : betType;
		let selectedProduct = race.products.find(
			(product) => product.product_id === productId && product.bet_type === initialBetType,
		);

		// Sometimes the product doesn't exist for the chosen bet type (eg. eachway) so we need to pick the closest one
		if (!selectedProduct) {
			selectedProduct = race.products.find((product) => product.bet_type === initialBetType && product.fixed === fixed);
		}
		if (selectedProduct) {
			// Sometimes we don't want to match other bet types on the product_id key
			const matchingProductID =
				selectedProduct.product_type === GOAT_PRODUCT_TYPE_BOOST
					? selectedProduct.price_id
					: selectedProduct.product_id;

			RACING_BASE_BET_TYPES.forEach((racingBetType) => {
				selectedProducts[racingBetType] = race.products.find(
					(product) => product.product_id === matchingProductID && product.bet_type === racingBetType,
				);
			});

			// Reset our actual selected product to be on the initial matching key
			selectedProducts[initialBetType] = selectedProduct;

			// Check for any that might be non-existing and add the first we find
			if (!selectedProducts.win) {
				const typeProducts = race.products.find(
					(product) => product.bet_type === RACING_BET_TYPE_WIN && product.fixed === fixed,
				);
				selectedProducts.win = Array.isArray(typeProducts) ? typeProducts[0] : typeProducts;
			}
			if (!selectedProducts.place) {
				const typeProducts = race.products.find(
					(product) => product.bet_type === RACING_BET_TYPE_PLACE && product.fixed === fixed,
				);
				selectedProducts.place = Array.isArray(typeProducts) ? typeProducts[0] : typeProducts;
			}
		} else {
			selectedProduct = { product_id: productId, fixed: fixed, product_type: PRODUCT_TYPE_STANDARD };
		}

		// isFixed decides if clicked product is fixed or tote
		// fixed override can be provided when there is no product, eg. Eachway
		const isFixed =
			(selectedProducts.win && selectedProducts.win.fixed) ||
			(selectedProducts.place && selectedProducts.place.fixed) ||
			fixed;

		const selection = race.selections.find((selection) => selection.id === selectionId);
		const { place, win, margin, prices, oldPrices } = buildRacingPrices(
			selection,
			isFixed,
			betType,
			selectedProducts,
			race.products,
		);

		// Get the number of non scratched selections
		const numOfSelections = getNumberOfNotScratchedSelections(race.selection);

		/**
		 * Prepares bet type menu in the bet prompt.
		 * Not initializing 'racingBetTypes' will trigger default menu win/place/eachway.
		 */
		let racingBetTypes = [];
		if (win.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_WIN_SHORT,
				value: RACING_BET_TYPE_WIN,
			});
		}
		if (place.code && numOfSelections >= 3) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_PLACE_SHORT,
				value: RACING_BET_TYPE_PLACE,
			});
		}
		if (win.code && place.code && numOfSelections >= 3) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_EACHWAY_SHORT,
				value: RACING_BET_TYPE_EACHWAY,
			});
		}
		if (margin.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_MARGIN_SHORT,
				value: RACING_BET_TYPE_MARGIN,
			});
		}

		// Check if the product is a bump product
		if (win && selectedProducts.win && selectedProducts.win.product_type === GOAT_PRODUCT_TYPE_BOOST) {
			// Calculate the boosted price, based off the bet price
			const state = getState();
			const isRacing = true;
			win.odds = getFixedPriceRollup(state, isRacing, win.odds);
		}

		return {
			isRacing: true,
			racingBetType: betType,
			racingBetTypes,
			product: selectedProduct,
			product_type: selectedProduct ? selectedProduct.product_type : PRODUCT_TYPE_STANDARD,

			// Prices data
			place,
			win,
			margin,
			prices,
			oldPrices,
			name: selection.name, // add by newly
			selection_number: selection.number, // add by newly
			silk: selection.silk,
		};
	}
};

/**
 * Build the feaure racing selection object, with a list of prices
 * http://tst.api.grs.bet/api/v3/racing/upcoming_races?date=2020-10-15&race_type=fr&limit=20
 * @param race
 * @param selectionId
 * @param productId
 * @param betType
 * @param fixed
 */
export const buildFeRacingSelection = (race, selectionId, productId, betType, selectionType, fixed = false) => (
	dispatch,
	getState,
) => {
	if (race) {
		let selectedProducts = {};

		// Get the selected product for the bet_type
		const initialBetType = betType === RACING_BET_TYPE_EACHWAY ? RACING_BET_TYPE_WIN : betType;
		let selectedProduct = race.products.find(
			(product) => product.product_id === productId && product.bet_type === initialBetType,
		);

		// Sometimes the product doesn't exist for the chosen bet type (eg. eachway) so we need to pick the closest one
		if (!selectedProduct) {
			selectedProduct = race.products.find((product) => product.bet_type === initialBetType && product.fixed === fixed);
		}
		if (selectedProduct) {
			// Reset our actual selected product to be on the initial matching key
			selectedProducts[initialBetType] = selectedProduct;

			// Check for any that might be non-existing and add the first we find
			if (!selectedProducts.win) {
				const typeProducts = race.products.find(
					(product) => product.bet_type === RACING_BET_TYPE_WIN && product.fixed === fixed,
				);
				selectedProducts.win = typeProducts;
			}
			if (!selectedProducts.place) {
				const typeProducts = race.products.find(
					(product) => product.bet_type === RACING_BET_TYPE_PLACE && product.fixed === fixed,
				);
				selectedProducts.place = typeProducts;
			}
		} else {
			selectedProduct = { product_id: productId, fixed: fixed, product_type: PRODUCT_TYPE_STANDARD };
		}

		// isFixed decides if clicked product is fixed or tote
		// fixed override can be provided when there is no product, eg. Eachway
		const isFixed =
			(selectedProducts.win && selectedProducts.win.fixed) ||
			(selectedProducts.place && selectedProducts.place.fixed) ||
			fixed;

		// const selection = race.selections.find((selection) => selection.id === selectionId); // comment by @HW 15Oct2020
		// add selectionType to get selection data by@HW 05Jan2021
		const selection =
			selectionType === 'top_selections'
				? race.top_selections.find((selection) => selection.id === selectionId)
				: race.favorite_selection.find((selection) => selection.id === selectionId); // top_selections
		const { place, win, margin, prices, oldPrices } = buildRacingPrices(
			selection,
			isFixed,
			betType,
			selectedProducts,
			race.products,
		);

		// Get the number of non scratched selections
		const numOfSelections = getNumberOfNotScratchedSelections(race.selection);
		/**
		 * Prepares bet type menu in the bet prompt.
		 * Not initializing 'racingBetTypes' will trigger default menu win/place/eachway.
		 */
		let racingBetTypes = [];
		if (win.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_WIN_SHORT,
				value: RACING_BET_TYPE_WIN,
			});
		}
		if (place.code && numOfSelections >= 3) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_PLACE_SHORT,
				value: RACING_BET_TYPE_PLACE,
			});
		}
		if (win.code && place.code && numOfSelections >= 3) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_EACHWAY_SHORT,
				value: RACING_BET_TYPE_EACHWAY,
			});
		}
		if (margin.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_MARGIN_SHORT,
				value: RACING_BET_TYPE_MARGIN,
			});
		}
		// Check if the product is a bump product
		if (win && selectedProducts.win && selectedProducts.win.product_type === GOAT_PRODUCT_TYPE_BOOST) {
			// Calculate the boosted price, based off the bet price
			const state = getState();
			const isRacing = true;
			win.odds = getFixedPriceRollup(state, isRacing, win.odds);
		}

		return {
			isRacing: true,
			racingBetType: betType,
			racingBetTypes,
			product: selectedProduct,
			product_type: selectedProduct ? selectedProduct.product_type : PRODUCT_TYPE_STANDARD,

			// Prices data
			place,
			win,
			margin,
			prices,
			oldPrices,
			name: selection.name, // add by newly @HW 15Oct2020
			selection_number: selection.number, // add by newly @HW 05Jan2023
			silk: selection.silk,
		};
	}
};

export const buildExpertTipsSelection = (race, selectionId, productId, betType, selectionType, fixed = false) => (
	dispatch,
	getState,
) => {
	if (race && race.products) {
		let selectedProducts = {};

		// Get the selected product for the bet_type
		const initialBetType = betType === RACING_BET_TYPE_EACHWAY ? RACING_BET_TYPE_WIN : betType;
		let selectedProduct = race.products.find(
			(product) => product.product_id === productId && product.bet_type === initialBetType,
		);

		// Sometimes the product doesn't exist for the chosen bet type (eg. eachway) so we need to pick the closest one
		if (!selectedProduct) {
			selectedProduct = race.products.find((product) => product.bet_type === initialBetType && product.fixed === fixed);
		}
		if (selectedProduct) {
			// Reset our actual selected product to be on the initial matching key
			selectedProducts[initialBetType] = selectedProduct;

			// Check for any that might be non-existing and add the first we find
			if (!selectedProducts.win) {
				const typeProducts = race.products.find((product) => product.bet_type === RACING_BET_TYPE_WIN);
				selectedProducts.win = typeProducts;
			}
			if (!selectedProducts.place) {
				const typeProducts = race.products.find((product) => product.bet_type === RACING_BET_TYPE_PLACE);
				selectedProducts.place = typeProducts;
			}
		} else {
			selectedProduct = { product_id: productId, fixed: fixed, product_type: PRODUCT_TYPE_STANDARD };
		}
		// isFixed decides if clicked product is fixed or tote
		// fixed override can be provided when there is no product, eg. Eachway
		const isFixed =
			(selectedProducts.win && selectedProducts.win.fixed) ||
			(selectedProducts.place && selectedProducts.place.fixed) ||
			fixed;

		// const selection = race.selections.find((selection) => selection.id === selectionId); // comment by @HW 15Oct2020
		// add selectionType to get selection data by@HW 05Jan2021
		const selection =
			selectionType === 'top_selections'
				? race.top_selections.find((selection) => selection.id === selectionId)
				: race.favorite_selection.find((selection) => selection.id === selectionId); // top_selections
		const { place, win, margin, prices, oldPrices } = buildRacingPrices(
			selection,
			isFixed,
			betType,
			selectedProducts,
			race.products,
		);
		// Get the number of non scratched selections
		const numOfSelections = getNumberOfNotScratchedSelections(race.selection);
		/**
		 * Prepares bet type menu in the bet prompt.
		 * Not initializing 'racingBetTypes' will trigger default menu win/place/eachway.
		 */
		let racingBetTypes = [];
		if (win.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_WIN_SHORT,
				value: RACING_BET_TYPE_WIN,
			});
		}
		if (place.code && numOfSelections >= 3) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_PLACE_SHORT,
				value: RACING_BET_TYPE_PLACE,
			});
		}
		if (win.code && place.code && numOfSelections >= 3) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_EACHWAY_SHORT,
				value: RACING_BET_TYPE_EACHWAY,
			});
		}
		if (margin.code) {
			racingBetTypes.push({
				text: RACING_BET_TYPE_MARGIN_SHORT,
				value: RACING_BET_TYPE_MARGIN,
			});
		}
		// Check if the product is a bump product
		if (win && selectedProduct && selectedProduct.product_type === GOAT_PRODUCT_TYPE_BOOST) {
			// Calculate the boosted price, based off the bet price
			const state = getState();
			const isRacing = true;
			win.odds = getFixedPriceRollup(state, isRacing, win.odds);
		}

		return {
			isRacing: true,
			racingBetType: betType,
			racingBetTypes,
			product: selectedProduct,
			product_type: selectedProduct ? selectedProduct.product_type : PRODUCT_TYPE_STANDARD,
			price_id: selectedProduct ? selectedProduct.price_id : null,
			// Prices data
			place,
			win,
			margin,
			prices,
			oldPrices,
			name: selection.name, // add by newly
			selection_number: selection.number, // add by newly
			silk: selection.silk,
		};
	}
};
