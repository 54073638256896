import { denormalize, normalize } from 'normalizr';
import { articlesSchema } from './Entities';

/**
 * Normalizes articles.
 *
 * @param response
 * @returns {Object}
 */

const normalizeArticles = (response) => {
	return normalize(response, articlesSchema);
};

/**
 * Denormalize articles.
 *
 * @param {object} entities
 * @param {Array} keys
 * @returns {Array}
 */
const denormalizeArticles = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.articles), articlesSchema, entities);
};

export { normalizeArticles, denormalizeArticles };
