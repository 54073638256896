export const BUYIN = 'buyin';
export const PLAYERS = 'players';
export const PRIZE_POOL = 'prizePool';
export const REBUYS = 'rebuys';

// Used for the Tournament search filter titles
export const FILTER_RESULTS = 'Filter Results';
export const CURRENT = 'Current';
export const YESTERDAY = 'Yesterday';
export const LAST_7_DAYS = 'Last 7 Days';
export const LAST_MONTH = 'Last Month';
export const CUSTOM = 'Custom';

export const TITLE_TICKET_PURCHASE = 'Ticket Purchase';
export const TITLE_TOURNAMENT_ENTRY = 'Tournament Entry';

export const TOURNAMENT_TYPE_RACE = 'race';
export const TOURNAMENT_TYPE_SPORT = 'sport';
export const TOURNAMENT_TYPE_RACE_LABEL = 'Racing';
export const TOURNAMENT_TYPE_SPORT_LABEL = 'Sport';
