import moment from 'moment';
/**
 * Sort array of Feature Races
 * @param feRaces
 * @return {Array.<*>}
 */
export const sortFeatureRacesByStartTime = (feRaces = []) => {
	return feRaces.sort((a, b) => moment(a.start_datetime) - moment(b.start_datetime));
};

/**
 * Filter feature races by the given types
 *
 * @param {Array} feRaces Array with feRaces
 * @param {Array} types    Array with constants
 */
export const filterFeatureRaces = (feRaces = [], types = []) => {
	if (types.length == 0) {
		return feRaces;
	}

	if (!Array.isArray(types)) {
		types = [types];
	}

	let items = feRaces.filter((race) => types.includes(race.type));
	
	return items;
	//return items.length ? items : feRaces;
};




