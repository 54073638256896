import { createSelector } from 'reselect';

/**
 * Return the user's credit cards depending on the conditionals
 *
 * @param state
 * @param conditionals
 * @returns {state.entities.creditCards|{}}
 */
export const getFilteredCreditCards = (state, conditionals) => {
	const { isQuickDeposit, showUnverifiedCards, verifyCreditCardsOnly } = conditionals;

	let creditCards = verifyCreditCardsOnly
		? getUnverifiedCreditCardsOnly(state)
		: isQuickDeposit ? getVerifiedCreditCardsOnly(state) : state.entities.creditCards;

	if (showUnverifiedCards) {
		creditCards = getUnverifiedCreditCardsFirst(creditCards);
	}

	return creditCards;
};

/**
 * Grab verified credit cards only
 * @param state
 */
export const getVerifiedCreditCardsOnly = createSelector(
	(state) => state.entities.creditCards,
	(creditCards) => {
		return Object.keys(creditCards).reduce((verifiedCards, cardId) => {
			let card = creditCards[cardId];
			if (card.verified) {
				return {
					...verifiedCards,
					[cardId]: card,
				};
			}
			return verifiedCards;
		}, {});
	},
);

/**
 * Grab unverified credit cards only
 * @param state
 */
export const getUnverifiedCreditCardsOnly = createSelector(
	(state) => state.entities.creditCards,
	(creditCards) => {
		return Object.keys(creditCards).reduce((verifiedCards, cardId) => {
			let card = creditCards[cardId];
			if (!card.verified) {
				return {
					...verifiedCards,
					[cardId]: card,
				};
			}
			return verifiedCards;
		}, {});
	},
);

/**
 * Sort the credit cards to show the unverified cards first
 *
 * @param creditCards
 * @returns {{}}
 */
export const getUnverifiedCreditCardsFirst = (creditCards) => {
	let sortedCards = [];

	for (let card in creditCards) {
		if (creditCards.hasOwnProperty(card)) {
			sortedCards.push(creditCards[card]);
		}
	}

	sortedCards.sort((a, b) => {
		return a.verified > b.verified;
	});

	let sortedCardsObject = {};

	for (let i = 0; i < sortedCards.length; i++) {
		let card = sortedCards[i];
		sortedCardsObject[card.id] = card;
	}

	return sortedCardsObject;
};
