import { batchActions } from 'redux-batched-actions';

import { setBodyLoading } from '../store/application/applicationActions';

const {
	routeToTournament,
	setActiveTournamentCompetitionFilter,
	setActiveTournamentRaceFilter,
	setActiveTournamentSelectedEvent,
} = require('../store/activeTournament/activeTournamentActions');
import { show404 } from '../store/application/applicationActions';
const { fetchTournament } = require('../store/entities/actions/TournamentActions');
import { getLoadedRaces } from '../store/entities/actions/RaceActions';

const { dispatch, getState } = App.store;

const { RESET_ACTIVE_TOURNAMENT_FILTERS } = require('../store/activeTournament/activeTournamentActionTypes');
const { TOURNAMENTS_BASE_URL } = require('../pages/Tournaments/TournamentsConstants');

const TournamentsRouter = Backbone.Router.extend({
	routes: {
		[TOURNAMENTS_BASE_URL]: 'showTournamentsHome',
		[`${TOURNAMENTS_BASE_URL}/:name-:id`]: 'showTournamentWithGenericFilter',
		[`${TOURNAMENTS_BASE_URL}/:name-:id/:genericName-:genericId`]: 'showTournamentWithGenericFilter',
		[`${TOURNAMENTS_BASE_URL}/:name-:id/:competitionName-:competitionId/:eventName-:eventId`]: 'showTournamentWithEvent',
	},

	showTournament: function(name, tournamentId) {
		dispatch(
			batchActions(
				[
					setActiveTournamentCompetitionFilter(null),
					setActiveTournamentRaceFilter(null),
					setActiveTournamentSelectedEvent(null),
				],
				RESET_ACTIVE_TOURNAMENT_FILTERS,
			),
		);
		dispatch(routeToTournament(tournamentId));
	},

	showTournamentsHome: function() {
		dispatch(setBodyLoading(true));
		App.startSubApp('Tournaments');
	},

	showTournamentWithGenericFilter: function(name, tournamentId, genericName = null, genericId = null) {
		const loadedRaces = dispatch(getLoadedRaces());
		const raceWasLoadedPreviously = genericId && loadedRaces.some((race) => race.id === parseInt(genericId));
		let tournaments = getState().entities.tournaments;

		if (raceWasLoadedPreviously) {
			if (tournaments[tournamentId].type === 'race') {
				dispatch(setActiveTournamentRaceFilter(+genericId));
			}
		}

		dispatch(setActiveTournamentSelectedEvent(null));
		dispatch(fetchTournament(tournamentId, genericId))
			.then(() => {
				tournaments = getState().entities.tournaments;
				if (tournaments[tournamentId].type === 'sport') {
					dispatch(setActiveTournamentCompetitionFilter(+genericId));
				} else if (!raceWasLoadedPreviously) {
					dispatch(setActiveTournamentRaceFilter(+genericId));
				}

				dispatch(routeToTournament(tournamentId));
			})
			.catch((e) => {
				//console.log(e);
				dispatch(show404());
			});
	},

	showTournamentWithEvent: function(name, tournamentId, competitionName, competitionId, eventName, eventId) {
		dispatch(fetchTournament(tournamentId, competitionId))
			.then(() => {
				dispatch(setActiveTournamentCompetitionFilter(+competitionId));
				dispatch(setActiveTournamentSelectedEvent(+eventId));
				dispatch(routeToTournament(tournamentId));
			})
			.catch(() => {
				dispatch(show404());
			});
	},
});

module.exports = new TournamentsRouter();
