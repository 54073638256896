import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, Button, Text, Notification, TitleBlock } from '@tbh/ui-kit';
import BetSelection from '../../BetSelection/BetSelection';
import Receipt from '../../../Transactions/Receipt/Receipt';
import CurrencyDisplay from '../../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

import { formatOddsAsPrice } from '../../../../../common/BetPlacement';
import {
	BET_REJECTED_STATUS,
	BET_FULL_REFUND_STATUS,
	BET_CANCELLED_STATUS,
} from '../../../../../common/constants/Bets';
import { BET_TYPE_WIN } from '../../../../../store/entities/constants/BetConstants';

/**
 * Styling
 */
const StyledBetReceipt__BalanceHeader = styled(TitleBlock)(
	(props) => css`
		label: BetReceipt__BalanceHeader;

		border-bottom: 1px solid ${ui(props).color_4};
		padding-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledBetReceipt__BetSelection = styled(BetSelection)(
	(props) => css`
		label: BetReceipt__BetSelection;

		padding: ${spacings(props).comfortable}px ${spacings(props).cozy}px;

	`,
);

const StyledBetReceipt__RejectionMessage = styled('div')(
	(props) => css`
		label: BetReceipt__RejectionMessage;

		padding: ${spacings(props).comfortable}px ${spacings(props).cozy}px ${spacings(props).compact}px;
	`,
);

const StyledBetReceipt__ActionBody = styled('div')(
	(props) => css`
		label: BetReceipt__ActionBody;

		display: flex;
		padding: ${spacings(props).cozy}px;
	`,
);

const StyledBetReceipt__ActionBodyContinue = styled(Button)(
	(props) => css`
		label: BetReceipt__ActionBodyContinue;

		flex: 2;
		margin-left: ${spacings(props).cozy}px;
	`,
);
// End Styling

const BetReceipt = (props) => {
	const {
		t,
		betStatus,
		account_balance,
		currencyType,
		bets,
		className,
		handleReBet,
		backToBetting,
		betSelectionProps,
		receiptId,
		receiptMethodTitle,
		wasPriceBumped,
		receiptType,
		subtitle,
		isSingleWallet,
	} = props;

	let { interceptRejectReason, interceptMessage, receiptMethod } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const betIsRefunded = betStatus === BET_FULL_REFUND_STATUS;
	const betIsRejected = betStatus === BET_REJECTED_STATUS || betStatus === BET_CANCELLED_STATUS;

	let notificationProps;
	if (betIsRefunded || betIsRejected) {
		notificationProps = {
			message: betIsRefunded ? t('BetReceipt__Refunded') : t('BetReceipt__Rejected'),
			type: betIsRefunded ? 'warning' : 'danger',
		};
	}

	// If an Eachway bet
	if (bets.length > 1) {
		const rejectedBets = bets.filter(
			(bet) => bet.status === BET_REJECTED_STATUS || bet.status === BET_CANCELLED_STATUS,
		);
		const refundedBets = bets.filter((bet) => bet.status === BET_FULL_REFUND_STATUS);
		const approvedBets = bets.filter(
			(bet) =>
				bet.status !== BET_REJECTED_STATUS &&
				bet.status !== BET_CANCELLED_STATUS &&
				bet.status !== BET_FULL_REFUND_STATUS,
		);

		// Check to see if a bet has been fully rejected or refunded
		if (
			(refundedBets.length && refundedBets.length !== bets.length) ||
			(rejectedBets.length && rejectedBets.length !== bets.length)
		) {
			if (rejectedBets.length) {
				interceptRejectReason = rejectedBets[0].rejection_reason;
				interceptMessage = rejectedBets[0].rejection_comment;

				notificationProps = {
					message: t('BetReceipt__RejectedSingle'),
					type: 'info',
				};
			} else {
				notificationProps = {
					message: t('BetReceipt__RefundedSingle'),
					type: 'info',
				};
			}

			 

			receiptMethod = approvedBets
				.concat(rejectedBets)
				.concat(refundedBets)
				.sort((a, b) => new Date(a.date) - new Date(b.date))
				.map((bet) => {
					const betType = bet.bet_type === BET_TYPE_WIN ? t('Win') : t('Place');
					const isRefunded = bet.status === BET_FULL_REFUND_STATUS;
					const isRejected = bet.status === BET_REJECTED_STATUS || bet.status === BET_CANCELLED_STATUS;
					
					return (
						<Text size="-1" key={betType}>
							{`${betType} ${formatOddsAsPrice(bet.odds)} - `}
							<Text size="-1" tag="span" type={isRejected ? 'danger' : isRefunded ? 'warning' : 'success'}>
								{isRejected ? t('Rejected') : isRefunded ? t('Refunded') : t('Approved')}
							</Text>
							{!isRejected && (
								<Text key={receiptId} size="-1" strong>
									- #{bet.id}
								</Text>
							)}
						</Text>
					);
				});
		}
	}

	return (
		<div className={componentClasses}>
			{!isSingleWallet && (
				<StyledBetReceipt__BalanceHeader
					align="center"
					header={<CurrencyDisplay amount={account_balance} type={currencyType} />}
					subheader={subtitle}
				/>
			)}
			{notificationProps && <Notification strong {...notificationProps} />}
			<StyledBetReceipt__BetSelection {...betSelectionProps} displayPriceIsBumped={wasPriceBumped} />
			<Receipt
				type={receiptType}
				number={receiptId}
				error={interceptRejectReason}
				errorTitle={interceptRejectReason ? t('BetReceipt__RejectReason') : null}
				method={receiptMethod}
				methodTitle={receiptMethodTitle}
				hasBackground
				icon={betIsRejected || betIsRefunded ? 'receipt-crossed' : 'receipt'}
			/>
			{interceptMessage && (
				<StyledBetReceipt__RejectionMessage>
					<Text strong>{t('BetReceipt__Trader')}:</Text>
					<Text>{interceptMessage}</Text>
				</StyledBetReceipt__RejectionMessage>
			)}
			<StyledBetReceipt__ActionBody>
				<Button
					className={css`
						flex: 1;
					`}
					type="secondary"
					size="medium"
					block
					action={handleReBet}
				>
					{t('Bet Again')}?
				</Button>
				<StyledBetReceipt__ActionBodyContinue type="calltoaction" size="medium" block action={backToBetting}>
					{t('BetReceipt__Continue')}
				</StyledBetReceipt__ActionBodyContinue>
			</StyledBetReceipt__ActionBody>
		</div>
	);
};

BetReceipt.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The user's remaining account balance */
	account_balance: PropTypes.number.isRequired,

	/** Action to handle the rebet action */
	handleReBet: PropTypes.func.isRequired,

	/** Action to navigate away from the bet receipt */
	backToBetting: PropTypes.func.isRequired,

	/** The list of props for the BetSelection component */
	betSelectionProps: PropTypes.object.isRequired,

	/** The array of placed bets */
	bets: PropTypes.array.isRequired,

	/** The ID of the place bet/receipt */
	receiptId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,

	/** Extra information to show on the receipt */
	receiptMethod: PropTypes.node.isRequired,

	/** The title of the extra information to show on the receipt */
	receiptMethodTitle: PropTypes.node.isRequired,

	/** Whether the application is running in single wallet mode (and this isn't a tournament bet) or not */
	isSingleWallet: PropTypes.bool.isRequired,

	/** The subheader to put underneath the account balance amount */
	subtitle: PropTypes.node.isRequired,

	/** The status of the bet placed */
	betStatus: PropTypes.string,

	/** What type of currency should be displayed */
	currencyType: PropTypes.oneOf(['currency', 'bonusCurrency', 'tournamentCurrency']),

	/** The type of receipt to display */
	receiptType: PropTypes.string,

	/** The final price was bumped up */
	wasPriceBumped: PropTypes.bool,

	/** Reason for the bet being rejected */
	interceptRejectReason: PropTypes.string,

	/** Extra trader comment if the bet was rejected */
	interceptMessage: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

BetReceipt.defaultProps = {
	className: '',
	betStatus: '',
	currencyType: 'currency',
	interceptRejectReason: null,
	wasPriceBumped: false,
	interceptMessage: null,
	receiptType: 'Deposit',
};

export default withNamespaces()(BetReceipt);
