import {get, post} from '../../common/Ajax';
import {createAction} from '../../common/actions/actionHelpers';
import {UPDATE_LOSS_LIMIT} from './lossLimitsActionsTypes';


/**
 * Action to Get the Loss Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const getLossLimit = (period) => (dispatch) => {

	return get(`/user/bet-limit?period=${period}`)
		.then((response) => {
			let payload = (
				period === 'daily' ?
					{daily_loss_limit: response.data.data} :
					period === 'weekly' ?
						{weekly_loss_limit: response.data.data} :
						{monthly_loss_limit: response.data.data}
			);
			return dispatch(createAction(UPDATE_LOSS_LIMIT, payload));
		}).catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};


/**
 * Action to Set the Loss Limit
 *
 * @param amount: number
 * @param period: 1, 7 or 30
 */
export const setLossLimit = (amount, period) => () => {
	return post('/user/set-bet-limit', {amount, period})
		.then((response) => {
			
			return response;
		}).catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};


/**
 * Action to Remove the Loss Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const removeLossLimit = (period) => () => {
	return post('/user/remove-bet-limit', {period})
		.then((response) => {
			return response;
		}).catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};


/**
 * Action to Update the Loss Limit
 *
 * @param period: 'daily', 'weekly' or 'monthly'
 */
export const updateLossLimit = (period) => () => {
	return post(`/user/update-bet-limit?period=${period}`)
		.then((response) => {
			return response;
		}).catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			throw error;
		});
};