import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { debounce } from 'lodash-es';
import { withNamespaces } from 'react-i18next';

import {
	spacings,
	Button,
	ButtonGroup,
	Checkbox,
	Icon,
	Input,
	InputGroup,
	Link,
	Notification,
	Text,
	PlotElements,
} from '@tbh/ui-kit';
import CurrencyEntryKeypad from '../../Application/Currency/CurrencyEntryKeypad/CurrencyEntryKeypad';
import CurrencyInput from '../../Application/Currency/CurrencyInput/CurrencyInput';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

const StyledPoliForm = styled('div')(
	(props) => css`
		label: PoliForm;

		padding: ${spacings(props).cozy}px;
	`,
);

const StyledPoliForm__Notification = styled(Notification)(
	(props) => css`
		label: PoliForm__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledPoliForm__Keypad = styled(CurrencyEntryKeypad)(
	(props) => css`
		label: PoliForm__Keypad;

		padding-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledPoliForm__Terms = styled(Text)(
	(props) => css`
		label: PoliForm__Terms;

		padding-bottom: ${spacings(props).cozy}px;
	`,
);

class PoliForm extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Submit deposit */
		onDepositClick: PropTypes.func.isRequired,

		/** validate promo code */
		onValidatePromoCode: PropTypes.func.isRequired,

		/** Clear errors in store */
		clearDepositErrors: PropTypes.func.isRequired,

		/** Clear store data on unmount */
		clearDepositsStore: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Values to use on currency key pad */
		buttonValues: PropTypes.array,

		/** Errors Poli link generation */
		depositErrors: PropTypes.string,

		/** Whether the is valid */
		isPromoCodeValid: PropTypes.bool,

		/** Cancel Process */
		onCancel: PropTypes.func,

		/** Render checkbox for Terms and Conditions */
		termsAndConditionsRequired: PropTypes.bool,

		/** Render checkbox for Terms and Conditions */
		termsAndConditionsLink: PropTypes.string,

		/** Valid promo from store deposit */
		validPromoCode: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		buttonValues: [1000, 2500, 5000, 10000, 25000, 50000],
		depositErrors: null,
		isPromoCodeValid: undefined,
		onCancel: null,
		termsAndConditionsRequired: false,
		termsAndConditionsLink: 'https://help.topbetta.com.au/knowledge-base/bonus-bets-terms-conditions/',
		validPromoCode: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			depositAmount: 1000,
			promoCode: '',
			termsAccepted: false,
		};
	}

	componentWillUnmount() {
		this.props.clearDepositsStore();
	}

	/**
	 * Control amount changes via keypad
	 * @param e
	 * @param value
	 */
	handleKeypadClick = (e, value) => {
		e.preventDefault();
		this.setState({
			depositAmount: this.state.depositAmount + value,
		});
	};

	/**
	 * Control text inputs
	 */
	handleInputChange = (name, value) => {
		this.setState(
			{
				[name]: value,
			},
			() => {
				if (name === 'promoCode' && value) {
					if (value) {
						this.debounced();
					}
				}
			},
		);
	};

	/**
	 * Debounce and validate promo code
	 * @type {Function}
	 */
	debounced = debounce(() => {
		this.props.onValidatePromoCode(this.state.promoCode);
	}, 1100);

	/**
	 * Handler for the terms and conditions checkbox
	 */
	handleCheckbox = () => {
		this.setState({
			termsAccepted: !this.state.termsAccepted,
		});
	};

	/**
	 * Submit Deposit
	 */
	handleDeposit = () => {
		this.handleDismissErrors();
		this.props.onDepositClick(this.state.depositAmount);
	};

	/**
	 * Clear the deposit errors
	 */
	handleDismissErrors = () => {
		this.props.clearDepositErrors();
	};

	/** Check local rules to enable/disable deposit action */
	isDepositEnabled = () => {
		return (
			this.state.depositAmount >= 1000 && (this.props.termsAndConditionsRequired ? this.state.termsAccepted : true)
		);
	};

	render() {
		const {
			t,
			className,
			buttonValues,
			isPromoCodeValid,
			onCancel,
			validPromoCode,
			depositErrors,
			termsAndConditionsRequired,
		} = this.props;
		const { depositAmount, promoCode, termsAccepted } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledPoliForm className={componentClasses}>
				{depositErrors && (
					<StyledPoliForm__Notification
						buttonAction={this.handleDismissErrors}
						buttonProps={{ type: 'danger' }}
						buttonText="Dismiss"
						type={Notification.types.COLOUR_DANGER}
						message={depositErrors}
						strong
					/>
				)}

				<Text strong paragraph>
					{t('PoliForm__Title', {poli: 'POLi'})}
				</Text>

				<StyledPoliForm__Keypad buttonAction={this.handleKeypadClick} buttonValues={buttonValues} />

				<InputGroup>
					<CurrencyInput
						label={t('DepositForm__Deposit')}
						value={depositAmount.toString()}
						name="depositAmount"
						onChange={this.handleInputChange}
						horizontalMargin="cozy"
						margin="cozy"
						placeholder="$10 Min Deposit"
					/>
					<Input
						label={t('PromoCode')}
						name="promoCode"
						value={validPromoCode || promoCode}
						valid={promoCode ? isPromoCodeValid : null}
						error={(validPromoCode || promoCode) && isPromoCodeValid === false ? 'Not eligible promo!' : null}
						onChange={this.handleInputChange}
						margin="cozy"
					/>
				</InputGroup>

				{termsAndConditionsRequired && (
					<StyledPoliForm__Terms size="-1">
						<Checkbox
							name="termsAccepted"
							value="termsAccepted"
							action={this.handleCheckbox}
							checked={termsAccepted}
							pushRight="1"
							inline
						/>
						{`${t('Accept')} `}
						<Link target="blank" href={this.props.termsAndConditionsLink} type="secondary">
							{t('termsAndConditions')}
						</Link>
					</StyledPoliForm__Terms>
				)}

				{onCancel ? (
					<ButtonGroup center>
						<Button action={onCancel} type="ghost" autoFlex>
							Cancel
						</Button>
						<Button action={this.handleDeposit} disabled={!this.isDepositEnabled()} type="primary" autoFlex>
							<PlotElements>
								<span>{t('Deposit')}&nbsp;</span>
								<CurrencyDisplay amount={depositAmount} />
								<span>&nbsp;{`${t('via')} POLi`}&nbsp;</span>
								<Icon icon="log-out" size="-1" />
							</PlotElements>
						</Button>
					</ButtonGroup>
				) : (
					<Button action={this.handleDeposit} disabled={!this.isDepositEnabled()} type="primary" block>
						<PlotElements>
							<span>{t('Deposit')}&nbsp;</span>
							<CurrencyDisplay amount={depositAmount} />
							<span>&nbsp;{`${t('via')} POLi`}&nbsp;</span>
							<Icon icon="log-out" size="-1" />
						</PlotElements>
					</Button>
				)}
			</StyledPoliForm>
		);
	}
}

export default withNamespaces()(PoliForm);
