import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import moment from 'moment';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { application, spacings, typography, media, Button, ButtonGroup, Text } from '@tbh/ui-kit';

import DatePicker from '../../../ui-elements/Forms/DatePicker/DatePicker';
import { serverDateTime } from '../../../../legacy/core/format';
import { BREAKPOINTS, FAMILYS, MEDIA_QUERIES } from '../../../../constants/themes';

const StyledRacingDateSelection__Button = styled(Button)(
	(props) => css`
    label: RacingDateSelection__Button;
    border: 1px solid black;
    font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};

		// margin-top: ${spacings(props).compact}px;
		// This is to keep all the buttons at the top of the race card the same width
		min-width: 120px;
    max-width: 120px;
    border-radius: 4px;
    @media (max-width: ${BREAKPOINTS.laptopMin}px) {
      max-width: 100%;
    }

    width:100%;

		&:hover {
			// This is to override the specificity of the Text component
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
      background:#850000;
		}

    margin-left:0px!important;



    // In case the button is active the color will be red
    ${
			props.active
				? `
      background:#850000;
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
    `
				: `
        background:white;
        ${Text} {
          color:black;
        }
    `
		}
	`,
	media(
		(props) => css`
			min-width: ${application(props).racing.button_width_expanded}px;
		`,
		780,
	),
);

const StyledRacingDateSelection__DatePicker = styled(DatePicker)(
	(props) => css`
		label: RacingDateSelection__DatePicker;

    margin-left:0px!important;
    border: 1px solid black;
    line-height: 1.5;
    // margin-top: ${spacings(props).compact}px;
    font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
    border-radius: 4px;
		min-width: 120px;

    max-width: 120px;
    @media (max-width: ${BREAKPOINTS.laptopMin}px) {
      max-width: 100%;
    }

    width:100%;
		${DatePicker.StyledButton} {
			// This is to keep all the buttons at the top of the race card the same width
			min-width: ${application(props).racing.button_width}px;
      width:100%;
      padding:0px;
      margin-left:0px!important;
		}
    .react-datepicker-wrapper {
      width:100%;
    };
    .react-datepicker__input-container {
      width:100%;
    };
   

		// Overrides the position of the popover triangle
		.react-datepicker__triangle {
			right: ${spacings(props).spacious}px;
			left: auto;
		}
    &:hover {
			// This is to override the specificity of the Text component
			${Text} {
				color: ${typography(props).color_1_alternate};
			}
      background:#850000;
		}

	`,

	media(
		(props) => css`
			${DatePicker.StyledButton} {
				min-width: ${application(props).racing.button_width_expanded}px;
			}
		`,
		780,
	),
);

const StyledRacingDateSelection = styled(ButtonGroup)(
	(props) => css`
		label: RacingDateSelection;
		display: flex;
    flex-direction:row;
    flex-wrap: nowrap!important;
		justify-content: flex-end;
		align-items: center;
    // margin-bottom: ${spacings(props).compact}px;
    gap: 15px;
    font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
    height: 100%;
    width:fit-content!important;

    @media (max-width: ${BREAKPOINTS.laptopMin}px) {
      width: 100%!important;
      flex-direction: column;
    }

    @media ${MEDIA_QUERIES.tabletOnly} {
      flex-direction: row;
      margin-left: 30px;
    }

	`,
	media(
		(props) => css`
			justify-content: space-between;

			${StyledRacingDateSelection__Button} {
        height: 100%;
      height:30px;
        border: 1px solid black;
        border-radius: 4px;
				// min-width: ${application(props).racing.button_width_expanded}px;
			}

			${StyledRacingDateSelection__DatePicker} {
        height: 100%;
      height:30px;
        border: 1px solid black;
        border-radius: 4px;
				// This is to keep all the buttons at the top of the race card the same width
				// min-width: ${application(props).racing.button_width_expanded}px;
			}
		`,
		780,
	),
);

const cssRacingDateSelection__Text = css`
	label: RacingDateSelection__Text;

	line-height: inherit;
`;

const TabContainer = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 6px;
	border: 1px solid #666666;
	border-radius: 4px;
	width: 100%;
	font-size: 12px;
	font-weight: 550;
`;

const Tab = styled('div')`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	width: 100%;
	cursor: pointer;
	border-radius: 4px;
	padding: 4px;

	${(props) => props.active && 'background-color: #850000;color: #fff;'}
	button {
		${(props) => (props.active ? 'color: #fff!important;' : 'color: #000!important;')}
		font-size: 12px;
		font-weight: 550;
	}
	&:hover {
		background-color: #850000;
		color: #fff;
	}
`;

const TabDatePicker = styled(DatePicker)`
	display: flex;
	flex: 1;
	justify-content: center !important;
	align-items: center !important;
`;

const RacingDateSelectionV2 = (props) => {
	const { t, date, handleSelectDay, className, size } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const day = serverDateTime(moment(date));

	/**
	 * add  today clcik event @HW22Nov2019
	 **/
	const handleTodayClick = () => {
		handleSelectDay(serverDateTime(moment()), 'Today');
	};

	const handleYesterdayClick = () => {
		handleSelectDay(serverDateTime(moment().subtract(1, 'day')), 'Yesterday');
	};

	const handleTomorrowClick = () => {
		handleSelectDay(serverDateTime(moment().add(1, 'day')), 'Tomorrow');
	};

	const handleSelectedDayClick = (datePicker, day) => {
		// console.log(day)
		if(!day) {
			return;
		}

		handleSelectDay(serverDateTime(day.startOf('day')), 'SelectDate');
	};

	/**
	 * TODO: This method has been duplicated in the RacingHomePage and should be moved to a common point
	 * @param day
	 * @returns {number}
	 */
	const diffFromToday = (day) =>
		serverDateTime(moment())
			.startOf('day')
			.diff(day.startOf('day'), 'days');

	const handleIsActive = (dayString, date) => {
		switch (dayString) {
			// @HW 22Nov2019  add todaybutton style
			case 'today': {
				return diffFromToday(date) === 0;
			}
			case 'yesterday': {
				return diffFromToday(date) === 1;
			}
			case 'tomorrow': {
				return diffFromToday(date) === -1;
			}
			default:
				return false;
		}
	};

	const getButtonStyle = (dayString, date) => {
		switch (dayString) {
			// @HW 22Nov2019  add todaybutton style
			case 'today': {
				return diffFromToday(date) === 0 ? 'primary' : undefined;
			}
			case 'yesterday': {
				return diffFromToday(date) === 1 ? 'primary' : undefined;
			}
			case 'tomorrow': {
				return diffFromToday(date) === -1 ? 'primary' : undefined;
			}
			default:
				return undefined;
		}
	};

	const getTextStyle = (dayString, date) => {
		switch (dayString) {
			// @HW 22Nov2019  add todaybuttonText style
			case 'today': {
				return diffFromToday(date) === 0 ? 'default' : 'alternate';
			}
			case 'yesterday': {
				return diffFromToday(date) === 1 ? 'default' : 'alternate';
			}
			case 'tomorrow': {
				return diffFromToday(date) === -1 ? 'default' : 'alternate';
			}
			default:
				return 'default';
		}
	};

	const buttonStyles = {
		today: {
			active: handleIsActive('today', day),
			button: getButtonStyle('today', day),
			text: getTextStyle('today', day),
		},
		tomorrow: {
			active: handleIsActive('tomorrow', day),
			button: getButtonStyle('tomorrow', day),
			text: getTextStyle('tomorrow', day),
		},
		yesterday: {
			active: handleIsActive('yesterday', day),
			button: getButtonStyle('yesterday', day),
			text: getTextStyle('yesterday', day),
		},
	};

	const tomorrowPlusOne = serverDateTime(date);
	if (size.width > 780) {
		return (
			<StyledRacingDateSelection
				className={componentClasses}
				spacing={ButtonGroup.spacings.SPACING_TIGHT}
				right
				size={size}
			>
				{/* {handleFeaturedRacingClick && (
					<StyledRacingDateSelection__Button action={handleFeaturedRacingClick} size="small">
						<Text align="center" size="-1" className={cssRacingDateSelection__Text}>
							{t('RacingDateSelection__Futures')}
						</Text>
					</StyledRacingDateSelection__Button>
				)} */}
				<StyledRacingDateSelection__Button
					hoverType="primary"
					active={buttonStyles.today.active}
					action={handleTodayClick}
					size="small"
				>
					<Text align="center" size="-1" className={cssRacingDateSelection__Text}>
						Today
					</Text>
				</StyledRacingDateSelection__Button>

				<StyledRacingDateSelection__Button
					// hoverType="primary"
					active={buttonStyles.tomorrow.active}
					// type={buttonStyles.tomorrow.button}
					action={handleTomorrowClick}
					size="small"
				>
					<Text
						align="center"
						size="-1"
						// type={buttonStyles.tomorrow.text}
						className={cssRacingDateSelection__Text}
					>
						Tomorrow
					</Text>
				</StyledRacingDateSelection__Button>
				<StyledRacingDateSelection__DatePicker
					date={tomorrowPlusOne}
					action={handleSelectedDayClick}
					button
					buttonSize="medium"
					popperPlacement="bottom-end"
					size={size}
				/>
			</StyledRacingDateSelection>
		);
	}

	return (
		<TabContainer>
			<Tab active={buttonStyles.tomorrow.active} onClick={handleSelectedDayClick}>
				<TabDatePicker
					className={componentClasses}
					date={tomorrowPlusOne}
					action={handleSelectedDayClick}
					button
					buttonSize="medium"
					right
					size={size}
				/>
			</Tab>
			<Tab active={buttonStyles.yesterday.active} onClick={handleYesterdayClick}>
				{t('Yesterday')}
			</Tab>
			<Tab active={buttonStyles.today.active} onClick={handleTodayClick}>
				{t('Today')}
			</Tab>
			<Tab active={buttonStyles.tomorrow.active} onClick={handleTomorrowClick}>
				{t('Tomorrow')}
			</Tab>
		</TabContainer>
	);
};

RacingDateSelectionV2.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Selected date */
	date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),

	/** Navigate to date */
	handleSelectDay: PropTypes.func,

	/** Navigate to featured racing when /sports/feature-racing is available */
	// handleFeaturedRacingClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),

	/** Extra classes */
	className: PropTypes.string,
};

RacingDateSelectionV2.defaultProps = {
	date: undefined,
	handleSelectDay: () => undefined,
	handleFeaturedRacingClick: false,
	className: null,
};

const isEquivalent = (prevProps, nextProps) => {
	return prevProps.date === nextProps.date;
};


// export default withNamespaces()(RacingDateSelectionV2);
export default React.memo(withNamespaces()(RacingDateSelectionV2), isEquivalent);
