import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearBankEFTData, fetchDepositDetails } from '../../store/deposits/depositActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';
import BankEFTDeposit from '../../components/features/Deposit/BankEFTDeposit/BankEFTDeposit';
import { DEPOSIT_DEFAULT, DEPOSIT_USER_ACCOUNT } from '../../store/deposits/depositsReducerNames';

class BankEFTDepositContainer extends React.Component {
	static propTypes = {
		/** User details */
		username: PropTypes.string.isRequired,
		userId: PropTypes.number.isRequired,

		/** Topbetta's Bank name */
		eftBankName: PropTypes.string,

		/** BSB number */
		eftBsb: PropTypes.string,

		/** Bank logo url. From backend */
		eftBankLogo: PropTypes.string,

		/** Bank logo url. From backend */
		eftAccountNumber: PropTypes.string,

		/** Functions */
		/** Track on close click */
		track: PropTypes.func,

		/** Load bank details on mount */
		loadDetails: PropTypes.func,

		/** clear data on store on unmount */
		clearBankEFTData: PropTypes.func,

		/** On 'Got it...' handler */
		onButtonClick: PropTypes.func,

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		eftBankName: undefined,
		eftBsb: undefined,
		eftAccountNumber: undefined,
		eftBankLogo: undefined,
		clearBankEFTData: () => undefined,
		loadDetails: () => undefined,
		onButtonClick: undefined,
		track: () => undefined,
		brandName: '',
	};

	componentDidMount() {
		this.props.loadDetails();
	}

	componentWillUnmount() {
		this.props.clearBankEFTData();
	}

	/**
	 * Handle click on 'Got it..' button
	 */
	handleButtonClick = () => {
		this.props.track('Close');
		this.props.onButtonClick();
	};

	render() {
		const { eftBsb, eftBankName, eftBankLogo, eftAccountNumber, onButtonClick, username, userId, brandName } = this.props;

		return (
			<BankEFTDeposit
				username={username}
				userId={userId}
				bankName={eftBankName}
				bsb={eftBsb}
				accountNumber={eftAccountNumber}
				logo={eftBankLogo}
				onGotItClick={onButtonClick ? this.handleButtonClick : null}
				brandName={brandName}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const sliceOfState = ownProps.renderOnPage ? state[DEPOSIT_USER_ACCOUNT] : state[DEPOSIT_DEFAULT];

	const { eftBankName, eftBsb, eftAccountNumber, eftBankLogo } = sliceOfState;
	const { username, id: userId } = state.entities.users[state.application.authenticatedUser];

	const brandName = state.acl.brandDetails && state.acl.brandDetails.name;

	return { eftBankName, eftBsb, eftAccountNumber, eftBankLogo, userId, username, brandName };
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const reducerName = ownProps.renderOnPage ? DEPOSIT_USER_ACCOUNT : DEPOSIT_DEFAULT;

	return {
		track: (action) => dispatch(trackGaEvent('EFT Deposit', 'Click', action)),
		loadDetails: () => dispatch(fetchDepositDetails(reducerName, 'bankdeposit')),
		clearBankEFTData: () => dispatch(clearBankEFTData(reducerName)),
	};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(BankEFTDepositContainer);

Container.propTypes = {
	onButtonClick: PropTypes.func,
};

Container.defaultTypes = {
	onButtonClick: undefined,
};

export default Container;
