import {
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING,
	USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
	USER_ACCOUNT_SUBMENU_DEPOSIT,
	USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS,
	USER_ACCOUNT_SUBMENU_WITHDRAWALS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_USER_HOME,
	USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
} from '../../pages/UserAccount/UserAccountPageConstants';
import UserAccountMenuItems from '../../pages/UserAccount/UserAccountPage.data';
import UserAccountV2MenuItems from '../../pages/UserAccountV2/UserAccountV2Page.data';
import { USER_ACCOUNT_BONUS_BET_TRANSACTION_URL, USER_ACCOUNT_DEPOSIT_URL, USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL, USER_ACCOUNT_RNS_ACCOUNT_URL, USER_ACCOUNT_SETTINGS_URL, USER_ACCOUNT_TRANSACTIONS_URL, USER_ACCOUNT_WITHDRAW_URL } from '../../pages/UserAccountV2/UserAccountPageConstants';

/**
 * Gets the user account menu items and filter the responsible gambling items if the feature is toggled
 *
 * @param state
 * @returns {*}
 */
export const getUserAccountMenuItems = (state) => {
	const featureToggles = state.featureToggles.features;
	const { accountTransactions, responsibleGambling, depositsAndWithdrawals, bonusBets, userProfile } = featureToggles;

	let menuItems = UserAccountMenuItems;

	// Remove the user profile items from the menu items if the feature is toggled
	if (userProfile && !userProfile.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_SUBMENU_USER_HOME && menuItem.id !== USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
		);
	}

	// Remove the account transactions items from the menu items if the feature is toggled
	if (accountTransactions && !accountTransactions.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS &&
				menuItem.id !== USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
		);
	}

	// Remove the responsible gambling items from the menu items if the feature is toggled
	if (userProfile && !userProfile.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_SUBMENU_USER_HOME && menuItem.id !== USER_ACCOUNT_SUBMENU_RESET_PASSWORD,
		);
	}

	// Remove the account transactions items from the menu items if the feature is toggled
	if (accountTransactions && !accountTransactions.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS &&
				menuItem.id !== USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
		);
	}

	// Remove the responsible gambling items from the menu items if the feature is toggled
	if (responsibleGambling && !responsibleGambling.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_SUBMENU_RESPONSIBLE_GAMBLING &&
				menuItem.id !== USER_ACCOUNT_SUBMENU_DEFAULT_BET_AMOUNTS,
		);
	}

	// Remove the deposits and withdrawals items from the menu items if the feature is toggled
	if (depositsAndWithdrawals && !depositsAndWithdrawals.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_SUBMENU_DEPOSIT &&
				menuItem.id !== USER_ACCOUNT_SUBMENU_WITHDRAW_FUNDS &&
				menuItem.id !== USER_ACCOUNT_SUBMENU_WITHDRAWALS,
		);
	}

	// Remove the bonus bets item from the menu items if the feature is toggled
	if (bonusBets && !bonusBets.enabled) {
		menuItems = menuItems.filter((menuItem) => menuItem.id !== USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS);
	}

	return menuItems;
};
export const getUserAccountV2MenuItems = (state) => {
	const featureToggles = state.featureToggles.features;
	const { accountTransactions, responsibleGambling, depositsAndWithdrawals, bonusBets, userProfile } = featureToggles;

	let menuItems = UserAccountV2MenuItems;

	if (userProfile && !userProfile.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_SETTINGS_URL &&
				menuItem.id !== USER_ACCOUNT_RNS_ACCOUNT_URL
		);
	}

	// Remove the responsible gambling items from the menu items if the feature is toggled
	if (responsibleGambling && !responsibleGambling.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_RESPONSIBLE_GAMBLING_URL
		);
	}

	// Remove the deposits and withdrawals items from the menu items if the feature is toggled
	if (depositsAndWithdrawals && !depositsAndWithdrawals.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_WITHDRAW_URL&&
				menuItem.id !== USER_ACCOUNT_DEPOSIT_URL
		);
	}

	// Remove the account transactions items from the menu items if the feature is toggled
	if (accountTransactions && !accountTransactions.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_TRANSACTIONS_URL
		);
	}

	// Remove the account transactions items from the menu items if the feature is toggled
	if (bonusBets && !bonusBets.enabled) {
		menuItems = menuItems.filter(
			(menuItem) =>
				menuItem.id !== USER_ACCOUNT_BONUS_BET_TRANSACTION_URL
		);
	}

	return menuItems;
};

