import styled from 'react-emotion';

const IMAGE_MODIFIERS = {
    exSmall: () => `
        width: 24px;
        height: 24px;
    `,
    small: () => `
        width: 48px;
        height: 48px;
    `,
    medium: () => `
        width: 64px;
        height: 64px;
    `,
    large: () => `
        width: 84px;
        height: 84px;
    `,
    exLarge: () => `
        width: 124px;
        height: 124px;
    `,
    static: () => `
        width: 48px;
        height: 27px;
    `,
    circle: () => `
        border-radius: 50%;
    `,
};

const Image = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;

    ${props => props.modifiers && IMAGE_MODIFIERS[props.modifiers] ? IMAGE_MODIFIERS[props.modifiers] : ''}
`;

export default Image;