import styled, { css } from 'react-emotion';
import { application, spacings, typography, ui, media } from '@tbh/ui-kit';
import { ListItem } from '@tbh/ui-kit';
import RaceBettingAdd from '../../../Racing/RaceBettingAdd/RaceBettingAdd';

/**
 * Define all the selection statuses & racing type codes as defined in the racing constants file
 */
import {
    SELECTION_LATE_SCRATCHED_STATUS,
    RACING_BET_TYPE_MARGIN,
} from '../../../../../common/constants/Racing';

export const StyledGRSExpertSelectionsListItem = styled(ListItem)`
${(props) =>
        css`
        label: GRSExpertSelectionsListItem;

        list-style: none;
        max-height: 35px;

        &:nth-of-type(3n + 1):hover,
        &:hover {
            background: ${ui(props).color_3};
        }

        ${props.selection_status === SELECTION_LATE_SCRATCHED_STATUS &&
            css`
                &:before {
                    display: block;
                    position: absolute;
                    background: ${ui(props).color_5};
                    color: ${typography(props).color_1_alternate};
                    padding: ${spacings(props).tight}px;
                    width: 30px;
                    height: 30px;
                    border-radius: 20px;
                    font-size: ${typography(props)['size_-2']};
                    line-height: 24px;
                    text-align: center;
                    content: 'LS';
                }
            `};
    `};
`;

export const StyledGRSExpertRaceBettingAdd = styled(RaceBettingAdd)(
    (props) => css`
    flex: 0 0 ${application(props).racing.odds_button_width}px;
    max-width: ${application(props).racing.odds_button_width}px;
    margin: 0 ${spacings(props).constrictive}px;
    text-align: center;

    // -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    // -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);

    &:last-child {
        margin-right: 0;
    }

    ${props.scratched &&
        css`
            display: none;
        `};

    ${props.betType === RACING_BET_TYPE_MARGIN &&
        css`
            margin: 0;
        `};

    ${props.small &&
        css`
            flex: 0 0 ${application(props).racing.odds_button_width_small}px;
            max-width: ${application(props).racing.odds_button_width_small}px;
        `};
`,
    media(
        (props) => css`
        ${props.small &&
            css`
                flex: 0 0 ${application(props).racing.odds_button_width}px;
                max-width: ${application(props).racing.odds_button_width}px;
            `};
    `,
        660,
    ),
);

export const StyledGRSExpertSelectionPricesList = styled('ul')`
${(props) =>
        css`
        label: GRSExpertSelectionPricesList;

        padding: ${spacings(props).constrictive}px 0 ${spacings(props).tight}px;
    `};
`;

export const StyledGRSExpertSelectionPriceListItem = styled(ListItem)`
${(props) =>
        css`
        label: GRSExpertSelectionPriceListItem;

        list-style: none;

        float: right !important;
        padding: 2px 0;
    `};
`;

export const StyledGRSExpertSelectionsListItem__Wrapper = styled('div')(
    (props) =>
        css`
        label: GRSExpertSelectionsListItem__Wrapper;

        // Set a min-height so the trends don't overlap
        min-height: ${spacings(props).roomy}px;
        display: flex;
        align-items: center;
    `,
);