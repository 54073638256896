import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { LoadingMask } from '@tbh/ui-kit';

import PendingWithdrawalsList from '../../../components/features/Withdrawal/PendingWithdrawalsList/PendingWithdrawalsList';
import CancelWithdrawalConfirmation from '../../../components/features/Withdrawal/CancelWithdrawalConfirmation/CancelWithdrawalConfirmation';

import { createErrorBoundary } from '../../ErrorBoundary/ErrorBoundaryActions';
import { denormalizeWithdrawals } from '../../../store/entities/schemas/WithdrawalSchema';
import { fetchPendingWithdrawals, cancelWithdrawal } from '../../../store/entities/actions/WithdrawalActions';
import { getAuthenticatedUser } from '../../../store/application/applicationSelectors';

const cssPendingWithdrawalsContainer = css`
	label: PendingWithdrawalsContainer;

	flex: auto;
`;

class PendingWithdrawalsContainer extends React.Component {
	static propTypes = {
		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Cancel a specific withdrawal */
		cancelWithdrawal: PropTypes.func.isRequired,

		/** Fetch all pending withdrawals */
		fetchPendingWithdrawals: PropTypes.func.isRequired,

		/** User's username */
		username: PropTypes.string.isRequired,

		/** The list of withdrawals */
		withdrawals: PropTypes.array,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		withdrawals: [],
		className: '',
	};

	state = {
		isLoading: false,

		// Hold the logic for displaying the withdrawal confirmation window
		withdrawalConfirmation: {
			id: null,
			amount: null,
			isLoading: false,
			isConfirmed: false,
			isError: false,
			callback: null,
		},
	};

	componentDidMount() {
		this.loadPendingWithdrawals();
	}

	loadPendingWithdrawals = () => {
		this.setState({ isLoading: true }, () => {
			this.props.fetchPendingWithdrawals().finally(() => {
				this.setState({ isLoading: false });
			});
		});
	};

	/**
	 * Set the Withdrawal Confirmation window to open
	 *
	 * @param id
	 * @param amount
	 * @param isLoading
	 * @param isConfirmed
	 * @param isError
	 * @param callback
	 */
	openCancelWithdrawalConfirmation = (
		id,
		amount,
		isLoading = false,
		isConfirmed = false,
		isError = false,
		callback = null,
	) => {
		this.setState({
			withdrawalConfirmation: {
				id,
				amount,
				isLoading,
				isConfirmed,
				isError,
				callback,
			},
		});
	};

	/**
	 * Close the Withdrawal Confirmation window
	 */
	closeCancelWithdrawalConfirmation = () => {
		this.openCancelWithdrawalConfirmation(null, null);
	};

	/**
	 * When the user wants to cancel a withdrawal
	 *
	 * @param withdrawalId
	 */
	onWithdrawalCancel = (withdrawalId) => {
		const withdrawal = this.props.withdrawals.find((withdrawal) => withdrawal.id === withdrawalId);
		const { id, amount } = withdrawal;

		const onConfirm = () => {
			this.openCancelWithdrawalConfirmation(id, amount, true, false);
			return this.props
				.cancelWithdrawal(id)
				.then((response) => {
					return response;
				})
				.then(() => this.openCancelWithdrawalConfirmation(id, amount, false, true))
				.catch(() => {
					return this.openCancelWithdrawalConfirmation(id, amount, false, false, true);
				});
		};

		return this.openCancelWithdrawalConfirmation(id, amount, false, false, false, onConfirm);
	};

	render() {
		const { className, withdrawals, size } = this.props;
		const { withdrawalConfirmation } = this.state;

		const containerClasses = cx(cssPendingWithdrawalsContainer, {
			[className]: className,
		});

		return (
			<div className={containerClasses}>
				<LoadingMask loading={this.state.isLoading} />

				{withdrawalConfirmation.id ? (
					<CancelWithdrawalConfirmation
						amount={withdrawalConfirmation.amount}
						onPromptConfirm={withdrawalConfirmation.callback}
						onPromptClose={this.closeCancelWithdrawalConfirmation}
						isLoading={withdrawalConfirmation.isLoading}
						isConfirmed={withdrawalConfirmation.isConfirmed}
						isError={withdrawalConfirmation.isError}
					/>
				) : (
					<PendingWithdrawalsList
						size={size}
						pendingWithdrawals={withdrawals}
						onWithdrawalCancel={this.onWithdrawalCancel}
						isLoading={this.state.isLoading}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		withdrawals: denormalizeWithdrawals(state.entities),
		username: getAuthenticatedUser(state).username,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		cancelWithdrawal: (id) => dispatch(cancelWithdrawal(id)),
		fetchPendingWithdrawals: () => dispatch(fetchPendingWithdrawals()),
	};
};

export default createErrorBoundary(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(PendingWithdrawalsContainer),
);
