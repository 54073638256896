import { denormalize, normalize } from 'normalizr';

import { sportSelectionsSchema } from './Entities';

/**
 * Convert normalized tournament entities back to a structure similar to the API response
 *
 * @param entities
 * @param keys
 */
const denormalizeSportSelections = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.sportSelections), sportSelectionsSchema, entities).filter(
		(sel) => !!sel,
	);
};

/**
 * Normalize the tournament structure to suit JS Applications
 *
 * @param response
 */
const normalizeSportSelections = (response) => {
	return normalize(response, sportSelectionsSchema);
};

module.exports = {
	denormalizeSportSelections,
	normalizeSportSelections,
};
