import { BET_TYPE_DAILY_DOUBLE, BET_TYPE_QUADDIE } from '../store/entities/constants/BetConstants';
import { RACING_BET_TYPE_RUNNING_DOUBLE, RACING_BET_TYPE_RUNNING_TREBLE } from './constants/Racing';

/**
 * A function for building an array of race_ids based on the `first_leg` property.
 * Currently used for exotic bet types containing multi leg functionality, quaddie and dailydouble.
 *
 * @param {example} 2935767
 * @param {example} 2
 * @param {example} 'quaddie'
 */
export const buildRaceLegSequence = (races, raceId, legPosition, product) => {
	// sanity check
	if (!legPosition) {
		return [];
	}
	const firstLeg = product.first_leg - 1;

	if (product.bet_type === BET_TYPE_QUADDIE) {
		/* number of legs to generate */
		const quaddieLegs = 4;
		const quaddiesRaces = races.slice(firstLeg, firstLeg + quaddieLegs);
		const raceIds = quaddiesRaces.map(r => r.id);
		return raceIds;
	}

	if (product.bet_type === RACING_BET_TYPE_RUNNING_TREBLE) {
		const quaddieLegs = 3;
		const quaddiesRaces = races.slice(firstLeg, firstLeg + quaddieLegs);
		const raceIds = quaddiesRaces.map(r => r.id);
		return raceIds;
	}

	if (product.bet_type === BET_TYPE_DAILY_DOUBLE) {
		if (legPosition === 1) {
			const findIndex = races.findIndex(r => r.id === raceId);

			const filtedRaces = [races[findIndex], races[findIndex + 2]];

			return filtedRaces.map(r => r.id);
		}

		if (legPosition === 2) {
			const findIndex = races.findIndex(r => r.id === raceId);

			const filtedRaces = [races[findIndex - 2], races[findIndex]];

			return filtedRaces.map(r => r.id);
		}
	}

	if (product.bet_type === RACING_BET_TYPE_RUNNING_DOUBLE) {
		/* number of legs to generate */
		if (legPosition === 1) {
			const findIndex = races.findIndex(r => r.id === raceId);
			
			const filtedRaces = [races[findIndex], races[findIndex + 1]];

			return filtedRaces.map(r => r.id);
		}

		if (legPosition === 2) {
			const findIndex = races.findIndex(r => r.id === raceId);

			const filtedRaces = [races[findIndex - 1], races[findIndex]];

			return filtedRaces.map(r => r.id);
		}
	}
};
