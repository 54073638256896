import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { brand, ui, typography, spacings } from '@tbh/ui-kit';

import { Icon, Image } from '@tbh/ui-kit';

import AclContext from '../../../../contexts/AclContext';

const StyledMyBetsIcon = styled(Icon)(
	(props) => css`
		label: MyBetsIcon;

		color: ${brand(props).color_1};

		&:before {
			background: ${ui(props).color_2};
			border-radius: 50%;    
			background: #ffffff;
			box-shadow: 1px 0px 4px 1px ${ui(props).color_2};
		}
	`,
);

const StyledMyBetsIcon__Image = styled(Image)(
	(props) => css`
		label: MyBetsIcon__Image;

		position: relative;
		margin: 0 ${spacings(props).tight * props.push}px 0 0;
		text-align: center;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	`,
);

const MyBetsIcon = (props) => {
	const { className, size, push } = props;

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<AclContext.Consumer>
			{(acl) => {
				const { myBetsImage } = acl;
				return !myBetsImage || myBetsImage === 'my-bets' ? (
					<StyledMyBetsIcon className={componentClasses} icon="my-bets" size={size} push={push} />
				) : (
					<StyledMyBetsIcon__Image
						className={componentClasses}
						width={typography(props)[`size_${size}`]}
						src={global.currencyIconModules[`./${myBetsImage}`] || ''}
						alt="My Bet"
						push={push}
					/>
				);
			}}
		</AclContext.Consumer>
	);
};

MyBetsIcon.propTypes = {
	/** Extra styles to pass through */
	className: PropTypes.string,

	/** Size of the icon to use */
	size: PropTypes.oneOf(['-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5']),

	/** How much right margin to give the icon */
	push: PropTypes.oneOf(['0', '1', '2', '3', '4', '5']),
};

MyBetsIcon.defaultProps = {
	className: null,
	size: '-2',
	push: '0',
};

export default MyBetsIcon;
