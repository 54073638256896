import { GRS_NEXJUMP_BASE_URL } from '../pages/GRSNextJump/GRSNextJumpConstants';
import {RACING_NEW_BASE_URL, RACING_THOROUGHBRED_CODE} from '../pages/GRSNewRacing/RacingNewConstrants';
import {slugify} from '../legacy/core/format';
import {SELECTED_DATE_FORMAT} from '../pages/Racing/RacingConstants';
import {SERVER_DATE_FORMAT} from '../common/constants/DateFormats';
import moment from 'moment';

/**
 * Build route for date and type. This function will handle cases where we pass 'YYYY-MM-DDZ' format, e.g. '2010-10-10+11',
 * @param {*} date 
 * @param {*} type 
 */
// export const buildRacingGRSRouteToDateWithType = (type,date) => {
  
//   const datetime = !date ? moment.parseZone(new Date, SELECTED_DATE_FORMAT) : moment.parseZone(date, SELECTED_DATE_FORMAT);
//   let route = `/${RACING_NEW_BASE_URL}`;

//   if (type) {
//     if (type.length === 1) {
//       route += `/${slugify(type.toUpperCase())}`;
//     }
//   } 
//   else {
//     route += '/'+RACING_THOROUGHBRED_CODE.toLowerCase(); // default Thoroughbred
//   }

//   if (datetime.isValid()) {
//     route += `/${datetime.format(SERVER_DATE_FORMAT)}`;
//   }

//   return route;
// };
/**
 *
 * @param type
 * @param meetingName
 * @param raceNumber
 * @param raceId
 * @param meetingId
 * @return {string}
 */
export const buildRouteToRace = (
  date,
  {id: meetingId, name: meetingName, type} = {},
  {number: raceNumber, id: raceId} = {}
) => {

  const datetime = !date ? moment.parseZone(new Date, SELECTED_DATE_FORMAT) : moment.parseZone(date, SELECTED_DATE_FORMAT);
  let route = `/${RACING_NEW_BASE_URL}`; // calling racenew router

  // If no Id route to home
  if (!meetingId || !raceId) {
    return route;
  }

  if (type) {
    if (type.length === 1) {
      route += `/${slugify(type.toUpperCase())}`;
    }
  } 
  else {
    route += '/'+RACING_THOROUGHBRED_CODE.toLowerCase(); // default Thoroughbred
  }

  if (datetime.isValid()) {
    route += `/${datetime.format(SERVER_DATE_FORMAT)}`;
  }

  if (meetingName) {
    route += `/${slugify(meetingName)}`;
  } else {
    route += '/unknown';
  }

  route += `/race-${(raceNumber || 0)}-${raceId}-${meetingId}`;
  return route;
};

export const buildNextRacingRouteFromState = (state) => {
  const {grsRacingHome, entities} = state;
  const {selectedRace, selectedMeeting, selectedDate, selectedType} = grsRacingHome;

  const datetime = moment.parseZone(selectedDate, SELECTED_DATE_FORMAT);
 
  // 'Or' includes alternative cases covered by called function
  if (selectedRace || selectedMeeting) {

    const {meetings, races} = entities;
    return buildRouteToRace(selectedType,datetime,meetings[selectedMeeting], races[selectedRace]);

  }

  // default case
  return `/${GRS_NEXJUMP_BASE_URL}`;
};
/**
 * create new next jump route
 * @HW12-Oct-2019
 */
export const buildRouteToNext2Jump = () => {
    return `/${GRS_NEXJUMP_BASE_URL}`;
  };
