// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces, Trans } from 'react-i18next';

import { spacings, Button, ButtonGroup, Icon, Input, InputGroup, Text } from '@tbh/ui-kit';

// Constants
import { EVENT_KEY_ENTER } from '../../../../../common/constants/Application';

const StyledMobileVerificationForm__Actions = styled(ButtonGroup)(
	(props) => css`
		label: MobileVerificationForm__Actions;

		margin-top: ${spacings(props).comfortable}px;
	`,
);

class MobileVerificationForm extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The mobile number to verify */
		mobile_number: PropTypes.string.isRequired,

		/** The users' first name */
		first_name: PropTypes.string.isRequired,

		/** Action to go to a different step in the registration process */
		goToStep: PropTypes.func.isRequired,

		/** Action to verify the mobile */
		verifyMobile: PropTypes.func.isRequired,


		/** If the user/mobile is verified or not */
		verified: PropTypes.bool,

		/** Shows the loading mask on the Create Account button */
		loadingCreateAccount: PropTypes.bool,

		/** Shows the loading mask on the Verify Mobile button */
		loadingVerifyMobile: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		loadingCreateAccount: false,
		loadingVerifyMobile: false,
		verified: false,
		className: null,
	};

	state = {
		code_1: '',
		code_2: '',
		code_3: '',
		code_4: '',
		code_1_valid: true,
		code_2_valid: true,
		code_3_valid: true,
		code_4_valid: true,
		componentInFocus: 'code_1',
		checked: false,
		error: null,
	};

	componentDidMount() {
		this.inputs.code_1.focus();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.componentInFocus !== this.state.componentInFocus) {
			this.inputs[this.state.componentInFocus].focus();
		};
	};

	inputs = {};
	local_loading = false;
	/**
	 * Handle changes in the inputs
	 *
	 * @param name
	 * @param value
	 */
	handleChange = (name, value) => {
		let codes = ['code_1', 'code_2', 'code_3', 'code_4'];

		if (value.length < 1) {
			this.setState({
				[name]: '',
				[`${name}_valid`]: false,
				error: this.props.t('MobileVerificationForm__CodeError'),
			});

			if (name !== 'code_1') {
				let index = codes.indexOf(name);

				this.setState({
					componentInFocus: codes[index - 1],
				});
				// erase the next inputs;

				for (let i = index; i < codes.length; i++) {
					this.setState({
						[codes[i]]: '',
						[`${codes[i]}_valid`]: false,
					});
				};

			}
			return;
		}

		if (value == ' ' || isNaN(value)) {
			this.setState({
				[name]: '',
				[`${name}_valid`]: false,
				error: this.props.t('MobileVerificationForm__CodeError'),
				componentInFocus: name,
			});
			// Update input so that it doesn't show the space
			this.inputs[name].blur();

			setTimeout(function () {
				this.inputs[name].focus();
			}.bind(this), 200);

			return;
		};

		let c = codes.filter(code => code != name);
		let error = c.filter(code => {
			return !this.state[`${code}_valid`];
		}).length > 0;

		this.setState({
			[name]: value,
			[`${name}_valid`]: true,
			error: error
				? this.props.t('MobileVerificationForm__CodeError')
				: null,
		});

		if (name !== 'code_4') {
			// Gets the last character of the input's name
			let lastChar = name.substr(name.length - 1);
			lastChar++;
			let nextInput = 'code_' + lastChar;

			// Explicitly focus the next input using the raw DOM API

			this.setState({
				componentInFocus: nextInput,
			});
		}


	};

	/**
	 * Returns to the mobile authentication form
	 */
	undo = () => {
		this.props.goBack();
	};

	/**
	 * Verifies the mobile number
	 */
	verifyMobile = () => {

		this.props.verifyMobile(this.state.code_1 + this.state.code_2 + this.state.code_3 + this.state.code_4).then((response) => { 
			this.local_loading = false;
		});
	};

	/**
	 * Toggles checked state of the checkbox
	 */
	onCheckboxClick = () => {
		this.setState({
			checked: !this.state.checked,
		});
	};

	/**
	 * Verifies the mobile number when pressing enter
	 * @param event
	 */
	onKeyDown = (event) => {
		if (event.key === EVENT_KEY_ENTER && event.target.name === 'code_4') {
			// verify if the verification code is a 4 digit number
			let code = parseInt(this.state.code_1 + this.state.code_2 + this.state.code_3 + this.state.code_4);
			let valid = code && code.toString().length === 4;

			if (valid && !this.props.verified && !this.local_loading) {
				this.local_loading = true;
				this.verifyMobile();
			}
		};
	};


	
	render() {
		const {
			t,
			className,
			first_name,
			loadingCreateAccount,
			loadingVerifyMobile,
			mobile_number,
			verified,
		} = this.props;

		const {
			code_1,
			code_2,
			code_3,
			code_4,
			code_1_valid,
			code_2_valid,
			code_3_valid,
			code_4_valid,
			checked,
			error,
			componentInFocus,
		} = this.state;

		const { handleChange, undo, verifyMobile, onCheckboxClick } = this;

		// verify if the verification code is a 4 digit number
		const code = parseInt(code_1 + code_2 + code_3 + code_4);
		const valid = code && code.toString().length === 4;
		const get_Z_value = mobile_number.slice(2, 3);
		const countryCode = mobile_number.slice(0, 2);
		const mobNumber = get_Z_value === '0' ? `${('0' + mobile_number.slice(3, 15)).split(' ').join('')}` : `${('0' + mobile_number.slice(2, 15)).split(' ').join('')}`;

		// Should only go to next input if the input is valid

		let availableInputs = {
			code_1: true,
			code_2: code_1.length === 1,
			code_3: code_2.length === 1,
			code_4: code_3.length === 1,
		};

		return (
			<div className={cx({ [className]: className })}>
				<Text paragraph strong>
					{verified ? t('MobileVerificationForm__Verified', { first_name }) : t('MobileVerificationForm__Verify')}
				</Text>

				<Text size="-1" paragraph>
					{t('MobileVerificationForm__VerifyMobile')}
				</Text>

				<Text size="-2" strong>
					{t('MobileVerificationForm__VerificationCode')}
				</Text>

				<InputGroup>
					<div>
						<InputGroup>
							<Input
								name="code_1"
								type="tel"
								value={code_1}
								onChange={handleChange}
								onKeyDown={this.onKeyDown}
								
								inputRef={(input) => {
									this.inputs['code_1'] = input;
								}}
								maxLength={1}
								valid={code_1_valid ? null : false}
								disabled={verified || !availableInputs.code_1}
								align="center"
								margin="cozy"
								horizontalMargin="compact"
							/>
							<Input
								name="code_2"
								type="tel"
								value={code_2}
								onChange={handleChange}
								onKeyDown={this.onKeyDown}
								inputRef={(input) => {
									this.inputs['code_2'] = input;
								}}
								maxLength={1}
								valid={code_2_valid ? null : false}
								disabled={verified || !availableInputs.code_2}
								align="center"
								margin="cozy"
								horizontalMargin="compact"
							/>
							<Input
								name="code_3"
								type="tel"
								value={code_3}
								onChange={handleChange}
								onKeyDown={this.onKeyDown}
								inputRef={(input) => {
									this.inputs['code_3'] = input;
								}}
								maxLength={1}
								valid={code_3_valid ? null : false}
								disabled={verified || !availableInputs.code_3}
								align="center"
								margin="cozy"
								horizontalMargin="compact"
							/>
							<Input
								name="code_4"
								type="tel"
								value={code_4}
								onChange={handleChange}
								onKeyDown={this.onKeyDown}
								inputRef={(input) => {
									this.inputs['code_4'] = input;
								}}
								maxLength={1}
								valid={code_4_valid ? (verified ? true : null) : false}
								disabled={verified || !availableInputs.code_4}
								align="center"
								margin="cozy"
							/>
						</InputGroup>

						{error && (
							<Text type="danger" size="-2" paragraph>
								{error}
							</Text>
						)}

						{verified ? (
							<div>
								<Text size="-1" paragraph>
									{t('MobileVerificationForm__VerifiedOne')}
								</Text>
								<Text size="-1" paragraph strong>
									{t('MobileVerificationForm__VerifiedTwo')}
								</Text>
								{/* <Text size="-1" paragraph>
									{t('MobileVerificationForm__VerifiedThree')}
								</Text> */}
							</div>
						) : (
							<Text size="-2" paragraph>
								<Trans i18nKey="MobileVerificationForm__Message" values={{ mobNumber }}>
									Please enter the four digit verification code that has been sent to <strong>{{ mobNumber }}</strong>{' '}
									so that we can validate your mobile number.
								</Trans>
							</Text>
						)}
					</div>

					<Icon
						className={css`
							font-size: 90px;
							line-height: 90px; // for IE
							align-self: flex-start;
						`}
						icon="phone-code"
					/>
				</InputGroup>

				{verified ? (
					<div>
						<StyledMobileVerificationForm__Actions center>
							<Text
								className={css`
									flex: 0 1 50%;
								`}
								size="-1"
							>
								{t('MobileVerificationForm__Step3of3')}
							</Text>

							<Button
								action={this.props.goNext}
								type="primary"
								loading={loadingCreateAccount}
								bold
								flex
							>
								{t('MobileVerificationForm__NextStep')}
							</Button>
						</StyledMobileVerificationForm__Actions>
					</div>
				) : (
					<ButtonGroup center>
						<Button type="ghost" action={undo} bold flex>
							{t('MobileVerificationForm__UndoNumber')}
						</Button>

						<Button action={verifyMobile} type="primary" disabled={!valid} loading={loadingVerifyMobile} bold flex>
							{t('MobileVerificationForm__VerifyMobileButton')}
						</Button>
					</ButtonGroup>
				)}
			</div>
		);
	}
}

export default withNamespaces()(MobileVerificationForm);



