import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, spacings } from '@tbh/ui-kit';
import moment from 'moment';

import MarketRow from '../MarketRow/MarketRow';
import TeamsEventHeader from '../TeamsEventHeader/TeamsEventHeader';
import { convertToLocalDateTime } from '../../../../../common/Date';
import JsonLd from '../../../Meta/JsonLd/JsonLd';

const StyledTeamsEventItem__MarketRow = styled(MarketRow)(
	(props) => css`
		label: TeamsEventItem__MarketRow;

		margin-bottom: ${spacings(props).tight}px;
	`,

	media(
		css`
			margin-right: 100px;
		`,
		470,
	),
);

/**
 * NOTE: The logic in the file looks more complicated than it should be. The issue is that both markets and
 * selections come down with team, however the team on the selection has no reference to the 'position' (i,e, home/away)
 *
 * This is because teams can play in many different events, and will change between home and away. The team on the
 * market has a field added specifically for that event
 */

/**
 * Given the two market teams, pass through the selection team and see if it matches one
 * of the market teams
 *
 * @param selectionTeam
 * @param teamA
 * @param teamB
 * @returns {*}
 */
const getTeam = (selectionTeam = {}, teamA, teamB) => {
	if (selectionTeam.id === teamA.id) {
		return teamA;
	}

	if (selectionTeam.id === teamB.id) {
		return teamB;
	}

	return selectionTeam;
};

/**
 * The market selections need to be ordered, with the home team first, away team last, and all other
 * selections in between. Fake selections are also created for team vs team markets, as it allows for better formatting
 * of the BettingAdd components
 *
 * TODO: This could be a performance drain. Look at memoizing, or taking a new approach
 *
 * @param selections
 * @param teamA
 * @param teamB
 * @returns {any[]}
 */
const processSelections = (selections, teamA, teamB) => {
	let selectionsList = [...selections];

	// If a home team can't be found, fake it
	if (
		!selections.find((selection) => {
			const team = getTeam(selection.team || {}, teamA, teamB);
			return team.position === 'home';
		})
	) {
		selectionsList.push({ id: 'h', team: teamA, display: false });
	}

	// If an away team can't be found, fake it
	if (
		!selections.find((selection) => {
			const team = getTeam(selection.team || {}, teamA, teamB);
			return team.position === 'away';
		})
	) {
		selectionsList.push({ id: 'a', team: teamB, display: false });
	}

	// If at least one undefined position can't be found, fake it
	if (
		!selections.find((selection) => {
			const team = getTeam(selection.team || {}, teamA, teamB);
			return team.position === undefined;
		})
	) {
		selectionsList.push({ id: 'u', display: false });
	}

	// Remove the team name from selections that match a given team, as well as adding on the line position for the market row
	let sortedSelections = selectionsList.map((selection) => {
		if (selection.team && (selection.team.id === teamA.id || selection.team.id === teamB.id)) {
			let newSelection = {
				...selection,
				linePosition: selection.team.id === teamA.id ? 'right' : 'left',
			};
			newSelection.name = '';
			return newSelection;
		}
		return selection;
	});

	return sortedSelections.sort((a, b) => {
		let tA = getTeam(a.team, teamA, teamB);
		let tB = getTeam(b.team, teamA, teamB);
		if (tA.position === tB.position) {
			return 0;
		}

		if (tA.position === 'home') {
			return -1;
		}

		if (tA.position === 'away') {
			return 1;
		}

		if (!tA.position && tB.position === 'home') {
			return 1;
		}

		if (!tA.position && tB.position === 'away') {
			return -1;
		}

		return 0;
	});
};

const TeamsEventItem = ({
	className,
	disableBetting,
	eventId,
	hasBump,
	teamA,
	teamB,
	markets,
	eventStart,
	addToMulti,
	addToSingle,
	onEventClick,
	extraData,
	route,
	size,
}) => {
	const classes = cx({
		[className]: className,
	});

	const handleHeaderClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		onEventClick(eventId);
	};

	const addTo = (callback, market) => {
		if (!callback) {
			return callback;
		}

		return (selection, productId) => {
			callback(eventId, market.sportSelections.find((s) => s.id === selection), market, productId, extraData);
		};
	};

	const eventName = `${teamA.name} vs ${teamB.name}`;
	const localEventStart = convertToLocalDateTime(eventStart);
	const eventLd = {
		'@context': 'http://schema.org',
		'@type': 'SportsEvent',
		name: eventName,
		description: eventName,
		startDate: localEventStart.format(),
		endDate: localEventStart
			.clone()
			.add('60', 'minutes')
			.format(),
		url: window.location, // Should I do this?
		competitor: [],
		location: {
			'@type': 'Place',
			name: eventName,
			address: eventName,
		},
		performer: [],
	};

	return (
		<div className={classes}>
			<JsonLd content={eventLd} />
			<TeamsEventHeader
				eventStart={eventStart}
				teamA={teamA}
				teamB={teamB}
				onClick={onEventClick && handleHeaderClick}
				route={route}
				priceBump={hasBump}
				size={size}
			/>
			{markets.map((market) => (
				<StyledTeamsEventItem__MarketRow
					key={market.id}
					marketName={market.name}
					selections={processSelections(market.sportSelections, teamA, teamB)}
					bettingAvailable={!disableBetting}
					addToSingle={addTo(addToSingle, market)}
					addToMulti={addTo(addToMulti, market)}
					size={size}
				/>
			))}
		</div>
	);
};

const TeamShape = PropTypes.shape({
	/** The ID of the team */
	id: PropTypes.number.isRequired,

	/** The name of the team */
	name: PropTypes.string.isRequired,

	/** The team icon */
	icon: PropTypes.string,
});

TeamsEventItem.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The ID of the event */
	eventId: PropTypes.number.isRequired,

	/** The moment when the event starts */
	eventStart: PropTypes.instanceOf(moment).isRequired,

	/** The first team in the event */
	teamA: TeamShape.isRequired,

	/** The second team in the event */
	teamB: TeamShape.isRequired,

	/** Action to add the selection to the bet prompt */
	addToSingle: PropTypes.func.isRequired,

	/** The list of market items in the market group */
	markets: PropTypes.arrayOf(
		PropTypes.shape({
			/** The market item */
			id: PropTypes.number.isRequired,

			/** The market name */
			name: PropTypes.string.isRequired,

			/** The list of selections in the market */
			sportSelections: PropTypes.array.isRequired,
		}),
	),

	/** The event has bump product available */
	hasBump: PropTypes.bool,

	/** Action to add the selection to the multi bet slip */
	addToMulti: PropTypes.func,

	/** Action to fire when the event is clicked on */
	onEventClick: PropTypes.func,

	/** Extra data to return with the callback */
	extraData: PropTypes.object,

	/** The route for the team event */
	route: PropTypes.string,

	/** Disable betting for the event */
	disableBetting: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

TeamsEventItem.defaultProps = {
	markets: [],
	addToMulti: null,
	className: null,
	onEventClick: null,
	extraData: {},
	route: null,
	disableBetting: false,
	hasBump: false,
};

export default TeamsEventItem;
