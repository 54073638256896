import { createSelector } from 'reselect';
// Schema functions
import { denormalizeFeatureRaces } from '../../../store/entities/schemas/FeatureRaceSchema';
import { denormalizeUpcomingRaces } from '../../../store/entities/schemas/UpcomingAllRaceSchema';

import { sortFeatureRacesByStartTime } from '../../../store/entities/selectors/FeatureRaceSelectors';
import moment from 'moment';
import { serverDateTime } from '../../../../src/legacy/core/format';
/**
 * Memoized function to build feature races data.
 * @params state
 */
const getFeatureRaces = createSelector(
	(state) => state.entities.featureRaces,
	(featureRaces) => {
		
		return sortFeatureRacesByStartTime(denormalizeFeatureRaces({ featureRaces }));
	},
);

const getUpcomingRaces = createSelector(
	(state) => state.entities.upcomingRaces,
	(upcomingRaces) => {
		return sortFeatureRacesByStartTime(denormalizeUpcomingRaces({ upcomingRaces }));
	},
);

const getSelectedDateFeaureRaces= (items = [], date) => {
	 const filterIems = items.filter((item) =>  serverDateTime(moment(item.start_datetime)).format('YYYY-MM-DD') === serverDateTime(moment(date)).format('YYYY-MM-DD'));
	 return sortFeatureRacesByStartTime(filterIems);
};

const getAllFeaureRaces= (items = []) => {
	
	return sortFeatureRacesByStartTime(items);
};

const getAllUpcomingFeaureRaces= (items = [], date) => {
	
	const filterIems = items.filter((item) =>  serverDateTime(moment(item.start_datetime)).format('YYYY-MM-DD') > serverDateTime(moment(date)).format('YYYY-MM-DD'));
	return sortFeatureRacesByStartTime(filterIems);
};


export {
	getFeatureRaces,
	getUpcomingRaces,
	getSelectedDateFeaureRaces,
	getAllFeaureRaces,
	getAllUpcomingFeaureRaces,
};