import {
	BET_PROMPT_ADD_SELECTIONS,
	BET_PROMPT_CLEAR_ERRORS,
	BET_PROMPT_RESET,
	BET_PROMPT_RESET_TITLE,
	BET_PROMPT_SET_ERRORS,
	BET_PROMPT_UPDATE,
} from './betPromptActionTypes';
import { DEFAULT_BET_PROMPT_TITLE } from '../entities/constants/BetConstants';

const initialState = {
	autoAccept: false,
	creditCards: [],
	errorMessage: '',
	isLoading: false,
	// onDestroy: opts.onDestroy,
	preventLoadingCreditCards: false,
	stake: 0,
	useBonusBets: false,
	title: DEFAULT_BET_PROMPT_TITLE,
	bettaBucks: undefined,
	entryFee: undefined,
	showQuickDeposit: false,
	selections: [],
	receipts: [],

	// Sports Props

	/**
	 * Racing Props
	 * Should vary between 'win', 'place', 'eachway'
	 **/
	racingBetType: null,
	exoticDetails: {
		// TODO: change 'id' for 'betType'
		id: '',
		title: null,
		isBoxed: null,
		productId: null,
		race: null,
	},

	// New
	betsPlaced: [],
	selectionType: null,
};

const betPrompt = (state = initialState, action) => {
	switch (action.type) {
		case BET_PROMPT_UPDATE:
			return {
				...state,
				...action.payload,
			};

		case BET_PROMPT_RESET:
			return {
				...initialState,
			};

		case BET_PROMPT_RESET_TITLE:
			return {
				...state,
				title: initialState.title,
			};

		case BET_PROMPT_ADD_SELECTIONS:
			return {
				...state,
				selections: action.payload,
				// For racing single bet
				racingBetType: action.racingBetType,
				// For bet type menu
				racingBetTypes: action.racingBetTypes,
				// For racing exotic bet
				exoticDetails: action.exoticDetails,
				// For different types of bet prompt selections
				selectionType: action.selectionType,
				// For loading the correct derivative market
				derivativeMarketId: action.derivativeMarketId,
			};

		case BET_PROMPT_SET_ERRORS:
			return {
				...state,
				errorMessage: action.payload,
			};

		case BET_PROMPT_CLEAR_ERRORS:
			return {
				...state,
				errorMessage: initialState.errorMessage,
			};

		default:
			return state;
	}
};

export default betPrompt;
