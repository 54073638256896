import React from 'react';
import { BounceLoader } from 'react-spinners';

const Spinner = () => {
    return (
        <BounceLoader
            loading={true}
            size={60}
            color="#f16565"
        />
    );
};

export default Spinner;