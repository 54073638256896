import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { CollapsiblePanel, Text } from '@tbh/ui-kit';

class ThemeJson extends Component {
	static propTypes = {
		/** Theme */
		theme: PropTypes.object.isRequired,

		/** Extra classes */
		className: PropTypes.string,
	};
	static defaultProps = {
		className: '',
	};

	state = {
		showThemeGroup: false,
	};

	/**
	 * Opens/Closes the Collapsible Panel
	 */
	toggleThemeGroup = () => {
		this.setState({
			showThemeGroup: !this.state.showThemeGroup,
		});
	};

	render() {
		const { className, theme } = this.props;

		const { showThemeGroup } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<CollapsiblePanel
				className={componentClasses}
				label={<Text strong>JSON</Text>}
				isCollapsed={!showThemeGroup}
				padding={CollapsiblePanel.spacings.SPACING_COZY}
				toggleCollapsibleContent={this.toggleThemeGroup}
				type={CollapsiblePanel.types.SUPPORT}
				useBorder
			>
				<Text
					className={css`
						word-break: break-all;
					`}
					size="-2"
				>
					{JSON.stringify(theme)}
				</Text>
			</CollapsiblePanel>
		);
	}
}

export default ThemeJson;
