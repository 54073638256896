// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

// Components
import { application, spacings, ui, Icon, RadioButton, Table, Text, Panel } from '@tbh/ui-kit';

const StyledBonusBetsList__Table = styled(Table)(
	(props) => css`
		label: BonusBetsList__Table;

		margin: ${spacings(props).compact}px 0;
	`,
);

const StyledBonusBetsList__Cards = styled(Icon)(
	(props) => css`
		label: BonusBetsList__Cards;

		min-height: 26px;
		min-width: 35px;
		background-color: ${ui(props).color_black};
		padding: ${spacings(props).constrictive}px ${spacings(props).tight}px;
		border-radius: ${application(props).border_radius_2}px;
	`,
);

const StyledBonusBetsList__BonusBetsMessage = styled(Panel)(
	(props) => css`
		label: BonusBetsList__BonusBetsMessage;

		margin: ${spacings(props).cozy}px 0;
	`,
);

const BonusBetsList = (props) => {
	const { bonus_bets, handleBonusSelection, selected_bonus, t } = props;

	let current_bonus = bonus_bets.findIndex((x) => x.value === selected_bonus);
	current_bonus = bonus_bets[current_bonus];

	return selected_bonus && selected_bonus !== 'Other Amount' ? (
		<StyledBonusBetsList__Table size="-1" horizontalCompact>
			<Table.TG>
				<Table.TR>
					<Table.TD tertiary>
						<RadioButton
							name={current_bonus.value}
							value={current_bonus.value}
							onChange={handleBonusSelection}
							label={current_bonus.value === 'Custom Amount' ? current_bonus.value : `$${current_bonus.value}`}
							checked
						/>
					</Table.TD>
					<Table.TD right>
						{current_bonus.value === 'Custom Amount'
							? '+' + current_bonus.bonus
							: (parseInt(current_bonus.bonus.charAt(0)) ? '+$' : '+') + current_bonus.bonus + ' ' + t('BonusBet')}
					</Table.TD>
				</Table.TR>
			</Table.TG>
		</StyledBonusBetsList__Table>
	) : (
		<div>
			<StyledBonusBetsList__Table size="-1" horizontalCompact>
				<Table.TG>
					{bonus_bets.map((bonus) => (
						<Table.TR key={bonus.value}>
							<Table.TD tertiary>
								<RadioButton
									name={bonus.value}
									value={bonus.value}
									onChange={handleBonusSelection}
									label={
										bonus.value === 'Other Amount' || bonus.value === 'Custom Amount' ? bonus.value : `$${bonus.value}`
									}
									checked={selected_bonus === bonus.promoCode}
								/>
							</Table.TD>
							<Table.TD right>
								{bonus.promoCode === 'CHATLIVE'
									? bonus.bonus
									: bonus.value === 'Custom Amount'
										? '+' + bonus.bonus
										: (parseInt(bonus.bonus.charAt(0)) ? '+$' : '+') + bonus.bonus + ' ' + t('BonusBet')}
							</Table.TD>
						</Table.TR>
					))}
				</Table.TG>
			</StyledBonusBetsList__Table>

			<StyledBonusBetsList__BonusBetsMessage
				borderType="default"
				type={Panel.types.THREE}
				padding={Panel.padding.SPACING_COZY}
			>
				<Text size="-2" align="center" strong>
					{t('BonusBet_plural')}
				</Text>
				<Text size="-2" align="center">
					{t('BonusBetsList__BonusBetsMessage')}
				</Text>
			</StyledBonusBetsList__BonusBetsMessage>

			<Text align="center">
				<StyledBonusBetsList__Cards icon="visa" size="4" color="white" />
				&nbsp;
				<StyledBonusBetsList__Cards icon="mastercard" size="4" color="white" />
			</Text>

			<Text size="-2" align="center">
				{t('BonusBetsList__Accepted')}
			</Text>
		</div>
	);
};

BonusBetsList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The list of bonus bets */
	bonus_bets: PropTypes.arrayOf(
		PropTypes.shape({
			/** The amount required for the bonus bet */
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

			/** The amount that is a bonus */
			bonus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

			/** The promo code for the bonus bet */
			promoCode: PropTypes.string,
		}),
	).isRequired,

	/** The action for selecting a bonus bet */
	handleBonusSelection: PropTypes.func.isRequired,

	/** The selected bonus bet */
	selected_bonus: PropTypes.string,
};

BonusBetsList.defaultProps = {
	selected_bonus: null,
};

export default withNamespaces()(BonusBetsList);
