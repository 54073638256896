import { denormalize, normalize } from 'normalizr';

import { expertRacesSchema } from './Entities';

/**
 * Denormalizes entities.
 *
 * @param entities
 * @param keys
 * @returns {Array}
 */
const denormalizeExpertRaces = (entities, keys = null) => {
	return denormalize(keys || Object.keys(entities.expertRaces), expertRacesSchema, entities);
};

/**
 * Normalizes entities.
 *
 * @param response
 * @returns {Object}
 */
const normalizeExpertRaces = (response) => {
	return normalize(response, expertRacesSchema);
};

export { denormalizeExpertRaces, normalizeExpertRaces };


