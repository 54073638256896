import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { Circle } from '@tbh/ui-kit';

const cssPINEnteredNumber = css`
	label: PINEnteredNumber;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const StyledCircle = styled(Circle)`
	background-color: #dddddd;
	${(props) =>
		props.number &&
		css`
			background-color: #666666;
		`};
`;

const PINEnteredNumber = ({ className, number }) => {
	const componentClasses = cx(cssPINEnteredNumber, {
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			{number || ''}
			<StyledCircle number={number} size={25} />
		</div>
	);
};

PINEnteredNumber.propTypes = {
	/** Extra classes */
	className: PropTypes.string,

	/** The number that was entered */
	number: PropTypes.any,
};

PINEnteredNumber.defaultProps = {
	className: null,
	number: null,
};

export default PINEnteredNumber;
