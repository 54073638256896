export const AUTHENTICATED_USER_POLLING_INTERVAL = 9000000000;
export const MASTHEAD_DEFAULT = 'Default';
export const MASTHEAD_AFFILIATE = 'Affiliate';
export const MASTHEAD_IFRAME = 'Iframe';
export const MASTHEAD_TYPES = [MASTHEAD_DEFAULT, MASTHEAD_AFFILIATE, MASTHEAD_IFRAME];

export const EVENT_KEY_ENTER = 'Enter';
export const OFFLINE_MESSAGE = 'This site is currently offline.'; //TODO: add to translations once avaialble

export const TITLE_BET_RECEIPT = 'Bet Receipt';
export const TITLE_QUICK_DEPOSIT = 'Quick Deposit';

export const TITLE_DEPOSIT = 'Deposit';
export const TITLE_BONUS_BET = 'Bonus Bet';
export const TITLE_WITHDRAWAL = 'Withdrawal';

export const TITLE_SINGLE_BET_SLIP = 'Single Bet Slip';
export const TITLE_PLAYER_PROFILE = 'Player Profile';
export const TITLE_TOURNAMENT_REBUY = 'Tournament Rebuy';
export const TITLE_SLEDGE_BOX = 'Sledge Box';

export const ROCKET_CHAT_LINK = 'https://grsbet.rocket.chat/livechat';

