const combinations = (set, k) => {
	let i, j, combs, head, tailCombinations;

	if (k > set.length || k <= 0) {
		return [];
	}

	if (k === set.length) {
		return [set];
	}

	if (k === 1) {
		combs = [];
		for (i = 0; i < set.length; i++) {
			combs.push([set[i]]);
		}

		return combs;
	}

	combs = [];
	for (i = 0; i < set.length - k + 1; i++) {
		head = set.slice(i, i + 1);
		tailCombinations = combinations(set.slice(i + 1), k - 1);
		for (j = 0; j < tailCombinations.length; j++) {
			combs.push(head.concat(tailCombinations[j]));
		}
	}

	return combs;
};

export default combinations;
