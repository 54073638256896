import {
	application,
	ArrowIcon,
	brand,
	FlexText,
	HeaderBar,
	Icon,
	media,
	Panel,
	spacings,
	typography,
	ui,
} from '@tbh/ui-kit';
import cx from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import Switch from 'react-switch';

import {
	DEFAULT_EXOTIC_CHECKBOX_STATE,
	GENERIC_SILK,
	QUADDIES_BET_TYPES,
	QUADDIES_BET_TYPES_COUNT,
	RACING_BET_PRODUCT_CODE_SP,
	RACING_BET_PRODUCT_CODE_TF,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_SP,
	RACING_BET_TYPE_STRAIGHT_EIGHT,
	RACING_EXOTIC_BET_TYPES,
	// RACING_NON_EXOTIC_BET_TYPE,
	RACING_MULTIPLES_TYPE,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_TYPES_LOOKUP,
	SELECTION_NOT_SCRATCHED_STATUS
} from '../../../../common/constants/Racing';

import { dollarsAsFormat } from '../../../../legacy/core/format';

import { Button, Checkbox, Image, List, PlotElements, Text } from '@tbh/ui-kit';
import { Tooltip } from 'chart.js';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';
import { BREAKPOINTS, COLORS, MEDIA_QUERIES } from '../../../../constants/themes';
import { TOTE_NAMES_BY_PRODUCT, toteProducts } from '../../../../store/entities/constants/BetConstants';
import Modal from '../../../controllers/Modal/Modal';
import RaceSelectionsListItem from './RaceSelectionsListItem/RaceSelectionsListItem';
import RaceLegsPrice from './RaceSRMPrice';

const StyledRaceSelectionsList = styled('div')`
	clear: both; // this is to clear the floated 'all form' toggle button
	&:after {
		content: '';
		display: table;
		clear: both;
	}
`;

const StyledRaceSelectionsList__HeaderRow = styled('div')(
	(props) => css`
		label: SelectionsList__HeaderRow;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 ${spacings(props).tight}px;
		gap: ${spacings(props).compact}px;
		border-bottom: 1px solid hsl(0deg 0% 85%);
		flex-wrap: wrap;
	`,
);
const StyledRaceSelectionsList__HeaderCell = styled(Text)`
	label: RaceSelectionsList__HeaderCell;
	display: flex;
	gap: ${(props) => spacings(props).tight}px;
	justify-content: center;
	width: 100%;
	margin: 0 ${(props) => spacings(props).constrictive}px;
	padding: ${(props) => spacings(props).tight}px ${(props) => spacings(props).tight}px
		${(props) => spacings(props).tight}px 0;
	text-align: center;
	position: relative;
	flex: 1 !important;
	max-width: 100% !important;

	${(props) =>
		props.emphasised &&
		`
    color: ${typography(props).color_1_alternate};
    background-color: ${brand(props).color_1};
    text-shadow: ${application(props).shadow};
    margin: 0 1px;
  `}

	${(props) =>
		props.small &&
		`
    flex: 0 0 ${application(props).racing.odds_button_width_small}px;
    max-width: ${application(props).racing.odds_button_width_small}px;
  `}
`;

const StyledHeaderCellGroup = styled('div')(
	(props) => css`
		display: flex;
		width: 100%;
	`,

	(props) =>
		media(
			css`
				width: ${props.buttons * 52}px;
			`,
			300,
		),
);

const StyledRaceSelectionsList__HeaderFlucs = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsList__HeaderFlucs;
			position: relative;
			display: none;
			text-align: center;
			text-transform: uppercase;
			flex: 0 0 ${application(props).racing.flucs_width}px;
			max-width: ${application(props).racing.flucs_width}px;
		`};

	${media(
		(props) =>
			css`
				flex: 0 0 ${application(props).racing.odds_button_width_small}px;
				max-width: ${application(props).racing.odds_button_width_small}px;
			`,
		420,
	)};
`;

const StyledRaceSelectionsList__ProductIcon = styled(Image)`
	${(props) =>
		css`
			label: RaceSelectionsList__ProductIcon;

			max-width: ${application(props).racing.odds_button_width}px;
			max-height: 22px;
			padding: 0 ${spacings(props).constrictive}px;
			margin-bottom: ${spacings(props).constrictive}px;
		`};
`;

const StyledRaceSelectionsList__CheckAllCheckbox = styled(Checkbox)`
	${(props) =>
		css`
			label: RaceSelectionsList__CheckAllCheckbox;

			flex: 0 0 28px;
			margin-left: ${spacings(props).tight}px;
		`};
`;

const AllCheckboxesContainer = styled('div')`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 4px;
`;

const StyledRaceSelectionsList__AllCheckboxes = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsList__AllCheckboxes;

			max-width: 256px;
			display: flex;
		`};
`;

const StyledRaceSelectionsList__AllCheckboxesLabel = styled('label')`
	${(props) =>
		css`
			label: RaceSelectionsList__AllCheckboxesLabel;

			display: flex;
			align-items: center;
			font-size: ${typography(props)['size_-2']};
			font-weight: 600;
			margin-bottom: 0px;
			margin-right: ${spacings(props).tight}px;
		`};
`;

const StyledReaceCardFixed = styled('div')`
	${(props) => css`
		position: relative;
		width: ${props.size - 2}px;
		margin-left: -8px;
	`}
`;

const StyledRaceCardBottom = styled('div')`
	${(props) =>
		css`
			display: flex;
			background: ${brand(props).race_grey_color ? brand(props).race_grey_color : '#1C1F21'};
			justify-content: flex-end;
			align-items: center;
			gap: ${spacings(props).compact}px;
			width: inherit;

			${!props.position &&
				css`
					position: fixed;

					bottom: 50px;
					@media (min-width: ${BREAKPOINTS.laptopMin}px) {
						bottom: 0;
					}
				`};
		`};
`;

const SelectionPainelController = styled('div')`
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
	background-color: #6666;
	font-size: 12px;
	padding: 8px 12px;
	color: #fff;
`;

const StyledPlaceButtonWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	gap: 8px;
	position: relative;
`;

const StyledRaceSelectionsList__PlaceBetButton = styled(Button)`
	${(props) =>
		css`
			label: RaceSelectionsList__PlaceBetButton;

			// float: right;
			// clear: both;
			// margin-bottom: ${spacings(props).cozy}px;
			border-radius:4px;
			padding: 7px !important;
			background-color:${COLORS.red[600]};
			margin-right: 10px;
			margin-bottom: 8px;
			margin-top: 8px;
			&:disabled {
				background-color: ${COLORS.gray[200]};
			}
		`};
`;

const StyledRaceSelectionsList__RaceSelectionsListItems = styled(List)`
	${(props) =>
		css`
			label: RaceSelectionsList__RaceSelectionsListItem;

			margin: 0;
		`};
`;

const StyledRaceSelectionsList__PoolRow = styled(PlotElements)`
	${(props) =>
		css`
			label: RaceSelectionsList__PoolRow;

			padding: ${spacings(props).compact}px 0 ${spacings(props).compact}px ${spacings(props).tight}px !important;

			&:nth-child(odd) {
				background: ${ui(props).color_2};
			}
		`};
`;

const StyledRaceSelectionsList__PoolTotal = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsList__PoolTotal;

			width: ${application(props).racing.odds_button_width}px;
			font-size: ${typography(props)['size_-2']};
			color: ${brand(props).color_2};
			text-align: center;
			padding: 6px 0;
			background: ${ui(props).color_2};
			margin: 0 ${spacings(props).constrictive}px;
			border: 1px solid ${ui(props).color_3};

			${props.small &&
				css`
					width: ${application(props).racing.odds_button_width_small}px;
				`};
		`};

	${(props) =>
		media(
			css`
				width: ${application(props).racing.odds_button_width}px;
			`,
			660,
		)};
`;

const StyledRaceSelectionsList__GhostColumn = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsList__GhostColumn;

			width: ${application(props).racing.odds_button_width}px;
			margin: 0 ${spacings(props).constrictive}px;

			${props.small &&
				css`
					width: ${application(props).racing.odds_button_width_small}px;
				`};
		`};

	${(props) =>
		media(
			css`
				width: ${application(props).racing.odds_button_width}px;
			`,
			660,
		)};
`;

export const StyledRaceStrikeRate_header = styled('div')`
	${(props) =>
		css`
			label: RaceStrikeRate _header;

			width: 55%;
			display: flex;
			width: fit-content;
			white-space: nowrap;
			align-items: center;
			justify-content: center;
			padding: 0px 2px;
		`};
	${media(
		css`
			padding-top: 0;
			width: 90%;
		`,
		660,
	)};
`;

export const StyledRaceStrikeRate_percentage_header = styled('div')`
	${(props) =>
		css`
			label: RaceStrikeRate_percentage_header;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1 1 25%;
			width: 24px;
		`};
`;

export const StyledRaceStrikeRate__Subtitle = styled(Text)`
	${(props) =>
		css`
			label: RaceStrikeRate__Subtitle;

            font-size: 11px;//${typography(props)['size_-2']};

		`};
`;

export const StyledRaceSwitch = styled('div')`
	${(props) =>
		css`
			label: RaceSwitch;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: fit-content;
		`};
	${media(
		(props) => css`
			top: 20px;
			gap: 10px;
			padding: ${spacings(props).compact}px ${spacings(props).tight}px;
		`,
		660,
	)};
`;

const Modle__Header = styled('div')`
	${(props) =>
		css`
			label: Modle__Header;
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			height: inherit;
			width: 100%;
			-webkit-align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			overflow: hidden;
			text-transform: none;
			background: #070b19;
			padding: 0 15px;
			padding-left: 0;
			padding-right: 0;
			min-height: 45px;
			background: #070b19;
			margin-bottom: 10px;
			margin-top: 10px;
		`};
`;

const StyledRaceSelectionsDetails__SilkImage = styled(Image)`
	height: 26px;

	${media(
		css`
			height: 37px;
		`,
		660,
	)};
`;

const RaceHeaderBarModle = styled(FlexText)`
	${(props) => css`
		label: RaceHeaderBarModle__Heading;

		//padding-top: ${spacings(props).tight}px;
		//padding-bottom: ${spacings(props).tight}px;
	`};
`;

const StyledRaceHeaderBar__RaceNumber = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__RaceNumbe;

		background-color: ${brand(props).rch_4};
		display: inline-block;

		padding: ${spacings(props).compact}px;
		font-size: 30px;
		font-weight: 600;

		text-align: center;
	`};
`;

const StyledRaceHeaderBar = styled(HeaderBar)`
	${(props) => css`
		min-height: 45px; // To Match the height of a regular header bar
		background: ${brand(props).rch_5};
	`};
`;

const StyledRaceHeaderBar__RaceName = styled(Text)`
	${media(
		css`
			display: block;
		`,
		500,
		{ sizeKey: 'mediaSize' },
	)};
`;

const StyledRaceSelectionsDetails = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails;

			width: 100%;
			display: flex;
			padding: ${spacings(props).tight}px 0;
			height: 65px;
		`};
`;

const StyledRaceSelectionsDetails__LastStartsRow = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__LastStartsRow;

			${props.scratched &&
				css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
		(props) =>
			css`
				font-size: ${typography(props)['size_-2']};
			`,
		660,
		{ sizeKey: 'mediaSize' },
	)};
`;

const StyledRaceSelectionsDetails__DetailsColumn = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__DetailsColumn;

			padding-top: ${spacings(props).tight}px;
			text-overflow: ellipsis;
			overflow: hidden;
			${props.scratched &&
				css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
		css`
			padding-top: 0;
		`,
		660,
	)};
`;

const StyledRaceSelectionsDetails__Name = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Name;
			margin-right: ${spacings(props).tight}px;
			color: #003a91;
			&:after {
				content: ' ';
			}
		`};

	${media(
		(props) =>
			css`
				${typography(props)['size_-2']}px;
			`,
		660,
		{ sizeKey: 'mediaSize' },
	)};
`;

const StyledRaceSelectionsDetails__Silk = styled('div')`
	${(props) =>
		css`
			label: RaceSelectionsDetails__Silk;

			float: left;
			width: 28px;
			min-width: 28px;
			max-height: 37px;
			margin-right: ${spacings(props).constrictive}px;
			text-align: center;
			align-self: center;

			${props.scratched &&
				css`
					opacity: 0.2;
					filter: grayscale(100%);
				`};
		`};

	${media(
		(props) =>
			css`
				margin-right: ${spacings(props).tight}px;
			`,
		500,
	)};

	${media(
		css`
			width: 45px;
			max-height: 55px;
		`,
		660,
	)};
`;

const StyledRaceSelectionsDetails__RunnerDetails = styled(Text)`
	${media(
		(props) =>
			css`
				font-size: ${typography(props)['size_-3']}PX;
			`,
		660,
		{ sizeKey: 'mediaSize' },
	)};
`;
const Styled_Div_ArrowIcon = styled('div')`
	${(props) =>
		css`
			label: Style_Hover_Tote;
			display: flex;
			flex-direction: column;
			justify-content: center;
			cursor: pointer;
		`};
`;

export const Styled_Switch_group = styled('div')(
	(props) => css`
		display: flex;
		width: fit-content;
		gap: 8px;
		padding: 8px 4px;
		flex-direction: row;
		flex-wrap: wrap;
	`,
	media(
		css`
			// width: 50%;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		`,
		660,
	),
);

export const Styled_Render_Form_Button = styled('div')`
	${(props) =>
		css`
			label: Styled_Render_Form_Button;
			display: flex;
			flex-direction: row;
			white-space: nowrap;
			cursor: pointer;
			font-size: 12px;
			font-weight: bold;
			gap: 2px;
			border: 1px solid #115fa3;
			padding: 4px;
			border-radius: 4px;
			${props.active ? 'color: #115FA3;' : 'color: #248DEA;'};
			// background: #115fa3;
			&:hover {
				color: #248dea;
			}
		`};
`;

const OptionButton = styled('div')`
	${(props) => css`
		label: OptionButton;
		font-size: 12px;
		font-weight: bold;
		gap: 2px;
		border: 1px solid #850000;
		padding: 4px;
		border-radius: 4px;
		${props.active ? 'color: #fff;background:#850000' : 'color: #850000;'};
	`}
`;

const SelectionsPainel = styled('div')`
	bottom: 100%;
	left: auto;
	position: absolute;
	width: 100%;
	max-width: 100%;
	z-index: 1000;
	background-color: #636c72;
	color: #fff;
	padding: 8px;
	font-size: 12px;
	display: grid;
	grid-template-columns: 80% 20%;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		max-width: 324px;
	}

	p {
		margin: 0;
	}
`;

const SelectionsPainelContent = styled('div')`
	gap: 12px;
	display: flex;
	flex-direction: column;
`;
const HotStrikeRateGroup = styled('div')(`
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	width: 216px;
	padding-right: 8px;
`);
class RaceSelectionsList extends Component {
	static initialState = (props = {}) => ({
		/** Props used for checking updates */
		raceId: props.raceId,
		betType: props.betType,
		boxed: props.boxed,
		allFormOpen: props.allFormOpen,

		/** Set the initial state of how many checkboxes & checkbox headers will be displayed */
		displayedCheckboxes: props.displayedCheckboxes || DEFAULT_EXOTIC_CHECKBOX_STATE,

		/** Set the initial state of the 'check all' checkboxes */
		checkAllCheckboxes: DEFAULT_EXOTIC_CHECKBOX_STATE,

		/** Initial state of the selection checkboxes */
		selectionCheckboxes: {},

		/** An Array of selection ids, where the form should be opened */
		openedForm: props.openedForm,
		displayToteText: false,
	});

	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}).isRequired,

		/** If race changes clear state. */
		raceId: PropTypes.number.isRequired,

		/** Race Number */

		raceNumber: PropTypes.number.isRequired,

		/** Array of objects that contain all the selection details */
		selections: PropTypes.arrayOf(
			PropTypes.shape({
				/**
				 * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
				 * They are defined in the RaceSelectionsListItem
				 */
				id: PropTypes.number.isRequired,

				/** The list of prices for the selection */
				prices: PropTypes.arrayOf(
					PropTypes.shape({
						/** The current price for a win bet with this product */
						win_odds: PropTypes.number,

						/** The current price for a place bet */
						place_odds: PropTypes.number,

						/** The current price for a margin bet */
						margin_odds: PropTypes.number,

						/** The code for this product with this product */
						product_code: PropTypes.string.isRequired,

						/** The product type, if it is a special product */
						product_type: PropTypes.string,

						/** The product id (NOTE: this is not a unique id between products) */
						product_id: PropTypes.number.isRequired,

						liability: PropTypes.number.isRequired,
					}),
				),
			}),
		).isRequired,

		/**
		 * This will be an array of bet products that will be displayed for each selection
		 * The order of display will be from left to right as given in the array.
		 */
		displayedBetProducts: PropTypes.arrayOf(
			PropTypes.shape({
				/** The bet type for the product */
				bet_type: PropTypes.string.isRequired,

				/** The product ID of the bet product */
				product_id: PropTypes.number.isRequired,

				/** The product code of the bet product */
				product_code: PropTypes.string.isRequired,

				/** The product image */
				product_image: PropTypes.string,

				/** The type of product if it is a special product, eg. butt */
				product_type: PropTypes.string,

				/** If the product is available for betting */
				available: PropTypes.bool,
			}),
		).isRequired,

		/** Action to add the selection to the single bet prompt */
		clickSingle: PropTypes.func.isRequired,

		/** Action for handling adding the bet to the multi bet slip */
		clickMulti: PropTypes.func.isRequired,

		/** Action for handling an exotic bet */
		clickExotic: PropTypes.func.isRequired,

		/** Boolean indicating if betting is available ie. race isn't closed */
		bettingAvailable: PropTypes.bool.isRequired,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** Whether or not the component is being viewed in small context */
		isMobileWidth: PropTypes.bool,

		/** Goat Length */
		goatLength: PropTypes.number,

		/** Which fluctuations to display, or don't display if null */
		displayedFlucs: PropTypes.oneOf([null, 'apn_fluctuations', 'topbetta_fluctuations']),

		/** The currently selected bet type */
		betType: PropTypes.string,

		/** If the currently selected bet type is boxed */
		boxed: PropTypes.bool,

		/** An object of selection ID's whose form panel is open */
		openedForm: PropTypes.objectOf(PropTypes.bool),

		/** GA Tracking for runner form */
		trackOpenRunnerForm: PropTypes.func,

		/** Do we need to open all the form collapsible panels */
		allFormOpen: PropTypes.bool,

		/** Do we need to open hot selection form collapsible panels */
		hotStatsOpen: PropTypes.bool,

		/** An object of selection ID's whose form panel is open */
		displayedCheckboxes: PropTypes.shape({
			1: PropTypes.bool,
			2: PropTypes.bool,
			3: PropTypes.bool,
			4: PropTypes.bool,
			5: PropTypes.bool,
			6: PropTypes.bool,
			7: PropTypes.bool,
			8: PropTypes.bool,
		}),

		/** Extra class(es) to pass through to the component */
		className: PropTypes.string,

		/** The Win pool total */
		winPool: PropTypes.number,

		/** The Place pool total */
		placePool: PropTypes.number,

		/** hot Selection */
		hotSelection: PropTypes.number,

		/** Price Bump Click */
		onSelectGoatProduct: PropTypes.bool,

		/** show old fix win price when click price bump */
		showSelectWinFixedOldPrice: PropTypes.array,

		selectedGoatProduct: PropTypes.string,
	};

	static defaultProps = {
		isMobileWidth: false,
		goatLength: null,
		displayedFlucs: null,
		betType: '',
		boxed: false,
		openedForm: {},
		allFormOpen: false,
		displayedCheckboxes: DEFAULT_EXOTIC_CHECKBOX_STATE,
		className: null,
		trackOpenRunnerForm: () => undefined,
		winPool: null,
		placePool: null,
		visible: false,
		hotSelection: null,
		hotStatsOpen: false,
		onSelectGoatProduct: false,
		selectedGoatProduct: null,
		showSelectWinFixedOldPrice: [],
		selections: [],
	};



	constructor(props) {
		super(props);

		this.state = RaceSelectionsList.initialState(props);
		this.WrapperRef = React.createRef(null);
		this.fixedRef = React.createRef();

		this.state = {
			fixedWrapper: false,
			checked: this.state.fixedWrapper ? false : true,
			checkedTote: true,
			checkedHotStack: false,
			showVerifyExplanation: false,
			FlucsData: '',
			runnerName: '',
			runnerNumber: '',
			sortSelection: null,
			priceFilter: null,
			sortedProduct: null,
			runnerFormVisibility: {},
			openSelections: false,
		};

		this.handleOnScrollFixed = this.handleOnScrollFixed.bind(this);
		this.handleRunnerFormVisibility = this.handleRunnerFormVisibility.bind(this);
	}

	/**
	 * Update the opened form when the component receives new props
	 *
	 * @param nextProps
	 * @param prevState
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		// Clear state when changing race. (except for quaddie/dd)
		if (nextProps.raceId !== prevState.raceId) {
			// quaddiesLegsSelections

			if (QUADDIES_BET_TYPES.includes(nextProps.betType)) {
				const { quaddiesLegsSelections } = nextProps;

				const selectedSelections = quaddiesLegsSelections[nextProps.raceId];

				const selectionCheckboxes = {};

				if (selectedSelections && selectedSelections.length > 0) {
					selectedSelections.forEach((s) => {
						selectionCheckboxes[s.selection_id] = {
							1: true,
						};
					});
				}
				return (newState = {
					...RaceSelectionsList.initialState(nextProps),
					selectionCheckboxes,
				});
			} else {
				return RaceSelectionsList.initialState(nextProps);
			}
		}

		let newState = null;

		// Check if the bet type state has changed
		if (nextProps.betType !== prevState.betType || nextProps.boxed !== prevState.boxed) {
			if (QUADDIES_BET_TYPES.includes(nextProps.betType)) {
				nextProps.handleClearSelections();
				return (newState = {
					betType: nextProps.betType,
					displayedCheckboxes: nextProps.displayedCheckboxes,
					selectionCheckboxes: {},
					checkAllCheckboxes: [],
				});
			} else {
				return (newState = {
					boxed: nextProps.boxed,
					betType: nextProps.betType,
					selectionCheckboxes: {}, // clear selections on bet type change
					displayedCheckboxes: nextProps.displayedCheckboxes,
					checkAllCheckboxes: RaceSelectionsList.initialState().checkAllCheckboxes,
				});
			}
		}
		// if clear selections is true, clear checkboxes and reset clear selections toggle to false
		if (nextProps.clearSelections || prevState.clearSelections) {
			newState = {
				selectionCheckboxes: {},
				checkAllCheckboxes: [],
			};
			nextProps.handleClearSelections();
		}

		// check if the form state has changed
		if (nextProps.allFormOpen !== prevState.allFormOpen) {
			let newOpenedForm = {};

			if (nextProps.allFormOpen) {
				// Builds the 'openedForm' object with ALL the form panels set as open
				nextProps.selections.forEach((selection) => {
					newOpenedForm[selection.id] = true;
				});
			}

			newState = {
				...newState,
				openedForm: newOpenedForm,
				allFormOpen: nextProps.allFormOpen,
			};
		}

		// now after all the prop changes have been processed call setState once to avoid multiple renders
		return newState;
	}
	componentDidMount() {
		if (this.props.isMobileWidth) {
			this.props.toteChanged(false);
		}
		window.addEventListener('scroll', this.handleOnScrollFixed);
	}

	componentDidUpdate(prevProps) {}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleOnScrollFixed);
	}

	logGraphIconClick = (event, direction, betProduct) => {
		// console.log('Graph icon clicked', { event, direction, betProduct });
	};

	handleRunnerFormVisibility(runnerId) {
		this.setState((prevState) => ({
			runnerFormVisibility: {
				...prevState.runnerFormVisibility,
				[runnerId]: !prevState.runnerFormVisibility[runnerId],
			},
		}));
	}

	/**
	 * Builds the 'openedForm' object with ALL the form panels set as open
	 */
	// buildAllFormOpen = () => {
	// 	let allFormOpen = {};
	//
	// 	this.props.selections.forEach((selection) => {
	// 		allFormOpen[selection] = true;
	// 	});
	//
	// 	return allFormOpen;
	// };

	/**
	 * Handles the single bet clicks
	 *
	 * @param selectionId
	 * @param productId
	 * @param betType
	 */
	handleSingle = (selectionId, productId, betType) => {
		this.props.clickSingle(selectionId, productId, betType);
	};

	handleClick = (event) => {
		if (event.target.checked) {
			this.setState({
				visible: true,
			});
		} else {
			this.setState({
				visible: false,
			});
		}
	};
	/**
	 * Handles the multi bet clicks
	 *
	 * @param {number} selectionId
	 * @param {number} productId
	 * @param {string} betType either 'win' or 'place'
	 */
	handleMulti = (selectionId, productId, betType) => {
		this.props.clickMulti(selectionId, productId, betType);
	};

	/**
	 * This handles the placing of an exotic bet
	 * passes through an object of all the selected checkboxes for each selection
	 */
	handleExoticBetPlacement = () => {
		const { selections, clickExotic } = this.props;
		const { selectionCheckboxes } = this.state;
		let obj = {};

		selections.forEach((selection) => {
			// return if the selection is scratched in any way ... well not, not scratched ... ;)
			if (selection.selection_status !== SELECTION_NOT_SCRATCHED_STATUS) {
				return;
			}

			// return if no property for the selection ... means it has nothing checked
			if (!selectionCheckboxes[selection.id]) {
				return;
			}

			// if we have valid selections then pass them to the exotic bet function
			obj[selection.id] = {
				...selectionCheckboxes[selection.id],
			};
		});
		this.props.handleClearSelections();
		clickExotic(obj);
		this.props.onMenuClick();
	};

	validadeExotics = () => {
		const { selectionCheckboxes } = this.state;
		let valid = false;

		// Check there is no selected checkbox
		if (Object.keys(selectionCheckboxes).length === 0) {
			return valid;
		}

		// Check there is no checkbox in the position 1 selected
		for (let selection in selectionCheckboxes) {
			if (selectionCheckboxes[selection][1]) {
				valid = true;
				break;
			}
		}

		return valid;
	};

	groupBySraching = () => {
		const { selections } = this.props;
		return selections;
	};

	/**
	 * Handle the toggling of a single selection checkbox
	 *
	 * @param checkboxColumnIndex
	 * @param selectionId
	 * @returns {{selectionCheckboxes: *, checkAllCheckboxes: *}}
	 */
	handleCheckboxChange = (checkboxColumnIndex, selectionId) => {
		this.setState((prevState) => {
			// get the previous 'check all' & selection state sets
			let selectionCheckboxes = { ...prevState.selectionCheckboxes };
			let checkAllCheckboxes = { ...prevState.checkAllCheckboxes };
			// if no selection property then previous state was false so we will need to create it
			if (!selectionCheckboxes[selectionId]) {
				selectionCheckboxes[selectionId] = {};
			}
			// now set the checkbox column property to true if it doesn't exist
			if (!selectionCheckboxes[selectionId][checkboxColumnIndex]) {
				selectionCheckboxes[selectionId][checkboxColumnIndex] = true;

				// otherwise the previous state was true so remove the reference
			} else {
				delete selectionCheckboxes[selectionId][checkboxColumnIndex];
				if (Object.keys(selectionCheckboxes[selectionId]).length === 0) {
					delete selectionCheckboxes[selectionId];
				}
			}
			// To determine the new 'check all' state we will start by setting 'allCheckboxesAreChecked' to true,
			// then set to false if we find any unchecked checkboxes as we loop through the selections
			let allCheckboxesAreChecked = true;

			// loop through selections to determine the new 'check all' state
			this.props.selections.forEach((selection) => {
				if (!selectionCheckboxes[selection.id] || !selectionCheckboxes[selection.id][checkboxColumnIndex]) {
					allCheckboxesAreChecked = false;
				}
			});

			// add the new 'check all' state to the set and return
			checkAllCheckboxes[checkboxColumnIndex] = allCheckboxesAreChecked;
			return { selectionCheckboxes, checkAllCheckboxes };
		});
		const { betType } = this.props;

		if (QUADDIES_BET_TYPES.includes(betType)) {
			this.props.handleQuaddieSelections(selectionId);
		}
	};

	/**
	 * Handles the checking or un-checking of ALL the checkboxes
	 *
	 * @param e
	 * @param checkboxColumnIndex
	 * @returns {{selectionCheckboxes: *, checkAllCheckboxes: *}}
	 */
	handleCheckAllCheckboxes = (e, checkboxColumnIndex) => {
		this.setState((prevState) => {
			// get the previous 'check all' & selection state sets
			let selectionCheckboxes = { ...prevState.selectionCheckboxes };
			let checkAllCheckboxes = { ...prevState.checkAllCheckboxes };
			const { betType } = this.props;

			// When looping through the selections here we don't need to worry about the scratch status of a selection,
			// this will be taken care of at the time of placing an exotic bet
			this.props.selections.forEach((selection) => {
				// Now if the NEW 'check all' status is true then set all selection checkboxes & 'check all' to true
				if (!checkAllCheckboxes[checkboxColumnIndex]) {
					// create the selection property if it doesn't exist
					if (!selectionCheckboxes[selection.id]) {
						selectionCheckboxes[selection.id] = {};
					}
					// then add the checkbox property to the state set
					if (!selectionCheckboxes[selection.id][checkboxColumnIndex]) {
						selectionCheckboxes[selection.id][checkboxColumnIndex] = true;

						if (QUADDIES_BET_TYPES.includes(betType)) {
							this.props.handleQuaddieSelections(selection.id);
						}
					}
					// Otherwise the NEW 'check all' status will be false
					// So we will need to uncheck all the selection checkboxes & unset the 'check all' checkbox state
				} else {
					// remove any selection checkbox properties (which will set them as false)
					if (selectionCheckboxes[selection.id] && selectionCheckboxes[selection.id][checkboxColumnIndex]) {
						delete selectionCheckboxes[selection.id][checkboxColumnIndex];
					}
					// Also remove any selection properties if they are empty
					if (Object.keys(selectionCheckboxes[selection.id]).length === 0) {
						delete selectionCheckboxes[selection.id];
					}

					if (QUADDIES_BET_TYPES.includes(betType)) {
						this.props.handleQuaddieSelections(selection.id, true);
					}
				}
			});

			// toggle the 'check all' state and return
			checkAllCheckboxes[checkboxColumnIndex] = !checkAllCheckboxes[checkboxColumnIndex];
			return { selectionCheckboxes, checkAllCheckboxes };
		});
	};

	handleCleanQuaddies = () => {
		this.setState(
			(prevState) => {
				// get the previous 'check all' & selection state sets
				let selectionCheckboxes = { ...prevState.selectionCheckboxes };
				let checkAllCheckboxes = { ...prevState.checkAllCheckboxes };
				const { betType } = this.props;
				// When looping through the selections here we don't need to worry about the scratch status of a selection,
				// this will be taken care of at the time of placing an exotic bet
				this.props.selections.forEach((selection) => {
					// remove any selection checkbox properties (which will set them as false)
					if (selectionCheckboxes[selection.id] && selectionCheckboxes[selection.id][1]) {
						delete selectionCheckboxes[selection.id][1];
					}

					if (QUADDIES_BET_TYPES.includes(betType)) {
						this.props.handleQuaddieSelections(selection.id, true);
					}
				});

				// toggle the 'check all' state and return
				checkAllCheckboxes[1] = false;
				return { selectionCheckboxes, checkAllCheckboxes };
			},
			() => {
				this.props.cleanQuaddies();
				this.props.handleClearSelections();
			},
		);
	};

	/**
	 * Build the checkbox headers to render
	 *
	 * @param checkBoxesDisplayed
	 * @returns {Array}
	 */
	buildCheckboxHeaders = (checkBoxesDisplayed) => {
		let checkBoxHeaderArray = [];

		/**
		 * Run through the checkBoxesDisplayed object eg. {1: true, 2: true, 3: false, 4: false}
		 * and add a header label for each checkbox that will be displayed for each selection item in the list
		 */
		// for (const index in checkBoxesDisplayed) {
		Object.keys(checkBoxesDisplayed).forEach((key) => {
			if (checkBoxesDisplayed[key]) {
				checkBoxHeaderArray.push(
					<div
						key={key}
						className={css`
							width: 32px;
							text-align: center;
						`}
					>
						{key}
					</div>,
				);
			}
		});

		return checkBoxHeaderArray;
	};
	/**
	 * Builds up the checkboxes to be used in footer to toggle all the checkboxes
	 *
	 * @param checkBoxesDisplayed
	 * @returns {Array}
	 */
	buildCheckAllCheckboxes = (checkBoxesDisplayed) => {
		let checkAllCheckBoxArray = [];

		/**
		 * Run through the checkBoxesDisplayed object eg. {1: true, 2: true, 3: false, 4: false}
		 * and add a check all checkbox that will be displayed for each checkbox column
		 */
		Object.keys(checkBoxesDisplayed).forEach((key) => {
			if (checkBoxesDisplayed[key]) {
				checkAllCheckBoxArray.push(
					<StyledRaceSelectionsList__CheckAllCheckbox
						key={key}
						name={'check-all_' + key}
						checked={this.state.checkAllCheckboxes[key]}
						action={this.handleCheckAllCheckboxes}
						data={key}
						size="large"
					/>,
				);
			}
		});

		return checkAllCheckBoxArray;
	};
	/**
	 * Builds products header.
	 * Uses image | 'Fixed' for fixed product | product code
	 *
	 * @param betProduct
	 * @return {string}
	 */
	buildProductHeader = (betProduct) => {
		const { t } = this.props;
		const { fixed, product_id, product_image, product_code, bet_type, product_type } = betProduct;

		const getProductHeader = () => {
			if (fixed) {
				if (product_code == RACING_BET_PRODUCT_CODE_TF) return 'TopFluc';
				if (product_code == RACING_BET_PRODUCT_CODE_SP) return '';
				return t('FIXED');
			}
			if (product_image) {
				return <StyledRaceSelectionsList__ProductIcon src={betProduct.product_image} alt={betProduct.product_code} />;
			}
			if (toteProducts.includes(product_code)) {
				return TOTE_NAMES_BY_PRODUCT[betProduct.product_code] &&
				TOTE_NAMES_BY_PRODUCT[betProduct.product_code][this.props.Type]
					? TOTE_NAMES_BY_PRODUCT[betProduct.product_code][this.props.Type]
					: 'TOTE';
			}
			return t(product_code);
		};

		const getProductType = () => {
			if (bet_type === RACING_SAME_RACE_MULTI_TYPE) {
				return t(product_code.replace('SRM', ''));
			}
			if (toteProducts.includes(product_code)) {
				return product_code === RACING_BET_TYPE_SP ? 'SP' : bet_type.toUpperCase();
			}
			return t(bet_type.toUpperCase());
		};

		let productHeader = getProductHeader();
		let productType = getProductType();

		if (product_type === RACING_BET_TYPE_EACHWAY) {
			productHeader = 'Win';
			productType = 'Place';
		}

		return (
			<span
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer',
					whiteSpace: 'nowrap'
				}}
				onClick={() => this.reOrderRunners(!this.state.priceFilter, betProduct)}
			>
				{productHeader}
				<br />
				{productType}
			</span>
		);
	};

	/**
	 * Builds the buttons for the Pool Totals
	 *
	 * @param displayedBetProducts
	 * @param small
	 * @returns {Array}
	 */
	buildPoolButtons = (displayedPoolProducts, small) => {
		let columns = [];

		displayedPoolProducts.forEach((product) => {
			if (!product) {
				return;
			}

			if (product.has_pool) {
				columns.push(
					<StyledRaceSelectionsList__PoolTotal size={this.props.size} small={small}>
						{dollarsAsFormat(product.pool_total / 100, '$0,0')}
					</StyledRaceSelectionsList__PoolTotal>,
				);
			} else {
				columns.push(<StyledRaceSelectionsList__GhostColumn size={this.props.size} small={small} />);
			}
		});

		return columns;
	};

	onOpenModal = () => {
		this.setState({ open: true });
	};
	onCloseModal = () => {
		this.setState({ open: false });
	};

	ShowModal = (fuData, runnerName, number, silk, raceNumber, last_starts_string, trainer, jockey, weight) => {
		// console.log('ShowModal Data: ', {
		// 	fuData,
		// 	runnerName,
		// 	number,
		// 	silk,
		// 	raceNumber,
		// 	last_starts_string,
		// 	trainer,
		// 	jockey,
		// 	weight,
		// });
		this.setState({
			showVerifyExplanation: true,
			FlucsData: fuData,
			runnerName: runnerName,
			runnerNumber: number,
			runnerSilk: silk,
			raceNumber: raceNumber,
			last_starts_string: last_starts_string,
			trainer: trainer,
			jockey: jockey,
			weight: weight,
		});
	};

	getWinOnePrice = (selections) => {
		return [selections.prices.find((price) => price.product_id === 19)];
	};

	getWinTwoPrice = (selections) => {
		return [selections.prices.find((price) => price.product_id === 20)];
	};

	getWinThreePrice = (selections) => {
		return [selections.prices.find((price) => price.product_id === 21)];
	};

	Mtest = (selections) => {
		return [selections.prices.find((price) => price.product_id === 19)];
	};

	reOrderRunners(isUp, betProduct) {
		const sortFunction = (a, b) => {
			const aPrice = a.prices.find((price) => price.product_id === betProduct.product_id);
			const bPrice = b.prices.find((price) => price.product_id === betProduct.product_id);

			if (!aPrice && !bPrice) return 0;
			if (!aPrice) return 1;
			if (!bPrice) return -1;

			const betOdd = [16, 36].includes(betProduct.product_id) ? 'win_odds' : 'place_odds';

			if (aPrice[betOdd] === bPrice[betOdd]) return 0;
			return isUp ? bPrice[betOdd] - aPrice[betOdd] : aPrice[betOdd] - bPrice[betOdd];
		};

		const priceFilter =
			isUp === this.priceFilter &&
			this.state.sortedProduct === `${betProduct.product_id}${betProduct.bet_type}${betProduct.product_code}`
				? !isUp
				: isUp;

		this.setState({
			sortedProduct: `${betProduct.product_id}${betProduct.bet_type}${betProduct.product_code}`,
			priceFilter: priceFilter,
			sortSelection: sortFunction,
		});
	}
	sortByRunnerNumber = () => {
		let sortFuntion = () => {};

		if (
			this.state.sortedProduct == 'asc' ||
			(this.state.sortedProduct != 'desc' && this.state.sortedProduct != 'asc')
		) {
			sortFuntion = (a, b) => {
				if (a.number > b.number) return -1;
				return 1;
			};
		} else {
			sortFuntion = (a, b) => {
				if (a.number < b.number) return -1;
				return 1;
			};
		}

		this.setState({
			sortedProduct:
				this.state.sortedProduct == 'asc' || (this.state.sortedProduct != 'desc' && this.state.sortedProduct != 'asc')
					? 'desc'
					: 'asc',
			priceFilter: null,
			sortSelection: sortFuntion,
		});
	};

	handleSameRaceMultiBetPlacement = () => {
		const { selectionsSameRaceMulti, handleAddSameRaceMultiBetSlip } = this.props;
		if (selectionsSameRaceMulti.length > 0) {
			handleAddSameRaceMultiBetSlip(selectionsSameRaceMulti);
		}
	};

	handleOnScrollFixed() {
		const lastRow = this.WrapperRef.current;
		const wrapperHeight = lastRow.offsetTop + lastRow.clientHeight;
		const pageOffset = window.pageYOffset + window.innerHeight;
		const bottomOffset = 100;
		//console.log('fixed', pageOffset, wrapperHeight, pageOffset > wrapperHeight + bottomOffset)
		if (pageOffset > wrapperHeight + bottomOffset) {
			this.setState({ fixedWrapper: true });
		} else {
			this.setState({ fixedWrapper: false });
		}
	}

	// Flucs | Tote | HotStack
	handleSwitch = (name, state) => {
		switch (name) {
			case 'Flucs':
				this.setState({
					checked: state,
				});
				break;
			case 'Tote':
				this.props.toteChanged(state);
				break;
			case 'HotStats':
				this.props.handleHotStatsToggle(state);
				break;
			default:
				break;
		}
	};
	/**
	 * Remove a selection id from the list of opened selection items
	 *
	 * @param selectionId
	 */
	handleCloseForm = (selectionId) => {
		this.setState((prevState) => {
			let openedForm = {
				...prevState.openedForm,
				[selectionId]: false,
			};

			return {
				openedForm,
			};
		});
	};

	test = () => {};

	handleHideVerifyExplanation = () => {
		this.setState({
			showVerifyExplanation: false,
		});
	};

	handleShowVerifyExplanation = () => {
		this.setState({
			showVerifyExplanation: true,
		});
	};
	/**
	 * Toggles the form panel
	 */
	toggleForm = (selectionId) => {
		if (this.state.openedForm[selectionId]) {
			this.handleCloseForm(selectionId);
		} else {
			this.handleOpenForm(selectionId);
		}
	};
	/**
	 * Add a selection id to the list of opened form items
	 *
	 * @param selectionId
	 */
	handleOpenForm = (selectionId) => {
		this.setState((prevState) => {
			let openedForm = {
				...prevState.openedForm,
				[selectionId]: true,
			};

			this.props.trackOpenRunnerForm();
			return {
				...prevState,
				openedForm,
			};
		});
	};

	validateQuaddies = () => {
		const { quaddiesLegsSelections, betType } = this.props;

		const races = Object.keys(quaddiesLegsSelections);
		if (races.length == 0) {
			return false;
		}

		if (races.length != QUADDIES_BET_TYPES_COUNT[betType]) {
			return false;
		}

		for (const key of races) {
			if (quaddiesLegsSelections[key].length == 0) {
				return false;
			}
		}

		return true;
	};

	isArrowActive = (direction, betProduct) => {
		const { sortedProduct, priceFilter } = this.state;
		const sortedProductString = `${betProduct.product_id}${betProduct.bet_type}${betProduct.product_code}`;
		return (
			sortedProduct === sortedProductString &&
			((direction == 'up' && priceFilter) || (direction == 'down' && !priceFilter))
		);
	};

	render() {
		const {
			t,
			className,
			displayedBetProducts,
			displayedPoolProducts,
			bettingAvailable,
			goatLength,
			displayedFlucs,
			betType,
			buildSelectionBetButtons,
			isMobileWidth,
			size,
			boxed,
			hotSelection,
			hotStatsOpen,
			raceNumber,
			raceName,
			Type,
			StartDate,
			MettingName,
			speedmapOpen,
			meetingCountry,
			raceDistance,
			selectedGoatProduct,
			user,
			enabled_cache_out,
			handleAddToBackBook,
			backBookResponse,
			showSelectWinFixedOldPrice,
			selectionsOnbet,
			selectionsSameRaceMulti,
			currentSameRaceMultiPrice,
			handleSameRaceMultiSelections,
			handleAddSameRaceMultiBetSlip,
			shouldRenderFormsButton,
			handleAllFormToggle,
			expandFormVisibility,
			allFormOpen,

			handleHotStatsToggle,
			raceType,

			// Boxed options props
			handleBetTypeChange,
			isBoxedSelected,
			activeProduct,
			selections,
			flucsCheck,
		} = this.props;
		const componentClasses = cx({
			[className]: className,
		});

		const { sortSelection } = this.state;
		const small = isMobileWidth ? displayedBetProducts.length > 2 : displayedBetProducts.length > 4;
		const atLeastOnePoolTotal = displayedPoolProducts.find((product) => product.pool_total > 0);
		const showFlucs_Chart = flucsCheck;
		/// When bet  type is Same Race Multi

		// And disable the other options

		/**
		 * Handles the toggling of the boxed selector checkboxes and sets new bet type
		 *
		 * @param e
		 * @param requestedBetType
		 */
		const toggleBoxed = () => {
			// send back the bet type and toggle the boxed status
			handleBetTypeChange(betType, !isBoxedSelected, activeProduct);
		};

		const isExotic = RACING_EXOTIC_BET_TYPES.includes(betType);
		const isQuaddie = QUADDIES_BET_TYPES.includes(betType);

		const selectionsState = selections ? [...this.props.selections] : [];
		if (sortSelection) {
			selectionsState.sort(sortSelection);
		}

		const scratched_selections =
			selectionsState &&
			selectionsState.filter((selections) => selections.selection_status !== SELECTION_NOT_SCRATCHED_STATUS);
		const unscratched_selections =
			selectionsState &&
			selectionsState.filter((selections) => selections.selection_status === SELECTION_NOT_SCRATCHED_STATUS);
		return (
			<div>
				<StyledRaceSelectionsList className={componentClasses}>
					<StyledRaceSelectionsList__HeaderRow size="-5" strong>
						<div style={{ display: 'flex', gap: 4, cursor: 'pointer' }} onClick={() => this.sortByRunnerNumber()}>
							<Text size="-3" strong>
								Runner Number
							</Text>
							{this.state.sortedProduct == 'asc' && <ArrowIcon color="black" direction="up" size="-3" />}
							{this.state.sortedProduct == 'desc' && <ArrowIcon color="black" direction="down" size="-3" />}
							{this.state.sortedProduct != 'desc' && this.state.sortedProduct != 'asc' && (
								<ArrowIcon color="black" direction="down" size="-3" />
							)}
						</div>

						{this.state.showVerifyExplanation && (
							<Modal
								config={{
									mobileWidth: true,
									noBorder: true,
									preventBackdropClose: true,
									showEncryptionMessage: false,
									hideClose: true,
									wide: true,
								}}
								open={this.state.showVerifyExplanation}
								onClose={this.handleHideVerifyExplanation}
							>
								<Panel
									className={css`
										max-width: 700px !important;
										background-color: #fff;
										padding: 20px;
									`}
								>
									<button
										onClick={this.handleHideVerifyExplanation}
										className={css`
											border-radius: 50%;
											width: 30px;
											height: 30px;
										`}
									>
										<i className="fa fa-times" aria-hidden="true" />
									</button>

									<Modle__Header>
										<StyledRaceHeaderBar
											fluid
											noPadding
											className={componentClasses}
											type="primary"
											nodeLeft={
												<div>
													<StyledRaceHeaderBar__RaceNumber>{raceNumber}</StyledRaceHeaderBar__RaceNumber>
													<Icon
														className={css`
															padding: 6px;
														`}
														key="2"
														size="1"
														icon={RACING_TYPES_LOOKUP[Type]}
														push="1"
													/>
												</div>
											}
											margin={false}
										>
											<RaceHeaderBarModle>
												<PlotElements
													vertical
													align="space-around"
													alignElements="start"
													className={css`
														overflow: hidden;
													`}
												>
													<Text
														textOverflow="ellipsis"
														strong
														className={css`
															display: inline-block;
															white-space: nowrap;
														`}
														type="alternate"
													>
														{`${MettingName}`}
														<span
															className={css`
																font-size: 11px;
																padding-right: 7px;
																padding-left: 7px;
																font-weight: normal;
															`}
														>{`(${meetingCountry})`}</span>
														<span
															className={css`
																font-size: 11px;
																font-weight: normal;
															`}
														>{`- ${raceDistance}m`}</span>
													</Text>
													<StyledRaceHeaderBar__RaceName
														mediaSize={size}
														textOverflow="ellipsis"
														size="-3"
														type="alternate"
													>
														{raceName}
													</StyledRaceHeaderBar__RaceName>
													<StyledRaceHeaderBar__RaceName
														mediaSize={size}
														textOverflow="ellipsis"
														size="-3"
														type="alternate"
													>
														{t('Race Date')} | {StartDate.split('T')[0]}
														<span
															className={css`
																padding-left: 10px;
															`}
														/>
														{t('Race Time')} | {StartDate.split('T')[1].substring(0, 8)}
													</StyledRaceHeaderBar__RaceName>
												</PlotElements>
											</RaceHeaderBarModle>
										</StyledRaceHeaderBar>
									</Modle__Header>

									<StyledRaceSelectionsDetails className={componentClasses}>
										<div>
											<StyledRaceSelectionsDetails__Silk size={size}>
												<StyledRaceSelectionsDetails__SilkImage
													size={size}
													src={this.state.runnerSilk ? this.state.runnerSilk : GENERIC_SILK}
													alt={'racing silk'}
													substituteImageSrc={GENERIC_SILK}
												/>
												<StyledRaceSelectionsDetails__LastStartsRow size="-5" align="center" strong mediaSize={size}>
													{this.state.last_starts_string}
												</StyledRaceSelectionsDetails__LastStartsRow>
											</StyledRaceSelectionsDetails__Silk>
											<StyledRaceSelectionsDetails__DetailsColumn size={size}>
												<StyledRaceSelectionsDetails__Name mediaSize={size} size="-2" transform="uppercase" strong>
													{this.state.runnerNumber && `${this.state.runnerNumber}.`} {this.state.runnerName}
												</StyledRaceSelectionsDetails__Name>
												<StyledRaceSelectionsDetails__RunnerDetails mediaSize={size} size="-3">
													Trainer: <b>{this.state.trainer}</b>
												</StyledRaceSelectionsDetails__RunnerDetails>
												<StyledRaceSelectionsDetails__RunnerDetails
													mediaSize={size}
													size="-3"
													whiteSpace="nowrap"
													textOverflow="ellipsis"
												>
													{Type && Type !== 'G' && this.state.jockey ? (
														<StyledRaceSelectionsDetails__RunnerDetails
															mediaSize={size}
															size="-3"
															whiteSpace="nowrap"
															textOverflow="ellipsis"
														>
															{Type !== 'H' ? (
																<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-3">
																	Weight :{' '}
																	<b>
																		{this.state.weight}
																		Kg
																	</b>
																	<span
																		className={css`
																			margin-right: 10px;
																		`}
																	/>
																</StyledRaceSelectionsDetails__RunnerDetails>
															) : null}
															{Type !== 'H' ? 'Jockey' : 'Driver'} : <b>{this.state.jockey} </b>
														</StyledRaceSelectionsDetails__RunnerDetails>
													) : null}
												</StyledRaceSelectionsDetails__RunnerDetails>
											</StyledRaceSelectionsDetails__DetailsColumn>
										</div>
									</StyledRaceSelectionsDetails>

									<div
										className={css`
											margin-top: 15px;
										`}
									>
										{/* {console.log('LineChart Data: ', this.state.FlucsData)} */}
										<LineChart
											width={420}
											height={420}
											data={this.state.FlucsData}
											syncId="anyId"
											margin={{
												left: 0,
											}}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis
												interval={2}
												dx={0}
												padding={{ top: -20, left: 30, right: 30 }}
												fontSize={9}
												textAnchor="end"
											/>
											<YAxis type="number" />
											<Tooltip />
											<Legend align="left" />
											<Line type="monotone" dataKey="fixed_odds" stroke="#B6000B" fill="#B6000B" />
										</LineChart>
									</div>
								</Panel>
							</Modal>
						)}
						<div
							className={css`
								display: flex;
								flex-direction: row;
								flex: 1;
								justify-content: end;
								align-items: center;
							`}
						>
							{isExotic && (
								<StyledRaceSwitch style={{ paddingRight: '10px', alignItems: 'center' }}>
									<Switch onChange={toggleBoxed} checked={isBoxedSelected} width={30} height={15} />

									<span
										className={css`
											font-size: 9px;
											padding-left: 5px;
											font-weight: bold;
										`}
									>
										Boxed
									</span>
								</StyledRaceSwitch>
							)}

							{!isMobileWidth && hotStatsOpen && (
								<HotStrikeRateGroup>
									<div style={{ textAlign: 'center' }}>
										<Text strong size="-3" align="center">
											Win
										</Text>

										<StyledRaceStrikeRate_header>
											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JH')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>
											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_J')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>

											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_T')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>

											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JT')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>
										</StyledRaceStrikeRate_header>
									</div>

									<div style={{ textAlign: 'center' }}>
										<Text strong size="-3" align="center">
											Place
										</Text>

										<StyledRaceStrikeRate_header>
											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JH')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>
											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_J')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>

											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_T')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>

											<StyledRaceStrikeRate_percentage_header>
												<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JT')}</StyledRaceStrikeRate__Subtitle>
											</StyledRaceStrikeRate_percentage_header>
										</StyledRaceStrikeRate_header>
									</div>
								</HotStrikeRateGroup>
							)}

							{/* race strike rate header end*/}
							{showFlucs_Chart === true && betType !== 'OddGrid' ? (
								<StyledRaceSelectionsList__HeaderFlucs size={size}>
									{t('RaceSelectionsList__Flucs')}
								</StyledRaceSelectionsList__HeaderFlucs>
							) : null}
							<StyledHeaderCellGroup betType={betType} buttons={displayedBetProducts.length} size={size}>
								{displayedBetProducts.map((betProduct) => (
									// Cover cases where there is no win/place product.
									<StyledRaceSelectionsList__HeaderCell
										key={
											betProduct.id +
											betProduct.bet_type +
											betProduct.product_code +
											Math.random() * betProduct.requested_at
										}
										small={small}
										emphasised={betProduct.bet_type === RACING_BET_TYPE_MARGIN}
										size="-5"
										strong
									>
										{this.buildProductHeader(betProduct)}
										{betProduct.product_id !== -1 &&
											betProduct.product_code !== RACING_BET_TYPE_SP &&
											betProduct.product_id !== 14 && (
												<Styled_Div_ArrowIcon>
													{['up', 'down'].map((direction) => (
														<div
															key={direction}
															onClick={(event) => {
																this.reOrderRunners(direction === 'up', betProduct);
																this.logGraphIconClick(event, direction, betProduct);
															}}
														>
															<ArrowIcon
																color="black"
																direction={direction}
																size="-4"
																style={{
																	color: this.isArrowActive(direction, betProduct) ? '#c72128' : '#000',
																}}
															/>
														</div>
													))}
												</Styled_Div_ArrowIcon>
											)}
									</StyledRaceSelectionsList__HeaderCell>
								))}
							</StyledHeaderCellGroup>
							{bettingAvailable &&
								(betType !== RACING_BET_TYPE_STRAIGHT_EIGHT ||
									boxed ||
									(betType === RACING_BET_TYPE_STRAIGHT_EIGHT && !isMobileWidth)) &&
								this.buildCheckboxHeaders(this.state.displayedCheckboxes)}
						</div>
					</StyledRaceSelectionsList__HeaderRow>

					<div ref={this.WrapperRef}>
						<StyledRaceSelectionsList__RaceSelectionsListItems center compact>
							{unscratched_selections.map((selection) => (
								<RaceSelectionsListItem
									t={t}
									key={selection.id}
									{...selection}
									formOpened={this.state.openedForm[selection.id]}
									toggleForm={this.toggleForm}
									handleCheckboxChange={this.handleCheckboxChange}
									addToSingle={this.handleSingle}
									addToMulti={this.handleMulti}
									checkBoxesDisplayed={this.state.displayedCheckboxes}
									checkBoxStatus={this.state.selectionCheckboxes[selection.id]}
									selectionBetButtons={buildSelectionBetButtons(selection.prices, displayedBetProducts, betType)}
									selectionPrice={selection.prices}
									bettingAvailable={bettingAvailable}
									displayedFlucs={displayedFlucs}
									goatLength={goatLength}
									isMobileWidth={isMobileWidth}
									betType={betType}
									size={size}
									boxed={boxed}
									ShowModal={this.ShowModal}
									hotSelection={hotSelection}
									showTote={this.state.checkedTote}
									showhotStatsOpen={hotStatsOpen}
									showFlucs_Chart={showFlucs_Chart}
									showPlaceHotStats={this.state.checkedHotStack}
									selection_fluctuations={
										Array.isArray(selection.selection_fluctuations) ? selection.selection_fluctuations : []
									}
									raceNumber={raceNumber}
									scratching_time={selection.scratching_time}
									Type={Type}
									price={selection.prices}
									sppedmapOpen={speedmapOpen}
									showVerifyExplanation={this.state.showVerifyExplanation}
									getWinOnePrice={this.getWinOnePrice(selection)}
									getWinTwoPrice={this.getWinTwoPrice(selection)}
									getWinThreePrice={this.getWinThreePrice(selection)}
									OddsLibility={this.Mtest(selection)}
									selectedGoatProduct={selectedGoatProduct}
									enabled_cache_out={enabled_cache_out}
									user={user}
									handleAddToBackBook={handleAddToBackBook}
									MettingName={MettingName}
									StartDate={StartDate}
									backBookResponse={backBookResponse}
									showSelectWinFixedOldPrice={
										showSelectWinFixedOldPrice && showSelectWinFixedOldPrice.length > 0
											? showSelectWinFixedOldPrice
											: []
									}
									selectionsSameRaceMulti={selectionsSameRaceMulti}
									handleSameRaceMultiSelections={handleSameRaceMultiSelections}
								/>
							))}
						</StyledRaceSelectionsList__RaceSelectionsListItems>
						{bettingAvailable &&
							(betType === RACING_SAME_RACE_MULTI_TYPE || isQuaddie || isExotic) &&
							(betType !== RACING_BET_TYPE_STRAIGHT_EIGHT ||
								boxed ||
								(betType === RACING_BET_TYPE_STRAIGHT_EIGHT && !isMobileWidth)) && (
								<AllCheckboxesContainer>
									<StyledRaceSelectionsList__AllCheckboxes>
										{betType === RACING_MULTIPLES_TYPE && (
											<StyledRaceSelectionsList__AllCheckboxesLabel>
												Take the field
											</StyledRaceSelectionsList__AllCheckboxesLabel>
										)}
										{this.buildCheckAllCheckboxes(this.state.displayedCheckboxes)}
									</StyledRaceSelectionsList__AllCheckboxes>
								</AllCheckboxesContainer>
							)}
						{scratched_selections.length > 0 && (
							<StyledRaceSelectionsList__RaceSelectionsListItems center compact>
								{scratched_selections.map((selection) => (
									<RaceSelectionsListItem
										t={t}
										key={selection.id}
										{...selection}
										formOpened={this.state.openedForm[selection.id]}
										toggleForm={this.toggleForm}
										handleCheckboxChange={this.handleCheckboxChange}
										addToSingle={this.handleSingle}
										addToMulti={this.handleMulti}
										checkBoxesDisplayed={this.state.displayedCheckboxes}
										checkBoxStatus={this.state.selectionCheckboxes[selection.id]}
										selectionBetButtons={buildSelectionBetButtons(selection.prices, displayedBetProducts, betType)}
										selectionPrice={selection.prices}
										bettingAvailable={bettingAvailable}
										displayedFlucs={displayedFlucs}
										goatLength={goatLength}
										isMobileWidth={isMobileWidth}
										betType={betType}
										size={size}
										boxed={boxed}
										ShowModal={this.ShowModal}
										hotSelection={hotSelection}
										showTote={this.state.checkedTote}
										showhotStatsOpen={hotStatsOpen}
										showFlucs_Chart={showFlucs_Chart}
										showPlaceHotStats={this.state.checkedHotStack}
										selection_fluctuations={
											Array.isArray(selection.selection_fluctuations) ? selection.selection_fluctuations : []
										}
										raceNumber={raceNumber}
										scratching_time={selection.scratching_time}
										Type={Type}
										price={selection.prices}
										sppedmapOpen={speedmapOpen}
										showVerifyExplanation={this.state.showVerifyExplanation}
										getWinOnePrice={this.getWinOnePrice(selection)}
										getWinTwoPrice={this.getWinTwoPrice(selection)}
										getWinThreePrice={this.getWinThreePrice(selection)}
										OddsLibility={this.Mtest(selection)}
										selectedGoatProduct={selectedGoatProduct}
										enabled_cache_out={enabled_cache_out}
										user={user}
										handleAddToBackBook={handleAddToBackBook}
										MettingName={MettingName}
										StartDate={StartDate}
										backBookResponse={backBookResponse}
										showSelectWinFixedOldPrice={
											showSelectWinFixedOldPrice && showSelectWinFixedOldPrice.length > 0
												? showSelectWinFixedOldPrice
												: []
										}
									/>
								))}
							</StyledRaceSelectionsList__RaceSelectionsListItems>
						)}
						<StyledReaceCardFixed size={this.props.size.width}>
							{bettingAvailable && (betType === RACING_SAME_RACE_MULTI_TYPE || isQuaddie || isExotic) && (
								<StyledRaceCardBottom
									position={this.state.fixedWrapper}
									style={{
										justifyContent: 'flex-end',
									}}
								>
									{betType === RACING_SAME_RACE_MULTI_TYPE && (
										<RaceLegsPrice price={currentSameRaceMultiPrice} legs={selectionsSameRaceMulti} />
									)}
									{betType === RACING_SAME_RACE_MULTI_TYPE && (
										<StyledPlaceButtonWrapper>
											<StyledRaceSelectionsList__PlaceBetButton
												type="primary"
												disabled={!currentSameRaceMultiPrice}
												action={this.handleSameRaceMultiBetPlacement}
											>
												{t('RaceSelectionsList__PlaceBet')}
											</StyledRaceSelectionsList__PlaceBetButton>
										</StyledPlaceButtonWrapper>
									)}

									{isQuaddie && (
										<SelectionsPainel
											style={{
												display: this.state.openSelections ? 'grid' : 'none',
											}}
										>
											<SelectionsPainelContent>
												{Object.keys(this.props.quaddiesLegsSelections).length == 0 ||
												Object.values(this.props.quaddiesLegsSelections).every((leg) => leg && leg.length == 0) ? (
													<span>No selections</span>
												) : (
													Object.values(this.props.quaddiesLegsSelections).map((leg, i) =>
														leg ? (
															<div
																className={css`
																	display: flex;
																	justify-content: space-between;
																	max-width: 80%;
																`}
																key={i}
															>
																<span>Leg {i + 1}:</span> <span>{leg.map((s) => s.selection_number).join(',')}</span>
															</div>
														) : null,
													)
												)}
											</SelectionsPainelContent>
											<div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
												<Icon action={() => this.handleCleanQuaddies()} color="white" size="-4" icon="close" />
												<span>Clear</span>
											</div>
										</SelectionsPainel>
									)}
									{isQuaddie && (
										<StyledPlaceButtonWrapper>
											<SelectionPainelController
												onClick={() => this.setState({ openSelections: !this.state.openSelections })}
											>
												<ArrowIcon color="white" direction={this.state.openSelections ? 'up' : 'down'} size="-2" />
												<span>{this.state.openSelections ? 'Hide' : 'View'} Selections</span>
											</SelectionPainelController>

											<StyledRaceSelectionsList__PlaceBetButton
												type="primary"
												disabled={!this.validateQuaddies()}
												action={() => this.props.placeQuaddieBet()}
											>
												{t('RaceSelectionsList__AddToBetSlip')}
											</StyledRaceSelectionsList__PlaceBetButton>
										</StyledPlaceButtonWrapper>
									)}

									{isExotic && (
										<StyledPlaceButtonWrapper>
											<StyledRaceSelectionsList__PlaceBetButton
												disabled={!this.validadeExotics()}
												type="primary"
												action={this.handleExoticBetPlacement}
											>
												{t('RaceSelectionsList__PlaceBet')}
											</StyledRaceSelectionsList__PlaceBetButton>
										</StyledPlaceButtonWrapper>
									)}
								</StyledRaceCardBottom>
							)}
						</StyledReaceCardFixed>
						{atLeastOnePoolTotal ? (
							<StyledRaceSelectionsList__PoolRow align="space-between">
								<Text strong>{t('RaceSelectionsList__PoolTotals')}</Text>
								<div
									className={css`
										display: flex;
										justify-content: flex-end;
									`}
								>
									{this.buildPoolButtons(displayedPoolProducts, small)}
								</div>
							</StyledRaceSelectionsList__PoolRow>
						) : null}
					</div>
				</StyledRaceSelectionsList>
			</div>
		);
	}
}

export default withNamespaces()(RaceSelectionsList);
