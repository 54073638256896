import styled, { css } from 'react-emotion';
import { spacings, media } from '@tbh/ui-kit';


export const StyledBetbuilder_MeetingCard__Filters_section = styled('div')`
	${(props) =>
		css`
			label: Betbuilder_MeetingCard__Filters_section;


			box-sizing: border-box;
			-webkit-tap-highlight-color: transparent;

		`};

	${media(
		(props) =>
			css`
				display: flex;
				padding: ${spacings(props).compact}px;
			`,
		500,
	)};
`;
// export const StyledRaceCard__BetBuilder__Content = styled ('div')`
//     label: StyledRaceCard__BetBuilder;
//     width: 100%;
//     height:auto;
//     background-image:
//     linear-gradient(to bottom, rgba(129, 129, 129, 0.52), rgba(210, 210, 210, 0.73)),
//     url('https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/a-california-racetrack-is-shutting-down-indefinit-2-32590-1551897611-0_dblbig.jpg');
//     background-size: cover;
//     color: white;
//     padding: 20px;
//     display:flex;
// `;
// export const StyledRaceCard__BetBuilder__Meeter = styled ('div')`
//     label: StyledRaceCard__BetBuilder__Meeter;
//     background-image:url('https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/ban-909970_1280.png');
//     background-size: cover;
//     width:340px;
//     height:340px;
//     margin-top:150px;
//     margin-left:60px;
// `;

export const StyledRaceCard__BetBuilder__Button = styled ('div')`
    label: StyledRaceCard__BetBuilder__Button;
    width:50%;
    height:100%;
    &:table td, table th {
    padding:0;
    }
`;

export const StyledRaceCard__BetBuilder__Card__Content = styled('div')`
      label: StyledRaceCard__BetBuilder__Card__Content;
      width:100%;
       display:flex;
`;

export const StyledRaceCard__BetBuilder__Card__Content__Details = styled('div')`
      label: StyledRaceCard__BetBuilder__Card__Content__Details;
      width:100%;
      height:100%;
      color:#000000;
`;
export const StyledRaceCard__BetBuilder__Card__Content__Button = styled('div')`
      label: StyledRaceCard__BetBuilder__Card__Content__Button;
      position: relative;
      top:80px;
      left:115px;
`;

export const StyledRaceCard__BetBuilder__Card__Content__Lable = styled('div')`
      label: StyledRaceCard__BetBuilder__Card__Content__Lable;
      position: relative;
      top:60px;
      left:80px;
      font-size:20px;
      text-transform: uppercase;
      font-weight: bold;
`;
export const StyledRaceCard__BetBuilder__Card__Content__Marks__Lable = styled('div')`
      label: StyledRaceCard__BetBuilder__Card__Content__Marks__Lable;
      position: relative;
      top:80px;
      font-size:80px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
`;