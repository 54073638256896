export const DEPOSIT_TYPE_CARD = 'card';
export const DEPOSIT_TYPE_POLI = 'poli';
export const DEPOSIT_TYPE_BPAY = 'bpay';
export const DEPOSIT_TYPE_BANK = 'bank';

export const DEFAULT_DEPOSIT_RECEIPT_TITLE = 'DepositReceipt';

export const DEPOSIT_TYPES_NAMES = {
	[DEPOSIT_TYPE_CARD]: 'Credit Card',
	[DEPOSIT_TYPE_POLI]: 'POLi',
	[DEPOSIT_TYPE_BPAY]: 'BPay',
	[DEPOSIT_TYPE_BANK]: 'Bank Account',
};

export const VERIFIED = 'Verified';
export const NOT_VERIFIED = 'Not Verified';
