import { get } from '../../../common/Ajax';
import { resetEntities , mergeEntities} from '../../../common/actions/actionHelpers';
import { normalizeNextToJumpRaces } from '../schemas/NextToJumpRaceSchema';
import { normalizeNextToJumpRaceSelections } from '../schemas/NextToJumpRaceSelectionSchema';
import { getRaceByID } from '../../../pages/Racing/RacingHome/racingSelectorsGRS';

/**
 * Reset the NTJ races with the data from the pusher
 *
 * @param data
 */
export const nextNtjRace = (data) => {
	return resetEntities(normalizeNextToJumpRaces(data).entities);
	//return resetEntities(normalizeNextToJumpRaceSelections(data).entities); //@HW
};


export const resetNJRacesWithSelections = (data) => {
	return resetEntities(normalizeNextToJumpRaceSelections(data).entities);
};

/**
 * Normalizes and dispatch merge action for array of Races.
 * @param {Array} races
 */
export const mergeNJRaces = (races) => {
	return mergeEntities(normalizeNextToJumpRaces(races).entities);
};


export const getNJRace = (entities,raceId) => {

	return getRaceByID(entities, raceId, false);
};
/**
 * Normalizes and dispatch merge action for a Race
 * @param race
 */
export const mergeNJRace = (race) => {
	return mergeNJRaces([race]);
};


/**
 * Fetch next to jump Races.
 */
export const fetchNextToJumpRaces = () => (dispatch) => {
	return get('racing/next-to-jump')
		.then((response) => {
			let normalizedEntities = normalizeNextToJumpRaces(response.data.result);
			dispatch(resetEntities(normalizedEntities.entities));
			response.ntjRaceIds = normalizedEntities.result;
			return response;
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			return error;
		});
};

/**
 * Fetch next to jump Races.
 * new GRS route
 * @HW26SEP2019
 */
export const fetchNextToJumpRaces_GRS = () => (dispatch) => {
	return get('racing/next-to-jump-grs-v2')
		.then((response) => {
			let normalizedEntities = normalizeNextToJumpRaces(response.data.result);
			dispatch(resetEntities(normalizedEntities.entities));
			response.ntjRaceIds = normalizedEntities.result;
			return response;
		})
		.catch((error) => {
			console.error('NTJ racing request failed');
			document.Sentry && document.Sentry.captureException(error);
			return error;
		});
};

/**
 * Merge the NTJ races with the data from the pusher to update the Sidebar.
 */
export const fetchNextToJumpRaceSelections = () => (dispatch) => {
	return get('racing/next-to-jump-grs-v2')
		.then((response) => {
			let normalizedEntities = normalizeNextToJumpRaceSelections(response.data.result);
			//console.log(normalizedEntities);
			dispatch(resetEntities(normalizedEntities.entities));
			response.ntjRaceIds = normalizedEntities.result;
			return response;
		})
		.catch((error) => {
			console.error('NTJ racing request failed');
			document.Sentry && document.Sentry.captureException(error);
			return error;
		});
};

