import { get, post } from '../../../common/Ajax';
import { normalizeBankAccounts } from '../schemas/BankAccountSchema';
import { mergeEntities, removeEntities } from '../../../common/actions/actionHelpers';
import {GENERIC_ERROR_MESSAGE} from '../../../common/constants/Notifications';

/**
 * Fetch bank accounts created by current user and merge to store.
 */
export const fetchBankAccounts = () => (dispatch) => {
	return get('user/bank-details')
		.then((response) => {
			dispatch(mergeEntities(normalizeBankAccounts(response.data.data).entities));
			return response.data.data;
		});
};

/**
 * POST request to create account.
 * @param bsb
 * @param accountNumber
 * @param accountName
 * @param selectedBank
 * @return new bank account id
 */
export const createBankAccount = (bsb, accountNumber, accountName, selectedBank) => (dispatch) => {
	const params = {
		bsb_number: String(bsb),
		account_number: String(accountNumber),
		account_name: String(accountName),
		bank_details_id: String(selectedBank),
	};

	return post('user/bank-details', params)
		.then((response) => {
			dispatch(mergeEntities(normalizeBankAccounts([response.data.data]).entities));
			return response;
		});
};

/**
 * Sends delete request to server and delete entity locally
 * @param id
 */
export const deleteBankAccount = (id) => (dispatch) => {
	return post(`user/bank-details/${id}`)
		.then(() => {
			dispatch(removeEntities(normalizeBankAccounts([{ id }]).entities));
		})
		.catch((errorData) => {
			throw new Error(
				errorData.response.data && errorData.response.data.errors
					? errorData.response.data.errors
					: GENERIC_ERROR_MESSAGE,
			);
		});
};


// Updated action creator for fetching BSB details
export const fetchBsbDetails = (bsb_number) => {
	const params = { bsb_number: String(bsb_number) };
	return post('user/bsb-details', params)
		.then((response) => response.data.data)
		.catch((error) => {
			console.error('Error fetching BSB details:', error);
			throw error;
		});
};




