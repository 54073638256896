import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';

import { Text } from '@tbh/ui-kit';
import * as WHPS from '../../../../pages/Welcome/WelcomeHome/WelcomePage.styled';

const UpcommingSports = (props) => {
  const {teamName, session, Date, team_one, team_one_ods, team_two, team_two_odds, size, market_count, start_time, sport_data} = props;

  // sport_data.map((sports) => {
  //   console.log(sports.Teams)
  // })
  // console.log(sport_data.map((sports) => {
  //   return sports.sport_selections
  // }))
  return (
      <div className="row" size={size}>
        {
          sport_data.map((sports) => (
              <div className="col-md-6 col-sm-12" key={`${sports.id}`}>
                <WHPS.StyledWelcomePage__NextToJumpSportsItem_styled>
                  <WHPS.HeaderContainer_Up_Coming_Sports>
                    <WHPS.Up_Coming_Sports_TitleContainer>
                      <Text size="-2">{sports.event[0].name}</Text>
                    </WHPS.Up_Coming_Sports_TitleContainer>
                    <WHPS.Up_Coming_Sports_TitleRight>
                      <Text size="-3">{market_count}  {' markets '}</Text>
                      <Text size="-3">{start_time}</Text>
                    </WHPS.Up_Coming_Sports_TitleRight>
                  </WHPS.HeaderContainer_Up_Coming_Sports>

                  <WHPS.Up_Coming_Sports_BetContainer>

                    {sports.Teams.map((teams) => (
                      <React.Fragment key={`${teams.id}`}>
                        <WHPS.Up_Coming_Sports_TeamIconContainer>
                          <img src={'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/'+ teams.flag + '.svg'} width="30px" height="30px" className={css`border-radius: 50%`}/>
                        </WHPS.Up_Coming_Sports_TeamIconContainer>
                        <WHPS.Up_Coming_Sports_SingleBet>
                          <WHPS.Up_Coming_Sports_BetText>
                            <WHPS.Up_Coming_Sports_SelectionTitleText>
                              <Text size="-3">{teams.name}</Text>
                            </WHPS.Up_Coming_Sports_SelectionTitleText>
                            <WHPS.Up_Coming_Sports_OddsText>
                              <Text size="-3">{10}</Text>
                            </WHPS.Up_Coming_Sports_OddsText>
                          </WHPS.Up_Coming_Sports_BetText>
                        </WHPS.Up_Coming_Sports_SingleBet>
                      </React.Fragment>
                    ))}
                    <div>
                      {/*{sports.sport_selections.map((odds) => (*/}
                      {/*  <WHPS.Up_Coming_Sports_OddsText key={`${odds.id}`}>*/}
                      {/*    <Text size="-3">{odds.price}</Text>*/}
                      {/*  </WHPS.Up_Coming_Sports_OddsText>*/}
                      {/*))}*/}
                    </div>

                  </WHPS.Up_Coming_Sports_BetContainer>
                </WHPS.StyledWelcomePage__NextToJumpSportsItem_styled>
              </div>
          )
          )}

      </div>
  );
};

UpcommingSports.defaultProps = {
  teamName: PropTypes.string,
  session: PropTypes.string,
  Date:PropTypes.string,
  team_one:PropTypes.string,
  team_two:PropTypes.string,
  team_one_ods:PropTypes.string,
  team_two_odds:PropTypes.string,
  market_count:PropTypes.string,
  start_time:PropTypes.string,
};

export  default UpcommingSports;
