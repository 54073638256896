import React from 'react';
import styled from 'react-emotion';
import ModalHeader from '../../features/Application/ModalHeader/ModalHeader';

const Curtain = styled('div')`
    position:fixed;
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    alignItems: center;
    justifyContent: center;
    transition: all 0.3s ease;
`;

export const Backdrop = ({
    children,
    onClick,
    ...props
}) => {
    return (
        <Curtain
            {...props}
            onClick={onClick}
        >
            {children}
        </Curtain>
    );
};

const ModalContainer = styled('div')`
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    margin-top: 80px;
    margin-bottom: 80px;
    animation: 0.3s ease-out fadeIn;
    position: relative;
    width: fit-content;
    min-width: 300px;
    border-radius: 4px;
    background: #fff;
    overflow-y: auto;
    max-height: 90%;
    min-height: 250px;
    height: fit-content;
`;


const ModalContent = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
`;

export const Modal = ({
    closeModal,
    children,
    title
}) => {
    return (
        <Backdrop onClick={closeModal} css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                {title && <ModalHeader
                    title={title}
                    onClose={closeModal}
                />}


                {/* {children} */}
                <ModalContent>
                    {children}
                </ModalContent>

            </ModalContainer>
        </Backdrop>
    );
};

