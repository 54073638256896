import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import titleize from 'underscore.string/titleize';
import moment from 'moment';

import {
	buildBetsForRace,
	buildSelectionBetButtons,
} from '../../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
import { RACING_NON_EXOTIC_BET_TYPE } from '../../../common/constants/Racing';

import { formatAndAddSingleSelectionToBetPromptNJ, resetBetPrompt } from '../../../store/betPrompt/betPromptActions';

import { getAuthenticatedUser } from '../../../store/application/applicationSelectors';
import { formatAndAddRacingMultiBetNJ, formatAndAddRacingMultiBetUpcoming } from '../../../common/actions/multiActions';
import { isBetReferred } from '../../Betting/bettingMemoizedSelectors';
import { triggerEventMessage } from '../../../common/actions/widgetActions';

// Components
import Modal from '../../../components/controllers/Modal/Modal';
import DepositContainer from '../../Deposit/DepositContainer/DepositContainer';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import BetPromptNJContainer from '../../Betting/BetPromptContainer/BetPromptNJContainer'; // add new  BetPromptNJContainer
import UpRaceCard from '../../../components/features/Racing/UpRaceCard/UpRaceCard';
import BrandContactPhone from '../../../components/ui-components/BrandContactPhone/BrandContactPhone';
import ModalFooter from '../../../components/features/Application/ModalFooter/ModalFooter';
import { toggleSideBetSlip } from '../../../store/application/applicationActions';

class UpRaceCardContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}),

		/** De-normalized race and details */
		race: PropTypes.shape({
			id: PropTypes.number.isRequired,
			products: PropTypes.array,
			type: PropTypes.string,
			country: PropTypes.string,
			meeting_name: PropTypes.string,
			race_name: PropTypes.string,
			race_number: PropTypes.number,
			state: PropTypes.string,
			start_datetime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment)]),
			selections: PropTypes.array,
			weather: PropTypes.string,
			track_condition: PropTypes.string,
			fixed_odds_enabled: PropTypes.bool,
			class: PropTypes.string,
		}).isRequired,

		/** Passed via ownProps from parent, needed for bet placement */
		meetingId: PropTypes.number.isRequired,

		/** Passed via ownProps from parent, needed for bet placement */
		meetingName: PropTypes.string,

		raceId: PropTypes.number.isRequired,

		/** Array of objects that contain all the selection details */
		selections: PropTypes.arrayOf(
			PropTypes.shape({
				/**
				 * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
				 * They are defined in the RaceSelectionsListItem
				 */
				id: PropTypes.number.isRequired,

				/** The list of prices for the selection */
				prices: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.number, //@HW
						/** The current price for a win bet with this product */
						win_odds: PropTypes.number,

						/** The current price for a place bet */
						place_odds: PropTypes.number,

						/** The current price for a margin bet */
						margin_odds: PropTypes.number,

						/** The code for this product with this product */
						product_code: PropTypes.string.isRequired,

						/** The product id (NOTE: this is not a unique id between products) */
						product_id: PropTypes.number.isRequired,
					}),
				),
			}),
		).isRequired,

		/** Open a notification */
		//onOpenNotification: PropTypes.func.isRequired,

		/** Action to fire when the bet slip badge is clicked on */
		onSideBetSlipClick: PropTypes.func.isRequired,

		/** Action to fire when the bet slip badge is clicked on */
		isSideBetSlipOpen: PropTypes.bool.isRequired,

		/** Function for hiding bet prompt */
		handleHideBetPrompt: PropTypes.func,

		/** Indicator whether the bet has been referred */
		betReferred: PropTypes.bool,

		/** Extra class(es) to pass through */
		className: PropTypes.string,

		/** The currently authenticated user */
		user: PropTypes.object,

		/** Win/Place to build betting buttons */
		winPlaceProducts: PropTypes.arrayOf(
			PropTypes.shape({
				fixed: PropTypes.bool,
				bet_type: PropTypes.string,
			}),
		),

		/** Product choices to be displayed on small screens */
		winPlaceProductChoices: PropTypes.array,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** Add single selection when clicking on odds */

		formatAndAddSingleSelectionToBetPromptNJ: PropTypes.func.isRequired,

		/** Add to multi bet slip */
		formatAndAddRacingMultiBetUpcoming: PropTypes.func.isRequired,

		/** Actions for bet prompt */
		//resetBetPrompt: PropTypes.func.isRequired, // when  added bet popup then this one need

		/** Reset the bet prompt title back to it's default value */
		//resetBetPromptTitle: PropTypes.func.isRequired,// when  added bet popup then this one need

		/** Requirements from the bet prompt slice of state */
		betPrompt: PropTypes.shape({
			title: PropTypes.string.isRequired,
		}).isRequired,

		/** Single bets place in this race */
		bets: PropTypes.array,

		/** Brand name */
		brandName: PropTypes.string,

		/** Context name for prefixing custom events to be fired through middleware */
		eventContext: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

		/** Navigates to the selected race page with date and type*/
		//navigateToRaceWithType: PropTypes.func,

		action: PropTypes.func,

		selectionType: PropTypes.string,
	};

	static defaultProps = {
		user: null,
		bets: [],
		selections: [],
		eventContext: '',
		meetingName: 'n/a',
		className: null,
		winPlaceProducts: [],
		winPlaceProductChoices: [],
		betReferred: false,
		isSideBetSlipOpen: false,
		handleHideBetPrompt: () => {},
		brandName: '',
		size: null,
		action: () => {},
		selectionType: '',
	};

	constructor(props) {
		super(props);

		this.state = {
			// For bet type filter use
			selectedBetType: RACING_NON_EXOTIC_BET_TYPE,
			//boxed: false,
			raceId: props.raceId,

			selectedProduct: props.winPlaceProductChoices[0] && props.winPlaceProductChoices[0].betType,

			// Flag whether the bet prompt container is showing
			showBetPrompt: false,

			multiLegSelections: [],
		};
	}

	/**
	 * Get the bet prompt modal
	 *
	 * @returns {boolean|XML}
	 */
	buildBetPromptModal() {
		// Setup the modal configuration for the bet prompt
		// JASON: please help me finding a way to concentrate this logic somewhere.
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			hideClose: true,
			preventBackdropClose: true,

			beforeModalClose: () => {
				if (this.props.betReferred) {
					return false;
				}
				this.setState({
					showBetPrompt: false,
				});
			},
		};

		return (
			this.state.showBetPrompt && (
				<Modal
					open={this.state.showBetPrompt}
					componentKey="event-controller__single-bet-prompt"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<div>
						<ModalHeader
							onClose={MODAL_CONFIG.beforeModalClose}
							title={this.props.betPrompt.title}
							aside={<BrandContactPhone />}
						/>

						<BetPromptNJContainer
							eventContext={this.props.eventContext}
							handleCancel={this.handleHideBetPrompt}
							handleQuickDeposit={this.handleShowQuickDepositPrompt}
						/>

						<ModalFooter />
					</div>
				</Modal>
			)
		);
	}

	/**
	 * Get the side bet prompt
	 *
	 * @returns {boolean|XML}
	 */
	buildSideBetPrompt() {
		const { isSideBetSlipOpen, t } = this.props;

		if (isSideBetSlipOpen) {
			return ReactDOM.createPortal(
				<React.Fragment>
					<ModalHeader title={t('BetSlip')} onClose={this.handleHideSideBetPrompt} />
					<BetPromptNJContainer showQuickDeposit={false} handleCancel={this.handleHideSideBetPrompt} />
				</React.Fragment>,
				document.getElementById('side-betslip-portal'),
			);
		} else {
			null;
		}
	}

	/**
	 * Get the quick deposit prompt
	 */
	buildQuickDepositModal() {
		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			preventBackdropClose: true,
			hideClose: true,
		};

		return (
			this.state.showQuickDepositPrompt && (
				<Modal
					open={this.state.showQuickDepositPrompt}
					componentKey="event-controller__quick-deposit"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<div>
						<ModalHeader
							onClose={this.handleHideQuickDepositPrompt}
							title={this.props.betPrompt.title}
							aside={<BrandContactPhone />}
						/>
						<DepositContainer
							isQuickDeposit
							trackingCategory="Quick Deposit"
							handleClose={this.handleHideQuickDepositPrompt}
						/>
						<ModalFooter />
					</div>
				</Modal>
			)
		);
	}

	/**
	 * Show the bet prompt
	 */
	handleShowBetPrompt = () => {
		this.setState({
			showBetPrompt: true,
		});
	};

	/**
	 * Hide the bet prompt
	 */
	handleHideBetPrompt = () => {
		this.setState({
			showBetPrompt: false,
		});
	};

	/**
	 * Hide the side bet prompt
	 */
	handleHideSideBetPrompt = () => {
		const { onSideBetSlipClick } = this.props;
		resetBetPrompt();
		onSideBetSlipClick(true);
		this.setState({ multiLegSelections: [] });
	};

	/**
	 * Handles click on odds
	 * @param selectionId
	 * @param productId
	 * @param betType e.g. 'win', 'place''
	 */
	handleAddToSingle = (selectionId, productId, betType) => {
		const { meetingId, raceId, formatAndAddSingleSelectionToBetPromptNJ, eventContext, race, user } = this.props;
		// add new formatAndAddSingleSelectionToBetPromptNJ for next jump container by @HW 10Feb2020
		formatAndAddSingleSelectionToBetPromptNJ(
			selectionId,
			raceId,
			meetingId,
			productId,
			race.favorite_selection.length > 0 ? 'favorite_selection' : 'top_selections',
		).then(() => {
			triggerEventMessage(eventContext, 'selectionAddedToBetPrompt', true, user);
		});

		this.handleShowBetPrompt();
	};

	/**
	 * Handles click on plus sign. Build data from de-normalized entities and dispatch action.
	 *
	 * @param selectionId
	 * @param productId
	 * @param betType
	 */
	handleAddToMulti = (selectionId, productId, betType) => {
		const { meetingId, race,raceId, eventContext, selectionType } = this.props;

		this.props
			.formatAndAddRacingMultiBetUpcoming(
				selectionId,
				raceId,
				meetingId,
				productId,
				betType,
				race.favorite_selection.length > 0 ? 'favorite_selection' : 'top_selections',
			)
			.then(() => {
				triggerEventMessage(eventContext, 'selectionAddedToBetSlip');
			});
	};

	render() {
		const {
			size,
			user,
			bets,
			race,
			meetingName,
			meetingId,
			winPlaceProductChoices,
			buildSelectionBetButtons,
			className,
			action,
		} = this.props;

		const { selectedBetType, selectedProduct, raceId } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		const titles = {
			race: titleize(race.race_name),
			meeting: titleize(meetingName),
		};

		const selectedType = race.favorite_selection.length > 0 ? 'favorite_selection' : 'top_selections';
		const selections = race[selectedType].sort((a, b) => a.id - b.id).slice(0, 3);

		return (
			<div className={containerClasses}>
				{this.buildBetPromptModal()}
				{this.buildQuickDepositModal()}
				{this.buildSideBetPrompt()}

				<UpRaceCard
					size={size}
					user={user}
					bets={bets}
					raceId={raceId}
					race={race}
					selectedBetType={selectedBetType}
					buildSelectionBetButtons={buildSelectionBetButtons}
					selections={selections}
					selectionType={selectedType}
					goToRace={action}
					meetingName={meetingName}
					meetingId={meetingId}
					onClickSingle={this.handleAddToSingle}
					onClickMulti={this.handleAddToMulti}
					winPlaceProducts={race.products}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const betReferred = isBetReferred(state);

	return {
		betReferred,
		bets: buildBetsForRace(state),
		betPrompt: state.betPrompt,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		user: getAuthenticatedUser(state),
		isSideBetSlipOpen: state.application.showSideBetSlip,
	};
};

const mapDispatchToProps = (dispatch) => ({
	buildSelectionBetButtons: (prices, displayedBetProducts, betType) =>
		dispatch(buildSelectionBetButtons(prices, displayedBetProducts, betType)),

	formatAndAddSingleSelectionToBetPromptNJ: (selectionId, raceId, meetingId, productId, betType) =>
		dispatch(formatAndAddSingleSelectionToBetPromptNJ(selectionId, raceId, meetingId, productId, betType, false)),

	formatAndAddRacingMultiBetNJ: (selectionId, raceId, meetingId, productId, betType) =>
		dispatch(formatAndAddRacingMultiBetNJ(selectionId, raceId, meetingId, productId, betType)),

	formatAndAddRacingMultiBetUpcoming: (selectionId, raceId, meetingId, productId, betType, selectionType) =>
		dispatch(formatAndAddRacingMultiBetUpcoming(selectionId, raceId, meetingId, productId, betType, selectionType)),

	onSideBetSlipClick: (isSideBetSlipOpen) => {
		return dispatch(toggleSideBetSlip(isSideBetSlipOpen));
	},
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(UpRaceCardContainer));
