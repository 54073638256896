// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, mediaMax, brand, typography } from '@tbh/ui-kit';

// Components
import {
	Button,
	Icon,
	Text,
	CollapsibleIcon,
	Link,
	// Image
} from '@tbh/ui-kit';
import CurrencyDisplay from '../../Currency/CurrencyDisplay/CurrencyDisplay';
import { BREAKPOINTS } from '../../../../../constants/themes';

const StyledLoggedUserElements_RSAccount = styled(Link)(
	(props) => css`
		label: LoggedUserElements_RSAccount;

		padding: ${spacings(props).tight}px;
		font-family: ${typography(props).base_font};
		font-size: 14px;
		font-weight: 100;
		cursor: pointer;
		color: #fff !important;
	`,
	(props) =>
		mediaMax(
			css`
				display: none;
			`,
			481,

			{ sizeKey: 'mediaSize' },
		),
);

const StyledLoggedUserElements__BetsButton = styled(Button)(
	(props) => css`
		label: LoggedUserElements__BetsButton;

		padding: ${spacings(props).tight}px;
		font-family: ${typography(props).base_font};
		font-size: 14px;
		font-weight: 100;

		${props.type === 'primary' &&
			css`
				background: #c72128;
				border: 1px solid #c72128;
			`};
	`,
	(props) =>
		mediaMax(
			css`
				label: LoggedUserElements__BetsButton;
				padding: ${spacings(props).tight}px;
				font-family: ${typography(props).base_font};
				font-size: 14px;

				${props.type === 'primary' &&
					css`
						background: #c72128;
						border: 1px solid #c72128;
					`};
			`,
			767,
			{ sizeKey: 'mediaSize' },
		),
);

const StyledLoggedUserElements__FundsButton = styled(Button)(
	(props) => css`
		label: LoggedUserElements__FundsButton;
		//margin-left: ${spacings(props).compact}px;
		font-family:${typography(props).base_font};
		font-size: ${typography(props)['size_0']};
		font-weight: 100;
	`,
	(props) =>
		mediaMax(
			css`
				padding: ${spacings(props).tight}px;
			`,
			481,
			{ sizeKey: 'mediaSize' },
		),
);

const StyledLoggedUserElements__FundsIcon = styled(Icon)(
	mediaMax(
		css`
			label: LoggedUserElements__FundsIcon;

			display: none;
		`,
		550,
		{ sizeKey: 'mediaSize' },
	),
);

const StyledLoggedUserElements__User = styled('div')(
	(props) => css`
		label: LoggedUserElements__User;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		cursor: pointer;
		width: 100%;
	`,
);

const StyledLoggedUserElements__FirstName = styled('div')(
	(props) => css`
		label: LoggedUserElements__FirstName;
		display: flex;
		gap: 6px;
		align-items: center; /* Align items vertically */
		overflow: hidden; /* Hide overflow text */
		text-overflow: ellipsis; /* Add ellipsis for overflow text */
		flex: 1;
		white-space: nowrap;

		
	`,
);

const StyledFisrtnameText = styled(Text)(
	(props) => css`
	white-space: nowrap; 
	overflow: hidden; 
	text-overflow: ellipsis;

	@media (max-width: ${BREAKPOINTS.tabletMin}px) {
		max-width: 48px;
	}
	max-width: 100px;
	display: none;
	`
	
);


const StyledLoggedUserElements__BalanceCurrency = styled(CurrencyDisplay)(
	css`
		label: LoggedUserElements__BalanceCurrency;

		min-width: 0;
		font-size: inherit;
		${CurrencyDisplay.StyledCurrencyDisplay__Amount} {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			${'' /* text-decoration: underline; */}
		}
	`,
);

const StyledLoggedUserElements__ToggleBalance = styled('span')(
	(props) => css`
		label: LoggedUserElements__ToggleBalance;

		margin-left: ${spacings(props).compact}px;
	`,
	mediaMax(
		css`
			display: none;
		`,
		550,
	),
);

const StyledLoggedUserElements__Arrow = styled(CollapsibleIcon)(
	(props) => css`
		label: LoggedUserElements__Arrow;
		font-size: 12px;
	`,
);

const StyledLoggedUserElements = styled('div')(
	(props) => css`
		label: LoggedUserElements;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		cursor: pointer;
		background-color: ${brand(props).color_4};
		border-radius: ${spacings(props).tight}px;
		padding: ${spacings(props).tight}px;
	`,
);

const LoggedUserElements = (props) => {
	const {
		t,
		className,
		name,
		onBetsClick,
		onAddFunds,
		openSummaryView,
		first_name,
		username,
		show_account_balance,
		toggleShowBalance,
		account_balance,
		summaryOpen,
		size,
		goToAccountTransactionsPage,
		free_credit_balance,
		bonus_bet_enabled,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * Handle the click of the account balance to hide/show the balance
	 *
	 * @param e
	 */
	const onToggleShowBalance = (e) => {
		// Prevent onClick from firing
		e.stopPropagation();

		toggleShowBalance();
	};

	const isMobile = window.innerWidth < 768;

	return (
		<StyledLoggedUserElements className={componentClasses} size={size}>
			<StyledLoggedUserElements__User size={size} style={{ display: isMobile ? 'flex' : 'flex' }}>
				<Text size="-3" type="alternate-light">
					Balance
				</Text>
				<Text tag="span" type="alternate-light" size="-2" strong>
					<div onClick={openSummaryView}>
						{show_account_balance ? (
							<StyledLoggedUserElements__BalanceCurrency
								className={css`
									font-size: 11px;
								`}
								amount={account_balance}
							/>
						) : (
							t('Hidden')
						)}
					</div>
				</Text>
			</StyledLoggedUserElements__User>
			{bonus_bet_enabled && (
				<StyledLoggedUserElements__User size={size} style={{ display: isMobile ? 'none' : 'flex' }}>
					<Text size="-3" type="alternate-light">
						Bonus
					</Text>
					<Text tag="span" type="alternate-light" size="-2" strong>
						<div onClick={openSummaryView}>
							{show_account_balance ? (
								<StyledLoggedUserElements__BalanceCurrency amount={free_credit_balance} />
							) : (
								t('Hidden')
							)}
						</div>
					</Text>
				</StyledLoggedUserElements__User>
			)}
			<StyledLoggedUserElements__User size={size} style={{ display: isMobile ? 'flex' : 'flex' }}>
				<StyledLoggedUserElements__FirstName>
					<StyledFisrtnameText size="-3" type="alternate-light">
						{first_name}
					</StyledFisrtnameText>
					<div
						onClick={openSummaryView}
						className={css`
							display: flex;
							gap: 6px;
						`}
					>
						<Icon color="white" icon={'user'} size={'-1'} />
						<StyledLoggedUserElements__Arrow isCollapsed={!summaryOpen} color="white" />
					</div>
				</StyledLoggedUserElements__FirstName>
			</StyledLoggedUserElements__User>
		</StyledLoggedUserElements>
	);
};

LoggedUserElements.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The current users' account balance */
	account_balance: PropTypes.number.isRequired,

	/** The current users' ID */
	id: PropTypes.number.isRequired,

	/** The current users' username */
	username: PropTypes.string.isRequired,

	/** Action when the bets button is clicked on */
	onBetsClick: PropTypes.func.isRequired,

	/** Action when the add funds button is clicked on */
	onAddFunds: PropTypes.func.isRequired,

	/** Action to open the account summary view */
	openSummaryView: PropTypes.func.isRequired,

	/** Whether or not the users' account balance is open or not */
	show_account_balance: PropTypes.bool.isRequired,

	/** Action to toggle the display of the user' balance */
	toggleShowBalance: PropTypes.func.isRequired,

	/** The size of the component */
	size: PropTypes.shape({
		/** How wide the component is */
		width: PropTypes.number,
	}).isRequired,

	/** The current users' first name */
	first_name: PropTypes.string,

	/** The current users' full name */
	name: PropTypes.string,

	/** If the summary section is open or not */
	summaryOpen: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,

	goToAccountTransactionsPage: PropTypes.func,
};

LoggedUserElements.defaultProps = {
	className: null,
	first_name: null,
	name: null,
	summaryOpen: false,
	goToAccountTransactionsPage: null,
};

export default withNamespaces()(LoggedUserElements);
