/**
 * Created by Josef Frank on 2017/03/06.
 */
'use strict';

// Functions
const {
	denormalize,
	normalize,
	schema,
} = require('normalizr');

import strategies, {requiredFields} from 'normalizr-strategies';

// Schemas
const leaderboardItemSchema = new schema.Entity('leaderboardItems', {}, {
	processStrategy: strategies(
		requiredFields('tournament_id')
	)
});
const leaderboardItemsSchema = new schema.Array(leaderboardItemSchema);

/**
 * Denormalize leaderboard item entities back to a structure similar to the API response.
 *
 * @param entities
 * @returns {Array}
 */
const denormalizeLeaderboardItems = (entities) => {
	let keys = Object.keys(entities.leaderboardItems);
	return denormalize(keys, leaderboardItemsSchema, entities);
};

/**
 * Normalize the leaderboard item structure to suit our application.
 *
 * @param rawLeaderboardItems
 * @returns {Object}
 */
const normalizeLeaderboardItems = (rawLeaderboardItems) => {
	return normalize(rawLeaderboardItems, leaderboardItemsSchema);
};

module.exports = {
	leaderboardItemSchema,
	leaderboardItemsSchema,
	denormalizeLeaderboardItems,
	normalizeLeaderboardItems,
};