'use strict';
const {
	SET_ACTIVE_TOURNAMENT_ACTIVE_MODAL,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_AUTO_ACCEPT,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_CONFIRMED,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_TYPE,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_ERROR_MESSAGE,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_EVENT_ID,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_IS_LOADING,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_RECEIPTS,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_EXOTIC,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_PRODUCT,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_SELECTION,
	SET_ACTIVE_TOURNAMENT_BET_PROMPT_STAKE,
	SET_ACTIVE_TOURNAMENT_COMMENTS,
	SET_ACTIVE_TOURNAMENT_COMPETITION_FILTER,
	SET_ACTIVE_TOURNAMENT_EVENTS_ARE_LOADING,
	SET_ACTIVE_TOURNAMENT_MARKET_GROUP,
	SET_ACTIVE_TOURNAMENT_MARKET_TYPE_FILTER,
	SET_ACTIVE_TOURNAMENT_PLAYER_PROFILE_BETS_LOADING,
	SET_ACTIVE_TOURNAMENT_RACE_FILTER,
	SET_ACTIVE_TOURNAMENT_RACE_IS_LOADING,
	SET_ACTIVE_TOURNAMENT_REBUY_ERROR_MESSAGE,
	SET_ACTIVE_TOURNAMENT_REBUY_IS_LOADING,
	SET_ACTIVE_TOURNAMENT_SELECTED_USER,
	SET_ACTIVE_TOURNAMENT_SLEDGE_LOADING,
	SET_ACTIVE_TOURNAMENT_TICKET_ID,
	SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_LOADING,
	SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_ERROR_MESSAGE,
	SET_ACTIVE_TOURNAMENT_TITLE_IS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_TOURNAMENT_BETS_IS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_MY_PROGRESS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_COMPETITIONS_IS_COLLAPSED,
	SET_ACTIVE_TOURNAMENT_TOURNAMENT_ID,
	SET_ACTIVE_TOURNAMENT_TOURNAMENT_TYPE,
	SET_ACTIVE_TOURNAMENT_EVENT_MARKET_FILTER,
	SET_ACTIVE_TOURNAMENT_SELECTED_EVENT,
	SET_ACTIVE_TOURNAMENT_DETAIL_TAB
} = require('./activeTournamentActionTypes');

const {
	DEFAULT_MARKET_GROUP,
	LEADERBOARD_DETAIL_TAB,
} = require('./activeTournamentConstants');


const initialState = {
	activeModal: null,
	activeTicketId: null,
	activeTournamentComments: [],
	activeTournamentId: null,
	activeTournamentType: null,

	activeTournamentSelectedDetailTab: window.innerWidth < 768 ? null : LEADERBOARD_DETAIL_TAB,
	betPromptDetails: {
		autoAccept: false,
		betConfirmed: false,
		errorMessage: '',
		eventId: null,
		isLoading: false,
		receipts: [],
		productId: null,
		selectionId: null,
		stake: 0,
		type: '',
	},

	myProgressCollapsed: true,
	playerProfileBetsAreLoading: false,

	raceDetails: {
		raceFilter: null,
		raceIsLoading: false,
	},

	rebuyErrorMessage: null,
	rebuyIsLoading: false,
	selectedLeaderboardUser: null,
	sledgeIsLoading: false,

	sportsDetails: {
		eventsAreLoading: false,
		selectedCompetition: null,
		selectedEvent: null,
		selectedEventMarketFilter: null,
		selectedMarketGroup: DEFAULT_MARKET_GROUP,
		selectedMarketTypeFilter: null,
	},

	ticketPurchaseErrorMessage: null,
	ticketPurchaseIsLoading: false,
	titleIsCollapsed: false,
};

const raceDetailsReducer = (state, action) => {
	switch (action.type) {
		case SET_ACTIVE_TOURNAMENT_RACE_FILTER:
			return {
				...state,
				raceFilter: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_RACE_IS_LOADING:
			return {
				...state,
				raceIsLoading: action.payload,
			};

		default:
			return state;
	}
};

const sportsDetailsReducer = (state, action) => {
	switch (action.type) {
		case SET_ACTIVE_TOURNAMENT_COMPETITION_FILTER:
			return {
				...state,
				selectedCompetition: action.payload
			};

		case SET_ACTIVE_TOURNAMENT_MARKET_TYPE_FILTER:
			return {
				...state,
				selectedMarketTypeFilter: action.payload
			};

		case SET_ACTIVE_TOURNAMENT_MARKET_GROUP:
			return {
				...state,
				selectedMarketGroup: action.payload
			};

		case SET_ACTIVE_TOURNAMENT_EVENT_MARKET_FILTER:
			return {
				...state,
				selectedEventMarketFilter: action.payload
			};

		case SET_ACTIVE_TOURNAMENT_EVENTS_ARE_LOADING:
			return {
				...state,
				eventsAreLoading: action.payload
			};

		case SET_ACTIVE_TOURNAMENT_SELECTED_EVENT:
			return {
				...state,
				selectedEvent: action.payload
			};

		default:
			return state;
	}
};


const activeTournamentReducer = (state = initialState, action) => {
	var betPromptDetails = Object.assign({}, state.betPromptDetails);

	switch (action.type) {

		case SET_ACTIVE_TOURNAMENT_DETAIL_TAB:
			return {
				...state,
				activeTournamentSelectedDetailTab: action.payload
			};

		case SET_ACTIVE_TOURNAMENT_ACTIVE_MODAL:
			return Object.assign({}, state, {
				activeModal: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_AUTO_ACCEPT:
			betPromptDetails.autoAccept = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_CONFIRMED:
			betPromptDetails.betConfirmed = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_BET_TYPE:
			betPromptDetails.type = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_ERROR_MESSAGE:
			betPromptDetails.errorMessage = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_EVENT_ID:
			betPromptDetails.eventId = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_IS_LOADING:
			betPromptDetails.isLoading = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_RECEIPTS:
			betPromptDetails.receipts = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_EXOTIC:
			betPromptDetails.exoticDetails = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_PRODUCT:
			betPromptDetails.productId = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_SELECTION:
			betPromptDetails.selectionId = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_BET_PROMPT_STAKE:
			betPromptDetails.stake = action.payload;
			return Object.assign({}, state, {
				betPromptDetails,
			});

		case SET_ACTIVE_TOURNAMENT_COMMENTS:
			return Object.assign({}, state, {
				activeTournamentComments: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_COMPETITION_FILTER:
		case SET_ACTIVE_TOURNAMENT_EVENT_MARKET_FILTER:
		case SET_ACTIVE_TOURNAMENT_EVENTS_ARE_LOADING:
		case SET_ACTIVE_TOURNAMENT_MARKET_GROUP:
		case SET_ACTIVE_TOURNAMENT_MARKET_TYPE_FILTER:
		case SET_ACTIVE_TOURNAMENT_SELECTED_EVENT:
			return Object.assign({}, state, {
				sportsDetails: sportsDetailsReducer(state.sportsDetails, action)
			});

		case SET_ACTIVE_TOURNAMENT_PLAYER_PROFILE_BETS_LOADING:
			return {
				...state,
				playerProfileBetsAreLoading: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_RACE_FILTER:
		case SET_ACTIVE_TOURNAMENT_RACE_IS_LOADING:
			return {
				... state,
				raceDetails: raceDetailsReducer(state.raceDetails, action)
			};

		case SET_ACTIVE_TOURNAMENT_REBUY_ERROR_MESSAGE:
			return {
				...state,
				rebuyErrorMessage: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_REBUY_IS_LOADING:
			return {
				...state,
				rebuyIsLoading: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_SELECTED_USER:
			return Object.assign({}, state, {
				selectedLeaderboardUser: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_SLEDGE_LOADING:
			return Object.assign({}, state, {
				sledgeIsLoading: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_TICKET_ID:
			return Object.assign({}, state, {
				activeTicketId: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_ERROR_MESSAGE:
			return Object.assign({}, state, {
				ticketPurchaseErrorMessage: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_TICKET_PURCHASE_LOADING:
			return Object.assign({}, state, {
				ticketPurchaseIsLoading: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_TOURNAMENT_ID:
			return Object.assign({}, state, {
				activeTournamentId: action.payload
			});

		case SET_ACTIVE_TOURNAMENT_TITLE_IS_COLLAPSED:
			return {
				...state,
				titleIsCollapsed: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_TOURNAMENT_BETS_IS_COLLAPSED:
			return {
				...state,
				tournamentBetsIsCollapsed: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_MY_PROGRESS_COLLAPSED:
			return {
				...state,
				myProgressCollapsed: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_COMPETITIONS_IS_COLLAPSED:
			return {
				...state,
				competitionsIsCollapsed: action.payload,
			};

		case SET_ACTIVE_TOURNAMENT_TOURNAMENT_TYPE:
			return Object.assign({}, state, {
				activeTournamentType: action.payload
			});
	}
	return state;
};

module.exports = activeTournamentReducer;