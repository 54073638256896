import React from 'react';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { spacings } from '@tbh/ui-kit';
import { StyledNJRaceHeaderBar__Metting__NameLoading } from './../../../Carousel/UpCarouselItem/UpCarouselItem.styled';

const RaceItemLoadingContainer = styled('div')(
	(props) => css`
		label: RaceItemLoading;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: 1px solid #dddddd;
		border-radius: 4px;
		gap: 2px;
		padding: ${spacings(props).compact}px 0;
		min-width: 100px;
	`,
);

const RaceItemLoading = (props) => {
	const { size, id } = props;

	return (
		<RaceItemLoadingContainer size={size}>
			<StyledNJRaceHeaderBar__Metting__NameLoading width="20px" height="10px" style={{ borderRadius: '4px' }} />
			<StyledNJRaceHeaderBar__Metting__NameLoading width="35px" height="10px" style={{ borderRadius: '4px' }} />
		</RaceItemLoadingContainer>
	);
};

export default withNamespaces()(RaceItemLoading);
