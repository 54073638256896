import { get } from '../../../common/Ajax';
import { resetEntities, mergeEntities } from '../../../common/actions/actionHelpers';
import { normalizeExpertRaces } from '../schemas/ExpertRaceSchema';

/**
 * Reset the Expert races with the data from the pusher
 *
 * @param data
 */
export const resetExpertRaces = (data) => {
	return resetEntities(normalizeExpertRaces(data).entities);
};

/**
 * Normalizes and dispatch merge action for array of Expert Races.
 * @param {Array} data
 */
export const updateMeetings = (data) => {
	return mergeEntities(normalizeExpertRaces(data).entities);
};

/**
 * Normalizes and dispatch merge action for array of Expert Races.
 * @param {Array} races
 */
export const mergeExpertRaces = (races) => {
	return mergeEntities(normalizeExpertRaces(races).entities);
};

/**
 * Normalizes and dispatch merge action for a Expert Race
 * @param race
 */
export const mergeExpertRace = (race) => {
	return mergeExpertRaces([race]);
};

export const getAllExpertTipsByQuery = (limit, selectDate, raceType) => async (dispatch) => {
	try {
		const response = await get(`racing/expert-racetips?limit=${limit}&date=${selectDate}&race_type=${raceType}`);
		let expertRaces = response.data.data.map((expertRace) => ({ ...expertRace, requested_at: null }));
		// response.data.data.map((item) => updatePrices({id: item.id, favorite_selection: item.favorite_selection}));
		let normalizedEntities = normalizeExpertRaces(expertRaces);
		dispatch(resetEntities(normalizedEntities.entities));
		return response;
	} catch (error) {
		return error;
	}
};
