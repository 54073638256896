import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import {
	// Icon,
	Text,
	StatCell,
} from '@tbh/ui-kit';
import {
	RACE_SELLING_STATUS,
	// RACING_TYPES_LOOKUP 
} from '../../../../../common/constants/Racing';
// import { HOUR_MIN_AMPM } from '../../../../../common/constants/DateFormats';
import moment from 'moment';
import {
	serverDateTime,
	// countdown
} from '../../../../../legacy/core/format';
import {
	// Styled__TimeToGoBUtton,
	StyledRaceFeatureHeader__Status,
	// StyledRacingFeature__raceNumberBox,
} from './RacingFeatureHeader.styled';
import CountryImage from '../../CountryImage/CountryImage';
import { COLORS, FAMILYS, TYPE_SCALE, WEIGHTS, MEDIA_QUERIES } from '../../../../../constants/themes';
//import Badges from '../../../../UI/Badges';
import { PrimaryButton } from '../../../../UI/Buttons';

const RacingFeatureHeader = (props) => {
	const {
		t,
		id,
		country,
		meetingName,
		number,
		name,
		distance,
		start_datetime,
		type,
		size,
		onClickItem,
		lessThan2Min,
		status,
	} = props;

	const handleEventClick = (e) => {
		e.preventDefault();
		onClickItem(id);
	};

	const diffFromToday = (day) =>
		serverDateTime(moment())
			.startOf('day')
			.diff(serverDateTime(day).startOf('day'), 'days');

	const getHeaderText = (day) => {
		if (!day) {
			return 'Today';
		}

		switch (diffFromToday(day)) {
			case 0:
				return 'Today , ';
			case -1:
				return 'Tomorrow , ';
			case 1:
				return 'Yesterday , ';
			default:
		}
	};

	const changeCountryIcon = (country) => {
		switch (country) {
			case 'AU':
				return {
					src: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/AUS.svg',
					alt: 'AUS LOGO',
				};
			case 'NZ':
				return {
					src: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/NZ.svg',
					alt: 'NZ LOGO',
				};
			case 'SGP':
				return {
					src: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SGP.svg',
					alt: 'SGP LOGO',
				};
			case 'HK':
				return {
					src: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/HK.svg',
					alt: 'HK LOGO',
				};
			case 'FR':
				return {
					src: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/FR.svg',
					alt: 'FR LOGO',
				};
			case 'UK':
				return {
					src: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/GB.svg',
					alt: 'GB LOGO',
				};
			default:
				return null;
		}
	};


	const countryLogoInfo = changeCountryIcon(country);
	return (
		<React.Fragment>
			<FeaturedContentWrapper>
				<RaceNumberWrapper>
					<RaceNumber>{number}</RaceNumber>
				</RaceNumberWrapper>
				<RaceContentWrapper>
					<RaceMeetingName>{meetingName} {' - '} {distance} {'m'}</RaceMeetingName>
					<RaceName>{name}</RaceName>
					<RaceDateAndTime>
						{diffFromToday(start_datetime) <= 1 && getHeaderText(start_datetime)}
						{diffFromToday(start_datetime) <= 1 && serverDateTime(moment(start_datetime)).format('MMM Do YYYY, h:mm A')}
						{diffFromToday(start_datetime) >= 2 && serverDateTime(moment(start_datetime)).format('MMM Do YYYY, h:mm A')}
					</RaceDateAndTime>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'fit-content' }}>
						<StyledRaceFeatureHeader__Status
							transparent
							spacing={StatCell.spacings.SPACING_COMPACT}
							statusColor={''}
							status={status}
							value={
								<Text size="-4" type={status === RACE_SELLING_STATUS ? 'dark' : 'alternate'} transform="uppercase" ellipsis>
									{t(`Racing__Status--${status}`)}
								</Text>
							}
						/>
					</div>
				</RaceContentWrapper>
				<RaceFlagWrapper>
					<CountryImage
						country={country}
						className={css`
							width: 100%;
							border-radius: 4px;
							margin-top: 11px;
						`}
					/>
				</RaceFlagWrapper>
			</FeaturedContentWrapper>
			<RaceBottomWrapper>
				<PrimaryButton modifiers="large" onClick={handleEventClick}>View Full Race Card</PrimaryButton>
			</RaceBottomWrapper>
		</React.Fragment>
	);
};

RacingFeatureHeader.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,
	/** Extra class(es) to pass through to the component */
	className: PropTypes.string,

	/** ID of the  item */
	id: PropTypes.any.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Handle when a meeting is clicked on */
	onClickItem: PropTypes.func.isRequired,

	name: PropTypes.string,

	country: PropTypes.string,

	type: PropTypes.string,

	number: PropTypes.number,

	meetingName: PropTypes.string,

	start_datetime: PropTypes.string,

	distance: PropTypes.string,

	lessThan2Min: PropTypes.string,

	status: PropTypes.string,
};

RacingFeatureHeader.defaultProps = {
	className: null,
	name: null,
	country: null,
	type: null,
	number: null,
	meetingName: null,
	start_datetime: null,
	distance: null,
	lessThan2Min: '',
	status: '',
};

const RaceBottomWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const RaceFlagWrapper = styled.div`
	background-color: ${COLORS.white};
	border-radius: 4px;
	width: 44px;
	height: 44px;
	text-align: center;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		width: 64px;
		height: 64px;
	}
`;

const RaceDateAndTime = styled.p`
	color: ${COLORS.gray[500]};
	font-weight: ${WEIGHTS.medium};
	font-size: ${TYPE_SCALE.paragraph};
	font-family: ${FAMILYS.primary};
`;

const RaceName = styled.h5`
	color: ${COLORS.gray[500]};
	font-weight: ${WEIGHTS.bold};
	font-size: 12px;
	font-family: ${FAMILYS.primary};
	margin: 0;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		color: ${COLORS.gray[500]};
		font-weight: ${WEIGHTS.medium};
		font-size: ${TYPE_SCALE.header6};
		font-family: ${FAMILYS.primary};
		margin: 0;
	}
`;

const RaceMeetingName = styled.h4`
	color: rgb(0,58,145);
	font-weight: ${WEIGHTS.bold};
	font-size: 1rem;
	font-family: ${FAMILYS.primary};
	margin-bottom: 3px;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		color: rgb(0,58,145);
		font-weight: ${WEIGHTS.bold};
		font-size: ${TYPE_SCALE.header4};
		font-family: ${FAMILYS.primary};
		margin-bottom: 6px;
	}
`;

const RaceContentWrapper = styled.div`
	text-align: center;
`;

const RaceNumber = styled.span`
	font-weight: ${WEIGHTS.bold};
	font-size: 1.5rem;
	line-height: 2.2rem;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		line-height: 3.4rem;
		font-size: ${TYPE_SCALE.header1};
	}
`;

const RaceNumberWrapper = styled.div`
	background-color: ${COLORS.red[600]};
	border-radius: 4px;
	color: ${COLORS.white};
	width: 44px;
	height: 44px;
	padding: 0.5rem;
	text-align: center;
	white-space: nowrap;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		width: 64px;
		height: 64px;
	}
`;

const FeaturedContentWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 1rem;
	margin-bottom: -1px;
`;

export default withNamespaces()(RacingFeatureHeader);
