// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { ui, EmptyList } from '@tbh/ui-kit';

import BetSummaryTicketItem from '../BetSummaryTicketItem/BetSummaryTicketItem';

const StyledBetSummaryTicketList = styled(BetSummaryTicketItem)(
	(props) => css`
		label: BetSummaryTicketList;

		border-bottom: 1px solid ${ui(props).color_3};
		background-color: ${ui(props).color_2};

		&:nth-child(even) {
			background-color: ${ui(props).color_1};
		}
	`,
);

const BetSummaryTicketList = (props) => {
	const { className, onClickTicketItem, tickets, t } = props;

	const componentClasses = cx({
		[className]: className,
	});

	if (tickets.length > 0) {
		return tickets.map((ticket) => {
			return (
				<StyledBetSummaryTicketList
					className={componentClasses}
					key={ticket.id}
					available_currency={ticket.available_currency}
					buyin={ticket.tournament.buyin}
					end_date={ticket.tournament.end_date}
					entrants={ticket.tournament.entrants}
					icon={ticket.icon}
					id={ticket.tournament.id}
					name={ticket.tournament.name}
					onClickTicketItem={onClickTicketItem}
					position={ticket.position}
					start_date={ticket.tournament.start_date}
				/>
			);
		});
	} else {
		return <EmptyList message={t('BetSummaryTicketList__NoTickets')} />;
	}
};

BetSummaryTicketList.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** User's tournament tickets */
	tickets: PropTypes.arrayOf(PropTypes.object).isRequired,

	/** Action for when clicking on a ticket */
	onClickTicketItem: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,
};

BetSummaryTicketList.defaultProps = {
	className: '',
};

export default withNamespaces()(BetSummaryTicketList);
