import React, { useEffect, useState } from 'react';
import { Icon, LoadingMask, Text, Ticker, spacings } from '@tbh/ui-kit';
import styled, { css } from 'react-emotion';
import CurrencyDisplay from '../../../components/features/Application/Currency/CurrencyDisplay/CurrencyDisplay';
import { legsByResultString } from '../../../common/ExoticBets';
import numeral from 'numeral';
import { countdown, decimalPlaces } from '../../../legacy/core/format';
import { QUADDIES_BET_TYPES, RACING_EXOTIC_BET_TYPES, SRMTypes } from '../../../common/constants/Racing';

// Styled Components
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: ${({ compact }) => spacings({ compact }).compact}px;
	overflow: auto;
	position: relative;
`;
const Button = styled.button`
	border-radius: 4px;
	border: none;
	padding: 4px 12px;
	font-size: 12px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	${({ isAccept }) =>
		isAccept
			? css`
					background-color: #4caf50;
					color: #fff;

					&:hover {
						background-color: #45a049;
					}
			  `
			: css`
					background-color: #f44336;
					color: #fff;

					&:hover {
						background-color: #d32f2f;
					}
			  `}

	${({ disabled }) =>
		disabled &&
		css`
			background-color: #ddd;
			cursor: not-allowed;
			&:hover {
				background-color: #ddd;
			}
		`}
`;
const MessageContainer = styled.div`
	background: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 8px;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Message = styled.div`
	background: white;
	border-radius: 4px;
	border: 1px solid black;
	padding: 4px 6px;
	width: 100%;
	max-width: 250px;
	color: #850000;
	font-size: 11px;
	text-align: center;
	cursor: pointer;
	&:hover {
		background: #dddd;
	}
`;

const BetInfo = ({ item, bet, children }) => (
	<Box>
		<Text size="-3" align="start" strong>
			R{item.race_number} - {item.competition_name}
		</Text>
		<Text size="-5" align="start" type="secondary" strong>
			{item.bet_type} {bet.product_code === 'GRSF' && '(Fixed)'} {bet.product_code === 'GRST' && '(Tote)'}
		</Text>
		{children}
	</Box>
);

const SelectionList = ({ selections }) =>
	selections.map(({ selection_number, selection_name }, index) => (
		<Text key={selection_number} size="-4" align="start" type="secondary">
			#{selection_number} {selection_name}
		</Text>
	));

const SelectionListLegs = ({ legs }) => {
	return Object.keys(legs).map((key, index) => (
		<Text size="-3" key={key}>
			<span style={{ fontWeight: 600 }}>{numeral(index + 1).format('0o')} :</span>&nbsp;
			<span style={{ fontWeight: 400 }}>{legs[key].join(', ')}</span>
		</Text>
	));
};

const SelectionListSRM = ({ selections }) => {
	return selections.map(({ selection_number, selection_name, product }, index) => (
		<Text size="-3" key={selection_name}>
			<span style={{ fontWeight: 600 }}>{SRMTypes[product]}:</span>
			<span
				className={css`
					flex: 0 0 auto;
				`}
			>
				&nbsp;
			</span>
			<span style={{ fontWeight: 400, marginLeft: 5 }}>
				#{selection_number} {selection_name}
			</span>
		</Text>
	));
};
export const Countdown = ({ betId, time, openNotification, setMessage, showTab }) => {
	// const calculateTime = (time) => new Date(time).getTime() - new Date().getTime();
	// const [sent, setSent] = useState(false);

	// useEffect(() => {
	// 	if (calculateTime(time) <= 0 && !sent) {
	// 		openNotification(`Your Partial Bet [${betId}] has expired`, 'danger');
	// 		setMessage('Your bet has expired.');
	// 		showTab();
	// 		setSent(true);
	// 	}
	// }, [time, sent, betId, openNotification, setMessage, showTab]);

	const c = countdown(time);

	return (
		<div style={{ display: 'flex', gap: '2px', border: '1px solid black', borderRadius: '8px', padding: '4px' }}>
			<Icon icon="clock" size="-2" />
			<Text size="-3" align="center" strong>
				{c}
			</Text>
		</div>
	);
};

const PedingBet = ({
	children,
	bet,
	handleNotificationPending,
	handlePartialBet,
	handleClosePartialBet,
	showTab,
	openNotification,
}) => {
	const [loading, setLoading] = useState(false);
	const [awaitPartialBet, setAwaitPartialBet] = useState(false);
	const [message, setMessage] = useState('');
	const data = bet.response && bet.response[0];
	const status = data && data.status;

	useEffect(() => {
		if (!data) return;
		handleNotificationPending(bet.id, data.status);
		if (data.status === 'ACCEPTED') {
			setMessage('Your bet has been accepted.');
		} else if (data.status === 'REJECTED') {
			setMessage('Your bet has been rejected.');
		}
	}, [status, data, bet.id, handleNotificationPending]);

	const handlePartialAction = async (accept = true) => {
		setLoading(true);
		if (accept) {
			setAwaitPartialBet(true);
			await handlePartialBet(data.betId, data.partialAmount);
			setAwaitPartialBet(false);
		} else {
			await handlePartialBet(data.betId, null, false);
		}
		setLoading(false);
	};

	return (
		<Container
			style={{
				background: data && data.status.toLowerCase() === 'partial' ? '#f2f2f2' : 'white',
				border: data && data.status.toLowerCase() === 'partial' ? '1px solid #850000' : '1px solid #dddd',
				borderRadius: '8px',
			}}
		>
			{data && data.end_time && !awaitPartialBet && (
				<Ticker every={() => 1000}>
					<Countdown
						betId={data.betId}
						time={data.end_time}
						setMessage={setMessage}
						openNotification={openNotification}
						showTab={showTab}
					/>
				</Ticker>
			)}
			{message && (
				<MessageContainer>
					<Message onClick={() => handleClosePartialBet(bet.id, data.status)}>
						{message} <br /> Click to close
					</Message>
				</MessageContainer>
			)}
			<LoadingMask loading={loading} />
			<BetContainer>{children}</BetContainer>

			{status !== 'REJECTED' && status != 'ACCEPTED' && (
				<BetContainer>
					<Button
						style={{
							width: '60%',
						}}
						isAccept
						onClick={handlePartialAction}
						disabled={!(status == 'PARTIAL' || status == 'PENDING') || status === 'INTERCEPTED' || status === 'PROCESSING'  || loading}
					>
						Accept Partial Bet
					</Button>
					<Button
						style={{
							width: '40%',
						}}
						onClick={() => handlePartialAction(false)}
						disabled={status === 'INTERCEPTED' || status === 'PROCESSING' || status === 'REJECTED' || loading}
					>
						Reject
					</Button>
				</BetContainer>
			)}
		</Container>
	);
};

const Box = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

const BetContainer = styled.div`
	padding: ${({ compact }) => spacings({ compact }).compact}px;
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: ${({ tight }) => spacings({ tight }).tight}px;
`;

const Bet = ({
	bet,
	handleNotificationPending,
	handlePartialBet,
	handleClosePartialBet,
	isActive,
	showTab,
	openNotification,
}) => {
	const item = bet.bet_selections[0];
	const data = bet.response && bet.response[0];
	const legs = legsByResultString(bet.selection_string);
	const isMultiLegBetType = QUADDIES_BET_TYPES.includes(bet.bet_type);
	const isExotic = RACING_EXOTIC_BET_TYPES.includes(bet.bet_type);
	const isSRM = bet.bet_type === 'sameracemulti';

	const handleOdds = () => {
		const type = bet.bet_type;

		if (type == 'sameracemulti') {
			return decimalPlaces(bet.display_amount / 100, 2);
		}

		if (type === 'multi') {
			return decimalPlaces(
				bet.bet_selections.reduce((acc, selection) => acc * parseFloat(selection.fixed_odds), 1),
				2,
			);
		}

		if (RACING_EXOTIC_BET_TYPES.includes(type) || QUADDIES_BET_TYPES.includes(type)) {
			return bet.percentage + '%';
		}

		return decimalPlaces(item.fixed_odds, 2);
	};

	return (
		<PedingBet
			bet={bet}
			handleNotificationPending={handleNotificationPending}
			handlePartialBet={handlePartialBet}
			handleClosePartialBet={handleClosePartialBet}
			isActive={isActive}
			showTab={showTab}
			openNotification={openNotification}
		>
			<BetInfo item={item} bet={bet}>
				{(isMultiLegBetType || isExotic) && <SelectionListLegs legs={legs} />}
				{isSRM && <SelectionListSRM selections={bet.bet_selections} />}
				{!isMultiLegBetType && !isExotic && !isSRM && <SelectionList selections={bet.bet_selections} />}
			</BetInfo>
			<Box>
				<Text size="-3" align="center" strong>
				[ID:{bet.id}] Bet <CurrencyDisplay type="AUD" amount={bet.amount} imageWidth={11} />@{handleOdds()}
				</Text>
				<Text size="-4" align="center" type="secondary">
					{data && data.status}
				</Text>
				{data && data.partialAmount > 0 && (
					<Text size="-4" align="center" type="secondary" strong>
						Suggested Partial Amount: <CurrencyDisplay type="AUD" amount={data.partialAmount * 100} imageWidth={11} />
					</Text>
				)}
			</Box>
		</PedingBet>
	);
};

const PendingBetContainer = ({
	isActive,
	bets,
	handleNotificationPending,
	handlePartialBet,
	handleClosePartialBet,
	showTab,
	openNotification,
}) => {
	return (
		<Container
			style={{
				padding: 12,
			}}
		>
			{bets.map((bet) => (
				<Bet
					key={bet.id}
					bet={bet}
					handleNotificationPending={handleNotificationPending}
					handlePartialBet={handlePartialBet}
					handleClosePartialBet={handleClosePartialBet}
					showTab={showTab}
					openNotification={openNotification}
				/>
			))}
		</Container>
	);
};

export default PendingBetContainer;
