import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PUSHER_EVENT_UPDATE } from '../../../../common/constants/Racing';
import { updatePrices } from '../../../../store/entities/actions/PriceActions';
import { mergeRace, mergeDerivativeSelection, mergeSelection } from '../../../../store/entities/actions/RaceActions';

import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';

/**
 * Pusher Subscriber Container for Race.
 * Channel: race_<raceId>
 */

class PusherSubscriberRaceItemChannel extends Component {
	static propTypes = {
		/** The race ID to update */
		raceId: PropTypes.number.isRequired,

		/** Action to update the selected race */
		updateRace: PropTypes.func,

		/** Action to update the prices in the race */
		updatePrices: PropTypes.func,

		/** Action to update the prices for a derivative selection */
		updateDerivativeSelection: PropTypes.func,
	};

	static defaultProps = {
		updateRace: () => {},
		updatePrices: () => {},
		updateDerivativeSelection: () => {},
	};

	/**
	 * Handles pushed data to update race
	 * @param data
	 */
	handlePusherRaceUpdate = (data) => {
		this.props.updateRace(data);
	};

	render() {
		const { raceId } = this.props;

		// Determine channel for all subscriptions
		const channel = `race_${raceId}`;
		return (
			<Fragment>
				<PusherSubscribe
					key={PUSHER_EVENT_UPDATE}
					channel={channel}
					event={PUSHER_EVENT_UPDATE}
					onUpdate={this.handlePusherRaceUpdate}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
	updateRace: (race) => dispatch(mergeRace(race)),
	updatePrices: (prices) => dispatch(updatePrices(prices)),
	updateSelection: (selection) => dispatch(mergeSelection(selection)),
	updateDerivativeSelection: (selection) => dispatch(mergeDerivativeSelection(selection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberRaceItemChannel);
