import React from 'react';
import cx from 'classnames/bind';
import { css } from 'react-emotion';

import {
	StyledCarouselItemV3_header_content,
	StyledCarouselItemV4,
	StyledCarouselItemV3_hedar_left_content,
	StyledCarouselItemV3_right_hedear_content,
	StyledLoading,
} from './CarouselItemV4.styled';

const CarouselItemV4Loading = (props) => {
	const carouselItemClass = cx({
		['className']: carouselItemClass,
	});

	return (
		<StyledCarouselItemV4 size={'0'} className={carouselItemClass} action={() => {}} border padding>
			<StyledCarouselItemV3_header_content>
				<StyledCarouselItemV3_hedar_left_content>
					<div
						className={css`
							flex: 0 0 20%;
							max-width: 20%;
							margin-right: 5px;
						`}
					>
						<StyledLoading height="15px" />
						<StyledLoading height="15px" />
					</div>
					<div
						className={css`
							flex: 0 0 80%;
							max-width: 80%;
						`}
					>
						<StyledLoading height="10px" />
						<StyledLoading height="10px" />
					</div>
				</StyledCarouselItemV3_hedar_left_content>
				<StyledCarouselItemV3_right_hedear_content>
					<StyledLoading height="25px" width="25px" radius={'9999px'} />
				</StyledCarouselItemV3_right_hedear_content>
			</StyledCarouselItemV3_header_content>
		</StyledCarouselItemV4>
	);
};
export default CarouselItemV4Loading;
