import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { navigateToNextToJumpRaceWithType } from '../../store/GRSracingHome/GRSracingHomeActions';

import RaceNextJumpV3 from '../../components/features/Racing/RaceNextJumpV3/RaceNextJumpV3'; // create new @HW

import { Ticker } from '@tbh/ui-kit';
import { trackNextToJump } from '../../store/GRSracingHome/GRSracingTrackingActions';


import {
	buildSelectionBetButtons,
	getNextToJumpRaces,
} from '../../pages/GRSNewRacing/RacingNewHome/racingSelectorsGRS';
// import PusherSubscriberNTJRacesChannelV3 from '../../components/features/Racing/PusherSubscribers/PusherSubscriberNTJRacesChannelV3'; //@HW add new pusher


class NewNextToJumpRacesContainer extends React.Component {
	static propTypes = {
		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			//height: PropTypes.number,
		}).isRequired,
		
		items: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
				products: PropTypes.array,
				meeting_name: PropTypes.string,
				race_name: PropTypes.string,
				state: PropTypes.string,
				start_datetime: PropTypes.string,
				selection: PropTypes.array,
				distance: PropTypes.string,

			}),
		).isRequired,
		isNJRacingHome: PropTypes.bool,
		trackOnFilterClick: PropTypes.func,
		className: PropTypes.string,
		hideRaceNextJumpFilter: PropTypes.bool,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,

		/** Navigates to the selected race page */
		navigateToRaceWithType: PropTypes.func.isRequired,

		selectedPage: PropTypes.string.isRequired,

	};


	static defaultProps = {
		isNJRacingHome: false,
		trackOnFilterClick: () => undefined,
		className: '',
		hideRaceNextJumpFilter: false,
	};

	/**
	 * Tracking event used the carousel filter
	 * @param {string} type - racing type
	 */
	trackOnFilterClick = (type) => {
		this.props.trackOnFilterClick(type, this.props.isNJRacingHome);
	};

	/**
	 * Navigates to the selected race
	 *
	 * @param raceId
	 * @param meetingId
	 */
	handleGoToRace = (type,date,raceId, meetingId) => {
		this.props.navigateToRaceWithType(type,date,raceId, meetingId);
	};
	render() {
		const {
			buildSelectionBetButtons,
			size,
		} = this.props;
		return (
			<React.Fragment>
				 {/* <PusherSubscriberNTJRacesChannelV3 /> */}
				
				<Ticker every={90000}>
					<RaceNextJumpV3
						className={this.props.className}
						items={this.props.items}
						onGoToRace={this.handleGoToRace}
						trackOnFilterClick={this.trackOnFilterClick}
						hideRaceNextJumpFilter={this.props.hideRaceNextJumpFilter}
						buildSelectionBetButtons={buildSelectionBetButtons}
						size={size}
					/>
				</Ticker>
			</React.Fragment>
		);
					}
}

const mapStateToProps = (state) => {
	return {
		items: getNextToJumpRaces(state),
		selectedPage:state.application.selectedPage,
	};
};

const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type,date,raceId, meetingId, isNJRacingHome) => {
		dispatch(trackNextToJump('Click', null, isNJRacingHome));
		dispatch(navigateToNextToJumpRaceWithType(type,date,raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
	buildSelectionBetButtons: (prices, displayedBetProducts, betType) =>
		dispatch(buildSelectionBetButtons(prices, displayedBetProducts, betType)),
	
	trackOnFilterClick: (type, isNJRacingHome) => dispatch(trackNextToJump('Filter', type, isNJRacingHome)),	
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(NewNextToJumpRacesContainer);
