import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';


export const StyledRacingV5HomePage = styled('div')`
	${(props) =>
  css`
			label: RacingV5HomePage;
			margin: ${spacings(props).compact}px;
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: stretch;
			align-content: stretch;
			background-color:inherit;
			width:100%;
			height:auto;
			padding-left:5%;
			padding-right:5%;
			margin-bottom:2%;
		`};
`;

export const StyledRacingV5HomePage_LeftMenu = styled('div')`
	${(props) =>
  css`
			label: RacingV5HomePage_LeftMenu ;
      width:23%;
      height:30px;
      background-color:${props.backGround_color};
     border-radius:5px;
     -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
     box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
		`};
`;

export const StyledRacingV5HomePage_Main_Container = styled('div')`
	${(props) =>
  css`
			label: RacingV5HomePage_Main_Container ;
      width:50%;
      border-radius:5px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
		`};
`;

export const StyledRacingV5HomePage_Main_Container_TopMenu = styled('div')`
	${(props) =>
  css`
			label: RacingV5HomePage_Main_Container_TopMenu;
      width:100%;
      height:100px;
      border-radius:5px;
      background-color:inherit;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      background-color:${props.backGround_color};
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
		`};
`;

export const StyledRacingV5HomePage_Main_Container_MainContent = styled('div')`
	${(props) =>
  css`
			label: RacingV5HomePage_Main_Container_MainContent;
      width:100%;
      min-height:300px;
      border-radius:5px;
      background-color:inherit;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      background-color:${props.backGround_color};
      margin-top:15px;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
		`};
`;

export const StyledRacingV5HomePage_Bet_Container = styled('div')`
	${(props) =>
  css`
			label: RacingV5HomePage_Main_Container ;
      width:23%;
      height:30px;
      border-radius:5px;
      background-color:${props.backGround_color};
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.37);
		`};
`;
