// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { throttle } from 'lodash-es';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { application, spacings, ui } from '@tbh/ui-kit';

// Actions
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import {
	validateRegistrationForm,
	sendSmsCode,
	verifySmsCode,
	createAccount,
	createAccountFromShortForm,
	clearRedirectRoute,
	navigateToStep,
	setRegistrationStep,
	verifyEmailCode,
	sendEmailCode,
	setLocalUser,
} from '../../../store/registration/registrationActions';
import { openLoginForm } from '../../../store/authentication/authenticationActions';
import {
	routeTo,
	scrollToTop,
	hideMastheadFields,
	showNavigation,
	hideNavigation,
	showDeposits,
} from '../../../store/application/applicationActions';
import { setDepositLimit } from '../../../store/depositLimits/depositLimitsActions';
import { setLossLimit } from '../../../store/lossLimits/lossLimitsActions';
import { trackGaEvent, trackBrazeEvent, trackGtmEvent } from '../../../store/trackingPixels/trackingActions';

// Components
import { Notification } from '@tbh/ui-kit';
import Route from '../../../components/controllers/Route/Route';
import RegistrationHeader from '../../../components/features/Authentication/Registration/RegistrationHeader/RegistrationHeader';
import UserDetailsForm from '../../../components/features/Authentication/Registration/UserDetailsForm/UserDetailsForm';
import UserAddressForm from '../../../components/features/Authentication/Registration/UserAddressForm/UserAddressForm';
import MobileAuthenticationForm from '../../../components/features/Authentication/Registration/MobileAuthenticationForm/MobileAuthenticationForm';
import MobileVerificationForm from '../../../components/features/Authentication/Registration/MobileVerificationForm/MobileVerificationForm';
import RegistrationConfirmation from '../../../components/features/Authentication/Registration/RegistrationConfirmation/RegistrationConfirmation';
import FirstDepositContainer from '../../../containers/Authentication/FirstDeposit/FirstDepositContainer';
import ModalFooter from '../../../components/features/Application/ModalFooter/ModalFooter';
import SimpleRegistration from '../../../components/features/Authentication/Registration/SimpleRegistration/SimpleRegistration';
import { postPrefixedMessage } from '../../../common/actions/widgetActions';
// Constants
import {
	USER_DETAILS_FORM,
	USER_ADDRESS_FORM,
	MOBILE_AUTHENTICATION,
	MOBILE_VERIFICATION,
	REGISTRATION_CONFIRMATION,
	GREENID_VERIFICATION,
	FIRST_DEPOSIT,
	SET_LIMITS,
	EMAIL_AUTHENTICATION,
} from '../../../components/features/Authentication/Registration/StepsContants';
import { GENERIC_ERROR_MESSAGE } from '../../../common/constants/Notifications';
import { REGISTRATION_BASE_URL } from './RegistrationPageConstants';
import LastStep from '../../../components/features/Authentication/Registration/LastStep';
import EmailAuthentication from '../../../components/features/Authentication/Registration/EmailAuthentication/EmailAuthentication';
import VerificationFormV2 from '../../../containers/UserAccountV2/ContentUserAccount/Verifications/VerificationFormV2';
import MobileOPTForm from '../../../components/features/Authentication/Registration/MobileOPTForm/MobileOPTForm';

const StyledRegistrationPage = styled('div')(
	(props) => css`
		label: RegistrationPage;

		background-color: ${ui(props).color_2};
		border-radius: 4px;
		margin: 0 auto;
		${!props.isFullWidth &&
			css`
				// max-width: ${application(props).max}px;
			`};
	`,
);

const StyledRegistrationPage__Wrapper = styled('div')(
	(props) => css`
		label: RegistrationPage__Wrapper;

		min-height: 300px;
		${!props.isGreenId && 'max-width: 584px;'}
		padding: ${spacings(props).cozy}px;
		margin: 0 auto;
	`,
);

const StyledRegistrationPage__Error = styled(Notification)(
	(props) => css`
		label: RegistrationPage__Error;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledRegistrationPage__Confirmation = styled(RegistrationConfirmation)(
	(props) => css`
		label: RegistrationPage__Confirmation;

		margin: ${spacings(props).comfortable}px 0;
	`,
);

class RegistrationPage extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Creates a new TopBetta account */
		createAccount: PropTypes.func.isRequired,

		/** Navigates to a specific step in the registration process */
		navigateToStep: PropTypes.func.isRequired,

		/** Closes the registration page */
		onClose: PropTypes.func.isRequired,

		/** Scrolls the page to the top */
		scrollToTop: PropTypes.func.isRequired,

		/** Sends a SMS code to the provided mobile number */
		sendSmsCode: PropTypes.func.isRequired,

		/** Sets a deposit limit for the user */
		setDepositLimit: PropTypes.func.isRequired,

		/** Sets a loss limit for the user */
		setLossLimit: PropTypes.func.isRequired,

		/** Opens the Login form */
		signIn: PropTypes.func.isRequired,

		/** GA tracking */
		trackGaEvent: PropTypes.func.isRequired,

		/** Tracking */
		trackEvent: PropTypes.func.isRequired,

		/** GTM Tracking */
		trackGtmEvent: PropTypes.func.isRequired,

		/** Braze Tracking */
		trackBrazeEvent: PropTypes.func.isRequired,

		/** Back end validation for the registration form fields */
		validateRegistrationForm: PropTypes.func.isRequired,

		/** Back end verification of the SMS code sent to the mobile number */
		verifySmsCode: PropTypes.func.isRequired,

		/** Hides the Masthead fields and sends GA tracking when the registration page opens */
		startRegistration: PropTypes.func.isRequired,

		/** Shows the Masthead fields */
		resetMasthead: PropTypes.func.isRequired,

		/** Whether or not there is a max-width restriction on the site */
		isFullWidth: PropTypes.bool.isRequired,

		/** Function to mark that the container has stopped loading - NOTE: automatically passed in by a wrapper */
		setContainerHasLoaded: PropTypes.func,

		/** Current registration step */
		currentRoute: PropTypes.string,

		/** Email can be pre-populated from the masthead or Facebook */
		email: PropTypes.string,

		/** First and Last name can be pre-populated if Facebook was used to register */
		first_name: PropTypes.string,
		last_name: PropTypes.string,

		/** Route that the user should be redirect to when the page is closed */
		redirectRoute: PropTypes.string,

		/** If the Deposits and Withdrawals feature is enabled or not */
		isDepositsAndWithdrawalsEnabled: PropTypes.bool,

		/** Handles the registration of a new user using a short form */
		onShortRegistration: PropTypes.func,

		/** If the registration should be a simple form or not */
		simpleRegistration: PropTypes.bool,

		/** If the registration should have mobile verification */
		mobileVerification: PropTypes.bool,

		/** If the registration should have address verification */
		addressVerification: PropTypes.bool,

		/** Should the first deposit screen show on registration */
		firstDepositOnSignup: PropTypes.bool,

		/** Link for the terms and conditions */
		termsAndConditionsLink: PropTypes.string,

		/** Show routing and meta components from the page */
		useRouting: PropTypes.bool,
	};

	static defaultProps = {
		currentRoute: 'step-1',
		email: '',
		first_name: '',
		last_name: '',
		isDepositsAndWithdrawalsEnabled: true,
		onShortRegistration: null,
		redirectRoute: null,
		setContainerHasLoaded: null,
		simpleRegistration: false,
		mobileVerification: true,
		addressVerification: true,
		firstDepositOnSignup: false,
		termsAndConditionsLink: '',
		termsAndConditions: '',
		useRouting: true,
	};

	state = {
		step: USER_DETAILS_FORM,
		error: '',

		// User Details Form
		email: '',
		email_code: '',
		email_code_error: null,
		email_valid: false,
		password: '',
		username: '',
		dob_day: '',
		dob_month: '',
		dob_year: '',
		email_error: null,
		password_error: null,
		username_error: null,
		date_of_birth: '',
		date_of_birth_error: null,
		details_form_is_valid: false,

		// Address Form
		first_name: this.props.first_name,
		last_name: this.props.last_name,
		street: '',
		street_number: '',
		street_type: '',
		unit_type: '',
		unit_number: '',
		city: '',
		country: 'Australia',
		state: 'NSW',
		postcode: '',
		first_name_error: null,
		last_name_error: null,
		street_error: null,
		street_number_error: null,
		street_type_error: null,
		unit_type_error: null,
		unit_number_error: null,
		city_error: null,
		country_error: null,
		state_error: null,
		postcode_error: null,
		address_form_is_valid: false,
		deposit_limit: '',
		loss_limit: '',

		// Mobile Authentication Form
		mobile_number: '',
		mobile_is_valid: false,
		mobile_number_error: null,
		loadingSendSms: false,

		// Mobile Verification Form
		loadingCreateAccount: false,
		loadingVerifyMobile: false,
		verified_mobile: false,
		verify_msisdn: null,

		marketing_opt_in_flag: 1,
		loading: null,
	};

	/**
	 * Checks the registration route to display the right content
	 *
	 * @param nextProps
	 * @param prevState
	 */
	static getDerivedStateFromProps(nextProps, prevState) {
		const lastRoute = prevState.currentRoute;
		const newRoute = nextProps.currentRoute;

		if (!nextProps.simpleRegistration) {
			let step = MOBILE_AUTHENTICATION;
			if (newRoute === 'step-2') {
				return {
					step: MOBILE_VERIFICATION,
					//verified_mobile: false,
					currentRoute: newRoute,
				};
			} else if (newRoute === 'step-3') {
				return {
					step: EMAIL_AUTHENTICATION,
					currentRoute: newRoute,
				};
			} else if (newRoute === 'step-4') {
				return {
					step: USER_DETAILS_FORM,
					currentRoute: newRoute,
				};
			} else if (newRoute === 'step-5') {
				return {
					step: USER_ADDRESS_FORM,
					currentRoute: newRoute,
				};
			} else if (newRoute === 'step-6') {
				return {
					step: GREENID_VERIFICATION,
					currentRoute: newRoute,
				};
			} else if (prevState.details_form_is_valid && prevState.address_form_is_valid) {
				return {
					step: REGISTRATION_CONFIRMATION,
					currentRoute: newRoute,
				};
			} else if (newRoute === 'registration-confirmation') {
				return {
					step: REGISTRATION_CONFIRMATION,
					currentRoute: newRoute,
				};
			} else if (newRoute === 'set-limits') {
				return {
					step: SET_LIMITS,
					currentRoute: newRoute,
				};
			}
			/*
	  else if (
		newRoute === 'first-deposit' &&
		prevState.details_form_is_valid &&
		prevState.address_form_is_valid &&
		(prevState.verified_mobile || !nextProps.mobileVerification)
	  ) {
		step = FIRST_DEPOSIT;
	  } else if (lastRoute === 'first-deposit') {
		return {
		  currentRoute: newRoute,
		};
	  }
	   */
			return {
				step,
				currentRoute: newRoute,
			};
		}
		if (lastRoute !== newRoute) {
			return {
				currentRoute: newRoute,
			};
		}
		return null;
	}
	componentDidMount() {
		const {
			email,
			navigateToStep,
			setContainerHasLoaded,
			simpleRegistration,
			startRegistration,
			trackGaEvent,
			trackBrazeEvent,
			useRouting,
			validateRegistrationForm,
			localUser,
			currentRoute,
		} = this.props;

		// if there a local user in state, then he should be redirected to greenId form
		if (localUser && currentRoute === 'step-6') {
			return navigateToStep('step-6', useRouting);
		}

		// Start the registration process
		if (useRouting) {
			navigateToStep('step-1', useRouting);
			startRegistration();
		}
		trackBrazeEvent('Registration Step 1');
		trackGaEvent('Register', 'Status', 'Start');

		if (!simpleRegistration && email) {
			validateRegistrationForm({ email: email }).then((response) => {
				if (response.data.data.email) {
					this.setState({
						email_error: response.data.data.email[0],
					});
				}
			});
		}

		if (setContainerHasLoaded) {
			setContainerHasLoaded();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.currentRoute === 'first-deposit' && this.props.useRouting) {
			this.props.onClose(this.props.redirectRoute);
		}
	}

	componentWillUnmount() {
		if (this.props.useRouting) {
			this.props.resetMasthead();
		}
	}

	/**
	 * Sets the registration step and updates the route
	 *
	 * @param step
	 */
	goToStep = (step) => {
		const {
			isDepositsAndWithdrawalsEnabled,
			navigateToStep,
			onClose,
			redirectRoute,
			simpleRegistration,
			firstDepositOnSignup,
			useRouting,
		} = this.props;
		this.clearMessage();
		this.setState({
			error: null,
			step: step,
		});

		// Can't go to the next step if the form is invalid
		if (this.state.error_blacklist) {
			return;
		}

		switch (step) {
			// Step 1
			case MOBILE_AUTHENTICATION:
				navigateToStep('step-1', useRouting);
				this.props.trackBrazeEvent('Registration Step 1');
				break;

			// Step 2
			case MOBILE_VERIFICATION:
				navigateToStep('step-2', useRouting);
				this.props.trackBrazeEvent('Registration Step 2');
				break;

			//	Step 3
			// case GREENID_VERIFICATION:
			// 	navigateToStep('step-5', useRouting);
			// 	this.props.trackBrazeEvent('Registration Step 5');
			// 	break;

			// Step 3 - if mobileVerification true
			case EMAIL_AUTHENTICATION:
				navigateToStep('step-3', useRouting);
				this.props.trackBrazeEvent('Registration Step 3');
				break;

			// Step 3 - if mobileVerification true
			case USER_DETAILS_FORM:
				navigateToStep('step-4', useRouting);
				this.props.trackBrazeEvent('Registration Step 4');
				break;

			// Step 4
			case USER_ADDRESS_FORM:
				navigateToStep('step-5', useRouting);
				this.props.trackBrazeEvent('Registration Step 5');
				break;
			case GREENID_VERIFICATION:
				navigateToStep('step-6', useRouting);
				this.props.trackBrazeEvent('Registration Step 6');
				break;
			case SET_LIMITS:
				navigateToStep('set-limits', useRouting);
				break;
			case REGISTRATION_CONFIRMATION:
				navigateToStep('registration-confirmation', useRouting);
				break;
			default:
				navigateToStep('step-1', useRouting);
				this.props.trackBrazeEvent('Registration Step 1');
				break;
		}
	};

	validateDetailsFormThrottled = throttle(() => this.validateDetailsForm(), 500);
	validateAddressFormThrottled = throttle(() => this.validateAddressForm(), 500);

	/**
	 * Validates the User Details
	 */
	validateDetailsForm = () => {
		// check if the date of birth is complete
		const dob = this.state.date_of_birth || '';

		const dobComplete = dob.indexOf('_') === -1;
		const object = {
			email: this.state.email,
			password: this.state.password,
			//username: this.state.username,
			dob_day: dobComplete ? this.state.dob_day : '',
			dob_month: dobComplete ? this.state.dob_month : '',
			dob_year: dobComplete ? this.state.dob_year : '',
		};
		const time = this.isLoading();
		this.props
			.validateRegistrationForm(object)
			.then((response) => {
				const errors = response.data.data;
				const blacklist = errors.blacklist;

				let date_of_birth_error = '';
				if (dobComplete && object.dob_year && object.dob_month && object.dob_day) {
					let moreThan100YearsOld = Number(this.state.dob_year) <= 1901;
					if (errors.dob_day || errors.dob_month || errors.dob_year || errors.dob) {
						date_of_birth_error = this.props.t('RegistrationPage__AdultsOnly');
					} else if (moreThan100YearsOld) {
						date_of_birth_error = 'This date is not available for registration.';
					}
				}

				const details_form_is_valid = this.props.simpleRegistration
					? !errors.duplicated && !errors.email && !errors.password && !errors.country
					: !errors.duplicated &&
					  !errors.email &&
					  !errors.password &&
					  //!errors.username &&
					  !errors.dob_day &&
					  !errors.dob_month &&
					  !errors.dob_year &&
					  !errors.dob;

				// Set errors for each input if there are errors
				this.setState(
					{
						error: errors.duplicated ? errors.duplicated[0] : null,
						email_error: object.email && errors.email ? errors.email[0] : null,
						password_error: object.password && errors.password ? errors.password[0] : null,
						//username_error: object.username && errors.username ? errors.username[0] : null,
						date_of_birth_error: date_of_birth_error ? date_of_birth_error : null,

						// if all inputs are valid, set the details form as valid
						details_form_is_valid: details_form_is_valid,
						email_valid: !(object.email && errors.email),
					},
					() => {
						// if the form is valid, save the form
						if (!this.state.details_form_is_valid) {
							this.props.trackGaEvent('Registration', 'validation:failed', 'create-account');
						}
					},
				);

				if (blacklist) {
					this.setState({
						error: blacklist[0],
						error_blacklist: blacklist[0],
					});
				} else {
					this.setState({
						error_blacklist: null,
					});
				}
			})
			.catch((error) => {
				this.setState({
					error:
						error.response.data && error.response.data.errors
							? error.response.data.errors
							: this.props.t(GENERIC_ERROR_MESSAGE),
					details_form_is_valid: false,
				});
			})
			.catch(() => {
				this.setState({
					error: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			})
			.finally(() => {
				this.removeLoading(time);
			});
	};

	/**
	 * Validates the Address
	 *
	 * @param object
	 */
	validateAddressForm = () => {
		const time = this.isLoading();
		const object = {
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			street: this.state.street,
			street_number: this.state.street_number,
			street_type: this.state.street_type,
			unit_type: this.state.unit_type,
			unit_number: this.state.unit_number,
			city: this.state.city,
			country: this.state.country,
			state: this.state.state,
			postcode: this.state.postcode,
		};

		this.props
			.validateRegistrationForm({
				...object,
				street_number: object.street_number,
			})
			.then((response) => {
				let errors = response.data.data;

				// Set errors for each input if there are errors
				this.setState(
					{
						first_name_error: object.first_name && errors.first_name && errors.first_name[0],
						last_name_error: object.last_name && errors.last_name && errors.last_name[0],
						street_error: object.street && errors.street && errors.street[0],
						street_number_error: object.street_number && errors.street_number && errors.street_number[0],
						street_type_error: object.street_type && errors.street_type && errors.street_type[0],
						unit_type_error: object.unit_type && errors.unit_type && errors.unit_type[0],
						unit_number_error: object.unit_number && errors.unit_number && errors.unit_number[0],
						city_error: object.city && errors.city && errors.city[0],
						country_error: object.country && errors.country && errors.country[0],
						state_error: object.state && errors.state && errors.state[0],
						postcode_error: object.postcode && errors.postcode && errors.postcode[0],

						// if all inputs are valid, set the details form as valid
						address_form_is_valid:
							!errors.first_name &&
							!errors.last_name &&
							!errors.street &&
							!errors.street_number &&
							!errors.street_type &&
							!errors.city &&
							!errors.country &&
							!errors.state &&
							!errors.postcode &&
							object.postcode.length >= 4,
					},
					() => {
						// if the form is valid, save the form
						if (!this.state.address_form_is_valid) {
							this.props.trackGaEvent('Registration', 'validation:failed', 'create-account');
						}
					},
				);
			})
			.catch((error) => {
				this.setState({
					error:
						error.response.data && error.response.data.errors
							? error.response.data.errors
							: this.props.t(GENERIC_ERROR_MESSAGE),
					details_form_is_valid: false,
				});
			})
			.catch(() => {
				this.setState({
					error: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			})
			.finally(() => {
				this.removeLoading(time);
			});
	};

	/**
	 * Saves in state the deposit and loss limits
	 *
	 * @param deposit_limit
	 * @param loss_limit
	 */
	saveLimits = (deposit_limit, loss_limit) => {
		this.setState({
			deposit_limit: deposit_limit,
			loss_limit: loss_limit,
		});
	};

	/**
	 * Validates the mobile number
	 *
	 * @param mobile_number
	 * @param valid
	 */
	validateMobileNumber = (mobile_number, valid) => {
		this.props.validateRegistrationForm({ msisdn: mobile_number }).then((response) => {
			let mobile_number_error = response.data.data.msisdn;
			const errors = response.data.data;
			const blacklist = errors.blacklist;

			this.setState({
				mobile_number: mobile_number,
				mobile_is_valid: valid,
				mobile_number_error: null,
				error: blacklist ? blacklist[0] : null,
				error_blacklist: blacklist ? blacklist[0] : null,
				//mobile_number_error: mobile_number_error ? mobile_number_error[0] : null,
			});
		});
	};

	/**
	 * Validates the mobile number
	 * Sends SMS code to the provided mobile number
	 *
	 * @param mobile_number
	 */
	sendSmsCode = async (mobile_number) => {
		this.setLoadingMask('loadingSendSms', true);

		try {
			await sendSmsCode(mobile_number);
			this.goToStep(MOBILE_VERIFICATION);
			this.props.scrollToTop();
			this.props.trackEvent('Registration', 'request:verification');
		} catch (e) {
			//console.log(e);
			if (e.response && e.response.data && e.response.data.errors) {
				this.setState({
					error: e.response.data.errors,
				});
			} else {
				this.setState({
					error: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			}
		}
		this.setLoadingMask('loadingSendSms', false);
	};

	/**
	 * Verifies the SMS code sent to the mobile number
	 *
	 * @param code
	 */
	verifyMobile = async (code) => {
		this.setLoadingMask('loadingVerifyMobile', true);
		this.props.trackEvent('Registration', 'send:verification');

		try {
			await verifySmsCode(this.state.mobile_number, code);
			this.setState({
				error: null,
				verified_mobile: true,
				verify_msisdn: code,
			});

			this.goToStep(EMAIL_AUTHENTICATION);
			this.props.trackEvent('Registration', 'successful:verification');
			this.props.scrollToTop();
		} catch (e) {
			//console.log(e);
			if (e.response && e.response.data && e.response.data.errors) {
				this.setState({
					error: e.response.data.errors,
				});
			} else {
				this.setState({
					error: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			}
		}
		this.setLoadingMask('loadingVerifyMobile', false);
	};
	/**
	 * Creates a new account
	 * Then sets the weekly deposit and loss limits if there are limits
	 */
	createAccount = () => {
		let payload = {
			// User's Details
			email: this.state.email,
			password: this.state.password,
			username: this.state.email.replace(/[^a-zA-Z0-9 ]/g, ''),
			dob: this.state.dob_day + '/' + this.state.dob_month + '/' + this.state.dob_year,
			dob_day: this.state.dob_day,
			dob_month: this.state.dob_month,
			dob_year: this.state.dob_year,

			// Address
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			title: 'Mr',
			street: this.state.street,
			street_number: this.state.street_number,
			street_type: this.state.street_type,
			unit_type: this.state.unit_type,
			unit_number: this.state.unit_number,
			city: this.state.city,
			country: this.state.country,
			state: this.state.state,
			postcode: this.state.postcode,

			// Mobile
			msisdn: this.state.mobile_number,
			verify_msisdn: this.state.verify_msisdn,
			marketing_opt_in_flag: this.state.marketing_opt_in_flag, // checkbox for the Terms of Service
		};

		let errorArray = [];

		if (this.state.error) {
			errorArray = [this.state.error];
		}

		this.setLoadingMask('loadingCreateAccount', true);

		// Creates account
		this.props
			.createAccount(payload)
			.then((response) => {
				this.props.trackGtmEvent('successfulRegistration');
				this.props.trackEvent('Registration', 'successful:registration');
				this.props.trackEvent('Registration', 'Status', 'End');

				// Sets the limits
				// if (this.state.deposit_limit || this.state.loss_limit) {
				// 	this.setLimits(response.data.data.id);
				// }

				this.props.scrollToTop();
				this.props.setLocalUser(response.data.data);

				// Goes to the confirmation screen
				// document.greenIdInit = false;
				this.goToStep(GREENID_VERIFICATION);
			})
			.catch((error) => {
				if (error.response.data && error.response.data.errors) {
					let errors = error.response.data.errors;
					let firstError = errors[Object.keys(errors)[0]];
					errorArray = firstError;

					this.setState({
						error: errorArray,
					});
				} else {
					errorArray.push(this.props.t(GENERIC_ERROR_MESSAGE));
					this.setState({
						error: errorArray,
					});
				}
			})
			.catch(() => {
				this.setState({
					error: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			})
			.finally(() => {
				this.setLoadingMask('loadingCreateAccount', false);
			});
	};

	/**
	 * Sets the weekly deposit and loss limits
	 *
	 * @param id
	 */
	setLimits = (id) => {
		let errorArray = [];

		if (this.state.deposit_limit) {
			this.props
				.setDepositLimit(id, this.state.deposit_limit, 7)
				.catch((error) => {
					if (error.response.data && error.response.data.errors) {
						errorArray.concat(error.response.data.errors);
						this.setState({
							error: errorArray,
						});
					} else {
						errorArray.push(this.props.t('RegistrationPage__DepositLimitError'));
						this.setState({
							error: errorArray,
						});
					}
					return errorArray;
				})
				.catch(() => {
					this.setState({
						error: this.props.t(GENERIC_ERROR_MESSAGE),
					});
				});
		}

		if (this.state.loss_limit) {
			this.props
				.setLossLimit(this.state.loss_limit, 7)
				.catch((error) => {
					if (error.response.data && error.response.data.errors) {
						errorArray.concat(error.response.data.errors);
						this.setState({
							error: errorArray,
						});
					} else {
						errorArray.push(this.props.t('RegistrationPage__BettingLimitError'));
						this.setState({
							error: errorArray,
						});
					}
					return errorArray;
				})
				.catch(() => {
					this.setState({
						error: this.props.t(GENERIC_ERROR_MESSAGE),
					});
				});
		}
	};

	/**
	 * Create a new account using the user details from a simple form
	 *
	 * @param user
	 */
	handleSimpleRegistration = (user) => {
		this.setLoadingMask('loadingCreateAccount', true);

		// Creates account
		this.props
			.onShortRegistration(user)
			.then(() => {
				this.props.trackEvent('Registration', 'successful:registration');
				this.props.trackEvent('Registration', 'Status', 'End');

				this.props.scrollToTop();

				// Goes to the confirmation screen
				this.goToStep(SET_LIMITS);
			})
			.catch((error) => {
				this.setState({
					error:
						error.response.data && error.response.data.errors
							? error.response.data.errors
							: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			})
			.catch(() => {
				this.setState({
					error: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			})
			.finally(() => {
				this.setLoadingMask('loadingCreateAccount', false);
			});
	};

	/**
	 * Sets a loading mask
	 *
	 * @param location: where in the Registration the loading mask should be displayed
	 * @param loading: true or false
	 */
	setLoadingMask = (location, loading = false) => {
		this.setState({
			[location]: loading,
		});
	};

	/**
	 * Removes the error message
	 */
	clearMessage = () => {
		this.setState({
			error: null,
		});
	};

	/**
	 * Closes the page and return to previous route
	 */
	onClose = () => {
		if (this.props.useRouting) {
			this.props.onClose(this.props.redirectRoute);
		}
	};

	/**
	 * Opens the Login form and send the redirect route
	 */
	handleSignIn = () => {
		this.props.signIn(this.props.redirectRoute);
	};

	onBulkChange = (newState = {}) => {
		this.setState(newState, () => {
			if (this.state.step === USER_DETAILS_FORM) {
				this.validateDetailsFormThrottled();
			} else if (this.state.step === USER_ADDRESS_FORM) {
				this.validateAddressFormThrottled();
			}
		});
	};

	onChange = (name, value, valid, validationRoutine) => {
		this.setState(
			{
				[name]: value,
				[`${name}_valid`]: valid,
			},
			() => {
				if (validationRoutine) {
					validationRoutine();
				} else {
					this.onBulkChange();
				}
			},
		);
	};

	isLoading = () => {
		const currentDate = new Date().getTime();

		this.setState({ loading: currentDate });

		return currentDate;
	};

	removeLoading = (time) => {
		const { loading } = this.state;
		if (loading == time) this.setState({ loading: null });
	};

	sendEmail = async () => {
		const time = this.isLoading();
		try {
			await sendEmailCode(this.state.email);

			this.setState({
				email_was_sent: true,
				errors: null,
			});
			// trackEvent('Registration', 'successful:verification');
		} catch (e) {
			//console.log(e);
			if (e.response && e.response.data && e.response.data.errors) {
				if (e.response.data.errors.includes('Already sent an OTP to your email.')) {
					this.setState({
						email_was_sent: true,
					});
				}
				this.setState({
					error: e.response.data.errors,
				});
			} else {
				this.setState({
					error: this.props.t(GENERIC_ERROR_MESSAGE),
				});
			}
		}
		this.removeLoading(time);
	};

	validateEmail = async (email_code) => {
		const time = this.isLoading();
		try {
			await verifyEmailCode(this.state.email, email_code);

			this.setState({
				email_code,
				verify_email: true,
				errors: null,
			});

			this.goToStep(USER_DETAILS_FORM);
			// trackEvent('Registration', 'successful:verification');
		} catch (e) {
			//console.log(e);
			if (e.response && e.response.data && e.response.data.errors) {
				if (e.response.data.errors.includes('Already verified')) {
					this.setState({
						verify_email: false,
						errors: null,
					});
				}

				this.setState({
					error: e.response.data.errors,
				});
			} else {
				this.setState({
					error: t(GENERIC_ERROR_MESSAGE),
				});
			}
		}
		this.removeLoading(time);
	};

	render() {
		const {
			currentRoute,
			simpleRegistration,
			mobileVerification,
			addressVerification,
			termsAndConditionsLink,
			termsAndConditions,
			useRouting,
			isFullWidth,
			t,
		} = this.props;
		const {
			error,
			step,

			// User Details Form
			date_of_birth,
			date_of_birth_error,
			details_form_is_valid,
			dob_day,
			dob_month,
			dob_year,
			email,
			email_error,
			email_valid,
			password,
			password_error,
			username,
			username_error,

			// Address Form
			address_form_is_valid,
			address,
			city,
			city_error,
			country,
			deposit_limit,
			deposit_limit_valid,
			title,
			first_name,
			first_name_error,
			last_name,
			last_name_error,
			loss_limit,
			loss_limit_valid,
			postcode,
			postcode_error,
			state,
			street,
			street_error,

			// Mobile Authentication Form
			loadingSendSms,
			mobile_number_error,

			// Mobile Verification Form
			loadingCreateAccount,
			loadingVerifyMobile,
			mobile_number,
			mobile_is_valid,
			verified_mobile,
		} = this.state;

		let datePickerDate;
		if (date_of_birth && date_of_birth.indexOf('_') === -1) {
			datePickerDate = dob_year + '-' + dob_month + '-' + dob_day;
		}
		return (
			<StyledRegistrationPage isFullWidth={isFullWidth}>
				{useRouting && <Route route={`/${REGISTRATION_BASE_URL}/${currentRoute}`} />}

				<RegistrationHeader onClose={useRouting ? this.onClose : null} isGreenId={step === GREENID_VERIFICATION} />

				<StyledRegistrationPage__Wrapper isGreenId={step === GREENID_VERIFICATION}>
					{error && (
						<StyledRegistrationPage__Error
							strong
							type={Notification.types.COLOUR_DANGER}
							message={error}
							buttonText={t('Dismiss')}
							buttonAction={this.clearMessage}
						/>
					)}

					<div>
						{(() => {
							switch (step) {
								case USER_DETAILS_FORM:
									return simpleRegistration ? (
										<SimpleRegistration
											loadingCreateAccount={loadingCreateAccount}
											onSignIn={this.handleSignIn}
											onSignUp={this.handleSimpleRegistration}
											termsAndConditionsLink={termsAndConditionsLink}
											emailError={t(email_error)}
											passwordError={t(password_error)}
											usernameError={t(username_error)}
											onValidateForm={this.validateDetailsForm}
										/>
									) : (
										<UserDetailsForm
											signIn={this.handleSignIn}
											goToStep={this.goToStep}
											onChange={this.onChange}
											onBulkChange={this.onBulkChange}
											email={email}
											email_valid={email_valid}
											password={password}
											username={username}
											form_is_valid={details_form_is_valid}
											email_error={t(email_error)}
											password_error={t(password_error)}
											username_error={t(username_error)}
											mobileVerification={mobileVerification}
											mobile_number={mobile_number}
											date_of_birth={date_of_birth}
											date_of_birth_error={t(date_of_birth_error)}
											datePickerDate={datePickerDate}
											marketing_opt_in_flag={this.state.marketing_opt_in_flag}
											termsAndConditions={termsAndConditions}
										/>
									);
								case EMAIL_AUTHENTICATION:
									return (
										<EmailAuthentication
											email_valid={email_valid}
											email={this.state.email}
											emailCode={this.state.email_code}
											emailWasSent={this.state.email_was_sent}
											verified={this.state.verify_email}
											emailError={t(email_error)}
											emailCodeError={this.state.email_code_error}
											handleChange={(name, value, error) => {
												this.setState((prev) => ({
													...prev,
													[name]: value,
													email_error: error ? null : 'Email is invalid',
												}));
											}}
											validate={this.validateDetailsForm}
											loading={this.state.loading}
											sendEmail={this.sendEmail}
											verifyEmail={this.validateEmail}
											goNext={() => this.goToStep(USER_DETAILS_FORM)}
											invalid={!!this.state.error_blacklist}
										/>
									);
								case USER_ADDRESS_FORM:
									return (
										<UserAddressForm
											mobileVerification={mobileVerification}
											addressVerification={addressVerification}
											goToStep={this.goToStep}
											createAccount={this.createAccount}
											loadingCreateAccount={loadingCreateAccount}
											onChange={this.onChange}
											onBulkChange={this.onBulkChange}
											setLimits={this.saveLimits}
											address={address}
											city={city}
											city_error={t(city_error)}
											country={country}
											deposit_limit={deposit_limit}
											deposit_limit_valid={deposit_limit_valid}
											form_is_valid={address_form_is_valid && !this.state.error_blacklist && !this.state.loading}
											title={title}
											first_name={first_name}
											first_name_error={t(first_name_error)}
											last_name={last_name}
											last_name_error={t(last_name_error)}
											loss_limit={loss_limit}
											loss_limit_valid={loss_limit_valid}
											postcode={postcode}
											postcode_error={t(postcode_error)}
											state={state}
											street={street}
											street_error={t(street_error)}
											street_number={this.state.street_number}
											street_number_error={t(this.state.street_number_error)}
											street_type={this.state.street_type}
											street_type_error={t(this.state.street_type_error)}
											unit_number={this.state.unit_number}
											unit_number_error={t(this.state.unit_number_error)}
											unit_type={this.state.unit_type}
											unit_type_error={t(this.state.unit_type_error)}
										/>
									);
								case MOBILE_AUTHENTICATION:
									return (
										<MobileAuthenticationForm
											sendSmsCode={this.sendSmsCode}
											country={country}
											mobile_number={mobile_number}
											mobile_is_valid={!error && mobile_is_valid}
											mobile_number_error={t(mobile_number_error)}
											loadingSendSms={loadingSendSms}
											validateMobileNumber={this.validateMobileNumber}
											brandName={this.props.brandName}
											invalid={!!this.state.error_blacklist}
										/>
									);
								case MOBILE_VERIFICATION:
									return (
										<MobileOPTForm
											first_name={first_name}
											mobile_number={mobile_number}
											verifyMobile={this.verifyMobile}
											createAccount={this.createAccount}
											goBack={() => this.goToStep(MOBILE_AUTHENTICATION)}
											goNext={() => this.goToStep(EMAIL_AUTHENTICATION)}
											verified={verified_mobile}
											loadingVerifyMobile={loadingVerifyMobile}
											loadingCreateAccount={loadingCreateAccount}
										/>
									);
								case REGISTRATION_CONFIRMATION:
									return <LastStep onClose={() => this.props.onClose('/')} isRegistation={true} />;
								case GREENID_VERIFICATION:
									return this.props.localUser ? (
										<VerificationFormV2
											localUser={this.props.localUser}
											nextStep={() => this.goToStep(SET_LIMITS)}
											onClose={() => this.props.onClose('/')}
										/>
									) : null;
								case SET_LIMITS:
									return <LastStep onClose={() => this.props.onClose('/')} />;
								case FIRST_DEPOSIT:
									return <FirstDepositContainer />;
							}
						})()}
					</div>
				</StyledRegistrationPage__Wrapper>

				<ModalFooter />
			</StyledRegistrationPage>
		);
	}
}

const mapStateToProps = (state) => {
	const termsAndConditions = state.legalRules.selectedRule && state.legalRules.selectedRule.fulltext;
	return {
		localUser: state.registration.localUser,
		email: state.registration.registrationEmail,
		redirectRoute: state.registration.redirectRoute,
		currentRoute: state.registration.route,
		isDepositsAndWithdrawalsEnabled: state.featureToggles.features.depositsAndWithdrawals.enabled,
		simpleRegistration: state.featureToggles.features.simpleRegistration.enabled,
		mobileVerification: state.featureToggles.features.mobileVerification.enabled,
		addressVerification: state.featureToggles.features.addressVerification.enabled,
		firstDepositOnSignup: state.featureToggles.features.firstDepositOnSignup.enabled,
		termsAndConditionsLink: state.acl.termsAndConditionsLink,
		termsAndConditions: termsAndConditions ? termsAndConditions : '',
		isFullWidth: state.featureToggles.features.fullWidth.enabled,
		brandName: state.acl.brandDetails ? state.acl.brandDetails.teamname : '',
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		scrollToTop,
		signIn: (redirectRoute) => {
			return ownProps.useRouting
				? dispatch(openLoginForm(true, redirectRoute))
				: postPrefixedMessage('registrationSignInExisting');
		},
		onClose: (route) => {
			dispatch(routeTo('/' + route));
			dispatch(showNavigation());
			dispatch(hideMastheadFields(false));
			return dispatch(clearRedirectRoute());
		},
		validateRegistrationForm: (object) => {
			return dispatch(validateRegistrationForm(object));
		},
		sendSmsCode: (number) => {
			return dispatch(sendSmsCode(number));
		},
		verifySmsCode: (number, code) => {
			return dispatch(verifySmsCode(number, code));
		},
		createAccount: (object) => {
			return dispatch(createAccount(object));
		},
		setDepositLimit: (userId, amount, period) => {
			return dispatch(setDepositLimit(userId, amount, period));
		},
		setLossLimit: (amount, period) => {
			return dispatch(setLossLimit(amount, period));
		},
		navigateToStep: (step, useRouting) => {
			dispatch(trackGaEvent('Registration', 'click:next-step', `next-button-${step}`));
			dispatch(setRegistrationStep(step));

			if (useRouting) {
				dispatch(navigateToStep(step));
			}
		},
		trackEvent: (category, action, label) => {
			dispatch(trackGaEvent(category, action, label));
		},
		trackGtmEvent: (event) => {
			dispatch(trackGtmEvent(event));
		},
		trackBrazeEvent: (step) => {
			dispatch(trackBrazeEvent('Registration', { Step: step }));
		},
		trackGaEvent: (category, action, label) => {
			return dispatch(trackGaEvent(category, action, label));
		},
		startRegistration: () => {
			dispatch(trackGaEvent('Registration', 'Status', 'Start'));
			dispatch(trackBrazeEvent('Registration', { Step: 'Start Registration' }));
			dispatch(hideNavigation());
			return dispatch(hideMastheadFields(true));
		},
		resetMasthead: () => {
			dispatch(showNavigation());
			return dispatch(hideMastheadFields(false));
		},
		onShortRegistration: (user) => {
			return dispatch(createAccountFromShortForm(user));
		},
		openDeposits: () => {
			return dispatch(showDeposits());
		},
		setLocalUser: (user) => {
			return dispatch(setLocalUser(user));
		},
	};
};

// 0450102132
// 55 Wentworth Avenue
// Kingston
// ACT
// 2604
/** Define the connected container here so we can add props to it */
const ConnectedRegistrationPage = connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);

ConnectedRegistrationPage.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Allow for navigation actions */
	useRouting: PropTypes.bool,
};

ConnectedRegistrationPage.defaultProps = {
	useRouting: true,
};

export default withNamespaces()(createErrorBoundary(ConnectedRegistrationPage));
