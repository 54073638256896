import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel } from '@tbh/ui-kit';

import ModalHeader from '../../components/features/Application/ModalHeader/ModalHeader';
import AclForm from '../../components/features/Acl/AclForm/AclForm';

import { closeModal } from '../../store/modal/modalActions';
import { setAclAttributes } from '../../store/acl/aclActions';
import { addFeatures } from '../../store/featureToggles/featureTogglesActions';
import { aclSchema, aclUiSchema } from './AclManager.data';

const AclManagerContainer = (props) => {
	const { onClose, aclItems, isOnPage, onAclUpdate } = props;

	return (
		<div>
			<ModalHeader title="Acl Manager" onClose={isOnPage ? null : onClose} />

			<Panel padding={Panel.padding.SPACING_COMFORTABLE}>
				<AclForm schema={aclSchema} uiSchema={aclUiSchema} formData={aclItems} onAclUpdate={onAclUpdate} />
			</Panel>
		</div>
	);
};

AclManagerContainer.propTypes = {
	/** List with all Acl properties */
	aclItems: PropTypes.object.isRequired,

	/** Handler for changing the property value */
	onAclUpdate: PropTypes.func.isRequired,

	/** Closes the modal */
	onClose: PropTypes.func.isRequired,

	/** If the container is being rendered in a page or not */
	isOnPage: PropTypes.bool,
};

AclManagerContainer.defaultProps = {
	isOnPage: false,
};

const mapStateToProps = (state) => {
	return {
		aclItems: state.acl,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onClose: () => {
			return dispatch(closeModal('AclManagerContainer'));
		},
		onAclUpdate: (newAcl) => {
			dispatch(setAclAttributes(newAcl));
			dispatch(addFeatures(newAcl.features));

			if (!ownProps.isOnPage) {
				return dispatch(closeModal('AclManagerContainer'));
			}
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AclManagerContainer);
