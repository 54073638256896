import React, { useRef, useState, useEffect } from 'react';
import { Button, ButtonGroup, CONSTRAINT_EMAIL, Icon, Input, InputGroup, Text } from '@tbh/ui-kit';
import { withNamespaces } from 'react-i18next';
import { css } from 'emotion';

const EmailAuthentication = ({
	t,
	email_valid,
	email,
	emailError,
	emailWasSent,
	verified,
	loading,
	sendEmail,
	verifyEmail,
	goNext,
	handleChange,
	validate,
	invalid,
}) => {
	const length = 4;
	const [otp, setOTP] = useState(new Array(length).fill(''));
	const inputsRef = useRef([]);
	const [typed, setTyped] = useState(false);
	const [debouncedEmail, setDebouncedEmail] = useState(email);

	let valid = otp.every((data) => data !== '');

	const handleOTPChange = (v, index) => {
		if (isNaN(v) || v === '') return;
		setOTP((prev) => [...prev.map((data, idx) => (idx === index ? v : data))]);

		// Focus next input field
		if (index !== length - 1) inputsRef.current[index + 1].focus();
		else inputsRef.current[index].blur();
	};

	const handlePaste = (e) => {
		const pasteData = e.clipboardData.getData('text').split('');
		if (pasteData.length !== length) return;

		const newOTP = [...otp.map((_, idx) => pasteData[idx] || '')];
		setOTP(newOTP);
	};

	const handleKeyDown = (e, index) => {
		const value = e.key;
		if (value === 'Backspace') {
			e.preventDefault();
			setOTP((prev) => [...prev.map((data, idx) => (idx === index ? '' : data))]);
			if (index !== 0) inputsRef.current[index - 1].focus();
			else {
				inputsRef.current[index].blur();
			}
		} else if (value === 'ArrowLeft' && index !== 0) {
			inputsRef.current[index - 1].focus();
		} else if (value === 'ArrowRight' && index !== length - 1) {
			inputsRef.current[index + 1].focus();
		} else if (e.target.value.length === 1) {
			e.preventDefault();
			handleOTPChange(value, index);
		} else if (value === 'Enter' && valid) {
			verifyEmailCode();
		}
	};

	const verifyEmailCode = () => {
		verifyEmail(otp.join(''));
	};

	const handleEmailChange = (name, value, error) => {
		handleChange(name, value, error);

		setDebouncedEmail(value);
	};

	useEffect(() => {
		setTyped(true);
		// Set a timeout to update the debounced value after 500ms
		const timer = setTimeout(() => {
			if (email) {
				validate();
				setTyped(false);
			}
		}, 750);

		// Cleanup the timeout if email changes before the delay is reached
		return () => clearTimeout(timer);
	}, [debouncedEmail]);
	if (emailWasSent) {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
				<Text paragraph strong>
					{!verified ? t('Verify your email address') : t('Email verified')}
				</Text>
				<Text size="-1" strong>
					{t('We have sent you an email with a verification code. Please enter it below.')}
				</Text>

				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<div>
						<div
							style={{
								display: 'flex',
								gap: '4px',
							}}
							onPaste={handlePaste}
						>
							{otp.map((data, index) => (
								<Input
									key={index}
									type="text"
									name={`code_${index + 1}`}
									maxLength={1}
									value={data}
									onChange={(name, value) => handleOTPChange(value, index)}
									onKeyDown={(e) => handleKeyDown(e, index)}
									onFocus={(e) => e.target.select()}
									inputRef={(el) => (inputsRef.current[index] = el)}
									disabled={verified}
									align="center"
									margin="cozy"
									horizontalMargin="compact"
									pattern="[0-9]*"
								/>
							))}
						</div>

						{verified ? (
							<Text size="-1" paragraph>
								{t('Congratulations! Your email has been verified.')}
								{t('You can now proceed to the next step.')}
							</Text>
						) : (
							<Text size="-2" paragraph>
								Please enter the four digit verification code that has been sent to <strong>{email}</strong> so that we
								can validate your email.
							</Text>
						)}
					</div>

					<Icon
						className={css`
							font-size: 90px;
							line-height: 90px; // for IE
							align-self: flex-start;
						`}
						icon="email"
					/>
				</div>

				<ButtonGroup center>
					<Button type="ghost" action={() => handleChange('email_was_sent', false)} bold flex>
						{t('Go back')}
					</Button>
					{verified ? (
						<Button action={goNext} type="primary" loading={loading} bold flex disabled={invalid}>
							{t('Next')}
						</Button>
					) : (
						<Button action={verifyEmailCode} disabled={!valid || invalid} type="primary" loading={loading} bold flex>
							{t('Verify Email')}
						</Button>
					)}
				</ButtonGroup>
			</div>
		);
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
			<Text paragraph strong>
				{t('Verify your email address')}
			</Text>

			<Text size="-2" strong>
				{t('Please enter your email address below.')}
			</Text>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
				<Input
					key="email"
					name="email"
					type="email"
					value={email}
					onChange={handleEmailChange}
					error={emailError != 'Email is invalid' ? emailError : null}
					onBlur={validate}
					constraints={CONSTRAINT_EMAIL}
					runValidation={true}
					align="start"
					margin="cozy"
					horizontalMargin="compact"
					label={t('UserDetailsForm__Email')}
					className={css`
						width: 100%;
						margin-bottom: 20px;
					`}
					autoFocus
				/>
				<Icon
					className={css`
						font-size: 90px;
						line-height: 90px; // for IE
						align-self: flex-start;
					`}
					icon="email"
				/>
			</div>

			<ButtonGroup center>
				<Button
					action={sendEmail}
					type="primary"
					disabled={typed || emailError || !email_valid || !email || invalid}
					loading={loading}
					bold
					flex
				>
					{t('Send code')}
				</Button>
			</ButtonGroup>
		</div>
	);
};

export default withNamespaces()(EmailAuthentication);
