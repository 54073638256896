// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { withNamespaces } from 'react-i18next';

// Actions & Selectors
import { closeModal } from '../../../store/modal/modalActions';
import { openQuickBettingSummary, routeTo, toggleMultiBetSlip } from '../../../store/application/applicationActions';
import { getUserAccountMenuItems } from '../../../store/userAccount/userAccountSelectors';
import { setSelectedMenuItem } from '../../../store/userAccount/userAccountActions';
import { logoutUser } from '../../../store/authentication/authenticationActions';

// Components
import AccountSummary from '../../../components/features/Application/AccountSummary/AccountSummary';
import ModalHeader from '../../../components/features/Application/ModalHeader/ModalHeader';
import { Button } from '@tbh/ui-kit';
import {
	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
	USER_ACCOUNT_BASE_URL,
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
} from '../../../pages/UserAccount/UserAccountPageConstants';
import { PLACE_MULTI_BET } from '../../../store/betPlacement/betPlacementReducerNames';

const MobileAccountSummaryContainer = (props) => {
	const {
		t,
		account_balance,
		first_name,
		free_credit_balance,
		gamblingMessage,
		goToAccountTransactions,
		goToAccountTransactionsBonus,
		id,
		isCordova,
		name,
		navItems,
		onClose,
		onLogout,
		onNavItemClick,
		openQuickBettingSummary,
		pending_bets,
		potential_winnings,
		total_stake,
		username,
		verified,

		isMultiBetSlipOpen,
		onBetSlipClick,
		count_bets
	} = props;


	const handleBetSlipClick = () => {
		onClose();
		onBetSlipClick(isMultiBetSlipOpen);
	};


	return (
		<SizeMe>
			{({ size }) => (
				<div>
					<ModalHeader
						title={t('UserAccountPage__ManageAccount')}
						onClose={onClose}
						aside={
							<Button type={Button.types.SECONDARY} action={openQuickBettingSummary} size="small">
								{pending_bets} {t('Pending')}
							</Button>
						}
					/>

					<AccountSummary
						account_balance={account_balance + free_credit_balance}
						cash_balance={account_balance}
						first_name={first_name}
						free_credit_balance={free_credit_balance}
						gamblingMessage={gamblingMessage}
						goToAccountTransactions={goToAccountTransactions}
						goToAccountTransactionsBonus={goToAccountTransactionsBonus}
						goToPendingBets={openQuickBettingSummary}
						id={id}
						isCordova={isCordova}
						name={name}
						navItems={navItems}
						onLogout={onLogout}
						onNavItemClick={onNavItemClick}
						pending_bets={pending_bets}
						potential_winnings={potential_winnings}
						total_stake={total_stake}
						username={username}
						verified={verified}
						isMobile
						size={size}
						onBetSlipClick={handleBetSlipClick}
						count_bets={count_bets}

					/>
				</div>
			)}
		</SizeMe>
	);
};

MobileAccountSummaryContainer.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Navigation items */
	navItems: PropTypes.arrayOf(PropTypes.object).isRequired,

	/** Handler for clicking on a navigation item */
	onNavItemClick: PropTypes.func.isRequired,

	/** Navigates to the account transactions page */
	goToAccountTransactions: PropTypes.func.isRequired,

	/** Navigates to the account transactions bonus page */
	goToAccountTransactionsBonus: PropTypes.func.isRequired,

	openQuickBettingSummary: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onLogout: PropTypes.func.isRequired,
	account_balance: PropTypes.number,
	first_name: PropTypes.string,
	free_credit_balance: PropTypes.number,

	/** Gambling message from the acl */
	gamblingMessage: PropTypes.string,

	id: PropTypes.number,

	/** If it's the cordova app or not */
	isCordova: PropTypes.bool,

	name: PropTypes.string,
	pending_bets: PropTypes.number,
	potential_winnings: PropTypes.number,
	total_stake: PropTypes.number,
	username: PropTypes.string,
	verified: PropTypes.bool,
};

MobileAccountSummaryContainer.defaultProps = {
	account_balance: 0,
	first_name: null,
	free_credit_balance: 0,
	gamblingMessage: '',
	id: null,
	isCordova: false,
	name: null,
	pending_bets: 0,
	potential_winnings: 0,
	total_stake: 0,
	username: null,
	verified: false,
};

const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;

	return {
		account_balance: state.entities.users[authenticatedUser].account_balance,
		first_name: state.entities.users[authenticatedUser].first_name,
		free_credit_balance: state.entities.users[authenticatedUser].free_credit_balance,
		gamblingMessage: state.acl.gamblingMessages,
		id: state.entities.users[authenticatedUser].id,
		navItems: getUserAccountMenuItems(state),
		name: state.entities.users[authenticatedUser].name,
		pending_bets: state.entities.users[authenticatedUser].pending_bets,
		potential_winnings: state.entities.users[authenticatedUser].potential_winnings,
		total_stake: state.entities.users[authenticatedUser].total_stake,
		username: state.entities.users[authenticatedUser].username,
		verified: state.entities.users[authenticatedUser].verified,
		isCordova: state.application.isCordova,

		count_bets: state[PLACE_MULTI_BET].selections.length,
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToAccountTransactions: () => {
			dispatch(
				routeTo(
					`/${USER_ACCOUNT_BASE_URL}/${USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS}/${USER_ACCOUNT_ALL_TRANSACTIONS_URL}`,
				),
			);
			return dispatch(closeModal('MobileAccountSummaryContainer'));
		},
		goToAccountTransactionsBonus: () => {
			dispatch(
				routeTo(
					`/${USER_ACCOUNT_BASE_URL}/${USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS}/${USER_ACCOUNT_ALL_TRANSACTIONS_URL}`,
				),
			);
			return dispatch(closeModal('MobileAccountSummaryContainer'));
		},
		onClose: () => {
			return dispatch(closeModal('MobileAccountSummaryContainer'));
		},
		onLogout: () => {
			dispatch(logoutUser());
			dispatch(closeModal('MobileAccountSummaryContainer'));
		},
		openQuickBettingSummary: () => {
			dispatch(closeModal('MobileAccountSummaryContainer'));
			return dispatch(openQuickBettingSummary());
		},
		onNavItemClick: (item) => {
			dispatch(closeModal('MobileAccountSummaryContainer'));
			dispatch(setSelectedMenuItem(item, true));
		},
		onBetSlipClick: (isMultiBetSlipOpen) => {
			return dispatch(toggleMultiBetSlip(isMultiBetSlipOpen));
		},
	};
};

export default withNamespaces()(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(MobileAccountSummaryContainer),
);
