import { createAction } from '../../common/actions/actionHelpers';
import { post } from '../../common/Ajax';
import { openNotification, setBodyLoading } from '../application/applicationActions';
import { setTheAuthenticatedUser } from '../authentication/authenticationActions';
import {
	VERIFICATION_SUCCESS,
	VERIFICATION_ERROR,
	VERIFICATION_SET_TOKEN,
	VERIFICATION_WARNING,
} from './VerificationsActionType';

export const acceptedStatuses = ['VERIFIED', 'VERIFIED_ADMIN', 'VERIFIED_WITH_CHANGES', 'PENDING'];

const handleError = (err, dispatch) => {
	let message = 'There was an error verifying your account. Please try again later.';
	document.Sentry && document.Sentry.captureException(err);
	if (err.response && err.response.data && err.response.data.errors) {
		message = err.response.data.errors;
	}
	dispatch(setBodyLoading(false));
	dispatch(openNotification('Oops! Something went wrong.', 'danger'));
	return dispatch(createAction(VERIFICATION_ERROR, message));
};

export const verifyVerificationForm = (form) => async (dispatch) => {
	dispatch(setBodyLoading(true));

	try {
		const response = await post('/greenid/verify', {
			...form,
		});
		const user = response.data.data;
		dispatch(setBodyLoading(false));
		dispatch(createAction(VERIFICATION_SUCCESS, {}));
		return user;
	} catch (err) {
		let error = 'There was an error verifying your account. Please try again later.';

		if (err.response && err.response.data && err.response.data.errors) {
			error = err.response.data.errors;
		}

		document.Sentry && document.Sentry.captureException(err);
		dispatch(setBodyLoading(false));
		return dispatch(createAction(VERIFICATION_ERROR, error));
	}
};

export const updateVerificationStatus = (green_id_trans_id, session_token, status) => async (dispatch) => {
	dispatch(setBodyLoading(true));

	if (!acceptedStatuses.includes(status)) {
		return dispatch(setBodyLoading(false));
	}

	if (status == 'LOCKED_OUT') {
		dispatch(openNotification('Your account is locked out. Please contact our customer support.', 'danger'));
		return dispatch(
			createAction(VERIFICATION_ERROR, 'Your account is locked out. Please contact our customer support.'),
		);
	}

	try {
		const response = await post('registration/greenid/status', {
			green_id_trans_id,
			session_token,
		});

		const user = response.data.data;

		dispatch(setBodyLoading(false));
		dispatch(openNotification('Your account is verified!', 'success'));
		dispatch(createAction(VERIFICATION_SUCCESS, {}));
		dispatch(createAction(VERIFICATION_SET_TOKEN, null));
		dispatch(setTheAuthenticatedUser(user, true, false));
	} catch (err) {
		document.Sentry && document.Sentry.captureException(err);
		dispatch(setBodyLoading(false));
		if (err.response && err.response.data) {
			if (err.response.data.http_status_code == 403) {
				return dispatch(createAction(VERIFICATION_WARNING, err.response.data.errors));
			}

			return dispatch(createAction(VERIFICATION_ERROR, err.response.data.errors));
		} else {
			dispatch(openNotification('Oops! Something went wrong.', 'danger'));
		}

		return dispatch(
			createAction(VERIFICATION_ERROR, 'There was an error verifying your account. Please try again later.'),
		);
	}
};

export const getUserVerificationToken = (green_id_trans_id) => async (dispatch) => {
	dispatch(setBodyLoading(true));

	try {
		const response = await post('registration/greenid/token', { green_id_trans_id });
		const { status, session_token } = response.data;
		dispatch(setBodyLoading(false));

		if (acceptedStatuses.includes(status)) {
			dispatch(updateVerificationStatus(green_id_trans_id, session_token, status));
		} else if (status === 'IN_PROGRESS') {
			dispatch(createAction(VERIFICATION_SET_TOKEN, session_token));
		} else if (status == 'LOCKED_OUT') {
			dispatch(openNotification('Your account is locked out. Please contact our customer support.', 'danger'));
			return dispatch(
				createAction(VERIFICATION_ERROR, 'Your account is locked out. Please contact our customer support.'),
			);
		} else {
			document.getElementById('submitbob').click();
			dispatch(createAction(VERIFICATION_SET_TOKEN, session_token));
		}
	} catch (err) {
		handleError(err, dispatch);
	}
};
