import React from 'react';
import styled, { css } from 'react-emotion';
import {  spacings, ui, media, TabsItem, Tabs } from '@tbh/ui-kit';

import RaceItem from './RaceItem/RaceItem';
import Carousel from '../../../features/Carousel/Carousel';
import RaceItemLoading from './RaceItem/RaceItemLoading';

const MIN_HEIGHT = 41;

const StyledRaceNavLoading__Carousel = styled(Carousel)(
	css`
		label: RaceNavLoading__Carousel;
		overflow: hidden;
		height: 64px !important;
		padding: 8px;
		${Carousel.NextButton}, ${Carousel.PrevButton},
		${Carousel.NextButtonIcon}, ${Carousel.PrevButtonIcon} {
			height: 64px !important;
		}

		.swiper-wrapper {
			gap: 2px;
		}
	`,
);

const StyledRaceNavLoading__RaceItem = styled(RaceItemLoading)(
	(props) => css`
		label: RaceNavLoading__RaceItem;
		min-width: 100px;
		width: 100% !important;
		height: 45.7px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 0 !important;
		${props.displayCarousel &&
		css`
				height: inherit !important;
				padding: ${spacings(props).tight}px;
				${RaceItem.StyledRaceItem__Label} {
					padding-top: 0;
					line-height: unset;
				}
			`};
	`,

	media(
		css`
			min-width: 60px;
		`,
		767,
	),
);

const StyledRaceNavLoading__TabsItem = styled(TabsItem)(
	(props) => css`
		label: RaceNavLoading__TabsItem;
		flex: 1 1 auto;
		min-width: 100px;
		/* max-width: 120px; */
		padding: 0;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: ${MIN_HEIGHT}px;
		border-bottom: none!important;
	`,
	media(
		css`
			min-width: 60px;
		`,
		767,
	),
);

const StyledRaceNavLoading = styled('div')(
	(props) => css`
	label: RaceNav;
	border: 1px solid ${ui(props).color_6};

	${Carousel.StyledCarouselSlideSpan} {
		label: RaceNav__CarouselItem;
		flex: auto;
		min-height: ${MIN_HEIGHT}px;
		border-right: 1px solid ${ui(props).color_3};
	}
	`,
);

const RaceNavLoading = (props) => {
	const { carouselConfig, size } = props;

	const showCarousel = 5;

	const raceItems = [0, 1, 2, 3, 4, 5, 6, 7];
	// If we want to activate the carousel and the races list reaches a certain length,
	// then change tha Tabs component to a carousel component
	let Component = Tabs;

	let componentProps = {
		padding: Tabs.paddings.SPACING_NONE,
		alignment: 'left',
		wrap: raceItems.length > 8,
	};

	if (showCarousel) {
		Component = StyledRaceNavLoading__Carousel;
		// height Needed due to overflow: hidden hiding the tab active border
		componentProps = {
			nextButton: true,
			prevButton: true,
			freeMode: true,
			roundLengths: true,
			cssWidthAndHeight: true,
			visibilityFullFit: true,
			buttonStyle: '1',
			className: css`
				display: flex;
				// height: ${MIN_HEIGHT + 1}px;
			`,
			settings: carouselConfig,
		};
	}

	return (
		<StyledRaceNavLoading>
			<Component {...componentProps}>
				{raceItems.map((item) => (
					<StyledRaceNavLoading__TabsItem
						id={item}
						key={item}
						active={item === 1}
						data={item}
						showCarousel={showCarousel}
					>
						<StyledRaceNavLoading__RaceItem
							id={item}
							size={size}
						/>
					</StyledRaceNavLoading__TabsItem>
				))}
			</Component>
		</StyledRaceNavLoading>
	);
};

export default RaceNavLoading;
