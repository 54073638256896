
import {
    
    TRACKING_CATEGORY_BET_BUILDER_HOME_TYPE_SELECTOR,
	TRACKING_CATEGORY_BET_BUILDER_CARD_TYPE_SELECTOR,
	TRACKING_CATEGORY_RACING_HOME_DATE_SELECTOR,
	TRACKING_CATEGORY_RACE_CARD_DATE_SELECTOR,
	TRACKING_CATEGORY_BET_BUILDER_COUNRY_CODE_SELECTOR,
	TRACKING_CATEGORY_BET_BUILDER_CARD_COUNRY_CODE_SELECTOR,
} from '../../common/constants/Racing';

import {trackGaEvent} from '../trackingPixels/trackingActions';

/**
 * Send GA tracking for meeting type selector
 * @param {*} label [gallops|harness|greyhounds]
 * @HW 12Dec2019
 */
export const trackCountry = (action, type, isFeatureRacingHome) => {
    let category = getCategory(
        isFeatureRacingHome,
		TRACKING_CATEGORY_BET_BUILDER_COUNRY_CODE_SELECTOR,
		TRACKING_CATEGORY_BET_BUILDER_CARD_COUNRY_CODE_SELECTOR,
    );
	return trackGaEvent(category, action, type);
};

/**
 * Send GA tracking for date selector
 *
 * @param {string} label [Yesterday|Tomorrow|SelectDate]
 * @param {boolean} isFeatureRacingHome - meant to choose 'category'
 * @return {object} action
 */
export const trackDateSelection = (label, isFeatureRacingHome) => {
	let category = getCategory(
		isFeatureRacingHome,
		TRACKING_CATEGORY_RACING_HOME_DATE_SELECTOR,
		TRACKING_CATEGORY_RACE_CARD_DATE_SELECTOR,
	);

	return trackGaEvent(category, 'Select', label);
};

/**
 * Send GA tracking for filtering and click on bet builder races carousel
 * @param {string} action - ['Click'|'Filter']
 * @param {string} type - Bet builder type
 * @param {boolean} isbetBuilderHome - meant to choose 'category'
 * @return {object} action
 */
export const trackbetBulderType = (action, type, isbetBuilderHome) => {
	let category = getCategory(
		isbetBuilderHome,
		TRACKING_CATEGORY_BET_BUILDER_HOME_TYPE_SELECTOR,
		TRACKING_CATEGORY_BET_BUILDER_CARD_TYPE_SELECTOR
	);

	return trackGaEvent(category, action, type);
};

/**
 * Local function to choose which category to use based which page the client is at.
 * @param isbetBuilderHome
 * @param homeCategory
 * @param betBuilderRaceCategory
 * @return {*}
 */
const getCategory = (isbetBuilderHome, homeCategory, betBuilderRaceCategory) => {
	return isbetBuilderHome ? homeCategory : betBuilderRaceCategory;
};