import { SIDEBAR_CHANGE } from './SideBarActionsTypes';
import { serverDateTime } from '../../legacy/core/format';
import moment from 'moment';

const initialState = {
	// The initial state previous results
	previousResults: {
		id: '',
		name: 'Previous Results',
		date: serverDateTime(moment().subtract(1, 'day')).format('YYYY-MM-DD'),
		hidden: false,
		children: [
			{
				name:'thoroughbred Results',
				id:'racing_types_TODAYthoroughbred',
				hidden:true,
				children:[],
				step:'step-2',
			}
		],
		step: 'step-1',
		
	},
	today: {
		id: '',
		name: 'Today\'s Racing',
		date: serverDateTime(moment()).format('YYYY-MM-DD'),
		children: [],
		step: 'step-1',
	},
	tomorrow: {
		id: '',
		name: 'Tomorrow\'s Racing',
		date: serverDateTime(moment().add(1, 'day')).format('YYYY-MM-DD'),
		step: 'step-1',
		children: [],
	},
	dayAfterTomorrow: {
		id: '',
		name: serverDateTime(moment().add(2, 'day')).format('dddd') + '\'s Racing',
		date: serverDateTime(moment().add(2, 'day')).format('YYYY-MM-DD'),
		step: 'step-1',
		children: [],
	},
};

const sideBarData = (state = initialState, action) => {
	switch (action.type) {
		case SIDEBAR_CHANGE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export default sideBarData;
