/**
 * Created by Jason Kinnear on 25/1/17.
 * Re Created by Ashan
 */

import entity from './entity';
import { CLEAR_DEPOSITS_DATA } from '../deposits/depositActionTypes';
import { WITHDRAWALS_CLEAR_ENTITIES } from '../withdrawalPrompt/withdrawalPromptActionTypes';

const { SET_MARKETS_FOR_EVENT } = require('../activeTournament/activeTournamentActionTypes');
const { CLEAR_AUTHENTICATED_USER, SET_AUTHENTICATED_USER } = require('../application/applicationActionTypes');

const {
  UPDATE_ENTITIES,
  MERGE_ENTITIES,
  REMOVE_ENTITIES,
  REPLACE_ENTITIES,
  RESET_ENTITIES,
} = require('./EntityActionTypes');
import { normalizeNavigationItems } from './schemas/NavigationItemsSchema';




import moment from 'moment';
import { serverDateTime } from '../../legacy/core/format';

/**
 * Default State
 */
const initialState = {
  bankAccounts: {},
  base_competitions: {},
  bet_selections: {},
  bets: {},
  comments: {},
  competitions: {},
  creditCards: {},
  deposits: {},
  derivative_markets: {},
  derivative_selections: {},
  events: {},
  last_starts: {},
  leaderboardItems: {},
  markets: {},
  marketTypeGroups: {},
  meetings: {},
  meta: {},
  navigationItems: {},
  navigationSubItems: {},
  nextTickets: {},
  nextToPlayTournaments: {},
  expertRaces: {},
  featureRaces: {},
  nextToJumpRaces: {},
  nextToJumpRaceSelections: {},
  nextToJumpSports: {},
  prices: {},
  races: {},
  runners: {},
  selections: {},
  sports: {},
  sportSelections: {},
  teams: {},
  tickets: {},
  articles: {},
  tournamentBets: {},
  tournamentGroups: {},
  tournaments: {},
  trendingBets: {},
  users: {},
  withdrawals: {},
  versions: {},
  upcomingRaces: {},
};

let newDate = new Date();
let date = newDate.getDate();
let Tommorwdate = newDate.getDate() + 1;
let TommorwdateOne = newDate.getDate() + 2;
let TommorwdateTwo = newDate.getDate() + 3;
let month = newDate.getMonth() + 1;
let monthName = newDate.getMonth();
let year = newDate.getFullYear();
let day = newDate.getDay() + 2;
let dayafter = newDate.getDay() + 3;
let PreDay = newDate.getDate() - 1;

switch (dayafter) {
  case 0:
    dayafter = 'Sun';
    break;
  case 1:
    dayafter = 'Mon';
    break;
  case 2:
    dayafter = 'Tue';
    break;
  case 3:
    dayafter = 'Wed';
    break;
  case 4:
    dayafter = 'Thu';
    break;
  case 5:
    dayafter = 'Fri';
    break;
  case 6:
    dayafter = 'Sat';
    break;
  case 7:
    dayafter = 'Sun';
    break;
}

// switch(day) {
//   case 0:
//     day = 'Sun';
//     break;
//   case 1:
//     day = 'Mon';
//     break;
//   case 2:
//     day = 'Tue';
//     break;
//   case 3:
//     day = 'Wed';
//     break;
//   case 4:
//     day = 'Thu';
//     break;
//   case 5:
//     day = 'Fri';
//     break;
//   case 6:
//     day = 'Sat';
//     break;
//   case 7:
//     day = 'Sun';
//     break;
// }
switch (day) {
  case 0:
    day = 'SUNDAY`S  RACING';
    break;
  case 1:
    day = 'MONDAY`S  RACING';
    break;
  case 2:
    day = 'TUESDAY`S  RACING';
    break;
  case 3:
    day = 'WEDNESDAY`S  RACING';
    break;
  case 4:
    day = 'THURSDAY`S  RACING';
    break;
  case 5:
    day = 'FRIDAY`S  RACING';
    break;
  case 6:
    day = 'SATURDAY`S  RACING';
    break;
  case 7:
    day = 'SUNDAY`S  RACING';
    break;
}
switch (monthName) {
  case 0:
    monthName = 'January';
    break;
  case 1:
    monthName = 'February';
    break;
  case 2:
    monthName = 'March';
    break;
  case 3:
    monthName = 'April';
    break;
  case 4:
    monthName = 'May';
    break;
  case 5:
    monthName = 'June';
    break;
  case 6:
    monthName = 'July';
    break;
  case 7:
    monthName = 'August';
    break;
  case 8:
    monthName = 'September';
    break;
  case 9:
    monthName = 'October';
    break;
  case 10:
    monthName = 'November';
    break;
  case 11:
    monthName = 'December';
    break;
}


/**
 * Build the entity action.
 *
 * NOTE: This action does not get dispatched, and it is generally not recommended to build actions in the reducers. However
 * this should be considered as supplying extra data for the action
 *
 * @param action
 * @param entityKey
 * @returns {*}
 */
const buildEntityAction = (action, entityKey) => {
  return Object.assign({}, action, {
    entities: action.entities[entityKey],
    entityKey,
  });
};

let tournamentsReducer = entity(['leaderboards', 'list', 'meetings', 'competitions']);
let tournaments = (state = {}, action) => {
  const incomingTournaments = action.entities || {};
  let entities = {};

  Object.keys(incomingTournaments).forEach((key) => {
    const tournament = incomingTournaments[key];

    if (!tournament.prizes) {
      entities[key] = tournament;
      return;
    }

    // Attach an id to all prizes
    entities[key] = {
      ...tournament,
      prizes: tournament.prizes.map((prize) => {
        return {
          ...prize,
          id: prize.position,
        };
      }),
    };
  });

  return tournamentsReducer(state, { ...action, entities });
};

// TODO: rename entity to entityReducerGenerator and add a description
let entityReducerGenerator = entity();

/**
 * Default navigation items
 * @param state
 * @param action
 * @returns {*}
 */
const navigationItems = (state = {}, action) => {
  const yesterday = moment().add(-1, 'day').format('YYYY-MM-DD');
  const tommorow = moment().add(1, 'day').format('YYYY-MM-DD');
  const tommorowDayOne = moment().add(2, 'day').format('YYYY-MM-DD');
  const tommorowDayTwo = moment().add(3, 'day').format('YYYY-MM-DD');
  const nextSunday = moment().day(7).format('YYYY-MM-DD');



  if (Object.values(state).length === 0) {
    state = {
      ...state,
      ...normalizeNavigationItems([
        // {
        //   icon: 'racing',
        //   id: 'Home',
        //   mobile: true,
        //   order: 1,
        //   route: '/racing-v3/r/'+serverDateTime(new Date).format('YYYY-MM-DD'),//'/racing-v3', //@HW edit 25Nov2019
        //   routeOptions: { trigger: true, keepAdvert: true },
        //   showOnSidebar: true,
        //   sidebarOrder: 4,
        //   title: 'Racing',
        //   subMemu: '1',

        //   subItemOne: RACING_TYPES_LOOKUP[RACING_HARNESS_CODE],
        //   subItemOneTitle: 'Next To Go',
        //   subItemOneURL: '/next-jump-races',

        //   subItemTwo: RACING_TYPES_LOOKUP[RACING_THOROUGHBRED_CODE],
        //   subItemTwoTitle: "Today's Racing",
        //   subItemTwoURL: '/racing-v3/r/'+serverDateTime(new Date).format('YYYY-MM-DD'),

        //   subItemThree: RACING_TYPES_LOOKUP[RACING_GREYHOUNDS_CODE],
        //   subItemThreeTitle: "Today's Greyhounds",
        //   subItemThreeURL: '/racing-v3/g/'+serverDateTime(new Date).format('YYYY-MM-DD'),

        //   subItemFour: RACING_TYPES_LOOKUP[RACING_HARNESS_CODE],
        //   subItemFourTitle: "Today's Harness",
        //   subItemFourURL: '/racing-v3/h/'+serverDateTime(new Date).format('YYYY-MM-DD'),

        //   subItemFive: RACING_TYPES_LOOKUP[RACING_HARNESS_CODE],
        //   subItemFiveTitle: 'Tomorrow',
        //   subItemFiveURL: '/racing-v3/h/'+tommorow,

        //   subItemSix: RACING_TYPES_LOOKUP[RACING_HARNESS_CODE],
        //   subItemSixTitle: `${day} ${TommorwdateOne} ${monthName}`,
        //   subItemSixURL: '/racing-v3/h/'+tommorowDayOne,

        //   subItemSeven: RACING_TYPES_LOOKUP[RACING_HARNESS_CODE],
        //   subItemSevenTitle: moment().add(3, 'day').format('ddd DD  MMMM'),
        //   subItemSevenURL: '/racing-v3/h/'+tommorowDayTwo,
        // },
        // {
        //   icon: 'racing',
        //   id: 'result',
        //   mobile: true,
        //   order: 2,
        //   route: '/racing-v3/r/'+serverDateTime(new Date).format('YYYY-MM-DD'),//'/racing-v3', //@HW edit 25Nov2019
        //   routeOptions: {trigger: true, keepAdvert: true},
        //   showOnSidebar: true,
        //   sidebarOrder: 5,
        //   title: 'Result',
        //   subMemu: '1',

        //   subItemOne: null,

        //   subItemTwo: RACING_TYPES_LOOKUP[RACING_THOROUGHBRED_CODE],
        //   subItemTwoTitle: "Today's Racing",
        //   subItemTwoURL: '/racing-v3/r/'+serverDateTime(new Date).format('YYYY-MM-DD'),

        //   subItemThree: RACING_TYPES_LOOKUP[RACING_GREYHOUNDS_CODE],
        //   subItemThreeTitle: "Today's Greyhounds",
        //   subItemThreeURL: '/racing-v3/g/'+serverDateTime(new Date).format('YYYY-MM-DD'),

        //   subItemFour: RACING_TYPES_LOOKUP[RACING_HARNESS_CODE],
        //   subItemFourTitle: "Today's Harness",
        //   subItemFourURL: '/racing-v3/h/'+serverDateTime(new Date).format('YYYY-MM-DD'),

        //   subItemFive: RACING_TYPES_LOOKUP[RACING_HARNESS_CODE],
        //   subItemFiveTitle: 'Previous Result',
        //   subItemFiveURL: '/racing-v3/r/'+yesterday,

        //   subItemSix: null,
        //   subItemSeven: null,
        // },
        // {
        // 	icon: 'sports',
        // 	id: 'sports',
        // 	mobile: true,
        // 	order: 3,
        // 	route: '/sports', // @HW 26Sep2019
        // 	routeOptions: { trigger: true, keepAdvert: true },
        // 	showOnSidebar: true,
        // 	sidebarOrder: 6,
        // 	title: 'Sports',
        //   sportMenu: '1',


        //   sportItemOne: SPORT_BASKET_BALL_CODE,
        //   sportItemOneIcon:SPORT_BASKET_BALL_ICON_CODE,
        //   sportItemOneURL:'/sports/'+SPORT_BASKET_BALL_ICON_CODE,

        //   sportItemTwo: SPORT_SOCCER_CODE,
        //   sportItemTwoIcon:SPORT_SOCCER_ICON_CODE,
        //   sportItemTwoURL:'/sports/'+SPORT_SOCCER_ICON_CODE,

        //   sportItemThree:null,

        //   sportItemFour:'SportResult',
        //   sportItemFourTitle:'Previous Result Sport',
        //   sportItemSevenURL: '',
        // },
        // {
        // 	icon: 'trophy',
        // 	id: 'tournaments',
        // 	order: 4,
        // 	route: '/tournaments',
        // 	primary: false,
        // 	showOnSidebar: true,
        // 	sidebarOrder: 7,
        // 	title: 'Tournaments',
        // },
        // {
        // 	icon: 'trophy',
        // 	id: 'play',
        // 	mobile: true,
        // 	primary: false,
        // 	order: 5,
        // 	route: '/tournaments',
        // 	title: 'Play',
        // },
        // {
        // 	icon: 'promotion',
        // 	id: 'promotions',
        // 	order: 6,
        // 	primary: false,
        // 	route: '/promotions',
        // 	secondary: true,
        // 	showOnSidebar: true,
        // 	sidebarOrder: 1,
        // 	title: 'Promotions',
        // },
        {
          icon: 'racing',
          id: 'today',
          order: 6,
          primary: false,
          route: '/racing-v3/r/' + serverDateTime(new Date).format('YYYY-MM-DD'),
          secondary: true,
          showOnSidebar: true,
          sidebarOrder: 1,
          title: 'TODAY`S RACING',
        },

        {
          icon: 'racing',
          id: 'tomorrow',
          order: 6,
          primary: false,
          route: '/racing-v3/r/' + tommorow,
          secondary: true,
          showOnSidebar: true,
          sidebarOrder: 2,
          title: 'TOMORROW`S RACING',
        },

        {
          icon: 'racing',
          id: 'sunday',
          order: 6,
          primary: false,
          route: '/racing-v3/r/' + tommorowDayOne,
          secondary: true,
          showOnSidebar: true,
          sidebarOrder: 3,
          title: `${day}`,
        },



        // {
        // 	icon: 'my-bets',
        // 	id: 'playerBets',
        // 	meta: false,
        // 	mobile: true,
        // 	order: 4,
        // 	primary: false,
        // 	route: '/player-bets',
        // 	routeOptions: { trigger: true, keepAdvert: true },
        // 	secondary: true,
        // 	showOnSidebar: true,
        // 	sidebarOrder: 5,
        // 	title: 'Player Bets',
        // },
        // {
        // 	icon: 'az-sports',
        // 	id: 'azSports',
        // 	order: 5,
        // 	primary: false,
        // 	secondary: true,
        // 	title: 'A-Z Sports',
        // },
      ]).entities.navigationItems,
    };
  }
  return entityReducerGenerator(state, action);
};
const navigationSubItems = (state = {}, action) => {
  if (Object.values(state).length === 0) {
    state = {
      ...state,
      ...normalizeNavigationItems([
        // {
        //   icon: 'racing',
        //   id: 'racing',
        //   mobile: true,
        //   order: 1,
        //   route: '/racing-v3/r/'+serverDateTime(new Date).format('YYYY-MM-DD'),//'/racing-v3', //@HW edit 25Nov2019
        //   routeOptions: { trigger: true, keepAdvert: true },
        //   showOnSidebar: true,
        //   sidebarOrder: 2,
        //   title: 'Racing',
        // },
        // {
        //   icon: 'sports',
        //   id: 'sports',
        //   mobile: true,
        //   order: 2,
        //   route: '/sports-v3', // @HW 26Sep2019
        //   routeOptions: { trigger: true, keepAdvert: true },
        //   showOnSidebar: true,
        //   sidebarOrder: 3,
        //   title: 'Sports',
        // },
        // {
        //   icon: 'trophy',
        //   id: 'tournaments',
        //   order: 3,
        //   route: '/tournaments',
        //   primary: false,
        //   showOnSidebar: true,
        //   sidebarOrder: 4,
        //   title: 'Tournaments',
        // },
        // {
        //   icon: 'trophy',
        //   id: 'play',
        //   mobile: true,
        //   primary: false,
        //   order: 1,
        //   route: '/tournaments',
        //   title: 'Play',
        // },
        // {
        //   icon: 'promotion',
        //   id: 'promotions',
        //   order: 6,
        //   primary: false,
        //   route: '/promotions',
        //   secondary: true,
        //   showOnSidebar: true,
        //   sidebarOrder: 1,
        //   title: 'Promotions',
        // },

        // {
        //   icon: 'my-bets',
        //   id: 'playerBets',
        //   meta: false,
        //   mobile: true,
        //   order: 4,
        //   primary: false,
        //   route: '/player-bets',
        //   routeOptions: { trigger: true, keepAdvert: true },
        //   secondary: true,
        //   showOnSidebar: true,
        //   sidebarOrder: 5,
        //   title: 'Player Bets',
        // },
        // {
        //   icon: 'az-sports',
        //   id: 'azSports',
        //   order: 5,
        //   primary: false,
        //   secondary: true,
        //   title: 'A-Z Sports',
        // },
        // @HW add new next jump 25Nov2019
        // {
        //   icon: 'racing',
        //   id: 'nextJump',
        //   order: 6,
        //   primary: false,
        //   route: '/next-jump-races',
        //   secondary: true,
        //   showOnSidebar: true,
        //   sidebarOrder: 4,
        //   title: 'Next Jump Races',
        // },
      ]).entities.navigationSubItems,
    };
  }
  return entityReducerGenerator(state, action);
};



/**
 * Entity Reducers
 *
 * Each entity reducer will accept the entire entities state and a custom build action (See buildEntityAction)
 */
const entityReducers = {
  base_competitions: entity(['competitions']),
  derivative_markets: entity(['derivative_selections']),
  meetings: entity(['races']),
  races: entity(['raceSelections', 'derivative_markets']),
  sports: entity(['base_competitions']),
  tournamentBets: entity(['bet_selections']),
  tournamentGroups: entity(['tournaments']),
  tournaments,
  navigationItems,
  navigationSubItems,
  competitions: entity(['events']),
  markets: entity(['marketTypeGroups']),
  events: entity(['markets', 'teams']),
};

/**
 *
 * Entity Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const entities = (state = initialState, action) => {
  switch (action.type) {
    /**
     * Actions for when the authenticated user is set
     */
    case SET_AUTHENTICATED_USER:
      return Object.assign({}, state, { users: action.users });

    /**
     * Reducer for clearing the authenticated user
     */
    case CLEAR_AUTHENTICATED_USER:
      return Object.assign({}, state, {
        creditCards: initialState.creditCards,
        deposits: initialState.deposits,
        users: initialState.users,
        bets: initialState.bets,
        bet_selections: initialState.bet_selections,
        tickets: initialState.tickets,
        tournamentBets: initialState.tournamentBets,
        nextTickets: initialState.nextTickets,
        withdrawals: initialState.withdrawals,
        bankAccounts: initialState.bankAccounts,
      });

    case CLEAR_DEPOSITS_DATA:
      return {
        ...state,
        deposits: initialState.deposits,
      };

    case SET_MARKETS_FOR_EVENT:
      return {
        ...state,
        events: eventsReducer(state.events, action),
      };

    case WITHDRAWALS_CLEAR_ENTITIES:
      return {
        ...state,
        bankAccounts: initialState.bankAccounts,
        creditCards: initialState.creditCards,
      };
    /**
     * Standard Entity handlers
     */
    case MERGE_ENTITIES:
    case REMOVE_ENTITIES:
    case REPLACE_ENTITIES:
    case RESET_ENTITIES:
    case UPDATE_ENTITIES:
      // NOTE: this was reduced to an un-readable form to avoid eslint complaints.
      // Looping through the supplied entities and calling the entity.js reducer, which handles
      // merging, replacing, resetting and removing entities from state
      return Object.assign(
        {},
        Object.keys(action.entities).reduce((eObj, key) => {
          const reducer = entityReducers[key] || entity();
          return (eObj[key] = reducer(eObj, buildEntityAction(action, key)));
        }, Object.assign({}, state)),
      );
  }

  return Object.assign(
    {},
    Object.keys(entityReducers).reduce((eObj, key) => {
      let reducer = entityReducers[key] || entity();
      let initialState = reducer(state[key], {});
      return {
        ...eObj,
        [key]: initialState,
      };
    }, Object.assign({}, state)),
  );
};

/**
 * Events reducer
 */
const eventsReducer = (state, action) => {
  switch (action.type) {
    case SET_MARKETS_FOR_EVENT: {
      const event = { ...state[action.payload.eventId] };
      return {
        ...state,
        [event.id]: {
          ...event,
          markets: action.payload.markets,
        },
      };
    }
  }
};

/**
 * Exports
 */
module.exports = entities;
