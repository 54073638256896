/**
 * Created by Josef Frank on 2017/04/03.
 */
'use strict';

// Constants
import {SPORT_UPCOMING_BASE_URL, SPORTS_BASE_URL} from '../pages/Sports/SportsConstants';

// Functions
import {slugify} from '../legacy/core/format';

/**
 * Builds a route to an individual sport.
 *
 * @param sportName
 * @returns {*}
 */
export const buildRouteToSport = function (sportName) {
	// This function only accepts a string
	if (typeof sportName !== 'string') {
		throw new Error('buildRouteToSport requires a string');
	}

	// Builds a proper string
	return `${SPORTS_BASE_URL}/${slugify(sportName)}`;
};

/**
 * Given the current state of the application, build the current route
 *
 * @param state
 * @returns {string}
 */
export const buildSportsRouteFromState = (state) => {

	let {entities, sportsHome} = state;
	let {selectedSport, selectedCompetition, selectedEvent, selectedSportName} = sportsHome;

	let sport = entities.sports[selectedSport];

	// NOTE: This should only happen when you load a sport from the past, where its not available in one of the 'visible-sports'
	if (!sport && selectedSport) {
		sport = {
			id: selectedSport,
			name: selectedSportName || 'unknown'
		};
	}
	let competition = entities.competitions[selectedCompetition];
	let event = entities.events[selectedEvent];

	return buildSportRoute(sport, competition, event);
};


export const buildSportsRouteFromUpcoming = () => {
  return `/${SPORT_UPCOMING_BASE_URL}`;
};

/**
 * But the URL for base sports
 *
 * @param url
 * @returns {string}
 */
export const slugifyBaseURL = (url = SPORTS_BASE_URL) => `/${url}`;


/**
 * Build and slugify the url for the selected sport
 *
 * @param sport
 * @returns {string}
 */
export const slugifySportURL = (sport) => `/${slugify(sport.name)}`;

/**
 * Build and slugify the url for the selected competition
 *
 * @param competition
 * @returns {string}
 */
export const slugifyCompetitionURL = (competition) => `/${slugify(competition.name)}-${competition.id}`;

/**
 * Build and slugify the url for the selected event
 *
 * @param event
 * @returns {string}
 */
export const slugifyEventURL = (event) => `/${slugify(event.name)}-${event.id}`;

/**
 * Core function to build routes for sports purposes.
 * The function follows the sequence bellow:
 *
 *     /sports -> sport -> competition (name, id)-> event (name, id)
 *
 * In case where the function runs with missing data the result route will
 * be as long as the parameters given, respecting the sequence above.
 *
 * @param {object} sport
 * @param {object} competition
 * @param {object} event
 * @return {string}
 */
export const buildSportRoute = (sport = {}, competition = {}, event = {}) => {
	let route = slugifyBaseURL();

	if (!sport.name) {return route;}
	route += slugifySportURL(sport);

	if (!competition.name || !competition.id) {return route;}
	route += slugifyCompetitionURL(competition);

	if (!event.name || !event.id) {return route;}
	route += slugifyEventURL(event);

	return route;
};

/**
 * This function is meant to build the route for any given denormalized Event.
 * The application has Circular Relationship set and running and should bring
 * the whole chain up to Sport when every related entity lives in the store.
 * Otherwise, if the given Event exists but another entity does not, '/sports'
 * should be returned.
 *
 * @param event denormalized entity
 */
export const buildRouteForSportEvent = (event) => {

	let competition = event && event.competition;

	/*
	If the full tree to sport is truthy, grab sport.
	 */
	let sport = event.competition &&
		event.competition.base_competition &&
		event.competition.base_competition.sport;

	return buildSportRoute(sport, competition, event);
};

export const buildSportRouteForObject = (ntjEvent = {}) => {

	return buildSportRoute(
		{name: ntjEvent.sport_name},
		{name: ntjEvent.competition_name, id: ntjEvent.competition_id},
		{name: ntjEvent.event_name, id: ntjEvent.event_id}
	);
};
