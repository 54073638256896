import React from 'react';
import { RACING_THOROUGHBRED_CODE, RACING_TYPES_ICONS } from '../../../../common/constants/Racing';


const RacingIcon = ({type=RACING_THOROUGHBRED_CODE,color='white',width=38,height=32,classname,onClick}) => {
    return (
        <img src={RACING_TYPES_ICONS[type][color]} className={classname} style={{ width:width, height:height }} onClick={onClick}  />
    );
};

export default RacingIcon;