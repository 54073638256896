import { RS_TIPS_BASE_URL, RS_TIPS_SUB_APP } from '../pages/RSTips/RSTipsConstants';
import { scrollToTop, setBodyLoading, show404 } from '../store/application/applicationActions';
import { fetchNextToJumpRaces_GRS } from '../store/entities/actions/NextToJumpRaceActions';

const RSTipsRouter = Backbone.Router.extend({
    /* standard routes can be mixed with appRoutes/Controllers above */
    routes: {
        [RS_TIPS_BASE_URL]: 'showRSTips'
    },

    showRSTips: async function () {
        App.startSubApp(RS_TIPS_SUB_APP);
        scrollToTop();
        App.store.dispatch(setBodyLoading(false));
        try {
            await Promise.all([
             App.store.dispatch(fetchNextToJumpRaces_GRS()),
            ]);
            scrollToTop();
          } catch (e) {
           // console.log("caba",e);
           App.store.dispatch(show404());
          }
    },



});

module.exports = new RSTipsRouter();