import moment from 'moment';

// Constants
const {
	SET_ACTIVE_BET_FILTER,
	SET_TOURNAMENTS_HOME_ACTIVE_MODAL,
	SET_TOURNAMENTS_HOME_ORDER_DIRECTION,
	SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_END_DATE,
	SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_FILTER,
	SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_START_DATE,
	SET_TOURNAMENTS_HOME_DAILY_RACES_COLLAPSED,
	SET_TOURNAMENTS_HOME_DECK_ITEMS,
	SET_TOURNAMENTS_HOME_IN_PLAY_COLLAPSED,
	SET_TOURNAMENTS_HOME_INITIAL_LOAD_OF_TICKETS,
	SET_TOURNAMENTS_HOME_SELECTED_TOURNAMENT,
	SET_TOURNAMENTS_HOME_LOADING,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_FILTER,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_COLLAPSED,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_END_DATE,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_FILTER,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_IS_LOADING,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_RESULTS,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_START_DATE,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SELECTED,
	SET_TOURNAMENTS_HOME_NAVIGATION,
	SET_TOURNAMENTS_HOME_NTP_COLLAPSED,
	SET_TOURNAMENTS_HOME_NTP_FILTER,
	SET_TOURNAMENTS_HOME_NTP_LOADING,
	SET_TOURNAMENTS_HOME_NTP_SEARCH_TERM,
	SET_TOURNAMENTS_HOME_NTP_SEARCH_RESULTS,
	SET_TOURNAMENTS_HOME_TICKET_PURCHASE_ERROR_MESSAGE,
	SET_TOURNAMENTS_HOME_TICKET_PURCHASE_LOADING,
	SET_TOURNAMENTS_HOME_TRENDING_COLLAPSED,
	SET_TOURNAMENTS_HOME_FILTER_AND_ORDER,
	SET_TOURNAMENTS_HOME_DATE_FILTER,
	SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_COLLAPSED,
	SET_TOURNAMENTS_HOME_DAILY_SCHEDULE_VISIBILITY,
} = require('../../tournamentsHome/tournamentsHomeActionTypes');

const { MERGE_ENTITIES, REPLACE_ENTITIES, UPDATE_ENTITIES } = require('../EntityActionTypes');

const {
	ADVANCED_SEARCH_LAST_MONTH,
	ADVANCED_SEARCH_LAST_SEVEN_DAYS,
	ADVANCED_SEARCH_YESTERDAY,
	MY_TOURNAMENTS_SEARCH_LAST_MONTH,
	MY_TOURNAMENTS_SEARCH_LAST_SEVEN_DAYS,
} = require('../../../pages/Tournaments/TournamentsConstants');

// Functions
const { normalizeTournamentGroups } = require('../selectors/TournamentGroupSelectors');
const { normalizeTournaments } = require('../schemas/TournamentSchema');
import { normalizeNextToPlayTournaments } from '../schemas/NextToPlayTournamentsSchema';
const { createAction, mergeEntities } = require('../../../common/actions/actionHelpers');
const { getLocalTime } = require('../../../common/Date');
import { get } from '../../../common/Ajax';

/**
 * Fetches a singular tournament
 * @param tournamentId
 * @param eventId
 * @return {function}
 *
 */
const fetchTournament = (tournamentId, eventId) => (dispatch) => {
	let params = {
		tournament_id: tournamentId,
	};

	if (eventId) {
		params.event_id = eventId;
	}

	return get('combined/tournament/events', { params })
		.then((response) => {
			dispatch({
				type: MERGE_ENTITIES,
				...normalizeTournaments([response.data.data]),
			});
			return response;
		})
		.catch((e) => {
			console.warn(e);
		});
};

/**
 * Fetch all the Next To Jump Tournaments
 */
const fetchNextToJumpTournaments = () => (dispatch) => {
	return get('tournaments/next-to-jump').then((response) => {
		const normalizedData = normalizeNextToPlayTournaments(response.data.data);
		dispatch(mergeEntities(normalizedData.entities));
		return normalizedData.result;
	});
};

/**
 * Fetch Tournaments Reducers
 *
 * @returns {function(*)}
 */
const fetchTournamentsCombined = () => (dispatch) => {
	dispatch(setTournamentsHomeLoading(true));

	return get('combined/tournament-groups/tournaments')
		.then((response) => {
			let action = Object.assign(
				{
					type: MERGE_ENTITIES,
				},
				normalizeTournamentGroups(response.data.data),
			);
			dispatch(action);
		})
		.catch(() => {
			console.warn('Error on fetching combined tournaments');
		});
};

/**
 * Base search method
 *
 * @param searchTerm
 * @param startDate
 * @param endDate
 * @param entered
 */
const searchTournaments = (searchTerm, startDate, endDate, entered) => (dispatch) => {
	let params = {};

	// Set the search term if there is one defined
	if (searchTerm) {
		params.name = searchTerm;
	}

	// Set the start date if one was given
	if (startDate) {
		params.start_date = startDate;
	}

	// Set the end date if one was given
	if (endDate) {
		params.end_date = endDate;
	}

	// Set the entered boolean if there is one supplied
	if (entered !== undefined) {
		params.entered = entered;
	}

	return get('tournaments/filtered', { params })
		.then((response) => {
			let normalizedTournaments = normalizeTournaments(response.data.data);
			if (normalizedTournaments.entities) {
				let action = {
					type: REPLACE_ENTITIES,
					entities: normalizedTournaments.entities,
				};
				dispatch(action);
			}

			return response;
		})
		.catch((error) => {
			console.warn(error);
		});
};

/**
 * Returns the action for merging a single tournament.
 *
 * @param tournament
 * @returns {Object}
 */
const mergeTournament = (tournament) => {
	return mergeTournaments([tournament]);
};

/**
 * Returns the action for merging an array of tournaments.
 *
 * @param tournaments
 * @returns {Object}
 */
const mergeTournaments = (tournaments) => {
	return {
		type: MERGE_ENTITIES,
		...normalizeTournaments(tournaments),
	};
};

/**
 * Returns the action for merging a single tournament.
 *
 * @param tournament
 * @returns {Object}
 */
const updateTournament = (tournament) => {
	return updateTournaments([tournament]);
};

/**
 * Returns the action for merging an array of tournaments.
 *
 * @param tournaments
 * @returns {Object}
 */
const updateTournaments = (tournaments) => {
	return {
		type: UPDATE_ENTITIES,
		...normalizeTournaments(tournaments),
	};
};

/**
 * Performed an advanced search through past events.
 *
 * @param text
 * @param filter
 * @returns {Function}
 */
const performAdvancedSearch = (text, filter) => (dispatch) => {
	// The end date will be the start of tomorrow
	let endDate = getLocalTime()
		.add(1, 'day')
		.startOf('day');
	let startDate = endDate.clone();

	switch (filter) {
		case ADVANCED_SEARCH_YESTERDAY:
			endDate.subtract(1, 'day');
			startDate.subtract(2, 'day');
			break;

		case ADVANCED_SEARCH_LAST_SEVEN_DAYS:
			startDate.subtract(7, 'day');
			break;

		case ADVANCED_SEARCH_LAST_MONTH:
			startDate.subtract(1, 'month');
			break;

		default:
			startDate.subtract(1, 'day');
	}

	dispatch(setNTPSearchTerm(text));
	return dispatch(searchTournaments(text, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))).then(
		(response) => {
			dispatch(setNTPSearchResults(response.data.data.map((tournament) => tournament.id)));
		},
	);
};

/**
 * Performed a search for entered tournaments through past events.
 *
 * @param filter
 * @returns {Function}
 */
const performMyTournamentsSearch = (filter) => (dispatch) => {
	// The end date will be the start of today
	let endDate = getLocalTime().startOf('day');
	let startDate = endDate.clone().subtract(1, 'day');

	switch (filter) {
		case MY_TOURNAMENTS_SEARCH_LAST_SEVEN_DAYS:
			startDate.subtract(1, 'week');
			break;

		case MY_TOURNAMENTS_SEARCH_LAST_MONTH:
			startDate.subtract(1, 'month');
			break;
	}

	return dispatch(searchTournaments('', startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), true)).then(
		(response) => {
			dispatch(setMyTournamentsSearchResults(response.data.data.map((tournament) => tournament.id)));
		},
	);
};

const setActiveModal = (payload) => {
	return {
		type: SET_TOURNAMENTS_HOME_ACTIVE_MODAL,
		payload,
	};
};

const setMyTournamentsFilter = (payload) => {
	return {
		type: SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_FILTER,
		payload,
	};
};

const setMyTournamentsSelectedTournament = (payload) => {
	return {
		type: SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SELECTED,
		payload,
	};
};

const setTicketPurchaseErrorMessage = (payload) => {
	return {
		type: SET_TOURNAMENTS_HOME_TICKET_PURCHASE_ERROR_MESSAGE,
		payload,
	};
};

const setTicketPurchaseLoading = (payload) => {
	return {
		type: SET_TOURNAMENTS_HOME_TICKET_PURCHASE_LOADING,
		payload,
	};
};

/**
 * Build action for setting the Daily Races collapsedness
 *
 * @param isCollapsed
 * @returns {{type: *, filter: *}}
 */
const setDailyRacesCollapsed = (isCollapsed) => {
	return createAction(SET_TOURNAMENTS_HOME_DAILY_RACES_COLLAPSED, isCollapsed);
};

const setDeckItems = (payload) => {
	return {
		type: SET_TOURNAMENTS_HOME_DECK_ITEMS,
		payload,
	};
};

const setSelectedTournament = (payload) => {
	return {
		type: SET_TOURNAMENTS_HOME_SELECTED_TOURNAMENT,
		payload,
	};
};

/**
 * Set the order direction
 *
 * @param direction
 * @returns {*}
 */
const setOrderDirection = (direction = 'ASC') => {
	return createAction(SET_TOURNAMENTS_HOME_ORDER_DIRECTION, direction);
};

/**
 * Build action for setting the advanced search end date
 *
 * @param endDate
 * @returns {Object}
 */
const setAdvancedSearchEndDate = (endDate) => {
	return createAction(SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_END_DATE, endDate);
};

/**
 * Build action for setting the advanced search filter
 *
 * @param filter
 * @returns {Object}
 */
const setAdvancedSearchFilter = (filter) => {
	return createAction(SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_FILTER, filter);
};

/**
 * Build action for setting the advanced search start date
 *
 * @param startDate
 * @returns {Object}
 */
const setAdvancedSearchStartDate = (startDate) => {
	return createAction(SET_TOURNAMENTS_HOME_ADVANCED_SEARCH_START_DATE, startDate);
};

/**
 * Build action for setting the In Play collapsedness
 *
 * @param isCollapsed
 * @returns {{type: *, filter: *}}
 */
const setInPlayCollapsed = (isCollapsed) => {
	return createAction(SET_TOURNAMENTS_HOME_IN_PLAY_COLLAPSED, isCollapsed);
};

/**
 * Build action for setting the initial load of tickets flag
 *
 * @param isLoaded
 * @returns {{type: *, filter: *}}
 */
const setInitialLoadOfTickets = (isLoaded) => {
	return createAction(SET_TOURNAMENTS_HOME_INITIAL_LOAD_OF_TICKETS, isLoaded);
};

/**
 * Build action for setting whether or not the my tournaments search is collapsed
 *
 * @param isCollapsed
 * @returns {Object}
 */
const setMyTournamentsSearchCollapsed = (isCollapsed) => {
	return createAction(SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_COLLAPSED, isCollapsed);
};

/**
 * Build action for setting the my tournaments search end date
 *
 * @param endDate
 * @returns {Object}
 */
const setMyTournamentsSearchEndDate = (endDate) => {
	return createAction(SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_END_DATE, endDate);
};

/**
 * Build action for setting the my tournaments search filter
 *
 * @param filter
 * @returns {Object}
 */
const setMyTournamentsSearchFilter = (filter) => {
	return createAction(SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_FILTER, filter);
};

/**
 * Build action for setting whether or not my tournaments search is loading
 *
 * @param isLoading
 * @returns {Object}
 */
const setMyTournamentsSearchIsLoading = (isLoading) => {
	return createAction(SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_IS_LOADING, isLoading);
};

/**
 * Build action for setting the my tournaments search results
 *
 * @param results
 * @returns {Object}
 */
const setMyTournamentsSearchResults = (results) => {
	return createAction(SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_RESULTS, results);
};

/**
 * Build action for setting the my tournaments search start date
 *
 * @param startDate
 * @returns {Object}
 */
const setMyTournamentsSearchStartDate = (startDate) => {
	return createAction(SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_SEARCH_START_DATE, startDate);
};

/**
 * Build action for setting the NTP collapsedness
 *
 * @param isCollapsed
 * @returns {{type: *, filter: *}}
 */
const setNTPCollapsed = (isCollapsed) => {
	return createAction(SET_TOURNAMENTS_HOME_NTP_COLLAPSED, isCollapsed);
};

/**
 * Build action for setting the NTP Filter
 *
 * @param filter
 * @returns {{type: *, filter: *}}
 */
const setNTPFilter = (filter) => {
	return createAction(SET_TOURNAMENTS_HOME_NTP_FILTER, filter);
};

/**
 * Build action for setting the NTP loading flag
 *
 * @param loading
 * @returns {{type: *, loading: *}}
 */
const setNTPLoading = (loading) => {
	return createAction(SET_TOURNAMENTS_HOME_NTP_LOADING, loading);
};

/**
 * Build action for the date filter
 *
 * @param dateString
 * @returns {*}
 */
const setDateFilter = (dateString) => {
	return createAction(SET_TOURNAMENTS_HOME_DATE_FILTER, dateString);
};

/**
 * Set NTP Search
 *
 * @param searchTerm
 * @param startDate
 * @param endDate
 * @returns {function(*)}
 */
const setNTPSearch = (searchTerm, startDate, endDate, tournaments) => (dispatch) => {
	let term = searchTerm.toLowerCase();

	let results = tournaments.filter((tournament) => {
		let tournamentStartDate = moment(tournament.start_date);
		let dateCheck = tournamentStartDate.isAfter(startDate);
		if (endDate) {
			dateCheck = dateCheck && tournamentStartDate.isBefore(endDate);
		}
		let nameCheck = tournament.name.toLowerCase().search(term) > -1;
		return dateCheck && nameCheck;
	});

	dispatch(setNTPSearchTerm(searchTerm));
	dispatch(setNTPSearchResults(results.map((tournament) => tournament.id)));
};

/**
 * Build action for the NTP search results
 *
 * @param results
 * @returns {{type: *, results: *}}
 */
const setNTPSearchResults = (results) => {
	return {
		type: SET_TOURNAMENTS_HOME_NTP_SEARCH_RESULTS,
		results,
	};
};

/**
 * Build the action for the NTP search term
 *
 * @param searchTerm
 * @returns {{type: *, searchTerm: *}}
 */
const setNTPSearchTerm = (searchTerm) => {
	return {
		type: SET_TOURNAMENTS_HOME_NTP_SEARCH_TERM,
		searchTerm,
	};
};

/**
 * Set the active tournaments home navigation
 *
 * @param id
 * @returns {{type: *, payload: *}}
 */
const setSelectedNavigation = (id) => {
	return {
		type: SET_TOURNAMENTS_HOME_NAVIGATION,
		payload: id,
	};
};

/**
 * Build action for the tournament filter
 *
 * @param filter
 * @returns {{type: *, filter: *}}
 */
const setTournamentFilter = (filter) => {
	return {
		type: SET_ACTIVE_BET_FILTER,
		filter,
	};
};

/**
 * Build action for the tournaments home loading flag
 *
 * @param loading
 * @returns {{type: *, loading: boolean}}
 */
const setTournamentsHomeLoading = (loading = false) => {
	return {
		type: SET_TOURNAMENTS_HOME_LOADING,
		loading,
	};
};

/**
 * Build action for setting the active order field
 *
 * @param orderField
 * @param filter
 * @param dateFilter
 * @returns {*}
 */
const setOrderAndFilter = (orderField, filter, dateFilter) => {
	return createAction(SET_TOURNAMENTS_HOME_FILTER_AND_ORDER, {
		filter,
		orderField,
		dateFilter,
	});
};

/**
 * Build action for setting the trending collapsedness
 *
 * @param isCollapsed
 * @returns {{type: *, filter: *}}
 */
const setTrendingCollapsed = (isCollapsed) => {
	return createAction(SET_TOURNAMENTS_HOME_TRENDING_COLLAPSED, isCollapsed);
};

/**
 * Build action for setting the my tournaments collapsedness
 *
 * @param isCollapsed
 * @returns {{type: string, payload: *}}
 */
const setMyTournamentsCollapsed = (isCollapsed) => {
	return createAction(SET_TOURNAMENTS_HOME_MY_TOURNAMENTS_COLLAPSED, isCollapsed);
};

/**
 *
 * @param visible
 * @returns {*}
 */
const dailyScheduleVisible = (visible = true) => {
	return createAction(SET_TOURNAMENTS_HOME_DAILY_SCHEDULE_VISIBILITY, visible);
};

module.exports = {
	searchTournaments,
	dailyScheduleVisible,
	setMyTournamentsCollapsed,
	setDateFilter,
	setOrderAndFilter,
	fetchTournament,
	fetchTournamentsCombined,
	mergeTournament,
	mergeTournaments,
	performAdvancedSearch,
	performMyTournamentsSearch,
	setActiveModal,
	setAdvancedSearchEndDate,
	setAdvancedSearchFilter,
	setAdvancedSearchStartDate,
	setDailyRacesCollapsed,
	setDeckItems,
	setInPlayCollapsed,
	setInitialLoadOfTickets,
	setMyTournamentsFilter,
	setMyTournamentsSearchCollapsed,
	setMyTournamentsSearchEndDate,
	setMyTournamentsSearchFilter,
	setMyTournamentsSearchIsLoading,
	setMyTournamentsSearchResults,
	setMyTournamentsSearchStartDate,
	setMyTournamentsSelectedTournament,
	setNTPCollapsed,
	setNTPFilter,
	setNTPLoading,
	setNTPSearch,
	setNTPSearchResults,
	setNTPSearchTerm,
	setSelectedNavigation,
	setSelectedTournament,
	setTicketPurchaseErrorMessage,
	setTicketPurchaseLoading,
	setTournamentFilter,
	setTournamentsHomeLoading,
	setTrendingCollapsed,
	updateTournament,
	updateTournaments,
	setOrderDirection,
	fetchNextToJumpTournaments,
};
