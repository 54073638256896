import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, Text, Icon } from '@tbh/ui-kit';

const StyledReceipt = styled('div')(
	(props) => css`
		label: Receipt;
		display: flex;

		${props.hasBackground &&
			css`
				background-color: ${ui(props).color_4};
				padding: ${spacings(props).cozy}px;
			`};
	`,
);

const StyledReceipt__Method = styled(Text)(
	(props) => css`
		label: Receipt__Method;

		margin-top: ${spacings(props).cozy}px;
	`,
);

const Receipt = (props) => {
	const { t, hasBackground, error, errorTitle, icon, type, number, method, methodTitle, className } = props;

	const componentClasses = cx({
		[className]: className,
	});

	const receiptIds = Array.isArray(number) ? number : [number];

	return (
		<StyledReceipt className={componentClasses} hasBackground={hasBackground}>
			<Icon style={{ fontSize: '90px' }} icon={icon} push="2" />

			<Text>
				{error && errorTitle ? (
					<React.Fragment>
						<Text size="-1">{errorTitle}</Text>
						<Text size="-1" strong>
							{error}
						</Text>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Text key={type} size="-1">
							{`${t(type)} ${t('Receipt')}`}
						</Text>
						{receiptIds.map((receiptId) => {
							return (
								<Text key={receiptId} size="-1" strong>
									#{receiptId}
								</Text>
							);
						})}
					</React.Fragment>
				)}
				<StyledReceipt__Method size="-1">{methodTitle}</StyledReceipt__Method>
				<Text size="-1" strong>
					{method}
				</Text>
			</Text>
		</StyledReceipt>
	);
};

Receipt.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Payment Method */
	method: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,

	/** The title for the payment method */
	methodTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

	/** Type of receipt: Deposit, Bonus Bet... */
	type: PropTypes.string,

	/** Receipt number */
	number: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),

	/** Whether or not the Receipt component has a background */
	hasBackground: PropTypes.bool,

	/** What icon to display for the receipt */
	icon: PropTypes.string,

	/** Error message to display, if there is an error */
	error: PropTypes.string,

	/** Title of the error message */
	errorTitle: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,
};

Receipt.defaultProps = {
	className: null,
	hasBackground: false,
	icon: 'receipt',
	type: 'Deposit',
	number: [],
	error: null,
	errorTitle: null,
};

export default withNamespaces()(Receipt);
