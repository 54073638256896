import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, Icon, Notification } from '@tbh/ui-kit';

import ModalFooter from '../../Application/ModalFooter/ModalFooter';

const StyledExternalDepositResult = styled('div')(
	(props) => css`
		label: ExternalDepositResult;

		padding: ${spacings(props).cozy}px;
	`,
);

const StyledExternalDepositResult__Icon = styled(Icon)(
	(props) => css`
		label: ExternalDepositResult__Icon;

		display: block;
		line-height: unset;

		${props.depositSuccessful
			? css`
					color: ${ui(props).color_success};
			  `
			: css`
					color: ${ui(props).color_danger};
			  `};
	`,
);

class ExternalDepositResult extends React.Component {
	render() {
		const { className, depositSuccessful, t } = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledExternalDepositResult className={componentClasses}>
				<StyledExternalDepositResult__Icon
					depositSuccessful={depositSuccessful}
					icon={depositSuccessful ? 'check-circle' : 'close-circle'}
					style={{ fontSize: '5em' }}
				/>
				<Notification
					type={depositSuccessful ? Notification.types.COLOUR_SUCCESS : Notification.types.COLOUR_DANGER}
					message={
						depositSuccessful ? t('ExternalDepositResult__SuccessMessage') : t('ExternalDepositResult__FailMessage')
					}
				/>
				<ModalFooter />
			</StyledExternalDepositResult>
		);
	}
}

ExternalDepositResult.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra classes */
	className: PropTypes.string,

	/** Whether the deposit was successful or not */
	depositSuccessful: PropTypes.bool,
};

ExternalDepositResult.defaultProps = {
	className: '',
	depositSuccessful: false,
};

export default withNamespaces()(ExternalDepositResult);
