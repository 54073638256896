import { WELCOME_BASE_URL } from '../pages/Welcome/WelcomeConstants';

/**
 * Builds route to a welcome page.
 * this is simple
 * @returns {String}
 */
export const buildRouteToWelcome = () => {
  return `/${WELCOME_BASE_URL}`;
};


export const buildRacingWelcomeRouteFromState = (state) => {
  const {welcomeHome, entities} = state;

  // default case
  return `/${WELCOME_BASE_URL}`;
};
