import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import { List } from '@tbh/ui-kit';
import { ListItem } from '@tbh/ui-kit';
import RaceBetItem from '../RaceBetItem/RaceBetItem';

/**
 * Define all the race and bet statuses as defined in the racing constants file
 */
import { RACE_STATUSES } from '../../../../common/constants/Racing';
import { BET_RESULT_STATUSES } from '../../../../common/constants/Bets';

const RaceBetsList = (props) => {
	const { className, activeBets, raceStatus, handlecacheOut, cashout_limit , dailyBailOutLimit,bailOutIsEnabled} = props;

	const componentClasses = cx({
		[className]: className,
	});
	return (
		<List className={componentClasses} padding="1" >
			{activeBets.map((activeBet) => (
				<ListItem key={activeBet.id}>
					<RaceBetItem 
					    betItem={activeBet} 
						raceStatus={raceStatus} 
						handlecacheOut={handlecacheOut} 
						cashout_limit={cashout_limit} 
						dailyBailOutLimit={dailyBailOutLimit} 
						bailOutIsEnabled={bailOutIsEnabled}							
						/>
				</ListItem>
			))}
		</List>
	);
};

RaceBetsList.propTypes = {
	/** Used to send extra style classes through to the component. Used for the default media queries. */
	className: PropTypes.string,

	activeBets: PropTypes.arrayOf(
		PropTypes.shape({
			/** The ID of the bet placed */
			id: PropTypes.number.isRequired,

			/** An array of objects containing the bet selections */
			bet_selections: PropTypes.arrayOf(
				PropTypes.shape({
					selection_id: PropTypes.number.isRequired,
					selection_name: PropTypes.string.isRequired,
					selection_number: PropTypes.number.isRequired,
					margin: PropTypes.number,
				}),
			).isRequired,

			/** The type of bet that was placed ie. 'Win', 'Place', 'Trifecta' etc. */
			bet_type: PropTypes.string.isRequired,

			/** The amount that was placed on the bet */
			amount: PropTypes.number.isRequired,

			/** Bet status as defined in our racing constants */
			status: PropTypes.oneOf(BET_RESULT_STATUSES).isRequired,

			/** If an exotic bet type then this contains the percentage of the bet dividend that is returned to the punter */
			percentage: PropTypes.number,

			/** The odds that are being paid for the bet. This will be an approximate only if it is a tote product and unresulted */
			odds: PropTypes.number,

			/** The code for the odds product that the bet was placed with */
			product_code: PropTypes.string,
			/** How much the bet paid out */
			paid: PropTypes.number,

			/** Whether the bet is an exotic net or not ie. 'Trifecta', 'Quinella' etc. */
			is_exotic: PropTypes.bool,

			/** A string containing the exotic bet selections including whether is is boxed or not ie. '3, 4, 5, 6, 9 (BOXED)' */
			selection_string: PropTypes.string,

			/** Margin for a Butt bet */
			margin: PropTypes.string,

			
		}),
	).isRequired,

	/** The race status as defined in our racing constants */
	raceStatus: PropTypes.oneOf(RACE_STATUSES).isRequired,
	dailyBailOutLimit: PropTypes.number,
};

RaceBetsList.defaultProps = {
	className: null,
	activeBets: {
		percentage: null,
		odds: null,
		product_code: null,
		paid: 0,
		is_exotic: false,
		selection_string: null,
	},
	dailyBailOutLimit: 0,
};

export default RaceBetsList;
