// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withNamespaces } from 'react-i18next';

// Components
import { Select, SelectOption, Text } from '@tbh/ui-kit';

const TakeABreakLong = (props) => {
	const { onSelection, t } = props;

	return (
		<div>
			<Trans i18nKey="TakeABreakLong__Content">
				<Text size="-1" paragraph strong>
					If you take a short break from gambling on this site, this is what happens next:
				</Text>
				<Text size="-1" paragraph>
					All pending bets will stand.
				</Text>
				<Text size="-1" paragraph>
					Standing balance will be automatically withdrawn to last active withdrawal method when pending bets are
					resulted.
				</Text>
				<Text size="-1" paragraph>
					You will cease to receive marketing communications within 24hours.
				</Text>
				<Text size="-1" paragraph>
					You will be removed from all active Punting Clubs.
				</Text>
				<Text size="-1" paragraph>
					You will need to contact Customer Services to re-activate your account after the requested duration is over.
				</Text>

				<Text size="-1" paragraph strong>
					How long do you want a long break for?
				</Text>
			</Trans>

			<Select name="break" onChange={onSelection} margin="cozy">
				<SelectOption value="182" label={t('TakeABreakLong__6MonthsLabel')}>
					{t('TakeABreakLong__6MonthsValue')}
				</SelectOption>
				<SelectOption value="365" label={t('TakeABreakLong__1YearLabel')}>
					{t('TakeABreakLong__1YearValue')}
				</SelectOption>
				<SelectOption value="1095" label={t('TakeABreakLong__3YearLabel')}>
					{t('TakeABreakLong__3YearValue')}
				</SelectOption>
				<SelectOption value="1825" label={t('TakeABreakLong__5YearLabel')}>
					{t('TakeABreakLong__5YearValue')}
				</SelectOption>
			</Select>
		</div>
	);
};

TakeABreakLong.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Hook for when the selected value changes */
	onSelection: PropTypes.func.isRequired,
};

export default withNamespaces()(TakeABreakLong);
