// import { PLAYER_BETS_BASE_URL, PLAYER_BETS_SUB_APP } from '../common/constants/PlayerBetsHome';
import { POLI_CONFIRMATION_BASE_URL, POLI_CONFIRMATION_SUB_APP } from '../common/constants/PoliConfimation';
import { WELCOME_BASE_URL } from '../pages/Welcome/WelcomeConstants';
import { setBodyLoading, scrollToTop } from '../store/application/applicationActions';
import { navigate } from '../store/application/applicationActions';
const PoliConfirmationRouter = Backbone.Router.extend({
	routes: {
		[POLI_CONFIRMATION_BASE_URL]: 'showPoliConfirmationHome',
	},

	showPoliConfirmationHome: function() {
		const params = new URLSearchParams(window.location.search);

		// In case the token doesn't exist
		if (!params.has('token')) {
			App.store.dispatch(navigate(WELCOME_BASE_URL));

			return;
		}

		App.store.dispatch(setBodyLoading(false));

		scrollToTop();
		App.startSubApp(POLI_CONFIRMATION_SUB_APP);
	},
});

module.exports = new PoliConfirmationRouter();
