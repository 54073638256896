'use strict';

require('./PasswordResetRouter');
require('./PlayerBetsRouter');
require('./PromotionsRouter');
//require('./RacingRouter');
require('./RegistrationRouter');
require('./SportsRouter');
require('./TestRouter');
require('./TournamentRouter');
require('./UserAccountRouter');
require('./GRSRouter');
require('./CricketRouter');
require('./NextJumpRouter'); //@HW12Oct2019 next2jump page
require('./WelcomeRouter'); // @HW18Oct2019 welcome page
require('./SportsNextJumpRouter'); // @HW20Jan2020 sport nextjump page
require('./GRSSportsRouter'); // @HW24Jan2020 GRS sport page
require('./BetBuilderRouter'); // BetBuilderRouter @HW04JULY2020
require('./FeatureRacingRouter'); // add FeatureRacingRouter @HW09Oct2020
require('./PoliConfirmation'); // add PaymentConfirmation @HW10Set2022
require('./UserAccountV2Router'); // add UserAccountV2Router @HW19Set2022
require('./RSTipsRouter');
require('./DeactivatedAccountRouter');
require('./PromotionsV2Router');
require('./LegalRouter');
require('./NextToJump');





