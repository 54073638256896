// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';

// Functions
import { formatAsOdds } from '../../../../../legacy/core/format';

// Constants
import { SELECTION_NOT_SCRATCHED_STATUS } from '../../../../../common/constants/Racing';

// Components
import RaceSelectionDetails from '../../RaceSelectionsList/RaceSelectionDetails/RaceSelectionDetails';
import { BettingAdd, FlexText, Text } from '@tbh/ui-kit';

const StyledDerivativeSelection__Content = styled(FlexText)(
	(props) => css`
		label: DerivativeSelection__Content;

		height: 100%;
		padding: ${spacings(props).compact}px;
	`,
);

const StyledDerivativeSelection = styled(BettingAdd)(
	(props) => css`
		label: DerivativeSelection;

		width: 100%;
		cursor: pointer;

		${props.disabled &&
			css`
				cursor: default;
			`};
	`,
);

const DerivativeSelection = (props) => {
	const {
		action,
		barrier,
		className,
		disabled,
		jockey,
		name,
		number,
		odds,
		selectionId,
		silk,
		subtitle,
		trainer,
		type_code,
		weight,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * When the selection is clicked on, fire an action with the selection ID as the parameter
	 */
	const onSelectionClick = () => {
		action(selectionId);
	};

	return (
		<StyledDerivativeSelection
			className={componentClasses}
			actionSingle={disabled ? null : onSelectionClick}
			disabled={disabled}
		>
			<StyledDerivativeSelection__Content>
				<RaceSelectionDetails
					barrier={barrier}
					jockey={jockey}
					name={name}
					number={number}
					silk={silk}
					subtitle={subtitle}
					trainer={trainer}
					type_code={type_code}
					weight={weight}
					hideSilk={!silk}
					selection_status={SELECTION_NOT_SCRATCHED_STATUS}
				/>
				<Text size="-1">{formatAsOdds(odds)}</Text>
			</StyledDerivativeSelection__Content>
		</StyledDerivativeSelection>
	);
};

DerivativeSelection.propTypes = {
	/** Action to fire when the selection is clicked on */
	action: PropTypes.func.isRequired,

	/** The selection name */
	name: PropTypes.string.isRequired,

	/** The selection ID */
	selectionId: PropTypes.number.isRequired,

	/** If the derivative selection is disabled */
	disabled: PropTypes.bool,

	/** The race selection type */
	type_code: PropTypes.string,

	/** The selection barrier */
	barrier: PropTypes.number,

	/** Extra classes */
	className: PropTypes.string,

	/** The jockey name */
	jockey: PropTypes.string,

	/** The selection number */
	number: PropTypes.number,

	/** The selection odds */
	odds: PropTypes.number,

	/** The jockey silk */
	silk: PropTypes.string,

	/** Extra text to display under the runner */
	subtitle: PropTypes.string,

	/** The trainer name */
	trainer: PropTypes.string,

	/** The jockey weight */
	weight: PropTypes.number,
};

DerivativeSelection.defaultProps = {
	disabled: false,
	barrier: null,
	className: '',
	jockey: null,
	number: null,
	odds: 0,
	silk: null,
	trainer: null,
	weight: null,
	subtitle: null,
	type_code: null,
};

export default DerivativeSelection;
