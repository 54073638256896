// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { Trans, withNamespaces } from 'react-i18next';

import { spacings } from '@tbh/ui-kit';

// Components
import { Button, ButtonGroup, Heading, Notification, Panel, Text } from '@tbh/ui-kit';

const StyledTakeABreakConfirmation__Options = styled(ButtonGroup)(
	(props) => css`
		label: TakeABreakConfirmation__Options;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

/**
 * text to display when the tab 'Short Break' is active
 */
const short_break = (
	<Trans i18nKey="TakeABreakConfirmation__Short">
		<Text size="-1" paragraph strong>
			I hereby acknowledge:
		</Text>
		<Text size="-1" paragraph>
			My pending bets will stand.
		</Text>
		<Text size="-1" paragraph>
			My account balance will remain unchanged.
		</Text>
		<Text size="-1" paragraph>
			I will cease to receive marketing communications withing 24 hours.
		</Text>
		<Text size="-1" paragraph>
			I will not be able to bet with TopBetta.
		</Text>
		<Text size="-1" paragraph>
			I will not be able to open any new accounts.
		</Text>
		<Text size="-1" paragraph>
			This action will take effect immediately and cannot be undone.
		</Text>
	</Trans>
);

/**
 * text to display when the tab 'Long Break' is active
 */
const long_break = (
	<Trans i18nKey="TakeABreakConfirmation__Long">
		<Text size="-1" paragraph strong>
			I hereby acknowledge:
		</Text>
		<Text size="-1" paragraph>
			My pending bets will stand.
		</Text>
		<Text size="-1" paragraph>
			My balance will automatically be withdrawn to my last withdrawal method when my pending bets are resulted.
		</Text>
		<Text size="-1" paragraph>
			I will cease to receive marketing communications withing 24 hours.
		</Text>
		<Text size="-1" paragraph>
			This action will take effect immediately and cannot be undone.
		</Text>
		<Text size="-1" paragraph>
			I will need to contact Customer Services to re-activate my account after the requested duration is over.
		</Text>
	</Trans>
);

const TakeABreakConfirmation = (props) => {
	const { className, breakLabel, cancelBreak, confirmBreak, error, clearError, loading, t } = props;

	const takeBreakConfirmationClass = cx({
		[className]: className,
	});

	const handleConfirmBreak = () => {
		clearError();
		confirmBreak();
	};

	return (
		<div className={takeBreakConfirmationClass}>
			{error && (
				<Notification strong type={Notification.types.COLOUR_DANGER} message={error} buttonAction={clearError} />
			)}

			<Panel padding={Panel.padding.SPACING_COZY}>
				<Heading type="5" strong>
					{t('TakeABreakConfirmation__Title', { breakLabel })}
				</Heading>

				{breakLabel === '6 Months' ? long_break : breakLabel === '1 Year' ? long_break : short_break}

				<StyledTakeABreakConfirmation__Options center>
					<Button type="ghost" action={cancelBreak} flex>
						{t('TakeABreakConfirmation__ChangedMind')}
					</Button>

					<Button action={handleConfirmBreak} type="danger" loading={loading} flex>
						{t('Confirm')}
					</Button>
				</StyledTakeABreakConfirmation__Options>

				<Notification type={Notification.types.COLOUR_INFO} transparent>
					<Text type="info" size="-1">
						<Trans i18nKey="TakeABreakConfirmation__Notification">
							<strong>Note:</strong> You will be logged out immediately.
						</Trans>
					</Text>
				</Notification>
			</Panel>
		</div>
	);
};

TakeABreakConfirmation.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Selected length for the break */
	breakLabel: PropTypes.string.isRequired,

	/** Cancel and return to break selection screen */
	cancelBreak: PropTypes.func.isRequired,

	/** Function to clear the error message */
	clearError: PropTypes.func.isRequired,

	/** Hook for confirming break and blocking user */
	confirmBreak: PropTypes.func.isRequired,

	/** Error message for when the confirmation fails */
	error: PropTypes.string,

	/** Extra classes */
	className: PropTypes.string,

	/** If the request is loading or not */
	loading: PropTypes.bool,
};

TakeABreakConfirmation.defaultProps = {
	error: null,
	className: null,
	loading: false,
};

export default withNamespaces()(TakeABreakConfirmation);
