import { Icon, ProgressBar, ui } from '@tbh/ui-kit';
import React from 'react';
import styled, { css } from 'react-emotion';

const VerifyProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border: 1px solid #cacaca;
    border-radius:12px;
    padding:12px;
`;




const Title = styled.h1`
    font-size: 16px;
    color: #000000;
    font-weight: 800;   
    margin: 0;
`;

const Text = styled.p`
    font-size: 12px;
    font-weight: 500;   
    margin: 0;
`;

const VerificationItem = styled.div`
    display: flex;
    gap: 4px;
    // background: #28a745;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .tooltip {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover {
        .tooltip {
            visibility: visible;
            opacity: 1;
        }
    }
`;
const Tooltip = styled.div`
    position: absolute;
    bottom: 110%;
    left: 0;
    width: 200px;
    padding: 8px;
    border-radius: 4px;
    background: #666666;
    color: #ffffff;
`;
const StyledPopover__Arrow = styled('span')`
	${(props) => css`
		label: Popover__Arrow;

        display: block;
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        bottom: -15px;
        left: 5%;
        border-left: 8px solid rgba(255,255,255,0);
        border-right: 8px solid rgba(255,255,255,0);
        border-top: 8px solid #666666;
        border-bottom: 8px solid rgba(255,255,255,0);

		${props.error &&
        css`
				border-bottom-color: ${ui(props).color_danger};
			`};
	`};
`;


const VerificationItems = styled('div')`
    display: flex;
    flex-direction:row;
    gap:10px;
    flex-wrap:wrap;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;
export const VerifyProgress = ({
    green_id_status,
    email,
    phone,
}) => {
    const progress = {
        email: {
            value: email ? 1 : 0,
            title: 'Email',
            text: 'A email has been sent to your email address. Please click the link to verify your email address.',
        },
        phone: {
            value: phone ? 1 : 0,
            title: 'Phone Number',
            text: 'In your account creation process, you have verified your phone number.',
        },
        green_id_status: {
            value: green_id_status === 'VERIFIED' || green_id_status === 'VERIFIED_WITH_CHANGES' ||  green_id_status === 'VERIFIED_ADMIN' ? 1 : 0,
            title: 'ID Verification',
            text: 'You need to verify your ID to complete your account verification.',
        }
    };
    const completed = Object.values(progress).reduce((acc, value) => acc + value.value, 0);
    const total = Object.keys(progress).length;


    return (
        <VerifyProgressContainer>
            <Card style={{ width: '100%' }}>
                <Title>Verification Status</Title>
                <Icon icon="trophy" size={'-3'} />
                <ProgressBar percentage={(completed / total) * 100} className={css`padding:0;`} />
                <Text>{"You've"} compled {completed} out of {total} verifications tasks.</Text>
                <VerificationItems>
                    {Object.keys(progress).map((key) => (
                        <VerificationItem key={key} style={{
                            background: progress[key].value ? '#28a745' : '#dc3545',
                            color: '#ffffff',
                        }}>
                            {!progress[key].value && (
                                <Tooltip className="tooltip">
                                    <StyledPopover__Arrow />
                                    <Text>{progress[key].text}</Text>
                                </Tooltip>
                            )}
                            <Icon icon={progress[key].value ? 'checkmark' : 'close'} size={'-3'} />
                            <Text>{progress[key].title}</Text>
                        </VerificationItem>
                    )
                    )}
                </VerificationItems>
            </Card>

        </VerifyProgressContainer>
    );
};
