import styled, { css } from 'react-emotion';
import { spacings, ui, Button, TitleBlock } from '@tbh/ui-kit';
import Receipt from '../../Transactions/Receipt/Receipt';

export const StyledWithdrawalReceipt__InformationBody = styled('div')(
	(props) => css`
		label: WithdrawalReceipt__InformationBody;

		position: relative;
		background: ${ui(props).color_3};
		padding: 0 ${spacings(props).cozy}px ${spacings(props).cozy}px;
	`,
);

export const StyledWithdrawalReceipt__BalanceHeader = styled(TitleBlock)(
	(props) => css`
		label: WithdrawalReceipt__BalanceHeader;

		border-bottom: 1px solid ${ui(props).color_4};
		padding: ${spacings(props).cozy}px;
	`,
);

export const StyledWithdrawalReceipt__SuccessBody = styled('div')(
	(props) => css`
		label: WithdrawalReceipt__SuccessBody;

		padding: ${spacings(props).comfortable}px 0;
	`,
);

export const StyledWithdrawalReceipt__Receipt = styled(Receipt)(
	(props) => css`
		label: WithdrawalReceipt__Receipt;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

export const StyledWithdrawalReceipt__Button = styled(Button)(
	(props) => css`
		label: WithdrawalReceipt__Button;

		margin-top: ${spacings(props).cozy}px;
	`,
);
