import { denormalize, normalize } from 'normalizr';
import { marketsSchema } from './Entities';

/**
 * Convert normalized Market entities back to a structure similar to the API response
 *
 * @param entities
 * @param keys
 */
export const denormalizeMarkets = (entities, keys) => {
	return denormalize(keys || Object.keys(entities.markets), marketsSchema, entities).filter((mar) => !!mar);
};

/**
 * Normalize the Market structure to suit JS Applications
 *
 * @param response
 */
export const normalizeMarkets = (response) => {
	return normalize(response, marketsSchema);
};
