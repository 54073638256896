import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import BetTypeFiltersBasic from './BetTypeFiltersBasic/BetTypeFiltersBasic';
import BetTypeFiltersDropdown from './BetTypeFiltersDropdown/BetTypeFiltersDropdown';

import {
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_EXOTIC_BET_TYPES,
	RACING_BET_TYPES,
} from '../../../../common/constants/Racing';

const BetTypeFilters = (props) => {
	const {
		className,
		displayDropdownVersion,
		productsDisplayed,
		activeProduct,
		activeBetType,
		exoticsAvailable,
		isBoxedSelected,
		handleBetTypeChange,
		raceProductBetTypes,
		quaddieRaceNumbers,
		dailyDoubleRaceNumbers,
    selection,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	/**
	 * handles changing of the active bet type selection
	 *
	 * @param newBetType
	 * @param boxed
	 * @param newActiveProduct
	 */
	const activateBetType = (newBetType, boxed, newActiveProduct = activeProduct) => {
		// activate the new bet type with these new prop values
		handleBetTypeChange(newBetType, boxed, newActiveProduct);
	};

	return (
		<div className={componentClasses}>
			{displayDropdownVersion ? (
				<BetTypeFiltersDropdown
					selectBetType={activateBetType}
					activeBetType={activeBetType}
					isBoxedSelected={isBoxedSelected}
					exoticsAvailable={exoticsAvailable}
					openExoticPanel={false}
					productsDisplayed={productsDisplayed}
					activeProduct={activeProduct}
					raceProductBetTypes={raceProductBetTypes}
					quaddieRaceNumbers={quaddieRaceNumbers}
					dailyDoubleRaceNumbers={dailyDoubleRaceNumbers}
				/>
			) : (
				<BetTypeFiltersBasic
					selectBetType={activateBetType}
					activeBetType={activeBetType}
					isBoxedSelected={isBoxedSelected}
					exoticsAvailable={exoticsAvailable}
					raceProductBetTypes={raceProductBetTypes}
					quaddieRaceNumbers={quaddieRaceNumbers}
					dailyDoubleRaceNumbers={dailyDoubleRaceNumbers}
          availablebetproduct={selection}
				/>
			)}
		</div>
	);
};

BetTypeFilters.propTypes = {
	/** Function to handle the changing of the bet type */
	handleBetTypeChange: PropTypes.func.isRequired,

	/** Which bet type is currently active or selected */
	activeBetType: PropTypes.oneOf([RACING_NON_EXOTIC_BET_TYPE, ...RACING_EXOTIC_BET_TYPES]),

	/** Are exotics available for the current race */
	exoticsAvailable: PropTypes.bool,

	/** A collection of race numbers within this bet type */
	dailyDoubleRaceNumbers: PropTypes.string,

	/** A collection of race numbers within this bet type */
	quaddieRaceNumbers: PropTypes.string,

	/** Whether the bet type selections are boxed or not */
	isBoxedSelected: PropTypes.bool,

	/** The list of products available for the race */
	raceProductBetTypes: PropTypes.array,

	/**
	 * Determines which version of the component should be rendered
	 * There are basic and dropdown versions
	 */
	displayDropdownVersion: PropTypes.bool,

	/** An array of non exotic products to display on the dropdown version. ie. win & place products */
	productsDisplayed: PropTypes.arrayOf(
		PropTypes.shape({
			betType: PropTypes.string.isRequired,
			productLabel: PropTypes.string.isRequired,
		}),
	),

	/**
	 * The product code of the non exotic product to display as active on the dropdown version
	 *  NOTE: Needs to match a 'productCode' from the 'productsDisplayed' prop or be set to null is exotic is active
	 */
	activeProduct: PropTypes.string,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,
};

BetTypeFilters.defaultProps = {
	raceProductBetTypes: RACING_BET_TYPES,
	activeBetType: RACING_NON_EXOTIC_BET_TYPE,
	exoticsAvailable: true,
	isBoxedSelected: false,
	displayDropdownVersion: true,
	productsDisplayed: [],
	activeProduct: null,
	className: null,
	quaddieRaceNumbers: '',
	dailyDoubleRaceNumbers: '',
};

export default BetTypeFilters;
