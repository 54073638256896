import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, } from '@tbh/ui-kit';

const StyledBannCarouselSlide = styled('div')(
	(props) => css`
		label: BannCarouselSlide;

		&:hover {
			background: rgba(0, 0, 0, 0.05);
        }
        
        flex: 0 0 100%;
		max-width: 100%;

		${props.fitContent && css`
			flex: 0 0 auto;
			max-width: none;
			width: fit-content;
		`}
		${props.autoWidth &&
			css`
				width: auto !important;
			`};
    `,
    media(
		(props) =>
            css`
            flex: 0 0 100%;
		    max-width: 100%;
			${props.fitContent && css`
			flex: 0 0 auto;
			max-width: none;
			width: fit-content;
			`}
			`,
			424,
	),
);
const StyledBannCarouselSlideSpan = StyledBannCarouselSlide.withComponent('span');

class BannCarouselSlide extends React.Component {
	static propTypes = {
		/** Children for the carousel item */
		children: PropTypes.node.isRequired,

		/** If the carousel items shouldn't be links */
		noLinkItems: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,

		/** ID of the carousel item */
		itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

		/** Action to fire when the item is clicked on */
		onClick: PropTypes.func,

		/** If the current item is the active icon */
		active: PropTypes.bool,

		/** Whether to set width: auto */
        autoWidth: PropTypes.bool,
        
        /** The size of the component - used for media query logic */
        size: PropTypes.shape({
            width: PropTypes.number,
        }).isRequired,
	};

	static defaultProps = {
		noLinkItems: false,
		className: '',
		onClick: function() {},
		active: false,
		autoWidth: false,
	};

	constructor() {
		super();
		this.handleClick = this.handleClick.bind(this);
	}

	/**
	 * Handle click on tab
	 */
	handleClick() {
		this.props.onClick(this.props.itemId);
	}

	render() {
		const { children, className, autoWidth, noLinkItems, size,fitContent } = this.props;

		const classes = cx('swiper-slide', 'carousel-slide', {
			[className]: className,
		});

		if (noLinkItems) {
			return (
				<StyledBannCarouselSlide size={size} autoWidth={autoWidth} className={classes} fitContent={fitContent}>
					{children}
				</StyledBannCarouselSlide>
			);
		}

		return (
			<StyledBannCarouselSlideSpan size={size} autoWidth={autoWidth} className={classes} onClick={this.handleClick} fitContent={fitContent}>
				{children}
			</StyledBannCarouselSlideSpan>
		);
	}
}

BannCarouselSlide.StyledBannCarouselSlide = StyledBannCarouselSlide;
BannCarouselSlide.StyledBannCarouselSlideSpan = StyledBannCarouselSlideSpan;

export default BannCarouselSlide;
