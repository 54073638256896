// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { ButtonClose, Text, HeaderBar } from '@tbh/ui-kit';

const StyledModalHeader = styled(HeaderBar)`
	label: ModalHeader;
    border-radius: 4px 4px 0px 0px;
	height: 70px;
	text-transform: capitalize;
`;

const cssModalHeader__Title = css`
	label: ModalHeader__Title;

	align-items: center;
	display: inline-flex;
`;

const ModalHeader = (props) => {
	const { className, title, onClose, aside, t } = props;

	const modalHeaderClass = cx({
		[className]: className,
	});

	const nodeLeft = onClose ? <ButtonClose action={onClose} dark /> : null;

	return (
		<StyledModalHeader className={modalHeaderClass} strong type="primary" aside={aside} nodeLeft={nodeLeft}>
			<Text className={cssModalHeader__Title} size="1" type="alternate" strong>
				{typeof title === 'string' ? t(title) : title}
			</Text>
		</StyledModalHeader>
	);
};

ModalHeader.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Title for the header */
	title: PropTypes.any.isRequired,

	/** Hook for clicking the close button */
	onClose: PropTypes.func,

	/** Node for the right side of the HeaderBar */
	aside: PropTypes.node,

	/** Extra classes */
	className: PropTypes.string,
};

ModalHeader.defaultProps = {
	aside: null,
	className: null,
	onClose: null,
};

export default withNamespaces()(ModalHeader);
