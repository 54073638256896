import React, { useState } from 'react';
import styled, { css, keyframes } from 'react-emotion';
import { connect } from 'react-redux';
import { openNotification } from '../../../store/application/applicationActions';
import { cancelPartialBet, partialBet } from '../../../store/betPlacement/betPlacementActions';
import { fetchActiveBets, updateBetStatus } from '../../../store/entities/actions/BetActions';
import { getPendingBets } from '../../../store/entities/selectors/BetSelectors';
import { withNamespaces } from 'react-i18next';
import { Text, Icon, Ticker, Loader } from '@tbh/ui-kit';
import numeral from 'numeral';
import CurrencyDisplay from '../../../components/features/Application/Currency/CurrencyDisplay/CurrencyDisplay';
import { calculateCombinations, legsByResultString, parsePositions } from '../../../common/ExoticBets';
import { countdown, decimalPlaces } from '../../../legacy/core/format';
import { BREAKPOINTS } from '../../../constants/themes';
import { QUADDIES_BET_TYPES, RACING_EXOTIC_BET_TYPES, SRMTypes } from '../../../common/constants/Racing';
// Animation for notification sliding in
const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Styled Components
const NotificationsContainer = styled.div`
	@media (max-width: ${BREAKPOINTS.tabletMin}px) {
		display: flex;
	}
	display: none;
	position: fixed;
	right: 0;
	top: 10%;
	max-width: 400px;
	width: calc(100% - 50px);
	max-height: 80vh;
	overflow-y: auto;
	padding: 10px;
	flex-direction: column;
	gap: 10px;
	z-index: 1000;
`;

const Notification = styled.div`
	background: ${({ status }) => (status === 'Rejected' ? '#f0f0f0' : '#ffffff')};
	border: 1px solid #dddddd;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 15px;
	display: flex;
	flex-direction: column;
	animation: ${slideIn} 0.3s ease-out;
	opacity: 0.95;
	transition: opacity 0.3s ease, transform 0.3s ease;
	position: relative;

	&:hover {
		opacity: 1;
	}
`;

const CountdownContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	border: 1px solid black;
	border-radius: 8px;
	padding: 4px;
	background: #fff;
`;

const BetDetails = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
`;

const BetAmount = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: #850000;
`;

const BetStatus = styled.div`
	font-size: 13px;
	color: ${({ status }) => (status === 'REJECTED' ? '#f44336' : status === 'ACCEPTED' ? '#4caf50' : '#888888')};
	margin-top: 4px;
	margin-bottom: 4px;
`;

const Button = styled.button`
	border-radius: 4px;
	border: none;
	padding: 4px 12px;
	font-size: 12px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	${({ isAccept }) =>
		isAccept
			? css`
					background-color: #4caf50;
					color: #fff;

					&:hover {
						background-color: #45a049;
					}
			  `
			: css`
					background-color: #f44336;
					color: #fff;

					&:hover {
						background-color: #d32f2f;
					}
			  `}

	${({ disabled }) =>
		disabled &&
		css`
			background-color: #ddd;
			cursor: not-allowed;
		`}
`;

const Box = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

const BetInfo = ({ item, bet, children }) => (
	<Box>
		<Text size="-3" align="start" strong>
			R{item.race_number} - {item.competition_name}
		</Text>
		<Text size="-5" align="start" type="secondary" strong>
			{item.bet_type} {bet.product_code === 'GRSF' && '(Fixed)'} {bet.product_code === 'GRST' && '(Tote)'}
		</Text>
		{children}
	</Box>
);
const SelectionList = ({ selections }) =>
	selections.map(({ selection_number, selection_name }, index) => (
		<Text key={index} size="-4" align="start" type="secondary">
			#{selection_number} {selection_name}
		</Text>
	));

const SelectionListLegs = ({ legs }) => {
	return Object.keys(legs).map((key, index) => (
		<Text size="-3" key={key}>
			<span style={{ fontWeight: 600 }}>{numeral(index + 1).format('0o')} :</span>&nbsp;
			<span style={{ fontWeight: 400 }}>{legs[key].join(', ')}</span>
		</Text>
	));
};

const SelectionListSRM = ({ selections }) => {
	return selections.map(({ selection_number, selection_name, product }, index) => (
		<Text size="-3" key={selection_name}>
			<span style={{ fontWeight: 600 }}>{SRMTypes[product]}:</span>
			<span
				className={css`
					flex: 0 0 auto;
				`}
			>
				&nbsp;
			</span>
			<span style={{ fontWeight: 400, marginLeft: 5 }}>
				#{selection_number} {selection_name}
			</span>
		</Text>
	));
};

// Countdown Component
const Countdown = ({ betId, time, openNotification, setMessage, showTab }) => {
	// const calculateTime = (time) => new Date(time).getTime() - new Date().getTime();
	// const [sent, setSent] = useState(false);

	// useEffect(() => {
	// 	if (calculateTime(time) <= 0 && !sent) {
	// 		openNotification(`Your Partial Bet [${betId}] has expired`, 'danger');
	// 		setMessage('Your bet has expired.');
	// 		showTab();
	// 		setSent(true);
	// 	}
	// }, [time, sent, betId, openNotification, setMessage, showTab]);

	const c = countdown(time);

	return (
		<CountdownContainer>
			<Icon icon="clock" size="-2" />
			<Text size="-3" align="center" strong>
				{c}
			</Text>
		</CountdownContainer>
	);
};

const Message = styled.div`
	background: white;
	border-radius: 4px;
	border: 1px solid black;
	padding: 4px 6px;
	width: 100%;
	background: ${({ status }) => (status === 'REJECTED' ? '#f44336' : status === 'ACCEPTED' ? '#4caf50' : '#888888')};
	color: ${({ status }) => (status === 'REJECTED' ? '#fff' : status === 'ACCEPTED' ? '#fff' : '#000')};
	font-size: 11px;
	text-align: center;
	cursor: pointer;
	&:hover {
		background: ${({ status }) => (status === 'REJECTED' ? '#d32f2f' : status === 'ACCEPTED' ? '#45a049' : '#888888')};
		outline: none;
	}
`;

const CloseButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const CloseButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	font-size: 14px;
	z-index: 1000;
	&:hover {
		color: #850000;
	}
`;

const BackDrop = styled.div`
	background: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100%;
	position: absolute;
	border-radius: 8px;
	right: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const PendingBet = ({
	bet,
	handlePartialBet,
	handleClosePartialBet,
	openNotification,
	onClose,
	setMessage,
	showTab,
}) => {
	const { id, amount, flexiAmount, bet_selections, selection_string, product_code, bet_type, response } = bet;
	const item = bet_selections && bet_selections.length > 0 ? bet_selections[0] : {};
	const legs = selection_string ? legsByResultString(selection_string) : [];

	const isMultiLegBetType = QUADDIES_BET_TYPES.includes(bet_type);
	const isExotic = RACING_EXOTIC_BET_TYPES.includes(bet_type);
	const isSRM = bet_type === 'sameracemulti';

	const [awaitPartialBet, setAwaitPartialBet] = useState(false);
	const [loading, setLoading] = useState(false);

	const data = response && response[0];
	const status = data && data.status;
	const end_time = data && data.end_time;

	const handleAccept = async () => {
		setAwaitPartialBet(true);
		setLoading(true);
		try {
			await handlePartialBet({ betId: id, amount: data.partialAmount, isAcepted: true, isExotic, flexiAmount });
		} catch (error) {
			// Handle error if needed
		} finally {
			setAwaitPartialBet(false);
			setLoading(false);
		}
	};

	const handleReject = async () => {
		setAwaitPartialBet(true);
		setLoading(true);
		try {
			await handlePartialBet({ betId: id, amount: data.partialAmount, isAcepted: false, isExotic, flexiAmount });
		} catch (error) {
			// Handle error if needed
		} finally {
			setAwaitPartialBet(false);
			setLoading(false);
		}
	};

	const handleOdds = () => {
		const type = bet.bet_type;
		if (type == 'sameracemulti') {
			return decimalPlaces(bet.display_amount / 100, 2);
		}

		if (type === 'multi') {
			return decimalPlaces(
				bet.bet_selections.reduce((acc, selection) => acc * parseFloat(selection.fixed_odds), 1),
				2,
			);
		}

		if (RACING_EXOTIC_BET_TYPES.includes(type) || QUADDIES_BET_TYPES.includes(type)) {
			return bet.percentage + '%';
		}

		return decimalPlaces(item.fixed_odds, 2);
	};

	return (
		<Notification status={status}>
			{loading && (
				<BackDrop>
					<Loader />
				</BackDrop>
			)}

			<CloseButtonContainer>
				<Text
					size="-3"
					align="center"
					strong
					style={{
						color: '#850000',
					}}
				>
					[ID:{bet.id}] Bet <CurrencyDisplay type="AUD" amount={bet.amount} imageWidth={11} />@{handleOdds()}
				</Text>

				<CloseButton onClick={() => onClose(id)}>X</CloseButton>
			</CloseButtonContainer>

			<BetDetails>
				{end_time && !awaitPartialBet && (
					<Ticker every={() => 1000}>
						<Countdown
							betId={id}
							time={end_time}
							openNotification={openNotification}
							setMessage={setMessage}
							showTab={showTab}
						/>
					</Ticker>
				)}
			</BetDetails>

			{/* Display Bet Information based on its type */}
			<BetInfo item={item} bet={bet}>
				{(isMultiLegBetType || isExotic) && <SelectionListLegs legs={legs} />}
				{isSRM && <SelectionListSRM selections={bet_selections} />}
				{!isMultiLegBetType && !isExotic && !isSRM && <SelectionList selections={bet_selections} />}
			</BetInfo>

			{/* Display current bet status */}
			<BetStatus status={status}>Status: {status}</BetStatus>

			{status === 'ACCEPTED' && (
				<Message status={status} onClick={() => onClose(id)}>
					{status === 'ACCEPTED' && 'Your bet has been accepted. '}
					<br /> Click on this notification to remove it.
				</Message>
			)}

			{status === 'REJECTED' && (
				<Message status={status} onClick={() => handleClosePartialBet(bet.id, status)}>
					{status === 'REJECTED' && 'Your bet has been rejected. '}
					<br />
					Click on this notification to remove it.
				</Message>
			)}

			<div>
				{data && data.partialAmount > 0 && (
					<Text size="-4" align="center" type="secondary" strong>
						Suggested Partial Amount: <CurrencyDisplay type="AUD" amount={data.partialAmount * 100} imageWidth={11} />
					</Text>
				)}
			</div>

			{status !== 'REJECTED' && status != 'ACCEPTED' && (
				<div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
					<Button
						style={{
							width: '60%',
						}}
						isAccept
						onClick={handleAccept}
						disabled={!(status == 'PARTIAL' || status == 'PENDING') || loading}
					>
						Accept Partial Bet
					</Button>
					<Button
						style={{
							width: '40%',
						}}
						onClick={handleReject}
						disabled={status === 'INTERCEPTED' || status === 'PROCESSING' || status === 'REJECTED' || loading}
					>
						Reject
					</Button>
				</div>
			)}
		</Notification>
	);
};

const PendingBetNotifications = ({ isMultiBetSlipOpen, userBets, handlePartialBet, handleClosePartialBet }) => {
	if (isMultiBetSlipOpen || !userBets || userBets.length === 0) {
		return null;
	}

	const [dismissedNotifications, setDismissedNotifications] = useState([]);

	const visibleNotifications = userBets.filter((bet) => !dismissedNotifications.includes(bet.id));

	const handleDismissNotification = (id) => {
		setDismissedNotifications((prev) => [...prev, id]);
	};

	const onPartialBet = async ({ betId, amount, isAcepted = true, flexiAmount }) => {
		let a = amount * 100;

		const data = { betId, amount: a.toFixed(2), isAcepted };
		const bet = userBets.find((bet) => bet.id == betId);

		let isExotic = RACING_EXOTIC_BET_TYPES.includes(bet.bet_type);
		let isQuaddie = QUADDIES_BET_TYPES.includes(bet.bet_type);
		data.isExotic = isExotic || isQuaddie;
		if (isQuaddie) {
			const combinations = calculateCombinations(bet.bet_type, bet.bet_selections, bet.boxed_flag);
			const flexiAmount = a / combinations;
			data.flexiAmount = flexiAmount.toFixed(2);
		} else if (isExotic) {
			const selections = parsePositions(bet.selection_string);
			for (let s of selections) {
				const selection = bet.bet_selections.find((sel) => sel.selection_number == s.number);
				s.id = selection.id;
			}
			const combinations = calculateCombinations(bet.bet_type, selections, bet.boxed_flag);
			const flexiAmount = a / combinations;
			data.flexiAmount = flexiAmount.toFixed(2);
		}
		handlePartialBet(data);
	};

	return (
		<NotificationsContainer>
			{visibleNotifications.map((bet) => (
				<PendingBet
					key={bet.id}
					bet={bet}
					isExotic
					onClose={handleDismissNotification}
					handlePartialBet={onPartialBet}
					handleClosePartialBet={handleClosePartialBet}
				/>
			))}
		</NotificationsContainer>
	);
};

const mapStateToProps = (state) => ({
	isMultiBetSlipOpen: state.application.showMultiBetSlip,
	userBets: getPendingBets(state),
});

const mapDispatchToProps = (dispatch) => ({
	handlePartialBet: async ({ betId, amount, isAcepted = true, flexiAmount }) => {
		try {
			const action = isAcepted ? partialBet({ betId, amount, flexi: flexiAmount }) : cancelPartialBet({ betId });

			const response = await dispatch(await action);
			await dispatch(fetchActiveBets());

			if (response && typeof response === 'object') {
				const successMessage = isAcepted
					? 'Your partial bet has been placed successfully'
					: 'Your partial bet has been cancelled';
				dispatch(openNotification(successMessage, 'success', { autoClose: 3500 }));

				if (!isAcepted) {
					dispatch(updateBetStatus(betId, 'REJECTED'));
				}
			} else {
				const errorMessage =
					response ||
					(isAcepted
						? 'Failed to place your partial bet. Please try again.'
						: 'Failed to cancel your partial bet. Please try again.');
				dispatch(openNotification(errorMessage, 'danger', { autoClose: 3500 }));
			}
		} catch (error) {
			console.error('Error handling partial bet:', error);
			dispatch(
				openNotification('An unexpected error occurred. Please try again later.', 'danger', { autoClose: 3500 }),
			);
		}
	},
	handleClosePartialBet: (id, status) => {
		dispatch(updateBetStatus(id, status));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(PendingBetNotifications));
