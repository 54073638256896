/**
 * Higher-order reducer factory for creating named reducers
 * Both params are required, and it will return your reducer or state
 * @param key
 * @param reducer
 * @returns {function}
 */
const createNamedReducer = (key, reducer) => {
	return (state, action) => {
		// Action must have a key called 'reducerName'. Use the optional metadata param with createAction
		const { reducerName } = action;
		const isInitialCall = state === undefined; // Check if initialization is happening

		// If the provided key doesn't match the reducerName, and it's not the initialization, return the state
		if (reducerName !== key && !isInitialCall) {
			return state;
		}

		// Otherwise return the reducer
		return reducer(state, action);
	};
};

export default createNamedReducer;
