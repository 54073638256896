import {
    NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK
  } from './NextJumpHomeActionTypes';
  
  const initialState = {
    loadingContainer: undefined,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK:
        return{
          ...state,
          loadingContainer: false,
        };
      default:
        return state;
    }
  }