import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled from 'react-emotion';

import Modal from '../../../../../components/controllers/Modal/Modal';

import { Iframe, LoadingMask } from '@tbh/ui-kit';

import LoginContainer from '../../../../../containers/Application/Login/LoginContainer';

const StyledMarketGroupFrame = styled('div')`
	label: MarketGroupFrame;

	position: relative;
`;

class MarketGroupFrame extends Component {
	static propTypes = {
		/** The src URI for the iFrame */
		frameURI: PropTypes.string.isRequired,

		/** Data to identify the window message from the frame */
		data: PropTypes.number.isRequired,

		/** Base API src for player bets widget */
		playerBetsWidgetSrc: PropTypes.string.isRequired,

		/** The currently authenticated user */
		user: PropTypes.shape({
			/** Their username */
			username: PropTypes.string.isRequired,

			/** Their laravel session ID */
			user_token: PropTypes.string.isRequired,
		}),

		/** Whether or not the market group is open by default or not */
		marketGroupInitiallyOpen: PropTypes.bool,

		/** Whether or not the iFrame is in view  */
		inView: PropTypes.bool,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		user: null,
		inView: true,
		marketGroupInitiallyOpen: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			/** Message to post to the embedded iframe */
			frameMessage: '',

			/** The height of the embedded iframe */
			frameHeight: 50,

			/** The iFrame will need to load the data after is is placed into view */
			isLoading: true,

			/** Track that the iFrame has loaded, so that we don't reload it */
			hasLoaded: false,
			showLoginPrompt: false,
		};
	}

	/**
	 * Login to the DST service, or login and place a bet
	 *
	 * @param data
	 * @param pureLogin
	 */
	dstLogin = (data, pureLogin = true) => {
		const { user } = this.props;

		// Set the player authorisation message to login
		this.setState({
			showLoginPrompt: !pureLogin && !user,

			// ['response','ds::${eventId}::login','authorization',{username:'tomasswood',password:'MY_TOKEN',requester:'quickBet'}]
			frameMessage: user
				? [
						'response',
						`ds::${data}::${pureLogin ? 'login' : 'loginAndPlace'}`,
						'authorization',
						{ username: user.username, password: user.user_token, requester: 'quickBet' },
				  ]
				: '',
		});
	};

	/**
	 * Handle when the login modal was closed
	 */
	handleLoginModalClose = () => {
		// If the user has logged in, try and place their bet
		if (this.props.user) {
			this.dstLogin(false);
		} else {
			this.setState({
				showLoginPrompt: false,
			});
		}
	};

	/**
	 * Get the login modal
	 *
	 * @returns {boolean|XML}
	 */
	buildLoginModal = () => {
		if (this.props.user) {
			return null;
		}

		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			preventBackdropClose: true,
			showEncryptionMessage: false,
			hideClose: true,
		};

		return (
			this.state.showLoginPrompt && (
				<Modal
					open={this.state.showLoginPrompt}
					componentKey="event-controller__login-prompt"
					store={App.store}
					config={MODAL_CONFIG}
				>
					<LoginContainer handleClose={this.handleLoginModalClose} />
				</Modal>
			)
		);
	};

	/**
	 * Handle the embedded iframe posting messages to us
	 *
	 * @param event
	 * @param data
	 */
	handleFrameMessage = (event, data) => {
		if (Array.isArray(event.data)) {
			if (event.data.length === 4) {
				if (event.data[1] === `ds::${data}::height` && this.state.frameHeight !== event.data[3]) {
					// Set the iframe height
					this.setState({
						frameHeight: event.data[3],
					});
				} else if (event.data[1] === 'ds::login' || event.data[1] === `ds::${data}::login`) {
					this.dstLogin(data, false);
				} else if (event.data[1] === `ds::${data}::ready`) {
					this.setState({
						isLoading: false,
						hasLoaded: true,
					});
					this.dstLogin(data);
				}
			}
		}
	};

	render() {
		const { className, frameURI, data, inView, playerBetsWidgetSrc } = this.props;
		const { frameHeight, frameMessage, isLoading, hasLoaded } = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<StyledMarketGroupFrame className={componentClasses}>
				{this.buildLoginModal()}
				<LoadingMask loading={isLoading} transparent />
				{inView || hasLoaded ? (
					<Iframe
						src={`${frameURI}&widget=${data}`}
						data={data}
						height={frameHeight}
						onMessageReceived={this.handleFrameMessage}
						message={frameMessage}
						targetOrigin={playerBetsWidgetSrc}
					/>
				) : null}
			</StyledMarketGroupFrame>
		);
	}
}

export default MarketGroupFrame;
