/**
 * Libraries
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { brand, spacings } from '@tbh/ui-kit';
import moment from 'moment';

import { HeaderBar, Text } from '@tbh/ui-kit';
import MarketRow from '../MarketRow/MarketRow';
import Svg from '../../../../ui-components/Svg/Svg';
import { convertToLocalDateTime } from '../../../../../common/Date';
import JsonLd from '../../../Meta/JsonLd/JsonLd';
import { GOAT_SPORTS_BUMP_ICON_SIZE } from '../../../../../common/constants/GoatProducts';

const StyledDefaultEventItem__Icon = styled(Svg)(
	(props) => css`
		label: DefaultEventItem__Icon;

		margin-right: ${spacings(props).tight}px;
		flex: 1 0 ${spacings(props).comfortable}px;
		background: ${brand(props).color_1};
		border-radius: 50%;
		max-width: ${spacings(props).comfortable}px;
	`,
);

/**
 * Sort selections alphabetically
 *
 * @param selections
 * @returns {Array}
 */
const processSelections = (selections = []) => {
	return selections.sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * The default event item. Displays the event details as well as a list of default markets for the event
 *
 * @param className
 * @param disableBetting
 * @param markets
 * @param eventId
 * @param eventStart
 * @param eventName
 * @param addToSingle
 * @param addToMulti
 * @param onEventClick
 * @param hasBump
 * @param route
 * @returns {XML}
 * @constructor
 */

const DefaultEventItem = ({
	className,
	disableBetting,
	markets,
	eventId,
	eventStart,
	eventName,
	addToSingle,
	addToMulti,
	onEventClick,
	hasBump,
	route,
	size,
}) => {
	// Classes
	const classes = cx({
		[className]: className,
	});

	// Generate the addTo single or multi action
	const addTo = (callback, market) => {
		if (!callback) {
			return callback;
		}

		return (selection, productId) =>
			callback(eventId, market.sportSelections.find((s) => s.id === selection), market, productId);
	};

	const handleOnHeaderClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		onEventClick(eventId);
	};

	// Get the local time for the event start date
	const localEventStart = convertToLocalDateTime(eventStart);

	const timeDisplay = (
		<Text tag="span" align="right">
			<Text align="right" size="-2" type="alternate">
				{localEventStart.format('MMM Do')}
			</Text>
			<Text align="right" size="-2" type="alternate">
				{localEventStart.format('h:mma')}
			</Text>
		</Text>
	);

	const eventLd = {
		'@context': 'http://schema.org',
		'@type': 'SportsEvent',
		name: eventName,
		description: eventName,
		startDate: localEventStart.format(),
		endDate: localEventStart
			.clone()
			.add('60', 'minutes')
			.format(),
		url: window.location && window.location.href,
		competitor: [],
		location: {
			'@type': 'Place',
			name: eventName,
			address: eventName,
		},
		performer: [],
	};

	return (
		<div className={classes}>
			<JsonLd content={eventLd} />
			<HeaderBar
				nodeLeft={
					hasBump && (
						<StyledDefaultEventItem__Icon
							name="goat-bump"
							width={GOAT_SPORTS_BUMP_ICON_SIZE}
							height={GOAT_SPORTS_BUMP_ICON_SIZE}
						/>
					)
				}
				href={route}
				strong
				type="primary"
				aside={timeDisplay}
				action={onEventClick && handleOnHeaderClick}
			>
				{eventName}
			</HeaderBar>

			{markets.map((market) => (
				<MarketRow
					alignTop
					key={market.id}
					marketName={market.name}
					selections={processSelections(market.sportSelections)}
					bettingAvailable={!disableBetting}
					addToSingle={addTo(addToSingle, market)}
					addToMulti={addTo(addToMulti, market)}
					size={size}
				/>
			))}
		</div>
	);
};

DefaultEventItem.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** The ID of the event */
	eventId: PropTypes.number.isRequired,

	/** The name of the event */
	eventName: PropTypes.string.isRequired,

	/** The moment when the event starts */
	eventStart: PropTypes.instanceOf(moment).isRequired,

	/** The list of market items in the market group */
	markets: PropTypes.arrayOf(
		PropTypes.shape({
			/** The market item */
			id: PropTypes.number.isRequired,

			/** The market name */
			name: PropTypes.string.isRequired,

			/** The list of selections in the market */
			sportSelections: PropTypes.array.isRequired,
		}),
	),

	/** Action to add the selection to the bet prompt */
	addToSingle: PropTypes.func.isRequired,

	/** Action to add the selection to the multi bet slip */
	addToMulti: PropTypes.func,

	/** Action to fire when the event is clicked on */
	onEventClick: PropTypes.func,

	/** The route for the team event */
	route: PropTypes.string,

	/** Disable betting for the event item */
	disableBetting: PropTypes.bool,

	/** Event has bump available */
	hasBump: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,
};

DefaultEventItem.defaultProps = {
	markets: [],
	addToMulti: null,
	className: null,
	onEventClick: null,
	route: null,
	disableBetting: false,
	hasBump: false,
};

export default DefaultEventItem;
