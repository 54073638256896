import {
	RACING_AU,
	RACING_COUNTRY_IMPORTANCE_LOOKUP,
	RACING_THOROUGHBRED_CODE,
	RACING_GRADE_IMPORTANCE_LOOKUP,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
	RACING_STATE_IMPORTANCE_LOOKUP,
	RACING_INT,
	RACING_GROUP_TITLE_OTHER,
	RACING_GROUP_TITLE_LOCAL,
	RACING_GROUP_TITLE_INTERNATIONAL,

	RACING_GROUP_TITLE_THOROUGHBRED,
	RACING_GROUP_GALLOPS, //@HW

	/// bet builder
	BET_BUILDER_LAST_POSITION_WIN,
	BET_BUILDER_LAST_POSITION_PLACE,
	BET_BUILDER_LAST_BIG_WIN_2,
    BET_BUILDER_LAST_BIG_WIN_5,
    BET_BUILDER_BEATEN_FAV_YES,
    BET_BUILDER_BEATEN_FAV_NO,
    BET_BUILDER_DISTANCE_WIN,
    BET_BUILDER_DISTANCE_PLACE,
    BET_BUILDER_COURSE_WIN,
    BET_BUILDER_COURSE_PLACE,
    BET_BUILDER_COURSE_DISTANCE_WIN,
    BET_BUILDER_COURSE_DISTANCE_PLACE,
    BET_BUILDER_RATE_TO_WIN_Y,
    BET_BUILDER_RATE_TO_WIN_N,
    BET_BUILDER_WET_WIN,
    BET_BUILDER_WET_PLACE,
    BET_BUILDER_STRIKE_RATE_16,
	BET_BUILDER_STRIKE_RATE_20,
	BET_BUILDER_JOCKEY_STRIKE_RATE_16,
	BET_BUILDER_JOCKEY_STRIKE_RATE_20,
    BET_BUILDER_JOCKEY_WON_HORSE_Y,
    BET_BUILDER_JOCKEY_WON_HORSE_N,
    BET_BUILDER_JOCKEY_SWITCH_Y,
    BET_BUILDER_JOCKEY_SWITCH_N,
    BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16,
    BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20,
} from '../../../common/constants/Racing';
import { sortMeetingGroups } from '../../../pages/Racing/RacingHome/racingSelectorsGRS';

/**
 * To be applied to Array.sort(). Sort meetings by resulted and un-resulted,
 * and each groups sort them by grade.
 *
 * Return value varies between -1, 0, 1 according to FR rules.
 * @return number
 * @param mA
 * @param mB
 */
export const compareMeetingsByStatusAndGrade = (mA, mB) => {
	if (!isResulted(mA) && !isResulted(mB)) {
		return compareByGrade(mA, mB);
	}

	if (!isResulted(mA) && isResulted(mB)) {
		return -1;
	}

	if (isResulted(mA) && !isResulted(mB)) {
		return 1;
	}

	if (isResulted(mA) && isResulted(mB)) {
		return compareByGrade(mA, mB);
	}

	return 0;
};

/**
 * Considers the grades importance declared in the constant RACING_GRADE_IMPORTANCE_LOOKUP.
 * The grades order should be as follows:
 * METRO > PROVINCIAL > COUNTRY
 *
 * @param meetingA
 * @param meetingB
 * @return {number}
 */
export const compareByGrade = (meetingA, meetingB) => {
	if (meetingA.grade === meetingB.grade) {
		return compareByCountry(meetingA, meetingB);
	}
	return (RACING_GRADE_IMPORTANCE_LOOKUP[meetingA.grade] || 0) > (RACING_GRADE_IMPORTANCE_LOOKUP[meetingB.grade] || 0)
		? -1
		: 1;
};

/**
 * Considers the country importance declared in the constant RACING_COUNTRY_IMPORTANCE_LOOKUP.
 *
 * @param meetingA
 * @param meetingB
 * @return {number}
 */
export const compareByCountry = (meetingA, meetingB) => {
	if (meetingA.country === meetingB.country) {
		// If Australia compare states, otherwise by state name
		if (meetingA.country === RACING_AU) {
			return compareByAustralianState(meetingA, meetingB);
		} else {
			return compareByString(meetingA, meetingB, 'state', 'name');
		}
	}
	return (RACING_COUNTRY_IMPORTANCE_LOOKUP[meetingA.country] || 0) >
		(RACING_COUNTRY_IMPORTANCE_LOOKUP[meetingB.country] || 0)
		? -1
		: 1;
};

/**
 * Considers the state importance declared in the constant RACING_STATE_IMPORTANCE_LOOKUP.
 *
 * @param meetingA
 * @param meetingB
 * @return {number}
 */
export const compareByAustralianState = (meetingA, meetingB) => {
	if (meetingA.state === meetingB.state) {
		return compareByString(meetingA, meetingB, 'name');
	}
	return (RACING_STATE_IMPORTANCE_LOOKUP[meetingA.state] || 0) > (RACING_STATE_IMPORTANCE_LOOKUP[meetingB.state] || 0)
		? -1
		: 1;
};

export const compareByString = (meetingA, meetingB, propertyKey, alternativeKey) => {
	if (meetingA[propertyKey] === meetingB[propertyKey] && alternativeKey) {
		return compareByString(meetingA, meetingB, alternativeKey);
	}
	return meetingA[propertyKey] <= meetingB[propertyKey] ? -1 : 1;
};

/**
 * Checks if a Meeting is of type Thoroughbred.
 * @param meeting
 * @return boolean
 */
export const isGallops = (meeting) => isType(meeting, RACING_THOROUGHBRED_CODE);

/**
 * Checks if a Meeting is of type Greyhounds.
 * @param meeting
 * @return boolean
 */
export const isGreyhounds = (meeting) => isType(meeting, RACING_GREYHOUNDS_CODE);

/**
 * Checks if a Meeting is of type Harness.
 * @param meeting
 * @return boolean
 */
export const isHarness = (meeting) => isType(meeting, RACING_HARNESS_CODE);

//
//
/**
 * Counts how the number of meetings per country code
 *
 * @param state
 * @returns {string{}}
 */
export const meetingsCountByCountryCode = (state) => {
	const meetings = state.entities.meetings;
	let meetingGroupsCount = {};

	Object.values(meetings).forEach((meeting) => {
		let groupCode = meeting.country;

		meetingGroupsCount[groupCode] && meetingGroupsCount[groupCode] > 0
			? meetingGroupsCount[groupCode]++
			: (meetingGroupsCount[groupCode] = 1);
	});
	return meetingGroupsCount;
};

export const buildMeetingGroupFilters = (groupMeetingsByCountry, meetingGroups, meetingGroupsCount) => {
	let meetingGroupFilters = [];
	const meetingCodesWithMeetings = Object.keys(meetingGroupsCount);
	
	// If the deprecated 'groupMeetingsByCountry' feature is enabled then return ['AU/NZ', 'INTERNATIONAL']
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		meetingGroupFilters.push(
			{
				title: RACING_GROUP_TITLE_LOCAL,
				groupFilterId: RACING_GROUP_TITLE_LOCAL,
				ordinal: 1,
				default: false,
			},
			{
				title: RACING_GROUP_TITLE_INTERNATIONAL,
				groupFilterId: 'default',
				default: true,
			},
		);
	}

	// If the 'meetingGroups' feature is enabled then return the custom meeting groups
	else if (meetingGroups && meetingGroups.value) {
		// This will accumulate the meeting codes we use
		let groupedMeetingCodes = [];

		meetingGroups.value.map((meetingGroup) => {
			// only add title if code has meetings
			let hasMeetings = false;

			meetingGroup.countryCodes && meetingGroup.countryCodes.forEach((countryCode) => {
				// check if has meetings
				if (meetingCodesWithMeetings.includes(countryCode)) {
					hasMeetings = true;
					groupedMeetingCodes.push(countryCode);
				}
			});

			if (hasMeetings) {
				meetingGroupFilters.push({
					groupFilterId: meetingGroup.title,
					title: meetingGroup.title,
					ordinal: meetingGroup.ordinal,
					default: false,
				});
			}
		});

		let leftOverMeetingsNotGrouped = meetingCodesWithMeetings.filter((code) => {
			return !groupedMeetingCodes.includes(code);
		});

		if (leftOverMeetingsNotGrouped.length > 0) {
			// Check and see if there is a custom title set for the default group
			const hasCustomDefaultGroupTitle = meetingGroups.value.find((meetingGroup) => {
				return meetingGroup.countryCodes && meetingGroup.countryCodes.length === 0 && meetingGroup.title;
			});

			meetingGroupFilters.push({
				groupFilterId: 'default',
				title: hasCustomDefaultGroupTitle ? hasCustomDefaultGroupTitle.title : RACING_GROUP_TITLE_OTHER,
				default: true,
			});
		}
	}

	// Order the meeting groups and return
	return meetingGroupFilters.sort(sortMeetingGroups);
};




// added by @HW
export const buildMeetingFilters = (groupMeetingsByCountry, meetingGroups, meetingGroupsCount) => {
	let meetingGroupFilters = [];
	const meetingCodesWithMeetings = Object.keys(meetingGroupsCount);

	// If the deprecated 'groupMeetingsByCountry' feature is enabled then return ['AU/NZ', 'INTERNATIONAL']
	if (groupMeetingsByCountry && groupMeetingsByCountry.enabled) {
		meetingGroupFilters.push(
			{
				title: RACING_GROUP_TITLE_THOROUGHBRED,
				groupFilterId: RACING_GROUP_GALLOPS,
				ordinal: 1,
				default: false,
			},
			// {
			// 	title: RACING_GROUP_TITLE_THOROUGHBRED,
			// 	groupFilterId: 'default',
			// 	default: true,
			// },
		);
	}

	// If the 'meetingGroups' feature is enabled then return the custom meeting groups
	else if (meetingGroups && meetingGroups.value) {
		// This will accumulate the meeting codes we use
		let groupedMeetingCodes = [];
			
		meetingGroups.value.map((meetingGroup) => {
			// only add title if code has meetings
			let hasMeetings = false;

			meetingGroup.countryCodes && meetingGroup.countryCodes.forEach((countryCode) => {
				// check if has meetings
				if (meetingCodesWithMeetings.includes(countryCode)) {
					hasMeetings = true;
					groupedMeetingCodes.push(countryCode);
				}
			});

			if (hasMeetings) {
				meetingGroupFilters.push({
					groupFilterId: meetingGroup.title,
					title: meetingGroup.title,
					ordinal: meetingGroup.ordinal,
					default: false,
				});
			}
		});

		let leftOverMeetingsNotGrouped = meetingCodesWithMeetings.filter((code) => {
			return !groupedMeetingCodes.includes(code);
		});

		if (leftOverMeetingsNotGrouped.length > 0) {
			// Check and see if there is a custom title set for the default group
			const hasCustomDefaultGroupTitle = meetingGroups.value.find((meetingGroup) => {
				return meetingGroup.countryCodes && meetingGroup.countryCodes.length === 0 && meetingGroup.title;
			});

			meetingGroupFilters.push({
				groupFilterId: 'default',
				title: hasCustomDefaultGroupTitle ? hasCustomDefaultGroupTitle.title : RACING_GROUP_TITLE_OTHER,
				default: true,
			});
		}
	}

	// Order the meeting groups and return
	return meetingGroupFilters.sort(sortMeetingGroups);
};






/**
 * Checks a meeting type to see if it is from a country in the countryCodes
 * @param meeting
 * @param type
 * @param countryCodes
 * @return boolean
 */
export const containsCountryCodes = (type, countryCodes, meeting) =>
	isMeetingInCountryList(meeting, countryCodes) && isType(meeting, type);

/**
 * Checks a meeting type to see if it is NOT from a country in the countryCodes
 * @param meeting
 * @param type
 * @param countryCodes
 * @return boolean
 */
export const doesNotContainCountryCodes = (type, countryCodes, meeting) =>
	!isMeetingInCountryList(meeting, countryCodes) && isType(meeting, type);

/**
 * Checks if a meeting is from a country in the countryCodes
 *  NOTE: Currently for International meetings the country code is actually stored in state
 * @param meeting
 * @param countryCodes
 * @return boolean
 */
export const isMeetingInCountryList = (meeting = {}, countryCodes = []) =>
	meeting.country === RACING_INT ? countryCodes.includes(meeting.state) : countryCodes.includes(meeting.country);

export const allContryCodes = 	(type, meeting) =>
isMeetingInCountry(meeting) && isType(meeting, type);

export const isMeetingInCountry = (meeting = {}) => meeting;
/**
 * Checks if a meeting is of given type.
 * @param {object} meeting
 * @param {string} type (gallops, greyhounds, harness)
 * @return boolean
 */
export const isType = (meeting = {}, type) => meeting.type === type;

/**
 * Checks if a meeting finished all races, independent of its status.
 * @param meeting
 * @return boolean
 */
export const isResulted = (meeting = {}) => {
	return meeting.next_race_number === 0;
};


/**
* filter by Meeting Type
* @param {object} meetings
* @param Type;
* @HW13OCT2019
*/

export const filterMeetingByType = (meetings = {},type) =>{
	if (!type) {
	return meetings;
	}
	let filterMeetings = Object.values(meetings).filter((meeting) => meeting.type == type);
	return filterMeetings.length ? filterMeetings : meetings;
};


/**
 * get all selection count
 * @HW 10June2020
 * @param {*} meeting 
 */
export const betBuilderMeetingSelectionsCount = (meetingId,betBuilderMeeting, types = []) => {

	let filteList = [];

	if(meetingId == betBuilderMeeting.id){
		Object.values(betBuilderMeeting.races).map((betRace) => {
			if (Array.isArray(betRace.selections) && betRace.selections.length > 0) {
	
				betRace.selections.map((Bselection) => {
					if(Bselection.runnerstats){
					filteList.push(Bselection.runnerstats);
					}
					
				});
			}
		});
	}
	
   const filterDatacount = filterByType(filteList, types);
	return filterDatacount;	   
};

/**
 * get all selection count
 * @param {*} meetingId 
 * @param {*} betBuilderMeeting 
 * @param {*} types 
 */
export const betBuilderSelectionsCount = (betBuilderMeeting, types = []) => {

	let filteList = [];

	
		Object.values(betBuilderMeeting.races).map((betRace) => {
			if (Array.isArray(betRace.selections) && betRace.selections.length > 0) {
	
				betRace.selections.map((Bselection) => {
					if(Bselection.runnerstats){
					filteList.push(Bselection.runnerstats);
					}
					
				});
			}
		});
	
   const filterDatacount = filterByType(filteList, types);

	return filterDatacount;	   
};




/**
 * filtered selection on bet builder of races
 * @HW 15JUNE2020
 * @param {*} betBuilderMeeting 
 * @param {*} types 
 */
export const betBuilderMeetingSelectionsList = ((betBuilderMeeting, types = []) => {
	let racesList = [];
	let filterList = [];
	 

	// make races list object
	Object.values(betBuilderMeeting.races).map((betRace) => {
		racesList.push(betRace);
	});

		racesList.map((race) => {	
			if(race && race.selections){
				let filterSelection = filterRunnerStats(race.selections, types);
			
				if (filterSelection && filterSelection.length > 0 ){
				
					race.selections = filterSelection;
					filterList.push(race);
				}
			}
			
		});
	
		return filterList;

});

export const withoutTypesbetBuilderMeetingSelectionsList = ((betBuilderMeeting) => {
	let racesList = [];

	// make races list object
	Object.values(betBuilderMeeting.races).map((betRace) => {
		racesList.push(betRace);
	});

	return racesList;
});



/**
 * filter race selections runnerstats
 * @param {*} data 
 * @param {*} types 
 */
const filterRunnerStats = (data,types = []) => {
	
	let finalfilterList = data;
	if(Array.isArray(types) && types.length == 0){
		return finalfilterList;
	}else{
		// filter function
		
		if(types.includes(BET_BUILDER_LAST_POSITION_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.last_position == 1);
		}
		else if (types.includes(BET_BUILDER_LAST_POSITION_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.last_position == 1 || selection.runnerstats.last_position == 2 || selection.runnerstats.last_position == 3 ));
		}

		// Last Start Big Win
		if(types.includes(BET_BUILDER_LAST_BIG_WIN_2)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.last_position == 1 && selection.runnerstats.win_margin >= 2) && (selection.runnerstats.last_position == 1 && selection.runnerstats.win_margin <= 4.9 ));
		}
		if(types.includes(BET_BUILDER_LAST_BIG_WIN_5)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.last_position == 1 && selection.runnerstats.win_margin >= 5 ));
		}
		// Beaten Favourite
		if(types.includes(BET_BUILDER_BEATEN_FAV_YES)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.is_fev == 1 && selection.runnerstats.last_position != 1 ));
		}
		if(types.includes(BET_BUILDER_BEATEN_FAV_NO)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.is_fev == 0 || (selection.runnerstats.is_fev == 1 && selection.runnerstats.last_position == 1)) );
		}
		// Distance
		if(types.includes(BET_BUILDER_DISTANCE_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.distance_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_DISTANCE_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.distance_stat === 'W') || (selection.runnerstats.distance_stat === 'P') );
		}
		//Course
		if(types.includes(BET_BUILDER_COURSE_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.course_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_COURSE_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.course_stat === 'W') || (selection.runnerstats.course_stat === 'P') );
		}
		// Course and Distance
		if(types.includes(BET_BUILDER_COURSE_DISTANCE_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.course_distance_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_COURSE_DISTANCE_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.course_distance_stat === 'P') || (selection.runnerstats.course_distance_stat === 'W') );
		}
		//Rate to win
		if(types.includes(BET_BUILDER_RATE_TO_WIN_Y)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.win_rate == 1 );
		}
		if(types.includes(BET_BUILDER_RATE_TO_WIN_N)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.win_rate == 0 );
		}

		//wet
		if(types.includes(BET_BUILDER_WET_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.wet_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_WET_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.wet_stat === 'P') || (selection.runnerstats.wet_stat === 'W'));
		}

		// Trainer Strike Rate (ST)
		if(types.includes(BET_BUILDER_STRIKE_RATE_16)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.trainer_strike_rate >= 16 && selection.runnerstats.trainer_strike_rate <= 19.9 );
		}
		if(types.includes(BET_BUILDER_STRIKE_RATE_20)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.trainer_strike_rate >= 20 );
		}

		// Jockey Strike Rate (ST)
		if(types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_16)){
			finalfilterList = finalfilterList.filter( selection => (selection.runnerstats.jockey_strike_rate >= 16 && selection.runnerstats.jockey_strike_rate <= 19.9) );
		}
		if(types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_20)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.jockey_strike_rate >= 20 );
		}

		// Jockey Won on horse
		if(types.includes(BET_BUILDER_JOCKEY_WON_HORSE_Y)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.jockey_runner_strike_rate > 0);
		}
		if(types.includes(BET_BUILDER_JOCKEY_WON_HORSE_N)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.jockey_runner_strike_rate == 0 );
		}

		// Jockey SwitchfinalfilterList
		if(types.includes(BET_BUILDER_JOCKEY_SWITCH_Y)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.is_jockey_switch == 1);
		}
		if(types.includes(BET_BUILDER_JOCKEY_SWITCH_N)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.is_jockey_switch == 0 );
		}

		// Trainer and Jockey Strike Rate (ST)
		if(types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.trainer_jockey_strike_rate >= 16 && selection.runnerstats.trainer_jockey_strike_rate <= 19.9);
		}
		if(types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20)){
			finalfilterList = finalfilterList.filter( selection => selection.runnerstats.trainer_jockey_strike_rate >= 20 );
		}
		
	
	return finalfilterList;

}

};

/**
 * selections array filter
 * @param {*} data 
 * @param {*} types 
 */
const filterByType = (data, types = []) =>{ 
	
	
	
	let finalfilterList = data;
	    if(types.length == 0){
			return data.length;
		}
		if(types.includes(BET_BUILDER_LAST_POSITION_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.last_position == 1);
		}
		if (types.includes(BET_BUILDER_LAST_POSITION_PLACE) ){
			finalfilterList = finalfilterList.filter( selection => selection.last_position == 1 || selection.last_position == 2 ||selection.last_position == 3 );
		}
		// Last Start Big Win
		if(types.includes(BET_BUILDER_LAST_BIG_WIN_2)){
			finalfilterList = finalfilterList.filter( selection => (selection.last_position == 1 && selection.win_margin >= 2) && (selection.last_position == 1 && selection.win_margin <= 4.9 ));
		}
		if(types.includes(BET_BUILDER_LAST_BIG_WIN_5)){
			finalfilterList = finalfilterList.filter( selection => (selection.last_position == 1 && selection.win_margin >= 5 ));
		}
		// Beaten Favourite
		if(types.includes(BET_BUILDER_BEATEN_FAV_YES)){
			finalfilterList = finalfilterList.filter( selection => (selection.is_fev == 1 && selection.last_position != 1 ));
		}
		if(types.includes(BET_BUILDER_BEATEN_FAV_NO)){
			finalfilterList = finalfilterList.filter( selection => (selection.is_fev == 0 || (selection.is_fev == 1 && selection.last_position == 1)) );
		}
		// Distance
		if(types.includes(BET_BUILDER_DISTANCE_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.distance_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_DISTANCE_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.distance_stat === 'W') || (selection.distance_stat === 'P') );
		}
		//Course
		if(types.includes(BET_BUILDER_COURSE_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.course_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_COURSE_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.course_stat === 'W') || (selection.course_stat === 'P') );
		}
		// Course and Distance
		if(types.includes(BET_BUILDER_COURSE_DISTANCE_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.course_distance_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_COURSE_DISTANCE_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.course_distance_stat === 'P') || (selection.course_distance_stat === 'W') );
		}
		//Rate to win
		if(types.includes(BET_BUILDER_RATE_TO_WIN_Y)){
			finalfilterList = finalfilterList.filter( selection => selection.win_rate == 1 );
		}
		if(types.includes(BET_BUILDER_RATE_TO_WIN_N)){
			finalfilterList = finalfilterList.filter( selection => selection.win_rate == 0 );
		}

		//wet
		if(types.includes(BET_BUILDER_WET_WIN)){
			finalfilterList = finalfilterList.filter( selection => selection.wet_stat === 'W' );
		}
		if(types.includes(BET_BUILDER_WET_PLACE)){
			finalfilterList = finalfilterList.filter( selection => (selection.wet_stat === 'P') || (selection.wet_stat === 'W'));
		}

		// Trainer Strike Rate (ST)
		if(types.includes(BET_BUILDER_STRIKE_RATE_16)){
			finalfilterList = finalfilterList.filter( selection => selection.trainer_strike_rate >= 16 && selection.trainer_strike_rate <= 19.9 );
		}
		if(types.includes(BET_BUILDER_STRIKE_RATE_20)){
			finalfilterList = finalfilterList.filter( selection => selection.trainer_strike_rate >= 20 );
		}

		// Jockey Strike Rate (ST)
		if(types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_16)){
			finalfilterList = finalfilterList.filter( selection => selection.jockey_strike_rate >= 16 && selection.jockey_strike_rate <= 19.9 );
		}
		if(types.includes(BET_BUILDER_JOCKEY_STRIKE_RATE_20)){
			finalfilterList = finalfilterList.filter( selection => selection.jockey_strike_rate >= 20 );
		}

		// Jockey Won on horse
		if(types.includes(BET_BUILDER_JOCKEY_WON_HORSE_Y)){
			finalfilterList = finalfilterList.filter( selection => selection.jockey_runner_strike_rate > 0);
		}
		if(types.includes(BET_BUILDER_JOCKEY_WON_HORSE_N)){
			finalfilterList = finalfilterList.filter( selection => selection.jockey_runner_strike_rate == 0 );
		}

		// Jockey SwitchfinalfilterList
		if(types.includes(BET_BUILDER_JOCKEY_SWITCH_Y)){
			finalfilterList = finalfilterList.filter( selection => selection.is_jockey_switch == 1);
		}
		if(types.includes(BET_BUILDER_JOCKEY_SWITCH_N)){
			finalfilterList = finalfilterList.filter( selection => selection.is_jockey_switch == 0 );
		}

		// Trainer and Jockey Strike Rate (ST)
		if(types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_16)){
			finalfilterList = finalfilterList.filter( selection => selection.trainer_jockey_strike_rate >= 16 && selection.trainer_jockey_strike_rate <= 19.9);
		}
		if(types.includes(BET_BUILDER_TRAINER_JOCKEY_STRIKE_RATE_20)){
			finalfilterList = finalfilterList.filter( selection => selection.trainer_jockey_strike_rate >= 20 );
		}
		
	
	return finalfilterList.length;
	
  };
  