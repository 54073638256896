import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import BetSelectionSingle from './BetSelectionSingle/BetSelectionSingle';
import BetSelectionMulti from './BetSelectionMulti/BetSelectionMulti';
import BetSelectionExotic from './BetSelectionExotic/BetSelectionExotic';

import {
	RACING_BET_TYPE_EXACTA,
	RACING_BET_TYPE_FIRST_FOUR,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_DAILY_DOUBLE,
	RACING_BET_TYPE_STRAIGHT_EIGHT,
	RACING_BET_TYPE_QUINELLA,
	RACING_BET_TYPE_TRIFECTA,
	RACING_BET_TYPE_WIN_SHORT,
	RACING_BET_TYPE_PLACE_SHORT,
	RACING_BET_TYPE_EACHWAY_SHORT,
	RACING_BET_TYPE_MARGIN_SHORT,
} from '../../../../common/constants/Racing';
import { BetSelectionSRM } from './BetSelectionSRM/BetSelectionSRM';

const BetSelection = (props) => {
	const {
		className,
		isRacing,
		isExotic,
		isMulti,
		racingBetType,
		selectionName,
		betTypeLabel,
		displayPriceIsBumped,
		eventName,
		price,
		placePrice,
		silk,
		enableRemoval,
		margin,
		multiBetId,
		exoticType,
		exoticSelections,
		eachwayDisabled,
		priceRollups,
		onMultiToggle,
		onMultiRemove,
		onBetTypeChange,
		exoticSelectionType,
		singleOnly,
		onClickSelection,
		user_bet_amount,
		Dis_raceNumber,
		meeting,
		isSRM,
		srm,
		product_name,
		hasTote,
		handleSelectedCupomBet,
		stake
	} = props;

	const componentClasses = cx({
		[className]: className,
	});
	return (
		<div className={componentClasses}>
			{isMulti && (
				<BetSelectionMulti
					selectionName={selectionName}
					betTypeLabel={betTypeLabel}
					eventName={eventName}
					isRacing={isRacing}
					racingBetType={racingBetType}
					price={price}
					placePrice={placePrice}
					singleOnly={singleOnly}
					enableRemoval={enableRemoval}
					multiBetId={multiBetId}
					onMultiRemove={onMultiRemove}
					onMultiToggle={onMultiToggle}
					onClickSelection={onClickSelection}
					product_name={product_name}
					handleSelectedCupomBet={handleSelectedCupomBet}
					stake={stake}
				/>
			)}

			{isExotic && (
				<BetSelectionExotic
					selectionName={exoticType}
					betTypeLabel={betTypeLabel}
					eventName={eventName}
					price={price}
					exoticSelectionType={exoticSelectionType}
					exoticSelections={exoticSelections}
				/>
			)}

			{isSRM && (
				<BetSelectionSRM
					srm={srm}
					betTypeLabel={betTypeLabel}
					eventName={eventName}
					isRacing={isRacing}
					racingBetType={racingBetType}
					price={srm.price}
					placePrice={placePrice}
					singleOnly={singleOnly}
					enableRemoval={enableRemoval}
					onMultiRemove={onMultiRemove}
					onMultiToggle={onMultiToggle}
				/>
			)}

			{!isExotic && !isMulti && !isSRM && (
				<BetSelectionSingle
				hasTote={hasTote}
					isRacing={isRacing}
					silk={silk}
					racingBetType={racingBetType}
					selectionName={selectionName}
					betTypeLabel={betTypeLabel}
					eventName={eventName}
					product_name={product_name}
					price={price}
					placePrice={placePrice}
					eachwayDisabled={eachwayDisabled}
					priceRollups={priceRollups}
					displayPriceIsBumped={displayPriceIsBumped}
					onBetTypeChange={onBetTypeChange}
					margin={margin}
					raceid_number={Dis_raceNumber}
					handleSelectedCupomBet={handleSelectedCupomBet}
					stake={stake}
					multiBetId={multiBetId}
				/>
			)}
		</div>
	);
};

BetSelection.propTypes = {
	/** Name of the event or competition */
	eventName: PropTypes.string.isRequired,

	/** The price or odds of the selection being bet on */
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

	/** The name of the selection being bet on */
	selectionName: PropTypes.string,

	/** The bet type being bet on */
	betTypeLabel: PropTypes.string,

	/** If eachway then the place price or odds of the selection being bet on */
	placePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** The image path for the racing silk */
	margin: PropTypes.number,

	/** The margin the bet is placed on */
	silk: PropTypes.string,

	/** If the selection item is not enabled for multi */
	singleOnly: PropTypes.bool,

	/** The non-exotic bet type to use with the filters */
	racingBetType: PropTypes.oneOf([
		RACING_BET_TYPE_WIN_SHORT,
		RACING_BET_TYPE_PLACE_SHORT,
		RACING_BET_TYPE_EACHWAY_SHORT,
		RACING_BET_TYPE_MARGIN_SHORT,
	]),

	/** The number of rolls to perform when the price changes */
	priceRollups: PropTypes.number,

	/** Is the event being bet racing or sports */
	isRacing: PropTypes.bool,

	/** Is the bet and exotic bet type */
	isExotic: PropTypes.bool,

	/** If bet is an exotic then what type of exotic */
	exoticType: PropTypes.oneOf([
		RACING_BET_TYPE_QUINELLA,
		RACING_BET_TYPE_EXACTA,
		RACING_BET_TYPE_TRIFECTA,
		RACING_BET_TYPE_FIRST_FOUR,
		RACING_BET_TYPE_QUADDIE,
		RACING_BET_TYPE_DAILY_DOUBLE,
		RACING_BET_TYPE_STRAIGHT_EIGHT,
	]),

	/** Is the bet a multi bet */
	isMulti: PropTypes.bool,

	/** The id of the multi bet leg */
	multiBetId: PropTypes.number,

	/** Function to call when the multi checkbox id toggled */
	onMultiToggle: PropTypes.func,

	/** Function to call when the bet is to be removed from the multi bet slip */
	onMultiRemove: PropTypes.func,

	/** Function to call when the non exotic bet type changes */
	onBetTypeChange: PropTypes.func,

	/** Function to call when the bet selection name is clicked on */
	onClickSelection: PropTypes.func,

	/** Enable the bet to be removed from the multi bet slip */
	enableRemoval: PropTypes.bool,

	user_bet_amount: PropTypes.number,

	/** What type of exotic selection is it */
	exoticSelectionType: PropTypes.oneOf(['standard', 'boxed', 'standout']),

	/** Standard exotic selection with a single runner per position */
	exoticSelections: PropTypes.arrayOf(
		PropTypes.shape({
			/** Label for the position of the runner */
			label: PropTypes.string,

			/** Runner number ro selection string */
			selection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		}),
	),

	/** Whether or not the place and eachway selectors should be disabled/hidden */
	eachwayDisabled: PropTypes.bool,

	/** Indicate that the price has been bumped up */
	displayPriceIsBumped: PropTypes.bool,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,

	Dis_raceNumber: PropTypes.number,
};

BetSelection.defaultProps = {
	selectionName: null,
	betTypeLabel: null,
	placePrice: null,
	silk: null,
	singleOnly: false,
	racingBetType: 'W',
	isRacing: true,
	margin: null,
	isExotic: false,
	exoticType: null,
	isMulti: false,
	multiBetId: null,
	onMultiToggle: undefined,
	onMultiRemove: undefined,
	onBetTypeChange: undefined,
	onClickSelection: undefined,
	enableRemoval: true,
	exoticSelectionType: 'standard',
	exoticSelections: null,
	className: null,
	eachwayDisabled: false,
	priceRollups: 0,
	displayPriceIsBumped: false,
	Dis_raceNumber: null,
	user_bet_amount: null,
};

export default BetSelection;
