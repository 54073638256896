// import env from '../commons/EnvironmentVariables';

/**
 * Searches the Harmony address database for matches
 *
 * @param fullAddress
 * @param minLength
 * @returns {Promise}
 */
async function searchAddressDatabase(fullAddress = '', minLength = 3) {
	// If the address is too short to warrant searching for, resolve to empty array
	if (fullAddress.length < minLength) {
		return new Promise((resolve) => resolve([]));
	}


	// Make call for matching addresses
	return new Promise((resolve, reject) => {
		Harmony.v2.find({ fullAddress, country: 'au' }, null, (response) => {

			if (response.status === Harmony.SUCCESS) {
				resolve(response.payload);
			} else {
				reject(response.messages);
			}
		});
	});
}

async function retrieveAddress(id) {
	return new Promise((resolve, reject) => {
		Harmony.v2.retrieve({ id }, (response) => {
			if (response.status === Harmony.SUCCESS) {
				resolve(response.payload[0]);
			} else {
				reject(response.messages);
			}
		});
	});
}

module.exports = {
	searchAddressDatabase,
	retrieveAddress,
};