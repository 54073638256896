import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';

import { BettingAdd } from '@tbh/ui-kit';

const cssSportBettingAdd = css`
	label: SportBettingAdd;

	width: 60px;
	max-width: 60px;
	flex: 1 1 60px;
`;

const SportBettingAdd = (props) => {
	const {
		className,
		priceRollups,
		addToSingle,
		addToMulti,
		selectionId,
		productId,
		bettingAvailable,
		emphasisedType,
		metric,
		hasMulti,
		size,
	} = props;

	/**
	 * Handles the clicking of a single bet
	 *
	 * @param e
	 */
	const handleAddSingle = (e) => {
		e.stopPropagation();
		addToSingle(selectionId, productId, e);
	};

	/**
	 * Handles the clicking of a multi bet
	 *
	 * @param e
	 */
	const handleAddMulti = (e) => {
		e.stopPropagation();
		addToMulti(selectionId, productId, e);
	};

	const componentClasses = cx(cssSportBettingAdd, {
		[className]: className,
	});

	return (
		<BettingAdd
			className={componentClasses}
			size={size}
			metric={metric}
			actionSingle={handleAddSingle}
			// actionMulti={hasMulti && bettingAvailable ? handleAddMulti : null}
      actionMulti={null}
			disabled={!bettingAvailable ? true : null}
			emphasisedType={emphasisedType}
			priceRollups={priceRollups}
		/>
	);
};

SportBettingAdd.propTypes = {
	/** Id of the selection being bet on */
	selectionId: PropTypes.number.isRequired,

	/** Contains the metric or product code to display */
	metric: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

	/** Action to add the selection to the bet prompt */
	addToSingle: PropTypes.func.isRequired,

	/** Contains the id of the product being bet on */
	productId: PropTypes.number,

	/** Boolean indicating if betting is available ie. race isn't closed */
	bettingAvailable: PropTypes.bool,

	/** Whether or not the bet has multi bets available */
	hasMulti: PropTypes.bool,

	/** Action to add the selection to the multi bet slip */
	addToMulti: PropTypes.func,

	/** The size of the button */
	size: PropTypes.oneOf(['1','2','3','4']),

	/** If the component needs an emphasized background color or border */
	emphasisedType: PropTypes.oneOf(['background', 'border']),

	/** The number of rolls to perform when the price changes */
	priceRollups: PropTypes.number,

	/** Extra class(es) to pass through */
	className: PropTypes.string,
};

SportBettingAdd.defaultProps = {
	className: null,
	productId: null,
	bettingAvailable: true,
	addToMulti: undefined,
	emphasisedType: null,
	hasMulti: false,
	priceRollups: 0,
	size: '2',
};

export default SportBettingAdd;
