'use strict';

const TestRouter = Backbone.Router.extend({

	/* standard routes can be mixed with appRoutes/Controllers above */
	routes : {
		'testroute' : 'showTest'
	},

	showTest: function(){
		App.startSubApp('Test');
	},



});

module.exports = new TestRouter();