// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { withNamespaces, Trans } from 'react-i18next';
import { throttle } from 'lodash-es';

// Components
import { Button, Checkbox, EmailInput, Heading, Input, Link, PasswordInput, SelectCountry, Text } from '@tbh/ui-kit';
import { withAclContext } from '../../../../../contexts/AclContext';

// Constants
import { CONSTRAINT_USERNAME, PASSWORD_8_CHAR, CONSTRAINT_EMAIL, translateConstraints } from '@tbh/ui-kit';

class SimpleRegistration extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Handler for opening the sign in screen */
		onSignIn: PropTypes.func.isRequired,

		/** Handler for registering a new user */
		onSignUp: PropTypes.func.isRequired,

		/** Validates the form */
		onValidateForm: PropTypes.func.isRequired,

		/** Link to the terms and conditions */
		termsAndConditionsLink: PropTypes.string.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Shows the loading mask on the Sign Up button */
		loadingCreateAccount: PropTypes.bool,

		/** Errors from the back end validation */
		emailError: PropTypes.string,
		passwordError: PropTypes.string,
		usernameError: PropTypes.string,
		phone_numberError: PropTypes.string,

		defaultCountry: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		loadingCreateAccount: false,
		emailError: null,
		passwordError: null,
		usernameError: null,
		phone_numberError: null,
		defaultCountry: 'Australia',
	};

	state = {
		agreeToTermsAndCondition: false,
		country: this.props.defaultCountry,
		email: '',
		password: '',
		username: '',
		phone_number: '',
		countryIsValid: true,
		emailIsValid: false,
		passwordIsValid: false,
		usernameIsValid: false,
		phone_numberIsValid: false,
	};

	/**
	 * Handles input change
	 *
	 * @param name
	 * @param value
	 * @param valid
	 */
	handleChange = (name, value, valid) => {
		this.setState(
			{
				[name]: value,
				[`${name}IsValid`]: valid,
			},
			() => {
				// if the input is valid, run the form validation
				if (valid) {
					this.throttled(name, value);
				}
			},
		);
	};

	/**
	 * Handles the terms and conditions checkbox
	 */
	handleCheckbox = () => {
		this.setState({
			agreeToTermsAndCondition: !this.state.agreeToTermsAndCondition,
		});
	};

	/**
	 * Handles the request to create an account
	 */
	handleSignUp = () => {
		const { country, email, password, username, phone_number } = this.state;
		const user = { country, email, password, username, phone_number };

		this.props.onSignUp(user);
	};

	throttled = throttle(() => {
		this.handleValidateForm();
	}, 1000);

	/**
	 * Validates the form if all fields are valid in the front end
	 */
	handleValidateForm = () => {
		const { country, email, password, username, phone_number } = this.state;
		const form = {
			country,
			email,
			password,
			username,
			phone_number,
		};
		this.props.onValidateForm(form);
	};

	render() {
		const {
			t,
			className,
			emailError,
			loadingCreateAccount,
			onSignIn,
			passwordError,
			termsAndConditionsLink,
			usernameError,
			phone_numberError,
		} = this.props;

		const {
			agreeToTermsAndCondition,
			country,
			countryIsValid,
			email,
			emailIsValid,
			password,
			passwordIsValid,
			username,
			usernameIsValid,
			phone_number,
			phone_numberIsValid,
		} = this.state;

		const componentClasses = cx({
			[className]: className,
		});

		const formIsValid =
			countryIsValid &&
			emailIsValid &&
			passwordIsValid &&
			usernameIsValid &&
			phone_numberIsValid &&
			(!termsAndConditionsLink || agreeToTermsAndCondition) &&
			!emailError &&
			!passwordError &&
			!usernameError &&
			!phone_numberError;

		translateConstraints(t, [CONSTRAINT_USERNAME, PASSWORD_8_CHAR, CONSTRAINT_EMAIL]);

		return (
			<div className={componentClasses}>
				<Heading type="5" centered>
					{t('SimpleRegistration__Header')}
				</Heading>

				<Text size="-1" align="center" paragraph>
					{t('SimpleRegistration__HaveAccount')}{' '}
					<Link action={onSignIn} type="secondary" underline>
						{t('SimpleRegistration__SignIn')}
					</Link>
				</Text>

				<Heading type="6">{t('SimpleRegistration__YourDetails')}</Heading>

				<EmailInput
					name="email"
					label={t('EmailAddress')}
					placeholder="yourname@email.com"
					onChange={this.handleChange}
					value={email}
					error={emailError}
					constraints={CONSTRAINT_EMAIL}
					margin="cozy"
				/>

				<Input
					name="username"
					label={t('Username')}
					onChange={this.handleChange}
					value={username}
					error={usernameError}
					constraints={CONSTRAINT_USERNAME}
					margin="cozy"
				/>

				<PasswordInput
					name="password"
					label={t('Password')}
					onChange={this.handleChange}
					value={password}
					error={passwordError}
					constraints={PASSWORD_8_CHAR}
					margin="cozy"
				/>

				<Input
					name="phone_number"
					label={t('PhoneNumber')}
					onChange={this.handleChange}
					value={phone_number}
					error={phone_numberError}
					margin="cozy"
					type="tel"
				/>

				<SelectCountry onChange={this.handleChange} value={country} />

				{termsAndConditionsLink && (
					<Text size="-1" paragraph>
						<Checkbox
							name="agreeToTermsAndCondition"
							value="agreeToTermsAndCondition"
							action={this.handleCheckbox}
							checked={agreeToTermsAndCondition}
							pushRight="1"
							inline
						/>
						<Trans i18nKey="SimpleRegistration__TermsAndConditions">
							Agree to{' '}
							<Link href={termsAndConditionsLink} type={Link.types.PRIMARY} size="-1" target="blank" underline>
								terms and conditions
							</Link>
						</Trans>
					</Text>
				)}

				<Button
					type={Button.types.PRIMARY}
					action={this.handleSignUp}
					disabled={!formIsValid}
					loading={loadingCreateAccount}
					block
				>
					{t('SimpleRegistration__SignUpNow')}
				</Button>
			</div>
		);
	}
}

export const NameSpacedSimpleRegistration = withNamespaces()(SimpleRegistration);

export default withAclContext(NameSpacedSimpleRegistration);
