// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { spacings } from '@tbh/ui-kit';

// Components
import { Button, ButtonGroup, Notification, Text, Panel } from '@tbh/ui-kit';

// Functions
import { countdown } from '../../../../../legacy/core/format';

const StyledReminderDialog__Options = styled(ButtonGroup)(
	(props) => css`
		label: ReminderDialog__Options;

		margin-top: ${spacings(props).cozy}px;
		margin-bottom: ${spacings(props).cozy}px;
	`,
);

class ReminderDialog extends React.Component {
	static propTypes = {
		/** Hook for updating the Reality Check */
		continueRealityCheck: PropTypes.func.isRequired,

		/** Hook for logging out the user */
		onLogout: PropTypes.func.isRequired,

		/** User's current reality check */
		reality_check: PropTypes.number.isRequired,

		/** Starting time for the timer */
		start_time: PropTypes.number.isRequired,

		/** Clears the error message */
		clearError: PropTypes.func.isRequired,

		/** Error returned from the function continueRealityCheck */
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		error: null,
		className: null,
	};

	state = {
		elapsed: 30, // Start with 30 seconds
	};

	componentDidMount = () => {
		this.timer = window.setInterval(this.tick, 1000);
	};

	componentWillUnmount = () => {
		window.clearInterval(this.timer);
	};

	/**
	 * update the timer by ticking down 1 second
	 */
	tick = () => {
		this.setState(prevState => ({
			elapsed: prevState.elapsed - 1,
		}));

		if (this.state.elapsed <= 0) {
			this.props.onLogout();
		}
	};

	render() {
		const { reality_check, continueRealityCheck, onLogout, error, clearError, className, isLoading } = this.props;

		let { elapsed } = this.state;

		const realityCheckSettingsClass = cx({
			[className]: className,
		});

		const handleContinueRealityCheck = () => {
			clearError();
			continueRealityCheck();
		};

		return (
			<div className={realityCheckSettingsClass}>
				{error && (
					<Notification strong type={Notification.types.COLOUR_DANGER} message={error} buttonAction={clearError} />
				)}

				<Panel padding={Panel.padding.SPACING_COZY}>
					<Text size="-1" align="center" strong>
						You have been logged into your account for {reality_check} {reality_check === 1 ? 'hour' : 'hours'}.
					</Text>
{/* 
					<Panel padding={Panel.padding.SPACING_COMFORTABLE}>
						<Text align="center" type="danger" size="3" strong>
							{countdown(elapsed)}
						</Text>
						<Text size="-1" align="center">
							To go before auto logout
						</Text>
					</Panel> */}

					<StyledReminderDialog__Options center>
						<Button type="ghost" action={onLogout} flex>
							Logout
						</Button>

						<Button type="primary" action={handleContinueRealityCheck} flex loading={isLoading} disabled={isLoading}>
							Continue
						</Button>
					</StyledReminderDialog__Options>

					<Notification
						type="info"
						transparent
						message="You will be logged out automatically unless you chose to continue."
					/>
				</Panel>
			</div>
		);
	}
}

export default ReminderDialog;
