import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, ui, brand, Button } from '@tbh/ui-kit';

import BetSelection from '../BetSelection';
import BetAmountAndPayout from '../../BetAmountAndPayout/BetAmountAndPayout';
import NonExoticFilter from '../../NonExoticFilter/NonExoticFilter';
import { centsAsDollars } from '../../../../../legacy/core/format';
import {
	BET_UNRESULTED_STATUS,
	BET_PENDING_STATUS,
	BET_PARTIAL_REFUND_STATUS,
} from '../../../../../common/constants/Bets';
import { RACING_BET_TYPE_PLACE_SHORT, RACING_BET_TYPE_WIN_SHORT } from '../../../../../common/constants/Racing';
import BetNotificationEventClosed from '../../BetNotification/BetNotificationEventClosed';
// import { RACING_BET_TYPE_EACHWAY_SHORT } from '../../../../../common/constants/Racing';

const StyledBetSelectionAndAmount = styled('div')(
	(props) => css`
		@keyframes greenPulse {
			0% {
				background-color: rgba(0, 255, 0, 0.2);
			}
			50% {
				background-color: rgba(0, 255, 0, 0.4);
			}
			100% {
				background-color: rgba(0, 255, 0, 0.2);
			}
		}

		@keyframes redPulse {
			0% {
				background-color: rgba(255, 0, 0, 0.2);
			}
			50% {
				background-color: rgba(255, 0, 0, 0.4);
			}
			100% {
				background-color: rgba(255, 0, 0, 0.2);
			}
		}

		label: BetSelectionAndAmount;
		position: relative;
		padding: 8px;
		border: 2px solid ${ui(props).color_4};
		${props.disabled &&
			css`
				opacity: 0.5;
				pointer-events: none;
			`};

		${props.warning &&
			props.invalid &&
			css`
				border: 2px solid ${ui(props).color_danger};
			`};

		${props.pulse &&
			css`
				animation: ${props.pulse === 'green' ? 'greenPulse' : 'redPulse'} 1s infinite;
				animation-timing-function: ease-in-out;
			`};

		${props.selectedBet && 'border: 2px solid #660809!important;'}
		border-radius:4px!important;
	`,
);

const StyledBetSelectionAndAmount__DetailsWrapper = styled('div')(
	(props) => css`
		label: BetSelectionAndAmount__DetailsWrapper;

		// padding: ${spacings(props).compact}px;
		position: relative;
	`,
);

const StyledBetSelectionAndAmount__Filter = styled(NonExoticFilter)(
	(props) => css`
		label: BetSelectionAndAmount__Filter;

		width: 60px;
		height: 36px;
		margin-right: ${spacings(props).compact}px;
		margin-top: -${spacings(props).tight}px; // This ensures the exotic selector and the BetAmountAndPayout are lined up correctly
		display: none;
	`,
);
// End Styling
const StyledTimeToRace = styled('div')(
	(props) => css`
		label: StyledTimeToRace;

		display: block;
		background: ${brand(props).rch_4};
		border-color: ${brand(props).rch_4};
		font-size: 12px;
		width: auto;
		color: #ffffff;
		font-weight: bold;
		padding: 4px;
		border-radius: 3px !important;
		${props.lessThan2Min &&
			css`
				&& {
					background-color: #379b37;
					border: 1px solid #379b37;
				}
			`};
	`,
);
// "bet_type": "win",
// "bet_type_id": 1,
// "available": true,
// "multi_available": true,
// "fixed": true,
// "product_code": "GB",
// "product_id": 13

export const PriceBumpButton = styled(Button)(
	(props) => css`
		border-radius: ${spacings(props).tight}px;
		border-bottom-width: 1px !important;
		background-color: #fff;
		border: 1px solid ${brand(props).rch_4};
		transition: all 0.2s ease-in-out;

		@keyframes Fire {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@keyframes JumpRocket {
			0% {
				transform: scale(1);
			}
			50% {
				transform: scale(1.2);
			}
			100% {
				transform: scale(1);
			}
		}

		.fire {
			display: none;
			position: absolute;
			transform: rotate(235deg);
			left: 2px;
			top: 18px;
			font-size: 8px;
			color: white;
			animation: Fire 0.5s infinite alternate;
		}

		&:hover {
			background-color: ${brand(props).rch_4};
			.fa-rocket {
				animation: JumpRocket 1s infinite;
			}

			.fire {
				display: block;
			}
		}

		${props.active &&
			`
			background-color: ${brand(props).rch_4};
			color: #fff;
			.fa-rocket {
				animation: JumpRocket 1s infinite;
			}

			.fire {
				display: block;
			}
		`}
	`,
);

class BetSelectionAndAmount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pulse: null,
		};
	}

	getSelectedPrice = () => {
		const { racingBetType } = this.props;
		if (racingBetType === RACING_BET_TYPE_WIN_SHORT) {
			return 'winPrice';
		}

		if (racingBetType === RACING_BET_TYPE_PLACE_SHORT) {
			return 'placePrice';
		}

		return 'price';
	};

	componentDidUpdate(prevProps, prevState) {
		const type = this.getSelectedPrice();

		const sameBet =
			prevProps.product_id == this.props.product_id && prevProps.racingBetType == this.props.racingBetType;
		if (prevProps[type] !== this.props[type] && prevState.pulse === this.state.pulse && sameBet) {
			this.setState({ pulse: this.props[type] > prevProps[type] ? 'green' : 'red' });
			setTimeout(() => {
				this.setState({ pulse: null });
			}, 5000);
		}
	}

	render() {
		const {
			t,
			id,
			selectionDisplay,
			betTypeLabel,
			eachwayDisabled,
			eventDisplay,
			isMulti,
			isRacing,
			isSRM,
			price,
			onMultiToggle,
			onRemove,
			onStakeChange,
			onBetTypeChange,
			onClickSelection,
			racingBetType,
			enableRemoval,
			placePrice,
			winPrice,
			disabled,
			invalid,
			singleOnly,
			status,
			stake,
			onDismiss,
			className,
			bet,
			user_bet_amount,
			cash_out_enabled,
			srm,
			selectionsAreFromSameRace,
			selectedBetType,
			startTime,
			isLeg,
			shouldPulse,
			product_name,
			product_id,
			bet_code,
			hasTote,
			eachway,
			handleSelectedCupomBet,
			handleBonusBetChange,
			bonus_bet_enabled,
			price_bump_enabled,
			free_credit_flag,
			onChangeBetType,
			selection_count,
			selectedBet,
			handleSelectedBet,
			isClosed,
			hasGBProduct,
			raceType
		} = this.props;

		let { warning } = this.props;
		/**
		 * Update the stake with the given amount
		 *
		 * @param name
		 * @param value
		 * @param racingBetType
		 */
		const handleInputChange = (name, value) => {
			onStakeChange(id, value, racingBetType, product_id);
		};

		/**
		 * Handle warning message being closed
		 */
		const handleDismiss = () => {
			onDismiss(id);
		};

		/**
		 * Determine if a Bet has a pending status
		 */
		const isPending = (status) => {
			return [BET_UNRESULTED_STATUS, BET_PENDING_STATUS, BET_PARTIAL_REFUND_STATUS].includes(status);
		};

		if (!warning && isPending(status)) {
			warning = t('UnitStake__PendingReview');
		}

		/**
		 * Handle the bet type being changed on the non-exotic filter for races
		 *
		 * @param betType
		 */
		const handleBetTypeChange = (betType, isTote) => {
			onBetTypeChange(id, betType, selectedBetType, product_id, isTote);
		};

		const componentClasses = cx({
			[className]: className,
		});

		const showBetAmmount = (selectionsAreFromSameRace && isMulti) || !isMulti || isSRM;



		return (
			<StyledBetSelectionAndAmount
				className={componentClasses}
				disabled={disabled}
				warning={warning}
				invalid={invalid}
				pulse={this.state.pulse}
				selectedBet={selectedBet}
				onClick={handleSelectedBet}
			>
				{isClosed && <BetNotificationEventClosed onClick={onRemove} />}
				{/* {warning && !invalid ? <OverlayMessage action={handleDismiss} message={warning} open /> : null} */}
				{/* <StyledTimeToRace lessThan2Min={Math.abs(new Date(startTime) - new Date()) >= 120000}>
					{countdown(startTime)}
				</StyledTimeToRace> */}
				<StyledBetSelectionAndAmount__DetailsWrapper
					style={{
						opacity: isClosed ? 0.3 : 1,
					}}
				>
					<BetSelection
						className={css`
							padding: 0;
						`}
						multiBetId={id}
						selectionName={selectionDisplay}
						betTypeLabel={betTypeLabel}
						eachwayDisabled={eachwayDisabled}
						eventName={eventDisplay}
						isMulti={isMulti}
						isSRM={isSRM}
						isRacing={isRacing}
						price={price}
						onMultiToggle={onMultiToggle}
						onMultiRemove={onRemove}
						onBetTypeChange={onBetTypeChange}
						onClickSelection={onClickSelection}
						racingBetType={racingBetType}
						enableRemoval={enableRemoval}
						placePrice={placePrice}
						singleOnly={singleOnly}
						user_bet_amount={user_bet_amount}
						srm={srm}
						product_name={product_name}
						hasTote={hasTote}
						handleSelectedCupomBet={handleSelectedCupomBet}
						stake={stake}
						startTime={startTime}
					/>

					<div
						className={css`
							display: flex;
							align-items: start;
						`}
					>
						{/* {isRacing && eachwayDisabled && (
							<StyledBetSelectionAndAmount__Filter betType={racingBetType} action={handleBetTypeChange} />
						)} */}

						<BetAmountAndPayout
							className={css`
								padding: 0;
								flex: 1;
							`}
							raceType={raceType}
							pulse={this.state.pulse}
							onInputChange={handleInputChange}
							onChangeBetType={onChangeBetType}
							//stake={stake ? stake : bet}
							stake={stake ? stake : user_bet_amount}
							price={price}
							winPrice={winPrice}
							placePrice={placePrice}
							racingBetType={racingBetType}
							placeHolder={centsAsDollars(bet, true)}
							betTypeLabel={betTypeLabel}
							displayFlexed
							cash_out_enabled={cash_out_enabled}
							onBetTypeChange={handleBetTypeChange}
							isLeg={isLeg}
							hasTote={hasTote}
							bet_code={bet_code}
							eachway={eachway}
							handleBonusBetChange={handleBonusBetChange}
							bonus_bet_enabled={bonus_bet_enabled}
							price_bump_enabled={price_bump_enabled}
							free_credit_flag={free_credit_flag}
							selection_count={selection_count}
							startTime={startTime}
							product_id={product_id}
							hasGBProduct={hasGBProduct}
						/>
					</div>
				</StyledBetSelectionAndAmount__DetailsWrapper>
				{/* {warning && invalid ? (
					 <Notification action={handleDismiss} type={Notification.types.COLOUR_DANGER} message={warning} strong />
				) : null} */}
			</StyledBetSelectionAndAmount>
		);
	}
}
BetSelectionAndAmount.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** The id of the selection */
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

	/** The name of the selection being bet on */
	selectionDisplay: PropTypes.string.isRequired,

	/** The bet type being bet on */
	betTypeLabel: PropTypes.string.isRequired,

	/** Name of the event or competition */
	eventDisplay: PropTypes.string.isRequired,

	/** Is the bet a multi bet */
	isMulti: PropTypes.bool.isRequired,

	/** Is the event being bet racing or sports */
	isRacing: PropTypes.bool.isRequired,

	/** The price or odds of the selection being bet on */
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

	/** function to call when the input changes */
	onStakeChange: PropTypes.func.isRequired,

	/** The non-exotic bet type to use with the filters */
	racingBetType: PropTypes.string,

	/** Whether or not the place and eachway selectors should be disabled/hidden */
	eachwayDisabled: PropTypes.bool,

	/** Function to call when the non exotic bet type changes */
	onBetTypeChange: PropTypes.func,

	/** Function to call when the multi checkbox id toggled */
	onMultiToggle: PropTypes.func,

	/** Function to call when the bet is to be removed from the multi bet slip */
	onRemove: PropTypes.func,

	/** Function to call when the bet is clicked on */
	onClickSelection: PropTypes.func,

	/** Enable the bet to be removed from the multi bet slip */
	enableRemoval: PropTypes.bool,

	/** If eachway then the place price or odds of the selection being bet on */
	placePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** The user's defined bet amount */
	stake: PropTypes.number,

	/** If the selection item is not enabled for multi */
	singleOnly: PropTypes.bool,

	/** If the selection item is disabled or not */
	disabled: PropTypes.bool,

	/** If the selection item is invalid or not */
	invalid: PropTypes.bool,

	/** The status of the bet, if it is in one */
	status: PropTypes.string,

	/** Warning or error message */
	warning: PropTypes.string,

	/** Function for dismissing any error message */
	onDismiss: PropTypes.func,

	/** Extra style class(es) to pass through */
	className: PropTypes.string,

	defaultBetAmount: PropTypes.number,

	bet: PropTypes.number,

	user_bet_amount: PropTypes.number,

	/** The currently logged in User */
	user: PropTypes.shape({
		/** The bonus bet balance for the user */
		free_credit_balance: PropTypes.number,

		/** The number of sports boosts available to the user */
		sports_boost_available: PropTypes.number,

		/** The number of racing boosts available to the user */
		racing_boost_available: PropTypes.number,

		/** The number of butt bets available to the user */
		racing_butt_available: PropTypes.number,
	}),
};

BetSelectionAndAmount.defaultProps = {
	onBetTypeChange: () => {},
	racingBetType: 'W',
	enableRemoval: true,
	eachwayDisabled: false,
	placePrice: null,
	disabled: false,
	invalid: false,
	singleOnly: false,
	status: null,
	stake: null,
	warning: null,
	onDismiss: () => {},
	onMultiToggle: () => {},
	onRemove: () => {},
	onClickSelection: () => {},
	className: '',
	user: null,
	defaultBetAmount: 0,
	bet: 0,
};

export default withNamespaces()(BetSelectionAndAmount);
