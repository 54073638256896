import styled, { css } from 'react-emotion';
import { spacings, ui, Button, media, brand, Popover, Text, Image } from '@tbh/ui-kit';
import { DEFAULT_COLORS } from '../../../../constants/themes';
// Components
import {
	MAIN_APPLICAION_BACKGROUND_COLOR,
	FONT_SIZE_10,
	moreThan2minColor,
	lessThan2MinColor,
} from '../../../../store/application/applicationConstants';

export const StyledPopover = styled(Popover)(
	(props) =>
		css`
			label: popover;

			height: auto;
			background: #f7f8f8;
			display: -webkit-flex; /* Safari */
			max-width: 250px;
		`,
);

/**
 *
 */

export const StyledCarouselItemV3_header_content = styled('div')(
	(props) => css`
		padding: 5px 0px;
		border-top: 0px solid #2b2b2b;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		color: white;
	`,
);

export const StyledCarouselItemV3_hedar_left_content = styled('div')`
	${(props) =>
		css`
			label: CarouselItemV4_runner_left_content;

			// padding-top: ${spacings(props).tight}px;
			padding-left: ${spacings(props).tight}px;
			display: flex;

			flex: 0 0 70%;
			flex-direction: row;
			justify-content: flex-start;
			overflow: hidden;
			text-overflow: ellipsis;
		`};

	${media(
		css`
			padding-top: 0;
		`,
		660,
	)};
`;

export const StyledCarouselItemV3_right_hedear_content = styled('div')`
	${(props) =>
		css`
			label: CarouselItemV4_right_runner_content;

			display: flex;
			flex-direction: row;
			flex: 0 0 30%;
			max-width: 30%;
			justify-content: center;
			overflow: hidden;
			text-overflow: ellipsis;
			// margin-top: -10px;
		`};

	${media(
		css`
			padding-top: 0;
		`,
		660,
	)};
`;

export const StyledCarouselItemV3_race_closed_fristCircle = styled('div')`
	${(props) =>
		css`
			label: CarouselItemV3_race_closed_fristCircle;

			background-color: ${moreThan2minColor};
			width: 36px;
			height: 36px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid ${moreThan2minColor};
			border-radius: 50%;
			${props.lessThan2Min === 'less2Min' &&
				css`
					&& {
						background-color: ${lessThan2MinColor};
						border: 1px solid ${lessThan2MinColor};
					}
				`};
		`};
`;
export const StyledCarouselItemV3_race_closed_fristCircleLoading = styled('div')`
	${(props) =>
		css`
			label: CarouselItemV3_race_closed_fristCircle;

			background-color: ${moreThan2minColor};
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			border-radius: 50%;
			animation: buttonLoading 2s infinite;
		`};
`;

export const StyledCarouselItemV3_race_closed_secondCircle = styled('div')(
	(props) => css`
		label: CarouselItemV3_race_closed_secondCircle;

		background-color: #fff;
		// top: 4.5%;
		// left: 4.5%;
		width: 88%;
		height: 88%;
		position: relative;
		border: 1px solid white;
		border-radius: 50%;
		color: black;
		font-weight: 600;
		padding: 5px;
		text-align: center;
		font-size: ${FONT_SIZE_10};
	`,
);

export const StyledCarouselItemV3_race_post = styled('div')`
	${(props) =>
		css`
			label: styledCarouselItemV3_race_post;

			position: absolute;
			top: 64%;
			left: 84%;
			height: 15px;
			background: no-repeat url('https://grsresource.s3.ap-southeast-2.amazonaws.com/site-resources/images/post.png');
		`};
`;

export const StyledCarouselItemV3__Flage = styled('span')(
	(props) => css`
		label: StyledCarouselItemV3__Flage;
		display: flex;
		flex-direction: column;
		justify-content: center;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-top: 0px;
		margin-left: 0px;
	`,
);
export const StyledCarouselItemV4__Image = styled(Image)(
	(props) => css`
		label: CarouselItem__Image;

		margin: 0 0 ${spacings(props).compact}px 0;
		pointer-events: none;
		max-width: none;

		${props.center &&
			css`
				margin-left: auto;
				margin-right: auto;
			`};
	`,
);
export const StyledCarouselItemV4__Label = styled(Text)(
	(props) => css`
		label: CarouselItem__Label;

		display: block;
		margin-bottom: ${spacings(props).tight}px;
	`,
);
export const StyledCarouselItemV4_Race_name = styled(Text)(
	(props) => css`
		label: StyledCarouselItemV4_Race_name;
		padding-top: ${spacings(props).tight}px;
		overflow: hidden;
		text-overflow: ellipsis;
		width: auto;
		color: #ffffff;
		font-size: ${FONT_SIZE_10};
		margin-left: ${spacings(props).tight}px;
	`,
);
export const StyledCarouselItemV4_Race_Number = styled('span')(
	(props) => css`
		label: StyledCarouselItemV4_Race_Number;
		// padding-top: ${spacings(props).tight}px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		overflow: hidden;
		text-overflow: ellipsis;

		margin-left: 4px;
		font-size: ${FONT_SIZE_10};
	`,
);
export const StyledCarouselItemV4__Meta = styled(Button)(
	(props) => css`
		label: CarouselItem__Meta;
		//border-radius: 12px !important;
		display: block; //import { LoadingSpanner } from './../../Application/LoadingSpinner/LoadingSpinner';

		margin-top: ${spacings(props).tight}px;
		background: ${brand(props).rch_4};
		border-color: ${brand(props).rch_4};
		size: small;
		width: 55px;
		height: 30px;
		margin-left: 23px;
	`,
);

export const StyledCarouselItemV4 = styled(Text)(
	(props) => css`
		label: CarouselItem;

		display: block;
		position: relative;
		cursor: pointer;
		background: ${brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color};
		height: 80%;
		width: 200px;

		// new Style 
		// border: 1px solid white;
		// border-radius: 4px;

		// Old Style
		border-right: 1px solid white;
		${props.center &&
			css`
				text-align: center;
			`};

		// ${props.padding &&
			css`
				padding: ${spacings(props).compact}px;
			`};

		${props.margin &&
			css`
				margin-right: ${spacings(props).cozy}px;
			`};

		${props.border &&
			css`
				//border-right: 1px solid ${ui(props).color_3};
				// border-right: 1px solid ${MAIN_APPLICAION_BACKGROUND_COLOR};
			`};

		${props.slides &&
			props.slides === '1' &&
			css`
				width: 100%;
			`};

		${props.slides &&
			props.slides === '2' &&
			css`
				width: 50%;
			`};
	`,
);

export const StyledLoading = styled('div')`
	${(props) => css`
		width: ${props.width ? props.width : '100%'};
		height: ${props.height};
		margin-bottom: 5px;
		border-radius: ${props.radius ? props.radius : '4px'};
		background-color: rgb(54, 69, 79);
		animation: changeColor 1s infinite;
		@keyframes changeColor {
			0% {
				background-color: rgba(54, 69, 79, 0.2);
			}
			25% {
				background-color: rgba(54, 69, 79, 0.6);
			}
			50% {
				background-color: rgba(54, 69, 79, 0.8);
			}
			75% {
				background-color: rgba(54, 69, 79, 0.6);
			}
			100% {
				background-color: rgba(54, 69, 79, 0.4);
			}
		}
	`};
`;

export const styledFlag = css`
	width: 22px;
	height: 12px;
	margin-right: 8px;
`;
export const styledLoading = css`
	width: 25px;
	height: 12px;
	background-color: rgb(54, 69, 79);
`;
export const styledLoadingIcon = css`
	width: 22px;
	height: 12px;
	background-color: rgba(54, 69, 79, 1);
	border-radius: 20px;
	-webkit-animation: changeColor 2s linear infinite;
	animation: changeColor 2s linear infinite;

	@keyframes changeColor {
		0% {
			background-color: rgba(54, 69, 79, 0.2);
		}
		25% {
			background-color: rgba(54, 69, 79, 0.6);
		}
		50% {
			background-color: rgba(54, 69, 79, 0.8);
		}
		75% {
			background-color: rgba(54, 69, 79, 0.6);
		}
		100% {
			background-color: rgba(54, 69, 79, 0.4);
		}
	}
`;
