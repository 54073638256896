import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { Panel, Icon } from '@tbh/ui-kit';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Modal from '../../../../components/controllers/Modal/Modal';
import {
	SELECTION_NOT_SCRATCHED_STATUS,
	RACING_BET_TYPE_MARGIN,
	GENERIC_SILK,
	RACING_TYPES_LOOKUP,
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_PLACE,
} from '../../../../common/constants/Racing';

import { PlotElements, Text } from '@tbh/ui-kit';
import FeatureRaceSelectionListItem from './FeatureRaceSelectionListItem/FeatureRaceSelectionListItem';
import { FONT_SIZE_12, COLORS, WEIGHTS } from '../../../../store/application/applicationConstants';
import {
	StyledRaceSelectionsList__ProductIcon,
	StyledbetFeatureRaceSelectionList,
	StyledFlusRaceHeaderBar,
	StyledFlucsRaceHeaderBar__RaceNumber,
	FlucsRaceHeaderBarModle,
	StyledFeatureRaceSelections__Name,
	StyledFeaRaceSelectionsDetails,
	StyledRaceSelectionsDetails__Silk,
	StyledRaceSelectionsDetails__SilkImage,
	StyledFeRaceSelectionsDetails__LastStartsRow,
	StyledFeRaceSelectionsDetails__DetailsColumn,
	StyledRaceSelectionsDetails__Name,
	StyledRaceSelectionsDetails__RunnerDetails,
	StyledFeatureRaceSelectionList_header__Wrapper,
	StyledRaceSelectionsList__HeaderFlucs,
	StyledRaceSelectionsList__HeaderCell,
	StyledbetFeatureRaceSelectionList__RaceSelectionsListItems,
	modelPanel,
} from './FeatureRaceSelectionList.styled';
import { PLACE_ICON_URL, WIN_ICON_URL } from '../../../../store/entities/constants/BetConstants';

class FeatureRaceSelectionList extends Component {
	static initialState = (props = {}) => ({
		/** Props used for checking updates */
		raceId: props.raceId,
		betType: props.betType,
	});

	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
			height: PropTypes.number,
		}),

		/** If race changes clear state. */
		raceId: PropTypes.number.isRequired,

		/** Array of objects that contain all the selection details */
		selections: PropTypes.arrayOf(
			PropTypes.shape({
				/**
				 * Selections will contain all the selection details but besides id' they are not needed to be defined here.
				 * They are defined in the RaceSelectionsListItem
				 */
				id: PropTypes.number.isRequired,

				/** The list of prices for the selection */

				prices: PropTypes.arrayOf(
					PropTypes.shape({
						/** The current price for a win bet with this product */
						win_odds: PropTypes.number,

						/** The current price for a place bet */
						place_odds: PropTypes.number,

						/** The current price for a margin bet */
						margin_odds: PropTypes.number,

						/** The code for this product with this product */
						product_code: PropTypes.string.isRequired,

						/** The product id (NOTE: this is not a unique id between products) */
						product_id: PropTypes.number.isRequired,
					}),
				),
			}),
		).isRequired,

		/**
		 * This will be an array of bet products that will be displayed for each selection
		 * The order of display will be from left to right as given in the array.
		 */
		displayedBetProducts: PropTypes.arrayOf(
			PropTypes.shape({
				/** The bet type for the product */
				bet_type: PropTypes.string.isRequired,

				/** The product ID of the bet product */
				product_id: PropTypes.number.isRequired,

				/** The product code of the bet product */
				product_code: PropTypes.string.isRequired,

				/** The product image */
				product_image: PropTypes.string,

				/** The type of product if it is a special product, eg. butt */
				product_type: PropTypes.string,

				/** If the product is available for betting */
				available: PropTypes.bool,
			}),
		).isRequired,

		/** Action to add the selection to the single bet prompt */
		clickSingle: PropTypes.func.isRequired,

		/** Action for handling adding the bet to the multi bet slip */
		clickMulti: PropTypes.func.isRequired,

		/** Boolean indicating if betting is available ie. race isn't closed */
		bettingAvailable: PropTypes.bool.isRequired,

		/** Whether or not the component is being viewed in small context */
		isMobileWidth: PropTypes.bool,

		/** The currently selected bet type */
		betType: PropTypes.string,

		/** If the currently selected bet type is boxed */
		boxed: PropTypes.bool,
		/** Which fluctuations to display, or don't display if null */
		// displayedFlucs: PropTypes.oneOf([null, 'apn_fluctuations', 'topbetta_fluctuations']),

		winPlaceProducts: PropTypes.array,

		raceNumber: PropTypes.number.isRequired,

		raceName: PropTypes.string.isRequired,
		Type: PropTypes.string.isRequired,
		StartDate: PropTypes.string.isRequired,
		MettingName: PropTypes.string.isRequired,
		selectionType: PropTypes.string,
		meetingCountry: PropTypes.string,
		raceDistance: PropTypes.string,
	};
	static defaultProps = {
		isMobileWidth: false,
		displayedFlucs: null,
		betType: '',
		boxed: false,
		className: null,
		winPlaceProducts: [],
		size: null,
		selectionType: '',
		meetingCountry: null,
		raceDistance: '',
	};

	constructor(props) {
		super(props);

		this.state = FeatureRaceSelectionList.initialState(props);
		this.state = { showVerifyExplanation: false, FlucsData: '', runnerName: '', runnerNumber: '' };
	}

	/**
	 * Builds products header.
	 * Uses image | 'Fixed' for fixed product | product code
	 *
	 * @param betProduct
	 * @return {string}
	 */
	buildProductHeader = (betProduct) => {
		let productHeader;
		if (betProduct.product_image) {
			productHeader = (
				<StyledRaceSelectionsList__ProductIcon src={betProduct.product_image} alt={betProduct.product_code} />
			);
		} else {
			productHeader = `${this.props.t(betProduct.product_code)} `;
		}
		return productHeader;
	};
	/**
	 * Handles the single bet clicks
	 *
	 * @param selectionId
	 * @param productId
	 * @param betType
	 */
	handleSingle = (selectionId, productId, betType) => {
		this.props.clickSingle(selectionId, productId, betType);
	};

	/**
	 * Handles the multi bet clicks
	 *
	 * @param {number} selectionId
	 * @param {number} productId
	 * @param {string} betType either 'win' or 'place'
	 */
	handleMulti = (selectionId, productId, betType) => {
		this.props.clickMulti(selectionId, productId, betType);
	};

	/**
	 * Add a selection id to the list of opened flucs items chart
	 *
	 */

	handleShowVerifyExplanation = () => {
		this.setState({
			showVerifyExplanation: true,
		});
	};

	handleHideVerifyExplanation = () => {
		this.setState({
			showVerifyExplanation: false,
		});
	};

	/**
	 * model open
	 */
	onOpenModal = () => {
		this.setState({ open: true });
	};
	/**
	 * model close
	 */
	onCloseModal = () => {
		this.setState({ open: false });
	};
	/**
	 *
	 */
	ShowModal = (fuData, runnerName, number, silk, raceNumber, last_starts_string, trainer, jockey, weight) => {
		this.setState({
			showVerifyExplanation: true,
			FlucsData: fuData,
			runnerName: runnerName,
			runnerNumber: number,
			runnerSilk: silk,
			raceNumber: raceNumber,
			last_starts_string: last_starts_string,
			trainer: trainer,
			jockey: jockey,
			weight: weight,
		});
	};

	render() {
		const {
			t,
			className,
			displayedBetProducts,
			selections,
			bettingAvailable,
			betType,
			buildSelectionBetButtons,
			size,
			boxed,
			raceNumber,
			raceName,
			Type,
			StartDate,
			MettingName,
			meetingId,
			selectionType,
			meetingCountry,
			raceDistance,
			goToRace,
			raceId,
			onClickItem
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		// remove duplicate value
		//const RemovedDuplicateDisplayedBetProducts= Array.from(new Set(displayedBetProducts));
		const small = displayedBetProducts.length == 2;
		const betProducts = [];

		displayedBetProducts.forEach((betProduct) => {
			if (!betProducts.find(b => b.bet_type == betProduct.bet_type)) {
				betProducts.push(betProduct);
			}
		});

		betProducts.sort((a, b) => {
			if (a.bet_type == RACING_BET_TYPE_WIN) {
				return -1;
			}

			if (b.bet_type == RACING_BET_TYPE_WIN) {
				return 1;
			}

			if (a.bet_type == RACING_BET_TYPE_PLACE) {
				return -1;
			}

			if (b.bet_type == RACING_BET_TYPE_PLACE) {
				return 1;
			}
		});

		const topMapSelection = new Map(displayedBetProducts.map(obj => [obj.bet_type, obj]));
		const renderSelection = [...topMapSelection.values()];
		const sortBySelectionType = renderSelection.sort((a, b) => a.bet_type_id - b.bet_type_id);

		const customStyles = {
			content: {
				top: '50%',
				left: '30%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '150%',
				transform: 'translate(-50%, -50%)',
			},
		};

		return (
			<StyledbetFeatureRaceSelectionList className={componentClasses}>
				<Panel>
					{this.state.showVerifyExplanation && (
						<Modal
							open={this.state.showVerifyExplanation}
							onClose={this.handleHideVerifyExplanation}
							style={customStyles}
							componentKey={'flucs'}
						>
							<Panel className={modelPanel}>
								<button
									className={css`
										float: left;
										border-radius: 50%;
										width: 30px;
										height: 30px;
										margin-bottom: 10px;
									`}
									onClick={this.handleHideVerifyExplanation}
								>
									<i className="fa fa-times" aria-hidden="true" />
								</button>
								{/* chart header */}
								<div>
									<StyledFlusRaceHeaderBar
										fluid
										noPadding
										className={componentClasses}
										type="primary"
										nodeLeft={
											<div>
												<StyledFlucsRaceHeaderBar__RaceNumber>{raceNumber}</StyledFlucsRaceHeaderBar__RaceNumber>
												<Icon
													className={css`
														padding: 6px;
													`}
													key="2"
													size="1"
													icon={RACING_TYPES_LOOKUP[Type]}
													push="1"
												/>
											</div>
										}
										margin={false}
									>
										<FlucsRaceHeaderBarModle>
											<PlotElements
												vertical
												align="space-around"
												alignElements="start"
												className={css`
													overflow: hidden;
												`}
											>
												<Text
													textOverflow="ellipsis"
													strong
													className={css`
														display: inline-block;
														white-space: nowrap;
													`}
													type="alternate"
												>
													{`${MettingName}`}
													<span
														className={css`
															font-size: ${FONT_SIZE_12};
															padding-right: 7px;
															padding-left: 7px;
															font-weight: normal;
														`}
													>{`(${meetingCountry})`}</span>
													<span
														className={css`
															font-size: ${FONT_SIZE_12};
															font-weight: normal;
														`}
													>{`- ${raceDistance}m`}</span>
												</Text>
												<StyledFeatureRaceSelections__Name
													mediaSize={size}
													textOverflow="ellipsis"
													size="-3"
													type="alternate"
												>
													{raceName}
												</StyledFeatureRaceSelections__Name>
												<StyledFeatureRaceSelections__Name
													mediaSize={size}
													textOverflow="ellipsis"
													size="-3"
													type="alternate"
												>
													{t('Race Date')} | {StartDate.split('T')[0]}
													<span
														className={css`
															padding-left: 10px;
														`}
													/>
													{t('Race Time')} | {StartDate.split('T')[1].substring(0, 8)}
												</StyledFeatureRaceSelections__Name>
											</PlotElements>
										</FlucsRaceHeaderBarModle>
									</StyledFlusRaceHeaderBar>
								</div>
								{/* selection details */}
								<StyledFeaRaceSelectionsDetails className={componentClasses}>
									<div>
										<StyledRaceSelectionsDetails__Silk size={size}>
											<StyledRaceSelectionsDetails__SilkImage
												size={size}
												src={this.state.runnerSilk ? this.state.runnerSilk : GENERIC_SILK}
												alt={'racing silk'}
												substituteImageSrc={GENERIC_SILK}
											/>
											<StyledFeRaceSelectionsDetails__LastStartsRow size="-5" align="center" strong mediaSize={size}>
												{this.state.last_starts_string}
											</StyledFeRaceSelectionsDetails__LastStartsRow>
										</StyledRaceSelectionsDetails__Silk>
										<StyledFeRaceSelectionsDetails__DetailsColumn size={size}>
											<StyledRaceSelectionsDetails__Name mediaSize={size} size="-2" transform="uppercase" strong>
												{this.state.runnerNumber && `${this.state.runnerNumber}.`} {this.state.runnerName}
											</StyledRaceSelectionsDetails__Name>
											<StyledRaceSelectionsDetails__RunnerDetails mediaSize={size} size="-3">
												Trainer: <b>{this.state.trainer}</b>
											</StyledRaceSelectionsDetails__RunnerDetails>
											<StyledRaceSelectionsDetails__RunnerDetails
												mediaSize={size}
												size="-3"
												whiteSpace="nowrap"
												textOverflow="ellipsis"
											>
												{Type && Type !== 'G' && this.state.jockey ? (
													<StyledRaceSelectionsDetails__RunnerDetails
														mediaSize={size}
														size="-3"
														whiteSpace="nowrap"
														textOverflow="ellipsis"
													>
														{Type !== 'H' ? (
															<StyledRaceSelectionsDetails__RunnerDetails tag="span" mediaSize={size} size="-3">
																Weight :{' '}
																<b>
																	{this.state.weight}
																	Kg
																</b>
																<span
																	className={css`
																		margin-right: 10px;
																	`}
																/>
															</StyledRaceSelectionsDetails__RunnerDetails>
														) : null}
														{Type !== 'H' ? 'Jockey' : 'Driver'} : <b>{this.state.jockey} </b>
													</StyledRaceSelectionsDetails__RunnerDetails>
												) : null}
											</StyledRaceSelectionsDetails__RunnerDetails>
										</StyledFeRaceSelectionsDetails__DetailsColumn>
									</div>
								</StyledFeaRaceSelectionsDetails>

								{/* chart body */}
								<LineChart
									width={650}
									height={550}
									data={this.state.FlucsData}
									syncId="anyId"
									margin={{
										top: 20,
										right: 30,
										left: 20,
										bottom: 10,
									}}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="fixTime"
										interval={1}
										angle={-70}
										dx={0}
										padding={{ top: -20, left: 30, right: 30 }}
										fontSize={9}
										textAnchor="end"
									/>
									<YAxis />
									<Tooltip />
									<Legend />
									<Line type="monotone" dataKey="fixed_odds" stroke="#B6000B" fill="#B6000B" />
								</LineChart>
							</Panel>
						</Modal>
					)}
				</Panel>
				{selections.length > 0 && (
					<StyledFeatureRaceSelectionList_header__Wrapper>
						<div
							className={css`
							flex: 1 1 auto;
							display: flex;
							overflow: hidden;
						`}
						>
							{selectionType && selectionType === 'favorite_selection' ? (
								<Text
									className={css`
									color: ${COLORS.primary};
									margin-top: 7px;
									text-transform: uppercase;
									padding-left: 10px;
									font-weight: ${WEIGHTS.bold};
									font-size: 12px;
								`}
								>
									Favorite Selections
								</Text>
							) : (
								<Text
									className={css`
									color: ${COLORS.primary};
									margin-top: 7px;
									text-transform: uppercase;
									padding-left: 10px;
									font-weight: ${WEIGHTS.bold};
									font-size: 12px;
								`}
								>
									Expert Selections
								</Text>
							)}
						</div>
						{this.state.FlucsData && (
							<StyledRaceSelectionsList__HeaderFlucs size={size}>
								{t('RaceSelectionsList__Flucs')}
							</StyledRaceSelectionsList__HeaderFlucs>
						)}

						{sortBySelectionType.map(
							(betProduct, i) =>
								// Cover cases where there is no win/place product.
								betProduct && (
									<StyledRaceSelectionsList__HeaderCell
										key={betProduct.index}
										small={small}
										emphasised={betProduct.bet_type === RACING_BET_TYPE_MARGIN}
										size={size}
										className={css`max-width: 50px;`}
									>
										{betProduct.bet_type == 'win' ? (
											<span
												className={css`
											text-transform: uppercase;
											font-size: 10px;
										`}
											>
												<img
													src={WIN_ICON_URL}
													width="20px"
													height="20px"
												/>
											</span>
										) : (
											<span
												className={css`
												text-transform: uppercase;
												font-size: 10px;
											`}
											>
												<img
													src={PLACE_ICON_URL}
													width="20px"
													height="20px"
												/>
											</span>
										)}
									</StyledRaceSelectionsList__HeaderCell>
								),
						)}
					</StyledFeatureRaceSelectionList_header__Wrapper>
				)}

				{selections && (
					<StyledbetFeatureRaceSelectionList__RaceSelectionsListItems center compact>
						{/* not scratched selections */}
						{selections
							.filter((selections) => selections.selection_status === SELECTION_NOT_SCRATCHED_STATUS)
							.slice(0, 3)
							.map((topselection, index) => (
								<FeatureRaceSelectionListItem
									key={topselection.id}
									onClickItem={onClickItem}
									{...topselection}
									addToSingle={this.handleSingle}
									addToMulti={this.handleMulti}
									selectionBetButtons={buildSelectionBetButtons(topselection.prices, displayedBetProducts.slice(0, 2), betType)}
									bettingAvailable={bettingAvailable}
									displayedFlucs={topselection.selection_fluctuations}
									betType={betType}
									size={size}
									onClickToRace={goToRace}
									StartDate={StartDate}
									MettingName={MettingName}
									meetingId={meetingId}
									raceId={raceId}
									boxed={boxed}
									ShowModal={this.ShowModal}
									pricesAvailable={topselection.prices && topselection.prices.length > 0 ? true : false}
								/>
							))}

						{/* scratched selections */}
						{/* {
							selections
								.filter((selections) => selections.selection_status !== SELECTION_NOT_SCRATCHED_STATUS)
								.map((topselection, index) => (
									<FeatureRaceSelectionListItem
										key={topselection.id}
										onClickItem={onClickItem}
										{...topselection}
										addToSingle={this.handleSingle}
										addToMulti={this.handleMulti}
										selectionBetButtons={buildSelectionBetButtons(topselection.prices, displayedBetProducts.slice(0, 2), betType)}
										bettingAvailable={bettingAvailable}
										displayedFlucs={topselection.selection_fluctuations}
										betType={betType}
										size={size}
										StartDate={StartDate}
										MettingName={MettingName}
										meetingId={meetingId}
										raceId={raceId}
										boxed={boxed}
										pricesAvailable={topselection.prices && topselection.prices.length > 0 ? true : false}
									/>
								))} */}
					</StyledbetFeatureRaceSelectionList__RaceSelectionsListItems>
				)}
			</StyledbetFeatureRaceSelectionList>
		);
	}
}

export default withNamespaces()(FeatureRaceSelectionList);
