import { PASSWORD_RESET_SET_TOKEN, PASSWORD_RESET_SUCCESSFUL } from './passwordResetActionTypes';

const initialState = {
	token: null,
	passwordResetSuccessful: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case PASSWORD_RESET_SET_TOKEN:
			return {
				...state,
				token: action.payload,
			};

		case PASSWORD_RESET_SUCCESSFUL:
			return {
				...state,
				passwordResetSuccessful: Boolean(action.payload),
			};

		default:
			return state;
	}
};
