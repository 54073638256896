import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BPayDeposit from '../../components/features/Deposit/BPayDepositDetails/BPayDeposit';
import { clearBpayData, fetchDepositDetails } from '../../store/deposits/depositActions';
import { trackGaEvent } from '../../store/trackingPixels/trackingActions';
import { DEPOSIT_DEFAULT, DEPOSIT_USER_ACCOUNT } from '../../store/deposits/depositsReducerNames';

class BPayDepositContainer extends React.Component {
	static propTypes = {
		/** biller code number */
		bpayBillerCode: PropTypes.string,

		/** Bpay Ref number */
		bpayRef: PropTypes.string,

		/** Bpay logo url. From backend */
		bpayLogo: PropTypes.string,

		/** Functions */
		/** Track on close click */
		track: PropTypes.func,

		/** Load bpay details on mount */
		loadDetails: PropTypes.func,

		/** clear data on store */
		clearBpayData: PropTypes.func,

		/** Close modal */
		onClose: PropTypes.func,
	};

	static defaultProps = {
		bpayBillerCode: undefined,
		bpayRef: undefined,
		bpayLogo: undefined,
		track: () => undefined,
		loadDetails: () => undefined,
		clearBpayData: () => undefined,
		onClose: undefined,
	};

	componentDidMount() {
		this.props.loadDetails();
	}

	componentWillUnmount() {
		this.props.clearBpayData();
	}

	handleClose = () => {
		this.props.track('Close');
		this.props.onClose();
	};

	render() {
		const { bpayRef, bpayBillerCode, bpayLogo, onClose } = this.props;

		return (
			<BPayDeposit
				bpayRef={bpayRef}
				billerCode={bpayBillerCode}
				logo={bpayLogo}
				onGotItClick={onClose ? this.handleClose : null}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const sliceOfState = ownProps.renderOnPage ? state[DEPOSIT_USER_ACCOUNT] : state[DEPOSIT_DEFAULT];

	const { bpayBillerCode, bpayRef, bpayLogo } = sliceOfState;
	return { bpayBillerCode, bpayRef, bpayLogo };
};

const mapDispatchToProps = (dispatch, ownProps) => {
	const reducerName = ownProps.renderOnPage ? DEPOSIT_USER_ACCOUNT : DEPOSIT_DEFAULT;

	return {
		track: (action) => dispatch(trackGaEvent('BPay Deposit', 'Click', action)),
		loadDetails: () => dispatch(fetchDepositDetails(reducerName, 'bpay')),
		clearBpayData: () => dispatch(clearBpayData(reducerName)),
		onClose: ownProps.onClose,
	};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(BPayDepositContainer);

Container.propTypes = {
	onClose: PropTypes.func,
};

Container.defaultTypes = {
	onClose: undefined,
};

export default Container;
