import { createAction } from '../../common/actions/actionHelpers';
import { navigate } from '../application/applicationActions';
import { NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK } from '../nextJumpHome/NextJumpHomeActionTypes';

/**
 * Navigate to the next-jump-races page
 * @HW make by hushani
 */
export const navigateToNextjumpHome = () => (dispatch) => {
	return dispatch(navigate('/next-jump-races', { trigger: true }));
};

export const setContainerLoadingMask = (isLoading) => createAction(NEXTJUMP_HOME_SET_CONTAINER_LOADING_MASK, isLoading);